export enum TitleTypes {
  h1 = 'H1',
  h2 = 'H2',
  h3 = 'H3',
  h4 = 'H4',
  info = 'Info',
  titleWithSubTitle = 'TITLE_SUBTITLE'
}
export enum HeadingTypes {
  heading = 'HEADING',
  subHeading = 'SUB_HEADING',
  infoHeading = 'INFO_HEADING',
  labelValueHeading = 'LABEL_VALUE_HEADING'
}

export const COMPOSITE_COLUMN_EQUAL = 'COMPOSITE_COLUMN_EQUAL';

/**
 * Enum for Dashboard Literal IDs.
 */
export enum DashboardLiteralIds {
  crossChannel = 'cross-channel',
  measuredBenchmarks = 'measured-benchmarks',
  geoDoe = 'geo-doe',
  homePage = 'home-page',
  optimizationReport = 'optimization-report'
}

export const defaultDashboardLiteralId = DashboardLiteralIds.crossChannel;

export const crossChannelDashboardLiteralIds = [
  DashboardLiteralIds.crossChannel
];
export const isCrossChannelDashboards = (literalId: string): boolean => {
  //TODO: Have Data grid config for the metrics needs to be visible or not
  return crossChannelDashboardLiteralIds.includes(
    literalId as DashboardLiteralIds
  );
};

export const landingDashboardWithFallBack: Record<
  string,
  { displayOrder: number }
> = {
  [DashboardLiteralIds.homePage]: { displayOrder: 1 },
  [DashboardLiteralIds.crossChannel]: { displayOrder: 2 },
  [DashboardLiteralIds.measuredBenchmarks]: { displayOrder: 3 },
  [DashboardLiteralIds.geoDoe]: { displayOrder: 4 }
};

export const filterConstants = {
  positionDivisor: 10,
  minimumFilterValueSelectedCount: 1
};

export const monthDateYearFormat = 'MM-DD-YYYY';

export const benchmarksTabMapping: Record<string, string> = {
  portfolioTab: 'portfolio_relative_day',
  growthTab: 'growth_relative_day'
};

export const demoDefaultClient = {
  clientId: 9995,
  brandId: 1354
};
