import { GradientColorObject, PatternObject } from 'highcharts';
import {
  DataGridAvailable,
  DataGridBarVisualizer,
  DataGridControls,
  DataGridEditable,
  DataGridEventDriven,
  DataGridGrouped,
  DataGridInfoWithIcon,
  DataGridSelected,
  DataGridSortedColumn
} from './datagrid';
import {
  BaseElement,
  ElementSubType,
  LayoutTypes,
  ViewElementType
} from './element-type';
import {
  ChartAvailableX,
  ChartAvailableY,
  ChartPlotlineX,
  ChartSelectedX,
  ChartSelectedY,
  DisplayControl,
  FilterControl,
  MetricControl,
  TableToggleControl
} from './metric';
import { DataResponse } from './response';

interface ElementGroup extends BaseElement {
  elements: (
    | FilterElement
    | HeroElement
    | HeroElementBounded
    | DataGridElement
    | ChartElement
    | BackBarElement
    | HeaderElement
    | TabElement
    | TitleElement
    | DashboardStaticTitle
    | CardComponent
    | CardComponentSection
    | EditPopup
    | ActionElement
    | ToggleElement
    | CardElement
    | RadioFilterElement
    | ResultHeader
    | IconElement
    | ExperimentDataElement
    | ViewElement
    | MetricCardElement
    | BreadCrumbElement
    | VerticalSeperatorElement
  )[];
  relativePosition?: RelativePosition[];
}

enum RelativePosition {
  first = 'first',
  middle = 'middle',
  last = 'last'
}

interface ViewElement extends BaseElement {
  layoutType: ViewElementType;
  elements?: (ViewElement | HeroElement)[];
  available?: ElementSubType[];
  selected?: ElementSubType[];
  ownerOnly?: ElementSubType[];
  inverse?: ElementSubType[];
}

interface DashboardStaticTitle extends BaseElement {
  layoutType: 'DASHBOARD_STATIC_TITLE';
}

interface CardComponent extends BaseElement {
  layoutType: 'CARD_COMPONENT';
}

interface CardComponentSection extends BaseElement {
  layoutType: 'CARD_COMPONENT_SECTION';
}

interface EditPopup extends BaseElement {
  layoutType: 'EDIT_POPUP';
}

interface BackBarElement extends BaseElement {
  layoutType: 'BACK_BAR';
}

interface HeaderElement extends BaseElement {
  layoutType: 'HEADER';
}
interface ResultHeader extends BaseElement {
  layoutType: 'RESULT_HEADER';
}

interface TabElement extends BaseElement {
  layoutType: 'TAB';
  elements: (
    | FilterElement
    | HeroElement
    | HeroElementBounded
    | DataGridElement
    | ChartElement
    | BackBarElement
    | HeaderElement
    | TabElement
    | TitleElement
    | ContextElement
    | ActionElement
    | ToggleElement
    | CardElement
    | IconElement
    | VerticalSeperatorElement
  )[];
}

interface ExperimentComparison {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ROW: { selected: ElementGroup[] };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  COLUMN: { selected: ElementGroup[]; export: ElementGroup[] };
  literalId: string;
  layoutType: 'EXPERIMENT_RESULT_COMPARISON_SECTION';
}

interface ExperimentExpansion {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  PIVOT_ROW: { selected: DataGridSelected[]; export: DataGridSelected[] };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  PIVOT_DIMENSION: { selected: ElementGroup[] };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  PIVOT_ROW_HEADER: { selected: ElementGroup[] };
  literalId: string;
  layoutType: 'EXPERIMENT_RESULT_COMPARISON_SECTION';
}
interface ExperimentCaption {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  RESULT_CAPTION: { selected: ElementGroup[] };
  literalId: string;
  layoutType: 'EXPERIMENT_RESULT_COMPARISON_SECTION';
}

interface ExperimentDataElement extends BaseElement {
  layoutType: 'EXPERIMENT_RESULT_DATA_SECTION';
  elements: (
    | ExperimentDataElement
    | ExperimentComparison
    | ExperimentCaption
    | ExperimentExpansion
  )[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  EXPERIMENT_LAYOUT_SELECTION_FILTER: {
    selected: ElementSubType[];
  };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  EXPERIMENT_LAYOUT_EXPANSION_SECTION_VISIBILITY: {
    selected: ElementSubType[];
  };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  EXPERIMENT_SIGNIFICANCE_FILTER: {
    selected: ElementSubType[];
  };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  EXPANSION_DATA_SET_GROUP_BY_FIELD: {
    groupBy: ElementSubType[];
  };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  EXPANSION_DATA_SET_DEFAULT: {
    defaultSettings: ElementSubType[];
  };
}

interface DataGridElement extends BaseElement {
  layoutType: 'DATA_SET';
  selected: (DataGridSelected | DataGridEditable)[];
  available: (DataGridAvailable | DataGridEditable)[];
  editable?: DataGridEditable[];
  grouped?: (DataGridGrouped | DataGridEditable)[];
  groupedBy?: (DataGridAvailable | DataGridEditable)[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  RELATED_WIDGET?: ElementGroup['elements'];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  COLUMN: {
    grouped?: (DataGridGrouped | DataGridEditable)[];
    selected: DataGridSelected[];
    available: DataGridAvailable[];
    groupedBy?: DataGridAvailable[];
    barVisualizer?: DataGridBarVisualizer[];
    infoWithIcon?: DataGridInfoWithIcon[];
  };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  DIMENSION: {
    editable?: DataGridEditable[];
    selected: DataGridSelected[];
    grouped?: DataGridGrouped[];
    available: (DataGridAvailable | DataGridEditable)[];
  };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  SORTED_COLUMN?: {
    ascending: DataGridSortedColumn[];
    descending: DataGridSortedColumn[];
  };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CONTROL?: {
    metricControl?: DataGridControls[];
    filterControl?: DataGridControls[];
    dimensionNavigationControl?: DataGridControls[];
  };

  elements?: BaseElement[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  EVENT_DRIVEN?: Record<string, DataGridEventDriven[]>;
  filterable: boolean;
  expandableSelected?: (DataGridSelected | DataGridEditable)[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  COMPONENT_TAB?: Record<string, ElementSubType[]>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ALLOW_SELECTION?: Record<string, ElementSubType[]>;
}

interface TabSectionElement extends BaseElement {
  layoutType: 'TAB_SECTION';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CONTROL?: {
    filterControl?: FilterControl[];
  };
  elements: TabElement[];
}

interface ContextElement extends BaseElement {
  layoutType: 'CONTEXT_BAR';
}

interface TitleElement extends BaseElement {
  layoutType: 'TITLE';
  selected: DashboardTitleElement[];
}

interface DashboardTitleElement extends BaseElement {
  layoutType:
    | 'HEADING'
    | 'EDITABLE_HEADING'
    | 'BADGE'
    | 'FAQ'
    | 'ICON_BADGE'
    | 'REFRESH_TIME';
}

interface FilterElement extends BaseElement {
  layoutType: 'FILTER_SET' | LayoutTypes.verticalSeperator;
}
interface VerticalSeperatorElement extends BaseElement {
  layoutType: 'VERTICAL_SEPERATOR';
}

interface ToggleElement extends BaseElement {
  layoutType: 'TOGGLE';
}

interface IconElement extends BaseElement {
  layoutType: 'DOWNLOAD_ICON';
}

interface ActionElement extends BaseElement {
  layoutType: 'ACTION_SET';
}

interface HeroElement extends BaseElement {
  layoutType: 'DATA_POINT';
}

interface CardElement extends BaseElement {
  layoutType: 'CARD_COMPONENT';
}

interface RadioFilterElement extends BaseElement {
  layoutType: 'FILTER_SET_RADIO';
}

interface BreadCrumbElement extends BaseElement {
  layoutType: 'BREAD_CRUMB';
}

interface HeroElementBounded extends BaseElement {
  layoutType: 'DATA_POINT_BOUNDED';
  children: string[];
}

interface MetricCardHeader extends ElementSubType {
  subType: 'primary' | 'secondary';
}

interface MetricCardData extends ElementSubType {
  isCompositeColumn?: boolean;
  subType: 'primary' | 'secondary' | 'alternate';
}

interface MetricCardVisualizer extends ElementSubType {
  otherItems?: number;
  totalItems?: number;
  vizMetric?: string;
  percentValue?: number | null;
  layoutType: 'BAR_VISUALIZER';
  subType: 'visualization';
}

interface MetricCardElement extends BaseElement {
  layoutType: 'BASEBALL_CARD';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  HEADER: {
    primary: MetricCardHeader[];
    secondary: MetricCardHeader[];
  };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  METRIC: {
    primary: MetricCardData[];
    secondary: MetricCardData[];
    alternate: MetricCardData[];
  };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  FOOTER: {
    visualization: MetricCardVisualizer[];
  };
}

interface ChartElement extends BaseElement {
  layoutType: 'DATA_CHART';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CONTROL?: {
    filterControl?: FilterControl[];
    metricControl?: MetricControl[];
    plotlineX: ChartPlotlineX[];
  };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  DIMENSION?: {
    availableY: ChartAvailableY[];
    selectedY: ChartSelectedY[];
  };
  plotlineX: ChartPlotlineX[];
  availableY: ChartAvailableY[];
  selectedY: ChartSelectedY[];
  availableX: ChartAvailableX[];
  selectedX: ChartSelectedX[];
  tableToggleControl?: TableToggleControl[];
  displayControl?: DisplayControl[];
  exportTable?: IconElement[];
}

type ChartDataElement = Record<
  string,
  {
    data: DataChartValue;
    chartElement: ChartElement;
  }
>;

interface ChartLegend {
  name: string;
  color: string | GradientColorObject | PatternObject | undefined;
  id: string | undefined;
  opacity: number;
}

interface DataChartValue {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  dataPoints?: DataResponse;
  seriesBy: boolean;
}

interface ChartGroupElement extends BaseElement {
  layoutType: 'DATA_CHART_GROUP';
  filterControl: FilterControl[];
  tableToggleControl?: TableToggleControl;
  exportTable?: IconElement[];
}

interface ChartCommonGroupElement extends BaseElement {
  layoutType: 'DATA_CHART_COMMON';
}

interface IFormat {
  format: string;
  type: string;
  digitsInfo: string;
}

interface ITableField {
  label: string;
  subText?: string | null;
  field: string;
  format: IFormat;
}

interface IExpandableTableData {
  [key: string]: string | DataResponse[] | IStaticPivotConfig;
  expandableData: DataResponse[];
  expansionData: IStaticPivotConfig;
}

interface IPivotRows {
  label: string;
  subText?: string | null;
  field: string;
  format: IFormat;
}

interface IStaticPivotConfig {
  pivotRows: IPivotRows[];
  exportPivotRows: IPivotRows[];
  pivotRowsToIgnore: string[];
  pivotRowHeaderLabel: string;
  pivotFields: { field: string }[];
  pivotData: DataResponse[];
  styleClass?: string;
  name: string;
}

interface IDynamicPivotConfig {
  pivotRowsBy: {
    label: string;
    field: string;
    aggregateBy: string;
  };
  pivotFields: {
    field: string;
    format: IFormat;
    aggregateFun: 'sum' | 'avg';
  }[];
  pivotData: DataResponse[];
}

interface IExportTableConfig {
  columns: ITableField[];
  data: IExpandableTableData[] | DataResponse[];
  tableType: string;
  tableName: string;
}

enum HeaderLayoutTypes {
  dashboardTitle = 'DASHBOARD_TITLE',
  heading = 'HEADING',
  editableHeading = 'EDITABLE_HEADING',
  badge = 'BADGE',
  faq = 'FAQ',
  iconBadge = 'ICON_BADGE',
  refreshTime = 'REFRESH_TIME'
}

export {
  FilterElement,
  HeroElement,
  HeroElementBounded,
  ChartElement,
  BackBarElement,
  DataGridElement,
  HeaderElement,
  TabElement,
  TitleElement,
  DashboardTitleElement,
  ContextElement,
  ActionElement,
  ToggleElement,
  CardElement,
  RadioFilterElement,
  EditPopup,
  IconElement,
  IFormat,
  ITableField,
  IDynamicPivotConfig,
  IExportTableConfig,
  IStaticPivotConfig,
  IPivotRows,
  IExpandableTableData,
  ExperimentDataElement,
  ExperimentCaption,
  ExperimentExpansion,
  ChartCommonGroupElement,
  ChartGroupElement,
  ElementGroup,
  RelativePosition,
  ChartDataElement,
  ChartLegend,
  ExperimentComparison,
  DataChartValue,
  ViewElement,
  MetricCardElement,
  MetricCardHeader,
  MetricCardData,
  MetricCardVisualizer,
  HeaderLayoutTypes,
  BreadCrumbElement,
  TabSectionElement,
  VerticalSeperatorElement
};
