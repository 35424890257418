<p-carousel
  class="m-geo-carousel"
  [styleClass]="styleClass"
  [value]="value"
  [numVisible]="numVisible"
  [numScroll]="numScroll"
  [circular]="circular"
  [responsiveOptions]="responsiveOptions"
  (onPage)="onPage.emit($event)">
  <ng-template let-item pTemplate="item">
    <div class="flex">
      <ng-container *ngIf="item?.conversionType; else itemName">
        <m-tooltip
          content="<div class='text-gray-500 mb-2 min-w-[152px]'>Conversion Type</div><div class='m-divider'></div><div class='mt-2'>{{
            item?.conversionType
          }}</div>"
          position="top"
          class="w-full"
          [tooltipOptions]="{
            positionLeft: -80,
            positionTop: -8,
          }">
          <ng-container *ngTemplateOutlet="itemName"></ng-container>
        </m-tooltip>
      </ng-container>
      <ng-template #itemName>
        <div class="flex items-center justify-between w-full">
          <div class="c3 text-gray-800">{{ item?.name }}</div>
          <em
            class="material-symbols-outlined icon-small icon-filled text-orange-200"
            >new_releases</em
          >
        </div>
      </ng-template>
    </div>

    <!-- Grid container with margin and grid -->
    <div class="grid gap-4 grid-cols-2 mt-3">
      <!-- First Column -->
      <div class="col-span-1">
        <m-card variant="small">
          <div class="c1 text-gray-800">Spend</div>
          <div class="flex items-center mt-1">
            <em class="material-symbols-outlined icon-small text-gray-500"
              >monetization_on</em
            >
            <div class="sh2 text-gray-1000 ml-1">{{ item?.spend }}</div>
          </div>
        </m-card>
      </div>

      <!-- Second Column -->
      <div class="col-span-1">
        <m-card variant="small">
          <div class="c1 text-gray-800">Contribution</div>
          <div class="flex items-center mt-1">
            <em class="material-symbols-outlined icon-small text-gray-500"
              >clock_loader_20</em
            >
            <div class="sh2 text-gray-1000 ml-1">{{ item?.contribution }}</div>
          </div>
        </m-card>
      </div>
    </div>

    <m-button
      (click)="onButtonClick(item?.conversionTypeId)"
      type="secondary"
      styleClass="w-full my-3">
      <div class="b2 text-blue-600 w-full text-center">Explore more</div>
    </m-button>
  </ng-template>
</p-carousel>
