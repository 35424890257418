import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@portal/environments/environment';
import {
  IAlert,
  IAlertData,
  IAlertActionData,
  IAlertCount
} from '@portal/app/shared/types/native/IAlert';
import {
  alertIconTypeMap,
  AlertItemProps,
  StackedAlertRequestParams,
  Status,
  UpdateAlertStatusRequestParams,
  IPrimaryActionButtonProperties,
  AlertIconTypeToNameMap,
  TabType,
  AlertButtonType,
  NotificationViewMode
} from '@design-system/components/m-alert-item';
import dayjs from 'dayjs';
import { compact, sortBy } from 'lodash-es';

const apiDomain = environment.apiDomain;

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  apiDomain = environment.apiDomain;
  private readonly apiRootV2 = `${apiDomain}/api/v2`;

  constructor(private httpClient: HttpClient) {}

  public getAlertsCount(
    clientId: number,
    brandId: number
  ): Observable<IAlertCount> {
    return this.httpClient.get<IAlertCount>(
      `${this.apiRootV2}/alerts/count/${clientId}/${brandId}`
    );
  }

  public getAllGroupedAlerts(
    clientId: number,
    brandId: number,
    tab: TabType
  ): Observable<IAlert> {
    return this.httpClient.post<IAlert>(
      `${this.apiRootV2}/alerts/${clientId}/${brandId}`,
      {
        activeTab: tab
      }
    );
  }

  public getStackedAlerts(
    clientId: number,
    brandId: number,
    stackedAlertConfig: StackedAlertRequestParams
  ): Observable<IAlert> {
    return this.httpClient.post<IAlert>(
      `${this.apiRootV2}/alerts/stacked/${clientId}/${brandId}`,
      {
        filters: stackedAlertConfig
      }
    );
  }

  public updateAlertStatus(
    clientId: number,
    brandId: number,
    updateAlertConfig: UpdateAlertStatusRequestParams[]
  ): Observable<IAlert> {
    return this.httpClient.put<IAlert>(
      `${this.apiRootV2}/alerts/${clientId}/${brandId}`,
      {
        alertStatusUpdateConfig: updateAlertConfig
      }
    );
  }

  mapPaginatedAlertsResponseToAlertItems(
    paginatedAlerts: IAlertData[],
    stackedPaginatedAlerts: boolean,
    notificationView: NotificationViewMode = NotificationViewMode.page
  ): AlertItemProps[] {
    return paginatedAlerts.map((alertObj) => {
      const id = (
        stackedPaginatedAlerts ? alertObj?.alertLogIds[0] : alertObj.id
      ) as number;
      const alertItem: AlertItemProps = {
        id,
        alertConfigId: alertObj.alertConfigId,
        alertLogIds: alertObj.alertLogIds,
        identifier: alertObj.identifier,
        isStacked: alertObj.stacked > 1,
        stackedCount: alertObj.stacked,
        alertIcon:
          alertIconTypeMap[alertObj.data.alertIconType] ||
          alertIconTypeMap[alertObj.data.type],
        title: alertObj.data.title,
        tag: alertObj.data.productShortName,
        timestamp: dayjs(alertObj.dateTime).fromNow(),
        body: [
          alertObj.data?.isLongDescription
            ? alertObj.data?.longDescription
            : alertObj.data?.description
        ],
        isRead: alertObj.alertStatus === Status.unread ? false : true,
        isResolved: alertObj.alertStatus === Status.resolved ? true : false,
        isActionAble: alertObj.data?.isActionable || false,
        icon:
          AlertIconTypeToNameMap[
            alertIconTypeMap[alertObj.data.alertIconType]
          ] || AlertIconTypeToNameMap[alertIconTypeMap[alertObj.data.type]],
        type: alertObj.data?.isActionable ? 'actionNeeded' : 'alerts',
        actionButtonsProps: [],
        isVisible: true,
        resolvedTime: dayjs(alertObj.updatedTime).fromNow(),
        resolvedBy: alertObj.updatedBy,
        shortDescription: [alertObj.data.description],
        isLongDescription: alertObj.data.isLongDescription,
        brandName: alertObj?.brandName,
        clientId: alertObj?.clientId,
        brandId: alertObj?.brandId,
        severity: this.formatSeverity(alertObj),
        studyName: alertObj?.data?.identifier?.['3'] || 'NA',
        alertStartDate: dayjs(alertObj?.alertStartDate)?.fromNow()
      };

      if (alertObj.data?.isActionable) {
        if (alertItem.isLongDescription && notificationView === 'POPUP') {
          alertItem.actionButtonsProps.push({
            label: 'View details',
            order: 1,
            type: AlertButtonType.viewDetails
          });
        } else {
          alertItem.actionButtonsProps.push({
            label: alertItem.isResolved
              ? 'Mark as unresolved'
              : 'Mark as resolved',
            order: 2,
            type: alertItem.isResolved
              ? AlertButtonType.markAsUnresolved
              : AlertButtonType.markAsResolved
          });
          const primaryActionButtonsProperties =
            this.getActionableButtonDetails(alertObj.data?.action);
          if (primaryActionButtonsProperties.length) {
            const primaryActionButtonProperties =
              primaryActionButtonsProperties[0];
            alertItem.actionButtonsProps.push({
              primaryActionButtonProperties,
              label: primaryActionButtonProperties?.displayName || '',
              order: primaryActionButtonProperties?.sequenceNumber || 0,
              type: AlertButtonType.actionButton
            });
          }
        }
      }

      alertItem.actionButtonsProps = sortBy(
        alertItem.actionButtonsProps,
        'order',
        'asc'
      );
      return alertItem;
    });
  }

  getActionableButtonDetails(
    actionData: IAlertActionData[]
  ): IPrimaryActionButtonProperties[] {
    return sortBy(
      compact(
        actionData.map((actionItem, index) => {
          if (actionItem && actionItem.actionProperties) {
            const { displayName, externalLink, redirectParams, tooltip } =
              actionItem.actionProperties;
            return {
              displayName,
              externalLink,
              routingLink: redirectParams?.suffix,
              tooltip,
              sequenceNumber: actionItem.sequenceNumber || index
            } as IPrimaryActionButtonProperties;
          }
          return null;
        })
      ),
      'sequenceNumber',
      'asc'
    );
  }

  formatSeverity(alertObj: IAlertData): string | undefined {
    const severity =
      alertObj.data.severity?.UI || alertObj.data.severity?.SLACK;
    return severity
      ?.toLowerCase()
      ?.replace(/^\w/, (c: string) => c.toUpperCase());
  }
}
