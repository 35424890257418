import dayjs, { Dayjs } from 'dayjs';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

// Services
import { ApiService } from '@portal/app/shared/services/api.service';
import { CompareService } from '@portal/app/shared/services/compare.service';
import { FilterService } from '@portal/app/dashboard/home-page/services/filter.service';
import { DayJsDateFormat } from '@portal/app/shared/services/date-time.service';

// Utils
import { FilterSetUtil } from '@portal/app/dashboard/filter-set/filter-set-util';

// Types
import type {
  DateRange,
  DateRangeData,
  Filter,
  FilterValue
} from '@portal/app/shared/types';
import type { SelectItem } from 'primeng/api';
import { Store } from '@ngrx/store';
import { AppState, DashboardState } from '@portal/app/store/app.state';
import { selectStore } from '@portal/app/store/app.selectors';

@Injectable({
  providedIn: 'root'
})
export class HeaderService implements OnDestroy {
  private destroy$ = new Subject<void>();

  // Conversion type Filter value
  private selectedConversionType = new BehaviorSubject<FilterValue>('');
  selectedConversionType$ = this.selectedConversionType.asObservable();

  // Conversion type Filter options
  private conversionTypeOptions = new BehaviorSubject<SelectItem[]>([]);
  conversionTypeOptions$ = this.conversionTypeOptions.asObservable();

  // Relative Date dropdown value
  private selectedRelativeDaySubject = new BehaviorSubject<Filter | undefined>(
    undefined
  );

  selectedRelativeDay$ = this.selectedRelativeDaySubject.asObservable();

  // Day Filter value
  private selectedDateRangeSubject = new BehaviorSubject<DateRange | null>(
    null
  );

  selectedDateRange$ = this.selectedDateRangeSubject.asObservable();

  // Compare Filter
  private compareFilterSubject = new BehaviorSubject<Filter | undefined>(
    undefined
  );

  compareFilter$ = this.compareFilterSubject.asObservable();

  private dashboardStateSubject = new BehaviorSubject<
    DashboardState | undefined
  >(undefined);

  dashboardState$ = this.dashboardStateSubject.asObservable();

  // Compare date Filter value
  private compareDateRangeSubject = new BehaviorSubject<DateRange | null>(null);
  compareDateRange$ = this.compareDateRangeSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private compareService: CompareService,
    private filterService: FilterService,
    private store: Store<AppState>
  ) {
    this.initFilters();
    this.setDashboardState();
  }

  private initFilters() {
    this.filterService.filters$
      .pipe(takeUntil(this.destroy$))
      .subscribe((filters) => {
        this.updateConversionTypeOptions(filters);
        this.updateSelectedValuesFromFilters(filters);
      });
  }

  private setDashboardState() {
    this.store
      .select(selectStore)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => this.dashboardStateSubject.next(state.dashboard));
  }

  private updateConversionTypeOptions(filters: Filter[]) {
    const conversionTypeFilter = filters.find(
      (filter) => filter.literalId === 'conversion_type'
    );
    if (conversionTypeFilter?.options) {
      const options = conversionTypeFilter.options.map((option) => ({
        label: option,
        value: option
      }));
      this.conversionTypeOptions.next(options);
    }
  }

  private updateSelectedValuesFromFilters(filters: Filter[]): void {
    this.updateConversionType(filters);
    this.updateRelativeDay(filters);
    this.updateDateRange(filters);
    this.updateCompareFlag();
    this.updateCompareDateRange(filters);
    this.updateCompareFilter(filters);
  }

  private updateCompareFilter(filters: Filter[]) {
    const compareFilter = filters.find((f) => f.literalId === 'compare_date');
    this.compareFilterSubject.next(compareFilter);
  }

  private updateConversionType(filters: Filter[]): void {
    const filter = filters.find((f) => f.literalId === 'conversion_type');
    if (filter?.value) {
      this.selectedConversionType.next(filter.value);
    }
  }

  private updateRelativeDay(filters: Filter[]): void {
    const filter = filters.find((f) => f.literalId === 'relative_day');
    if (filter?.value) {
      this.selectedRelativeDaySubject.next(filter);
    }
  }

  private updateDateRange(filters: Filter[]): void {
    const startDate = filters.find((f) => f.literalId === 'dateStart')
      ?.value as string;
    const endDate = filters.find((f) => f.literalId === 'dateStop')
      ?.value as string;
    const dateRange = FilterSetUtil.setAsDateRange([startDate, endDate]);
    if (dateRange) {
      this.selectedDateRangeSubject.next(dateRange);
    }
  }

  private updateCompareFlag(): void {
    this.compareService.compareIsAvailable = true;
    this.compareService.compare = true;
  }

  private updateCompareDateRange(filters: Filter[]): void {
    const compareDate = filters.find((f) => f.literalId === 'compare_date')
      ?.value as string[];
    // const endDate = filters.find((f) => f.literalId === 'compareStop')
    //   ?.value as string;
    const dateRange = FilterSetUtil.setAsDateRange(compareDate);
    if (dateRange) {
      this.compareDateRangeSubject.next(dateRange);
    }
  }

  setConversionType(conversionType: string) {
    this.filterService.updateFilterValue('conversion_type', conversionType);
    const conversionTypeFilter = this.filterService
      .getCurrentFilters()
      .find((filter: Filter) => filter.literalId === 'conversion_type');
    if (conversionTypeFilter) {
      this.selectedConversionType.next(conversionType);
      // Pass the full conversionTypeFilter object to the API call
      this.apiService.overrideUserFilters([
        { ...conversionTypeFilter, value: conversionType }
      ]);
    }
  }

  setRelativeDay(relativeDay: string) {
    const dateFilters = this.filterService.getDateFilters(relativeDay);
    if (dateFilters) {
      this.filterService.setFilters(dateFilters);
    }
    const relativeDayFilter = this.filterService
      .getCurrentFilters()
      .find((filter: Filter) => filter.literalId === 'relative_day');

    const updatedFilter = {
      ...relativeDayFilter,
      value: relativeDay
    } as Filter;
    this.selectedRelativeDaySubject.next(updatedFilter);

    if (relativeDayFilter) {
      this.apiService.overrideUserFilters([updatedFilter]);
    }
  }

  setDateFilters(dateRangeData: DateRangeData[]) {
    const currentFilters = this.filterService.getCurrentFilters();

    const filterKeys = ['compare', 'compare_date', 'day', 'relative_day'];
    const dateFilters = filterKeys
      .map((key: string) => {
        const filter = currentFilters.find((f) => f.literalId === key);
        if (!filter) {
          return null; // Skip this filter if not found
        }

        // Find the corresponding value in dateRangeData
        const dataEntry = dateRangeData.find((d) => d.filterLiteralId === key);
        let value = dataEntry ? dataEntry.value : null;

        if ((key === 'compare_date' || key === 'day') && value) {
          // Convert the date values if applicable
          value = this.convertDate(value as Dayjs[]);
        }

        // Return the updated filter
        return { ...filter, value };
      })
      .filter((filter) => filter !== null); // Filter out any null entries

    if (dateFilters.length > 0) {
      this.apiService.overrideUserFilters(dateFilters as Filter[]);
    }
  }

  updateDateFilters(dateRangeData: DateRangeData[]) {
    const currentFilters = this.filterService.getCurrentFilters();
    // filters
    const dayFilter = currentFilters.find(
      (f) => f.literalId === 'day'
    ) as Filter;
    const compareDateFilter = currentFilters.find(
      (f) => f.literalId === 'compare_date'
    ) as Filter;
    const relativeDayFilter = currentFilters.find(
      (f) => f.literalId === 'relative_day'
    ) as Filter;

    // values
    const day = dateRangeData.find(
      (f: DateRangeData) => f.filterLiteralId === 'day'
    )?.value as FilterValue;
    const compareDate = dateRangeData.find(
      (f: DateRangeData) => f.filterLiteralId === 'compare_date'
    )?.value as FilterValue;
    const relativeDay = dateRangeData.find(
      (f: DateRangeData) => f.filterLiteralId === 'relative_day'
    )?.value as FilterValue;

    const dateFilters = this.filterService.getCustomDateFilters(
      { ...dayFilter, value: day },
      { ...compareDateFilter, value: compareDate }
    );

    if (dateFilters) {
      this.filterService.setFilters([
        ...dateFilters,
        { ...relativeDayFilter, value: relativeDay }
      ]);
    }
  }

  convertDate(dates: Dayjs[]) {
    return dates?.map((date: Dayjs) =>
      dayjs(date).format(DayJsDateFormat.fullDate)
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
