import { computed, inject, Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { toSignal } from '@angular/core/rxjs-interop';
import { catchError, filter, map, Observable, of, switchMap, tap } from 'rxjs';
import {
  Options,
  SeriesOptionsType,
  TooltipOptions,
  XAxisOptions,
  YAxisOptions
} from 'highcharts';
import { isEmpty } from 'lodash-es';
import {
  TrendingDataByMetricResponseDTO,
  MetricDetailsRequestDTO,
  MetricsResponseDTO,
  TrendingDataByMetricDataResponseDTO
} from '@portal/app/dashboard/context-modal/context-model.types';
import { ModalService } from '@portal/app/dashboard/context-modal/services/modal.service';
import { MetricsService } from '@portal/app/dashboard/context-modal/services/metrics.service';
import { colors } from '@design-system/styles/colors/colors.config';
import { FormatterService } from '@portal/app/shared/services/formatter.service';
import { FieldDefinitions } from '@portal/app/shared/types';
import { baseChartConfig } from '@design-system/components/m-line-chart/m-line-chart.component.const';
import { getGradientFill } from '@design-system/components/m-line-chart';

const singleLineChart = { ...baseChartConfig };

interface TrendingDataByMetricResponse {
  response: TrendingDataByMetricResponseDTO;
  chartOptions: Options;
}

@Injectable()
export class TrendingChartService {
  private readonly http: HttpClient = inject(HttpClient);
  private readonly formatterService = inject(FormatterService);

  private readonly modalService: ModalService = inject(ModalService);

  private readonly metricsService: MetricsService = inject(MetricsService);

  private initialValue: TrendingDataByMetricResponse = {
    response: {
      data: [] as TrendingDataByMetricDataResponseDTO[],
      fieldDefinition: {} as FieldDefinitions
    } as TrendingDataByMetricResponseDTO,
    chartOptions: {} as Options
  };

  private isLoadingSignal = signal<boolean>(false);

  public data = toSignal(this.fetchData$(), {
    initialValue: this.initialValue
  });

  get isLoading() {
    return this.isLoadingSignal.asReadonly();
  }

  public isChartReady = computed(() => !isEmpty(this.data().chartOptions));
  public chartOptions = computed(() => this.data().chartOptions);

  getParams() {
    const modalParams = this.modalService.modalParams();
    return {
      ...this.modalService.getParams(),
      dimension: modalParams.channel ? 'channel' : 'tactic',
      filter: {
        ...this.modalService.getDateParams(),
        channel: modalParams.channel,
        tactic: modalParams.tactic ? modalParams.tactic : undefined,
        conversion_type: this.modalService.conversionType(),
        literalId: this.metricsService.getActiveMetric()?.literalId
      }
    } as MetricDetailsRequestDTO;
  }

  fetchData$(): Observable<TrendingDataByMetricResponse> {
    const url = `${ModalService.baseUriV1}/metric-level-trending-data`;

    return this.metricsService.activeTabIndex$.pipe(
      filter((index: number) => index > -1),
      tap(() => this.isLoadingSignal.set(true)),
      switchMap(() =>
        this.http
          .post<TrendingDataByMetricResponseDTO>(url, this.getParams())
          .pipe(
            catchError((error) => {
              console.error('Error fetching top metric data', error);
              this.isLoadingSignal.set(false);
              return of({
                data: {},
                fieldDefinition: {}
              } as TrendingDataByMetricResponseDTO);
            }),
            map((response) => {
              response.data = response.data.map((item) => {
                // Check for valid numeric values, excluding NaN, Infinity, and -Infinity
                item.value =
                  !isNaN(item.value) && isFinite(item.value) ? item.value : 0;
                return item;
              });
              return {
                response,
                chartOptions: this.getChartOptions(response)
              };
            })
          )
      ),
      tap(() => this.isLoadingSignal.set(false))
    );
  }

  getChartOptions(response: TrendingDataByMetricResponseDTO) {
    const categories = response?.data?.map(
      (item: { date: string }) => item.date
    );
    const activeMetric = this.metricsService.getActiveMetric();

    const chartOptions = this.updateSingleLineChart(
      response,
      categories,
      activeMetric || ({} as MetricsResponseDTO)
    ) as Options;
    return chartOptions;
  }

  public updateSingleLineChart(
    response: TrendingDataByMetricResponseDTO,
    categories: string[],
    activeMetric: MetricsResponseDTO
  ) {
    if (response?.data?.length > 0) {
      const firstSeries = singleLineChart?.series?.[0];
      const newSeriesData = this.formatSeriesData(
        response,
        activeMetric,
        firstSeries
      );
      const xAxisCategories = this.formatXAxisCategories(categories);
      const yAxisOptions = this.formatYAxisOptions(response, activeMetric);
      const tooltipOptions = this.formatTooltipOptions(response);

      // Assemble the new chart options
      return {
        ...singleLineChart,
        series: [newSeriesData],
        xAxis: xAxisCategories,
        yAxis: yAxisOptions,
        tooltip: tooltipOptions
      } as Options;
    }
  }

  private formatSeriesData(
    response: TrendingDataByMetricResponseDTO,
    activeMetric: MetricsResponseDTO,
    firstSeries?: SeriesOptionsType
  ) {
    return {
      ...firstSeries,
      data: response.data.map((item) => item.value),
      name: activeMetric.label,
      color: colors['gray-800'],
      fillColor: getGradientFill(colors['gray-500'] as string)
    };
  }

  private formatXAxisCategories(categories: string[]): XAxisOptions {
    return {
      ...singleLineChart?.xAxis,
      categories,
      crosshair: {
        width: 1.5,
        color: colors['gray-1100'],
        dashStyle: 'Dash'
      }
    };
  }

  private formatYAxisOptions(
    response: TrendingDataByMetricResponseDTO,
    activeMetric: MetricsResponseDTO
  ) {
    const formatterService = this.formatterService;

    const values = response.data.map((item) => item.value);
    const min = Math.min(...values);
    let max = Math.max(...values);
    const buffer = max * 0.25;
    max += buffer;

    return {
      ...singleLineChart?.yAxis,
      min,
      max,
      labels: {
        formatter() {
          const value = this.value as number;
          const field = response.fieldDefinition[activeMetric.literalId];
          if (field) {
            return formatterService.format(field, value);
          }
          return value;
        }
      },
      crosshair: {
        width: 1,
        color: colors['gray-800'],
        dashStyle: 'Dash'
      }
    } as YAxisOptions;
  }

  private formatTooltipOptions(response: TrendingDataByMetricResponseDTO) {
    return {
      useHTML: true,
      formatter() {
        let tooltipHtml = `<div class="tooltip-body">`;
        tooltipHtml += `<span class="b3 text-gray-000 text-center block">${this.x}</span>`;
        tooltipHtml += `<div class="m-divider dark my-2"></div>`;

        if (this.y !== null && this.y !== undefined) {
          const row = response.data[this.point.index];
          const seriesName = this.series.name;

          tooltipHtml += `<div class="flex justify-between items-center text-gray-000">`;
          tooltipHtml += `<div><span style="color:${this.color}">\u25CF</span> ${seriesName}:</div>`;
          tooltipHtml += `<span>${row?.formattedValue}</span>`;
          tooltipHtml += `</div>`;
        }

        tooltipHtml += `</div>`;
        return tooltipHtml;
      }
    } as TooltipOptions;
  }
}
