import { FieldDefinitions } from '..';
import { ElementGroup } from './element';

interface PortalEvent {
  id?: string | number;
  action?: string;
  eventCategory?: PortalEventCategory;
  payload?: PortalEventPayload;
  metaData?: PortalMetaData;
}

interface PortalEventPayload {
  [index: string]:
    | string
    | number
    | undefined
    | ElementGroup
    | FieldDefinitions;
  item?: ElementGroup;
  notes?: string;
  fieldDefinitions?: FieldDefinitions;
}

type PortalMetaData = Record<string, string | number | boolean | null>;

enum PortalEventCategory {
  multiViewEvent = 'MULTI_VIEW_EVENT'
}

export { PortalEvent, PortalEventCategory, PortalEventPayload, PortalMetaData };
