/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { DropdownService } from '@portal/app/dashboard/home-page/services/dropdown.service';
import { TrendingChartService } from '@portal/app/dashboard/home-page/components/trending-chart/trending-chart.service';

@Component({
  selector: 'portal-trending-chart',
  templateUrl: './trending-chart.component.html'
})
export class TrendingChartComponent implements OnInit, OnDestroy {
  chartRelativeDay = '';
  private destroy$ = new Subject<void>();
  isLoading = true;
  relativeDayOptions?: SelectItem[];
  singleLineChartOptions: Highcharts.Options | undefined = undefined;
  isChartReady: boolean[] = [];
  previousRelativeDay: string | null = null;

  constructor(
    private dropdownService: DropdownService,
    private trendingChartService: TrendingChartService
  ) {}

  ngOnInit() {
    this.relativeDayOptions = this.dropdownService.getRelativeDayOptions();
    this.subscribeToData();
  }

  subscribeToData() {
    this.trendingChartService.chartData$
      .pipe(takeUntil(this.destroy$))
      .subscribe((chartData) => {
        this.singleLineChartOptions = chartData;
      });

    this.trendingChartService.isLoading$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoading) => {
        if (isLoading !== this.isLoading) {
          this.isLoading = isLoading;
        }
      });

    this.trendingChartService.filters$
      .pipe(takeUntil(this.destroy$))
      .subscribe((filters) => {
        this.chartRelativeDay =
          this.trendingChartService.getRelativeDay(filters);
      });
  }

  onChartRelativeDayChange(newValue: string) {
    if (newValue !== this.previousRelativeDay) {
      this.previousRelativeDay = newValue;
      this.trendingChartService.fetchDataWithTempFilters(newValue);
    }
  }

  // Handles cleanup when the service is destroyed
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
