import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataGridComponent } from '@portal/app/datagrid/data-grid/data-grid.component';
import { DataGridGroupComponent } from '@portal/app/datagrid/data-grid-group/data-grid-group.component';
import { DataGridRowComponent } from '@portal/app/datagrid/data-grid-row/data-grid-row.component';
import { DeltaCellComponent } from '@portal/app/datagrid/delta-cell/delta-cell.component';
import { SharedModule } from '@portal/app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { PrimengModule } from '@portal/app/datagrid/primeng.module';
import { DataGridPivotComponent } from '@portal/app/datagrid/data-grid-pivot/data-grid-pivot.component';
import { ComponentTabComponent } from '@portal/app/shared/components/component-tab/component-tab.component';
import { CompareColumnComponent } from '@portal/app/datagrid/compare-column/compare-column.component';
import { DataGridPivotV1Component } from '@portal/app/datagrid/data-grid-pivot/data-grid-pivot-v1.component';
import { DataGridRowGroupExpandComponent } from '@portal/app/datagrid/data-grid-row-group-expand/data-grid-row-group-expand';
import { NavigationColumnComponent } from '@portal/app/datagrid/navigation-column/navigation-column.component';
import { LinkColumnComponent } from '@portal/app/datagrid/link-column/link-column.component';
import { DataGridDimensionNavigatorComponent } from '@portal/app/datagrid/data-grid-dimension-navigator/data-grid-dimension-navigator.component';
import { CommonServicesModule } from '@libs/common-services';
import { ContextModalModule } from '@portal/app/dashboard/context-modal/context-modal.module';

@NgModule({
  declarations: [
    DataGridComponent,
    DataGridGroupComponent,
    DataGridRowComponent,
    DataGridPivotComponent,
    DeltaCellComponent,
    ComponentTabComponent,
    CompareColumnComponent,
    DataGridPivotV1Component,
    DataGridRowGroupExpandComponent,
    NavigationColumnComponent,
    LinkColumnComponent,
    DataGridDimensionNavigatorComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    PrimengModule,
    CommonServicesModule,
    ContextModalModule
  ],
  exports: [
    DataGridComponent,
    DataGridGroupComponent,
    DataGridRowComponent,
    DataGridPivotComponent,
    ComponentTabComponent,
    DeltaCellComponent,
    CompareColumnComponent,
    DataGridPivotV1Component,
    DataGridRowGroupExpandComponent,
    NavigationColumnComponent,
    LinkColumnComponent
  ]
})
export class DataGridModule {}
