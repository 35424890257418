import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ApiService } from '@libs/apis';
import { IHeroMetricConfig } from '../models/IHeroMetricConfig';
import { CampaignViewService } from '../services/campaign-view.service';
import { SharedDataService } from '../services/shared-data.service';
import { Constants } from './taxonomy-container.constants';

@Component({
  selector: 'lib-taxonomy-container',
  templateUrl: './taxonomy-container.component.html',
  styleUrls: ['./taxonomy-container.component.scss']
})
export class TaxonomyContainerComponent implements OnChanges {
  @Input()
  public clientId = 0;

  @Input()
  public brandId = 0;

  public headerConfig = Constants.headerConfig;
  public lastUpdatedAtConfig = Constants.lastUpdatedAtConfig;
  public bannerConfig = Constants.bannerConfig;
  public heroMetricConfig: IHeroMetricConfig[] = [];
  public showBanner = true;

  constructor(
    private readonly apiService: ApiService,
    private readonly campaignViewService: CampaignViewService,
    private readonly sharedDataService: SharedDataService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['clientId'] &&
      changes['clientId'].currentValue &&
      changes['clientId'].currentValue !== changes['clientId'].previousValue
    ) {
      this.initializeComponent();
    }
  }

  private initializeComponent() {
    this.getLastUpdatedAtDate();
    this.prepareHeroMetricConfig().catch((err) => {
      console.error(err);
    });
    this.sharedDataService.setClientAndBrandId(this.clientId, this.brandId);
  }

  private async prepareHeroMetricConfig() {
    const heroMetrics =
      await this.apiService.taxonomyApisOperations.getHeroMetrics(
        this.clientId,
        this.brandId
      );
    this.heroMetricConfig =
      this.campaignViewService.getHeroMetricConfig(heroMetrics);
  }

  private getLastUpdatedAtDate() {
    this.apiService.taxonomyApisOperations
      .getLastUpdatedAtDate(this.clientId, this.brandId)
      .then((lastRefresh) => {
        if (this.lastUpdatedAtConfig[0]) {
          this.lastUpdatedAtConfig[0].label = `Last updated: ${lastRefresh.lastRefreshDate}`;
        }
      })
      .catch((err) => {
        console.error(err);
        if (this.lastUpdatedAtConfig[0]) {
          this.lastUpdatedAtConfig[0].label = 'Last updated: (Not available)';
        }
      });
  }

  public learnMore() {
    window.open(Constants.learnMoreUrl, '_blank');
  }
}
