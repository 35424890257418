import { isEmpty, sortBy } from 'lodash-es';
import { GeoCellDisplayStatus, GeoTestStatus } from '../models/ICPAdTestConfig';
import { GeoTestType, IColumnIcon } from '@libs/apis';

export const statusFilterOptions = [
  {
    label: 'Active',
    value: GeoTestStatus.active
  },
  {
    label: 'Scheduled',
    value: GeoTestStatus.scheduled
  },
  {
    label: 'Finished',
    value: GeoTestStatus.finished
  }
];

export enum ColumnType {
  text = 'TEXT',
  textWithIcon = 'TEXT_WITH_ICON',
  statusBadge = 'STATUS_BADGE',
  date = 'DATE',
  dateRange = 'DATE_RANGE',
  currency = 'CURRENCY',
  metric = 'METRIC',
  metadata = 'METADATA',
  switch = 'SWITCH',
  percent = 'PERCENT',
  custom = 'CUSTOM',
  boolean = 'BOOLEAN',
  action = 'ACTION',
  loading = 'LOADING'
}

export interface IGeoTestColumnDefinition {
  header: string;
  field: string;
  order: number;
  availableFor: GeoTestStatus[];
  width: string;
  styleClass?: string;
  columnType?: ColumnType;
  isLink?: boolean;
  columnInfo?: string;
  options?: DateRangeProgressOptions;
}

export enum GeoTestBudgetLevel {
  adset = 'adset',
  campaign = 'campaign'
}

export interface IGeoTestMediaFieldDefinition {
  header: string;
  field: string;
  order: number;
  availableFor: GeoTestBudgetLevel[];
  width: string;
  styleClass?: string;
  forTypes: GeoTestType[];
  columnType: ColumnType;
}

interface DateRangeProgressOptions {
  showProgress: boolean;
  startDateField: string;
  endDateField: string;
}

export class GeoTestsFieldDefinition {
  private static readonly geoTestsFieldDefinition: IGeoTestColumnDefinition[] =
    [
      {
        header: 'Test Name',
        field: 'testName',
        order: 1,
        availableFor: [
          GeoTestStatus.active,
          GeoTestStatus.scheduled,
          GeoTestStatus.finished
        ],
        width: '17.5rem',
        styleClass: 'w-full',
        columnType: ColumnType.textWithIcon,
        isLink: true
      },
      {
        header: 'ID',
        field: 'studyId',
        order: 2,
        availableFor: [
          GeoTestStatus.active,
          GeoTestStatus.scheduled,
          GeoTestStatus.finished
        ],
        width: '5rem'
      },
      {
        header: 'New Adjustment Factor',
        field: 'percInc',
        columnType: ColumnType.metric,
        order: 3,
        availableFor: [GeoTestStatus.finished],
        width: '13.75rem',
        styleClass: 'fw-bold'
      },
      {
        header: 'Contribution',
        field: 'percOrdersI',
        columnType: ColumnType.metric,
        order: 4,
        availableFor: [GeoTestStatus.finished],
        width: '10rem',
        styleClass: 'fw-bold',
        columnInfo: 'Contribution to orders over the test duration'
      },
      {
        header: 'Conversion Type',
        field: 'conversionType',
        columnType: ColumnType.metadata,
        order: 5,
        availableFor: [
          GeoTestStatus.active,
          GeoTestStatus.scheduled,
          GeoTestStatus.finished
        ],
        width: '13.75rem'
      },
      {
        header: 'Type',
        field: 'treatment',
        columnType: ColumnType.metadata,
        order: 6,
        availableFor: [
          GeoTestStatus.active,
          GeoTestStatus.scheduled,
          GeoTestStatus.finished
        ],
        width: '11.125rem'
      },
      {
        header: 'Implementation',
        field: 'executionMethod',
        columnType: ColumnType.metadata,
        order: 7,
        availableFor: [
          GeoTestStatus.active,
          GeoTestStatus.scheduled,
          GeoTestStatus.finished
        ],
        width: '10.375rem'
      },
      {
        header: 'Status',
        field: 'displayStatus',
        order: 8,
        availableFor: [GeoTestStatus.active, GeoTestStatus.scheduled],
        width: '9.375rem',
        columnType: ColumnType.statusBadge
      },
      {
        header: 'Test Dates',
        field: 'startDate',
        order: 9,
        availableFor: [GeoTestStatus.active],
        width: '14rem',
        columnType: ColumnType.dateRange,
        options: {
          showProgress: true,
          startDateField: 'startDate',
          endDateField: 'endDate'
        }
      },
      {
        header: 'Test Dates',
        field: 'startDate',
        order: 10,
        availableFor: [GeoTestStatus.scheduled, GeoTestStatus.finished],
        width: '14rem',
        columnType: ColumnType.dateRange,
        options: {
          showProgress: false,
          startDateField: 'startDate',
          endDateField: 'endDate'
        }
      }
    ];

  private static readonly recommendedTestsFieldDefinition: IGeoTestColumnDefinition[] =
    [
      {
        header: 'Tests',
        field: 'name',
        order: 1,
        availableFor: [],
        width: '26.5rem',
        styleClass: 'w-full',
        columnType: ColumnType.textWithIcon,
        isLink: true
      },
      {
        header: 'Type',
        field: 'testType',
        columnType: ColumnType.metadata,
        order: 2,
        availableFor: [],
        width: '11.125rem'
      },
      {
        header: 'Implementation',
        field: 'method',
        columnType: ColumnType.metadata,
        order: 3,
        availableFor: [],
        width: '10.375rem'
      },
      {
        header: 'Scale Cost',
        field: 'additionalSpend',
        order: 4,
        availableFor: [],
        width: '8.325rem',
        columnType: ColumnType.metric,
        columnInfo: 'Cost to run this scale test'
      },
      {
        header: 'Spend',
        field: 'mediaSpend',
        order: 5,
        availableFor: [],
        width: '6.5rem',
        styleClass: 'p-table-column-highlight',
        columnType: ColumnType.metric,
        columnInfo: 'Amount spent over the last 30 days'
      },
      {
        header: 'Contribution',
        field: 'percOrdersI',
        columnType: ColumnType.metric,
        order: 6,
        availableFor: [],
        width: '9.125rem',
        styleClass: 'p-table-column-highlight',
        columnInfo: 'Contribution to orders over the test duration'
      },
      {
        header: 'Incremental ROAS',
        field: 'incrementalRoas',
        columnType: ColumnType.metric,
        order: 7,
        availableFor: [],
        width: '12rem',
        styleClass: 'p-table-column-highlight',
        columnInfo: 'Incremental ROAS over the test duration'
      },
      {
        header: 'Tested',
        field: 'lastTested',
        columnType: ColumnType.date,
        order: 8,
        availableFor: [],
        width: '8.325rem'
      }
    ];

  private static testTacticFields: IGeoTestMediaFieldDefinition[] = [
    {
      header: 'Tactic',
      field: 'tactic',
      order: 1,
      width: '50rem',
      availableFor: [GeoTestBudgetLevel.campaign, GeoTestBudgetLevel.adset],
      forTypes: [GeoTestType.scale, GeoTestType.holdout, GeoTestType.lift],
      columnType: ColumnType.custom
    },
    {
      header: 'Reference Spend',
      field: 'refSpend',
      order: 2,
      width: '8rem',
      availableFor: [GeoTestBudgetLevel.campaign, GeoTestBudgetLevel.adset],
      forTypes: [GeoTestType.scale, GeoTestType.holdout, GeoTestType.lift],
      columnType: ColumnType.currency
    },
    {
      header: 'Reference Contribution',
      field: 'refContribution',
      order: 3,
      width: '8rem',
      availableFor: [GeoTestBudgetLevel.campaign, GeoTestBudgetLevel.adset],
      forTypes: [GeoTestType.scale, GeoTestType.holdout, GeoTestType.lift],
      columnType: ColumnType.percent
    },
    {
      header: 'Campaigns',
      field: 'campaignCount',
      order: 4,
      width: '6rem',
      availableFor: [GeoTestBudgetLevel.campaign, GeoTestBudgetLevel.adset],
      forTypes: [GeoTestType.scale, GeoTestType.holdout, GeoTestType.lift],
      columnType: ColumnType.metadata
    },
    {
      header: '',
      field: 'removeTactic',
      order: 5,
      width: '4rem',
      availableFor: [GeoTestBudgetLevel.campaign, GeoTestBudgetLevel.adset],
      forTypes: [GeoTestType.scale, GeoTestType.holdout, GeoTestType.lift],
      columnType: ColumnType.action
    }
  ];

  private static testCampaignFields: IGeoTestMediaFieldDefinition[] = [
    {
      header: 'Campaign',
      field: 'campaignName',
      order: 1,
      width: '20rem',
      availableFor: [GeoTestBudgetLevel.campaign],
      forTypes: [GeoTestType.scale, GeoTestType.holdout, GeoTestType.lift],
      columnType: ColumnType.custom
    },
    {
      header: 'Ad Set',
      field: 'subCampaignName',
      order: 1,
      width: '20rem',
      availableFor: [GeoTestBudgetLevel.adset],
      forTypes: [GeoTestType.scale, GeoTestType.holdout, GeoTestType.lift],
      columnType: ColumnType.custom
    },
    {
      header: 'Reference Spend',
      field: 'refSpend',
      order: 2,
      width: '8rem',
      availableFor: [GeoTestBudgetLevel.campaign, GeoTestBudgetLevel.adset],
      forTypes: [GeoTestType.scale, GeoTestType.holdout, GeoTestType.lift],
      columnType: ColumnType.currency
    },
    {
      header: 'Reference Contribution',
      field: 'refContribution',
      order: 3,
      width: '8rem',
      availableFor: [GeoTestBudgetLevel.campaign, GeoTestBudgetLevel.adset],
      forTypes: [GeoTestType.scale, GeoTestType.holdout, GeoTestType.lift],
      columnType: ColumnType.percent
    },
    {
      header: 'ID',
      field: 'campaignId',
      order: 4,
      width: '10rem',
      availableFor: [GeoTestBudgetLevel.campaign],
      forTypes: [GeoTestType.scale, GeoTestType.holdout, GeoTestType.lift],
      columnType: ColumnType.metadata
    },
    {
      header: 'ID',
      field: 'subCampaignId',
      order: 4,
      width: '10rem',
      availableFor: [GeoTestBudgetLevel.adset],
      forTypes: [GeoTestType.scale, GeoTestType.holdout, GeoTestType.lift],
      columnType: ColumnType.metadata
    },
    {
      header: 'Daily Scale Budget',
      field: 'scaleBudget',
      order: 5,
      width: '8rem',
      availableFor: [GeoTestBudgetLevel.campaign, GeoTestBudgetLevel.adset],
      forTypes: [GeoTestType.scale],
      columnType: ColumnType.currency
    },
    {
      header: 'Included',
      field: 'includedInTest',
      order: 6,
      width: '6rem',
      styleClass: 'text-center',
      availableFor: [GeoTestBudgetLevel.campaign, GeoTestBudgetLevel.adset],
      forTypes: [GeoTestType.scale, GeoTestType.holdout, GeoTestType.lift],
      columnType: ColumnType.switch
    }
  ];

  public static getTestNameIcons(): IColumnIcon {
    return {
      iconName: {
        [GeoCellDisplayStatus.resultsPending]: 'cached',
        [GeoCellDisplayStatus.actionNeeded]: 'warning'
      },
      iconStyleClass: {
        [GeoCellDisplayStatus.resultsPending]: 'text-muted',
        [GeoCellDisplayStatus.actionNeeded]: 'text-danger'
      },
      infoText: {
        [GeoCellDisplayStatus.resultsPending]: `
          <div class="text-start">
            <div class="b2 text-gray-500 mb-1"> Results pending </div>
            <div class="b1">It may take up to 7 days for results to be processed. We’ll keep you posted.</div>
          </div>`,
        [GeoCellDisplayStatus.actionNeeded]: `
           <div class="text-start">
              <div class="b2 text-red-400 mb-1"> Approval is needed for launch </div>
              <div class="b1">The parameters of your test have changed due to the tactic's recent performance.</div>
           </div>`
      }
    };
  }

  public static getGeoTestColumnsForStatus(status: GeoTestStatus) {
    return sortBy(
      this.geoTestsFieldDefinition.filter((column) =>
        column.availableFor.includes(status)
      ),
      ['order']
    );
  }

  public static getRecommendedTestColumns(
    fieldIds: string[]
  ): IGeoTestColumnDefinition[] {
    const filteredColumns = sortBy(
      this.recommendedTestsFieldDefinition.filter(
        (columnDef) => isEmpty(fieldIds) || fieldIds.includes(columnDef.field)
      ),
      ['order']
    );

    if (!isEmpty(fieldIds)) {
      return filteredColumns.sort(
        (columnDef1, columnDef2) =>
          fieldIds.indexOf(columnDef1.field) -
          fieldIds.indexOf(columnDef2.field)
      );
    }
    return filteredColumns;
  }

  public static getAffectedCampaignColumns(
    level: GeoTestBudgetLevel,
    testType: GeoTestType
  ): IGeoTestMediaFieldDefinition[] {
    return GeoTestsFieldDefinition.testCampaignFields.filter(
      (field) =>
        field.availableFor.includes(level) && field.forTypes.includes(testType)
    );
  }

  public static getAffectedTacticColumns(
    level: GeoTestBudgetLevel,
    testType: GeoTestType,
    removeTacticAllowed: boolean
  ): IGeoTestMediaFieldDefinition[] {
    const tacticColumns = GeoTestsFieldDefinition.testTacticFields.filter(
      (field) =>
        field.availableFor.includes(level) && field.forTypes.includes(testType)
    );
    if (removeTacticAllowed) {
      return tacticColumns;
    }
    return tacticColumns.filter(
      (column) => column.columnType !== ColumnType.action
    );
  }
}
