import { Component, inject, input } from '@angular/core';
import { CardVariant } from '@design-system/components/m-card';
import { SummaryMetricService } from '@portal/app/dashboard/context-modal/services/summary-metric.service';

@Component({
  selector: 'portal-context-summary',
  templateUrl: './summary.component.html',
  providers: [SummaryMetricService]
})
export class SummaryComponent {
  public cardVariant = CardVariant;
  private summaryMetricService = inject(SummaryMetricService);

  comparisonPeriod = input.required<string>();

  isLoading = this.summaryMetricService.isLoading;
  isDataAvailable = this.summaryMetricService.isDataAvailable;
  topMetric = this.summaryMetricService.topMetric;
  drivers = this.summaryMetricService.drivers;
}
