import { computed, inject, Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  TacticReturnCurveResponseDTO,
  TacticReturnCurveRequestDTO,
  TacticReturnCurveDataPoint
} from '@portal/app/dashboard/context-modal/context-model.types';
import { catchError, map, Observable, of, switchMap, tap } from 'rxjs';
import { ModalService } from '@portal/app/dashboard/context-modal/services/modal.service';
import { isEmpty } from 'lodash-es';
import {
  AxisLabelsFormatterCallbackFunction,
  Options,
  Point,
  SeriesOptionsType,
  TooltipFormatterCallbackFunction,
  XAxisOptions,
  YAxisOptions
} from 'highcharts';

import { FieldService } from '@portal/app/shared/services/field.service';
import { FormatterService } from '@portal/app/shared/services/formatter.service';

interface ReturnCurveChartResponse {
  response: TacticReturnCurveResponseDTO;
  chartOptions: Options;
}
interface ChartDataPoint {
  x: number;
  y: number;
  xFormatted: string;
  yFormatted: string;
}
interface CustomPoint extends Highcharts.Point {
  point: {
    y: number | null;
    yFormatted: string | null;
  };
}

@Injectable()
export class ReturnCurveChartService {
  private readonly http: HttpClient = inject(HttpClient);
  private readonly modalService: ModalService = inject(ModalService);
  private readonly fieldService: FieldService = inject(FieldService);
  private readonly formatterService: FormatterService =
    inject(FormatterService);

  private readonly LIGHT_GREY_HEX = '#8d98a8';

  private initialValue: ReturnCurveChartResponse = {
    response: {} as TacticReturnCurveResponseDTO,
    chartOptions: {} as Options
  };

  private isLoadingSignal = signal<boolean>(false);

  public data = toSignal(this.fetchData$(), {
    initialValue: this.initialValue
  });

  chartOptions = computed(() => {
    return this.data().chartOptions;
  });

  get isLoading() {
    return this.isLoadingSignal.asReadonly();
  }

  isChartReady = computed(() => {
    return !this.isLoading() && !isEmpty(this.data().chartOptions);
  });

  isChartValid = computed(() => {
    // You may get a flat curve if the a,b parameters are null for the tactic.
    const isCurveValid =
      typeof this.data().response.curveParams.a === 'number' &&
      !isNaN(this.data().response.curveParams.a);

    return !this.isLoading() && isCurveValid;
  });

  getParams() {
    const modalParams = this.modalService.modalParams();
    return {
      ...this.modalService.getParams(),
      channel: modalParams.channel,
      tactic: modalParams.tactic,
      conversionType: this.modalService.conversionType()
    } as TacticReturnCurveRequestDTO;
  }

  fetchData$(): Observable<ReturnCurveChartResponse> {
    const url = `${ModalService.baseUriV1}/return-curve`;

    return this.modalService.modalParams$.pipe(
      tap(() => this.isLoadingSignal.set(true)),
      switchMap(() =>
        this.http
          .post<TacticReturnCurveResponseDTO>(url, this.getParams())
          .pipe(
            catchError((error) => {
              console.error('Error fetching top metric data', error);
              this.isLoadingSignal.set(false);
              return of({
                data: [] as TacticReturnCurveDataPoint[]
              } as TacticReturnCurveResponseDTO);
            }),
            map((response) => ({
              response,
              chartOptions: this.getChartOptions(response)
            }))
          )
      ),
      tap(() => this.isLoadingSignal.set(false))
    );
  }

  /*
  * mapResponseData - separates the bundled API response payload into the respective
  *     series (return curve, incremental curve)
  * Also filters out top 5% of media spend for scatter plot only
  *
    "response.data": [{"year": 2021, "week": 52, 
                        "mediaSpend": {value:1980,formattedValue: "1,980"}, 
                        "ordersLT": {value: 46,formattedValue: "46"} , 
                        "returnCurveRes": 59, "platformCurveRes": 106 }]"
        =>
    "data": {
         returnCurveData: [{x:mediaSpend0.value, y:returnCurve0.value, 
                            xFormatted: mediaSpend0.formattedValue, yFormatted: returnCurve0.formattedValue}...],
         platformCurveData: [{x:mediaSpend0.value, y:platformCurve0.value, 
                            xFormatted: mediaSpend0.formattedValue, yFormatted: platformCurve0.formattedValue}...],
         scatterPlotData: [[mediaSpend0, ordersLT0, year, week]...],
      }
  */
  private mapResponseData(data: TacticReturnCurveDataPoint[]) {
    // sorted by Media Spend ASCENDING order
    const spendAscBundledData = [...data].sort((a, b) => {
      const aValue =
        typeof a.mediaSpend.value === 'number' ? a.mediaSpend.value : 0;
      const bValue =
        typeof b.mediaSpend.value === 'number' ? b.mediaSpend.value : 0;
      return aValue - bValue;
    });

    // The following steps filters outlier PlatformOrders (ordersLT) y-plot data that may cause the
    // return curve to appear flat within the graph.
    // 1. sorted by PlatformOrders DESCENDING order
    const sortedDataOrdersDesc = [...data].sort((a, b) => {
      const aOrders =
        typeof a.ordersLT.value === 'number' ? a.ordersLT.value : 0;
      const bOrders =
        typeof b.ordersLT.value === 'number' ? b.ordersLT.value : 0;
      return bOrders - aOrders;
    });

    // 2. Calculate the top x%
    const OUTLIER_PERC = 0.1;
    const topXPercentCount = Math.floor(
      sortedDataOrdersDesc.length * OUTLIER_PERC
    );

    // 3. Filter out the top x% of Platform Orders
    const filteredData = sortedDataOrdersDesc.slice(topXPercentCount);

    // 4. [ScatterPlot] Re-sort by Year-Week ASCENDING
    // The last 4 elements will have a different rendering
    const weeklyAscFilteredData = [...filteredData].sort((a, b) => {
      if (a.year === b.year) {
        return a.week - b.week; // If years are the same, sort by week
      }
      return a.year - b.year; // Otherwise, sort by year
    });

    const response = {
      returnCurveData: spendAscBundledData.map((item) => ({
        x:
          typeof item.mediaSpend.value === 'number' ? item.mediaSpend.value : 0,
        y:
          typeof item.returnCurveRes.value === 'number'
            ? item.returnCurveRes.value
            : 0,
        xFormatted: item.mediaSpend.formattedValue ?? '',
        yFormatted: item.returnCurveRes.formattedValue ?? ''
      })),
      platformCurveData: spendAscBundledData.map((item) => ({
        x:
          typeof item.mediaSpend.value === 'number' ? item.mediaSpend.value : 0,
        y:
          typeof item.platformCurveRes.value === 'number'
            ? item.platformCurveRes.value
            : 0,
        xFormatted: item.mediaSpend.formattedValue ?? '',
        yFormatted: item.platformCurveRes.formattedValue ?? ''
      })),
      scatterPlotData: weeklyAscFilteredData.map((item) => [
        typeof item.mediaSpend.value === 'number' ? item.mediaSpend.value : 0,
        typeof item.ordersLT.value === 'number' ? item.ordersLT.value : 0,
        item.year,
        item.week
      ]),
      bundledData: spendAscBundledData
    };

    // Add (0,0) data point so curves starts from origin
    response.returnCurveData = [
      { x: 0, y: 0, xFormatted: '$0', yFormatted: '0' },
      ...response.returnCurveData
    ];
    response.platformCurveData = [
      { x: 0, y: 0, xFormatted: '$', yFormatted: '0' },
      ...response.platformCurveData
    ];

    return response;
  }

  private getChartOptions(response: TacticReturnCurveResponseDTO) {
    if (!response?.data?.length) {
      return {};
    }
    const data = this.mapResponseData(response.data);
    const options = this.formatChartData(
      data.bundledData,
      data.returnCurveData,
      data.platformCurveData,
      data.scatterPlotData
    );
    return options;
  }

  formatChartData(
    bundledData: TacticReturnCurveDataPoint[],
    returnCurveData: ChartDataPoint[],
    platformCurveData: ChartDataPoint[],
    scatterPlotData: number[][]
  ): Options {
    const mediaSpends = bundledData.map((item) =>
      typeof item.mediaSpend.value === 'number' ? item.mediaSpend.value : 0
    );
    const maxSpend = Math.max(...mediaSpends);
    const yValues = bundledData.map((item) =>
      Math.max(
        0,
        typeof item.ordersLT?.value === 'number' ? item.ordersLT.value : 0,
        typeof item.platformCurveRes?.value === 'number'
          ? item.platformCurveRes.value
          : 0,
        typeof item.returnCurveRes?.value === 'number'
          ? item.returnCurveRes.value
          : 0
      )
    );
    const maxYValues = Math.max(...yValues);

    const chartOptions = {
      series: this.createSeriesData(
        returnCurveData,
        platformCurveData,
        scatterPlotData
      ) as SeriesOptionsType[],
      xAxis: this.createXAxisFormatters(maxSpend),
      yAxis: this.createYAxisFormatters(
        returnCurveData,
        platformCurveData,
        scatterPlotData,
        maxYValues
      ),
      tooltip: {
        shared: true,
        useHTML: true,
        formatter: this.createTooltipFormatter(),
        positioner: function (
          labelWidth: number,
          labelHeight: number,
          point: Point
        ) {
          const x = point.plotX !== undefined ? point.plotX : 0;
          const offset = 2;
          const tooltip = this as unknown as Highcharts.Tooltip;

          // Calculate tooltip position
          let tooltipX = x - labelWidth / 2; // Center horizontally
          const tooltipY = 0 + offset; // top of chart

          // Adjust for right edge clipping
          if (tooltipX + labelWidth > tooltip.chart.chartWidth) {
            tooltipX = tooltip.chart.plotWidth - labelWidth - offset; // Move to the left
          }

          // Adjust for left edge clipping
          if (tooltipX < 0) {
            tooltipX = 0; // Move to the right
          }

          return {
            x: tooltipX,
            y: tooltipY
          };
        }
      }
    };

    return chartOptions as Options;
  }

  private createSeriesData(
    returnCurveData: ChartDataPoint[],
    platformCurveData: ChartDataPoint[],
    scatterPlotData: number[][]
  ): SeriesOptionsType[] {
    const returnCurveSeries = {
      data: returnCurveData,
      name: 'Incremental Orders',
      type: 'spline',
      color: '#7fd4b2', // green
      showInLegend: false,
      // color: '#d9977b', // orange
      marker: { enabled: false },
      dataLabels: { enabled: false }
    };

    const platformConversionCurveSeries = {
      data: platformCurveData,
      name: 'Platform Orders',
      type: 'spline',
      // color: '#acb3c1', // grey
      color: '#d9977b', // orange
      showInLegend: false,
      marker: { enabled: false },
      dataLabels: { enabled: false }
    };

    const scatterPlotSeries = {
      name: 'Scatter Series',
      type: 'scatter',
      showInLegend: false,
      data: scatterPlotData.map((item) => ({
        x: item[0],
        y: item[1],
        custom: {
          year: item[2],
          week: item[3]
        }
      })),
      color: '#d6e1f3',
      marker: {
        symbol: 'circle',
        radius: 3
      }
    };

    const latestFourScatterPlotSeries = {
      name: 'Spend in Last 4 Weeks',
      type: 'scatter',
      data: (scatterPlotData || []).slice(-4).map((item) => ({
        x: item[0],
        y: item[1],
        custom: {
          year: item[2],
          week: item[3]
        }
      })),
      color: '#83a6d9',
      marker: {
        symbol: 'circle',
        radius: 4
      }
    };

    // To create a dot in legend, but not on graph. we create dummy series with scatter type
    const returnCurveLegendDot = {
      name: 'Incremental Orders', //'Return Curve',
      type: 'scatter',
      color: '#7fd4b2', // green
      data: [],
      marker: {
        symbol: 'circle',
        radius: 4
      },
      showInLegend: true
    };

    // To create a dot in legend, but not on graph. we create dummy series with scatter type
    const platformCurveLegendDot = {
      name: 'Platform Orders', //'Platform Conversions',
      type: 'scatter',
      color: '#d9977b', // orange
      data: [],
      marker: {
        symbol: 'circle',
        radius: 4
      },
      showInLegend: true
    };

    return [
      scatterPlotSeries,
      latestFourScatterPlotSeries,
      returnCurveSeries,
      platformConversionCurveSeries,
      returnCurveLegendDot,
      platformCurveLegendDot
    ] as SeriesOptionsType[];
  }

  private createXAxisFormatters(maxSpend: number): XAxisOptions {
    const xAxisFormatters = function (): string {
      const value = this?.value as number;
      if (maxSpend < 1000) {
        return value ? '$' + value : '$0';
      }
      return value ? '$' + value / 1000 + 'K' : '$0K'; // Handle potential zero
    } as AxisLabelsFormatterCallbackFunction;

    const xAxisOptions = {
      title: {
        text: 'Media Spend', // X-axis label
        style: {
          color: '#8d98a8' // grey
        }
      },
      lineColor: this.LIGHT_GREY_HEX, // x-axis line
      tickColor: this.LIGHT_GREY_HEX, // x-axis ticks
      min: 0,
      max: maxSpend,
      labels: {
        formatter: xAxisFormatters,
        style: {
          color: this.LIGHT_GREY_HEX
        }
      }
    };

    return xAxisOptions as XAxisOptions;
  }

  private createYAxisFormatters(
    returnCurveData: ChartDataPoint[],
    platformCurveData: ChartDataPoint[],
    scatterPlotData: number[][],
    maxYValue: number
  ): YAxisOptions {
    const yAxisFormatters = function (): string {
      const value = this?.value as number;
      if (maxYValue < 1000) {
        return value ? '' + value : '0';
      }
      return value ? '' + value / 1000 + 'K' : '0K';
    } as AxisLabelsFormatterCallbackFunction;

    let values = returnCurveData
      .map((item) => item.y)
      .filter((item) => Number.isFinite(item)) as number[];
    values = values.concat(
      platformCurveData
        .map((item) => item.y)
        .filter((item) => Number.isFinite(item)) as number[]
    );
    values = values.concat(
      scatterPlotData
        .map((item) => item[1])
        .filter((item) => Number.isFinite(item)) as number[]
    );

    const CHART_HEIGHT_SCALER = 1.0;
    const max = Math.max(...values) * CHART_HEIGHT_SCALER;

    const yAxisOptions = {
      title: {
        text: 'Orders', // Y-axis label
        style: {
          color: '#8d98a8' // grey
        }
      },
      lineColor: '#8d98a8', // y-axis line
      tickColor: '#8d98a8', // y-axis ticks
      min: 0,
      max,
      labels: {
        formatter: yAxisFormatters,
        style: {
          color: this.LIGHT_GREY_HEX
        }
      }
    };

    return yAxisOptions as YAxisOptions;
  }

  /**
   * Create the tooltip formatter function.
   *
   * @param viewBy The view type.
   * @returns The tooltip formatter callback function.
   */
  private createTooltipFormatter(): TooltipFormatterCallbackFunction {
    return function (): string | false {
      if (!this.points || !this.points.length) {
        return false;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const {
        point: { xFormatted }
      } = this as unknown as { point: { xFormatted: string } };

      const spendValue = xFormatted;
      let tooltipHtml = `<div class="tooltip-body">`;

      // Return Curve(s) Tooltip - Groups the (2) curves into (1) Tooltip
      // possible with the `points` key

      tooltipHtml += `<span class="b3 text-gray-000 text-center block">Media Spend: ${spendValue}</span>`;
      tooltipHtml += `<div class="m-divider dark my-2"></div>`;

      // Sort the points by y-value DESC order so tooltip order matches corresponding curve
      this?.points?.sort((a, b) => {
        if (a?.y !== null && a?.y !== undefined) {
          return (b?.y as number) - a.y;
        }
        return 0; // If y is null or undefined, don't change the order
      });

      this?.points?.forEach((point) => {
        const customPoint = point as unknown as CustomPoint;

        if (
          customPoint?.point?.y !== null &&
          customPoint?.point?.y !== undefined &&
          customPoint?.point?.yFormatted !== null &&
          customPoint?.point?.yFormatted !== undefined
        ) {
          const seriesName = customPoint?.series?.name;
          const formattedValue = customPoint?.point?.yFormatted;
          tooltipHtml += `<div class="flex justify-between items-center">`;
          tooltipHtml += `<div><span style="color:${customPoint?.color}; font-size: 12px;">\u25CF</span>`;
          tooltipHtml += `<span class="b1 text-gray-000 ml-1">${seriesName}</span>:</div>`;
          tooltipHtml += `<span class="b1 text-gray-000">${formattedValue}</span>`;
          tooltipHtml += `</div>`;
        }
      });

      tooltipHtml += `</div>`;
      return tooltipHtml;
    };
  }
}
