<m-fade-in-up>
  <div class="dp2 my-6">Personalized Insights</div>

  <div class="grid grid-cols-3 gap-4">
    <portal-geo-test-cards></portal-geo-test-cards>
    <portal-benchmark-cards></portal-benchmark-cards>
    <portal-channel-optimization-cards
      [viewBy]="viewBy()"></portal-channel-optimization-cards>
  </div>
</m-fade-in-up>
