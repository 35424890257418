<div class="flex flex-col bg-gray-100">
  <div
    class="w-full px-4 border border-lgrey border-bottom border-start-0 border-end-0 border-top-0 bg-white">
    <lib-ui-header
      [headerConfig]="headerConfig"
      [infoConfig]="lastUpdatedAtConfig"
      [headerData]="{}"></lib-ui-header>
  </div>
  <div
    class="flex flex-col w-full min-h-screen p-6 gap-6 bg-gray-100 mb-[2.5rem]">
    @if (showBanner) {
      <m-banner
        [type]="bannerConfig.type"
        [title]="bannerConfig.title"
        [subText]="bannerConfig.subText"
        (closeBanner)="showBanner = false">
        <img
          creative
          class="w-full"
          src="/assets/images/creative/map-your-way-to-success.svg"
          alt="I" />
        <span
          class="flex items-center cursor-pointer"
          (click)="learnMore()"
          action>
          Read more about campaign mapping and how it works within Measured
          <span
            class="flex items-center material-symbols-outlined ms-1 icon-small"
            >arrow_forward</span
          >
        </span>
      </m-banner>
    }
    <div class="bg-gray-000 border border-gray-300 p-6 rounded-lg">
      <lib-taxonomy-hero-metrics
        [metricConfig]="heroMetricConfig"></lib-taxonomy-hero-metrics>
    </div>
    <div class="bg-gray-000 p-8 rounded-lg border border-gray-300">
      <lib-taxonomy-campaigns-view
        [clientId]="clientId"
        [brandId]="brandId"></lib-taxonomy-campaigns-view>
    </div>
  </div>
</div>
