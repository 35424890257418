<div
  [class.hidden]="!showDatePickerWrapper"
  class="saas-ui date-picker-wrapper"
  [ngClass]="containerClass">
  <label (click)="toggleCalendarComponent($event)" class="date-range-label">
    {{ label }}
    <!--    if compared is off and selection is not custom check if auto (start/end) option is selected,
            if so show auto and value of start/end date,
            if not show regular start and end date-->
    <input
      #calendarInput
      type="text"
      [class]="datePickerInputStyleClass"
      [style.width]="
        datePickerInputWidth && datePickerInputWidth === ''
          ? compareToggledOn
            ? '16.875rem'
            : '11.375rem'
          : datePickerInputWidth
      "
      [value]="getDatePickerLabel()" />
  </label>
  <label *ngIf="compareEnabled" class="ms-3 compare" for="compareSwitchOutSide">
    Compare
    <p-inputSwitch
      id="compareSwitchOutSide"
      class="compare-switch mt-1"
      [(ngModel)]="compareToggledOn"
      (onChange)="
        toggleCompare($event.checked); openCalendar()
      "></p-inputSwitch>
  </label>
</div>
<p-dialog
  header="Title"
  [(visible)]="showCalendar"
  appendTo="body"
  [styleClass]="'date-picker-popup'"
  [showHeader]="false"
  [modal]="true"
  [focusTrap]="true"
  (onHide)="cancel()"
  [transitionOptions]="'0ms'">
  <div
    #calendarPopup
    class="date-picker-popup-content"
    [ngClass]="{ 'position-static': pageCentered }">
    <div
      class="left-side saas-ui saas-ui-size-default"
      [ngClass]="{ compare: compareToggledOn && compareToggledOn }">
      <div class="date-picker-header">
        <span class="sh2 text-gray-1000">Date Presets</span>
        <div class="flex gap-2 mt-2 items-center">
          <i
            class="material-symbols-outlined icon-small text-gray-500"
            aria-hidden="true"
            >info</i
          >
          <span class="c1 text-gray-800">{{ availabilityInfoText }}</span>
        </div>
      </div>

      <div class="date-section">
        <p-dropdown
          *ngIf="showPresetSelection"
          [options]="currentRangeOptions"
          [(ngModel)]="selectedOption"
          optionLabel="name"
          [autofocus]="true"
          (onChange)="
            changeDateRangeOption(asCurrentDropdownOption($event.value).name)
          "></p-dropdown>
        <div *ngIf="showInputPickers" class="from-side">
          <label class="input-label">
            From
            <input
              pInputText
              (change)="
                applyFromInputField(
                  asInputElement($event.target).value,
                  'startDate'
                )
              "
              [disabled]="disableStartDate"
              type="text"
              class="date-input"
              [(ngModel)]="startDate" />
          </label>
        </div>
        <div *ngIf="showInputPickers" class="to-side">
          <label class="input-label"
            >To
            <input
              pInputText
              type="text"
              (change)="
                applyFromInputField(
                  asInputElement($event.target).value,
                  'endDate'
                )
              "
              [disabled]="disableEndDate"
              class="date-input"
              [(ngModel)]="endDate" />
          </label>
        </div>
        <div class="col-md-12 text-red" *ngIf="isOutOfDateRange">
          Maximum allowed duration is {{ allowedDuration }} days
        </div>
        <div class="bulk-mode" *ngIf="dateFilter && dateFilter.bulkModeValue">
          <label class="input-label">
            Select by
            <p-dropdown
              [options]="bulkModeOptions"
              optionLabel="label"
              optionValue="value"
              [(ngModel)]="selectedBulkMode"
              (onChange)="updateDateBasedOnBulkMode()"></p-dropdown>
          </label>
        </div>
      </div>

      <div
        class="date-section"
        [ngClass]="{ compare: compareToggledOn && compareEnabled }">
        <div class="input-switch" *ngIf="compareEnabled">
          <label for="compareSwitch">
            <p-inputSwitch
              id="compareSwitch"
              [style]="{ height: '2rem' }"
              [(ngModel)]="compareToggledOn"
              (onChange)="toggleCompare($event.checked, true)"></p-inputSwitch>
            <span class="toggle-text">Compare with</span>
          </label>
        </div>
        <ng-container *ngIf="compareToggledOn && compareEnabled">
          <p-dropdown
            [options]="compareRangeOptions"
            [(ngModel)]="selectedCompareOption"
            optionLabel="name"
            (onChange)="
              changeCompareDateRangeOption(
                asCompareDropdownOption($event.value).name
              )
            "></p-dropdown>
          <div class="from-side">
            <label class="input-label"
              >From
              <input
                pInputText
                type="text"
                (change)="
                  applyFromInputField(
                    asInputElement($event.target).value,
                    'startDateCompare'
                  )
                "
                class="date-input"
                [(ngModel)]="startCompareDate" />
            </label>
          </div>
          <div class="to-side">
            <label class="input-label"
              >To
              <input
                pInputText
                type="text"
                (change)="
                  applyFromInputField(
                    asInputElement($event.target).value,
                    'endDateCompare'
                  )
                "
                class="date-input"
                [(ngModel)]="endCompareDate" />
            </label>
          </div>
        </ng-container>
      </div>
      @if (showApplyForAll) {
        <div class="ms-3 d-flex gap-0">
          <m-checkbox
            [isChecked]="isApplyForAllChecked"
            [binary]="true"
            (onChange)="
              isApplyForAllChecked = !isApplyForAllChecked
            "></m-checkbox>
          <span>Apply to All Rows</span>
        </div>
      }
      <div class="date-picker-footer d-flex gap-2 mt-auto">
        <button
          [disabled]="isApplyButtonDisabled"
          pButton
          class="p-button p-button-primary"
          (click)="apply()"
          label="Save"></button>
        <button
          pButton
          class="p-button p-button-outlined"
          (click)="cancel()"
          label="Cancel"></button>
      </div>
    </div>
    <!--regular date range pick-->
    <div class="calendar-side single" *ngIf="!compareToggledOn && showCalendar">
      <drp-daterangepicker
        class="w-100"
        [calendarDisabled]="disableStartDate && disableEndDate"
        *ngIf="showCalendarComponent"
        [autoApply]="true"
        [linkedCalendars]="true"
        [bulkSelection]="
          dateFilter && dateFilter.bulkModeValue
            ? !!dateFilter.bulkModeValue
            : false
        "
        [selectionMode]="selectedBulkMode"
        [selectionSize]="1"
        [focusOnEndDate]="disableStartDate"
        (choosedDate)="selectCurrentDateFromCalendar(asDateRange($event))"
        [startDate]="selected.startDate"
        [lockStartDate]="disableStartDate"
        [lockEndDate]="disableEndDate"
        [endDate]="selected.endDate"
        [minDate]="minDate"
        [maxDate]="disableEndDate ? psuedoMaxEndDate : maxDate"
        [locale]="locale">
      </drp-daterangepicker>
    </div>
    <!--regular date range pick-->
    <!--compare pick calendars-->
    <div
      class="calendar-side multiple"
      *ngIf="compareToggledOn && showCalendar">
      <div class="d-flex flex-column gap-3 align-items-center">
        <drp-daterangepicker
          class="left-calendar"
          *ngIf="showCalendarComponent"
          [autoApply]="true"
          [linkedCalendars]="false"
          [startDate]="selected.startDate"
          (datesUpdated)="selectCurrentDateFromCalendar(asDateRange($event))"
          [endDate]="selected.endDate"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [locale]="locale">
        </drp-daterangepicker>
        <p class="calendar-label">Current Time Period</p>
      </div>
      <div class="separator"></div>

      <div class="d-flex flex-column gap-3 align-items-center">
        <drp-daterangepicker
          class="secondary"
          *ngIf="showCalendarComponent"
          [autoApply]="true"
          (datesUpdated)="selectCompareDateFromCalendar(asDateRange($event))"
          [startDate]="selectedCompare.startDate"
          [endDate]="selectedCompare.endDate"
          [minDate]="minDate"
          [maxDate]="selected.startDate.subtract(1, 'day')"
          [locale]="locale">
        </drp-daterangepicker>
        <p class="calendar-label">Comparison Time Period</p>
      </div>
    </div>
    <!--compare pick calendars-->
  </div>
</p-dialog>
