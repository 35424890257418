import { computed, inject, Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { toSignal } from '@angular/core/rxjs-interop';
import { catchError, Observable, of, switchMap, tap } from 'rxjs';
import {
  GeoResponseDTO,
  GeoTestRequestDTO,
  LiveGeoTestDTO,
  TestOpportunitiesDTO,
  LatestTestResultDTO,
  ContextModalCard
} from '@portal/app/dashboard/context-modal/context-model.types';
import { ModalService } from '@portal/app/dashboard/context-modal/services/modal.service';
import { GeoDesignerConstants } from '@portal/app/geo-designer/common/geo-designer.constants';
import { GeoTestType } from '@libs/apis';
import { environment } from '@portal/environments/environment';
import { ViewByBrandService } from '@portal/app/dashboard/context-modal/services/view-by-brand.service';

@Injectable()
export class GeoTestsService {
  private readonly http: HttpClient = inject(HttpClient);
  private readonly modalService: ModalService = inject(ModalService);
  private readonly viewByBrandService: ViewByBrandService =
    inject(ViewByBrandService);

  public static baseUriV1 = `${environment.apiDomain}/api/v1/bff/geo/context-menu`;

  private initialValue: GeoResponseDTO = {
    liveGeoTest: {} as LiveGeoTestDTO,
    testOpportunities: [] as TestOpportunitiesDTO[],
    latestTestResult: {} as LatestTestResultDTO
  } as GeoResponseDTO;

  private isLoadingSignal = signal<boolean>(false);

  public data = toSignal(this.fetchData$(), {
    initialValue: this.initialValue
  });

  type = computed(() => this.data()?.type);
  liveGeoTest = computed(() => this.data()?.liveGeoTest);
  testOpportunities = computed(() => this.data()?.testOpportunities);
  latestTestResult = computed(() => this.data()?.latestTestResult);

  isDataAvailable = computed(() => {
    return this.data().type !== 'NoData';
  });

  getLiveGeoTestDates = computed(() => {
    return `${this.liveGeoTest().startDate} - ${this.liveGeoTest().endDate}`;
  });

  getDaysLeftLabel = computed(() => {
    const days = this.liveGeoTest().daysLeft;
    return `${days} ${days < 2 ? 'day' : 'days'} left`;
  });

  getDaysLeftPercent = computed(() => {
    return Math.floor(((100 - this.liveGeoTest().daysLeft) / 100) * 100);
  });

  get isLoading() {
    return this.isLoadingSignal.asReadonly();
  }

  liveGeoTestCard = {
    title: 'Active Test',
    buttonText: 'View details',
    metrics: [],
    icons: []
  } as ContextModalCard;

  testOpportunitiesCard = {
    title: 'Testing Opportunities',
    description:
      'These tactics are available for testing, based on recent spending and their contribution to your revenue:',
    buttonText: 'Explore more'
  } as ContextModalCard;

  latestTestResultCard = {
    title: 'Latest Test Results',
    buttonText: 'View details',
    metrics: [],
    icons: []
  } as ContextModalCard;

  emptyStateCard = computed(() => {
    return {
      title: 'Geo Test',
      content: `<div class="b2">Did you know?</div>Brands who ran 8 incrementality tests <span class="b2">improved their ${this.viewByBrandService.data().viewBy} by 32%</span> that year on average.`,
      buttonText: 'Explore Geo Tests'
    };
  });

  getParams() {
    const modalParams = this.modalService.modalParams();
    return {
      ...this.modalService.getParams(),
      dimension: modalParams.tactic ? 'tactic' : 'channel',
      channel: modalParams.channel,
      tactic: modalParams.tactic ? modalParams.tactic : undefined,
      conversion_type: this.modalService.conversionType()
    } as GeoTestRequestDTO;
  }

  fetchData$(): Observable<GeoResponseDTO> {
    const url = `${GeoTestsService.baseUriV1}/geo-tests`;

    return this.modalService.modalParams$.pipe(
      tap(() => this.isLoadingSignal.set(true)),
      switchMap(() =>
        this.http.post<GeoResponseDTO>(url, this.getParams()).pipe(
          catchError((error) => {
            console.error('Error fetching geo tests data', error);
            this.isLoadingSignal.set(false);
            return of({ ...this.initialValue });
          })
        )
      ),
      tap((response) => {
        if (response?.type === 'LIVE') {
          this.updateLiveGeoTestCard(response);
        } else if (response?.type === 'LatestResult') {
          this.updateLatestTestResultCard(response);
        }
        this.isLoadingSignal.set(false);
      })
    );
  }

  updateLiveGeoTestCard(response: GeoResponseDTO) {
    const liveGeoTest = response?.liveGeoTest;
    if (liveGeoTest) {
      this.liveGeoTestCard.title = liveGeoTest.tactic
        ? `${this.liveGeoTestCard.title}: <span class="text-gray-800 font-normal">${liveGeoTest.tactic}</span>`
        : this.liveGeoTestCard.title;

      this.liveGeoTestCard.metrics = [
        liveGeoTest.spend,
        liveGeoTest.contribution
      ];
      this.liveGeoTestCard.icons = [
        {
          label: liveGeoTest.cellType,
          value:
            GeoDesignerConstants.testTypeIcons[
              liveGeoTest.cellType.toUpperCase() as GeoTestType
            ]
        },
        {
          label: liveGeoTest.testType,
          value:
            liveGeoTest.testType === 'Automatic' ? 'smart_toy' : 'front_hand'
        }
      ];
    }
  }

  updateLatestTestResultCard(response: GeoResponseDTO) {
    const latestTestResult = response?.latestTestResult;
    if (latestTestResult) {
      this.latestTestResultCard.metrics = [
        latestTestResult.inc,
        latestTestResult.metric
      ];
      this.latestTestResultCard.icons = [
        {
          label: latestTestResult.cellType,
          value:
            GeoDesignerConstants.testTypeIcons[
              latestTestResult.cellType.toUpperCase() as GeoTestType
            ]
        },
        {
          label: latestTestResult.testDurationInWeeks,
          value: 'calendar_today'
        },
        {
          label: latestTestResult.testEndDate,
          value: 'sports_score'
        }
      ];
    }
  }
}
