<!--Card Header-->
<div class="metric-card-header border-bottom">
  @for (header of headerData; track header) {
    @if (header.subType === "primary") {
      <div
        class="main-title text-truncate"
        [title]="fieldDefinitions[header.dashboardFieldId]?.label">
        {{ fieldDefinitions[header.dashboardFieldId]?.label }}
      </div>
    }
    @if (header.subType === "secondary") {
      <div
        class="sub-title text-truncate"
        [title]="fieldDefinitions[header.dashboardFieldId]?.label">
        {{ fieldDefinitions[header.dashboardFieldId]?.label }}
      </div>
    }
  }
</div>

<!--Card Body-->
<div class="metric-card-body">
  <div class="d-flex gap-2 px-2 py-3">
    <!--Metrics section-->
    @for (item of metricsData; track item) {
      <div class="flex-grow-1 text-center">
        <div class="metric-label">
          {{ fieldDefinitions[item.dashboardFieldId]?.label }}
        </div>
        <div class="metric-value">
          <ng-container
            [ngTemplateOutlet]="metricValueTemplate"
            [ngTemplateOutletContext]="{ $implicit: item }">
          </ng-container>
        </div>
      </div>
    }
  </div>

  <!--Visualization section-->
  @if (isMetricActive) {
    @for (item of footerData; track item) {
      @if (item.layoutType === layoutTypes.visualizer) {
        <div class="visualizer p-4 pt-2 border-top flex-grow-1">
          <portal-metrics-bar-visualizer
            [key]="item.dashboardLayoutLiteralId"
            [label]="item.label"
            [value]="item.percentValue"
            [vizMetric]="item.vizMetric"
            [totalItems]="item.totalItems"
            [otherItems]="item.otherItems"
            [tooltipPosition]="'bottom'">
          </portal-metrics-bar-visualizer>
        </div>
      }
    }
  }
</div>

<ng-template #metricValueTemplate let-item>
  @if (toMetricCardData(item); as item) {
    @if (item.layoutType === layoutTypes.composite) {
      <portal-composite-data-cell
        [key]="item.dashboardLayoutLiteralId"
        [cellLayoutType]="item.subType"
        [col]="item"
        [rowData]="convertedDataPoints"
        [fieldDefinitions]="fieldDefinitions"
        [dashboardId]="dashboardLiteralId">
      </portal-composite-data-cell>
    } @else {
      <div>
        <portal-data-cell
          [key]="item.dashboardLayoutLiteralId"
          [cellLayoutType]="item.subType"
          [dashboardId]="dashboardLiteralId"
          [data]="displayValueCache[item.dashboardFieldId]">
        </portal-data-cell>
      </div>
    }
  }
</ng-template>
