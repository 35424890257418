import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiService, IAngularEnvironment } from '@libs/apis';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { CurrencyService } from './currency.service';
import { CustomCurrencyPipe } from './pipes/custom-currency.pipe';
import { ToastMessageService } from './toast-message.service';

@NgModule({
  imports: [CommonModule],
  exports: [EllipsisPipe, CustomCurrencyPipe],
  declarations: [EllipsisPipe, CustomCurrencyPipe],
  providers: [CurrencyService, ToastMessageService]
})
export class CommonServicesModule {
  public static forRoot(
    environment: IAngularEnvironment
  ): ModuleWithProviders<CommonServicesModule> {
    return {
      ngModule: CommonServicesModule,
      providers: [ApiService, { provide: 'environment', useValue: environment }]
    };
  }
}
