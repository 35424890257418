<div class="sticky-top py-2 py-lg-0 nav-bar">
  <div class="nav-bar-title">
    <img
      src="assets/images/measured-m.svg"
      class="measured-logo act-as-link"
      alt="Measured"
      [routerLink]="landingDashboardLink" />
    <h5
      class="text-start mb-0 location-label brand-label act-as-link truncate"
      [routerLink]="landingDashboardLink">
      {{ brandLabel }}
    </h5>
  </div>

  <div class="menu-options">
    <div class="menu-options-group">
      <m-button type="menu" (click)="onClickHome()"
        ><span class="b2">Home</span></m-button
      >
      @if (analyzeMenuItems?.length) {
        <m-menu [menuItems]="analyzeMenuItems"
          ><span class="b2">Analyze</span></m-menu
        >
      }
      <m-menu [menuItems]="experimentMenuItems"
        ><span class="b2">Experiment</span></m-menu
      >
      @if (optimizeMenuItems?.length) {
        <m-menu [menuItems]="optimizeMenuItems"
          ><span class="b2">Optimize</span></m-menu
        >
      }

      <m-button
        type="menu"
        (click)="onClickBenchmark && onClickBenchmark()"
        [disabled]="!onClickBenchmark"
        ><span class="b2">Benchmarks</span></m-button
      >
    </div>

    <div class="menu-options-group">
      <m-menu [menuItems]="supportMenuItems" [showArrow]="false" type="menu">
        <em
          aria-hidden="true"
          class="material-symbols-outlined icon-small menuicon"
          >help_outline</em
        >
      </m-menu>

      <div [class.action-alerts-active]="actionAlertsCount > 0">
        <m-button
          #notificationCenterButton
          [showArrow]="false"
          type="menu"
          (click)="onClickNotificationCenter($event)"
          ><em class="material-symbols-outlined icon-small menuicon"
            >notifications_active</em
          >
          @if (actionAlertsCount > 0) {
            <span class="b2 action-alert-count">{{
              actionAlertsCount > 99 ? "99+" : actionAlertsCount
            }}</span>
          }
          <span
            [class.notification-dot]="
              unreadInfoAlertsCount > 0 && actionAlertsCount === 0
            "></span>
        </m-button>
      </div>

      <m-menu [menuItems]="settingsMenuItems" [showArrow]="false" type="menu">
        <em
          aria-hidden="true"
          class="material-symbols-outlined icon-small menuicon"
          >build</em
        >
      </m-menu>
      <m-menu [menuItems]="userOptionsMenuItems">
        <em
          aria-hidden="true"
          class="material-symbols-outlined icon-small menuicon accountcircle mr-2"
          >account_circle</em
        ><span class="b2 button-label">{{ username }}</span>
      </m-menu>
    </div>
  </div>

  @if (showExport) {
    <m-menu #tableauExport [menuItems]="tableauExportItems"
      ><em
        aria-hidden="true"
        class="material-symbols-outlined icon-small menuicon accountcircle"
        >save_alt</em
      >Export</m-menu
    >
  }
</div>

<m-modal
  [(visible)]="brandsDialogVisible"
  (onHide)="closeBrandDialogModal()"
  [modal]="true"
  class="brand-dialog">
  <ng-container modalHeader>
    <h4 class="h4">Change Brand</h4>
  </ng-container>
  <portal-brands (rowClicked)="hideBrandsDialog()"></portal-brands>
  <ng-container modalFooter>
    <div class="d-flex justify-content-end mt-4">
      <m-button (click)="hideBrandsDialog()" type="secondary"
        ><span class="b1 text blue-600">Cancel</span></m-button
      >
    </div>
  </ng-container>
</m-modal>

<p-dialog
  header="Logging Out"
  [(visible)]="logOutModalIsVisible"
  [modal]="true">
  <p>Thank you for using Measured Portal!</p>
</p-dialog>

<portal-notification-center
  [visibilityType]="notificationViewMode.popup"
  [notificationCenterVisible]="notificationCenterVisible"
  (closePopup)="notificationCenterVisible = false"></portal-notification-center>

<portal-feedback-modal></portal-feedback-modal>
