import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { exportDataConfigs } from '@portal/app/dashboard/export-native-dashboard-data/export-native-dashboard-configs';
import { DateRange, DateRangeData, User } from '@portal/app/shared/types';
import { StorageService } from '@portal/app/shared/services/storage.service';
import { UserModel } from '@portal/app/shared/models/userModel';
import { NavbarConstants } from '@portal/app/shared/types/navigation';
import {
  CurrentDropdownOption,
  custom,
  DateTimeService,
  DayJsDateFormat,
  latestNintyDays,
  latestSevenDays,
  latestThirtyDays
} from '@portal/app/shared/services/date-time.service';
import { flatten, groupBy, isArray, keys } from 'lodash-es';
import { FilterSetUtil } from '@portal/app/dashboard/filter-set/filter-set-util';
import { ApiService } from '@portal/app/shared/services/api.service';
import {
  IExportConfig,
  IExportDataForm,
  IExportDataReqParams,
  IOptions
} from '@portal/app/dashboard/export-native-dashboard-data/models/IExportConfig';
import { SelectionService } from '@portal/app/shared/services/selection.service';
import dayjs from 'dayjs';
import { ViewType } from '@libs/common-components';
import { DateRangePickerComponent } from '@portal/app/dashboard/date-range-picker/date-range-picker.component';

@Component({
  selector: 'portal-export-data',
  templateUrl: './export-native-dashboard-data.component.html',
  styleUrls: ['./export-native-dashboard-data.component.scss']
})
export class ExportNativeDashboardDataComponent implements OnInit {
  @ViewChild('datePicker') datePickerComponent!: DateRangePickerComponent;
  loading = false;
  loadingMessage = '';
  exportDataConfigs = exportDataConfigs;
  reportNames: IOptions[] = [];
  conversionTypeOptions: IOptions[] = [];
  dimensionOptions: IOptions[] = [];
  userEmail = new UserModel(
    this.store.getAsUser(NavbarConstants.user) as User
  ).getEmail();

  clientId = 0;
  brandId = 0;
  allowedDuration = dayjs(new Date()).year() % 4 === 0 ? 366 : 365;

  timePeriodOptions: CurrentDropdownOption[] = [];

  dataExportForm: FormGroup<IExportDataForm> = this.formInitializer();

  selectedTimePeriod: DateRange = FilterSetUtil.setAsDateRange(
    this.dataExportForm.controls.timePeriod.value
  );

  exportConfigs: Record<string, IExportConfig> = {};

  showMessage = false;

  config = {
    isMessage: true,
    closeIcon: true,
    titleIcon: true,
    title: '',
    message: '',
    view: ViewType.success,
    okayButton: true,
    okayButtonText: 'Got it!'
  };

  timeSeriesResolutionInfoTooltip =
    'Allows you to view the data aggregated at resolutions like day, week, month, quarter, full period';

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly store: StorageService,
    private readonly dateTimeService: DateTimeService,
    private readonly apiService: ApiService,
    private readonly selectionService: SelectionService
  ) {}

  ngOnInit() {
    this.setTimePeriodOptions();
    this.setUpClientAndBrandId();
    this.loading = true;
    this.loadingMessage = 'Fetching the export configs';
    this.apiService
      .getExportDataConfig(this.clientId, this.brandId)
      .subscribe({
        next: (exportConfigs) => {
          if (exportConfigs.length) {
            const groupedByReportId = groupBy(exportConfigs, 'reportId');
            for (const reportId of keys(groupedByReportId)) {
              this.exportConfigs[reportId] = (
                groupedByReportId[reportId] as IExportConfig[]
              )[0] as IExportConfig;
            }
            this.reportNames = exportConfigs.map((config) => {
              return {
                label: config.reportName,
                value: config.reportId
              };
            });
            this.setupExportDataFormValue(
              (this.reportNames[0] as IOptions).value
            );
          }
        },
        error: (err) => {
          console.error('Error while fetching the export config', err);
        }
      })
      .add(() => {
        this.loading = false;
        this.loadingMessage = '';
      });
  }

  formInitializer(): FormGroup<IExportDataForm> {
    return this.formBuilder.group({
      reportName: new FormControl('xcd', {
        nonNullable: true,
        validators: Validators.required
      }),
      dimensions: new FormControl([''], {
        nonNullable: true,
        validators: Validators.required
      }),
      timePeriod: new FormControl(this.getDefaultTimePeriod(), {
        nonNullable: true,
        validators: Validators.required
      }),
      timeSeriesResolution: new FormControl(
        exportDataConfigs.exportDataFormDefaultValue.timeSeriesResolution,
        {
          nonNullable: true,
          validators: Validators.required
        }
      ),
      conversionTypes: new FormControl([''], {
        nonNullable: true,
        validators: Validators.required
      }),
      exportFormat: new FormControl(
        exportDataConfigs.exportDataFormDefaultValue.exportFormat,
        {
          nonNullable: true,
          validators: Validators.required
        }
      )
    });
  }

  setupExportDataFormValue(reportName: string): void {
    const { dimensions = [], conversionTypes = [] } = this.exportConfigs[
      reportName
    ] as IExportConfig;

    this.dimensionOptions = dimensions.map((dim) => {
      dim.disabled = dim.default || false;
      return dim;
    });
    this.conversionTypeOptions = conversionTypes.map((conversionType) => {
      return { label: conversionType, value: conversionType };
    });

    this.dataExportForm.controls.reportName.setValue(reportName);
    this.dataExportForm.controls.dimensions.setValue(
      dimensions.map(({ value }) => value)
    );
    this.dataExportForm.controls.conversionTypes.setValue(conversionTypes);
  }

  onDateChange(dateRangeData: DateRangeData[]): void {
    const date = dateRangeData.find((obj) => obj.name === 'regularDate');
    if (date && date.value && isArray(date.value) && date.value.length === 2) {
      this.dataExportForm.controls.timePeriod.setValue([
        this.dateTimeService.format(date.value[0], DayJsDateFormat.fullDate),
        this.dateTimeService.format(date.value[1], DayJsDateFormat.fullDate)
      ]);
      this.selectedTimePeriod = FilterSetUtil.setAsDateRange(
        this.dataExportForm.controls.timePeriod.value
      );
    }
  }

  onChangeOfReport(event: { value: string }): void {
    this.setupExportDataFormValue(event.value);
  }

  getDefaultTimePeriod(): string[] {
    const last30Days =
      this.timePeriodOptions.find((obj) => obj.name === latestThirtyDays)
        ?.action || this.dateTimeService.calculatePrevious30Days();

    const timePeriod = [
      this.dateTimeService.format(
        last30Days.startDate,
        DayJsDateFormat.fullDate
      ),
      this.dateTimeService.format(last30Days.endDate, DayJsDateFormat.fullDate)
    ];

    this.selectedTimePeriod = FilterSetUtil.setAsDateRange(timePeriod);
    return timePeriod;
  }

  exportData(): void {
    if (this.dataExportForm.invalid) {
      console.error('Export configs are invalid');
      return;
    }
    const {
      conversionTypes,
      dimensions,
      exportFormat,
      timeSeriesResolution,
      timePeriod,
      reportName
    } = this.dataExportForm.value;
    const requestParams = {
      conversionTypes,
      dimensions: flatten((dimensions || []).map((dim) => dim.split(','))),
      exportFormat,
      reportName,
      timePeriod,
      timeSeriesResolution
    };
    this.loading = true;
    this.loadingMessage = 'Saving the export request';

    this.apiService
      .saveExportDataConfig(
        requestParams as IExportDataReqParams,
        this.clientId,
        this.brandId
      )
      .subscribe({
        next: (res) => {
          this.config.view = res.success ? ViewType.success : ViewType.error;
          this.config.title = res.success ? 'Success!' : 'Error!';
          this.config.message = res.message;
          this.config.okayButtonText = res.success ? 'Got it!' : 'Try again';
          this.showMessage = true;
        },
        error: (err) => {
          console.error('Error while saving the export configs: ', err);
          this.config.view = ViewType.error;
          this.config.title = 'Error!';
          this.config.message =
            'Failed to generate report. Please try again after some time.';
          this.showMessage = true;
          this.config.okayButtonText = 'Try again';
        }
      })
      .add(() => {
        this.loading = false;
        this.loadingMessage = '';
      });
  }

  setUpClientAndBrandId(): void {
    const { clientId, brandId } = this.selectionService.getClientIdAndBrandId();
    this.clientId = clientId;
    this.brandId = brandId;
  }

  resetExportDataForm(): void {
    this.selectedTimePeriod = FilterSetUtil.setAsDateRange(
      this.dataExportForm.controls.timePeriod.value
    );
    const { reportName, exportFormat, timeSeriesResolution } =
      exportDataConfigs.exportDataFormDefaultValue;
    this.dataExportForm.setValue({
      reportName,
      exportFormat,
      timeSeriesResolution,
      timePeriod: this.getDefaultTimePeriod(),
      conversionTypes: [],
      dimensions: []
    });
    this.setupExportDataFormValue(reportName);
    if (this.datePickerComponent) {
      this.datePickerComponent.changeDateRangeOption(latestThirtyDays);
      this.datePickerComponent.apply();
    }
  }

  setTimePeriodOptions(): void {
    this.timePeriodOptions = [];
    this.timePeriodOptions = [
      {
        name: latestThirtyDays,
        action: this.dateTimeService.calculatePrevious30Days()
      },
      {
        name: latestSevenDays,
        action: this.dateTimeService.calculatePrevious7Days()
      },
      {
        name: latestNintyDays,
        action: this.dateTimeService.calculatePrevious90Days()
      },
      { name: custom, action: null }
    ];
  }
}
