import {
  Component,
  computed,
  effect,
  inject,
  OnInit,
  signal
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ITabPanelHeader } from '@design-system/components/m-tab-view';
import { ModalBaseComponent } from '@portal/app/dashboard/context-modal/components/base-modal.component';
import { MediaService } from '@portal/app/dashboard/context-modal/services/media.service';
import { ModalService } from '@portal/app/dashboard/context-modal/services/modal.service';
import { ViewByBrandService } from '@portal/app/dashboard/context-modal/services/view-by-brand.service';
import { capitalize } from 'lodash-es';
import { DimensionOption } from '@portal/app/dashboard/context-modal/services/link.service';
import { LinkService } from '@portal/app/dashboard/context-modal/services/link.service';

@Component({
  selector: 'portal-context-channel-modal',
  templateUrl: './channel-modal.component.html',
  providers: [ModalService, MediaService]
})
export class ChannelModalComponent
  extends ModalBaseComponent
  implements OnInit
{
  modalService = inject(ModalService);
  mediaService = inject(MediaService);
  viewByBrandService = inject(ViewByBrandService);
  private route = inject(ActivatedRoute); // NEW
  private router = inject(Router); // NEW
  linkService = inject(LinkService); // Ensure LinkService is injected

  currentRow = this.modalService.currentRow;
  compareCurrentPeriod = this.modalService.compareCurrentPeriod;

  viewByData = this.viewByBrandService.data;

  isMediaLoading = this.mediaService.isLoading;
  mediaTable = this.mediaService.table;
  isMediaDataAvailable = this.mediaService.isDataAvailable;

  pill = computed(() => capitalize(this.modalService.modalType()));

  channelsOptions = this.linkService.channelsOptions;

  public tabPanelHeaders: ITabPanelHeader[] = [
    { header: 'Summary', disabled: false, showCount: false, count: 0 },
    { header: 'Metrics', disabled: false, showCount: false, count: 0 },
    { header: 'Tactics', disabled: false, showCount: false, count: 0 }
  ];

  title = computed(() => {
    return this.modalService.modalParams().channel;
  });

  // Signal to control deep link modal visibility.
  isChannelModalVisible = signal(false);

  protected get isVisible(): boolean {
    return this.isChannelModalVisible();
  }

  constructor() {
    super();
    effect(() => {
      const count = this.mediaService.response().data?.length;
      const tab = this.tabPanelHeaders[2];
      if (tab) {
        this.setCount(count, tab);
      }
    });
  }

  setCount(count: number, tab: ITabPanelHeader) {
    if (count > 0) {
      tab.showCount = true;
      tab.count = count;
    } else {
      tab.showCount = false;
    }
  }

  ngOnInit(): void {
    // Subscribe to URL query parameters for deep linking,
    // now also handling conversion_type.
    this.route.queryParams.subscribe((params) => {
      this.modalService.updateConversionTypeFromParams(params);
      if (params.modal === 'channel' && params.channel) {
        const channel = decodeURIComponent(params.channel);
        const conversionType = this.modalService.conversionType();
        const currentParams = this.modalService.modalParams();
        const updatedParams = {
          ...currentParams,
          channel,
          conversionType
        };
        this.modalService.setModalParams(updatedParams);
        this.modalService.setCurrentRow(updatedParams);
        this.isChannelModalVisible.set(true);
      } else {
        this.isChannelModalVisible.set(false);
      }
    });
  }

  nextAction() {
    const nextRow = this.linkService.findNextChannel(this.currentRow());
    if (nextRow) {
      this.modalService.setCurrentRow(nextRow);
    }
  }

  previousAction() {
    const prevRow = this.linkService.findPrevChannel(this.currentRow());
    if (prevRow) {
      this.modalService.setCurrentRow(prevRow);
    }
  }

  selectAction(row: DimensionOption) {
    const { channel } = row;
    this.modalService.setCurrentRow({ channel });
  }

  override onCloseModal(): void {
    super.onCloseModal();
    this.router.navigate([], {
      queryParams: { modal: null, channel: null, conversion_type: null },
      queryParamsHandling: 'merge'
    });
    this.isChannelModalVisible.set(false);
  }
}
