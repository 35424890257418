/* eslint-disable @typescript-eslint/naming-convention */
import { colors } from '@design-system/styles/colors';
import { typographyConfig } from '@design-system/styles/typography/typography.config';
import { Options } from 'highcharts';

const formatNumber = (value: number, includeDecimal = true): string => {
  let formattedValue: number | string = value;
  let suffix = '';

  if (value >= 1000000) {
    formattedValue /= 1000000;
    suffix = 'M';
  } else if (value >= 1000) {
    formattedValue /= 1000;
    suffix = 'K';
  }

  formattedValue = includeDecimal
    ? formattedValue.toFixed(2)
    : Math.floor(formattedValue).toString();

  return formattedValue + suffix;
};

export const defaultConfigs: Options = {
  xAxis: {
    categories: [
      'Oct `24',
      'Nov `24',
      'Dec `24',
      'Jan `24',
      'Feb `24',
      'Mar `24'
    ],
    lineColor: colors['gray-300'],
    lineWidth: 1,
    gridLineWidth: 0,
    labels: {
      useHTML: true,
      style: {
        color: colors['gray-800'],
        fontSize: typographyConfig.styles.c1.size,
        fontFamily: typographyConfig.styles.c1.fontFamily,
        fontWeight: typographyConfig.styles.c1.weight
      }
    }
  },
  yAxis: {
    endOnTick: false,
    min: 75000000,
    max: 250000000,
    title: {
      text: ''
    },
    lineColor: colors['gray-300'],
    lineWidth: 1,
    gridLineWidth: 0,
    labels: {
      formatter() {
        const value = Number(this.value);
        return `$${formatNumber(value, false)}`;
      },
      style: {
        color: colors['gray-800'],
        fontSize: typographyConfig.styles.c1.size,
        fontFamily: typographyConfig.styles.c1.fontFamily,
        fontWeight: typographyConfig.styles.c1.weight
      }
    }
  },
  legend: {
    align: 'center',
    verticalAlign: 'top',
    x: 0,
    y: -40,
    borderWidth: 0,
    shadow: false,
    useHTML: true,
    labelFormatter() {
      return `<span class="c1 text-gray-800 block z-0">${this?.name}</span>`;
    },
    symbolWidth: 8,
    symbolHeight: 8
  },
  tooltip: {
    useHTML: true,
    outside: true,
    distance: 15,
    formatter() {
      const monthNames: Record<string, string> = {
        Jan: 'January',
        Feb: 'February',
        Mar: 'March',
        Apr: 'April',
        May: 'May',
        Jun: 'June',
        Jul: 'July',
        Aug: 'August',
        Sep: 'September',
        Oct: 'October',
        Nov: 'November',
        Dec: 'December'
      };
      if (this && typeof this.x === 'string' && this.y) {
        const month = monthNames[this.x.substring(0, 3)];
        return `
          <div class="tooltip-body">
              <div class="b2 text-gray-500">${month} ${this.x.substring(
                3
              )}</div>
              <div class="m-divider my-2 text-gray-900"></div>
              <div class="flex justify-between w-full">
                  <span class="b1 text-gray-000">${
                    this.point.series.name
                  }</span>
                  <span class="b1 text-gray-000">$${formatNumber(this.y)}</span>
              </div>
          </div>`;
      } else {
        return ``;
      }
    }
  },
  plotOptions: {
    column: {
      dataLabels: {
        enabled: false
      },
      borderWidth: 0,
      pointPadding: 0,
      // groupPadding: 0.318,
      groupPadding: 0.37,
      pointWidth: 16,
      maxPointWidth: 16,
      states: {
        hover: {
          enabled: false
        },
        inactive: {
          opacity: 1
        }
      }
    }
  },
  series: [
    {
      name: 'CAC',
      data: [125230000, 140120000, 150260000, 160450000, 175360000, 180290000],
      type: 'column',
      color: colors['blue-400']
    },
    {
      name: 'LTV',
      data: [150340000, 175230000, 200140000, 210340000, 225380000, 240580000],
      type: 'column',
      color: colors['blue-700']
    }
  ]
};
