import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { YAxisOptions, Series, SeriesLineOptions } from 'highcharts';
import {
  cloneDeep,
  compact,
  difference,
  filter,
  find,
  flatten,
  forEach,
  isEmpty,
  kebabCase,
  keyBy,
  map,
  sortBy,
  values
} from 'lodash-es';
import { GEO_NATIVE_CHART_COLORS } from '@portal/app/charts/shared/chart-constants';
import {
  ChartCommonGroupElement,
  ChartDataElement,
  ChartElement,
  ChartGroupElement,
  ContextField,
  DataChartValue,
  DataEndpoint,
  DataGridExportConfig,
  DataGridElement,
  ElementGroup,
  FieldDefinitions,
  Filter,
  WidgetType,
  ChartLegend,
  ChartSelectedX,
  FieldDefinition
} from '@portal/app/shared/types';
import { ContextStore } from '@portal/app/shared/state/context.store';
import { ApiService } from '@portal/app/shared/services/api.service';
import { Required } from '@portal/app/shared/decorators/required.decorator';
import { createFilterFields } from '@portal/app/dashboard/utils';
import { Subscription } from 'rxjs/internal/Subscription';
import { cellTypeMap } from '@portal/app/dashboard/geo-doe-config/shared/constants';
import { ChartService } from '@portal/app/charts/services/chart.service';
import {
  prepareChartTooltip,
  prepareChartYAxisConfig,
  xAxisType
} from '@portal/app/charts/shared/utils';
import {
  ChartDataTypes,
  IChartSeriesOption,
  ResolutionType
} from '@portal/app/charts/shared/chart-model';
import { Observable, take } from 'rxjs';
import { ViewStore } from '@portal/app/shared/state/view.store';
import { DataGridService } from '@portal/app/datagrid/services/data-grid.service';
import { FiltersService } from '@portal/app/shared/services/filters.service';
import { FormatterService } from '@portal/app/shared/services/formatter.service';

@Component({
  selector: 'portal-chart-group',
  templateUrl: './chart-group.component.html',
  styleUrls: ['./chart-group.component.scss']
})
export class ChartGroupComponent implements OnInit, OnDestroy {
  @Input() chartGroup!: ChartGroupElement;
  @Input() @Required fieldDefinitions: FieldDefinitions = {};
  @Input() @Required filters: Filter[] = [];
  chartGroupFilterControls: ContextField[] = [];
  chartGroupLayouts: ChartGroupElement[] = [];
  chartGroupSelectedLayout: ChartGroupElement | undefined = undefined;
  chartTableToggle: boolean | null = null;
  chartLegendGroup: boolean | null = null;
  overrideExport = false;
  charts: Record<string, Chart | undefined> = {};
  chartData: ChartDataElement = {};
  commonLegend: Record<string, ChartLegend[]> = {};

  chartTableData: ChartDataElement | undefined = undefined;
  chartTableLayout: ElementGroup | undefined = undefined;
  productId = '';
  private readonly subs: Subscription = new Subscription();
  filterContext$: Observable<Filter[]> = this.contextStore.filterContext;
  chartFilters$: Observable<Filter[]> = this.filtersService.dataChartFilter;
  chartLegends: { label: string; value: string; isSelected: boolean }[] = [];

  selectedChartLegends: string[] = [];

  constructor(
    private readonly contextStore: ContextStore,
    private readonly apiService: ApiService,
    private readonly formatterService: FormatterService,
    private readonly chartService: ChartService,
    private readonly viewStore: ViewStore,
    private readonly dataGridService: DataGridService,
    private readonly filtersService: FiltersService
  ) {}

  ngOnInit(): void {
    this.viewStore.selectedBCPDId.pipe(take(1)).subscribe((value) => {
      this.productId = value.productId;
    });
    this.setupFilterControls();
    this.setShowTableToggle();
    this.setChartLegendGroup();
    this.setShouldOverrideExport();
    this.selectLayoutByViewBy();
    this.setUpChart();
  }

  setupFilterControls(): void {
    this.chartGroupFilterControls = createFilterFields(
      this.chartGroup as ChartGroupElement,
      this.filters
    );
  }

  setShowTableToggle(): void {
    const chartTableToggleControl = this.chartGroup?.tableToggleControl;
    this.chartTableToggle = chartTableToggleControl
      ? (this.fieldDefinitions[
          chartTableToggleControl.dashboardFieldId
        ] as unknown as boolean)
      : null;
  }

  setShouldOverrideExport(): void {
    this.overrideExport = !!(
      this.chartGroup &&
      this.chartGroup.exportTable &&
      this.chartGroup.exportTable[0]
    );
  }

  setUpChart(): void {
    const chartElements = this.getAllChartElement();
    this.getAllChartData(chartElements);
  }

  getAllChartElement(): ChartElement[] {
    let chartElements: ChartElement[] = [];
    if (this.chartGroupSelectedLayout) {
      const chartCommonElements = this.chartGroupSelectedLayout
        .elements as ChartCommonGroupElement[];
      chartElements = flatten(
        chartCommonElements.map(({ elements }) => elements)
      ) as ChartElement[];
    }
    return chartElements;
  }

  setupChartGroups(data: DataChartValue[]): void {
    this.commonLegend = {};
    this.charts = {};
    if (
      this.chartGroupSelectedLayout &&
      this.chartGroupSelectedLayout.elements
    ) {
      const chartCommonElements = this.chartGroupSelectedLayout
        .elements as ChartCommonGroupElement[];
      let lastElement = chartCommonElements.length;
      for (const chartCommonElement of chartCommonElements) {
        lastElement = lastElement - 1;
        const groupedChart = chartCommonElement.elements as ChartElement[];
        this.commonLegend[chartCommonElement.literalId] = [];
        for (let i = 0; i < groupedChart.length; i++) {
          const chartElement = groupedChart[i];
          if (chartElement?.literalId) {
            const chartData = find(data, {
              name: chartElement.literalId
            }) as DataChartValue;
            this.chartData[chartElement.literalId] = {
              chartElement,
              data: chartData
            };
            this.charts[chartElement.literalId] = this.createChart(
              chartCommonElement.literalId,
              i,
              chartData,
              chartElement
            );
          }
        }
      }
      this.toggleChartTable(this.chartTableToggle as boolean);
    }
  }

  selectLayoutByViewBy(): void {
    if (this.chartGroup && this.chartGroup.elements) {
      this.chartGroupLayouts = this.chartGroup.elements as ChartGroupElement[];
      this.chartGroupSelectedLayout = this.chartGroupLayouts[0];
      this.filters.forEach((filterObj) => {
        if (filterObj.literalId === 'viewBy') {
          this.chartGroupSelectedLayout =
            (find(this.chartGroupLayouts, {
              literalId: filterObj.value
            }) as ChartGroupElement) || this.chartGroupLayouts[0];
        }
      });
    }
  }

  createChart(
    id: string,
    index: number,
    data: DataChartValue | undefined,
    chartElement: ChartElement
  ): Chart {
    const chartConfig = this.chartService.getDefaultChartConfig(this.productId);
    const xPropertyFieldId = (chartElement.selectedX[0] as ChartSelectedX)
      .dashboardFieldId;

    chartConfig.colors = GEO_NATIVE_CHART_COLORS;
    chartConfig.title = {
      text: chartElement.label as string,
      align: 'left'
    };

    const chartOptions = this.chartService.getDefaultChartOptions();
    const axisSeriesOptions: IChartSeriesOption[] = [];
    forEach(chartElement?.selectedY, (option) => {
      axisSeriesOptions.push({
        fields: [option.dashboardFieldId],
        label:
          index === 0
            ? chartElement.literalId.includes('BAU')
              ? 'Anchor BAU'
              : this.getCellType()
            : '',
        disabled: false
      });
    });
    chartOptions.axisSeriesOption = axisSeriesOptions;
    chartOptions.yAxisPropertyFields = map(
      chartElement?.selectedY,
      (yOption) => yOption.dashboardFieldId
    );
    const yAxisConfig = prepareChartYAxisConfig({
      chartOptions,
      isMultipleAxesActive: false,
      fieldDefinitions: this.fieldDefinitions,
      plotlineValues: {},
      formatterServiceInstance: this.formatterService
    }) as YAxisOptions;
    const series = this.prepareChartSeries(
      data as DataChartValue,
      chartElement,
      id
    );
    const chartConfigData = {
      chart: { ...chartConfig.chart, plotBorderWidth: 1 },
      legend: { ...chartConfig.legend, enabled: false },
      yAxis: yAxisConfig,
      xAxis: {
        ...chartConfig.xAxis,
        type: xAxisType(
          this.fieldDefinitions[xPropertyFieldId] as FieldDefinition
        )
      },
      series,
      tooltip: prepareChartTooltip(
        this.getResolution(),
        this.fieldDefinitions,
        chartConfig.tooltip,
        this.formatterService
      )
    };
    this.prepareLegend(id, series as SeriesLineOptions[]);
    return new Chart({ ...chartConfig, ...chartConfigData });
  }

  getAllChartData(chartElements: ChartElement[]): void {
    Promise.all(
      chartElements.map((chartElement) => {
        return new Promise((resolve) => {
          this.setChartDimension(chartElement);
          const dynamicFilter: Filter =
            this.updateHiddenFilterValue(chartElement);
          const chartFilter: Filter = this.updateChartFilter(chartElement);
          const resolutionFilter: Filter = this.updateResolutionFilter();
          const sub: Subscription = this.filterContext$.subscribe({
            next: (filterSet) => {
              const filters = cloneDeep(filterSet);
              const chartSub = this.chartFilters$.subscribe((chartFilters) => {
                this.apiService
                  .getDashboardData({
                    filters: map(
                      keyBy(
                        ([] as Filter[]).concat(
                          filters,
                          chartFilters,
                          ...this.filters,
                          dynamicFilter,
                          chartFilter,
                          resolutionFilter
                        ),
                        'literalId'
                      ),
                      (filterObj) => filterObj
                    ),
                    type: DataEndpoint.dataCharts
                  })
                  .then((response) => {
                    const typedResponse = cloneDeep(
                      response as unknown as DataChartValue[]
                    );
                    const chartResponse = find(typedResponse, {
                      name: chartElement.literalId
                    }) || { name: chartElement.literalId, data: [] };
                    resolve(chartResponse);
                  })
                  .catch((error) => {
                    console.error(
                      `Error while fetching the chart data: `,
                      error
                    );
                    resolve({
                      name: chartElement.literalId,
                      data: []
                    });
                  });
              });
              this.subs.add(chartSub);
            }
          });
          this.subs.add(sub);
        });
      })
    ).then((data) => {
      this.setupChartGroups(compact(data) as DataChartValue[]);
    });
  }

  hideSeries(series: Series, legend: ChartLegend): void {
    series.hide();
    legend.opacity = 0.2;
  }

  showSeries(series: Series, legend: ChartLegend): void {
    series.show();
    legend.opacity = 1;
  }

  handleLegendItemsSelection(): void {
    const chartCommonLiteralIds = difference(
      this.chartLegends.map(({ value }) => value),
      this.selectedChartLegends
    );
    for (const chartObj of values(this.charts)) {
      if (chartObj != null) {
        chartObj.ref$.subscribe({
          next: (value) => {
            const allSeries = value.series;
            allSeries.forEach((s) => {
              const legend = find(flatten(values(this.commonLegend)), {
                name: s.name
              });
              if (
                isEmpty(this.selectedChartLegends) ||
                chartCommonLiteralIds.includes(s.userOptions.id as string)
              ) {
                this.hideSeries(s, legend as ChartLegend);
              } else {
                this.showSeries(s, legend as ChartLegend);
              }
            });
          }
        });
      }
    }

    this.toggleChartTable(this.chartTableToggle as boolean);
  }

  legendItemClick(chartCommonLiteralId: string): void {
    for (const chartObj of values(this.charts)) {
      const cObj = chartObj;
      if (chartCommonLiteralId && cObj != null) {
        cObj.ref$.subscribe({
          next: (value) => {
            const series = value.get(chartCommonLiteralId) as Series;
            if (series) {
              const legend = find(flatten(values(this.commonLegend)), {
                name: series.name
              });
              if (series.visible) {
                this.hideSeries(series, legend as ChartLegend);
              } else {
                this.showSeries(series, legend as ChartLegend);
              }
            }
          }
        });
      }
    }
    this.prepareSelectedChartLegends();
  }

  updateChartGroupLayout(filterObj: ContextField): void {
    this.chartGroupSelectedLayout = find(this.chartGroupLayouts, {
      literalId: filterObj.value
    }) as ChartGroupElement;
    this.chartData = {};
    this.chartTableData = undefined;
    this.setUpChart();
  }

  setChartDimension(chartElement: ChartElement): void {
    this.filters.map((filterObj) => {
      if (filterObj.literalId === 'chartDimensions') {
        filterObj.value = chartElement.selectedY.map(
          ({ dashboardFieldId }) => dashboardFieldId
        );
      }
    });
  }

  updateHiddenFilterValue(chartElement: ChartElement): Filter {
    const cellIdFilter = find(this.filters, { literalId: 'cell_id' });
    if (cellIdFilter) {
      const dynamicFilter = cloneDeep(cellIdFilter);
      if (chartElement.literalId.includes('BAU')) {
        dynamicFilter.value =
          find(this.filters, {
            literalId: 'bauCellId'
          })?.value || '';
      } else {
        dynamicFilter.value =
          find(this.filters, {
            literalId: 'cell_id'
          })?.value || '';
      }
      return dynamicFilter;
    }
    return this.filters[0] as Filter;
  }

  updateChartFilter(chartElement: ChartElement): Filter {
    const chartFilter = cloneDeep(find(this.filters, { literalId: 'charts' }));
    if (chartFilter) {
      chartFilter.value = chartElement.literalId as string;
      return chartFilter;
    }
    return this.filters[0] as Filter;
  }

  updateResolutionFilter(): Filter {
    const chartFilter = cloneDeep(
      find(this.filters, { literalId: 'resolution' })
    );
    const specificFilter = cloneDeep(
      find(this.filters, { literalId: 'chartGroupResolution' })
    );
    if (chartFilter) {
      chartFilter.value =
        specificFilter && specificFilter.value
          ? specificFilter.value
          : chartFilter.value;
      return chartFilter;
    }
    return this.filters[0] as Filter;
  }

  prepareChartSeries(
    chartData: DataChartValue,
    chartElement: ChartElement,
    chartCommonElementLiteralId: string
  ): SeriesLineOptions[] {
    if (chartData.seriesBy) {
      const chartSeries: SeriesLineOptions[] = [];
      chartElement.selectedY.forEach((yObj) => {
        let colorIndex = -1;
        const chartSeriesBySelectedY: SeriesLineOptions[] = sortBy(
          chartData.data,
          'name'
        ).map((dObj) => {
          colorIndex = colorIndex + 1;
          if (colorIndex >= GEO_NATIVE_CHART_COLORS.length) {
            colorIndex = 0;
          }
          return {
            id: `${chartCommonElementLiteralId}_${dObj.name}_${yObj.dashboardFieldId}`,
            type: 'line',
            name:
              chartElement.selectedY.length === 1
                ? dObj.name
                : `${dObj.name}:${yObj.dashboardFieldId}`,
            data: dObj.data.map((obj: Record<string, string | number>) => {
              return {
                x: obj.date,
                y: obj[yObj.dashboardFieldId]
              };
            }),
            custom: {
              propName: yObj.dashboardFieldId,
              type: ChartDataTypes.dataChart
            },
            marker: {
              states: {
                hover: {
                  lineColor: GEO_NATIVE_CHART_COLORS[colorIndex]
                }
              }
            }
          };
        });
        chartSeries.push(...chartSeriesBySelectedY);
      });
      return chartSeries;
    } else {
      return chartData.data.map((obj: Record<string, string | number>) => {
        return {
          type: 'line',
          id: `${chartCommonElementLiteralId}_${obj.name}`,
          x: obj.date,
          y: obj.conversions
        };
      });
    }
  }

  getCellType(): string {
    const cellTypeFilter = find(this.filters, {
      literalId: 'cell_type'
    });
    return cellTypeFilter
      ? (cellTypeMap[cellTypeFilter.value as string] as string)
      : '';
  }

  toggleChartTable(toggleValue: boolean): void {
    this.chartTableToggle = toggleValue;
    this.chartService.setGeoExperimentChartTableData(undefined);
    if (toggleValue) {
      this.setTableLayout();
      const selectedLegendsNames = compact(
        flatten(values(this.commonLegend)).map((obj) => {
          if (obj && obj.id && this.selectedChartLegends.includes(obj.id)) {
            return obj.name;
          }
        })
      );
      this.chartTableData = cloneDeep(this.chartData);
      for (const chartTableDataKey in this.chartTableData) {
        if (
          chartTableDataKey &&
          this.chartTableData[chartTableDataKey] &&
          !isEmpty(this.chartTableData[chartTableDataKey]?.data)
        ) {
          (
            this.chartTableData[chartTableDataKey] as {
              data: DataChartValue;
              chartElement: ChartElement;
            }
          ).data.data = [
            ...compact(
              this.chartTableData[chartTableDataKey]?.data?.data.map(
                (chartTableDataObj) => {
                  if (selectedLegendsNames.includes(chartTableDataObj.name)) {
                    return { ...chartTableDataObj };
                  } else {
                    return null;
                  }
                }
              )
            )
          ];
        }
      }
      this.chartService.setGeoExperimentChartTableData(this.chartTableData);
    }
  }

  prepareLegend(
    chartCommonElementLiteralId: string,
    series: SeriesLineOptions[]
  ): void {
    if (series.length) {
      this.commonLegend[chartCommonElementLiteralId] = series.map((sObj) => {
        return {
          name: sObj.name as string,
          color: sObj.marker?.states?.hover?.lineColor,
          id: sObj.id,
          opacity: 1,
          isSelected: true
        };
      });
      this.prepareSelectedChartLegends();
    }
  }

  getResolution(): ResolutionType {
    return 'Week';
  }

  setTableLayout(): void {
    if (this.chartGroup && this.chartGroup.elements) {
      const dataGridElement = find(
        this.chartGroup?.elements as ChartGroupElement | DataGridElement,
        {
          layoutType: WidgetType.table
        }
      );
      if (dataGridElement) {
        this.chartTableLayout = dataGridElement as ElementGroup;
      }
    }
  }

  overrideExportChartHandler(): void {
    if (!this.chartTableToggle) {
      return;
    }
    const tableName = this.chartTableLayout
      ? this.chartTableLayout.literalId
      : '';
    const chartType = this.chartGroupSelectedLayout?.literalId;
    const config: DataGridExportConfig = {
      tableName,
      fileName: `${kebabCase(chartType)}-${kebabCase(tableName)}`,
      showFooter: false
    };
    this.dataGridService.exportTable(config);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  prepareSelectedChartLegends(): void {
    this.chartLegends = compact(
      flatten(compact(values(this.commonLegend))).map((obj) => {
        return {
          label: obj.name,
          value: obj.id,
          isSelected: obj.opacity === 1
        };
      })
    ) as { label: string; value: string; isSelected: boolean }[];
    this.selectedChartLegends = filter(this.chartLegends, {
      isSelected: true
    }).map(({ value }) => value);
  }

  setChartLegendGroup(): void {
    const chartLegendGroupControl = this.chartGroup?.legendGroup;
    this.chartLegendGroup = !isEmpty(chartLegendGroupControl);
  }
}
