import { IActionButtonConfig, IHeaderConfig } from '@libs/common-components';
import { BannerType } from '@design-system/components/banner/m-banner';

export class Constants {
  public static readonly headerConfig: IHeaderConfig = {
    primaryTitle: {
      field: 'title',
      defaultValue: 'Campaign Mapping',
      styleClass: 'lh-2',
      span: 0
    },
    secondaryTitles: [],
    badges: [],
    faq: {
      label: 'Learn More',
      span: 0,
      field: 'https://support.measured.com/article/w1oa2yaq7h-campaign-mapper'
    }
  };

  public static readonly lastUpdatedAtConfig: IActionButtonConfig[] = [
    {
      label: 'Last updated:',
      styleClass: 'saas-ui-size-default b1 text-gray-800',
      value: 'last-updated-at'
    }
  ];

  public static readonly bannerConfig = {
    type: BannerType.primary,
    title: 'Map out a path to success',
    subText: `Use this page to map your campaigns into tactics, which are specific strategies within your channels. These tactics will play a key role in your usage of all Measured features.`
  };

  public static readonly learnMoreUrl =
    'https://support.measured.com/article/w1oa2yaq7h-campaign-mapper';
}
