<p-overlayPanel [dismissable]="true" [showCloseIcon]="true" #oPanel>
  <ng-template pTemplate="content">
    <h4 class="popup-header">
      {{ assocFilterService.getFilterLabel(name, filters) }}
    </h4>
    @if (layoutType === "FILTER_SET_RADIO_POPUP") {
      <portal-app-filter-set-radio-popup
        [name]="name"
        [dashboardId]="dashboardId"
        [fieldDefinitions]="fieldDefinitions"
        (filterChange)="onFilterValueChange($event)"
        [filterSet]="filters">
      </portal-app-filter-set-radio-popup>
    }
    @if (layoutType === "EDIT_POPUP") {
      <span class="overlay-header">{{ widgetLayout.label }}</span>
      <div class="popup-container">
        @for (item of widgetLayout.elements; track item) {
          @if (!showChild) {
            <div class="popup-top-container">
              @if (item.layoutType === "CUSTOM_BUTTON_GROUP") {
                <div class="custom-btn-group" [ngClass]="[item.literalId]">
                  <p class="overlay-sub-title">{{ item.label }}</p>
                  <portal-button-group-custom
                    class="button-group"
                    [options]="
                      assocFilterService.getFilter(item.literalId, filterSet)
                        .options
                    "
                    [filter]="
                      assocFilterService.getFilter(item.literalId, filterSet)
                    "
                    (valueChange)="onFilterValueChange($event)"
                    [literalId]="item.literalId">
                  </portal-button-group-custom>
                </div>
              }
              @if (item.layoutType === "PREFIX_TEXTBOX") {
                <div class="prefix-txt-box" [ngClass]="[item.literalId]">
                  <p class="overlay-sub-title">{{ item.label }}</p>
                  <ng-container>
                    <span class="dollar">{{
                      formatterService.currencySymbolValue
                    }}</span>
                    <p-inputNumber
                      [(ngModel)]="
                        assocFilterService.getFilter(item.literalId, filterSet)
                          .value
                      "
                      (ngModelChange)="
                        onFilterValueChange(
                          assocFilterService.getFilter(
                            item.literalId,
                            filterSet
                          )
                        )
                      "
                      class="target-budget-input"
                      type="number"></p-inputNumber>
                  </ng-container>
                </div>
              }
            </div>
          }
          @if (!showChild && item.layoutType === "BOUNDED_SLIDER") {
            <ng-container
              [ngTemplateOutlet]="boundedSlider"
              [ngTemplateOutletContext]="{
                item: item,
              }">
            </ng-container>
          }
          @if (showChild && item.layoutType === "CHILD_BOUNDED_SLIDER") {
            <ng-container
              [ngTemplateOutlet]="boundedSlider"
              [ngTemplateOutletContext]="{
                item: item,
              }">
            </ng-container>
          }
        }
      </div>
    }

    <div class="mt-3">
      <p-button
        label="Apply"
        [ngClass]="['p-button-success']"
        (click)="oPanel.hide(); onApply()"></p-button>

      <button class="btn btn-link btn-cancel" (click)="oPanel.hide()">
        Cancel
      </button>
    </div>
  </ng-template>
</p-overlayPanel>

<ng-template #boundedSlider let-item="item">
  <div class="bounded-slider" [ngClass]="[item.literalId]">
    <div class="radio-slider-container">
      <span class="overlay-sub-title">{{ item.label }}</span>
      <portal-radio-button-bar
        [leftText]="'Conservative'"
        [rightText]="'Ambitious'"
        [options]="
          assocFilterService.getFilter(item.literalId, filterSet).options
        "
        [selectedValue]="
          assocFilterService.getFilter(item.literalId, filterSet).value
        "
        [filter]="assocFilterService.getFilter(item.literalId, filterSet)"
        (valueChange)="onFilterValueChange($event)">
      </portal-radio-button-bar>
    </div>
  </div>
</ng-template>
