import { Component, effect, inject, Renderer2 } from '@angular/core';
import { MmmModalDetailsService } from '@portal/app/shared/components/mmm-model-details/mmm-modal-details.service';
import { SlideInOutAnimator } from '@libs/common-animations';
import { ITabPanelHeader } from '@design-system/components/m-tab-view';
import * as Highcharts from 'highcharts';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'portal-mmm-modal-details',
  templateUrl: './mmm-modal-details.component.html',
  styleUrls: ['./mmm-modal-details.component.scss'],
  animations: [SlideInOutAnimator.slideInUpOutDown()]
})
export class MmmModalDetailsComponent {
  private renderer = inject(Renderer2);
  mmmModalDetailsService: MmmModalDetailsService = inject(
    MmmModalDetailsService
  );

  public activeIndex = 0;

  public tabPanelHeaders = [
    {
      header: 'Contributing Tests',
      disabled: false,
      showCount: false,
      count: 0
    },
    {
      header: 'Contributing Tactics',
      disabled: false,
      showCount: false,
      count: 0
    }
  ] as ITabPanelHeader[];

  conversionTypeOptions =
    this.mmmModalDetailsService.conversionTypeOptionsSignal;

  conversionType = this.mmmModalDetailsService.conversionTypeSignal;

  showModal = this.mmmModalDetailsService.showModalSignal;
  mediums = this.mmmModalDetailsService.mediumsSignal;
  isMediumsLoading = this.mmmModalDetailsService.isMediumsLoadingSignal;

  resolutionOptions = this.mmmModalDetailsService.resolutionOptionsSignal;
  resolution = this.mmmModalDetailsService.resolutionSignal;
  periodOptions = this.mmmModalDetailsService.periodOptionsSignal;
  period = this.mmmModalDetailsService.periodSignal;

  contributingTests = this.mmmModalDetailsService.testsSignal;
  isTestsLoading = this.mmmModalDetailsService.isTestsLoadingSignal;
  isTestsDataAvailable = this.mmmModalDetailsService.isTestsDataAvailableSignal;
  contributingTactics = this.mmmModalDetailsService.tacticsSignal;
  isTacticsLoading = this.mmmModalDetailsService.isTacticsLoadingSignal;
  isTacticsDataAvailable =
    this.mmmModalDetailsService.isTacticsDataAvailableSignal;

  chartOptions = this.mmmModalDetailsService.chartOptionsSignal;
  isChartReady = this.mmmModalDetailsService.isChartReadySignal;
  isChartDataAvailable = this.mmmModalDetailsService.isChartDataAvailableSignal;
  isHistoryPerformanceLoading =
    this.mmmModalDetailsService.isHistoryPerformanceLoadingSignal;

  private createExportMenu(
    formatOptions: string[],
    type: 'TABLE' | 'CHART'
  ): MenuItem[] {
    const labels: Record<string, string> = {
      csv: 'CSV',
      tsv: 'TSV',
      xlsx: 'Excel'
    };
    return formatOptions.map((format: string) => ({
      label: labels[format],
      icon: 'pi pi-fw pi-file',
      styleClass: `export-item ${format}`,
      command: () =>
        type === 'TABLE'
          ? this.exportTableData(format)
          : this.exportChartData(format)
    }));
  }

  public exportTableMenu = this.createExportMenu(
    ['csv', 'tsv', 'xlsx'],
    'TABLE'
  );

  public exportChartMenu = this.createExportMenu(
    ['csv', 'tsv', 'xlsx'],
    'CHART'
  );

  constructor() {
    effect(() => {
      if (this.showModal()) {
        this.renderer.addClass(document.body, 'overflow-hidden');
      } else {
        this.renderer.removeClass(document.body, 'overflow-hidden');
      }

      if (this.contributingTests()?.response?.data) {
        if (this.tabPanelHeaders[0]) {
          this.tabPanelHeaders[0].count =
            this.contributingTests().response?.data.length;
          this.tabPanelHeaders[0].showCount = this.tabPanelHeaders[0].count > 0;
        }
      }

      if (this.contributingTactics()?.response?.data) {
        if (this.tabPanelHeaders[1]) {
          this.tabPanelHeaders[1].count =
            this.contributingTactics().response?.data.length;
          this.tabPanelHeaders[1].showCount = this.tabPanelHeaders[1].count > 0;
        }
      }
    });
  }

  onChartRender(chart: Highcharts.Chart): void {
    if (chart) {
      this.setMarkerPosition(chart);
    }
  }

  clearMarkers(): void {
    const existingMarkers = document.querySelectorAll(
      '.mmm-modal-details-chart div[data-point-index]'
    );
    existingMarkers.forEach((marker) => marker.remove());
  }

  setMarkerPosition(chart: Highcharts.Chart): void {
    const imageWidth = 48;
    const imageHeight = 51;
    let positionY = 0;
    const calibrationDates = [] as string[];
    const lefts = [] as number[];
    const overlapAdjustment = 12;

    this.clearMarkers();

    chart?.series.forEach((serie) => {
      if (serie.name.includes('#Test#')) {
        serie.data.forEach((point: Highcharts.Point) => {
          const test = point.options.custom?.test;
          const index = point.options.custom?.index;
          if (test) {
            const occurrences = calibrationDates.filter(
              (date) => date === test.calibrationDate.formattedValue
            )?.length;
            if (occurrences > 0) {
              positionY = imageHeight * occurrences;
            } else {
              positionY = 0;
            }

            let left = (point.plotX || 0) + chart.plotLeft - imageWidth / 2;
            if (lefts.includes(left)) {
              left += overlapAdjustment;
            }
            lefts.push(left);

            const iconEl = document.createElement('div');
            iconEl.style.position = 'absolute';
            iconEl.style.left = `${left}px`;
            iconEl.style.top = `${(point.plotY || 0) + chart.plotTop - imageHeight - positionY}px`;
            iconEl.style.width = `${imageWidth}px`;
            iconEl.style.height = `${imageHeight}px`;
            iconEl.style.backgroundImage = 'url(/assets/icons/location.svg)';
            iconEl.style.zIndex = '1024';
            iconEl.setAttribute('data-point-index', index);
            iconEl.addEventListener('mouseover', () => {
              this.mmmModalDetailsService.hoverPointSignal.set(point);
              iconEl.style.backgroundImage =
                'url(/assets/icons/location_hover.svg)';
              chart.tooltip.refresh(point);
            });
            iconEl.addEventListener('mouseout', () => {
              this.mmmModalDetailsService.hoverPointSignal.set(null);
              iconEl.style.backgroundImage = 'url(/assets/icons/location.svg)';
              chart.tooltip.hide();
            });
            document
              .querySelector('.mmm-modal-details-chart')
              ?.appendChild(iconEl);

            calibrationDates.push(test.calibrationDate.formattedValue);
          }
        });
      }
    });
  }

  closeModal(): void {
    this.renderer.removeClass(document.body, 'overflow-hidden');
    this.mmmModalDetailsService.showModalSignal.set(false);
  }

  onConversionTypeChange(conversionType: string): void {
    this.mmmModalDetailsService.conversionTypeSignal.set(conversionType);
  }

  onResolutionChange(resolution: string): void {
    this.mmmModalDetailsService.resolutionSignal.set(resolution);
  }

  onPeriodChange(period: string): void {
    this.mmmModalDetailsService.periodSignal.set(period);
  }

  exportChartData(format: string) {
    this.mmmModalDetailsService.exportChartData(format);
  }

  exportTableData(format: string) {
    this.mmmModalDetailsService.exportTableData(this.activeIndex, format);
  }

  onActiveIndex(index: number): void {
    this.activeIndex = index;
  }
}
