import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil
} from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import dayjs from 'dayjs';

// Services
import { DateRangeUtilityService } from '@portal/app/shared/services/date-range-utility.service';
import { FilterService } from '@portal/app/dashboard/home-page/services/filter.service';
import { HomepageService } from '@portal/app/dashboard/home-page/services/home-page.service';

// Types
import type { Filter } from '@portal/app/shared/types';
import { DayJsDateFormat } from '@portal/app/shared/services/date-time.service';

// Constants
import { FilterIds, Resolution } from '@portal/app/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class OptimizationResultsService implements OnDestroy {
  private destroy$ = new Subject<void>();

  // State subjects
  private startDateSubject = new BehaviorSubject<string | null>(null);
  public startDate$ = this.startDateSubject.asObservable();

  private stopDateSubject = new BehaviorSubject<string | null>(null);
  public stopDate$ = this.stopDateSubject.asObservable();

  private conversionTypeSubject = new BehaviorSubject<string | null>(null);
  public conversionType$ = this.conversionTypeSubject.asObservable();

  private viewBySubject = new BehaviorSubject<string | null>(null);
  public viewBy$ = this.viewBySubject.asObservable();

  constructor(
    private dateRangeUtilityService: DateRangeUtilityService,
    private filterService: FilterService,
    private homepageService: HomepageService
  ) {
    this.setDateRange(Resolution.monthly);
    this.subscribeToChanges();
  }

  /**
   * Sets the date range for the report with T-1 SLA logic and updates chart dates.
   */
  public setDateRange(resolution: Resolution) {
    // Calculate the stop date using shared utility
    const stopDate = dayjs(
      this.dateRangeUtilityService.calculateMaxDate(resolution)
    ).format(DayJsDateFormat.fullDate);

    const { chartStartDate, chartStopDate } =
      this.dateRangeUtilityService.setChartDates(resolution, stopDate);

    this.startDateSubject.next(chartStartDate);
    this.stopDateSubject.next(chartStopDate);
  }

  /**
   * Subscribes to changes in filters and view settings, updating the subjects accordingly.
   */
  private subscribeToChanges() {
    combineLatest([this.filterService.filters$, this.homepageService.viewBy$])
      .pipe(
        map(([filters, viewBy]) => this.extractVariables(filters, viewBy)),
        distinctUntilChanged(isEqual),
        debounceTime(300),
        takeUntil(this.destroy$)
      )
      .subscribe(({ conversionType, viewBy }) => {
        this.conversionTypeSubject.next(conversionType);
        this.viewBySubject.next(viewBy);
      });
  }

  /**
   * Extracts variables from the given filters and view settings.
   *
   * @param filters - The list of filters.
   * @param viewBy - The current view setting.
   * @returns The extracted variables.
   */
  private extractVariables(filters: Filter[], viewBy: string | null) {
    const conversionType =
      filters.find(
        (filter) => filter.literalId === FilterIds.conversionTypeLiteralId
      )?.value || null;

    return { filters, conversionType, viewBy };
  }

  /**
   * Cleans up the service by completing the destroy$ subject.
   */
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
