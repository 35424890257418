/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { environment } from '@portal/environments/environment';
import { compact } from 'lodash-es';

// Services
import { AuthService } from '@portal/app/shared/services/auth.service';
import { SelectionService } from './selection.service';
import { ViewService } from './view.service';

// Types
import type {
  Filter,
  FilterValue,
  IRoutedFilterFromHomePage
} from '@portal/app/shared/types';
import {
  BrandRow,
  ConfigDashboardInstance,
  CurrentStatus,
  DisplayDashboard
} from '../types';
import { NavbarConstants } from '@portal/app/shared/types/navigation';
import { UserType } from '../models/userModel';
import { UserModel } from '@portal/app/shared/models/userModel';

// Constants
import { FILTER_LITERAL_IDS } from '@portal/app/dashboard/home-page/home-page.constants';
import { FeatureConfigService } from './feature-config.service';
import { FeatureConfigResponseDTO } from '@libs/api';

const {
  conversionTypeLiteralId,
  dateStartLiteralId,
  compareDateLiteralId,
  dateStopLiteralId,
  relativeDayLiteralId,
  previousDateStartLiteralId,
  previousDateStopLiteralId
} = FILTER_LITERAL_IDS;

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  constructor(
    private readonly authService: AuthService,
    private readonly selectionService: SelectionService,
    private viewService: ViewService,
    private readonly router: Router,
    private readonly featureConfigService: FeatureConfigService
  ) {}

  private getBrandMenuItem(
    showBrandsDialog: () => void,
    brandRows: BrandRow[]
  ) {
    const user = this.authService.getUser() as UserModel;
    if (user.getUserType() === 'internal') {
      return [
        {
          id: NavbarConstants.controlTowerId,
          label: 'Control Tower',
          routerLink: `/a/${this.selectionService.buildSelectionSlug(
            this.selectionService.getSelection()
          )}/products/control-tower`
        },
        {
          id: 'change-brand',
          label: 'Switch Brand',
          command: (): void => {
            showBrandsDialog();
          }
        }
      ];
    }

    if (brandRows && brandRows.length === 1) {
      const brand = brandRows[0];
      return [
        {
          label: brand?.brand.brandName,
          id: 'brand',
          command: (): void => {
            this.viewService.switchBrand(brand as BrandRow, user);
          }
        }
      ];
    }

    if (brandRows && brandRows.length > 1 && brandRows.length <= 3) {
      return brandRows.map((b) => ({
        label: b.brand.brandName,
        id: 'brand',
        styleClass: 'brand-item',
        command: (): void => {
          this.viewService.switchBrand(b, user);
        }
      }));
    }

    return [
      {
        label: 'Switch Brand',
        styleClass: 'brand-dropdown',
        items: brandRows.map((b) => {
          return {
            label: b.brand.brandName,
            id: b.brand.brandName,
            styleClass: 'brand-item',
            command: (): void => {
              this.viewService.switchBrand(b, user);
            }
          } as unknown as MenuItem;
        })
      }
    ];
  }

  private isConfigDashboardConfigured(
    configDashboards: Record<string, ConfigDashboardInstance>,
    literalId = ''
  ): boolean {
    return configDashboards[literalId] !== undefined;
  }

  private buildOnboardingURL() {
    if (environment.innovation) {
      return `${window.location.protocol}//${window.location.host}:4300`;
    }
    return 'https://onboarding.measured.com';
  }

  createUserOptionsMenuItems(
    configDashboards: Record<string, ConfigDashboardInstance>,
    showBrandsDialog: () => void,
    brandRows: BrandRow[],
    updateLogOutModalIsVisible: (value: boolean) => void
  ): MenuItem[] {
    return [
      {
        id: NavbarConstants.userAccountId,
        routerLink: '/a/user',
        label: 'My Profile'
      },

      ...this.getBrandMenuItem(showBrandsDialog, brandRows),
      {
        id: 'logout',
        command: (): void => {
          updateLogOutModalIsVisible(true);
          this.authService.logout();
        },
        label: 'Log Out'
      }
    ];
  }

  createNativeExportReportMenu(
    configDashboards: Record<string, ConfigDashboardInstance>,
    brandProductPath: string
  ): MenuItem | null {
    const user = this.authService.getUser() as UserModel;
    const exportDataDashboard = configDashboards['export-data'];
    const userType = user.getUserType();
    if (
      exportDataDashboard &&
      (userType === UserType.internal ||
        (userType === UserType.external &&
          exportDataDashboard.status === 'ACTIVE'))
    ) {
      const label =
        exportDataDashboard.displayName ||
        exportDataDashboard.configDashboard.displayName;

      return {
        routerLink: `/a/${brandProductPath}/export-report`,
        label: `${label}${
          exportDataDashboard.status === 'DRAFT' ? ' (BETA)' : ''
        }`
      };
    }
    return null;
  }

  createSettingsMenuItems(
    configDashboards: Record<string, ConfigDashboardInstance>,
    brandProductPath: string
  ): MenuItem[] {
    const dataExportMenuItem = this.createNativeExportReportMenu(
      configDashboards,
      brandProductPath
    );
    return [
      {
        id: NavbarConstants.ddiSettingsId,
        label:
          configDashboards[NavbarConstants.ddiSettingsId]?.displayName ||
          'Settings Manager',
        url: `a/${this.selectionService.buildSelectionSlug(
          this.selectionService.getSelection()
        )}/products/settings-manager/settings`,
        title: 'Settings Manager',
        routerLink: `/a/${this.selectionService.buildSelectionSlug(
          this.selectionService.getSelection()
        )}/products/settings-manager/settings`
      },
      {
        ...(dataExportMenuItem
          ? {
              ...dataExportMenuItem,
              routerLink: `/a/${this.selectionService.buildSelectionSlug(this.selectionService.getSelection())}/products/export-report`
            }
          : {})
      },
      {
        id: NavbarConstants.adminPageId,
        label:
          configDashboards[NavbarConstants.adminPageId]?.displayName ||
          'Administration',
        routerLink: `/a/${this.selectionService.buildSelectionSlug(
          this.selectionService.getSelection()
        )}/products/admin`
      },
      {
        id: NavbarConstants.campaignMapping,
        label:
          configDashboards[NavbarConstants.campaignMapping]?.displayName ||
          'Campaign Mapping',
        routerLink: `/a/${this.selectionService.buildSelectionSlug(
          this.selectionService.getSelection()
        )}/products/campaign-mapping`
      },
      {
        id: NavbarConstants.onboardingId,
        label:
          configDashboards[NavbarConstants.onboardingId]?.displayName ||
          'Onboarding',
        url: this.buildOnboardingURL(),
        title: 'Client Onboarding'
      }
    ];
  }

  createOnClickBenchmark(
    dashboards: DisplayDashboard[],
    brandProductPath: string
  ): (() => void) | undefined {
    const benchmarkDashboard = dashboards.find(
      (dashboard) => dashboard.literalId === NavbarConstants.benchmarksId
    );

    if (benchmarkDashboard) {
      return () => {
        this.router.navigate([
          `/a/${brandProductPath}/${benchmarkDashboard.routerLinkPart}`
        ]);
      };
    } else {
      return undefined;
    }
  }

  createOnClickOptimize(
    dashboards: DisplayDashboard[],
    brandProductPath: string
  ): (() => void) | undefined {
    const mpoDashboard: DisplayDashboard | null =
      dashboards.filter(
        (d) =>
          d.literalId === NavbarConstants.mpoLiteralId &&
          d.productId === NavbarConstants.mpoProductId
      )[0] || null;
    if (mpoDashboard != null) {
      return () =>
        this.router.navigate(
          [`/a/${brandProductPath}/${mpoDashboard.routerLinkPart}`],
          {
            queryParams: { isMultiView: mpoDashboard.isMultiView }
          }
        );
    } else {
      return undefined;
    }
  }

  createExperimentMenuItems(
    dashboards: DisplayDashboard[],
    configDashboards: Record<string, ConfigDashboardInstance>,
    brandProductPath: string
  ): MenuItem[] {
    const filteredDashboards = dashboards
      .filter((d) => d.status !== CurrentStatus.inactive)
      .filter(
        (d) =>
          d.dashboardId === NavbarConstants.experimentDashboardId ||
          NavbarConstants.experimentIds.includes(d.literalId) ||
          d.label.match(NavbarConstants.experimentLabelRegex) != null
      )
      .filter((d) => {
        return d.dashboardId === NavbarConstants.experimentDashboardId
          ? this.isConfigDashboardConfigured(configDashboards, d.literalId)
          : true;
      });

    return filteredDashboards.map((d) => ({
      routerLink: `/a/${brandProductPath}/${d.routerLinkPart}`,
      label: `${
        configDashboards[d.literalId]?.displayName ||
        configDashboards[d.literalId]?.configDashboard.displayName ||
        d.label
      }${d.status === 'DRAFT' ? ' (BETA)' : ''}`
    }));
  }

  /**
   * Creates an array of MenuItem objects based on the provided dashboards and brand product path.
   * If an optimization report exists, it is inserted between the first and remaining dashboard items.
   * Optionally, an MPO v2 (Alpha) link is also added if the feature flag is enabled.
   *
   * @param dashboards - An array of dashboard objects, each containing details about individual dashboards.
   * @param brandProductPath - The base path for brand-specific products used in generating URLs for the menu items.
   * @returns An array of menu items ready for use in a navigation component.
   */
  createOptimizeMenuItems(
    dashboards: DisplayDashboard[],
    brandProductPath: string
  ): MenuItem[] {
    // Build dashboard items for MPO product
    const dashboardItems = dashboards
      .filter((d) => d.productId === NavbarConstants.mpoProductId)
      .map((d) => ({
        label: d.label,
        command: () => {
          const url = `/a/${brandProductPath}/${d.routerLinkPart}`;
          const queryParams = `?isMultiView=${d.isMultiView}`;
          window.open(url + queryParams, '_blank');
        }
      }));

    // Determine if an optimization report is available
    const hasOptimizationReport = dashboards.some((d) =>
      d.items?.some(
        (item) => item.literalId === NavbarConstants.optimizationReportId
      )
    );

    let optimizationReport: MenuItem | undefined;
    if (hasOptimizationReport) {
      const isBeta = dashboards.some(
        (d) =>
          d.status === 'DRAFT' &&
          d.literalId === NavbarConstants.optimizationReportId
      );
      optimizationReport = {
        label: `Optimization Report${isBeta ? ' (BETA)' : ''}`,
        command: () => {
          window.open(`/a/${brandProductPath}/optimization-report`, '_blank');
        }
      };
    }

    // Conditionally create the MPO v2 link if the feature is enabled
    const isMPOV2Enabled = this.featureConfigService.isFeatureEnabled(
      FeatureConfigResponseDTO.FeatureNameEnum.MpoV2
    );
    let mpoV2: MenuItem | undefined;
    if (isMPOV2Enabled) {
      mpoV2 = {
        routerLink: `/a/${brandProductPath}/mpo`,
        label: 'MPO v2 (Alpha)'
      };
    }

    // Build the final menu items list.
    // If an optimization report is available, insert it (and MPO v2 if enabled) between the first dashboard item and the rest.
    if (optimizationReport) {
      const finalMenuItems: MenuItem[] = [];
      if (dashboardItems.length > 0) {
        finalMenuItems.push(dashboardItems[0] as MenuItem);
      }
      finalMenuItems.push(optimizationReport);
      if (mpoV2) {
        finalMenuItems.push(mpoV2);
      }
      if (dashboardItems.length > 1) {
        finalMenuItems.push(...dashboardItems.slice(1));
      }
      return finalMenuItems;
    }

    // Otherwise, simply return the dashboard items.
    return dashboardItems;
  }

  createAnalyzeMenuItems(
    dashboards: DisplayDashboard[],
    brandProductPath: string
  ): MenuItem[] {
    return dashboards
      .filter(
        (d) =>
          d.literalId !== NavbarConstants.ddiReportingRevenue &&
          d.literalId !== NavbarConstants.ddiReportingConversion &&
          d.literalId !== NavbarConstants.homePageId &&
          d.literalId !== NavbarConstants.benchmarksId &&
          d.literalId !== NavbarConstants.notificationCenterId &&
          !NavbarConstants.experimentIds.includes(d.literalId) &&
          d.label.match(NavbarConstants.experimentLabelRegex) == null &&
          d.productId !== NavbarConstants.mpoProductId &&
          d.status !== CurrentStatus.inactive
      )
      .flatMap((d) => {
        const items = [];

        // Handle Shopify Dashboard separately
        if (d.literalId === NavbarConstants.shopifyDashboardId) {
          items.push({
            routerLink: `/a/${brandProductPath}/shopify-dashboard`,
            label: `Shopify Dashboard ${d.status === 'DRAFT' ? ' (BETA)' : ''}`
          });
        }

        // Default menu item
        if (items.length === 0) {
          items.push({
            routerLink: `/a/${brandProductPath}/${d.routerLinkPart}`,
            label: `${d.label}${d.status === 'DRAFT' ? ' (BETA)' : ''}`
          });
        }

        return items;
      });
  }

  createOnClickHome(
    dashboards: DisplayDashboard[],
    brandProductPath: string
  ): (() => void) | undefined {
    let homePageDashboard: DisplayDashboard | undefined;
    dashboards.some((dashboard) => {
      return dashboard?.items?.some((item) => {
        if (item.literalId === NavbarConstants.homePageId) {
          homePageDashboard = item;
          return true;
        }
        return false;
      });
    });

    if (homePageDashboard) {
      return () => {
        this.router.navigate([
          `/a/${brandProductPath}/${homePageDashboard?.routerLinkPart}`
        ]);
      };
    } else {
      return undefined;
    }
  }

  /**
   * Navigates to a product-related route with given additional path segments and query parameters.
   *
   * @param additionalSegments Additional segments to append to the base URL path.
   * @param queryParams Query parameters for the navigation.
   * @param state State parameters for the navigation.
   * @param newTab Whether to open the navigation in a new tab. Defaults to false.
   */
  navigateToProduct(
    additionalSegments: string[],
    queryParams: Record<string, any>,
    state: Record<string, any>,
    newTab = false
  ): void {
    const brandSlug = this.selectionService.buildSelectionSlug();
    const baseSegments = ['a', brandSlug, 'products'];
    const fullSegments = baseSegments.concat(compact(additionalSegments));
    const url = this.router
      .createUrlTree(fullSegments, { queryParams })
      .toString();

    if (newTab) {
      window.open(url, '_blank');
    } else {
      this.router
        .navigate(fullSegments, { queryParams, state })
        .catch((err) => {
          console.error('Navigation error:', err);
        });
    }
  }

  /**
   * Maps a type to a specific navigation route and parameters.
   *
   * @param type The type of navigation.
   * @param params Parameters for the navigation.
   * @param newTab Whether to open the navigation in a new tab. Defaults to false.
   */
  navigationMapping(
    type:
      | 'exploreChannel'
      | 'spendAllocation'
      | 'mpo'
      | 'benchmarksDrillDown'
      | 'benchmarks'
      | 'geo'
      | 'optimization-report',
    params: { filters: Filter[]; extraParams?: Record<string, any> },
    newTab = false
  ) {
    let productId = '';
    let dashboardId = '';
    let queryParams = {};
    const stateObj: IRoutedFilterFromHomePage = {
      from: 'home-page',
      filters: []
    };

    switch (type) {
      case 'exploreChannel': {
        productId = 'portfolio';
        dashboardId = 'cross-channel';
        stateObj.filters = this.prepareXCDState(params);
        break;
      }
      case 'spendAllocation': {
        productId = 'portfolio';
        dashboardId = 'cross-channel';
        stateObj.filters = this.prepareXCDState(params);
        break;
      }
      case 'mpo': {
        productId = 'media-plan-optimizer';
        dashboardId = 'portfolio-level';
        const paramObj = this.prepareMpoQueryParamAndState(params);
        queryParams = paramObj.queryParams;
        stateObj.filters = paramObj.state as {
          literalId: string;
          value: FilterValue;
        }[];
        break;
      }
      case 'benchmarksDrillDown': {
        productId = 'measured-benchmarks';
        dashboardId = 'benchmarks-drill-down';
        queryParams = params.extraParams?.queryParams || {};
        break;
      }
      case 'benchmarks': {
        productId = 'measured-benchmarks';
        dashboardId = 'measured-benchmarks';
        queryParams = params.extraParams?.queryParams || {};
        break;
      }
      case 'geo': {
        productId = 'geo-designer';
        dashboardId = 'home';
        stateObj.conversionTypeId = params.extraParams?.conversionTypeId;
        queryParams = params.extraParams?.queryParams || {};
        break;
      }
      case 'optimization-report': {
        dashboardId = 'optimization-report';
        queryParams = params.extraParams?.queryParams || {};
        break;
      }
      default:
        break;
    }

    this.navigateToProduct(
      [productId, dashboardId],
      queryParams,
      stateObj,
      newTab
    );
  }

  private prepareCommonNavigationParams(
    filters: Filter[]
  ): Record<string, any> {
    return {
      compare: true,
      day: [
        filters.find((f) => f.literalId === dateStartLiteralId)
          ?.value as string,
        filters.find((f) => f.literalId === dateStopLiteralId)?.value as string
      ],
      [relativeDayLiteralId as string]: filters.find(
        (f) => f.literalId === relativeDayLiteralId
      )?.value as string,
      [conversionTypeLiteralId as string]: filters.find(
        (f) => f.literalId === conversionTypeLiteralId
      )?.value as string,
      [compareDateLiteralId as string]: [
        filters.find((f) => f.literalId === previousDateStartLiteralId)
          ?.value as string,
        filters.find((f) => f.literalId === previousDateStopLiteralId)
          ?.value as string
      ]
    };
  }

  private transformNavigationStateObj(navigationDataObj: Record<string, any>) {
    return Object.keys(navigationDataObj).map((key) => {
      return {
        literalId: key,
        value: navigationDataObj[key]
      };
    });
  }

  private prepareXCDState(params: {
    filters: Filter[];
    extraParams?: Record<string, any>;
  }) {
    const { filters, extraParams } = params;
    const navigationDataObj: Record<string, any> = {
      ...this.prepareCommonNavigationParams(filters),
      channel: extraParams && extraParams.channel ? extraParams.channel : []
    };
    return this.transformNavigationStateObj(navigationDataObj);
  }

  private prepareMpoQueryParamAndState(params: {
    filters: Filter[];
    extraParams?: Record<string, any>;
  }) {
    const { filters, extraParams } = params;
    return {
      queryParams: {
        isMultiView: true
      },
      state: [
        {
          literalId: 'optimizeStrengthFilter',
          value:
            extraParams && extraParams.optimizeStrengthFilterValue
              ? extraParams.optimizeStrengthFilterValue
              : 1
        },
        {
          literalId: relativeDayLiteralId,
          value: filters.find((f) => f.literalId === relativeDayLiteralId)
            ?.value as string
        },
        {
          literalId: conversionTypeLiteralId,
          value: filters.find((f) => f.literalId === conversionTypeLiteralId)
            ?.value as string
        }
      ]
    };
  }
}
