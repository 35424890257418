import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'portal-accuracy-alert',
  templateUrl: './accuracy-alert.component.html',
  styleUrls: ['./accuracy-alert.component.scss']
})
export class AccuracyAlertComponent {
  @ViewChild('messageDiv', { static: false }) public messageDiv!: ElementRef;

  @Input() type: 'info' | 'warning' = 'info';
  @Input() metric = '';
  @Input() message = '';
}
