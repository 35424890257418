<ng-container *ngIf="!isRedirecting">
  <nav class="navbar bg-dark mb-1">
    <div class="container-fluid">
      <img
        src="assets/images/measured-logo-text-white-adjusted.svg"
        alt="Measured" />
    </div>
  </nav>
  <div class="container-fluid">
    <div class="row align-items-center justify-content-center center-content">
      <div class="col-12">
        <div class="img-container mx-auto">
          <img
            src="assets/images/measured-m.svg"
            alt="Measured"
            class="company-logo d-flex mx-auto pb-2 img-fluid" />
        </div>
        <div
          class="login-container d-flex align-items-center justify-content-center flex-column mx-auto">
          @if (!submitting) {
            <button
              type="submit"
              name="login"
              class="login-button btn btn-primary rounded-0"
              (click)="authService.signIn()">
              Login
            </button>
          } @else {
            <button
              type="submit"
              name="login"
              disabled
              class="login-button btn btn-primary rounded-0">
              Loading...
            </button>
          }
        </div>
      </div>
    </div>
    <portal-footer isLogin="true"></portal-footer>
  </div>
  <div id="background-component"></div>
  <p-toast position="top-right"></p-toast>

  <m-modal
    class="login-modal"
    [(visible)]="isMobileModalVisible"
    [showHeader]="false"
    [transitionOptions]="'0ms'"
    [isMaximized]="true"
    [style]="{ border: '0' }">
    <div class="px-6 py-4 sm:px-6 sm:py-32 lg:px-8">
      <div class="mx-auto max-w-3xl text-center">
        <div class="grid mobile-landscape:grid-cols-2 items-center gap-8">
          <div class="mobile-landscape:text-left">
            <img
              src="assets/images/measured-logo.svg"
              alt="logo"
              class="company-logo block mx-auto sm:px-0 md:px-4 sm:pb-4 md:pb-8 img-fluid max-w-md mobile-landscape:px-0 mobile-landscape:pb-6" />

            <h1 class="h4 mobile-landscape:h4 sm:h1 text-gray-1000">
              Desktop View Only
            </h1>
            <img
              src="assets/images/pair-programming.svg"
              alt="logo"
              class="company-logo block mx-auto py-9 img-fluid mobile-landscape:hidden" />
            <h3 class="sh1 sm:h3 mobile-landscape:sh1 text-gray-1000 pb-6">
              We&rsquo;ve noticed you&rsquo;re on a mobile device. For now, our
              portal can only be viewed in the desktop format.
            </h3>
            <div class="mobile-landscape:w-auto sm:w-80 block mx-auto">
              <m-button
                [type]="'primary'"
                (click)="closeMobileModal()"
                styleClass="md:w-full mobile-landscape:w-auto">
                <div class="b2 md:w-full mobile-landscape:w-auto">Continue</div>
              </m-button>
            </div>
            <div
              class="pt-12 flex justify-center mobile-landscape:justify-start">
              <m-checkbox
                (onChange)="onCheckboxChange()"
                [isChecked]="isDontShowAgainEnabled"
                [binary]="true"
                inputId="mobile-modal">
                <label
                  for="mobile-modal"
                  class="sh1 sm:h3 mobile-landscape:sh1 text-gray-1000 mb-0"
                  >Don&rsquo;t show this again
                </label>
              </m-checkbox>
            </div>
          </div>
          <div class="hidden mobile-landscape:block">
            <img
              src="assets/images/pair-programming.svg"
              alt="logo"
              class="company-logo d-block mx-auto py-9 img-fluid" />
          </div>
        </div>
      </div>
    </div>
  </m-modal>
</ng-container>
