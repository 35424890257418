import { Injectable } from '@angular/core';
import { cloneDeep, find } from 'lodash-es';
import {
  AssociatedFilter,
  AssociatedFilterValue,
  Filter
} from '@portal/app/shared/types';

@Injectable({
  providedIn: 'root'
})
export class AssociatedFilterService {
  public generateFilterModel(
    literalId: string,
    assocFilter: AssociatedFilterValue,
    filterSet: Filter[]
  ) {
    let filter = this.getFilter(literalId, filterSet);
    if (filter) {
      filter = cloneDeep(filter);
      const value = filter.value?.toString();
      assocFilter[literalId] = filter.value;
      if (
        value &&
        filter.associatedFilters &&
        filter.associatedFilters[value] &&
        (filter.associatedFilters[value] as AssociatedFilter[])[0]
      ) {
        this.generateFilterModel(
          (
            (
              filter.associatedFilters[value] as AssociatedFilter[]
            )[0] as AssociatedFilter
          ).associatedFilterLiteralId,
          assocFilter,
          filterSet
        );
      }
    }
  }

  public getFilter(literalId: string, filters: Filter[]): Filter | undefined {
    return find(filters, (d) => d.literalId === literalId);
  }

  public getAssociatedFilterLiteralId(
    literalId: string,
    filters: Filter[]
  ): string | undefined {
    const filter = this.getFilter(literalId, filters);
    const associatedFilter = this.getAssociatedFilter(filter, filters);
    return associatedFilter && associatedFilter.literalId !== literalId
      ? associatedFilter.literalId
      : undefined;
  }

  private getAssociatedFilter = (
    currentFilter: Filter | undefined,
    filters: Filter[]
  ): Filter | undefined => {
    if (currentFilter && currentFilter.associatedFilters) {
      const specificAssociatedFilter =
        currentFilter.associatedFilters[currentFilter.value as string];
      // TODO: Add support to setup more than one associated filter
      if (specificAssociatedFilter) {
        const nextFilter = this.getFilter(
          (specificAssociatedFilter[0] as AssociatedFilter)
            .associatedFilterLiteralId,
          filters
        );
        return this.getAssociatedFilter(nextFilter, filters);
      }
    }
    return currentFilter;
  };

  public getFilterLabel(literalId: string, filterSet: Filter[]): string {
    const filterByKey = this.getFilter(literalId, filterSet);
    return filterByKey ? filterByKey.label : '';
  }
}
