import { Component, ElementRef, AfterViewInit, Input } from '@angular/core';
import anime from 'animejs/lib/anime.es.js';

/**
 * Component for creating a fade-in-up animation.
 */
@Component({
  selector: 'm-fade-in-up',
  templateUrl: './m-fade-in-up.component.html',
  styleUrls: ['./m-fade-in-up.component.scss']
})
export class MFadeInUpComponent implements AfterViewInit {
  // Delay before the animation starts (ms).
  @Input() delay = 0;

  // Duration of the animation (ms).
  @Input() duration = 1000;

  // Distance to translate along the Y axis (px).
  @Input() translateY = 20;

  // Easing function for the animation.
  @Input() easing = 'easeOutQuad';

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    anime({
      targets: this.el.nativeElement.querySelector('.m-fade-in-up'),
      opacity: [0, 1],
      translateY: [this.translateY, 0],
      duration: this.duration,
      easing: this.easing,
      delay: this.delay
    });
  }
}
