import { Inject, Injectable } from '@angular/core';
import {
  ApiService,
  IAngularEnvironment,
  SSOProvider,
  User,
  UserRole,
  UserType
} from '@libs/apis';
import { UserModel } from '../model/userModel';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { AuthState, IDToken, OktaAuth } from '@okta/okta-auth-js';
import { filter, map } from 'rxjs/operators';

const ID_TOKEN = 'id_token';
const USER = 'user';

@Injectable({
  providedIn: 'root'
})
export class CommonAuthService {
  static unknownBrand = 'Unknown';
  public user: User | null = null;
  public readonly idToken$: Observable<string>;

  constructor(
    private readonly oktaStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private readonly oktaAuth: OktaAuth,
    @Inject('environment') private environment: IAngularEnvironment,
    private readonly apiService: ApiService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {
    this.apiService.apiV1 = environment.apiV1;
    this.apiService.apiV2 = environment.apiV2;
    if (this.environment.ssoProvider === SSOProvider.okta) {
      this.idToken$ = this.oktaStateService.authState$.pipe(
        filter((s: AuthState) => !!s && !!s.isAuthenticated && !!s.idToken),
        map((s: AuthState) => (s.idToken as IDToken).idToken)
      );
    } else {
      // AWS SSO Pathway
      this.idToken$ = this.route.fragment.pipe(
        filter(
          (fragment) =>
            (fragment != null && fragment.includes(ID_TOKEN)) ||
            !!localStorage.getItem(ID_TOKEN)
        ),
        map((fragment) => this.awsIdToken(fragment))
      );
    }
  }

  public loginUrl(): string {
    const { issuer, clientId, redirectUri } = this.environment.awsConfig;
    return `${issuer}/oauth2/authorize?identity_provider=AWSSSO&redirect_uri=${redirectUri}&response_type=TOKEN&client_id=${clientId}&scope=email openid profile offline_access`;
  }

  public async signIn(): Promise<void> {
    await this.oktaAuth.signInWithRedirect({
      scopes: ['openid', 'profile', 'email', 'offline_access']
    });
  }

  public async signOut(): Promise<void> {
    await this.oktaAuth.signOut({ clearTokensBeforeRedirect: true });
  }

  public getUserDetails(): Promise<User> {
    return new Promise((resolve, reject) => {
      this.apiService.platformApisOperations.getUser().then(
        (user) => {
          this.setUserInLocalStorage(user);
          return resolve(user);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  // public login

  private setUserInLocalStorage(user: User): void {
    localStorage.setItem(USER, JSON.stringify(user));
  }

  public getUser(): UserModel | null {
    const user = this.getUserFromLocalStorage();
    return user ? new UserModel(user) : null;
  }

  private getUserFromLocalStorage(): User | null {
    const user = localStorage.getItem(USER);
    return user === null ? null : JSON.parse(user);
  }

  public setUser(user: User): void {
    this.user = user;
  }

  public canEdit(): boolean {
    return this.isSuperUser(this.user);
  }

  private isSuperUser(user: User | null): boolean {
    if (user === null) {
      return false;
    }
    return this.isMeasuredUser(user);
  }

  private isMeasuredUser(user: User): boolean {
    return (
      this.isSuperuserOrDevOrSolutionsUser(user) || this.isInternalUser(user)
    );
  }

  private isSuperuserOrDevOrSolutionsUser(user: User): boolean {
    return (
      this.getRole(user) === UserRole.superUser ||
      this.getRole(user) === UserRole.measuredDev ||
      this.getRole(user) === UserRole.measuredSol
    );
  }

  private getRole(user: User): string {
    return user.role;
  }

  private isInternalUser(user: User): boolean {
    return UserType.internal === user.userType;
  }

  private awsIdToken(urlFragment: string | null): string {
    if (urlFragment && urlFragment.includes(ID_TOKEN)) {
      const params: string[] = urlFragment
        .toString()
        .split('&')
        .filter((e) => e.includes(ID_TOKEN));
      if (params[0] != null) {
        const value = params[0].split('=')[1];
        if (value != null) {
          return value;
        }
      }
    }
    return '';
  }

  // Standard logout method. Removes id_token & user from localstorage.
  // Navigates to /login on signout
  public logout(options = {}): void {
    localStorage.removeItem(ID_TOKEN);
    localStorage.removeItem(USER);
    this.user = null;

    if (this.environment.ssoProvider === SSOProvider.okta) {
      // Okta will automatically redirect to the postLogoutRedirectUri defined in app settings or fall back to window.location.origin
      // https://developer.okta.com/docs/guides/sign-users-out/react/main/#define-the-sign-out-callback
      this.signOut().catch((e) => throwError(() => new Error(e)));
    } else {
      this.router.navigate(['/login'], options).catch(console.error);
    }
  }
}
