import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { IFilterData } from '@design-system/components/m-text-field';

@Component({
  selector: 'lib-taxonomy-campaign-view-column-header',
  templateUrl: './campaign-view-column-header.component.html',
  styleUrls: ['./campaign-view-column-header.component.scss']
})
export class CampaignViewColumnHeaderComponent implements OnChanges {
  @Input() header = '';
  @Input() headerStyleClass = '';
  @Input() headerStyle: Record<string, string> = {};
  @Input() sort: { sortBy: string; sortOrder: string } | null = null;
  @Input() filterData: IFilterData = {
    contain: [],
    exclude: []
  };

  @Output() searchValueChange = new EventEmitter<IFilterData>();
  @Output() sortChange = new EventEmitter<{
    sortBy: string;
    sortOrder: string;
  }>();

  showSearchBox = false;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['filterData']) {
      this.filterData = changes['filterData'].currentValue;
    }
    this.cdr.detectChanges();
  }

  toggleSearchBox() {
    this.showSearchBox = !this.showSearchBox;
  }

  publishSearchValue(filterData: IFilterData) {
    this.showSearchBox =
      filterData.contain.length > 0 || filterData.exclude.length > 0;
    this.searchValueChange.emit(filterData);
  }

  public setSort(sortBy: string) {
    let sortOrder = this.sort?.sortOrder;
    if (this.sort?.sortOrder === 'desc') {
      sortOrder = 'asc';
    } else {
      sortOrder = 'desc';
    }
    this.sort = { sortBy, sortOrder };
    this.sortChange.emit(this.sort);
  }
}
