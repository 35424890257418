import { computed, inject, Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { toSignal } from '@angular/core/rxjs-interop';
import { catchError, filter, map, Observable, of, switchMap, tap } from 'rxjs';
import {
  TacticLevelOptimizationInsightResponseDTO,
  TacticLevelOptimizationInsightRequestDTO,
  ContextModalCard
} from '@portal/app/dashboard/context-modal/context-model.types';
import { ModalService } from '@portal/app/dashboard/context-modal/services/modal.service';
import { isEmpty } from 'lodash-es';
import { environment } from '@portal/environments/environment';
import { ViewByBrandService } from '@portal/app/dashboard/context-modal/services/view-by-brand.service';

export interface TacticLevelOptimizationInsightResponse {
  response: TacticLevelOptimizationInsightResponseDTO;
  card: ContextModalCard;
}

@Injectable({
  providedIn: 'root'
})
export class TacticOptimizationsService {
  private readonly http: HttpClient = inject(HttpClient);
  private readonly modalService: ModalService = inject(ModalService);
  private readonly viewByBrandService: ViewByBrandService =
    inject(ViewByBrandService);

  public static baseUriV1 = `${environment.apiDomain}/api/v1/bff/optimization-report/context-menu`;

  private initialValue = {
    response: {} as TacticLevelOptimizationInsightResponseDTO,
    card: {} as ContextModalCard,
    isSame: false
  } as TacticLevelOptimizationInsightResponse;

  public isLoadingSignal = signal<boolean>(false);
  public data = toSignal(this.fetchData$(), {
    initialValue: this.initialValue
  });

  response = computed(() => this.data().response);
  card = computed(() => this.data().card);
  isDataAvailable = computed(() => !isEmpty(this.response()));
  isSame = computed(
    () =>
      !this.data().response?.isTacticOptimizedCapped &&
      this.data().response?.currentBudgetAllocation ===
        this.data().response?.recommendedBudgetAllocation
  );

  get isLoading() {
    return this.isLoadingSignal.asReadonly();
  }

  emptyStateCard = computed(() => {
    return {
      title: 'Optimization',
      content: `Last month, brands who optimized to incrementality generated a gain of <span class="b2 text-gray-1000">10% to their incremental ${this.viewByBrandService.data().viewBy}</span> on average.`,
      buttonText: 'View details'
    };
  });

  getParams() {
    const { channel, tactic } = this.modalService.modalParams();
    const { dateStart, dateStop } = this.modalService.getDateParams();
    return {
      ...this.modalService.getParams(),
      channel,
      tactic,
      filter: {
        dateStart,
        dateStop,
        conversion_type: this.modalService.conversionType()
      }
    } as TacticLevelOptimizationInsightRequestDTO;
  }

  fetchData$(): Observable<TacticLevelOptimizationInsightResponse> {
    const url = `${TacticOptimizationsService.baseUriV1}/tactic-level-optimization-insight`;

    return this.modalService.modalParams$.pipe(
      filter(() => !!this.modalService.modalParams().tactic),
      tap(() => {
        this.isLoadingSignal.set(true);
      }),
      switchMap(() =>
        this.http
          .post<TacticLevelOptimizationInsightResponseDTO>(
            url,
            this.getParams()
          )
          .pipe(
            catchError((error) => {
              console.error(
                'Error fetching tactic optimization insight',
                error
              );
              this.isLoadingSignal.set(false);
              return of({} as TacticLevelOptimizationInsightResponseDTO);
            })
          )
      ),
      tap(() => this.isLoadingSignal.set(false)),
      map((response) => {
        if (response?.difference === '∞%') {
          return {
            response: {} as TacticLevelOptimizationInsightResponseDTO,
            card: {} as ContextModalCard
          };
        }
        return {
          response,
          card: this.getCard(response)
        };
      })
    );
  }

  getCard(response: TacticLevelOptimizationInsightResponseDTO) {
    let content = '';
    if (response?.isTacticOptimizedCapped) {
      content = `No budget changes are recommended based on this tactic's Media Plan Optimizer settings`;
    } else if (
      response?.currentBudgetAllocation ===
      response?.recommendedBudgetAllocation
    ) {
      content = 'No changes to budget allocation needed!';
    } else {
      content = `${response?.isBetter ? 'Increase' : 'Decrease'} budget allocation from ${response?.currentBudgetAllocation} to ${response?.recommendedBudgetAllocation}`;
    }

    let description = '';
    if (response.difference === '0%') {
      description = `Keep adjusting this tactic! Its ${this.viewByBrandService.data().viewBy} is <span class="b2 text-gray-1000">close to</span> your portfolio average, so your current spending is at its most efficient.`;
    } else {
      description = `Keep adjusting this tactic! Its ${this.viewByBrandService.data().viewBy} is <span class="b2 text-gray-1000">${response?.difference} ${response?.isBetter ? 'higher' : 'lower'}</span> than the portfolio average for your tactics which can be optimized.`;
    }
    return {
      title: 'How to Keep Improving',
      description,
      content,
      buttonText: 'Optimize now'
    };
  }
}
