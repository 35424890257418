import { Pipe, PipeTransform } from '@angular/core';
import { FormatterService } from '@portal/app/shared/services/formatter.service';
import { FieldDefinition } from '@portal/app/shared/types';

@Pipe({ name: 'formatValue' })
export class FormatValuePipe implements PipeTransform {
  constructor(private formatterService: FormatterService) {}

  transform(value: string | number, field: FieldDefinition): string {
    return this.formatterService.formatPartialValue(field, value);
  }
}
