import { computed, inject, Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { toSignal } from '@angular/core/rxjs-interop';
import { catchError, map, Observable, of, switchMap, tap } from 'rxjs';
import {
  AllMediaResponseDTO,
  AllMediaRequestDTO,
  AllMediaDataObjectDTO
} from '@portal/app/dashboard/context-modal/context-model.types';
import { ModalService } from '@portal/app/dashboard/context-modal/services/modal.service';

import { FieldDefinition, FieldDefinitions } from '@portal/app/shared/types';
import { DomSanitizer } from '@angular/platform-browser';
import { LinkService } from '@portal/app/dashboard/context-modal/services/link.service';
import {
  TableColumn,
  TableFooter,
  TableValue
} from '@design-system/pages/optimization-report/m-table';

export interface AllMediaResponseTable {
  columns: TableColumn[];
  values: TableValue[];
  footer: TableFooter[];
}

export interface AllMediaResponse {
  response: AllMediaResponseDTO;
  table: AllMediaResponseTable;
}

@Injectable()
export class MediaService {
  private readonly http: HttpClient = inject(HttpClient);
  private readonly modalService: ModalService = inject(ModalService);
  private readonly linkService = inject(LinkService);
  private readonly sanitized = inject(DomSanitizer);

  private initialValue: AllMediaResponse = {
    response: {
      data: [] as Record<string, AllMediaDataObjectDTO>[],
      fieldDefinition: {} as FieldDefinitions,
      totalRow: {} as Record<string, string>
    },
    table: {
      columns: [],
      values: [],
      footer: []
    }
  };

  private isLoadingSignal = signal<boolean>(false);
  public data = toSignal(this.fetchData$(), {
    initialValue: this.initialValue
  });

  public response = computed(() => this.data().response);
  public table = computed(() => this.data().table);
  public isDataAvailable = computed(
    () => this.data().response?.data?.length > 0
  );

  get isLoading() {
    return this.isLoadingSignal.asReadonly();
  }

  getParams() {
    const modalParams = this.modalService.modalParams();
    const { dateStart, dateStop } = this.modalService.getDateParams();

    const metrics = this.linkService.selectedMetrics();

    return {
      ...this.modalService.getParams(),
      dimension: modalParams.tactic ? 'campaign' : 'tactic',
      metrics,
      filter: {
        dateStart,
        dateStop,
        channel: modalParams.channel,
        tactic: modalParams.tactic ? modalParams.tactic : undefined,
        conversion_type: this.modalService.conversionType()
      }
    } as AllMediaRequestDTO;
  }

  fetchData$(): Observable<AllMediaResponse> {
    const url = `${ModalService.baseUriV1}/all-media`;

    return this.modalService.modalParams$.pipe(
      tap(() => this.isLoadingSignal.set(true)),
      switchMap(() =>
        this.http.post<AllMediaResponseDTO>(url, this.getParams()).pipe(
          catchError((error) => {
            console.error('Error fetching summary-metric data', error);
            this.isLoadingSignal.set(false);
            return of({} as AllMediaResponseDTO);
          }),
          map((response: AllMediaResponseDTO) => {
            return {
              response,
              table: this.getTableData(response)
            } as AllMediaResponse;
          })
        )
      ),
      tap(() => {
        this.isLoadingSignal.set(false);
      })
    );
  }

  getTableData(response: AllMediaResponseDTO) {
    const columns = this.getTableColumns(response);
    const values: TableValue[] = this.getTableValues(response);
    const footer =
      values && values.length
        ? this.getTableFooter(response, columns)
        : ([] as TableFooter[]);
    return {
      columns,
      values,
      footer
    };
  }

  getTableColumns(response: AllMediaResponseDTO) {
    const metrics = this.linkService.selectedMetrics();
    const columns = metrics
      .filter(
        (metric) =>
          response?.fieldDefinition && response?.fieldDefinition[metric]
      )
      .map((metric) => {
        const field = response?.fieldDefinition[metric];
        return {
          header: field?.label,
          field: metric,
          sortField: `${metric}Raw`,
          class: '',
          sortable: true
        } as TableColumn;
      });

    if (this.modalService.modalType() === 'channel') {
      const tactic = this.createTableColumn(response?.fieldDefinition?.tactic);
      if (tactic) {
        columns.unshift(tactic);
      }
    } else if (this.modalService.modalType() === 'tactic') {
      const campaign = this.createTableColumn(
        response?.fieldDefinition?.campaign
      );
      if (campaign) {
        columns.unshift(campaign);
      }
    }
    return columns;
  }

  createTableColumn(field?: FieldDefinition) {
    if (!field) {
      return null;
    }
    return {
      header: field.label,
      field: field.literalId,
      sortField: `${field.literalId}Raw`,
      class: '',
      sortable: true
    } as TableColumn;
  }

  getTableValues(response: AllMediaResponseDTO) {
    const isChannel = this.modalService.modalType() === 'channel';
    const { channel } = this.modalService.modalParams();

    return response?.data?.map((data) => {
      const value: TableValue = {};
      for (const key in data) {
        if (isChannel && key === 'tactic') {
          value[key] = this.sanitized.bypassSecurityTrustHtml(
            `<a class="context-modal-link b2 cursor-pointer text-blue-600" data-row='${JSON.stringify({ channel, tactic: data[key]?.value })}'>${data[key]?.formattedValue}</a>`
          );
        } else {
          value[key] = data[key]?.formattedValue;
        }
        value[`${key}Raw`] = data[key]?.value;
      }
      return value;
    });
  }

  getTableFooter(response: AllMediaResponseDTO, columns: TableColumn[]) {
    return columns.map((col) => {
      if (col.field.includes('tactic') || col.field.includes('campaign')) {
        return { header: 'Total' } as TableFooter;
      }
      let header = response?.totalRow[col.field];
      if (!header) {
        header = '-';
      }
      return {
        header
      } as TableFooter;
    }, {});
  }
}
