import { DataGridService } from '@portal/app/datagrid/services/data-grid.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ContextField,
  DataEndpoint,
  DataGridExportConfig,
  DateObject,
  DateRange,
  DateRangeData,
  ExportTableType,
  Filter,
  FilterElement,
  FilterOption,
  FilterValue,
  IFilterConfig,
  IFilterMappingAtChannel,
  IFilterMappingAtConversionType,
  LayoutTypes,
  ProductDashboardPersistenceMode
} from '@portal/app/shared/types';
import { ExportPdfService } from '@portal/app/shared/services/export-pdf.service';
import { ContextStore } from '@portal/app/shared/state/context.store';
import {
  cloneDeep,
  compact,
  find,
  intersection,
  isArray,
  isEmpty,
  keys,
  lowerCase,
  sortBy
} from 'lodash-es';
import { Observable, Subscription, take } from 'rxjs';
import { MultiSelect } from 'primeng/multiselect';
import { NativeSectionsService } from '@portal/app/shared/services/native-sections.service';
import {
  custom,
  DateTimeService,
  dayFilterLiteralIds,
  DayJsDateFormat
} from '@portal/app/shared/services/date-time.service';
import { ViewStore } from '@portal/app/shared/state/view.store';
import { CompareService } from '@portal/app/shared/services/compare.service';
import { ApiService } from '@portal/app/shared/services/api.service';
import { FiltersService } from '@portal/app/shared/services/filters.service';
import { MenuItem } from 'primeng/api';
import { AppState, DashboardState } from '@portal/app/store/app.state';
import dayjs, { Dayjs } from 'dayjs';
import {
  isMeasuredBenchmark,
  isMeasuredBenchmarkLandingPage
} from '@portal/app/dashboard/utils';
import {
  benchmarksTabMapping,
  filterConstants,
  monthDateYearFormat
} from '@portal/app/shared/constants/constants';
import { FilterSetUtil } from '@portal/app/dashboard/filter-set/filter-set-util';
import { ActivatedRoute } from '@angular/router';
import { selectStore } from '@portal/app/store/app.selectors';
import { Store } from '@ngrx/store';
import {
  IPopupTableColumnConfig,
  IPopupTableDataConfig
} from '@portal/app/shared/models/IPopup';
import { customRangeValue } from '@libs/date-range-picker';
import { FilterIds } from '@portal/app/shared/constants';

@Component({
  selector: 'portal-filter-set',
  templateUrl: './filter-set.component.html',
  styleUrls: ['./filter-set.component.scss']
})
export class FilterSetComponent implements OnInit, OnDestroy {
  @Input() elements: FilterElement[] = [];
  public layoutTypes = LayoutTypes;
  public layout: ContextField[][] = [];
  public filterConfig: IFilterConfig = {
    dynamicFilters: null,
    shouldPersistFilters: false,
    dashboardLiteralId: ''
  };

  public compareFilter: Filter | undefined = undefined;
  public compareDate?: string[] | null;
  public exportMenu: MenuItem[] = [];
  public relativeDate: Filter | null = null;
  public showResetSelection = false;

  public minDate!: dayjs.Dayjs | undefined;
  public maxDate?: dayjs.Dayjs;

  public isMeasuredBenchmark = isMeasuredBenchmark;
  public setAsDateRange = FilterSetUtil.setAsDateRange;

  private cache: Record<
    string,
    { actualValue: FilterValue; temporaryValue: FilterValue }
  > = {};

  private filters?: Filter[];
  filterContext$: Observable<Filter[]> = this.contextStore.filterContext;
  private readonly subscriptions: Subscription = new Subscription();

  private previousSelectedValues: Record<string, string[]> = {};

  private dashboardState: DashboardState | undefined;

  public isFilterPopupVisible = false;
  public filterPopupDialogTitle = '';
  public filterPopupTableData: IPopupTableDataConfig[] = [];
  public filterPopupTableColumns: IPopupTableColumnConfig[] = [];
  public monthDateYearFormat = monthDateYearFormat;
  public isFiltersUpdating = false;

  constructor(
    private readonly contextStore: ContextStore,
    public readonly dateService: DateTimeService,
    public readonly nativeSectionsService: NativeSectionsService,
    public readonly dataGridService: DataGridService,
    public readonly viewStore: ViewStore,
    public readonly compareService: CompareService,
    public readonly apiService: ApiService,
    private readonly filtersService: FiltersService,
    private route: ActivatedRoute,
    private readonly exportPdfService: ExportPdfService,
    private readonly store: Store<AppState>
  ) {}

  ngOnInit(): void {
    const dashboardStateAndFilterConfig =
      this.filtersService.getDashboardStateAndRelativeFilterConfig();
    this.dashboardState = dashboardStateAndFilterConfig.dashboardState;
    this.minDate = this.nativeSectionsService.getMinDate(
      this.dashboardState?.reportingRangeInMonths
    );
    this.filterConfig = dashboardStateAndFilterConfig.filterConfig;
    const sub = this.filterContext$.subscribe({
      next: (fil) => {
        const filters = cloneDeep(fil);
        if (!isEmpty(filters)) {
          this.setCompareDates(filters);
          this.setFilterLayoutAsContextField(filters);
          this.dashboardSpecificSetup();
        }
        this.filterSelectionOverSearchHandler();
        this.filters = this.nativeSectionsService.setFilterDefaults(filters);
        this.setupCompareState();
        this.setupRelativeDayState();
        this.setupDriverLayoutType();
        this.checkIfAllFiltersAreSelected();
      }
    });
    this.subscriptions.add(sub);
  }

  setCompareDates(filters: Filter[]): void {
    this.compareFilter = filters.find((el) => el.literalId === 'compare_date');
    this.compareDate = this.compareFilter
      ? (this.compareFilter.value as string[])
      : null;
  }

  setFilterLayoutAsContextField(filters: Filter[]) {
    const firstSet = (
      this.elements.filter(
        (i) => i.displayOrder <= filterConstants.positionDivisor
      ) || []
    ).sort(FilterSetUtil.sortByDisplayOrder);
    const secondSet = (
      this.elements.filter(
        (i) => i.displayOrder > filterConstants.positionDivisor
      ) || []
    ).sort(FilterSetUtil.sortByDisplayOrder);
    // Todo finish handling the layout for grouped context fields...
    this.layout = compact(
      [firstSet, secondSet].map((items) => {
        if (items && items.length) {
          return items.map((item: FilterElement) =>
            FilterSetUtil.mapFilterField(
              filters,
              item.literalId,
              item.displayOrder,
              item.layoutType
            )
          );
        }
        return undefined;
      })
    );
  }

  dashboardSpecificSetup(): void {
    if (this.filterConfig.dashboardLiteralId === 'fb-experiment-result') {
      //TODO: @Vijay This can be achieved through the filter association
      this.layout[0] = (this.layout[0] as ContextField[]).filter((item) => {
        return (
          (item.layoutType === 'NUMBER_INPUT_BOX' &&
            item.name ===
              ((this.layout[0] as ContextField[])[0] as ContextField).value) ||
          item.layoutType !== 'NUMBER_INPUT_BOX'
        );
      });
    }
  }

  filterSelectionOverSearchHandler() {
    this.layout.map((items) => {
      items.map((item) => {
        if (
          item.inputType === 'multiselect' &&
          (item.options || []).length > 13 &&
          Array.isArray(item.value)
        ) {
          this.previousSelectedValues[item.name] =
            (item.options || []).length === (item.value || []).length
              ? []
              : item.value;
        }
      });
    });
  }

  setupCompareState(): void {
    const compareFilter = this.filters?.find(
      (filter) => filter.literalId === 'compare'
    );
    if (compareFilter) {
      this.compareService.compare = compareFilter.value as boolean;
    }
  }

  setupRelativeDayState() {
    const relativeDate = this.filters?.find(
      (filter) => filter.literalId === 'relative_day'
    );
    if (relativeDate) {
      this.relativeDate = relativeDate;
    }
  }

  setupDriverLayoutType() {
    const driverLayoutFilter = this.filters?.find(
      (el) => el.literalId === 'layout_feature'
    );
    if (driverLayoutFilter) {
      this.compareService.driversLayout = driverLayoutFilter.value as boolean;
    }
  }

  checkIfAllFiltersAreSelected(): void {
    if (this.filterConfig.dashboardLiteralId === 'portfolio-level') {
      this.showResetSelection = false;
      return;
    }
    const isAllMultiValueFiltersOptionsSelected: boolean[] = [];
    this.filters?.forEach((filter) => {
      if (
        filter.type === 'string[]' &&
        filter.applyTo === null &&
        filter.options &&
        isArray(filter.value) &&
        filter.options.length >= 1 &&
        !['dimensions', 'childDimensions'].includes(filter.literalId)
      ) {
        const isAllSelected =
          isEmpty(filter.value) ||
          filter.options.length === filter.value.length;
        filter.value = isAllSelected ? [] : filter.value;
        isAllMultiValueFiltersOptionsSelected.push(isAllSelected);
      }
    });
    this.showResetSelection =
      isAllMultiValueFiltersOptionsSelected.includes(false);
  }

  handleFilteredMultiSelectAllSelectionDeSelection = (
    idx: number,
    multiSelect: MultiSelect,
    filterLiteralId: string
  ): void => {
    const item = this.getFilterLayout(idx, filterLiteralId);
    // eslint-disable-next-line no-underscore-dangle
    let filteredOptions = multiSelect._filteredOptions || [];
    filteredOptions = filteredOptions
      ? filteredOptions.map(({ value }) => value)
      : [];
    if (!item || isEmpty(filteredOptions)) {
      if (item) {
        (
          this.cache[filterLiteralId] as {
            actualValue: FilterValue;
            temporaryValue: FilterValue;
          }
        ).temporaryValue = item.value;
      }
      return;
    }
    const filterValues = multiSelect.value;
    const previouslySelectedValue = this.cache[filterLiteralId]
      ?.temporaryValue as string[];
    if (filteredOptions && filteredOptions.length) {
      let updatedValue: FilterValue = [];
      if (filterValues.length === 0) {
        updatedValue = [
          ...new Set(
            previouslySelectedValue.filter(
              (o) => !(filteredOptions || []).includes(o)
            )
          )
        ];
      } else if (filteredOptions.length === filterValues.length) {
        updatedValue = [
          ...new Set(previouslySelectedValue.concat(filterValues))
        ];
      } else if (previouslySelectedValue.length > filterValues.length) {
        updatedValue = [
          ...new Set(intersection(previouslySelectedValue, filterValues))
        ];
      } else if (previouslySelectedValue.length < filterValues.length) {
        updatedValue = filterValues;
      }
      item.value = updatedValue as string[];
    }
    (
      this.cache[filterLiteralId] as {
        actualValue: FilterValue;
        temporaryValue: FilterValue;
      }
    ).temporaryValue = item.value;
  };

  cacheValue(filterLiteralId: string, value: string | string[]): void {
    this.cache[filterLiteralId] = {
      actualValue: value,
      temporaryValue: value
    };
  }

  updateDateRange(
    idx: number,
    filterLiteralId: string,
    dateRangeData: DateRangeData[]
  ) {
    let updatedFilters = compact(
      this.filters?.map((filterObj) => {
        const dateFilter = dateRangeData.find(
          (d) => d.filterLiteralId === filterObj.literalId
        );
        if (dateFilter) {
          filterObj.value =
            dateFilter.type === 'DateRange'
              ? [
                  this.getFormattedDate(
                    (dateFilter.value as Dayjs[])[0] as Dayjs
                  ),
                  this.getFormattedDate(
                    (dateFilter.value as Dayjs[])[1] as Dayjs
                  )
                ]
              : (dateFilter.value as FilterValue);
          return filterObj;
        }
      })
    );

    updatedFilters = this.updateFiltersForBenchmarksDashboard(updatedFilters);
    this.updateFilters(updatedFilters);
  }

  private updateFiltersForBenchmarksDashboard(filters: Filter[]) {
    if (isMeasuredBenchmarkLandingPage(this.filterConfig.dashboardLiteralId)) {
      const selectedTab = this.filters?.find(
        (e) => e.literalId === 'tabSectionControl'
      )?.value as string;

      const propertyName = benchmarksTabMapping[selectedTab];

      const tabSpecificRelativeDayFilter = this.filters?.find(
        (e) => e.literalId === propertyName
      );

      if (tabSpecificRelativeDayFilter) {
        tabSpecificRelativeDayFilter.value = this.filters?.find(
          (e) => e.literalId === 'relative_day'
        )?.value as string;
        filters.push(tabSpecificRelativeDayFilter);
      }
    }

    return filters;
  }

  private getFormattedDate(date: Dayjs) {
    return dayjs(date).format('YYYY-MM-DD');
  }

  updateContextFilter(
    filterIndexFromLayout: number,
    filterLiteralId: string
  ): void {
    const item = this.layout[filterIndexFromLayout]?.find(
      (i) => i.name === filterLiteralId
    );
    if (
      item &&
      this.cache[filterLiteralId] &&
      (
        this.cache[filterLiteralId] as {
          actualValue: FilterValue;
          temporaryValue: FilterValue;
        }
      ).actualValue === item.value
    ) {
      return;
    }
    const filterObjUpdatedByUser = this.filters?.find((f) => {
      return f.literalId === filterLiteralId;
    }) as Filter;

    this.checkOnSpecificFilter(filterLiteralId);
    if (item && filterObjUpdatedByUser) {
      const isAllSelected = this.isAllOptionsAreSelected(
        filterObjUpdatedByUser,
        item.options,
        item.value
      );
      filterObjUpdatedByUser.value = isAllSelected ? [] : item.value;
      const updatedParentFilters = [filterObjUpdatedByUser];
      this.updateFilters(updatedParentFilters);
    }
  }

  asStringArray(val: unknown): string[] {
    return val as string[];
  }

  asContext(val: unknown): ContextField[] {
    return val as ContextField[];
  }

  asFilterSetGroups(val: unknown): ContextField[][] {
    return val as ContextField[][];
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getExportMenu(item: ContextField) {
    this.exportMenu = [];
    const exportFormats = (
      item.options && item.options.length > 0 ? item.options : [item.value]
    ) as string[];
    exportFormats.forEach((format) => {
      this.addSupportedFormatToExportMenuItem(format);
    });
  }

  addSupportedFormatToExportMenuItem(label: string) {
    let commandFn: () => void;
    switch (label) {
      case 'CSV':
        commandFn = () => {
          this.exportData('csv');
        };
        break;
      case 'EXCEL':
        commandFn = () => {
          this.exportData('xlsx');
        };
        break;
      case 'TSV':
        commandFn = () => {
          this.exportData('tsv');
        };
        break;
      case 'PDF':
        commandFn = () => {
          this.exportToPDF();
        };
        break;
      default:
        commandFn = () => {
          console.warn('');
        };
        break;
    }
    const menuItem = {
      label,
      styleClass: 'export-item',
      command: commandFn
    };
    this.exportMenu.push(menuItem);
  }

  exportData(formatType?: ExportTableType): void {
    const fileName = this.nativeSectionsService.getDisplayNameByLiteralId(
      this.filterConfig.dashboardLiteralId
    );
    const config: DataGridExportConfig = {
      tableName: '',
      literalId: this.filterConfig.dashboardLiteralId
    };
    config.format = formatType;
    config.fileName = fileName;
    config.showFooter = false;

    if (this.filterConfig.dashboardLiteralId === 'portfolio-level') {
      config.tableName = 'ROAS';
      this.dataGridService.exportTable(config);
    }
    if (this.filterConfig.dashboardLiteralId === 'geo-experiment-result') {
      const optFilter = find(this.filters, { literalId: 'optimization' });
      if (optFilter) {
        config.tableName = `${lowerCase(
          optFilter.value as string
        )}ComparisonSection`;
        this.dataGridService.exportTable(config);
      }
    }
  }

  resetFilterSelection(): void {
    this.showResetSelection = false;
    let updatedFilters: Filter[] = [];
    if (this.filters) {
      updatedFilters = compact(
        this.filters.map((filterObj) => {
          if (filterObj.type === 'string[]' && filterObj.applyTo === null) {
            filterObj.value = [];
            return filterObj;
          }
        })
      );
    }
    if (!isEmpty(updatedFilters)) {
      this.updateFilters(updatedFilters);
    }
  }

  onKeyDown(idx: number, name: string, e?: Record<string, unknown>): void {
    if (e?.key === 'Enter') {
      // this.updateContext(idx, name, e);
    }
  }

  exportToPDF(): void {
    const fileName = this.nativeSectionsService.getDisplayNameByLiteralId(
      this.filterConfig.dashboardLiteralId
    );
    this.exportPdfService.downloadEditablePdf(
      'main-container-id',
      fileName,
      'p'
    );
  }

  getChannelName(options: FilterOption[]): string {
    const channelName = options.find((option) => option.value === '');
    return channelName ? channelName.label : '';
  }

  get showChild(): boolean {
    return (
      this.route.snapshot.queryParams.showChild &&
      this.route.snapshot.queryParams.viewId
    );
  }

  get formatRangeDate(): string {
    const day = this.filters?.find((filter) => filter.literalId === 'day');
    if (day) {
      return this.compareService.historicalDateDisplayFormat(
        day.value as string[]
      );
    }
    return '';
  }

  checkOnSpecificFilter(filterLiteralId: string) {
    // TODO: Identify the filter which is reset of entire dashboard - Talk to Davor for what is the functionality here
    if (filterLiteralId === 'conversion_type') {
      this.dataGridService.resetSelections();
    }
  }

  /**
   * @function preventNullDayFilter
   * @description HACK: This function filters out the 'day' filter if its value is [null, null].
   * This is a temporary workaround to resolve a user-facing issue where null values
   * are being added to the database, causing the page to fail to load.
   *
   * @param {Filter[]} updatedFilters - The array of filters to be processed.
   * @returns {Filter[]} - The modified array of filters with null-valued day filters removed.
   */
  private preventNullDayFilter(updatedFilters: Filter[]): Filter[] {
    return updatedFilters.filter((filter) => {
      if (
        filter.literalId === FilterIds.day &&
        Array.isArray(filter.value) &&
        filter.value.length === 2 &&
        filter.value[0] === null &&
        filter.value[1] === null
      ) {
        // Remove day filter if its value is [null, null]
        return false;
      }
      return true;
    });
  }

  /**
   * @function transformDateFilters
   * @description HACK: This function transforms date filters (for `day` and `compareDateLiteralId`)
   * by converting the `startDate` and `endDate` values into an array with the format `YYYY-MM-DD`.
   * Only transforms filters where `literalId` matches `FilterIds.day` or `FilterIds.compareDateLiteralId`.
   *
   * @param {Filter[]} updatedFilters - The array of filters to be processed.
   * @returns {Filter[]} - The modified array of filters with formatted date values.
   */
  private transformDateFilters(updatedFilters: Filter[]): Filter[] {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return updatedFilters.map((filter: any) => {
      if (
        filter?.literalId === FilterIds.day ||
        filter?.literalId === FilterIds.compareDateLiteralId
      ) {
        if (
          !Array.isArray(filter?.value) &&
          typeof filter?.value === 'object' &&
          filter?.value !== null
        ) {
          const { startDate, endDate } = filter.value;

          filter.value = [
            startDate
              ? dayjs(startDate.split('T')[0]).format('YYYY-MM-DD')
              : null,
            endDate ? dayjs(endDate.split('T')[0]).format('YYYY-MM-DD') : null
          ];
        }
      }
      return filter;
    });
  }

  private updateFilters(updatedFilters: Filter[]) {
    this.isFiltersUpdating = true;
    this.setDateRangeFilterValueProperly();
    this.store.select(selectStore).subscribe((state: AppState) => {
      if (this.dashboardState) {
        this.dashboardState.dimensionFormat = state.dashboard.dimensionFormat;
      }
    });
    if (this.filterConfig.shouldPersistFilters) {
      const filterAssociatedWithDataSetRow = updatedFilters.filter((fil) => {
        return fil.applyTo && fil.applyTo.includes('DATA_SET_ROW');
      });
      const dimensionFormat = filterAssociatedWithDataSetRow.length
        ? (this.dashboardState?.dimensionFormat as string)
        : (null as unknown as string);

      const transformedFilters = this.transformDateFilters(updatedFilters);
      const cleanedFilters = this.preventNullDayFilter(transformedFilters);

      this.apiService
        .overrideFiltersUpdated(cleanedFilters, dimensionFormat)
        .then((overriddenFilters) => {
          console.warn(overriddenFilters);
          this.callGetFilterAPI();
        })
        .catch(() => (this.isFiltersUpdating = false));
    } else {
      updatedFilters.forEach((filter) => {
        const filterObj = (this.filters || []).find(
          (f) => f.literalId === filter.literalId
        );
        if (filterObj && filterObj.type === 'string[]') {
          filterObj.value = filter.value;
        }
      });
      if (
        this.dashboardState &&
        this.dashboardState.dashboardPersistence ===
          ProductDashboardPersistenceMode.multiView &&
        this.dashboardState.dashboardLiteralId === 'portfolio-level'
      ) {
        const dayFilter = (this.filters || []).find(
          (f) => f.literalId === 'day'
        ) || { value: undefined };
        const relativeDayFilter = (this.filters || []).find(
          (f) => f.literalId === 'relative_day'
        ) || { value: undefined };
        this.callGetFilterAPI({
          dashboardLiteralId: this.dashboardState.dashboardLiteralId,
          day: dayFilter.value as string[],
          relativeDay: relativeDayFilter.value as string,
          filters: cloneDeep(this.filters) || [],
          updatedFilters
        });
      } else {
        this.contextStore.changeFilterContext(this.filters as Filter[]);
      }
    }
  }

  private isAllOptionsAreSelected(
    filter: Filter,
    options: FilterOption[] | undefined,
    value: string | string[] | DateRange | null
  ): boolean {
    if (
      filter.type === 'string[]' &&
      filter.applyTo === null &&
      options &&
      isArray(value) &&
      options.length > 1
    ) {
      return (
        isEmpty(value) ||
        (options as FilterOption[]).length === (value as FilterValue[]).length
      );
    }
    return false;
  }

  private setDateRangeFilterValueProperly(): void {
    this.filters = this.filters?.map((filterObj) => {
      if (filterObj.literalId === 'day') {
        if (
          filterObj &&
          !isArray(filterObj.value) &&
          typeof filterObj.value === 'object'
        ) {
          const val = filterObj.value as unknown as DateObject;
          filterObj.value = [
            dayjs(val.startDate).format('YYYY-MM-DD'),
            dayjs(val.endDate).format('YYYY-MM-DD')
          ];
        }
      }
      return filterObj;
    });
  }

  private getFilterLayout(idx: number, filterLiteralId: string | undefined) {
    return this.layout[idx]?.find((i) => i.name === filterLiteralId);
  }

  private callGetFilterAPI(params?: {
    dashboardLiteralId: string;
    day: string[] | undefined;
    relativeDay: string | undefined;
    filters: Filter[];
    updatedFilters: Filter[];
  }): void {
    this.subscriptions.add(
      this.apiService
        .getFilters(DataEndpoint.dataPoints, this.filters)
        .pipe(take(1))
        .subscribe({
          next: (latestFilters) => {
            this.keepFilterStateForMPODashboard(params, latestFilters);
            this.filters =
              this.nativeSectionsService.setFilterDefaults(latestFilters);
            this.updateDateBasedOnRelativeDayFilter();
            this.contextStore.changeFilterContext(this.filters);
            this.isFiltersUpdating = false;
          },
          error: (error) => {
            console.error(
              'FilterSetComponent: Error while fetching the filters',
              error
            );
            this.isFiltersUpdating = false;
          }
        })
    );
  }

  updateDateBasedOnRelativeDayFilter(): void {
    if (!this.filters) {
      return;
    }
    const relativeDayFilterValue =
      this.filters.find((f) => f.literalId === 'relative_day')?.value || custom;
    if (
      ![custom, customRangeValue].includes(relativeDayFilterValue as string)
    ) {
      const dateFilter = this.filters.find((f) =>
        dayFilterLiteralIds.includes(f.literalId)
      ) || { value: undefined };
      const relativeDateOption =
        this.dateService.getRelativeDayOptionByRelativeDay(
          relativeDayFilterValue
        );
      if (relativeDateOption && relativeDateOption.action) {
        const dateValue = relativeDateOption.action;
        dateFilter.value = [
          DateTimeService.dateString(
            dateValue.startDate as dayjs.Dayjs,
            DayJsDateFormat.fullDate
          ),
          DateTimeService.dateString(
            dateValue.endDate as dayjs.Dayjs,
            DayJsDateFormat.fullDate
          )
        ];
      }
    }
  }

  onMultiselectFooterClickHandler(filter: Filter): void {
    this.isFilterPopupVisible = true;
    this.filterPopupDialogTitle = filter.label;
    this.prepareFilterPopupTableConfigWithData(filter);
  }

  prepareFilterPopupTableConfigWithData(filter: Filter) {
    const additionalFieldColumns: IPopupTableColumnConfig[] = [];
    if (filter.additionalFields) {
      filter.additionalFields.forEach((additionalField) => {
        const additionalFieldFilter = this.filters?.find(
          (e) => `${e.literalId}` === additionalField
        );
        if (additionalFieldFilter) {
          additionalFieldColumns.push({
            columnId: additionalFieldFilter.literalId,
            label: additionalFieldFilter.label,
            dataType: 'string'
          });
        }
      });
      this.filterPopupTableColumns = [
        {
          columnId: filter.name,
          label: filter.label,
          dataType: 'string'
        },
        ...(additionalFieldColumns || [])
      ];
      this.prepareFilterPopupTableData(filter);
    }
  }

  prepareFilterPopupTableData(filter: Filter) {
    this.filterPopupTableData = [];
    const conversionTypeFilter = (this.filters || []).find(
      (f) => f.literalId === 'conversion_type'
    );

    if (
      conversionTypeFilter &&
      conversionTypeFilter.options?.length &&
      this.filterPopupTableColumns.length &&
      this.filterConfig.dynamicFilters
    ) {
      const filtersGroupedByConversionType =
        this.filterConfig.dynamicFilters.filtersGroupedByConversionType;
      conversionTypeFilter.options.forEach((conversionType) => {
        const data = (
          filtersGroupedByConversionType as Record<
            string,
            { children: Record<string, string[]> }
          >
        )[conversionType] as unknown as IFilterMappingAtChannel;
        if (data) {
          for (const channel of keys(data)) {
            const channelWithTactics = data[
              channel
            ] as IFilterMappingAtConversionType;
            channelWithTactics.tactics.forEach((tactic) => {
              this.filterPopupTableData.push({
                tactic,
                channel
              });
            });
          }
        }
      });
    }

    this.filterPopupTableData = sortBy(this.filterPopupTableData, [
      (e) => {
        return e[filter.name];
      }
    ]);
  }

  closeFilterPopup() {
    this.isFilterPopupVisible = false;
  }

  private keepFilterStateForMPODashboard(
    params:
      | {
          dashboardLiteralId: string;
          day: string[] | undefined;
          relativeDay: string | undefined;
          filters: Filter[];
          updatedFilters: Filter[];
        }
      | undefined,
    latestFilters: Filter[]
  ): void {
    const dayFilter = (latestFilters || []).find((f) => f.literalId === 'day');
    const relativeDayFilter = (latestFilters || []).find(
      (f) => f.literalId === 'relative_day'
    );
    if (params && params.dashboardLiteralId === 'portfolio-level') {
      if (dayFilter && params.day && params.day.length === 2) {
        dayFilter.value = params.day;
      }
      if (relativeDayFilter && params.relativeDay) {
        relativeDayFilter.value = params.relativeDay;
      }
      const filterUpdatedByUser = params?.updatedFilters?.map(
        ({ literalId }) => literalId
      );
      let filtersToMaintain = [
        'channel',
        'tactic',
        'campaign',
        'childCampaign',
        'showChild',
        'conversion_type',
        'viewBy',
        'planningBudget',
        'optimizeStrengthFilter',
        'optimizeForFilter',
        'childOptimizeStrengthFilter'
      ];
      if (
        filterUpdatedByUser &&
        (filterUpdatedByUser.includes('relative_day') ||
          filterUpdatedByUser.includes('day') ||
          filterUpdatedByUser.includes('conversion_type'))
      ) {
        filtersToMaintain = filtersToMaintain.filter(
          (filterLiteralId) => filterLiteralId !== 'tactic'
        );
      }
      filtersToMaintain.map((filterKey) => {
        const fObj = params.filters.find((f) => f.literalId === filterKey);
        const latestFilter = latestFilters.find(
          (f) => f.literalId === filterKey
        );
        if (fObj && latestFilter) {
          latestFilter.value = fObj.value as FilterValue;
        }
      });
    }
  }
}
