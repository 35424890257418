import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ITitle } from '@libs/common-components';

@Component({
  selector: 'lib-ui-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent implements OnInit {
  @Input() titleConfigs: ITitle[] = [];
  @Output() editableTitleChange: EventEmitter<string> =
    new EventEmitter<string>();

  @ViewChild('titleInput') titleInput!: ElementRef;

  titleEditModeRecord: Record<number, boolean> = {};

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  emitTitleChangeEvent(title: string) {
    this.editableTitleChange.emit(title);
  }

  ngOnInit() {
    if (this.titleConfigs.length) {
      this.titleConfigs
        .filter((titleConfig) => titleConfig.isEditable)
        .forEach((_, index) => {
          this.titleEditModeRecord[index] = true;
        });
    }
  }

  toggleEditModeIfApplicable(titleConfig: ITitle, titleIndex: number) {
    if (titleConfig.isEditable) {
      this.titleEditModeRecord[titleIndex] =
        !this.titleEditModeRecord[titleIndex];
      this.changeDetectorRef.detectChanges();

      if (!this.titleEditModeRecord[titleIndex]) {
        this.titleInput.nativeElement.focus();
      }
    }
  }
}
