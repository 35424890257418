import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ViewStore } from '@portal/app/shared/state/view.store';
import {
  DateTimeService,
  DayJsDateFormat
} from '@portal/app/shared/services/date-time.service';
import { Filter } from '@portal/app/shared/types';
import { environment } from '@portal/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DatasetBaseService<T> {
  public static measuredUriV2 = `${environment.apiDomain}/api/v2/`;

  private dataSubject = new BehaviorSubject<T[]>([]);
  data$ = this.dataSubject.asObservable();

  protected currentConversionType = '';

  constructor(
    protected readonly viewStore: ViewStore,
    protected readonly dateService: DateTimeService
  ) {}

  set conversionType(conversionType: string) {
    this.currentConversionType = conversionType;
  }

  get conversionType() {
    return this.currentConversionType;
  }

  getParams(): Params {
    const {
      clientId,
      brandId,
      literalId: dashboard,
      productId: product
    } = this.viewStore.selectedBCPDId.getValue();

    return {
      clientId,
      brandId,
      product,
      dashboard
    };
  }

  getFilters(filters: Filter[]): Params {
    const filterParams: Params = {};
    // Find and add conversion type
    const conversionTypeFilter = filters.find(
      (f) => f.literalId === 'conversion_type'
    );
    if (conversionTypeFilter) {
      filterParams[conversionTypeFilter.literalId] = conversionTypeFilter.value;
      this.conversionType = conversionTypeFilter.value as string;
    }

    // Find the day filter and use dateService to get proper dates
    const dayFilter = filters.find((f: Filter) => f.literalId === 'day');
    if (dayFilter) {
      const last30days = this.dateService.calculatePrevious30Days();
      filterParams.dateStart = last30days.startDate.format(
        DayJsDateFormat.fullDate
      );
      filterParams.dateStop = last30days.endDate.format(
        DayJsDateFormat.fullDate
      );
    }
    return filterParams;
  }

  getQueryParams(filters: Filter[]): Params {
    const params = this.getParams();
    const filterParams = this.getFilters(filters);
    return {
      ...params,
      filter: {
        ...filterParams
      }
    };
  }

  setData(data: T[]): void {
    this.dataSubject.next(data);
  }

  getData(): T[] {
    return this.dataSubject.getValue();
  }
}
