import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'portal-navigation-column',
  templateUrl: './navigation-column.component.html',
  styleUrls: ['./navigation-column.component.scss']
})
export class NavigationColumnComponent {
  @Input() link!: string;
  @Input() disabled = false;

  constructor(private router: Router) {}

  navigate() {
    this.router.navigateByUrl(this.link);
  }
}
