import { Injectable } from '@angular/core';

// Services
import { FieldService } from '@portal/app/shared/services/field.service';
import { DateTimeService } from '@portal/app/shared/services/date-time.service';

// Types
import type { SelectItem } from 'primeng/api';

// Constants
const DEFAULT_METRICS = [
  'mediaSpend',
  'totalSales',
  'totalOrders',
  'salesI',
  'salesLT',
  'cpoI',
  'cpoLT',
  'roasI',
  'roasLT',
  'percSalesI',
  'percOrdersI',
  'ordersI'
];

@Injectable({
  providedIn: 'root'
})
export class DropdownService {
  constructor(
    private dateTimeService: DateTimeService,
    private fieldService: FieldService
  ) {}

  getRelativeDayOptions(): SelectItem[] {
    return this.dateTimeService.currentRangeOptions.map((option) => ({
      label: option.name,
      value: option.name
    }));
  }

  getMetricOptions(
    includeLTMetrics = false,
    include = DEFAULT_METRICS
  ): SelectItem[] {
    const fields = this.fieldService.getCurrentFields();
    const fieldValues = Object.values(fields);

    // Filter and map in one step with explicit type annotation
    return fieldValues.reduce<SelectItem[]>((acc, field) => {
      if (
        include.includes(field.literalId) &&
        (includeLTMetrics || !field.literalId.endsWith('LT'))
      ) {
        acc.push({ label: field.label, value: field.literalId });
      }
      return acc;
    }, []);
  }
}
