import { ErrorHandler, Injectable } from '@angular/core';
import { ApiService } from '@portal/app/shared/services/api.service';
import { environment } from '@portal/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppErrorHandler implements ErrorHandler {
  constructor(public apiService: ApiService) {}

  handleError(error: unknown): void {
    const message = this.getMessageString(error);
    let ignore = false;
    if (message.includes('tab.IJsApiMessageHandler')) {
      ignore = true;
    }
    if (environment.production && !ignore) {
      // noinspection JSUnusedLocalSymbols
      this.apiService
        .postLog('ERROR', message, 'ProductionError')
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .subscribe((response) => {
          // Nothing to do, ignore the response
        });
    } else {
      console.error(message);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getMessageString(error: any): string {
    let message: string;
    if (
      error.get_stack != null &&
      typeof error.get_stack === 'function' &&
      error.get_message != null &&
      typeof error.get_message === 'function'
    ) {
      message = `Message: ${error.get_message()} -
      ${error.get_stack()}`;
    } else if (error.message != null && error.stack != null) {
      message = `Message: ${error.stack}`;
    } else if (error.error) {
      message = error.error.message;
    } else if (error) {
      message = error.toString();
    } else {
      message = 'Unable to read Error message';
    }
    return message;
  }
}
