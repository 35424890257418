/**
 * Api documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MetricDTO } from './metricDTO';


export interface AllocationSettingsDTO { 
    allocationSetting: AllocationSettingsDTO.AllocationSettingEnum;
    allocationType: AllocationSettingsDTO.AllocationTypeEnum;
    allocationFormat: AllocationSettingsDTO.AllocationFormatEnum;
    allocationSettingBudget: MetricDTO;
    allocationSettingBudgetPercentage: MetricDTO;
    defaultAllocationSetting: AllocationSettingsDTO.DefaultAllocationSettingEnum;
    defaultAllocationType: AllocationSettingsDTO.DefaultAllocationTypeEnum;
    defaultAllocationFormat: AllocationSettingsDTO.DefaultAllocationFormatEnum;
    isDisabled?: boolean;
}
export namespace AllocationSettingsDTO {
    export type AllocationSettingEnum = 'Anchored' | 'Optimized' | 'Capped';
    export const AllocationSettingEnum = {
        Anchored: 'Anchored' as AllocationSettingEnum,
        Optimized: 'Optimized' as AllocationSettingEnum,
        Capped: 'Capped' as AllocationSettingEnum
    };
    export type AllocationTypeEnum = 'Reference Period' | 'Fixed';
    export const AllocationTypeEnum = {
        ReferencePeriod: 'Reference Period' as AllocationTypeEnum,
        Fixed: 'Fixed' as AllocationTypeEnum
    };
    export type AllocationFormatEnum = 'percent' | 'currency';
    export const AllocationFormatEnum = {
        Percent: 'percent' as AllocationFormatEnum,
        Currency: 'currency' as AllocationFormatEnum
    };
    export type DefaultAllocationSettingEnum = 'Anchored' | 'Optimized' | 'Capped';
    export const DefaultAllocationSettingEnum = {
        Anchored: 'Anchored' as DefaultAllocationSettingEnum,
        Optimized: 'Optimized' as DefaultAllocationSettingEnum,
        Capped: 'Capped' as DefaultAllocationSettingEnum
    };
    export type DefaultAllocationTypeEnum = 'Reference Period' | 'Fixed';
    export const DefaultAllocationTypeEnum = {
        ReferencePeriod: 'Reference Period' as DefaultAllocationTypeEnum,
        Fixed: 'Fixed' as DefaultAllocationTypeEnum
    };
    export type DefaultAllocationFormatEnum = 'percent' | 'currency';
    export const DefaultAllocationFormatEnum = {
        Percent: 'percent' as DefaultAllocationFormatEnum,
        Currency: 'currency' as DefaultAllocationFormatEnum
    };
}


