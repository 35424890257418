import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  AfterViewInit,
  SimpleChanges
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { PerformanceOverTimeChartService } from './performance-over-time-chart.service';
import type { Options } from 'highcharts';
import { ViewBy } from '@portal/app/shared/constants';

@Component({
  selector: 'portal-performance-overtime-chart',
  templateUrl: './performance-over-time-chart.component.html'
})
export class PerformanceOverTimeChartComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  private destroy$ = new Subject<void>();
  private inputChanges$ = new Subject<void>();
  isLoading = true;
  isChartReady = false;
  isDataAvailable = true;
  multiLineChartOptions: Options = {};
  viewInitialized = false;

  @Input() dateStart!: string;
  @Input() dateStop!: string;
  @Input() conversionType!: string;
  @Input() resolution?: string;
  @Input() viewBy?: ViewBy;

  constructor(
    private readonly performanceOverTimeChartService: PerformanceOverTimeChartService
  ) {}

  /**
   * Initializes the component, subscribing to data and handling input changes.
   */
  ngOnInit(): void {
    this.subscribeToData();
    this.handleInputChanges();
  }

  /**
   * Detects changes in input properties and triggers data fetching if necessary.
   *
   * @param changes - The detected changes in input properties.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.dateStart ||
      changes.dateStop ||
      changes.conversionType ||
      changes.resolution ||
      changes.viewBy
    ) {
      this.inputChanges$.next();
    }
  }

  /**
   * Subscribes to `isChartReady$` only after the view has been initialized.
   */
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.viewInitialized = true;
      this.subscribeToChartReady();
    });
  }

  /**
   * Handles input changes by debouncing and triggering data fetching.
   */
  private handleInputChanges(): void {
    this.inputChanges$
      .pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe(() => {
        this.clearData();
        this.fetchData();
      });
  }

  /**
   * Subscribes to data streams from the performance over time chart service.
   */
  private subscribeToData(): void {
    this.performanceOverTimeChartService.isLoading$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoading) => {
        this.isLoading = isLoading;
      });

    this.performanceOverTimeChartService.isDataAvailable$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isDataAvailable) => {
        this.isDataAvailable = isDataAvailable;
      });

    this.performanceOverTimeChartService.chartData$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.multiLineChartOptions = data;
      });
  }

  /**
   * Subscribes to the isChartReady$ observable after the view is initialized.
   */
  private subscribeToChartReady(): void {
    this.performanceOverTimeChartService.isChartReady$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isChartReady) => {
        this.isChartReady = isChartReady;
      });
  }

  /**
   * Clears the chart data and resets the loading states.
   */
  private clearData(): void {
    this.isLoading = true;
    this.isChartReady = false;
    this.isDataAvailable = false;
    this.multiLineChartOptions = {};
  }

  /**
   * Fetches data from the service based on the input properties.
   */
  private fetchData(): void {
    if (this.dateStart && this.dateStop && this.conversionType) {
      const filters = {
        dateStart: this.dateStart,
        dateStop: this.dateStop,
        conversionType: this.conversionType,
        resolution: this.resolution,
        viewBy: this.viewBy
      };
      this.performanceOverTimeChartService.fetchData(filters);
    }
  }

  /**
   * Cleans up the component by unsubscribing from all observables and clearing data.
   */
  ngOnDestroy(): void {
    this.clearData();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
