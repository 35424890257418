import { Injectable, signal } from '@angular/core';
import {
  AlertItemProps,
  Status,
  UpdateAlertStatusRequestParams
} from '@design-system/components/m-alert-item';
import dayjs from 'dayjs';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NotificationCenterService {
  public readonly alertTagOptionsSubject = new BehaviorSubject<string[]>([]);
  public alertTagOptions$ = this.alertTagOptionsSubject.asObservable();

  public selectedAlertsSubject = new BehaviorSubject<AlertItemProps[]>([]);
  public selectedAlerts$ = this.selectedAlertsSubject.asObservable();

  public actionAlertsCountSubject = new BehaviorSubject<number>(0);
  public actionAlertsCount$ = this.actionAlertsCountSubject.asObservable();

  public unreadInfoAlertsCountSubject = new BehaviorSubject<number>(0);
  public unreadInfoAlertsCount$ =
    this.unreadInfoAlertsCountSubject.asObservable();

  public expandAlertId = signal<number>(0);
  public tableCurrentState = signal<AlertItemProps[]>([]);

  getPastDateFromRelativeTime(relativeTime: string): string {
    const now = dayjs();
    const timeUnits = ['year', 'month', 'day', 'hour', 'minute'];

    for (const unit of timeUnits) {
      const singular = unit;
      const plural = `${unit}s`;

      if (relativeTime.includes(singular)) {
        const value = relativeTime.includes(plural)
          ? parseInt(relativeTime.split(' ')[0] as string, 10)
          : 1;

        const format =
          unit === 'hour' || unit === 'minute'
            ? 'YYYY-MM-DD HH:mm'
            : 'YYYY-MM-DD';
        return now.subtract(value, unit as dayjs.ManipulateType).format(format);
      }
    }
    return now.format('YYYY-MM-DD');
  }

  filterByDate(
    data: AlertItemProps[],
    dateRange: { startDate: string; endDate: string; custom: boolean }
  ): AlertItemProps[] {
    if (dateRange.startDate !== '') {
      const start = new Date(dateRange.startDate);
      const end = new Date(dateRange.endDate);

      if (dateRange.custom && start.getTime() === end.getTime()) {
        end.setDate(end.getDate() + 1);
      }
      return data.filter((alert) => {
        if (alert?.timestamp) {
          const alertDate = new Date(
            this.getPastDateFromRelativeTime(alert.timestamp)
          );

          return dateRange.custom
            ? alertDate >= start && alertDate <= end
            : alertDate >= start;
        }
        return false;
      });
    }
    return data;
  }

  filterByService(
    data: AlertItemProps[],
    services: string[]
  ): AlertItemProps[] {
    if (services.length > 0) {
      return data.filter((item: { tag?: string }) =>
        services.some(
          (service) => item.tag?.toLowerCase() === service.toLowerCase()
        )
      );
    }
    return data;
  }

  filterBySearch(data: AlertItemProps[], searchTerm: string): AlertItemProps[] {
    if (searchTerm) {
      return data.filter((item) =>
        Object.values(item).some((val) =>
          String(val || '')
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        )
      );
    }
    return data;
  }

  public getSelectedAlerts = (): AlertItemProps[] => {
    return this.selectedAlertsSubject.getValue();
  };

  createUpdateAlertConfig(
    alert: AlertItemProps,
    status: Status,
    clientId?: number,
    brandId?: number
  ): UpdateAlertStatusRequestParams {
    return {
      alertConfigId: alert.alertConfigId || 0,
      identifier: alert.identifier || '',
      status,
      alertLogIds: alert.alertLogIds || [],
      ...(clientId !== undefined && { clientId }),
      ...(brandId !== undefined && { brandId })
    };
  }

  getUnreadAlerts(alerts: AlertItemProps[]): AlertItemProps[] {
    return alerts.length
      ? alerts
      : this.getSelectedAlerts().filter((item) => !item.isRead);
  }
}
