export enum GeoTestAction {
  saveAsActive = 'save-as-active',
  updateTestActive = 'update-test-active',
  updateTestDraft = 'update-test-draft',
  saveAsDraft = 'save-as-draft',
  updateTest = 'update-test',
  saveAsInactive = 'save-as-inactive',
  downloadSummary = 'download-summary',
  cancel = 'cancel'
}
