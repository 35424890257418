import {
  GeoCellDisplayStatus,
  GeoTestStatus,
  ICPAdTestConfig
} from '@portal/app/geo-designer/models/ICPAdTestConfig';
import dayjs from 'dayjs';
import { getDateWithoutTimezoneConsideration } from '@portal/app/dashboard/geo-doe-config/shared/dateUtils';
import { CpCellType, RecommendationResult, Status } from '@libs/apis';
import { TestAlertsHelper } from '@portal/app/geo-designer/common/test-alerts.helper';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

export class TestStatusHelper {
  public static getDisplayStatus = (
    geoTest: ICPAdTestConfig
  ): {
    actualStatus: GeoTestStatus;
    displayStatus: GeoCellDisplayStatus;
  } => {
    const { today, startDate, endDate } = this.getFormattedTestDates(geoTest);

    if (geoTest.status === Status.ACTIVE) {
      if (today.isBefore(startDate)) {
        return {
          actualStatus: GeoTestStatus.scheduled,
          displayStatus: GeoCellDisplayStatus.scheduled
        };
      }
      const testCell = geoTest.cellConfigs.find(
        (cell) => cell.cellType !== CpCellType.BAU
      );
      if (
        TestStatusHelper.isStudyYetToPendingToLaunch(
          geoTest.studyState,
          geoTest.disableSync,
          geoTest.parentId,
          testCell?.taxonomyFilters.length || 0
        )
      ) {
        return {
          actualStatus: GeoTestStatus.scheduled,
          displayStatus: GeoCellDisplayStatus.pendingToLaunch
        };
      }

      if (today.isBetween(startDate, endDate, 'day', '[]')) {
        return {
          actualStatus: GeoTestStatus.active,
          displayStatus: GeoCellDisplayStatus.inProgress
        };
      }

      if (
        today.isBetween(
          dayjs(endDate).add(1, 'day'),
          dayjs(endDate).add(7, 'days'),
          'day',
          '[]'
        )
      ) {
        return {
          actualStatus: GeoTestStatus.active,
          displayStatus: GeoCellDisplayStatus.resultsPending
        };
      }

      if (today.isAfter(dayjs(endDate).add(8, 'days'))) {
        return {
          actualStatus: GeoTestStatus.finished,
          displayStatus: GeoCellDisplayStatus.finished
        };
      }
    } else if (geoTest.status === Status.DRAFT) {
      if (
        this.isActionNeeded(
          geoTest.recommendationResult as RecommendationResult
        ) ||
        today.isAfter(startDate)
      ) {
        return {
          actualStatus: GeoTestStatus.scheduled,
          displayStatus: GeoCellDisplayStatus.actionNeeded
        };
      } else if (today.isBefore(startDate)) {
        return {
          actualStatus: GeoTestStatus.scheduled,
          displayStatus: GeoCellDisplayStatus.draft
        };
      }
    }
    return {
      actualStatus: GeoTestStatus.active,
      displayStatus: GeoCellDisplayStatus.none
    };
  };

  private static getFormattedTestDates(geoTest: ICPAdTestConfig) {
    const today = dayjs();
    const startDate = getDateWithoutTimezoneConsideration(
      geoTest.startDate.toString().split('T')[0]
    );
    const endDate = getDateWithoutTimezoneConsideration(
      geoTest.endDate.toString().split('T')[0]
    );

    const reportEndDate = getDateWithoutTimezoneConsideration(
      geoTest.reportEndDate.toString().split('T')[0]
    );

    return { today, startDate, endDate, reportEndDate };
  }

  private static isActionNeeded = (
    recommendationResult: RecommendationResult
  ): boolean => {
    return (
      TestAlertsHelper.isTacticNotFound(recommendationResult) ||
      TestAlertsHelper.isMsExpired(recommendationResult) ||
      TestAlertsHelper.isWaitingForApproval(recommendationResult)
    );
  };

  public static isStudyYetToPendingToLaunch(
    studyState: { studyId: number; rModTime: string } | null,
    disableSync: 0 | 1,
    parentId: number | null,
    totalInventories: number
  ): boolean {
    if (studyState) {
      return false;
    }
    const isTestContributionOnly = totalInventories === 0;
    const isTestCloned = parentId !== null;
    const isTestManual = disableSync === 1;
    return !(isTestContributionOnly || isTestCloned || isTestManual);
  }
}
