<ng-container class="wrapper">
  @switch (layoutType) {
    @case ("spanGroup") {
      @if (data) {
        <p class="span-group-text">
          @for (item of data; track item) {
            <span class="{{ item.class }}">
              {{ item.text }}
            </span>
          }
        </p>
      }
    }
  }
</ng-container>

<div class="d-flex justify-content-center button-row">
  <button
    pButton
    class="p-button-outlined"
    label="Cancel"
    (click)="close()"></button>
  <button pButton class="pButton" label="Confirm" (click)="confirm()"></button>
</div>
