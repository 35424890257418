import {
  Component,
  AfterViewInit,
  Input,
  ViewChild,
  ElementRef
} from '@angular/core';
import anime from 'animejs/lib/anime.es.js';

// Types
import type { CountUpProps } from './m-count-up.component.types';

@Component({
  selector: 'm-count-up',
  templateUrl: 'm-count-up.component.html'
})
export class MCountUpComponent implements AfterViewInit, CountUpProps {
  /** The starting value for the count-up animation. Default is '0'. */
  @Input() start = '0';

  /** The ending value for the count-up animation. Default is '100'. */
  @Input() end = '100';

  /** The duration of the count-up animation in seconds. Default is 1 second. */
  @Input() duration = 1; // Duration in seconds

  /** Additional CSS classes to be applied to the counter element. Default is an empty string. */
  @Input() class = ''; // Additional CSS classes

  /** A reference to the counter element in the template. */
  @ViewChild('counter') counterElement!: ElementRef;

  /**
   * Lifecycle hook that is called after a component's view has been fully initialized.
   * Initializes the count-up animation.
   */
  ngAfterViewInit(): void {
    this.animateCountUp(this.start, this.end, this.duration);
  }

  /**
   * Animates the count-up from the start value to the end value over the specified duration.
   *
   * @param start - The starting value of the count-up animation.
   * @param end - The ending value of the count-up animation.
   * @param duration - The duration of the animation in seconds.
   */
  private animateCountUp(start: string, end: string, duration: number): void {
    const startNumber = this.parseNumber(start);
    const endNumber = this.parseNumber(end);
    const durationInMs = duration * 1000;

    anime({
      targets: { value: startNumber },
      value: endNumber,
      easing: 'linear',
      round: 0,
      duration: durationInMs,
      update: (animeInstance) => {
        const currentValue = animeInstance.animations[0]
          ?.currentValue as unknown as number;
        const formattedValue = this.interpolateValue(currentValue, end);
        this.counterElement.nativeElement.innerHTML = formattedValue;
      }
    });
  }

  /**
   * Parses the number from a formatted string.
   * Assumes the format is similar between start and end.
   */
  private parseNumber(value: string): number {
    return parseFloat(value.replace(/[^0-9.-]/g, '')) || 0;
  }

  /**
   * Interpolates the formatted value.
   * This function retains the original format (prefix, suffix) of the end value.
   */
  private interpolateValue(currentValue: number, endFormat: string): string {
    return endFormat.replace(/[\d,.-]+/, currentValue.toLocaleString());
  }
}
