import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ChartDataElement,
  ChartElement,
  DataChartValue,
  DataGridConfig,
  DataGridSelected,
  DataResponse,
  DataSetResponse,
  ElementGroup,
  FieldDefinitions
} from '@portal/app/shared/types';
import { ChartService } from '@portal/app/charts/services/chart.service';
import { DataGridStore } from '@portal/app/datagrid/store/data-grid.store';
import {
  compact,
  divide,
  flatten,
  groupBy,
  isUndefined,
  map,
  omitBy,
  subtract,
  sum,
  sumBy,
  values
} from 'lodash-es';
import { Required } from '@portal/app/shared/decorators/required.decorator';
import { Subscription } from 'rxjs';

@Component({
  selector: 'portal-geo-experiment-result-chart-group-table',
  templateUrl: './geo-experiment-result-chart-group-table.component.html'
})
export class GeoExperimentResultChartGroupTableComponent
  implements OnInit, OnDestroy
{
  geoPreAndPostExpChartData: DataSetResponse[] = [];
  @Input() @Required fieldDefinitions: FieldDefinitions = {};
  @Input() @Required tableWidget!: ElementGroup;
  @Input() @Required cellType = 'Test';
  chartData: ChartDataElement | undefined = undefined;
  dataGridConfig: DataGridConfig = this.dataGridStore.getDataGridTableConfig();
  groupByRowFooter: Record<string, DataResponse> | undefined = undefined;

  private subs: Subscription = new Subscription();

  constructor(
    private readonly chartService: ChartService,
    private readonly dataGridStore: DataGridStore
  ) {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.dataGridConfig.striped = false;
    this.dataGridConfig.showFooter = false;
    this.subs.add(
      this.chartService.geoExperimentChartTableData.subscribe({
        next: (data) => {
          this.chartData = data;
          if (data) {
            this.prepareTableData();
          }
        }
      })
    );
  }

  prepareTableData(): void {
    // TODO: Add support for the multiple metrics/dimension
    const [yObj] = this.chartData
      ? (
          values(this.chartData)[0] as {
            data: DataChartValue;
            chartElement: ChartElement;
          }
        ).chartElement.selectedY
      : [];
    const groupByFieldName = 'geo';
    const updatedChartData = flatten(
      values(this.chartData).map((data) => {
        return Object.entries(
          groupBy(
            flatten(map(data.data.data, (obj) => obj.data)),
            groupByFieldName
          )
        ).map(([key, value]) => {
          return omitBy(
            {
              geo: key,
              preExperiment: data.data.name.includes('Before')
                ? sumBy(value, yObj?.dashboardFieldId)
                : undefined,
              experiment: data.data.name.includes('After')
                ? sumBy(value, yObj?.dashboardFieldId)
                : undefined,
              type: data.data.name.includes('BAU')
                ? 'Anchor BAU'
                : this.cellType
            },
            isUndefined
          );
        });
      })
    );
    const finalData: DataResponse[] = Object.entries(
      groupBy(updatedChartData, groupByFieldName)
    ).map(([geo, data]) => {
      const pre = sum(compact(data.map(({ preExperiment }) => preExperiment)));
      const post = sum(compact(data.map(({ experiment }) => experiment)));

      return {
        geo,
        preExperiment: pre,
        experiment: post,
        change: this.calculateExpChange(pre, post),
        type: data[0]?.type as string
      };
    });
    this.calculateTotal(finalData);

    this.geoPreAndPostExpChartData = [
      {
        name: this.tableWidget.literalId,
        data: [...finalData]
      }
    ];
  }

  calculateExpChange(preExperiment = 0, experiment = 0): number {
    return divide(subtract(experiment, preExperiment), preExperiment);
  }

  calculateTotal(tableData: DataResponse[]): void {
    const groupedRowFooterData: Record<string, DataResponse> = {};
    const groupedData = groupBy(tableData, 'type');
    for (const groupedDataKey in groupedData) {
      if (groupedData[groupedDataKey]) {
        const obj: Record<string, number> = {};
        (this.tableWidget.elements[0]?.selected as DataGridSelected[]).forEach(
          (field) => {
            if (
              ['number', 'double'].includes(
                this.fieldDefinitions[field.dashboardFieldId]?.type as string
              ) &&
              field.dashboardFieldId !== 'change'
            ) {
              obj[field.dashboardFieldId] = sum(
                groupedData[groupedDataKey]?.map((data) => {
                  return data[field.dashboardFieldId];
                })
              );
            }
          }
        );
        groupedRowFooterData[groupedDataKey] = {
          ...obj,
          change: this.calculateExpChange(obj.preExperiment, obj.experiment)
        };
      }
    }
    this.groupByRowFooter = groupedRowFooterData;
  }
}
