import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { DateRange, FilterOption } from '@portal/app/shared/types';
import { NativeSectionsService } from '@portal/app/shared/services/native-sections.service';
import { ViewStore } from '@portal/app/shared/state/view.store';

@Component({
  selector: 'portal-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.scss']
})
export class ButtonGroupComponent implements OnInit, OnChanges {
  @Input() options!: FilterOption[];
  @Input() selectedOption: string | string[] | DateRange = '';
  @Input() showOptionCount = true;
  @Output() selectedOptionChange = new EventEmitter();
  decoratedOptions: FilterOption[] = [];
  parentClass = '';

  constructor(
    private readonly nativeSectionsService: NativeSectionsService,
    private readonly viewStore: ViewStore
  ) {}

  ngOnInit(): void {
    this.buildDecoratedOptions();
    this.viewStore.selectedBCPDId.subscribe((value) => {
      this.parentClass = this.nativeSectionsService.getClassByLiteralId(
        value.literalId
      );
    });
    if (!this.selectedOption) {
      this.selectedOption = this.decoratedOptions[0]?.value as string;
    }
  }

  ngOnChanges(): void {
    this.buildDecoratedOptions();
  }

  buildDecoratedOptions(): void {
    this.decoratedOptions = this.options.map((option) => ({
      ...option,
      label: this.evaluateLabel(option)
    }));
  }

  evaluateLabel(option: FilterOption): string {
    return this.showOptionCount &&
      (option?.perOptionCount || option?.perOptionCount === 0)
      ? `${option.label} (${option.perOptionCount})`
      : option.label;
  }

  onButtonClickHandler(index: number): void {
    this.selectedOption = this.decoratedOptions[index]?.value as string;
    this.selectedOptionChange.emit(this.decoratedOptions[index]?.value);
  }
}
