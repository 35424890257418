import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import {
  DataResponse,
  DataRowData,
  IDynamicPivotConfig,
  IFormats,
  IPivotRows,
  IStaticPivotConfig,
  ITableField
} from '@portal/app/shared/types';
import { cloneDeep, compact, filter, groupBy, isEmpty, keys } from 'lodash-es';
import { FormatterService } from '@portal/app/shared/services/formatter.service';
import { DataGridService } from '@portal/app/datagrid/services/data-grid.service';

@Component({
  selector: 'portal-data-grid-pivot-v2',
  templateUrl: './data-grid-pivot-v1.component.html',
  styleUrls: ['data-grid-pivot-v1.component.scss']
})
export class DataGridPivotV1Component implements OnInit, OnDestroy {
  @Input() styleClass = '';
  @Input() staticPivotConfig!: IStaticPivotConfig;
  @Input() dynamicPivotConfig: IDynamicPivotConfig | undefined = undefined;

  pivotDataGroupedByColumns: Record<string, DataResponse[]> = {};
  tableData: {
    data: DataResponse[];
    exportData: DataResponse[];
    columns: ITableField[];
    name: string;
  } = {
    data: [],
    columns: [],
    exportData: [],
    name: ''
  };

  constructor(
    public formatterService: FormatterService,
    private dataGridService: DataGridService
  ) {}

  ngOnInit(): void {
    this.formatPivotData();
    this.setupDynamicPivotTable();
    this.setupStaticDynamicPivotTable();
    this.dataGridService.setDataGridContextStore({
      data:
        this.tableData.exportData.length > 0
          ? this.tableData.exportData
          : this.tableData.data,
      columns: this.tableData.columns,
      tableType: 'PivotTable',
      tableName: this.tableData.name
    });
  }

  setupDynamicPivotTable(): void {
    this.tableData.columns = [
      {
        label: this.dynamicPivotConfig?.pivotRowsBy.label || '',
        subText: null,
        field: 'headerRowVal',
        format: { format: '', type: 'string', digitsInfo: '' }
      }
    ];
  }

  setupStaticDynamicPivotTable(): void {
    this.tableData.columns = [
      {
        label: this.staticPivotConfig?.pivotRowHeaderLabel || '',
        subText: 'headerRowValField',
        field: 'headerRowVal',
        format: { format: '', type: 'string', digitsInfo: '' }
      }
    ];
    if (this.staticPivotConfig) {
      this.tableData.name = this.staticPivotConfig.name;
      this.staticPivotConfig.pivotFields.map((pivotField) => {
        this.pivotDataGroupedByColumns = groupBy(
          this.tableData.data,
          pivotField.field
        );
        this.tableData.columns.push(
          ...keys(this.pivotDataGroupedByColumns).map((key) => {
            return {
              label: key,
              field: key,
              subText: null,
              format: {
                format: '',
                type: '',
                digitsInfo: ''
              }
            };
          })
        );
      });
      this.tableData.data = this.preparePivotData(
        this.staticPivotConfig.pivotRows
      );
      this.tableData.exportData = this.preparePivotData(
        this.staticPivotConfig.exportPivotRows
      );
    }
  }

  preparePivotData(pivotRows: IPivotRows[]): DataResponse[] {
    const cols = keys(this.pivotDataGroupedByColumns);
    let filteredPivotRows;
    if (this.staticPivotConfig) {
      if (!isEmpty(this.staticPivotConfig.pivotRowsToIgnore)) {
        filteredPivotRows = compact(
          cloneDeep(pivotRows).map((pRow) => {
            if (
              !this.staticPivotConfig?.pivotRowsToIgnore.includes(pRow.field)
            ) {
              return pRow;
            }
          })
        );
      } else {
        filteredPivotRows = pivotRows;
      }

      if (cols.length > 0) {
        return filteredPivotRows.map((rowData) => {
          const val: DataResponse = {};
          cols.forEach((col) => {
            val[col] = (
              (
                this.pivotDataGroupedByColumns[col as string] as DataResponse[]
              )[0] as DataResponse
            )[rowData.field] as DataRowData;
          });
          return {
            headerRowVal: rowData.label,
            headerRowValField: rowData.subText as unknown as string,
            ...val
          };
        });
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  formatPivotData(): void {
    if (!this.staticPivotConfig) {
      return;
    }
    this.tableData.data = cloneDeep(this.staticPivotConfig.pivotData);
    if (this.tableData.data && !isEmpty(this.tableData.data)) {
      const allFields: IFormats = {};
      this.staticPivotConfig.pivotRows.forEach((pRow) => {
        allFields[pRow.field] = pRow.format;
      });
      this.tableData.data = this.tableData.data.map((dObj) => {
        keys(dObj).forEach((key) => {
          const [pivotField] = filter(this.staticPivotConfig?.pivotRows, {
            field: key
          });
          if (pivotField) {
            dObj[key] = this.formatterService.formatPartialValue(
              pivotField.format,
              dObj[key] as DataRowData
            );
          }
        });
        return dObj;
      });
    }
  }

  ngOnDestroy(): void {
    this.dataGridService.removeTableFromDataGridStore(this.tableData.name);
  }
}
