import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from '@portal/app/auth/login/login.component';
import { titleBase } from '@portal/app/shared/helpers/helpers';
import { OktaCallbackComponent } from '@okta/okta-angular';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: { title: `${titleBase} Login` }
  },
  {
    path: 'login/callback',
    component: OktaCallbackComponent
  },
  {
    path: 'force-login',
    component: LoginComponent,
    data: { title: `${titleBase} Redirecting Login...` }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
