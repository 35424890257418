import {
  Component,
  Input,
  ElementRef,
  NgZone,
  ChangeDetectorRef,
  Renderer2,
  InjectionToken,
  TemplateRef,
  ContentChild
} from '@angular/core';
import { FilterService, OverlayService, PrimeNGConfig } from 'primeng/api';
import { Table, TableService } from 'primeng/table';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';

// Types
import type { TableFooter, TableGroup } from './m-table.component.types';
import { DomSanitizer } from '@angular/platform-browser';

export const ObjectInjection = new InjectionToken<any>('Object');

/**
 * MTableComponent - A table component with customizable columns, groups, and footers.
 */
@Component({
  selector: 'm-table',
  templateUrl: './m-table.component.html',
  providers: [{ provide: ObjectInjection, useValue: Object }]
})
export class MTableComponent extends Table {
  /** Grouped columns for the table */
  @Input() groupedColumns?: TableGroup[];
  /** Footer data for the table */
  @Input() footerData?: TableFooter[];

  @ContentChild('customBody') customBody!: TemplateRef<any>;

  constructor(
    el: ElementRef,
    zone: NgZone,
    tableService: TableService,
    cd: ChangeDetectorRef,
    filterService: FilterService,
    overlayService: OverlayService,
    @Inject(ObjectInjection) platformId: any,
    renderer: Renderer2,
    config: PrimeNGConfig,
    @Inject(DOCUMENT) mTableDocument: Document,
    @Inject(DomSanitizer) mTableDomSanitizer: DomSanitizer
  ) {
    super(
      mTableDocument,
      platformId,
      renderer,
      el,
      zone,
      tableService,
      cd,
      filterService,
      overlayService,
      config,
      mTableDomSanitizer
    );
  }
}
