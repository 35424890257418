/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
HighchartsMore(Highcharts);
@Component({
  selector: 'm-basic-column-chart',
  templateUrl: './m-basic-column-chart.component.html',
  styleUrls: ['./m-basic-column-chart.component.scss']
})
export class MBasicColumnChartComponent implements OnInit {
  @Input() series: Highcharts.SeriesOptionsType[] = [];
  @Input() yAxis: Highcharts.YAxisOptions | Highcharts.YAxisOptions[] = {};
  @Input() xAxis: Highcharts.XAxisOptions | Highcharts.XAxisOptions[] = {};
  @Input() legend: Highcharts.LegendOptions = {};
  @Input() plotOptions: Highcharts.PlotOptions = {};
  @Input() tooltip: Highcharts.TooltipOptions = {};
  @Input() width: number | string = 582;
  @Input() minWidth = 800;
  @Input() height: number | string = 305;
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};
  ngOnInit(): void {
    this.chartOptions = {
      chart: {
        type: 'column',
        height: this.height,
        spacingTop: 60,
        reflow: true,
        scrollablePlotArea: {
          minWidth: this.minWidth,
          scrollPositionX: 1
        }
      },
      title: {
        text: ''
      },
      xAxis: this.xAxis,
      yAxis: this.yAxis,
      legend: this.legend,
      tooltip: this.tooltip,
      plotOptions: this.plotOptions,
      series: this.series,
      credits: { enabled: false },
      exporting: { enabled: false }
    };
  }
}
