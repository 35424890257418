import {
  Status,
  GeoTestType,
  GeoTestMethod,
  ITestMetadata,
  ITestMetrics
} from '@libs/apis';
import { IGeoCellPlatform } from './IGeoCellPlatform';
import { CpCellType } from './CpCellType';

export interface IRecommendedTest {
  id: number;
  name: string;
  clientId: number;
  brandId: number;
  conversionTypeId: number;
  compositeDataSourceId: number;
  compositeDataSourceName: string;
  channel?: string;
  tactic: string;
  globalTactic: string;
  tactics?: ITestTacticMapping[];
  type: CpCellType;
  mdl: number;
  testType: GeoTestType;
  method: GeoTestMethod;
  status: Status;
  durationInWeeks: number;
  metadata: ITestMetadata;
  metrics: ITestMetrics;
  dtdMap: ITestDtdMap[];
  parameterHistory?: ITestParameterHistory[];
  isRecommended?: boolean;
  isCustomRecommendation?: boolean;
  platformInfo?: IGeoCellPlatform;
  isMultiTactic?: boolean;
  mediaSpend?: number;
  percOrdersI?: number;
  isTestable: boolean;
}

export interface ITestTacticMapping {
  id: number;
  recommendationId: number;
  tactic: string;
}

export interface ITestDtdMap {
  dtdId: number;
  dtdType: string;
  scaleBudget: number;
  tactic: string;
}

export interface ITestParameterHistory {
  studyId: number;
  cellConfigId: number;
  newValue: string;
  oldValue: string;
  parameter: Parameter;
  approvalStatus: ApprovalStatus;
  rCreTime: Date;
}

export interface ITestComments {
  mediaSpend: string;
  percOrdersI: string;
  percInc: string;
  percSalesContribution: string;
  additionalSpend: string;
  benchmarks: string;
  duration: string;
  type: string;
  method: string;
  recommendation?: string;
  refMediaSpend: string;
  refPercOrdersI: string;
  confidenceLevel?: string;
  previousIncrementalityMetric?: string;
}

export enum Parameter {
  SCALE_COST = 'SCALE_COST',
  TREATMENT = 'TREATMENT',
  STATUS = 'STATUS',
  EXECUTION_METHOD = 'EXECUTION_METHOD'
}

export enum ApprovalStatus {
  RESOLVED = 'RESOLVED',
  UN_RESOLVED = 'UN_RESOLVED',
  REJECTED = 'REJECTED',
  NO_ACTION_REQUIRED = 'NO_ACTION_REQUIRED'
}
