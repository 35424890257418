import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@portal/app/dashboard/integrations/services/api.service';
import { callbackDomain } from '@portal/app/dashboard/integrations/shared/constants';
import {
  IOAuthDetails,
  IVendorConnectInitResponse
} from '@portal/app/dashboard/integrations/models/vendor/VendorIntegrationModels';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { WindowService } from '@portal/app/shared/services/window.service';

@Component({
  selector: 'portal-shopify',
  templateUrl: './shopify.component.html',
  styleUrls: ['./shopify.component.scss']
})
export class ShopifyComponent implements OnInit {
  set = true;

  loading = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly apiService: ApiService,
    private readonly windowService: WindowService
  ) {}

  ngOnInit() {
    if (this.isInstallFlow()) {
      this.handleInstall();
    } else {
      this.handleCallback();
    }
  }

  private isInstallFlow() {
    return window.location.pathname.includes('/install/shopify');
  }

  private handleInstall() {
    this.loading.next(true);
    this.route.queryParams.subscribe((queryParams) => {
      this.apiService
        .vendorAuthInit(queryParams.shop, callbackDomain())
        .subscribe((response: IVendorConnectInitResponse) => {
          this.windowService.navigateToHref(
            (response.vendorCredentialsDetails as IOAuthDetails).redirectURL
          );

          this.loading.next(false);
        });
    });
  }

  private handleCallback() {
    console.warn('callback url: ', window.location.href);
  }
}
