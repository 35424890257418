export enum BannerType {
  primary = 'primary',
  warn = 'warn',
  error = 'error',
  draft = 'draft',
  success = 'success'
}

export enum CreativeAlignment {
  bottom = 'bottom',
  center = 'center',
  top = 'top'
}
