import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Message } from 'primeng/api';

@Component({
  selector: 'm-messages',
  templateUrl: './m-messages.component.html',
  styleUrls: ['./m-messages.component.scss']
})
export class MMessagesComponent {
  @Input() messages: Message[] = []; // Input for messages array
  @Input() closable = false; // Input to make the message closable
  @Input() escape = true; // Input to allow html

  @Output() closeMessage: EventEmitter<any> = new EventEmitter<any>();

  handleClose(event: any) {
    this.closeMessage.emit(event); // Emit the event to the parent component
  }
}
