/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable()
export class CsvService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  downloadFile(data: any, filename = 'data') {
    const blob = new Blob(['\ufeff' + data], {
      type: 'text/csv;charset=utf-8;'
    });
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser =
      navigator.userAgent.indexOf('Safari') !== -1 &&
      navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  convertToCSV(objArray: any, headerList: any) {
    const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';
    headerList.forEach((header: string, index: number) => {
      row += header;
      if (index < headerList.length - 1) {
        row += ',';
      }
    });
    str += row + '\r\n';

    for (const item of array) {
      let line = '';
      for (const header of headerList) {
        line += item[header] + ',';
      }
      line = line.slice(0, -1);
      str += line + '\r\n';
    }
    return str;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  convertToTSV(objArray: any, headerList: any) {
    const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';

    // Append headers
    headerList.forEach((header: string, index: number) => {
      row += header;
      if (index < headerList.length - 1) {
        row += '\t';
      }
    });
    str += row + '\r\n';

    for (const item of array) {
      let line = '';
      for (const header of headerList) {
        line += item[header] + '\t';
      }
      line = line.slice(0, -1);
      str += line + '\r\n';
    }
    return str;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  convertToExcel(objArray: any, headerList: any) {
    const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    const worksheetData = [
      headerList,
      ...array.map((item: any) =>
        headerList.map((header: string) => item[header])
      )
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    return XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  downloadFileWithFormat(
    data: any,
    format: 'csv' | 'tsv' | 'xlsx',
    filename = 'data'
  ) {
    let fileType = 'text/plain;charset=utf-8;';

    switch (format) {
      case 'csv':
        fileType = 'text/csv;charset=utf-8;';
        // eslint-disable-next-line no-param-reassign
        filename += '.csv';
        break;
      case 'tsv':
        fileType = 'text/tab-separated-values;charset=utf-8;';
        // eslint-disable-next-line no-param-reassign
        filename += '.tsv';
        break;
      case 'xlsx':
        // eslint-disable-next-line no-param-reassign
        filename += '.xlsx';
        fileType = 'application/octet-stream';
        break;
    }

    if (data) {
      const blob = new Blob([data], { type: fileType });
      const dwldLink = document.createElement('a');
      const url = URL.createObjectURL(blob);
      const isSafariBrowser =
        navigator.userAgent.indexOf('Safari') !== -1 &&
        navigator.userAgent.indexOf('Chrome') === -1;
      if (isSafariBrowser) {
        dwldLink.setAttribute('target', '_blank');
      }
      dwldLink.setAttribute('href', url);
      dwldLink.setAttribute('download', filename);
      dwldLink.style.visibility = 'hidden';
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
    }
  }
}
