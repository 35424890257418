@if (showModal()) {
  <div
    class="details-modal fixed w-full h-full bg-gray-200 top-0 left-0 p-6 overflow-scroll"
    [@slideInOut]>
    <m-card class="flex flex-col">
      <div
        header
        class="flex content-between justify-between items-center w-full mb-6">
        <div class="h4 text-gray-1000">Your MIM v2 Performance</div>
        <div class="flex items-center">
          <ng-container *ngIf="conversionTypeOptions()">
            <span
              class="material-symbols-outlined icon-filled text-gray-500 me-[16px]">
              filter_alt
            </span>
            <m-dropdown
              [options]="conversionTypeOptions()"
              [ngModel]="conversionType()"
              (formControlChange)="onConversionTypeChange($event?.value)"
              class="me-6">
            </m-dropdown>
          </ng-container>
          <span
            class="material-symbols-outlined text-blue-600 icon-regular cursor-pointer"
            (click)="closeModal()"
            >close</span
          >
        </div>
      </div>
      <div class="content flex flex-column gap-6">
        @if (isMediumsLoading()) {
          <m-skeleton height="152px" class="w-full"></m-skeleton>
        } @else {
          <m-card [noShadow]="true">
            <div class="dp2 text-gray-1000 mb-6">Media Mix Model Details</div>
            <div class="grid grid-cols-4 gap-6 mb-2">
              @for (medium of mediums(); track medium) {
                <div class="col-span-1 text-center">
                  <div class="b1 text-gray-800">{{ medium.label }}</div>
                  <div class="sh2 text-gray-1000">{{ medium.value }}</div>
                </div>
              }
            </div>
          </m-card>
        }

        <m-card [noShadow]="true">
          <div class="flex justify-between items-center mb-4">
            <div class="dp2 text-gray-1000">Media Performance History</div>
            <div class="flex items-center">
              <m-dropdown
                [options]="resolutionOptions()"
                optionLabel="label"
                optionValue="value"
                [ngModel]="resolution()"
                (formControlChange)="onResolutionChange($event.value)"
                class="me-4">
              </m-dropdown>
              <m-dropdown
                [options]="periodOptions()"
                [ngModel]="period()"
                (formControlChange)="onPeriodChange($event?.value)"
                class="me-2">
              </m-dropdown>
              <button
                *ngIf="isChartDataAvailable()"
                pButton
                class="p-button-text p-button-icon-only w-auto"
                pTooltip="Export"
                tooltipPosition="bottom"
                tooltipStyleClass="saas-tooltip"
                showDelay="800"
                (click)="chartMenu.toggle($event)">
                <span class="material-symbols-outlined"> ios_share </span>
              </button>
              <p-menu
                styleClass="saas-menu-overlay !z-[1024]"
                #chartMenu
                [popup]="true"
                [model]="exportChartMenu"
                appendTo="body"></p-menu>
            </div>
          </div>
          @if (!isChartReady()) {
            <m-skeleton height="300px" class="w-full"></m-skeleton>
          } @else if (!isChartDataAvailable()) {
            <m-not-available></m-not-available>
          } @else if (isChartReady()) {
            <m-line-chart
              class="mmm-modal-details-chart relative"
              *ngIf="chartOptions().series"
              [series]="chartOptions().series"
              [xAxis]="chartOptions().xAxis"
              [yAxis]="chartOptions().yAxis"
              [plotOptions]="chartOptions().plotOptions"
              [tooltip]="chartOptions().tooltip"
              [legend]="chartOptions().legend"
              [height]="300"
              (chartRender)="onChartRender($event)">
            </m-line-chart>
          }
        </m-card>

        <m-card [noShadow]="true">
          <div class="relative">
            <button
              *ngIf="
                (activeIndex === 0 && isTestsDataAvailable()) ||
                (activeIndex === 1 && isTacticsDataAvailable())
              "
              pButton
              class="table-export-button p-button-text p-button-icon-only absolute top-0 right-0 w-auto"
              pTooltip="Export"
              tooltipPosition="bottom"
              tooltipStyleClass="saas-tooltip"
              showDelay="800"
              (click)="tableMenu.toggle($event)">
              <span class="material-symbols-outlined"> ios_share </span>
            </button>
            <p-menu
              styleClass="saas-menu-overlay !z-[1024]"
              #tableMenu
              [popup]="true"
              [model]="exportTableMenu"
              appendTo="body"></p-menu>
            <m-tab-view
              [tabPanelHeaders]="tabPanelHeaders"
              (activeIndexChange)="onActiveIndex($event)">
              <ng-template [mTabIndex]="1" #tabContent>
                <div class="pt-2">
                  @if (isTestsLoading()) {
                    <m-skeleton height="150px" class="w-full"></m-skeleton>
                  } @else if (!isTestsDataAvailable()) {
                    <m-not-available></m-not-available>
                  } @else {
                    <m-table
                      [columns]="contributingTests().table.columns"
                      [value]="contributingTests().table.values">
                      <ng-template #customBody let-rowData>
                        <tr>
                          @for (
                            col of contributingTests().table.columns;
                            track col
                          ) {
                            @if (col.field === "testName") {
                              <td [ngClass]="col?.class">
                                <div class="flex">
                                  <span class="b2 mr-2">{{
                                    rowData[col!.field]
                                  }}</span>
                                  <span
                                    *ngIf="rowData?.row?.isMultiTactic"
                                    [pTooltip]="
                                      rowData?.row?.tactics | multiTacticTooltip
                                    "
                                    tooltipStyleClass="saas-tooltip"
                                    tooltipPosition="top"
                                    [escape]="false"
                                    class="material-symbols-outlined icon-small text-blue-400"
                                    >stacks</span
                                  >
                                </div>
                              </td>
                            } @else {
                              <td [ngClass]="col?.class">
                                <div [innerHTML]="rowData[col!.field]"></div>
                              </td>
                            }
                          }
                        </tr>
                      </ng-template>
                    </m-table>
                  }
                </div>
              </ng-template>
              <ng-template [mTabIndex]="2" #tabContent>
                <div class="pt-2">
                  @if (isTacticsLoading()) {
                    <m-skeleton height="150px" class="w-full"></m-skeleton>
                  } @else if (!isTacticsDataAvailable()) {
                    <m-not-available></m-not-available>
                  } @else {
                    <m-table
                      [columns]="contributingTactics().table.columns"
                      [value]="contributingTactics().table.values"
                      [footerData]="contributingTactics().table.footer">
                      <ng-template #customBody let-rowData>
                        <tr>
                          @for (
                            col of contributingTactics().table.columns;
                            track col
                          ) {
                            @if (col.field === "modelResult") {
                              <td [ngClass]="col?.class">
                                <div class="flex">
                                  <span
                                    [pTooltip]="
                                      rowData[col!.field] === 'Not available'
                                        ? 'Results for this tactic are unavailable due to<br />insufficient data'
                                        : rowData[col!.field]
                                    "
                                    tooltipStyleClass="saas-tooltip"
                                    tooltipPosition="top"
                                    [escape]="false"
                                    >{{ rowData[col!.field] }}</span
                                  >
                                </div>
                              </td>
                            } @else {
                              <td [ngClass]="col?.class">
                                <div [innerHTML]="rowData[col!.field]"></div>
                              </td>
                            }
                          }
                        </tr>
                      </ng-template>
                    </m-table>
                  }
                </div>
              </ng-template>
            </m-tab-view>
          </div>
        </m-card>
      </div>
    </m-card>
  </div>
}
