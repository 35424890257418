import {
  AlertItemProps,
  IGroupByFieldsConfigs,
  IColumnFields,
  notificationTabs
} from '@design-system/components/m-alert-item';

export const tabOptions = [
  {
    label: 'Active',
    value: notificationTabs.active
  },
  {
    label: 'Resolved',
    value: notificationTabs.resolved
  }
];

export class NotificationTableConfigs {
  public static readonly columnFieldsForActiveTab: IColumnFields[] = [
    {
      header: 'Notifications',
      field: 'title',
      class: 'min-w-[890px] 2xl:min-w-[973px] b3 text-gray-1000 title-text',
      type: 'iconTitle'
    },
    {
      header: 'Service',
      field: 'tag',
      class: 'min-w-[176px] caption-text',
      type: 'tag'
    },
    {
      header: 'Date',
      field: 'timestamp',
      class: 'min-w-[130px] caption-text',
      type: 'text'
    },
    {
      header: '',
      field: 'rowToggler',
      class: 'min-w-[85px] caption-text',
      type: 'action'
    }
  ];

  public static readonly columnFieldsForResolvedTab: IColumnFields[] = [
    {
      header: 'Notifications',
      field: 'title',
      class: 'min-w-[794px] 2xl:min-w-[875px] b3 text-gray-1000 title-text',
      type: 'iconTitle'
    },
    {
      header: 'Service',
      field: 'tag',
      class: 'min-w-[176px] caption-text',
      type: 'tag'
    },
    {
      header: 'Date',
      field: 'timestamp',
      class: 'min-w-[130px] caption-text',
      type: 'text'
    },
    {
      header: 'Resolved By',
      field: 'resolvedBy',
      class: 'min-w-[150px] caption-text',
      type: 'avatarText'
    },
    {
      header: '',
      field: 'rowToggler',
      class: 'min-w-[85px] caption-text',
      type: 'action'
    }
  ];

  public static readonly groupConfig: IGroupByFieldsConfigs = {
    actionNeeded: {
      header: 'Action Needed',
      class: 'ml-2 action-alert-count c2 text-gray-000',
      value: (data: AlertItemProps[]) =>
        data.filter((item: AlertItemProps) => item.type === 'actionNeeded')
          .length
    },
    alerts: {
      header: 'Alerts',
      class: (data: AlertItemProps[]) =>
        data.some(
          (item: AlertItemProps) => item.type === 'alerts' && !item.isRead
        )
          ? 'ml-2 notification-dot'
          : '',
      value: ''
    }
  };
}
