/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { SelectItem } from 'primeng/api';

// services
import { HomepageService } from '@portal/app/dashboard/home-page/services/home-page.service';
import { SpendChartService } from '@portal/app/dashboard/home-page/components/spend-chart/spend-chart.service';
import { NavigationService } from '@portal/app/shared/services/navigation.service';

// Type
import type { Legend } from './spend-chart.service';

@Component({
  selector: 'portal-spend-chart',
  templateUrl: './spend-chart.component.html'
})
export class SpendChartComponent implements OnInit, OnDestroy {
  donutChartOptions: any = {};
  isLoading = true;
  dimensions = 'channel';
  dimensionsOptions?: SelectItem[];
  isChartDataEmpty = false;
  public chartLegend: Legend[] = [];
  private destroy$ = new Subject<void>();

  constructor(
    private homepageService: HomepageService,
    private spendChartService: SpendChartService,
    private navigationService: NavigationService
  ) {}

  get isChartReady(): boolean {
    return (
      !this.isLoading &&
      !!this.donutChartOptions?.data &&
      !!this.donutChartOptions?.innerContent
    );
  }

  ngOnInit() {
    this.dimensionsOptions = [
      { label: 'By Channel', value: 'channel' },
      { label: 'By Ad Platform', value: 'adPlatform' }
    ];

    this.spendChartService.chartData$
      .pipe(takeUntil(this.destroy$))
      .subscribe((chartData) => {
        this.isChartDataEmpty = !chartData?.data?.length;

        this.donutChartOptions = chartData;
      });

    this.spendChartService.isLoading$
      .pipe(takeUntil(this.destroy$))
      .subscribe((loading) => (this.isLoading = loading));

    this.spendChartService.chartLegend$
      .pipe(takeUntil(this.destroy$))
      .subscribe((legends) => {
        this.chartLegend = legends;
      });

    this.homepageService.spendAllocationDimension$
      .pipe(takeUntil(this.destroy$))
      .subscribe((dimensions) => {
        if (dimensions) {
          this.dimensions = dimensions;
        }
      });
  }

  onDimensionsChange(newValue = '') {
    this.spendChartService.setChartDimensions([newValue]);

    const layoutData = {
      spendAllocationDimension: newValue
    };

    this.homepageService.updateHomepageLayout(layoutData).subscribe({
      error: (error: any) => console.error(error)
    });
  }

  navigateToPage(): void {
    this.navigationService.navigationMapping('spendAllocation', {
      filters: this.spendChartService.getFilters(),
      extraParams: {
        channel: this.spendChartService.getActivateChannels()
      }
    });
  }

  // Handles cleanup when the service is destroyed
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
