export interface ITargetTableColumns {
  label: string;
  value: string;
  ordinalPosition?: number;
  tableName?: string;
  comments?: string;
  columnDescription?: IColumnDescription;
}

export interface IColumnDescription {
  label?: string;
  description?: string;
  visibility?: string;
}

export enum Visibility {
  Hidden = 'Hidden',
  Required = 'Required',
  Optional = 'Optional'
}
