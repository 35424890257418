import { NgModule } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputNumberModule } from 'primeng/inputnumber';

@NgModule({
  declarations: [],
  imports: [
    InputNumberModule,
    MultiSelectModule,
    DropdownModule,
    CheckboxModule
  ],
  exports: [
    InputNumberModule,
    MultiSelectModule,
    DropdownModule,
    CheckboxModule
  ]
})
export class PrimengModule {}
