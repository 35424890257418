/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import dayjs from 'dayjs';

// Services
import { FilterService } from '@portal/app/dashboard/home-page/services/filter.service';
import { FormatService } from '@portal/app/dashboard/home-page/services/format.service';

// Types
import {
  DataResponse,
  FieldDefinitions,
  Filter,
  FilterValue
} from '@portal/app/shared/types';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

export interface IMmmModalResponse {
  name: string;
  conversionType: string;
  startDate: string;
  endDate: string;
  spend: number;
  noOfContributingTactics: number;
  latestModelRunTimestamp: string;
  mimModelVersion: number;
}

@Injectable({
  providedIn: 'root'
})
export class MmmBannerService {
  private dataSubject = new BehaviorSubject<DataResponse>({});
  data$ = this.dataSubject.asObservable();

  constructor(
    private filterService: FilterService,
    private formatService: FormatService
  ) {}

  getFilters(providedFilters: Filter[], brandId: number): Filter[] {
    const neededFilterIds = ['conversion_type', 'dateStart', 'dateStop'];
    const selectedFilters = providedFilters.filter((filter) =>
      neededFilterIds.includes(filter.literalId)
    );

    // Find the day filter and safely extract the start and end dates
    const dayFilter = providedFilters.find(
      (f: Filter) => f.literalId === 'day'
    );
    let startDate: FilterValue | null = null;
    let endDate: FilterValue | null = null;

    if (
      dayFilter &&
      Array.isArray(dayFilter.value) &&
      dayFilter.value.length >= 2
    ) {
      startDate = dayFilter.value[0] as FilterValue;
      endDate = dayFilter.value[1] as FilterValue;
    }

    const dateFilters: Filter[] = [];

    if (startDate) {
      dateFilters.push(
        this.filterService.createFilter({
          literalId: 'dateStart',
          type: 'string',
          value: startDate
        })
      );
    }

    if (endDate) {
      dateFilters.push(
        this.filterService.createFilter({
          literalId: 'dateStop',
          type: 'string',
          value: endDate
        })
      );
    }

    const hardcodedFilters = [
      this.filterService.createFilter({
        literalId: 'dataSets',
        type: 'string[]',
        value: ['mimData']
      }),
      this.filterService.createFilter({
        literalId: 'brand_id',
        type: 'number',
        value: brandId
      })
    ];

    const combinedFilters = [
      ...selectedFilters,
      ...hardcodedFilters,
      ...dateFilters
    ];

    return Array.from(
      new Map(
        combinedFilters.map((filter) => [filter.literalId, filter])
      ).values()
    );
  }

  formatData(
    dataSets: any,
    fieldDefinitions: FieldDefinitions
  ): IMmmModalResponse[] {
    if (!Array.isArray(dataSets) || typeof fieldDefinitions !== 'object') {
      return [];
    }

    // Check if dataSets has the expected structure and data
    if (dataSets.length === 0 || !dataSets[0].data) {
      return [];
    }

    // Prepare the data from dataSets
    return dataSets[0].data
      .map((dataSet: any) => {
        this.dataSubject.next(dataSet);
        return Object.keys(dataSet).reduce(
          (acc: any, key: string) => {
            const fieldDef = fieldDefinitions[key];
            const value = fieldDef ? dataSet[fieldDef.literalId] : dataSet[key];

            if (fieldDef) {
              if (value !== null && value !== undefined && value !== '') {
                acc[fieldDef.literalId] = this.formatService.formatValue(
                  fieldDef,
                  value
                );
              }
            } else if (key === 'conversionType') {
              acc[key] = value;
            }

            return acc;
          },
          {
            startDate: dayjs(dataSet.startDate).format('MMM D, YYYY'),
            endDate: dayjs(dataSet.endDate).format('MMM D, YYYY')
          }
        );
      })
      .filter((item: any) =>
        Object.values(item).every(
          (value) => value !== null && value !== undefined && value !== ''
        )
      );
  }

  generateTooltip(
    conversionType: string,
    startDate: string,
    endDate: string,
    spend: number,
    noOfContributingTactics: number,
    mimModelVersion: number
  ): string {
    return `
      <div class='w-[22rem]'>
        <div class='text-center border-solid border-t-0 border-x-0 border-b border-gray-900 p-2'>
          <div class='b3'>The Measured Incrementality Model (V${mimModelVersion})</div>
          <div class='c2 text-gray-500 pt-1'>Updated each Thursday at 8 AM EST</div>
        </div>
        <div class="py-4">
          <ul role="list" class="list-none m-0 p-0">
            <li class="flex justify-between b1">
              <div>Conversion Type</div>
              <div>${conversionType}</div>
            </li>
            <li class="flex justify-between b1">
              <div>Calibration Period</div>
              <div>${startDate} - ${endDate}</div>
            </li>
            <li class="flex justify-between b1">
              <div>Total Period Spend</div>
              <div>${spend}</div>
            </li>
            <li class="flex justify-between b1">
              <div>Contributing Tactics</div>
              <div>${noOfContributingTactics}</div>
            </li>
          </ul>
        </div>
      </div>
    `;
  }
}
