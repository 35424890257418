/* eslint-disable @typescript-eslint/naming-convention */

import { compact, filter, flatten } from 'lodash-es';
import {
  ICampaign,
  ICellConfigForSave,
  IConversionType,
  IPlatform
} from '@portal/app/dashboard/geo-doe-config/models';
import { UserRole } from '@portal/app/shared/models/userModel';
import { User } from '@portal/app/shared/types';
import { ICellSizeDropdownOptions } from '@portal/app/dashboard/geo-doe-config/models/ICellSizeDropdownOptions';
import { Status } from '@libs/apis';
import { IUserAccess } from '@libs/apis';
import { demoDefaultClient } from '@portal/app/shared/constants/constants';

export const cellTypeMap: Record<string, string> = {
  BAU: 'Anchor',
  CONTROL_SCALE: 'Scale',
  CONTROL_HOLDOUT: 'Holdout'
};

export const defaultValues = {
  defaultScaleFactor: 50,
  defaultSaturationFactor: 50,
  defaultTacticImpressionShare: 20,
  defaultMinTacticIncrementality: 50
};

export enum CellTypes {
  CONTROL_SCALE = 'CONTROL_SCALE',
  CONTROL_HOLDOUT = 'CONTROL_HOLDOUT',
  BAU = 'BAU',
  NON_BAU = 'nonBAU'
}

export enum StudyStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DRAFT = 'DRAFT',
  ARCHIVED = 'ARCHIVED',
  SCHEDULED = 'SCHEDULED',
  LIVE = 'LIVE',
  COMPLETED = 'COMPLETED',
  PENDING_LAUNCH_TODAY = 'PENDING_LAUNCH_TODAY',
  PENDING_LAUNCH = 'PENDING_TO_LAUNCH'
}

export enum inventoryRefPeriodSelection {
  REFRESH = 'REFRESH',
  DRAFT = 'DRAFT'
}

export const SPLIT_TYPE = 'GEO';
export const SCALE_TYPE = 'VALUE';
export const BAU_DATA_SOURCE_ID = 'ga-geo';
export const DEFAULT_FLUCTUATION = 100;
export const DATE_FORMAT = 'YYYY-MM-DD';
export const EXPERIMENT_DURATIONS_IN_DAYS = [58, 51, 44, 37, 30, 23, 16];
export const DEFAULT_EXPERIMENT_DURATIONS_IN_DAYS = 30;
export enum GeoSplitType {
  REGION = 'REGION',
  DMA = 'DMA',
  CITY = 'CITY',
  ZIP = 'ZIP',
  OUTWARD_CODE_ZIP = 'OUTWARD_CODE_ZIP'
}
export enum GeoSplitTypeLabel {
  REGION = 'State',
  DMA = 'DMA (US only)',
  CITY = 'City (International)',
  ZIP = 'Zipcode'
}
export const GEO_SPLIT_LEVEL = [
  {
    label: GeoSplitTypeLabel.REGION,
    value: GeoSplitType.REGION,
    disabled: false
  },
  {
    label: GeoSplitTypeLabel.DMA,
    value: GeoSplitType.DMA,
    disabled: false
  },
  {
    label: GeoSplitTypeLabel.CITY,
    value: GeoSplitType.CITY,
    disabled: false
  },
  {
    label: GeoSplitTypeLabel.ZIP,
    value: GeoSplitType.ZIP,
    disabled: false
  }
];

export const getFeasibilityColorClassAndLabel = (
  isFeasible: number
): { colorClass: string; label: string } => {
  let colorClass = 'color-red';
  let label = 'Not Recommended';
  switch (isFeasible) {
    case 1:
      label = 'Recommended';
      colorClass = 'color-dark-green';
      break;
    case 2:
      label = 'Not Recommended';
      colorClass = 'color-red';
      break;
    case 3:
      label = 'Minimum';
      colorClass = 'color-green';
      break;
    default:
      break;
  }
  return { colorClass, label };
};

export const displayChangesInSpend = (
  changeInSpend: number,
  cellTreatment: string
): string => {
  const value =
    cellTreatment === CellTypes.CONTROL_HOLDOUT ? -100.0 : changeInSpend;
  return isNaN(value) ? `0.00%` : `${value.toFixed(2)}%`;
};

export const displayConversionBusinessRisk = (
  conversionBusinessRisk: number,
  cellTreatment: string
): string => {
  const convRisk = `${conversionBusinessRisk.toFixed(2)}%`;
  return cellTreatment === CellTypes.CONTROL_SCALE
    ? 'N/A'
    : conversionBusinessRisk === 0
      ? convRisk
      : convRisk === '0.00%'
        ? `<${convRisk}`
        : convRisk;
};

export const allowedTotalCell = 1;

export const DEMO_CLIENT_ID = demoDefaultClient.clientId;
export const DEFAULT_CLIENT_ID = 10000;

export enum GeoDataSourceIds {
  SOT_GEO = 'sot-geo',
  FACEBOOK_GEOSPLIT = 'facebook-geosplit',
  ADWORDS_GEOSPLIT = 'adwords-geosplit',
  SNAPCHAT_GEOSPLIT = 'snapchat-geosplit',
  PINTEREST_GEOSPLIT = 'pinterest-geosplit',
  TIKTOK_GEOSPLIT = 'tiktok-geosplit',
  TIKTOK_GEOSPLIT_PROVINCE = 'tiktok-geo-province',
  TIKTOK_GEOSPLIT_DMA = 'tiktok-geo-dma',
  ADWORDS = 'adwords',
  FACEBOOK = 'facebook'
}

export const onlySOTAsPlatform: IPlatform = {
  name: 'Custom Cell',
  displayName: 'Lift Only',
  dataSourceId: 'sot-geo',
  geoDataSourceId: GeoDataSourceIds.SOT_GEO,
  oldGeoDataSourceId: [GeoDataSourceIds.SOT_GEO],
  isSupportedBySyncTool: false,
  level: '' as VendorLevel,
  vendorConfiguredAt: '' as VendorConfiguredAt,
  dtdMapPlatformName: 'Custom',
  disabled: false,
  platformIdentifier: null,
  compositeDataSourceId: 0,
  associatedCompositeDataSourceId: null
};

export const isSOTOnlyPlatform = (platform: IPlatform): boolean =>
  platform.name === onlySOTAsPlatform.name ||
  platform.name === onlySOTAsPlatform.displayName;

export const getGeoLocationIds = (
  cells: ICellConfigForSave[],
  isBAUCell: boolean
): number[] => {
  const filteredCell = isBAUCell
    ? filter(cells, (cell) => cell.cellType === CellTypes.BAU)
    : filter(cells, (cell) => cell.cellType !== CellTypes.BAU);
  return flatten(
    flatten(filteredCell).map(({ geoLocationIds }) => geoLocationIds)
  );
};

export enum Weekdays {
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
  SUNDAY = 'Sunday'
}

export enum ValidationSnapShotValues {
  PASS = 'PASS',
  FAIL = 'FAIL',
  WARNING = 'WARNING'
}

export enum ALERT_ACTION_TYPE {
  API_PENDING = 'API_PENDING',
  API_FIXED = 'API_FIXED',
  API_ERROR = 'API_ERROR',
  CLIENT_FIXED = 'CLIENT_FIXED',
  IGNORE = 'IGNORE'
}

export const getTotalSpend = (validSelectedCampaigns: ICampaign[]) =>
  +validSelectedCampaigns
    .reduce((prev, curr) => curr.spend + prev, 0)
    .toFixed(2);

export const getTotalConversions = (validSelectedCampaigns: ICampaign[]) =>
  +validSelectedCampaigns.reduce((prev, curr) => curr.conversions + prev, 0);

export const OVERLAPPING_DURATION = 14;

export const MS_OVERLAPPING_DURATION = 16;

export const isClientUser = (loggedInUser: User) => {
  if (loggedInUser) {
    const isClientRole = [UserRole.clientUser, UserRole.clientAdmin].includes(
      loggedInUser.role
    );
    const userAccesses: IUserAccess[] = (loggedInUser.roles || []).filter(
      (userAcc) => (userAcc.status = Status.ACTIVE)
    );
    const isClientRoleExist = userAccesses.map((userAccess) =>
      [UserRole.clientUser, UserRole.clientAdmin].includes(
        userAccess.role as UserRole
      )
    );
    return isClientRole || isClientRoleExist.includes(true);
  } else {
    return false;
  }
};

export const BAU_CELL_BUSINESS_QUESTION = 'N/A';

export enum DisableSync {
  false = 0,
  true = 1
}

export enum VendorLevel {
  campaign = 'CAMPAIGN',
  subCampaign = 'SUB_CAMPAIGN'
}

export enum VendorConfiguredAt {
  ID = 'ID',
  NAME = 'NAME'
}

export enum ReportConfigUpdateLabelAndValue {
  REPORT_DATE_UPDATE = 'Reporting dates updated',
  CELL_NAME_UPDATE = 'Cell name updated',
  CELL_TYPE_UPDATE = 'Cell type updated',
  BAU_MARKETS_UPDATE = 'BAU markets updated',
  TEST_MARKETS_UPDATE = 'Test markets updated'
}

export const healthChecksMapping: Record<string, string> = {
  vendorSeeding: 'Vendor Seeding (Last 90 days)',
  sotSeeding: 'Sot Seeding (Last 365 days)'
};

export enum MDLColors {
  red = 'color-red',
  lightGreen = 'light-green',
  green = 'green'
}

export const DEFAULT_MDL_FOR_SCALE_CELL = 6;

export const RED_MDL_RANGE_MIN = -2.99;
export const RED_MDL_RANGE_MAX = 2.99;

export const GREEN_MDL_RANGE_MIN = 3;
export const GREEN_MDL_RANGE_MAX = 6;

export const DIMINISHING_RETURNS = 1.5;

export const daysInQuarter = 90;
export const minDaysDiffBtwStartAndEndDate = 15;
export const daysInWeek = 7;
export const maxStudyDuration = 58;

export enum MS_MARKETS_CELL_TYPE {
  TEST = 'TEST',
  BAU = 'BAU'
}

export enum MS_MARKET_CELL_STATUS {
  AVAILABLE = 'Available',
  INFLIGHT = 'In-flight',
  SCHEDULED = 'Scheduled',
  COOLING = 'Available on $date',
  DRAFT = 'Draft'
}
export const mdlScaleValues = [6, 7, 8, 9, 10];
export const mdlHoldoutValues = [3, 4, 5, 6];
export const cellSizeValue = [10, 20, 30, 40];

export enum ConfigType {
  mdlScale = 'mdlScale',
  mdlHoldout = 'mdlHoldout',
  cellSize = 'cellSize'
}

export const dataScienceRecomended: Record<string, number> = {
  [ConfigType.mdlScale]: 6,
  [ConfigType.mdlHoldout]: 3,
  [ConfigType.cellSize]: 10
};

export const prepareMDLDropdownOptions = (
  values: number[],
  recomendedValue: number
): {
  label: string;
  value: number | null;
}[] => {
  return compact(
    values.map((val) => {
      const isRecomended = val === recomendedValue;
      return {
        label: isRecomended ? `${val} (Recomended)` : `${val}`,
        value: isRecomended ? null : val
      };
    })
  );
};

export const prepareCellSizeDropdownOptions = (
  values: number[]
): ICellSizeDropdownOptions[] => {
  return compact(
    values.map((val) => {
      return {
        label: `${val}% (Recomended)`,
        value: val
      };
    })
  );
};

export const prepareConversionTypeOptions = (
  conversionTypes: IConversionType[]
): {
  label: string;
  value: number;
}[] => {
  return compact(
    conversionTypes.map((type) => {
      const { conversionType, id } = type;
      return { label: conversionType, value: id };
    })
  );
};

export const prepareExperimentDurationOptions = (): {
  label: string;
  value: number;
}[] => {
  return EXPERIMENT_DURATIONS_IN_DAYS.map((duration) => {
    const weeks = Math.floor(duration / 7);
    const days = duration % 7;
    return {
      label: `${weeks} weeks (+${days} days)`,
      value: duration
    };
  });
};

export const GEO_DOE_PROPERTY_TYPE = 'cross-platform-doe';

export const VENDOR_DEPENDENCY_REMOVED = 'is_vendor_dependency_removed';

export enum NEW_MS_CONFIG_PARAM_NAME {
  NEW_MS_MIN_CONTRIBUTION = 'ds-min-contribution-per-cell',
  UPDATE_REPORTING_DATES_VISIBLE_TO = 'update-reporting-dates-visible-to'
}

export const fullSpanWidth = 12;

export const DATE_FORMAT_WITH_MONTH_ABBR = 'MMM DD, YYYY';

export const DATE_FORMAT_SEPERATED_BY_SLASH = 'MM/DD/YYYY';

export const countryIds = {
  UK: 263
};
