<div class="row CHART_GROUP" *ngIf="chartGroup">
  <div class="col-md-{{ chartGroup.span }} CHART_GROUP">
    <portal-title-bar
      [title]="chartGroup.label"
      [filterControls]="chartGroupFilterControls"
      (filterChanged)="updateChartGroupLayout($event)"
      [chartTableToggle]="chartTableToggle"
      [chartDataPoints]="{}"
      [fieldDefinitions]="fieldDefinitions"
      [overrideExport]="overrideExport"
      [showExportIcon]="true"
      (chartTableToggleChanged)="toggleChartTable($event)"
      (exportData)="overrideExportChartHandler()"
      class="CHART-TITLE-BAR"></portal-title-bar>

    <div class="d-flex justify-content-end">
      <div *ngIf="chartLegendGroup" class="ms-auto">
        <label class="control-label"
          ><span class="d-flex justify-content-end">Legends</span>
          <p-multiSelect
            id="chartGroupLegends"
            [options]="chartLegends"
            [(ngModel)]="selectedChartLegends"
            [maxSelectedLabels]="4"
            selectedItemsLabel="{0} items selected"
            class="d-block"
            appendTo="body"
            panelStyleClass="saas-legends-panel saas-ui"
            (onChange)="handleLegendItemsSelection()"></p-multiSelect>
        </label>
      </div>
    </div>
    <div class="row" id="chartGroup">
      <div
        class="col-md-12"
        *ngIf="chartGroupSelectedLayout && chartGroupSelectedLayout.elements">
        <div
          id="chartGroup_{{ chartCommonIndex }}"
          class="row"
          *ngFor="
            let chartCommon of chartGroupSelectedLayout.elements;
            let chartCommonIndex = index
          ">
          <div
            class="col-md-{{ chart.span }}"
            *ngFor="let chart of chartCommon.elements; let i = index">
            <portal-empty-state
              [showMessage]="
                chartData[chart.literalId] &&
                chartData[chart.literalId].data &&
                chartData[chart.literalId].data.data
                  ? chartData[chart.literalId].data.data.length === 0
                  : true
              ">
              <div class="chart-common" [chart]="charts[chart.literalId]"></div>
            </portal-empty-state>
          </div>
          <div class="col-md-12">
            <div class="row justify-content-center fw-boldish custom-legend">
              <div
                id="chartCommonLegend_{{ chartCommonIndex }}"
                class="col-md-2 px-3 pointer chart-custom-legend justify-content-center"
                [style.opacity]="legend.opacity"
                (click)="legendItemClick(legend.id)"
                [title]="legend.name"
                *ngFor="let legend of commonLegend[chartCommon.literalId]">
                <span class="dot" [style.background-color]="legend.color">
                </span>
                <button class="btn btn-link legend-link">
                  {{ legend.name }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="chartTableToggle && chartTableLayout && chartTableData">
    <portal-geo-experiment-result-chart-group-table
      id="chartDataTable"
      [tableWidget]="chartTableLayout"
      [fieldDefinitions]="fieldDefinitions"
      [cellType]="
        getCellType()
      "></portal-geo-experiment-result-chart-group-table>
  </div>
</div>
