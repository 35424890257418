import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@portal/app/store/app.state';
import { cloneDeep, isArray, isEqual } from 'lodash-es';
import { ApiService } from '@portal/app/shared/services/api.service';
import dayjs from 'dayjs';
import {
  ChartAvailableY,
  ChartSelectedY,
  DataGridAvailable,
  DataGridSelected,
  ElementGroup,
  ElementSubTyping,
  ElementTyping,
  PersistenceOperations,
  PersistenceRequest,
  ProductDashboardPersistenceMode
} from '@portal/app/shared/types';
import { selectStore } from '@portal/app/store/app.selectors';
import { DashboardActions } from '../dashboard/actions';

@Injectable()
export class DashboardEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private apiService: ApiService
  ) {}

  $saveDataGrid = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DashboardActions.setDataGrid),
        withLatestFrom(this.store.select(selectStore)),
        tap(([action, state]) => {
          if (
            state.dashboard.dashboardPersistence ===
            ProductDashboardPersistenceMode.dashboardPersistence
          ) {
            const layoutToSend = cloneDeep(state.dashboard.layout);
            const originalLayout = cloneDeep(state.dashboard.layout);
            const {
              dataGridSelected,
              dataGridElement: dataGridLiteralId,
              orderChange
            } = action;
            // get data grid whole layout
            const dataGridLayout = layoutToSend?.find(
              (el) => el.layoutType === 'DATA_SET'
            );
            const originalLayoutDataGridElement = this.getDataGridLayoutElement(
              originalLayout,
              dataGridLiteralId
            );

            const dataGridLayoutElement = this.getDataGridLayoutElement(
              layoutToSend,
              dataGridLiteralId
            );
            if (dataGridLayout) {
              const initiallySelected: string[] = [];
              if (isArray(dataGridLayoutElement?.selected)) {
                dataGridLayoutElement?.selected.forEach((el) => {
                  initiallySelected.push(
                    (el as DataGridSelected).dashboardFieldId
                  );
                });
              }
              // get initial available elements, so we can create new selected out of them
              const initialAvailableElements = cloneDeep(
                dataGridLayoutElement?.available
              ) as DataGridAvailable[];
              // creating all new selected elements out of all available elements
              let newGeneratedSelectedElements =
                initialAvailableElements?.filter((el) =>
                  dataGridSelected.includes(el.dashboardFieldId)
                ) as (DataGridSelected | DataGridAvailable)[];
              if (newGeneratedSelectedElements) {
                // sort by initial order of columns as they were sent from data grid because creating them
                // in the above step is resorting it
                newGeneratedSelectedElements.sort(
                  (a, b) =>
                    dataGridSelected.indexOf(a.dashboardFieldId) -
                    dataGridSelected.indexOf(b.dashboardFieldId)
                );
                const availableDimensions = (
                  originalLayoutDataGridElement
                    ? (originalLayoutDataGridElement.available as DataGridAvailable[])
                    : []
                ).filter(
                  (e) =>
                    e.type === ElementTyping.dimension &&
                    e.subType === ElementSubTyping.available
                );
                let selectedColumnCount = availableDimensions.length + 1;
                newGeneratedSelectedElements = newGeneratedSelectedElements.map(
                  (el: DataGridSelected | DataGridAvailable) => {
                    el.subType = ElementSubTyping.selected;
                    // assign display order to all columns, so their order can be saved
                    if (el.type === ElementTyping.column) {
                      el.displayOrder = selectedColumnCount;
                      selectedColumnCount++;
                    }
                    return el;
                  }
                );
                // assign new selected elements to selected field
                if (dataGridLayoutElement) {
                  dataGridLayoutElement.selected = newGeneratedSelectedElements;
                }
              }
              // sort initial and new selected metrics/dimensions, so we can compare if they are the same or different
              initiallySelected.sort();
              dataGridSelected.sort();
              // check if layout has changed, if so update the layout
              if (
                (!isEqual(originalLayout, layoutToSend) &&
                  !isEqual(dataGridSelected, initiallySelected) &&
                  layoutToSend &&
                  dataGridSelected.length) ||
                (layoutToSend && orderChange)
              ) {
                this.apiService.overrideUserLayout(layoutToSend);
              }
            }
          }
        })
      ),
    { dispatch: false }
  );

  $saveDataGridSort = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DashboardActions.setDataGridSort),
        withLatestFrom(this.store.select(selectStore)),
        tap(([action, state]) => {
          if (
            state.dashboard.dashboardPersistence ===
            ProductDashboardPersistenceMode.dashboardPersistence
          ) {
            const {
              sortedColumns,
              dashboardId,
              dashboardLayoutId,
              dashboardLayoutLiteralId
            } = action;

            const persistSortReqBody: PersistenceRequest = {
              dashboardId,
              dashboardLayoutId,
              dashboardLayoutLiteralId,
              persistenceOperation: PersistenceOperations.dataSetColumnSort,
              data: sortedColumns.map((sortItem, i) => ({
                dashboardFieldId: sortItem.dashboardFieldId,
                subType: sortItem.subType,
                displayOrder: i + 1,
                dashboardLayoutLiteralId: sortItem.dashboardLayoutLiteralId
              }))
            };

            this.apiService.persistLayout(persistSortReqBody);
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * Hack: PMDEV-4108 Filters the `selectedY` array to ensure it contains no more than two objects.
   * This is a temporary workaround to limit the number of selected Y-axis elements.
   *
   * @param selectedY - The array of selected Y-axis elements.
   * @returns Filtered array containing a maximum of 2 elements.
   */
  private filterSelectedY(selectedY: ChartAvailableY[]): ChartAvailableY[] {
    return selectedY?.slice(0, 2); // Limit to a maximum of 2 elements
  }

  $saveChart = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DashboardActions.setChart),
        withLatestFrom(this.store.select(selectStore)),
        tap(([action, state]) => {
          if (
            state.dashboard.dashboardPersistence ===
            ProductDashboardPersistenceMode.dashboardPersistence
          ) {
            const { chartSelectedY, chartElement } = action;
            const layoutToSend = cloneDeep(state.dashboard.layout);
            const originalLayout = cloneDeep(state.dashboard.layout);
            // get data grid whole layout
            const chartLayout = layoutToSend?.find(
              (el) => el.literalId === 'chart'
            );
            if (chartLayout) {
              // if the breakdown exist find the matching element, otherwise just take the only one [0]
              let layoutElement = chartLayout.elements[0];
              if (chartElement) {
                const findElement = chartLayout.elements.find(
                  (el) => el.literalId === chartElement
                );
                if (findElement) {
                  layoutElement = findElement;
                }
              }
              const initiallySelectedY: string[] = [];
              if (isArray(layoutElement?.selectedY)) {
                layoutElement?.selectedY.forEach((el) => {
                  initiallySelectedY.push(
                    (el as ChartSelectedY).dashboardFieldId
                  );
                });
              }
              // get initial available elements, so we can create new selected out of them
              const initialAvailableElements = cloneDeep(
                layoutElement?.availableY
              ) as ChartAvailableY[];
              // creating all new selected elements out of all available elements
              let newGeneratedSelectedElements =
                initialAvailableElements?.filter((el) =>
                  chartSelectedY.includes(el.dashboardFieldId)
                );
              // sort by primary and secondary metric
              newGeneratedSelectedElements.sort(
                (a, b) =>
                  chartSelectedY.indexOf(a.dashboardFieldId) -
                  chartSelectedY.indexOf(b.dashboardFieldId)
              );

              newGeneratedSelectedElements = this.filterSelectedY(
                newGeneratedSelectedElements
              );

              if (newGeneratedSelectedElements) {
                // because we are making selected elements out of available we should change subtype to selected instead available
                let count = 1;
                newGeneratedSelectedElements.forEach(
                  (el: ChartAvailableY | ChartSelectedY) => {
                    el.subType = ElementSubTyping.selectedY;
                    el.displayOrder = count;
                    count++;
                  }
                );
                // assign new selected elements to selected field
                if (layoutElement) {
                  layoutElement.selectedY = newGeneratedSelectedElements;
                }
              }
              initiallySelectedY.sort();
              chartSelectedY.sort();
              // save new layout changes
              if (
                !isEqual(originalLayout, layoutToSend) &&
                !isEqual(chartSelectedY, initiallySelectedY) &&
                layoutToSend &&
                chartSelectedY.length
              ) {
                this.apiService.overrideUserLayout(layoutToSend);
              }
            }
          }
        })
      ),
    { dispatch: false }
  );

  $saveHeroGroup = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DashboardActions.setHeroGroup),
        withLatestFrom(this.store.select(selectStore)),
        tap(([action, state]) => {
          if (
            state.dashboard.dashboardPersistence ===
            ProductDashboardPersistenceMode.dashboardPersistence
          ) {
            const layoutToSend = cloneDeep(state.dashboard.layout);
            const originalLayout = cloneDeep(state.dashboard.layout);
            if (layoutToSend) {
              const findMatchingLayout = layoutToSend.find(
                (el) => el.literalId === action.heroGroupName
              );
              if (findMatchingLayout) {
                findMatchingLayout.elements.forEach((el) => {
                  const foundElement = action.heroGroupItems.find(
                    (i) => i.literalId === el.literalId
                  );
                  if (foundElement) {
                    el.displayOrder = foundElement.displayOrder;
                  }
                });
              }
              // check if layout has changed, if so update the layout
              if (!isEqual(originalLayout, layoutToSend)) {
                this.apiService.overrideUserLayout(layoutToSend);
              }
            }
          }
        })
      ),
    { dispatch: false }
  );

  $saveFilter = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DashboardActions.setFilter),
        withLatestFrom(this.store.select(selectStore)),
        tap(([action, state]) => {
          const copiedFilters = cloneDeep(state.dashboard.copiedFilters);
          const filterData = cloneDeep(action.filter);
          const updatedFilters = state.dashboard.updatedFilters
            ? state.dashboard.updatedFilters
            : [];
          if (
            state.dashboard.dashboardPersistence ===
            ProductDashboardPersistenceMode.dashboardPersistence
          ) {
            if (filterData && filterData.literalId !== 'chartDimensions') {
              const copyOfFilterToUpdate = copiedFilters?.find(
                (el) => el.literalId === filterData.literalId
              );
              // start filters from backend so if user recalls the call with same filter it won't save them
              if (
                copyOfFilterToUpdate &&
                isEqual(copyOfFilterToUpdate.value, filterData.value)
              ) {
                return;
              }
              // if filter is date range take start and end date and format it and then compare it
              if (
                copyOfFilterToUpdate &&
                filterData.value &&
                typeof filterData.value === 'object' &&
                'startDate' in filterData.value
              ) {
                if (
                  isEqual(copyOfFilterToUpdate.value, [
                    filterData.value.startDate.format('YYYY-MM-DD'),
                    filterData.value.endDate.format('YYYY-MM-DD')
                  ])
                ) {
                  return;
                }
              }
              // update existing filter(this.copiedFilters) with new value, so it won't get stuck in the start filters
              // and won't update it
              if (copyOfFilterToUpdate) {
                copyOfFilterToUpdate.value = filterData.value;
              }
              // if filter  value is date object change it to array of 2 strings(start/end) date
              if (
                filterData.value &&
                typeof filterData.value === 'object' &&
                'startDate' in filterData.value
              ) {
                filterData.value = [
                  dayjs(filterData.value.startDate).format('YYYY-MM-DD'),
                  dayjs(filterData.value.endDate).format('YYYY-MM-DD')
                ];
              }
              updatedFilters.push(filterData);
              this.store.dispatch(
                DashboardActions.setUpdatedFilters({ updatedFilters })
              );
              this.apiService.overrideUserFilters(
                updatedFilters,
                filterData.literalId === 'inc_data_source' &&
                  state.dashboard.dimensionFormat
                  ? state.dashboard.dimensionFormat
                  : undefined
              );
              this.store.dispatch(
                DashboardActions.setCopiedFilters({ copiedFilters })
              );
            }
          }
        })
      ),
    { dispatch: false }
  );

  private getDataGridLayoutElement(
    layout: ElementGroup[],
    dataGridLiteralId: string
  ) {
    const dataGridLayout = layout.find(
      (el) => el.layoutType === 'DATA_SET'
    ) || {
      elements: []
    };
    return (
      dataGridLayout.elements.find(
        (el) => el.literalId === dataGridLiteralId
      ) || dataGridLayout.elements[0]
    );
  }
}
