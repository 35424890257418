// Decorator to notify developer which @Input() is required
// eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/no-explicit-any
export const Required = (target: object, propertyKey: string): any =>
  Object.defineProperty(target, propertyKey, {
    get: () => {
      throw new Error(`Attribute ${propertyKey} is required`);
    },
    set: (value): void => {
      Object.defineProperty(target, propertyKey, {
        value,
        writable: true,
        configurable: true
      });
    },
    configurable: true
  });
