import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  autoUpdate,
  computePosition,
  flip,
  offset,
  shift
} from '@floating-ui/dom';
import {
  BulkSelectionMode,
  customRangeValue,
  DateRangePickerComponent as DRP,
  finalEndingDate,
  initialStartingDate,
  LocaleConfig
} from '@libs/date-range-picker';
import { FilterSetUtil } from '@portal/app/dashboard/filter-set/filter-set-util';
import { monthDateYearFormat } from '@portal/app/shared/constants/constants';
import { CompareService } from '@portal/app/shared/services/compare.service';
import {
  allTime,
  autoEndDate,
  autoStartDate,
  autoValue,
  CurrentDropdownOption,
  custom,
  customCompare,
  DateTimeService,
  latestThirtyDays,
  previousMonth,
  previousPeriod,
  previousQuarter,
  previousYear
} from '@portal/app/shared/services/date-time.service';
import { SelectionService } from '@portal/app/shared/services/selection.service';
import {
  ContextField,
  DateRange,
  DateRangeData,
  Filter
} from '@portal/app/shared/types';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import utc from 'dayjs/plugin/utc';
import { cloneDeep } from 'lodash-es';
import { Subscription } from 'rxjs';

dayjs.extend(utc);
dayjs.extend(quarterOfYear);
dayjs.extend(advancedFormat);

interface CompareDropdownOption {
  name: string;
}
type DateType = 'startDate' | 'endDate' | 'startDateCompare' | 'endDateCompare';

export interface BulkModeDropdownOption {
  label: string;
  value: BulkSelectionMode;
}

const bulkModeOptions: BulkModeDropdownOption[] = [
  {
    label: 'Week',
    value: BulkSelectionMode.weekly
  },
  {
    label: 'Month',
    value: BulkSelectionMode.monthly
  }
];

@Component({
  selector: 'portal-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(DRP) datePicker?: DRP;
  @ViewChild('calendarInput', { static: true }) calenderInput!: ElementRef;
  @ViewChild('calendarPopup', { static: true }) calenderPopup!: ElementRef;
  @Output() showCalendarChange = new EventEmitter<boolean>();
  @Output() cancelEvent = new EventEmitter<void>();
  // Label to be shown in the UI
  @Input() label = 'Time Period';
  // calendar side
  @Input() leftSide = false;
  // Show or hide the preset selection
  @Input() showPresetSelection = true;
  // Show or hide from/to input pickers
  @Input() showInputPickers = true;
  // Show or hide the picker wrapper (inputs)
  @Input() showDatePickerWrapper = true;
  // relative date value
  @Input() relativeDate: Filter | null = null;
  // how to show dates
  @Input() dateFormatType = monthDateYearFormat;
  // Minimum date allowed to be chosen in the date pickers
  @Input() minDate: dayjs.Dayjs =
    this.dateService.systemWideMinDateForDatePicker();

  // Maximum date allowed to be chosen in the date pickers
  @Input() maxDate: dayjs.Dayjs | undefined = this.dateService.yesterday();
  @Input() allowedDuration = 0;
  // date from filter set component
  @Input() date?: DateRange | null;
  // Bulk Mode Dropdown Selections
  // @Input() bulkModeOptions?: BulkModeDropdownOption[];
  @Input() dateFilter: ContextField | undefined = undefined;
  @Input() compareFilter: Filter | undefined = undefined;
  @Input() currentRangeOptions: CurrentDropdownOption[] =
    this.dateService.currentRangeOptions;

  @Input() datePickerInputStyleClass = 'form-control form-control-sm';
  @Input() containerClass = '';
  @Input() datePickerInputWidth = '';

  @Input() hideDatePickerAfterApply = false;
  @Input() pageCentered = false;
  @Input() showApplyForAll = false;
  // emmit date to filter set through this
  @Input() availabilityInfoTextRequired = true;
  @Output() dateChanged = new EventEmitter<DateRangeData[]>();
  // emmit compare option changed event
  @Output() selectedCompareOptionChanged = new EventEmitter<string>();
  compareDateRangeValue?: DateRange | null;
  compareToggledOn = this.compareService.compare;
  locale: LocaleConfig = {};

  compareEnabled = this.compareService.compareIsAvailable;
  // options to select dates

  public settingsManagerOptions: CurrentDropdownOption[] =
    this.dateService.settingsManagerOptions;

  public benchmarksRangeOptions: CurrentDropdownOption[] =
    this.dateService.benchmarkRangeOptions;

  public selectedBulkMode: BulkSelectionMode = BulkSelectionMode.weekly;
  public bulkModeOptions: BulkModeDropdownOption[] = [];

  // options to select dates for previous period
  public compareRangeOptions: CompareDropdownOption[] = [
    { name: previousPeriod },
    { name: previousMonth },
    { name: previousQuarter },
    { name: previousYear },
    { name: customCompare }
  ];

  // show/hide the calendar pick
  public showCalendar = false;
  // selected value from rangesBasic
  public selectedOption = this.currentRangeOptions.find(
    (o) => o.name === latestThirtyDays
  ) as CurrentDropdownOption;

  // selected value from rangesCompare
  public selectedCompareOption = this.compareRangeOptions.find(
    (o) => o.name === previousPeriod
  ) as CompareDropdownOption;

  // showing in the input next to calendar picker
  public startDate = '';
  public endDate = '';
  public startCompareDate = '';
  public endCompareDate = '';
  // selected date
  public selected: DateRange;
  // selected compare date
  public selectedCompare: DateRange;
  // using this to turn on/off calendar( to reload it)
  public showCalendarComponent = true;
  //disabling startDate
  public disableStartDate = false;
  //disabling endDate
  public disableEndDate = false;
  // showing in the input(where calendar is opened)
  public dateToShowSingleRange = '';
  public dateToShowCompareRange = '';
  public readonly psuedoMaxEndDate = finalEndingDate.clone();
  private cleanupPopup?: () => void;
  public previousSelected: DateRange;
  public previousSelectedCompare: DateRange;
  public previousCompareToggledOn = this.compareToggledOn;
  public isSettingsManagerScreen = false;
  private subs: Subscription = new Subscription();
  public benchmarksLiteralId = 'measured-benchmarks';
  firstDayOfWeekIncluded: string[] = [
    'cross-channel',
    'home-page',
    'media-plan-optimizer',
    'ddi-reporting-revenue',
    'ddi-reporting-conversion',
    'export-report'
  ];

  public isApplyButtonDisabled = false;
  public isOutOfDateRange = false;

  availabilityInfoText = `Latest complete data available from ${this.formatDate(
    this.dateService.maximumEndDate(),
    'MMMM D, YYYY'
  )}`;

  isApplyForAllChecked = false;

  // calculate previous date based on how many days is selected in initial date and return the same range in the past
  // if selected is March 5 - March 8 it will return March 1 - March 4 ( range is 4 days)
  private static calculatePreviousPeriod(date: DateRange) {
    const dayDifference: number = date.endDate.diff(date.startDate, 'day') + 1;
    return {
      startDate: dayjs(date.startDate, monthDateYearFormat)
        .utc(true)
        .subtract(dayDifference, 'day'),
      endDate: dayjs(date.startDate, monthDateYearFormat)
        .utc(true)
        .subtract(1, 'day')
    };
  }

  static calculatePrevious(
    date: DateRange,
    timePeriod: 'month' | 'quarter' | 'year'
  ) {
    return {
      startDate: dayjs(date.startDate, monthDateYearFormat)
        .utc(true)
        .subtract(1, timePeriod),
      endDate: dayjs(date.endDate, monthDateYearFormat)
        .utc(true)
        .subtract(1, timePeriod)
    };
  }

  private static calculateCompareDate(selectedOption: string, date: DateRange) {
    if (selectedOption === previousPeriod) {
      return DateRangePickerComponent.calculatePreviousPeriod(date);
    }
    if (selectedOption === previousMonth) {
      return DateRangePickerComponent.calculatePrevious(date, 'month');
    }
    if (selectedOption === previousQuarter) {
      return DateRangePickerComponent.calculatePrevious(date, 'quarter');
    }
    if (selectedOption === previousYear) {
      return DateRangePickerComponent.calculatePrevious(date, 'year');
    }
    return DateRangePickerComponent.calculatePreviousPeriod(date);
  }

  constructor(
    public readonly dateService: DateTimeService,
    public readonly compareService: CompareService,
    private readonly route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private selectionService: SelectionService
  ) {
    this.selected = {
      startDate: this.dateService.twoDaysAgo(),
      endDate: this.dateService.twoDaysAgo()
    };
    this.selectedCompare = {
      startDate: this.dateService.twoDaysAgo(),
      endDate: this.dateService.twoDaysAgo()
    };
    this.previousSelected = this.selected;
    this.previousSelectedCompare = this.selectedCompare;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.maxDate && this.dateFilter && this.dateFilter.bulkModeValue) {
      this.selectedBulkMode = this.dateFilter.bulkModeValue;
      this.availabilityInfoText = this.generateAvailabilityInfo(
        dayjs(changes.maxDate.currentValue)
      );
    }

    // Check if 'relativeDate' input has changed
    if (changes.relativeDate) {
      const newRelativeDate = changes.relativeDate
        .currentValue as Filter | null;

      // Check if the new relative date exists and is not a custom range value
      if (newRelativeDate && newRelativeDate.value !== customRangeValue) {
        const matchingOption = this.currentRangeOptions.find(
          (option) => option.name === newRelativeDate.value
        );

        // If a matching option is found, set it as the selected option
        if (matchingOption) {
          this.selectedOption = matchingOption;

          // Update start and end dates based on the selected option
          this.selected.startDate = dayjs(matchingOption.action?.startDate);
          this.selected.endDate = dayjs(matchingOption.action?.endDate);
        } else {
          // Handle case where no matching option is found
          this.checkInitialDates();
        }
      } else {
        // Handle cases where the new relative date is custom or null
        this.checkInitialDates();
      }
    }
  }

  generateAvailabilityInfo(maxDate: dayjs.Dayjs): string {
    let periodType: 'month' | 'week' | 'day';
    let formatTemplate = this.dateFormatType;

    switch (this.selectedBulkMode) {
      case 'Monthly':
        periodType = 'month';
        formatTemplate = 'MMMM YYYY';
        break;
      case 'Weekly':
        periodType = 'week';
        formatTemplate = 'MMMM Do, YYYY';
        break;
      default:
        periodType = 'day';
        break;
    }
    return `The most recent available data is from the ${periodType} of ${this.formatDate(
      maxDate.startOf(periodType),
      formatTemplate
    )}`;
  }

  ngOnInit(): void {
    this.compareDateRangeValue = this.compareFilter
      ? FilterSetUtil.setAsDateRange(this.compareFilter.value as string[])
      : null;
    this.handleBulkModeOnLoad();

    if (this.relativeDate && this.relativeDate.value !== customRangeValue) {
      const matchingRelativeOption = this.currentRangeOptions.find(
        (opt) => opt.name === this.relativeDate?.value
      );
      // if relative day value matches the possible selections set that option otherwise set day filter value(or initial value if there is no day filter value)
      if (matchingRelativeOption) {
        this.selectedOption = matchingRelativeOption;
        this.selected.startDate = dayjs(
          matchingRelativeOption.action?.startDate
        );
        this.selected.endDate = dayjs(matchingRelativeOption.action?.endDate);
      } else {
        this.checkInitialDates();
      }
    } else {
      this.checkInitialDates();
    }
    if (
      this.compareDateRangeValue &&
      this.compareDateRangeValue.startDate < this.selected.endDate &&
      this.compareDateRangeValue.endDate < this.selected.startDate
    ) {
      this.selectedCompare = this.compareDateRangeValue;
    } else {
      this.calculateCompareDate();
    }
    if (this.compareService.compare) {
      this.compareService.currentDate = this.selected;
      this.compareService.previousDate = this.selectedCompare;
    }
    if (!this.compareEnabled) {
      this.compareToggledOn = false;
    }
    // if this is MIM settings manager, add new fields
    if (
      this.route.snapshot.url.find((s) => s.path.includes('settings')) != null
    ) {
      this.isSettingsManagerScreen = true;
      this.currentRangeOptions = [
        ...this.settingsManagerOptions,
        ...this.currentRangeOptions
      ];
    }
    if (
      this.route.snapshot.url.find((s) =>
        s.path.includes(this.benchmarksLiteralId)
      ) != null
    ) {
      this.currentRangeOptions = this.benchmarksRangeOptions;
    }
    this.cacheSelectedValues(
      this.selected,
      this.selectedCompare,
      this.compareToggledOn
    );
    this.calculateSelectedOption();
    this.calculateSelectedCompareOption();
    this.formatBaseInput();
    this.refreshInputDates();
    if (
      this.route.snapshot.url.find((s) =>
        this.firstDayOfWeekIncluded.includes(s.path)
      ) != null
    ) {
      this.locale.firstDay =
        this.selectionService.getSelection().brand?.firstDayOfWeek;
    }
  }

  calculateCompareDate() {
    this.selectedCompare = DateRangePickerComponent.calculatePreviousPeriod(
      this.selected
    );
  }

  checkInitialDates() {
    // if date is parsed set it else set range to last 30 days
    if (this.date) {
      this.selected.startDate = this.date.startDate;
      this.selected.endDate = this.date.endDate;
    } else {
      this.selected = this.dateService.calculatePrevious30Days();
    }
  }

  cacheSelectedValues(
    selected: DateRange,
    selectedCompare: DateRange,
    compareToggledOn: boolean
  ): void {
    this.previousSelected = cloneDeep(selected);
    this.previousSelectedCompare = cloneDeep(selectedCompare);
    this.previousCompareToggledOn = cloneDeep(compareToggledOn);
  }

  rollbackSelectedValues(): void {
    this.selected = cloneDeep(this.previousSelected);
    this.selectedCompare = cloneDeep(this.previousSelectedCompare);
    this.compareToggledOn = cloneDeep(this.previousCompareToggledOn);
  }

  openCalendar() {
    if (!this.showCalendar) {
      this.toggleCalendarComponent();
    }
  }

  // open / close calendar
  toggleCalendarComponent(event?: MouseEvent) {
    this.validateAllowedDuration();
    if (event) {
      event.preventDefault();
    }
    this.changeDetectorRef.detach();
    this.showCalendar = !this.showCalendar;
    if (!this.showCalendar) {
      if (this.cleanupPopup) {
        this.cleanupPopup();
      }
      this.showCalendarChange.emit(this.showCalendar);
    }
    this.changeDetectorRef.detectChanges();
    this.changeDetectorRef.reattach();
    this.cleanupPopup = autoUpdate(
      this.calenderInput.nativeElement,
      this.calenderPopup.nativeElement,
      () => {
        computePosition(
          this.calenderInput.nativeElement,
          this.calenderPopup.nativeElement,
          {
            placement: 'bottom-end',
            middleware: [offset(7), flip(), shift({ padding: 7 })]
          }
        ).then(({ x, y }) => {
          Object.assign(this.calenderPopup.nativeElement.style, {
            left: `${x}px`,
            top: `${y}px`
          });
        });
      }
    );
  }

  toggleCompare(value: boolean) {
    this.compareToggledOn = value;
    this.compareService.compare = value;
    this.compareService.previousDate = this.selectedCompare;
  }

  // select date from dropdown as  option
  changeDateRangeOption(selectedOption: string) {
    if (selectedOption === 'Custom') {
      this.checkCustomOptions(selectedOption);
      return;
    }
    const rangeOption = this.currentRangeOptions.find(
      (el) => el.name === selectedOption
    )?.action;
    if (rangeOption) {
      this.selected = cloneDeep(rangeOption);
    }
    this.checkCustomOptions(selectedOption);
    this.selectedCompare = DateRangePickerComponent.calculatePreviousPeriod(
      this.selected
    );
    this.selectedCompareOption = this
      .compareRangeOptions[0] as CompareDropdownOption;
    this.refreshInputDates();
    this.updateCalendarValue();
  }

  calculateAutoSelection() {
    if (
      this.compareTwoFormattedDates(
        this.selected.startDate,
        initialStartingDate
      ) &&
      !this.compareTwoFormattedDates(this.selected.endDate, finalEndingDate)
    ) {
      this.selectedOption = this
        .currentRangeOptions[0] as CurrentDropdownOption;
    }
    if (
      this.compareTwoFormattedDates(this.selected.endDate, finalEndingDate) &&
      !this.compareTwoFormattedDates(
        this.selected.startDate,
        initialStartingDate
      )
    ) {
      this.selectedOption = this
        .currentRangeOptions[1] as CurrentDropdownOption;
    }
    if (
      this.compareTwoFormattedDates(
        this.selected.startDate,
        initialStartingDate
      ) &&
      this.compareTwoFormattedDates(this.selected.endDate, finalEndingDate)
    ) {
      this.selectedOption = this
        .currentRangeOptions[2] as CurrentDropdownOption;
    }
  }

  checkCustomOptions(selectedOption: string): void {
    let isDisabledSelected = true;
    switch (selectedOption) {
      case autoStartDate:
        this.disableStartDate = true;
        this.disableEndDate = false;
        this.startDate = autoValue;
        isDisabledSelected = false;
        break;
      case autoEndDate:
        this.disableStartDate = false;
        this.disableEndDate = true;
        this.endDate = autoValue;
        isDisabledSelected = false;
        break;
      case allTime:
        this.disableEndDate = true;
        this.disableStartDate = true;
        this.startDate = autoValue;
        this.endDate = autoValue;
        isDisabledSelected = false;
        break;
    }
    if (isDisabledSelected) {
      this.disableEndDate = false;
      this.disableStartDate = false;
    }
    this.refreshInputDates();
  }

  // select date from dropdown as  option
  changeCompareDateRangeOption(selectedOption: string) {
    this.selectedCompare = DateRangePickerComponent.calculateCompareDate(
      selectedOption,
      this.selected
    );
    this.refreshInputDates();
    this.updateCalendarValue();
  }

  asCurrentDropdownOption(val: unknown): CurrentDropdownOption {
    return val as CurrentDropdownOption;
  }

  asCompareDropdownOption(val: unknown): CompareDropdownOption {
    return val as CompareDropdownOption;
  }

  asInputElement(val: unknown): HTMLInputElement {
    return val as HTMLInputElement;
  }

  asDateRange(val: unknown): DateRange {
    return val as DateRange;
  }

  // select date
  selectCurrentDateFromCalendar(event: DateRange) {
    this.selected.startDate = event.startDate;
    this.selected.endDate = event.endDate;
    this.selectedCompare = DateRangePickerComponent.calculateCompareDate(
      this.selectedCompareOption.name,
      this.selected
    );
    if (!this.disableEndDate && !this.disableStartDate) {
      this.selectedOption = this.currentRangeOptions.find(
        (o) => o.name === custom
      ) as CurrentDropdownOption;
    }
    this.selectedCompareOption = this.compareRangeOptions.find(
      (o) => o.name === previousPeriod
    ) as CompareDropdownOption;
    this.refreshInputDates();
    this.updateCalendarValue();
    this.validateAllowedDuration();
  }

  // select date
  selectCompareDateFromCalendar(event: DateRange) {
    this.selectedCompare.startDate = event.startDate;
    this.selectedCompare.endDate = event.endDate;
    this.selectedCompareOption = this.compareRangeOptions.find(
      (o) => o.name === customCompare
    ) as CompareDropdownOption;
    this.refreshInputDates();
    this.updateCalendarValue();
  }

  // reset the calendar( off than on) to be able to apply new dates
  public updateCalendarValue() {
    this.validateAllowedDuration();
    this.showCalendarComponent = false;
    setTimeout(() => {
      this.showCalendarComponent = true;
      this.changeDetectorRef.detectChanges();
    }, 0);
  }

  // when the user is changing the date in the input from, if date is valid apply the new value
  // else return to the previous value
  applyFromInputField(inputDate: string, dateType: DateType) {
    const date = dayjs(inputDate, monthDateYearFormat).utc(true);
    // check if the date is not greater than today date or is it a valid date
    if (
      !isNaN(date.date()) &&
      date.isBefore(this.maxDate) &&
      (date.isSame(this.minDate, 'day') || date.isAfter(this.minDate, 'day'))
    ) {
      switch (dateType) {
        case 'startDate':
          // set entered date
          this.selected.startDate = date;
          this.startDate = this.formatDate(date);
          // if the start date is greater than end date, set start and end date to the entered date
          if (date > this.selected.endDate) {
            this.selected.endDate = date;
            this.endDate = this.formatDate(date);
          }
          this.selectedOption = this.currentRangeOptions.find(
            (o) => o.name === custom
          ) as CurrentDropdownOption;
          break;
        case 'endDate':
          // set entered date
          this.selected.endDate = date;
          this.endDate = this.formatDate(date);
          // if the entered end date is less than start date, set start and end date to the initial start date
          if (date < this.selected.startDate) {
            this.selected.endDate = this.selected.startDate;
            this.startDate = this.formatDate(this.selected.startDate);
            this.endDate = this.formatDate(this.selected.startDate);
          }
          this.selectedOption = this.currentRangeOptions.find(
            (o) => o.name === custom
          ) as CurrentDropdownOption;
          break;
        case 'startDateCompare':
          // if the entered compare start date is greater than regular start date or greater than compare end date, return compare date to old value
          if (
            date > this.selected.startDate ||
            date > this.selectedCompare.endDate
          ) {
            this.refreshInputDates();
          } else {
            // set entered date
            this.selectedCompare.startDate = date;
            this.startCompareDate = this.formatDate(date);
            this.selectedCompareOption = this.currentRangeOptions.find(
              (o) => o.name === custom
            ) as CurrentDropdownOption;
          }
          break;
        case 'endDateCompare':
          // if the entered compare end date is greater than regular start date or lower than compare start date, return compare date to old value
          if (
            date > this.selected.startDate ||
            date < this.selectedCompare.startDate
          ) {
            this.refreshInputDates();
          } else {
            // set entered date
            this.selectedCompare.endDate = date;
            this.endCompareDate = this.formatDate(date);
            this.selectedCompareOption = this.currentRangeOptions.find(
              (o) => o.name === custom
            ) as CurrentDropdownOption;
          }
          break;
      }
      this.updateCalendarValue();
    }
    // if the date is selected before the minimum date allowed, it is defaulted to the minimum date instead of previous date
    else if (!isNaN(date.date()) && date.isBefore(this.minDate)) {
      const previousStartCompareDate = this.selectedCompare.startDate;
      const previousEndCompareDate = this.selectedCompare.endDate;

      switch (dateType) {
        case 'startDate':
          this.selected.startDate = this.minDate;
          this.startDate = this.formatDate(this.minDate);
          this.selectedOption = this.currentRangeOptions.find(
            (o) => o.name === custom
          ) as CurrentDropdownOption;
          break;
        case 'startDateCompare':
          this.selectedCompare.startDate = previousStartCompareDate;
          this.startCompareDate = this.formatDate(previousStartCompareDate);
          this.selectedCompareOption = this.compareRangeOptions.find(
            (o) => o.name === customCompare
          ) as CompareDropdownOption;
          break;
        case 'endDateCompare':
          this.selectedCompare.endDate = previousEndCompareDate;
          this.endCompareDate = this.formatDate(previousEndCompareDate);
          this.selectedCompareOption = this.compareRangeOptions.find(
            (o) => o.name === customCompare
          ) as CompareDropdownOption;
          break;
      }
    } else {
      // if the date is invalid or not passing requirements just return prev values to input's
      this.refreshInputDates();
    }
    this.updateCalendarValue();
  }

  // Cancel date range selection. Rollback the selected values & refresh the formatted data.
  cancel() {
    this.showCalendar = false;
    // Rollback the values and refresh the input dates
    this.rollbackSelectedValues();
    this.refreshInputDates();
    this.formatBaseInput();
    this.calculateSelectedOption();
    if (this.dateFilter && this.dateFilter.bulkModeValue) {
      this.selectedBulkMode = this.dateFilter
        .bulkModeValue as BulkSelectionMode;
    }
    this.checkInitialDates();
    this.cancelEvent.emit();
  }

  // Apply the selected date range and send it to the filter set to call new api
  apply() {
    this.isApplyButtonDisabled = true;

    this.formatBaseInput();
    this.calculateSelectedOption();
    const relativeDateValue =
      this.selectedOption.name === 'Custom'
        ? customRangeValue
        : this.selectedOption.name;
    const dateRageData: DateRangeData[] = [
      {
        name: 'regularDate',
        value: [this.selected.startDate, this.selected.endDate],
        filterLiteralId: this.dateFilter?.name || '',
        type: 'DateRange'
      },
      {
        name: 'day',
        value: [this.selected.startDate, this.selected.endDate],
        filterLiteralId: 'day',
        type: 'DateRange'
      },
      {
        name: 'compareDate',
        value: [this.selectedCompare.startDate, this.selectedCompare.endDate],
        filterLiteralId: 'compare_date',
        type: 'DateRange'
      },
      {
        name: 'relativeDate',
        value: relativeDateValue,
        filterLiteralId: this.relativeDate?.literalId || '',
        type: 'string'
      },
      {
        name: 'compare',
        value: this.compareToggledOn,
        filterLiteralId: 'compare',
        type: 'boolean'
      },
      {
        name: 'bulkMode',
        value: this.selectedBulkMode,
        filterLiteralId: this.dateFilter?.bulkModeFilterLiteralId || '',
        type: 'boolean'
      },
      {
        name: 'selectedCompareOption',
        value: this.selectedCompareOption?.name,
        filterLiteralId: 'selected_compare_option',
        type: 'string'
      },
      {
        name: 'applyForAll',
        value: this.isApplyForAllChecked,
        filterLiteralId: 'apply_for_all',
        type: 'boolean'
      }
    ];
    this.compareService.currentDate = this.selected;
    this.compareService.previousDate = this.selectedCompare;
    this.cacheSelectedValues(
      this.selected,
      this.previousSelected,
      this.compareToggledOn
    );
    this.dateChanged.emit(dateRageData);
    this.compareService.setCompareValue(this.compareService.compare);
    if (this.isSettingsManagerScreen) {
      this.showCalendar = false;
    } else if (this.hideDatePickerAfterApply) {
      this.showCalendar = false;
    }

    // Emit the selected compare option name
    this.selectedCompareOptionChanged.emit(this.selectedCompareOption.name);
  }

  ngOnDestroy() {
    if (this.cleanupPopup) {
      this.cleanupPopup();
    }
    this.subs.unsubscribe();
  }

  // update variables that store date(showing in input) when date is selected in calendar
  public refreshInputDates() {
    this.startDate = this.formatDate(this.selected.startDate);
    this.endDate = this.formatDate(this.selected.endDate);
    if (this.selectedOption.name === autoStartDate) {
      this.startDate = autoValue;
    }
    if (this.selectedOption.name === autoEndDate) {
      this.endDate = autoValue;
    }
    if (this.selectedOption.name === allTime) {
      this.startDate = autoValue;
      this.endDate = autoValue;
    }
    this.startCompareDate = this.formatDate(this.selectedCompare.startDate);
    this.endCompareDate = this.formatDate(this.selectedCompare.endDate);
  }

  // return formatted date in string(for showing dates in input form(s))
  formatDate(date: dayjs.Dayjs, template = this.dateFormatType): string {
    return date.format(template);
  }

  // calculate what is selected option, custom if there is no matching dates with calculating dates
  public calculateSelectedOption() {
    // optionFound that checks if the selected date matches on of the options,
    // if not than for the selection option select Custom
    let optionFound = true;
    this.currentRangeOptions.forEach((i) => {
      if (
        i.action &&
        this.compareTwoFormattedDates(
          this.selected.startDate,
          i.action.startDate
        ) &&
        this.compareTwoFormattedDates(
          this.selected.endDate,
          i.action.endDate
        ) &&
        optionFound
      ) {
        optionFound = false;
        this.selectedOption = i;
      }
    });
    if (optionFound) {
      // selected option is Custom
      this.selectedOption = this.currentRangeOptions.find(
        (o) => o.name === custom
      ) as CurrentDropdownOption;
    }
    if (this.disableEndDate || this.disableEndDate) {
      this.calculateAutoSelection();
    }
    this.checkCustomOptions(this.selectedOption.name);
  }

  // calculate what is selected compare option, custom compare if there is no matching dates with calculating dates
  public calculateSelectedCompareOption() {
    // condition that checks if the selected compare date matches one of the options,
    // if not, then the selection option select Custom Compare
    let condition = true;
    this.compareRangeOptions.forEach((i) => {
      const calculatedDate = DateRangePickerComponent.calculateCompareDate(
        i.name,
        this.selected
      );
      if (
        this.compareTwoFormattedDates(
          calculatedDate.startDate,
          this.selectedCompare.startDate
        ) &&
        this.compareTwoFormattedDates(
          calculatedDate.endDate,
          this.selectedCompare.endDate
        )
      ) {
        if (
          this.compareRangeOptions.find((o) => o.name === i.name)?.name !==
          customCompare
        ) {
          this.selectedCompareOption = this.compareRangeOptions.find(
            (o) => o.name === i.name
          ) as CurrentDropdownOption;
          condition = false;
          // Emit the selected compare option name
          this.selectedCompareOptionChanged.emit(
            this.selectedCompareOption.name
          );
        }
      }
    });
    if (condition) {
      // if nothing is found then the selected option is Custom Compare
      this.selectedCompareOption = this.compareRangeOptions.find(
        (o) => o.name === customCompare
      ) as CurrentDropdownOption;
      // Emit the selected compare option name
      this.selectedCompareOptionChanged.emit(this.selectedCompareOption.name);
    }
  }

  // return if 2 dates are the same or not(true | false)
  compareTwoFormattedDates(firstDate: dayjs.Dayjs, secondDate: dayjs.Dayjs) {
    return this.formatDate(firstDate) === this.formatDate(secondDate);
  }

  // refreshing value that is showing in input form, date range showed
  public formatBaseInput() {
    this.dateToShowCompareRange = `${this.formatDate(
      this.selected.startDate
    )} - ${this.formatDate(this.selected.endDate)} | ${this.formatDate(
      this.selectedCompare.startDate
    )} - ${this.formatDate(this.selectedCompare.endDate)}`;
    // if auto start/end date is don't show the whole formatted dates,  instead show auto text and start/end date
    if (
      (this.disableStartDate && !this.disableEndDate) ||
      (!this.disableStartDate && this.disableEndDate)
    ) {
      this.dateToShowSingleRange = `${
        this.disableStartDate
          ? 'Auto '
          : this.formatDate(this.selected.startDate)
      } - ${
        this.disableEndDate ? 'Auto ' : this.formatDate(this.selected.endDate)
      }`;
    } else {
      this.dateToShowSingleRange = this.getFormattedDate();
    }
  }

  private getFormattedDate(): string {
    if (this.dateFilter && this.dateFilter.bulkModeValue) {
      switch (this.dateFilter.bulkModeValue) {
        case 'Monthly':
          return this.formatDate(this.selected.startDate, 'MMMM YYYY');
        case 'Weekly':
          return `Week of ${this.formatDate(
            this.selected.startDate,
            'MMM Do, YYYY'
          )}`;
        default:
          return `${this.formatDate(
            this.selected.startDate
          )} - ${this.formatDate(this.selected.endDate)}`;
      }
    } else {
      return `${this.formatDate(this.selected.startDate)} - ${this.formatDate(
        this.selected.endDate
      )}`;
    }
  }

  // TODO: introduce config for different datepicker modes and other filters
  public getDatePickerLabel(): string {
    if (this.dateFilter && this.dateFilter.bulkModeValue) {
      return this.dateToShowSingleRange;
    } else {
      return this.compareToggledOn
        ? this.dateToShowCompareRange
        : this.selectedOption.name === 'Custom'
          ? this.dateToShowSingleRange
          : (this.disableStartDate && !this.disableEndDate) ||
              (!this.disableStartDate && this.disableEndDate)
            ? this.dateToShowSingleRange
            : this.selectedOption.name;
    }
  }

  updateDateBasedOnBulkMode(): void {
    if (this.selectedBulkMode === 'Weekly') {
      this.maxDate = this.dateService.calculateBulkWeeklyMaxDateForBenchmark();
      this.selected = this.dateService.calculateFirstMondayOfMonthFromDate(
        this.selected.startDate
      );
    } else {
      this.maxDate = this.dateService.setInitialMaxDateForBenchMarkBulkMode();
      this.selected = this.dateService.calculateCompleteMonthByDate(
        this.selected.startDate
      );
      if (this.selected.startDate > this.maxDate) {
        this.selected =
          this.dateService.calculateLastAvailableMonthForBenchmark();
      }
    }
  }

  private handleBulkModeOnLoad(): void {
    // Currently these feature is very restricted based on the Benchmark dashboard requirement
    if (this.dateFilter && this.dateFilter.bulkModeValue) {
      this.selectedBulkMode = this.dateFilter.bulkModeValue;
      this.bulkModeOptions = this.dateFilter.bulkModeOptions || bulkModeOptions;
      this.maxDate =
        this.selectedBulkMode === 'Weekly'
          ? this.dateService.calculateBulkWeeklyMaxDateForBenchmark()
          : this.dateService.setInitialMaxDateForBenchMarkBulkMode();
    }
  }

  private validateAllowedDuration() {
    if (!this.allowedDuration) {
      this.isApplyButtonDisabled = false;
      return;
    }

    const duration = this.dateService.startDateAndEndDateInclusiveDifference(
      this.endDate,
      this.startDate
    );
    const isDurationExceeded = duration > this.allowedDuration;

    this.isApplyButtonDisabled = isDurationExceeded;
    this.isOutOfDateRange = isDurationExceeded;
  }
}
