<ng-container *ngIf="widgetLayout">
  <div class="custom-card" *ngIf="data">
    <ng-container *ngFor="let item of widgetLayout.elements">
      <ng-container [ngSwitch]="item.layoutType">
        <ng-container *ngSwitchCase="'TITLE'">
          <h3 class="card-title">{{ item.label }}</h3>
        </ng-container>

        <portal-hero-group
          *ngSwitchCase="'DATA_POINT'"
          class="ow-data-point {{ item.literalId }}"
          [title]="nativeSectionsService.asString(item.label)"
          [name]="nativeSectionsService.asString(item.literalId)"
          [width]="item.span"
          [items]="nativeSectionsService.asHeroElements(item.elements)"
          [gridItem]="true"
          [overrideWidth]="12"
          [fieldDefinitions]="fieldDefinitions"></portal-hero-group>

        <portal-hero-group
          *ngSwitchCase="'DATA_POINT_SM'"
          class="ow-data-point-sm {{ item.literalId }}"
          [title]="nativeSectionsService.asString(item.label)"
          [name]="nativeSectionsService.asString(item.literalId)"
          [width]="item.span"
          [items]="nativeSectionsService.asHeroElements(item.elements)"
          [gridItem]="true"
          [overrideWidth]="12"
          [fieldDefinitions]="fieldDefinitions"></portal-hero-group>

        <ng-container *ngIf="!showChild && data">
          <ng-container
            *ngSwitchCase="'BOUNDED_DATA'"
            [ngTemplateOutlet]="radioSlider"
            [ngTemplateOutletContext]="{
              item: item,
              data: data,
            }">
          </ng-container>
        </ng-container>
        <ng-container *ngIf="showChild">
          <ng-container
            *ngSwitchCase="'CHILD_BOUNDED_DATA'"
            [ngTemplateOutlet]="radioSlider"
            [ngTemplateOutletContext]="{
              item: item,
              data: data,
            }">
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template #radioSlider let-item="item" let-data="data">
  <div class="card-sub-title mt-2">
    {{ item.label }}
  </div>
  <div class="radio-slider-container">
    <portal-radio-button-bar
      [leftText]="'Conservative'"
      [rightText]="'Ambitious'"
      [isDisabled]="true"
      [options]="optimizeStrengthOptions"
      [selectedValue]="data[item.literalId]">
    </portal-radio-button-bar>
  </div>
</ng-template>
