/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil
} from 'rxjs/operators';
import { isEqual } from 'lodash-es';

// Services
import { DataSetService } from '@portal/app/dashboard/home-page/services/data-set.service';
import { FilterService } from '@portal/app/dashboard/home-page/services/filter.service';
import { ChannelsService } from '@portal/app/dashboard/home-page/services/channels.service';
import { MetricsService } from '@portal/app/dashboard/home-page/services/metrics.service';
import { HomepageService } from '@portal/app/dashboard/home-page/services/home-page.service';

// Types
import type { Filter } from '@portal/app/shared/types';
import type { Metric } from '@portal/app/dashboard/home-page/services/metrics.service';
export interface Channel {
  name: string;
  metrics: Metric[];
}

@Injectable({
  providedIn: 'root'
})
export class MyChannelsService implements OnDestroy {
  private allFilters: Filter[] = [];

  private channelDataSetsSubject = new BehaviorSubject<any[]>([]);
  channelDataSets$ = this.channelDataSetsSubject.asObservable();

  private metricsSubject = new BehaviorSubject<Metric[]>([]);
  metrics$ = this.metricsSubject.asObservable();

  private destroy$ = new Subject<void>();

  // Loading state
  private isLoadingSubject = new BehaviorSubject<boolean>(true);
  public isLoading$ = this.isLoadingSubject.asObservable();

  // Modal loading state
  public isModalLoadingSubject = new BehaviorSubject<boolean>(true);
  public isModalLoading$ = this.isModalLoadingSubject.asObservable();

  // Options selected by user in customize dashboard modal
  private channelViewMetrics: string[] = [];
  private channelTileMetrics: string[] = [];

  constructor(
    private channelsService: ChannelsService,
    private dataSetService: DataSetService,
    private homepageService: HomepageService,
    private filterService: FilterService,
    private metricsService: MetricsService
  ) {
    this.subscribeToChanges();
  }

  private subscribeToChanges() {
    combineLatest([
      this.filterService.filters$,
      this.channelsService.selectedChannels$,
      this.homepageService.channelViewMetrics$,
      this.homepageService.channelTileMetrics$
    ])
      .pipe(
        map(
          ([
            filters,
            selectedChannels,
            channelViewMetrics,
            channelTileMetrics
          ]) => ({
            filters,
            selectedChannels,
            channelViewMetrics,
            channelTileMetrics
          })
        ),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        debounceTime(300),
        takeUntil(this.destroy$)
      )
      .subscribe(
        ({
          filters,
          selectedChannels,
          channelViewMetrics,
          channelTileMetrics
        }) => {
          this.channelViewMetrics = channelViewMetrics;
          this.channelTileMetrics = channelTileMetrics;
          this.fetchData(selectedChannels, filters);
        }
      );

    combineLatest([
      this.channelsService.activeChannel$,
      this.filterService.filters$
    ])
      .pipe(
        map(([activeChannel, filters]) => ({
          activeChannel,
          filters
        })),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        debounceTime(300),
        takeUntil(this.destroy$)
      )
      .subscribe(({ activeChannel, filters }) => {
        if (activeChannel) {
          this.fetchMetricsData(filters, this.channelViewMetrics, [
            activeChannel
          ]);
        }
      });
  }

  fetchMetricsData(filters: Filter[], metrics: string[], channels: string[]) {
    this.isModalLoadingSubject.next(true);

    this.metricsService.fetchData(filters, metrics, channels).subscribe({
      next: (data) => {
        this.metricsSubject.next(data);
        this.isModalLoadingSubject.next(false);
      },
      error: (error) => {
        // Handle error
        console.error('PortfolioService fetchData error:', error);
        this.isModalLoadingSubject.next(false);
      }
    });
  }

  fetchData(selectedChannels: string[], filters: Filter[]): void {
    this.isLoadingSubject.next(true);

    const chartFilters = this.getChartFilters(selectedChannels, filters);
    this.allFilters = chartFilters;
    this.dataSetService
      .fetchDataSets({
        dimensions: ['channel'],
        filters: chartFilters
      })
      .subscribe({
        next: (dataSets) => {
          const newChannels = this.formatData(dataSets, selectedChannels);
          this.channelDataSetsSubject.next(newChannels);
          this.isLoadingSubject.next(false);
        },
        error: (error) => {
          // TODO:GP add error state
          console.error('Error fetching chart data', error);
          this.isLoadingSubject.next(false);
        }
      });
  }

  getChartFilters(
    selectedChannels: string[],
    providedFilters: Filter[]
  ): Filter[] {
    // Define which filters are needed for charts
    const neededFilterIds = [
      'dateStart',
      'dateStop',
      'previousDateStart',
      'previousDateStop',
      'relative_day',
      'conversion_type',
      'isInitialFilterCall',
      'resolution'
    ];

    // Select only necessary filters from providedFilters
    const selectedFilters = providedFilters.filter((filter) =>
      neededFilterIds.includes(filter.literalId)
    );

    // Hardcoded filters
    const hardcodedFilters = [
      this.filterService.createFilter({
        literalId: 'dataSets',
        type: 'string[]',
        value: ['channelSection']
      }),
      this.filterService.createFilter({
        literalId: 'channel',
        type: 'string[]',
        value: selectedChannels
      }),
      this.filterService.createFilter({
        literalId: 'showDatasetDrivers',
        type: 'string',
        value: true
      }),
      this.filterService.createFilter({
        literalId: 'showDatasetDrivers',
        type: 'string',
        value: true
      }),
      this.filterService.createFilter({
        literalId: 'metrics',
        type: 'string',
        value: {
          channelSection: this.channelTileMetrics
        } as unknown as string
      })
    ];

    // Combine selected provided filters with hardcoded filters
    const combinedFiltersMap = new Map(
      selectedFilters
        .concat(hardcodedFilters)
        .map((filter) => [filter.literalId, filter])
    );

    return Array.from(combinedFiltersMap.values());
  }

  formatData(dataSets: any, selectedChannels: string[]): Channel[] {
    if (!Array.isArray(dataSets) || !Array.isArray(selectedChannels)) {
      return [];
    }

    // Prepare the data from dataSets
    const formattedData = dataSets[0].data.map((dataSet: any) => ({
      name: dataSet.channel,
      metrics: this.metricsService.formatData(dataSet, this.channelTileMetrics)
    }));

    // Add channels from selectedChannels not present in dataSets with metrics: []
    selectedChannels.forEach((channel) => {
      if (!formattedData.some((data: any) => data.name === channel)) {
        formattedData.push({
          name: channel,
          metrics: this.metricsService.formatData({}, this.channelTileMetrics)
        });
      }
    });

    // Sort formattedData based on the order of channels in selectedChannels
    formattedData.sort(
      (a: any, b: any) =>
        selectedChannels.indexOf(a.name) - selectedChannels.indexOf(b.name)
    );

    return formattedData;
  }

  public getFilters() {
    return this.allFilters;
  }

  // Handles cleanup when the service is destroyed
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
