<p-menu
  class="m-menu"
  #menu
  [model]="menuItems"
  [popup]="true"
  [style]="style"
  [styleClass]="styleClass"
  [appendTo]="appendTo"
  [autoZIndex]="autoZIndex"
  [baseZIndex]="baseZIndex"
  [showTransitionOptions]="showTransitionOptions"
  [id]="id"
  (onShow)="onMenuOpen()"
  (onHide)="onMenuClose()"></p-menu>
<m-button
  type="menu"
  [styleClass]="isMenuOpen ? 'active has-icon' : 'has-icon'"
  [badge]="buttonBadge"
  [disabled]="disabled"
  [style]="style"
  [ariaLabel]="ariaLabel"
  (click)="menu.toggle($event)">
  <ng-content></ng-content>
  @if (!isMenuOpen && showArrow) {
    <i aria-hidden="true" class="material-symbols-outlined icon-small ml-2">
      keyboard_arrow_down
    </i>
  }
  @if (isMenuOpen && showArrow) {
    <i aria-hidden="true" class="material-symbols-outlined icon-small ml-2">
      keyboard_arrow_up
    </i>
  }
</m-button>
