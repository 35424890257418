import { Location } from '@angular/common';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import {
  Brand,
  BrandRow,
  CurrentStatus,
  DisplayDashboard,
  Filter,
  FilterOption,
  FilterValue
} from '../types';
import { CurrencySymbol } from '../types/currency-format';
import { CurrencyService } from './currency.service';
import { SelectionService } from './selection.service';
import { UserModel } from '@portal/app/shared/models/userModel';
import { BehaviorSubject, take } from 'rxjs';
import { DashboardService } from '@portal/app/shared/services/dashboard.service';
import { compact, isArray, isEmpty } from 'lodash-es';
import {
  getDefaultDashboard,
  findRouterLinkByLiteralId
} from '@portal/app/dashboard/utils';
import { FeatureConfigService } from '@portal/app/shared/services/feature-config.service';
import { ConfigDashboardService } from './config-dashboard.service';
import { NavigationService } from './navigation.service';

interface InteractionData {
  key: string;
  subType: string;
  isHovered: boolean;
}

type BulkModeValue = 'day' | 'week' | 'month';

@Injectable({
  providedIn: 'root'
})
export class ViewService {
  private interactionSubject = new BehaviorSubject<InteractionData | null>(
    null
  );

  private selectedBulkMode = new BehaviorSubject<BulkModeValue>('week');
  public selectedBulkMode$ = this.selectedBulkMode.asObservable();
  public interactionEvent$ = this.interactionSubject.asObservable();

  constructor(
    private router: Router,
    private readonly selectionService: SelectionService,
    private readonly dashboardService: DashboardService,
    private currencyService: CurrencyService,
    private location: Location,
    private readonly featureConfig: FeatureConfigService,
    private readonly configDashboardService: ConfigDashboardService,
    private injector: Injector
  ) {}

  private get navigationService(): NavigationService {
    return this.injector.get(NavigationService);
  }

  switchBrand(row: BrandRow, user: UserModel): void {
    if (row.status === CurrentStatus.active && user) {
      // Set client/brand selection to be able to access it later.
      this.selectionService.setSelection(row.client, row.brand);

      this.featureConfig.fetchData();

      this.dashboardService
        .getDashboards(this.selectionService.getSelection())
        .pipe(take(1))
        .subscribe({
          next: (dashboards) => {
            this.handleRedirect(dashboards, row, user as UserModel);
          },
          error: (e) => console.error(e)
        });
    }
  }

  public handleRedirect(
    dashboards: DisplayDashboard[] = [],
    row: BrandRow,
    user: UserModel
  ) {
    const searchForLiteralId = this.extractLiteralIdFromUrl();

    if (searchForLiteralId) {
      this.configDashboardService
        .getConfigDashboardsByLiteralId()
        .then((configDashboards) => {
          const hasAccessToRouterLink = findRouterLinkByLiteralId(
            dashboards,
            searchForLiteralId
          );

          if (hasAccessToRouterLink) {
            this.setUpBrandCurrency(row.brand);
            this.redirectToReferrer(hasAccessToRouterLink);
          } else {
            const settingsMenu = this.navigationService.createSettingsMenuItems(
              configDashboards,
              'products'
            );

            const settingMenuItemWithAccess = settingsMenu.find((menuItem) => {
              return (
                searchForLiteralId ===
                this.extractLiteralIdFromUrl(menuItem.routerLink)
              );
            });

            if (settingMenuItemWithAccess) {
              this.setUpBrandCurrency(row.brand);
              this.redirectTo(settingMenuItemWithAccess.routerLink as string);
            } else {
              this.redirectToDashboard(dashboards, row, user);
            }
          }
        });
    } else {
      this.redirectToDashboard(dashboards, row, user);
    }
  }

  private extractLiteralIdFromUrl(
    currentUrl = this.location.path()
  ): string | null {
    const urlRegex = /\/products\/(?:[^/]+\/)?([^/?]+)/;
    const matchUrl = currentUrl.match(urlRegex);

    if (matchUrl) {
      let literalId = matchUrl[1];

      // Handle use cases specific to Geo
      switch (literalId) {
        case 'geoExperiments': //geo-doe/geoExperimentes
          literalId = 'geo-doe';
          break;
        case 'home': //geo-designer/home
          literalId = 'geo-designer';
          break;
        case 'admin':
          literalId = 'integration';
          break;
      }

      if (literalId) {
        return literalId;
      }
    }

    return null;
  }

  private redirectToReferrer(routerLink: {
    routerLinkPart: string;
    isMultiView: boolean;
  }): void {
    //TO DO: Remove when Optimization Report url is updated
    const modifyOptReportUrl = (part: string): string => {
      if (part === 'portfolio/optimization-report') {
        return part.replace('portfolio/', '');
      }
      return part;
    };

    const routerLinkPart = routerLink.routerLinkPart;
    const modifiedRouterLink = modifyOptReportUrl(routerLinkPart);

    const navigationExtraQueryParams = routerLink.isMultiView
      ? {
          isMultiView: routerLink.isMultiView
        }
      : undefined;

    this.redirectTo(
      `/a/${this.selectionService.buildSelectionSlug()}/products/${modifiedRouterLink}`,
      navigationExtraQueryParams
    );
  }

  public redirectToDashboard(
    dashboards: DisplayDashboard[] = [],
    row: BrandRow,
    user: UserModel
  ) {
    this.setUpBrandCurrency(row.brand);
    const defaultDashboard = getDefaultDashboard(dashboards, user);

    this.redirectTo(
      `a/${this.selectionService.buildSelectionSlug()}/products/${
        defaultDashboard?.routerLinkPart
      }`
    );
  }

  public redirectTo(uri: string, extraQueryParams = {}): void {
    this.router.navigate(['/login'], {
      skipLocationChange: true,
      queryParams: { returnUrl: uri, ...extraQueryParams }
    });
  }

  public setUpBrandCurrency(brand: Brand) {
    const currencyCode = brand.currencyCode ? brand.currencyCode : 'USD';
    const currencyDisplay: CurrencySymbol =
      this.currencyService.checkCurrencySymbol(brand);
    const currencyLocale = brand?.currencyLocale
      ? brand.currencyLocale
      : 'en-US';
    this.currencyService.updateCurrencyData({
      code: currencyCode,
      locale: currencyLocale,
      display: currencyDisplay
    });
  }

  setComponentInteraction(value: InteractionData): void {
    this.interactionSubject.next(value);
  }

  updateFilterValues(
    filters: Filter[] = [],
    chosenFilters: Filter[]
  ): Filter[] {
    return filters.map((filter) => {
      const f = chosenFilters.find((ff) => ff.literalId === filter.literalId);
      if (f && !f.literalId.startsWith('child')) {
        filter.value = f.value;
        filter.value = this.setupFirstOptionValue(filter);
      }
      return filter;
    });
  }

  setupFirstOptionValue(filter: Filter): FilterValue {
    if (
      isArray(filter.options) &&
      !isEmpty(compact(filter.options)) &&
      filter.options[0] &&
      typeof filter.options?.[0] === 'object' &&
      filter.value !== null &&
      !isEmpty(filter.value)
    ) {
      if (
        filter.type !== 'string[]' &&
        !(filter.options as unknown as FilterOption[])
          .map(({ value }) => value)
          .includes(filter.value as string)
      ) {
        filter.value = (filter.options as unknown as FilterOption[])[0]
          ?.value as FilterValue;
      }
    }
    return filter.value;
  }
}
