import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonComponentsModule } from '@libs/common-components';
import { CommonServicesModule } from '@libs/common-services';
import { DesignSystemModule } from '@libs/design-system';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { CampaignMappingUpdatePopoverComponent } from './components/campaign-mapping-update-popover/campaign-mapping-update-popover.component';
import { CampaignViewColumnHeaderComponent } from './components/campaigns-view/campaign-view-column-header/campaign-view-column-header.component';
import { CampaignViewFilterContextComponent } from './components/campaigns-view/campaign-view-filter-context/campaign-view-filter-context.component';
import { CampaignsViewComponent } from './components/campaigns-view/campaigns-view.component';
import { HeroMetricsComponent } from './components/hero-metrics/hero-metrics.component';
import { UnpublishedCampaignsComponent } from './components/review-campaigns/unpublished-campaigns.component';
import { LastElementVisibleDirective } from './helper/last-element-visibile.directive';
import { TaxonomyContainerComponent } from './taxonomy-container/taxonomy-container.component';
import { TaxonomyReviewPublishComponent } from './taxonomy-ui/taxonomy-review-publish/taxonomy-review-publish.component';
import { TaxonomyUiComponent } from './taxonomy-ui/taxonomy-ui.component';

@NgModule({
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    TableModule,
    CommonComponentsModule,
    ButtonModule,
    InputTextModule,
    ToastModule,
    CheckboxModule,
    ReactiveFormsModule,
    TooltipModule,
    DialogModule,
    CommonServicesModule,
    MessagesModule,
    ConfirmDialogModule,
    AutoCompleteModule,
    ChipsModule,
    AccordionModule,
    BadgeModule,
    InputSwitchModule,
    ListboxModule,
    TabViewModule,
    DesignSystemModule,
    DividerModule,
    CalendarModule,
    MenuModule,
    OverlayPanelModule,
    MultiSelectModule,
    SkeletonModule
  ],
  exports: [TaxonomyContainerComponent],
  declarations: [
    TaxonomyUiComponent,
    TaxonomyReviewPublishComponent,
    TaxonomyContainerComponent,
    HeroMetricsComponent,
    CampaignsViewComponent,
    CampaignViewFilterContextComponent,
    LastElementVisibleDirective,
    CampaignMappingUpdatePopoverComponent,
    UnpublishedCampaignsComponent,
    CampaignViewColumnHeaderComponent
  ]
})
export class TaxonomyUiModule {}
