import { inject, Injectable, signal } from '@angular/core';
import { Params } from '@angular/router';
import {
  ContextModalParams,
  ContextModalType
} from '@portal/app/dashboard/context-modal/context-model.types';
import { environment } from '@portal/environments/environment';
import { ContextStore } from '@portal/app/shared/state/context.store';
import { DataResponse, Filter } from '@portal/app/shared/types';
import {
  DateTimeService,
  DayJsDateFormat
} from '@portal/app/shared/services/date-time.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { SelectionService } from '@portal/app/shared/services/selection.service';

export const DATE_FORMAT = 'MMM DD, YYYY';

@Injectable()
export class ModalService {
  private selection = inject(SelectionService);
  private contextStore = inject(ContextStore);
  private dateTimeService = inject(DateTimeService);

  public static baseUriV1 = `${environment.apiDomain}/api/v1/bff/xcd/context-menu`;

  private conversionTypeSignal = signal<string>('');
  private modalTypeSignal = signal<ContextModalType>(ContextModalType.unknown);
  private comparePreviousPeriodSignal = signal<string>('');
  private compareCurrentPeriodSignal = signal<string>('');
  private modalParamsSignal = signal<ContextModalParams>(
    {} as ContextModalParams
  );

  private currentRowSignal = signal<ContextModalParams>(
    {} as ContextModalParams
  );

  public modalParams$ = toObservable(this.modalParams);

  constructor() {
    this.contextStore.filterContext.subscribe((filters: Filter[]) => {
      this.setConversionTypeFromFilter(filters);
    });
  }

  private setConversionTypeFromFilter(filters: Filter[]) {
    const value = filters.find((f) => f.literalId === 'conversion_type')?.value;
    if (value) {
      this.conversionTypeSignal.set(value.toString());
    }
  }

  public updateConversionTypeFromParams(params: Params): void {
    if (params.conversion_type) {
      try {
        const decoded = decodeURIComponent(params.conversion_type);
        this.conversionTypeSignal.set(decoded);
      } catch (e) {
        console.error('Error decoding conversion_type:', e);
        this.conversionTypeSignal.set(params.conversion_type);
      }
    }
  }

  get conversionType() {
    return this.conversionTypeSignal.asReadonly();
  }

  get modalType() {
    return this.modalTypeSignal.asReadonly();
  }

  get comparePreviousPeriod() {
    return this.comparePreviousPeriodSignal.asReadonly();
  }

  get compareCurrentPeriod() {
    return this.compareCurrentPeriodSignal.asReadonly();
  }

  get modalParams() {
    return this.modalParamsSignal.asReadonly();
  }

  get currentRow() {
    return this.currentRowSignal.asReadonly();
  }

  setCurrentRow(row: DataResponse | ContextModalParams) {
    const { channel, tactic } = row;
    const currentRow = {
      channel: channel as string,
      tactic: tactic as string
    };
    this.currentRowSignal.set(currentRow);
    this.setModalParams(currentRow);
  }

  setModalParams(params: ContextModalParams) {
    if (params.tactic) {
      this.modalTypeSignal.set(ContextModalType.tactic);
    } else {
      this.modalTypeSignal.set(ContextModalType.channel);
    }
    this.modalParamsSignal.set(params);
  }

  getParams(): Params {
    const { clientId, brandId } = this.selection.getClientIdAndBrandId();
    return { clientId, brandId };
  }

  getDateParams() {
    const dateParams: Params = {};
    const currentMonth = this.dateTimeService.calculatePrevious30Days();
    const previousMonth =
      this.dateTimeService.calculatePreviousPeriod(currentMonth);
    dateParams.dateStart = currentMonth?.startDate.format(
      DayJsDateFormat.fullDate
    );
    dateParams.dateStop = currentMonth?.endDate.format(
      DayJsDateFormat.fullDate
    );
    dateParams.previousDateStart = previousMonth?.startDate.format(
      DayJsDateFormat.fullDate
    );
    dateParams.previousDateStop = previousMonth?.endDate.format(
      DayJsDateFormat.fullDate
    );
    this.compareCurrentPeriodSignal.set(
      `${currentMonth?.startDate.format(DATE_FORMAT)} - ${currentMonth?.endDate.format(DATE_FORMAT)}`
    );
    this.comparePreviousPeriodSignal.set(
      `${previousMonth?.startDate.format(DATE_FORMAT)} - ${previousMonth?.endDate.format(DATE_FORMAT)}`
    );
    return dateParams;
  }
}
