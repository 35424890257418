// Carousel responsive options
export const RESPONSIVE_OPTIONS = [
  {
    breakpoint: '5120px',
    numVisible: 9,
    numScroll: 5
  },
  {
    breakpoint: '2560px',
    numVisible: 8,
    numScroll: 5
  },
  {
    breakpoint: '2280px',
    numVisible: 7,
    numScroll: 5
  },
  {
    breakpoint: '2120px',
    numVisible: 6,
    numScroll: 5
  },
  {
    breakpoint: '1920px',
    numVisible: 5,
    numScroll: 5
  },
  {
    breakpoint: '1680px',
    numVisible: 4,
    numScroll: 4
  }
];

// Metric description map
export const DESCRIPTION_MAP = {
  cpoI: 'This is the spend divided by the incremental orders from the media.',
  ordersI: 'This shows the incremental orders driven by your media.',
  percOrdersI:
    'This represents the incremental contribution from media to your total orders.',
  percSalesI:
    'This represents the incremental contribution from media to your total sales.',
  roasI: 'This is the incremental sales from the media divided by your spend.',
  salesI: 'This is the incremental sales driven by media.'
};
