import { Injectable } from '@angular/core';
import { Brand, CurrencyData } from '@portal/app/shared/types';
import {
  CurrencyCode,
  CurrencyFormat,
  CurrencySymbol
} from '@portal/app/shared/types/currency-format';
import { LocaleCode } from '@portal/app/shared/types/locale-codes';

const DEFAULT_CODE: CurrencyCode = 'USD';
const DEFAULT_LOCALE: LocaleCode = 'en-US';
const DEFAULT_DISPLAY: CurrencySymbol = 'symbol';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  private currencyValue: CurrencyData = {
    code: DEFAULT_CODE,
    locale: DEFAULT_LOCALE,
    display: DEFAULT_DISPLAY
  };

  public updateCurrencyData(value: CurrencyData): void {
    this.currencyValue = value;
  }

  get currencyConfig(): CurrencyData {
    return this.currencyValue;
  }

  public checkCurrencySymbol(brand: Brand): CurrencySymbol {
    // find the value in the CurrencyFormatMap
    if (String(CurrencyFormat.shortCode) === brand.currencyFormat) {
      return 'code';
    }
    if (String(CurrencyFormat.symbol) === brand.currencyFormat) {
      return 'symbol';
    }
    if (String(CurrencyFormat.symbolNarrow) === brand.currencyFormat) {
      return 'symbol-narrow';
    }
    return 'symbol';
  }
}
