@if (isLoading()) {
  <div class="h-full">
    <m-skeleton width="100%" height="290px"></m-skeleton>
  </div>
} @else if (!isDataAvailable()) {
  <!-- Empty state -->
  <portal-context-modal-card
    class="h-full"
    [card]="emptyStateCard()"
    (buttonAction)="onButtonAction()">
    <div>
      <m-card [variant]="cardVariantType.small">
        <div class="grid grid-cols-12 gap-4 items-center">
          <div
            class="b1 text-gray-1000 col-span-8"
            [innerHTML]="emptyStateCard().content"></div>
          <div class="col-span-4">
            <img
              width="100px"
              class="block ml-auto"
              src="assets/images/geo-tests.svg"
              alt="Optimization" />
          </div>
        </div>
      </m-card>
    </div>
  </portal-context-modal-card>
} @else if (type() === "LIVE") {
  <portal-context-modal-card
    class="h-full"
    [card]="liveGeoTestCard"
    (buttonAction)="onButtonAction()">
    <div class="grid gap-3 grid-cols-12">
      <div class="col-span-7">
        <m-card [variant]="cardVariantType.small">
          <div class="flex flex-col gap-2">
            @for (metric of liveGeoTestCard.metrics; track metric) {
              <div class="flex gap-2">
                <div class="b1 text-gray-800 flex-1">{{ metric.label }}</div>
                <div class="b2 text-gray-1000 flex-1">
                  {{ metric.formattedValue }}
                </div>
              </div>
            }
          </div>
        </m-card>
      </div>
      <div class="col-span-5">
        <m-card [variant]="cardVariantType.small">
          @for (icon of liveGeoTestCard.icons; track icon) {
            <div class="flex gap-2">
              <i
                class="material-symbols-outlined icon-small text-gray-500 mr-2">
                {{ icon.value }}
              </i>
              <div class="b1 text-gray-800">{{ icon.label }}</div>
            </div>
          }
        </m-card>
      </div>
    </div>
    <div class="mt-[20px] mb-4">
      <div class="flex justify-between mb-2 b1 text-gray-800">
        <div>{{ getLiveGeoTestDates() }}</div>
        <div>{{ getDaysLeftLabel() }}</div>
      </div>
      <m-progress-bar
        [value]="getDaysLeftPercent()"
        styleClass="success"></m-progress-bar>
    </div>
  </portal-context-modal-card>
} @else if (type() === "LatestResult") {
  <portal-context-modal-card
    class="h-full"
    [card]="latestTestResultCard"
    (buttonAction)="onButtonAction()">
    <div class="grid gap-3 grid-cols-2">
      @for (metric of latestTestResultCard.metrics; track metric) {
        <div class="col-span-1">
          <m-card [variant]="cardVariantType.small">
            <div class="flex flex-col items-center">
              <div class="b1 text-gray-800 mb-1.5">{{ metric.label }}</div>
              <div class="sh2 text-green-700">{{ metric.formattedValue }}</div>
            </div>
          </m-card>
        </div>
      }
    </div>
    <div class="flex justify-between border rounded-1 px-3 py-2.5 mt-3">
      @for (icon of latestTestResultCard.icons; track icon) {
        <div class="flex items-center">
          <i class="material-symbols-outlined text-gray-500 mr-2">
            {{ icon.value }}
          </i>
          <div class="b1 text-gray-1000">{{ icon.label }}</div>
        </div>
      }
    </div>
  </portal-context-modal-card>
} @else if (type() === "PossibleTestOpportunities") {
  <portal-context-modal-card
    class="h-full"
    [card]="testOpportunitiesCard"
    (buttonAction)="onButtonAction()">
    <table class="w-full">
      <thead class="c2 text-gray-1000 border-b-8 border-transparent">
        <tr>
          <th scope="col" class="w-2/4">Tactic</th>
          <th scope="col" class="w-1/4">Spend</th>
          <th scope="col" class="w-1/4">Contribution</th>
        </tr>
      </thead>
      <tbody class="c2 text-gray-800">
        @for (row of testOpportunities(); let last = $last; track row) {
          <tr>
            <td class="w-2/4 pe-2" [ngClass]="{ 'pb-[6px]': !last }">
              {{ row.tactic }}
            </td>
            <td class="w-1/4" [ngClass]="{ 'pb-[6px]': !last }">
              {{ row.spend.formattedValue }}
            </td>
            <td class="w-1/4" [ngClass]="{ 'pb-[6px]': !last }">
              {{ row.contribution.formattedValue }}
            </td>
          </tr>
        }
      </tbody>
    </table>
  </portal-context-modal-card>
}
