import { IHeaderConfig, ITitle, TitleTypes } from '@libs/common-components';
import { GeoTestMethod, GeoTestType } from '@libs/apis';
import { formatPercent } from '@angular/common';
import { GeoSplitType } from '@portal/app/dashboard/geo-doe-config/shared/constants';
import { DateTimeService } from '@portal/app/shared/services/date-time.service';
import { GeoCellDisplayStatus } from '@portal/app/geo-designer/models/ICPAdTestConfig';
import { ITestActionButton } from '@portal/app/geo-designer/models/ITestActionButton';
import { Confirmation } from 'primeng/api';
import { GeoTestAction } from '@portal/app/geo-designer/models/GeoTestAction';
import { GeoTestBudgetLevel } from '@portal/app/geo-designer/common/geo-tests-field-definition';
import { ButtonType } from '@design-system/components/m-button';
import { Dayjs } from 'dayjs';

export class GeoDesignerConstants {
  public static readonly recommendedTestCardId = 'recommended-test-action-card';

  public static readonly minStartDaysInFuture = 3;
  public static readonly geoTestDurationPaddingDays = 2;
  public static readonly daysInWeek = 7;
  public static readonly dateFormatLong = 'DD MMM, YYYY';
  public static readonly apiDateFormat = 'YYYY-MM-DD';
  public static readonly cellContaminationPaddingInDays = 16;
  public static readonly defaultReferenceDurationInDays = 30;
  public static readonly calendarMaxDateInMonths = 15;
  public static readonly defaultCalendarSelectionSizeInDays = 30;
  public static readonly defaultConcurrency = 2;
  public static readonly platformCompositeDataSourceId = {
    pinterest: 5,
    tiktok: 71,
    facebook: 1,
    adwords: 2
  };

  public static readonly designerVersion = {
    v1: 1,
    v2: 2
  };

  public static readonly headerConfig: IHeaderConfig = {
    primaryTitle: {
      defaultValue: 'Geo Tests',
      field: 'header',
      span: 12
    },
    faq: {
      field: 'https://support.measured.com/article/gaagjv18b5-geo-how-to',
      label: 'Get info about Geo Tests',
      span: 12
    },
    secondaryTitles: [],
    badges: []
  };

  public static readonly testTitleConfig: ITitle = {
    dataTracker: 'lib-ui-title__testOverview',
    title: '',
    styleClass: 'm-0 text-gray-1000',
    subTitle: null,
    type: TitleTypes.h4,
    ellipsisConfig: {
      title: {
        enabled: true,
        ellipsisMaxLength: 45
      }
    }
  };

  public static readonly allRecommendedTestsTitleConfigs: ITitle[] = [
    {
      title: 'Select a New Test',
      type: TitleTypes.h4,
      styleClass: 'mb-0 text-gray-1000',
      subTitle: null
    }
  ];

  public static readonly testTypeIcons: Record<GeoTestType, string> = {
    [GeoTestType.holdout]: 'block',
    [GeoTestType.scale]: 'stacked_line_chart',
    [GeoTestType.lift]: 'system_update_alt'
  };

  public static readonly testMethodIcons: Record<GeoTestMethod, string> = {
    [GeoTestMethod.auto]: 'smart_toy',
    [GeoTestMethod.manual]: 'front_hand'
  };

  public static readonly geoSplitTypeLabels: Record<string, string> = {
    [GeoSplitType.REGION]: 'States',
    [GeoSplitType.ZIP]: 'ZIP Codes',
    [GeoSplitType.OUTWARD_CODE_ZIP]: 'ZIP Codes',
    [GeoSplitType.DMA]: 'DMAs',
    [GeoSplitType.CITY]: 'Cities'
  };

  public static readonly scheduledTestMethodMessages: Record<
    GeoTestMethod,
    (date?: Dayjs | string) => string
  > = {
    [GeoTestMethod.auto]: (endDate?: Dayjs | string) =>
      `This test will launch automatically and changes will be switched back on ${DateTimeService.dayJsDate(
        endDate
      ).format(GeoDesignerConstants.dateFormatLong)}`,
    [GeoTestMethod.manual]: () => `This test cannot be launched automatically`
  };

  public static readonly recommendedTestMethodMessages: Record<
    GeoTestMethod,
    (date?: Dayjs | string) => string
  > = {
    [GeoTestMethod.auto]: (endDate?: Dayjs | string) =>
      `This test will launch automatically and changes will be switched back on ${DateTimeService.dayJsDate(
        endDate
      ).format(GeoDesignerConstants.dateFormatLong)}`,
    [GeoTestMethod.manual]: () => 'This test cannot be launched automatically'
  };

  public static readonly activeTestMethodMessages: Record<
    GeoTestMethod,
    (date?: Dayjs | string) => string
  > = {
    [GeoTestMethod.auto]: (endDate?: Dayjs | string) =>
      `This test is live. It's managed by Measured and the changes will be switched back on ${DateTimeService.dayJsDate(
        endDate
      ).format(GeoDesignerConstants.dateFormatLong)}`,
    [GeoTestMethod.manual]: (endDate?: Dayjs | string) =>
      `This test is managed by the advertiser. Changes will need to be reverted back on ${DateTimeService.dayJsDate(
        endDate
      ).format(GeoDesignerConstants.dateFormatLong)}`
  };

  public static readonly testActionButtons: ITestActionButton[] = [
    {
      label: 'Schedule',
      availableFor: [GeoCellDisplayStatus.none],
      style: ButtonType.primary,
      order: 1,
      action: GeoTestAction.saveAsActive,
      confirmationRequired: true
    },
    {
      label: 'Schedule',
      availableFor: [GeoCellDisplayStatus.actionNeeded],
      style: ButtonType.primary,
      order: 1,
      action: GeoTestAction.updateTestActive,
      confirmationRequired: true
    },
    {
      label: 'Save draft',
      availableFor: [GeoCellDisplayStatus.none],
      style: ButtonType.secondary,
      order: 2,
      action: GeoTestAction.saveAsDraft,
      confirmationRequired: true
    },
    {
      label: 'Save draft',
      availableFor: [
        GeoCellDisplayStatus.draft,
        GeoCellDisplayStatus.actionNeeded
      ],
      style: ButtonType.secondary,
      order: 2,
      action: GeoTestAction.updateTestDraft,
      confirmationRequired: true
    },
    {
      label: 'Revert to draft',
      availableFor: [GeoCellDisplayStatus.scheduled],
      style: ButtonType.secondary,
      order: 2,
      action: GeoTestAction.updateTestDraft,
      enabledAlways: true
    },
    {
      label: 'Schedule',
      availableFor: [GeoCellDisplayStatus.draft],
      style: ButtonType.primary,
      order: 2,
      action: GeoTestAction.updateTestActive,
      confirmationRequired: true,
      enabledAlways: true
    },
    {
      label: 'Download as PDF',
      availableFor: [
        GeoCellDisplayStatus.none,
        GeoCellDisplayStatus.scheduled,
        GeoCellDisplayStatus.draft,
        GeoCellDisplayStatus.inProgress
      ],
      style: ButtonType.tertiary,
      order: 3,
      action: GeoTestAction.downloadSummary,
      enabledAlways: true
    },
    {
      label: 'Download as PDF',
      availableFor: [
        GeoCellDisplayStatus.resultsPending,
        GeoCellDisplayStatus.pendingToLaunch,
        GeoCellDisplayStatus.finished
      ],
      style: ButtonType.primary,
      order: 3,
      action: GeoTestAction.downloadSummary,
      enabledAlways: true
    },
    {
      label: 'Remove test',
      availableFor: [
        GeoCellDisplayStatus.draft,
        GeoCellDisplayStatus.scheduled,
        GeoCellDisplayStatus.pendingToLaunch
      ],
      style: ButtonType.tertiary,
      order: 4,
      action: GeoTestAction.saveAsInactive,
      confirmationRequired: true,
      enabledAlways: true
    },
    {
      label: 'Cancel',
      availableFor: [GeoCellDisplayStatus.inProgress],
      style: ButtonType.secondary,
      order: 2,
      action: GeoTestAction.cancel,
      confirmationRequired: true
    },
    {
      label: 'Save',
      availableFor: [
        GeoCellDisplayStatus.scheduled,
        GeoCellDisplayStatus.inProgress,
        GeoCellDisplayStatus.resultsPending,
        GeoCellDisplayStatus.finished
      ],
      style: ButtonType.primary,
      order: 1,
      action: GeoTestAction.updateTest,
      confirmationRequired: true
    }
  ];

  public static confirmations: Record<string, Confirmation> = {
    [GeoTestAction.saveAsActive]: {
      header: 'Confirmation',
      message: `Make sure that the test details are correct before scheduling.`,
      acceptLabel: 'Schedule',
      rejectLabel: 'Cancel'
    },
    [GeoTestAction.updateTestActive]: {
      header: 'Confirmation',
      message: `Make sure that the test details are correct before scheduling.`,
      acceptLabel: 'Schedule',
      rejectLabel: 'Cancel'
    },
    [GeoTestAction.updateTestDraft]: {
      header: 'Confirmation',
      message: `This test will be saved as a Draft.`,
      acceptLabel: 'Save Draft',
      rejectLabel: 'Cancel'
    },
    [GeoTestAction.saveAsDraft]: {
      header: 'Confirmation',
      message: `Check your test's dates before saving it as a draft. It may conflict with other drafted tests.`,
      acceptLabel: 'Save Draft',
      rejectLabel: 'Cancel'
    },
    [GeoTestAction.updateTest]: {
      header: 'Confirmation',
      message: `Check your test settings before updating it there. Changing the end date may affect test results.`,
      acceptLabel: 'Save',
      rejectLabel: 'Cancel'
    },
    [GeoTestAction.cancel]: {
      header: 'Unsaved changes',
      message: `You have unsaved changes. If you close the popup they will be reset.`,
      acceptLabel: 'OK',
      rejectLabel: 'Return'
    },
    [GeoTestAction.saveAsInactive]: {
      header: 'Confirmation',
      message: `If you delete this, it will no longer be present on your schedule of upcoming tests.`,
      acceptLabel: 'Delete',
      rejectLabel: 'Cancel'
    }
  };

  public static readonly businessRiskMessageBuilder: Record<
    GeoTestType,
    (businessRisk?: number) => string
  > = {
    [GeoTestType.holdout]: (businessRisk?: number): string =>
      `This list maximizes test value while minimizing business risk, which in this case is only ${formatPercent(
        businessRisk || 0,
        'en-US',
        '1.2-2'
      )}.`,
    [GeoTestType.lift]: (): string =>
      'This list is used to find out your media contribution in these regions.',
    [GeoTestType.scale]: (): string =>
      'This list maximizes value while minimizing the cost.'
  };

  public static readonly adEntityLabelMap: Record<GeoTestBudgetLevel, string> =
    {
      [GeoTestBudgetLevel.adset]: 'ad set',
      [GeoTestBudgetLevel.campaign]: 'campaign'
    };

  public static readonly testInstructionText: Record<string, string> = {
    stepsToLaunch: 'Steps to Launch the Test',
    whileTestIsRunning: 'While Your Test is Running',
    stepsToEnd: 'Steps to End the Test',
    operationDetails: 'Operation Details',
    whenYourTestBegins: 'When Your Test Begins',
    customerSuccessInstruction:
      'Your customer success partner will alert you if any changes require your attention',
    testCorruptionNotice:
      'Be sure not to launch any promotions or activity that would create non-standard business conditions',
    testAutomationNotice:
      'This process will be automated as much as possible, but some manual action may be necessary'
  };

  public static readonly testAnchorMarketDescription: string = `These will not be tested, but they will be used as the basis for the
            predicted orders in your test markets.`;
}
