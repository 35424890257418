/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { GeoService } from './geo.service';
import { NavigationService } from '@portal/app/shared/services/navigation.service';

// Types
import type { RecommendedTest, MyTest } from './geo.types';

@Component({
  selector: 'portal-geo',
  templateUrl: './geo.component.html'
})
export class GeoComponent implements OnInit, OnDestroy {
  public isLoading = true;
  public myTests: MyTest[] = [];
  private destroy$ = new Subject<void>();
  public recommendedTests: RecommendedTest[] = [];

  constructor(
    private geoService: GeoService,
    private navigationService: NavigationService
  ) {}

  ngOnInit(): void {
    this.subscribeToData();
  }

  navigateToGeo(conversionTypeId?: number): void {
    this.navigationService.navigationMapping('geo', {
      filters: [],
      extraParams: {
        conversionTypeId,
        queryParams: {}
      }
    });
  }

  private subscribeToData(): void {
    this.geoService.recommendedTests$
      .pipe(takeUntil(this.destroy$))
      .subscribe((recommendedTests) => {
        this.recommendedTests = recommendedTests;
      });

    this.geoService.myTests$
      .pipe(takeUntil(this.destroy$))
      .subscribe((myTests) => {
        this.myTests = myTests;
      });

    this.geoService.isLoading$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoading) => {
        this.isLoading = isLoading;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
