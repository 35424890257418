import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SelectionService } from '@portal/app/shared/services/selection.service';
import { AuthService } from '@portal/app/shared/services/auth.service';

import {
  FeatureConfigV2ControllerService,
  FeatureConfigResponseDTO
} from '@libs/api';

@Injectable({
  providedIn: 'root'
})
export class FeatureConfigService {
  private dataSubject = new BehaviorSubject<FeatureConfigResponseDTO[]>([]);

  data$ = this.dataSubject.asObservable();

  constructor(
    private readonly featureConfigV2ControllerService: FeatureConfigV2ControllerService,
    private readonly selectionService: SelectionService,
    private readonly authService: AuthService
  ) {
    this.fetchData();
  }

  fetchData() {
    const { clientId, brandId } = this.selectionService.getClientIdAndBrandId();
    return this.featureConfigV2ControllerService
      .getAllFeatureConfigs(clientId, brandId)
      .subscribe((data: FeatureConfigResponseDTO[]) => {
        this.setData(data);
      });
  }

  setData(data: FeatureConfigResponseDTO[]): void {
    this.dataSubject.next(data);
  }

  getData(): FeatureConfigResponseDTO[] {
    return this.dataSubject.getValue();
  }

  isFeatureEnabled(key: FeatureConfigResponseDTO.FeatureNameEnum): boolean {
    const isMeasuredUser = this.authService.getUser()?.isMeasuredUser();
    const visibilityOptions = isMeasuredUser
      ? [
          FeatureConfigResponseDTO.VisibilityEnum.InternalUsers,
          FeatureConfigResponseDTO.VisibilityEnum.AllUsers
        ]
      : [FeatureConfigResponseDTO.VisibilityEnum.AllUsers];

    return this.dataSubject
      .getValue()
      .some(
        (item) =>
          item.featureName === key &&
          visibilityOptions.includes(
            item.visibility as FeatureConfigResponseDTO.VisibilityEnum
          )
      );
  }
}
