/**
 * Enum representing the possible variants of a card.
 * - `standard`: Represents the default card layout.
 * - `small`: Represents a smaller layout of the card.
 */
export enum CardVariant {
  standard = 'standard',
  small = 'small',
  compact = 'compact'
}
