import { Component, Input } from '@angular/core';
import { ProgressBar } from 'primeng/progressbar';

@Component({
  selector: 'm-progress-bar',
  templateUrl: './m-progress-bar.component.html',
  styleUrls: ['./m-progress-bar.component.scss']
})
export class MProgressBarComponent extends ProgressBar {
  @Input() inverted = false;
  @Input() compare = false;

  get extendedStyleClass(): string {
    return `${this.styleClass || ''} ${this.compare ? 'compare' : ''} ${
      this.inverted ? 'inverted' : ''
    }`;
  }
}
