/* eslint-disable @typescript-eslint/no-explicit-any */
import { IFacebookDoeApiOperation } from '../models/IFacebookDoeApiOperation';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Utility } from '../helpers/utility';
import { ITrackingEvent } from '../models/ITrackingEvent';
import { IDataSourceResponse } from '../models/IFacebookDataSource';

const promiseFrom = lastValueFrom;

export class FacebookDoeApis implements IFacebookDoeApiOperation {
  private constructor(
    private readonly httpClient: HttpClient,
    private readonly apiV1: string,
    private readonly apiV2: string
  ) {}

  public static facebookDoeApiOperation(
    httpClient: HttpClient,
    apiV1: string,
    apiV2: string
  ): IFacebookDoeApiOperation {
    return new FacebookDoeApis(httpClient, apiV1, apiV2);
  }

  public async getFacebookOfflineSpecList(
    clientId: number,
    includeConversionType: boolean
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/fb-offline-conv-specs`,
      queryParameters: { clientId, includeConversionType }
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async createFbOfflineSpec(postData: any): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/fb-offline-conv-specs`
    });
    return promiseFrom(this.httpClient.post(url, postData));
  }

  public async updateFBSpec(id: number, data: any): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/fb-offline-conv-specs/:id`,
      pathVariables: { id }
    });
    return promiseFrom(this.httpClient.put(url, data));
  }

  public async getSelectedFBSpec(
    id: number,
    includeConversionType: boolean
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/fb-offline-conv-specs/:id`,
      pathVariables: { id },
      queryParameters: { includeConversionType }
    });
    return this.httpClient.get(url);
  }

  public async getFbDoeConfigList(
    clientId: number,
    brandId: number
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/fb-ad-study/all`,
      queryParameters: {
        clientId,
        brandId,
        studyType: 'SPLIT_TEST'
      }
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async getFbDoeReportingList(
    clientId: number,
    brandId: number,
    reportType: string
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/fb-reporting/all`,
      queryParameters: { clientId, brandId, reportType }
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async getFbDoeReportingById(
    selectedId: number,
    clientId: number,
    brandId: number,
    reportType: string
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/fb-reporting/incrementality/:selectedId`,
      pathVariables: { selectedId },
      queryParameters: { clientId, brandId, reportType }
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async addFbDoeReporting(
    clientId: number,
    brandId: number,
    type: string,
    data: any
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/fb-reporting/:type`,
      pathVariables: { type },
      queryParameters: { clientId, brandId }
    });
    return promiseFrom(this.httpClient.post(url, data));
  }

  public async updateFbDoeReporting(
    selectedId: number,
    clientId: number,
    brandId: number,
    type: string,
    data: any
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/fb-reporting/:type/:selectedId`,
      pathVariables: { type, selectedId },
      queryParameters: { clientId, brandId }
    });
    return promiseFrom(this.httpClient.put(url, data));
  }

  public async getFbDoeAdAccounts(
    clientId: number,
    brandId: number
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/facebook/ad-accounts/all`,
      queryParameters: { clientId, brandId }
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async getFbDoeApps(
    adAccountIds: string,
    clientId: number,
    brandId: number
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/facebook/data-sources/all`,
      queryParameters: { adAccountIds, clientId, brandId }
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async getFbDoePages(clientId: number, brandId: number): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/facebook/accessible-pages/all`,
      queryParameters: { clientId, brandId }
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async getFbDoeCatalogs(
    accountId: string,
    clientId: number,
    brandId: number
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/facebook/product-catalog/:accountId`,
      pathVariables: { accountId },
      queryParameters: { clientId, brandId }
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async saveAdImage(
    data: any,
    adAccountId: string,
    clientId: number,
    brandId: number
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/facebook/ad-creative/upload-image/:adAccountId`,
      pathVariables: { adAccountId },
      queryParameters: { clientId, brandId }
    });
    return promiseFrom(
      this.httpClient.post(url, data, {
        headers: { Accept: 'text/plain; charset=utf8' }
      })
    );
  }

  public async getFbAdStudyDetails(
    selectedId: number,
    clientId: number,
    brandId: number
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/fb-ad-study/:selectedId`,
      pathVariables: { selectedId },
      queryParameters: { clientId, brandId }
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async getCampaigns(
    clientId: number,
    brandId: number,
    adAccountId: string
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/facebook/campaign/:adAccountId`,
      pathVariables: { adAccountId },
      queryParameters: { clientId, brandId }
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async createConfigRegEx(
    data: any,
    adAccountId: string,
    filterType: string,
    clientId: number,
    brandId: number
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/facebook/campaign/:adAccountId}/apply/:filterType`,
      pathVariables: { adAccountId, filterType },
      queryParameters: { clientId, brandId }
    });
    return promiseFrom(this.httpClient.post(url, data));
  }

  public async createConfigExperiment(
    data: any,
    clientId: number,
    brandId: number
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/fb-ad-study/SPLIT_TEST`,
      queryParameters: { clientId, brandId }
    });
    return promiseFrom(this.httpClient.post(url, data));
  }

  public async updateConfigExperiment(
    data: any,
    selectedId: number,
    clientId: number,
    brandId: number
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/fb-ad-study/:selectedId`,
      pathVariables: { selectedId },
      queryParameters: { clientId, brandId }
    });
    return promiseFrom(this.httpClient.put(url, data));
  }

  public async getFacebookTrackEvents(
    clientId: number,
    brandId: number,
    trackingType: string
  ): Promise<ITrackingEvent[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/facebook/tracking-events`,
      queryParameters: { clientId, brandId, trackingType }
    });
    return promiseFrom(this.httpClient.get<ITrackingEvent[]>(url));
  }

  public async getCustomConversionEvents(
    clientId: number,
    brandId: number,
    adAccountIds: string
  ): Promise<IDataSourceResponse> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/facebook/data-sources/all`,
      queryParameters: { clientId, brandId, adAccountIds }
    });
    return promiseFrom(this.httpClient.get<IDataSourceResponse>(url));
  }
}
