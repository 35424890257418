import {
  ChangeDetectorRef,
  Component,
  Input,
  Renderer2,
  OnDestroy,
  ChangeDetectionStrategy
} from '@angular/core';
import { TooltipOptions } from 'primeng/api';
import {
  TooltipPosition,
  TooltipArrowPosition,
  TooltipArrowPositionEnum
} from './m-tooltip.component.types';

/**
 * The `MTooltipComponent` provides a customizable tooltip component
 * that wraps around the PrimeNG Tooltip module for easy customization and usage.
 */
@Component({
  selector: 'm-tooltip',
  templateUrl: './m-tooltip.component.html',
  styleUrls: ['./m-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MTooltipComponent implements OnDestroy {
  /** MutationObserver to watch for DOM changes */
  private observer: MutationObserver;

  /**
   * @param renderer - Angular Renderer to manipulate the DOM
   * @param cdRef - ChangeDetectorRef to trigger change detection
   */
  constructor(
    private renderer: Renderer2,
    private cdRef: ChangeDetectorRef
  ) {
    this.observer = new MutationObserver(this.onMutation.bind(this));
    // Observing only direct child list changes of the body
    this.observer.observe(document.body, { childList: true });
  }

  /** Content to be displayed within the tooltip */
  @Input() content = '';

  /** Position of the tooltip relative to the target element */
  @Input() position: TooltipPosition = 'top';

  /** Position of the tooltip arrow, if specified */
  @Input() arrowPosition: TooltipArrowPosition | null = null;

  /** Additional configuration options for the tooltip */
  @Input() tooltipOptions: TooltipOptions = { escape: false };

  /** Additional styles to apply to the container div */
  @Input() styleClass = '';

  /** Cleanup the observer on component destruction */
  ngOnDestroy() {
    this.observer.disconnect();
  }

  /**
   * Callback function for the MutationObserver to adjust tooltip alignment
   * whenever a child node is added or removed in the document body.
   *
   * @param mutationsList - List of MutationRecord objects
   */
  private onMutation(mutationsList: MutationRecord[]) {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        const tooltipElement = document.querySelector('.p-tooltip');
        if (tooltipElement) {
          // Wrapping DOM manipulation in requestAnimationFrame for better performance
          requestAnimationFrame(() => {
            this.adjustTooltipAlignment(tooltipElement);
          });
        }
      }
    }
  }

  /**
   * Adjusts the alignment of the tooltip based on the specified position.
   *
   * @param tooltipElement - Reference to the tooltip DOM element
   */
  private adjustTooltipAlignment(tooltipElement: Element) {
    tooltipElement.classList.remove(
      TooltipArrowPositionEnum.left,
      TooltipArrowPositionEnum.right
    );
    if (this.arrowPosition) {
      tooltipElement.classList.add(this.arrowPosition);
    }
  }
}
