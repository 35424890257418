import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationExtras,
  Params,
  Router
} from '@angular/router';
import { ClientStoreService } from '@portal/app/dashboard/geo-doe-config/data-stores/client-store.service';
import { ApiService } from '@portal/app/dashboard/integrations/services/api.service';
import { DataShareService } from '@portal/app/dashboard/integrations/services/data-share.service';
import { ErrorHandlingService } from '@portal/app/dashboard/integrations/services/error-handling.service';
import { VENDOR_FACTORY } from '@portal/app/dashboard/integrations/shared/Vendors';
import {
  INTEGRATIONS_BRAND_ID_KEY,
  INTEGRATIONS_CLIENT_ID_KEY,
  VendorIds
} from '@portal/app/dashboard/integrations/shared/constants';
import { ApiService as SharedApiService } from '@portal/app/shared/services/api.service';
import { SelectionService } from '@portal/app/shared/services/selection.service';
import { StorageService } from '@portal/app/shared/services/storage.service';
import { WindowService } from '@portal/app/shared/services/window.service';
import { isEmpty } from 'lodash-es';
import { Subscription, take } from 'rxjs';
import { IntegrationStoreService } from '../../../data-store/integration-store.service';

@Component({
  selector: 'portal-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit, OnDestroy {
  vendorId = '';
  private subs: Subscription[] = [];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly apiService: ApiService,
    private readonly sharedApiService: SharedApiService,
    private readonly dataShareService: DataShareService,
    private readonly selectionService: SelectionService,
    private readonly storageService: StorageService,
    private readonly windowService: WindowService,
    private readonly integrationStoreService: IntegrationStoreService,
    private readonly clientStoreService: ClientStoreService,
    private readonly errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.pipe(take(1)).subscribe({
      next: (params) => {
        this.vendorId = params.vendorId;
        this.activatedRoute.queryParams
          .pipe(take(1))
          .subscribe((queryParams) => {
            if (queryParams && queryParams.error) {
              this.sharedApiService.getClients().subscribe({
                next: (resp) => {
                  const clientId = Number(
                    this.storageService.get(INTEGRATIONS_CLIENT_ID_KEY)
                  );
                  this.clientStoreService.allClients = resp.clients;
                  this.rebuildSelectionForIntegrationsUI();

                  this.integrationStoreService.setError(
                    `We weren’t able to connect to your vendor account. Please check your user credentials there. If you believe they are valid, reach out to support.`
                  );
                  this.errorHandlingService.logError(
                    `Error while connecting to "${
                      this.vendorId
                    }" for client ${clientId}: ${JSON.stringify(queryParams)}`
                  );
                  this.navigateToIntegrationPage('');
                }
              });
            } else {
              this.storeQueryParams(queryParams);

              this.sharedApiService.getClients().subscribe({
                next: (resp) => {
                  this.clientStoreService.allClients = resp.clients;
                  this.rebuildSelectionForIntegrationsUI();
                  this.confirmVendor();
                }
              });
            }
          });
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  confirmVendor(): void {
    if (!this.vendorId) {
      return;
    }
    const vendorHandlerObj = new VENDOR_FACTORY[this.vendorId as VendorIds](
      this.storageService,
      this.windowService
    );
    this.dataShareService.updateProgressSpinnerStatus(
      true,
      `Confirming the connection of ${this.vendorId} `
    );
    const confirmationReq = vendorHandlerObj.prepareRequestToConfirmVendor({});
    this.subs.push(
      this.apiService.confirmVendor(this.vendorId, confirmationReq).subscribe({
        next: (data) => {
          vendorHandlerObj.confirmVendor(data);
          this.dataShareService.updateProgressSpinnerStatus(false, '');
          this.navigateToIntegrationPage(this.vendorId);
        },
        error: (error) => {
          console.error(
            `Error while confirming the ${this.vendorId}: ${JSON.stringify(
              error
            )}`
          );
          this.integrationStoreService.setError(
            `We weren’t able to connect to your vendor account. Please check your user credentials there. If you believe they are valid, reach out to support.`
          );
          this.navigateToIntegrationPage('');
          this.dataShareService.updateProgressSpinnerStatus(false, '');
        }
      })
    );
  }

  navigateToIntegrationPage(vendorId: string) {
    const navigationExtras: NavigationExtras = {
      queryParams: {}
    };
    const connectParamName = 'connect';

    if (vendorId && !isEmpty(vendorId) && navigationExtras.queryParams) {
      navigationExtras.queryParams[connectParamName] = vendorId;
    }
    this.router
      .navigate(
        [
          `/a/${this.selectionService.buildSelectionSlug(
            this.selectionService.getSelection()
          )}/products/admin/integration/data-sources`
        ],
        navigationExtras
      )
      .catch(console.error);
  }

  storeQueryParams(queryParams: Params) {
    const callBackParams = ['code', 'state', 'hmac', 'shop'];
    if (callBackParams && !isEmpty(callBackParams)) {
      callBackParams.forEach((param) => {
        localStorage.setItem(param, queryParams[param]);
      });
    }
    localStorage.setItem('vendorId', this.vendorId);
  }

  private rebuildSelectionForIntegrationsUI(): void {
    const clientId = Number(
      this.storageService.get(INTEGRATIONS_CLIENT_ID_KEY)
    );
    const brandId = Number(this.storageService.get(INTEGRATIONS_BRAND_ID_KEY));

    const selectedClient = this.clientStoreService.allClients.find(
      (client) => !clientId || client.clientId === clientId
    );

    let selectedBrand = null;

    if (
      selectedClient &&
      selectedClient.brands &&
      selectedClient.brands.length
    ) {
      selectedBrand = selectedClient.brands.find(
        (brand) => !brandId || brand.brandId === brandId
      );
    }

    if (selectedClient && selectedBrand) {
      this.selectionService.setSelection(selectedClient, selectedBrand);
    }
  }
}
