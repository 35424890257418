import { Component, Input } from '@angular/core';

@Component({
  selector: 'm-count-up-display',
  templateUrl: './m-count-up-display.component.html'
})
export class MCountUpDisplayComponent {
  @Input() end!: string;
  @Input() label!: string;
  @Input() textClass!: string;
  @Input() subtitleClass!: string;
  @Input() duration = 2;
}
