<div class="optimization-results mt-4">
  <ng-container *ngIf="isLoading; else showChart">
    <!-- Loading skeleton -->
    <m-skeleton height="26.125rem" width="100%"></m-skeleton>
  </ng-container>

  <ng-template #showChart>
    <m-card>
      <div class="optimization-results-container">
        <div class="chart-container">
          <div class="sh2 text-green-1000">Optimization Results</div>
          <portal-performance-overtime-chart
            [dateStart]="startDate"
            [dateStop]="stopDate"
            [conversionType]="conversionType"
            [resolution]="resolution"
            [viewBy]="viewBy"
            class="w-full">
          </portal-performance-overtime-chart>
        </div>

        <div class="fixed-card">
          <div class="mb-4">
            <m-button-group
              [options]="resolutionOptions"
              [selectedOption]="resolution"
              (selectedOptionChange)="onResolutionChange($event)">
            </m-button-group>
          </div>
          <m-card styleClass="!h-auto">
            <div class="text-center">
              <div class="flex gap-2 items-center justify-center">
                <span class="material-symbols-outlined text-gray-500 icon-small"
                  >calendar_today</span
                >
                <div class="c2 text-gray-1000">{{ formattedDateRange }}</div>
              </div>

              <div class="m-divider my-3"></div>
              <div>
                <div [ngClass]="heroMetricClass" class="h4">
                  {{
                    heroMetric?.isPositive
                      ? heroMetric?.additionalMetric
                      : "[" + heroMetric?.additionalMetric + "]"
                  }}
                </div>

                <m-tag
                  [isDark]="false"
                  [isPositive]="heroMetric?.isPositive"
                  [isNeutral]="false"
                  [isUp]="heroMetric?.isUp">
                  {{ heroMetric?.changeInPercent }}
                </m-tag>

                <div class="b1 text-grey-1000 my-3">
                  {{ heroMetricText }}
                </div>
              </div>

              <div class="bg-gray-100 p-3 -mx-6 -mb-6">
                <m-button [type]="buttonSecondary" (click)="navigateToOP()">
                  View latest report
                </m-button>
              </div>
            </div>
          </m-card>
        </div>
      </div>
    </m-card>
  </ng-template>
</div>
