import { Component, computed, inject, input, OnInit } from '@angular/core';
import { DataResponse } from '@portal/app/shared/types';
import { Router } from '@angular/router';
import { LinkService } from '@portal/app/dashboard/context-modal/services/link.service';

@Component({
  selector: 'portal-context-modal-link',
  templateUrl: './link.component.html'
})
export class LinkComponent implements OnInit {
  private linkService = inject(LinkService);
  private router = inject(Router);

  row = input.required<DataResponse>();
  value = input.required<string>();

  isLink = computed(() => this.linkService.isLink(this.row()));

  ngOnInit(): void {
    this.linkService.addRow(this.row());
  }

  onLinkClick($event: Event) {
    $event.preventDefault();
    this.showModal();
  }

  showModal() {
    const tactic = this.row().tactic;
    const channel = this.row().channel;
    let modal = '';
    if (this.linkService.isChannel(this.row())) {
      modal = 'channel';
    } else {
      modal = 'tactic';
    }

    this.router.navigate([], {
      queryParams: { modal, tactic, channel },
      queryParamsHandling: 'merge'
    });
  }

  closeModal() {
    this.router.navigate([], {
      queryParams: { modal: null, id: null },
      queryParamsHandling: 'merge'
    });
  }
}
