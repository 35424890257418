import { Injectable } from '@angular/core';
import { Client } from '@portal/app/shared/types';

@Injectable({
  providedIn: 'root'
})
export class ClientStoreService {
  private clientData?: Client;
  private allAccessibleClients: Client[] = [];

  get allClients(): Client[] {
    return this.allAccessibleClients;
  }

  set allClients(clients: Client[]) {
    this.allAccessibleClients = clients;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get client(): Client | undefined {
    return this.clientData;
  }

  set client(client: Client | undefined) {
    this.clientData = client;
  }
}
