import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  DataEndpoint,
  DataResponse,
  DataRowFilter,
  FieldDefinitions,
  Filter,
  FilterValue,
  HeroElement,
  HeroElementBounded,
  HeroMetric,
  WidgetType
} from '@portal/app/shared/types';
import { ApiService } from '@portal/app/shared/services/api.service';
import { ContextStore } from '@portal/app/shared/state/context.store';
import { getSorted } from '@portal/app/shared/helpers/helpers';
import { cloneDeep } from 'lodash-es';
import { Observable, Subscription, take } from 'rxjs';
import { NativeSectionsService } from '@portal/app/shared/services/native-sections.service';
import { ViewStore } from '@portal/app/shared/state/view.store';
import { Store } from '@ngrx/store';
import { AppState } from '@portal/app/store/app.state';
import { MultiViewStore } from '@portal/app/shared/state/multi-view.store';
import { CompareService } from '@portal/app/shared/services/compare.service';
import Sortable from 'sortablejs';
import { DashboardActions } from '@portal/app/store/dashboard/actions';

@Component({
  selector: 'portal-hero-group',
  templateUrl: './hero-group.component.html',
  styleUrls: ['./hero-group.component.scss']
})
export class HeroGroupComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() title? = '';
  @Input() name? = '';
  @Input() width = 12;
  @Input() items: HeroElement[] = [];
  @Input() gridItem = true;
  @Input() data?: DataResponse;
  @Input() fieldDefinitions: FieldDefinitions = {};
  @Input() subscribeOnlyOnce?: boolean = false;
  @Input() overrideWidth = null;
  @Input() brandId = 0;

  public heroComponentWidth = '';
  public loading = true;
  public heroes: HeroMetric[] = [];
  public type = WidgetType.heroMetric;
  public originalHeroes: HeroMetric[] = [];
  public originalItems: HeroElement[] = [];
  public literalId = '';
  public filters: Filter[] = [];
  public componentFirstLoad = false;
  public viewId?: number;
  private readonly subscriptions: Subscription = new Subscription();
  filterContext$: Observable<Filter[]> = this.contextStore.filterContext;
  gridWrapperHeight = '5rem';

  constructor(
    private readonly apiService: ApiService,
    private readonly contextStore: ContextStore,
    private readonly ref: ChangeDetectorRef,
    public readonly nativeSectionsService: NativeSectionsService,
    private readonly viewStore: ViewStore,
    private store: Store<AppState>,
    private readonly multiViewStore: MultiViewStore,
    private readonly compareService: CompareService
  ) {}

  public static createFilters(
    dashboardId = -1,
    literalId: string,
    isRequired: 'YES' | 'NO' = 'YES',
    type:
      | 'string'
      | 'string[]'
      | 'number'
      | 'number[]'
      | 'dateRange' = 'string[]',
    format: string,
    applyTo: string[] | null,
    value: FilterValue,
    options: string[] | null
  ): Filter {
    return {
      dashboardId,
      name: literalId,
      literalId,
      isRequired,
      type,
      format,
      applyTo,
      value,
      options,
      label: literalId,
      id: -1,
      associatedFilters: undefined
    };
  }

  ngOnInit(): void {
    this.compareService.isCompareOn.subscribe((isCompareOn) => {
      this.gridWrapperHeight = isCompareOn ? '6rem' : '5rem';
    });
    this.viewStore.selectedBCPDId.pipe(take(1)).subscribe((value) => {
      this.literalId = value.literalId;
    });
    this.multiViewStore.currentView$.subscribe((currentView) => {
      this.viewId = currentView ? Number(currentView.viewId) : undefined;
    });

    if (this.data == null) {
      const componentFilterSubscription =
        this.contextStore.componentFilter.subscribe({
          next: (dataRowFilters: DataRowFilter[]) => {
            // get all listening components and check if the current one is included
            const listeningComponents: string[] =
              this.contextStore.listeningComponentName;
            // do not go further if the component is just loaded and data was saved of the previous emit
            if (listeningComponents.length && this.componentFirstLoad) {
              const listeningComponent: string | undefined =
                listeningComponents.find((el) => el === this.name);
              // if the component is included call the api with new filters
              if (
                listeningComponent !== undefined &&
                this.name === listeningComponent &&
                dataRowFilters
              ) {
                const existingGridDimension = this.filters.find(
                  (el) => el.literalId === 'grid_dimensions'
                );
                // if grid_dimensions filters exists change their value, else push new to arr
                if (existingGridDimension) {
                  existingGridDimension.value = dataRowFilters;
                } else {
                  this.filters.push({
                    id: 0,
                    dashboardId: 0,
                    name: 'grid_dimensions',
                    literalId: 'grid_dimensions',
                    label: '',
                    type: 'string',
                    value: dataRowFilters,
                    format: 'string',
                    isRequired: 'NO',
                    options: null,
                    applyTo: null
                  });
                }
                this.loadData(this.filters, true);
              }
            }
          }
        });
      this.subscriptions.add(componentFilterSubscription);
      // use the contextStore to pull new data and data on change
      const sub = this.filterContext$.subscribe({
        //test.reduce((cur, next) => {cur[next.name] = next.value; return cur },{})
        next: (filterSet) => {
          const filters = cloneDeep(filterSet);
          const valueAndOptions = this.items.map((i) => i.literalId);
          filters.push(
            HeroGroupComponent.createFilters(
              this.items[0]?.dashboardId,
              'dataPoints',
              'YES',
              'string[]',
              'none',
              ['DATA_POINT'],
              valueAndOptions,
              valueAndOptions
            )
          );
          this.filters = [...filters];
          this.loadData(filters, false);
          this.componentFirstLoad = true;
        }
      });
      if (this.subscribeOnlyOnce) {
        sub.unsubscribe();
      } else {
        this.subscriptions.add(sub);
      }
    } else {
      const typedData: DataResponse = this.data;
      this.heroes = this.items.map(
        (value: HeroElement | HeroElementBounded) => ({
          name: value.literalId,
          label: this.fieldDefinitions[value.literalId]?.label,
          value:
            this.data instanceof Promise
              ? this.data
              : (typedData[value.literalId] as number),
          width: value.span,
          field: this.fieldDefinitions[value.literalId],
          type: value.layoutType,
          children:
            'children' in value
              ? (value as HeroElementBounded).children.map((literalId) => ({
                  name: literalId,
                  field: this.fieldDefinitions[literalId]
                }))
              : []
        })
      ) as HeroMetric[];
      this.loading = false;
    }
    if (this.overrideWidth != null) {
      this.heroComponentWidth = `${this.overrideWidth}rem`;
    } else {
      this.heroComponentWidth = `${100 / this.heroes.length}%`;
    }
  }

  loadData(filters: Filter[], changeApiCall: boolean) {
    let dataPromise: Promise<DataResponse>;
    if (changeApiCall) {
      dataPromise = this.apiService.postDashboardData(
        filters,
        DataEndpoint.dataPoints
      ) as Promise<DataResponse>;
    } else {
      dataPromise = this.apiService.getDashboardData({
        filters,
        type: DataEndpoint.dataPoints,
        viewId: this.viewId
      }) as Promise<DataResponse>;
    }
    this.heroes = getSorted(this.items, 'displayOrder').map(
      (value: HeroElement | HeroElementBounded) => ({
        name: value.literalId,
        label: this.fieldDefinitions[value.literalId]?.label,
        value: dataPromise,
        width: value.span,
        field: this.fieldDefinitions[value.literalId],
        type: value.layoutType,
        children:
          'children' in value
            ? (value as HeroElementBounded).children.map((literalId) => ({
                name: literalId,
                field: this.fieldDefinitions[literalId]
              }))
            : []
      })
    ) as HeroMetric[];
    this.originalHeroes = cloneDeep(this.heroes);
    this.originalItems = cloneDeep(this.items);
    this.ref.detectChanges();
  }

  asHero(val: unknown): HeroMetric[] {
    return val as HeroMetric[];
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.setupSortableHeroMetrics();
  }

  private setupSortableHeroMetrics() {
    const id = `hero-group-container-${this.name || ''}`;
    const element = document.getElementById(id);
    if (element) {
      Sortable.create(element, {
        animation: 100,
        ghostClass: 'swap-highlighted-item',
        sort: true,
        onSort: (event) => {
          console.warn(event);
          const sortedIds = Array.from(element.children).map(({ id }) => id);
          sortedIds.forEach((id, index) => {
            const obj = this.items.find((el) => el.literalId === id);
            if (obj != null) {
              obj.displayOrder = index + 1;
            }
          });
        },
        onEnd: (event) => {
          console.warn(event);
          const heroItems = cloneDeep(this.items);
          if (this.name) {
            this.store.dispatch(
              DashboardActions.setHeroGroup({
                heroGroupItems: heroItems,
                heroGroupName: this.name
              })
            );
          }
        }
      });
    }
  }
}
