export interface IHeaderConfig {
  primaryTitle?: ITitleConfig;
  secondaryTitles: ITitleConfig[];
  editableTitle?: ITitleConfig;
  badges: IBadgeConfig[];
  faq?: IFAQConfig;
  refreshTime?: IRefreshTimeConfig;
  details?: {
    goalDetails?: {
      field: string;
      label: string;
      span: string;
    };
  };
  containerStyleClass?: string;
}

export interface ITitleConfig {
  field: string;
  span: number;
  styleClass?: string;
  defaultValue?: string;
}

export enum BadgeType {
  badge = 'BADGE',
  iconBadge = 'ICON_BADGE'
}
export interface IBadgeConfig {
  field: string;
  severity?: string;
  size?: 'large' | 'small';
  span: number;
  type: BadgeType;
  styleClass?: string;
  icon?: string;
  dashboardFilterId?: string;
}

export interface IFAQConfig {
  label: string;
  field: string;
  span?: number;
}

export interface IStatusBadgeConfig extends IBadgeConfig {
  value?: string;
}

export interface IRefreshTimeConfig {
  label: string | null;
  link: string;
  columns: {
    id: string;
    label: string;
  }[];
  span?: number;
}
