import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// Services
import { HomepageService } from '@portal/app/dashboard/home-page/services/home-page.service';
import {
  RecommendedService,
  DEFAULT_PLAN_STRENGTH
} from './recommended.service';
import { NavigationService } from '@portal/app/shared/services/navigation.service';

// Types
import type { Data } from './recommended.service';
import type { Filter } from '@portal/app/shared/types';

@Component({
  selector: 'portal-recommended',
  templateUrl: './recommended.component.html'
})
export class RecommendedComponent implements OnInit, OnDestroy {
  public data?: Data;
  private destroy$ = new Subject<void>();
  public isLoading = true;
  public isDataLoading = true;
  private filters: Filter[] = [];
  public optimizeStrengthFilterIndex = DEFAULT_PLAN_STRENGTH - 1;
  public relativeDayFilter?: Filter = undefined;
  public steps: string[] = [];
  public isCollapsed = false;

  constructor(
    private homepageService: HomepageService,
    private recommendedService: RecommendedService,
    private navigationService: NavigationService
  ) {}

  ngOnInit() {
    this.steps = this.recommendedService.steps;

    // Subscribe to observables
    this.subscribeToChanges();
  }

  private subscribeToChanges() {
    this.recommendedService.data$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.data = data;
      });

    this.recommendedService.initialLoading$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoading) => {
        this.isLoading = isLoading;
      });

    this.recommendedService.subsequentLoading$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoading) => {
        this.isDataLoading = isLoading;
      });

    this.recommendedService.filters$
      .pipe(takeUntil(this.destroy$))
      .subscribe((filters) => {
        this.filters = filters;
        const relativeDayFilter = filters.filter(
          (filter) => filter.literalId === 'relative_day'
        );

        this.relativeDayFilter = relativeDayFilter[0];
      });

    this.homepageService.mpoSectionShouldBeExpanded$
      .pipe(takeUntil(this.destroy$))
      .subscribe((mpoSectionShouldBeExpanded) => {
        this.isCollapsed = !mpoSectionShouldBeExpanded;
      });
  }

  onStepChange(index: number) {
    const newValue = index + 1;
    this.optimizeStrengthFilterIndex = index;
    this.recommendedService.setStrengthFilter(newValue);
  }

  navigateToPage(): void {
    const optimizeStrengthFilterValue = this.optimizeStrengthFilterIndex + 1;

    this.navigationService.navigationMapping(
      'mpo',
      {
        filters: this.filters,
        extraParams: {
          optimizeStrengthFilterValue: optimizeStrengthFilterValue || 1
        }
      },
      true
    );
  }

  handleExpandChange(isCollapsed: boolean) {
    const layoutData = {
      mpoSectionShouldBeExpanded: !isCollapsed
    };

    this.homepageService.updateHomepageLayout(layoutData).subscribe({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error: (error: any) => console.error(error)
    });
  }

  // Handles cleanup when the service is destroyed
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
