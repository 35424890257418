<div
  class="filters d-flex justify-content-between align-items-center mb-2"
  [ngClass]="filterFields.length !== 0 ? 'has-inputs' : ''">
  @if (filterFields.length !== 0) {
    <div class="filter-inputs d-flex">
      @for (field of filterFields; track field) {
        <div class="me-2">
          <label
            >{{ field?.label }}
            @if (field.inputType === "select") {
              <p-dropdown
                [options]="asStringArray(field.options)"
                [(ngModel)]="field.value"
                (onChange)="setFilterValues()"
                class="d-block"></p-dropdown>
            }
            @if (field.inputType === "multiselect") {
              <p-multiSelect
                #multiSelect
                [options]="field.options"
                [(ngModel)]="field.value"
                [baseZIndex]="2"
                (onPanelHide)="
                  handleMinimumSelection(multiSelect); setFilterValues()
                "
                selectedItemsLabel="{multiSelect.value.length} selected"
                class="d-block"></p-multiSelect>
            }
            @if (field.inputType === "number") {
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">{{
                  formatterService.currencySymbolValue
                }}</span>
                <p-inputNumber
                  [(ngModel)]="field.value"
                  name="{{ field.name }}"
                  [minFractionDigits]="2"
                  (onKeyDown)="filterInputKeyDown($event)"></p-inputNumber>
              </div>
            }
          </label>
        </div>
      }
    </div>
  }
  @if (nativeSectionsService.shouldShowExport(literalId)) {
    <div class="filter-buttons ms-auto margin-top-auto">
      @if (filterable) {
        <p-button
          icon="pi pi-filter-slash"
          label="Clear Filters"
          [ngClass]="[
            'p-button-success filters-button me-2',
            wasFiltered ? 'disabled' : '',
          ]"
          [disabled]="!wasFiltered"
          (click)="clearFilters()"></p-button>
      }
      <button
        pButton
        class="p-button-text p-button-icon-only"
        pTooltip="Export"
        tooltipPosition="bottom"
        tooltipStyleClass="saas-tooltip"
        showDelay="800"
        (click)="menu.toggle($event)">
        <span class="material-symbols-outlined"> ios_share </span>
      </button>
      <p-menu
        #menu
        [popup]="true"
        [model]="exportTableMenu"
        appendTo="body"></p-menu>
    </div>
  }
</div>
<div class="table-responsive">
  <p-table
    #dt
    [columns]="table.fields.sort(sortByDisplayOrder)"
    (sortFunction)="customSort($event)"
    [loading]="loading"
    [resizableColumns]="true"
    columnResizeMode="expand"
    responsiveLayout="scroll"
    [scrollable]="dataGridConfig.scrollable"
    scrollDirection="both"
    [responsive]="true"
    [customSort]="dataGridConfig.customSort"
    [value]="table.data"
    (onColReorder)="onColumnReorder($event)"
    [reorderableColumns]="true"
    styleClass="{{
      this.dataGridConfig.striped
        ? 'p-datatable-striped text-elide'
        : 'text-elide'
    }} {{ dataGridConfig.tableScrollHeightClass }}"
    (onFilter)="filterChanged($event)">
    <ng-template pTemplate="header" let-cols>
      <tr>
        @for (col of nativeSectionsService.asTableColumns(cols); track col) {
          <th
            pReorderableColumn
            [pReorderableColumnDisabled]="col?.type === 'DIMENSION'"
            pResizableColumn
            pFrozenColumn
            [frozen]="
              frozenColumnsEnabled &&
              (col?.type === 'DIMENSION' || col?.type === 'PIVOT_ROW_HEADER')
            "
            [ngClass]="
              col
                | generateHeaderClassInput
                  : fieldDefinitions
                  : pivotRows.length > 0
                  : pivotDimensionId
            "
            [pSortableColumnDisabled]="pivotRows.length > 0"
            [pSortableColumn]="col?.dashboardFieldId"
            scope="col"
            class="d-flex">
            <span class="header-title">{{ col?.label }}</span>
            @if (!pivotRows.length) {
              <p-sortIcon [field]="col?.dashboardFieldId"></p-sortIcon>
            }
            @if (filterable && !pivotRows.length) {
              @switch (
                col?.type !== "PIVOT_DIMENSION"
                  ? fieldDefinitions[col?.dashboardFieldId].format
                  : pivotFieldDefinition.format
              ) {
                @case ("percent") {
                  <p-columnFilter
                    [type]="getFieldDefinition(col) | formatFilterType"
                    [field]="col?.dashboardFieldId"
                    display="menu"
                    [minFractionDigits]="2"></p-columnFilter>
                }
                @default {
                  <p-columnFilter
                    [type]="getFieldDefinition(col) | formatFilterType"
                    [field]="col?.dashboardFieldId"
                    display="menu"></p-columnFilter>
                }
              }
            }
          </th>
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-cols="columns">
      <tr>
        @for (
          col of nativeSectionsService.asTableColumns(cols);
          track col;
          let i = $index
        ) {
          <td
            pFrozenColumn
            [frozen]="
              frozenColumnsEnabled &&
              (col?.type === 'DIMENSION' || col?.type === 'PIVOT_ROW_HEADER')
            "
            [ngClass]="
              getFieldDefinition(col, rowData)
                | generateRowClassInput
                  : pivotRows.length > 0
                  : pivotRowHeaders[0]?.dashboardFieldId
                  : col
            ">
            <ng-container>
              @if (!loading) {
                @if (
                  fieldDefinitions[col.dashboardFieldId]?.type === "string"
                ) {
                  <div
                    [pTooltip]="
                      rowData[col.dashboardFieldId]
                        | formatValue: getFieldDefinition(col, rowData)
                    "
                    tooltipPosition="right">
                    {{
                      rowData[col.dashboardFieldId]
                        | formatValue: getFieldDefinition(col, rowData)
                    }}
                  </div>
                }
                @if (
                  fieldDefinitions[col.dashboardFieldId]?.type !== "string"
                ) {
                  <div>
                    @if (
                      !arrowsDisabledFor.includes(
                        rowData[col.dashboardFieldId]
                          | formatValue: getFieldDefinition(col, rowData)
                      ) &&
                      fieldsToHighlight.includes(
                        getFieldDefinition(col, rowData)?.literalId
                      )
                    ) {
                      <portal-previous-value
                        value="{{
                          rowData[col.dashboardFieldId]
                            | formatValue: getFieldDefinition(col, rowData)
                        }}"
                        [isUp]="rowData[col.dashboardFieldId] > 0"
                        [alignment]="'left'">
                      </portal-previous-value>
                    } @else {
                      {{
                        rowData[col.dashboardFieldId]
                          | formatValue: getFieldDefinition(col, rowData)
                      }}
                    }
                  </div>
                }
              }
              @if (loading) {
                <p-progressSpinner [style]="{ width: '20px', height: '20px' }"
                  >Loading!</p-progressSpinner
                >
              }
            </ng-container>
          </td>
        }
      </tr>
    </ng-template>
  </p-table>
</div>
