import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FieldDefinitions, Filter } from '@portal//app/shared/types';
import { Required } from '@portal/app/shared/decorators/required.decorator';
import { AssociatedFilterService } from '@portal/app/shared/services/associated-filter.service';
import { ViewStore } from '@portal/app/shared/state/view.store';
import { take } from 'rxjs';

const ALLOCATION_LITERAL_ID = 'allocationSetting';
const ANCHOR_ALLOCATION_LITERAL_ID = 'anchorAllocationSetting';
const OPTIMIZED_CAPPED_LITERAL_ID = 'optimizedCappedAllocationSetting';

@Component({
  selector: 'portal-app-filter-set-radio-popup',
  templateUrl: './filter-set-radio-popup.component.html'
})
export class FilterSetRadioPopupComponent {
  @Input() @Required fieldDefinitions: FieldDefinitions = {};
  @Input() @Required filterSet: Filter[] = [];
  @Input() @Required clientId: string | undefined;
  @Input() @Required brandId: string | undefined;
  @Input() @Required dashboardId: string | undefined;
  @Output() filterChange: EventEmitter<Filter> = new EventEmitter<Filter>();
  @Input() name = '';
  public productId = '';
  public literalId = '';
  // noop -> dummy code to remove failures in html template
  public idx = -1;

  constructor(
    private readonly associatedFilterService: AssociatedFilterService,
    private readonly viewStore: ViewStore
  ) {
    this.viewStore.selectedBCPDId.pipe(take(1)).subscribe((value) => {
      this.productId = value.productId;
      this.literalId = value.literalId;
    });
  }

  labelToClass(label: string | undefined): string {
    return label
      ? label
          .split(' ')
          .join('')
          .toLowerCase()
          .replace(/[^\w\s]/gi, '')
      : '';
  }

  getLayoutType(filter: Filter | undefined) {
    if (filter === undefined) return;
    let layoutType = 'SELECT';
    if (filter.type === 'string[]') {
      layoutType = 'multiSelect';
    } else if (filter.type === 'dateRange') {
      layoutType = 'DATE_RANGE';
    } else if (filter.type === 'number') {
      layoutType = 'INPUT_NUMBER';
    }
    if (filter.literalId === ALLOCATION_LITERAL_ID) {
      layoutType = 'RADIO';
    } else if (filter.literalId === ANCHOR_ALLOCATION_LITERAL_ID) {
      layoutType = 'SELECT_WITH_TEXTBOX';
    } else if (filter.literalId === OPTIMIZED_CAPPED_LITERAL_ID) {
      layoutType = 'SELECT_WITH_TEXTBOX';
    }

    return layoutType;
  }

  getFilter(key: string): Filter | undefined {
    return this.associatedFilterService.getFilter(key, this.filterSet);
  }

  onFilterValueChange(e: Filter) {
    if (e.type === 'number' && e.value === null) {
      e.value = 0;
    }
    this.filterChange.emit(e);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateContext(idx: number, literalId: string): void {
    // noop -> dummy code to remove failures in html template
  }

  getMinDigits(filter: Filter) {
    return filter.format === 'currency' ? 0 : 2;
  }
}
