import { ViewResponse } from './response';

interface CurrentView {
  dashboardHasViews: boolean;
  viewId?: number;
  active?: boolean;
  view?: ViewResponse;
  reloadData?: boolean;
}

enum ViewSaveState {
  saved = 'saved',
  saving = 'saving',
  notSaved = 'notSaved'
}

enum ViewType {
  default = 'default',
  allPlans = 'All Plans',
  myPlans = 'My Plans',
  publishedPlans = 'Published Plans'
}

enum ViewOperations {
  publish = 'publish',
  unpublish = 'unpublish',
  default = 'default',
  delete = 'delete'
}

enum ViewLayoutTypes {
  viewSave = 'VIEW_SAVE',
  viewConfirmSave = 'VIEW_CONFIRM_SAVE',
  childViewSave = 'CHILD_VIEW_SAVE'
}

export {
  CurrentView,
  ViewSaveState,
  ViewType,
  ViewOperations,
  ViewLayoutTypes
};
