import { Injectable } from '@angular/core';
import dayjs, { Dayjs, ManipulateType } from 'dayjs';

// Constants

import { DayJsDateFormat } from '@portal/app/shared/services/date-time.service';
import { Resolution } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class DateRangeUtilityService {
  /**
   * Calculates the maximum date based on T-(N) SLA logic. Where N is the time required for the data to be ready in the BE
   * @param resolution - The resolution unit (e.g., 'day', 'week', 'month').
   * @returns The end of the last completed period as a `Date` object.
   */
  calculateMaxDate(resolution: string): Date {
    const unit = resolution as ManipulateType;
    return dayjs().subtract(1, unit).subtract(3, 'day').endOf(unit).toDate();
  }

  /**
   * Calculates the date range based on resolution and a reference date.
   * @param params - An object containing the resolution unit, number of periods to subtract, reference date, and first day of the week.
   * @returns An object containing the start and stop dates.
   */
  getDateRange(params: {
    resolution: string;
    periodsAgo: number;
    referenceDate?: Dayjs;
    firstDayOfWeek?: number;
  }): { start: string; stop: string } {
    const {
      resolution,
      periodsAgo,
      referenceDate,
      firstDayOfWeek = 0
    } = params;
    const unit = resolution as ManipulateType;
    const refDate = referenceDate || dayjs();
    let startDate = refDate.subtract(periodsAgo, unit).startOf(unit);
    let stopDate = refDate.subtract(periodsAgo, unit).endOf(unit);

    // Adjust for week resolution to ensure it aligns with the correct first day of the week
    if (resolution === Resolution.weekly) {
      startDate = startDate.day(firstDayOfWeek);
      stopDate = startDate.add(6, 'day'); // Ensure it's a week range
    }

    return {
      start: startDate.format(DayJsDateFormat.fullDate),
      stop: stopDate.format(DayJsDateFormat.fullDate)
    };
  }

  /**
   * Sets chart dates based on resolution and stop date.
   * @param resolution - The resolution unit.
   * @param stopDate - The stop date as a string.
   * @returns An object containing the chart's start and stop dates.
   */
  setChartDates(
    resolution: string,
    stopDate: string
  ): { chartStartDate: string; chartStopDate: string } {
    const unit = resolution as ManipulateType;
    const value = resolution === Resolution.quarterly ? 3 : 11;
    const stop = dayjs(stopDate);
    const start = stop.subtract(value, unit).startOf(unit);

    return {
      chartStartDate: start.format(DayJsDateFormat.fullDate),
      chartStopDate: stop.format(DayJsDateFormat.fullDate)
    };
  }
}
