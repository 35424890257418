import { IVendorConfigurationRequest } from '@portal/app/dashboard/integrations/models/vendor/IVendorConfigurationRequest';
import { IVendorInitiateRequest } from '@portal/app/dashboard/integrations/models/vendor/IVendorInitiateRequest';
import { IVendorOAuthConfirmationRequest } from '@portal/app/dashboard/integrations/models/vendor/IVendorOAuthConfirmationRequest';
import { IVendorReConfigurationRequest } from '@portal/app/dashboard/integrations/models/vendor/IVendorReConfigurationRequest';
import { IVendorConnectConfirmation } from '@portal/app/dashboard/integrations/models/vendor/VendorIntegrationModels';
import { VendorIdSource } from '@portal/app/dashboard/integrations/shared/constants';
import { VendorHandler } from '@portal/app/dashboard/integrations/shared/vendor-handlers/vendor-handler';
import { map } from 'lodash-es';
import { IVendorAccounts } from '../../models/IVendorAccounts';

export class ImpactRadiusTransactionHandler implements VendorHandler {
  prepareRequestObjectToInitiateVendor(): IVendorInitiateRequest {
    return {
      queryParams: {},
      pathParams: { vendorId: VendorIdSource.impactTransaction }
    };
  }

  handleVendorInitiateResponse(): void {
    this.confirmVendor();
  }

  confirmVendor(): IVendorConnectConfirmation {
    throw new Error('Method not implemented.');
  }

  prepareRequestObjectToConfigureVendor(
    accounts: IVendorAccounts[]
  ): IVendorConfigurationRequest {
    return {
      vendorId: VendorIdSource.impactTransaction,
      vendorData: map(accounts, (account) => ({
        clientAccountId: account.accountId,
        clientAccountName: account.clientAccountName
      }))
    };
  }

  prepareRequestToConfirmVendor(
    confirmationData: Record<string, string>
  ): IVendorOAuthConfirmationRequest {
    return {
      vendorId: VendorIdSource.impactTransaction,
      userId: confirmationData.userId || '',
      userSecret: confirmationData.userSecret || ''
    };
  }

  prepareRequestObjectToReConfigureVendor(
    accounts: IVendorAccounts[],
    dsInstanceIds: number[]
  ): IVendorReConfigurationRequest {
    return {
      vendorId: VendorIdSource.impactTransaction,
      vendorData: map(accounts, (account) => ({
        clientAccountId: account.accountId
      })),
      dsInstanceIds
    };
  }
}
