import { Component, inject } from '@angular/core';
import { CardVariant } from '@design-system/components/m-card';
import { TrendingChartService } from '@portal/app/dashboard/context-modal/services/trending-chart.service';

@Component({
  selector: 'portal-context-trending-chart',
  templateUrl: './trending-chart.component.html',
  providers: [TrendingChartService]
})
export class TrendingChartComponent {
  trendingChartService = inject(TrendingChartService);

  cardVariantType = CardVariant;

  isLoading = this.trendingChartService.isLoading;
  isChartReady = this.trendingChartService.isChartReady;
  chartOptions = this.trendingChartService.chartOptions;
}
