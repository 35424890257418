/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil
} from 'rxjs/operators';
import { isEqual } from 'lodash-es';

// Services
import { ApiService } from '@portal/app/shared/services/api.service';
import { ChannelsService } from '@portal/app/dashboard/home-page/services/channels.service';
import { CompareService } from '@portal/app/shared/services/compare.service';
import { DateTimeService } from '@portal/app/shared/services/date-time.service';
import { FilterService } from '@portal/app/dashboard/home-page/services/filter.service';
import { FieldService } from '@portal/app/shared/services/field.service';
import { FormatService } from '@portal/app/dashboard/home-page/services/format.service';

// Import base chart configuration
import {
  baseChartConfig,
  getGradientFill
} from '@design-system/components/m-line-chart';
import { colors } from '@design-system/styles/colors';

// Types
import {
  DataEndpoint,
  Filter,
  FieldDefinitions
} from '@portal/app/shared/types';
import type {
  AxisLabelsFormatterContextObject,
  Options,
  SeriesOptionsType,
  TooltipFormatterCallbackFunction,
  YAxisOptions
} from 'highcharts';

import {
  CHART_AXIS_FORMAT_DATEMONTHYEAR,
  formatDateForChart
} from '@portal/app/charts/shared/utils';

@Injectable({
  providedIn: 'root'
})
export class CompareLineChartService implements OnDestroy {
  // Chart options state
  private readonly chartDataSubject = new BehaviorSubject<any>(null);
  public chartData$ = this.chartDataSubject.asObservable();

  // Chart filters state
  private readonly chartDimensionsSubject = new BehaviorSubject<string[]>([
    'mediaSpend',
    'roasI'
  ]);

  public chartDimensions$ = this.chartDimensionsSubject.asObservable();

  // Local filters state
  public currentFilters$ = new BehaviorSubject<Filter[]>([]);

  private readonly currentFields$ = new BehaviorSubject<FieldDefinitions>({});

  // Cleanup
  private destroy$ = new Subject<void>();

  // Loading state
  private readonly isLoadingSubject = new BehaviorSubject<boolean>(false);
  public isLoading$ = this.isLoadingSubject.asObservable();

  // Default chart config
  multiLineChartOptions?: Options = { ...baseChartConfig };

  // Store selected channels for channel filter
  private selectedChannels: string[] = [];

  constructor(
    private readonly apiService: ApiService,
    private channelsService: ChannelsService,
    public readonly compareService: CompareService,
    public readonly dateTimeService: DateTimeService,
    private readonly filterService: FilterService,
    private readonly fieldService: FieldService,
    private readonly formatService: FormatService
  ) {
    this.subscribeToChanges();
  }

  private subscribeToChanges(): void {
    combineLatest([
      this.chartDimensions$,
      this.fieldService.fields$,
      this.filterService.filters$,
      this.channelsService.selectedChannels$
    ])
      .pipe(
        map(([chartDimensions, fields, filters, selectedChannels]) => ({
          chartDimensions,
          fields,
          filters,
          selectedChannels
        })),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        debounceTime(300),
        takeUntil(this.destroy$)
      )
      .subscribe(({ chartDimensions, fields, filters, selectedChannels }) => {
        this.currentFields$.next(fields);
        this.currentFilters$.next(filters);
        this.selectedChannels = selectedChannels;
        this.fetchAndSetChartData(chartDimensions, fields, filters);
      });
  }

  setChartDimensions(dimensions: string[]): void {
    this.chartDimensionsSubject.next(dimensions);
  }

  getChartFilters(chartDimensions: string[], filters?: Filter[]): Filter[] {
    const currentFilters = this.currentFilters$.getValue();
    const combinedFilters = filters
      ? [...currentFilters, ...filters]
      : [...currentFilters];
    // Define which filters are needed for charts
    const neededFilterIds = [
      'dateStart',
      'dateStop',
      'relative_day',
      'conversion_type',
      'isInitialFilterCall',
      'resolution'
    ];

    // Select only necessary filters from combinedFilters
    const selectedFilters = combinedFilters.filter((filter) =>
      neededFilterIds.includes(filter.literalId)
    );

    // Hardcoded filters
    const hardcodedFilters = [
      this.filterService.createFilter({
        literalId: 'chartDimensions',
        type: 'string[]',
        value: chartDimensions
      }),
      this.filterService.createFilter({
        literalId: 'channel',
        type: 'string[]',
        value: this.selectedChannels
      }),
      this.filterService.createFilter({
        literalId: 'charts',
        type: 'string[]',
        value: ['chart']
      })
    ];

    // Combine selected provided filters with hardcoded filters
    const combinedFiltersMap = new Map(
      selectedFilters
        .concat(hardcodedFilters)
        .map((filter) => [filter.literalId, filter])
    );

    return Array.from(combinedFiltersMap.values());
  }

  private fetchAndSetChartData(
    chartDimensions: string[],
    fields: FieldDefinitions,
    filters: Filter[]
  ): void {
    this.isLoadingSubject.next(true);

    const chartFilters = this.getChartFilters(chartDimensions, filters);

    this.apiService
      .getDashboardData({
        filters: chartFilters,
        type: DataEndpoint.dataCharts
      })
      .then((response) => {
        const formattedChartData = this.formatChartData(
          response,
          fields,
          chartDimensions
        );

        this.chartDataSubject.next(formattedChartData);
        this.isLoadingSubject.next(false);
      })
      .catch((error) => {
        console.error('Error fetching chart data:', error);
        this.isLoadingSubject.next(false);
      });
  }

  formatSeriesData(series: SeriesOptionsType[]): SeriesOptionsType[] {
    const colorMappings = [
      {
        color: colors['orange-300'],
        fillColor: getGradientFill(colors['orange-100'] as string)
      },
      {
        color: colors['blue-700'],
        fillColor: getGradientFill(colors['blue-400'] as string)
      }
    ];

    // Initialize multiLineChartOptions and its series array if not already initialized
    this.multiLineChartOptions = this.multiLineChartOptions || { series: [] };

    // Update or add new series data
    const newSeries = series?.map((newSeriesData, index) => {
      // Cast to series with data (e.g., SeriesLineOptions)
      const typedSeriesData = newSeriesData as any; // Use a more specific type here if you know it's a line or spline series

      const existingSeriesData =
        this.multiLineChartOptions?.series?.[index] || {};
      const colorConfig = colorMappings[index] || { color: '', fillColor: '' };

      return {
        ...existingSeriesData,
        name: typedSeriesData?.name,
        data: typedSeriesData?.data,
        color: colorConfig.color,
        fillColor: colorConfig.fillColor,
        yAxis: index
      };
    });

    return newSeries as SeriesOptionsType[];
  }

  formatYAxis(
    series: SeriesOptionsType[],
    chartDimensions: string[]
  ): YAxisOptions[] {
    const formatService = this.formatService;
    const fieldService = this.fieldService;

    // Calculate min/max values for each series
    const seriesMinMax = series.map((s) => {
      const seriesData = (s as any).data as number[]; // Assume `data` is available
      const minValue = Math.min(...seriesData);
      const maxValue = Math.max(...seriesData) * 1.25; // Add a 25% buffer to max
      return { min: minValue, max: maxValue };
    });

    // Create formatter functions and title text for each dimension
    const yAxisOptions = chartDimensions.map((dimension, index) => {
      const fieldDefinition =
        fieldService.getFieldDefinitionByLiteralId(dimension);
      const titleText = fieldDefinition ? fieldDefinition.label : ''; // Get title from field definition

      return {
        ...baseChartConfig?.yAxis,
        min: seriesMinMax[index]?.min,
        max: seriesMinMax[index]?.max,
        title: {
          text: titleText // Dynamically set the yAxis title
        },
        labels: {
          formatter: function (this: AxisLabelsFormatterContextObject) {
            const value = this?.value as number;
            return fieldDefinition
              ? formatService.formatValue(fieldDefinition, value).toString()
              : value.toString();
          },
          style: {
            opacity: 1 // Ensure labels are fully visible
          }
        },
        opposite: index === 1 // Place the second yAxis on the opposite side
      };
    });

    return yAxisOptions;
  }

  formatTooltip() {
    const formatService = this.formatService;
    const fieldService = this.fieldService;
    const formatter: TooltipFormatterCallbackFunction = function () {
      if (!this?.points) {
        return false;
      }
      let tooltipHtml = `<div class="tooltip-body">`;
      tooltipHtml += `<span class="b3 text-gray-500 text-center block">${formatDateForChart(
        dayjs.utc(this?.x, CHART_AXIS_FORMAT_DATEMONTHYEAR).valueOf(),
        'DateMonthYear'
      )}</span>`;
      tooltipHtml += `<div class="m-divider dark my-2"></div>`;

      this?.points?.forEach((point) => {
        if (point?.y !== null && point?.y !== undefined) {
          const seriesName = point?.series?.name;
          const fieldDef = fieldService.getFieldDefinitionByName(seriesName);
          const formattedValue = formatService.formatValue(fieldDef, point?.y);

          tooltipHtml += `<div class="flex justify-between items-center">`;
          tooltipHtml += `<div><span style="color:${point?.color}; font-size: 12px;">\u25CF</span> <span class="b1 text-gray-000 ml-1">${seriesName}</span>:</div>`;
          tooltipHtml += `<span class="b1 text-gray-000">${formattedValue}</span>`;
          tooltipHtml += `</div>`;
        }
      });

      tooltipHtml += `</div>`;
      return tooltipHtml;
    };

    return {
      shared: true,
      useHTML: true,
      formatter
    };
  }

  formatChartData(
    response: any,
    fields: FieldDefinitions,
    chartDimensions: string[]
  ): Options {
    // Before returning this.multiLineChartOptions, ensure it's not undefined
    this.multiLineChartOptions = this.multiLineChartOptions || {
      series: [],
      yAxis: [],
      xAxis: {}
    }; // Provide a minimal valid Options object

    const dataArray = response[0]?.data || [];
    // Create a series for each dimension
    const series: SeriesOptionsType[] = chartDimensions?.map(
      (dimension: string) => ({
        type: 'line',
        name: fields[dimension]?.label,
        data: dataArray?.map((item: any) => item[dimension] || 0)
      })
    );
    // Format series data
    const newSeries = this.formatSeriesData(series);

    // Format yxis
    const yAxis = this.formatYAxis(series, chartDimensions);

    // Format tooltip
    const tooltip = this.formatTooltip();

    // Format categories
    const categories = dataArray.map((item: any) =>
      dayjs.utc(item.date).format(`MMM DD, 'YY`)
    );

    // Update the chart options
    this.multiLineChartOptions = {
      ...this.multiLineChartOptions,
      series: newSeries as SeriesOptionsType[],
      xAxis: {
        ...this.multiLineChartOptions?.xAxis,
        categories
      },
      yAxis,
      tooltip
    };

    return this.multiLineChartOptions;
  }

  /**
   * Fetches and sets chart data using temporary filters.
   *
   * @param chartDimensions Array of chart dimensions.
   * @param tempFilters Temporary filters to apply.
   */
  fetchAndSetChartDataWithTempFilters(tempFilters: Filter[]): void {
    this.isLoadingSubject.next(true);

    // Get the current chart dimensions
    const chartDimensions = this.chartDimensionsSubject.getValue();

    const chartFilters = this.getChartFilters(chartDimensions);
    // Use the unique combined filters for fetching chart data
    this.fetchAndSetChartData(chartDimensions, this.currentFields$.getValue(), [
      ...chartFilters,
      ...tempFilters
    ]);
  }

  public getRelativeDay(): string | undefined {
    const currentFilters = this.currentFilters$.getValue();
    const relativeDayFilter = currentFilters.find(
      (filter) => filter.literalId === 'relative_day'
    );
    return relativeDayFilter?.value as string | undefined;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
