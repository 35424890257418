/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@portal/environments/environment';
import { SelectionService } from '@portal/app/shared/services/selection.service';

@Injectable({
  providedIn: 'root'
})
export class LayoutConfigService {
  private readonly measuredUriV2 = `${environment.apiDomain}/api/v2/`;

  constructor(
    private readonly http: HttpClient,
    private readonly selectionService: SelectionService // Assuming SelectionService exists to fetch client and brand IDs
  ) {}

  getLayoutConfig(
    productId: string,
    dashboardId: string,
    viewId?: number
  ): Observable<any> {
    // Retrieve client and brand IDs from SelectionService
    const { clientId, brandId } = this.selectionService.getClientIdAndBrandId();

    // Set up query parameters
    let params = new HttpParams()
      .set('clientId', clientId)
      .set('brandId', brandId)
      .set('productId', productId.toString())
      .set('dashboardId', dashboardId.toString());

    if (viewId) {
      params = params.set('viewId', viewId.toString());
    }

    // Make the GET request with the parameters
    return this.http.get<any>(`${this.measuredUriV2}dataviz/config/layout`, {
      params
    });
  }
}
