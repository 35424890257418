<ng-container *ngIf="titleConfigs.length > 0">
  <div
    class="d-flex gap-3 align-items-center"
    *ngFor="let titleConfig of titleConfigs; let i = index">
    <ng-container
      *ngIf="
        !titleConfig.isEditable ||
        (titleConfig.isEditable && titleEditModeRecord[i])
      ">
      <span
        *ngIf="titleConfig.title"
        [attr.data-tracker]="titleConfig?.dataTracker"
        class="{{ titleConfig.type }} {{
          titleConfig.type === 'Info' ? 'info-heading' : ''
        }} {{ titleConfig.isEditable ? 'editable-title' : '' }} {{
          titleConfig.styleClass
        }}"
        [pTooltip]="
          titleConfig.ellipsisConfig?.title?.enabled &&
          (titleConfig.title + '').length >
            titleConfig.ellipsisConfig.title.ellipsisMaxLength
            ? titleConfig.title + ''
            : ''
        "
        tooltipPosition="bottom"
        tooltipStyleClass="saas-tooltip"
        (click)="toggleEditModeIfApplicable(titleConfig, i)">
        <ng-container
          *ngIf="
            titleConfig.ellipsisConfig?.title?.enabled;
            else readonlyTitle
          ">
          {{
            titleConfig.title + ""
              | ellipsis: titleConfig.ellipsisConfig.title.ellipsisMaxLength!
          }}
        </ng-container>
        <ng-template #readonlyTitle
          ><span [innerHTML]="titleConfig.title"></span
        ></ng-template>
      </span>
    </ng-container>
    <div
      *ngIf="titleConfig.isEditable && !titleEditModeRecord[i]"
      class="d-flex title-edit-container">
      <input
        #titleInput
        class="{{ titleConfig.type }} {{
          titleConfig.type === 'Info' ? 'info-heading' : ''
        }} {{ titleConfig.styleClass }} lh-base"
        [value]="titleConfig.title"
        (blur)="toggleEditModeIfApplicable(titleConfig, i)"
        (change)="
          emitTitleChangeEvent($event.target.value);
          toggleEditModeIfApplicable(titleConfig, i)
        " />
    </div>
    <span
      *ngIf="titleConfig.subTitle"
      [ngClass]="titleConfig.subTitleStyleClass || ''"
      [pTooltip]="
        titleConfig.ellipsisConfig?.subTitle?.enabled &&
        (titleConfig.subTitle + '').length >
          titleConfig.ellipsisConfig.subTitle.ellipsisMaxLength
          ? titleConfig.subTitle + ''
          : ''
      "
      tooltipPosition="bottom"
      tooltipStyleClass="saas-tooltip"
      [innerHtml]="titleConfig.subTitle"></span>

    <ng-container *ngIf="titleConfig.icons?.length">
      <em
        *ngFor="let icon of titleConfig.icons"
        class="{{ icon.class }}"
        [ngClass]="icon.styleClass || ''"
        pTooltip="{{ icon.tooltip }}"
        showDelay="800"
        tooltipStyleClass="saas-tooltip"
        tooltipPosition="bottom"
        [escape]="!icon.isHtmlTooltip">
        {{ icon.content }}
      </em>
    </ng-container>

    <ng-container *ngIf="titleConfig.chips?.length">
      <p-chip
        *ngFor="let chip of titleConfig.chips"
        [label]="chip.label"
        [styleClass]="chip.styleClass || ''"
        [style.min-width.rem]="7.5">
      </p-chip>
    </ng-container>
  </div>
</ng-container>
