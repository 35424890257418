<div *ngIf="item?.elements" class="row card">
  <ng-container *ngFor="let element of item.elements">
    <ng-container [ngSwitch]="element.layoutType">
      <portal-view-header
        *ngSwitchCase="'VIEW_HEADER'"
        [item]="element"
        [id]="id"
        [view]="view"
        [fieldDefinitions]="fieldDefinitions">
      </portal-view-header>

      <portal-hero-group
        [ngClass]="'custom-hero-data'"
        *ngSwitchCase="'VIEW_DATA_POINT'"
        [items]="element.elements"
        [fieldDefinitions]="fieldDefinitions"
        [data]="dataPoints"></portal-hero-group>
    </ng-container>
  </ng-container>

  <div class="custom-spinner-div" *ngIf="isLoading">
    <p-progressSpinner animationDuration="0.7s" styleClass="custom-spinner">
    </p-progressSpinner>
  </div>
</div>
