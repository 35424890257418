import {
  Component,
  Input,
  HostListener,
  ViewEncapsulation
} from '@angular/core';
import { CardVariant } from './m-card.component.types';

@Component({
  selector: 'm-card',
  templateUrl: './m-card.component.html',
  styleUrls: ['./m-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MCardComponent {
  @Input() draggable = false;
  @Input() hoverable = false;
  @Input() showIcon = false;
  @Input() noShadow = false;
  @Input() variant: CardVariant = CardVariant.standard;
  @Input() styleClass?: string;

  hovered = false;
  dragging = false;

  get cardClasses(): Record<string, boolean> {
    return {
      hover: this.isHovered,
      'small-card': this.variant === 'small',
      compact: this.variant === 'compact',
      draggable: this.draggable,
      dragging: this.dragging,
      'no-shadow': this.noShadow
    };
  }

  get showDragIcon(): boolean {
    return this.draggable && (this.hovered || this.dragging);
  }

  get showHoverIcon(): boolean {
    return this.hoverable && this.hovered && this.showIcon;
  }

  get isHovered(): boolean {
    return this.hovered && this.hoverable;
  }

  @HostListener('mousedown')
  onMouseDown(): void {
    if (this.draggable) {
      this.dragging = true;
    }
  }

  @HostListener('document:mouseup')
  onMouseUp(): void {
    this.dragging = false;
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.hovered = true;
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.hovered = false;
    this.dragging = false;
  }
}
