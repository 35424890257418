import { HttpClient } from '@angular/common/http';
import { ISSOAPIOperations } from '../models/ISSOAPIOperations';
import {
  ISSOUserInfo,
  IUpdateUserRequest,
  ISSOGroupInfo,
  ICreateGroupRequest,
  ISSOServiceResponse,
  ICreateUserRequest
} from '@libs/apis';
import { Utility } from '../helpers/utility';
import { firstValueFrom as promiseFrom } from 'rxjs';

export class SSOAPIOperations implements ISSOAPIOperations {
  private constructor(
    private readonly httpClient: HttpClient,
    private readonly apiV2: string
  ) {}

  public static getInstance(
    httpClient: HttpClient,
    apiV2: string
  ): ISSOAPIOperations {
    return new SSOAPIOperations(httpClient, apiV2);
  }

  public async listUsers(): Promise<ISSOUserInfo[]> {
    return promiseFrom(
      this.httpClient.get<ISSOUserInfo[]>(
        Utility.buildURL({ url: `${this.apiV2}/user/list` })
      )
    );
  }

  public async getUserInfo(email: string): Promise<ISSOUserInfo> {
    return promiseFrom(
      this.httpClient.get<ISSOUserInfo>(
        Utility.buildURL({
          url: `${this.apiV2}/user/info`,
          queryParameters: { email }
        })
      )
    );
  }

  public async listGroups(): Promise<ISSOGroupInfo[]> {
    return promiseFrom(
      this.httpClient.get<ISSOGroupInfo[]>(
        Utility.buildURL({ url: `${this.apiV2}/user/groups/all` })
      )
    );
  }

  public async createUser(
    createUserRequest: ICreateUserRequest
  ): Promise<ISSOUserInfo> {
    return promiseFrom(
      this.httpClient.post<ISSOUserInfo>(
        Utility.buildURL({
          url: `${this.apiV2}/user`
        }),
        createUserRequest
      )
    );
  }

  public async updateUser(
    updateUserRequest: IUpdateUserRequest
  ): Promise<ISSOUserInfo> {
    return promiseFrom(
      this.httpClient.put<ISSOUserInfo>(
        Utility.buildURL({
          url: `${this.apiV2}/user/:userName`,
          pathVariables: { userName: updateUserRequest.userName }
        }),
        updateUserRequest
      )
    );
  }

  public async activateUser(userName: string): Promise<ISSOServiceResponse> {
    return promiseFrom(
      this.httpClient.post<ISSOServiceResponse>(
        Utility.buildURL({
          url: `${this.apiV2}/user/activate/:userName`,
          pathVariables: { userName }
        }),
        null
      )
    );
  }

  public async deactivateUser(userName: string): Promise<ISSOServiceResponse> {
    return promiseFrom(
      this.httpClient.post<ISSOServiceResponse>(
        Utility.buildURL({
          url: `${this.apiV2}/user/deactivate/:userName`,
          pathVariables: { userName }
        }),
        null
      )
    );
  }

  public async createGroup(
    createGroupRequest: ICreateGroupRequest
  ): Promise<ISSOGroupInfo> {
    return promiseFrom(
      this.httpClient.post<ISSOGroupInfo>(
        Utility.buildURL({
          url: `${this.apiV2}/user/groups`
        }),
        createGroupRequest
      )
    );
  }

  public async resendActivationLink(
    userName: string
  ): Promise<ISSOServiceResponse> {
    return promiseFrom(
      this.httpClient.post<ISSOServiceResponse>(
        Utility.buildURL({
          url: `${this.apiV2}/user/resend-activation-email/:userName`,
          pathVariables: { userName }
        }),
        null
      )
    );
  }

  public async resetPassword(userName: string): Promise<ISSOServiceResponse> {
    return promiseFrom(
      this.httpClient.post<ISSOServiceResponse>(
        Utility.buildURL({
          url: `${this.apiV2}/user/reset-password/:userName`,
          pathVariables: { userName }
        }),
        null
      )
    );
  }

  public async resetAuthenticators(
    userName: string
  ): Promise<ISSOServiceResponse> {
    return promiseFrom(
      this.httpClient.post<ISSOServiceResponse>(
        Utility.buildURL({
          url: `${this.apiV2}/user/reset-authenticator/:userName`,
          pathVariables: { userName }
        }),
        null
      )
    );
  }

  public async deleteUser(userName: string): Promise<ISSOServiceResponse> {
    return promiseFrom(
      this.httpClient.delete<ISSOServiceResponse>(
        Utility.buildURL({
          url: `${this.apiV2}/user/delete/:userName`,
          pathVariables: { userName }
        })
      )
    );
  }
}
