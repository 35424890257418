<ng-container *ngIf="avatars.length > 1; else singleAvatar">
  <ng-container *ngIf="grouped; else ungrouped">
    <p-avatarGroup>
      <ng-container *ngFor="let avatar of avatars">
        <ng-container *ngIf="avatar">
          <ng-container *ngIf="avatar.icon">
            <p-avatar
              class="m-avatar"
              [shape]="avatar.shape || 'circle'"
              [styleClass]="avatar.styleClass || ''"
              [style]="{
                height: sizeValue(avatar),
                width: sizeValue(avatar),
                fontSize: fontSize(avatar),
              }">
              <span
                class="flex material-symbols-outlined {{
                  avatar.iconStyleClass
                }}"
                >{{ avatar.icon }}</span
              >
            </p-avatar>
          </ng-container>
          <ng-container *ngIf="avatar.imageUrl; else textAvatar">
            <p-avatar
              class="m-avatar"
              [image]="avatar.imageUrl"
              [shape]="avatar.shape || 'circle'"
              [styleClass]="avatar.styleClass || ''"
              [style]="{
                height: sizeValue(avatar),
                width: sizeValue(avatar),
                fontSize: fontSize(avatar),
                background: 'transparent',
              }"></p-avatar>
          </ng-container>
          <ng-template #textAvatar>
            <p-avatar
              class="m-avatar"
              [shape]="avatar.shape || 'circle'"
              [label]="avatar.initials || 'M'"
              [styleClass]="avatar.styleClass || ''"
              [style]="{
                height: sizeValue(avatar),
                width: sizeValue(avatar),
                fontSize: fontSize(avatar),
                color: avatar.color,
                background: avatar.bg,
              }"></p-avatar>
          </ng-template>
        </ng-container>
      </ng-container>
    </p-avatarGroup>
  </ng-container>
  <ng-template #ungrouped>
    <div class="flex items-center gap-2">
      <ng-container *ngFor="let avatar of avatars">
        <ng-container *ngIf="avatar">
          <ng-container *ngIf="avatar.imageUrl; else textAvatar">
            <p-avatar
              class="m-avatar"
              [image]="avatar.imageUrl"
              [shape]="avatar.shape || 'circle'"
              [styleClass]="avatar.styleClass || ''"
              [style]="{
                height: sizeValue(avatar),
                width: sizeValue(avatar),
                fontSize: fontSize(avatar),
                background: 'transparent',
              }"></p-avatar>
          </ng-container>
          <ng-template #textAvatar>
            <p-avatar
              class="m-avatar"
              [shape]="avatar.shape || 'circle'"
              [label]="avatar.imageUrl ? '' : avatar.initials || 'M'"
              [styleClass]="avatar.styleClass || ''"
              [style]="{
                height: sizeValue(avatar),
                width: sizeValue(avatar),
                fontSize: fontSize(avatar),
                color: avatar.color,
                background: avatar.bg,
              }"></p-avatar>
          </ng-template>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>
</ng-container>
<ng-template #singleAvatar>
  <ng-container *ngIf="firstAvatar">
    <ng-container *ngIf="firstAvatar.icon">
      <p-avatar
        class="m-avatar"
        [shape]="firstAvatar.shape || 'circle'"
        [styleClass]="firstAvatar.styleClass || ''"
        [style]="{
          height: sizeValue(firstAvatar),
          width: sizeValue(firstAvatar),
          fontSize: fontSize(firstAvatar),
          background: firstAvatar.bg,
        }">
        <span
          class="flex material-symbols-outlined {{
            firstAvatar.iconStyleClass
          }}"
          >{{ firstAvatar.icon }}</span
        >
      </p-avatar>
    </ng-container>
    <ng-container *ngIf="firstAvatar.imageUrl; else textAvatar">
      <p-avatar
        class="m-avatar"
        [image]="firstAvatar.imageUrl"
        [shape]="firstAvatar.shape || 'circle'"
        [styleClass]="firstAvatar.styleClass || ''"
        [style]="{
          height: sizeValue(firstAvatar),
          width: sizeValue(firstAvatar),
          fontSize: fontSize(firstAvatar),
          background: 'transparent',
        }"></p-avatar>
    </ng-container>
    <ng-template #textAvatar>
      <p-avatar
        *ngIf="!firstAvatar.icon"
        class="m-avatar"
        [shape]="firstAvatar.shape || 'circle'"
        [label]="firstAvatar.initials || 'M'"
        [styleClass]="firstAvatar.styleClass || ''"
        [style]="{
          height: sizeValue(firstAvatar),
          width: sizeValue(firstAvatar),
          fontSize: fontSize(firstAvatar),
          color: firstAvatar.color,
          background: firstAvatar.bg,
        }"></p-avatar>
    </ng-template>
  </ng-container>
</ng-template>
