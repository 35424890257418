interface DashboardRequestParameters {
  clientId: number;
  brandId: number;
  productId: string;
  literalId: string;
}

interface ProductDashboardInstance {
  status: 'ACTIVE' | 'INACTIVE';
  rCreId: string;
  rCreTime: string;
  rModId: string | null;
  rModTime: string | null;
  id: number;
  dashboardId: number;
  clientId: number;
  brandId: number;
  displayName: string;
  dashboard: ProductDashboard;
}

enum ProductDashboardPersistenceMode {
  dashboardPersistence = 'DASHBOARD_PERSISTENCE',
  multiView = 'MULTI_VIEW'
}

interface ProductDashboard {
  status: 'ACTIVE' | 'INACTIVE';
  rCreId: string;
  rCreTime: string;
  rModId: string | null;
  rModTime: string | null;
  id: number;
  literalId: string;
  productId: string;
  displayName: string;
  dashboardPersistenceMode: ProductDashboardPersistenceMode | null;
  faqLink: string | null;
  product: ProductDashboardProduct;
}

interface ProductDashboardProduct {
  id: string;
  name: string;
}

interface IDashboardRefreshDate {
  lastRefreshDate: string;
}

interface IDashboardAllDataSourcesRefreshDate {
  dataSource: string;
  availableDate: string;
  displayName: string;
}

export {
  ProductDashboard,
  ProductDashboardInstance,
  ProductDashboardProduct,
  DashboardRequestParameters,
  ProductDashboardPersistenceMode,
  IDashboardRefreshDate,
  IDashboardAllDataSourcesRefreshDate
};
