import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil
} from 'rxjs/operators';
import { isEqual } from 'lodash-es';

// Services
import { ApiService } from '@portal/app/shared/services/api.service';
import { FilterService } from '@portal/app/dashboard/home-page/services/filter.service';
import { FieldService } from '@portal/app/shared/services/field.service';
import { DateTimeService } from '@portal/app/shared/services/date-time.service';
import { FormatService } from '@portal/app/dashboard/home-page/services/format.service';
import { MyCustomersService } from '@portal/app/dashboard/home-page/components/my-customers/my-customers.service';

// Chart default config
import { defaultConfig } from '@design-system/components/m-stacked-column-chart/m-stacked-column-chart.component.utils';

// Types
import {
  DataEndpoint,
  DataSetResponse,
  FieldDefinitions,
  Filter,
  FilterValue
} from '@portal/app/shared/types';
import {
  AxisLabelsFormatterContextObject,
  Options,
  SeriesOptionsType,
  TooltipOptions,
  YAxisOptions
} from 'highcharts';
import dayjs from 'dayjs';
import {
  CHART_AXIS_FORMAT_MONTHYEAR,
  formatDateForChart
} from '@portal/app/charts/shared/utils';

@Injectable()
export class NewVsReturningCustomersService implements OnDestroy {
  // Chart options state
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private readonly chartDataSubject = new BehaviorSubject<any>(null);
  public chartData$ = this.chartDataSubject.asObservable();

  // Chart filters state
  private readonly chartDimensionsSubject = new BehaviorSubject<string[]>([
    'newCustomer',
    'returningCustomers',
    'returningCustomersPerc',
    'newCustomersPerc'
  ]);

  public chartDimensions$ = this.chartDimensionsSubject.asObservable();

  private readonly dataPointsSubject = new BehaviorSubject<
    Record<string, unknown>
  >({});

  public dataPoints$ = this.dataPointsSubject.asObservable();

  private readonly dateValueSubject = new BehaviorSubject<string[]>(['', '']);

  public dateValue$ = this.dateValueSubject.asObservable();

  // Local filters state
  public currentFilters$ = new BehaviorSubject<Filter[]>([]);

  private readonly currentFields$ = new BehaviorSubject<FieldDefinitions>({});

  // Cleanup
  private destroy$ = new Subject<void>();

  // Lodaing state
  private readonly isLoadingSubject = new BehaviorSubject<boolean>(false);
  public isLoading$ = this.isLoadingSubject.asObservable();

  // Default chart config
  stackedColumnChartOptions?: Options = defaultConfig;

  minWidth = 120;

  private readonly GROUP_WIDTH = 16;
  private readonly COLUMN_SPACING = 6;

  constructor(
    private readonly apiService: ApiService,
    private readonly filterService: FilterService,
    private readonly fieldService: FieldService,
    private readonly dateTimeService: DateTimeService,
    private readonly formatService: FormatService,
    private readonly myCustomersService: MyCustomersService
  ) {
    this.subscribeToChanges();
  }

  private subscribeToChanges(): void {
    combineLatest([
      this.chartDimensions$,
      this.dateValue$,
      this.fieldService.fields$,
      this.filterService.filters$,
      this.myCustomersService.conversionTypeChange$
    ])
      .pipe(
        map(
          ([
            chartDimensions,
            dateValue,
            fields,
            filters,
            customerSectionConversionType
          ]) => ({
            chartDimensions,
            dateValue,
            fields,
            filters,
            customerSectionConversionType
          })
        ),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        debounceTime(300),
        takeUntil(this.destroy$)
      )
      .subscribe(
        ({
          chartDimensions,
          dateValue,
          fields,
          filters,
          customerSectionConversionType
        }) => {
          this.currentFields$.next(fields);
          this.currentFilters$.next(filters);
          this.fetchAndSetChartData(
            chartDimensions,
            dateValue,
            fields,
            filters,
            customerSectionConversionType
          );
        }
      );
  }

  setChartDimensions(dimensions: string[]): void {
    this.chartDimensionsSubject.next(dimensions);
  }

  setDatevalue(dimensions: string[]): void {
    this.dateValueSubject.next(dimensions);
  }

  getChartFilters(
    chartDimensions: string[],
    dateValue: string[],
    customerSectionConversionType: string,
    filters?: Filter[]
  ): Filter[] {
    const currentFilters = this.currentFilters$.getValue();
    const combinedFilters = filters
      ? [...currentFilters, ...filters]
      : [...currentFilters];
    // Define which filters are needed for charts
    const neededFilterIds = ['isInitialFilterCall'];

    // Select only necessary filters from combinedFilters
    const selectedFilters = combinedFilters.filter((filter) =>
      neededFilterIds.includes(filter.literalId)
    );

    // Hardcoded filters
    const hardcodedFilters = [
      this.filterService.createFilter({
        literalId: 'chartDimensions',
        type: 'string[]',
        value: chartDimensions
      }),
      this.filterService.createFilter({
        literalId: 'charts',
        type: 'string[]',
        value: ['customerChartData']
      }),
      this.filterService.createFilter({
        literalId: 'resolution',
        type: 'string',
        value: 'month'
      }),
      this.filterService.createFilter({
        literalId: 'dateStart',
        type: 'string',
        value: dateValue[0] as FilterValue
      }),
      this.filterService.createFilter({
        literalId: 'dateStop',
        type: 'string',
        value: dateValue[1] as FilterValue
      }),
      this.filterService.createFilter({
        literalId: 'conversion_type',
        type: 'string',
        value: customerSectionConversionType
      })
    ];

    // Combine selected provided filters with hardcoded filters
    const combinedFiltersMap = new Map(
      selectedFilters
        .concat(hardcodedFilters)
        .map((filter) => [filter.literalId, filter])
    );

    return Array.from(combinedFiltersMap.values());
  }

  private fetchAndSetChartData(
    chartDimensions: string[],
    dateValue: string[],
    fields: FieldDefinitions,
    filters: Filter[],
    customerSectionConversionType: string
  ): void {
    this.isLoadingSubject.next(true);

    const chartFilters = this.getChartFilters(
      chartDimensions,
      dateValue,
      customerSectionConversionType,
      filters
    );

    this.apiService
      .getDashboardData({
        filters: chartFilters,
        type: DataEndpoint.dataCharts
      })
      .then((response) => {
        const formattedChartData = this.formatChartData(
          response,
          fields,
          chartDimensions
        );
        this.updateDataPointsValue(response as DataSetResponse[]);
        this.chartDataSubject.next(formattedChartData);
        this.isLoadingSubject.next(false);
      })
      .catch((error) => {
        console.error('Error fetching chart data:', error);
        this.chartDataSubject.next({});
        this.isLoadingSubject.next(false);
      });
  }

  updateDataPointsValue(response: DataSetResponse[]) {
    const dataPoints = response[0]?.dataPoints || {};
    this.dataPointsSubject.next(dataPoints);
  }

  formatSeriesData(series: SeriesOptionsType[]): SeriesOptionsType[] {
    // Initialize stackedColumnChartOptions and its series array if not already initialized
    this.stackedColumnChartOptions = this.stackedColumnChartOptions || {
      series: []
    };

    // Update or add new series data
    const newSeries = series?.map((newSeriesData, index) => {
      // Ensure existing series data is an object
      const existingSeriesData =
        this.stackedColumnChartOptions?.series?.[index] ?? {};

      // Update series data if new data is available
      if ('data' in newSeriesData && Array.isArray(newSeriesData?.data)) {
        return {
          ...existingSeriesData,
          name: newSeriesData?.name,
          data: newSeriesData?.data
        };
      }
      return existingSeriesData;
    });

    return newSeries as SeriesOptionsType[];
  }

  formatYAxis(series: SeriesOptionsType[], chartDimensions: string[]) {
    const formatService = this.formatService;
    const fieldService = this.fieldService;

    // Initialize stackedColumnChartOptions and its series array if not already initialized
    this.stackedColumnChartOptions = this.stackedColumnChartOptions || {
      series: []
    };

    // Pre-calculate series min/max values
    const totalMax = series?.reduce((maxSum, s: SeriesOptionsType) => {
      if ('data' in s && Array.isArray(s?.data)) {
        const maxValue = Math.max(...(s.data as number[]));
        return maxSum + maxValue;
      }
      return maxSum;
    }, 0);

    // Set min to 0 to start y-axis at 0
    const seriesMinMax = [{ min: 0, max: totalMax }];

    const yAxisFormatters = chartDimensions?.map((dimension: string) => {
      return function (this: AxisLabelsFormatterContextObject) {
        const value = this?.value as number;
        const fieldDefinition =
          fieldService.getFieldDefinitionByLiteralId(dimension);
        if (!fieldDefinition) {
          return value.toString(); // Ensure string is returned
        }
        return formatService.formatValue(fieldDefinition, value).toString(); // Convert formatted value to string
      };
    });

    // Handle yAxis options
    const yAxisOptions = Array.isArray(this.stackedColumnChartOptions?.yAxis)
      ? this.stackedColumnChartOptions?.yAxis
      : [this.stackedColumnChartOptions?.yAxis || {}];

    return yAxisOptions?.map((yAxis, index) => {
      const { min, max } = seriesMinMax[index] as { min: number; max: number };
      return {
        ...yAxis,
        min,
        max,
        labels: {
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          formatter: yAxisFormatters[index] || (() => {})
        }
      };
    });
  }

  formatTooltip(): TooltipOptions {
    const formatService = this.formatService;
    const fieldService = this.fieldService;

    const customerOrRevenueKey = this.chartDimensionsSubject
      .getValue()
      .includes('newCustomer')
      ? 'newCustomer'
      : 'newCustomersRevenue';

    return {
      useHTML: true,
      outside: true,
      distance: 15,
      formatter() {
        if (this.point && this.point.y !== undefined) {
          const seriesName = this.point?.series?.name;
          const fieldDef =
            fieldService.getFieldDefinitionByLiteralId(customerOrRevenueKey);
          const formattedValue = formatService.formatValue(
            fieldDef,
            this.point.y
          );
          return `
                  <div class="tooltip-body">
                      <div class="flex justify-center items-center h-full">
                          <div class="b2 text-gray-500">${seriesName}</div>
                      </div>
                      <div class="flex justify-center items-center">
                      <span class="b2 text-gray-500 text-center">${formatDateForChart(
                        dayjs
                          .utc(this?.x, CHART_AXIS_FORMAT_MONTHYEAR)
                          .valueOf(),
                        'Month'
                      )}</span>
                      </div>
                      <div class="m-divider my-2 text-gray-900"></div>
                      <div class="flex justify-between w-full">
                          <span class="b1 text-gray-000">Actual</span>
                          <span class="b1 text-gray-000">${formattedValue}</span>
                      </div>
                      <div class="flex justify-between w-full">
                          <span class="b1 text-gray-000">Percentage</span>
                          <span class="b1 text-gray-000">${this.point?.percentage?.toFixed(
                            2
                          )}%</span>
                      </div>
                  </div>`;
        } else {
          return '';
        }
      },
      style: {
        zIndex: 10
      }
    };
  }

  formatChartData(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response: any,
    fields: FieldDefinitions,
    chartDimensions: string[]
  ): Options {
    // Before returning this.stackedColumnChartOptions, ensure it's not undefined
    this.stackedColumnChartOptions = this.stackedColumnChartOptions || {
      series: [],
      yAxis: {},
      xAxis: {}
    }; // Provide a minimal valid Options object

    const dataArray = response[0]?.data || [];
    // Create a series for each dimension
    const series: SeriesOptionsType[] = (chartDimensions || [])?.map(
      (dimension: string) => ({
        type: 'column',
        name: fields[dimension]?.label, //TODO have literal and label as separate
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: dataArray?.map((item: any) => item[dimension] || 0)
      })
    );
    const seriesData = series.slice(0, 2); //TODO as it has tooltip data also slicing it

    // Format series data
    const newSeries = this.formatSeriesData(seriesData);

    // Format yxis
    const yAxis = this.formatYAxis(seriesData, chartDimensions.slice(0, 2));

    // Format tooltip
    const tooltip = this.formatTooltip();

    // Format categories
    const categories = dataArray.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (item: any) => formatDateForChart(item.date, 'AbbrMonthYear')
    );

    this.setMinWidth(categories.length);

    // Update the chart options
    this.stackedColumnChartOptions = {
      ...this.stackedColumnChartOptions,
      series: newSeries as SeriesOptionsType[],
      xAxis: {
        ...this.stackedColumnChartOptions?.xAxis,
        categories
      },
      yAxis: yAxis as YAxisOptions | YAxisOptions[],
      tooltip
    };

    return this.stackedColumnChartOptions;
  }

  public setMinWidth(categoriesCount: number) {
    this.minWidth =
      categoriesCount * this.GROUP_WIDTH +
      categoriesCount * this.COLUMN_SPACING;
  }

  public getMinWidth() {
    return this.minWidth;
  }

  formatTotalNewValue(literalId: string, value: number): string {
    const fieldDef = this.fieldService.getFieldDefinitionByLiteralId(literalId);
    return this.formatService.formatValue(fieldDef, value);
  }

  // Handles cleanup when the service is destroyed
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
