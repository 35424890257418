/* eslint-disable @typescript-eslint/naming-convention */

import { typographyConfig } from '@design-system/styles/typography';
import { colors } from '@design-system/styles/colors';

export const horizontalBarChart = {
  args: {
    series: [
      {
        name: 'Current Period',
        data: [107, 133, 600],
        pointPadding: 0.2,
        type: 'bar'
      }
    ],
    yAxis: {
      labels: {
        enabled: false
      },
      lineWidth: 1,
      lineColor: colors['gray-300'],
      gridLineWidth: 1,
      gridLineColor: colors['gray-300'],
      gridLineDashStyle: 'Dash',
      title: {
        text: ''
      }
    },
    xAxis: {
      categories: ['Paid Social', 'Paid Search', 'Affiliate'],
      labels: {
        enabled: false
      },
      lineWidth: 1,
      lineColor: colors['gray-300'],
      gridLineWidth: 0
    },
    title: {
      text: 'Current Period',
      align: 'left',
      style: {
        fontSize: typographyConfig.styles.c1.size,
        color: colors['gray-1100'],
        fontFamily: typographyConfig.styles.b2.fontFamily,
        fontWeight: typographyConfig.styles.b2.weight
      }
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      bar: {
        minPointLength: 32,
        dataLabels: {
          enabled: true,
          formatter: function (point: Highcharts.Point): string {
            // Type the 'point' context
            return `<tspan style="stroke: none">${point.y !== undefined ? point.y : 0}</tspan>`; // Added a fallback for safety
          },
          style: {
            fontSize: typographyConfig.styles.c2.size,
            color: colors['gray-700'],
            fontFamily: typographyConfig.styles.c2.fontFamily,
            fontWeight: typographyConfig.styles.c2.weight
          }
        },
        pointWidth: 16,
        color: colors['blue-400']
      }
    },
    tooltip: {
      enabled: false
    }
  }
};
