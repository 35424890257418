// m-type-pill.component.ts
import { Component, Input } from '@angular/core';

// Styles
import { colors } from '@design-system/styles/colors';

// Types
import { TypePillSize } from './m-type-pill.component.types';

@Component({
  selector: 'm-type-pill',
  templateUrl: './m-type-pill.component.html',
  styleUrls: ['./m-type-pill.component.scss']
})
export class MTypePillComponent {
  @Input() size: TypePillSize = TypePillSize.default;
  @Input() text = '';
  @Input() iconName: string | undefined = undefined;
  @Input() secondaryColor = colors['orange-200'];
  @Input() backgroundColor = colors['gray-100'];
  @Input() borderColor = colors['gray-400'];

  readonly tagSize = TypePillSize;
}
