import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Metric } from './m-metric-card.component.types';

@Component({
  selector: 'm-metric-card',
  templateUrl: './m-metric-card.component.html'
})
export class MMetricCardComponent {
  @Input() metric!: Metric;
  @Output() cardClick = new EventEmitter<Metric>();

  onClick(): void {
    this.cardClick.emit(this.metric);
  }
}
