import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  Inject,
  Input,
  PLATFORM_ID,
  QueryList,
  Renderer2,
  TemplateRef
} from '@angular/core';
import {
  ITabPanelHeader,
  TabViewHeaderSize
} from './m-tab-view.component.types';
import { TabView } from 'primeng/tabview';

@Component({
  selector: 'm-tab-view',
  templateUrl: './m-tab-view.component.html',
  styleUrls: ['./m-tab-view.component.scss']
})
export class MTabViewComponent extends TabView implements AfterViewInit {
  @Input() headerSize: TabViewHeaderSize = TabViewHeaderSize.default;
  @Input() tabPanelHeaders: ITabPanelHeader[] = [];
  @ContentChildren('tabContent') tabContents!: QueryList<TemplateRef<any>>;

  tabContentList: TemplateRef<any>[] = [];

  constructor(
    @Inject(PLATFORM_ID) platformId: any,
    el: ElementRef,
    cd: ChangeDetectorRef,
    renderer: Renderer2
  ) {
    super(platformId, el, cd, renderer);
  }

  override ngAfterViewInit() {
    this.tabContentList = this.tabContents.map((t) => t);
    this.sortTabContents();
    // Force change detection after the initial rendering to avoid the error:
    // Expression has changed after it was checked
    this.cd.detectChanges();
  }

  emitIndexChange() {
    this.activeIndexChange.emit(this.activeIndex);
  }

  private sortTabContents() {
    this.tabContentList.sort((t1, t2) => {
      const t1Data = JSON.parse(
        (t1?.elementRef.nativeElement.data as string).replace(
          'bindings=',
          ''
        ) || '{}'
      );

      const t2Data = JSON.parse(
        (t2?.elementRef.nativeElement.data as string).replace(
          'bindings=',
          ''
        ) || '{}'
      );

      return t1Data['ng-reflect-tab-index'] - t2Data['ng-reflect-tab-index'];
    });
  }
}
