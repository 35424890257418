import { animate, style, transition, trigger } from "@angular/animations";

export class FadeInAnimator {
  private static triggerName = 'fadeIn';

  public static fade  = () => trigger(FadeInAnimator.triggerName, [
    transition('* => *', [
      style({ opacity: 0 }),
      animate('300ms ease-out', style({ opacity: 1 }))
    ])
  ]);
}
