<div class="metric-amount-tab-header">
  <div class="b1 text-gray-800">{{ title }}</div>

  <div class="h5 text-gray-1000">
    {{ amount }}
  </div>
  <m-tag
    [isDark]="true"
    [isPositive]="isPositive"
    [isNeutral]="isNeutral"
    [isUp]="isUp"
    >{{
      prevPercDifferenceFormattedValue
        ? prevPercDifferenceFormattedValue
        : (prevPercDifference / 100 | percent: "1.2-2")
    }}</m-tag
  >
</div>
