import { DataResponse } from '@portal/app/shared/types';

export class DataGridTotalRowCustomAggregationUtil {
  public static getCalculationForCustomTotal(
    key: string,
    data: DataResponse,
    dataPoints: DataResponse | undefined
  ) {
    switch (key) {
      case 'roasI':
        return DataGridTotalRowCustomAggregationUtil.calculateROASITotal(data);
      case 'roasLT':
        return DataGridTotalRowCustomAggregationUtil.calculateROASLTTotal(data);
      case 'cpoI':
        return DataGridTotalRowCustomAggregationUtil.calculateCPOITotal(data);
      case 'cpoLT':
        return DataGridTotalRowCustomAggregationUtil.calculateCPOLTTotal(data);
      case 'percSalesI':
        return DataGridTotalRowCustomAggregationUtil.calculatePreSalesITotal(
          data,
          dataPoints
        );
      case 'percOrdersI':
        return DataGridTotalRowCustomAggregationUtil.calculatePreOrdersITotal(
          data,
          dataPoints
        );
      default:
        return null;
    }
  }

  private static calculateROASITotal(data: DataResponse) {
    if (
      typeof data.salesI === 'number' &&
      typeof data.mediaSpend === 'number' &&
      DataGridTotalRowCustomAggregationUtil.divisorIsNotZero(data.mediaSpend)
    ) {
      return data.salesI / data.mediaSpend;
    }
  }

  private static calculateROASLTTotal(data: DataResponse) {
    if (
      typeof data.salesLT === 'number' &&
      typeof data.mediaSpend === 'number' &&
      DataGridTotalRowCustomAggregationUtil.divisorIsNotZero(data.mediaSpend)
    ) {
      return data.salesLT / data.mediaSpend;
    }
  }

  private static calculateCPOITotal(data: DataResponse) {
    if (
      typeof data.mediaSpend === 'number' &&
      typeof data.ordersI === 'number' &&
      DataGridTotalRowCustomAggregationUtil.divisorIsNotZero(data.ordersI)
    ) {
      return data.mediaSpend / data.ordersI;
    }
  }

  private static calculateCPOLTTotal(data: DataResponse) {
    if (
      typeof data.mediaSpend === 'number' &&
      typeof data.ordersLT === 'number' &&
      DataGridTotalRowCustomAggregationUtil.divisorIsNotZero(data.ordersLT)
    ) {
      return data.mediaSpend / data.ordersLT;
    }
  }

  private static calculatePreSalesITotal(
    data: DataResponse,
    dataPoints: DataResponse | undefined
  ) {
    if (
      typeof data.salesI === 'number' &&
      dataPoints &&
      typeof dataPoints.totalSales === 'number' &&
      DataGridTotalRowCustomAggregationUtil.divisorIsNotZero(
        dataPoints.totalSales
      )
    ) {
      return data.salesI / dataPoints.totalSales;
    }
  }

  private static calculatePreOrdersITotal(
    data: DataResponse,
    dataPoints: DataResponse | undefined
  ) {
    if (
      typeof data.ordersI === 'number' &&
      dataPoints &&
      typeof dataPoints.totalOrders === 'number' &&
      DataGridTotalRowCustomAggregationUtil.divisorIsNotZero(
        dataPoints.totalOrders
      )
    ) {
      return data.ordersI / dataPoints.totalOrders;
    }
  }

  private static divisorIsNotZero(value: number): boolean {
    return value !== 0;
  }
}
