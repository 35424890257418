import { Injectable } from '@angular/core';
import { ApiService, IVCampaign } from '@libs/apis';
import { fromPairs, groupBy, sortBy } from 'lodash-es';
import { TaxonomyDimension } from '../../models/ICampaignFilterContext';

@Injectable({
  providedIn: 'root'
})
export class ReviewCampaignsService {
  constructor(private readonly apiService: ApiService) {}

  transformToCampaignsByDimension(
    campaigns: IVCampaign[],
    dimension: TaxonomyDimension,
    field: string
  ): Record<string, Record<string, IVCampaign[]>> {
    if (!dimension) {
      return {};
    }

    return {
      [dimension]: groupBy(campaigns, field)
    };
  }

  transformToCampaignCountByDimension(
    campaigns: IVCampaign[],
    dimension: TaxonomyDimension,
    field: string
  ): Record<string, Record<string, number>> {
    if (!dimension) {
      return {};
    }

    return {
      [dimension]: fromPairs(
        sortBy(
          Object.entries(groupBy(campaigns, field)).map(
            ([dimensionValue, dimensionCampaigns]) => [
              dimensionValue,
              dimensionCampaigns.length
            ]
          ),
          0
        )
      )
    };
  }

  submitInReviewCampaigns(
    clientId: number,
    brandId: number
  ): Promise<Record<string, number>> {
    return this.apiService.taxonomyApisOperations.publishCampaignChanges(
      clientId,
      brandId
    );
  }
}
