import { Component, Input } from '@angular/core';
import { IHeroMetricCard } from '@libs/apis';
import { IHeroMetricConfig } from '../../models/IHeroMetricConfig';
import { CampaignViewService } from '../../services/campaign-view.service';

@Component({
  selector: 'lib-taxonomy-hero-metrics',
  templateUrl: './hero-metrics.component.html',
  styleUrls: ['./hero-metrics.component.scss']
})
export class HeroMetricsComponent {
  @Input()
  metricConfig: IHeroMetricConfig[] = [];

  defaultMetricConfig: IHeroMetricConfig[] = [];

  constructor(private readonly campaignViewService: CampaignViewService) {
    this.defaultMetricConfig = campaignViewService.getHeroMetricConfig([
      {},
      {},
      {}
    ] as IHeroMetricCard[]);
  }
}
