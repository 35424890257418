import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TermsComponent } from './shared/components/terms/terms.component';
import { titleBase } from './shared/helpers/helpers';
import { AuthGuard } from '@portal/app/shared/helpers/auth-guard';
import { CallbackComponent } from '@portal/app/dashboard/integrations/components/auth/callback/callback.component';
import { ShopifyComponent } from '@portal/app/dashboard/integrations/components/shopify/shopify.component';
import { SmartRedirectComponent } from '@portal/app/smart-redirect/smart-redirect.component';

const routes: Routes = [
  {
    path: 'terms',
    component: TermsComponent,
    data: { title: `${titleBase} Terms` }
  },
  {
    path: 'install/shopify',
    component: ShopifyComponent,
    data: { title: `${titleBase} Install Shopify`, isIntegrationsUI: true }
  },
  {
    path: 'oauth/callback/:vendorId',
    canActivate: [AuthGuard],
    component: CallbackComponent,
    data: { title: `${titleBase} Auth Callback`, isIntegrationsUI: true }
  },
  {
    path: 'redirect/:productId/:literalId',
    canActivate: [AuthGuard],
    component: SmartRedirectComponent
  },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableViewTransitions: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
