import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  ICellConfig,
  ICPAdStudyConfig,
  IStudyConfig
} from '@portal/app/dashboard/geo-doe-config/models';
import {
  CellTypes,
  DEFAULT_EXPERIMENT_DURATIONS_IN_DAYS,
  GeoSplitType,
  inventoryRefPeriodSelection,
  OVERLAPPING_DURATION,
  SPLIT_TYPE,
  StudyStatus
} from '@portal/app/dashboard/geo-doe-config/shared/constants';
import { BrandStoreService } from '@portal/app/dashboard/geo-doe-config/data-stores/brand-store.service';
import {
  dateSince,
  Duration,
  yesterday
} from '@portal/app/dashboard/geo-doe-config/shared/dateUtils';
import { isEmpty, uniq } from 'lodash-es';
import { IOverlappingStudies } from '@portal/app/dashboard/geo-doe-config/models/IStudyContaminationValidatorResponse';
import {
  ICampaignAdAccountId,
  ICampaignAdAccountIdV2
} from '@portal/app/dashboard/geo-doe-config/models/IStudyConfig';
import { setSavedStudyDatesWithoutTimezone } from '@portal/app/dashboard/geo-doe-config/shared/utils';
import { IConversionType } from '@portal/app/dashboard/geo-doe-config/models/IConversionType';

@Injectable({
  providedIn: 'root'
})
export class StudyConfigStoreService {
  getInventoryRefEndDateForRefresh = yesterday;

  private readonly studyConfig = new BehaviorSubject<IStudyConfig>(
    this.defaultStudyConfiguration
  );

  private readonly savedStudyConfigData =
    new BehaviorSubject<ICPAdStudyConfig | null>(null);

  private readonly isInventoryChangedForEditSub: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private readonly isReadOnlyViewSub: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private readonly overlappingGeoStudiesSub: BehaviorSubject<
    IOverlappingStudies[]
  > = new BehaviorSubject<IOverlappingStudies[]>([]);

  private minimumCellContribution: BehaviorSubject<string> =
    new BehaviorSubject<string>('');

  private isVendorRemovedConfigSub: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private conversionTypesSub: BehaviorSubject<IConversionType[]> =
    new BehaviorSubject<IConversionType[]>([]);

  constructor(private readonly brandStoreService: BrandStoreService) {}

  get conversionTypes(): IConversionType[] {
    return this.conversionTypesSub.getValue();
  }

  setConversionTypes(conversionTypes: IConversionType[]): void {
    this.conversionTypesSub.next(conversionTypes);
  }

  get defaultStudyConfiguration(): IStudyConfig {
    return {
      studyId: null,
      clientId: this.brandStoreService?.brand?.clientId,
      brandId: this.brandStoreService?.brand?.brandId,
      studyName: '',
      timeToConversion: 0,
      geoSplitLevel: GeoSplitType.REGION,
      startDate: '',
      endDate: '',
      splitType: SPLIT_TYPE,
      geoScopeIds: [52],
      cells: [],
      marketSelectionExecutionId: '',
      status: StudyStatus.ACTIVE,
      conversionTypeId: 0,
      inventoryRefStartDate: this.getInventoryRefStartDateForRefresh(
        DEFAULT_EXPERIMENT_DURATIONS_IN_DAYS
      ),
      inventoryRefEndDate: this.getInventoryRefEndDateForRefresh,
      unapprovedGeoIds: [],
      inventoryRefPeriodSelectionType: inventoryRefPeriodSelection.REFRESH,
      approvedGeos: null,
      experimentDurationInDays: DEFAULT_EXPERIMENT_DURATIONS_IN_DAYS,
      dsFactDataConfigIds: [],
      selectedCampaignsAdAccountIds: {},
      selectedCampaignsAdAccountIdsV2: {},
      allowedOverlapDurationOnStudyLaunchDateInDays: OVERLAPPING_DURATION,
      isStudyWithVendorRemoval: false
    };
  }

  get studyConfiguration(): IStudyConfig {
    return this.studyConfig.getValue();
  }

  get savedStudyConfig(): ICPAdStudyConfig | null {
    return this.savedStudyConfigData.getValue();
  }

  get isInventoryChangedForEdit(): boolean {
    return this.isInventoryChangedForEditSub.getValue();
  }

  get overlappingGeoStudies(): IOverlappingStudies[] {
    return this.overlappingGeoStudiesSub.getValue();
  }

  get isVendorRemovedConfig(): boolean {
    return this.isVendorRemovedConfigSub.getValue();
  }

  setIsVendorRemovedConfig(isVendorRemoved: boolean): void {
    this.isVendorRemovedConfigSub.next(isVendorRemoved);
  }

  setOverlappingGeoStudies(studies: IOverlappingStudies[]): void {
    const overlappingStudies = studies.map((study) => {
      study.study = setSavedStudyDatesWithoutTimezone(study.study);
      return study;
    });
    this.overlappingGeoStudiesSub.next(overlappingStudies);
  }

  setMinimumCellContribution(value: string): void {
    this.minimumCellContribution.next(value);
  }

  getMinimumCellValueBasedOnGeoSplitType(geoSplitType: string): number {
    try {
      const config = JSON.parse(this.minimumCellContribution.getValue());
      return config[geoSplitType] ? config[geoSplitType] * 100 : 0;
    } catch (error) {
      return 0;
    }
  }

  getIsReadOnlyView(): boolean {
    return this.isReadOnlyViewSub.getValue();
  }

  setIsReadOnlyView(value: boolean): void {
    this.isReadOnlyViewSub.next(value);
  }

  getInventoryRefStartDateForRefresh(experimentDuration: number): Date {
    return dateSince(yesterday, experimentDuration - 1);
  }

  setupMarketSelectionExecutionId(executionId: string): void {
    this.studyConfiguration.marketSelectionExecutionId = executionId;
  }

  setStudyConfiguration(studyConfig: IStudyConfig): void {
    this.studyConfig.next(studyConfig);
  }

  setSavedStudyConfig(savedStudyConfig: ICPAdStudyConfig | null): void {
    let study = savedStudyConfig;
    if (savedStudyConfig) {
      study = setSavedStudyDatesWithoutTimezone(savedStudyConfig);
    }
    this.savedStudyConfigData.next(study);
  }

  setStudyConfigToEditStudy(): void {
    if (!this.savedStudyConfig) {
      return;
    }
    const {
      studyId,
      clientId,
      brandId,
      studyName,
      startDate,
      endDate,
      conversionTypeId,
      geoSplitLevel,
      inventoryRefStartDate,
      inventoryRefEndDate,
      marketSelectionExecutionId,
      unapprovedGeoIds,
      timeToConversion,
      geoScopeIds,
      approvedGeos,
      splitType,
      status,
      isStudyWithVendorRemoval
    } = this.savedStudyConfig;
    const studyConfig: IStudyConfig = {
      studyId,
      clientId,
      brandId,
      studyName,
      startDate,
      endDate,
      conversionTypeId,
      geoSplitLevel,
      inventoryRefStartDate,
      inventoryRefEndDate,
      splitType,
      status,
      geoScopeIds,
      approvedGeos,
      marketSelectionExecutionId,
      unapprovedGeoIds,
      timeToConversion,
      experimentDurationInDays: Duration(startDate, endDate),
      inventoryRefPeriodSelectionType: inventoryRefPeriodSelection.DRAFT,
      selectedCampaignsAdAccountIds: {},
      selectedCampaignsAdAccountIdsV2: {},
      cells: [],
      allowedOverlapDurationOnStudyLaunchDateInDays:
        this.defaultStudyConfiguration
          .allowedOverlapDurationOnStudyLaunchDateInDays,
      dsFactDataConfigIds: [],
      isStudyWithVendorRemoval
    };
    this.setStudyConfiguration(studyConfig);
  }

  setInventoryRefStartAndEndDate(type: inventoryRefPeriodSelection): void {
    if (type === inventoryRefPeriodSelection.DRAFT && this.savedStudyConfig) {
      this.studyConfiguration.inventoryRefStartDate =
        this.savedStudyConfig.inventoryRefStartDate;
      this.studyConfiguration.inventoryRefEndDate =
        this.savedStudyConfig.inventoryRefEndDate;
    } else {
      this.studyConfiguration.inventoryRefStartDate =
        this.getInventoryRefStartDateForRefresh(
          this.studyConfiguration.experimentDurationInDays
        );
      this.studyConfiguration.inventoryRefEndDate =
        this.getInventoryRefEndDateForRefresh;
    }
  }

  setIsInventoryChangedForEdit(isChanged: boolean): void {
    this.isInventoryChangedForEditSub.next(isChanged);
  }

  resetStudyConfigs(): void {
    this.setIsReadOnlyView(false);
    this.setSavedStudyConfig(null);
    this.setStudyConfiguration(this.defaultStudyConfiguration);
  }

  isStudyInDraftMode(): boolean {
    return (
      this.savedStudyConfig !== null &&
      this.studyConfiguration.inventoryRefPeriodSelectionType ===
        inventoryRefPeriodSelection.DRAFT &&
      !this.isInventoryChangedForEdit
    );
  }

  isExperimentDurationChangedOnEdit(): boolean {
    if (this.getIsReadOnlyView()) {
      return false;
    }
    if (!this.savedStudyConfig) {
      return false;
    }
    return !(
      Duration(
        this.getDateWithoutTimezoneConsideration(
          this.savedStudyConfig.startDate
        ),
        this.getDateWithoutTimezoneConsideration(this.savedStudyConfig.endDate)
      ) === this.studyConfiguration.experimentDurationInDays
    );
  }

  setDsFactDataConfigIds(configIds: number[]): void {
    this.studyConfiguration.dsFactDataConfigIds = uniq(configIds);
  }

  isConversionTypeChanged(): boolean {
    if (!this.savedStudyConfig) {
      return false;
    }
    return (
      this.savedStudyConfig.conversionTypeId !==
      this.studyConfiguration.conversionTypeId
    );
  }

  setSelectedCampaignsAdAccountIds(): void {
    const data: ICampaignAdAccountId = {};
    const testCells = this.studyConfiguration.cells.filter(
      (cell) => cell.cellType !== CellTypes.BAU
    );
    testCells.forEach((cell) => {
      const { campaigns, geoDataSourceId } = cell;
      const ids = uniq(campaigns.map(({ adAccountId }) => adAccountId));
      if (isEmpty(data[geoDataSourceId])) {
        data[geoDataSourceId] = ids;
      } else {
        data[geoDataSourceId]?.push(...ids);
      }
      data[geoDataSourceId] = uniq(data[geoDataSourceId]);
    });
    this.studyConfiguration.selectedCampaignsAdAccountIds = data;
  }

  setSelectedCampaignsAdAccountIdsV2(): void {
    const data: ICampaignAdAccountIdV2 = {};
    const testCells = this.studyConfiguration.cells.filter(
      (cell) => cell.cellType !== CellTypes.BAU
    );
    testCells.forEach((cell) => {
      const { campaigns, geoDataSourceId } = cell;
      if (!data[geoDataSourceId]) {
        data[geoDataSourceId] = {
          adAccountIds: [],
          compositeDataSourceId: 0,
          associatedCompositeDataSourceId: null
        };
      }
      const adAccountIds = uniq(
        campaigns.map(({ adAccountId }) => adAccountId)
      );
      (
        data[geoDataSourceId] as {
          adAccountIds: (string | number)[];
          compositeDataSourceId: number;
          associatedCompositeDataSourceId: number | null;
        }
      ).compositeDataSourceId = cell.compositeDataSourceId;
      (
        data[geoDataSourceId] as {
          adAccountIds: (string | number)[];
          compositeDataSourceId: number;
          associatedCompositeDataSourceId: number | null;
        }
      ).associatedCompositeDataSourceId = cell.associatedCompositeDataSourceId;
      if (
        isEmpty(
          (
            data[geoDataSourceId] as {
              adAccountIds: (string | number)[];
              compositeDataSourceId: number;
              associatedCompositeDataSourceId: number | null;
            }
          ).adAccountIds
        )
      ) {
        (
          data[geoDataSourceId] as {
            adAccountIds: (string | number)[];
            compositeDataSourceId: number;
            associatedCompositeDataSourceId: number | null;
          }
        ).adAccountIds = adAccountIds;
      } else {
        (
          data[geoDataSourceId] as {
            adAccountIds: (string | number)[];
            compositeDataSourceId: number;
            associatedCompositeDataSourceId: number | null;
          }
        ).adAccountIds.push(...adAccountIds);
      }
      (
        data[geoDataSourceId] as {
          adAccountIds: (string | number)[];
          compositeDataSourceId: number;
          associatedCompositeDataSourceId: number | null;
        }
      ).adAccountIds = uniq(
        (
          data[geoDataSourceId] as {
            adAccountIds: (string | number)[];
            compositeDataSourceId: number;
            associatedCompositeDataSourceId: number | null;
          }
        ).adAccountIds
      );
    });
    this.studyConfiguration.selectedCampaignsAdAccountIdsV2 = data;
  }

  getDateWithoutTimezoneConsideration(date: string | Date): Date {
    return new Date(date.toString().split('Z')[0] as string);
  }

  checkIfStudyIsOldMS(): boolean {
    const savedConfig = this.savedStudyConfig;
    if (savedConfig && this.getIsReadOnlyView()) {
      const { cellConfigs } = savedConfig;
      const testCellConfigs = cellConfigs.filter(
        (cellConfig: ICellConfig) => cellConfig.cellType !== CellTypes.BAU
      );
      return !isEmpty(testCellConfigs)
        ? (testCellConfigs[0] as ICellConfig).msCell === null
        : false;
    } else {
      return false;
    }
  }
}
