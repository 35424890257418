import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ViewTab } from '@portal/app/shared/types';
import { DashboardService } from '@portal/app/shared/services/dashboard.service';
import { SelectionService } from '@portal/app/shared/services/selection.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'portal-tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: ['./tab-bar.component.scss']
})
export class TabBarComponent implements OnInit, OnDestroy {
  @Output() tabChanged = new EventEmitter<ViewTab>();
  public items: ViewTab[] = [];
  private readonly subs: Subscription = new Subscription();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly dashboardService: DashboardService,
    private readonly selectionService: SelectionService
  ) {}

  ngOnInit(): void {
    this.subs.add(
      this.route.params.subscribe((params: Record<string, string>) => {
        this.subs.add(
          this.dashboardService.dashboards.subscribe({
            next: (dashboards) => {
              this.items = [];
              const dashboard = dashboards.find(
                (d) =>
                  d.items &&
                  d.items.find(
                    (di) =>
                      di.literalId === params.literalId &&
                      di.dashboardType === 'REPORTING'
                  )
              );

              if (
                dashboard &&
                dashboard.items &&
                dashboard.items.length &&
                dashboard.productId === params.productId
              ) {
                dashboard.items.forEach((item) => {
                  if (
                    item.dashboardType === 'REPORTING' &&
                    dashboard.productId !== 'portfolio'
                  ) {
                    this.items.push({
                      title:
                        item.status === 'DRAFT'
                          ? `${item.label} (BETA)`
                          : item.label,
                      link: `/a/${this.selectionService.buildSelectionSlug()}/products/${
                        item.routerLinkPart
                      }`,
                      checked:
                        `${params.productId}/${params.literalId}` ===
                        item.routerLinkPart
                    });
                  }
                });
              }
            }
          })
        );
      })
    );
  }

  navigate(item: ViewTab): void {
    this.tabChanged.emit(item);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
