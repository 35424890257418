import { Injectable } from '@angular/core';
import {
  DashboardTitleElement,
  ElementGroup,
  FieldDefinitions,
  HeaderLayoutTypes,
  TitleElement
} from '@portal/app/shared/types';
import {
  BadgeType,
  IBadgeConfig,
  IFAQConfig,
  IHeaderConfig,
  IRefreshTimeConfig,
  ITitleConfig
} from '@portal/app/shared/models/IHeaderConfig';
import { NativeSectionsService } from '@portal/app/shared/services/native-sections.service';
import { compact, filter, find, isEmpty } from 'lodash-es';
import { AppState } from '@portal/app/store/app.state';

@Injectable({
  providedIn: 'root'
})
export class DashboardConfigHeaderTranslationService {
  constructor(public readonly nativeSectionsService: NativeSectionsService) {}

  public prepareHeaderConfig(
    state: AppState | null,
    fieldDefinitions: FieldDefinitions,
    dashboardLiteralId: string,
    item?: ElementGroup
  ): IHeaderConfig | undefined {
    if (!item) {
      return undefined;
    }

    const elements: TitleElement[] = item.elements.sort(
      this.nativeSectionsService.sortByDisplayOrder
    ) as TitleElement[];

    return this.setupDashboardTitleElements(
      state,
      elements,
      fieldDefinitions,
      dashboardLiteralId
    );
  }

  setupDashboardTitleElements(
    state: AppState | null,
    elements: TitleElement[],
    fieldDefinitions: FieldDefinitions,
    dashboardLiteralId: string
  ): IHeaderConfig | undefined {
    const dashboardTitleElements = find(elements, {
      layoutType: HeaderLayoutTypes.dashboardTitle
    }) as DashboardTitleElement | undefined;

    if (dashboardTitleElements) {
      const chunks = (
        dashboardTitleElements.selected as DashboardTitleElement[]
      ).sort(this.nativeSectionsService.sortByDisplayOrder);
      const faqLinkLabel =
        'Get info about ' +
        this.nativeSectionsService.getOptionalLineBreakForId(
          dashboardLiteralId
        ) +
        (state?.dashboard?.displayName || '');
      const faqLink = state?.dashboard?.faqLink || null;
      if (!isEmpty(chunks)) {
        return {
          primaryTitle: this.preparePrimaryTitle(
            chunks,
            fieldDefinitions,
            state
          ),
          secondaryTitles: this.prepareSecondaryTitle(chunks),
          editableTitle: this.prepareEditableTitle(chunks),
          badges: this.prepareBadges(chunks),
          faq: this.prepareFAQ(faqLinkLabel, faqLink),
          refreshTime: this.prepareRefreshTime(chunks, dashboardLiteralId),
          containerStyleClass: dashboardLiteralId
        };
      }
      return undefined;
    }
  }

  private preparePrimaryTitle(
    chunks: DashboardTitleElement[],
    fieldDefinitions: FieldDefinitions,
    state: AppState | null
  ): ITitleConfig | undefined {
    const [primaryHeading] = filter(chunks, {
      layoutType: HeaderLayoutTypes.heading
    });
    return primaryHeading
      ? {
          field: String(primaryHeading.dashboardFieldId),
          span: primaryHeading.span,
          defaultValue:
            (fieldDefinitions.dashboardTitle &&
              fieldDefinitions.dashboardTitle.defaultValue) ||
            state?.dashboard?.displayName ||
            ''
        }
      : undefined;
  }

  private prepareSecondaryTitle(
    chunks: DashboardTitleElement[]
  ): ITitleConfig[] {
    const headerTitles = filter(chunks, {
      layoutType: HeaderLayoutTypes.heading
    });
    return compact(
      headerTitles.slice(1).map((chunk) => {
        return {
          field: String(chunk.dashboardFieldId),
          span: chunk.span
        };
      })
    );
  }

  private prepareEditableTitle(
    chunks: DashboardTitleElement[]
  ): ITitleConfig | undefined {
    const [editableHeading] = filter(chunks, {
      layoutType: HeaderLayoutTypes.editableHeading
    });
    return editableHeading
      ? {
          field: String(editableHeading.dashboardFieldId),
          span: editableHeading.span
        }
      : undefined;
  }

  private prepareBadges(chunks: DashboardTitleElement[]): IBadgeConfig[] {
    const badges = filter(
      chunks,
      (e: DashboardTitleElement) =>
        e.layoutType === HeaderLayoutTypes.badge ||
        e.layoutType === HeaderLayoutTypes.iconBadge
    );

    return compact(
      badges.map((badge) => {
        return {
          field: String(badge.dashboardFieldId),
          span: badge.span,
          type: badge.layoutType as BadgeType,
          icon: badge.additionalAttributes && badge.additionalAttributes.icon,
          dashboardFilterId: badge.dashboardFilterId
        };
      })
    );
  }

  private prepareFAQ(
    faqLinkLabel: string,
    faqLink: string | null
  ): IFAQConfig | undefined {
    return faqLink
      ? {
          label: faqLinkLabel,
          field: faqLink
        }
      : undefined;
  }

  private prepareRefreshTime(
    chunks: DashboardTitleElement[],
    dashboardLiteralId: string
  ): IRefreshTimeConfig | undefined {
    const [refreshTime] = filter(chunks, {
      layoutType: HeaderLayoutTypes.refreshTime
    });
    if (
      refreshTime &&
      refreshTime.additionalAttributes &&
      dashboardLiteralId === 'cross-channel'
    ) {
      const { additionalAttributes } = refreshTime;
      return {
        label: refreshTime.label,
        link: additionalAttributes.link,
        columns: additionalAttributes.columns,
        span: refreshTime.span
      };
    }
  }
}
