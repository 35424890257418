import { Component, Input, OnInit } from '@angular/core';
import {
  IHeaderConfig,
  IRefreshTimeConfig
} from '@portal/app/shared/models/IHeaderConfig';
import { forkJoin, take } from 'rxjs';
import { IPopupTableColumnConfig } from '../../models/IPopup';
import { ApiService } from '@portal/app/shared/services/api.service';
import { IDashboardAllDataSourcesRefreshDate } from '@portal/app/shared/types';
import { LastRefreshControllerService } from '@libs/api';
import { ViewStore } from '@portal/app/shared/state/view.store';

@Component({
  selector: 'portal-refresh-time',
  templateUrl: './refresh-time.component.html',
  styleUrls: ['./refresh-time.component.scss']
})
export class RefreshTimeComponent implements OnInit {
  @Input() headerConfig!: IHeaderConfig | { refreshTime: IRefreshTimeConfig };
  notAvailable = 'Not available';
  latestRefreshTime: string | null = null;
  isRefreshTimePopupVisible = false;
  dialogSubTitle = '';
  refreshTimeTableData: IDashboardAllDataSourcesRefreshDate[] = [];
  refreshTimeTableColumns: IPopupTableColumnConfig[] = [];
  private clientId = 0;
  private brandId = 0;
  private literalId = '';

  constructor(
    private readonly apiService: ApiService,
    private readonly lastRefreshControllerService: LastRefreshControllerService,
    private readonly viewStore: ViewStore
  ) {
    this.viewStore.selectedBCPDId.subscribe((value) => {
      this.clientId = value.clientId;
      this.brandId = value.brandId;
      this.literalId = value.literalId;
    });
  }

  ngOnInit(): void {
    this.setupRefreshTimeConfig();
    this.fetchLatestRefreshTime();
  }

  setupRefreshTimeConfig(): void {
    const { refreshTime } = this.headerConfig;
    if (refreshTime) {
      this.dialogSubTitle = `Your dashboard may get updated for a variety of reasons. <a href='${refreshTime.link}' target="_blank">Learn about them here.</a>`;
      this.refreshTimeTableColumns = refreshTime.columns.map((e) => ({
        columnId: e.id,
        label: e.label,
        dataType: (e.id === 'availableDate' && 'date') || 'string'
      }));
    }
  }

  fetchLatestRefreshTime(): void {
    forkJoin([
      this.lastRefreshControllerService.getDashboardLastRefreshDate(
        this.literalId,
        this.clientId,
        this.brandId
      ),
      this.apiService.getDashboardAllDataSourcesLatestRefreshTime()
    ])
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.latestRefreshTime = response[0].lastRefreshDate;
          this.refreshTimeTableData = response[1];
          this.sortData();
        },
        error: () => {
          this.latestRefreshTime = this.notAvailable;
        }
      });
  }

  sortData(): void {
    this.refreshTimeTableData = this.refreshTimeTableData.sort((a, b) =>
      a.displayName.localeCompare(b.displayName)
    );
  }

  onRefreshTimeClick(): void {
    if (this.latestRefreshTime !== this.notAvailable) {
      this.isRefreshTimePopupVisible = true;
    }
  }

  closeDataUpdatesPopup(): void {
    this.isRefreshTimePopupVisible = false;
  }
}
