<p-multiSelect
  [appendTo]="appendTo"
  [ngClass]="{
    'm-multiselect m-checkbox': true,
    'is-draggable': isDraggable,
    'ng-invalid': !isValid,
  }"
  [filterPlaceHolder]="filterPlaceHolder"
  [maxSelectedLabels]="maxSelectedLabels"
  [(ngModel)]="value"
  (ngModelChange)="onModelChange($event)"
  [options]="options"
  (onPanelShow)="handlePanelShow()"
  [placeholder]="placeholder()"
  [selectedItemsLabel]="selectedItemsLabel"
  [style]="{ width: width, height: height }"
  [panelStyleClass]="panelStyleClass + ' m-multiselect-panel'"
  (onPanelHide)="onPanelHide.emit($event)">
  <!-- Custom Filter Icon Template -->
  <ng-template pTemplate="filtericon">
    <!-- Example using a Font Awesome icon -->
    <i
      class="material-symbols-outlined icon-regular text-blue-600"
      aria-hidden="true"
      >search</i
    >
  </ng-template>
</p-multiSelect>
