import {
  animate,
  group,
  style,
  transition,
  trigger
} from '@angular/animations';
import { CampaignType, ICampaign } from '@libs/apis';
import {
  IActionButtonConfig,
  IHeaderConfig,
  IPTableColumnInfo,
  ITitle,
  TitleTypes
} from '@libs/common-components';

export class TaxonomyUiConstants {
  public static readonly unmappedValue = 'Others';
  public static readonly dataSourceChannelTableColumns: IPTableColumnInfo[] = [
    {
      width: '5%',
      field: 'tick',
      header: ''
    },
    {
      width: '55%',
      field: 'compositeDataSourceName',
      header: 'Data Source',
      isFilterEnabled: true
    },
    {
      width: '22%',
      field: 'unmappedCount',
      header: 'Unmapped Campaigns'
    },
    {
      width: '20%',
      field: 'mappedCount',
      header: 'Mapped Campaigns'
    }
  ];

  public static readonly filterCriteria = [
    {
      label: 'Contains',
      value: 'contains'
    },
    {
      label: 'Starts With',
      value: 'startsWith'
    },
    {
      label: 'Ends With',
      value: 'endsWith'
    },
    {
      label: 'Equals',
      value: 'equals'
    }
  ];

  public static readonly headerConfig: IHeaderConfig = {
    primaryTitle: {
      field: 'title',
      defaultValue: 'Campaign Mapping',
      span: 6
    },
    secondaryTitles: [],
    badges: [],
    faq: {
      field:
        'https://support.measured.com/article/7v2r2yep3o-integrations-overview',
      span: 3,
      label: 'Have questions? Check out our FAQs.'
    }
  };

  public static readonly dataSourcesTitleConfigs: ITitle[] = [
    {
      title: 'Data Sources',
      type: TitleTypes.h2,
      subTitle: null,
      styleClass: 'text-orange fw-light'
    },
    {
      title: `Click the number under Unmapped or Mapped Campaigns to see more details.`,
      type: TitleTypes.info,
      subTitle: null,
      styleClass: 'text-gray fw-normal'
    }
  ];

  public static readonly checkboxTableColumn: IPTableColumnInfo = {
    width: '5%',
    field: 'checkbox',
    header: ''
  };

  public static readonly defaultCampaignTableColumns: IPTableColumnInfo[] = [
    {
      width: '25%',
      field: 'campaign',
      header: 'Campaign',
      isFilterEnabled: true
    },
    {
      width: '25%',
      field: 'accountName',
      header: 'Ad Account',
      isFilterEnabled: true
    }
  ];

  public static readonly tacticTableColumn = {
    width: '20%',
    field: 'tactic',
    header: 'Tactic',
    isFilterEnabled: true,
    value: (campaign: ICampaign) =>
      campaign.tactic === TaxonomyUiConstants.unmappedValue
        ? TaxonomyUiConstants.unmappedValue
        : campaign.tactic
  };

  public static readonly channelTableColumn = {
    width: '20%',
    field: 'channel',
    header: 'Channel',
    isFilterEnabled: true,
    value: (campaign: ICampaign) =>
      campaign.channel === TaxonomyUiConstants.unmappedValue
        ? TaxonomyUiConstants.unmappedValue
        : campaign.channel
  };

  public static readonly modifiedByTableColumns: IPTableColumnInfo = {
    width: '20%',
    field: 'modifiedBy',
    header: 'Modified By'
  };

  public static readonly segmentTableColumn = {
    width: '20%',
    field: 'segment',
    header: 'Segment',
    isFilterEnabled: true,
    value: (campaign: ICampaign) => {
      return campaign.segment &&
        campaign.segment !== TaxonomyUiConstants.unmappedValue
        ? campaign.segment
        : TaxonomyUiConstants.unmappedValue;
    }
  };

  public static readonly createNewTactic = 'Create New Tactic';
  public static readonly createNewChannel = 'Create New Channel';
  public static readonly mappedCount = 'mappedCount';
  public static readonly unmappedCount = 'unmappedCount';
  public static readonly underProcessingCount = 'underProcessingCount';
  public static readonly multipleTactics = 'Multiple Tactics';
  public static readonly defaultLazyLoadingSize = 1000;

  public static readonly campaignTypeFactory: Record<string, string> = {
    [this.mappedCount]: CampaignType.MAPPED_CAMPAIGN,
    [this.unmappedCount]: CampaignType.UNMAPPED_CAMPAIGN,
    [this.underProcessingCount]: CampaignType.UNDER_PROCESSING_CAMPAIGN
  };

  public static readonly fetchingCampaignsLoaderMessage: Record<
    string,
    string
  > = {
    mappedCount: 'Retrieving mapped campaigns',
    unmappedCount: 'Retrieving unmapped campaigns',
    underProcessingCount: 'Retrieving campaigns being processed'
  };

  public static readonly loadFactor = 0.25;
  public static readonly waitTimeInMilliseconds = 2000;

  public static readonly updateTacticOverlayTriggerAnimations = [
    trigger('updateTacticOverlayTrigger', [
      transition(':enter', [
        style({
          width: 10,
          transform: 'translateX(0px)',
          opacity: 0
        }),
        group([
          animate(
            '0.3s ease',
            style({
              transform: 'translateX(0)',
              width: '*'
            })
          ),
          animate(
            '0.3s ease',
            style({
              opacity: 1
            })
          )
        ])
      ]),
      transition(':leave', [
        group([
          animate(
            '0.3s ease',
            style({
              transform: 'translateX(0px)',
              width: 0
            })
          ),
          animate(
            '0.2s ease',
            style({
              opacity: 0
            })
          )
        ])
      ])
    ])
  ];

  public static readonly failureMessage = 'Failed to retrieve campaigns';
  public static readonly campaignFactory: Record<string, string> = {
    [this.mappedCount]: 'mapped campaigns',
    [this.unmappedCount]: 'unmapped campaigns',
    [this.underProcessingCount]: 'under processing campaigns'
  };

  public static readonly updateTactic = 'tactic-update';
  public static readonly publishChanges = 'publish-changes';
  public static readonly retrievingCampaigns = 'Retrieving Campaigns!';
  public static readonly negativeMultipleFilterCharacter = '~';

  public static readonly reviewButtonConfig: IActionButtonConfig = {
    label: 'Review Changes',
    styleClass: 'p-button p-button-outlined',
    value: 'reviewChanges',
    disabled: true
  };

  public static readonly goBackButtonConfig: IActionButtonConfig = {
    label: 'Go Back',
    styleClass: 'p-button p-button-outlined',
    value: 'goBack'
  };

  public static readonly publishButtonConfig: IActionButtonConfig = {
    label: 'Publish',
    styleClass: 'p-button p-button-primary',
    value: 'publish',
    disabled: true
  };

  public static readonly unpublishedTitleConfig: ITitle[] = [
    {
      title: `The Unpublished changes across all data sources are listed here. Please verify the accuracy of changes before clicking Publish.<br> If you publish the same campaign twice, the most recent version will be published.`,
      type: TitleTypes.info,
      subTitle: null,
      styleClass: 'text-gray fw-normal'
    }
  ];

  public static readonly pendingChangesTitleConfig: ITitle[] = [
    {
      title: `The pending changes are listed here. If you publish the same campaign twice, the most recent version will be published.`,
      type: TitleTypes.info,
      subTitle: null,
      styleClass: 'text-gray fw-normal'
    }
  ];

  public static readonly ruleDescriptionFieldConfig = {
    channel: 'channelRuleDesc',
    tactic: 'tacticRuleDesc',
    segment: 'segmentRuleDesc'
  };
}

export enum TaxonomyReviewChangeType {
  unpublished = 'Unpublished',
  pendingChanges = 'Pending changes'
}

export enum CampaignDimension {
  channel = 'channel',
  tactic = 'tactic',
  segment = 'segment'
}
