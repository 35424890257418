<span class="timestamp-container">
  @if (!latestRefreshTime) {
    <div class="loader-container me-2">
      <p-progressSpinner strokeWidth="4"></p-progressSpinner>
    </div>
  } @else {
    <span class="latest-refresh-time-container" (click)="onRefreshTimeClick()">
      <span>
        {{ headerConfig.refreshTime?.label }} {{ latestRefreshTime }}
      </span>
    </span>
  }
</span>
@if (isRefreshTimePopupVisible) {
  <portal-popup
    [(dialogVisibility)]="isRefreshTimePopupVisible"
    dialogTitle="Data Updates"
    [dialogSubTitle]="dialogSubTitle"
    (dialogVisibilityChange)="closeDataUpdatesPopup()"
    [tableColumns]="refreshTimeTableColumns"
    [tableData]="refreshTimeTableData"></portal-popup>
}
