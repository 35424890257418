import { Params } from '@angular/router';
import { createAction, props } from '@ngrx/store';
import {
  AssociatedFilterValues,
  DataResponse,
  Filter
} from '@portal/app/shared/types';

const navigateToChild = createAction(
  '[Multi View] navigateToChild',
  props<{ viewId?: number; rowData: DataResponse; filters: Filter[] }>()
);

const navigateToParent = createAction(
  '[Multi View] navigateToParent',
  props<{ viewId?: number }>()
);

const setQueryParams = createAction(
  '[Multi View] setQueryParams',
  props<{ queryParams: Params }>()
);

const setAssociatedFilterHolder = createAction(
  '[Multi View] setAssociatedFilterHolder',
  props<{ associatedFilterHolder: AssociatedFilterValues }>()
);
// eslint-disable-next-line @typescript-eslint/naming-convention
export const MultiViewActions = {
  navigateToChild,
  navigateToParent,
  setQueryParams,
  setAssociatedFilterHolder
};
