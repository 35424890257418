import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
  OnInit,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { ChangeContext, Options } from '@angular-slider/ngx-slider';

import { colors } from '../../styles/colors/colors.config';

/**
 * MSliderStepComponent is a slider component with ticks.
 *
 * @example
 * <m-slider-step [steps]=[steps] (stepChange)="onStepChange()"></m-slider-step>
 */
@Component({
  selector: 'm-slider-step',
  templateUrl: './m-slider-step.component.html',
  styleUrls: ['./m-slider-step.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MSliderStepComponent implements OnInit, OnChanges {
  @Input() steps: string[] = [];
  @Input() initialValue = 0; // Input property for the initial value
  @Output() stepChange = new EventEmitter<number>();

  stepIndex = 0;
  sliderValue = 0; // This will be bound to the slider's value

  stepColors = [
    colors['gray-300'],
    colors['orange-100'],
    colors['orange-200'],
    colors['orange-300']
  ];

  options: Options = {
    floor: 0,
    ceil: 3,
    step: 1,
    showTicks: true,
    showTicksValues: false,
    ticksArray: [1, 2],
    showSelectionBarFromValue: 0,
    hideLimitLabels: true,
    hidePointerLabels: true,
    showSelectionBar: false,
    animateOnMove: true,
    getSelectionBarColor: (index: number) => {
      return String(this.stepColors[index]);
    }
  };

  ngOnInit() {
    this.sliderValue = this.initialValue; // Initialize sliderValue with the input value
    this.stepIndex = this.initialValue; // Optionally, set stepIndex as well
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['steps']) {
      this.updateOptions();
    }
  }

  private updateOptions() {
    this.options = {
      ...this.options,
      ceil: this.steps.length - 1,
      ticksArray: Array.from({ length: this.steps.length }, (_, index) => index)
    };
  }

  onUserChange(changeContext: ChangeContext): void {
    this.stepIndex = changeContext.value;
    this.sliderValue = changeContext.value; // Update sliderValue on user change
    this.stepChange.emit(this.stepIndex);
  }
}
