import { OktaAuthOptions } from '@okta/okta-auth-js';

export interface IAngularEnvironment {
  production: boolean;
  stage: boolean;
  innovation: boolean;
  clientVersion: string;
  apiV1: string;
  apiV2: string;
  apiDomain: null | string;
  authToken?: null | string;
  ssoProvider: SSOProvider;
  oktaConfig: OktaAuthOptions & { learnUponAppId: string };
  awsConfig: {
    issuer: string;
    clientId: string;
    redirectUri: string;
  };
}

export enum SSOProvider {
  aws = 'AWS',
  okta = 'OKTA'
}
