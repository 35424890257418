import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { custom } from '@portal/app/shared/services/date-time.service';
import { DropdownService } from '@portal/app/dashboard/home-page/services/dropdown.service';
import { FilterService } from '@portal/app/dashboard/home-page/services/filter.service';
import { HeaderService } from './header.service';
import { UserService } from '@portal/app/dashboard/home-page/services/user.service';

// Components
import { DateRangePickerComponent } from '@portal/app/dashboard/date-range-picker/date-range-picker.component';

// Types
import type {
  DateRange,
  DateRangeData,
  Filter,
  FilterValue
} from '@portal/app/shared/types';
import dayjs from 'dayjs';
import { NativeSectionsService } from '@portal/app/shared/services/native-sections.service';

@Component({
  selector: 'portal-homepage-header',
  templateUrl: './header.component.html'
})
export class HomepageHeaderComponent implements OnInit, OnDestroy {
  userName = '';
  title = '';
  compareDateRange: DateRange | null = null;
  compareFilter: Filter | undefined = undefined;
  conversionTypeOptions: SelectItem[] = [];
  conversionType: FilterValue = '';
  relativeDay: FilterValue = '';
  relativeDayFilter: Filter | undefined = undefined;
  relativeDayOptions: SelectItem[] = [];
  selectedDateRange: DateRange | null = null;
  previousRelativeDay: FilterValue = '';
  minDate!: dayjs.Dayjs;

  private destroy$ = new Subject<void>();

  @Input() hasData = false;

  // Components
  @ViewChild('datePicker') datePickerComponent!: DateRangePickerComponent;

  constructor(
    private dropdownService: DropdownService,
    private filterService: FilterService,
    private headerService: HeaderService,
    private userService: UserService,
    public readonly nativeSectionsService: NativeSectionsService
  ) {}

  ngOnInit() {
    this.initializeUser();
    this.initializeDropdownOptions();
    this.subscribeToServiceObservables();
  }

  private initializeUser() {
    this.userService.fetchAndSetUserName();
    this.userService.userName
      .pipe(takeUntil(this.destroy$))
      .subscribe((userName) => {
        this.userName = userName;
        this.title = this.getTitle(this.userName);
      });
  }

  private initializeDropdownOptions() {
    this.relativeDayOptions = this.dropdownService.getRelativeDayOptions();
  }

  private subscribeToServiceObservables() {
    this.subscribeToDashboardState();
    this.subscribeToConversionTypeOptions();
    this.subscribeToSelectedConversionType();
    this.subscribeToSelectedRelativeDay();
    this.subscribeToSelectedDateRange();
    this.subscribeToCompareDateRange();
    this.subscribeToCompareFilter();
  }

  private subscribeToConversionTypeOptions() {
    this.headerService.conversionTypeOptions$
      .pipe(takeUntil(this.destroy$))
      .subscribe((options) => (this.conversionTypeOptions = options));
  }

  private subscribeToSelectedConversionType() {
    this.headerService.selectedConversionType$
      .pipe(takeUntil(this.destroy$))
      .subscribe((type) => (this.conversionType = type));
  }

  private subscribeToSelectedRelativeDay() {
    this.headerService.selectedRelativeDay$
      .pipe(takeUntil(this.destroy$))
      .subscribe((relativeDay) => {
        this.relativeDayFilter = relativeDay;
        this.previousRelativeDay = relativeDay?.value || '';
        this.relativeDay = relativeDay?.value || '';
      });
  }

  private subscribeToSelectedDateRange() {
    this.headerService.selectedDateRange$
      .pipe(takeUntil(this.destroy$))
      .subscribe((dateRange) => (this.selectedDateRange = dateRange));
  }

  private subscribeToCompareDateRange() {
    this.headerService.compareDateRange$
      .pipe(takeUntil(this.destroy$))
      .subscribe((compareDateRange) => {
        this.compareDateRange = compareDateRange;
      });
  }

  private subscribeToCompareFilter() {
    this.headerService.compareFilter$
      .pipe(takeUntil(this.destroy$))
      .subscribe((compareFilter) => {
        this.compareFilter = compareFilter;
      });
  }

  private subscribeToDashboardState() {
    this.headerService.dashboardState$
      .pipe(takeUntil(this.destroy$))
      .subscribe((dashboardState) => {
        this.minDate = this.nativeSectionsService.getMinDate(
          dashboardState?.reportingRangeInMonths
        );
      });
  }

  onRelativeDayChange(value: string) {
    if (value === custom) {
      this.datePickerComponent.showCalendar = true;
    } else {
      this.headerService.setRelativeDay(value);
    }
  }

  onConversionTypeChange(conversionType: string) {
    this.headerService.setConversionType(conversionType);
  }

  navigateToSupportPage(): void {
    window.open(
      'https://support.measured.com/article/ng7ezt7tuv-homepage-using-your-measured-homepage',
      '_blank'
    );
  }

  onCustomDateChange(dateRangeData: DateRangeData[]) {
    this.headerService.updateDateFilters(dateRangeData);
    this.headerService.setDateFilters(dateRangeData);
    const selectedCompareOptionName = dateRangeData.find(
      (d) => (d.filterLiteralId = 'selected_compare_option')
    )?.name as string;
    // Update selected compare option upon save
    this.filterService.setSelectedCompareOption(selectedCompareOptionName);
  }

  handleCancel() {
    this.relativeDay = this.previousRelativeDay;
  }

  // Handle "Custom" option being clicked
  onDropdownClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;

    const isCustomSpan =
      target.tagName === 'SPAN' &&
      target.classList.length === 1 &&
      target.classList.contains('ng-star-inserted') &&
      target.innerText.trim() === custom;

    const isCustomLabel =
      target.tagName === 'LI' && target.getAttribute('aria-label') === custom;

    // Check if the target is custom option
    if (isCustomSpan || isCustomLabel) {
      this.datePickerComponent.showCalendar = true;
    }
  }

  handleCompareOptionChange(selectedCompareOptionName: string): void {
    this.filterService.setSelectedCompareOption(selectedCompareOptionName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getTitle(userName: string) {
    let title = `Welcome, ${userName}`;
    if (!userName || userName === 'Unknown User') {
      title = 'Welcome to Measured';
    }
    return title;
  }
}
