import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Filter } from '@portal/app/shared/types';

@Component({
  selector: 'portal-button-group-custom',
  templateUrl: './button-group-custom.component.html',
  styleUrls: ['./button-group-custom.component.scss']
})
export class ButtonGroupCustomComponent implements OnInit {
  @Output() valueChange: EventEmitter<Filter> = new EventEmitter<Filter>();
  @Input() literalId!: string;
  @Input() options: number[] | string[] = [];
  @Input() filter!: Filter;
  @Input() allowCustom = false;
  isCustomValue = true;

  ngOnInit(): void {
    this.options.forEach((option: number | string) => {
      if (option === this.filter.value) {
        this.isCustomValue = false;
      }
    });
  }

  shouldShowCustom(): boolean {
    return !!this.filter.allowCustom;
  }

  onValueChange() {
    this.valueChange.emit(this.filter);
  }
}
