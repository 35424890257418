import { Pipe, PipeTransform } from '@angular/core';
import { FieldDefinition } from '@portal/app/shared/types';

@Pipe({ name: 'formatFilterType' })
export class FormatFilterTypePipe implements PipeTransform {
  transform(field: FieldDefinition): 'numeric' | 'date' | 'text' {
    if (field?.type === 'double') {
      return 'numeric';
    } else if (field?.type === 'datetime') {
      return 'date';
    }
    return 'text';
  }
}
