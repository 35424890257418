import { IShopifyDashboardData } from '@portal/app/dashboard/samples/shopify/shopify-dashboard.models';

export const dummyData: IShopifyDashboardData[] = [
  {
    customerId: 1,
    orderId: 1000,
    createdAt: '2024-04-19T16:10:00.000Z',
    orders: 4,
    totalSales: 40.28,
    netSales: 40.11,
    grossSales: 40.28,
    totalSalesCalc: 40.28,
    totalLineItemsPrice: 40.28,
    totalDiscounts: 0.17,
    billingAddressCountry: 'France (FR)',
    cancelledAt: null,
    shopName: 'My Cool Shop',
    sourceName: 'Email Marketing'
  },
  {
    customerId: 2,
    orderId: 1001,
    createdAt: '2024-04-25T09:00:00.000Z',
    orders: 1,
    totalSales: 105.94,
    netSales: 101.98,
    grossSales: 105.94,
    totalSalesCalc: 105.94,
    totalLineItemsPrice: 105.94,
    totalDiscounts: 3.95,
    billingAddressCountry: 'Country 11',
    cancelledAt: null,
    shopName: 'Tech Gadgets',
    sourceName: 'Direct Traffic'
  },
  {
    customerId: 3,
    orderId: 1002,
    createdAt: '2024-04-27T06:05:00.000Z',
    orders: 4,
    totalSales: 5.54,
    netSales: 5.09,
    grossSales: 5.54,
    totalSalesCalc: 5.54,
    totalLineItemsPrice: 5.54,
    totalDiscounts: 0.44,
    billingAddressCountry: 'United States (US)',
    cancelledAt: null,
    shopName: 'Homeware Shop',
    sourceName: 'Paid Search'
  },
  {
    customerId: 4,
    orderId: 1003,
    createdAt: '2024-04-02T23:20:00.000Z',
    orders: 3,
    totalSales: 76.71,
    netSales: 74.81,
    grossSales: 76.71,
    totalSalesCalc: 76.71,
    totalLineItemsPrice: 76.71,
    totalDiscounts: 1.89,
    billingAddressCountry: 'France (FR)',
    cancelledAt: null,
    shopName: 'Homeware Shop',
    sourceName: 'Direct Traffic'
  },
  {
    customerId: 5,
    orderId: 1004,
    createdAt: '2024-04-01T06:22:00.000Z',
    orders: 4,
    totalSales: 191.02,
    netSales: 187.65,
    grossSales: 191.02,
    totalSalesCalc: 191.02,
    totalLineItemsPrice: 191.02,
    totalDiscounts: 3.36,
    billingAddressCountry: 'United Kingdom (GB)',
    cancelledAt: '2024-04-30T02:15:00.000Z',
    shopName: 'Tech Gadgets',
    sourceName: 'In-Store Purchase'
  },
  {
    customerId: 6,
    orderId: 1005,
    createdAt: '2024-04-06T21:25:00.000Z',
    orders: 3,
    totalSales: 11.92,
    netSales: 11.46,
    grossSales: 11.92,
    totalSalesCalc: 11.92,
    totalLineItemsPrice: 11.92,
    totalDiscounts: 0.46,
    billingAddressCountry: 'United Kingdom (GB)',
    cancelledAt: null,
    shopName: 'Tech Gadgets',
    sourceName: 'Social Media'
  },
  {
    customerId: 7,
    orderId: 1006,
    createdAt: '2024-04-21T05:44:00.000Z',
    orders: 4,
    totalSales: 110.71,
    netSales: 102.58,
    grossSales: 110.71,
    totalSalesCalc: 110.71,
    totalLineItemsPrice: 110.71,
    totalDiscounts: 8.12,
    billingAddressCountry: 'Canada (CA)',
    cancelledAt: null,
    shopName: 'Tech Gadgets',
    sourceName: 'Social Media'
  },
  {
    customerId: 8,
    orderId: 1007,
    createdAt: '2024-04-26T02:06:00.000Z',
    orders: 4,
    totalSales: 50.69,
    netSales: 47.15,
    grossSales: 50.69,
    totalSalesCalc: 50.69,
    totalLineItemsPrice: 50.69,
    totalDiscounts: 3.53,
    billingAddressCountry: 'India (IN)',
    cancelledAt: null,
    shopName: 'Tech Gadgets',
    sourceName: 'Email Marketing'
  },
  {
    customerId: 9,
    orderId: 1008,
    createdAt: '2024-04-12T15:20:00.000Z',
    orders: 3,
    totalSales: 31.5,
    netSales: 30.47,
    grossSales: 31.5,
    totalSalesCalc: 31.5,
    totalLineItemsPrice: 31.5,
    totalDiscounts: 1.03,
    billingAddressCountry: 'Germany (DE)',
    cancelledAt: null,
    shopName: 'My Store',
    sourceName: 'Paid Search'
  },
  {
    customerId: 10,
    orderId: 1009,
    createdAt: '2024-04-08T08:49:00.000Z',
    orders: 2,
    totalSales: 184.98,
    netSales: 170.74,
    grossSales: 184.98,
    totalSalesCalc: 184.98,
    totalLineItemsPrice: 184.98,
    totalDiscounts: 14.24,
    billingAddressCountry: 'India (IN)',
    cancelledAt: null,
    shopName: 'My Store',
    sourceName: 'In-Store Purchase'
  },
  {
    customerId: 11,
    orderId: 1010,
    createdAt: '2024-04-07T17:42:00.000Z',
    orders: 3,
    totalSales: 185.84,
    netSales: 178.02,
    grossSales: 185.84,
    totalSalesCalc: 185.84,
    totalLineItemsPrice: 185.84,
    totalDiscounts: 7.81,
    billingAddressCountry: 'Country 10',
    cancelledAt: null,
    shopName: 'My Cool Shop',
    sourceName: 'Social Media'
  },
  {
    customerId: 12,
    orderId: 1011,
    createdAt: '2024-04-09T07:26:00.000Z',
    orders: 4,
    totalSales: 104.23,
    netSales: 96.29,
    grossSales: 104.23,
    totalSalesCalc: 104.23,
    totalLineItemsPrice: 104.23,
    totalDiscounts: 7.93,
    billingAddressCountry: 'United States (US)',
    cancelledAt: null,
    shopName: 'My Cool Shop',
    sourceName: 'In-Store Purchase'
  },
  {
    customerId: 13,
    orderId: 1012,
    createdAt: '2024-04-08T15:19:00.000Z',
    orders: 2,
    totalSales: 42.16,
    netSales: 39.52,
    grossSales: 42.16,
    totalSalesCalc: 42.16,
    totalLineItemsPrice: 42.16,
    totalDiscounts: 2.64,
    billingAddressCountry: 'Italy',
    cancelledAt: null,
    shopName: 'My Cool Shop',
    sourceName: 'Paid Search'
  },
  {
    customerId: 14,
    orderId: 1013,
    createdAt: '2024-04-26T11:27:00.000Z',
    orders: 1,
    totalSales: 76.68,
    netSales: 72.56,
    grossSales: 76.68,
    totalSalesCalc: 76.68,
    totalLineItemsPrice: 76.68,
    totalDiscounts: 4.11,
    billingAddressCountry: 'Country 10',
    cancelledAt: null,
    shopName: 'Homeware Shop',
    sourceName: 'Website'
  },
  {
    customerId: 15,
    orderId: 1014,
    createdAt: '2024-04-03T11:47:00.000Z',
    orders: 4,
    totalSales: 20.02,
    netSales: 19.79,
    grossSales: 20.02,
    totalSalesCalc: 20.02,
    totalLineItemsPrice: 20.02,
    totalDiscounts: 0.23,
    billingAddressCountry: 'Country 10',
    cancelledAt: null,
    shopName: 'Green Clothing',
    sourceName: 'Email Marketing'
  },
  {
    customerId: 16,
    orderId: 1015,
    createdAt: '2024-04-05T08:41:00.000Z',
    orders: 1,
    totalSales: 102.75,
    netSales: 102.36,
    grossSales: 102.75,
    totalSalesCalc: 102.75,
    totalLineItemsPrice: 102.75,
    totalDiscounts: 0.39,
    billingAddressCountry: 'Country 10',
    cancelledAt: null,
    shopName: 'Green Clothing',
    sourceName: 'In-Store Purchase'
  },
  {
    customerId: 17,
    orderId: 1016,
    createdAt: '2024-04-17T01:33:00.000Z',
    orders: 2,
    totalSales: 74.21,
    netSales: 73.26,
    grossSales: 74.21,
    totalSalesCalc: 74.21,
    totalLineItemsPrice: 74.21,
    totalDiscounts: 0.94,
    billingAddressCountry: 'France (FR)',
    cancelledAt: null,
    shopName: 'My Store',
    sourceName: 'Website'
  },
  {
    customerId: 18,
    orderId: 1017,
    createdAt: '2024-04-05T12:38:00.000Z',
    orders: 2,
    totalSales: 90.09,
    netSales: 90.0,
    grossSales: 90.09,
    totalSalesCalc: 90.09,
    totalLineItemsPrice: 90.09,
    totalDiscounts: 0.09,
    billingAddressCountry: 'Country 11',
    cancelledAt: '2024-04-04T09:02:00.000Z',
    shopName: 'My Cool Shop',
    sourceName: 'In-Store Purchase'
  },
  {
    customerId: 19,
    orderId: 1018,
    createdAt: '2024-04-18T06:20:00.000Z',
    orders: 4,
    totalSales: 177.92,
    netSales: 167.1,
    grossSales: 177.92,
    totalSalesCalc: 177.92,
    totalLineItemsPrice: 177.92,
    totalDiscounts: 10.81,
    billingAddressCountry: 'Country 10',
    cancelledAt: null,
    shopName: 'My Cool Shop',
    sourceName: 'Direct Traffic'
  },
  {
    customerId: 20,
    orderId: 1019,
    createdAt: '2024-04-05T10:26:00.000Z',
    orders: 3,
    totalSales: 178.94,
    netSales: 169.89,
    grossSales: 178.94,
    totalSalesCalc: 178.94,
    totalLineItemsPrice: 178.94,
    totalDiscounts: 9.04,
    billingAddressCountry: 'Italy',
    cancelledAt: null,
    shopName: 'Tech Gadgets',
    sourceName: 'Direct Traffic'
  }
];
