import { HttpClient } from '@angular/common/http';
import {
  CampaignType,
  HeroMetricType,
  ICampaignFilterCriteria,
  ICampaignsCount,
  ICancelUnpublishedCampaignChanges,
  IChannels,
  IChannelsMappedAndUnmappedCount,
  ICompositeDSReportingDimensionsConfig,
  IHeroMetricCard,
  IReviewCampaignFilterCriteria,
  ISegments,
  ITactic,
  ITactics,
  IUpdateCampaignWithFilterCriteria,
  IUpdateReviewCampaignWithFilterCriteria,
  IUpdateTacticInCampaignsResponse,
  IVCampaign,
  Utility
} from '@libs/apis';
import { lastValueFrom } from 'rxjs';
import { IChannelTacticGlobalMapping } from '../models/IChannelTacticGlobalMapping';
import { ILookups } from '../models/ILookups';
import { ITaxonomyApiOperation } from '../models/ITaxonomyApiOperation';
import { IUniqueChannelTactic } from '../models/IUniqueChannelTactic';

const promiseFrom = lastValueFrom;

export class TaxonomyApis implements ITaxonomyApiOperation {
  private constructor(
    private readonly httpClient: HttpClient,
    private readonly apiV1: string,
    private readonly apiV2: string
  ) {}

  public static taxonomyApiOperation(
    httpClient: HttpClient,
    apiV1: string,
    apiV2: string
  ): ITaxonomyApiOperation {
    return new TaxonomyApis(httpClient, apiV1, apiV2);
  }

  public async getAllResolvedTaxonomies(
    clientId: number,
    status: string | null
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/taxonomy/lookup-rules/:clientId`,
      pathVariables: { clientId },
      queryParameters: { status }
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async getRuleDetails(clientId: number, ruleId: number): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/taxonomy/lookup-rules/:clientId/:ruleId`,
      pathVariables: { clientId, ruleId }
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async getTaxonomyStatus(
    clientId: number,
    filter = null
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/taxonomy/status/:clientId`,
      pathVariables: { clientId },
      queryParameters: {
        lookuptypes: filter || null
      }
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async cloneRules(data: any, clientId: number): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/taxonomy/lookup-rules/:clientId`,
      pathVariables: { clientId }
    });
    return promiseFrom(this.httpClient.post(url, data));
  }

  public async getUnmappedRecords(
    clientId: number,
    lookUpType: string,
    dimension: string
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/taxonomy/unmapped-records/:clientId`,
      pathVariables: { clientId },
      queryParameters: { lookUpType, dimension }
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async getUpdateQuery(rule: any): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/taxonomy/build-update-query`
    });
    return promiseFrom(this.httpClient.post(url, rule));
  }

  public async createRule(data: any): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/taxonomy/lookup-rules`
    });
    return promiseFrom(this.httpClient.post(url, data));
  }

  public async updateRule(data: any): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/taxonomy/lookup-rules`
    });
    return promiseFrom(this.httpClient.put(url, data));
  }

  public async updateRuleSql(data: any): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/taxonomy/lookup-rules/query`
    });
    return promiseFrom(this.httpClient.put(url, data));
  }

  public async updateStatus(data: any): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/taxonomy/lookup-rules/status`
    });
    return promiseFrom(this.httpClient.put(url, data));
  }

  public async deleteRule(clientId: number, ruleId: number): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/taxonomy/lookup-rules/:clientId/:ruleId`,
      pathVariables: { clientId, ruleId }
    });
    return promiseFrom(this.httpClient.delete(url));
  }

  public async getPreviewRule(clientId: number, ruleId: number): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/taxonomy/preview-rule/:clientId/:ruleId`,
      pathVariables: { clientId, ruleId }
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async getPreviewRulesAsCSV(
    clientId: number,
    ruleId: number
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/taxonomy/preview-rule-csv/:clientId/:ruleId`,
      pathVariables: { clientId, ruleId }
    });
    return promiseFrom(
      this.httpClient.get(url, {
        headers: { Accept: 'text/plain; charset=utf8' }
      })
    );
  }

  public async getAllChannelTacticsGlobalMapping(
    clientId: number
  ): Promise<IChannelTacticGlobalMapping[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/channel-tactic-global-map`,
      queryParameters: { clientId }
    });
    return promiseFrom(this.httpClient.get<IChannelTacticGlobalMapping[]>(url));
  }

  public async updateChannelTacticGblMap(
    clientId: number,
    channelTactics: IChannelTacticGlobalMapping[]
  ): Promise<IChannelTacticGlobalMapping[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/channel-tactic-global-map`,
      queryParameters: { clientId }
    });
    return promiseFrom(
      this.httpClient.put<IChannelTacticGlobalMapping[]>(url, channelTactics)
    );
  }

  public async getUnmappedChannelTacticSegmentLookups(
    clientId: number,
    searchText: string,
    lookupTypes: string[],
    media: boolean
  ): Promise<ILookups[]> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/taxonomy/unmapped-channel-tactic-lookups/:clientId`,
      pathVariables: { clientId },
      queryParameters: {
        media,
        searchText: searchText || null,
        lookupTypes: lookupTypes?.length ? lookupTypes : null
      }
    });
    return promiseFrom(this.httpClient.get<ILookups[]>(url));
  }

  public async getAllChannelTacticSegments(
    clientId: number
  ): Promise<IUniqueChannelTactic> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/taxonomy/all-channel-tactic-segments/:clientId`,
      pathVariables: { clientId }
    });
    return promiseFrom(this.httpClient.get<IUniqueChannelTactic>(url));
  }

  public async getAllLookUpTypes(clientId: number): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/taxonomy/lookup-types/:clientId`,
      pathVariables: { clientId }
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async getUniqueLookupTypes(clientId: number): Promise<string[]> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/taxonomy/unique-lookup-types/:clientId`,
      pathVariables: { clientId }
    });
    return promiseFrom(this.httpClient.get<string[]>(url));
  }

  public async updateLookup(
    clientId: number,
    lookups: ILookups[]
  ): Promise<ILookups[]> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/taxonomy/update-lookup/:clientId`,
      pathVariables: { clientId }
    });
    return promiseFrom(this.httpClient.put<ILookups[]>(url, lookups));
  }

  public async getCampaignsCounts(
    clientId: number,
    brandId: number,
    includeInProgress?: boolean,
    daterange?: string,
    groupByDimension?: string,
    campaignType?: CampaignType
  ): Promise<IChannelsMappedAndUnmappedCount[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/self-serve-taxonomy/campaigns-count/:clientId/:brandId`,
      pathVariables: { clientId, brandId },
      queryParameters: {
        includeInProgress,
        daterange,
        groupByDimension,
        campaignType
      }
    });
    return promiseFrom(
      this.httpClient.get<IChannelsMappedAndUnmappedCount[]>(url)
    );
  }

  public async getCampaignsByDatasourceNChannel(
    clientId: number,
    brandId: number,
    filter: ICampaignFilterCriteria
  ): Promise<IVCampaign[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/self-serve-taxonomy/campaigns/:clientId/:brandId`,
      pathVariables: { clientId, brandId }
    });
    return promiseFrom(this.httpClient.post<IVCampaign[]>(url, filter));
  }

  public async getTacticsByDatasource(
    clientId: number,
    brandId: number,
    dataSourceId?: string
  ): Promise<ITactics[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/self-serve-taxonomy/tactics/:clientId/:brandId`,
      pathVariables: { clientId, brandId },
      queryParameters: { dataSourceId }
    });
    return promiseFrom(this.httpClient.get<ITactics[]>(url));
  }

  public async updateCampaignsMapping(
    clientId: number,
    brandId: number,
    filter: IUpdateCampaignWithFilterCriteria
  ): Promise<IUpdateTacticInCampaignsResponse> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/self-serve-taxonomy/campaigns/:clientId/:brandId`,
      pathVariables: { clientId, brandId }
    });
    return promiseFrom(
      this.httpClient.put<IUpdateTacticInCampaignsResponse>(url, filter)
    );
  }

  public async getUnmappedTactic(): Promise<ITactic> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/self-serve-taxonomy/unmapped-tactic`
    });
    return promiseFrom(this.httpClient.get<ITactic>(url));
  }

  public async getCampaignCountByDataSourceAndChannel(
    clientId: number,
    brandId: number,
    dataSourceId: string
  ): Promise<IChannelsMappedAndUnmappedCount> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/self-serve-taxonomy/datasource-channel-campaigns-count/:clientId/:brandId`,
      pathVariables: { clientId, brandId },
      queryParameters: { dataSourceId }
    });
    return promiseFrom(
      this.httpClient.get<IChannelsMappedAndUnmappedCount>(url)
    );
  }

  public getCompositeDSReportingDimensionConfigs(
    dataSourceName: string
  ): Promise<ICompositeDSReportingDimensionsConfig[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/self-serve-taxonomy/reporting-dimension-config/:dataSourceName`,
      pathVariables: { dataSourceName }
    });
    return promiseFrom(
      this.httpClient.get<ICompositeDSReportingDimensionsConfig[]>(url)
    );
  }

  public async getUnderProcessingCampaignsCount(
    clientId: number,
    brandId: number,
    dataSourceId: string
  ): Promise<ICampaignsCount> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/self-serve-taxonomy/modified-campaigns-count/:clientId/:brandId`,
      pathVariables: { clientId, brandId },
      queryParameters: { dataSourceId }
    });
    return promiseFrom(this.httpClient.get<ICampaignsCount>(url));
  }

  public async getMultipleTacticCampaignsCount(
    clientId: number,
    brandId: number,
    dataSourceId: string
  ): Promise<ICampaignsCount> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/self-serve-taxonomy/multiple-tactic-campaigns-count/:clientId/:brandId`,
      pathVariables: { clientId, brandId },
      queryParameters: { dataSourceId }
    });
    return promiseFrom(this.httpClient.get<ICampaignsCount>(url));
  }

  getSegments(
    clientId: number,
    brandId: number,
    dataSourceId?: string
  ): Promise<ISegments[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/self-serve-taxonomy/segments/:clientId/:brandId`,
      pathVariables: { clientId, brandId },
      queryParameters: { dataSourceId }
    });
    return promiseFrom(this.httpClient.get<ISegments[]>(url));
  }

  public async getReviewNPublishedCampaigns(
    clientId: number,
    brandId: number,
    filter: IReviewCampaignFilterCriteria
  ): Promise<IVCampaign[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/self-serve-taxonomy/review-campaigns/:clientId/:brandId`,
      pathVariables: { clientId, brandId }
    });
    return promiseFrom(this.httpClient.post<IVCampaign[]>(url, filter));
  }

  getChannels(
    clientId: number,
    brandId: number,
    dataSourceId?: string
  ): Promise<IChannels[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/self-serve-taxonomy/channels/:clientId/:brandId`,
      pathVariables: { clientId, brandId },
      queryParameters: { dataSourceId }
    });
    return promiseFrom(this.httpClient.get<IChannels[]>(url));
  }

  getCampaignsCountByStatus(
    clientId: number,
    brandId: number
  ): Promise<Record<string, number>> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/self-serve-taxonomy/review-publish-campaigns-count/:clientId/:brandId`,
      pathVariables: { clientId, brandId }
    });
    return promiseFrom(this.httpClient.get<Record<string, number>>(url));
  }

  publishCampaignChanges(
    clientId: number,
    brandId: number
  ): Promise<Record<string, number>> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/self-serve-taxonomy/publish-campaigns/:clientId/:brandId`,
      pathVariables: { clientId, brandId }
    });
    return promiseFrom(this.httpClient.put<Record<string, number>>(url, {}));
  }

  cancelPublishCampaignChanges(
    clientId: number,
    brandId: number,
    filter: IUpdateReviewCampaignWithFilterCriteria
  ): Promise<Record<string, number>> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/self-serve-taxonomy/cancel-publish-campaigns/:clientId/:brandId`,
      pathVariables: { clientId, brandId }
    });
    return promiseFrom(
      this.httpClient.put<Record<string, number>>(url, filter)
    );
  }

  public async updateReviewCampaignsMapping(
    clientId: number,
    brandId: number,
    filter: IUpdateReviewCampaignWithFilterCriteria
  ): Promise<IUpdateTacticInCampaignsResponse> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/self-serve-taxonomy/review-campaigns/:clientId/:brandId`,
      pathVariables: { clientId, brandId }
    });
    return promiseFrom(
      this.httpClient.put<IUpdateTacticInCampaignsResponse>(url, filter)
    );
  }

  public async getHeroMetrics(
    clientId: number,
    brandId: number,
    metrics?: HeroMetricType[]
  ): Promise<IHeroMetricCard[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/self-serve-taxonomy/hero-metrics/:clientId/:brandId`,
      pathVariables: { clientId, brandId },
      queryParameters: {
        metrics
      }
    });
    return promiseFrom(this.httpClient.get<IHeroMetricCard[]>(url));
  }

  public async getGeoTestStatusByDimension(
    clientId: number,
    brandId: number
  ): Promise<Record<string, boolean>> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/self-serve-taxonomy/geo-test-status/:clientId/:brandId`,
      pathVariables: { clientId, brandId }
    });
    return promiseFrom(this.httpClient.get<Record<string, boolean>>(url));
  }

  public async getLastUpdatedAtDate(
    clientId: number,
    brandId: number
  ): Promise<{ lastRefreshDate: string }> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/last-refresh/taxonomy`,
      queryParameters: { clientId, brandId }
    });
    return promiseFrom(this.httpClient.get<{ lastRefreshDate: string }>(url));
  }

  public async cancelUnpublishedCampaignChanges(
    clientId: number,
    brandId: number,
    cancelUnpublishedCampaignRequest: ICancelUnpublishedCampaignChanges
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/self-serve-taxonomy/cancel-review-campaigns/:clientId/:brandId`,
      pathVariables: { clientId, brandId }
    });

    return promiseFrom(
      this.httpClient.put(url, cancelUnpublishedCampaignRequest)
    );
  }

  public async revertPublishedCampaignChanges(
    clientId: number,
    brandId: number,
    revertPublishedCampaignRequest: IUpdateCampaignWithFilterCriteria
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/self-serve-taxonomy/revert-publish-campaigns/:clientId/:brandId`,
      pathVariables: { clientId, brandId }
    });

    return promiseFrom(
      this.httpClient.put(url, revertPublishedCampaignRequest)
    );
  }

  public async getCampaignCountWithFilter(
    clientId: number,
    brandId: number,
    campaignCountRequest: any
  ): Promise<IChannelsMappedAndUnmappedCount[]> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/self-serve-taxonomy/campaign-count-with-filter/:clientId/:brandId`,
      pathVariables: { clientId, brandId }
    });

    return promiseFrom(
      this.httpClient.post<IChannelsMappedAndUnmappedCount[]>(
        url,
        campaignCountRequest
      )
    );
  }
}
