<div
  [ngClass]="['m-type-pill', 'm-type-pill-' + size]"
  [ngStyle]="{
    background: backgroundColor,
    'border-left': '2px solid ' + borderColor,
  }">
  @if (iconName) {
    <span
      class="m-type-pill-icon material-symbols-outlined"
      [ngClass]="{ 'icon-tiny': size === tagSize.small }"
      [style.color]="secondaryColor">
      {{ iconName }}
    </span>
  }
  <span class="m-type-pill-text">{{ text }}</span>
</div>
