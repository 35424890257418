import { TabularListComponent } from '@portal/app/shared/components/tabular-list/tabular-list.component';
import { HeaderComponent } from '@portal/app/shared/components/header/header.component';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BackBarComponent } from '@portal/app/shared/components/back-bar/back-bar.component';
import { ListComponent } from '@portal/app/shared/components/list/list.component';
import { RadioBarComponent } from '@portal/app/shared/components/radio-bar/radio-bar.component';
import { TitleBarComponent } from '@portal/app/shared/components/title-bar/title-bar.component';
import { FooterComponent } from '@portal/app/shared/components/footer/footer.component';
import { TableauComponent } from '@portal/app/shared/components/tableau/tableau.component';
import { TermsComponent } from '@portal/app/shared/components/terms/terms.component';
import { PrimengModule } from '@portal/app/dashboard/primeng.module';
import { UserDetailsPageComponent } from '@portal/app/shared/components/user-details-page/user-details-page.component';
import { OverlayPanelComponent } from '@portal/app/shared/components/overlay-panel/overlay-panel.component';
import { ButtonGroupCustomComponent } from '@portal/app/shared/components/button-group-custom/button-group-custom.component';
import { EmptyStateComponent } from '@portal/app/shared/components/empty-state/empty-state.component';
import { FilterSetRadioPopupComponent } from '@portal/app/shared/components/filter-set-radio-popup/filter-set-radio-popup.component';
import { AccuracyAlertComponent } from '@portal/app/shared/components/accuracy-alert/accuracy-alert.component';
import { NotesComponent } from '@portal/app/shared/components/notes/notes.component';
import { MessageDialogComponent } from '@portal/app/shared/components/message-dialog/message-dialog.component';
import { FormatValuePipe } from '@portal/app/shared/pipes/format-value.pipe';
import { PreviousValueComponent } from '@portal/app/shared/components/previous-value/previous-value.component';
import { CustomCurrencyPipe } from '@portal/app/shared/pipes/custom-currency.pipe';
import { TitleComponent } from '@portal/app/shared/components/title/title.component';
import { GenerateRowClassInputPipe } from '@portal/app/shared/pipes/generate-row-class-input.pipe';
import { GenerateClassInputPipe } from '@portal/app/shared/pipes/generate-class-input.pipe';
import { FormatFilterTypePipe } from '@portal/app/shared/pipes/format-filter-type.pipe';
import { FormatLabelPipe } from '@portal/app/shared/pipes/format-label.pipe';
import { MetricsBarVisualizerComponent } from '@portal/app/shared/components/metrics-bar-visualizer/metrics-bar-visualizer.component';
import { ButtonGroupComponent } from '@portal/app/shared/components/button-group/button-group.component';
import { DataCellComponent } from '@portal/app/shared/components/data-cell/data-cell.component';
import { CompositeDataCellComponent } from '@portal/app/shared/components/composite-data-cell/composite-data-cell.component';
import { IconDataCellComponent } from '@portal/app/shared/components/icon-data-cell/icon-data-cell.component';
import { BadgeComponent } from '@portal/app/shared/components/badge/badge.component';
import { PopupComponent } from '@portal/app/shared/components/popup/popup.component';
import { RefreshTimeComponent } from '@portal/app/shared/components/refresh-time/refresh-time.component';
import { ConfirmationPopupComponent } from './components/confirmation-popup/confirmation-popup.component';
import { DividerModule } from 'primeng/divider';
import { DesignSystemModule } from '@libs/design-system';
import { AbsoluteValuePipe } from '@portal/app/shared/pipes/absolute-value.pipe';
import { PerformanceOverTimeChartComponent } from '@portal/app/shared/components/performance-over-time-chart/performance-over-time-chart.component';
import { TaxonomyBannerComponent } from '@portal/app/shared/components/taxonomy-banner/taxonomy-banner.component';
import { MultiTacticTooltipPipe } from '@portal/app/shared/pipes/multi-tactic-tooltip.pipe';

@NgModule({
  imports: [
    CommonModule,
    PrimengModule,
    FormsModule,
    DividerModule,
    DesignSystemModule
  ],
  declarations: [
    TitleBarComponent,
    TitleComponent,
    FooterComponent,
    TableauComponent,
    TermsComponent,
    ListComponent,
    HeaderComponent,
    UserDetailsPageComponent,
    OverlayPanelComponent,
    EmptyStateComponent,
    RadioBarComponent,
    FilterSetRadioPopupComponent,
    EmptyStateComponent,
    NotesComponent,
    MessageDialogComponent,
    FormatValuePipe,
    FormatFilterTypePipe,
    FormatLabelPipe,
    GenerateClassInputPipe,
    GenerateRowClassInputPipe,
    PreviousValueComponent,
    AccuracyAlertComponent,
    CustomCurrencyPipe,
    TabularListComponent,
    BackBarComponent,
    MetricsBarVisualizerComponent,
    ButtonGroupComponent,
    ButtonGroupCustomComponent,
    DataCellComponent,
    CompositeDataCellComponent,
    DataCellComponent,
    IconDataCellComponent,
    BadgeComponent,
    PopupComponent,
    RefreshTimeComponent,
    ConfirmationPopupComponent,
    AbsoluteValuePipe,
    PerformanceOverTimeChartComponent,
    TaxonomyBannerComponent,
    MultiTacticTooltipPipe
  ],
  exports: [
    TitleBarComponent,
    TitleComponent,
    FooterComponent,
    TableauComponent,
    TermsComponent,
    ListComponent,
    HeaderComponent,
    RadioBarComponent,
    UserDetailsPageComponent,
    OverlayPanelComponent,
    FilterSetRadioPopupComponent,
    EmptyStateComponent,
    NotesComponent,
    MessageDialogComponent,
    FormatValuePipe,
    FormatFilterTypePipe,
    FormatLabelPipe,
    GenerateClassInputPipe,
    GenerateRowClassInputPipe,
    PreviousValueComponent,
    AccuracyAlertComponent,
    CustomCurrencyPipe,
    TabularListComponent,
    BackBarComponent,
    MetricsBarVisualizerComponent,
    ButtonGroupComponent,
    ButtonGroupCustomComponent,
    DataCellComponent,
    CompositeDataCellComponent,
    IconDataCellComponent,
    BadgeComponent,
    PopupComponent,
    RefreshTimeComponent,
    ConfirmationPopupComponent,
    AbsoluteValuePipe,
    PerformanceOverTimeChartComponent,
    TaxonomyBannerComponent,
    MultiTacticTooltipPipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
