/* eslint-disable prefer-const */
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@portal/app/store/app.state';
import { selectStore } from '@portal/app/store/app.selectors';
import { MultiViewActions } from './actions';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Filter } from '@portal/app/shared/types';
import { cloneDeep, isArray, isEmpty, omit } from 'lodash-es';
import { ContextStore } from '@portal/app/shared/state/context.store';

const setQueryParamFromFilter = (
  queryParams: Params,
  literalId: string,
  filters: Filter[]
) => {
  const filter = filters.find((f) => f.literalId === literalId);
  if (filter) {
    queryParams[literalId] = JSON.stringify(filter.value);
  }
};

@Injectable()
export class MultiViewEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private contextStore: ContextStore,
    public route: ActivatedRoute
  ) {}

  // navigate to child
  $navigateToChild = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MultiViewActions.navigateToChild),
        withLatestFrom(this.store.select(selectStore)),
        tap(([action, state]) => {
          const { viewId, rowData } = action;
          const { filters } = state.multiView;

          const queryParams: Params = {};

          if (viewId) {
            queryParams.viewId = viewId;
          }

          const showChild = filters.find((f) => f.literalId === 'showChild');
          if (showChild) {
            queryParams.showChild = true;
            showChild.value = true;
          }

          //add relative day
          const relativeDay = filters.find(
            (f) => f.literalId === 'relative_day'
          );
          if (relativeDay) {
            queryParams.relative_day = JSON.stringify(relativeDay.value);
          }

          const filterNames = [
            'conversion_type',
            'viewBy',
            'day',
            'optimizeForFilter',
            'optimizeStrengthFilter'
          ];

          filterNames.forEach((literalId) => {
            setQueryParamFromFilter(queryParams, literalId, filters);
          });

          const planningBudget = filters.find(
            (f) => f.literalId === 'planningBudget'
          );
          if (planningBudget) {
            queryParams.planningBudget = rowData.optBudget;
            queryParams.targetBudget = planningBudget.value;
          }
          const dimensions = filters.find((f) => f.literalId === 'dimensions');
          if (dimensions && dimensions.options) {
            dimensions.options.forEach((dim: string) => {
              queryParams[dim] = JSON.stringify(rowData[dim]);
            });
          }
          const selectedTactics = state.multiView.filters.find(
            (f) => f.literalId === 'tactic'
          );
          if (
            selectedTactics &&
            isArray(selectedTactics.value) &&
            selectedTactics.value.length > 0
          ) {
            queryParams.selectedTactics = selectedTactics.value;
          }
          const url = this.router.createUrlTree([]);

          this.router
            .navigate([url.toString(), 'child'], {
              queryParams
            })
            .then(() => {
              this.store.dispatch(
                MultiViewActions.setQueryParams({ queryParams })
              );
            });
        })
      ),
    { dispatch: false }
  );

  $navigateToParent = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MultiViewActions.navigateToParent),
        withLatestFrom(this.store.select(selectStore)),
        tap(([action, state]) => {
          const { filters } = state.multiView;
          const dimensions = filters.find((f) => f.literalId === 'dimensions');
          let params = cloneDeep(this.route.snapshot.queryParams);
          // reset channel, tactic, showChild and set viewId to number instead of string
          if (
            'showChild' in params &&
            'viewId' in params &&
            'channel' in params &&
            'tactic' in params
          ) {
            params.showChild = false;
            params.viewId = Number(params.viewId);
            params.channel = '[]';
            params.tactic = '[]';
            if ('selectedTactics' in params) {
              params.tactic = JSON.stringify(params.selectedTactics);
            }
          }
          const queryParams: Params = {
            ...omit(
              !isEmpty(state.multiView.queryParams)
                ? state.multiView.queryParams
                : params,
              ['showChild'].concat(dimensions ? (dimensions.value as []) : [])
            ),
            viewId: action.viewId,
            planningBudget: params.targetBudget ? params.targetBudget : null,
            navigateToParent: true,
            optimizeForFilter: params.optimizeForFilter
              ? params.optimizeForFilter
              : null,
            optimizeStrengthFilter: params.optimizeStrengthFilter
              ? params.optimizeStrengthFilter
              : null
          };
          dimensions?.options?.forEach((dim) => {
            setQueryParamFromFilter(queryParams, dim, filters);
          });

          // reset associatedFilterHolder
          this.contextStore.associatedFilterSelection =
            state.multiView.associatedFilterHolder;

          const url = this.router.createUrlTree([]).toString();
          this.router.navigate([url.replace('/child', '')], {
            queryParams
          });
        })
      ),
    { dispatch: false }
  );
}
