<div class="measured-feedback-btn-container" style="transform: rotate(-90deg)">
  <m-button
    type="primary"
    (click)="toggleFeedbackModal()"
    styleClass="pb-3 rounded-t">
    <i class="material-symbols-outlined icon-small mr-2">maps_ugc</i>
    <span class="sh2">Feedback</span>
  </m-button>
</div>

<m-modal
  class="aida-modal"
  [draggable]="false"
  [modal]="true"
  [dismissableMask]="true"
  [(visible)]="isFeedbackModalVisible"
  (onShow)="onShowFeedbackModal()"
  (onHide)="toggleFeedbackModal()"
  [showHeader]="true"
  [transitionOptions]="'0ms'"
  [style]="{ border: '0', width: '675px', height: '750px', padding: '0 1rem' }">
  <ng-container modalHeader>
    <span>&nbsp;</span>
  </ng-container>
  <div>
    <div
      data-aidaform-widget="form-2019-12"
      data-url="https://measured.aidaform.com/feedback"
      data-width="100%"
      data-height="500px"
      data-do-resize></div>
  </div>
</m-modal>
