import { animate, state, style, transition, trigger } from "@angular/animations";

export enum FlipState {
  front = 'Front',
  back = 'Back'
}

enum FlipDirection {
  x = 'rotateX(180deg)',
  y = 'rotateY(180deg)'
}

export class CardFlipAnimator {
  private static triggerName = 'cardFlip';

  public static flipX = () => CardFlipAnimator.flip(FlipDirection.x)
  public static flipY = () => CardFlipAnimator.flip(FlipDirection.y)
  private static flip = (flipDirection: FlipDirection) => trigger(CardFlipAnimator.triggerName, [
    state(
      FlipState.front,
      style({
        transform: 'rotate(0)'
      })
    ),
    state(
      FlipState.back,
      style({
        transform: flipDirection
      })
    ),
    transition(`${FlipState.front} => ${FlipState.back}`, animate('500ms ease-in-out')),
    transition(`${FlipState.back} => ${FlipState.front}`, animate('500ms ease-in-out'))
  ]);
}
