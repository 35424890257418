@if (showMessage) {
  <lib-ui-loader-inline
    [config]="config"
    [loaderTitle]="config.title"
    [loaderMessage]="config.message"
    [view]="config.view"
    (handleRetry)="exportData()"
    (handleClose)="showMessage = false"
    (handleContinue)="showMessage = false"></lib-ui-loader-inline>
}
<div #exportContainer class="d-flex col-12 flex-column">
  @if (loading) {
    <lib-ui-loader-inline
      [loaderMessage]="loadingMessage"
      [loaderContainer]="exportContainer"></lib-ui-loader-inline>
  }
  <div class="mx-5 my-3">
    <portal-header
      [headerConfig]="exportDataConfigs.headerConfig"
      [headerData]="{
        dataExportLabel: 'Data Export',
      }"></portal-header>
  </div>
  <div class="page-content saas-ui saas-ui-size-default">
    <div class="col-12 d-flex justify-content-center ui-background">
      <div class="col-3 ui-card pe-4 export-data-wrapper">
        <div class="col-12 d-flex align-items-center justify-content-between">
          <lib-ui-title
            [titleConfigs]="exportDataConfigs.titleConfig"></lib-ui-title>
        </div>
        <form
          id="exportDataConfigForm"
          [formGroup]="dataExportForm"
          (ngSubmit)="exportData()">
          <div class="row gx-0 mb-3">
            <div class="col-md-12 gx-0">
              <label class="d-block mb-2">
                Report
                <p-dropdown
                  id="reportName"
                  formControlName="reportName"
                  [options]="reportNames"
                  (onChange)="onChangeOfReport($event)"
                  class="d-block">
                </p-dropdown>
              </label>
            </div>
          </div>
          <div class="row gx-0 mb-3">
            <div class="col-md-12 gx-0">
              <label class="d-block mb-2">
                Dimensions
                <p-multiSelect
                  id="dimensions"
                  formControlName="dimensions"
                  [options]="dimensionOptions"
                  class="d-block">
                </p-multiSelect>
              </label>
            </div>
          </div>
          <div class="row gx-0 mb-3">
            <div class="col-md-12 gx-0">
              <label class="d-block mb-2">
                Time Period
                <portal-date-range-picker
                  #datePicker
                  label=""
                  [containerClass]="'row'"
                  [datePickerInputStyleClass]="'form-control p-inputtext'"
                  [datePickerInputWidth]="'100%'"
                  [date]="selectedTimePeriod"
                  [hideDatePickerAfterApply]="true"
                  [allowedDuration]="allowedDuration"
                  [currentRangeOptions]="timePeriodOptions"
                  [dateFormatType]="'MM-DD-YYYY'"
                  (dateChanged)="
                    onDateChange($event)
                  "></portal-date-range-picker>
              </label>
            </div>
          </div>
          <div class="row gx-0 mb-3">
            <div class="col-md-12 gx-0">
              <label class="d-block mb-2">
                Time Series Resolution
                <p-dropdown
                  id="timeSeriesResolution"
                  formControlName="timeSeriesResolution"
                  [options]="exportDataConfigs.timeSeriesResolution"
                  class="d-block">
                </p-dropdown>
              </label>
            </div>
          </div>
          <div class="row gx-0 mb-3">
            <div class="col-md-12 gx-0">
              <label class="d-block mb-2">
                Conversion Type
                <p-multiSelect
                  id="conversionTypes"
                  formControlName="conversionTypes"
                  [options]="conversionTypeOptions"
                  class="d-block">
                </p-multiSelect>
              </label>
            </div>
          </div>
          <div class="row gx-0 mb-3">
            <div class="col-md-12 gx-0">
              <label class="d-block mb-2">
                Export Format
                <p-dropdown
                  id="exportFormat"
                  formControlName="exportFormat"
                  [options]="exportDataConfigs.exportReportFormats"
                  class="d-block">
                </p-dropdown>
              </label>
            </div>
          </div>
          <div class="row gx-0 mb-3">
            <div class="col-md-12 gx-0 user-info">
              <span>{{ exportDataConfigs.infoMessage }}</span>
              <button class="p-badge p-badge-info user-badge">
                {{ userEmail }}
              </button>
            </div>
            <div class="col-md-12 gx-0"></div>
          </div>
          <div class="row gx-0 mb-1">
            <div class="col-md-12 gx-0">
              <div class="ui-g-6 text-end pe-0">
                <button
                  type="button"
                  class="p-button-outlined mx-2"
                  pButton
                  label="Reset"
                  (click)="resetExportDataForm()"></button>
                <button
                  id="exportRequestSubmitButton"
                  type="submit"
                  class="p-button-primary"
                  pButton
                  label="Export"
                  [disabled]="dataExportForm.invalid"></button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
