import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { DataResponse, Filter } from '@portal/app/shared/types';
import {
  BadgeType,
  IHeaderConfig,
  ITitleConfig
} from '@portal/app/shared/models/IHeaderConfig';
import { Observable, Subscription } from 'rxjs';
import { ContextStore } from '@portal/app/shared/state/context.store';
import { NativeSectionsService } from '@portal/app/shared/services/native-sections.service';
import { DataTrackerService } from '@libs/common-services';

@Component({
  selector: 'portal-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnChanges, OnDestroy {
  @Input() headerConfig!: IHeaderConfig;
  @Input() headerData!: DataResponse;
  @Input() isEditMode?: boolean;
  @Input() dashboardLiteralId?: string;
  @Input() showEmptyData = true;
  @Output() editableHeaderValue = new EventEmitter<string>();
  @Output() enterKeyClicked = new EventEmitter<boolean>();

  private editableHeaderValueInitialized = false;
  private filterContext$: Observable<Filter[]> =
    this.contextStore.filterContext;

  private subs: Subscription = new Subscription();

  constructor(
    protected readonly contextStore: ContextStore,
    public readonly nativeSectionsService: NativeSectionsService,
    public readonly dataTrackerService: DataTrackerService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.dashboardLiteralId === 'benchmarks-drill-down' &&
      changes &&
      changes.headerData &&
      !changes.headerData.isFirstChange()
    ) {
      this.setOverrideHeader();
    }
    if (changes.headerData || changes.isEditMode || changes.headerConfig) {
      if (this.isEditMode && this.headerConfig?.editableTitle?.field) {
        if (
          this.headerData?.[this.headerConfig.editableTitle.field] &&
          !this.editableHeaderValueInitialized
        ) {
          this.editableHeaderValue.emit(
            this.headerData?.[this.headerConfig.editableTitle.field] as string
          );
          this.editableHeaderValueInitialized = true;
        }
      }
    }
  }

  onEditableHeaderKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.enterKeyClicked.emit(true);
    } else {
      this.editableHeaderValue.emit((event.target as HTMLInputElement).value);
    }
  }

  onClickFAQ(link: string): void {
    window.open(link, '_blank');
  }

  setOverrideHeader() {
    const filterContextSub = this.filterContext$.subscribe((filters) => {
      let primaryTitle = '';
      let iconBadge = '';
      primaryTitle = filters.find((filter) => filter.literalId === 'tactic')
        ?.value as string;
      iconBadge = 'Tactic';
      if (!primaryTitle) {
        primaryTitle = filters.find((filter) => filter.literalId === 'channel')
          ?.value as string;
        iconBadge = 'Channel';
      }
      this.headerData[this.headerConfig.primaryTitle?.field as string] =
        primaryTitle;
      const iconBadgeIndex = this.headerConfig.badges.findIndex(
        (e) => e.type === BadgeType.iconBadge
      );
      this.headerData[
        this.headerConfig.badges[iconBadgeIndex]?.field as string
      ] = iconBadge;
    });
    this.subs.add(filterContextSub);
  }

  handleIfThereAreNoData(value: ITitleConfig, index: string): string {
    if (index === 'styleClass') {
      return value && value.styleClass ? value.styleClass : '';
    }
    return value && value.field ? value.field : '';
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
