/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  forwardRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface MButtonGroupOption {
  label: string;
  value?: string;
  disabled?: string;
  perOptionCount?: number;
}

@Component({
  selector: 'm-button-group',
  templateUrl: './m-button-group.component.html',
  styleUrls: ['./m-button-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MButtonGroupComponent),
      multi: true
    }
  ]
})
export class MButtonGroupComponent
  implements OnInit, OnChanges, ControlValueAccessor
{
  @Input() options!: MButtonGroupOption[];
  @Input() selectedOption = '';
  @Input() showOptionCount = true;
  @Output() selectedOptionChange = new EventEmitter<string>();

  decoratedOptions: MButtonGroupOption[] = [];

  // Callbacks for ControlValueAccessor.
  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  ngOnInit(): void {
    this.buildDecoratedOptions();
    // If no selected option is provided and there is at least one option, default to the first one.
    if (
      !this.selectedOption &&
      this.decoratedOptions &&
      this.decoratedOptions.length > 0
    ) {
      this.selectedOption = this?.decoratedOptions[0]?.value as string;
      this.propagateChange();
    }
  }

  ngOnChanges(): void {
    this.buildDecoratedOptions();
  }

  buildDecoratedOptions(): void {
    this.decoratedOptions = this.options.map((option) => ({
      ...option,
      label: this.evaluateLabel(option)
    }));
  }

  evaluateLabel(option: MButtonGroupOption): string {
    return this.showOptionCount &&
      (option?.perOptionCount || option?.perOptionCount === 0)
      ? `${option.label} (${option.perOptionCount})`
      : option.label;
  }

  onButtonClickHandler(index: number): void {
    this.selectedOption = this.decoratedOptions[index]?.value as string;
    this.propagateChange();
    this.selectedOptionChange.emit(this.selectedOption);
  }

  propagateChange(): void {
    this.onChange(this.selectedOption);
    this.onTouched();
  }

  // ControlValueAccessor methods.
  writeValue(value: any): void {
    if (value !== undefined) {
      this.selectedOption = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
