<div [class]="styleClass" id="expandableTable">
  <p-table
    [value]="groups"
    [columns]="fields"
    sortMode="single"
    rowGroupMode="subheader"
    [groupRowsBy]="groupByField"
    [sortField]="groupByField"
    [dataKey]="groupByField"
    [expandedRowKeys]="expandedRows">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th scope="col"></th>
        @for (col of columns; track col) {
          <th id="header">
            {{ col.label }}
          </th>
        }
      </tr>
    </ng-template>
    <ng-template
      pTemplate="groupheader"
      let-groupData
      let-expanded="expanded"
      let-columns="columns">
      @for (data of groupData.expandableData; track data; let index = $index) {
        <tr class="{{ expanded ? '' : 'collapsed' }}">
          @if (index === 0) {
            <td
              [attr.rowspan]="groupData.expandableData.length"
              [style.width]="'3%'">
              <button
                type="button"
                pButton
                pRipple
                [pRowToggler]="groupData"
                class="p-button-text p-button-rounded p-button-plain mr-2"
                [icon]="
                  expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
                "></button>
            </td>
          }
          @for (col of columns; track col) {
            <td
              [ngClass]="
                highlightColumns.includes(col.field) && index === 0
                  ? 'highlight-cell'
                  : ''
              ">
              {{ data[col.field] }}
            </td>
          }
        </tr>
      }
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-groupData>
      <tr>
        <td [style.width]="'3%'"></td>
        <td [colSpan]="fields.length" [style.padding]="'0px'">
          <portal-data-grid-pivot-v2
            [staticPivotConfig]="groupData.expansionData"
            [styleClass]="
              'EXPERIMENT_RESULT_EXPANSION_SECTION'
            "></portal-data-grid-pivot-v2>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
