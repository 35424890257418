@if (isLoading()) {
  <div class="h-full">
    <m-skeleton width="100%" height="290px"></m-skeleton>
  </div>
} @else if (!isDataAvailable()) {
  <!-- Empty state -->
  <portal-context-modal-card
    class="h-full"
    [card]="emptyStateCard()"
    (buttonAction)="onButtonAction()">
    <div>
      <m-card [variant]="cardVariantType.small">
        <div class="grid grid-cols-12 gap-4 items-center">
          <div
            class="b1 text-gray-1000 col-span-7"
            [innerHTML]="emptyStateCard().content"></div>
          <div class="col-span-5">
            <img
              width="140px"
              class="block ml-auto"
              src="assets/images/revenue.svg"
              alt="Optimization" />
          </div>
        </div>
      </m-card>
    </div>
  </portal-context-modal-card>
} @else if (isDataAvailable()) {
  <portal-context-modal-card
    class="h-full"
    [card]="card()"
    (buttonAction)="onButtonAction()">
    <div
      class="p-4 rounded-2"
      [ngClass]="
        response().isTacticOptimizedCapped || isSame()
          ? 'bg-gray-100'
          : response().isBetter
            ? 'bg-green-100'
            : 'bg-yellow-200'
      ">
      <div class="flex gap-3 items-center">
        <i
          aria-hidden="true"
          class="material-symbols-outlined icon-small"
          [ngClass]="
            response().isTacticOptimizedCapped || isSame()
              ? 'text-gray-1000'
              : response().isBetter
                ? 'text-green-700'
                : 'text-orange-300'
          ">
          discover_tune
        </i>
        <div class="b2 text-gray-1000">
          {{ card().content }}
        </div>
      </div>
    </div>
  </portal-context-modal-card>
}
