<div class="dimension-navigation-tabs">
  <p-tabView
    (onChange)="tabChange($event)"
    headerAlignment="center"
    [(activeIndex)]="activeIndex">
    @for (tab of configs; track tab; let index = $index) {
      <p-tabPanel>
        <ng-template pTemplate="header">
          <div>
            {{ tab.label }}
            @if (tab.showCount) {
              <span class="dimension-navigation-badge px-2">
                @if (loading) {
                  @if (loading) {
                    <p-progressSpinner
                      [style]="{ width: '30px', height: '30px', top: '8px' }"
                      >Loading!</p-progressSpinner
                    >
                  }
                } @else {
                  <p-badge
                    [value]="tab.count"
                    class="p-badge p-badge-draft"></p-badge>
                }
              </span>
            }
          </div>
        </ng-template>
      </p-tabPanel>
    }
  </p-tabView>
</div>
