@if (campaignViewFilterForm) {
  <form class="filter-context-wrapper" [formGroup]="campaignViewFilterForm">
    @if (filterConfig.isColumnSelectorEnabled) {
      <div class="block">
        <m-tooltip
          position="top"
          content="Show/hide columns"
          [styleClass]="'flex'">
          <span
            #columnSelectorButton
            class="material-symbols-outlined cursor-pointer text-gray-800 icon-regular lh-1"
            (click)="toggleColumnSelector()"
            >view_week</span
          >
        </m-tooltip>
        <p-listbox
          #columnSelector
          class="saas-ui saas-ui-size-default"
          [options]="campaignViewColumns"
          [multiple]="true"
          [checkbox]="true"
          [showToggleAll]="true"
          formControlName="tableColumns"
          optionLabel="header"
          optionValue="field"
          (onChange)="refreshCampaignRecords()">
          <ng-template pTemplate="filter">
            <span class="text-gray-1000 b1">All</span>
          </ng-template>
        </p-listbox>
      </div>
    }
    <div class="h-8">
      <div class="m-divider vertical"></div>
    </div>
    @if (filterConfig.isDimensionSelectorEnabled) {
      <div class="flex gap-2 items-center">
        <span class="text-gray-800 b2">Group by</span>
        <m-dropdown
          [style]="{ width: '10rem' }"
          [options]="groupByDimensions"
          optionLabel="label"
          optionValue="value"
          placeholder="Select dimension"
          formControlName="groupByDimension"
          (formControlChange)="
            refreshCampaignRecords($event?.value, 'groupByDimension')
          "></m-dropdown>
      </div>
    }
    @if (filterConfig.isDateRangeEnabled) {
      <m-dropdown
        [style]="{ width: '12rem' }"
        [options]="dateRangeOptions"
        optionLabel="label"
        optionValue="value"
        placeholder="Select date range"
        formControlName="dateRange"
        (formControlChange)="
          refreshCampaignRecords($event?.value, 'dateRange')
        "></m-dropdown>
    }
    <span
      class="saas-input-icon-right saas-ui saas-ui-size-default"
      style="width: 11rem">
      <span
        class="material-symbols-outlined saas-icon text-blue-600 cursor-pointer"
        (click)="refreshCampaignRecords()"
        >search</span
      >
      <input
        id="dimension-search"
        type="text"
        pInputText
        [placeholder]="accordionFilterPlaceholder"
        formControlName="dimensionFilter"
        (keyup)="refreshCampaignRecords()"
        autocomplete="off" />
    </span>
  </form>
}
