import { Component, inject } from '@angular/core';
import { CardVariant } from '@design-system/components/m-card';
import { BenchmarkService } from '@portal/app/dashboard/context-modal/services/benchmark.service';
import { NavigationService } from '@portal/app/shared/services/navigation.service';

@Component({
  selector: 'portal-benchmark-cards',
  templateUrl: './benchmark-cards.component.html',
  styleUrl: './benchmark-cards.component.scss',
  providers: [BenchmarkService]
})
export class BenchmarkCardsComponent {
  benchmarkService: BenchmarkService = inject(BenchmarkService);
  navigationService = inject(NavigationService);

  cardVariantType = CardVariant;

  isLoading = this.benchmarkService.isLoading;
  isDataAvailable = this.benchmarkService.isDataAvailable;
  response = this.benchmarkService.response;
  card = this.benchmarkService.card;

  emptyStateCard = this.benchmarkService.emptyStateCard;

  onButtonAction() {
    this.navigationService.navigationMapping(
      'benchmarks',
      {
        filters: [],
        extraParams: {
          queryParams: {}
        }
      },
      true
    );
  }
}
