export enum DataEndpoint {
  dataPoints = 'data-points',
  dataSets = 'data-sets',
  dataCharts = 'charts',
  dataExperimentResult = 'experiment-result',
  dataSetExport = 'export-data-set',
  chartExport = 'export-chart'
}

export enum NativeDashboardViewType {
  default = 'default',
  allPlans = 'All Plans',
  myPlans = 'My Plans',
  publishedPlans = 'Published Plans'
}
