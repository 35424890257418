import { Injectable } from '@angular/core';
import { ApiService } from '@portal/app/shared/services/api.service';
import { SelectionService } from '@portal/app/shared/services/selection.service';
import { ConfigDashboardInstance } from '@portal/app/shared/types';
import { Observable, firstValueFrom, map, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigDashboardService {
  constructor(
    private readonly apiService: ApiService,
    private readonly selectionService: SelectionService
  ) {}

  public async getConfigDashboardsByLiteralId(): Promise<
    Record<string, ConfigDashboardInstance>
  > {
    const mappedConfigDashboards: Record<string, ConfigDashboardInstance> = {};

    const configDashboards = await firstValueFrom(
      this.getConfigDashboards().pipe(take(1))
    );

    configDashboards.forEach(
      (dashboardInstance) =>
        (mappedConfigDashboards[dashboardInstance.literalId] =
          dashboardInstance)
    );

    return mappedConfigDashboards;
  }

  public getConfigDashboards(): Observable<ConfigDashboardInstance[]> {
    const selection = this.selectionService.getSelection();
    const brandId = selection.brand?.brandId;
    const clientId = selection.brand?.clientId;

    return this.apiService.getConfigDashboards({ brandId, clientId }).pipe(
      catchError((error) => {
        return throwError(error);
      }),
      map((dashboards: ConfigDashboardInstance[]) => {
        return dashboards.map((dashboard) => {
          dashboard.literalId = dashboard.configDashboard.literalId;
          return dashboard;
        });
      })
    );
  }
}
