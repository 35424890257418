<ng-container>
  <div *ngIf="contextItem && contextItem.elements">
    <div *ngFor="let contextElement of contextItem.elements">
      <portal-native-sections-template
        [item]="
          nativeSectionService.convertToWidgetLayout(contextElement, filters)
        "
        [fieldDefinitions]="fieldDefinitions"
        [filters]="filters">
      </portal-native-sections-template>
    </div>
  </div>
</ng-container>
