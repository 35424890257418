import { DateTimeService } from '@portal/app/shared/services/date-time.service';

export const dayAfterTomorrow = DateTimeService.dayAfterTomorrow(false);
export const yesterday = DateTimeService.yesterdayDate();

export const isAfterToday = DateTimeService.isAfterToday;
export const isBeforeToday = DateTimeService.isBeforeToday;

// eslint-disable-next-line @typescript-eslint/naming-convention
export const Duration = DateTimeService.dayDuration;

export const dateSince = DateTimeService.dateSinceWithOutTimezone;

export const getDateWithFormatWithoutTimezoneConsideration =
  DateTimeService.dateStringWithoutTimeZone;

export const getDateWithoutTimezoneConsideration =
  DateTimeService.dateWithoutTimeZone;
