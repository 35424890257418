<div
  *ngIf="headerConfig"
  class="saas-ui d-flex align-items-center py-3 bg-white justify-content-between {{
    headerConfig.containerStyleClass
  }}">
  <span
    class="d-flex align-items-center gap-1"
    [ngClass]="
      headerConfig.primaryTitle?.span
        ? 'col-md-' + headerConfig.primaryTitle?.span
        : 'col-md-12'
    "
    [ngClass]="headerConfig.faq ? 'col-md-8' : 'col-md-12'">
    <div
      *ngIf="
        headerConfig.primaryTitle?.field ||
        headerConfig.primaryTitle?.defaultValue
      "
      class="DASHBOARD_TITLE_HEADING"
      id="primaryTitle"
      [ngClass]="headerConfig.primaryTitle.styleClass">
      {{
        headerData[headerConfig.primaryTitle.field] ||
          headerConfig.primaryTitle.defaultValue ||
          "No Data"
      }}
      {{
        headerConfig.secondaryTitles && headerConfig.secondaryTitles.length
          ? "|"
          : ""
      }}
    </div>
    <h3
      class="pe-2"
      id="secondaryTitle"
      *ngFor="let secondaryTitle of headerConfig.secondaryTitles"
      [ngClass]="secondaryTitle.styleClass">
      {{ headerData[secondaryTitle.field] || "No Data" }}
    </h3>
    <ng-container *ngIf="headerConfig.editableTitle?.field">
      <h3
        *ngIf="!isEditMode"
        [ngClass]="{
          DASHBOARD_TITLE_HEADING: i === 0,
          'd-block pe-2 mb-0': true,
        }">
        {{ headerData[headerConfig.editableTitle.field] ?? "No Data" }}
      </h3>
      <input
        *ngIf="isEditMode"
        class="editable-input pe-2"
        (keyup)="onEditableHeaderKeyUp($event)"
        type="text"
        value="{{ headerData[headerConfig.editableTitle.field] ?? 'No Data' }}"
        autofocus />
    </ng-container>
    <ng-container *ngFor="let badge of headerConfig.badges">
      <p-badge
        *ngIf="showEmptyData || (!showEmptyData && headerData[badge.field])"
        [severity]="badge.severity || 'success'"
        [size]="badge.severity || 'large'"
        [class]="badge.styleClass || 'BADGE'"
        value="{{ headerData[badge.field] || 'No Data' }}">
      </p-badge>
    </ng-container>
    <ng-container *ngIf="headerConfig.tags?.length !== 0">
      <ng-container *ngFor="let tag of headerConfig.tags">
        <m-type-pill
          *ngIf="showEmptyData || (!showEmptyData && headerData[tag.field])"
          [size]="tag.size"
          [text]="tag.text || ''">
        </m-type-pill>
      </ng-container>
    </ng-container>
  </span>
  <div class="d-flex align-items-center gap-3">
    <ng-content select="[customContent]"></ng-content>
    <div *ngIf="infoConfig" class="d-flex saas-ui-size-default gap-3">
      <span *ngFor="let config of infoConfig" [ngClass]="config.styleClass">
        {{ config.label }}
      </span>
    </div>
    <div *ngIf="actionButtonConfig" class="d-flex saas-ui-size-default gap-3">
      <button
        *ngFor="let actionButton of actionButtonConfig"
        pButton
        [ngClass]="actionButton.styleClass"
        label="{{ actionButton.label }}"
        [disabled]="actionButton.disabled"
        (click)="onClickAction(actionButton.value)"></button>
    </div>
    <div *ngIf="headerConfig.faq">
      <m-tooltip
        content="<div class='b1 text-gray-000 text-center'>{{
          headerConfig.faq.label
        }}</div>"
        (click)="onClickFAQ(headerConfig.faq.field)"
        position="bottom"
        [tooltipOptions]="{
          positionTop: 10,
          showDelay: 800,
        }">
        <m-button
          [type]="'secondary'"
          class="saas-ui-size-default"
          [attr.data-tracker]="
            this.dataTrackerService.generateKeySuffix(
              'm_button__learn_more--',
              headerData[headerConfig.primaryTitle.field] ||
                headerConfig.primaryTitle.defaultValue ||
                ''
            )
          ">
          <span class="b2 text-blue-600 lh-1 text-nowrap">Learn more</span>
        </m-button>
      </m-tooltip>
    </div>
  </div>
</div>
