<p-table
  class="m-table"
  [value]="value"
  [scrollable]="scrollable"
  [scrollHeight]="scrollHeight"
  [lazy]="lazy"
  (onSort)="onSort.emit($event)"
  [customSort]="customSort"
  [sortMode]="sortMode"
  [sortField]="sortField"
  [sortOrder]="sortOrder"
  [responsive]="true">
  <ng-template pTemplate="header">
    <tr class="header-group">
      @for (group of groupedColumns; track group) {
        <th
          [attr.colspan]="group?.colspan"
          [ngClass]="group?.class || ''"
          scope="col">
          <div [innerHTML]="group?.header"></div>
        </th>
      }
    </tr>
    <tr>
      @for (col of columns; track col) {
        <th
          [ngClass]="col?.class"
          scope="col"
          [pSortableColumn]="col.sortable ? col.sortField : null">
          <div class="flex">
            <div [innerHTML]="col?.header"></div>
            @if (col.sortable) {
              <p-sortIcon [field]="col.sortField"></p-sortIcon>
            }
          </div>
        </th>
      }
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData>
    @if (customBody) {
      <ng-container
        [ngTemplateOutlet]="customBody"
        [ngTemplateOutletContext]="{ $implicit: rowData }"></ng-container>
    } @else {
      <tr>
        @for (col of columns; track col) {
          <td [ngClass]="col?.class">
            <div [innerHTML]="rowData[col?.field]"></div>
          </td>
        }
      </tr>
    }
  </ng-template>
  <ng-template pTemplate="footer">
    <ng-content></ng-content>
    <tr>
      @for (cell of footerData; track cell) {
        <td [attr.colspan]="cell?.colspan" [ngClass]="cell?.class || ''">
          <div [innerHTML]="cell?.header"></div>
        </td>
      }
    </tr>
  </ng-template>
</p-table>
