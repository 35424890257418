import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { BrandRow, Client } from '@portal/app/shared/types';
import { AuthService } from '@portal/app/shared/services/auth.service';
import { UserModel } from '@portal/app/shared/models/userModel';
import { SelectionService } from '@portal/app/shared/services/selection.service';
import { SortEvent } from 'primeng/api';
import { BrandRowsService } from '@portal/app/shared/services/brand-rows.service';
import { BehaviorSubject, Observable, Subscription, take } from 'rxjs';
import { ViewService } from '@portal/app/shared/services/view.service';
import { ApiService } from '@portal/app/shared/services/api.service';

@Component({
  selector: 'portal-brands',
  templateUrl: './brands.component.html'
})
export class BrandsComponent implements OnInit, OnDestroy {
  @Output() rowClicked: EventEmitter<void> = new EventEmitter<void>();

  public brands: Client[] = [];
  private rowsHolder: BehaviorSubject<BrandRow[]> = new BehaviorSubject<
    BrandRow[]
  >([]);

  public user: UserModel | null = this.authService.getUser();
  public loading = false;

  private subs: Subscription = new Subscription();

  constructor(
    public readonly viewService: ViewService,
    private readonly authService: AuthService,
    public readonly selectionService: SelectionService,
    private brandRowsService: BrandRowsService,
    private apiService: ApiService
  ) {}

  get rows(): Observable<BrandRow[]> {
    return this.rowsHolder.asObservable();
  }

  changeRows(rows: BrandRow[]): void {
    this.rowsHolder.next(rows);
  }

  ngOnInit(): void {
    this.subs.add(
      this.brandRowsService.shouldFetchBrandRows.subscribe({
        next: (value: boolean) => {
          if (value) {
            this.loading = true;
            const brandRows = this.brandRowsService.brandRows;
            if (brandRows.length > 0) {
              this.changeRows(brandRows);
              this.loading = false;
            } else {
              return this.brandRowsService
                .buildBrandRows()
                .pipe(take(1))
                .subscribe({
                  next: (builtRows: BrandRow[]) => {
                    this.changeRows(builtRows);
                    this.brandRowsService.brandRows = builtRows;
                    this.loading = false;
                  },
                  error: (e) => {
                    this.loading = false;
                    console.error(e);
                  }
                });
            }
          }
        },
        error: (err: Error) => {
          this.loading = false;
          console.error(err);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  customSort(event: SortEvent): number {
    if (
      event != null &&
      event.data != null &&
      event.field != null &&
      event.order != null
    ) {
      let field = 'clientId';
      if (event.field === 'Brand') {
        field = 'name';
      }
      event.data.sort((data1, data2) => {
        const value1 = data1[field || ''];
        const value2 = data2[field || ''];
        let result;
        if (value1 == null && value2 != null) {
          result = -1;
        } else if (value1 != null && value2 == null) {
          result = 1;
        } else if (value1 == null && value2 == null) {
          result = 0;
        } else if (typeof value1 === 'string' && typeof value2 === 'string') {
          result = value1.localeCompare(value2);
        } else {
          result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
        }

        return (event.order || 0) * result;
      });
    }
    return 0;
  }

  switchBrand(rowData: BrandRow, user: UserModel | null) {
    this.apiService.cancelPendingRequest.next(true);
    this.rowClicked.emit();
    this.viewService.switchBrand(rowData, user as UserModel);
    sessionStorage.removeItem('ddi-settings');
    sessionStorage.removeItem('ddi-conversion-types');
  }
}
