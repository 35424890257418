import { Component, Input, OnInit } from '@angular/core';
import {
  DataResponse,
  FieldDefinition,
  FieldDefinitions,
  MetricCardData,
  MetricCardElement,
  MetricCardHeader,
  MetricCardVisualizer
} from '@portal/app/shared/types';
import { ViewService } from '@portal/app/shared/services/view.service';
import { FormatterService } from '@portal/app/shared/services/formatter.service';
import { isMeasuredBenchmarkLandingPage } from '@portal/app/dashboard/utils';

enum LayoutTypes {
  visualizer = 'BAR_VISUALIZER',
  composite = 'COMPOSITE'
}

@Component({
  selector: 'portal-metric-card-item',
  templateUrl: './metric-card-item.component.html',
  styleUrls: ['./metric-card-item.component.scss']
})
export class MetricCardItemComponent implements OnInit {
  @Input() metricData!: MetricCardElement;
  @Input() fieldDefinitions: FieldDefinitions = {};
  @Input() dataPoints: DataResponse = {};
  @Input() isMetricActive = false;
  @Input() dashboardLiteralId = '';

  headerData: MetricCardHeader[] = [];
  metricsData: MetricCardData[] = [];
  footerData: MetricCardVisualizer[] = [];

  layoutTypes = LayoutTypes;
  displayValueCache: Record<string, string> = {};
  convertedDataPoints: Record<string, string> = {};

  constructor(
    private readonly viewService: ViewService,
    private readonly formatterService: FormatterService
  ) {}

  ngOnInit(): void {
    this.generateHeaderData();
    this.generateMetricData();
    if (this.isMetricActive) {
      this.generateFooterData();
    }
  }

  generateHeaderData(): void {
    this.headerData = [this.metricData.HEADER.primary[0] as MetricCardHeader];

    if (this.metricData.HEADER?.secondary) {
      this.headerData.push(
        this.metricData.HEADER.secondary[0] as MetricCardHeader
      );
    }
  }

  generateMetricData(): void {
    this.metricsData = [
      this.isMetricActive
        ? (this.metricData.METRIC.primary[0] as MetricCardData)
        : (this.metricData.METRIC.alternate[0] as MetricCardData),
      this.metricData.METRIC.secondary[0] as MetricCardData
    ];
    this.metricsData.forEach(
      (e: MetricCardData) =>
        (this.displayValueCache[e.dashboardFieldId] = this.getDisplayValue(
          this.dataPoints[e.dashboardFieldId] as number,
          this.fieldDefinitions[e.dashboardFieldId] as FieldDefinition
        ))
    );

    Object.entries(this.dataPoints).forEach(([key, value]) => {
      if (this.fieldDefinitions[key]) {
        this.convertedDataPoints[key] =
          this.formatterService.convertPartialItem(
            this.fieldDefinitions[key] as FieldDefinition,
            value as number
          ) as string;
      }
    });
  }

  generateFooterData(): void {
    this.footerData = [
      this.metricData.FOOTER.visualization[0] as MetricCardVisualizer
    ];
    this.footerData = this.footerData.map((v) => {
      const totalItems = Number(
        this.dataPoints[v?.additionalAttributes?.vizFields.totalItemsKey]
      );

      const otherItems = Number(
        this.dataPoints[v?.additionalAttributes?.vizFields.otherItemsKey]
      );

      const percentValue =
        this.dataPoints[v.dashboardFieldId] !== null
          ? (this.formatterService.convertPartialItem(
              this.fieldDefinitions[v.dashboardFieldId] as FieldDefinition,
              Number(this.dataPoints[v.dashboardFieldId])
            ) as number)
          : null;

      const header = this.headerData.filter((h) => h.subType === 'primary')[0];
      const vizMetric = (
        this.fieldDefinitions[
          header?.dashboardFieldId as string
        ] as FieldDefinition
      ).label;

      return {
        ...v,
        totalItems,
        otherItems,
        vizMetric,
        label: `${totalItems} Brands`,
        percentValue
      };
    });
  }

  toMetricCardData(item: unknown): MetricCardData {
    return item as MetricCardData;
  }

  // format data using fieldDefinition
  getDisplayValue(value: number, fieldDefinition: FieldDefinition): string {
    let val = this.formatterService.convertPartialItem(fieldDefinition, value);
    val = this.formatterService.formatPartialValue(fieldDefinition, val);
    if (isMeasuredBenchmarkLandingPage(this.dashboardLiteralId)) {
      const num = +val.slice(0, val.length - 1);
      return num > 0 ? `+${val}` : val;
    }
    return `${val}`;
  }
}
