import {
  AlertActionType,
  AlertIcon,
  AlertSeverity,
  IAlertMessageConfig
} from '@libs/common-components';
import {
  CpCellType,
  GeoTestType,
  ITestComments,
  ITestMetadataAttributes,
  ITestParameterHistory,
  Parameter,
  RecommendationResult
} from '@libs/apis';

export interface IGeoAlertMessageConfig extends IAlertMessageConfig {
  parameterChanges?: ParameterChanges[];
}

export enum GeoAlertKeys {
  parametersChanged = 'PARAMETERS_CHANGED',
  tacticNotFound = 'TACTIC_NOT_FOUND',
  lateToStart = 'LATE_TO_START',
  changesApplied = 'CHANGES_APPLIED',
  msExpired = 'MS_EXPIRED'
}

export enum AlertEventId {
  accept = 'accept',
  deleteTest = 'delete-test'
}

export interface ParameterChanges {
  parameterHistory: ITestParameterHistory;
  comment: string;
  oldRawValue: string | number;
  newRawValue: string | number;
  literalId: keyof ITestMetadataAttributes;
  parameterStyleClass: string;
}

export class TestAlertsHelper {
  static readonly alertItems: Record<GeoAlertKeys, IAlertMessageConfig> = {
    [GeoAlertKeys.parametersChanged]: {
      key: GeoAlertKeys.parametersChanged,
      summary: 'Your approval is needed for launch',
      description:
        'The parameters of your test have changed due to recent performance. You can accept the changes and then schedule the test, or remove the test altogether.',
      severity: AlertSeverity.danger,
      icon: AlertIcon.warning,
      alertActions: [
        {
          label: 'Remove Test',
          type: AlertActionType.secondary,
          eventId: AlertEventId.deleteTest
        },
        {
          label: 'Accept Changes',
          type: AlertActionType.primary,
          eventId: AlertEventId.accept
        }
      ]
    },
    [GeoAlertKeys.tacticNotFound]: {
      key: GeoAlertKeys.tacticNotFound,
      summary: 'This test cannot be run as planned',
      description:
        'Data that was necessary for this test is no longer available and it needs to be removed.',
      severity: AlertSeverity.danger,
      icon: AlertIcon.warning,
      alertActions: [
        {
          label: 'Remove Test',
          type: AlertActionType.primary,
          eventId: AlertEventId.deleteTest
        }
      ]
    },
    [GeoAlertKeys.lateToStart]: {
      key: GeoAlertKeys.lateToStart,
      summary: `You're late on starting a scheduled test`,
      description: `Your test was scheduled to launch $duration day(s) ago. You must change the start and end dates or cancel this test.`,
      severity: AlertSeverity.warn,
      icon: AlertIcon.warning,
      alertActions: [
        {
          label: 'Remove Test',
          type: AlertActionType.primary,
          eventId: AlertEventId.deleteTest
        }
      ]
    },
    [GeoAlertKeys.changesApplied]: {
      key: GeoAlertKeys.changesApplied,
      summary: `The changes were applied successfully on $now`,
      description: 'The following parameters have been changed:',
      severity: AlertSeverity.success,
      icon: AlertIcon.checkCircle,
      alertActions: []
    },
    [GeoAlertKeys.msExpired]: {
      key: GeoAlertKeys.msExpired,
      summary: 'The market selection process has updated',
      description:
        'The conditions for the markets involved in this test have changed and it must be removed.',
      severity: AlertSeverity.danger,
      icon: AlertIcon.warning,
      alertActions: [
        {
          label: 'Remove Test',
          type: AlertActionType.primary,
          eventId: AlertEventId.deleteTest
        }
      ]
    }
  };

  public static isApplicableForParameterChange(
    parameterHistory: ITestParameterHistory
  ): boolean {
    return (
      parameterHistory.parameter === Parameter.TREATMENT ||
      parameterHistory.parameter === Parameter.SCALE_COST ||
      parameterHistory.parameter === Parameter.EXECUTION_METHOD
    );
  }

  public static getComment(
    parameterHistory: ITestParameterHistory,
    comments: ITestComments
  ): string {
    switch (parameterHistory.parameter) {
      case Parameter.TREATMENT:
        return comments.type;
      case Parameter.EXECUTION_METHOD:
        return comments.method;
      case Parameter.SCALE_COST:
        return comments.additionalSpend;
      case Parameter.STATUS:
        return `Dry run failed for this test. Please look into the slack alerts to
              know more about the failure reason and refer this
              <a
                class="saas-text-link"
                target="_blank"
                rel="noopener"
                href="https://measured.atlassian.net/wiki/spaces/TEC/pages/2625372161/Dry+Run+and+Dry+Run+Errors"
                >document</a
              >
              to understand some errors.`;
      default:
        return '';
    }
  }

  public static getParameterRawValue(
    parameter: Parameter,
    value: string
  ): string | number {
    switch (parameter) {
      case Parameter.SCALE_COST:
        return parseInt(value, 10);
      case Parameter.TREATMENT:
        return value === CpCellType.CONTROL_SCALE
          ? GeoTestType.scale
          : GeoTestType.holdout;
      default:
        return value;
    }
  }

  public static getMetadataLiteralId(
    parameter: Parameter
  ): keyof ITestMetadataAttributes {
    switch (parameter) {
      case Parameter.TREATMENT:
        return 'testType';
      case Parameter.EXECUTION_METHOD:
        return 'method';
      case Parameter.SCALE_COST:
        return 'scaleCost';
      case Parameter.STATUS:
      default:
        return 'status';
    }
  }

  public static getParameterHighlighter(
    alertConfig: IAlertMessageConfig
  ): string {
    if (alertConfig.severity === AlertSeverity.danger) return 'text-red b3';
    else if (alertConfig.severity === AlertSeverity.success)
      return 'text-green b3';
    return '';
  }

  static hasParameterChanges(alertConfig: IAlertMessageConfig): boolean {
    const applicableKeysForParameterChanges: GeoAlertKeys[] = [
      GeoAlertKeys.parametersChanged,
      GeoAlertKeys.changesApplied
    ];
    return applicableKeysForParameterChanges.includes(
      alertConfig.key as GeoAlertKeys
    );
  }

  static hasBreakingAlerts(alertConfig: IAlertMessageConfig): boolean {
    const breakingAlertKeys: GeoAlertKeys[] = [
      GeoAlertKeys.tacticNotFound,
      GeoAlertKeys.msExpired
    ];

    return breakingAlertKeys.includes(alertConfig.key as GeoAlertKeys);
  }

  public static isTacticNotFound = (recommendationResult: string) =>
    recommendationResult === RecommendationResult.TACTIC_NOT_FOUND;

  public static isWaitingForApproval = (recommendationResult: string) =>
    recommendationResult === RecommendationResult.WAITING_FOR_APPROVAL;

  public static isSuccess = (recommendationResult: string) =>
    recommendationResult === RecommendationResult.SUCCESS;

  public static isCustomised = (recommendationResult: string) =>
    recommendationResult === RecommendationResult.CUSTOMISED;

  public static isMsExpired = (recommendationResult: string) =>
    recommendationResult === RecommendationResult.MS_EXPIRED;
}
