<div class="flex justify-between items-center mb-4">
  <div class="flex items-center">
    <h2 class="dp2 text-blue-1000 mb-0">My Portfolio</h2>
    <div
      class="b1 text-blue-600 cursor-pointer pl-4 mt-1"
      (click)="navigateToPage()">
      View details
    </div>
    <p-divider layout="vertical"></p-divider>
    <portal-mmm-banner
      [brandId]="brandId"
      [filters]="filters"
      [fieldDefinitions]="fieldDefinitions"></portal-mmm-banner>
  </div>

  <div class="flex items-center">
    <span class="mr-4">
      @if (refreshTimeConfig.refreshTime) {
        <portal-refresh-time
          [headerConfig]="refreshTimeConfig"></portal-refresh-time>
      }
    </span>
    <m-tooltip
      content="<span class='b1 text-gray-000'>Configure metrics, channels, and ordering</span>"
      position="bottom"
      [tooltipOptions]="{
        positionTop: 10,
        showDelay: 800,
      }">
      <m-button [type]="'secondary'" (click)="toggleModal()">
        <span class="b2 text-blue-600">Customize</span>
      </m-button>
    </m-tooltip>
  </div>
</div>

<m-modal
  [(visible)]="isModalVisible"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  (onHide)="closeModal()"
  [isFullscreen]="true"
  [dismissableMask]="false">
  <!-- Modal Header -->
  <ng-container modalHeader>
    <div class="mb-4 text-center w-full">
      <h4 class="h4 mb-0 mr-2">Customize Homepage</h4>
      <div>
        <span class="b1 text-gray-600">
          Configure the channels and metrics you will see on your dashboard.
        </span>
      </div>
    </div>
  </ng-container>

  <div class="px-8">
    <!-- My Portfolio -->
    <div>
      <h5 class="h5 mb-3">My Portfolio</h5>
      <div class="flex mb-3">
        <div>
          <img
            alt="metrics"
            src="assets/images/home-page/metrics.svg"
            width="160px" />
        </div>
        <div class="ml-4">
          <div class="b3 mb-2">My Portfolio Metrics</div>
          <div class="b2 text-gray-600 mb-2">
            Choose the metrics shown and their order under My Portfolio.
          </div>
          <m-multiselect
            class="mr-2"
            data-tracker="portal-portfolio-header__multiSelect--myPortfolio"
            (dragEnd)="sortMetrics($event)"
            [draggable]="true"
            [isValid]="!errorMetrics"
            [maxSelectedLabels]="0"
            [(ngModel)]="selectedMetrics"
            (ngModelChange)="onMetricsChange($event)"
            [options]="metricOptions"
            [selectedItemsLabel]="selectedMetricssLabel"></m-multiselect>
          <div *ngIf="errorMetrics" class="b2 text-red-700 mt-2">
            Minimum 1 selected metric
          </div>
        </div>
      </div>
      <div class="flex mb-3">
        <div>
          <img
            alt="metrics"
            src="assets/images/home-page/optimization-results.svg"
            width="160px" />
        </div>
        <div class="ml-4">
          <div class="b3 mb-2">Optimization Results</div>
          <div class="flex">
            <m-switch
              [(ngModel)]="showOptimizationResults"
              (onChange)="toggleOptimizationResults($event.checked)"></m-switch>
            <div class="b2 text-gray-600 ml-1">
              Show or hide the Optimization Results charts.
            </div>
          </div>
        </div>
      </div>
      <div class="flex">
        <div>
          <img
            alt="metrics"
            src="assets/images/home-page/spend-data.svg"
            width="160px" />
        </div>

        <div class="ml-4">
          <div class="b3 mb-2">Spending Visualizations</div>
          <div class="flex">
            <m-switch
              [(ngModel)]="showChartView"
              (onChange)="toggleChartView($event.checked)"></m-switch>
            <div class="b2 text-gray-600 ml-1">
              Show or hide the Spend Allocation and Trending charts.
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- My Customers -->
    <div class="my-4">
      <h5 class="h5 mb-3">My Customers</h5>
      <div class="flex mb-3">
        <div>
          <img
            alt="metrics"
            src="assets/images/home-page/customers.svg"
            width="160px" />
        </div>
        <div class="ml-4">
          <div class="b3 mb-2">My Customers Section</div>
          <div class="flex">
            <m-switch
              [(ngModel)]="showCustomerView"
              (onChange)="toggleCustomerView($event.checked)"></m-switch>
            <div class="b2 text-gray-600 ml-1">
              Show or hide the My Customers section.
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- My Channels -->
    <div class="my-4">
      <h5 class="h5 mb-3">My Channels</h5>
      <div class="flex mb-3">
        <div>
          <img
            alt="channels"
            src="assets/images/home-page/channels.svg"
            width="160px" />
        </div>
        <div class="ml-4">
          <div class="b3 mb-2">Included Channels</div>
          <div class="b2 text-gray-600 mb-2">
            Select which channels will be aggregated across your Homepage.
          </div>
          <m-multiselect
            class="mr-2"
            data-tracker="portal-portfolio-header__multiSelect--myChannels"
            [draggable]="true"
            [isValid]="!errorChannels"
            (dragEnd)="sortChannels($event)"
            [maxSelectedLabels]="0"
            [(ngModel)]="selectedChannels"
            (ngModelChange)="onChannelsChange($event)"
            [options]="channelOptions"
            [selectedItemsLabel]="selectedChannelsLabel"></m-multiselect>
          @if (errorChannels) {
            <div class="b2 text-red-700 mt-2">Minimum 1 selected channel</div>
          }
        </div>
      </div>
      <div class="flex mb-3">
        <div>
          <img
            alt="channels"
            src="assets/images/home-page/channel-tile-metrics.svg"
            width="160px" />
        </div>
        <div class="ml-4">
          <div class="b3 mb-2">Channel Tile Metrics</div>
          <div class="b2 text-gray-600 mb-2">
            Choose the metrics to be shown on the My Channel tiles.
          </div>
          <m-multiselect
            class="mr-2"
            [isValid]="!errorTileMetrics"
            [maxSelectedLabels]="0"
            [(ngModel)]="channelTileMetrics"
            (ngModelChange)="onChannelTileMetricsChange($event)"
            [options]="channelMetricOptions"
            [selectedItemsLabel]="channelTileMetricsLabel"></m-multiselect>
          @if (errorTileMetrics) {
            <div class="b2 text-red-700 mt-2">
              {{ tileMetricsErrorMessage }}
            </div>
          }
        </div>
      </div>
      <div class="flex">
        <div>
          <img
            alt="channels"
            src="assets/images/home-page/channel-view-metrics.svg"
            width="160px" />
        </div>
        <div class="ml-4">
          <div class="b3 mb-2">Channel View Metrics</div>
          <div class="b2 text-gray-600 mb-2">
            Select metrics to see after clicking a My Channel tile.
          </div>
          <m-multiselect
            class="mr-2"
            [isValid]="!errorViewMetrics"
            [maxSelectedLabels]="0"
            [(ngModel)]="channelViewMetrics"
            (ngModelChange)="onChannelViewMetricsChange($event)"
            [options]="channelMetricOptions"
            [selectedItemsLabel]="channelViewMetricsLabel"></m-multiselect>
          @if (errorViewMetrics) {
            <div class="b2 text-red-700 mt-2">
              {{ viewMetricsErrorMessage }}
            </div>
          }
        </div>
      </div>
    </div>
    <!-- Add spacing for dropdown-->
    <div class="mb-60"></div>
  </div>

  <!-- Modal Footer -->
  <ng-container modalFooter>
    <div class="mt-2">
      <m-button type="secondary" (click)="closeModal()">
        <span class="b2 text-blue-600">Cancel</span>
      </m-button>

      <m-button (click)="onSave()" [disabled]="!isFormValid">
        <span class="b2 text-gray-000">Apply</span>
      </m-button>
    </div>
  </ng-container>
</m-modal>
