<p-dialog
  [(visible)]="dialogVisibility"
  [style]="{ width: '45vw' }"
  class="popup-dialog"
  (onHide)="hidePopup()"
  [draggable]="false"
  appendTo="body"
  [modal]="true">
  <ng-template pTemplate="header">
    <div class="d-block">
      <div class="primary-heading">{{ dialogTitle }}</div>
      <div class="secondary-heading" [innerHTML]="dialogSubTitle"></div>
    </div>
  </ng-template>
  <p-table
    [value]="tableData"
    scrollHeight="flex"
    scrollable="true"
    class="saas-ui">
    <ng-template pTemplate="header">
      <tr>
        @for (col of tableColumns; track col) {
          <th id="popup-table-header" pSortableColumn="{{ col.columnId }}">
            {{ col.label }}
            <span class="ms-2 material-symbols-outlined sort-icon">sort</span>
          </th>
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data>
      <tr>
        @for (col of tableColumns; track col) {
          @switch (col.dataType) {
            @case ("string") {
              <td>{{ data[col.columnId] }}</td>
            }
            @case ("date") {
              <td>
                {{ data[col.columnId] | date: "MM/dd/YYYY" : "UTC" }}
              </td>
            }
          }
        }
      </tr>
    </ng-template>
  </p-table>
</p-dialog>
