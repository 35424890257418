/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// services
import { MetricsService } from '@portal/app/dashboard/home-page/services/metrics.service';
import { HomepageService } from '@portal/app/dashboard/home-page/services/home-page.service';
import { DropdownService } from '@portal/app/dashboard/home-page/services/dropdown.service';
import { ChannelsService } from '@portal/app/dashboard/home-page/services/channels.service';
import { MyChannelsService } from '@portal/app/dashboard/home-page/components/my-channels/my-channels.service';
import { NavigationService } from '@portal/app/shared/services/navigation.service';
import { FilterService } from '@portal/app/dashboard/home-page/services/filter.service';
import { FieldService } from '@portal/app/shared/services/field.service';
import { ViewStore } from '@portal/app/shared/state/view.store';

// Types
import { IRefreshTimeConfig } from '@portal/app/shared/models/IHeaderConfig';
import type { SelectItem } from 'primeng/api';
import { Filter } from '@portal/app/shared/types';
import { FieldDefinitions } from '@portal/app/shared/types';

@Component({
  selector: 'portal-portfolio-header',
  templateUrl: './portfolio-header.component.html',
  styleUrls: ['./portfolio-header.component.scss']
})
export class PortfolioHeaderComponent implements OnInit, OnDestroy {
  data: any;
  filters: Filter[] = [];
  fieldDefinitions: FieldDefinitions = {};
  public brandId = 0;

  // Cleanup
  private destroy$ = new Subject<void>();

  isModalVisible = false;

  selectedMetrics: string[] = [];
  metricOptions: SelectItem[] = [];

  selectedChannels: string[] = [];
  channelOptions: SelectItem[] = [];

  refreshTimeConfig: { refreshTime: IRefreshTimeConfig } =
    this.homepageService.getRefreshTimeConfig();

  showChartView = true;
  showCustomerView = true;
  showOptimizationResults = true;
  channelTileMetrics: string[] = [];
  channelViewMetrics: string[] = [];
  channelMetricOptions: SelectItem[] = [];

  maxTileMetrics = 2;
  maxViewMetrics = 5;

  // Error state variables
  errorMetrics = false;
  errorChannels = false;
  errorTileMetrics = false;
  errorViewMetrics = false;
  tileMetricsErrorMessage = '';
  viewMetricsErrorMessage = '';

  constructor(
    private fieldService: FieldService,
    private filterService: FilterService,
    private metricsService: MetricsService,
    private homepageService: HomepageService,
    private dropdownService: DropdownService,
    private channelsService: ChannelsService,
    private navigationService: NavigationService,
    private myChannelsService: MyChannelsService,
    private readonly viewStore: ViewStore
  ) {
    const allChannels = this.channelsService.getAllChannels();
    let selectedChannels = this.channelsService.getSelectedChannels();
    this.fieldDefinitions = this.fieldService.getCurrentFields();

    // Filter selectedChannels to ensure they are all valid as per allChannels
    selectedChannels = selectedChannels.filter((channel) =>
      allChannels.includes(channel)
    );

    // Update the component's state with the validated selectedChannels
    this.selectedChannels = selectedChannels;

    // Sort all channels by selected channels
    const sortedChannels = this.sortChannelsBySelected(
      allChannels,
      this.selectedChannels
    );
    // Pass sorted channels to getChannelOptions
    this.channelOptions = this.getChannelOptions(sortedChannels);

    // Sort all metric options by selected options
    this.sortMetricOptionsBySelectedMetrics();
  }

  ngOnInit(): void {
    // Subscribe to changes
    this.subscribeToChanges();
  }

  subscribeToChanges() {
    // Subscribe to the observable and use takeUntil to manage unsubscription
    this.homepageService.showChartView$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.showChartView = value;
      });

    this.homepageService.showCustomerView$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.showCustomerView = value;
      });

    this.homepageService.showOptimizationResults$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.showOptimizationResults = value;
      });

    this.homepageService.channelTileMetrics$
      .pipe(takeUntil(this.destroy$))
      .subscribe((channelTileMetrics) => {
        this.channelTileMetrics = channelTileMetrics;
      });

    this.homepageService.channelViewMetrics$
      .pipe(takeUntil(this.destroy$))
      .subscribe((channelViewMetrics) => {
        this.channelViewMetrics = channelViewMetrics;
      });

    this.homepageService.channelMetrics$
      .pipe(takeUntil(this.destroy$))
      .subscribe((channelMetrics) => {
        this.channelMetricOptions = this.dropdownService.getMetricOptions(
          false,
          channelMetrics
        );
      });

    this.filterService.filters$
      .pipe(takeUntil(this.destroy$))
      .subscribe((filters) => {
        this.filters = filters;
      });

    this.viewStore.selectedBCPDId.subscribe((value) => {
      this.brandId = value.brandId;
    });
  }

  // Need to ensure the dropdown options are in the proper order
  sortMetricOptionsBySelectedMetrics(): void {
    this.metricOptions = this.dropdownService.getMetricOptions();
    this.selectedMetrics = this.metricsService.getSelectedMetrics();

    this.metricOptions.sort((a, b) => {
      const indexA = this.selectedMetrics.indexOf(a.value);
      const indexB = this.selectedMetrics.indexOf(b.value);

      if (indexA === -1 && indexB === -1) return 0; // Neither are selected
      if (indexA === -1) return 1; // A is not selected, B is
      if (indexB === -1) return -1; // B is not selected, A is

      return indexA - indexB;
    });
  }

  sortChannelsBySelected(
    allChannels: string[],
    selectedChannels: string[]
  ): string[] {
    // First, filter out the selected channels in their order
    const orderedSelectedChannels = selectedChannels.filter((channel) =>
      allChannels.includes(channel)
    );

    // Then, get the rest of the channels that were not selected
    const restOfTheChannels = allChannels.filter(
      (channel) => !selectedChannels.includes(channel)
    );

    // Combine them to get the sorted list
    return [...orderedSelectedChannels, ...restOfTheChannels];
  }

  getChannelOptions(allChannels: string[]) {
    return allChannels.map((channel) => ({
      label: channel,
      value: channel
    }));
  }

  closeModal() {
    this.resetData();
    this.isModalVisible = false;
  }

  toggleModal() {
    this.isModalVisible = true;
    this.data = {
      metrics: [...this.selectedMetrics],
      channelByDisplayOrder: [...this.selectedChannels],
      channelKpiTileMetrics: [...this.channelTileMetrics],
      channelKpiViewMetrics: [...this.channelViewMetrics],
      showSpendAllocationAndTrendingChartView: this.showChartView,
      showCustomerSection: this.showCustomerView,
      showOptimizationResults: this.showOptimizationResults
    };
  }

  onMetricsChange(items: string[]) {
    // Validation logic
    this.errorMetrics = !items.length;
    this.selectedMetrics = [...items];
  }

  onChannelsChange(items: string[]) {
    // Validation logic
    this.errorChannels = !items.length;
    this.selectedChannels = [...items];
  }

  onChannelTileMetricsChange(items: string[]) {
    // Validation logic for exactly 2 metrics
    if (items.length < 2) {
      this.errorTileMetrics = true;
      this.tileMetricsErrorMessage = 'Minimum 2 selected metrics required.';
    } else if (items.length > this.maxTileMetrics) {
      this.errorTileMetrics = true;
      this.tileMetricsErrorMessage = `Maximum ${this.maxTileMetrics} selected metrics.`;
    } else {
      this.errorTileMetrics = false;
      this.tileMetricsErrorMessage = '';
    }

    this.channelTileMetrics = [...items];
  }

  onChannelViewMetricsChange(items: string[]) {
    if (items.length === 0) {
      this.errorViewMetrics = true;
      this.viewMetricsErrorMessage = 'Minimum 1 selected metric.';
    } else if (items.length > this.maxViewMetrics) {
      this.errorViewMetrics = true;
      this.viewMetricsErrorMessage = `Maximum ${this.maxViewMetrics} selected metrics.`;
    } else {
      this.errorViewMetrics = false;
      this.viewMetricsErrorMessage = '';
    }

    this.channelViewMetrics = [...items];
  }

  onSave() {
    // If form invalid bail early
    if (!this.isFormValid) return;

    const layoutData = {
      metrics: this.selectedMetrics,
      channelByDisplayOrder: this.selectedChannels,
      channelKpiTileMetrics: this.channelTileMetrics,
      channelKpiViewMetrics: this.channelViewMetrics,
      showSpendAllocationAndTrendingChartView: this.showChartView,
      showCustomerSection: this.showCustomerView,
      showOptimizationResults: this.showOptimizationResults
    };

    this.homepageService.updateHomepageLayout(layoutData).subscribe({
      next: () => {
        this.homepageService.fetchAndSetHomepageData();
        this.isModalVisible = false;
      },
      error: (error: any) => {
        console.error('updateHomepageLayout failed', error);
        this.homepageService.setLoading(false);
      }
    });
  }

  getValues(options: SelectItem[], values: string[]) {
    return options
      .filter((option) => values.includes(option.value))
      .map((option) => option.value);
  }

  sortChannels(options: SelectItem[]) {
    this.channelOptions = options;
    this.selectedChannels = this.getValues(options, this.selectedChannels);
  }

  sortMetrics(options: SelectItem[]) {
    this.metricOptions = options;
    this.selectedMetrics = this.getValues(options, this.selectedMetrics);
  }

  toggleChartView(value: boolean) {
    this.showChartView = value;
  }

  toggleCustomerView(value: boolean) {
    this.showCustomerView = value;
  }

  toggleOptimizationResults(value: boolean) {
    this.showOptimizationResults = value;
  }

  navigateToPage(): void {
    this.navigationService.navigationMapping('exploreChannel', {
      filters: this.myChannelsService.getFilters(),
      extraParams: {
        queryParams: {}
      }
    });
  }

  get selectedChannelsLabel(): string {
    return `${this.selectedChannels?.length ?? 0}/${
      this.channelOptions?.length ?? 0
    } selected`;
  }

  get selectedMetricssLabel(): string {
    return `${this.selectedMetrics?.length ?? 0}/${
      this.metricOptions?.length ?? 0
    } selected`;
  }

  get channelViewMetricsLabel(): string {
    return `${this.channelViewMetrics?.length ?? 0}/${
      this.maxViewMetrics
    } selected`;
  }

  get channelTileMetricsLabel(): string {
    return `${this.channelTileMetrics?.length ?? 0}/${
      this.maxTileMetrics
    } selected`;
  }

  get isFormValid(): boolean {
    return (
      !this.errorMetrics &&
      !this.errorChannels &&
      !this.errorTileMetrics &&
      !this.errorViewMetrics
    );
  }

  resetData() {
    this.selectedMetrics = [...this.data.metrics];
    this.selectedChannels = [...this.data.channelByDisplayOrder];
    this.channelTileMetrics = [...this.data.channelKpiTileMetrics];
    this.channelViewMetrics = [...this.data.channelKpiViewMetrics];
    this.showChartView = this.data.showSpendAllocationAndTrendingChartView;
    this.showCustomerView = this.data.showCustomerSection;
    // Reset error states
    this.errorMetrics = false;
    this.errorChannels = false;
    this.errorTileMetrics = false;
    this.errorViewMetrics = false;
    this.tileMetricsErrorMessage = '';
    this.viewMetricsErrorMessage = '';
  }

  ngOnDestroy(): void {
    // Emit a value to trigger completion of all observables using takeUntil
    this.destroy$.next();
    // Ensure the subject itself is completed to prevent memory leaks
    this.destroy$.complete();
  }
}
