/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import dayjs from 'dayjs';

// Services
import {
  DashboardDataControllerV2Service,
  DashboardDataRequestDTO
} from '@libs/api';
import { FieldService } from '@portal/app/shared/services/field.service';
import { FormatterService } from '@portal/app/shared/services/formatter.service';
import { FormatService } from '@portal/app/dashboard/home-page/services/format.service';
import { SelectionService } from '@portal/app/shared/services/selection.service';
import { DayJsDateFormat } from '@portal/app/shared/services/date-time.service';

// Highcharts
import {
  AxisLabelsFormatterContextObject,
  Options,
  SeriesOptionsType,
  TooltipFormatterCallbackFunction,
  TooltipFormatterContextObject
} from 'highcharts';
import {
  baseChartConfig,
  checkChartDataAvailability,
  getGradientFill,
  markerStyles
} from '@design-system/components/m-line-chart';

// Utilities
import { colors } from '@design-system/styles/colors';

// Constants
import {
  DashboardLiteralIds,
  FieldDefinitions,
  ProductLiteralIds,
  Resolution,
  ViewBy
} from '@portal/app/shared/constants';
import { HeroMetric, TooltipData } from './performance-over-time-chart.types';
import { formatDateForChart } from '@portal/app/charts/shared/utils';

@Injectable({
  providedIn: 'root'
})
export class PerformanceOverTimeChartService implements OnDestroy {
  private readonly chartDataSubject = new BehaviorSubject<Options>(
    baseChartConfig
  );

  public chartData$ = this.chartDataSubject.asObservable();

  private readonly isLoadingSubject = new BehaviorSubject<boolean>(false);
  public isLoading$ = this.isLoadingSubject.asObservable();

  private readonly isChartReadySubject = new BehaviorSubject<boolean>(false);
  public isChartReady$ = this.isChartReadySubject.asObservable();

  private readonly isDataAvailableSubject = new BehaviorSubject<boolean>(true);
  public isDataAvailable$ = this.isDataAvailableSubject.asObservable();

  private additionalRevenueSubject = new BehaviorSubject<string | null>(null);
  public additionalRevenue$ = this.additionalRevenueSubject.asObservable();

  private destroy$ = new Subject<void>();

  private heroMetricSubject = new BehaviorSubject<HeroMetric>({
    dateStart: '',
    dateStop: '',
    isPositive: false,
    isUp: false,
    changeInPercent: '',
    additionalMetric: ''
  });

  public heroMetric$ = this.heroMetricSubject.asObservable();

  constructor(
    private readonly dashboardDataControllerV2Service: DashboardDataControllerV2Service,
    private readonly fieldService: FieldService,
    private readonly formatService: FormatService,
    private readonly formatterService: FormatterService,
    private readonly selectionService: SelectionService
  ) {}

  /**
   * Fetch data based on provided filters.
   *
   * @param filters The filters to apply to the data request.
   */
  public fetchData(filters: {
    dateStart: string;
    dateStop: string;
    conversionType: string;
    resolution?: string;
    viewBy?: ViewBy;
  }): void {
    if (!this.areFiltersValid(filters)) {
      console.error('Missing required filters:', filters);
      this.isLoadingSubject.next(false);
      return;
    }

    this.isLoadingSubject.next(true);
    const request = this.formatRequest(filters);
    this.getData(request, filters);
  }

  /**
   * Send data request to the server.
   *
   * @param request The formatted request object.
   * @param filters The filters applied to the data request.
   */
  private getData(
    request: DashboardDataRequestDTO,
    filters: {
      dateStart: string;
      dateStop: string;
      conversionType: string;
      resolution?: string;
      viewBy?: ViewBy;
    }
  ): void {
    this.dashboardDataControllerV2Service.getDataChartsPost(request).subscribe(
      (response) => this.handleDataResponse(response, filters),
      (error) => this.handleError(error)
    );
  }

  /**
   * Handle the data response from the server.
   *
   * @param response The data response from the server.
   * @param filters The filters applied to the data request.
   */
  private handleDataResponse(
    response: any,
    filters: { dateStart: string; dateStop: string; viewBy?: ViewBy }
  ): void {
    this.updateHeroMetric(response);

    const transformedData = this.transformDataBasedOnViewBy(
      response[0]?.data || [],
      filters.viewBy
    );

    const formattedChartData = this.formatChartData(
      transformedData,
      filters.viewBy
    );

    this.chartDataSubject.next(formattedChartData);

    const isChartReady = this.checkChartReadiness(formattedChartData);
    const isDataAvailable = checkChartDataAvailability(formattedChartData);

    this.isChartReadySubject.next(isChartReady);
    this.isDataAvailableSubject.next(isDataAvailable);
    this.isLoadingSubject.next(false);
  }

  /**
   * Handle errors during data fetching.
   *
   * @param error The error object.
   */
  private handleError(error: any): void {
    console.error('Error fetching chart data:', error);
    this.isLoadingSubject.next(false);
    this.isChartReadySubject.next(false);
    this.isDataAvailableSubject.next(false);
  }

  /**
   * Check if the chart data is ready.
   *
   * @param chartData The chart data to check.
   * @returns True if the chart data is ready, otherwise false.
   */
  private checkChartReadiness(chartData: Options): boolean {
    const hasRequiredChartData =
      !!chartData.series && chartData.series.length > 0;
    const hasRequiredAxes = !!chartData.xAxis && !!chartData.yAxis;
    const hasRequiredProperties =
      !!chartData.plotOptions && !!chartData.tooltip;

    return hasRequiredChartData && hasRequiredAxes && hasRequiredProperties;
  }

  /**
   * Validate the provided filters.
   *
   * @param filters The filters to validate.
   * @returns True if the filters are valid, otherwise false.
   */
  private areFiltersValid(filters: {
    dateStart: string;
    dateStop: string;
    conversionType: string;
    resolution?: string;
    viewBy?: ViewBy;
  }): boolean {
    return !!(filters.dateStart && filters.dateStop && filters.conversionType);
  }

  /**
   * Format the request object based on provided filters.
   *
   * @param filters The filters to apply to the data request.
   * @returns The formatted request object.
   */
  private formatRequest(filters: {
    dateStart: string;
    dateStop: string;
    conversionType: string;
    resolution?: string;
    viewBy?: ViewBy;
  }): DashboardDataRequestDTO {
    const { clientId, brandId } = this.selectionService.getClientIdAndBrandId();

    return {
      clientId,
      brandId,
      product: ProductLiteralIds.portfolio,
      dashboard: DashboardLiteralIds.optimizationReport,
      filter: {
        viewBy: filters.viewBy || ViewBy.roas,
        charts: ['optChart'],
        resolution: filters.resolution || 'month',
        chartDimensions: this.getChartDimensions(filters.viewBy),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        conversion_type: filters.conversionType,
        dateStart: filters.dateStart,
        dateStop: filters.dateStop,
        isInitialFilterCall: false
      },
      dimensions: ['channel', 'tactic'],
      exportObjParams: {}
    };
  }

  private getChartDimensions(viewBy?: ViewBy): string[] {
    return viewBy === ViewBy.cpo
      ? [
          FieldDefinitions.mediaSpend,
          FieldDefinitions.ordersI,
          FieldDefinitions.proratedCurOrdersI,
          FieldDefinitions.cpoI,
          FieldDefinitions.proratedCurCpoI
        ]
      : [
          FieldDefinitions.mediaSpend,
          FieldDefinitions.salesI,
          FieldDefinitions.proratedCurSalesI,
          FieldDefinitions.roasI,
          FieldDefinitions.proratedCurRoasI
        ];
  }

  /**
   * Transform the data based on the view type.
   *
   * @param data The raw data to transform.
   * @param viewBy The view type.
   * @returns The transformed data.
   */
  private transformDataBasedOnViewBy(data: any[], viewBy?: ViewBy): any[] {
    return data.map((item) => {
      return {
        ...item,
        metricI: viewBy === ViewBy.cpo ? item.cpoI : item.roasI,
        proratedCurMetricI:
          viewBy === ViewBy.cpo ? item.proratedCurCpoI : item.proratedCurRoasI,
        currentAllocationValue:
          viewBy === ViewBy.cpo ? item.ordersI : item.salesI,
        proratedCurMetricValueI:
          viewBy === ViewBy.cpo
            ? item.proratedCurOrdersI
            : item.proratedCurSalesI,
        metricChange: item.additionalData?.roasI || item.additionalData?.cpoI
      };
    });
  }

  /**
   * Create the chart series based on provided data and filters.
   *
   * @param chartData The data for the chart.
   * @param viewBy The view type.
   * @returns An array of series options.
   */
  private createSeries(chartData: any[], viewBy?: ViewBy): SeriesOptionsType[] {
    const isCPO = viewBy === ViewBy.cpo;

    const previousAllocationSeries = this.createPreviousAllocationSeries(
      chartData,
      isCPO
    );
    const currentAllocationSeries = this.createCurrentAllocationSeries(
      chartData,
      isCPO
    );
    const mediaSpendSeries = this.createMediaSpendSeries(chartData);

    return [
      previousAllocationSeries,
      currentAllocationSeries,
      mediaSpendSeries
    ];
  }

  /**
   * Create the previous allocation series.
   *
   * @param chartData The data for the chart.
   * @param isCPO Boolean flag indicating if the view is CPO.
   * @returns The series options for the previous allocation.
   */
  private createPreviousAllocationSeries(
    chartData: any[],
    isCPO: boolean
  ): SeriesOptionsType {
    const previousAllocationLabel =
      this.fieldService.getFieldDefinitionByLiteralId(
        isCPO
          ? FieldDefinitions.previousOrdersI
          : FieldDefinitions.previousSalesI
      )?.label || '';

    return {
      type: 'areaspline',
      name: previousAllocationLabel,
      yAxis: 1,
      data: chartData.map((item: any) => ({
        y: item.proratedCurMetricValueI,
        custom: {
          dateStart: item.dateStart,
          dateStop: item.dateStop,
          metricI: item.metricI,
          metricChange: { ...item.metricChange }
        }
      })),
      color: colors['gray-800'],
      fillColor: getGradientFill(String(colors['gray-500'])),
      lineWidth: 1,
      marker: markerStyles,
      zIndex: 1
    };
  }

  /**
   * Create the current allocation series.
   *
   * @param chartData The data for the chart.
   * @param isCPO Boolean flag indicating if the view is CPO.
   * @returns The series options for the current allocation.
   */
  private createCurrentAllocationSeries(
    chartData: any[],
    isCPO: boolean
  ): SeriesOptionsType {
    const currentAllocationLabel =
      this.fieldService.getFieldDefinitionByLiteralId(
        isCPO ? FieldDefinitions.currentOrdersI : FieldDefinitions.currentSalesI
      )?.label || '';

    return {
      type: 'areaspline',
      name: currentAllocationLabel,
      yAxis: 1,
      data: chartData.map((item: any) => ({
        y: item.currentAllocationValue,
        custom: {
          dateStart: item.dateStart,
          dateStop: item.dateStop,
          metricI: item.metricI,
          metricChange: { ...item.metricChange }
        }
      })),
      color: colors['orange-300'],
      fillColor: getGradientFill(String(colors['orange-100'])),
      lineWidth: 1,
      marker: markerStyles,
      zIndex: 0
    };
  }

  /**
   * Create the media spend series.
   *
   * @param chartData The data for the chart.
   * @returns The series options for the media spend.
   */
  private createMediaSpendSeries(chartData: any[]): SeriesOptionsType {
    const mediaSpendLabel =
      this.fieldService.getFieldDefinitionByLiteralId(
        FieldDefinitions.mediaSpend
      )?.label || '';

    return {
      type: 'areaspline',
      name: mediaSpendLabel,
      yAxis: 0,
      data: chartData.map((item: any) => ({
        y: item.mediaSpend,
        custom: {
          dateStart: item.dateStart,
          dateStop: item.dateStop,
          metricI: item.metricI,
          metricChange: { ...item.metricChange }
        }
      })),
      color: colors['blue-500'],
      dashStyle: 'ShortDash',
      fillColor: 'transparent',
      marker: markerStyles
    };
  }

  /**
   * Create the y-axis configuration.
   *
   * @param viewBy The view type.
   * @returns An array of y-axis options.
   */
  private createYAxis(viewBy?: ViewBy): any[] {
    const isCPO = viewBy === ViewBy.cpo;
    const spendLabel =
      this.fieldService.getFieldDefinitionByLiteralId(
        FieldDefinitions.mediaSpend
      )?.label || '';
    const yAxisLabel =
      this.fieldService.getFieldDefinitionByLiteralId(
        isCPO ? FieldDefinitions.ordersI : FieldDefinitions.salesI
      )?.label || '';

    const fieldService = this.fieldService;
    const formatService = this.formatService;

    return [
      {
        title: { text: spendLabel },
        endOnTick: false,
        // showLastLabel: true,
        padding: 10,
        gridLineWidth: 0,
        labels: {
          formatter(this: AxisLabelsFormatterContextObject): string {
            const value = this.value as number;
            const fieldDefinition = fieldService.getFieldDefinitionByLiteralId(
              FieldDefinitions.mediaSpend
            );
            return fieldDefinition
              ? formatService.formatValue(fieldDefinition, value).toString()
              : value.toString();
          },
          step: 0.5,
          style: {
            opacity: 1 // Ensure labels are fully visible
          }
        }
      },
      {
        title: { text: yAxisLabel },
        endOnTick: true,

        gridLineWidth: 0,
        labels: {
          formatter(this: AxisLabelsFormatterContextObject): string {
            const value = this.value as number;
            const fieldDefinition = fieldService.getFieldDefinitionByLiteralId(
              isCPO ? FieldDefinitions.ordersI : FieldDefinitions.salesI
            );
            return fieldDefinition
              ? formatService.formatValue(fieldDefinition, value).toString()
              : value.toString();
          },
          step: 0.5,
          style: {
            opacity: 1 // Ensure labels are fully visible
          }
        },
        opposite: true
      }
    ];
  }

  /**
   * Create the tooltip formatter function.
   *
   * @param viewBy The view type.
   * @returns The tooltip formatter callback function.
   */
  private createTooltipFormatter(
    viewBy?: ViewBy
  ): TooltipFormatterCallbackFunction {
    const isCPO = viewBy === ViewBy.cpo;
    const fieldService = this.fieldService;
    const formatterService = this.formatterService;
    const serviceInstance = this; // reference to the service instance

    return function (this: TooltipFormatterContextObject): string | false {
      if (!this.points || !this.points.length) {
        return false;
      }

      const { points } = this as TooltipFormatterContextObject;
      const dateStart = points && points[0]?.point?.options?.custom?.dateStart;
      const dateStop = points && points[0]?.point?.options?.custom?.dateStop;

      const currentAllocationLabel =
        fieldService.getFieldDefinitionByLiteralId(
          isCPO
            ? FieldDefinitions.currentOrdersI
            : FieldDefinitions.currentSalesI
        )?.label || '';

      const previousAllocationLabel =
        fieldService.getFieldDefinitionByLiteralId(
          isCPO
            ? FieldDefinitions.previousOrdersI
            : FieldDefinitions.previousSalesI
        )?.label || '';

      const mediaSpendLabel =
        fieldService.getFieldDefinitionByLiteralId(FieldDefinitions.mediaSpend)
          ?.label || '';

      const currentAllocationPoint = points?.find(
        (point) => point.series.name === currentAllocationLabel
      );
      const previousAllocationPoint = points?.find(
        (point) => point.series.name === previousAllocationLabel
      );
      const spendPoint = points?.find(
        (point) => point.series.name === mediaSpendLabel
      );

      const currentAllocationValue = currentAllocationPoint?.y || 0;
      const previousAllocationValue = previousAllocationPoint?.y || 0;
      const spend = spendPoint?.y || 0;

      const currentAllocationColor = String(
        currentAllocationPoint?.series.color
      );
      const previousAllocationColor = String(
        previousAllocationPoint?.series.color
      );
      const spendColor = String(spendPoint?.series.color);

      const metricI =
        (points && points[0]?.point?.options?.custom?.metricI) || 0;

      const changeInPercent =
        (points &&
          (points[0]?.point?.options?.custom?.metricChange
            ?.changeInPercent as number)) ||
        0;

      const isPositive =
        points &&
        points[0]?.point?.options?.custom?.metricChange?.isChangePositive;
      const isArrowUp =
        points &&
        points[0]?.point?.options?.custom?.metricChange?.changeDirection ===
          'UP';

      const tooltipData: TooltipData = {
        dateStart,
        dateStop,
        changeInPercent,
        isPositive,
        isArrowUp: Boolean(isArrowUp),
        metricI,
        currentAllocationValue,
        currentAllocationColor,
        spend,
        spendColor,
        isCPO,
        previousAllocationValue,
        previousAllocationColor
      };

      return serviceInstance.generateTooltipHTML(
        tooltipData,
        fieldService,
        formatterService
      );
    };
  }

  /**
   * Generate the HTML content for the tooltip.
   *
   * @param data The data for the tooltip.
   * @param fieldService The field service instance.
   * @param formatterService The formatter service instance.
   * @returns The HTML string for the tooltip.
   */
  private generateTooltipHTML(
    data: TooltipData,
    fieldService: FieldService,
    formatterService: FormatterService
  ): string {
    const metricLabel = this.getFieldLabel(
      data.isCPO ? FieldDefinitions.cpoI : FieldDefinitions.roasI,
      fieldService
    );
    const currentAllocationLabel = this.getFieldLabel(
      data.isCPO ? FieldDefinitions.ordersI : FieldDefinitions.salesI,
      fieldService
    );
    const previousAllocationLabel = this.getFieldLabel(
      data.isCPO ? FieldDefinitions.ordersI : FieldDefinitions.salesI,
      fieldService
    );
    const spendLabel = this.getFieldLabel(
      FieldDefinitions.mediaSpend,
      fieldService
    );

    const metricValue = this.getFormattedValue(
      data.isCPO ? FieldDefinitions.cpoI : FieldDefinitions.roasI,
      data.metricI,
      fieldService,
      formatterService
    );
    const currentAllocationValue = this.getFormattedValue(
      data.isCPO ? FieldDefinitions.ordersI : FieldDefinitions.salesI,
      data.currentAllocationValue,
      fieldService,
      formatterService
    );
    const previousAllocationValue = this.getFormattedValue(
      data.isCPO ? FieldDefinitions.ordersI : FieldDefinitions.salesI,
      data.previousAllocationValue,
      fieldService,
      formatterService
    );
    const spendValue = this.getFormattedValue(
      FieldDefinitions.mediaSpend,
      data.spend,
      fieldService,
      formatterService
    );
    const changeInPercentValue = this.getFormattedValue(
      FieldDefinitions.changeInPercent,
      data.changeInPercent,
      fieldService,
      formatterService
    );

    return this.buildTooltipHTML(
      data,
      metricLabel,
      metricValue,
      currentAllocationLabel,
      currentAllocationValue,
      spendLabel,
      spendValue,
      previousAllocationLabel,
      previousAllocationValue,
      changeInPercentValue
    );
  }

  /**
   * Get the field label based on the field definition.
   *
   * @param fieldDefinition The field definition identifier.
   * @param fieldService The field service instance.
   * @returns The field label.
   */
  private getFieldLabel(
    fieldDefinition: string,
    fieldService: FieldService
  ): string {
    return (
      fieldService.getFieldDefinitionByLiteralId(fieldDefinition)?.label || ''
    );
  }

  /**
   * Get the formatted value for a given field and value.
   *
   * @param fieldDefinition The field definition identifier.
   * @param value The value to format.
   * @param fieldService The field service instance.
   * @param formatterService The formatter service instance.
   * @returns The formatted value as a string.
   */
  private getFormattedValue(
    fieldDefinition: string,
    value: number,
    fieldService: FieldService,
    formatterService: FormatterService
  ): string {
    const field = fieldService.getFieldDefinitionByLiteralId(fieldDefinition);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return formatterService.format(field!, value);
  }

  /**
   * Build the HTML content for the tooltip.
   */
  private buildTooltipHTML(
    data: TooltipData,
    metricLabel: string,
    metricValue: string,
    currentAllocationLabel: string,
    currentAllocationValue: string,
    spendLabel: string,
    spendValue: string,
    previousAllocationLabel: string,
    previousAllocationValue: string,
    changeInPercentValue: string
  ): string {
    return `
      <div class="tooltip-body w-[21rem]">
        <span class="b2 text-gray-500 text-center block">${dayjs(
          data.dateStart
        ).format('MMMM D, YYYY')} – ${dayjs(data.dateStop).format(
          'MMMM D, YYYY'
        )}</span>
        <div class="m-divider dark my-2"></div>
        <div class="flex items-center justify-center h3 ${
          data.isPositive ? 'text-green-600' : 'text-red-400'
        }">
          <i aria-hidden="true" class="material-symbols-outlined icon-regular mr-2 ${
            data.isPositive ? 'text-green-600' : 'text-red-400'
          }">
            ${data.isArrowUp ? 'arrow_upward' : 'arrow_downward'}
          </i>
          <span>${changeInPercentValue}</span>
        </div>
        <div class="b1 text-gray-500 block text-center mb-4">Compared to our projection</div>

        <div class="flex justify-between items-center gap-2">
          <div>  
            <div class="b1 text-gray-000">
              <span style="color:${data.currentAllocationColor}">\u25CF</span> ${currentAllocationLabel}:
            </div>
            <div class="c1 ml-2 text-orange-200">&nbsp; Current</div>
          </div>
          <span class="b1 text-gray-000 self-start">${currentAllocationValue}</span>
        </div>
        <div class="flex justify-between items-center gap-2">
          <div>  
            <div class="b1 text-gray-000">
              <span style="color:${data.previousAllocationColor}">\u25CF</span> ${previousAllocationLabel}:
            </div>
            <div class="c1 ml-2 text-gray-500">&nbsp; Previous</div>
          </div>
          <span class="b1 text-gray-500 self-start">${previousAllocationValue}</span>
        </div>
        <div class="flex justify-between items-center">
          <div class="b1 text-gray-000">
            <span style="color:${data.spendColor}">\u25CF</span> ${spendLabel}:
          </div>
          <span class="b1 text-gray-000">${spendValue}</span>
        </div>
        <div class="m-divider dark my-2"></div>

        <div class="flex justify-between items-center gap-2">
          <span class="b1 text-gray-000"> ${metricLabel}:</span>
          <span class="b1 text-gray-000">${metricValue}</span>
        </div>
      </div>
    `;
  }

  /**
   * Format the chart data.
   *
   * @param data The raw data to format.
   * @param viewBy The view type.
   * @returns The formatted chart options.
   */
  private formatChartData(data: any, viewBy?: ViewBy): Options {
    const chartData = data;
    const categories = chartData.map((item: any) =>
      formatDateForChart(item.date * 1000, 'AbbrMonthYear')
    );

    const series = this.createSeries(chartData, viewBy);
    const yAxis = this.createYAxis(viewBy);
    const tooltipFormatter = this.createTooltipFormatter(viewBy);

    return {
      ...baseChartConfig,
      series: series as SeriesOptionsType[],
      xAxis: {
        lineColor: colors['gray-300'],
        categories,
        labels: {
          style: {
            opacity: 1 // Ensure labels are fully visible
          }
        }
      },
      yAxis,
      tooltip: {
        shared: true,
        useHTML: true,
        formatter: tooltipFormatter,
        outside: true,
        positioner: function (boxWidth, boxHeight, point) {
          const chart = this.chart;
          let x = point.plotX - boxWidth / 2; // Center tooltip X-axis to point
          let y = point.plotY - boxHeight; // Center tooltip's bottom edge to be above the point (w.r.t top Left as y-coord)

          // Check for clipping on the left side
          if (x < chart.plotLeft) {
            x = chart.plotLeft; // Align to the left edge
          }

          // Check for clipping on the right side
          if (x + boxWidth > chart.plotLeft + chart.plotWidth) {
            x = chart.plotLeft + chart.plotWidth - boxWidth; // Align to the right edge
          }

          // Check if too high above chart
          if (y <= 0 && Math.abs(y) / chart.plotHeight > 0.75) {
            y = point.plotY + (boxHeight * 3) / 4;
          }

          return { x, y };
        }
      },
      legend: {
        ...baseChartConfig.legend,
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'top'
      }
    };
  }

  /**
   * Formats the additional metric value for UI display.
   *
   * @param additionalMetricName - The name of the additional metric ('additionalRevenue' or 'additionalOrders').
   * @param additionalMetricValue - The value of the additional metric to be formatted.
   * @returns The formatted additional metric value.
   */
  private formatAdditionalMetricValue(
    additionalMetricName: string,
    additionalMetricValue: number
  ): string {
    const fieldDef =
      this.fieldService.getFieldDefinitionByLiteralId(additionalMetricName);

    if (fieldDef) {
      return this.formatterService.format(
        fieldDef,
        additionalMetricValue,
        true
      );
    }

    return additionalMetricValue.toString(); // Return as string if no formatting is needed
  }

  /**
   * Serializes the last index of `request[0].data` to extract `roasI` or `cpoI` from `additionalData`,
   * and includes `additionalRevenue` or `additionalOrders`, whichever is available.
   * It formats the data into `isPositive`, `isUp`, `changeInPercent`, and the additional metric.
   *
   * @param request - The API response that contains the data array.
   * @returns An object containing the formatted hero metric with `isPositive`, `isUp`, `changeInPercent`, and additional metric.
   */
  private formatHeroMetric(request: any): HeroMetric {
    // Extract the last index of request[0].data
    const data = request[0].data;
    const lastIndex = data[data.length - 1];

    // Destructure roasI, cpoI, additionalRevenue, and additionalOrders from additionalData
    const { roasI, cpoI } = lastIndex.additionalData;
    const { additionalRevenue, additionalOrders } = lastIndex;

    // Choose the primary metric to work with, giving priority to roasI
    const metric = roasI || cpoI;

    if (!metric) {
      throw new Error('No roasI or cpoI data available in the request.');
    }

    // Choose the additional metric (additionalRevenue or additionalOrders)
    const additionalMetricName =
      additionalRevenue !== undefined
        ? 'additionalRevenue'
        : 'additionalOrders';
    const additionalMetricValue = additionalRevenue || additionalOrders;

    // Format the additional metric value
    const formattedAdditionalMetric = this.formatAdditionalMetricValue(
      additionalMetricName,
      additionalMetricValue
    );

    // Format the additional metric value
    const formattedChangeInPercent = this.formatAdditionalMetricValue(
      FieldDefinitions.changeInPercent,
      metric.changeInPercent
    );

    // Serialize the data
    const formattedMetric = {
      dateStart: lastIndex.dateStart,
      dateStop: lastIndex.dateStop,
      isPositive: metric.isChangePositive,
      isUp: metric.changeDirection === 'UP',
      changeInPercent: formattedChangeInPercent,
      additionalMetric: formattedAdditionalMetric
    };

    return formattedMetric;
  }

  /**
   * Updates the hero metric signal with new serialized data.
   *
   * @param request - The API response containing the data to be serialized.
   */
  private updateHeroMetric(request: any): void {
    const data = this.formatHeroMetric(request);
    // Update the signal with the new data
    this.heroMetricSubject.next(data); // Emit new value
  }

  /**
   * Sets the chart start and stop dates based on the selected resolution.
   *
   * @param resolution - The selected resolution (e.g., 'week', 'month', 'quarter').
   * @param chartStopDate - The chart stop date to calculate the start date from.
   * @returns An object containing the start and stop dates for the chart.
   */
  public setChartDates(resolution: string, chartStopDate?: string) {
    const stopDate = chartStopDate
      ? dayjs(chartStopDate)
      : dayjs().subtract(1, 'month').endOf('month');

    let chartStartDate: string;

    switch (resolution) {
      case Resolution.weekly: {
        // Weekly resolution: 12 weeks
        chartStartDate = stopDate
          .subtract(11, Resolution.weekly) // Subtract 11 weeks because the stop date is inclusive
          .startOf(Resolution.weekly)
          .format(DayJsDateFormat.fullDate);
        break;
      }
      case Resolution.monthly:
        // Monthly resolution: 12 months
        chartStartDate = stopDate
          .subtract(11, Resolution.monthly)
          .startOf(Resolution.monthly)
          .format(DayJsDateFormat.fullDate);
        break;

      case Resolution.quarterly:
        // Quarterly resolution: 4 quarters
        chartStartDate = stopDate
          .subtract(3, Resolution.quarterly)
          .startOf(Resolution.quarterly)
          .format(DayJsDateFormat.fullDate);
        break;

      default:
        chartStartDate = '';
    }

    return {
      chartStartDate,
      chartStopDate: stopDate.format(DayJsDateFormat.fullDate)
    };
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.heroMetricSubject.complete();
  }
}
