/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { BenchmarkService } from './benchmark.service';
import { NavigationService } from '@portal/app/shared/services/navigation.service';
import { RecommendedService } from '@portal/app/dashboard/home-page/components/recommended/recommended.service';

// Types

import type { BenchmarkData } from './benchmark.types';

@Component({
  selector: 'portal-benchmark',
  templateUrl: './benchmark.component.html'
})
export class BenchmarkComponent implements OnInit, OnDestroy {
  data: BenchmarkData | null = null;
  isLoading = true;
  isRecommendedLoading = true;
  activeIndex = 0;
  medianTooltip = '<div class="b1 text-center">Benchmark</div>';
  tooltipPosition = 'bottom';

  private destroy$ = new Subject<void>();

  constructor(
    private benchmarkService: BenchmarkService,
    private navigationService: NavigationService,
    private recommendedService: RecommendedService
  ) {}

  ngOnInit(): void {
    this.benchmarkService.fetchData();
    this.subscribeToData();
  }

  onPageChange({ page }: { page: number }): void {
    this.activeIndex = page;
  }

  navigateToPage(): void {
    this.navigationService.navigationMapping('benchmarks', {
      filters: [],
      extraParams: {
        queryParams: {}
      }
    });
  }

  navigateToBenchmark(): void {
    this.navigationService.navigationMapping('benchmarksDrillDown', {
      filters: [],
      extraParams: {
        queryParams: { ...this.data?.items[this.activeIndex]?.navigationData }
      }
    });
  }

  private subscribeToData(): void {
    this.benchmarkService.data$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.data = data;
      });

    this.benchmarkService.isLoading$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoading) => {
        this.isLoading = isLoading;
      });

    // Hack to get both sidebar sections to load together as per product requirements.
    this.recommendedService.initialLoading$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoading) => {
        this.isRecommendedLoading = isLoading;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
