import { Component } from '@angular/core';
import { ApiService } from '@portal/app/shared/services/api.service';
import { ContextStore } from '@portal/app/shared/state/context.store';
import { NativeSectionsService } from '@portal/app/shared/services/native-sections.service';
import { DateTimeService } from '@portal/app/shared/services/date-time.service';
import { ViewStore } from '@portal/app/shared/state/view.store';
import { DataGridService } from '@portal/app/datagrid/services/data-grid.service';
import { DataGridStore } from '@portal/app/datagrid/store/data-grid.store';
import { ViewService } from '@portal/app/shared/services/view.service';
import { FormatterService } from '@portal/app/shared/services/formatter.service';
import { ExportTableService } from '@portal/app/shared/services/export-table.service';
import {
  AggFunction,
  BaseElement,
  ContextField,
  DataGridAvailable,
  DataGridBarVisualizer,
  DataGridConfig,
  DataGridEditable,
  DataGridElement,
  DataGridEventDrivenColumns,
  DataGridExportConfig,
  DataGridInfoWithIcon,
  DataGridSelected,
  DataResponse,
  DataRowData,
  DataSetResponse,
  DateRange,
  ElementTyping,
  ExportTableType,
  FieldDefinition,
  FieldDefinitions,
  Filter,
  FilterOption,
  FilterValue,
  TableResponse
} from '@portal/app/shared/types';
import { Table } from 'primeng/table';
import { cloneDeep, forEach, keyBy, lowerFirst, map } from 'lodash-es';
import {
  asContextField,
  sortByDisplayOrder
} from '@portal/app/dashboard/utils';
import {
  DataGridEventDriven,
  DataGridExecutableFilterEvent,
  DataGridExecutableFilterEvents,
  DataGridFilterEvents,
  DataGridNavigation
} from '@portal/app/shared/types/native/datagrid';
import { SortEvent } from 'primeng/api';
import { Subscription } from 'rxjs';
import { MultiSelect } from 'primeng/multiselect';
import {
  filterConstants,
  monthDateYearFormat
} from '@portal/app/shared/constants/constants';
import { DataGridTotalRowCustomAggregationUtil } from '@portal/app/datagrid/base-data-grid/data-grid-total-row-custom-aggregation-util';

@Component({
  selector: 'portal-base-datagrid-component',
  template: ''
})
export abstract class DatagridBaseComponent {
  public literalId = '';
  public productId = '';
  public clientId = 0;
  public groupByFieldName: string | undefined = undefined;
  protected filters: Filter[] = [];
  public dataGridConfig: DataGridConfig =
    this.dataGridStore.getDataGridTableConfig();

  protected table: TableResponse = {
    fields: [],
    exportFields: [],
    editableFields: [],
    data: [],
    footer: [],
    groupByRowFooter: {}
  };

  protected constructor(
    public readonly nativeSectionsService: NativeSectionsService,
    protected readonly dataGridService: DataGridService,
    protected readonly apiService: ApiService,
    protected readonly contextStore: ContextStore,
    protected readonly formatterService: FormatterService,
    protected readonly exportTableService: ExportTableService,
    protected readonly dataGridStore: DataGridStore,
    protected readonly viewService: ViewService,
    protected readonly dateService: DateTimeService,
    protected readonly viewStore: ViewStore
  ) {}

  // get required data formatted
  getFormattedData(
    tableRows: DataSetResponse[],
    fieldDefinitions: FieldDefinitions
  ) {
    return (this.table.data = this.formatterService.formatDataPartially(
      fieldDefinitions,
      tableRows[0]?.data as DataResponse[]
    ));
  }

  // sort table by a specific column
  sortByColumn(event: SortEvent): SortEvent {
    if (
      event != null &&
      event.data != null &&
      event.field != null &&
      event.order != null
    ) {
      event.data.sort(this.dataGridService.tableSorter.bind(null, event));
    }
    return event;
  }

  // generate a list of what columns and dimensions should be displayed
  generateFields(
    element: DataGridElement,
    metricsActive = false,
    dimensionsActive = false,
    metricsValues: string[] = [],
    dimensionsValues: string[] = [],
    eventDrivenColumns: Record<string, DataGridEventDriven[]> = {}
  ): (
    | DataGridAvailable
    | DataGridEditable
    | DataGridSelected
    | DataGridBarVisualizer
    | DataGridEventDriven
    | DataGridInfoWithIcon
    | DataGridNavigation
  )[] {
    let dimensionsToShow: (
      | DataGridAvailable
      | DataGridEditable
      | DataGridSelected
    )[] = [];
    let columnsToShow: (
      | DataGridAvailable
      | DataGridEditable
      | DataGridSelected
    )[] = [];
    let selectedColumns: DataGridSelected[] = [];
    let availableColumns: DataGridAvailable[] = [];
    dimensionsToShow = element?.selected?.filter((c) => c.type === 'DIMENSION');
    const pivotDimensionColumn =
      element.selected.filter((c) => c.type === 'PIVOT_DIMENSION') || [];
    const editablesToShow: DataGridEditable[] =
      element?.DIMENSION?.editable || [];
    const barVisualizersToShow: DataGridBarVisualizer[] =
      element?.COLUMN?.barVisualizer || [];
    const eventDrivenColumnsToShow: DataGridEventDriven[] =
      [] as DataGridEventDriven[];
    const infoWithIconColumnsToShow: DataGridInfoWithIcon[] =
      element?.COLUMN?.infoWithIcon || [];
    selectedColumns = element.selected as DataGridSelected[];
    availableColumns = element.available as DataGridAvailable[];
    if (metricsActive) {
      forEach(metricsValues, (checkedCol) => {
        forEach(selectedColumns, (selectedCol) => {
          if (selectedCol.dashboardFieldId === checkedCol) {
            columnsToShow.push(selectedCol);
          }
        });
        forEach(availableColumns, (availCol) => {
          const alreadyExists = columnsToShow.find(
            (column) => availCol.dashboardFieldId === column.dashboardFieldId
          );
          if (availCol.dashboardFieldId === checkedCol && !alreadyExists) {
            columnsToShow.push(availCol);
          }
        });
      });
    } else {
      columnsToShow = element.selected.filter((c) => c.type === 'COLUMN');
    }
    // collecting dimensions to be shown
    if (dimensionsActive) {
      dimensionsToShow = [];
      forEach(dimensionsValues, (currentDimension) => {
        forEach(selectedColumns, (selectedDimension) => {
          if (
            selectedDimension.dashboardFieldId ===
            currentDimension.toLowerCase()
          ) {
            dimensionsToShow.push(selectedDimension);
          }
        });
        forEach(availableColumns, (availableDimension) => {
          const alreadyExists = dimensionsToShow.find(
            (column) =>
              availableDimension.dashboardFieldId === column.dashboardFieldId
          );
          if (
            availableDimension.dashboardFieldId ===
              currentDimension.toLowerCase() &&
            !alreadyExists
          ) {
            dimensionsToShow.push(availableDimension);
          }
        });
      });
    } else {
      dimensionsToShow = element.selected.filter((c) => c.type === 'DIMENSION');
    }

    if (Object.keys(eventDrivenColumns).length) {
      forEach(Object.keys(eventDrivenColumns), (key) => {
        forEach(eventDrivenColumns[key], (edc) => {
          eventDrivenColumnsToShow.push(edc);
        });
      });
    }

    //adding editable fields to what to show
    // not sorting columnsToShow because they are supposed to appear in the sequence they are selected
    return [
      ...(dimensionsToShow.length
        ? dimensionsToShow.sort(sortByDisplayOrder)
        : []),
      ...(editablesToShow?.length
        ? editablesToShow.sort(sortByDisplayOrder)
        : []),
      ...(barVisualizersToShow?.length
        ? barVisualizersToShow.sort(sortByDisplayOrder)
        : []),
      ...(infoWithIconColumnsToShow?.length
        ? infoWithIconColumnsToShow.sort(sortByDisplayOrder)
        : []),
      ...(columnsToShow.length ? columnsToShow.sort(sortByDisplayOrder) : []),
      ...pivotDimensionColumn,
      ...(eventDrivenColumnsToShow.length
        ? eventDrivenColumnsToShow.sort(sortByDisplayOrder)
        : [])
    ];
  }

  createFooter(
    data: DataResponse[],
    scrollable: boolean,
    fieldDefinitions: FieldDefinitions
  ): DataResponse {
    // set the list of keys necessary
    const defaultValues = {} as DataResponse;
    if (data[0] != null) {
      for (const prop in data[0]) {
        // eslint-disable-next-line no-prototype-builtins
        if (data[0].hasOwnProperty(prop)) {
          switch (prop) {
            case 'percSalesI':
              defaultValues[prop] = '';
              break;
            case 'incDataSource':
              defaultValues[prop] = '';
              break;
            default:
              defaultValues[prop] = 0;
          }
        }
      }
    }

    return data.reduce((a: DataResponse, cur: DataResponse, idx: number) => {
      const deepCopy = Object.assign({}, a);
      const lastItem = idx + 1 === data.length;
      for (const [key, value] of Object.entries(cur)) {
        const aggFn =
          fieldDefinitions[key] != null
            ? fieldDefinitions[key]?.aggregateFunction
            : AggFunction.none;
        const newVal = a[key];
        switch (aggFn) {
          case AggFunction.sum:
            if (typeof newVal === 'number') {
              deepCopy[key] = (newVal as number) + (value as number);
            } else {
              deepCopy[key] = value as number;
            }
            break;
          case AggFunction.average:
            if (typeof newVal === 'number') {
              deepCopy[key] = (newVal as number) + (value as number);
            } else {
              deepCopy[key] = value as number;
            }
            if (lastItem) {
              deepCopy[key] = ((deepCopy[key] as number) / (idx + 1)).toFixed(
                4
              );
            }
            break;
          case AggFunction.none:
            deepCopy[key] = (newVal === 0 ? null : newVal) as DataRowData;
            break;
          default:
            deepCopy[key] = newVal as DataRowData;
        }
      }
      //TO-DO Move Summary Logic to API
      return deepCopy;
    }, defaultValues);
  }

  // generate ids of filters that are breakdown persistent
  generatePersistentFilterIds(element: DataGridElement) {
    const persistentFilterIds: string[] = [];
    forEach(element.CONTROL?.filterControl, (fc) => {
      if (fc.sourceLayoutLiteralId === 'breakdownPersistent') {
        persistentFilterIds.push(fc.dashboardFilterId as string);
      }
    });
    forEach(element.CONTROL?.metricControl, (mc) => {
      if (mc.sourceLayoutLiteralId === 'breakdownPersistent') {
        persistentFilterIds.push(mc.dashboardFilterId as string);
      }
    });
    return persistentFilterIds;
  }

  // generate a list of filters that are supposed to be breakdown persistent
  generateFiltersToPersist(
    dataGridSpecificFilters: Filter[],
    idsOfPersistentFilters: string[]
  ): Filter[] {
    return dataGridSpecificFilters.filter((f) => {
      if (idsOfPersistentFilters.includes(f.literalId)) {
        return f;
      }
    });
  }

  handleCustomAggregation(
    finalSummary: DataResponse,
    fieldDefinitions: FieldDefinitions,
    dataPoints: DataResponse | undefined
  ): DataResponse {
    try {
      for (const [key] of Object.entries(finalSummary)) {
        const aggFn =
          fieldDefinitions[key] != null
            ? fieldDefinitions[key]?.aggregateFunction
            : AggFunction.none;
        if (aggFn && aggFn === AggFunction.custom) {
          const totalValue =
            DataGridTotalRowCustomAggregationUtil.getCalculationForCustomTotal(
              key,
              finalSummary,
              dataPoints
            );
          if (totalValue) {
            finalSummary[key] = this.formatterService.convertPartialItem(
              fieldDefinitions[key] as FieldDefinition,
              totalValue
            );
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
    return finalSummary;
  }

  // make sure that in multiselect dropdowns at least one value stays selected
  handleMinimumSelection(multiSelect: MultiSelect): void {
    if (
      multiSelect.value.length ===
      filterConstants.minimumFilterValueSelectedCount
    ) {
      // disable the last one...
      const lastItemValue = multiSelect.value[0];
      (multiSelect.options || []).forEach((o: FilterOption) => {
        if (o.value === lastItemValue) {
          return Object.assign({ disabled: true }, o);
        } else {
          return o;
        }
      });
    } else {
      // remove any disabled statuses (if they exist)
      const disabledOpts = (multiSelect.options || []).filter(
        (o: FilterOption) => o.disabled != null
      );
      if (disabledOpts.length > 0) {
        for (const opt of disabledOpts) {
          delete opt.disabled;
        }
      }
    }
  }

  //generate a list of filters to be sent with the api call for data
  generateApiFilters(dataGridFilters: Filter[]): Filter[] {
    return map(
      keyBy(
        ([] as Filter[]).concat(this.filters, dataGridFilters),
        'literalId'
      ),
      (filter) => filter
    );
  }

  // setup metrics filter for your datagrid
  generateMetricsOptionsAndValues(
    element: DataGridElement,
    fieldDefinitions: FieldDefinitions,
    currentMetricValues: string[]
  ): [
    {
      label: string;
      value: string;
      disabled: boolean;
    }[],
    string[]
  ] {
    let metricsValues: string[] = [];
    const optionsToBeSelected = cloneDeep(currentMetricValues);
    const metricsOptions: {
      label: string;
      value: string;
      disabled: boolean;
    }[] = [];
    // GET SELECTED COLUMNS and create CHECKED COLUMNS
    forEach(element.COLUMN.selected.sort(sortByDisplayOrder), (column) => {
      forEach(fieldDefinitions, (field) => {
        if (field.literalId === column.dashboardFieldId) {
          if (currentMetricValues.length) {
            const inMetricValues = currentMetricValues?.find(
              (metric) => column.dashboardFieldId === lowerFirst(metric)
            );
            if (inMetricValues) {
              optionsToBeSelected.filter((o) => o !== inMetricValues);
              metricsValues.push(column.dashboardFieldId);
              metricsOptions.push({
                label: field.label,
                value: column.dashboardFieldId,
                disabled: column.type !== ElementTyping.column
              });
            }
          } else {
            metricsValues.push(column.dashboardFieldId);
            metricsOptions.push({
              label: field.label,
              value: column.dashboardFieldId,
              disabled: column.type !== ElementTyping.column
            });
          }
          //trying to derive as many metric options from selected columns as possible since they have display order
        }
      });
    });
    if (optionsToBeSelected) {
      forEach(element.COLUMN.available, (column) => {
        const inMetricValues = optionsToBeSelected?.find(
          (metric) => column.dashboardFieldId === lowerFirst(metric)
        );
        if (inMetricValues) {
          metricsValues.push(column.dashboardFieldId);
        }
      });
    }
    metricsValues = [...new Set(metricsValues)];
    //GET AVAILABLE COLUMNS
    // collecting the rest of the metric options that were not present in selected options
    forEach(element.COLUMN.available, (column) => {
      forEach(fieldDefinitions, (field) => {
        const isAlreadyCreated = metricsOptions.find(
          (option) => option.value === column.dashboardFieldId
        );
        if (field.literalId === column.dashboardFieldId && !isAlreadyCreated) {
          metricsOptions.push({
            label: field.label,
            value: column.dashboardFieldId,
            disabled: column.type !== ElementTyping.column
          });
        }
      });
    });
    return [metricsOptions, metricsValues];
  }

  // setup dimensions filter for your datagrid
  generateDimensionsOptionsAndValues(
    element: DataGridElement,
    fieldDefinitions: FieldDefinitions
  ): [
    {
      label: string | null;
      value: string;
    }[],
    string[]
  ] {
    let dimensionsOptions: {
      label: string | null;
      value: string;
    }[] = [];
    const currentDimensionValues: string[] = [];

    forEach(
      element?.DIMENSION?.selected?.sort(sortByDisplayOrder), //TODO: PMDEV-3695: Investigate how element.DIMENSION gets populated
      (availableDimension) => {
        forEach(fieldDefinitions, (field) => {
          if (availableDimension.dashboardFieldId === field.literalId) {
            dimensionsOptions.push({
              label: field.label,
              value: availableDimension.dashboardFieldId
            });
          }
        });
      }
    );
    forEach(element?.DIMENSION?.available, (availableDimension) => {
      forEach(fieldDefinitions, (field) => {
        const exists = dimensionsOptions.find(
          (opt) => opt.value === availableDimension.dashboardFieldId
        );
        if (
          availableDimension.dashboardFieldId === field.literalId &&
          !exists
        ) {
          dimensionsOptions.push({
            label: field.label,
            value: availableDimension.dashboardFieldId
          });
        }
      });
    });
    dimensionsOptions = [...new Set(dimensionsOptions)];
    forEach(element?.DIMENSION?.selected, (selectedDimension) => {
      currentDimensionValues.push(selectedDimension.dashboardFieldId);
    });
    return [dimensionsOptions, currentDimensionValues];
  }

  // row replacement function for rowDataChange
  replaceRow(
    newRow: DataResponse,
    table: TableResponse,
    fieldDefinitions: FieldDefinitions
  ): DataResponse[] {
    const dimensionsDashboardId: string[] = [];
    forEach(this.table.fields, (field) => {
      if (field.type === 'DIMENSION') {
        dimensionsDashboardId.push(field.dashboardFieldId);
      }
    });

    // finding the position of the row to be replaced and replace it with newRow
    forEach(this.table.data, (row, rowPosition) => {
      const replaceThisRow: boolean[] = [];
      forEach(dimensionsDashboardId, (dashboardId: string) => {
        replaceThisRow.push(row[dashboardId] === newRow[dashboardId]);
      });
      const result = [...new Set(replaceThisRow)];
      if (result.length === 1 && result[0]) {
        this.table.data.splice(
          rowPosition,
          1,
          this.formatterService.formatDataResponsePartially(
            fieldDefinitions,
            newRow
          )
        );
      }
    });
    return table.data;
  }

  prepareEditableFields(
    table: TableResponse,
    filters: Filter[]
  ): ContextField[] {
    const editableFields: ContextField[] = [];
    table.fields.map((field) => {
      const filter = filters.find(
        (f) => f.literalId === field.dashboardFilterId
      ) as Filter;
      if (field.subType === 'editable' && filter) {
        editableFields.push(asContextField(filter));
      } else {
        editableFields.push(asContextField({} as Filter));
      }
    });
    return editableFields;
  }

  // generate a particular hash using breakdown and filter values
  selectedFilterValuesHash(breakdownFilter: Filter, filters: Filter[]): string {
    const selectedBreakdown: FilterValue = breakdownFilter?.value || 'null';
    if (filters.length) {
      const filterFieldValues = filters
        .map((field) => {
          if (field.type === 'dateRange') {
            const dateRangeValue = field.value as DateRange;
            return `${dateRangeValue.startDate}:${dateRangeValue.endDate}`;
          }
          return field.value || 'null';
        })
        .join(':');
      return selectedBreakdown + filterFieldValues;
    } else {
      return selectedBreakdown + '';
    }
  }

  getUpdatedFieldDefinition(
    tableResponse: TableResponse,
    fieldDefinitions: FieldDefinitions,
    element: DataGridElement
  ): FieldDefinitions {
    const customFieldDefinition = cloneDeep(fieldDefinitions);
    const table = cloneDeep(tableResponse);
    table.fields = element.available;
    // Editing the Field Definitions to customize the header to append the column group label with the column labels
    this.table.fields.forEach((field) => {
      if (field.label) {
        (
          customFieldDefinition[field.dashboardFieldId] as FieldDefinition
        ).label = `${field.label} ${
          customFieldDefinition[field.dashboardFieldId]?.label
        }`;
      }
    });
    return customFieldDefinition;
  }

  // since we  are using multiple columns for our interpretation of filter events
  // we need to collect that data, rename values based on our interpretations
  // and store then into a convinient-to-use format for filter events execution
  collectSanitizeAndValidateFilterEventsConfig(
    elements: BaseElement[] | undefined,
    fieldDefinitions: FieldDefinitions
  ): DataGridExecutableFilterEvents {
    const dataGridFilterEventsContainer: DataGridExecutableFilterEvents = {};
    const filterEvents: Record<string, DataGridFilterEvents> = {};

    // from elements collect all the values with layout type 'Filter Events'
    forEach(elements, (element) => {
      if (element.layoutType === ('FILTER_EVENTS' as string)) {
        filterEvents[element.literalId] =
          element.FILTER_EVENT as DataGridFilterEvents;
      }
    });

    // assigning values to dataGridFilterEventsContainer that we created above
    // looping through values of filterevents
    forEach(Object.keys(filterEvents), (key, index) => {
      if (
        !((key as string) in dataGridFilterEventsContainer) &&
        key &&
        index === 0
      ) {
        dataGridFilterEventsContainer[key] = {};
      }
      // looping through sub values of filter events
      forEach(
        Object.keys(filterEvents[key] as DataGridFilterEvents),
        (event, eventsIndex) => {
          if (
            !(
              (event as string) in
              (dataGridFilterEventsContainer[key] as Record<
                string,
                DataGridExecutableFilterEvent[]
              >)
            ) &&
            key &&
            eventsIndex === 0
          ) {
            (
              dataGridFilterEventsContainer[key] as Record<
                string,
                DataGridExecutableFilterEvent[]
              >
            )[event] = [];
          }
          // filterEventValueObj is a collection of  executable interpreted filter events that is assigned to a property of dataGridFilterEventsContainer at once
          const filterEventValueObj: DataGridExecutableFilterEvent[] = [];
          // running checks if each value in the sub value contains all required values for a qualified filter event
          forEach(
            (filterEvents[key] as DataGridFilterEvents)[event],
            (eventValue) => {
              if (
                eventValue.dashboardFieldId &&
                eventValue.subType &&
                eventValue.dashboardFilterId &&
                Object.keys(fieldDefinitions).includes(
                  eventValue.dashboardFilterId
                )
              ) {
                // out of all the values collected from a single layout attribute row, we need to interpret the values the way we want
                // eg. layoutType => type of event | dashboardFieldId => subtype of event etc.
                const executableFilterEvent: DataGridExecutableFilterEvent = {
                  subType: eventValue.dashboardFieldId,
                  targetFilterId: eventValue.dashboardLayoutLiteralId as string,
                  targetValue: eventValue.subType,
                  columnDashboardFieldId: eventValue.dashboardFilterId
                };

                filterEventValueObj.push(executableFilterEvent);
              }
            }
          );
          // assign collected(interpreted) filterevents to particular property of dataGridFilterEventsContainer
          (
            dataGridFilterEventsContainer[key] as Record<
              string,
              DataGridExecutableFilterEvent[]
            >
          )[event] = filterEventValueObj;
        }
      );
    });
    return dataGridFilterEventsContainer as unknown as DataGridExecutableFilterEvents;
  }

  //  event driven columns have a layoutType of EVENT_DRIVEN and sourceLayout type as the id of filter that drives them
  // this data needs to be collected, renamed and sorted so its convinient to read and use at times of executions
  collectSanitizeAndValidateEventDrivenColumns(
    eventDrivenColumnsConfig: DataGridEventDrivenColumns,
    fieldDefinitions: FieldDefinitions
  ): DataGridEventDrivenColumns {
    const eventDrivenColumns: DataGridEventDrivenColumns = {};
    const eventDrivenCols: DataGridEventDrivenColumns =
      eventDrivenColumnsConfig as DataGridEventDrivenColumns;
    forEach(Object.keys(eventDrivenCols), (subType) => {
      if (eventDrivenCols) {
        forEach(
          eventDrivenCols[subType] as DataGridEventDriven[],
          (eventDrivenColumn, index) => {
            const filterId = eventDrivenColumn.sourceLayoutLiteralId;
            if (
              !((filterId as string) in eventDrivenColumns) &&
              filterId &&
              index === 0
            ) {
              eventDrivenColumns[filterId] = [];
            }
            if (filterId && Object.keys(fieldDefinitions).includes(filterId)) {
              eventDrivenColumns[filterId]?.push(eventDrivenColumn);
            } else {
              console.error(
                'one of the event driven column is either missing sourceLayoutLiteralId or there is not field definition doesnt exist for the id mentioned in sourceLayoutLiteralId'
              );
            }
          }
        );
      }
    });
    return eventDrivenColumns;
  }

  isTableEmpty(tableData: DataSetResponse[]): boolean {
    return Boolean(
      tableData &&
        tableData.length > 0 &&
        tableData[0]?.data &&
        tableData[0]?.data.length === 0
    );
  }

  exportWithFormat(
    format: ExportTableType,
    config: DataGridExportConfig = { tableName: '' },
    dt: Table,
    fieldDefinitions: FieldDefinitions
  ): void {
    const fileName = `${this.literalId}-${this.clientId}-${this.dateService
      .today()
      .format(monthDateYearFormat)}`;
    this.exportTableService.exportTable(
      config.fileName ? `${config.fileName}-${fileName}` : fileName,
      config.format || format,
      config.fieldDefinitions || fieldDefinitions,
      {
        ...this.table,
        data: dt.filteredValue || this.table.data
      },
      false,
      false,
      config.showFooter ?? this.dataGridConfig.exportShowFooter,
      this.groupByFieldName
    );
  }

  unsubscribeToAll(subscriptions: Subscription) {
    subscriptions.unsubscribe();
  }
}
