import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ElementGroup,
  FieldDefinitions,
  Filter,
  DataResponse
} from '@portal/app/shared/types';
import { Required } from '@portal/app/shared/decorators/required.decorator';
import { NativeSectionsService } from '@portal/app/shared/services/native-sections.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'portal-custom-card',
  templateUrl: './custom-card.component.html',
  styleUrls: ['./custom-card.component.scss']
})
export class CustomCardComponent implements OnInit, OnDestroy {
  @Input() @Required widgetLayout!: ElementGroup;
  @Input() @Required fieldDefinitions!: FieldDefinitions;
  @Input() @Required filterSet: Filter[] = [];
  @Input() data?: DataResponse;
  public showChild = false;
  public optimizeStrengthOptions = [1, 2, 3, 4];

  private readonly subs: Subscription = new Subscription();

  constructor(
    public readonly nativeSectionsService: NativeSectionsService,
    private readonly route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.subs.add(
      this.route.queryParams.subscribe((params: Params) => {
        this.showChild = params.showChild
          ? JSON.parse(params.showChild)
          : false;
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
