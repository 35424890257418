// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import packageJson from '../../../../package.json';
import { AngularEnvironment, SSOProvider } from '@portal/app/shared/types';

const { version } = packageJson;

export const environment: AngularEnvironment = {
  production: true,
  stage: false,
  innovation: false,
  clientVersion: version,
  apiV1: 'https://api-v2.measured.com/api/v1',
  apiV2: 'https://api-v2.measured.com/api/v2',
  apiDomain: `https://api-v2.measured.com`,
  ssoProvider: SSOProvider.okta,
  oktaConfig: {
    issuer: 'https://login.measured.com/oauth2/default',
    clientId: '0oa2k7o4st46YlODr697',
    redirectUri: `${window.location.origin}/login/callback`,
    responseMode: 'query',
    learnUponAppId: ''
  },
  awsConfig: {
    issuer: 'https://measured-sso.auth.us-east-1.amazoncognito.com',
    clientId: 'iaecsgup8poq74a0srfe7u05j',
    redirectUri: `${window.location.origin}/login`
  },
  gtmId: 'GTM-MXF882NQ'
};
