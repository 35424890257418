import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IReportingFeedsApiOperation } from '../models/IReportingFeedsApiOperation';
import { Utility } from '../helpers/utility';
import { ICreateRollUpSpec } from '../models/ICreateRollUpSpec';
import { ICreateVendorETLSpec } from '../models/ICreateVendorETLSpec';
import { ICreatePreRefresh } from '../models/ICreatePreRefresh';
import { ICreateCatalogueMailFile } from '@libs/apis';

const promiseFrom = lastValueFrom;

export class ReportingFeedsApis implements IReportingFeedsApiOperation {
  private constructor(
    private readonly httpClient: HttpClient,
    private readonly apiV1: string,
    private readonly apiV2: string
  ) {}

  public static reportingFeedsApiOperation(
    httpClient: HttpClient,
    apiV1: string,
    apiV2: string
  ): IReportingFeedsApiOperation {
    return new ReportingFeedsApis(httpClient, apiV1, apiV2);
  }

  public getTransactionDataList(
    spec: boolean,
    clientId?: number,
    includeConversionType?: boolean
  ): Promise<any> {
    let url = Utility.buildURL({
      url: `${this.apiV1}/transaction-data`,
      queryParameters: { spec }
    });
    if (!spec) {
      url = Utility.buildURL({
        url,
        queryParameters: { clientId, includeConversionType }
      });
    }
    return promiseFrom(this.httpClient.get(url));
  }

  public async createPostETLDataSpec(
    postData: any,
    isSpec: boolean
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/transaction-data`,
      queryParameters: { isSpec }
    });
    return promiseFrom(this.httpClient.post(url, postData));
  }

  public async updateETLSpecRecords(
    id: number,
    postData: any,
    spec: boolean
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/transaction-data/:id`,
      pathVariables: { id },
      queryParameters: { spec }
    });
    return promiseFrom(this.httpClient.put(url, postData));
  }

  public async getSelectedTransactionData(
    id: number,
    includeConversionType: boolean
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/transaction-data/:id`,
      pathVariables: { id },
      queryParameters: { includeConversionType }
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async getTransactionDataRollupList(
    spec: boolean,
    clientId?: number,
    includeConversionType = false
  ): Promise<any> {
    let url = Utility.buildURL({
      url: `${this.apiV1}/transaction-data-rollup`,
      queryParameters: { spec }
    });
    if (!spec) {
      url = Utility.buildURL({
        url,
        queryParameters: { clientId, includeConversionType }
      });
    }
    return promiseFrom(this.httpClient.get(url));
  }

  public async createRollUpSpecData(
    postData: ICreateRollUpSpec,
    isSpec: boolean,
    specName?: string
  ): Promise<any> {
    let url = Utility.buildURL({
      url: `${this.apiV1}/transaction-data-rollup`,
      queryParameters: { isSpec }
    });
    if (isSpec) {
      url = Utility.buildURL({
        url,
        queryParameters: { specName }
      });
    }
    return promiseFrom(this.httpClient.post(url, postData));
  }

  public async updateRollUpSpecRecords(
    id: number,
    postData: ICreateRollUpSpec,
    isSpec: boolean
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/transaction-data-rollup/:id`,
      pathVariables: { id },
      queryParameters: { isSpec }
    });
    return promiseFrom(this.httpClient.put(url, postData));
  }

  public async syntaxChecker(data: any): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/ccmaf-cc-spec/syntax-checker`
    });
    return promiseFrom(this.httpClient.post(url, data));
  }

  public async syntaxCheckerV2(data: any): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/syntax-checker`
    });
    return promiseFrom(this.httpClient.post(url, data));
  }

  public async getCCSpecs(
    spec: boolean,
    clientId?: number,
    includeConversionType?: boolean
  ): Promise<any> {
    let url = Utility.buildURL({
      url: `${this.apiV1}/ccmaf-cc-spec`,
      queryParameters: { spec }
    });
    if (!spec) {
      url = Utility.buildURL({
        url,
        queryParameters: { clientId, includeConversionType }
      });
    }
    return promiseFrom(this.httpClient.get(url));
  }

  public async getCCSpecById(
    id: number,
    includeConversionType: boolean
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/ccmaf-cc-spec/:id`,
      pathVariables: { id },
      queryParameters: { includeConversionType }
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async createVendorETLDataSpec(
    postData: ICreateVendorETLSpec | ICreateVendorETLSpec[],
    isSpec: boolean,
    specName?: string
  ): Promise<any> {
    let url = Utility.buildURL({
      url: `${this.apiV1}/ccmaf-cc-spec`,
      queryParameters: { isSpec }
    });
    if (isSpec) {
      url = Utility.buildURL({
        url,
        queryParameters: { specName }
      });
    }
    return promiseFrom(this.httpClient.post(url, postData));
  }

  public async updateVendorETLSpecRecords(
    id: number,
    postData: ICreateVendorETLSpec,
    spec: boolean
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/ccmaf-cc-spec/:id`,
      pathVariables: { id },
      queryParameters: { spec }
    });
    return promiseFrom(this.httpClient.put(url, postData));
  }

  public async validateCCSpec(postData: ICreateVendorETLSpec): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/ccmaf-cc-spec/validateSpec`
    });
    return promiseFrom(this.httpClient.post(url, postData));
  }

  public async getETLColumns(clientId: number, data: any): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/ccmaf-cc-spec/:clientId/get-schema`,
      pathVariables: { clientId }
    });
    return promiseFrom(this.httpClient.post(url, data));
  }

  public async getPreRefreshData(clientId: number): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/ccmaf-cc-preliminary-steps`,
      queryParameters: { clientId }
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async createPreRefresh(data: ICreatePreRefresh): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/ccmaf-cc-preliminary-steps`
    });
    return promiseFrom(this.httpClient.post(url, data));
  }

  public async updatePreRefreshList(
    id: number,
    data: ICreatePreRefresh
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/ccmaf-cc-preliminary-steps/:id`,
      pathVariables: { id }
    });
    return promiseFrom(this.httpClient.put(url, data));
  }

  public async selectedPreRefreshRecord(id: number): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/ccmaf-cc-preliminary-steps/:id`,
      pathVariables: { id }
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async getCatalogueMailFileList(
    spec: boolean,
    clientId?: number
  ): Promise<any> {
    let url = Utility.buildURL({
      url: `${this.apiV1}/catalog-spec`,
      queryParameters: { spec }
    });
    if (!spec) {
      url = Utility.buildURL({
        url,
        queryParameters: { clientId }
      });
    }
    return promiseFrom(this.httpClient.get(url));
  }

  public async createCatalogueMaileFile(
    postData: ICreateCatalogueMailFile,
    isSpec: boolean,
    specName?: string
  ): Promise<any> {
    let url = Utility.buildURL({
      url: `${this.apiV1}/catalog-spec`,
      queryParameters: { isSpec }
    });
    if (isSpec) {
      url = Utility.buildURL({
        url,
        queryParameters: { specName }
      });
    }
    return promiseFrom(this.httpClient.post(url, postData));
  }

  public async updateCatalogueETLSpecRecords(
    id: number,
    postData: ICreateCatalogueMailFile,
    spec: boolean
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/catalog-spec/:id`,
      pathVariables: { id },
      queryParameters: { spec }
    });
    return promiseFrom(this.httpClient.put(url, postData));
  }

  public async getSelectedCatalogueRecords(id: number): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/catalog-spec/:id`,
      pathVariables: { id }
    });
    return promiseFrom(this.httpClient.get(url));
  }
}
