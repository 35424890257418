import { Component, Input, OnInit } from '@angular/core';
import type { ProgressBarComparisonOptions } from './m-progress-bar-comparison.component.types';
import type { TooltipOptions } from 'primeng/api';

@Component({
  selector: 'm-progress-bar-comparison',
  templateUrl: './m-progress-bar-comparison.component.html',
  styleUrls: ['./m-progress-bar-comparison.component.scss']
})
export class MProgressBarComparisonComponent implements OnInit {
  @Input() options!: ProgressBarComparisonOptions;
  @Input() tooltipOptions: TooltipOptions = {};

  prevPeriodPercentage!: number;
  curPeriodPercentage!: number;

  ngOnInit(): void {
    this.calculatePercentages();
    this.tooltipOptions = {
      positionTop: -10
    };
  }

  private calculatePercentages(): void {
    const maxVal = Math.max(
      this.options.prevPeriodValue,
      this.options.curPeriodValue
    );
    this.prevPeriodPercentage = (this.options.prevPeriodValue / maxVal) * 100;
    this.curPeriodPercentage = (this.options.curPeriodValue / maxVal) * 100;
  }
}
