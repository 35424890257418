import { createReducer, on } from '@ngrx/store';
import { DashboardActions } from '@portal/app/store/dashboard/actions';
import { DashboardState } from '@portal/app/store/app.state';
import { cloneDeep, omit } from 'lodash-es';

export const initialState: DashboardState = {
  displayName: '',
  dashboardPersistence: '',
  dimensionFormat: null,
  dynamicFilters: null,
  filters: [],
  fieldDefinitions: {},
  layout: [],
  copiedFilters: [],
  isMultiView: false,
  updatedFilters: [],
  dashboardLiteralId: '',
  faqLink: null,
  rollupConversionTypes: []
};

export const dashboardReducer = createReducer(
  initialState,
  on(DashboardActions.setDashboard, (state, action: DashboardState) => ({
    ...state,
    ...omit(action, 'type'),
    copiedFilters: action.filters ? cloneDeep(action.filters) : [],
    layout: action.layout ? cloneDeep(action.layout) : []
  })),
  on(DashboardActions.setCopiedFilters, (state, { copiedFilters }) => ({
    ...state,
    copiedFilters
  })),
  on(DashboardActions.setUpdatedFilters, (state, { updatedFilters }) => ({
    ...state,
    updatedFilters
  })),
  on(DashboardActions.clearUpdatedFilters, (state) => ({
    ...state,
    updatedFilters: []
  })),
  on(DashboardActions.setDimensionFormat, (state, { dimensionFormat }) => ({
    ...state,
    dimensionFormat
  })),
  on(DashboardActions.updateLayout, (state, { layout }) => ({
    ...state,
    layout: layout ? cloneDeep(layout) : []
  }))
);
