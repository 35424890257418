import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  UrlTree,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { AuthService } from '@portal/app/shared/services/auth.service';
import { BrandRowsService } from '@portal/app/shared/services/brand-rows.service';
import { StorageService } from '@portal/app/shared/services/storage.service';
import { SelectionService } from '@portal/app/shared/services/selection.service';
import { UserModel } from '@portal/app/shared/models/userModel';
import { Observable, take, throwError } from 'rxjs';
import { BrandRow } from '@portal/app/shared/types';
import { defaultDashboardLiteralId } from '@portal/app/shared/constants/constants';

@Injectable({ providedIn: 'root' })
export class AuthProductGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly store: StorageService,
    private readonly authService: AuthService,
    private readonly brandRowsService: BrandRowsService,
    private readonly selectionService: SelectionService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<UrlTree | boolean> | boolean | UrlTree {
    const user = this.authService.getUser();
    if (user != null) {
      const brandSlug = route.params.brandSlug;
      return this.canActivateBrand(user, brandSlug);
    } else {
      // assume they are not logged in if the user does not exist,
      //  Send to login and clear stored
      //  user identifiable information using authService.logout()
      this.authService.logout({ queryParams: { returnUrl: state.url } });

      return false;
    }
  }

  canActivateBrand(
    user: UserModel,
    brandSlug: string
  ): Observable<UrlTree | boolean> | UrlTree | boolean {
    // Does the user have access to multiple clients/brands?
    if (user.hasAccessToMultipleClientOrBrandIds(this.selectionService)) {
      // do they have a client/brand selected?
      if (this.selectionService.hasSelection()) {
        // Is the client/brand selected the same as the one
        //  the user is navigating to? Allow if yes, navigate
        //  to fallback if no.
        if (brandSlug === this.selectionService.buildSelectionSlug()) {
          return true;
        } else {
          return this.canActivateUnselectedBrand(brandSlug);
        }
      } else {
        return this.canActivateUnselectedBrand(brandSlug);
      }
    } else {
      // show the product page for the client/brand for which the user has access
      return true;
    }
  }

  canActivateUnselectedBrand(
    brandSlug: string
  ): Observable<UrlTree | boolean> | UrlTree | boolean {
    const fallbackRoute = '/a/select-brand';
    // They have access to multiple Brands, and are navigating
    //  (potentially) to a brand that isn't their selection
    // Check if the brand they are routing to exists
    // ugh, use brandRows
    return new Observable<UrlTree | boolean>((observer) => {
      this.brandRowsService
        .buildBrandRows()
        .pipe(take(1))
        .subscribe({
          next: (rows) => {
            observer.next(this.hasBrandRow(rows, brandSlug, fallbackRoute));
            observer.complete();
          },
          error: (e) => {
            observer.next(false);
            throwError(() => new Error(e));
          }
        });
    });
  }

  hasBrandRow(
    brandRows: BrandRow[],
    brandSlug: string,
    fallbackRoute: string
  ): UrlTree | boolean {
    for (const row of brandRows) {
      if (brandSlug === this.selectionService.buildSelectionSlug(row)) {
        // Set the new found brand to the selection
        this.selectionService.setSelection(row.client, row.brand);
        return true;
      }
    }
    // If brandRows are null or the user doesn't have access to the brandSlug,
    //  navigate to brands (you must select a client/brand first)
    // as a fallback, set the selection as the first brand in their list
    if (brandRows != null && brandRows[0] != null) {
      const firstBrandRow = brandRows[0];
      this.selectionService.setSelection(
        firstBrandRow.client,
        firstBrandRow.brand
      );
      return this.router.parseUrl(
        `${this.selectionService.buildSelectionSlug(
          brandRows[0]
        )}/products/portfolio/${defaultDashboardLiteralId}`
      );
    }
    return this.router.parseUrl(fallbackRoute);
  }
}
