import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import {
  AssociatedFilterValue,
  DataResponse,
  DataRowData,
  ElementGroup,
  FieldDefinitions,
  Filter,
  FilterValue
} from '@portal/app/shared/types';
import { ContextStore } from '@portal/app/shared/state/context.store';
import { Required } from '@portal/app/shared/decorators/required.decorator';
import { AssociatedFilterService } from '@portal/app/shared/services/associated-filter.service';
import { cloneDeep } from 'lodash-es';
import { FiltersService } from '@portal/app/shared/services/filters.service';
import { FormatterService } from '@portal/app/shared/services/formatter.service';
import { take } from 'rxjs';
import { MultiViewStore } from '@portal/app/shared/state/multi-view.store';

@Component({
  selector: 'portal-overlay-panel',
  styleUrls: ['./overlay-panel.component.scss'],
  templateUrl: './overlay-panel.component.html'
})
export class OverlayPanelComponent implements OnInit {
  @ViewChild('oPanel', { static: true }) overlayPanel: OverlayPanel | undefined;
  @Input() @Required widgetLayout!: ElementGroup;
  @Input() @Required fieldDefinitions!: FieldDefinitions;
  @Input() @Required filterSet: Filter[] = [];
  @Input() @Required dashboardId: string | undefined;
  @Input() name = '';
  @Input() layoutType: string | undefined;
  @Input() data: DataResponse = {};
  valueChangedFilters: Filter[] = [];
  public filters: Filter[] = [];
  public showChild = false;

  constructor(
    public readonly assocFilterService: AssociatedFilterService,
    private readonly contextStore: ContextStore,
    private filtersService: FiltersService,
    private readonly multiViewStore: MultiViewStore,
    public readonly formatterService: FormatterService
  ) {}

  ngOnInit(): void {
    this.contextStore.filterContext.subscribe({
      next: (value) => {
        this.filters = cloneDeep(value);
        this.filters.forEach((filter) => {
          if (Object.keys(this.data).indexOf(filter.literalId) > -1) {
            if (this.data[filter.literalId] !== null) {
              filter.value = this.data[filter.literalId] as DataRowData;
            }
          }
        });
        this.showChild = this.multiViewStore.showChild;
        this.removeOptimizeCappedFromAllocationSettings(this.filters);
      }
    });
  }

  public removeOptimizeCappedFromAllocationSettings(filters: Filter[]): void {
    const allocationSetting = filters.filter(
      (f) => f.literalId === 'allocationSetting'
    );
    const allocationSettingOptions = allocationSetting[0]?.options;
    const allocationSettingAssociatedFilters =
      allocationSetting[0]?.associatedFilters;
    if (
      allocationSetting &&
      this.showChild &&
      allocationSettingOptions &&
      allocationSettingAssociatedFilters
    ) {
      const filteredOptions = allocationSettingOptions.filter(
        (el) => el !== 'Optimized (Capped)'
      );
      if (filteredOptions && allocationSetting[0]) {
        allocationSetting[0].options = allocationSettingOptions?.filter(
          (el) => el !== 'Optimized (Capped)'
        );
      }
      if ('Optimized (Capped)' in allocationSettingAssociatedFilters) {
        delete allocationSettingAssociatedFilters['Optimized (Capped)'];
      }
    }
  }

  public show($event: unknown) {
    this.overlayPanel?.toggle($event);
  }

  onFilterValueChange(e: Filter) {
    if (this.layoutType === 'FILTER_SET_RADIO_POPUP') {
      this.filters.forEach((filter) => {
        if (filter.literalId === e.literalId) {
          filter.value = e.value;
        }
      });
    } else {
      if (this.valueChangedFilters.length === 0) {
        this.valueChangedFilters.push(e);
      } else if (
        this.valueChangedFilters[this.valueChangedFilters.length - 1]
          ?.literalId === e.literalId
      ) {
        (
          this.valueChangedFilters[
            this.valueChangedFilters.length - 1
          ] as Filter
        ).value = e.value;
      } else {
        this.valueChangedFilters.push(e);
      }
    }
  }

  asString(value: FilterValue): string {
    return String(value);
  }

  onApply() {
    if (this.layoutType === 'FILTER_SET_RADIO_POPUP') {
      const assocFilter: AssociatedFilterValue = {};
      this.assocFilterService.generateFilterModel(
        this.name,
        assocFilter,
        this.filters
      );
      const conversionType = this.filters.find(
        (f) => f.literalId === 'conversion_type'
      );
      assocFilter.tactic = this.data?.tactic as DataRowData;
      assocFilter.channel = this.data?.channel as DataRowData;
      assocFilter.conversion_type = conversionType?.value as FilterValue;
      if (this.showChild) {
        assocFilter.tactic = this.multiViewStore.tactic;
        assocFilter.channel = this.multiViewStore.channel;
        assocFilter.campaign = this.data?.campaign as DataRowData;
      }
      this.contextStore.changeAssociatedFilterValues(
        this.name,
        assocFilter,
        this.showChild
      );
    } else {
      let filters: Filter[] = [];
      this.contextStore.filterContext.pipe(take(1)).subscribe((f) => {
        filters = f;
      });
      filters.map((filter: Filter) => {
        this.valueChangedFilters.forEach((changedFilter) => {
          if (filter.literalId === changedFilter.literalId) {
            filter.value = changedFilter.value;
          }
        });
      });
      this.filtersService.changeFilters({ filters });
    }
  }
}
