export interface ITabPanelHeader {
  header: string;
  disabled: boolean;
  showCount: boolean;
  count: number;
  field?: string;
}

export enum TabViewHeaderSize {
  default = 'default',
  small = 'small'
}
