import { Injectable } from '@angular/core';
import { BrandRow, Client, User, HeroElement } from '@portal/app/shared/types';
import { environment } from '@portal/environments/environment';
import { isEmpty } from 'lodash-es';
import { VendorIdSource } from '@portal/app/dashboard/integrations/shared/constants';
import { ViewStore } from '@portal/app/shared/state/view.store';
type SettablePrimitives =
  | string
  | number
  | bigint
  | boolean
  | null
  | undefined
  | string[]
  | number[]
  | bigint[]
  | boolean[]
  | null[]
  | undefined[];
type SettableRecords =
  | User
  | Client
  | Record<string, SettablePrimitives>
  | Record<string, SettablePrimitives>[]
  | BrandRow[];
export type SettableValues = SettablePrimitives | SettableRecords;

const clientVersionKey = 'clientVersion';
const clientVersionKeyFormat = (key: string) => `${key}.${clientVersionKey}`;
// Pulled from semver-compare
const cmp = (a: string, b: string): number => {
  const pa = a.split('.');
  const pb = b.split('.');
  for (let i = 0; i < 3; i++) {
    const na = Number(pa[i]);
    const nb = Number(pb[i]);
    if (na > nb) {
      return 1;
    }
    if (nb > na) {
      return -1;
    }
    if (!isNaN(na) && isNaN(nb)) {
      return 1;
    }
    if (isNaN(na) && !isNaN(nb)) {
      return -1;
    }
  }
  return 0;
};

@Injectable()
export class StorageService {
  private readonly store: Storage;
  private readonly sessionStore: Storage;
  private readonly whiteList: string[] = ['id_token', 'user'];
  private clientId = 0;
  private brandId = 0;
  private productId = '';
  private literalId = '';
  constructor(private readonly viewStore: ViewStore) {
    this.store = localStorage;
    this.sessionStore = sessionStorage;
    this.viewStore.selectedBCPDId.subscribe((value) => {
      this.clientId = value.clientId;
      this.brandId = value.brandId;
      this.productId = value.productId;
      this.literalId = value.literalId;
    });
  }

  private static sortByDisplayOrder(a: HeroElement, b: HeroElement): number {
    if (a.displayOrder > b.displayOrder) {
      return 1;
    } else if (a.displayOrder < b.displayOrder) {
      return -1;
    }
    return 0;
  }

  private isWhitelisted(key: string): boolean {
    return this.whiteList.includes(key);
  }

  public getAsUser(key: string): User | null {
    const val = this.getItem(key);
    return val == null ? null : JSON.parse(val);
  }

  // NOTE: This method ONLY returns string or null.
  //  performing this on an object will return '[object: object]'
  public get(key: string, ephemeral = false): string | null {
    try {
      const val = this.getItem(key, ephemeral);
      return val == null ? null : JSON.parse(val);
    } catch (error: unknown) {
      return this.getItem(key, ephemeral);
    }
  }

  public set(key: string, val: SettableValues, ephemeral = false): void {
    let valueToSet = val;
    if (typeof valueToSet !== 'string' && valueToSet !== null) {
      valueToSet = JSON.stringify(valueToSet);
    }
    if (valueToSet == null) {
      this.remove(clientVersionKeyFormat(key));
      return this.remove(key, ephemeral);
    }
    if (ephemeral) {
      return this.sessionStore.setItem(key, String(valueToSet));
    }
    this.updateClientVersion(key);
    this.store.setItem(key, String(valueToSet));
  }

  public remove(key: string, ephemeral = false): void {
    if (ephemeral) {
      return this.sessionStore.removeItem(key);
    }
    this.store.removeItem(key);
  }

  public setIntegrationAccountDetails(
    vendorId: VendorIdSource,
    jsonData: string
  ): void {
    this.store.setItem(`${vendorId}AccountDetails`, jsonData);
  }

  public removeIntegrationAccountDetails(vendorId: VendorIdSource): void {
    this.store.removeItem(`${vendorId}AccountDetails`);
  }

  public removeCallBackParams(): void {
    const callBackParams = ['code', 'state', 'hmac', 'shop'];
    if (callBackParams && !isEmpty(callBackParams)) {
      callBackParams.forEach((param) => {
        this.store.removeItem(param);
      });
    }
  }

  // Everything stored in localStorage (except whiteListed keys) will have an associated
  //  clientVersion key. If it does not, return true.
  private isClientVersionChanged(key: string): boolean {
    if (this.isWhitelisted(key)) {
      return false;
    }
    const currentClientVersion = environment.clientVersion;
    // DO NOT call StorageService here as we overload getItem to check this method:
    //  it would cause a cyclical redundancy
    const storedClientVersion = localStorage.getItem(
      clientVersionKeyFormat(key)
    );
    if (storedClientVersion == null) {
      return true;
    }
    return cmp(currentClientVersion, storedClientVersion) !== 0;
  }

  private getItem(key: string, ephemeral = false): string | null {
    if (ephemeral) {
      return this.sessionStore.getItem(key);
    }
    // Everything stored in localStorage (except whiteListed keys) will have an associated
    //  clientVersion key. If it does not, return true.
    if (this.isClientVersionChanged(key)) {
      // do not return the stored value if the client Version has changed,
      //  This will ensure the value will be called from the backend and updated
      return null;
    }
    // store a version for each call
    return this.store.getItem(key);
  }

  private updateClientVersion(key: string): void {
    if (this.isWhitelisted(key)) {
      return;
    }
    this.store.setItem(clientVersionKeyFormat(key), environment.clientVersion);
  }

  setVendorReAuthFlow(vendorId: string, vendorReAuth: boolean): void {
    this.store.setItem(`${vendorId}-ReAuthFlow`, JSON.stringify(vendorReAuth));
  }

  removeVendorReAuthFlow(vendorId: string): void {
    this.store.removeItem(`${vendorId}-ReAuthFlow`);
  }
}
