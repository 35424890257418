import { Component, EventEmitter, inject, input, Output } from '@angular/core';
import { TypePillSize } from '@design-system/components/m-type-pill';
import { SlideInOutAnimator } from '@libs/common-animations';
import { ModalService } from '@portal/app/dashboard/context-modal/services/modal.service';
import { DimensionOption } from '@portal/app/dashboard/context-modal/services/link.service';

@Component({
  selector: 'portal-context-modal-content',
  templateUrl: './content.component.html',
  styleUrl: './content.component.scss',
  animations: [SlideInOutAnimator.slideInUpOutDown()]
})
export class ContentComponent {
  modalService = inject(ModalService);
  title = input<string>('');
  pill = input<string>('');
  hasBackButton = input<boolean>(false);
  autoCompleteOptions = input<DimensionOption[]>([]);
  autoCompleteOptionLabel = input<string>('');

  modalType = this.modalService.modalType;
  conversionType = this.modalService.conversionType;

  @Output() closeModal = new EventEmitter();
  @Output() previousAction = new EventEmitter();
  @Output() nextAction = new EventEmitter();
  @Output() selectAction = new EventEmitter<DimensionOption>();

  readonly typePillSize = TypePillSize;

  onCloseModal() {
    this.closeModal.emit();
  }

  onPreviousAction() {
    this.previousAction.emit();
  }

  onNextAction() {
    this.nextAction.emit();
  }

  onSelectItem(item: DimensionOption) {
    this.selectAction.emit(item);
  }
}
