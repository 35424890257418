/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TypePillSize } from '../m-type-pill/m-type-pill.component.types';
import {
  IAlertPrimaryActionButtonProps,
  AlertIconType,
  AlertIconTypeToNameMap,
  AlertItemProps,
  errorClassIconList,
  IPrimaryActionButtonProperties,
  AlertButtonType
} from './m-alert-item.component.types';

@Component({
  selector: 'm-alert-item',
  templateUrl: './m-alert-item.component.html',
  styleUrls: ['./m-alert-item.component.scss']
})
export class MAlertItemComponent implements OnInit {
  @Input() id!: number;
  @Input() alertIcon: AlertIconType = AlertIconType.info;
  @Input() title: string | undefined = undefined;
  @Input() tag: string | undefined = undefined;
  @Input() isStacked = false;
  @Input() stackedCount = 0;
  @Input() timestamp: string | undefined = undefined;
  @Input() body = '';
  @Input() actionButtonsProps: IAlertPrimaryActionButtonProps[] = [];
  @Input() isRead = false;
  @Input() isResolved = false;
  @Input() isLoading = false;
  @Input() isExpanded = false;
  @Input() alertConfigId = 0;
  @Input() identifier = '';
  @Input() alertLogIds = [];

  public iconName = '';
  public alertIconType = AlertIconType;
  public tagSize = TypePillSize;
  public showUndoButton = false;
  timeoutValue = 1;

  @Input() updateIsExpanded: (
    alert: AlertItemProps,
    isExpanded: boolean
  ) => void = () => {};

  @Input() undoActionStatus: (alertId: number) => void = () => {};

  @Output() alertActionTriggered = new EventEmitter<{
    type: AlertButtonType;
    actionButtonsProps: IPrimaryActionButtonProperties;
    self: AlertItemProps;
  }>();

  ngOnInit(): void {
    this.iconName = AlertIconTypeToNameMap[this.alertIcon];
  }

  toggleExpanded(event: Event) {
    event.stopPropagation();
    this.updateIsExpanded(this as unknown as AlertItemProps, this.isExpanded);
  }

  handleMarkAsResolved(
    event: Event,
    type: AlertButtonType,
    actionButtonsProps: IPrimaryActionButtonProperties
  ) {
    event.stopPropagation();
    this.showUndoButton = true;
    this.timeoutValue = setTimeout(() => {
      this.showUndoButton = false;
    }, 5000) as unknown as number;
    this.alertActionTriggered.emit({
      type,
      actionButtonsProps,
      self: this as unknown as AlertItemProps
    });
  }

  undoAction(event: Event) {
    event.stopPropagation();
    clearInterval(this.timeoutValue);
    this.showUndoButton = false;
    this.undoActionStatus(this.id as number);
  }

  handleActionClick(
    event: Event,
    primaryActionButtonProperties: IPrimaryActionButtonProperties | undefined,
    type: AlertButtonType
  ) {
    if (type === AlertButtonType.markAsResolved) {
      this.handleMarkAsResolved(
        event,
        type,
        primaryActionButtonProperties as IPrimaryActionButtonProperties
      );
    } else {
      this.alertActionTriggered.emit({
        type,
        actionButtonsProps:
          primaryActionButtonProperties as IPrimaryActionButtonProperties,
        self: this as unknown as AlertItemProps
      });
    }
  }

  getAlertIconClassName() {
    return errorClassIconList.includes(this.alertIcon)
      ? 'error-icon'
      : `${this.alertIcon}-icon`;
  }
}
