import { Component, ContentChild, ElementRef } from '@angular/core';

/**
 * The `MLayoutComponent` serves as a container for layout structure,
 * managing the placement and styling of navigation, sidebar, and content areas.
 */
@Component({
  selector: 'm-layout',
  templateUrl: './m-layout.component.html',
  styleUrls: ['./m-layout.component.scss']
})
export class MLayoutComponent {
  /**
   * A reference to the content placed in the navigation area of the layout.
   */
  @ContentChild('navbarContent') navbarContent: ElementRef | undefined;

  /**
   * A reference to the content placed in the sidebar area of the layout.
   */
  @ContentChild('sidebarContent') sidebarContent: ElementRef | undefined;

  /**
   * Determines if the navigation area contains content.
   *
   * @returns `true` if content is present, otherwise `false`.
   */
  get hasNavbarContent(): boolean {
    return !!this.navbarContent;
  }

  /**
   * Determines if the sidebar area contains content.
   *
   * @returns `true` if content is present, otherwise `false`.
   */
  get hasSidebarContent(): boolean {
    return !!this.sidebarContent;
  }
}
