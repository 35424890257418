import {
  ContextField,
  ControlElement,
  DataResponse
} from '@portal/app/shared/types';
import {
  Point,
  PointLabelObject,
  TooltipFormatterContextObject
} from 'highcharts';
export const chartXAxisTypeValues = [
  'linear',
  'logarithmic',
  'datetime',
  'category',
  'treegrid'
] as const;
export type ChartXAxisType = (typeof chartXAxisTypeValues)[number];

export interface IChartOptions {
  type: string;
  yAxisText: string;
  yAxisPropertyFields: string[];
  xAxisText: string;
  xAxisType: ChartXAxisType;
  xAxisPropertyField: string;
  legend?: {
    layout: string;
    align: string;
    verticalAlign: string;
  };
  enableDataLabels: boolean;
  axisSeriesOption: IChartSeriesOption[];
  data: DataResponse[] | IChartData[];
  resolutionOptions: string[];
  selectedResolution: string;
  controls?: string[];
  filterFields?: ContextField[];
  metricControls?: ControlElement[];
}

export interface IChartSeriesOption {
  fields: string[];
  label: string;
  disabled: boolean;
  specialLabel?: {
    label: string;
    highlightedValue: string;
  };
}

export interface RoasChangedEvent {
  target: EventTarget;
}

export interface OptionChangedEvent {
  event: PointerEvent;
  value: string;
}

export interface IChartData {
  name: string;
  propName: string;
  data: DataResponse[];
}

export enum ChartDataTypes {
  regular = 'REGULAR',
  dataChart = 'DATA_CHART'
}

export interface CustomPoint extends Point {
  previousDate?: number | string;
}

export interface CustomPointLabelObject extends PointLabelObject {
  point: CustomPoint;
}

export interface CustomTooltipFormatterContextObject
  extends CustomPointLabelObject {
  points?: TooltipFormatterContextObject[];
}

export type PlotlineValues = Record<string, { value: number; label: string }>;

export type ResolutionType =
  | 'Year'
  | 'Month'
  | 'Week'
  | 'Day'
  | 'DateMonthYear'
  | 'AbbrMonthYear';
