import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  DataEndpoint,
  DataResponse,
  ElementGroup,
  Filter,
  FieldDefinitions
} from '@portal/app/shared/types';
import { ApiService } from '@portal/app/shared/services/api.service';
import { ContextStore } from '@portal/app/shared/state/context.store';
import { Required } from '@portal/app/shared/decorators/required.decorator';
import { cloneDeep, find } from 'lodash-es';
import { Observable, Subscription, take } from 'rxjs';
import { MultiViewStore } from '@portal/app/shared/state/multi-view.store';

@Component({
  selector: 'portal-card-group',
  templateUrl: './custom-card-group.component.html'
})
export class CustomCardGroupComponent implements OnInit, OnDestroy {
  @Input() @Required widgetLayout!: ElementGroup;
  @Input() @Required fieldDefinitions: FieldDefinitions = {};
  @Input() @Required filterSet: Filter[] = [];
  @Input() @Required filter!: Filter;
  @Input() data?: DataResponse;
  currentLayout?: ElementGroup;
  index = 0;
  isDataLoaded = false;
  private viewId?: number;
  private subscriptions: Subscription = new Subscription();
  filterContext$: Observable<Filter[]> = this.contextStore.filterContext;
  valueChangedFilters: Filter[] = [];

  constructor(
    private readonly apiService: ApiService,
    private readonly contextStore: ContextStore,
    private readonly multiViewStore: MultiViewStore
  ) {}

  ngOnInit(): void {
    this.multiViewStore.currentView$.subscribe((currentView) => {
      this.viewId = currentView ? Number(currentView.viewId) : undefined;
    });

    this.currentLayout = cloneDeep(this.widgetLayout);
    this.getCardView();
    this.getAssociatedFilterUpdate();
  }

  getCardView() {
    const context = this.filterContext$.subscribe({
      next: (filterSet) => {
        const filters = cloneDeep(filterSet);
        const viewByFilter = find(
          filters,
          (filterObj) => filterObj.literalId === 'viewBy'
        );
        const isSwitchableView = find(
          this.widgetLayout.elements,
          (filterObj) => filterObj.literalId === viewByFilter?.value
        );
        if (isSwitchableView) {
          if (this.currentLayout) {
            const widgetLayout = cloneDeep(this.widgetLayout);
            this.currentLayout.elements = widgetLayout.elements.filter(
              (element) => element.literalId === viewByFilter?.value
            );
          }
        }
        this.getData(filters);
      }
    });
    this.subscriptions.add(context);
  }

  getData(filters: Filter[]) {
    const dataPromise = this.apiService.getDashboardData({
      filters,
      type: DataEndpoint.dataPoints,
      viewId: this.viewId
    }) as Promise<DataResponse>;
    dataPromise.then((res) => {
      this.data = res;
      // to reinitialize cardcomponent with latest data
      this.isDataLoaded = false;
      setTimeout(() => {
        this.isDataLoaded = true;
      }, 100);
    });
  }

  getAssociatedFilterUpdate() {
    const associatedFilterContextStoreSubscription =
      this.contextStore.associatedFilterContext.subscribe({
        next: () => {
          let contextFiltersValue: Filter[] = [];
          this.filterContext$
            .pipe(take(1))
            .subscribe((f) => (contextFiltersValue = cloneDeep(f)));

          this.getData(contextFiltersValue);
        }
      });
    this.subscriptions.add(associatedFilterContextStoreSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
