import { animate, query, stagger, style, transition, trigger } from "@angular/animations";

enum SlideInDirection {
  toRight = 'translateX(-1.5rem)',
  toLeft = 'translateX(1.5rem)',
  toTop = 'translateY(1.5rem)',
  toBottom = 'translateY(-1.5rem)'
}

export class SlideInAnimator {
  private static triggerName = 'slideIn';
  public static readonly DEFAULT_DURATION_IN_MS = 300;

  public static slideInToRight = (selector = '', duration = this.DEFAULT_DURATION_IN_MS) => SlideInAnimator.slideIn(selector, SlideInDirection.toRight, duration)
  public static slideInToLeft = (selector = '', duration = this.DEFAULT_DURATION_IN_MS) => SlideInAnimator.slideIn(selector, SlideInDirection.toLeft, duration)
  public static slideInToTop = (selector = '', duration = this.DEFAULT_DURATION_IN_MS) => SlideInAnimator.slideIn(selector, SlideInDirection.toTop, duration)
  public static slideInToBottom = (selector = '', duration = this.DEFAULT_DURATION_IN_MS) => SlideInAnimator.slideIn(selector, SlideInDirection.toBottom, duration)

  private static slideIn = (selector = '', direction: SlideInDirection = SlideInDirection.toRight, duration = SlideInAnimator.DEFAULT_DURATION_IN_MS) => {
    return trigger(SlideInAnimator.triggerName, [
      transition('* => *', [
        query(
          `:enter${selector}`,
          style({ transform: direction, opacity: 0 }),
          {
            optional: true
          }
        ),
        query(
          `:enter${selector}`,
          stagger(150, [
            animate(
              `${duration}ms ease-in-out`,
              style({ transform: 'translateX(0)', opacity: 1 })
            )
          ]),
          { optional: true }
        )
      ])
    ])
  };
}
