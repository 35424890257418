import { GradientColorObject, Options, SeriesOptionsType } from 'highcharts';

/**
 * Converts a hexadecimal color value to RGBA format.
 *
 * @param hex - The hexadecimal color string (e.g., "#FF5733").
 * @param opacity - The opacity value ranging from 0 (fully transparent) to 1 (fully opaque).
 * @returns A string representing the color in RGBA format, e.g., "rgba(255,87,51,0.5)".
 */
export const hexToRgba = (hex: string, opacity: number): string => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const formattedHex = hex.replace(
    shorthandRegex,
    (m, r, g, b) => r + r + g + g + b + b
  );

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(formattedHex);
  return result
    ? `rgba(${parseInt(String(result[1]), 16)}, ${parseInt(String(result[2]), 16)}, ${parseInt(
        String(result[3]),
        16
      )}, ${opacity})`
    : '';
};

/**
 * Generates a gradient fill configuration for Highcharts.
 *
 * @param color - The base color for the gradient in hexadecimal format.
 * @returns A GradientColorObject representing the gradient fill.
 */
export const getGradientFill = (color: string): GradientColorObject => {
  return {
    linearGradient: {
      x1: 0,
      y1: 0,
      x2: 0,
      y2: 1
    },
    stops: [
      [0, hexToRgba(color, 0.5)],
      [1, hexToRgba(color, 0)]
    ]
  };
};

/**
 * Checks if the provided chart options contain any series with data.
 *
 * @param chartOptions - The Highcharts Options object to check.
 * @returns A boolean indicating whether any series in the chart options contain data.
 */
export const checkChartDataAvailability = (chartOptions: Options): boolean =>
  chartOptions.series?.some(
    (series: SeriesOptionsType) => (series as any)?.data?.length > 0
  ) ?? false;

/**
 * Sample formatter method used for demo purposes only to prevent injecting formatter service.
 *
 * @param value - number to be converted.
 * @returns A formatted truncated string E.G. 10K, 20M, etc..
 */
export const formatNumber = (value: number): string => {
  if (value >= 1000000) return `${(value / 1000000).toFixed(2)}M`;
  if (value >= 1000) return `${(value / 1000).toFixed(2)}K`;
  return value.toFixed(2);
};

/**
 * Sample tooltip formatter method used for demo purposes only to prevent injecting formatter service.
 *
 * @param value - number to be converted.
 * @returns A formatted truncated string E.G. 10K, 20M, etc..
 */
export function tooltipFormatter(
  this: Highcharts.TooltipFormatterContextObject
): string {
  let tooltipHtml = `<div class="tooltip-body">`;
  tooltipHtml += `<span class="b2 text-gray-500 text-center block">Date: ${this.x}</span>`;
  tooltipHtml += `<div class="m-divider dark my-2"></div>`;

  this.points?.forEach((point) => {
    if (point.y !== null && point.y !== undefined) {
      const seriesName = point.series.name;
      const value = point.y;
      tooltipHtml += `<div class="flex justify-between items-center">`;
      tooltipHtml += `<div><span class="b1 text-gray-000">${seriesName}</span>:</div>`;
      tooltipHtml += `<span class="b1 text-gray-000">$${formatNumber(value)}</span>`;
      tooltipHtml += `</div>`;
    }
  });

  tooltipHtml += `</div>`;
  return tooltipHtml;
}
