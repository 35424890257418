import { Component, OnDestroy, OnInit } from '@angular/core';
import { DisplayDashboard, FilterValue } from '@portal/app/shared/types';
import { SelectItem } from 'primeng/api';
import { Router } from '@angular/router';

// services
import { HomepageService } from '@portal/app/dashboard/home-page/services/home-page.service';
import { HeaderService } from '@portal/app/dashboard/home-page/components/header/header.service';
import { MyCustomersService } from '@portal/app/dashboard/home-page/components/my-customers/my-customers.service';
import { Subject, takeUntil } from 'rxjs';
import { DashboardService } from '@portal/app/shared/services/dashboard.service';
import { SelectionService } from '@portal/app/shared/services/selection.service';

@Component({
  selector: 'portal-my-customers',
  templateUrl: './my-customers.component.html'
})
export class MyCustomersComponent implements OnInit, OnDestroy {
  conversionType: FilterValue = ' ';
  conversionTypeOptions: SelectItem[] = [];
  private destroy$ = new Subject<void>();
  public isTableauCustomerReportingDashboardAvailable = false;
  public routerLink = '';

  constructor(
    private homepageService: HomepageService,
    private headerService: HeaderService,
    private myCustomersService: MyCustomersService,
    private dashboardService: DashboardService,
    private readonly selectionService: SelectionService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.homepageService.customerSectionConversionTypes$
      .pipe(takeUntil(this.destroy$))
      .subscribe((dimensions) => {
        this.conversionTypeOptions = dimensions;
      });

    this.headerService.selectedConversionType$
      .pipe(takeUntil(this.destroy$))
      .subscribe((type) => {
        this.conversionType = (
          this.checkConversionTypeExists(type)
            ? type
            : this.conversionTypeOptions[0]?.label
        ) as FilterValue;
        this.myCustomersService.updateCustomerConversionType(
          this.conversionType as string
        );
      });

    this.dashboardService.dashboards.pipe(takeUntil(this.destroy$)).subscribe({
      next: (dashboards: DisplayDashboard[]) => {
        if (dashboards.length) {
          this.setCustomerReportingAvailability(dashboards);
        }
      }
    });
  }

  onConversionTypeChange(conversionType: string) {
    this.myCustomersService.updateCustomerConversionType(conversionType);
  }

  checkConversionTypeExists(globalValue: FilterValue) {
    return this.conversionTypeOptions.some(
      (item) => item.label === globalValue
    );
  }

  setCustomerReportingAvailability(dashboards: DisplayDashboard[]) {
    const customerReportingDashboard = dashboards.find(
      (dashboard) => dashboard.literalId === 'customer-reporting'
    );
    if (customerReportingDashboard) {
      this.isTableauCustomerReportingDashboardAvailable = true;
      this.routerLink = customerReportingDashboard?.routerLinkPart || '';
    }
  }

  navigateToCustomerReporting() {
    this.router
      .navigate([
        `/a/${this.selectionService.buildSelectionSlug()}/products/${
          this.routerLink
        }`
      ])
      .catch((e) => console.error(e));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
