import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BrandStoreService } from '@portal/app/dashboard/geo-doe-config/data-stores/brand-store.service';
import { IntegrationStoreService } from '@portal/app/dashboard/integrations/data-store/integration-store.service';
import { IDSConfigResponse } from '@portal/app/dashboard/integrations/models/IDSConfigResponse';
import { IDsInstanceActivateDeactivateResponse } from '@portal/app/dashboard/integrations/models/IDsInstanceActivateDeactivateResponse';
import { ICriteoConfirmConnectionRequest } from '@portal/app/dashboard/integrations/models/vendor/ICriteoConfirmConnectionRequest';
import { IFacebookConfirmConnectionRequest } from '@portal/app/dashboard/integrations/models/vendor/IFacebookConfirmConnectionRequest';
import { IShopifyConfirmConnectionRequest } from '@portal/app/dashboard/integrations/models/vendor/IShopifyConfirmConnectionRequest';
import { IVendorConfigurationRequest } from '@portal/app/dashboard/integrations/models/vendor/IVendorConfigurationRequest';
import { IVendorConnectionStatus } from '@portal/app/dashboard/integrations/models/vendor/IVendorDetails';
import { IVendorInitiateRequest } from '@portal/app/dashboard/integrations/models/vendor/IVendorInitiateRequest';
import { IVendorReConfigurationRequest } from '@portal/app/dashboard/integrations/models/vendor/IVendorReConfigurationRequest';
import {
  IVendorConnectConfirmation,
  IVendorConnectInitResponse
} from '@portal/app/dashboard/integrations/models/vendor/VendorIntegrationModels';
import {
  Dashboard,
  LogRequestParams,
  LogResponse,
  PermissionResponse,
  SendEmailParams,
  SendEmailResponse
} from '@portal/app/shared/types';
import { environment } from '@portal/environments/environment';
import { keys } from 'lodash-es';
import { Observable } from 'rxjs';
import { IDsInstance } from '../models/IDsInstance';
import { IPinterestConfirmConnectionRequest } from '../models/vendor/IPinterestConfirmConnectionRequest';

interface QueryParams {
  clientId?: number;
  brandId?: number;
  productId?: string;
}

const apiDomain = environment.apiDomain;

type DashboardParams = Pick<QueryParams, 'clientId' | 'brandId' | 'productId'>;

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private apiRootV1 = `${apiDomain}/api/v1`;
  private apiRootV2 = `${apiDomain}/api/v2`;

  constructor(
    private httpClient: HttpClient,
    private integrationStoreService: IntegrationStoreService,
    private readonly brandStoreService: BrandStoreService
  ) {}

  private static buildQueryParams(queryParams?: DashboardParams): string {
    let qs = '';
    if (queryParams != null) {
      qs = '?';
      const queryParamsArray = Object.entries(queryParams);
      const lastKey = (
        queryParamsArray[queryParamsArray.length - 1] as [
          string,
          string | number
        ]
      )[0];
      for (const [key, val] of queryParamsArray) {
        qs += `${key}=${val}`;
        if (key !== lastKey) {
          qs += '&';
        }
      }
    }
    return qs;
  }

  getAllVendors(): Observable<IVendorConnectionStatus[]> {
    const params = new HttpParams()
      .set('clientId', `${this.brandStoreService.brand?.clientId}`)
      .set('brandId', `${this.brandStoreService.brand?.brandId}`);
    return this.httpClient.get<IVendorConnectionStatus[]>(
      `${this.apiRootV1}/vendor/all`,
      { params }
    );
  }

  vendorAuthInit(
    shop: string,
    callbackDomain: string
  ): Observable<IVendorConnectInitResponse> {
    const params = new HttpParams()
      .set('shop', shop)
      .set('callbackDomain', callbackDomain);
    return this.httpClient.get<IVendorConnectInitResponse>(
      `${this.apiRootV1}/vendor/oauth/init/shopify`,
      {
        params
      }
    );
  }

  configureVendor(
    data: IVendorConfigurationRequest
  ): Observable<IDSConfigResponse> {
    const params = new HttpParams()
      .set('clientId', `${this.brandStoreService.brand?.clientId}`)
      .set('brandId', `${this.brandStoreService.brand?.brandId}`);
    return this.httpClient.post<IDSConfigResponse>(
      `${this.apiRootV1}/vendor/configure/${data.vendorId}`,
      data,
      {
        params
      }
    );
  }

  deactivateVendor(vendorId: string): Observable<unknown> {
    const params = new HttpParams()
      .set('clientId', `${this.brandStoreService.brand?.clientId}`)
      .set('brandId', `${this.brandStoreService.brand?.brandId}`);
    return this.httpClient.delete(
      `${this.apiRootV1}/vendor/deactivate/${vendorId}`,
      { params }
    );
  }

  connectVendor(
    reqData: IVendorInitiateRequest
  ): Observable<IVendorConnectInitResponse> {
    const {
      pathParams: { vendorId },
      queryParams
    } = reqData;
    let params = new HttpParams()
      .set('clientId', `${this.brandStoreService.brand?.clientId}`)
      .set('brandId', `${this.brandStoreService.brand?.brandId}`);

    keys(queryParams).forEach((key) => {
      params = params.append(key, queryParams[key] as string);
    });

    return this.httpClient.get<IVendorConnectInitResponse>(
      `${this.apiRootV1}/vendor/oauth/init/${vendorId}`,
      {
        params
      }
    );
  }

  deActivateDsInstance(
    dsInstanceId: number
  ): Observable<IDsInstanceActivateDeactivateResponse> {
    const params = new HttpParams().set(
      'clientId',
      `${this.brandStoreService.brand?.clientId}`
    );
    return this.httpClient.patch<IDsInstanceActivateDeactivateResponse>(
      `${this.apiRootV1}/ds-instances/deactivate/${dsInstanceId}`,
      {},
      { params }
    );
  }

  activateDsInstance(
    dsInstanceId: number
  ): Observable<IDsInstanceActivateDeactivateResponse> {
    const params = new HttpParams().set(
      'clientId',
      `${this.brandStoreService.brand?.clientId}`
    );
    return this.httpClient.patch<IDsInstanceActivateDeactivateResponse>(
      `${this.apiRootV1}/ds-instances/activate/${dsInstanceId}`,
      {},
      { params }
    );
  }

  enableAllDsInstanceWithClientAccountId(
    clientAccountId: string
  ): Observable<IDsInstance[]> {
    const params = new HttpParams().set(
      'clientId',
      `${this.brandStoreService.brand?.clientId}`
    );
    return this.httpClient.patch<IDsInstance[]>(
      `${this.apiRootV1}/ds-instances/enable/${clientAccountId}`,
      {},
      { params }
    );
  }

  disableAllDsInstanceWithClientAccountId(
    clientAccountId: string
  ): Observable<IDsInstance[]> {
    const params = new HttpParams().set(
      'clientId',
      `${this.brandStoreService.brand?.clientId}`
    );
    return this.httpClient.patch<IDsInstance[]>(
      `${this.apiRootV1}/ds-instances/disable/${clientAccountId}`,
      {},
      { params }
    );
  }

  public getClientsAndBrands(): Observable<PermissionResponse> {
    return this.httpClient.get<PermissionResponse>(
      `${this.apiRootV2}/user/permissions`
    );
  }

  public getAvailableDashboards(
    queryParams?: DashboardParams
  ): Observable<Dashboard[]> {
    const qs = ApiService.buildQueryParams(queryParams);
    return this.httpClient.get<Dashboard[]>(
      `${this.apiRootV1}/dashboards${qs}`
    );
  }

  public postSendMail(
    emailParams: SendEmailParams
  ): Observable<SendEmailResponse> {
    return this.httpClient.post<SendEmailResponse>(
      `${this.apiRootV2}/sendMail`,
      emailParams
    );
  }

  public postLog(
    logLevel: 'INFO' | 'WARNING' | 'ERROR' = 'ERROR',
    message: string,
    messageType: string
  ): Observable<LogResponse> {
    const logBody: LogRequestParams = {
      appId: 'portal',
      message,
      subType: messageType
    };
    return this.httpClient.post<LogResponse>(
      `${this.apiRootV1}/log/${logLevel}`,
      logBody
    );
  }

  webhookHandler(
    action: string,
    type: string,
    data: Record<string, string | number>
  ): Observable<unknown> {
    return this.httpClient.post(
      `${this.apiRootV1}/webhook-handler/${action}/${type}/`,
      data
    );
  }

  confirmVendor(
    vendorId: string,
    data:
      | IShopifyConfirmConnectionRequest
      | IFacebookConfirmConnectionRequest
      | ICriteoConfirmConnectionRequest
      | IPinterestConfirmConnectionRequest
  ): Observable<IVendorConnectConfirmation> {
    const params = new HttpParams()
      .set('clientId', `${this.brandStoreService.brand?.clientId}`)
      .set('brandId', `${this.brandStoreService.brand?.brandId}`);
    return this.httpClient.post<IVendorConnectConfirmation>(
      `${this.apiRootV1}/connect-vendor/confirm/${vendorId}`,
      data,
      { params }
    );
  }

  reConfigureMeasuredAccess(
    data: IVendorReConfigurationRequest
  ): Observable<IDSConfigResponse> {
    const params = new HttpParams()
      .set('clientId', `${this.brandStoreService.brand?.clientId}`)
      .set('brandId', `${this.brandStoreService.brand?.brandId}`);
    return this.httpClient.post<IDSConfigResponse>(
      `${this.apiRootV1}/vendor/reconfigure-access/${data.vendorId}`,
      data,
      { params }
    );
  }
}
