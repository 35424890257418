import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { BannerType, CreativeAlignment } from './m-banner.component.types';

@Component({
  selector: 'm-banner',
  templateUrl: './m-banner.component.html',
  styleUrls: ['./m-banner.component.scss']
})
export class MBannerComponent {
  @ContentChild('creative') contentChild: ElementRef | undefined;

  @Input() type: BannerType = BannerType.primary;
  @Input() title = '';
  @Input() subText = '';
  @Input() creativePlacement: 'first' | 'last' | 'hidden' = 'last';
  @Input() creativeAlignment: CreativeAlignment = CreativeAlignment.bottom;
  @Input() closeable = true;
  @Output() closeBanner = new EventEmitter<string>();

  public handleBannerClose() {
    this.closeBanner?.emit();
  }
}
