<div class="m-checkbox-container">
  <p-checkbox
    [class]="{
      'm-checkbox-error': error,
      'm-checkbox-dashed': showDashedIcon,
      'm-checkbox': !error && !showDashedIcon,
    }"
    [name]="name"
    [value]="value"
    [tabindex]="tabindex"
    [inputId]="inputId"
    [ariaLabelledBy]="ariaLabelledBy"
    [ariaLabel]="ariaLabel"
    [required]="required"
    [binary]="binary"
    [(ngModel)]="isChecked"
    (onChange)="onChange.emit($event)"
    [checkboxIcon]="
      showDashedIcon
        ? 'p-checkbox-icon pi pi-minus'
        : 'p-checkbox-icon pi pi-check'
    "
    [disabled]="disabled">
  </p-checkbox>
  <label
    [class]="
      isChecked && !error ? 'm-checkbox-label' : 'm-checkbox-label-unselected'
    ">
    <ng-content></ng-content>
  </label>
</div>
