/* eslint-disable @typescript-eslint/no-explicit-any */

import { AvatarShape, IAvatarConfig } from '@design-system/components/m-avatar';
import { colors } from '@design-system/styles/colors';

export class AvatarTooltipUtil {
  public static modifiedUserColorCodes: string[] = [
    String(colors['green-500']),
    String(colors['orange-100']),
    String(colors['blue-500']),
    String(colors['yellow-600']),
    String(colors['red-400'])
  ];

  static buildModifiedByTooltipFormat(
    inputObject: Record<string, any>,
    avatarConfig: IAvatarConfig,
    modifiedAt: string,
    tooltipHeaderMessage: string
  ): string {
    if (inputObject['rModId']) {
      return `
        <div class="flex flex-col gap-2 items-start" style="width: 20rem;">
          <span class="b2 text-gray-500">${tooltipHeaderMessage}</span>
          <div class="flex items-center w-full gap-4">
            <img
              src="https://ui-avatars.com/api/?rounded=true&name=${
                avatarConfig.initials
              }&background=${avatarConfig.bg?.replace(
                '#',
                ''
              )}&color=fff&format=svg&size=32"
              alt="M" />
            <div class="flex flex-col gap-1 items-start">
              <span class="b2 text-gray-000 text-start">${
                inputObject['rModId']
              }</span>
              <span class="b2 text-gray-500 text-start">${modifiedAt}</span>
            </div>
          </div>
        </div>
      `;
    }
    return '';
  }

  static buildAvatarConfig(inputObject: Record<string, any>) {
    const initials = AvatarTooltipUtil.extractUserInitials(inputObject) || '';
    return {
      initials,
      color: '#fff',
      bg: AvatarTooltipUtil.extractUserHeadshotBg(inputObject),
      icon: initials === '-' ? 'smart_toy' : undefined,
      iconStyleClass: `text-gray-000`,
      shape: AvatarShape.circle
    };
  }

  static extractUserInitials(inputObject: Record<string, any>) {
    if (!inputObject['rModId']) {
      return '-';
    }
    const names = inputObject['rModId'].split('@');
    return names[0]
      ?.split('.')
      .map((name: string) => name.substring(0, 1).toUpperCase())
      .join('');
  }

  static extractUserHeadshotBg(inputObject: Record<string, any>) {
    const hashcode = AvatarTooltipUtil.hashCode(inputObject['rModId'] || '');
    const index = Math.abs(hashcode) % 5;
    return AvatarTooltipUtil.modifiedUserColorCodes[index] || '';
  }

  static readonly hashCode: (s: string) => number = (str: string) => {
    let hash = 0;
    let i;
    let chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      // eslint-disable-next-line no-bitwise
      hash = (hash << 5) - hash + chr;
      // eslint-disable-next-line no-bitwise
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  };
}
