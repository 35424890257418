import { Component, input } from '@angular/core';

// Types
import { DriversDTO } from '@portal/app/dashboard/context-modal/context-model.types';

export enum Column {
  one = 'one',
  two = 'two'
}

@Component({
  selector: 'portal-context-driver-display',
  templateUrl: './driver-display.component.html',
  styleUrl: './driver-display.component.scss'
})
export class DriverDisplayComponent {
  columnType = Column;
  column = input<Column>(Column.two);
  drivers = input<DriversDTO[] | undefined>();
}
