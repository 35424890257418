import {
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  FieldDefinitions,
  Filter,
  ElementGroup
} from '@portal/app/shared/types';
import { AccordionDirective } from '@portal/app/dashboard/accordion-section/accordion-section.directive';
import { keys } from 'lodash-es';
import {
  IAccordionData,
  IAccordionWithComponent
} from '@portal/app/shared/models/IAccordion';
@Component({
  selector: 'portal-accordion-section',
  templateUrl: './accordion-section.component.html',
  styleUrls: ['./accordion-section.component.scss']
})
export class AccordionSectionComponent implements OnInit {
  @Input() accordionItems?: ElementGroup;
  @Input() fieldDefinitions: FieldDefinitions = {};
  @Input() filters: Filter[] = [];
  @Input() accordionData: IAccordionData | undefined;
  @Input() accordionWithComponent: IAccordionWithComponent | undefined =
    undefined;

  @Output() isAccordionClickedEventEmitter = new EventEmitter<{
    id: string | undefined;
    isOpen: boolean;
  }>();

  @ViewChild(AccordionDirective, { static: true })
  accordionDir!: AccordionDirective;

  ngOnInit(): void {
    this.setUpAccordionComponent();
  }

  isAccordionClicked(isOpen: boolean): void {
    this.isAccordionClickedEventEmitter.next({
      id:
        this.accordionData?.id ||
        this.accordionWithComponent?.id ||
        this.accordionItems?.literalId,
      isOpen
    });
  }

  setUpAccordionComponent(): void {
    if (this.accordionWithComponent) {
      const { componentDetails } = this.accordionWithComponent;
      if (componentDetails.length > 0 && this.accordionDir) {
        const viewContainerRef = this.accordionDir.viewContainerRef;
        viewContainerRef.clear();
        componentDetails.forEach((componentDetail) => {
          const { component, params } = componentDetail;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const componentRef: ComponentRef<any> =
            viewContainerRef.createComponent(component);
          keys(params).forEach((param) => {
            componentRef.instance[param] = componentDetail.params[param];
          });
        });
      }
    }
  }
}
