import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// PrimeNg Modules
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { ProgressBarModule } from 'primeng/progressbar';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule, TableService } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';

// Highcharts
import { HighchartsChartModule } from 'highcharts-angular';

// Slider Module
import { NgxSliderModule } from '@angular-slider/ngx-slider';

// Components
import { MBannerComponent } from '@design-system/components/banner/m-banner';
import { MAlertItemComponent } from '@design-system/components/m-alert-item';
import { MArrowGroupComponent } from '@design-system/components/m-arrow-group';
import { MAvatarComponent } from '@design-system/components/m-avatar';
import { MBasicColumnChartComponent } from '@design-system/components/m-basic-column-chart';
import { MBottomPanelComponent } from '@design-system/components/m-bottom-panel';
import { MButtonComponent } from '@design-system/components/m-button';
import { MButtonGroupComponent } from '@design-system/components/m-button-group';
import { MCalendarComponent } from '@design-system/components/m-calendar';
import { MCardComponent } from '@design-system/components/m-card';
import { MChartBarComponent } from '@design-system/components/m-chart-bar';
import { MCheckboxComponent } from '@design-system/components/m-checkbox';
import { MChipComponent } from '@design-system/components/m-chip';
import { MCircularProgressComponent } from '@design-system/components/m-circular-progress';
import { MDateRangeComponent } from '@design-system/components/m-date-range';
import { MDonutChartComponent } from '@design-system/components/m-donut-chart';
import { MDropdownComponent } from '@design-system/components/m-dropdown';
import { MFileUploadComponent } from '@design-system/components/m-file-upload';
import { MHorizontalBarChartComponent } from '@design-system/components/m-horizontal-bar-chart';
import { MInputComponent } from '@design-system/components/m-input';
import { MLayoutComponent } from '@design-system/components/m-layout';
import { MLineChartComponent } from '@design-system/components/m-line-chart';
import { MMenuComponent } from '@design-system/components/m-menu';
import { MMessagesComponent } from '@design-system/components/m-messages';
import { MModalComponent } from '@design-system/components/m-modal';
import { MMultiSelectComponent } from '@design-system/components/m-multiselect';
import { MNotAvailableComponent } from '@design-system/components/m-not-available';
import { MNotFoundComponent } from '@design-system/components/m-not-found';
import { MNotificationCenterPopupComponent } from '@design-system/components/m-notification-center';
import { MPillComponent } from '@design-system/components/m-pill';
import { MProgressBarComponent } from '@design-system/components/m-progress-bar';
import { MProgressBarComparisonComponent } from '@design-system/components/m-progress-bar-comparison';
import { MProgressStepperComponent } from '@design-system/components/m-progress-stepper';
import { MSkeletonComponent } from '@design-system/components/m-skeleton';
import { MSliderStepComponent } from '@design-system/components/m-slider-step';
import { MStackedColumnChartComponent } from '@design-system/components/m-stacked-column-chart';
import { MSwitchComponent } from '@design-system/components/m-switch';
import { MTabViewComponent } from '@design-system/components/m-tab-view';
import { MTagComponent } from '@design-system/components/m-tag';
import { MTextFieldComponent } from '@design-system/components/m-text-field';
import { MToastComponent } from '@design-system/components/m-toast';
import { MTooltipComponent } from '@design-system/components/m-tooltip';
import { MTypePillComponent } from '@design-system/components/m-type-pill';
import { MVerticalBarChartComponent } from '@design-system/components/m-vertical-bar-chart';
import { MVerticalBarComparisonComponent } from '@design-system/components/m-vertical-bar-comparison';
import { MHighlightComponent } from './components/banner/m-highlight';
import { MPanelComponent } from './components/m-panel';
import { MRowGroupTableComponent } from './components/m-row-group-table';
import { MTabIndexDirective } from './components/m-tab-view/m-tab-index.directive';
import { MCountUpDisplayComponent } from '@design-system/components/m-count-up-display';
import { MCompareStackedBarChartComponent } from '@design-system/components/m-compare-stacked-bar-chart';

// Homepage Components
import { MMetricAmountHeaderComponent } from '@design-system/components/m-metric-amount-header';
import { MCarouselComponent } from '@design-system/pages/homepage/m-benchmarks-carousel';
import { MGeoCarouselComponent } from '@design-system/pages/homepage/m-geo-carousel';
import { MMetricCardComponent } from '@design-system/pages/homepage/m-metric-card';
import { MPortfolioCarouselComponent } from '@design-system/pages/homepage/m-portfolio-carousel';

// Optimization Report Components
import { MTableComponent } from './pages/optimization-report/m-table';

// Animations
import { MConfettiComponent } from '@design-system/animations/m-confetti';
import { MCountUpComponent } from '@design-system/animations/m-count-up';
import { MFadeInUpComponent } from '@design-system/animations/m-fade-in-up';
import { MExpandableDirective } from './animations/m-expandable/m-expandable.directive';

// Styles
import { MConfirmPopupComponent } from '@design-system/components/m-confirm-popup/m-confirm-popup.component';
import { MLinkComponent } from './components/m-link/m-link.component';
import { StepsModule } from 'primeng/steps';

@NgModule({
  imports: [
    AvatarGroupModule,
    AvatarGroupModule,
    AvatarModule,
    AvatarModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CarouselModule,
    CheckboxModule,
    ChipModule,
    ChipsModule,
    CommonModule,
    DialogModule,
    DropdownModule,
    FileUploadModule,
    FormsModule,
    HighchartsChartModule,
    InputSwitchModule,
    InputTextModule,
    MenuModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    NgxSliderModule,
    PanelModule,
    ProgressBarModule,
    ReactiveFormsModule,
    SkeletonModule,
    TableModule,
    TabViewModule,
    ToastModule,
    TooltipModule,
    ConfirmPopupModule,
    StepsModule
  ],
  providers: [TableService],
  declarations: [
    MAlertItemComponent,
    MArrowGroupComponent,
    MAvatarComponent,
    MBannerComponent,
    MBasicColumnChartComponent,
    MBottomPanelComponent,
    MButtonComponent,
    MButtonGroupComponent,
    MCalendarComponent,
    MCardComponent,
    MCarouselComponent,
    MChartBarComponent,
    MCheckboxComponent,
    MChipComponent,
    MCircularProgressComponent,
    MConfettiComponent,
    MCountUpComponent,
    MDateRangeComponent,
    MDonutChartComponent,
    MDropdownComponent,
    MExpandableDirective,
    MFadeInUpComponent,
    MFileUploadComponent,
    MGeoCarouselComponent,
    MHighlightComponent,
    MHorizontalBarChartComponent,
    MInputComponent,
    MLayoutComponent,
    MLineChartComponent,
    MMenuComponent,
    MMessagesComponent,
    MMetricAmountHeaderComponent,
    MMetricCardComponent,
    MModalComponent,
    MMultiSelectComponent,
    MNotAvailableComponent,
    MNotFoundComponent,
    MNotificationCenterPopupComponent,
    MPanelComponent,
    MPillComponent,
    MPortfolioCarouselComponent,
    MProgressBarComparisonComponent,
    MProgressBarComponent,
    MProgressStepperComponent,
    MSkeletonComponent,
    MSliderStepComponent,
    MStackedColumnChartComponent,
    MSwitchComponent,
    MTabIndexDirective,
    MTableComponent,
    MTabViewComponent,
    MTagComponent,
    MTextFieldComponent,
    MToastComponent,
    MTooltipComponent,
    MTypePillComponent,
    MVerticalBarChartComponent,
    MVerticalBarComparisonComponent,
    MRowGroupTableComponent,
    MTabViewComponent,
    MTabIndexDirective,
    MBannerComponent,
    MBottomPanelComponent,
    MAvatarComponent,
    MStackedColumnChartComponent,
    MBasicColumnChartComponent,
    MButtonGroupComponent,
    MFileUploadComponent,
    MCalendarComponent,
    MDateRangeComponent,
    MChipComponent,
    MInputComponent,
    MTextFieldComponent,
    MLinkComponent,
    MConfirmPopupComponent,
    MCountUpDisplayComponent,
    MCompareStackedBarChartComponent
  ],

  exports: [
    MAlertItemComponent,
    MArrowGroupComponent,
    MAvatarComponent,
    MBannerComponent,
    MBasicColumnChartComponent,
    MBottomPanelComponent,
    MButtonComponent,
    MButtonGroupComponent,
    MCalendarComponent,
    MCardComponent,
    MCarouselComponent,
    MChartBarComponent,
    MCheckboxComponent,
    MCircularProgressComponent,
    MConfettiComponent,
    MCountUpComponent,
    MDateRangeComponent,
    MDonutChartComponent,
    MDropdownComponent,
    MExpandableDirective,
    MFadeInUpComponent,
    MFileUploadComponent,
    MGeoCarouselComponent,
    MHorizontalBarChartComponent,
    MLayoutComponent,
    MLineChartComponent,
    MMenuComponent,
    MMessagesComponent,
    MMetricAmountHeaderComponent,
    MMetricCardComponent,
    MModalComponent,
    MMultiSelectComponent,
    MNotAvailableComponent,
    MNotFoundComponent,
    MNotificationCenterPopupComponent,
    MPanelComponent,
    MPillComponent,
    MPortfolioCarouselComponent,
    MProgressBarComparisonComponent,
    MProgressBarComponent,
    MProgressStepperComponent,
    MSkeletonComponent,
    MSliderStepComponent,
    MStackedColumnChartComponent,
    MSwitchComponent,
    MTabIndexDirective,
    MTableComponent,
    MTabViewComponent,
    MTagComponent,
    MToastComponent,
    MTooltipComponent,
    MTypePillComponent,
    MVerticalBarChartComponent,
    MVerticalBarComparisonComponent,
    MRowGroupTableComponent,
    MBannerComponent,
    MTabViewComponent,
    MTabIndexDirective,
    MAvatarComponent,
    MBottomPanelComponent,
    MStackedColumnChartComponent,
    MButtonGroupComponent,
    MFileUploadComponent,
    MCalendarComponent,
    MDateRangeComponent,
    MTextFieldComponent,
    MLinkComponent,
    MConfirmPopupComponent,
    MCountUpDisplayComponent,
    MCompareStackedBarChartComponent
  ]
})
export class DesignSystemModule {}
