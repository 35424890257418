/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

// Services

import { DataPointService } from './data-point.service';
import { FieldService } from '@portal/app/shared/services/field.service';
import { FilterService } from './filter.service';
import { FormatService } from '@portal/app/dashboard/home-page/services/format.service';

// Types and consts
import { FieldDefinition, Filter } from '@portal/app/shared/types';
export interface Metric {
  adPlatform?: string;
  arrowIntensity: number;
  drivers: any[];
  formattedValue: string;
  hasError: boolean;
  isNeutral?: boolean;
  isPositive: boolean;
  isUp: boolean;
  literalId: string;
  prevPercDifference: number;
  prevValue: number;
  title: string;
  value: number;
}

@Injectable({
  providedIn: 'root'
})
export class MetricsService implements OnDestroy {
  // Active metric selected by user via clicking on modal
  private activeMetricSubject = new BehaviorSubject<Metric | undefined>(
    undefined
  );

  public activeMetric$ = this.activeMetricSubject.asObservable();
  // Used for data cleanup
  private destroy$ = new Subject<void>();
  // Metrics set by user and returned via layout API
  private selectedMetricsSubject = new BehaviorSubject<string[]>([]);
  public selectedMetrics$ = this.selectedMetricsSubject.asObservable();
  // Array of metrics with data points
  private metricDataPointsSubject = new BehaviorSubject<string[]>([]);
  public metricDataPoints$ = this.metricDataPointsSubject.asObservable();
  // Loading state
  private isLoadingSubject = new BehaviorSubject<boolean>(true);
  public isLoading$ = this.isLoadingSubject.asObservable();

  constructor(
    private dataPointService: DataPointService,

    private fieldService: FieldService,
    private filterService: FilterService,
    private formatService: FormatService
  ) {}

  /**
   * Sets the metrics array and updates the BehaviorSubject.
   * It modifies the array by appending corresponding 'LT' metrics for each 'I' metric.
   *
   * @param newMetrics - Array of metric strings to be set.
   */
  setSelectedMetrics(newMetrics: string[]): void {
    this.selectedMetricsSubject.next(newMetrics);
  }

  getSelectedMetrics(): string[] {
    const currentMetrics = this.selectedMetricsSubject.getValue();
    return currentMetrics.filter((metric) => !metric?.endsWith('LT'));
  }

  /**
   * Sets the active metric and updates the BehaviorSubject.
   *
   * @param newActiveMetric - The new active metric to be set.
   */
  setActiveMetric(newActiveMetric: Metric): void {
    this.activeMetricSubject.next(newActiveMetric);
  }

  getActiveMetric(): Metric | undefined {
    return this.activeMetricSubject.getValue();
  }

  getFilters(
    filters: Filter[],
    metrics: string[],
    channels?: string[]
  ): Filter[] {
    const additionalFilters = [
      this.filterService.createFilter({
        literalId: 'showPointDrivers',
        type: 'string',
        value: true
      }),
      this.filterService.createFilter({
        literalId: 'showDatasetDrivers',
        type: 'string',
        value: true
      }),
      this.filterService.createFilter({
        literalId: 'layout_feature',
        type: 'string',
        value: false
      }),
      this.filterService.createFilter({
        literalId: 'compare',
        type: 'string',
        value: true
      }),
      this.filterService.createFilter({
        literalId: 'showPointDrivers',
        type: 'string',
        value: true
      })
    ];

    // Override channel filter if channels are provided
    if (channels && channels.length > 0) {
      const channelFilter = this.filterService.createFilter({
        literalId: 'channel',
        type: 'string[]',
        value: channels
      });
      // Remove existing channel filter, if any, and add the new one
      // eslint-disable-next-line no-param-reassign
      filters = filters.filter((filter) => filter.literalId !== 'channel');
      filters.push(channelFilter);
    }

    additionalFilters.forEach((additionalFilter) => {
      if (
        !filters.some(
          (filter) => filter.literalId === additionalFilter.literalId
        )
      ) {
        filters.push(additionalFilter);
      }
    });

    const dataPointsFilter = this.filterService.createFilter({
      literalId: 'dataPoints',
      isRequired: 'YES',
      type: 'string[]',
      format: 'none',
      applyTo: ['DATA_POINT'],
      value: metrics,
      options: metrics
    });

    return [
      ...filters.filter((filter) => filter.literalId !== 'dataPoints'),
      dataPointsFilter
    ];
  }

  fetchData(
    filters: Filter[],
    metrics: string[],
    channels?: string[]
  ): Observable<any> {
    this.isLoadingSubject.next(true);
    const metricsFilters = this.getFilters(filters, metrics, channels);

    return this.dataPointService.fetchDataPoints(metricsFilters).pipe(
      map((dataPoints) => this.formatData(dataPoints, metrics)),
      catchError((error) => {
        console.error('Error fetching data points', error);
        this.isLoadingSubject.next(false); // Consider moving to a finally block or similar approach
        return throwError(error); // Rethrow or handle the error as appropriate
      }),
      tap(() => this.isLoadingSubject.next(false)) // Turn off loading indicator
    );
  }

  formatData(data: any, metrics: string[]): Metric[] {
    const fields = this.fieldService.getCurrentFields();
    return metrics
      .map((literalId) => {
        const fieldDefinition = fields[literalId];
        const value = data[literalId];
        const formattedValue = this.formatService.formatValue(
          fieldDefinition as FieldDefinition,
          value
        );
        const metric = { ...data?.prevPeriodAttributes?.[literalId] };

        if (literalId.endsWith('I')) {
          const ltMetricId: string = literalId.slice(0, -1) + 'LT';
          if (data[ltMetricId]) {
            metric.adPlatform = this.formatService.formatValue(
              fields[ltMetricId] as FieldDefinition,
              data[ltMetricId]
            );
          }
        }

        // Initialize isPositive and isNeutral based on the literalId
        let isPositive = metric?.driverChange === 'POSITIVE';
        let isNeutral = false;
        if (literalId === 'mediaSpend') {
          isPositive = false;
          isNeutral = true;
        }

        const formattedMetric: Metric = {
          adPlatform: metric?.adPlatform,
          arrowIntensity: metric?.arrowIntensity || 0, // Ensure a default value or validation check
          drivers: metric?.drivers?.slice(0, 3),
          formattedValue,
          isUp: metric?.arrowDirection === 'UP',
          isPositive,
          isNeutral,
          literalId,
          prevPercDifference: Math.abs(metric?.prevPercDifference) || 0,
          prevValue: metric?.prevValue || 0,
          title: fieldDefinition?.label || '', // Ensure a default value or validation check
          value: value || 0,
          hasError: false // Initialize hasError
        };

        // Additional logic to set textColor for each driver based on conditions
        if (formattedMetric.drivers) {
          formattedMetric.drivers = formattedMetric.drivers.map((driver) => {
            // Determine the text color class for the driver
            let textColorClass = '';
            if (literalId === 'mediaSpend') {
              textColorClass = 'text-gray-900';
            } else if (driver.driverChange === 'POSITIVE') {
              textColorClass = 'text-green-700';
            } else {
              textColorClass = 'text-red-600';
            }

            // Attach the textColorClass to the driver
            return { ...driver, textColorClass };
          });
        }

        // Set hasError based on metric completeness
        if (!this.isMetricValid(formattedMetric)) {
          formattedMetric.hasError = true;
        }

        return formattedMetric;
      })
      .filter((metric) => !metric?.literalId.endsWith('LT'));
  }

  isMetricValid(metric: Metric): boolean {
    // Check for valid numeric values, excluding "Infinity"
    const isValidNumber = (value: any): boolean => {
      const num = parseFloat(value);
      return !isNaN(num) && isFinite(num);
    };

    return [
      metric?.title?.trim() !== '', // Title must not be empty
      metric?.formattedValue?.trim() !== '' &&
        !metric?.formattedValue.includes('Infinity'), // FormattedValue must not be empty or "$InfinityM"
      metric?.isUp !== undefined, // isUp must be defined
      metric?.isPositive !== undefined, // isPositive must be defined
      isValidNumber(metric?.prevValue), // prevValue must be a valid, finite number
      isValidNumber(metric?.value), // value must be a valid, finite number
      !!metric?.value
    ].every((condition) => condition); // Only if all conditions are true, the metric is considered complete
  }

  // Handles cleanup when the service is destroyed
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
