import { HttpClient, HttpParams } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { Utility } from '../helpers/utility';
import { IPlatformApiOperation } from '../models/IPlatformApiOperation';
import { IUserAccess, User } from '../models/User';
import { IClient, INewClientRequestBody } from '../models/IClient';
import { IBrand } from '../models/IBrand';
import { IUserPermissions } from '../models/IUserPermission';
import { IHealthMonitor } from '../models/IHealthMonitor';
import { ICredentialDetails } from '../models/ICredentialDetails';
import { IFlattenedBrand } from '../models/IFlattenedBrand';
import {
  ICommonLookups,
  ICommonLookupTypes,
  INewCommonLookups
} from '../models/ICommonLookups';
import { ICurrencyConfig } from '../models/ICurrencyConfig';
import { ClientType } from '../models/ClientType';
import { IAddBrandRequest } from '../models/IAddBrandRequest';
import { IEventWatcherRule } from '../models/IEventWatcherRule';
import { ICredentials } from '../models/ICredentials';
import { ICleanroomExecutionToolRequest } from '../models/ICleanroomExecutionToolRequest';
import { IChildNode, IGraphNode } from '../models/IComponentGraphNode';
import {
  IOptInChangePayload,
  IGeoTestCalibration
} from '../models/IGeoTestCalibration';

const promiseFrom = lastValueFrom;

export class PlatformApis implements IPlatformApiOperation {
  private constructor(
    private readonly httpClient: HttpClient,
    private readonly apiV1: string,
    private readonly apiV2: string
  ) {}

  public static platformApiOperation(
    httpClient: HttpClient,
    apiV1: string,
    apiV2: string
  ): IPlatformApiOperation {
    return new PlatformApis(httpClient, apiV1, apiV2);
  }

  public async getUser(): Promise<User> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/user`
    });
    return promiseFrom(this.httpClient.get<User>(url));
  }

  public async getAllRoles(): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/user-roles`
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async getAllApis(moduleSearchText: string): Promise<any> {
    const queryParams = new HttpParams({
      fromObject: {
        name: moduleSearchText
      }
    });
    return promiseFrom(
      this.httpClient.get(`${this.apiV1}/module-operations`, {
        params: queryParams
      })
    );
  }

  public async getModulePermissionsByRole(roleName: string): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/user-role-permissions/by-role/:roleName`,
      pathVariables: { roleName }
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async getUserAccess(userName: string): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/user-access/by-username/:userName`,
      pathVariables: { userName }
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async saveOrUpdateUserAccess(data: any): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/user-access/`
    });
    return promiseFrom(this.httpClient.put(url, data));
  }

  public async saveOrUpdateRolePermission(data: any): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/user-role-permissions/`
    });
    return promiseFrom(this.httpClient.put(url, data));
  }

  public async getClientById(clientId: number): Promise<IClient> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/clients/${clientId}`
    });
    return promiseFrom(this.httpClient.get<IClient>(url));
  }

  public async getAccessibleClients(): Promise<IUserPermissions> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/user/permissions`
    });
    return promiseFrom(this.httpClient.get<IUserPermissions>(url));
  }

  public async getAllClients(): Promise<IClient[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/clients`
    });
    return promiseFrom(this.httpClient.get<IClient[]>(url));
  }

  public async getAllBrands(): Promise<IBrand[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/brands`
    });
    return promiseFrom(this.httpClient.get<IBrand[]>(url));
  }

  public async getAllFlattenedBrands(): Promise<IFlattenedBrand[]> {
    return this.getAccessibleClients().then((permission) => {
      localStorage.setItem('userInfo', JSON.stringify(permission));
      return Utility.flattenBrands(permission.clients);
    });
  }

  public async getClients(): Promise<IClient[]> {
    return this.getAccessibleClients().then((accessibleClients) =>
      accessibleClients.userType === 'external'
        ? accessibleClients.clients
        : this.getAllClients()
    );
  }

  public async createClient(
    clientType: ClientType,
    newClientRequestBody: INewClientRequestBody
  ): Promise<IClient> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/clients/:clientType`,
      pathVariables: {
        clientType
      }
    });
    return promiseFrom(
      this.httpClient.post<IClient>(url, newClientRequestBody)
    );
  }

  public async updateClientDetails(
    clientId: number,
    data: IClient
  ): Promise<IClient> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/clients/:clientId`,
      pathVariables: { clientId }
    });
    return promiseFrom(this.httpClient.put<IClient>(url, data));
  }

  public async getBrands(clientId: number): Promise<IBrand[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/brands`,
      queryParameters: { clientId }
    });
    return promiseFrom(this.httpClient.get<IBrand[]>(url));
  }

  public async updateBrandDetails(
    brandId: number,
    data: IBrand
  ): Promise<IFlattenedBrand> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/brands/:brandId`,
      pathVariables: { brandId }
    });
    return promiseFrom(this.httpClient.put<IFlattenedBrand>(url, data));
  }

  public async addNewBrand(postData: IAddBrandRequest): Promise<IBrand> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/brands`
    });
    return promiseFrom(this.httpClient.post<IBrand>(url, postData));
  }

  public async getHealthMonitorData(
    clientId: number
  ): Promise<IHealthMonitor[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/health-monitor`,
      queryParameters: { clientId }
    });
    return promiseFrom(this.httpClient.get<IHealthMonitor[]>(url));
  }

  public async getCredentials(clientId: number): Promise<ICredentialDetails[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/credentials`,
      queryParameters: { clientId }
    });
    return promiseFrom(this.httpClient.get<ICredentialDetails[]>(url));
  }

  public async getCommonLookupTypes(
    status?: string,
    lookupType?: string
  ): Promise<ICommonLookups[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/common-lookup`,
      queryParameters: {
        status: status || null,
        lookupType: lookupType || null
      }
    });
    return promiseFrom(this.httpClient.get<ICommonLookups[]>(url));
  }

  public async getActiveCommonLookupTypes(
    lookupType: string
  ): Promise<ICommonLookupTypes[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/common-lookup/lookup-type`,
      queryParameters: {
        lookupType: lookupType || null
      }
    });
    return promiseFrom(this.httpClient.get<ICommonLookupTypes[]>(url));
  }

  public async createCommonLookup(
    commonLookups: INewCommonLookups[]
  ): Promise<ICommonLookups[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/common-lookup`
    });
    return promiseFrom(
      this.httpClient.post<ICommonLookups[]>(url, { commonLookups })
    );
  }

  public async updateCommonLookups(
    ids?: number[],
    commonLookups?: INewCommonLookups[]
  ): Promise<ICommonLookups[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/common-lookup`
    });
    const body = {
      ids: ids || null,
      commonLookups: commonLookups || null
    };
    return promiseFrom(this.httpClient.put<ICommonLookups[]>(url, body));
  }

  public async getCurrencyConfigs(): Promise<ICurrencyConfig[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/currency-config`
    });
    return promiseFrom(this.httpClient.get<ICurrencyConfig[]>(url));
  }

  public async getAlertSubscriptions(): Promise<IEventWatcherRule[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/event-watcher`
    });
    return promiseFrom(this.httpClient.get<IEventWatcherRule[]>(url));
  }

  public async getRuleById(id: number): Promise<IEventWatcherRule> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/event-watcher/:id`,
      pathVariables: { id }
    });
    return promiseFrom(this.httpClient.get<IEventWatcherRule>(url));
  }

  public async updateEventWatcherRule(rule: any): Promise<IEventWatcherRule> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/event-watcher`
    });
    return promiseFrom(this.httpClient.put<IEventWatcherRule>(url, rule));
  }

  public async createEventWatcherRule(rule: any): Promise<IEventWatcherRule> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/event-watcher`
    });
    return promiseFrom(this.httpClient.post<IEventWatcherRule>(url, rule));
  }

  public async getComponentGraphData(productId?: string): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/component-graph`,
      queryParameters: { productId: productId || null }
    });
    return promiseFrom(this.httpClient.get<any>(url));
  }

  public async getAllClientCredentials(
    clientId: number
  ): Promise<ICredentials[]> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/credentials`,
      queryParameters: { clientId }
    });
    return promiseFrom(this.httpClient.get<ICredentials[]>(url));
  }

  public async getCredentialById(id: number): Promise<ICredentials> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/credentials/:id`,
      pathVariables: { id }
    });
    return promiseFrom(this.httpClient.get<ICredentials>(url));
  }

  public async getDefaultClientCredentials(): Promise<ICredentials[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/credentials/default-client-credentials`
    });
    return promiseFrom(this.httpClient.get<ICredentials[]>(url));
  }

  public async createCredential(
    credentials: ICredentials
  ): Promise<ICredentials> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/credentials`
    });
    return promiseFrom(this.httpClient.post<ICredentials>(url, credentials));
  }

  public async updateCredential(
    id: number,
    credentials: ICredentials
  ): Promise<ICredentials> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/credentials/:id`,
      pathVariables: { id }
    });
    return promiseFrom(this.httpClient.put<ICredentials>(url, credentials));
  }

  public async getCredentialConnectionTypes(): Promise<
    Record<string, string>[]
  > {
    const url = Utility.buildURL({
      url: `${this.apiV1}/credentials/connection-types`
    });
    return promiseFrom(this.httpClient.get<Record<string, string>[]>(url));
  }

  public async getActiveCleanrooms(): Promise<Record<string, string>[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/clients/cleanroom`
    });
    return promiseFrom(this.httpClient.get<Record<string, string>[]>(url));
  }

  public async invokeCleanroomExecutionTool(
    invokeParameters: ICleanroomExecutionToolRequest
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/invoke-cleanroom-execution-tool`
    });
    return promiseFrom(this.httpClient.post<any>(url, invokeParameters));
  }

  public async addNodeToComponentGraphData(node: IGraphNode): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/component-graph/node`
    });
    return promiseFrom(this.httpClient.post<any>(url, node));
  }

  public async addChildToComponentGraphData(node: IChildNode): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/component-graph/child-node`
    });
    return promiseFrom(this.httpClient.post<any>(url, node));
  }

  public async getComponentsByType(componentType: string): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/component-graph/component`,
      queryParameters: { componentType }
    });
    return promiseFrom(this.httpClient.get<any>(url));
  }

  public async linkExistingComponent(
    parentComponentId: number,
    componentId: number
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/component-graph/link-node`
    });
    return promiseFrom(
      this.httpClient.post<any>(url, { parentComponentId, componentId })
    );
  }

  public async getAllUserAccess(): Promise<IUserAccess[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/user-access`
    });
    return promiseFrom(this.httpClient.get<IUserAccess[]>(url));
  }

  public async getAwsCredentials(
    clientId: number,
    datasourceId: string
  ): Promise<ICredentials> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/credentials/send-aws-credentials`,
      queryParameters: { clientId, datasourceId }
    });
    return promiseFrom(this.httpClient.get<ICredentials>(url));
  }

  public async getAllStudyAndCellConfigDetails(clientId: number): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/geo-test-calibration`,
      queryParameters: { clientId }
    });
    return promiseFrom(this.httpClient.get<any>(url));
  }

  public async updateOptInStatus(
    OptInChangePayload: IOptInChangePayload
  ): Promise<IGeoTestCalibration[]> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/geo-test-calibration/opt-in-out`
    });
    return promiseFrom(this.httpClient.post<any>(url, OptInChangePayload));
  }
}
