import { Component, OnDestroy, OnInit } from '@angular/core';
import { PortalEventBusService } from '@portal/app/shared/services/portal-event-bus.service';
import { PortalEventCategory } from '@portal/app/shared/types';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef
} from 'primeng/dynamicdialog';

@Component({
  selector: 'portal-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  providers: [DialogService]
})
export class NotesComponent implements OnInit, OnDestroy {
  private id: string | undefined;
  public notes = '';
  public isEditMode = false;
  public hasEditPermission = false;
  private eventCategory: PortalEventCategory | undefined;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public portalEventBusService: PortalEventBusService
  ) {}

  ngOnInit() {
    this.id = this.config.data?.id;
    this.notes = this.config.data?.notes;
    this.hasEditPermission = this.config.data?.hasEditPermission;
    this.eventCategory = this.config.data?.eventCategory;
  }

  editNote() {
    this.isEditMode = true;
  }

  cancelChanges() {
    this.notes = this.config.data?.notes;
    this.isEditMode = false;
  }

  saveChanges() {
    this.portalEventBusService.emit({
      id: this.id,
      action: 'SAVE_NOTES',
      eventCategory: this.eventCategory,
      payload: { notes: this.notes }
    });
    this.isEditMode = false;
  }

  ngOnDestroy(): void {
    if (this.ref) {
      this.ref.close();
    }
  }
}
