import { NgModule } from '@angular/core';
import { MenubarModule } from 'primeng/menubar';
import { StyleClassModule } from 'primeng/styleclass';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CardModule } from 'primeng/card';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MenuModule } from 'primeng/menu';
import { BadgeModule } from 'primeng/badge';
import { TabViewModule } from 'primeng/tabview';
import { SelectButtonModule } from 'primeng/selectbutton';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AccordionModule } from 'primeng/accordion';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { AutoCompleteModule } from 'primeng/autocomplete';

@NgModule({
  declarations: [],
  imports: [
    TableModule,
    MenubarModule,
    StyleClassModule,
    InputTextModule,
    InputNumberModule,
    ProgressSpinnerModule,
    DialogModule,
    CardModule,
    MultiSelectModule,
    CalendarModule,
    DropdownModule,
    InputSwitchModule,
    MenuModule,
    BadgeModule,
    SelectButtonModule,
    OverlayPanelModule,
    AccordionModule,
    RadioButtonModule,
    RippleModule,
    BreadcrumbModule,
    AutoCompleteModule
  ],
  exports: [
    TableModule,
    MenubarModule,
    StyleClassModule,
    InputTextModule,
    InputNumberModule,
    ProgressSpinnerModule,
    DialogModule,
    CardModule,
    MultiSelectModule,
    CalendarModule,
    DropdownModule,
    InputSwitchModule,
    MenuModule,
    BadgeModule,
    TabViewModule,
    SelectButtonModule,
    OverlayPanelModule,
    AccordionModule,
    RadioButtonModule,
    RippleModule,
    BreadcrumbModule,
    AutoCompleteModule
  ]
})
export class PrimengModule {}
