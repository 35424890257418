@if (!isChartReady()) {
  <div class="h-full">
    <m-skeleton width="100%" height="372px"></m-skeleton>
  </div>
} @else if (!isLoading() && isChartReady()) {
  <m-card [variant]="cardVariantType.small">
    <div header class="flex items-center w-full">
      <span class="sh2 mr-2">Trending</span>
    </div>
    <div class="text-center w-full mt-2">
      <m-line-chart
        [series]="chartOptions().series"
        [xAxis]="chartOptions().xAxis"
        [yAxis]="chartOptions().yAxis"
        [plotOptions]="chartOptions().plotOptions"
        [tooltip]="chartOptions().tooltip"
        [legend]="chartOptions().legend"
        [height]="300">
      </m-line-chart>
    </div>
  </m-card>
}
