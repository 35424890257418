import {
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  forwardRef
} from '@angular/core';
import { Checkbox } from 'primeng/checkbox';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'm-checkbox',
  templateUrl: './m-checkbox.component.html',
  styleUrls: ['./m-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MCheckboxComponent),
      multi: true
    }
  ]
})
export class MCheckboxComponent
  extends Checkbox
  implements ControlValueAccessor
{
  @Input() error = false;
  @Input() showDashedIcon = false;
  @Input() isChecked = false;

  constructor(
    cd: ChangeDetectorRef,
    injector: Injector,
    config: PrimeNGConfig
  ) {
    super(cd, injector, config);
  }
}
