import { Component, OnInit, OnDestroy, Renderer2, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HomepageService } from '@portal/app/dashboard/home-page/services/home-page.service';
import { DOCUMENT } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'portal-homepage',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomepageComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  public isLoading = true;
  public hasData = true;
  public showChartView = true;
  public showCustomerView = true;
  public showOptimizationResults = false;

  constructor(
    private homepageService: HomepageService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private router: Router
  ) {}

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.homepageService.fetchAndSetHomepageData();

    this.renderer.addClass(this.document.body, 'homepage-active');

    this.subscribeToChanges();
  }

  subscribeToChanges() {
    this.homepageService.isLoading$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoading) => (this.isLoading = isLoading));

    this.homepageService.showChartView$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.showChartView = value;
      });

    this.homepageService.showCustomerView$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.showCustomerView = value;
      });

    this.homepageService.hasData$
      .pipe(takeUntil(this.destroy$))
      .subscribe((hasData) => {
        this.hasData = hasData;
      });

    this.homepageService.showOptimizationResults$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.showOptimizationResults = value;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.renderer.removeClass(this.document.body, 'homepage-active');
  }
}
