import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { AppState } from '@portal/app/store/app.state';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { DashboardState } from '@portal/app/store/app.state';
import {
  BaseElement,
  BreadCrumbElement,
  FieldDefinitions,
  Filter
} from '@portal/app/shared/types';
import { ContextStore } from '@portal/app/shared/state/context.store';
import { Required } from '@portal/app/shared/decorators/required.decorator';
import { ActivatedRoute } from '@angular/router';
import { forEach } from 'lodash-es';

@Component({
  selector: 'portal-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss']
})
export class BreadCrumbComponent implements OnInit, OnDestroy {
  @Input() @Required layout!: BaseElement;
  @Input() @Required fieldDefinitions!: FieldDefinitions;
  items: MenuItem[] = [];
  dashboardState$!: Observable<DashboardState>;
  home!: MenuItem;
  brandSlug: string | null = null;
  dashboardLiteralId: string | null = null;
  private readonly homeRouteId = 'homeRoute';
  private readonly subs: Subscription = new Subscription();

  private filterContext$: Observable<Filter[]> =
    this.contextStore.filterContext;

  constructor(
    private router: Router,
    private store: Store<AppState>,
    protected readonly contextStore: ContextStore,
    private activatedRoute: ActivatedRoute
  ) {
    this.dashboardState$ = store.pipe(select('dashboard'));
  }

  ngOnInit() {
    // collection of brandslug on dashboardLiteralId
    this.subs.add(
      this.activatedRoute.params.subscribe((data) => {
        this.brandSlug = data.brandSlug;
        this.dashboardLiteralId = data.literalId;
      })
    );
    this.assignItemsUsingConfig(this.layout.elements as BreadCrumbElement[]);
  }

  // collect elements from config convert & convert them into items, also collect routes from fieldDefinitions.defaultValue
  assignItemsUsingConfig(elements: BreadCrumbElement[]) {
    forEach(elements, (element) => {
      if (element.literalId === this.homeRouteId) {
        this.home = {
          label: this.fieldDefinitions[element.literalId]?.label,
          routerLink: this.attachBrandSlug(
            this.brandSlug,
            this.fieldDefinitions[element.literalId]?.defaultValue
          )
        };
      } else {
        this.items.push({
          label: this.fieldDefinitions[element.literalId]?.label,
          routerLink: this.attachBrandSlug(
            this.brandSlug,
            this.fieldDefinitions[element.literalId]?.defaultValue
          )
        });
      }
    });
  }

  // attach brand slug to routes
  attachBrandSlug(brandSlug: string | null, route = '') {
    if (brandSlug !== null) {
      return `/a/${this.brandSlug}${route}`;
    } else {
      return ``;
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
