@if (
  gridItem && literalId !== "portfolio-level" && type !== "RESULT_DATA_POINT"
) {
  <div class="hero-handle">
    <svg
      width="32"
      height="10"
      viewBox="0 0 32 10"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="5.125" y="2" width="2" height="2"></rect>
      <rect x="9.125" y="2" width="2" height="2"></rect>
      <rect x="5.125" y="6" width="2" height="2"></rect>
      <rect x="9.125" y="6" width="2" height="2"></rect>
      <rect x="13.125" y="2" width="2" height="2"></rect>
      <rect x="17.125" y="2" width="2" height="2"></rect>
      <rect x="21.125" y="2" width="2" height="2"></rect>
      <rect x="13.125" y="6" width="2" height="2"></rect>
      <rect x="17.125" y="6" width="2" height="2"></rect>
      <rect x="21.125" y="6" width="2" height="2"></rect>
      <rect x="24.875" y="2" width="2" height="2"></rect>
      <rect x="24.875" y="6" width="2" height="2"></rect>
    </svg>
  </div>
}
<portal-empty-state
  [pTooltip]="tooltipText"
  tooltipStyleClass="custom-tooltip"
  tooltipPosition="top"
  [escape]="false"
  showDelay="700"
  [showMessage]="showEmptyState"
  message="No data"
  [containerClass]="
    'hero-empty' + (hasPreviousValue() ? ' tooltip-container' : '')
  ">
  <div class="item-content" [ngClass]="type">
    @if (type !== "DATA_POINT_SM") {
      <p class="data-point-label">{{ label }}</p>
    }
    @if (hasBoundedChildren) {
      @if (loading) {
        <p-progressSpinner [style]="{ width: '33px', height: '33px' }"
          >Loading!</p-progressSpinner
        >
      } @else {
        <div
          class="d-flex justify-content-center align-items-center hero-bounded">
          <div class="hero-bounded-item left">
            {{ childrenToShow[0]?.value }}
          </div>
          <h4>{{ valueToShow }}</h4>
          <div class="hero-bounded-item right">
            {{ childrenToShow[1]?.value }}
          </div>
        </div>
      }
    } @else {
      @if (type === "DATA_POINT") {
        <h4 [ngClass]="shouldAddClass() ? 'data-point-value' : ''">
          @if (loading) {
            <p-progressSpinner [style]="{ width: '33px', height: '33px' }"
              >Loading!</p-progressSpinner
            >
          }
          {{ valueToShow }}
        </h4>
      }
      @if (type === "VIEW_DATA_POINT") {
        <h6 [ngClass]="shouldAddClass() ? 'data-view-class' : ''">
          @if (loading) {
            <p-progressSpinner [style]="{ width: '33px', height: '33px' }"
              >Loading!</p-progressSpinner
            >
          }
          {{ valueToShow }}
        </h6>
      }
      @if (hasPreviousValue()) {
        <portal-previous-value
          [value]="previousValueToShow"
          [isUp]="isUp"></portal-previous-value>
      }
      @if (type === "DATA_POINT_SM") {
        <h6>
          @if (loading) {
            <p-progressSpinner [style]="{ width: '23px', height: '23px' }"
              >Loading!</p-progressSpinner
            >
          }
          {{ valueToShow }}
        </h6>
      }
      @if (type === "RESULT_DATA_POINT") {
        <h6 class="fw-boldish">
          @if (loading) {
            <p-progressSpinner [style]="{ width: '33px', height: '33px' }"
              >Loading!</p-progressSpinner
            >
          }
          {{ valueToShow }}
        </h6>
      }
    }
    <ng-template #hero>
      @if (type === "DATA_POINT") {
        <h4 [ngClass]="shouldAddClass() ? 'data-point-value' : ''">
          @if (loading) {
            <p-progressSpinner [style]="{ width: '33px', height: '33px' }"
              >Loading!</p-progressSpinner
            >
          }
          {{ valueToShow }}
        </h4>
      }
      @if (type === "VIEW_DATA_POINT") {
        <h6 [ngClass]="shouldAddClass() ? 'data-view-class' : ''">
          @if (loading) {
            <p-progressSpinner [style]="{ width: '33px', height: '33px' }"
              >Loading!</p-progressSpinner
            >
          }
          {{ valueToShow }}
        </h6>
      }
      @if (hasPreviousValue()) {
        <portal-previous-value
          [value]="previousValueToShow"
          [isUp]="isUp"></portal-previous-value>
      }
      @if (type === "DATA_POINT_SM") {
        <h6>
          @if (loading) {
            <p-progressSpinner [style]="{ width: '23px', height: '23px' }"
              >Loading!</p-progressSpinner
            >
          }
          {{ valueToShow }}
        </h6>
      }
      @if (type === "RESULT_DATA_POINT") {
        <h6 class="fw-boldish">
          @if (loading) {
            <p-progressSpinner [style]="{ width: '33px', height: '33px' }"
              >Loading!</p-progressSpinner
            >
          }
          {{ valueToShow }}
        </h6>
      }
    </ng-template>
    @if (type === "DATA_POINT_SM") {
      <p class="data-point-label">{{ label }}</p>
    }
  </div>
</portal-empty-state>
