<!-- Popup -->
@if (
  visibilityType === notificationViewMode.popup && notificationCenterVisible
) {
  <div class="notification-center-wrapper">
    <m-notification-center
      #notificationCenter
      [topListAlertsOriginal]="actionAlerts"
      [bottomListAlertsOriginal]="infoAlerts"
      [stackedAlertsOriginal]="stackedAlertsData"
      [isLoading]="alertsDataLoading"
      (viewAll)="navigateToNotificationCenter($event)"
      (handleAction)="navigateToActionAlertLink($event)"
      [unreadAlertIds]="unreadIds"
      [resolvedAlertIds]="resolvedIds"
      [unreadInfoAlertsCount]="unreadInfoAlertIds.length"
      (requestStackedAlertsData)="requestStackedAlerts($event)"
      (updateAlertStatusAsRead)="markSelectedAlertsAsRead([$event], 'popup')"
      (updateAlertStatusAsResolved)="
        updateAlertStatusAsResolved($event, 'popup')
      ">
    </m-notification-center>
  </div>
}

<!-- Page -->
@if (visibilityType === notificationViewMode.page) {
  <div>
    <m-layout>
      <div #navbarContent navbar-content>
        <div class="flex justify-between items-center h-full">
          <span class="h4 text-orange-300 mb-0">Notification Center</span>
          <m-button-group
            id="notification-tab-status"
            [options]="tabOptions"
            [(selectedOption)]="selectedTabOption"
            (selectedOptionChange)="setSelectedTab($event)">
          </m-button-group>
        </div>
      </div>

      <div main-content>
        <m-card
          class="d-block card-large"
          [isDraggable]="false"
          [isHoverable]="false"
          [isExpandable]="true">
          <div class="pb-4">
            <div class="flex justify-between">
              <!-- Button Section -->
              <div class="flex justify-between">
                @if (currentView === currentAlertsViewType.stacked) {
                  <div class="flex">
                    <m-button
                      [type]="'secondary'"
                      size="small"
                      (click)="goToNormalView()"
                      [style]="{ width: '6.25rem' }">
                      <i
                        class="material-symbols-outlined icon-small text-blue-600"
                        aria-hidden="true"
                        >chevron_left</i
                      >
                      <span class="b2"> Go Back </span>
                    </m-button>
                  </div>
                } @else {
                  <m-button
                    [type]="'secondary'"
                    size="small"
                    [disabled]="!enableMarkAsRead"
                    (click)="markSelectedAlertsAsRead([], null)">
                    <span class="b2"> Mark as read </span>
                  </m-button>
                }
              </div>
              @if (currentView === currentAlertsViewType.normal) {
                <!-- Filter section -->
                <portal-notification-center-filters
                  (filterChange)="
                    updateFilters($event)
                  "></portal-notification-center-filters>
              }
            </div>
          </div>

          <!-- Table section -->
          <m-row-group-table
            [value]="tableData"
            [columns]="columnFields"
            [scrollable]="true"
            [groupByFieldsConfigs]="groupByFieldsConfigs"
            [fieldNametoGroupBy]="'type'"
            (handleAction)="handleActionButtonClick($event)"
            [sortFields]="['title', 'tag', 'timestamp']"
            [enableRowSelectionCheckbox]="showSelectableRow"
            (selectedRows)="processSelectedAlertsUpdate($event)"
            (tableState)="saveTableState($event)"
            [enableTableHeaderCheckbox]="unreadIds.length > 0"
            (requestStackedDataForTable)="requestStackedAlerts($event)"
            [tableDataLoading]="alertsDataLoading"
            [searchInputHasData]="!!alertFilters.search"
            (alertReadEvent)="markSelectedAlertsAsRead([$event], 'table')"
            (resolveActionAlertEvent)="
              updateAlertStatusAsResolved($event, 'table')
            "
            [selectedRowsData]="selectedRowsData"></m-row-group-table>
        </m-card>
      </div>
    </m-layout>
  </div>
}
