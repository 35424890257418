<div class="fixed left-0 bottom-0 w-full" [style.z-index]="zIndex">
  <div class="m-bottom-panel">
    <div class="flex items-center gap-4">
      @if (message.length) {
        <div class="flex items-center gap-2">
          <span
            class="flex message-icon-{{
              messageType
            }} material-symbols-outlined icon-regular"
            >{{ messageIcon }}</span
          >
          <span class="b1 message-text-{{ messageType }}">{{ message }}</span>
        </div>
      }
      @if (leftActionButtons.length) {
        @for (leftActionButton of leftActionButtons; track leftActionButton) {
          <m-button
            [type]="leftActionButton.type"
            [disabled]="!!leftActionButton.disabled"
            (click)="publishButtonClick(leftActionButton, $event)">
            <span class="b2">{{ leftActionButton.label }}</span>
          </m-button>
        }
      }
    </div>
    <div class="flex items-center gap-4">
      @if (connectedPoints && connectedPoints.totalPoints > 0) {
        <span class="b1 text-gray-800">{{ connectedPointsMessage }}</span>
      }
      @for (rightActionButton of rightActionButtons; track rightActionButton) {
        @if (rightActionButton.id.includes("skeletal")) {
          <p-skeleton width="4rem" height="2rem"></p-skeleton>
        } @else {
          <m-button
            [type]="rightActionButton.type"
            [disabled]="!!rightActionButton.disabled"
            (click)="publishButtonClick(rightActionButton, $event)">
            <span class="b2">{{ rightActionButton.label }}</span>
          </m-button>
        }
      }
    </div>
  </div>
</div>
