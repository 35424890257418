import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { CurrencyService } from '../currency.service';

@Pipe({ name: 'customCurrency' })
export class CustomCurrencyPipe implements PipeTransform {
  constructor(
    private readonly currencyService: CurrencyService,
    private currencyPipe: CurrencyPipe
  ) {}

  transform(value: string | number | null, digitsInfo = '1.0-0') {
    const currencyConfig = this.currencyService.currencyConfig;
    return this.currencyPipe.transform(
      value,
      currencyConfig.code,
      currencyConfig.display,
      digitsInfo,
      currencyConfig.locale
    );
  }
}
