<div class="row notes-wrapper">
  <textarea
    class="notes-text-area"
    rows="10"
    [disabled]="!isEditMode"
    ngDefaultControl
    [(ngModel)]="notes"></textarea>
  @if (hasEditPermission) {
    @if (!isEditMode) {
      <div class="btn-container">
        <button
          pButton
          label="Edit"
          class="p-button-outlined"
          (click)="editNote()"></button>
      </div>
    } @else {
      <div class="btn-container">
        <button
          pButton
          label="Cancel"
          class="p-button-outlined"
          (click)="cancelChanges()"></button>
        <button
          pButton
          label="Save"
          class="p-button-primary"
          (click)="saveChanges()"></button>
      </div>
    }
  }
</div>
