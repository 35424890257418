/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, input, Output } from '@angular/core';
import {
  AutoCompleteCompleteEvent,
  AutoCompleteSelectEvent
} from 'primeng/autocomplete';

@Component({
  selector: 'portal-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrl: './autocomplete.component.scss'
})
export class AutoCompleteComponent {
  placeholder = input<string>();
  showClear = input<boolean>(true);
  options = input<any[]>([]);
  optionLabel = input.required<string>();

  suggestions: any[] = [];

  @Output() selectItem = new EventEmitter<any>();

  completeMethod(event: AutoCompleteCompleteEvent) {
    const query = event.query.toLowerCase();
    this.suggestions = this.options().filter((option) => {
      if (this.optionLabel()) {
        return option[this.optionLabel()]?.toLowerCase()?.includes(query);
      }
      return option.toLowerCase()?.includes(query);
    });
  }

  select(event: AutoCompleteSelectEvent) {
    this.selectItem.emit(event.value);
  }
}
