<div class="m-table">
  <p-table
    #table
    [columns]="
      user && user.isSuperUser(selectionService)
        ? ['Client ID', 'Brand ID', 'Brand']
        : ['Brand']
    "
    [loading]="loading"
    selectionMode="single"
    [scrollable]="true"
    scrollDirection="vertical"
    scrollHeight="40vh"
    sortField="Brand"
    [customSort]="true"
    (sortFunction)="customSort($event)"
    [value]="rows | async | sortBy: true : 'asc' : 'name'"
    [globalFilterFields]="['clientId', 'brandId', 'name']"
    styleClass="p-datatable-striped px-8">
    <ng-template pTemplate="caption">
      <div class="d-flex justify-content-end mb-4">
        <span class="p-input-icon-right ms-auto">
          <em class="pi pi-search me-2"></em>
          <input
            pInputText
            type="text"
            (input)="table.filterGlobal($event.target.value, 'contains')"
            placeholder="Search"
            [autofocus]="true" />
        </span>
      </div>
    </ng-template>
    <ng-template
      *ngIf="user && user.isSuperUser(selectionService)"
      pTemplate="header"
      let-cols>
      <tr>
        <th *ngFor="let col of cols" [pSortableColumn]="col" scope="col">
          {{ col }}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-cols="columns">
      <tr [pSelectableRow]="rowData" (click)="switchBrand(rowData, user)">
        <td *ngFor="let col of cols">
          <ng-container *ngIf="col === 'Client ID'">
            {{ rowData.clientId }}
          </ng-container>
          <ng-container *ngIf="col === 'Brand ID'">
            {{ rowData.brandId }}
          </ng-container>
          <ng-container *ngIf="col === 'Brand'">
            {{ rowData.name }}
          </ng-container>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
