import { inject, Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { toSignal } from '@angular/core/rxjs-interop';
import { catchError, Observable, of, tap } from 'rxjs';
import { environment } from '@portal/environments/environment';
import { ViewByDTO } from '@portal/app/dashboard/context-modal/context-model.types';
import { ViewStore } from '@portal/app/shared/state/view.store';

@Injectable({
  providedIn: 'root'
})
export class ViewByBrandService {
  public static baseUriV2 = `${environment.apiDomain}/api/v2/dataviz/config`;

  private readonly http: HttpClient = inject(HttpClient);
  private readonly viewStore: ViewStore = inject(ViewStore);

  private initialValue = {} as ViewByDTO;

  public isLoadingSignal = signal<boolean>(false);
  public data = toSignal(this.fetchData$(), {
    initialValue: this.initialValue
  });

  get isLoading() {
    return this.isLoadingSignal.asReadonly();
  }

  fetchData$(): Observable<ViewByDTO> {
    const { clientId, brandId } = this.viewStore.selectedBCPDId.getValue();
    const url = `${ViewByBrandService.baseUriV2}/view-by-brand?clientId=${clientId}&brandId=${brandId}`;

    return this.http.get<ViewByDTO>(url).pipe(
      tap(() => this.isLoadingSignal.set(true)),
      catchError((error) => {
        console.error('Error fetching view by brands', error);
        this.isLoadingSignal.set(false);
        return of({ ...this.initialValue });
      }),
      tap(() => this.isLoadingSignal.set(false))
    );
  }
}
