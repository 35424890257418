import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { cloneDeep, forEach, some } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';
import {
  ContextField,
  DataEndpoint,
  DataGridEditable,
  DataGridSelected,
  DataResponse,
  DataSetResponse,
  FieldDefinition,
  FieldDefinitions,
  Filter,
  FilterValue,
  AssociatedFilterValue,
  DataGridInfoWithIcon,
  DataGridAvailable,
  DataGridNavigation,
  ElementSubType,
  ElementTyping,
  ALIGNMENT,
  DefaultValue,
  LayoutTypes,
  DataRowData
} from '@portal/app/shared/types';
import { ApiService } from '@portal/app/shared/services/api.service';
import { FormatterService } from '@portal/app/shared/services//formatter.service';
import { ContextStore } from '@portal/app/shared/state/context.store';
import { Required } from '@portal/app/shared/decorators/required.decorator';
import { FiltersService } from '@portal/app/shared/services/filters.service';
import { DataGridService } from '@portal/app/datagrid/services/data-grid.service';
import { PreviousPeriodService } from '@portal/app/shared/services/previous-period.service';
import { CompareService } from '@portal/app/shared/services/compare.service';
import { SimpleChanges, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ICompositeCellConfig } from '@portal/app/shared/models/ICompositeCellConfig';
import { MultiViewStore } from '@portal/app/shared/state/multi-view.store';
import { Store } from '@ngrx/store';
import { AppState } from '@portal/app/store/app.state';
import { MultiViewActions } from '@portal/app/store/multi-view/actions';
import { isMeasuredBenchmarkLandingPage } from '@portal/app/dashboard/utils';
import { DataGridStore } from '@portal/app/datagrid/store/data-grid.store';

@Component({
  // disabling this to avoid rendering component header inside table
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[portal-data-grid-row]',
  templateUrl: './data-grid-row.component.html'
})
export class DataGridRowComponent implements OnInit, OnDestroy, OnChanges {
  @Input() @Required cols!: (
    | DataGridSelected
    | DataGridEditable
    | DataGridInfoWithIcon
  )[];

  @Input() @Required fieldDefinitions!: FieldDefinitions;
  @Input() @Required rowData!: DataResponse;
  @Input() @Required name!: string;
  @Input() editableFields: ContextField[] = [];
  @Input() rowIndex = 0;
  @Input() showExpansionArrow: null | boolean = null;
  @Input() filterSet: Filter[] = [];
  @Input() isRowFrozen = true;
  @Input() frozenColumnsEnabled = true;
  @Input() highlightColumns: string[] = [];
  @Input() shouldShowEditableInc = false;
  @Input() dashboardLiteralId = '';
  @Input() allowSelection = false;

  @Input() dimensionFormat?: string = '';
  @Input() widthSpecificToFields?: Record<string, number> = {};
  @Input() widthSpecificToFieldsUnit = DataGridStore.widthSpecificToFieldsUnit;
  @Output() rowDataChanged = new EventEmitter<DataSetResponse>();
  @Output() expansionArrowToggled = new EventEmitter<boolean>();
  filterSetClone: Filter[] = [];
  filters: Filter[] = [];
  benchmarkFilterValue?: string;
  brandSlug: string | null = null;
  public navigationLinks: Record<string, string> = {} as Record<string, string>;

  public loading = false;
  public layoutTypes = LayoutTypes;
  public elementTypes = ElementTyping;
  private readonly subs: Subscription = new Subscription();
  private originalRowData: DataResponse | undefined;
  private incSettingIndexName = 'incrementalitySetting';
  private incSettingMaxValue = 999999;
  private viewId?: number;

  filterContext$: Observable<Filter[]> = this.contextStore.filterContext;

  constructor(
    private readonly formatterService: FormatterService,
    private readonly contextStore: ContextStore,
    private readonly apiService: ApiService,
    public readonly dataGridService: DataGridService,
    private readonly filtersService: FiltersService,
    private readonly previousPeriodService: PreviousPeriodService,
    private readonly compareService: CompareService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly multiViewStore: MultiViewStore,
    private store: Store<AppState>,
    private readonly route: ActivatedRoute
  ) {}

  private static createFilter(
    literalId: string,
    type: 'string' | 'string[]' | 'number',
    value: FilterValue
  ): Filter {
    return {
      id: -1,
      dashboardId: -1,
      name: literalId,
      literalId,
      label: '',
      type,
      isRequired: 'YES',
      value,
      format: 'none',
      options: null,
      applyTo: ['DATA_SET']
    };
  }

  ngOnInit(): void {
    this.filterSetClone = cloneDeep(this.filterSet) || [];
    this.dataGridService.setGroupedColumns(this.cols);
    this.filterSetClone.forEach((filter) => {
      if (Object.keys(this.rowData).indexOf(filter.literalId) > -1) {
        if (this.rowData[filter.literalId] != null) {
          filter.value = this.rowData[filter.literalId] as DataRowData;
        }
      }
    });

    this.subs.add(
      this.filtersService.dataGridFilter.subscribe((gridFilters) => {
        this.benchmarkFilterValue = gridFilters
          .filter(
            (f: Filter) => f?.literalId === 'benchmark_metrics_flag_filter'
          )?.[0]
          ?.value?.toString();
      })
    );

    // check if navigation columns exist and generate links for each based on dashboard
    this.navigationLinks = this.generateNavigationLinks(
      this.dashboardLiteralId,
      this.cols
    );

    this.multiViewStore.currentView$.subscribe((currentView) => {
      this.viewId = currentView ? Number(currentView.viewId) : undefined;
    });
  }

  addCharacterInParentheses(
    value1: string | number | undefined,
    value2: DataResponse
  ): string {
    const text = value2.optimizedCappedAllocationSetting
      ? value2.optimizedCappedAllocationSetting
      : value2.anchorAllocationSetting;
    if (text === 'Reference Period %' || text === 'Fixed %') {
      return (
        (value1 === 'Anchored' ? value1 + ' ()' : value1) as string
      ).replace(')', value1 === 'Anchored' ? '%)' : ' %)');
    } else {
      return (
        (value1 === 'Anchored' ? value1 + ' ()' : value1) as string
      ).replace(')', value1 === 'Anchored' ? '$)' : ' $)');
    }
  }

  generateNavigationLinks(
    dashboardLiteralId: string,
    cols: (
      | DataGridAvailable
      | DataGridEditable
      | DataGridSelected
      | DataGridNavigation
      | DataGridInfoWithIcon
    )[]
  ): Record<string, string> {
    this.subs.add(
      this.activatedRoute.params.subscribe((data) => {
        this.brandSlug = data.brandSlug;
      })
    );
    forEach(cols, (col) => {
      if (col.layoutType === 'NAVIGATION_COLUMN') {
        if (isMeasuredBenchmarkLandingPage(dashboardLiteralId)) {
          const activeStatus = '"Active"';
          const inactiveStatus = '["New", "Inactive"]';
          const activeStatusValue = 'Active';
          const customQueryParams: Record<string, string | string[]> = {
            type: `"${this.rowData.type}"`,
            // if metric name status is not active it needs to be set as both new and inactive
            // eslint-disable-next-line @typescript-eslint/naming-convention
            metric_name_status:
              this.rowData.status === activeStatusValue
                ? activeStatus
                : inactiveStatus
          };

          if (this.rowData.type && this.rowData.typeId) {
            customQueryParams[this.rowData.type] = `"${
              this.rowData[this.rowData.typeId]
            }"`;
          }
          if (this.rowData.type === 'tactic') {
            customQueryParams.channel = `"${this.rowData.channel}"`;
          }
          if (this.rowData.type === 'channel') {
            customQueryParams.tactic = `""`;
          }
          // benchmarks navigation column needs to redirect user to drill down dashboard with specific qeury params
          this.navigationLinks[col.dashboardFieldId] = this.router
            .createUrlTree(
              [
                this.attachBrandSlug(
                  this.brandSlug,
                  this.fieldDefinitions.navigationParams?.defaultValue as string
                )
              ],
              {
                queryParams: customQueryParams,
                queryParamsHandling: 'merge'
              }
            )
            .toString();
        }
      }
    });
    return this.navigationLinks;
  }

  // attach brand slug to routes
  attachBrandSlug(brandSlug: string | null, route: string) {
    if (brandSlug !== null) {
      return `/a/${this.brandSlug}${route}`;
    } else {
      return ``;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.rowData) {
      this.originalRowData = cloneDeep(this.rowData);
    }
  }

  format(field: FieldDefinition, value: string | number): string {
    return this.formatterService.formatPartialValue(field, value);
  }

  onDataChange(): void {
    this.subs.add(
      this.filterContext$.subscribe({
        next: (filterSet) => {
          const filters = cloneDeep(filterSet);
          forEach(this.cols, (column) => {
            if (column.type === 'DIMENSION') {
              const filter = filters.find(
                (f) => f.literalId === column.dashboardFieldId
              );
              if (filter != null) {
                filter.value = this.rowData[
                  column.dashboardFieldId
                ] as DataRowData;
              } else {
                filters.push(
                  DataGridRowComponent.createFilter(
                    column.dashboardFieldId,
                    'string',
                    this.rowData[column.dashboardFieldId] as DataRowData
                  )
                );
              }
            }
          });
          filters.push(
            DataGridRowComponent.createFilter('dataSets', 'string[]', [
              this.name
            ])
          );
          this.loading = true;
          this.apiService
            .getDashboardData({
              filters,
              type: DataEndpoint.dataSets,
              dimensionFormat: this.dimensionFormat
            })
            .then((response) => {
              if (
                typeof response === 'object' &&
                Object.keys(response).includes('error')
              ) {
                this.loading = false;
              } else {
                const typedResponse = response as DataSetResponse[];
                const newRowData = typedResponse[0];
                this.rowDataChanged.emit(newRowData);
                if (newRowData?.data.length === 1) {
                  this.rowData = newRowData.data[0] as DataResponse;
                  this.loading = false;
                } else {
                  this.loading = false;
                }
              }
            });
        }
      })
    );
  }

  hasPreviousPeriod(col: DataGridSelected) {
    if (
      !this.compareService.compareIsAvailable ||
      !this.compareService.compare
    ) {
      return false;
    }
    return PreviousPeriodService.datagridCompareFields.includes(
      col.dashboardFieldId
    );
  }

  getPreviousPeriodValue(col: DataGridSelected) {
    const field = this.fieldDefinitions[col.dashboardFieldId];
    if (field) {
      return this.previousPeriodService.getDifferencePercentFormattedValue(
        this.rowData,
        field
      );
    }
    return '';
  }

  getUpPreviousValue(col: DataGridSelected) {
    const field = this.fieldDefinitions[col.dashboardFieldId];
    if (field) {
      return this.previousPeriodService.isUp(this.rowData, field);
    }
    return '';
  }

  getTooltip(col: DataGridSelected) {
    if (!this.compareService.compare) {
      return '';
    }
    if (
      PreviousPeriodService.datagridCompareFields.includes(col.dashboardFieldId)
    ) {
      const field = this.fieldDefinitions[col.dashboardFieldId];
      if (field && field.literalId) {
        const value = this.rowData[field.literalId];
        const formattedValue = this.rowData?.isMissingInCurrentPeriod
          ? '-'
          : this.format(field, value || 0);
        return this.previousPeriodService.getDataGridTooltip(
          formattedValue,
          this.rowData,
          field
        );
      }
    }
    return '';
  }

  asNumber(value: unknown): number {
    return value as number;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  emitRowExpansionClick(toggleVal: boolean): void {
    this.showExpansionArrow = toggleVal;
    this.expansionArrowToggled.emit(toggleVal);
  }

  onIncrementalitySettingChange(colId: string): void {
    if (
      this.rowData[colId] &&
      typeof this.rowData[colId] === 'number' &&
      this.isIncSettingMaxLimit(this.rowData[colId] as number)
    ) {
      if (this.originalRowData) {
        this.rowData[colId] = this.originalRowData?.[colId] as DataRowData;
      }
    } else if (this.rowData[colId] !== this.originalRowData?.[colId]) {
      const assocFilter: AssociatedFilterValue = {};
      const conversionType = this.filterSet.find(
        (f) => f.literalId === 'conversion_type'
      );
      assocFilter[colId] = this.rowData[colId] as DataRowData;
      assocFilter.tactic = this.rowData?.tactic as DataRowData;
      assocFilter.channel = this.rowData?.channel as DataRowData;
      assocFilter.conversion_type = conversionType?.value as FilterValue;
      if (this.multiViewStore.showChild) {
        assocFilter.tactic = this.multiViewStore.tactic;
        assocFilter.channel = this.multiViewStore.channel;
        assocFilter.campaign = this.rowData?.campaign as DataRowData;
      }
      this.contextStore.changeAssociatedFilterValues(
        this.incSettingIndexName,
        assocFilter,
        this.multiViewStore.showChild
      );
    }
  }

  getIncrementalitySettingVisibility(): boolean {
    const assocFilter = this.contextStore.associatedFilterSelection;
    const incSettingsFilters = assocFilter[this.incSettingIndexName];
    if (incSettingsFilters && Array.isArray(incSettingsFilters)) {
      const conversionType = this.filterSet.find(
        (f) => f.literalId === 'conversion_type'
      );
      return some(incSettingsFilters, (setting) => {
        if (this.multiViewStore.showChild) {
          return (
            setting.conversion_type === conversionType?.value &&
            setting.channel === this.multiViewStore.channel &&
            setting.tactic === this.multiViewStore.tactic &&
            setting.campaign === this.rowData?.campaign
          );
        } else {
          return (
            setting.conversion_type === conversionType?.value &&
            setting.channel === this.rowData?.channel &&
            setting.tactic === this.rowData?.tactic
          );
        }
      });
    }
    return false;
  }

  onRevertIncrementalitySettingChanges(): void {
    const assocFilter = this.contextStore.associatedFilterSelection;
    const incSettingsFilters = assocFilter[this.incSettingIndexName];
    let filterFound: AssociatedFilterValue | undefined;
    if (incSettingsFilters && Array.isArray(incSettingsFilters)) {
      const conversionType = this.filterSet.find(
        (f) => f.literalId === 'conversion_type'
      );
      incSettingsFilters.forEach((setting) => {
        if (this.multiViewStore.showChild) {
          if (
            setting.conversion_type === conversionType?.value &&
            setting.channel === this.multiViewStore.channel &&
            setting.tactic === this.multiViewStore.tactic &&
            setting.campaign === this.rowData?.campaign
          ) {
            filterFound = setting;
          }
        } else {
          if (
            setting.conversion_type === conversionType?.value &&
            setting.channel === this.rowData?.channel &&
            setting.tactic === this.rowData?.tactic
          ) {
            filterFound = setting;
          }
        }
      });
    }
    if (filterFound) {
      this.contextStore.removeAssociatedFilterValues(
        this.incSettingIndexName,
        filterFound
      );
    }
  }

  isIncSettingMaxLimit(val: number): boolean {
    return val > this.incSettingMaxValue;
  }

  asCellLayoutType(subType: string): string {
    let cellLayout = '';
    switch (subType) {
      case 'metric':
        cellLayout = 'primary';
        break;
      case 'benchmark':
        cellLayout = 'secondary';
        break;
      default:
        break;
    }
    return cellLayout;
  }

  getCompositeCellConfig(
    col: ElementSubType,
    rowData: DataResponse
  ): ICompositeCellConfig | undefined {
    const compositeCellConfig: ICompositeCellConfig = {
      subtype: null,
      fields: [],
      id: '',
      data: rowData
    };
    if (col.type === ElementTyping.dimension) {
      const fieldIds = col.additionalAttributes
        ? col.additionalAttributes.multiDashboardFieldIds
        : [col.dashboardFieldId];
      compositeCellConfig.fields = (fieldIds as string[]).map((f: string) => {
        return {
          id: f,
          emptyValue: DefaultValue.dash,
          alignment: ALIGNMENT.vertical
        };
      });
      return compositeCellConfig;
    }
    return undefined;
  }

  navigateToChildPage(): void {
    const { queryParams } = this.route.snapshot;
    this.store.dispatch(
      MultiViewActions.navigateToChild({
        viewId: queryParams.viewId,
        rowData: this.rowData,
        filters: this.filterSet
      })
    );
  }

  get showCellTooltip(): boolean | null {
    const dashboardsWithNoTooltips = [
      'measured-benchmarks',
      'ddi-reporting-revenue'
    ];
    return !dashboardsWithNoTooltips.includes(this.dashboardLiteralId);
  }
}
