import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import { takeUntil } from 'rxjs/operators';

// Services
import { CompareChartService } from './compare-chart.service';

// types
import type { Metric } from '@portal/app/dashboard/home-page/services/metrics.service';
import type { ProgressBarComparisonOptions } from '@design-system/components/m-progress-bar-comparison/m-progress-bar-comparison.component.types';

@Component({
  selector: 'portal-compare-chart',
  templateUrl: './compare-chart.component.html'
})
export class CompareChartComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  public isLoading = true;
  public metric?: Metric | null;
  public selectedCompareOption = 'Previous Period';
  public comparisonOptions: ProgressBarComparisonOptions | undefined;

  constructor(private compareChartService: CompareChartService) {}

  get isChartReady(): boolean {
    return !this.isLoading && !!this.comparisonOptions;
  }

  ngOnInit() {
    this.subscribeToData();
  }

  private subscribeToData() {
    this.compareChartService.isLoading$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoading) => {
        this.isLoading = isLoading;
      });

    this.compareChartService.activeMetric$
      .pipe(takeUntil(this.destroy$))
      .subscribe((metric) => {
        this.metric = metric;
      });

    this.compareChartService.chartData$
      .pipe(takeUntil(this.destroy$))
      .subscribe((chartData) => {
        this.comparisonOptions = chartData;
      });

    this.compareChartService.selectedCompareOption$
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedCompareOption) => {
        this.selectedCompareOption = selectedCompareOption;
      });
  }

  get isNotTotalMetric(): boolean {
    return (
      this.metric?.literalId !== 'totalSales' &&
      this.metric?.literalId !== 'totalOrders'
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
