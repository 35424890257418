<div class="w-full h-full">
  <!-- Conditionally render the empty state if no data is available -->
  <m-not-available *ngIf="!isDataAvailable && !isLoading"></m-not-available>
  <!-- Conditionally render the chart component when data is ready -->
  <m-line-chart
    *ngIf="isDataAvailable && isChartReady"
    [options]="multiLineChartOptions"
    class="w-full"
    [height]="350"></m-line-chart>
  <!-- Loading state -->
  <div *ngIf="isLoading">
    <m-skeleton width="100%" height="18.75rem"></m-skeleton>
  </div>
</div>
