<div *ngIf="item?.elements" class="row">
  <ng-container *ngFor="let element of item.elements">
    <ng-container [ngSwitch]="element.layoutType">
      <portal-view-controls
        *ngSwitchCase="'VIEW_CONTROLS'"
        [item]="element"
        [ngClass]="element.span ? 'col-md-' + element.span : ''">
      </portal-view-controls>

      <ng-container *ngSwitchCase="'VIEW_CARDS'">
        <ng-container
          [ngTemplateOutlet]="viewCardGroup"
          [ngTemplateOutletContext]="{ item: element }">
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #viewCardGroup let-item="item">
  <ng-container
    *ngIf="{
      isLoadingViewList: isLoadingViewList$ | async,
      filteredViews: filteredViews$ | async,
      loadingViewsIds: loadingViewsIds$ | async,
    } as obs">
    <ng-container *ngIf="obs.isLoadingViewList; else elseTemplate">
      <div class="centre-view-box">
        <p-progressSpinner
          animationDuration="0.7s"
          styleClass="custom-spinner"></p-progressSpinner>
      </div>
    </ng-container>
    <ng-template #elseTemplate>
      <portal-empty-state
        class="view-card-wrapper"
        [showMessage]="obs.filteredViews.length === 0">
        <ng-container *ngFor="let data of obs.filteredViews">
          <portal-view-card
            [item]="item"
            [id]="data.storeId"
            [view]="data"
            [fieldDefinitions]="fieldDefinitions"
            [isLoading]="obs.loadingViewsIds[data.storeId]">
          </portal-view-card>
        </ng-container>
      </portal-empty-state>
    </ng-template>
  </ng-container>
</ng-template>
