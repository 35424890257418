<m-card>
  <!-- Filters -->
  <div
    header
    class="flex flex-col 2xl:flex-row justify-between items-start 2xl:items-center w-full">
    <div class="flex items-center">
      <span class="sh2 mr-2">Compare</span>

      <m-dropdown
        class="mr-2"
        [options]="metricOptions"
        [(ngModel)]="compare"
        (formControlChange)="onCompareChange($event?.value)"
        [disabled]="isLoading"></m-dropdown>
      <span class="c1 mr-2">vs</span>
      <m-dropdown
        class="mr-2"
        [(ngModel)]="compare2"
        (formControlChange)="onCompare2Change($event?.value)"
        [options]="metricOptions"
        [disabled]="isLoading"></m-dropdown>
    </div>

    <m-dropdown
      class="min-w-[200px] mt-2 2xl:!mt-0"
      [options]="relativeDayOptions"
      [(ngModel)]="relativeDay"
      (formControlChange)="onRelativeDayChange($event?.value)"
      [disabled]="isLoading"></m-dropdown>
  </div>

  <div class="mt-4">
    @if (!isChartReady) {
      <m-skeleton width="100%" height="300px"></m-skeleton>
    }
    <!-- Loading state -->

    <!-- Error state -->
    @if (!isLoading && !isDataAvailable) {
      <m-not-available></m-not-available>
    }

    <!-- Chart -->
    @if (isChartReady && isDataAvailable) {
      <m-line-chart
        [series]="multiLineChartOptions?.series"
        [xAxis]="multiLineChartOptions?.xAxis"
        [yAxis]="multiLineChartOptions?.yAxis"
        [plotOptions]="multiLineChartOptions?.plotOptions"
        [tooltip]="multiLineChartOptions?.tooltip"
        [legend]="multiLineChartOptions?.legend"
        class="w-full">
      </m-line-chart>
    }
  </div>
</m-card>
