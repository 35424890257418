import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@portal/environments/environment';
import { map, Observable } from 'rxjs';
import { SelectionService } from './selection.service';
import { ConversionTypeResponseDTO } from '@libs/api';
import { IConversionTypeByRollUp } from '@portal/app/shared/models/IConversionTypeByRollUp';

@Injectable({
  providedIn: 'root'
})
export class ConversionTypeService {
  private readonly http: HttpClient = inject(HttpClient);
  private selectionService = inject(SelectionService);

  getConversionTypes(): Observable<IConversionTypeByRollUp> {
    const { clientId, brandId } = this.selectionService.getClientIdAndBrandId();
    const params = new HttpParams()
      .set('clientId', clientId)
      .set('brandId', brandId)
      .set('status', 'ACTIVE');
    const url = `${environment.apiDomain}/api/v1/conversion-types`;
    return this.http.get<ConversionTypeResponseDTO[]>(url, { params }).pipe(
      map((res) =>
        res.reduce(
          (acc, ct) => {
            if (this.shouldBeRollUpConversionType(ct)) {
              acc.rollupConversionTypes.push(ct.conversionType);
            } else {
              acc.nonRollupConversionType.push(ct.conversionType);
            }
            return acc;
          },
          {
            rollupConversionTypes: [] as string[],
            nonRollupConversionType: [] as string[]
          }
        )
      )
    );
  }

  private shouldBeRollUpConversionType(ct: ConversionTypeResponseDTO) {
    return ct.isRollup;
  }
}
