import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterType, IFilterData } from './m-text-field.component.types';

@Component({
  selector: 'm-text-field',
  templateUrl: './m-text-field.component.html',
  styleUrls: ['./m-text-field.component.scss']
})
export class MTextFieldComponent implements OnInit {
  @Input() disabled = false;
  @Input() filterData: IFilterData = {
    contain: [],
    exclude: []
  };

  @Output() search = new EventEmitter<IFilterData>();

  searchValue = '';
  totalKeywords = 0;
  showFilterMenu = false;
  filterType = FilterType;

  filterValues = [
    {
      label: FilterType.contain,
      iconClass: 'add',
      items: [] as string[]
    },
    {
      label: FilterType.exclude,
      iconClass: 'remove',
      items: [] as string[]
    }
  ];

  ngOnInit() {
    if (this.filterData) {
      this.filterValues
        .filter((filter) => filter.label === FilterType.contain)
        .forEach((filter) => (filter.items = this.filterData.contain || []));

      this.filterValues
        .filter((filter) => filter.label === FilterType.exclude)
        .forEach((filter) => (filter.items = this.filterData.exclude || []));

      this.updateComponentState();
    }
  }

  addFilterValue(filterType: FilterType) {
    const searchValue = this.searchValue;

    if (
      this.isNil(searchValue) ||
      !searchValue.length ||
      this.isKeywordPresentInAnyGroup(searchValue)
    ) {
      return;
    }

    const filterGroup = this.filterValues.find(
      (item) => item.label === filterType
    );

    filterGroup?.items?.push(searchValue);

    this.searchValue = '';
    this.updateComponentState();
  }

  private isKeywordPresentInAnyGroup(searchValue: string) {
    return this.filterValues
      .flatMap((group) => group.items)
      .includes(searchValue);
  }

  private updateComponentState() {
    this.totalKeywords = this.filterValues.flatMap(
      (filter) => filter.items
    ).length;
  }

  removeFilterValue(value: string | null, groupLabel: FilterType) {
    if (!value) {
      return;
    }

    const filterGroup = this.filterValues.find(
      (filter) => filter.label === groupLabel
    );

    if (filterGroup) {
      const index = filterGroup.items.findIndex((item) => item === value);
      filterGroup.items.splice(index, 1);
    }

    this.updateComponentState();
    this.showFilterMenu = this.totalKeywords > 0;
    if (this.totalKeywords === 0) {
      this.publishFilter();
    }
  }

  publishFilter() {
    this.search.emit({
      contain:
        this.filterValues.find((filter) => filter.label === FilterType.contain)
          ?.items || [],
      exclude:
        this.filterValues.find((filter) => filter.label === FilterType.exclude)
          ?.items || []
    });
  }

  private isNil(searchValue: string) {
    return searchValue === null || searchValue === undefined;
  }
}
