import { Component, OnDestroy, OnInit } from '@angular/core';
import { FilterValue } from '@portal/app/shared/types';
import { SelectItem } from 'primeng/api';
import { defaultConfigs } from '@design-system/components/m-basic-column-chart/m-basic-column-chart.component.utils';
import type { Options } from 'highcharts';

// services
import { HomepageService } from '@portal/app/dashboard/home-page/services/home-page.service';
import { DateTimeService } from '@portal/app/shared/services/date-time.service';
import { CacVsLtvService } from './cac-vs-ltv.service';
import { TooltipData } from './cac-vs-ltv.service';
import { MyCustomersService } from '@portal/app/dashboard/home-page/components/my-customers/my-customers.service';

import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'portal-cac-vs-ltv',
  templateUrl: './cac-vs-ltv.component.html',
  styleUrls: ['./cac-vs-ltv.component.scss'],
  providers: [CacVsLtvService]
})
export class CacVsLtvComponent implements OnInit, OnDestroy {
  ltvValue: FilterValue = 'ltv6Months';
  ltvOptions: SelectItem[] = [];
  startDateValue: FilterValue = '';
  startDateOptions: SelectItem[] = [];
  isDataAvailable = true;
  isLoading = true;
  basicColumnChartOptions: Options = (defaultConfigs as Options) || {};
  tooltipData?: TooltipData;
  lastMonth = '';
  previousToLastMonth = '';
  minWidthValue = 216;
  private destroy$ = new Subject<void>();

  constructor(
    private homepageService: HomepageService,
    private dateTimeService: DateTimeService,
    private cacVsLtvService: CacVsLtvService,
    private myCustomersService: MyCustomersService
  ) {}

  get isChartReady(): boolean {
    return (
      !this.isLoading &&
      !!this.basicColumnChartOptions?.series &&
      !!this.basicColumnChartOptions?.xAxis &&
      !!this.basicColumnChartOptions?.yAxis &&
      !!this.basicColumnChartOptions?.plotOptions &&
      !!this.basicColumnChartOptions?.tooltip
    );
  }

  ngOnInit(): void {
    this.homepageService.ltvVsCacDataChartDimensions$
      .pipe(takeUntil(this.destroy$))
      .subscribe((dimensions) => {
        this.ltvOptions = dimensions;
      });

    this.homepageService.myCustomersSectionDateOptions$
      .pipe(takeUntil(this.destroy$))
      .subscribe((dateOptions) => {
        if (dateOptions && dateOptions.length > 0) {
          const dataAvailableDates = this.dateTimeService.stripTimeFromDates(
            dateOptions
          ) as string[];
          this.startDateValue = dataAvailableDates?.[0] as FilterValue;
          this.startDateOptions =
            this.myCustomersService.prepareStartDateOptions(dataAvailableDates);
          this.initializeDateFilters();
          this.updateLastAndPreviousMonthValues();
        }
      });

    this.cacVsLtvService.isLoading$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoading) => {
        this.isLoading = isLoading;
      });

    this.cacVsLtvService.chartData$
      .pipe(takeUntil(this.destroy$))
      .subscribe((chartData) => {
        if (chartData) {
          this.basicColumnChartOptions = chartData;
          this.minWidthValue = this.cacVsLtvService.getMinWidth();
          this.isDataAvailable =
            this.homepageService.checkChartDataAvailability(chartData);
        }
      });

    this.cacVsLtvService.dataPoints$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.tooltipData = data;
      });
  }

  onLtvDimensionChange(newValue = '') {
    this.cacVsLtvService.setChartDimensions(newValue);
  }

  onStartDateChange(startDate = '') {
    const endDate: string = this.dateTimeService.getLastDayAtSixthMonth(
      this.startDateValue as string
    );
    const dateValue: string[] = [startDate, endDate];
    this.cacVsLtvService.setDatevalue(dateValue);
  }

  initializeDateFilters() {
    const dateValue: string[] = [
      this.startDateValue as string,
      this.dateTimeService.getLastDayAtSixthMonth(this.startDateValue as string)
    ];
    this.cacVsLtvService.setDatevalue(dateValue);
  }

  updateLastAndPreviousMonthValues() {
    this.lastMonth = this.myCustomersService.getLastMonth(
      this.startDateValue as string
    );
    this.previousToLastMonth = this.myCustomersService.getPreviousToLastMonth(
      this.startDateValue as string
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
