import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LOCALE_CONFIG, LocaleService } from './index';
import { DateRangePickerDirective } from './date-range-picker.directive';
import { DateRangePickerComponent } from './date-range-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocaleConfig } from './date-range-picker.config';

@NgModule({
  declarations: [DateRangePickerComponent, DateRangePickerDirective],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  providers: [],
  exports: [DateRangePickerComponent, DateRangePickerDirective]
})
export class DateRangePickerModule {
  static forRoot(
    config: LocaleConfig = {}
  ): ModuleWithProviders<DateRangePickerModule> {
    return {
      ngModule: DateRangePickerModule,
      providers: [
        { provide: LOCALE_CONFIG, useValue: config },
        {
          provide: LocaleService,
          useClass: LocaleService,
          deps: [LOCALE_CONFIG]
        }
      ]
    };
  }
}
