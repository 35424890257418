import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  Renderer2,
  Inject
} from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { Dialog } from 'primeng/dialog';
import { PLATFORM_ID } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'm-modal',
  templateUrl: './m-modal.component.html',
  styleUrls: ['./m-modal.component.scss']
})
export class MModalComponent extends Dialog {
  @Input() isFullscreen = false;
  @Input() isMaximized = false;
  @Input() override showHeader = true;
  @Input() showFooter = true;

  constructor(
    @Inject(DOCUMENT) document: Document,
    @Inject(PLATFORM_ID) platformId: any,
    el: ElementRef,
    renderer: Renderer2,
    zone: NgZone,
    cd: ChangeDetectorRef,
    config: PrimeNGConfig
  ) {
    super(document, platformId, el, renderer, zone, cd, config);
  }
}
