import { computed, inject, Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { catchError, map, Observable, of, switchMap, tap } from 'rxjs';
import {
  MetricsResponseDTO,
  MetricsRequestDTO
} from '@portal/app/dashboard/context-modal/context-model.types';
import { ModalService } from '@portal/app/dashboard/context-modal/services/modal.service';

@Injectable()
export class MetricsService {
  private readonly http: HttpClient = inject(HttpClient);
  private readonly modalService: ModalService = inject(ModalService);

  private activeTabIndexSignal = signal<number>(-1);

  public isLoading = signal<boolean>(false);
  public data = toSignal(this.fetchData$(), {
    initialValue: [] as MetricsResponseDTO[]
  });

  public isDataAvailable = computed(() => this.data()?.length > 0);
  public activeTabIndex$ = toObservable<number>(this.activeTabIndex);

  get activeTabIndex() {
    return this.activeTabIndexSignal.asReadonly();
  }

  setActiveTabIndex(index: number) {
    this.activeTabIndexSignal.set(index);
  }

  getActiveMetric(): MetricsResponseDTO | null | undefined {
    const index = this.activeTabIndex();
    const data = this.data();
    return data?.length ? data[index] : null;
  }

  getParams() {
    const modalParams = this.modalService.modalParams();
    return {
      ...this.modalService.getParams(),
      dimension: modalParams.tactic ? 'tactic' : 'channel',
      filter: {
        ...this.modalService.getDateParams(),
        channel: modalParams.channel,
        tactic: modalParams.tactic ? modalParams.tactic : undefined,
        conversion_type: this.modalService.conversionType()
      }
    } as MetricsRequestDTO;
  }

  fetchData$(): Observable<MetricsResponseDTO[]> {
    const url = `${ModalService.baseUriV1}/metrics`;

    return this.modalService.modalParams$.pipe(
      tap(() => {
        this.isLoading.set(true);
        this.activeTabIndexSignal.set(-1);
      }),
      switchMap(() =>
        this.http.post<MetricsResponseDTO[]>(url, this.getParams()).pipe(
          catchError((error) => {
            console.error('Error fetching metrics data', error);
            this.isLoading.set(false);
            return of([] as MetricsResponseDTO[]);
          })
        )
      ),
      map((data: MetricsResponseDTO[]) => {
        return data?.filter((metric) => {
          return metric.value !== null && Number.isFinite(metric.value);
        });
      }),
      tap((data) => {
        this.isLoading.set(false);
        if (data?.length) {
          this.activeTabIndexSignal.set(0);
        }
      })
    );
  }
}
