import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  combineFilters,
  widgetSpecificFilters
} from '@portal/app/dashboard/utils';
import { DataGridStore } from '@portal/app/datagrid/store/data-grid.store';
import {
  BehaviorSubject,
  Observable,
  Subscriber,
  take,
  throwError
} from 'rxjs';
import {
  DataEndpoint,
  EncodedFilterValue,
  Filter,
  IFilterConfig,
  FilterValue,
  IFilterChange,
  IParentChildFilterMapping,
  ProductDashboardPersistenceMode,
  WidgetType,
  DataSetResponse,
  DataResponse
} from '@portal/app/shared/types';
import { Store } from '@ngrx/store';
import { ApiService } from '@portal/app/shared/services/api.service';
import { cloneDeep } from 'lodash-es';
import { ContextStore } from '@portal/app/shared/state/context.store';
import { AppState, DashboardState } from '@portal/app/store/app.state';
import { selectStore } from '@portal/app/store/app.selectors';
import { ViewStore } from '@portal/app/shared/state/view.store';
import {
  custom,
  DateTimeService,
  dayFilterLiteralIds,
  DayJsDateFormat
} from '@portal/app/shared/services/date-time.service';
import dayjs from 'dayjs';
import { customRangeValue } from '@libs/date-range-picker';
@Injectable({
  providedIn: 'root'
})
export class FiltersService {
  private dynamicFilters?: IParentChildFilterMapping | null;
  constructor(
    private readonly apiService: ApiService,
    private readonly route: ActivatedRoute,
    private dataGridStore: DataGridStore,
    private readonly contextStore: ContextStore,
    public readonly viewStore: ViewStore,
    private store: Store<AppState>,
    private readonly dateService: DateTimeService
  ) {}

  private contextFilterForOverlay: BehaviorSubject<Filter[]> =
    new BehaviorSubject<Filter[]>([]);

  private chartFilter: BehaviorSubject<Filter[]> = new BehaviorSubject<
    Filter[]
  >([]);

  private gridFilter: BehaviorSubject<Filter[]> = new BehaviorSubject<Filter[]>(
    []
  );

  private filterChanged: BehaviorSubject<IFilterChange> =
    new BehaviorSubject<IFilterChange>({});

  public get onfilterChanged(): Observable<IFilterChange> {
    return this.filterChanged.asObservable();
  }

  // subscribe to the chart component, when chart filter change , only the chart component will render
  public get dataChartFilter(): Observable<Filter[]> {
    return this.chartFilter.asObservable();
  }

  // subscribe to the grid component, when chart filter change , only the grid component will render
  public get dataGridFilter(): Observable<Filter[]> {
    return this.gridFilter.asObservable();
  }

  // data chart component trigger
  public changeDataChartFilter(filters: Filter[]): Observable<Filter[]> {
    const observable = new Observable((sub: Subscriber<Filter[]>) => {
      sub.next(filters);
    });
    observable.subscribe({
      next: (f) => this.chartFilter.next(f)
    });
    return observable;
  }

  // data grid component trigger
  public changeDataGridFilter(filters: Filter[]): Observable<Filter[]> {
    const observable = new Observable((sub: Subscriber<Filter[]>) => {
      sub.next(filters);
    });
    observable.subscribe({
      next: (f) => this.gridFilter.next(f)
    });
    return observable;
  }

  public changeFilters({
    widgetType,
    filters,
    endpoint
  }: {
    widgetType?: WidgetType;
    filters?: Filter[];
    endpoint?: DataEndpoint;
  }): void {
    let contextFilters: Filter[] = [];
    this.contextStore.filterContext
      .pipe(take(1))
      .subscribe((f) => (contextFilters = f));
    const combinedFilters = combineFilters(filters || contextFilters, [
      ...this.chartFilter.value,
      ...this.gridFilter.value,
      ...this.dataGridStore.dimensionMetricControl
    ]);
    this.apiService
      .getFilters(endpoint, filters || combinedFilters)
      .pipe(take(1))
      .subscribe({
        next: (fil) => {
          switch (widgetType) {
            case WidgetType.chart:
              this.filterChanged.next({
                filterType: WidgetType.chart,
                filters: widgetSpecificFilters(fil, WidgetType.chart)
              });
              break;

            case WidgetType.table:
              this.filterChanged.next({
                filterType: WidgetType.table,
                filters: widgetSpecificFilters(fil, WidgetType.table)
              });
              break;

            default:
              this.contextFilterForOverlay.next(
                cloneDeep(combinedFilters as Filter[])
              );

              this.filterChanged.next({
                filterType: WidgetType.context,
                filters: fil
              });

              //apply new options from new filters
              combinedFilters?.forEach((el) => {
                fil?.forEach((f) => {
                  if (f.literalId === el.literalId) {
                    el.options = f.options;
                  }
                });
              });

              this.contextStore.changeFilterContext(combinedFilters).subscribe({
                error: (e) => throwError(() => new Error(e))
              });
          }
        }
      });
  }

  // trigger for all the components
  public getFilters(filters: Filter[], viewId?: number): Observable<Filter[]> {
    const filters$ = this.apiService.getFilters(
      DataEndpoint.dataPoints,
      filters,
      viewId
    );
    // TODO: This will call the API again instead call the filters API and triggre the filter changes for entire dashboard with help of updateFiltersForWholeDashboard(filters)
    // TODO: Check the usage of this function and remove this usage as its API 2 times unnecessary
    filters$.pipe(take(1)).subscribe((filterList) =>
      this.filterChanged.next({
        filterType: WidgetType.context,
        filters: filterList
      })
    );
    return filters$;
  }

  public updateFiltersForWholeDashboard(filters: Filter[]): void {
    this.filterChanged.next({
      filterType: WidgetType.context,
      filters
    });
  }

  public getModifiedFilters(filters: Filter[]): Filter[] {
    const newFilters: Filter[] = [];
    let searchFilterValue: EncodedFilterValue | undefined;
    filters.forEach((filter) => {
      searchFilterValue = this.getEncodedFilterValueFromUrlByFilterName(
        filter.literalId
      );
      const newFilter = { ...filter };
      if (searchFilterValue != null) {
        newFilter.value = searchFilterValue as FilterValue;
      }
      newFilters.push(newFilter);
    });
    return newFilters;
  }

  private getEncodedFilterValueFromUrlByFilterName(
    name: string
  ): EncodedFilterValue {
    let returnValue: EncodedFilterValue = null;
    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      const val: string | null = params[name];
      if (val != null) {
        try {
          returnValue = JSON.parse(val);
        } catch (e) {
          console.error(e);
        }
      }
    });
    return returnValue;
  }

  getFields(response: IParentChildFilterMapping): string[] {
    if (Object.keys(response).length) {
      const parentId = Object.keys(response)[0];
      const firstChildKey =
        parentId && response[parentId]
          ? Object.keys(response[parentId] ?? {})[0]
          : '';
      const firstChild =
        parentId && firstChildKey
          ? (response[parentId] ?? {})[firstChildKey]?.children
          : '';
      const childrenIds = firstChild ? Object.keys(firstChild) : [];
      return [...[parentId], ...childrenIds] as string[];
    }
    return [];
  }

  clearFilters() {
    this.chartFilter.next([]);
    this.chartFilter.next([]);
  }

  getDashboardStateAndRelativeFilterConfig(): {
    dashboardState: DashboardState | undefined;
    filterConfig: IFilterConfig;
  } {
    const filterConfig: {
      shouldPersistFilters: boolean;
      dynamicFilters: IParentChildFilterMapping | null;
      dashboardLiteralId: string;
    } = {
      dynamicFilters: null,
      shouldPersistFilters: false,
      dashboardLiteralId: ''
    };
    let dashState: DashboardState | undefined;
    this.store.select(selectStore).subscribe((state: AppState) => {
      dashState = state.dashboard;
      const { dashboardPersistence, dynamicFilters, dashboardLiteralId } =
        dashState;
      filterConfig.dashboardLiteralId = dashboardLiteralId;
      filterConfig.shouldPersistFilters =
        dashboardPersistence ===
        ProductDashboardPersistenceMode.dashboardPersistence;
      filterConfig.dynamicFilters = dynamicFilters;
    });
    this.viewStore.selectedBCPDId.pipe(take(1)).subscribe((value) => {
      filterConfig.dashboardLiteralId = value.literalId;
    });
    return { dashboardState: dashState, filterConfig };
  }

  public setupAndPersistFilters(
    updatedFiltersViaRoute: Filter[],
    dashboardState: DashboardState | undefined
  ): Promise<DataResponse | DataSetResponse | DataSetResponse[]> {
    const filterAssociatedWithDataSetRow = updatedFiltersViaRoute.filter(
      (fil) => {
        return fil.applyTo && fil.applyTo.includes('DATA_SET_ROW');
      }
    );
    const dimensionFormat = filterAssociatedWithDataSetRow.length
      ? (dashboardState?.dimensionFormat as string)
      : (null as unknown as string);
    return this.apiService.overrideFiltersUpdated(
      updatedFiltersViaRoute,
      dimensionFormat
    );
  }

  public updateDateBasedOnRelativeDayFilter(filters: Filter[]): void {
    const relativeDayFilterValue =
      filters.find((f) => f.literalId === 'relative_day')?.value || custom;
    if (
      ![custom, customRangeValue].includes(relativeDayFilterValue as string)
    ) {
      const dateFilter = filters.find((f) =>
        dayFilterLiteralIds.includes(f.literalId)
      ) || { value: undefined };
      const relativeDateOption =
        this.dateService.getRelativeDayOptionByRelativeDay(
          relativeDayFilterValue
        );
      if (relativeDateOption && relativeDateOption.action) {
        const dateValue = relativeDateOption.action;
        dateFilter.value = [
          DateTimeService.dateString(
            dateValue.startDate as dayjs.Dayjs,
            DayJsDateFormat.fullDate
          ),
          DateTimeService.dateString(
            dateValue.endDate as dayjs.Dayjs,
            DayJsDateFormat.fullDate
          )
        ];
      }
    }
  }

  /**
   * Retrieves a filter by its literal ID.
   *
   * @param filters - Array of filters
   * @param literalId - The literal ID of the filter to retrieve.
   * @returns The filter with the specified literal ID, or undefined if not found.
   */
  public getFilterByLiteralId(
    filters: Filter[],
    literalId: string
  ): Filter | undefined {
    return filters.find((filter) => filter.literalId === literalId);
  }
}
