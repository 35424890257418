import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  IActionButtonConfig,
  IHeaderConfig,
  IHeaderData
} from '@libs/common-components';
import { DataTrackerService } from '@libs/common-services';

@Component({
  selector: 'lib-ui-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnChanges {
  @Input() headerConfig!: IHeaderConfig;
  @Input() headerData!: IHeaderData;
  @Input() isEditMode: string | undefined;
  @Input() showEmptyData = true;
  @Input() actionButtonConfig: IActionButtonConfig[] | undefined;
  @Input() infoConfig: IActionButtonConfig[] | undefined;
  @Output() editableHeaderValue = new EventEmitter<string>();
  @Output() enterKeyClicked = new EventEmitter<boolean>();
  @Output() actionButtonClicked = new EventEmitter<string>();

  private editableHeaderValueInitialized = false;

  constructor(public readonly dataTrackerService: DataTrackerService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['headerData'] ||
      changes['isEditMode'] ||
      changes['headerConfig']
    ) {
      this.applyChanges();
    }
  }

  private applyChanges() {
    if (
      this.isTitleNotInitializedInEditMode() &&
      this.headerConfig?.editableTitle?.field &&
      this.headerData?.[this.headerConfig.editableTitle.field]
    ) {
      this.editableHeaderValue.emit(
        this.headerData?.[this.headerConfig.editableTitle.field] as string
      );
      this.editableHeaderValueInitialized = true;
    }
  }

  private isTitleNotInitializedInEditMode() {
    return this.isEditMode && !this.editableHeaderValueInitialized;
  }

  onEditableHeaderKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.enterKeyClicked.emit(true);
    } else {
      this.editableHeaderValue.emit((event.target as HTMLInputElement).value);
    }
  }

  onClickFAQ(link: string): void {
    window.open(link, '_blank');
  }

  onClickAction(event: string): void {
    this.actionButtonClicked.emit(event);
  }
}
