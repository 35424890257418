import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  CurrentView,
  DataEndpoint,
  DataRowData,
  ElementGroup,
  FieldDefinitions,
  Filter,
  FilterValue,
  IRoutedFilterFromHomePage,
  LayoutResponse,
  RelativePosition,
  ViewResponse,
  ViewTab
} from '@portal/app/shared/types';
import { Observable, Subscription, take, throwError } from 'rxjs';
import { ContextStore } from '@portal/app/shared/state/context.store';
import { ApiService } from '@portal/app/shared/services/api.service';
import { ViewService } from '@portal/app/shared/services/view.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ViewStore } from '@portal/app/shared/state/view.store';
import { NativeSectionsService } from '@portal/app/shared/services/native-sections.service';
import { SelectionService } from '@portal/app/shared/services/selection.service';
import { CompareService } from '@portal/app/shared/services/compare.service';
import { MultiViewStore } from '@portal/app/shared/state/multi-view.store';
import { FiltersService } from '@portal/app/shared/services/filters.service';
import { cloneDeep, compact } from 'lodash-es';
import { Store } from '@ngrx/store';
import { AppState } from '@portal/app/store/app.state';
import { DashboardActions } from '@portal/app/store/dashboard/actions';
import { Location } from '@angular/common';
import { demoDefaultClient } from '@portal/app/shared/constants/constants';
import { addAdditionalLayoutTypes } from '@portal/app/dashboard/utils';
import dayjs from 'dayjs';
import { FieldService } from '@portal/app/shared/services/field.service';

const MEASURED_DEMO_CLIENT_ID = demoDefaultClient.clientId;
const MEASURED_DEMO_BRAND_ID = demoDefaultClient.brandId;

@Component({
  selector: 'portal-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit, OnDestroy {
  public loading = true;
  public clientId = MEASURED_DEMO_CLIENT_ID;
  public brandId = MEASURED_DEMO_BRAND_ID;
  public productId = '';
  public literalId = '';
  public items: ElementGroup[] = [];
  public fieldDefinitions: FieldDefinitions = {};
  public filters: Filter[] = [];
  public filtersLayout?: ElementGroup;
  public maxColsPerRow = 12;
  public relativePosition = RelativePosition;
  private previousProduct = '';
  public currentView$: Observable<CurrentView | null>;
  public isLoadingView$: Observable<boolean>;

  private readonly subs: Subscription = new Subscription();
  private initialLoad = false;
  private routedFromHomePageData: IRoutedFilterFromHomePage | null = null;

  constructor(
    public readonly contextStore: ContextStore,
    public readonly viewStore: ViewStore,
    private readonly apiService: ApiService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public readonly viewService: ViewService,
    public readonly nativeSectionsService: NativeSectionsService,
    public readonly selectionService: SelectionService,
    public readonly compareService: CompareService,
    public readonly filtersService: FiltersService,
    private readonly multiViewStore: MultiViewStore,
    private readonly fieldService: FieldService,
    private store: Store<AppState>,
    private location: Location
  ) {
    this.isLoadingView$ = this.multiViewStore.isLoadingView$;
    this.currentView$ = this.multiViewStore.currentView$;
  }

  setMPOQueryParamsForChildPage(): void {
    const { queryParams } = this.route.snapshot;
    const showChild = !!(queryParams && queryParams.showChild);
    this.multiViewStore.showChild = showChild;
    if (showChild && queryParams.channel && queryParams.tactic) {
      this.multiViewStore.channel = JSON.parse(queryParams.channel);
      this.multiViewStore.tactic = JSON.parse(queryParams.tactic);
    }
  }

  ngOnInit(): void {
    this.initialLoad = true;
    this.subs.add(
      this.route.params.subscribe({
        next: (params) => {
          this.routedFromHomePageData =
            this.location.getState() as IRoutedFilterFromHomePage;
          this.setMPOQueryParamsForChildPage();
          // reset last filter in store on dashboard change
          this.store.dispatch(DashboardActions.setFilter({ filter: null }));
          this.subs.add(
            this.selectionService
              .parseSelectionSlug(params.brandSlug)
              .pipe(take(1))
              .subscribe({
                next: (value) => {
                  this.brandId = value.brandId;
                  this.clientId = value.clientId;
                }
              })
          );
          this.previousProduct = this.productId;
          this.productId = params.productId || '';
          this.literalId = params.literalId || '';

          this.viewStore.setBrandClientProductDashboardId({
            clientId: this.clientId,
            brandId: this.brandId,
            productId: this.productId,
            literalId: this.literalId
          });
          this.items = [];
          this.setInitialStateFromQueryParams();
          this.loadViewIfDashboardSupportsMultiView();
        }
      })
    );
    this.subs.add(
      this.multiViewStore.currentView$.subscribe((curr) => {
        this.processCurrentView(curr);
        this.initialLoad = false;
      })
    );
  }

  processCurrentView(curr: CurrentView | null): void {
    if (curr && !curr.dashboardHasViews) {
      this.loadData();
    } else if (curr && curr.active && curr.view && curr.reloadData) {
      this.loadData(curr.view);
    }
  }

  switchToDefaultView() {
    this.multiViewStore.switchToDefaultView();
  }

  loadData(view?: ViewResponse): void {
    this.loading = true;
    if (!this.initialLoad) {
      this.apiService.cancelPendingRequest.next(true);
    }
    this.apiService
      .getDashboard(view?.id)
      .pipe(take(1))
      .subscribe({
        next: async (fullLayout) => {
          //TODO: DU: This method was added to support direct injection of two components into Cross Channel view into the layout API response.
          //This work is tied to  PMDEV-3344 & PMDEV-3572 and is considered a temporary solution until Cross Channel no longer uses Native Framework
          this.addAdditionalLayoutTypes(fullLayout);
          // TODO: Have the compare as its own component so its driven through the layout type and no need to write this code
          this.compareService.compareIsAvailable = this.hasCompare(fullLayout);
          this.fieldDefinitions = fullLayout.fieldDefinitions;
          this.fieldService.setFields(fullLayout.fieldDefinitions);
          await this.handleFilterValueOptions(view, fullLayout.filters);
          this.filtersService.updateDateBasedOnRelativeDayFilter(this.filters);
          this.apiService
            .getFilters(DataEndpoint.dataPoints, this.filters, view?.id)
            .pipe(take(1))
            .subscribe({
              next: async (filterWithFilters) => {
                // need this to update filters again when child page is opened
                if (filterWithFilters.length && this.multiViewStore.showChild) {
                  this.multiViewStore.updateFilters(filterWithFilters, view);
                }
                await this.handleFilterValueOptions(view, filterWithFilters);
                this.filtersService.updateDateBasedOnRelativeDayFilter(
                  this.filters
                );
                this.contextStore.changeFilterContext(this.filters);
                this.items =
                  this.nativeSectionsService.createItemsFromNewBackend(
                    fullLayout.layout,
                    this.filters
                  );
                this.loading = false;
              },
              error: () => {
                this.loading = false;
              }
            });
          this.filtersLayout = fullLayout.layout.find(
            (i) => i.literalId === 'contextBar'
          );
        },
        error: (error) => {
          this.loading = false;
          throwError(() => new Error(error));
          if (error && error.status === 403) {
            this.router.navigate([
              `a/${this.selectionService.buildSelectionSlug()}/products/errors`
            ]);
          }
        }
      });
  }

  hasCompare(fullLayout: LayoutResponse) {
    const isDashboardHasCompare = !!fullLayout.layout.find(
      (item) => item.literalId === 'compare'
    );
    if (isDashboardHasCompare) {
      const compareFilter = fullLayout.filters?.find(
        (filter) => filter.literalId === 'compare'
      );
      const compareDateFilter = fullLayout.filters?.find(
        (filter) => filter.literalId === 'compare_date'
      );
      if (compareFilter && compareDateFilter) {
        const compareDate = compareDateFilter.value as string[];
        this.compareService.compare = compareFilter.value as boolean;
        this.compareService.previousDate = {
          startDate: dayjs(compareDate[0]),
          endDate: dayjs(compareDate[1])
        };
      }
    }

    return isDashboardHasCompare;
  }

  tabChanged(item: ViewTab): void {
    let queryParams = {} as Params;
    this.route.queryParams.pipe(take(1)).subscribe((params: Params) => {
      queryParams = params;
    });
    this.router
      .navigate([item.link], { queryParams })
      .catch((error) => console.error(error));
  }

  setInitialStateFromQueryParams(): void {
    this.route.data
      .pipe(take(1))
      .subscribe((params: Record<string, string>) => {
        if (params.literalId) {
          this.literalId = params.literalId;
        }
      });
  }

  setHiddenFilters() {
    return new Promise((resolve) => {
      this.route.queryParams.pipe(take(1)).subscribe({
        next: async (params) => {
          let response = null;

          const paramKeys = Object.keys(params);

          // update filters based on route query params
          if (paramKeys.length) {
            this.filters = this.filtersService.getModifiedFilters(this.filters);
            const dashboardStateAndFilterConfig =
              this.filtersService.getDashboardStateAndRelativeFilterConfig();
            this.incSettingForMPOFromGeoResultV2(params);
            // persist the filters that were updated by routes
            const filtersModifiedUsingRoute = compact(
              this.filters.map((f) => {
                if (paramKeys.includes(f.literalId)) {
                  return f;
                }
                return null;
              })
            );
            if (
              dashboardStateAndFilterConfig.filterConfig.shouldPersistFilters &&
              filtersModifiedUsingRoute.length
            ) {
              response = await this.filtersService.setupAndPersistFilters(
                filtersModifiedUsingRoute,
                dashboardStateAndFilterConfig.dashboardState
              );
            }
          }

          return resolve(response);
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.compareService.compareIsAvailable = false;
    this.subs.unsubscribe();
  }

  loadViewIfDashboardSupportsMultiView(): void {
    const viewId = this.route.snapshot.queryParams.viewId;
    const isMultiView = this.route.snapshot.queryParams.isMultiView;
    const showChild = this.route.snapshot.queryParams.showChild;
    this.multiViewStore.loadView(isMultiView === 'true', viewId, showChild);
  }

  handleFilterValueOptions(view: ViewResponse | undefined, filters: Filter[]) {
    let filtersWithoutFilter = cloneDeep(filters);
    if (view) {
      this.handleFilterForView(view, filtersWithoutFilter);
      filtersWithoutFilter = this.setDefaultFilterFromView(
        view,
        filtersWithoutFilter
      );
    }
    return this.filterFetchPostHandler(filtersWithoutFilter);
  }

  handleFilterForView(view: ViewResponse, fil: Filter[]): void {
    this.filters = this.multiViewStore.currViewFiltersWithDefaults;
    const { navigateToParent } = this.route.snapshot.queryParams;
    this.contextStore.clearAssocFilterOnPageLoad(navigateToParent);
    this.multiViewStore.mapFilterOverridesToAllocationSetting(
      view,
      fil,
      this.multiViewStore.showChild
    );
    this.multiViewStore.mapFilterOverridesToIncrementalitySetting(
      view,
      this.multiViewStore.showChild
    );
  }

  setDefaultFilterFromView(view: ViewResponse, fil: Filter[]): Filter[] {
    const viewGlobalFilterLiteralIds = Object.keys(view.filterOverrides.global);
    return fil.map((f) => {
      if (viewGlobalFilterLiteralIds.includes(f.literalId)) {
        f.value = view.filterOverrides.global[f.literalId] as DataRowData;
      }
      return f;
    });
  }

  private filterFetchPostHandler(fil: Filter[]) {
    this.filters = this.nativeSectionsService.setFilterDefaults(fil);
    if (
      !this.previousProduct ||
      this.previousProduct === this.productId ||
      (this.routedFromHomePageData &&
        this.routedFromHomePageData.from === 'home-page')
    ) {
      this.setupHomePageFilters();
      return this.setHiddenFilters();
    }
  }

  private setupHomePageFilters() {
    if (
      !this.previousProduct ||
      this.previousProduct === this.productId ||
      (this.routedFromHomePageData &&
        this.routedFromHomePageData.from === 'home-page')
    ) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        let response = null;
        const dashboardStateAndFilterConfig =
          this.filtersService.getDashboardStateAndRelativeFilterConfig();
        const state =
          (this.routedFromHomePageData?.filters as Record<string, unknown>[]) ||
          [];
        const filtersModifiedUsingRoute = compact(
          state.map((fObj: Record<string, unknown>) => {
            const filterObjToUpdate = this.filters.find(
              (f) => f.literalId === fObj.literalId
            );
            if (filterObjToUpdate) {
              filterObjToUpdate.value = fObj.value as FilterValue;
            }
            return filterObjToUpdate;
          })
        );
        if (
          dashboardStateAndFilterConfig.filterConfig.shouldPersistFilters &&
          filtersModifiedUsingRoute.length
        ) {
          response = await this.filtersService.setupAndPersistFilters(
            filtersModifiedUsingRoute,
            dashboardStateAndFilterConfig.dashboardState
          );
        }
        return resolve(response);
      });
    }
  }

  private incSettingForMPOFromGeoResultV2(params: Params): void {
    const incSettingKey = 'incrementalitySetting';
    if (
      this.isMPODashboard() &&
      this.isFromGeoResultV2(params) &&
      this.isHasIncSettingProperty(params, incSettingKey)
    ) {
      this.contextStore.changeAssociatedFilterValues(
        incSettingKey,
        JSON.parse(params[incSettingKey]),
        false
      );
    }
  }

  private isMPODashboard(): boolean {
    return this.literalId === `portfolio-level`;
  }

  private isFromGeoResultV2(params: Params) {
    return (
      params &&
      Object.keys(params).includes('from') &&
      params.from === 'geo-test-result-v2'
    );
  }

  private isHasIncSettingProperty(params: Params, incSettingKey: string) {
    return params && Object.keys(params).includes(incSettingKey);
  }

  public eventOptions = {
    draggable: '.draggable',
    group: 'group'
  };

  addAdditionalLayoutTypes(fullLayout: LayoutResponse) {
    addAdditionalLayoutTypes(fullLayout);
  }
}
