<p-dialog
  [(visible)]="visible"
  [modal]="modal"
  [draggable]="draggable"
  [resizable]="resizable"
  [closable]="closable"
  [style]="style"
  [appendTo]="appendTo"
  [dismissableMask]="dismissableMask"
  [blockScroll]="blockScroll"
  (onHide)="onHide.emit()"
  (onShow)="onShow.emit()"
  class="m-modal"
  [showHeader]="showHeader"
  [contentStyleClass]="contentStyleClass"
  [transitionOptions]="transitionOptions"
  [styleClass]="styleClass"
  [ngClass]="{ fullscreen: isFullscreen, maximized: isMaximized }">
  @if (showHeader) {
    <ng-template pTemplate="header">
      <!-- Custom header content -->
      <ng-content select="[modalHeader]"></ng-content>
    </ng-template>
  }

  <ng-content></ng-content>

  @if (showFooter) {
    <ng-template pTemplate="footer">
      <!-- Custom footer content -->
      <ng-content select="[modalFooter]"></ng-content>
    </ng-template>
  }
</p-dialog>
