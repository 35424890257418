/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

// services
import { MyChannelsService } from './my-channels.service';
import { ChannelsService } from '@portal/app/dashboard/home-page/services/channels.service';
import { MetricsService } from '@portal/app/dashboard/home-page/services/metrics.service';
import { NavigationService } from '@portal/app/shared/services/navigation.service';

// types
import type { Channel } from './my-channels.service';
import type { Metric } from '@portal/app/dashboard/home-page/services/metrics.service';

@Component({
  selector: 'portal-my-channels',
  templateUrl: './my-channels.component.html'
})
export class ChannelsComponent implements OnInit, OnDestroy {
  activeChannel?: Channel;
  activeTabIndex = 0;
  channels: Channel[] = [];
  private destroy$ = new Subject<void>();
  isLoading = true;
  isModalLoading = true;
  isModalVisible = false;
  metrics: Metric[] = [];

  constructor(
    private channelsService: ChannelsService,
    private metricsService: MetricsService,
    private navigationService: NavigationService,
    private myChannelsService: MyChannelsService
  ) {}

  ngOnInit() {
    this.subscribeToData();
  }

  private subscribeToData() {
    this.myChannelsService.metrics$
      .pipe(takeUntil(this.destroy$))
      .subscribe((metrics) => {
        this.metrics = metrics.filter((metric: Metric) => !metric.hasError);
        this.metricsService.setActiveMetric(
          this.metrics[this.activeTabIndex] as Metric
        );
      });

    this.myChannelsService.channelDataSets$
      .pipe(takeUntil(this.destroy$))
      .subscribe((channels) => {
        this.channels = channels;
      });

    this.myChannelsService.isLoading$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoading) => {
        this.isLoading = isLoading;
      });

    this.myChannelsService.isModalLoading$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isModalLoading) => {
        this.isModalLoading = isModalLoading;
      });
  }

  closeModal() {
    this.channelsService.setActiveChannel('');
    this.isModalVisible = false;
    this.isModalLoading = true;
  }

  // Toggles the visibility of the modal dialog
  toggleModal(activeChannel: Channel) {
    this.isModalLoading = true;
    // Reset tab index
    this.activeTabIndex = 0;

    // Set active channel
    this.activeChannel = activeChannel;
    this.channelsService.setActiveChannel(activeChannel.name);

    // Show modal
    this.isModalVisible = !this.isModalVisible;
  }

  onTabChange(event: any) {
    // Set tab index
    this.activeTabIndex = event.index;
    // Show active emtric
    const activeMetric = this.metrics[event.index];
    this.metricsService.setActiveMetric(activeMetric as Metric);
  }

  navigateToPageAllChannels(): void {
    this.navigationService.navigationMapping('exploreChannel', {
      filters: this.myChannelsService.getFilters(),
      extraParams: {
        queryParams: {}
      }
    });
  }

  navigateToPage(): void {
    this.navigationService.navigationMapping('exploreChannel', {
      filters: this.myChannelsService.getFilters(),
      extraParams: {
        channel: [this.channelsService.getActiveChannel()]
      }
    });

    // Reset active channel filter after navigation
    this.channelsService.setActiveChannel('');
  }

  // Handles cleanup when the service is destroyed
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
