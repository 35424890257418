<p-tabView
  class="m-tabview"
  [scrollable]="scrollable"
  [styleClass]="styleClass"
  [style]="style"
  [(activeIndex)]="activeIndex"
  (activeIndexChange)="emitIndexChange()">
  @for (tabPanel of tabPanelHeaders; track tabPanel; let i = $index) {
    <p-tabPanel [disabled]="tabPanel.disabled">
      <ng-template pTemplate="header">
        <div class="flex items-center gap-2">
          <span
            [ngClass]="{
              dp2: headerSize === 'default',
              sh2: headerSize === 'small',
            }"
            class="font-normal"
            >{{ tabPanel.header }}</span
          >
          @if (tabPanel.showCount) {
            <span class="bg-gray-200 text-gray-800 b2 rounded-full px-3">{{
              tabPanel.count
            }}</span>
          }
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <ng-template [ngTemplateOutlet]="tabContentList[i]"></ng-template>
      </ng-template>
    </p-tabPanel>
  }
</p-tabView>
