import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import dayjs from 'dayjs';

// Services
import { NavigationService } from '@portal/app/shared/services/navigation.service';
import { OptimizationResultsService } from './optimization-results.service';

// Types
import { CardVariant } from '@design-system/components/m-card';
import { ButtonType } from '@design-system/components/m-button';
import { PerformanceOverTimeChartService } from '@portal/app/shared/components/performance-over-time-chart/performance-over-time-chart.service';
import { SelectItem } from 'primeng/api';
import { Resolution, ViewBy } from '@portal/app/shared/constants';
import { HeroMetric } from '@portal/app/shared/components/performance-over-time-chart/performance-over-time-chart.types';
import { DATE_FORMAT_WITH_MONTH_ABBR } from '@portal/app/dashboard/geo-doe-config/shared/constants';

@Component({
  selector: 'portal-optimization-results',
  templateUrl: './optimization-results.component.html',
  styleUrls: ['./optimization-results.component.scss']
})
export class PortalOptimizationResultsComponent implements OnInit, OnDestroy {
  public startDate: string | null = null;
  public stopDate: string | null = null;
  public conversionType: string | null = null;
  public resolution = Resolution.monthly;
  public viewBy: string | null = null;
  public formattedDateRange: string | null = null;
  public additionalRevenue: string | null = null;
  public isLoading = true; // New loading state
  public cardSmall = CardVariant.small;
  public buttonSecondary = ButtonType.secondary;
  public heroMetric: HeroMetric | null = null;
  public heroMetricText: string | null = null;
  public heroMetricClass: string | null = null;

  private destroy$ = new Subject<void>();

  resolutionOptions: SelectItem[] = [
    { label: 'Weekly', value: Resolution.weekly },
    { label: 'Monthly', value: Resolution.monthly },
    { label: 'Quarterly', value: Resolution.quarterly }
  ];

  constructor(
    private navigationService: NavigationService,
    private optimizationResultsService: OptimizationResultsService,
    private performanceOverTimeChartService: PerformanceOverTimeChartService
  ) {}

  ngOnInit() {
    this.subscribeToData();
  }

  private subscribeToData() {
    this.performanceOverTimeChartService.isLoading$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoading) => {
        this.isLoading = isLoading;
      });

    this.optimizationResultsService.startDate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((date) => {
        this.startDate = date;
      });

    this.optimizationResultsService.stopDate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((date) => {
        this.stopDate = date;
      });

    this.optimizationResultsService.conversionType$
      .pipe(takeUntil(this.destroy$))
      .subscribe((type) => {
        this.conversionType = type;
      });

    this.optimizationResultsService.viewBy$
      .pipe(takeUntil(this.destroy$))
      .subscribe((view) => {
        this.viewBy = view;
      });

    this.performanceOverTimeChartService.heroMetric$
      .pipe(takeUntil(this.destroy$))
      .subscribe((metric: HeroMetric) => {
        this.heroMetric = metric;
        this.updateHeroMetric();
        this.updateFormattedDateRange();
      });
  }

  private updateFormattedDateRange() {
    if (this?.heroMetric?.dateStart && this?.heroMetric?.dateStop) {
      // Format from string to string using dayjs
      this.formattedDateRange = `${dayjs(this?.heroMetric?.dateStart).format(
        DATE_FORMAT_WITH_MONTH_ABBR
      )} - ${dayjs(this?.heroMetric?.dateStop).format(DATE_FORMAT_WITH_MONTH_ABBR)}`;
    }
  }

  navigateToOP(): void {
    this.navigationService.navigationMapping(
      'optimization-report',
      {
        filters: [],
        extraParams: {
          queryParams: {
            conversionType: this.conversionType,
            startDate: this?.heroMetric?.dateStart,
            stopDate: this?.heroMetric?.dateStop,
            resolution: this.resolution
          }
        }
      },
      true
    );
  }

  onResolutionChange(resolution: Resolution): void {
    this.resolution = resolution;
    this.optimizationResultsService.setDateRange(resolution);
  }

  // Method to update dynamic text, class, and heroMetric data
  private updateHeroMetric(): void {
    this.heroMetricText = this.heroMetric?.isPositive
      ? `Additional ${this.viewBy === ViewBy.cpo ? 'orders' : 'revenue'}  from budget adjustments`
      : `${this.viewBy === ViewBy.cpo ? 'Orders' : 'Revenue'} you missed due to this period’s budget changes`;

    this.heroMetricClass = this.heroMetric?.isPositive
      ? 'text-green-700'
      : 'text-orange-300';
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
