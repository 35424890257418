/**
 * Api documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RecommendedBudgetAdjustmentRequestDTO { 
    clientId: number;
    brandId: number;
    conversion_type: string;
    planId: number;
    dateStart: string;
    dateStop: string;
    tableDimension?: RecommendedBudgetAdjustmentRequestDTO.TableDimensionEnum;
}
export namespace RecommendedBudgetAdjustmentRequestDTO {
    export type TableDimensionEnum = 'channel' | 'segment' | 'tactic';
    export const TableDimensionEnum = {
        Channel: 'channel' as TableDimensionEnum,
        Segment: 'segment' as TableDimensionEnum,
        Tactic: 'tactic' as TableDimensionEnum
    };
}


