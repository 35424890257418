import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  UrlTree,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { AuthService } from '@portal/app/shared/services/auth.service';
import { StorageService } from '@portal/app/shared/services/storage.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly store: StorageService,
    private readonly authService: AuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    if (this.authService.getUser() != null) {
      return true;
    } else {
      // assume they are not logged in if the user does not exist,
      //  Send to login and clear stored
      //  user identifiable information using authService.logout()
      this.authService.logout({ queryParams: { returnUrl: state.url } });
      return false;
    }
  }
}
