<div class="pt-3 TABS">
  <p-tabView
    [(activeIndex)]="activeIndex"
    *ngIf="tabGroup"
    (onChange)="tabChange($event)"
    [class]="'result-tabs'">
    <p-tabPanel
      *ngFor="let tab of tabElements; let index = index"
      [header]="tab.label">
      <div *ngIf="activeIndex === index" [class]="tab.literalId">
        <div
          *ngFor="let tabElement of tab.elements"
          [ngSwitch]="tabElement.layoutType"
          class="{{ tabElement.layoutType }}">
          <portal-native-sections-template
            *ngIf="
              !nativeSectionsService
                .getWrapperComponentType()
                .includes(tabElement.layoutType)
            "
            [item]="tabElement"
            [fieldDefinitions]="fieldDefinitions"
            [filters]="filters">
          </portal-native-sections-template>
          <portal-context-bar
            *ngSwitchCase="'CONTEXT_BAR' || 'FILTER_SET'"
            [contextItem]="tabElement"
            [fieldDefinitions]="fieldDefinitions"
            [filters]="filters">
          </portal-context-bar>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
</div>
