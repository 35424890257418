import { jsPDF } from 'jspdf';
import { Injectable } from '@angular/core';
import html2canvas from 'html2canvas';
import { DateTimeService } from '@portal/app/shared/services/date-time.service';
import { ViewStore } from '@portal/app/shared/state/view.store';
import { monthDateYearFormat } from '@portal/app/shared/constants/constants';

const JPEG_IMAGE_TYPE = 'jpeg';
const PDF_IMAGE_QUALITY = 0.98;
const PDF_MARGIN = [0.5, 0.5];
const PDF_PAGE_COLOR = 'white';
const TOP_DOWN_MARGIN = 20;
const LEFT_RIGHT_MARGIN = 0;
const X_POSITION = 15;
const Y_POSITION = 5;
const WIDTH_TO_SCALE_FOR_LANDSCAPE = 600;
const WIDTH_TO_SCALE_FOR_PORTRAIT = 420;
const FULL_HEIGHT = '100%';

@Injectable({
  providedIn: 'root'
})
export class ExportPdfService {
  private literalId = '';
  private clientId = '';

  constructor(
    protected readonly dateService: DateTimeService,
    protected readonly viewStore: ViewStore
  ) {
    this.viewStore.selectedBCPDId.subscribe((value) => {
      this.clientId = value.clientId.toString();
      this.literalId = value.literalId;
    });
  }

  public downloadEditablePdf(
    elementId = 'main-container-id',
    fileName: string,
    orientation: 'l' | 'p' = 'p'
  ) {
    let defaultHeight = '';
    let defaultMaxHeight = '';
    const htmlData = document.getElementById(elementId);
    const allocationTable = htmlData?.getElementsByClassName(
      'p-datatable-wrapper'
    )[0] as HTMLElement;
    if (htmlData) {
      if (allocationTable) {
        // Save and set the table height to 100% for PDF generation
        defaultHeight = allocationTable.style.height;
        defaultMaxHeight = allocationTable.style.maxHeight;
        allocationTable.style.height = FULL_HEIGHT;
        allocationTable.style.maxHeight = FULL_HEIGHT;
      }
    }
    const clonedHTMLData = htmlData?.cloneNode(true) as HTMLElement;
    this.hideSVG(clonedHTMLData);
    const iconsToHide = this.getIconsClassesToHide();
    const exportedFileName = `${this.literalId}-${
      this.clientId
    }-${this.dateService.today().format(monthDateYearFormat)}`;
    const pdfFileName = fileName
      ? `${fileName}-${exportedFileName}`
      : exportedFileName;

    if (clonedHTMLData) {
      iconsToHide.forEach((className) => {
        const elements = clonedHTMLData.getElementsByClassName(className);
        Array.from(elements).forEach((element) => {
          (element as HTMLElement).style.display = 'none';
        });
      });
      const doc = new jsPDF(orientation, 'px', 'a4');
      doc.html(clonedHTMLData as HTMLElement, {
        margin: [TOP_DOWN_MARGIN, LEFT_RIGHT_MARGIN],
        callback: (docFile) => {
          docFile.save(pdfFileName);
        },
        x: X_POSITION,
        y: Y_POSITION,
        width:
          orientation === 'l'
            ? WIDTH_TO_SCALE_FOR_LANDSCAPE
            : WIDTH_TO_SCALE_FOR_PORTRAIT,
        windowWidth: window.innerWidth
      });
      // Reset table height to its original value
      allocationTable.style.height = defaultHeight;
      allocationTable.style.maxHeight = defaultMaxHeight;
    }
  }

  public getIconsClassesToHide(): string[] {
    return [
      'pi-chevron-down',
      'pi-chevron-right',
      'material-symbols-outlined',
      'pi-chevron-circle-left'
    ];
  }

  public downloadPdfForDesignSummaryV2UsingCanvas(fileName: string): void {
    const htmlData = document.getElementById('htmlDataToDownload');

    const adAccountTableForVendor = document
      .getElementById('adAccountTableForVendor')
      ?.getElementsByClassName('p-datatable-wrapper')[0] as HTMLElement;

    const implementationGuideTable = document
      .getElementById('implementationGuideTable')
      ?.getElementsByClassName('p-datatable-wrapper')[0] as HTMLElement;

    let defaultHeight = '';
    let defaultMaxHeight = '';
    if (htmlData) {
      if (adAccountTableForVendor) {
        // set table height to 100% and then reset the height after generating pdf
        defaultHeight = adAccountTableForVendor.style.height;
        defaultMaxHeight = adAccountTableForVendor.style.maxHeight;
        adAccountTableForVendor.style.height = FULL_HEIGHT;
        adAccountTableForVendor.style.maxHeight = FULL_HEIGHT;
      }
      if (implementationGuideTable) {
        defaultHeight = implementationGuideTable.style.height;
        defaultMaxHeight = implementationGuideTable.style.maxHeight;
        implementationGuideTable.style.height = FULL_HEIGHT;
        implementationGuideTable.style.maxHeight = FULL_HEIGHT;
      }
      html2canvas(htmlData, { allowTaint: false, useCORS: true }).then(
        (canvas) => {
          const image = { type: JPEG_IMAGE_TYPE, quality: PDF_IMAGE_QUALITY };
          const filename = `Design Summary - ${fileName}.pdf`;

          const imgWidth = 8.5;
          const initialPageHeight = 10.9;

          const innerPageWidth = imgWidth - (PDF_MARGIN[0] as number) * 2;
          const innerPageHeight =
            initialPageHeight - (PDF_MARGIN[1] as number) * 2;

          const pxFullHeight = canvas.height;
          const pxPageHeight = Math.floor(
            canvas.width * (initialPageHeight / imgWidth)
          );
          const numberOfPages = Math.ceil(pxFullHeight / pxPageHeight);

          // Define pageHeight separately so it can be trimmed on the final page.
          let pageHeight = innerPageHeight;

          // Create a one-page canvas to split up the full image.
          const pageCanvas = document.createElement('canvas');
          // pageCanvas.style.
          const pageCtx = pageCanvas.getContext('2d');
          pageCanvas.width = canvas.width;
          pageCanvas.height = pxPageHeight;
          const pdf = new jsPDF('p', 'in', 'a4');

          for (let page = 0; page < numberOfPages; page++) {
            // Trim the final page to reduce file size.
            if (
              page === numberOfPages - 1 &&
              pxFullHeight % pxPageHeight !== 0
            ) {
              pageCanvas.height = pxFullHeight % pxPageHeight;
              pageHeight =
                (pageCanvas.height * innerPageWidth) / pageCanvas.width;
            }

            if (pageCtx) {
              // Display the page.
              const w = pageCanvas.width;
              const h = pageCanvas.height;
              pageCtx.fillStyle = PDF_PAGE_COLOR;
              pageCtx.fillRect(0, 0, w, h);
              pageCtx.drawImage(
                canvas,
                0,
                page * pxPageHeight,
                w,
                h,
                0,
                0,
                w,
                h
              );

              // Add the page to the PDF.
              if (page > 0) pdf.addPage();
              const imgData = pageCanvas.toDataURL(
                'image/' + image.type,
                image.quality
              );
              pdf.addImage(
                imgData,
                image.type,
                PDF_MARGIN[1] as number,
                PDF_MARGIN[0] as number,
                innerPageWidth,
                pageHeight
              );
            }
          }
          pdf.save(filename);
          if (adAccountTableForVendor) {
            adAccountTableForVendor.style.height = defaultHeight;
            adAccountTableForVendor.style.maxHeight = defaultMaxHeight;
          }
          if (implementationGuideTable) {
            implementationGuideTable.style.height = defaultHeight;
            implementationGuideTable.style.maxHeight = defaultMaxHeight;
          }
        }
      );
    }
  }

  public downloadPdfForImplementationGuideTable(fileName: string): void {
    const htmlData = document.getElementById('implementationGuideSection');
    const implementationGuideTable = document
      .getElementById('implementationGuideTable')
      ?.getElementsByClassName('p-datatable-wrapper')[0] as HTMLElement;

    let defaultHeight = '';
    let defaultMaxHeight = '';

    if (htmlData) {
      if (implementationGuideTable) {
        // set table height to 100% and then reset the height after generating pdf
        defaultHeight = implementationGuideTable.style.height;
        defaultMaxHeight = implementationGuideTable.style.maxHeight;
        implementationGuideTable.style.height = FULL_HEIGHT;
        implementationGuideTable.style.maxHeight = FULL_HEIGHT;
      }

      html2canvas(htmlData).then((canvas) => {
        const image = { type: JPEG_IMAGE_TYPE, quality: PDF_IMAGE_QUALITY };
        const filename = `Implementation Guide - ${fileName}.pdf`;

        const imgWidth = 8.5;
        const initialPageHeight = 10.9;

        const innerPageWidth = imgWidth - (PDF_MARGIN[0] as number) * 2;
        const innerPageHeight =
          initialPageHeight - (PDF_MARGIN[1] as number) * 2;

        const pxFullHeight = canvas.height;
        const pxPageHeight = Math.floor(
          canvas.width * (initialPageHeight / imgWidth)
        );
        const numberOfPages = Math.ceil(pxFullHeight / pxPageHeight);

        // Define pageHeight separately so it can be trimmed on the final page.
        let pageHeight = innerPageHeight;

        // Create a one-page canvas to split up the full image.
        const pageCanvas = document.createElement('canvas');
        const pageCtx = pageCanvas.getContext('2d');
        pageCanvas.width = canvas.width;
        pageCanvas.height = pxPageHeight;

        const pdf = new jsPDF('p', 'in', 'a4');

        for (let page = 0; page < numberOfPages; page++) {
          // Trim the final page to reduce file size.
          if (page === numberOfPages - 1 && pxFullHeight % pxPageHeight !== 0) {
            pageCanvas.height = pxFullHeight % pxPageHeight;
            pageHeight =
              (pageCanvas.height * innerPageWidth) / pageCanvas.width;
          }

          if (pageCtx) {
            // Display the page.
            const w = pageCanvas.width;
            const h = pageCanvas.height;
            pageCtx.fillStyle = PDF_PAGE_COLOR;
            pageCtx.fillRect(0, 0, w, h);
            pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

            // Add the page to the PDF.
            if (page > 0) pdf.addPage();
            const imgData = pageCanvas.toDataURL(
              'image/' + image.type,
              image.quality
            );
            pdf.addImage(
              imgData,
              image.type,
              PDF_MARGIN[1] as number,
              PDF_MARGIN[0] as number,
              innerPageWidth,
              pageHeight
            );
          }
        }
        pdf.save(filename);
        if (implementationGuideTable) {
          implementationGuideTable.style.height = defaultHeight;
          implementationGuideTable.style.maxHeight = defaultMaxHeight;
        }
      });
    }
  }

  private hideSVG(clonedHTMLData: HTMLElement) {
    let styleElement = clonedHTMLData.querySelector('style');
    if (!styleElement) {
      styleElement = document.createElement('style');
      styleElement.innerHTML += 'svg { display: none !important; }';
      clonedHTMLData.appendChild(styleElement);
    } else {
      styleElement.innerHTML += 'svg { display: none !important; }';
    }
  }
}
