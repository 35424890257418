import {
  FiltersType,
  ICampaign,
  IFilterCriteria,
  IVCampaign
} from '@libs/apis';
import { IPTableColumnInfo } from '@libs/common-components';
import { AvatarShape, IAvatarConfig } from '@design-system/components/m-avatar';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { compact, groupBy, isEmpty, startCase, uniq, uniqBy } from 'lodash-es';
import { Constants } from '../components/campaigns-view/campaigns-view.constants';
import { ICampaignsCountByDimension } from '../models/ICampaignsCountByDataSource';
import { IColumnFilterContext } from '../models/IColumnFilterContext';
import {
  CampaignDimension,
  TaxonomyUiConstants
} from '../taxonomy-ui/taxonomy-ui.constants';
import { AvatarTooltipUtil } from '@libs/common-services';

dayjs.extend(utc);
dayjs.extend(tz);

export class TaxonomyHelper {
  private static readonly notAssignedRuleDescription = 'Not Assigned';

  public static getCampaignString(campaignSize: number): string {
    return campaignSize > 1 ? 'campaigns' : 'campaign';
  }

  public static formatStringByCount(field: string, count: number) {
    return count > 1 ? `${field}s` : field;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static prepareFilterCriteria(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filterCriteriaFormValue: any
  ): IFilterCriteria[] {
    const filterCriteria: IFilterCriteria[] = [];
    const scopes = Object.keys(filterCriteriaFormValue);
    scopes.forEach((scope) => {
      const filterValue = filterCriteriaFormValue[`${scope}FilterValue`];
      if (filterValue) {
        filterCriteria.push({
          fieldName: scope,
          value: filterValue.trim(),
          filter: filterCriteriaFormValue[scope]
        });
      }
    });
    return filterCriteria;
  }

  public static prepareFilterCriteriaWithRecordId(
    selectedCampaigns: ICampaign[] | IVCampaign[]
  ): IFilterCriteria[] {
    return [
      {
        fieldName: 'tempRecordId',
        filter: FiltersType.IN,
        value: uniq(
          selectedCampaigns.map((campaign) => campaign.tempRecordId)
        ).join('#####')
      }
    ];
  }

  public static prepareUnpublishedCampaignsToDownload(
    headers: string[],
    campaigns: IVCampaign[]
  ): Record<string, string>[] {
    return campaigns.map((campaign) => {
      const aliasCampaign: Record<string, string> = {};
      headers.forEach((header) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        aliasCampaign[startCase(header)] = (campaign as any)[header];
      });
      return aliasCampaign;
    });
  }

  public static convertToTimezone(
    time: string | Date,
    timezone: string
  ): string {
    return new Date(time).toLocaleString('en-US', {
      timeZone: timezone,
      timeZoneName: 'short'
    });
  }

  public static getNonDuplicateSelectedCampaignDimensionOptions(
    dimension: CampaignDimension,
    campaigns: IVCampaign[]
  ): string[] {
    return uniq(
      campaigns
        .map((campaign) => campaign[dimension])
        .filter(
          (dimensionValue) =>
            dimensionValue !== TaxonomyUiConstants.unmappedValue
        )
    );
  }

  public static enrichCampaign(
    campaign: IVCampaign,
    timezone: string
  ): IVCampaign {
    const avatarConfig = TaxonomyHelper.buildAvatarConfig(campaign);
    const modifiedAt = campaign.modificationTimestamp
      ? dayjs(campaign.modificationTimestamp)
          .tz(timezone, false)
          .format('MMM DD, YYYY [at] hh:mm a z')
      : '';
    const modifiedByTooltip = TaxonomyHelper.buildModifiedByTooltip(
      campaign,
      avatarConfig,
      modifiedAt
    );
    const { tacticTooltip, channelTooltip, segmentTooltip } =
      TaxonomyHelper.buildRuleDescriptionTooltips(campaign);
    return {
      ...campaign,
      avatarConfig,
      modifiedByTooltip,
      modifiedAt,
      tacticTooltip,
      channelTooltip,
      segmentTooltip
    };
  }

  private static buildAvatarConfig(campaign: IVCampaign) {
    const initials = this.extractUserInitials(campaign) || '';
    return {
      initials,
      color: '#fff',
      bg: TaxonomyHelper.extractUserHeadshotBg(campaign),
      icon: initials === '-' ? 'smart_toy' : undefined,
      iconStyleClass: `text-gray-000`,
      shape: AvatarShape.circle
    };
  }

  private static buildModifiedByTooltip(
    campaign: IVCampaign,
    avatarConfig: IAvatarConfig,
    modifiedAt: string
  ) {
    if (campaign.modifiedBy) {
      return `
      <div class="flex flex-col gap-2 items-start" style="width: 20rem;">
        <span class="b2 text-gray-500">Last modified by</span>
        <div class="flex items-center w-full gap-4">
          <img
            src="https://ui-avatars.com/api/?rounded=true&name=${
              avatarConfig.initials
            }&background=${avatarConfig.bg?.replace(
              '#',
              ''
            )}&color=fff&format=svg&size=32"
            alt="M" />
          <div class="flex flex-col gap-1 items-start">
            <span class="b2 text-gray-000 text-start">${
              campaign.modifiedBy
            }</span>
            <span class="b2 text-gray-500 text-start">${modifiedAt}</span>
          </div>
        </div>
      </div>
    `;
    }
    if (
      campaign.tacticRuleDesc ||
      campaign.channelRuleDesc ||
      campaign.segmentRuleDesc
    ) {
      const distinctRuleIds = uniq(
        compact([
          ...campaign.tacticRuleDesc.split('#####'),
          ...campaign.channelRuleDesc.split('#####'),
          ...campaign.segmentRuleDesc.split('#####')
        ])
      ).filter((ruleDesc) => ruleDesc !== 'Not Assigned');

      return `
      <div class="flex items-center w-full" style="width: 15rem;">
        <span class="b2 text-gray-000 text-center">These values are automatically set by ${distinctRuleIds.join(
          ', '
        )}</span>
      </div>
    `;
    }

    return '';
  }

  private static extractUserInitials(campaign: IVCampaign) {
    if (!campaign.modifiedBy) {
      return '-';
    }
    const names = campaign.modifiedBy.split('@');
    return names[0]
      ?.split('.')
      .map((name: string) => name.substring(0, 1).toUpperCase())
      .join('');
  }

  private static extractUserHeadshotBg(campaign: IVCampaign) {
    const hashcode = AvatarTooltipUtil.hashCode(
      campaign.modifiedBy || campaign.tacticRuleDesc || ''
    );
    const index = Math.abs(hashcode) % 5;
    return AvatarTooltipUtil.modifiedUserColorCodes[index];
  }

  public static enrichCampaignCountsData(
    campaignCountsByType: Record<string, ICampaignsCountByDimension>
  ) {
    const enrichedCampaignCountByType: Record<
      string,
      ICampaignsCountByDimension
    > = {};

    Object.entries(campaignCountsByType).forEach(([key, value]) => {
      if (value.metrics && value.metrics.length) {
        value.metrics.forEach((metric) => {
          metric.dimensionLogoStr = metric.dimension?.replace(/ /g, '-');
        });
      }
      enrichedCampaignCountByType[key] = value;
    });

    return enrichedCampaignCountByType;
  }

  private static buildRuleDescriptionTooltips(campaign: IVCampaign): {
    tacticTooltip: string;
    channelTooltip: string;
    segmentTooltip: string;
  } {
    const ruleDescriptionTooltips = {
      tacticTooltip: '',
      channelTooltip: '',
      segmentTooltip: ''
    };

    if (
      !isEmpty(campaign.tacticRuleDesc) &&
      !campaign.tacticRuleDesc.includes(
        TaxonomyHelper.notAssignedRuleDescription
      )
    ) {
      ruleDescriptionTooltips.tacticTooltip = `
      <div class="flex flex-col gap-2 items-center w-full" style="width: 15rem;">
        <span class="b1 text-gray-500">Tactic</span>
        <span class="b2 text-gray-000 text-center">This value is automatically set by ${campaign.tacticRuleDesc
          .split('#####')
          .join(', ')}</span>
      </div>
      `;
    }
    if (
      !isEmpty(campaign.channelRuleDesc) &&
      !campaign.channelRuleDesc.includes(
        TaxonomyHelper.notAssignedRuleDescription
      )
    ) {
      ruleDescriptionTooltips.channelTooltip = `
      <div class="flex flex-col gap-2 items-center w-full" style="width: 15rem;">
        <span class="b1 text-gray-500">Channel</span>
        <span class="b2 text-gray-000 text-center">This value is automatically set by ${campaign.channelRuleDesc
          .split('#####')
          .join(', ')}</span>
      </div>
      `;
    }
    if (
      !isEmpty(campaign.segmentRuleDesc) &&
      !campaign.segmentRuleDesc.includes(
        TaxonomyHelper.notAssignedRuleDescription
      )
    ) {
      ruleDescriptionTooltips.segmentTooltip = `
      <div class="flex flex-col gap-2 items-center w-full" style="width: 15rem;">
        <span class="b1 text-gray-500">Segment</span>
        <span class="b2 text-gray-000 text-center">This value is automatically set by ${campaign.segmentRuleDesc
          .split('#####')
          .join(', ')}</span>
      </div>
      `;
    }

    return ruleDescriptionTooltips;
  }

  public static handleSpendColumnVisibility(columns: IPTableColumnInfo[]) {
    const spendColumnIndex = columns.findIndex(
      (column) => column.field === 'spend'
    );

    if (spendColumnIndex >= 0) {
      columns.splice(spendColumnIndex, 1);
    }
  }

  public static getReviewCampaignsByUnmappedCampaigns(
    campaigns: IVCampaign[],
    reviewCampaigns: IVCampaign[],
    dimensions: string[]
  ) {
    const reviewCampaignsByDimensions = groupBy(
      reviewCampaigns,
      (reviewCampaign) =>
        dimensions
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .map((dimension) => (reviewCampaign as any)[dimension])
          .join('#####')
    );
    return compact(
      uniqBy(
        campaigns
          .map((campaign) => {
            const key = dimensions
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              .map((dimension) => (campaign as any)[dimension])
              .join('#####');
            return reviewCampaignsByDimensions[key];
          })
          .flatMap((campaignsList) => campaignsList),
        'tempRecordId'
      )
    );
  }

  public static prepareGetCampaignFilterCriteria(
    filterState: IColumnFilterContext,
    selectedMappedCampaign?: IVCampaign | null
  ): IFilterCriteria[] {
    if (
      !filterState?.filterCriteria ||
      !Object.keys(filterState.filterCriteria).length
    ) {
      return [];
    }
    const filterCriteria = Object.entries(filterState.filterCriteria || {})
      .filter(
        ([dimension, dimensionValue]) =>
          !isEmpty(dimension) && !isEmpty(dimensionValue)
      )
      .flatMap(([dimension, dimensionValue]) => {
        return [
          ...dimensionValue.contain.map((value) => ({
            fieldName: dimension,
            value,
            filter: FiltersType.CONTAINS
          })),
          ...dimensionValue.exclude.map((value) => ({
            fieldName: dimension,
            value,
            filter: FiltersType.NOT_CONTAINS
          }))
        ];
      });

    if (selectedMappedCampaign) {
      return [
        ...filterCriteria,
        ...TaxonomyHelper.prepareSelectedMappedCampaignCriteria(
          selectedMappedCampaign
        )
      ];
    } else {
      return filterCriteria;
    }
  }

  public static prepareSelectedMappedCampaignCriteria(
    selectedMappedCampaign: IVCampaign | null
  ): IFilterCriteria[] {
    return [
      {
        fieldName: Constants.tempRecordId,
        value: selectedMappedCampaign?.tempRecordId || '',
        filter: FiltersType.IN
      }
    ];
  }
}
