import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  AlertButtonType,
  AlertItemProps,
  IPrimaryActionButtonProperties
} from '@design-system/components/m-alert-item';
import { SlideInAnimator } from '@libs/common-animations';
import { cloneDeep, isEqual } from 'lodash-es';

@Component({
  selector: 'm-notification-center',
  templateUrl: './m-notification-center-popup.component.html',
  styleUrls: ['./m-notification-center-popup.component.scss'],
  animations: [SlideInAnimator.slideInToLeft()]
})
export class MNotificationCenterPopupComponent implements OnInit, OnChanges {
  private static readonly maxAlertCount = 99;
  @Input() topListAlertsOriginal: AlertItemProps[] = [];
  @Input() bottomListAlertsOriginal: AlertItemProps[] = [];
  @Input() isLoading = false;
  @Input() stackedAlertsOriginal: AlertItemProps[] = [];
  @Input() unreadAlertIds: number[] = [];
  @Input() resolvedAlertIds: number[] = [];
  @Input() unreadInfoAlertsCount = 0;

  @Output() updateAlertStatusAsRead = new EventEmitter<AlertItemProps>();
  @Output() updateAlertStatusAsResolved = new EventEmitter<AlertItemProps>();
  @Output() requestStackedAlertsData = new EventEmitter<AlertItemProps>();
  @Output() handleAction = new EventEmitter<string>();
  @Output() viewAll = new EventEmitter<number>();

  bottomListAlerts: AlertItemProps[] = [];
  topListAlerts: AlertItemProps[] = [];
  stackedAlerts: AlertItemProps[] = [];
  isStackedAlertsLoading = false;
  private undoTimer = 1;

  public isStackedView = false;

  @ViewChild('contentContainer') contentContainer!: ElementRef;

  public expandedAlertId: number | undefined = undefined;

  ngOnInit(): void {
    this.stackedAlerts = cloneDeep(this.stackedAlertsOriginal);
    this.bottomListAlerts = cloneDeep(this.bottomListAlertsOriginal);
    this.topListAlerts = cloneDeep(this.topListAlertsOriginal);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.validateAlertsLoadedOnlyIfItFetchedNewAlerts(changes);
  }

  resetScrollPosition() {
    if (this.contentContainer) {
      this.contentContainer.nativeElement.scrollTop = 0;
    }
  }

  updateExpandedAlert(alert: AlertItemProps, isExpanded: boolean) {
    this.updateAlertStatusValue(alert, isExpanded);

    if (alert.stackedCount && alert.stackedCount > 1) {
      this.updateNotificationView(alert);
      return;
    }
    this.expandedAlertId = isExpanded ? undefined : alert.id;
  }

  resolveActionStatus(alert: AlertItemProps) {
    this.resolvedAlertIds.push(alert.id);

    const timerId = setTimeout(() => {
      this.finalizeResolution(alert);
    }, 5000);

    this.undoTimer = timerId as unknown as number;
  }

  undoActionStatus(alertId: number) {
    if (this.undoTimer) {
      clearTimeout(this.undoTimer);
      const index = this.resolvedAlertIds.indexOf(alertId);
      if (index !== -1) {
        this.resolvedAlertIds.splice(index, 1);
      }
    }
  }

  finalizeResolution(alert: AlertItemProps) {
    this.updateAlertStatusAsResolved.emit(alert);
  }

  triggerAction(routingLink: string) {
    this.handleAction.emit(routingLink);
  }

  updateAlertStatusValue(alert: AlertItemProps, isExpanded = true) {
    if (!isExpanded) {
      const index = this.unreadAlertIds.indexOf(alert.id);
      if (index !== -1) {
        this.unreadAlertIds.splice(index, 1);
        this.updateAlertStatusAsRead.emit(alert);
      }
    }
  }

  shouldExpand(alertId: number) {
    return alertId === this.expandedAlertId;
  }

  isAlertRead(alertId: number): boolean {
    return this.unreadAlertIds.indexOf(alertId) === -1;
  }

  isAlertResolved(alertId: number): boolean {
    return this.resolvedAlertIds.indexOf(alertId) !== -1;
  }

  getActionAlertsCount = (): string | number => {
    const { length } = this.topListAlerts;
    return length > MNotificationCenterPopupComponent.maxAlertCount
      ? `${MNotificationCenterPopupComponent.maxAlertCount}+`
      : length;
  };

  private updateNotificationView(alert?: AlertItemProps) {
    if (!this.isStackedView && alert?.isStacked) {
      this.isStackedView = true;
      this.fetchStackedAlerts(alert);
    }
  }

  private fetchStackedAlerts(alert: AlertItemProps) {
    this.resetScrollPosition();
    this.isStackedAlertsLoading = true;
    this.requestStackedAlertsData.emit(alert);
  }

  back(event: Event) {
    event.stopPropagation();
    this.isStackedView = false;
    this.expandedAlertId = undefined;
  }

  validateAlertsLoadedOnlyIfItFetchedNewAlerts(changes: SimpleChanges) {
    const stackedAlertsKey = 'stackedAlertsOriginal';
    const bottomAlertsKey = 'bottomListAlertsOriginal';
    const topAlertsKey = 'topListAlertsOriginal';
    if (
      changes &&
      changes[stackedAlertsKey] &&
      !isEqual(
        changes[stackedAlertsKey].currentValue.alerts,
        this.stackedAlerts
      )
    ) {
      this.isStackedAlertsLoading = false;
      this.stackedAlerts = cloneDeep(this.stackedAlertsOriginal);
    }

    if (
      changes &&
      changes[bottomAlertsKey] &&
      changes[bottomAlertsKey].currentValue.length !==
        this.bottomListAlerts.length
    ) {
      this.bottomListAlerts = cloneDeep(this.bottomListAlertsOriginal);
    }

    if (
      changes &&
      changes[topAlertsKey] &&
      changes[topAlertsKey].currentValue.length !== this.topListAlerts.length
    ) {
      this.topListAlerts = cloneDeep(this.topListAlertsOriginal);
    }
  }

  navigateToNotificationPage(id = 0) {
    this.viewAll.emit(id);
  }

  handleAlertActions(param: {
    type: AlertButtonType;
    actionButtonsProps: IPrimaryActionButtonProperties;
    self: AlertItemProps;
  }) {
    const { type, actionButtonsProps, self } = param;
    switch (type) {
      case AlertButtonType.actionButton: {
        const { externalLink, routingLink } = actionButtonsProps;
        if (externalLink) {
          window.open(externalLink, '_blank');
        } else if (routingLink) {
          this.triggerAction(routingLink);
        }
        break;
      }
      case AlertButtonType.markAsResolved:
        this.resolveActionStatus(self);
        break;

      case AlertButtonType.viewDetails:
        this.navigateToNotificationPage(self.id);
        break;
    }
  }
}
