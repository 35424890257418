import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { INativeFrameworkApiOperation } from '../models/INativeFrameworkApiOperation';
import { DataEndpoint, INativeFrameworkDataRequest, Utility } from '@libs/apis';
import { INativeFrameWorkDataResponse } from '../models/native-framework/INativeFrameworkTypes';
import { ISampleDashboardData } from '../models/native-framework/ISampleDashboardData';

const promiseFrom = lastValueFrom;

export class NativeFrameworkApis implements INativeFrameworkApiOperation {
  private constructor(
    private readonly httpClient: HttpClient,
    private readonly apiV1: string,
    private readonly apiV2: string
  ) {}

  public static getInstance(
    httpClient: HttpClient,
    apiV1: string,
    apiV2: string
  ): INativeFrameworkApiOperation {
    return new NativeFrameworkApis(httpClient, apiV1, apiV2);
  }

  public async getDashboardData(
    endPointType: DataEndpoint,
    reqPayLoad: INativeFrameworkDataRequest
  ): Promise<INativeFrameWorkDataResponse> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/dataviz/data/${endPointType}`
    });
    return promiseFrom(
      this.httpClient.post<INativeFrameWorkDataResponse>(url, reqPayLoad)
    );
  }

  public async getSampleDashboardData(
    sampleDashboardId: string,
    endPointType: DataEndpoint,
    reqPayload: INativeFrameworkDataRequest
  ): Promise<ISampleDashboardData> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/dataviz/data/samples/${sampleDashboardId}/${endPointType}`
    });
    return promiseFrom(
      this.httpClient.post<ISampleDashboardData>(url, reqPayload)
    );
  }
}
