import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { MultiViewService } from '@portal/app/shared/services/multi-view.service';
import { PortalEventBusService } from '@portal/app/shared/services/portal-event-bus.service';
import { MultiViewStore } from '@portal/app/shared/state/multi-view.store';
import {
  ElementGroup,
  ElementSubType,
  FieldDefinitions,
  PortalEvent,
  PortalEventCategory,
  ViewElement,
  ViewLayoutTypes,
  ViewResponse,
  ViewSaveState
} from '@portal/app/shared/types';
import { MenuItem } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { MenuItemCommandEvent } from 'primeng/api/menuitem';

interface PEvent {
  value?: {
    label: string;
  };
  originalEvent: Event;
}

@Component({
  selector: 'portal-view-controls',
  templateUrl: './view-controls.component.html',
  styleUrls: ['./view-controls.component.scss']
})
export class ViewControlsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() id: string | undefined;
  @Input() view: ViewResponse | undefined;
  @Input() selectedGroupLayout: string | undefined;
  @Input() item: ElementGroup | undefined;
  @Input() fieldDefinitions: FieldDefinitions = {};
  public showChild = false;
  public lastTimeSaved: string | undefined;

  private selectedGroup: ElementGroup | undefined;
  leftAlignedGroup: ElementGroup[] = [];
  rightAlignedGroup: ElementGroup[] = [];
  menuHolder: Record<string, MenuItem[]> = {};

  public viewPersistanceState: Observable<ViewSaveState>;
  public viewChildPersistanceState: Observable<ViewSaveState>;
  public viewSaveState = ViewSaveState;
  public readonly mpoFaqlink =
    'https://measured.helpdocs.io/article/jmodg3dffe-mpo-overview';

  public viewChildSaveState = ViewSaveState;
  private readonly subs: Subscription = new Subscription();

  constructor(
    private multiViewStore: MultiViewStore,
    public multiViewService: MultiViewService,
    private portalEventBusService: PortalEventBusService,
    private readonly route: ActivatedRoute
  ) {
    this.viewPersistanceState = this.multiViewStore.viewPersistanceState$;
    this.viewChildPersistanceState =
      this.multiViewStore.viewChildPersistanceState$;
  }

  ngOnInit(): void {
    this.setSelectedGroup();
    this.subs.add(
      this.multiViewService.viewEventState$.subscribe((val: PortalEvent) => {
        if (val?.id === this.id) {
          this.selectedGroupLayout = val?.metaData?.controlSet as string;
          this.setSelectedGroup();
        }
      })
    );
    this.subs.add(
      this.route.queryParams.subscribe((params: Params) => {
        this.showChild = JSON.parse(params.showChild || false);
      })
    );
    this.setLastTimeSaved();
    setInterval(() => {
      this.setLastTimeSaved();
    }, 60000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.view || changes.selectedGroupLayout) {
      this.setSelectedGroup();
    }
    this.setLastTimeSaved();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  setSelectedGroup(): void {
    let elementFound;
    if (this.selectedGroupLayout) {
      elementFound = this.item?.elements?.find(
        (e) => e.layoutType === this.selectedGroupLayout
      );
    }
    this.selectedGroup = elementFound
      ? (elementFound as ElementGroup)
      : (this.item?.elements?.[0] as ElementGroup);
    this.setLeftAlignedGroup();
    this.setRightAlignedGroup();
  }

  setDropdownItems() {
    [...this.leftAlignedGroup, ...this.rightAlignedGroup].forEach((e) => {
      if (e.layoutType === 'VIEW_OPERATIONS') {
        this.setDropdownButtonMenu(e);
      }
      if (e.layoutType === 'VIEW_TYPE') {
        this.setDropdownSelectMenu(e);
      }
    });
  }

  setDropdownButtonMenu(item: ElementGroup): void {
    const menuItems: ElementSubType[] = [];
    const isViewOwner = this.multiViewService.isViewOwner(this.id as string);
    const operations = item.elements?.find(
      (e) => e.layoutType === 'VIEW_OPERATION_ITEMS'
    ) as ViewElement;
    operations?.selected?.forEach((e) => {
      let isRequired = true;
      if (!isViewOwner) {
        const found = operations.ownerOnly?.find(
          (o) => e.layoutType === o.layoutType
        );
        isRequired = found ? false : isRequired;
      }
      if (isRequired) {
        menuItems.push(e);
      }
    });
    const dropdownMenu = this.menuHolder[item.literalId] ?? [];
    dropdownMenu.splice(0, dropdownMenu.length);
    menuItems.forEach((m) => {
      let changedLabel: string | undefined;
      let isDisabled = false;
      if (
        m.layoutType === 'VIEW_OPERATION_DEFAULT' &&
        this.view?.badges.default
      ) {
        isDisabled = true;
      }
      if (
        m.layoutType === 'VIEW_OPERATION_SHARE' &&
        this.view?.badges.published
      ) {
        const found = operations.inverse?.find(
          (o) => m.layoutType === o.layoutType
        );
        changedLabel = found?.label ?? undefined;
      }
      dropdownMenu.push({
        label: changedLabel ?? m.label ?? '',
        disabled: isDisabled,
        command: () => this.interact(m.layoutType as string)
      });
    });
    this.menuHolder[item.literalId] = dropdownMenu;
  }

  setDropdownSelectMenu(item: ElementGroup) {
    const selectItems = item.elements?.find(
      (e) => e.layoutType === 'VIEW_TYPE_ITEMS'
    ) as ViewElement;
    const dropdownMenu = this.menuHolder[item.literalId] ?? [];
    dropdownMenu.splice(0, dropdownMenu.length);
    selectItems.selected?.forEach((s) => {
      if (s.label) {
        dropdownMenu.push({
          label: s.label,
          command: () => this.interact(s.layoutType as string)
        });
      }
    });
    this.menuHolder[item.literalId] = dropdownMenu;
  }

  setLeftAlignedGroup(): void {
    this.leftAlignedGroup = [];
    this.selectedGroup?.elements?.forEach((each) => {
      if (!each.displayOrder || each.displayOrder <= 5) {
        this.leftAlignedGroup.push(each as ElementGroup);
      }
    });
    this.setDropdownItems();
  }

  setRightAlignedGroup(): void {
    this.rightAlignedGroup = [];
    this.selectedGroup?.elements?.forEach((each) => {
      if (each.displayOrder && each.displayOrder > 5) {
        this.rightAlignedGroup.push(each as ElementGroup);
      }
    });
    this.setDropdownItems();
  }

  onKeyupEvent(event: Event, action: string) {
    this.portalEventBusService.emit({
      action,
      eventCategory: PortalEventCategory.multiViewEvent,
      payload: { value: (event.target as HTMLInputElement).value }
    });
  }

  onDropdownSelectEvent(event: PEvent, menu: MenuItem[]) {
    const item = menu.find((e) => e.label === event.value?.label);
    if (item && item.command) {
      item.command(event as MenuItemCommandEvent);
    }
  }

  interact(action: string, item?: ElementGroup): void {
    const event: PortalEvent = {
      id: this.id,
      action,
      eventCategory: PortalEventCategory.multiViewEvent
    };
    if (item) {
      event.payload = { item, fieldDefinitions: this.fieldDefinitions };
    }
    this.portalEventBusService.emit(event);
  }

  getButtonLabel(
    layout: string,
    item: ElementGroup,
    state: ViewSaveState
  ): string {
    if (this.view?.isMeasuredDefault && state === this.viewSaveState.notSaved) {
      return 'Duplicate';
    } else if (
      layout === ViewLayoutTypes.viewSave &&
      state === ViewSaveState.saved
    ) {
      return 'Saved';
    } else if (
      (layout === ViewLayoutTypes.viewSave ||
        layout === ViewLayoutTypes.viewConfirmSave) &&
      state === this.viewSaveState.saving
    ) {
      return 'Saving';
    }
    return item?.label ?? '';
  }

  getChildButtonLabel(
    layout: string,
    item: ElementGroup,
    state: ViewSaveState
  ): string {
    if (
      layout === ViewLayoutTypes.childViewSave &&
      state === ViewSaveState.saved
    ) {
      return 'Saved';
    } else if (
      (layout === ViewLayoutTypes.childViewSave ||
        layout === ViewLayoutTypes.viewConfirmSave) &&
      state === this.viewChildSaveState.saving
    ) {
      return 'Saving';
    }
    return item?.label ?? '';
  }

  isButtonLoading(layout: string, state: ViewSaveState) {
    return (
      (layout === ViewLayoutTypes.viewSave ||
        layout === ViewLayoutTypes.viewConfirmSave) &&
      state === ViewSaveState.saving
    );
  }

  isChildButtonLoading(layout: string, state: ViewSaveState) {
    return (
      layout === ViewLayoutTypes.childViewSave && state === ViewSaveState.saving
    );
  }

  isButtonSuccess(layout: string, state: ViewSaveState) {
    return (
      layout === ViewLayoutTypes.viewSave && state === this.viewSaveState.saved
    );
  }

  isChildButtonSuccess(layout: string, state: ViewSaveState) {
    return (
      layout === ViewLayoutTypes.childViewSave &&
      state === this.viewChildSaveState.saved
    );
  }

  getButtonIcon(layout: string, state: ViewSaveState) {
    if (layout === ViewLayoutTypes.viewSave && state === ViewSaveState.saved) {
      return 'pi pi-check-circle';
    }
    return 'no-icon';
  }

  getChildButtonIcon(layout: string, state: ViewSaveState) {
    if (
      layout === ViewLayoutTypes.childViewSave &&
      state === ViewSaveState.saved
    ) {
      return 'pi pi-check-circle';
    }
    return 'no-icon';
  }

  isSaveDisabled(id: string) {
    if (this.view?.isMeasuredDefault || this.multiViewService.isViewOwner(id)) {
      return false;
    }
    return true;
  }

  get isSaveChildVisible(): boolean {
    return !this.view?.isMeasuredDefault;
  }

  setLastTimeSaved(): void {
    this.lastTimeSaved = this.multiViewService.getLastTimeSaved(
      this.id,
      this.showChild
    ) as string;
  }

  onClickFAQ(link: string): void {
    window.open(link, '_blank');
  }
}
