import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { IBackBarLayoutConfig } from '@portal/app/shared/models/IBackbarLayout';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericBackBarAbstract } from '@portal/app/shared/base-components/generic-back-bar-abstract';
import { NativeSectionsService } from '@portal/app/shared/services/native-sections.service';
import { ViewStore } from '@portal/app/shared/state/view.store';
import { take } from 'rxjs';
import { Location } from '@angular/common';
import { Required } from '@portal/app/shared/decorators/required.decorator';
@Component({
  selector: 'portal-back-bar',
  templateUrl: './back-bar.component.html',
  styleUrls: ['./back-bar.component.scss']
})
export class BackBarComponent extends GenericBackBarAbstract implements OnInit {
  @Input() @Required backBarConfig!: IBackBarLayoutConfig;
  @Output() backEvent = new EventEmitter();
  public label = this.defaultLabel;
  public routeParentPath: string | null = this.defaultRouterParentPath;
  public span = this.defaultSpan;
  private parentType?: 'REPORTING';
  private productId = '';

  constructor(
    protected readonly router: Router,
    protected readonly route: ActivatedRoute,
    protected readonly nativeSectionsService: NativeSectionsService,
    protected readonly location: Location,
    private readonly viewStore: ViewStore
  ) {
    super(router, route, nativeSectionsService, location);
  }

  ngOnInit(): void {
    this.viewStore.selectedBCPDId.pipe(take(1)).subscribe((value) => {
      this.productId = value.productId;
    });
    this.setupConfig();
  }

  back(): void {
    if (this.routeParentPath) {
      super.handleGenericBackEvent();
    } else if (this.parentType === 'REPORTING') {
      super.handleNativeBackEvent(this.productId);
    } else {
      this.backEvent.emit();
    }
  }

  setupConfig(): void {
    this.label = this.backBarConfig.label || this.defaultLabel;
    this.routeParentPath =
      this.backBarConfig.routeParentPath || this.defaultRouterParentPath;
    this.span = this.backBarConfig.span || this.defaultSpan;
    this.parentType = this.backBarConfig.parentType || this.parentType;
  }
}
