<p-inputSwitch
  class="m-switch"
  [style]="style"
  [styleClass]="styleClass"
  [ngClass]="{ toggle: isToggle }"
  [formControl]="switchFormControl"
  [name]="name"
  [tabindex]="tabindex"
  [ariaLabel]="ariaLabel"
  [ariaLabelledBy]="ariaLabelledBy"
  [disabled]="disabled"
  [trueValue]="trueValue"
  [falseValue]="falseValue"
  [readonly]="readonly"
  (onChange)="onChange.emit($event)"
  [inputId]="inputId">
</p-inputSwitch>
