@if (isLoading) {
  <div class="h-full">
    <m-skeleton width="334px" height="382px"></m-skeleton>
  </div>
}

@if (singleLineChartOptions && !isLoading) {
  <div>
    <m-card variant="small">
      <div header class="flex items-center w-full">
        <span class="sh2 mr-2">Trending</span>
        <m-dropdown
          [options]="relativeDayOptions"
          [(ngModel)]="chartRelativeDay"
          (formControlChange)="onChartRelativeDayChange($event?.value)"
          class="mr-2"></m-dropdown>
      </div>
      <div class="text-center w-full mt-2">
        <m-line-chart
          [series]="singleLineChartOptions?.series"
          [xAxis]="singleLineChartOptions?.xAxis"
          [yAxis]="singleLineChartOptions?.yAxis"
          [plotOptions]="singleLineChartOptions?.plotOptions"
          [tooltip]="singleLineChartOptions?.tooltip"
          [legend]="singleLineChartOptions?.legend"
          [height]="300">
        </m-line-chart>
      </div>
    </m-card>
  </div>
}
