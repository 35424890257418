<span class="m-card">
  <p-card [ngClass]="cardClasses" [styleClass]="styleClass">
    <!-- Header Template -->
    <ng-template pTemplate="header">
      <div class="flex items-center">
        <!-- Content projected into the header section -->
        <ng-content select="[header]"></ng-content>

        <!-- Drag Icon -->
        <span
          *ngIf="showDragIcon"
          class="material-symbols-outlined icon-small drag-icon mr-1 cursor-pointer">
          drag_indicator
        </span>

        <!-- Hoverable Icon -->
        <span
          *ngIf="showHoverIcon"
          class="material-symbols-outlined icon-small click-icon mr-1 text-blue-600 -rotate-45 cursor-pointer">
          expand_content
        </span>
      </div>
    </ng-template>

    <!-- Main content of the card -->
    <ng-content></ng-content>

    <!-- Footer Template -->
    <ng-template pTemplate="footer">
      <ng-content select="[footer]"></ng-content>
    </ng-template>
  </p-card>
</span>
