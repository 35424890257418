/* eslint-disable @typescript-eslint/no-explicit-any */
import { IGeoDoeApiOperation } from '../models/IGeoDoeApiOperation';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  ICampaign,
  ICreateGeoTestRequest,
  IDSConversionTypeOutput,
  IListCampaignsRequest,
  IRecommendedTest,
  ITestParameterHistory,
  IUpdateGeoTestRequest,
  IGeoTestResultRequest,
  IGeoTestResultResponse,
  IGeoCell,
  IGeoTest,
  Status,
  Utility,
  IConversionTimelineChartRequest,
  IConversionTimelineChartResponse,
  IScaleCostCampaignRequest,
  IScaleCostCampaignResponse,
  IGeoSpec
} from '@libs/apis';
import { isUndefined, omitBy } from 'lodash-es';
import { ITacticsByPlatformRequest } from '../models/geo-test/ITacticsByPlatformRequest';

const promiseFrom = lastValueFrom;

export class GeoDoeApis implements IGeoDoeApiOperation {
  private constructor(
    private readonly httpClient: HttpClient,
    private readonly apiV1: string,
    private readonly apiV2: string
  ) {}

  public static geoDoeApiOperation(
    httpClient: HttpClient,
    apiV1: string,
    apiV2: string
  ): IGeoDoeApiOperation {
    return new GeoDoeApis(httpClient, apiV1, apiV2);
  }

  public async getAllGeoSpecs(
    clientId: number,
    status?: Status
  ): Promise<IGeoSpec[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/geo-spec`,
      queryParameters: {
        clientId: clientId || null,
        status: status || null
      }
    });
    return promiseFrom(this.httpClient.get<IGeoSpec[]>(url));
  }

  public async getGeoSpecById(id: number): Promise<IGeoSpec> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/geo-spec/:id`,
      pathVariables: { id }
    });
    return promiseFrom(this.httpClient.get<IGeoSpec>(url));
  }

  public async getGeoSpecSplitLevel(
    clientId: number,
    conversionTypeId: number,
    compositeDataSourceId: number,
    geoSplitLevel: string
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/geo-spec`,
      queryParameters: {
        spec: false,
        clientId,
        conversionTypeId,
        compositeDataSourceId,
        geoSplitLevel,
        status: Status.ACTIVE
      }
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async validateGeoSpec(postData: any): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/geo-spec/validate-geo-spec`
    });
    return promiseFrom(this.httpClient.post(url, postData));
  }

  public async createNewGeoSpec(postData: any): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/geo-spec`,
      queryParameters: {
        isSpec: false
      }
    });
    return promiseFrom(this.httpClient.post(url, postData));
  }

  public async updateGeoSpecRecords(id: number, data: any): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/geo-spec/:id`,
      pathVariables: { id }
    });
    return promiseFrom(this.httpClient.put(url, data));
  }

  public async getAllGeoTests(
    clientId: number,
    brandId: number,
    statuses: Status[]
  ): Promise<IGeoTest[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/geo-tests`,
      queryParameters: {
        clientId,
        brandId,
        statuses: statuses.join(',')
      }
    });
    return promiseFrom(this.httpClient.get<IGeoTest[]>(url));
  }

  public async getTestRecommendations(
    clientId: number,
    brandId: number,
    conversionTypeId: number
  ): Promise<IRecommendedTest[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/geo-test-recommendations`,
      queryParameters: {
        clientId,
        brandId,
        conversionTypeId
      }
    });
    return promiseFrom(this.httpClient.get<IRecommendedTest[]>(url));
  }

  public async updateTestParameters(
    testRecommendationId: number | undefined,
    studyId: number,
    cellConfigId: number,
    postData: any
  ): Promise<ITestParameterHistory[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/geo-test-recommendations/${testRecommendationId}/study/${studyId}/cell-config/${cellConfigId}/test-parameter`
    });
    return promiseFrom(
      this.httpClient.patch<ITestParameterHistory[]>(url, postData)
    );
  }

  public async getDSMdlSimulationReferenceData(
    clientId: number,
    brandId: number,
    conversionTypeId?: number
  ): Promise<IDSConversionTypeOutput[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/geo-tests-ds-simulation/reference-data`,
      queryParameters: omitBy(
        {
          clientId,
          brandId,
          conversionTypeId
        },
        isUndefined
      )
    });

    return promiseFrom(this.httpClient.get<IDSConversionTypeOutput[]>(url));
  }

  public async getGeoRegionsForRecommendation(
    clientId: number,
    brandId: number,
    conversionTypeId: number,
    startDate: string,
    endDate: string
  ): Promise<IGeoCell> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/geo-tests-ds-simulation/available-geo-cell`,
      queryParameters: {
        clientId,
        brandId,
        conversionTypeId,
        startDate,
        endDate
      }
    });

    return promiseFrom(this.httpClient.get<IGeoCell>(url));
  }

  public async getExistingGeoTestGeoCell(
    studyId: number,
    isV2: boolean,
    cellName: string,
    locationIds: number[]
  ): Promise<IGeoCell> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/geo-tests-ds-simulation/geo-cell`,
      queryParameters: {
        studyId,
        isV2,
        locationIds,
        cellName
      }
    });

    return promiseFrom(this.httpClient.get<IGeoCell>(url));
  }

  public async getGeoDsMarkets(
    clientId: number,
    brandId: number,
    conversionTypeId: number
  ): Promise<IGeoCell[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/geo-tests-ds-simulation/geo-ds-markets`,
      queryParameters: {
        clientId,
        brandId,
        conversionTypeId
      }
    });

    return promiseFrom(this.httpClient.get<IGeoCell[]>(url));
  }

  public getGeoTestCampaigns(
    listCampaingsRequest: Partial<IListCampaignsRequest>
  ): Promise<ICampaign[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/geo-tests/campaigns`,
      queryParameters: { clientId: listCampaingsRequest.clientId }
    });

    return promiseFrom(
      this.httpClient.post<ICampaign[]>(url, listCampaingsRequest)
    );
  }

  public createGeoTest(
    clientId: number,
    brandId: number,
    createGeoTestRequest: ICreateGeoTestRequest
  ): Promise<IGeoTest> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/geo-tests/`,
      queryParameters: { clientId, brandId }
    });

    return promiseFrom(
      this.httpClient.post<IGeoTest>(url, createGeoTestRequest)
    );
  }

  public updateGeoTest(
    studyId: number,
    updateGeoTestRequest: Partial<IUpdateGeoTestRequest>
  ): Promise<IGeoTest> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/geo-tests/:studyId`,
      pathVariables: {
        studyId
      }
    });

    const requestBody = omitBy(updateGeoTestRequest, isUndefined);

    return promiseFrom(this.httpClient.patch<IGeoTest>(url, requestBody));
  }

  public getGeoTestResult(
    clientId: number,
    brandId: number,
    testResultRequest: IGeoTestResultRequest
  ): Promise<IGeoTestResultResponse> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/geo-test-result`,
      queryParameters: {
        clientId,
        brandId
      }
    });
    return promiseFrom(
      this.httpClient.post<IGeoTestResultResponse>(url, testResultRequest)
    );
  }

  public getConversionTimelineChart(
    clientId: number,
    brandId: number,
    conversionTimelineChartRequest: IConversionTimelineChartRequest
  ): Promise<IConversionTimelineChartResponse[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/geo-test-result/actual-vs-predicated-chart-data`,
      queryParameters: {
        clientId,
        brandId
      }
    });
    return promiseFrom(
      this.httpClient.post<IConversionTimelineChartResponse[]>(
        url,
        conversionTimelineChartRequest
      )
    );
  }

  public getScaleCost(
    scaleCostRequest: IScaleCostCampaignRequest
  ): Promise<IScaleCostCampaignResponse> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/geo-tests/scale-cost`
    });
    const requestBody = omitBy(scaleCostRequest, isUndefined);
    return promiseFrom(
      this.httpClient.post<IScaleCostCampaignResponse>(url, requestBody)
    );
  }

  public getTacticsByPlatform(
    tacticsByPlatformRequest: ITacticsByPlatformRequest
  ): Promise<{ tactic: string }[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/geo-tests/tactics`
    });
    return promiseFrom(
      this.httpClient.post<{ tactic: string }[]>(url, tacticsByPlatformRequest)
    );
  }

  public getRecommendedConversionTypes(
    clientId: number,
    brandId: number
  ): Promise<{
    conversionTypes: { id: number; conversionType: string }[];
    defaultConversionTypeId: number;
  }> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/geo-tests/recommended-conversion-types`,
      queryParameters: {
        clientId,
        brandId
      }
    });

    return promiseFrom(
      this.httpClient.get<{
        conversionTypes: { id: number; conversionType: string }[];
        defaultConversionTypeId: number;
      }>(url)
    );
  }
}
