import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
  UrlMatchResult,
  UrlSegment
} from '@angular/router';

import { ErrorHandlerComponent } from '@portal/app/dashboard/error-handler/error-handler.component';
import { ExportNativeDashboardDataComponent } from '@portal/app/dashboard/export-native-dashboard-data/export-native-dashboard-data.component';
import { ShopifyDashboardComponent } from '@portal/app/dashboard/samples/shopify/shopify-dashboard.component';
import { SelectBrandComponent } from '@portal/app/dashboard/select-brand/select-brand.component';
import { ViewComponent } from '@portal/app/dashboard/view/view.component';
import { TableauComponent } from '@portal/app/shared/components/tableau/tableau.component';
import { AuthGuard } from '@portal/app/shared/helpers/auth-guard';
import { AuthProductGuard } from '@portal/app/shared/helpers/auth-product-guard';
import { titleBase } from '@portal/app/shared/helpers/helpers';
import { UserDetailsPageComponent } from '../shared/components/user-details-page/user-details-page.component';
import { CustomerFacingTaxonomyComponent } from './customer-facing-taxonomy/customer-facing-taxonomy.component';
import { TemplateComponent } from './template/template.component';
import { NotificationCenterComponent } from '@portal/app/dashboard/notification-center/notification-center.component';
import { MPO_RECOMMENDED_PLAN_ID } from '@portal/app/mpo/mpo.constants';

// path: ':brandSlug/products/:productId/:literalId', -> nativeUrlMatcher
const nativeUrlMatcher = (segments: UrlSegment[]): UrlMatchResult | null => {
  if (segments.length >= 4 && segments.length <= 5) {
    // has at least 4 or 5 parts
    const productId = segments[2]?.path;
    if (
      segments[1]?.path === 'products' &&
      isNaN(parseInt(productId ?? '', 10))
    ) {
      if (
        [
          'geo-doe',
          'geo-designer',
          'facebook-scale-config',
          'integrations',
          'admin',
          'mpo'
        ].includes(productId ?? '')
      ) {
        return null;
      }
      // products/some-string
      const posParams: Record<string, UrlSegment> = {};
      posParams.brandSlug = segments[0] as UrlSegment;
      posParams.productId = segments[2] as UrlSegment;
      posParams.literalId = segments[3] as UrlSegment;
      if (segments[4]) {
        posParams.viewId = segments[4];
      }

      return { consumed: segments.slice(0, segments.length), posParams };
    }
  }

  return null;
};

// path: ':brandSlug/products/:productId/:productName', -> tableauUrlMatcher
const tableauUrlMatcher = (segments: UrlSegment[]): UrlMatchResult | null => {
  if (segments.length === 4) {
    // has at least 4 parts
    if (
      segments[1]?.path === 'products' &&
      !isNaN(parseInt(segments[2]?.path ?? '', 10)) // is a number
    ) {
      // products/some-string
      const posParams: Record<string, UrlSegment> = {};
      posParams.brandSlug = segments[0] as UrlSegment;
      posParams.productId = segments[2] as UrlSegment;
      posParams.literalId = segments[3] as UrlSegment;

      return { consumed: segments.slice(0, segments.length), posParams };
    }
  }

  return null;
};

const routes: Routes = [
  {
    path: 'a',
    component: TemplateComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'select-brand',
        component: SelectBrandComponent,
        data: { title: `${titleBase} Select a Brand` }
      },
      {
        path: ':brandSlug/products/facebook-scale-config',
        loadChildren: () =>
          import('./facebook-scale/facebook-scale.module').then(
            (m) => m.FacebookScaleModule
          )
      },
      {
        path: ':brandSlug/products/geo-doe',
        loadChildren: () =>
          import('./geo-doe-config/geo-doe-config.module').then(
            (m) => m.GeoDoeConfigModule
          )
      },
      {
        path: ':brandSlug/products/integrations',
        loadChildren: () =>
          import('./integrations/integrations.module').then(
            (m) => m.IntegrationsModule
          )
      },
      {
        path: ':brandSlug/products/settings-manager',
        loadChildren: () =>
          import('./setting-manager/setting-manager.module').then(
            (m) => m.SettingManagerModule
          )
      },
      {
        matcher: nativeUrlMatcher,
        component: ViewComponent,
        canActivate: [AuthProductGuard],
        data: { title: `${titleBase} Dashboard` }
      },
      {
        matcher: tableauUrlMatcher,
        component: TableauComponent,
        canActivate: [AuthProductGuard],
        data: { title: `${titleBase} Dashboard` }
      },
      {
        path: ':brandSlug/products/export-report',
        canActivate: [AuthProductGuard],
        component: ExportNativeDashboardDataComponent
      },
      {
        path: ':brandSlug/products/errors',
        canActivate: [AuthProductGuard],
        component: ErrorHandlerComponent
      },
      {
        path: ':brandSlug/products/geo-designer',
        loadChildren: () =>
          import('../geo-designer/geo-designer.module').then(
            (m) => m.GeoDesignerModule
          )
      },
      {
        path: 'user',
        component: UserDetailsPageComponent,
        data: { title: `${titleBase} User Details` }
      },
      {
        path: ':brandSlug/products/admin',
        loadChildren: () =>
          import('./admin/admin.module').then((m) => m.AdminModule)
      },
      {
        path: ':brandSlug/products/campaign-mapping',
        canActivate: [AuthProductGuard],
        component: CustomerFacingTaxonomyComponent
      },
      {
        path: ':brandSlug/products/shopify-dashboard',
        canActivate: [AuthProductGuard],
        component: ShopifyDashboardComponent
      },
      {
        path: ':brandSlug/products/optimization-report',
        loadChildren: () =>
          import('../optimization-report/optimization-report.module').then(
            (m) => m.OptimizationReportModule
          )
      },
      {
        path: ':brandSlug/products/notification-center',
        canActivate: [AuthProductGuard],
        component: NotificationCenterComponent
      },
      {
        path: ':brandSlug/products/control-tower',
        loadChildren: () =>
          import('../control-tower/control-tower.module').then(
            (m) => m.ControlTowerModule
          )
      },
      {
        path: ':brandSlug/products/mpo',
        children: [
          {
            path: '',
            redirectTo: `plans/${MPO_RECOMMENDED_PLAN_ID}`,
            pathMatch: 'full'
          },
          {
            path: 'plans/:planId',
            loadChildren: () =>
              import('../mpo/mpo.module').then((m) => m.MPOPageModule)
          }
        ]
      },
      { path: '', redirectTo: 'select-brand', pathMatch: 'full' },
      { path: '**', redirectTo: 'select-brand', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule {}
