<m-fade-in-up>
  @if (isLoading()) {
    <m-skeleton height="280px" class="w-full"></m-skeleton>
  } @else if (!isDataAvailable() && !returnCurveIsReady()) {
    <m-not-available
      height="280px"
      description="We don't have a summary ready for you now"></m-not-available>
  } @else {
    <m-card class="w-full" [noShadow]="true" [variant]="cardVariant.compact">
      <div class="w-full grid grid-cols-12 gap-6">
        <div class="col-span-6">
          @if (topMetric()) {
            <m-card [variant]="cardVariant.small">
              <div class="grid grid-cols-12 gap-6 items-center">
                <div class="text-center col-span-4">
                  <div class="max-w-[200px] m-auto">
                    <img
                      class="max-w-full"
                      [src]="'assets/images/taxes.svg'"
                      alt="Metric" />
                  </div>
                </div>
                <div class="col-span-8 flex flex-col gap-1">
                  <div class="sh2 text-gray-1000 mb-1">
                    {{ topMetric().label }}
                    <span
                      class="ml-1"
                      [ngClass]="
                        topMetric().isChangePositive
                          ? 'text-green-700'
                          : 'text-orange-300'
                      "
                      >{{ topMetric().formattedValue }}</span
                    >
                  </div>
                  <div class="c1 text-gray-800 mb-[16px]">
                    Comparison period: {{ comparisonPeriod() }}
                  </div>
                  <div class="c1 text-gray-1000 mb-2">
                    Drivers for {{ topMetric().label }}:
                  </div>
                  <portal-context-driver-display
                    [column]="columnType.one"
                    [drivers]="drivers()"></portal-context-driver-display>
                </div>
              </div>
            </m-card>
          } @else {
            <m-not-available
              height="280px"
              description="We don't have a summary for you now"></m-not-available>
          }
        </div>
        <div class="col-span-6">
          @if (
            !returnCurveIsLoading() &&
            returnCurveIsReady() &&
            returnCurveValid()
          ) {
            <m-line-chart
              height="280px"
              [series]="returnCurveChartOptions().series"
              [xAxis]="returnCurveChartOptions().xAxis"
              [yAxis]="returnCurveChartOptions().yAxis"
              [tooltip]="returnCurveChartOptions().tooltip"
              [legend]="legend"
              class="w-full">
            </m-line-chart>
          } @else {
            <m-not-available
              height="280px"
              description="We don't have a return curve ready for you now"></m-not-available>
          }
        </div>
      </div>
    </m-card>
  }
</m-fade-in-up>
