import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  AssociatedFilterValues,
  Dashboard,
  DataEndpoint,
  DataPointFilters,
  DataResponse,
  DataRowData,
  DataSetResponse,
  ElementGroup,
  FieldDefinitions,
  Filter,
  FiltersToSend,
  FilterValue,
  LayoutResponse,
  LogRequestParams,
  LogResponse,
  NativeDashboard,
  PermissionResponse,
  ProductDashboardInstance,
  User,
  DefaultViewResponse,
  PatchViewResponse,
  ViewOperations,
  ViewResponse,
  ViewType,
  EndpointType,
  DashboardDataOrCountResponseType,
  ProductDashboardPersistenceMode,
  IParentChildFilterMapping,
  PersistenceRequest,
  OverrideLevel,
  ConfigDashboardInstance,
  ExportTableType,
  DataGridExportConfig,
  TableResponse,
  PivotTableResponse,
  DataGridAvailable,
  IExpandableTableData,
  IExportTableConfig,
  ITableField,
  ChartResponse,
  IDashboardRefreshDate,
  IDashboardAllDataSourcesRefreshDate,
  ReportingRangeConfigResponse
} from '@portal/app/shared/types';
import {
  catchError,
  firstValueFrom as promisify,
  forkJoin,
  Observable,
  Subject,
  take,
  retry,
  of
} from 'rxjs';
import { cloneDeep, debounce, isArray, isNil, omitBy, unset } from 'lodash-es';
import { environment } from '@portal/environments/environment';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ViewStore } from '@portal/app/shared/state/view.store';
import { ContextStore } from '@portal/app/shared/state/context.store';
import { CompareService } from '@portal/app/shared/services/compare.service';
import { Store } from '@ngrx/store';
import { DashboardActions } from '@portal/app/store/dashboard/actions';
import { saveAs } from 'file-saver';
import {
  DateTimeService,
  dayFilterLiteralIds
} from '@portal/app/shared/services/date-time.service';
import { DashboardState } from '@portal/app/store/app.state';
import {
  IExportConfig,
  IExportDataReqParams
} from '@portal/app/dashboard/export-native-dashboard-data/models/IExportConfig';
import { ProductLiteralIds } from '@portal/app/shared/constants';
import dayjs from 'dayjs';
import { ConversionTypeService } from '@portal/app/shared/services/conversion-type-service';
import { IConversionTypeByRollUp } from '@portal/app/shared/models/IConversionTypeByRollUp';

interface QueryParams {
  clientId?: number;
  brandId?: number;
  productId?: string;
}
interface DashboardDataParams {
  filters: Filter[];
  type: DataEndpoint;
  dimensionValues?: string[];
  dimensionFormat?: string;
  viewId?: number;
  getCount?: boolean;
  dataSetLiteralIds?: string[];
}

interface IExportDashboardDataParams {
  filters: Filter[];
  type: DataEndpoint;
  dimensionValues?: string[];
  dimensionFormat?: string;
  viewId?: number;
  getCount?: boolean;
  exportObjParams: ExportObjectParamValue;
}

interface GenerateParams {
  clientId: number;
  brandId: number;
  productId: string;
  literalId: string;
  filtersToSend: Record<string, FilterValue>;
  dimensionValues?: string[];
  associatedFilters: AssociatedFilterValues;
  viewId?: number;
  exportObjParams?: ExportObjectParamValue;
}

export interface ExportObjectParamValue {
  name: string;
  literalId: string;
  format?: ExportTableType;
  table?: TableResponse | PivotTableResponse;
  availableFields?: DataGridAvailable[];
  config?: DataGridExportConfig;
  pivotRows?: boolean;
  isPivotTable?: boolean;
  showFooter?: boolean;
  groupByFieldName?: string | undefined;
  tableData?: IExpandableTableData[];
  expansionTableConfig?: IExportTableConfig;
  columns?: ITableField[];
  chart?: ChartResponse;
}

export type DashboardParams = Pick<
  QueryParams,
  'clientId' | 'brandId' | 'productId'
>;
export type ConfigDashboardParams = Pick<QueryParams, 'clientId' | 'brandId'>;
const apiDomain = environment.apiDomain;
const gridDimensionsLiteralId = 'grid_dimensions';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public readonly cancelPendingRequest = new Subject<boolean>();
  private conversionTypeService = inject(ConversionTypeService);

  get pendingRequestCancellationStatus(): Observable<boolean> {
    return this.cancelPendingRequest.asObservable();
  }

  private callOverrideApi = debounce((layout, options) => {
    this.overrideLayout(layout, options)
      .then((value: ElementGroup[]) => {
        this.store.dispatch(DashboardActions.updateLayout({ layout: value }));
      })
      .catch((err) => {
        console.error(err);
      });
  }, 5000);

  private callPersistenceApi = debounce((layout, options) => {
    this.savePersistenceLayout(layout, options)
      .then((value: ElementGroup[]) => {
        this.store.dispatch(DashboardActions.updateLayout({ layout: value }));
      })
      .catch((err) => {
        console.error(err);
      });
  }, 5000);

  private callOverrideFilterApi = debounce((filters, options) => {
    this.overrideFilters(filters, options)
      .then(() => {
        this.store.dispatch(DashboardActions.clearUpdatedFilters());
      })
      .catch((err) => {
        this.store.dispatch(DashboardActions.clearUpdatedFilters());
        console.error(err);
      });
  }, 0);

  private readonly apiRootV1 = `${apiDomain}/api/v1`;
  private readonly apiRootV2 = `${apiDomain}/api/v2`;
  private clientId = 0;
  private brandId = 0;
  private productId = '';
  private literalId = '';
  constructor(
    private readonly httpClient: HttpClient,
    private readonly viewStore: ViewStore,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly contextStore: ContextStore,
    private readonly compareService: CompareService,
    private readonly store: Store,
    private readonly dateTimeService: DateTimeService
  ) {
    this.viewStore.selectedBCPDId.subscribe((value) => {
      this.clientId = value.clientId;
      this.brandId = value.brandId;
      this.productId = value.productId;
      this.literalId = value.literalId;
    });
  }

  private static generateParams(params: GenerateParams): Params {
    const {
      clientId,
      brandId,
      productId: product,
      literalId: dashboard,
      filtersToSend,
      dimensionValues = [],
      associatedFilters = {},
      viewId = undefined,
      exportObjParams = {}
    } = params;
    Object.keys(associatedFilters).forEach((filterKey) => {
      // TODO this has to be fixed, this is not good code.
      filtersToSend[filterKey] = associatedFilters[filterKey] as unknown as
        | string
        | string[]
        | number
        | number[];
    });
    return {
      clientId,
      brandId,
      product,
      dashboard,
      filter: filtersToSend,
      dimensions:
        dimensionValues && dimensionValues.length > 0
          ? dimensionValues
          : undefined,
      viewId,
      exportObjParams
    };
  }

  public getUser(testLogin = false): Observable<User> {
    let options = {};
    if (testLogin) {
      options = { params: { testLogin: 'true' } };
    }
    return this.httpClient.get<User>(`${this.apiRootV2}/user`, options);
  }

  public getClients(): Observable<PermissionResponse> {
    return this.httpClient.get<PermissionResponse>(
      `${this.apiRootV2}/user/permissions`
    );
  }

  public getAvailableDashboards(
    queryParams?: DashboardParams
  ): Observable<Dashboard[]> {
    let options = {};
    if (queryParams != null) {
      options = { params: queryParams };
    }

    return this.httpClient
      .get<Dashboard[]>(`${this.apiRootV1}/dashboards`, options)
      .pipe(
        retry(3),
        catchError((error) => {
          console.error('Error in getAvailableDashboards:', error);
          return of([]);
        })
      );
  }

  public getNativeDashboards(
    queryParams: DashboardParams
  ): Observable<NativeDashboard[]> {
    const options = { params: queryParams };

    return this.httpClient
      .get<
        NativeDashboard[]
      >(`${this.apiRootV2}/dataviz/dashboard-instances`, options)
      .pipe(
        retry(3),
        catchError((error) => {
          console.error('Error in getNativeDashboards:', error);
          return of([]);
        })
      );
  }

  public getConfigDashboards(
    queryParams: ConfigDashboardParams
  ): Observable<ConfigDashboardInstance[]> {
    const options = { params: queryParams };

    return this.httpClient
      .get<
        ConfigDashboardInstance[]
      >(`${this.apiRootV2}/dataviz/config-dashboard-instances`, options)
      .pipe(
        retry(3),
        catchError((error) => {
          console.error('Error in getConfigDashboards:', error);
          return of([]);
        })
      );
  }

  public postLog(
    logLevel: 'INFO' | 'WARNING' | 'ERROR' = 'ERROR',
    message: string,
    messageType: string
  ): Observable<LogResponse> {
    const logBody: LogRequestParams = {
      appId: 'portal',
      message,
      subType: messageType
    };
    return this.httpClient.post<LogResponse>(
      `${this.apiRootV1}/log/${logLevel}`,
      logBody
    );
  }

  public getFieldDefinitions(): Observable<FieldDefinitions> {
    const options = {
      params: {
        clientId: this.clientId,
        brandId: this.brandId,
        productId: this.productId,
        dashboardId: this.literalId
      }
    };
    return this.httpClient.get<FieldDefinitions>(
      `${this.apiRootV2}/dataviz/config/fields`,
      options
    );
  }

  public getFilters(
    type: DataEndpoint = DataEndpoint.dataPoints,
    filters?: Filter[],
    viewId?: number
  ): Observable<Filter[]> {
    const filter = this.generateFiltersToSend(filters || [], type);
    const params: Params = {
      clientId: this.clientId,
      brandId: this.brandId,
      productId: this.productId,
      dashboardId: this.literalId,
      filter
    };
    if (viewId) {
      params.viewId = viewId;
    }
    return this.httpClient.post<Filter[]>(
      `${this.apiRootV2}/dataviz/config/filters`,
      params
    );
  }

  public getLayout(viewId?: number): Observable<ElementGroup[]> {
    const params: Params = {
      clientId: this.clientId,
      brandId: this.brandId,
      productId: this.productId,
      dashboardId: this.literalId
    };
    const options = { params };
    if (viewId) {
      options.params.viewId = viewId;
    }
    return this.httpClient.get<ElementGroup[]>(
      `${this.apiRootV2}/dataviz/config/layout`,
      options
    );
  }

  public getDashboardByProduct(): Observable<ProductDashboardInstance[]> {
    const options = {
      params: {
        clientId: this.clientId,
        brandId: this.brandId,
        productId: this.productId
      }
    };
    return this.httpClient.get<ProductDashboardInstance[]>(
      `${this.apiRootV2}/dataviz/dashboard-instances`,
      options
    );
  }

  public getDashboard(viewId?: number): Observable<LayoutResponse> {
    return new Observable((subscribe) => {
      forkJoin([
        this.getDashboardByProduct(),
        this.getFilters(undefined, undefined, viewId),
        this.getFieldDefinitions(),
        this.getLayout(viewId),
        this.getDynamicFilters(),
        this.getReportingRange(),
        this.conversionTypeService.getConversionTypes()
      ]).subscribe({
        next: (
          values: [
            ProductDashboardInstance[],
            Filter[],
            FieldDefinitions,
            ElementGroup[],
            IParentChildFilterMapping,
            ReportingRangeConfigResponse,
            IConversionTypeByRollUp
          ]
        ) => {
          const dashboardInstance = values[0].find(
            (d) => d.dashboard.literalId === this.literalId
          );

          this.viewStore
            .changeTitle(
              (dashboardInstance?.displayName ||
                dashboardInstance?.dashboard.displayName) ??
                ''
            )
            .subscribe({
              error: (err) => console.error(err)
            });
          const dashboard: DashboardState = {
            dashboardLiteralId: dashboardInstance
              ? dashboardInstance.dashboard.literalId
              : '',
            dashboardPersistence: dashboardInstance?.dashboard
              ?.dashboardPersistenceMode
              ? dashboardInstance?.dashboard?.dashboardPersistenceMode
              : null,
            dimensionFormat: null,
            dynamicFilters: values[4] ? values[4] : null,
            displayName: getDisplayName(dashboardInstance),
            filters: values[1],
            fieldDefinitions: values[2],
            layout: values[3],
            isMultiView:
              dashboardInstance?.dashboard.dashboardPersistenceMode ===
              ProductDashboardPersistenceMode.multiView,
            faqLink: dashboardInstance?.dashboard?.faqLink || null,
            reportingRangeInMonths: this.setReportingRangeInMonths(values[5]),
            rollupConversionTypes: values[6].rollupConversionTypes
          };

          this.store.dispatch(DashboardActions.setDashboard(dashboard));
          subscribe.next(dashboard);
          subscribe.complete();
        },
        error: (error) => {
          subscribe.error(error);
          subscribe.complete();
        }
      });
    });
  }

  handleTacticInChildPage(filters: Filter[]) {
    const { queryParams } = this.route.snapshot;
    if (
      filters &&
      this.route.snapshot.queryParams.viewId &&
      this.route.snapshot.queryParams.showChild
    ) {
      const tacticFilter = filters.find((f) => f.literalId === 'tactic');
      if (tacticFilter) {
        tacticFilter.value = JSON.parse(queryParams.tactic);
      }
    }
  }

  public getDashboardData(
    params: DashboardDataParams,
    dashboardLiteralId?: string,
    productLiteralId?: string
  ): Promise<DashboardDataOrCountResponseType> {
    const {
      filters: f,
      type,
      dimensionValues,
      dimensionFormat,
      viewId,
      getCount,
      dataSetLiteralIds = undefined
    } = params;
    const filters = cloneDeep(f);
    if (type === (DataEndpoint.dataCharts || DataEndpoint.dataPoints)) {
      this.modifyQueryParams(filters);
    }
    this.handleTacticInChildPage(filters);
    // get filters to send from filter service
    const filtersToSend = this.generateFiltersToSend(filters, type);
    const endPointType = this.getBackendSupportedEndpointType(type);
    const countEndpoint = `${this.apiRootV2}/dataviz/data/count/${endPointType}`;
    const dataEndpoint = `${this.apiRootV2}/dataviz/data/${type}`;
    const endpoint = getCount ? countEndpoint : dataEndpoint;

    // if dimensionFormat is provided include it in filter
    if (dimensionFormat) {
      filtersToSend.dimensionFormat = dimensionFormat;
    }
    if (dataSetLiteralIds && isArray(dataSetLiteralIds)) {
      filtersToSend.dataSets = dataSetLiteralIds;
    }
    const dataPointFilters = this.getDataPointFilters(filters, filtersToSend);
    if (dataPointFilters) {
      return promisify(
        this.httpClient.post<DashboardDataOrCountResponseType>(
          endpoint,
          dataPointFilters
        )
      );
    } else {
      const body = ApiService.generateParams({
        clientId: this.clientId,
        brandId: this.brandId,
        productId: productLiteralId || this.productId,
        literalId: dashboardLiteralId || this.literalId,
        associatedFilters: this.contextStore.associatedFilterSelection,
        filtersToSend,
        dimensionValues,
        viewId
      });
      return promisify(
        this.httpClient.post<DashboardDataOrCountResponseType>(endpoint, body)
      );
    }
  }

  public getExportData(params: IExportDashboardDataParams) {
    const {
      filters: f,
      type,
      dimensionValues,
      dimensionFormat,
      viewId,
      exportObjParams
    } = params;
    const filters = cloneDeep(f);
    let body: Params;

    // get filters to send from filter service
    const filtersToSend = this.generateFiltersToSend(filters, type);
    const endpoint = `${this.apiRootV2}/dataviz/data/${type}`;
    if (dimensionFormat) {
      filtersToSend.dimensionFormat = dimensionFormat;
    }
    const dataPointFilters = this.getDataPointFilters(filters, filtersToSend);
    if (dataPointFilters) {
      return promisify(
        this.httpClient.post<DashboardDataOrCountResponseType>(
          endpoint,
          dataPointFilters
        )
      );
    } else {
      body = ApiService.generateParams({
        clientId: this.clientId,
        brandId: this.brandId,
        productId: this.productId,
        literalId: this.literalId,
        associatedFilters: this.contextStore.associatedFilterSelection,
        filtersToSend,
        dimensionValues,
        viewId,
        exportObjParams
      });
    }
    this.httpClient
      .post(endpoint, body, { responseType: 'blob' })
      .subscribe((response) => {
        const filename = `${exportObjParams?.name}.${exportObjParams?.format}`;
        saveAs(response, filename);
      });
  }

  public postDashboardData(
    filters: Filter[],
    type: DataEndpoint = DataEndpoint.dataPoints
  ): Promise<DataResponse | DataSetResponse[] | DataSetResponse> {
    const filtersToSend = this.generateFiltersToSend(filters, type);
    const dataPointFilters = this.getDataPointFilters(filters, filtersToSend);
    const endpoint = `${this.apiRootV2}/dataviz/data/${type}`;
    return promisify(
      this.httpClient.post<DataResponse | DataSetResponse[] | DataSetResponse>(
        endpoint,
        dataPointFilters
      )
    );
  }

  private getDataPointFilters(
    filters: Filter[],
    filtersToSend: FiltersToSend
  ): DataPointFilters | undefined {
    const gridDimensions = filters.find(
      (el) => el.literalId === gridDimensionsLiteralId
    );
    let dataPointFilters: DataPointFilters | undefined;
    // if grid dimensions exist create custom filters for data points
    if (gridDimensions) {
      const customFilters: DataPointFilters = {
        ['conversion_type']: filtersToSend.conversion_type || '',
        dateStart: filtersToSend.dateStart || '',
        dateStop: filtersToSend.dateStop || '',
        dataPoints: filtersToSend.dataPoints || '',
        gridDimensions: gridDimensions.value as DataRowData[]
      };
      dataPointFilters = {
        clientId: this.clientId,
        brandId: this.brandId,
        dashboard: this.literalId,
        product: this.productId,
        filter: customFilters
      };
    }
    return dataPointFilters;
  }

  public generateFiltersToSend(
    filters: Filter[],
    dataEndpoint: DataEndpoint
  ): FiltersToSend {
    const type = this.getBackendSupportedEndpointType(dataEndpoint);
    // When should a filter be sent to the backend?
    // When isRequired = 'YES' and applyTo = null (global filter)
    // When isRequired = 'YES' and applyTo = the same Type (DATA_POINT | DATA_SET | DATA_CHART)
    // When isRequired = 'NO' and applyTo = null AND the value is SET
    // When isRequired = 'NO' and applyTo = the same Type (DATA_POINT | DATA_SET | DATA_CHART) AND the value is SET
    const filtersToSend: FiltersToSend = {};
    const dates =
      this.dateTimeService.prepareDateStartAndStopForFiltersInitialCall();
    if (filters.length === 0 && dates && isArray(dates) && dates.length === 2) {
      const [dateStart, dateStop] = dates;
      return {
        dateStart: dateStart ?? '',
        dateStop: dateStop ?? '',
        isInitialFilterCall: true
      };
    }
    filters
      .filter(
        (filter) => filter.literalId !== 'orderdate' || filter.value == null
      )
      .forEach((filter) => {
        if (
          (filter.isRequired === 'YES' &&
            (filter.applyTo == null || filter.applyTo.includes(type))) ||
          (filter.isRequired === 'NO' &&
            (filter.applyTo == null || filter.applyTo.includes(type)))
        ) {
          if (isArray(filter.value) && filter.type !== 'dateRange') {
            if (filter.value.length > 0) {
              if (typeof filter.value[0] === 'number') {
                filtersToSend[filter.literalId] = filter.value.map((value) =>
                  parseFloat(String(value))
                );
              } else {
                filtersToSend[filter.literalId] = filter.value.map((value) =>
                  String(value)
                );
              }
            }
          } else if (typeof filter.value === 'string') {
            filtersToSend[filter.literalId] = String(filter.value);
          } else if (
            typeof filter.value === 'number' ||
            typeof filter.value === 'boolean'
          ) {
            filtersToSend[filter.literalId] = filter.value;
          } else if (filter.value != null) {
            if (dayFilterLiteralIds.includes(filter.literalId)) {
              filtersToSend.dateStart = (filter.value as string[])[0] as string;
              filtersToSend.dateStop = (filter.value as string[])[1] as string;
            }
            if (
              this.compareService.compare &&
              this.compareService.compareIsAvailable &&
              filter.literalId === 'compare_date' &&
              this.compareService.previousDate
            ) {
              // Validate the dates before formatting using optional chaining
              const prevStartDate = this.compareService.previousDate?.startDate;
              const prevEndDate = this.compareService.previousDate?.endDate;

              if (prevStartDate?.isValid()) {
                filtersToSend.previousDateStart =
                  prevStartDate.format('YYYY-MM-DD');
              }

              if (prevEndDate?.isValid()) {
                filtersToSend.previousDateStop =
                  prevEndDate.format('YYYY-MM-DD');
              }
            }
          }
        }
        if (
          filter.literalId === 'allocationSetting' &&
          typeof filter.value === 'string'
        ) {
          filters.splice(filters.indexOf(filter), 1);
          //Setting it to [] for the initial instance
          filtersToSend.allocationSetting = [];
        }
        if (filter.literalId === 'metrics') {
          filtersToSend.metrics = filter.value as unknown as string;
        }
      });
    filtersToSend.isInitialFilterCall = false;
    return filtersToSend;
  }

  public modifyParams(filters: Filter[]) {
    this.modifyQueryParams(filters);
  }

  private modifyQueryParams(filters: Filter[]): void {
    let queryParams: Params = {};
    this.route.queryParams.pipe(take(1)).subscribe((params: Params) => {
      queryParams = { ...params };
      const gridDimensionsExist = filters.find(
        (el) => el.literalId === gridDimensionsLiteralId
      );
      // if grid dimensions exist skip the logic for creating new query params
      if (gridDimensionsExist) {
        return;
      }
      filters.forEach((fil) => {
        if (this.productId === 'ddi' && fil.literalId === 'charts') {
          unset(queryParams, fil.literalId);
        } else if (
          this.literalId === 'benchmarks-drill-down' &&
          fil.literalId === 'metric_name_status'
        ) {
          const filterValue = fil.value as string[];
          if (isArray(filterValue) && !filterValue.includes('Active')) {
            filterValue.push('Active');
          }
        } else {
          if (
            typeof fil.value === 'object' &&
            fil.value != null &&
            !isArray(fil.value) &&
            fil.value?.startDate != null &&
            fil.value?.endDate != null &&
            dayjs(fil.value.startDate).isValid() &&
            dayjs(fil.value.endDate).isValid() &&
            fil.literalId !== 'compare_date'
          ) {
            const dateParams: Params = {
              startDate: dayjs(fil.value.startDate).format('YYYY-MM-DD'),
              endDate: dayjs(fil.value.endDate).format('YYYY-MM-DD')
            };
            return (queryParams[fil.literalId] = JSON.stringify(dateParams));
          }
          queryParams[fil.literalId] = JSON.stringify(fil.value);
        }
      });
    });
    this.router
      .navigate([], {
        relativeTo: this.route,
        // TODO: Remove queryParams when DP is enabled
        // queryParams,
        queryParamsHandling: 'merge'
      })
      .catch((err) => console.error(err));
  }

  getDefaultViewId(): Observable<DefaultViewResponse> {
    const options = {
      params: {
        clientId: this.clientId,
        brandId: this.brandId,
        productId: this.productId,
        dashboardId: this.literalId
      }
    };
    return this.httpClient.get<DefaultViewResponse>(
      `${this.apiRootV2}/dataviz/config/default-view-id`,
      options
    );
  }

  getViewList(
    viewType: ViewType = ViewType.allPlans,
    productId = this.productId,
    dashboardId = this.literalId
  ): Observable<ViewResponse[]> {
    const params: Params = {
      clientId: this.clientId,
      brandId: this.brandId,
      productId,
      dashboardId,
      viewType
    };
    const options = { params };
    return this.httpClient.get<ViewResponse[]>(
      `${this.apiRootV2}/dataviz/config/view/`,
      options
    );
  }

  getView(
    viewId: number,
    showChild = false,
    productId = this.productId,
    dashboardId = this.literalId
  ): Observable<ViewResponse> {
    const options = {
      params: {
        clientId: this.clientId,
        brandId: this.brandId,
        productId,
        dashboardId,
        viewId,
        showChild
      }
    };
    return this.httpClient.get<ViewResponse>(
      `${this.apiRootV2}/dataviz/config/view/${viewId}`,
      options
    );
  }

  postView(view: ViewResponse, viewId: number): Observable<ViewResponse> {
    const body = {
      clientId: this.clientId,
      brandId: this.brandId,
      productId: this.productId,
      dashboardId: this.literalId,
      viewId,
      view
    };
    return this.httpClient.post<ViewResponse>(
      `${this.apiRootV2}/dataviz/config/view`,
      body
    );
  }

  updateView(view: ViewResponse): Observable<ViewResponse> {
    const body = {
      clientId: this.clientId,
      brandId: this.brandId,
      productId: this.productId,
      dashboardId: this.literalId,
      view
    };
    const { queryParams } = this.route.snapshot;
    if (
      queryParams.showChild &&
      view.filterOverrides.global &&
      view.filterOverrides.global.tactic
    ) {
      view.filterOverrides.global.tactic = JSON.parse(queryParams.tactic);
    }
    return this.httpClient.put<ViewResponse>(
      `${this.apiRootV2}/dataviz/config/view/${view.id}`,
      body
    );
  }

  deleteView(viewId: number): Observable<PatchViewResponse> {
    const options = {
      params: {
        clientId: this.clientId,
        brandId: this.brandId,
        productId: this.productId,
        dashboardId: this.literalId,
        viewId
      }
    };
    return this.httpClient.delete<PatchViewResponse>(
      `${this.apiRootV2}/dataviz/config/view/${viewId}`,
      options
    );
  }

  patchView(view: ViewResponse, operation: ViewOperations) {
    const options = {
      params: {
        clientId: this.clientId,
        brandId: this.brandId,
        productId: this.productId,
        dashboardId: this.literalId
      }
    };
    return this.httpClient.patch<PatchViewResponse>(
      `${this.apiRootV2}/dataviz/config/view/${view.id}/${operation}`,
      null,
      options
    );
  }

  public createDataSetOverrideRow(filterOverride: Record<string, string>) {
    const body = {
      clientId: this.clientId,
      brandId: this.brandId,
      productId: this.productId,
      dashboardId: this.literalId,
      filterOverride
    };
    return this.httpClient.post<ElementGroup[]>(
      `${this.apiRootV2}/dataviz/config/create-or-update-row-override`,
      body
    );
  }

  public overrideUserFilters(
    updatedFilters: Filter[],
    dimensionFormat?: string
  ): void {
    const options = {
      params: {
        clientId: this.clientId,
        brandId: this.brandId,
        productId: this.productId,
        dashboardId: this.literalId
      }
    };
    let customOptions: Params | undefined;
    if (dimensionFormat) {
      customOptions = {
        params: {
          clientId: this.clientId,
          brandId: this.brandId,
          productId: this.productId,
          dashboardId: this.literalId,
          dimensionFormat
        }
      };
    }
    this.callOverrideFilterApi(
      updatedFilters,
      customOptions ? customOptions : options
    );
  }

  public overrideUserLayout(layout: ElementGroup[]): void {
    const options = {
      params: {
        clientId: this.clientId,
        brandId: this.brandId,
        productId: this.productId,
        dashboardId: this.literalId
      }
    };
    this.store.dispatch(DashboardActions.updateLayout({ layout }));
    this.callOverrideApi(layout, options);
  }

  public persistLayout(persistenceRequest: PersistenceRequest): void {
    const options = {
      params: {
        clientId: this.clientId,
        brandId: this.brandId,
        productId: this.productId,
        dashboardId: this.literalId
      }
    };
    this.callPersistenceApi(persistenceRequest, options);
  }

  private overrideLayout(
    layout: ElementGroup[],
    options: object
  ): Promise<ElementGroup[]> {
    return promisify(
      this.httpClient.patch<ElementGroup[]>(
        `${this.apiRootV2}/dataviz/config/layout/override/USER`,
        layout,
        options
      )
    );
  }

  public overrideHomepageLayout(
    layout: ElementGroup[]
  ): Observable<ElementGroup[]> {
    const options = {
      params: {
        clientId: this.clientId,
        brandId: this.brandId,
        productId: this.productId,
        dashboardId: this.literalId
      }
    };

    return this.httpClient.put<ElementGroup[]>(
      `${this.apiRootV2}/dataviz/config/layout/home-page-override/USER`,
      layout,
      options
    );
  }

  private savePersistenceLayout(
    layout: ElementGroup[],
    options: object,
    overrideLevel = OverrideLevel.user
  ): Promise<ElementGroup[]> {
    return promisify(
      this.httpClient.patch<ElementGroup[]>(
        `${this.apiRootV2}/dataviz/config/layout/persist/${overrideLevel}`,
        layout,
        options
      )
    );
  }

  public getDynamicFilters(): Promise<IParentChildFilterMapping> {
    const options = {
      params: {
        clientId: this.clientId,
        brandId: this.brandId,
        productId: this.productId,
        dashboardId: this.literalId
      }
    };
    return promisify(
      this.httpClient.get<IParentChildFilterMapping>(
        `${this.apiRootV2}/dataviz/config/filter-options-mapping`,
        options
      )
    );
  }

  public getDashboardLatestRefreshTime(): Observable<IDashboardRefreshDate> {
    const options = {
      params: {
        clientId: this.clientId,
        brandId: this.brandId
      }
    };
    return this.httpClient.get<IDashboardRefreshDate>(
      `${this.apiRootV2}/last-refresh/dashboard/${this.literalId}`,
      options
    );
  }

  public getDashboardAllDataSourcesLatestRefreshTime(): Observable<
    IDashboardAllDataSourcesRefreshDate[]
  > {
    const options = {
      params: {
        clientId: this.clientId,
        brandId: this.brandId
      }
    };
    return this.httpClient.get<IDashboardAllDataSourcesRefreshDate[]>(
      `${this.apiRootV2}/last-refresh/data-source`,
      options
    );
  }

  private overrideFilters(
    updatedFilters: Filter[],
    options: object
  ): Promise<DataResponse | DataSetResponse[] | DataSetResponse> {
    return promisify(
      this.httpClient.patch<DataResponse | DataSetResponse[] | DataSetResponse>(
        `${this.apiRootV2}/dataviz/config/filter/override/USER`,
        updatedFilters,
        options
      )
    );
  }

  public overrideFiltersUpdated(
    updatedFilters: Filter[],
    dimensionFormat: string
  ): Promise<DataResponse | DataSetResponse[] | DataSetResponse> {
    const params = omitBy(
      {
        clientId: this.clientId,
        brandId: this.brandId,
        productId: this.productId,
        dashboardId: this.literalId,
        dimensionFormat
      },
      isNil
    );
    const options = {
      params
    };
    return promisify(
      this.httpClient.patch<DataResponse | DataSetResponse[] | DataSetResponse>(
        `${this.apiRootV2}/dataviz/config/filter/override/USER`,
        updatedFilters,
        options
      )
    );
  }

  public getExportDataConfig(
    clientId: number,
    brandId: number
  ): Observable<IExportConfig[]> {
    return this.httpClient.get<IExportConfig[]>(
      `${this.apiRootV1}/export-data/export-report-configs`,
      {
        params: { clientId, brandId }
      }
    );
  }

  public saveExportDataConfig(
    exportDataRequestParams: IExportDataReqParams,
    clientId: number,
    brandId: number
  ): Observable<{ success: boolean; message: string }> {
    return this.httpClient.post<{ success: boolean; message: string }>(
      `${this.apiRootV1}/export-data`,
      exportDataRequestParams,
      { params: { clientId, brandId } }
    );
  }

  private getBackendSupportedEndpointType(type: DataEndpoint): EndpointType {
    let endPointType: EndpointType;
    switch (type) {
      case 'data-sets':
        endPointType = 'DATA_SET';
        break;
      case 'charts':
        endPointType = 'DATA_CHART';
        break;
      case 'export-data-set':
        endPointType = 'DATA_SET';
        break;
      case 'export-chart':
        endPointType = 'DATA_CHART';
        break;
      default:
        endPointType = 'DATA_POINT';
    }
    return endPointType;
  }

  public getReportingRange(): Observable<ReportingRangeConfigResponse> {
    const params: Params = {
      clientId: this.clientId,
      productId: this.productId,
      dashboardId: this.literalId
    };
    const options = { params };
    return this.httpClient.get<ReportingRangeConfigResponse>(
      `${this.apiRootV2}/dataviz/config/reporting-range`,
      options
    );
  }

  private setReportingRangeInMonths(
    response: ReportingRangeConfigResponse
  ): number | undefined {
    if (
      response &&
      response.timeInPastMonths &&
      response.timeInPastMonths !== -1
    ) {
      return response.timeInPastMonths;
    } else {
      return undefined;
    }
  }
}

const getDisplayName = (
  dashboardInstance: ProductDashboardInstance | undefined
) => {
  if (!dashboardInstance) {
    return '';
  }

  const isProductDDI =
    dashboardInstance?.dashboard?.productId === ProductLiteralIds.ddi;
  const productName = dashboardInstance?.dashboard?.product?.name;

  if (isProductDDI && productName) {
    return productName;
  }

  return (
    dashboardInstance.displayName ||
    dashboardInstance.dashboard.displayName ||
    ''
  );
};
