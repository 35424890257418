import { Component, Input, OnInit } from '@angular/core';
import {
  DataGridInfoWithIcon,
  DataResponse,
  FieldDefinitions
} from '@portal/app/shared/types';
import { Required } from '@portal/app/shared/decorators/required.decorator';

interface IconCondition {
  compare: 'EQUALS';
  value: string;
  iconName: string;
}

@Component({
  selector: 'portal-icon-data-cell',
  templateUrl: './icon-data-cell.component.html'
})
export class IconDataCellComponent implements OnInit {
  @Input() @Required col!: DataGridInfoWithIcon;
  @Input() @Required fieldDefinitions!: FieldDefinitions;
  @Input() @Required rowData!: DataResponse;
  @Input() key = '';
  iconPath = '';

  ngOnInit(): void {
    this.getConditionalIconPath();
  }

  getConditionalIconPath(): void {
    this.col.additionalAttributes?.conditions.forEach(
      (condition: IconCondition) => {
        switch (condition.compare) {
          case 'EQUALS':
            if (this.rowData[this.col.dashboardFieldId] === condition.value) {
              this.iconPath = `assets/icons/${condition.iconName}`;
            }
            break;
          default:
            break;
        }
      }
    );
    if (!this.iconPath) {
      this.iconPath = `assets/icons/${this.col.additionalAttributes?.defaultIcon}`;
    }
  }
}
