import { BrowserModule, Title } from '@angular/platform-browser';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  NgModule
} from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { NgxPendoModule } from 'ngx-pendo';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import { AppComponent } from '@portal/app/app.component';
import { AppRoutingModule } from '@portal/app/app-routing.module';
import { SmartRedirectComponent } from './smart-redirect/smart-redirect.component';
import { AuthModule } from '@portal/app/auth/auth.module';
import { ChartsModule } from '@portal/app/charts/charts.module';
import { DataGridModule } from '@portal/app/datagrid/data-grid.module';
import { SharedModule } from '@portal/app/shared/shared.module';
import { DashboardModule } from '@portal/app/dashboard/dashboard.module';
import { AuthService } from '@portal/app/shared/services/auth.service';
import { DateTimeService } from '@portal/app/shared/services/date-time.service';
import { DateRangeUtilityService } from '@portal/app/shared/services/date-range-utility.service';
import { AppErrorHandler } from '@portal/app/shared/helpers/app-error-handler';
import { AuthenticationInterceptor } from '@portal/app/shared/helpers/authentication-interceptor';
import { FiltersService } from '@portal/app/shared/services/filters.service';
import { FeatureConfigService } from '@portal/app/shared/services/feature-config.service';
import { DatasetAvailabilityService } from '@portal/app/shared/services/dataset-availability.service';
import { CustomCurrencyPipe } from '@portal/app/shared/pipes/custom-currency.pipe';
import { CurrencyPipe } from '@angular/common';
import { DialogService } from 'primeng/dynamicdialog';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { DashboardEffects } from '@portal/app/store/dashboard/effects';
import { MultiViewEffects } from '@portal/app/store/multi-view/effects';
import { reducers } from '@portal/app/store/reducers';
import { environment } from '@portal/environments/environment';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { AppConfigService } from '@portal/app/app-config.service';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { ApiModule, BASE_PATH } from '@libs/api';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { CsvService } from '@libs/common-services';
import {
  provideTanStackQuery,
  QueryClient,
  withDevtools
} from '@tanstack/angular-query-experimental';

const oktaAuth = new OktaAuth(environment.oktaConfig);

const initializeApp = () => {
  AppConfigService.initialize();
};

@NgModule({
  declarations: [AppComponent, SmartRedirectComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    DashboardModule,
    AuthModule,
    ChartModule,
    ChartsModule,
    NgbModule,
    ConfirmPopupModule,
    ToastModule,
    StoreDevtoolsModule.instrument({
      maxAge: 50,
      logOnly: environment.production,
      autoPause: true
    }),
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false
      }
    }),
    EffectsModule.forRoot([DashboardEffects, MultiViewEffects]),
    OktaAuthModule,
    NgxPendoModule.forRoot({
      pendoApiKey: 'ac0c5723-af67-4ea1-540c-a3985522ead2',
      pendoIdFormatter: (value: string) => value.toString().toLowerCase()
    }),
    DataGridModule,
    // AppRoutingModule always comes after all others
    AppRoutingModule,
    GoogleTagManagerModule.forRoot({
      id: environment.gtmId
    }),
    ApiModule
  ],
  providers: [
    ConfirmationService,
    DateTimeService,
    DateRangeUtilityService,
    CustomCurrencyPipe,
    CurrencyPipe,
    MessageService,
    CsvService,
    DialogService,
    AuthService,
    FiltersService,
    FeatureConfigService,
    DatasetAvailabilityService,
    Title,
    {
      provide: APP_INITIALIZER,
      useFactory: () => initializeApp,
      deps: [Sentry.TraceService],
      multi: true
    },
    HttpClient,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    },
    {
      provide: OKTA_CONFIG,
      useValue: { oktaAuth }
    },
    { provide: HIGHCHARTS_MODULES, useFactory: () => [more, exporting] },
    { provide: BASE_PATH, useValue: environment.apiDomain },
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false // This option disables the error dialog that would typically be shown to users when an error occurs. Instead of showing a dialog, errors will be captured and sent to Sentry silently.
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    provideTanStackQuery(
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            staleTime: 86400000, // T-(1) SLA logic.
            retry: 0, // TODO: set Retry 3 times
            retryDelay: 1000 // 1 second delay between retries
          }
        }
      }),
      withDevtools()
    )
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {}
