@if (isChannelModalVisible()) {
  <portal-context-modal-content
    [title]="title()"
    [pill]="pill()"
    [autoCompleteOptions]="channelsOptions()"
    autoCompleteOptionLabel="label"
    (closeModal)="onCloseModal()"
    (selectAction)="selectAction($event)"
    (nextAction)="nextAction()"
    (previousAction)="previousAction()">
    <m-tab-view [tabPanelHeaders]="tabPanelHeaders">
      <ng-template [mTabIndex]="1" #tabContent>
        <div class="pt-2">
          <portal-context-summary
            [comparisonPeriod]="
              compareCurrentPeriod()
            "></portal-context-summary>
          <portal-context-channel-insights
            [viewBy]="viewByData().viewBy"></portal-context-channel-insights>
        </div>
      </ng-template>
      <ng-template [mTabIndex]="2" #tabContent>
        <div class="pt-2">
          <portal-context-metrics></portal-context-metrics>
        </div>
      </ng-template>
      <ng-template [mTabIndex]="3" #tabContent>
        <div class="pt-2">
          <portal-context-media
            [isLoading]="isMediaLoading()"
            [table]="mediaTable()"
            [isDataAvailable]="isMediaDataAvailable()"></portal-context-media>
        </div>
      </ng-template>
    </m-tab-view>
  </portal-context-modal-content>
}
