import { Injectable } from '@angular/core';
import { Brand } from '@portal/app/shared/types';
import { SelectionService } from '@portal/app/shared/services/selection.service';

@Injectable({
  providedIn: 'root'
})
export class BrandStoreService {
  constructor(private readonly selection: SelectionService) {}

  get brand(): Brand {
    return this.selection.getSelection().brand as Brand;
  }
}
