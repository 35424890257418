import { Injectable, signal } from '@angular/core';
import { ICPAdTestConfig } from '@portal/app/geo-designer/models/ICPAdTestConfig';
import {
  ApiService,
  CpCellType,
  IConversionType,
  IDSConversionTypeOutput,
  IGeoTest,
  Status
} from '@libs/apis';
import { BrandStoreService } from '@portal/app/dashboard/geo-doe-config/data-stores/brand-store.service';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { setSavedTestDatesWithoutTimezone } from '@portal/app/dashboard/geo-doe-config/shared/utils';
import { SelectionService } from '@portal/app/shared/services/selection.service';
import { GeoTestPersistenceStatus } from '@portal/app/geo-designer/models/GeoTestPersistenceStatus';
import dayjs from 'dayjs';
import { IPlatform } from '@portal/app/dashboard/geo-doe-config/models';
import { TestStatusHelper } from '@portal/app/geo-designer/common/test-status.helper';

@Injectable({
  providedIn: 'root'
})
export class GeoTestsService {
  #geoTests = signal<IGeoTest[]>([]);
  #testsLoading = signal(true);
  #marketReferenceData = signal<IDSConversionTypeOutput[]>([]);
  private conversionTypes$: BehaviorSubject<IConversionType[]> =
    new BehaviorSubject<IConversionType[]>([]);

  public geoTests = this.#geoTests.asReadonly();
  public testsLoading = this.#testsLoading.asReadonly();
  public marketReferenceData = this.#marketReferenceData.asReadonly();

  private geoTestCreatedOrUpdated$: BehaviorSubject<{
    studyId: number;
    status: GeoTestPersistenceStatus;
  }> = new BehaviorSubject<{
    studyId: number;
    status: GeoTestPersistenceStatus;
  }>({
    studyId: 0,
    status: GeoTestPersistenceStatus.noOp
  });

  geoTestCreatedOrUpdated = this.geoTestCreatedOrUpdated$.asObservable();
  platformInfo: IPlatform[] = [];

  constructor(
    private readonly apiService: ApiService,
    private readonly brandStoreService: BrandStoreService,
    private readonly selectionService: SelectionService
  ) {
    this.selectionService.selectionChanged.subscribe(() => {
      this.initializeService();
    });
  }

  initializeService(): void {
    this.#geoTests.set([]);

    this.conversionTypes$ = new BehaviorSubject<IConversionType[]>([]);
  }

  getDsReferenceDataForConversionType(conversionTypeId: number) {
    return this.#marketReferenceData().find(
      this.dsMdlReferenceDataFilterPredicate(conversionTypeId)
    );
  }

  getAllGeoTests(): void {
    this.getGeoTests$.subscribe((tests) => {
      this.#geoTests.set(tests);
      this.#testsLoading.set(false);
    });
  }

  get getGeoTests$(): Observable<IGeoTest[]> {
    return fromPromise(
      this.apiService.geoDoeApisOperations.getAllGeoTests(
        this.brandStoreService.brand.clientId,
        this.brandStoreService.brand.brandId,
        [Status.ACTIVE, Status.DRAFT]
      )
    ).pipe(
      map((tests) => {
        return tests.map((geoTest: IGeoTest) => {
          this.mapStatus(geoTest.geoStudy as ICPAdTestConfig);
          (geoTest.geoStudy as ICPAdTestConfig).bauCell =
            geoTest.geoStudy.cellConfigs.find(
              (cellConfig) => cellConfig.cellType === CpCellType.BAU
            ) || null;
          setSavedTestDatesWithoutTimezone(geoTest.geoStudy);
          return geoTest;
        });
      })
    );
  }

  mapStatus(geoTest: ICPAdTestConfig): void {
    const { actualStatus, displayStatus } =
      TestStatusHelper.getDisplayStatus(geoTest);

    geoTest.actualStatus = actualStatus;
    geoTest.displayStatus = displayStatus;
  }

  getAllConversionTypes(): Observable<IConversionType[]> {
    if (!this.conversionTypes$.value.length) {
      return fromPromise(
        this.apiService.manageFactDataApisOperations
          .getAllConversionTypes(
            this.brandStoreService.brand.clientId,
            Status.ACTIVE,
            this.brandStoreService.brand.brandId
          )
          .then((conversionTypes) => {
            this.conversionTypes$.next(conversionTypes);
            return conversionTypes;
          })
      ).pipe(take(1));
    }

    return this.conversionTypes$.asObservable().pipe(take(1));
  }

  fetchDsReferenceData(): void {
    fromPromise(
      this.apiService.geoDoeApisOperations
        .getDSMdlSimulationReferenceData(
          this.brandStoreService.brand.clientId,
          this.brandStoreService.brand.brandId
        )
        .then((referenceDSOutput) => {
          const enrichedReferenceData = referenceDSOutput.map(
            (dsReferenceData) => {
              return {
                ...dsReferenceData,
                lastUpdated: dayjs(dsReferenceData?.executionId).toDate()
              };
            }
          );
          this.#marketReferenceData.set(enrichedReferenceData);
          return enrichedReferenceData;
        })
    );
  }

  private dsMdlReferenceDataFilterPredicate(conversionTypeId: number) {
    return (referenceDsOutput: IDSConversionTypeOutput) =>
      referenceDsOutput.conversionTypeId === conversionTypeId;
  }

  setGeoTestCreatedOrUpdated(studyId?: number) {
    this.geoTestCreatedOrUpdated$.next({
      studyId: studyId || 0,
      status: studyId
        ? GeoTestPersistenceStatus.studyUpdated
        : GeoTestPersistenceStatus.studyCreated
    });
  }
}
