<div
  [ngClass]="{
    hidden: !showUnpublishedCampaigns,
    'top-[100vh]': !showUnpublishedCampaigns,
    'top-0 z-[10000]': showUnpublishedCampaigns,
    flex: showUnpublishedCampaigns && (loading | async) === false,
  }"
  class="review-campaigns-wrapper">
  <div class="flex items-start justify-between w-full">
    <lib-ui-title
      [titleConfigs]="unpublishedCampaignTitleConfigs"></lib-ui-title>
    <span
      class="flex material-symbols-outlined cursor-pointer icon-regular text-blue-500"
      (click)="closeInReviewCampaigns()"
      >close</span
    >
  </div>
  <div class="flex flex-col w-full overflow-auto gap-4">
    <div class="review-campaigns-accordion-wrapper">
      <div class="flex w-full items-center justify-start gap-2">
        <span class="b1 text-gray-800">Group by</span>
        <m-dropdown
          [style]="{ width: '10rem' }"
          [options]="taxonomyDimensions"
          placeholder="Group by"
          [(ngModel)]="activeDimension"
          (formControlChange)="prepareCampaignData($event)"></m-dropdown>
      </div>
      <div class="saas-ui saas-ui-size-default w-full">
        <p-accordion
          #accordion
          [multiple]="false"
          [(activeIndex)]="activeIndex"
          class="saas-ui saas-ui-size-default w-full overflow-auto"
          (onOpen)="logOpenAccordion($event)"
          (activeIndexChange)="
            prepareCampaignTableColumnsByDimension(
              $any($event),
              accordion.tabs[$any($event)]
            )
          ">
          <p-accordionTab
            *ngFor="
              let campaignDimensionInfo of campaignCountByDimension[
                activeDimension
              ] | keyvalue;
              index as i
            ">
            <ng-template pTemplate="header">
              <div class="flex items-center justify-between w-full">
                <div class="flex items-center gap-2">
                  <img
                    *ngIf="activeDimension === 'compositeDataSourceName'"
                    class="data-source-icon"
                    src="/assets/images/integrations/{{
                      campaignDimensionInfo.key.replace(' ', '-') | lowercase
                    }}-logo.svg"
                    (error)="
                      handleImageError($event, campaignDimensionInfo.key)
                    "
                    alt="I" />
                  <span class="sh2">{{ campaignDimensionInfo.key }}</span>
                  <p
                    class="bg-gray-200 text-gray-800 b2 rounded-full px-2 mb-0">
                    {{ campaignDimensionInfo.value }}
                  </p>
                  <div
                    *ngIf="geoTestStatusByDimension[campaignDimensionInfo.key]"
                    class="flex items-center gap-1">
                    <span
                      class="flex material-symbols-outlined text-blue-600 text-base font-medium lh-1"
                      >info</span
                    >
                    <p class="text-gray-1000 b1 mb-0">Geo Test in progress</p>
                  </div>
                </div>
                <span
                  class="flex material-symbols-outlined link text-blue-600 icon-small cursor-pointer"
                  [escape]="false"
                  pTooltip="Remove all from<br />{{
                    campaignDimensionInfo.key
                  }}"
                  tooltipPosition="top"
                  tooltipStyleClass="saas-tooltip"
                  (click)="
                    removeAllFromGroup(campaignDimensionInfo.key);
                    $event.stopPropagation()
                  "
                  >close</span
                >
              </div>
            </ng-template>
            <ng-template pTemplate="content">
              <p-table
                [columns]="campaignViewColumns"
                [scrollDirection]="'vertical'"
                [scrollHeight]="'25rem'"
                [value]="
                  campaignsByDimension[activeDimension][
                    campaignDimensionInfo.key
                  ]
                "
                [tableStyle]="{ width: '100%' }"
                tableStyleClass="p-datatable-table-lg saas-ui saas-ui-size-default taxonomy-table table-fixed">
                <ng-template pTemplate="header">
                  <tr>
                    <th
                      scope="col"
                      *ngFor="let header of campaignViewColumns"
                      [style.width]="header.width"
                      class="{{ header.styleClass }}">
                      {{ header.header }}
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-campaign>
                  <tr *ngIf="(loading | async) === true">
                    <td
                      *ngFor="let header of campaignViewColumns"
                      class="p-table-td-lg {{ header.styleClass }}"
                      [style.width]="header.width"
                      [style.height]="'4rem !important'"
                      [ngSwitch]="header.field">
                      <m-skeleton height="2rem" width="100%"></m-skeleton>
                    </td>
                  </tr>
                  <tr *ngIf="(loading | async) === false">
                    <td
                      *ngFor="let header of campaignViewColumns"
                      class="p-table-td-lg {{ header.styleClass }}"
                      [style.width]="header.width"
                      [style.height]="'4rem !important'"
                      [ngSwitch]="header.field">
                      <ng-container *ngSwitchCase="'modifiedBy'">
                        <div
                          [pTooltip]="campaign.modifiedByTooltip"
                          [escape]="false"
                          tooltipPosition="top"
                          tooltipStyleClass="saas-tooltip">
                          <m-avatar
                            [avatars]="[campaign.avatarConfig]"></m-avatar>
                        </div>
                      </ng-container>
                      <ng-container *ngSwitchCase="'undo'">
                        <span
                          class="flex material-symbols-outlined link text-blue-600 icon-small cursor-pointer"
                          pTooltip="Remove from list"
                          tooltipPosition="top"
                          tooltipStyleClass="saas-tooltip"
                          (click)="removeUnpublishedRecord(campaign)"
                          >close</span
                        >
                      </ng-container>
                      <ng-container *ngSwitchDefault>
                        <span
                          [pTooltip]="
                            (campaign[header.field] + '').length > 30
                              ? campaign[header.field]
                              : ''
                          "
                          tooltipStyleClass="saas-tooltip"
                          tooltipPosition="top"
                          class="block max-h-full overflow-hidden overflow-ellipsis text-nowrap">
                          {{ campaign[header.field] || "-" }}
                        </span>
                      </ng-container>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <ng-container *ngIf="loading | async">
                    <tr *ngFor="let i of [1, 2, 3, 4, 5]">
                      <td
                        *ngFor="let header of campaignViewColumns"
                        class="p-table-td-lg {{ header.styleClass }}"
                        [style.width]="header.width"
                        [style.height]="'4rem !important'"
                        [ngSwitch]="header.type">
                        <m-skeleton height="2rem" width="100%"></m-skeleton>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-container
                    *ngIf="
                      (loading | async) === false &&
                      !campaignsByDimension[activeDimension][
                        campaignDimensionInfo.key
                      ]?.length
                    ">
                    <tr>
                      <td colspan="100%">
                        <m-not-available></m-not-available>
                      </td>
                    </tr>
                  </ng-container>
                </ng-template>
              </p-table>
            </ng-template>
          </p-accordionTab>
        </p-accordion>
      </div>
    </div>
  </div>
</div>
