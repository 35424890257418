export const FILTER_LITERAL_IDS: Record<string, string> = {
  relativeDayLiteralId: 'relative_day',
  compareDateLiteralId: 'compare_date',
  dateStartLiteralId: 'dateStart',
  dateStopLiteralId: 'dateStop',
  conversionTypeLiteralId: 'conversion_type',
  previousDateStartLiteralId: 'previousDateStart',
  previousDateStopLiteralId: 'previousDateStop'
};

export const RELATIVE_DAY_LITERAL_ID = 'relative_day';

export const HOMEPAGE_DASHBOARD_ID = 16;
