<div class="flex flex-col w-full column-header">
  <div class="flex items-center gap-2">
    <span class="{{ headerStyleClass }}">{{ header }}</span>
    <span
      class="material-symbols-outlined cursor-pointer text-[20px] saas-icon sort-icon"
      [ngClass]="{
        visible: header === sort?.sortBy,
        reversed: sort?.sortOrder === 'asc',
      }"
      (click)="setSort(header)"
      >sort</span
    >
    <span
      class="material-symbols-outlined cursor-pointer text-[20px] search-icon"
      (click)="op.toggle($event); toggleSearchBox()"
      [ngClass]="{
        visible:
          showSearchBox ||
          !!filterData?.contain?.length ||
          !!filterData?.exclude?.length,
        hidden:
          !showSearchBox &&
          !(filterData?.contain?.length && filterData?.exclude?.length),
      }"
      >search</span
    >
  </div>
  <div class="flex w-full items-center relative">
    <p-overlayPanel
      styleClass="saas-ui saas-ui-size-default column-header column-filter-panel"
      #op
      (onHide)="toggleSearchBox()">
      <div class="w-[16rem]">
        <m-text-field
          [filterData]="filterData"
          (search)="publishSearchValue($event)"></m-text-field>
      </div>
    </p-overlayPanel>
  </div>
</div>
