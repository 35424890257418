import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  Renderer2,
  forwardRef,
  Inject
} from '@angular/core';
import { Menu } from 'primeng/menu';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MenuItem, OverlayService, PrimeNGConfig } from 'primeng/api';
import { PLATFORM_ID } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'm-menu',
  templateUrl: './m-menu.component.html',
  styleUrls: ['./m-menu.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MMenuComponent),
      multi: true
    }
  ]
})
export class MMenuComponent extends Menu {
  @Input() menuItems: MenuItem[] = [];
  @Input() override disabled: any = false;
  @Input() buttonBadge = '';
  @Input() override ariaLabel = '';
  @Input() showArrow = true;
  @Input() override id = '';

  public isMenuOpen = false;

  constructor(
    @Inject(DOCUMENT) document: Document,
    @Inject(PLATFORM_ID) platformId: any,
    el: ElementRef,
    renderer: Renderer2,
    cd: ChangeDetectorRef,
    config: PrimeNGConfig,
    overlayService: OverlayService
  ) {
    super(document, platformId, el, renderer, cd, config, overlayService);
  }

  onMenuOpen(): void {
    this.isMenuOpen = true;
  }

  onMenuClose(): void {
    this.isMenuOpen = false;
  }
}
