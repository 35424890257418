import { DOCUMENT } from '@angular/common';
import {
  Component,
  forwardRef,
  AfterViewInit,
  OnDestroy,
  Input,
  ElementRef,
  Renderer2,
  ChangeDetectorRef,
  NgZone,
  Inject
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { OverlayService, PrimeNGConfig } from 'primeng/api';
import { Calendar } from 'primeng/calendar';

@Component({
  selector: 'm-calendar',
  templateUrl: './m-calendar.component.html',
  styleUrls: ['./m-calendar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MCalendarComponent),
      multi: true
    }
  ]
})
export class MCalendarComponent
  extends Calendar
  implements ControlValueAccessor, AfterViewInit, OnDestroy
{
  @Input() resolution: string | null = null;

  private hoverUnlistener: (() => void) | null = null;
  private selectedStart: HTMLElement | null = null;
  private selectedEnd: HTMLElement | null = null;

  override ngAfterViewInit() {
    this.attachHoverListeners();
  }

  override ngOnDestroy() {
    this.detachHoverListeners();
  }

  constructor(
    @Inject(DOCUMENT) document: Document,
    el: ElementRef,
    renderer: Renderer2,
    cd: ChangeDetectorRef,
    zone: NgZone,
    config: PrimeNGConfig,
    overlayService: OverlayService
  ) {
    super(document, el, renderer, cd, zone, config, overlayService);
  }

  attachHoverListeners(): void {
    this.hoverUnlistener = this.renderer.listen(
      this.el.nativeElement,
      'mouseover',
      this.onHoverDate.bind(this)
    );
  }

  detachHoverListeners(): void {
    if (this.hoverUnlistener) {
      this.hoverUnlistener();
      this.hoverUnlistener = null;
    }
  }

  onHoverDate(event: MouseEvent): void {
    if (this.selectionMode === 'range' && this.resolution === 'week') {
      this.clearPreviousPreviewHighlight();
      const target = (event.target as HTMLElement).closest('tr');
      if (target) {
        this.renderer.addClass(target, 'preview-range');
      }
    }
  }

  clearPreviousPreviewHighlight(): void {
    const highlightedElements =
      this.el.nativeElement.querySelectorAll('.preview-range');
    highlightedElements.forEach((el: HTMLElement) => {
      this.renderer.removeClass(el, 'preview-range');
    });
  }

  onDateClick(event: MouseEvent): void {
    const target = (event.target as HTMLElement).closest('td');
    if (
      target &&
      this.selectionMode === 'range' &&
      this.resolution !== 'week'
    ) {
      this.handleDateSelection(target);
    }
  }

  handleDateSelection(tdElement: HTMLElement): void {
    // Clear any previous selections by searching for selected-start, selected-end, and in-between-selected classes
    if (!!this.selectedStart && !!this.selectedEnd) {
      this.clearSelections();
    }

    if (!this.selectedStart) {
      // First date selected
      this.selectedStart = tdElement;
      this.renderer.addClass(tdElement, 'selected-start');
    } else if (!this.selectedEnd) {
      // Second date selected
      this.selectedEnd = tdElement;
      this.renderer.addClass(tdElement, 'selected-end');

      // Highlight all dates in between
      this.highlightDatesInBetween();
    } else {
      // Reset if a new selection starts
      this.selectedStart = tdElement;
      this.renderer.addClass(tdElement, 'selected-start');
    }
  }

  highlightDatesInBetween(): void {
    if (this.selectedStart && this.selectedEnd) {
      const allTdElements = Array.from(
        this.el.nativeElement.querySelectorAll('td')
      );
      const startIndex = allTdElements.indexOf(this.selectedStart);
      const endIndex = allTdElements.indexOf(this.selectedEnd);

      if (startIndex !== -1 && endIndex !== -1) {
        const minIndex = Math.min(startIndex, endIndex);
        const maxIndex = Math.max(startIndex, endIndex);

        for (let i = minIndex + 1; i < maxIndex; i++) {
          this.renderer.addClass(allTdElements[i], 'in-between-selected');
        }
      }
    }
  }

  restoreSelections(): void {
    // Find all spans with the class 'p-datepicker-current-day'
    const selectedDays = this.el.nativeElement.querySelectorAll(
      'span.p-datepicker-current-day'
    );
    const selectedTds: HTMLElement[] = [];

    selectedDays.forEach((span: any) => {
      const tdElement = span.closest('td');
      if (tdElement) {
        selectedTds.push(tdElement);
      }
    });

    // Ensure we have a start and end to work with
    if (selectedTds.length >= 2) {
      const [startTd, endTd] = [
        selectedTds[0],
        selectedTds[selectedTds.length - 1]
      ];

      // Clear previous selections if necessary
      this.clearSelections();

      // Set local variables
      if (startTd) {
        this.selectedStart = startTd;
      }
      if (endTd) {
        this.selectedEnd = endTd;
      }

      // Add 'selected-start' and 'selected-end' classes
      this.renderer.addClass(startTd, 'selected-start');
      this.renderer.addClass(endTd, 'selected-end');

      // Add 'in-between-selected' for all elements between start and end
      const allTdElements = Array.from(
        this.el.nativeElement.querySelectorAll('td')
      );
      const startIndex = allTdElements.indexOf(startTd);
      const endIndex = allTdElements.indexOf(endTd);

      if (startIndex !== -1 && endIndex !== -1) {
        const minIndex = Math.min(startIndex, endIndex);
        const maxIndex = Math.max(startIndex, endIndex);

        for (let i = minIndex + 1; i < maxIndex; i++) {
          this.renderer.addClass(allTdElements[i], 'in-between-selected');
        }
      }
    }
  }

  clearSelections(): void {
    // Clear 'selected-start' and 'selected-end' classes
    const startElement = this.el.nativeElement.querySelector('.selected-start');
    const endElement = this.el.nativeElement.querySelector('.selected-end');

    if (startElement) {
      this.renderer.removeClass(startElement, 'selected-start');
    }
    if (endElement) {
      this.renderer.removeClass(endElement, 'selected-end');
    }

    // Clear 'in-between-selected' classes from all in-between elements
    const inBetweenElements = this.el.nativeElement.querySelectorAll(
      '.in-between-selected'
    );
    inBetweenElements.forEach((el: HTMLElement) => {
      this.renderer.removeClass(el, 'in-between-selected');
    });

    // Reset stored selections
    this.selectedStart = null;
    this.selectedEnd = null;
  }

  handleShow(event: any) {
    if (this.selectionMode === 'range' && this.resolution !== 'week') {
      this.restoreSelections();
    }
    this.onShow.emit(event);
  }

  handleMonthChange(event: any) {
    if (this.selectionMode === 'range' && this.resolution !== 'week') {
      setTimeout(() => {
        this.restoreSelections();
      }, 100);
    }
    this.onMonthChange.emit(event);
  }
}
