import { isArray, isNil } from 'lodash-es';
import { IClient } from '../models/IClient';
import { IFlattenedBrand } from '../models/IFlattenedBrand';
import { IRequest } from '../models/RestRequest';

export class Utility {
  private constructor() {
    // to avoid instantiation
  }

  public static buildURL(request: IRequest): string {
    let url = request.url;

    if (request.pathVariables) {
      Object.entries(request.pathVariables).forEach(
        ([key, value]) => (url = url.replace(`:${key}`, value))
      );
    }

    const preparedUrl = new URL(url);

    if (request.queryParameters) {
      Object.entries(request.queryParameters).forEach(([key, value]) => {
        if (!isNil(key) && !isNil(value)) {
          if (isArray(value)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (value as any[]).forEach((valueElement) =>
              preparedUrl.searchParams.append(key, valueElement)
            );
          } else {
            preparedUrl.searchParams.append(key, value);
          }
        }
      });
    }

    return decodeURIComponent(preparedUrl.toString());
  }

  public static flattenBrands(clients: IClient[]): IFlattenedBrand[] {
    const flattenedBrands: IFlattenedBrand[] = [];
    for (const client of clients) {
      for (const brand of client.brands) {
        flattenedBrands.push(
          Object.assign({}, brand, {
            clientName: client.clientName,
            clientType: client.clientType,
            referenceId: client.referenceId,
            referenceIdLiteral: client.referenceIdLiteral,
            clientStatus: client.status,
            rCreId: client.rCreId,
            rCreTime: client.rCreTime,
            rModId: client.rModId,
            rModTime: client.rModTime,
            industry: client.industry,
            brandRCreId: brand.rCreId,
            brandRCreTime: brand.rCreTime,
            brandRModId: brand.rModId,
            brandRModTime: brand.rModTime,
            geography: client.geography
          }) as IFlattenedBrand
        );
      }
    }
    return flattenedBrands;
  }
}
