import {
  Component,
  computed,
  effect,
  EventEmitter,
  inject,
  Input,
  OnInit,
  signal
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ITabPanelHeader } from '@design-system/components/m-tab-view';
import { ModalBaseComponent } from '@portal/app/dashboard/context-modal/components/base-modal.component';
import { MediaService } from '@portal/app/dashboard/context-modal/services/media.service';
import { ModalService } from '@portal/app/dashboard/context-modal/services/modal.service';
import { ContextModalParams } from '@portal/app/dashboard/context-modal/context-model.types';
import { DimensionOption } from '@portal/app/dashboard/context-modal/services/link.service';
import { DataResponse } from '@portal/app/shared/types';
import { selectStore } from '@portal/app/store/app.selectors';
import { AppState } from '@portal/app/store/app.state';
import { Store } from '@ngrx/store';
import { LinkService } from '@portal/app/dashboard/context-modal/services/link.service';
import { MIMQueriesService } from '@portal/app/shared/services/queries/mim-queries.service';
import { injectQuery } from '@tanstack/angular-query-experimental';

@Component({
  selector: 'portal-context-tactic-modal',
  templateUrl: './tactic-modal.component.html',
  providers: [ModalService, MediaService]
})
export class TacticModalComponent extends ModalBaseComponent implements OnInit {
  modalService = inject(ModalService);
  mediaService = inject(MediaService);
  private mimQueries = inject(MIMQueriesService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private store = inject(Store<AppState>);
  linkService = inject(LinkService);

  mimVersionQuery = injectQuery(() => {
    return this.mimQueries.mimVersion();
  });

  // Signal controlling visibility (for deep linking)
  isTacticModalVisible = signal(false);
  // Signal holding modal parameters
  rowSignal = signal<ContextModalParams>({} as ContextModalParams);

  mimVersion = computed(() => this.mimVersionQuery.data()?.mimVersion || 1);

  rollUpConversionTypes: string[] = [];
  isRollUpConversionType = false;

  @Input()
  set row(value: DataResponse | ContextModalParams) {
    this.rowSignal.set(value as ContextModalParams);
  }

  previous = new EventEmitter<ContextModalParams>();
  next = new EventEmitter<ContextModalParams>();

  hasBackButton = false;

  currentRow = this.modalService.currentRow;
  compareCurrentPeriod = this.modalService.compareCurrentPeriod;

  isMediaLoading = this.mediaService.isLoading;
  mediaTable = this.mediaService.table;
  isMediaDataAvailable = this.mediaService.isDataAvailable;

  // Reintroduce tactics and tacticsOptions from linkService
  tactics = this.linkService.tactics;
  tacticsOptions = this.linkService.tacticsOptions;

  public tabPanelHeaders: ITabPanelHeader[] = [
    { header: 'Summary', disabled: false, showCount: false, count: 0 },
    { header: 'Metrics', disabled: false, showCount: false, count: 0 },
    { header: 'Campaigns', disabled: false, showCount: false, count: 0 }
  ];

  title = computed(() => this.modalService.modalParams().tactic || '');
  pill = computed(() => this.modalService.modalParams().channel);

  // Implement the abstract getter from the base component.
  protected get isVisible(): boolean {
    return this.isTacticModalVisible();
  }

  constructor() {
    super();
    this.store.select(selectStore).subscribe((state: AppState) => {
      this.rollUpConversionTypes = state.dashboard.rollupConversionTypes;
      this.isRollUpConversionType = this.rollUpConversionTypes.includes(
        this.modalService.conversionType()
      );
    });
    effect(() => {
      const count = this.mediaService.response().data?.length;
      const tab = this.tabPanelHeaders[2];
      if (tab) {
        this.setCount(count, tab);
      }
    });
  }

  setCount(count: number, tab: ITabPanelHeader): void {
    if (count > 0) {
      tab.showCount = true;
      tab.count = count;
    } else {
      tab.showCount = false;
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      // Update conversion type from URL params.
      this.modalService.updateConversionTypeFromParams(params);
      const modalParam = params.modal ? decodeURIComponent(params.modal) : null;
      if (modalParam === 'tactic' && params.channel && params.tactic) {
        const channel = decodeURIComponent(params.channel);
        const tactic = decodeURIComponent(params.tactic);
        // Use the (now updated) conversionType from modalService.conversionType
        const conversionType = this.modalService.conversionType();
        const currentParams = this.modalService.modalParams();
        const updatedParams: ContextModalParams = {
          ...currentParams,
          channel,
          tactic,
          conversionType: conversionType as string
        };
        this.modalService.setModalParams(updatedParams);
        this.rowSignal.set(updatedParams);
        this.isTacticModalVisible.set(true);
      } else {
        this.isTacticModalVisible.set(false);
      }
    });
    this.modalService.setCurrentRow(this.rowSignal());
  }

  nextAction(): void {
    const row = this.linkService.findNextTactic(
      this.currentRow(),
      this.hasBackButton ? this.tactics() : undefined
    );
    this.setCurrentRow(row);
  }

  previousAction(): void {
    const row = this.linkService.findPrevTactic(
      this.currentRow(),
      this.hasBackButton ? this.tactics() : undefined
    );
    this.setCurrentRow(row);
  }

  selectAction(row: DimensionOption): void {
    const { channel, tactic } = row;
    this.setCurrentRow({ channel, tactic });
  }

  override onCloseModal(): void {
    super.onCloseModal();
    this.router.navigate([], {
      queryParams: {
        modal: null,
        channel: null,
        tactic: null,
        conversion_type: null
      },
      queryParamsHandling: 'merge'
    });
    this.isTacticModalVisible.set(false);
  }

  private setCurrentRow(row: ContextModalParams | null | undefined): void {
    if (row) {
      this.modalService.setCurrentRow(row);
    }
  }
}
