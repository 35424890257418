import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from '@portal/app/shared/services/api.service';
import { StorageService } from '@portal/app/shared/services/storage.service';
import { SelectionService } from '@portal/app/shared/services/selection.service';
import { environment } from '@portal/environments/environment';
import { Client, SSOProvider, User } from '@portal/app/shared/types';
import { UserModel } from '@portal/app/shared/models/userModel';
import { IAccount, NgxPendoService } from 'ngx-pendo';
import { Observable, take, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { CommonAuthService } from '@libs/common-services';
import { find } from 'lodash-es';
import { demoDefaultClient } from '@portal/app/shared/constants/constants';
import { WindowService } from './window.service';

const ID_TOKEN = 'id_token';
const USER = 'user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public readonly changePasswordUrl = `https://login.measured.com/`;
  public readonly idToken$: Observable<string>;

  constructor(
    public commonAuth: CommonAuthService,
    private readonly router: Router,
    private readonly store: StorageService,
    private readonly apiService: ApiService,
    private readonly selectionService: SelectionService,
    protected pendoService: NgxPendoService,
    private readonly windowService: WindowService
  ) {
    this.idToken$ = this.commonAuth.idToken$;
  }

  public async signIn(): Promise<void> {
    if (environment.ssoProvider === SSOProvider.okta) {
      return this.commonAuth.signIn();
    }
    this.windowService.navigateToHref(this.commonAuth.loginUrl());
  }

  public async signOut(): Promise<void> {
    return this.commonAuth.signOut();
  }

  private clearUser(): void {
    this.store.remove(USER);
  }

  public setUser(user: User): void {
    this.store.set(USER, user);
  }

  public getUser(): UserModel | null {
    const user: User | null = this.store.getAsUser(USER);
    return user ? new UserModel(user) : null;
  }

  public setAuthToken(token: string): void {
    this.store.set(ID_TOKEN, token);
  }

  public login(idToken: string): Observable<UserModel> {
    this.setAuthToken(idToken);
    return new Observable((subscribe) => {
      this.commonAuth
        .getUserDetails()
        .then((user) => {
          if (user) {
            this.setUser(user);
            // Will check client/brand and set access on login for regular user.
            const userWithModel: UserModel | null = this.getUser();
            if (userWithModel != null) {
              if (
                !userWithModel.hasAccessToMultipleClientOrBrandIds(
                  this.selectionService
                )
              ) {
                // eslint-disable-next-line arrow-body-style
                this.setFirstClientAndBrand()
                  .pipe(take(1))
                  .subscribe({
                    next: () => {
                      subscribe.next(userWithModel);
                      subscribe.complete();
                    }
                  });
              }
              subscribe.next(userWithModel);
              subscribe.complete();
            } else {
              subscribe.error(`Unable to authenticate user account.`);
              subscribe.complete();
            }
          } else {
            this.clearUser();
            subscribe.error(`Unable to authenticate user account.`);
            subscribe.complete();
          }
        })
        .catch((error: HttpErrorResponse) => {
          subscribe.error(
            `Unable to authenticate with the provided id_token: ${error.message}, ${error.error}`
          );
          subscribe.complete();
        });
    });
  }

  public logout(options = {}): void {
    // Clears out stored user-sensitive data
    this.store.remove(ID_TOKEN);
    this.selectionService.clearSelection();
    this.store.remove('fb-scale.selectedParameter');
    this.commonAuth.logout(options);
  }

  public initPendo(user: UserModel, clients: Client[]): void {
    if (environment.production) {
      let accountInfo = {
        id: '1000',
        name: 'Measured',
        brandId: '1000',
        clientId: '9999',
        brandName: UserModel.measuredBrand
      };
      try {
        if (!user.isMeasuredUser()) {
          accountInfo = {
            id: `${clients[0]?.brands[0]?.brandId}`,
            name: clients[0]?.clientName || '',
            brandId: `${clients[0]?.brands[0]?.brandId}`,
            clientId: `${clients[0]?.clientId}`,
            brandName: clients[0]?.brands[0]?.brandName || ''
          };
          this.initializePendo(user, accountInfo);
        } else {
          this.initializePendo(user, accountInfo);
        }
      } catch (e) {
        console.error(`Pendo is blocked, ${e}`);
      }
    }
  }

  public setFirstClientAndBrand(): Observable<void> {
    const user = this.getUser() as UserModel;
    // This is a user with access to only one client/brand, just select the first one of each
    return new Observable<void>((subsribe) => {
      this.apiService.getClients().subscribe({
        next: (response) => {
          const clients = response != null ? response.clients || [] : [];
          let client = clients[0];
          let brand = client?.brands[0];
          if (
            user.isSuperUser(this.selectionService) &&
            user.isMeasuredUser()
          ) {
            // Specifically set to Measured Demo (if it exists)
            const measuredDemo = clients.find(
              (i) => i.clientId === demoDefaultClient.clientId
            );
            if (measuredDemo != null) {
              client = measuredDemo;
              brand =
                find(measuredDemo.brands, {
                  brandId: demoDefaultClient.brandId
                }) || measuredDemo.brands[0];
            }
          }
          if (client && brand) {
            this.selectionService.setSelection(client, brand);
          }
          subsribe.next();
          subsribe.complete();
        },
        error: (error: HttpErrorResponse) => {
          throwError(() => new Error(error.message));
        }
      });
    });
  }

  private initializePendo(user: UserModel, accountInfo: IAccount) {
    this.pendoService.initialize(
      user.userDetails(this.selectionService),
      accountInfo
    );
  }
}
