<portal-navbar></portal-navbar>
<div class="container-fluid main-container g-0" id="main-container-id">
  <div class="row g-0">
    <section
      class="col-md-12 position-relative main-content"
      [ngClass]="{
        'tableau-content': isTableauUriPath,
      }">
      <router-outlet></router-outlet>
    </section>
  </div>
</div>
<portal-footer class="portal-footer"></portal-footer>
