import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { LoaderType, Size, ViewType } from '@libs/common-components';

@Component({
  selector: 'lib-ui-loader-inline',
  templateUrl: './loader-inline.component.html',
  styleUrls: ['./loader-inline.component.scss']
})
export class LoaderInlineComponent implements AfterViewInit {
  @Input() isTransparent = false;
  @Input() loaderTitle = `We're on it!`;
  @Input() loaderMessage = 'Please wait while the operation completes';
  @Input() loaderContainer: HTMLElement | undefined;
  @Input() type: LoaderType = LoaderType.modal;
  @Input() view: ViewType = ViewType.loader;
  @Input() size: Size = Size.lg;

  @Input() config:
    | {
        closeIcon?: boolean;
        closeText?: string | null;
        retryButton?: boolean;
        okayButton?: boolean;
        okayButtonText?: string;
        titleIcon?: boolean;
        title?: string | null;
        details?: string;
        view: ViewType;
        isMessage?: boolean;
      }
    | undefined = undefined;

  @Output() handleContinue = new EventEmitter<void>();
  @Output() handleRetry = new EventEmitter<void>();
  @Output() handleClose = new EventEmitter<void>();
  @Output() handleMessageEvents = new EventEmitter<{
    ok: boolean;
    close: boolean;
  }>();

  @ViewChild('loaderContent') loaderContent: HTMLElement | undefined;

  ngAfterViewInit() {
    this.loaderContainer?.classList.add('position-relative');
    const content = document.getElementById('loader-wrapper');
    if (content) {
      this.showLoaderContent(content);
    }
  }

  private showLoaderContent(content: HTMLElement) {
    content.classList.add('show');
    content.classList.remove('hide');
  }
}
