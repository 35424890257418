import { Injectable } from '@angular/core';
import { Observable, catchError, from, of } from 'rxjs';

// Services
import { ApiService } from '@portal/app/shared/services/api.service';

// Types
import {
  DataEndpoint,
  DashboardDataOrCountResponseType,
  Filter
} from '@portal/app/shared/types';
interface Props {
  dimensions: string[];
  filters: Filter[];
  dashboardLiteralId?: string;
  productLiteralId?: string;
}

@Injectable({
  providedIn: 'root'
})
export class DataSetService {
  constructor(private apiService: ApiService) {}

  fetchDataSets({
    dimensions,
    filters,
    dashboardLiteralId,
    productLiteralId
  }: Props): Observable<DashboardDataOrCountResponseType> {
    const dashboardDataPromise = this.apiService.getDashboardData(
      {
        filters,
        dimensionValues: dimensions,
        type: DataEndpoint.dataSets
      },
      dashboardLiteralId,
      productLiteralId
    );

    return from(dashboardDataPromise).pipe(
      catchError((error) => {
        console.error('Error in fetchDataSets:', error);
        return of([]);
      })
    );
  }
}
