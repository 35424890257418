import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import {
  TableResponse,
  FieldDefinitions,
  PivotTableResponse,
  DataRowData,
  DataGridAvailable,
  DataGridGrouped,
  DataGridEditable,
  DataGridSelected,
  DataGridPivotColumn,
  FieldDefinition,
  DataGridBarVisualizer
} from '@portal/app/shared/types';
import {
  DataDriver,
  DataDriverResponse,
  DriverResponse,
  PreviousPeriodService
} from '@portal/app/shared/services/previous-period.service';
import { CompareService } from '@portal/app/shared/services/compare.service';

@Injectable({
  providedIn: 'root'
})
export class ExportCompareService {
  constructor(private readonly compareService: CompareService) {}

  public prepareDataForCompare(
    fieldDefinitions: FieldDefinitions,
    table: TableResponse | PivotTableResponse
  ): void {
    if (!this.compareService.compare) {
      return;
    }

    PreviousPeriodService.datagridCompareFields.forEach((key) => {
      const field = fieldDefinitions[key];
      if (field) {
        const prevKey = `${PreviousPeriodService.fieldPrefix}${key}`;
        const prevField = cloneDeep(field);
        prevField.label = `${PreviousPeriodService.labelPreviousPrefix} ${prevField.label}`;
        prevField.literalId = prevKey;
        fieldDefinitions[prevKey] = prevField;

        const diffKey = `${PreviousPeriodService.fieldDifferencePrefix}${key}`;
        const diffField = cloneDeep(field);
        diffField.label = `${PreviousPeriodService.labelDifferencePrefix} ${diffField.label}`;
        diffField.literalId = diffKey;
        fieldDefinitions[diffKey] = diffField;

        const tableField = table.fields.find((f) => f.dashboardFieldId === key);
        if (tableField) {
          const prevPeriodField = cloneDeep(tableField);
          prevPeriodField.dashboardFieldId = prevKey;
          let index = table.fields.indexOf(tableField);
          table.fields.splice(index + 1, 0, prevPeriodField);

          const diffPeriodField = cloneDeep(tableField);
          diffPeriodField.dashboardFieldId = diffKey;
          index = table.fields.indexOf(prevPeriodField);
          table.fields.splice(index + 1, 0, diffPeriodField);
        }

        // recalculate new fields in footer
        this.calculateFooterCell(table, prevKey, prevField.aggregateFunction);
        this.calculateFooterCell(table, diffKey, diffField.aggregateFunction);
      }
    });
  }

  public prepareDataForDrivers(
    fieldDefinitions: FieldDefinitions,
    table: DriverResponse
  ) {
    PreviousPeriodService.datagridDriversFields.forEach((key) => {
      const driverKey = `${PreviousPeriodService.fieldDriversPrefix}${key}`;
      const tableField = table.fields.find(
        (f) =>
          f.dashboardFieldId ===
          `${PreviousPeriodService.fieldDifferencePrefix}${key}`
      );
      if (tableField) {
        let startIndex = table.fields.indexOf(tableField);
        table.data.forEach((row: DataDriverResponse, index) => {
          const drivers = row[driverKey] as unknown as DataDriver[];
          if (drivers && driverKey.length) {
            drivers.forEach((driver) => {
              if (driver.field) {
                // current
                this.addDriverColumn(
                  fieldDefinitions,
                  table,
                  tableField,
                  row,
                  driver,
                  key,
                  'value',
                  `${driver.field?.label}`,
                  startIndex + 1
                );

                // previous
                this.addDriverColumn(
                  fieldDefinitions,
                  table,
                  tableField,
                  row,
                  driver,
                  key,
                  'previous',
                  `Previous ${driver.field?.label}`,
                  startIndex + 2
                );

                // difference
                this.addDriverColumn(
                  fieldDefinitions,
                  table,
                  tableField,
                  row,
                  driver,
                  key,
                  driver.field?.format === 'percent' ? 'percent' : 'difference',
                  driver.field?.format === 'percent'
                    ? `% Difference ${driver.field?.label}`
                    : `Difference ${driver.field?.label}`,
                  startIndex + 3
                );

                const lastColumnIndex = this.findColumnIndex(
                  table,
                  `${key}_${driver.field.literalId}_difference`
                );
                if (lastColumnIndex !== -1) {
                  startIndex = lastColumnIndex;
                }

                if (index === table.data.length - 1) {
                  // recalculate new fields in footer
                  this.calculateFooterCell(
                    table as TableResponse,
                    `${key}_${driver.field.literalId}_value`,
                    driver.field.aggregateFunction ||
                      (fieldDefinitions[key] as FieldDefinition)
                        .aggregateFunction
                  );
                  this.calculateFooterCell(
                    table as TableResponse,
                    `${key}_${driver.field.literalId}_previous`,
                    driver.field.aggregateFunction ||
                      (fieldDefinitions[key] as FieldDefinition)
                        .aggregateFunction
                  );
                  this.calculateFooterCell(
                    table as TableResponse,
                    `${key}_${driver.field.literalId}_difference`,
                    driver.field.aggregateFunction ||
                      (fieldDefinitions[key] as FieldDefinition)
                        .aggregateFunction
                  );
                }
              }
            });
          }
        });
      }
    });
  }

  private findColumnIndex(table: DriverResponse, key: string) {
    const lastField = table.fields.find((f) => f.dashboardFieldId === key);
    return lastField ? table.fields.indexOf(lastField) : -1;
  }

  private addDriverColumn(
    fieldDefinitions: FieldDefinitions,
    table: DriverResponse,
    tableField:
      | DataGridSelected
      | DataGridEditable
      | DataGridAvailable
      | DataGridGrouped
      | DataGridPivotColumn
      | DataGridBarVisualizer
      | undefined,
    row: DataDriverResponse,
    driver: DataDriver,
    key: string,
    property: string,
    label: string,
    index: number
  ) {
    const driverField = { ...driver.field } as FieldDefinition;
    if (driverField && driverField.literalId) {
      const currentKey = `${key}_${driverField.literalId}_${property}`;

      // add field to field definitions
      if (!fieldDefinitions[currentKey]) {
        if (
          driverField.literalId === 'ordersLT' &&
          ['value', 'previous'].includes(property)
        ) {
          driverField.digitsInfo = '1.2-7';
        }
        if (
          driverField.literalId === 'percInc' &&
          ['value', 'previous'].includes(property)
        ) {
          driver[property] = (driver[property] as number) * 100;
        }
        if (
          !driverField.id &&
          driverField.format === 'percent' &&
          ['value', 'previous'].includes(property)
        ) {
          driverField.format = 'number';
        }
        fieldDefinitions[currentKey] = {
          ...driverField,
          label
        };
      }

      // add field to table definitions
      const foundField = table.fields.find(
        (f) => f.dashboardFieldId === currentKey
      );
      if (!foundField && tableField) {
        const newField = cloneDeep(tableField);
        newField.dashboardFieldId = currentKey;
        table.fields.splice(index, 0, newField);
      }

      // add value to table row
      row[currentKey] = driver[property] as DataRowData;
    }
  }

  private calculateFooterCell(
    table: TableResponse | PivotTableResponse,
    propertyName: string,
    aggregateFunction: string | null
  ) {
    if (table.footer.length && table.footer[0]) {
      const data = table.data.map((row) => row[`${propertyName}`]);
      if (aggregateFunction && aggregateFunction === 'sum') {
        table.footer[0][`${propertyName}`] = data.reduce((total, curr) => {
          return typeof total === 'number' && typeof curr === 'number'
            ? total + curr
            : (total as number);
        }, 0) as DataRowData;
      }
    }
  }
}
