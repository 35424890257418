import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import {
  DataGridExportConfig,
  DataResponse,
  DataSetResponse,
  IExpandableTableData,
  IStaticPivotConfig,
  ITableField,
  Filter,
  DataEndpoint,
  DataRowData
} from '@portal/app/shared/types';
import { cloneDeep, filter, groupBy, isEmpty, keys } from 'lodash-es';
import { Required } from '@portal/app/shared/decorators/required.decorator';
import { FormatterService } from '@portal/app/shared/services/formatter.service';
import { DataGridService } from '@portal/app/datagrid/services/data-grid.service';
import { ExportTableService } from '@portal/app/shared/services/export-table.service';
import { Subscription, Observable } from 'rxjs';
import { ContextStore } from '@portal/app/shared/state/context.store';
import {
  ApiService,
  ExportObjectParamValue
} from '@portal/app/shared/services/api.service';

@Component({
  selector: 'portal-data-grid-row-group-expand',
  templateUrl: './data-grid-row-group-expand.html'
})
export class DataGridRowGroupExpandComponent implements OnInit, OnDestroy {
  @Required @Input() data!: DataSetResponse;
  @Required @Input() fields: ITableField[] = [];
  @Required @Input() groupByField = '';
  @Required @Input() styleClass = '';
  @Input() exportFields: ITableField[] = [];
  @Input() exportFileName: string | undefined = undefined;
  @Input() expansionData: IStaticPivotConfig | undefined = undefined;
  @Input() highlightColumns: string[] = [];
  @Input() shouldExpandAllAtLoad = false;

  groups: IExpandableTableData[] = [];
  expandedRows: Record<string, boolean> = {};

  private subscriptions: Subscription = new Subscription();
  filterContext$: Observable<Filter[]> = this.contextStore.filterContext;
  filters: Filter[] = [];

  constructor(
    private formatterService: FormatterService,
    private dataGridService: DataGridService,
    public exportTableService: ExportTableService,
    private readonly contextStore: ContextStore,
    protected readonly apiService: ApiService
  ) {}

  ngOnInit(): void {
    const formattedData = this.formatData();
    this.expansionData = this.formatExpansionData();
    if (this.data && this.groupByField) {
      const groupedByData = groupBy(formattedData, this.groupByField);
      this.groups = keys(groupedByData).map((key) => {
        if (this.shouldExpandAllAtLoad) {
          this.expandedRows[key] = this.shouldExpandAllAtLoad;
        }
        return {
          [this.groupByField]: key,
          expandableData: groupedByData[key],
          expansionData: this.expansionData as IStaticPivotConfig
        };
      }) as IExpandableTableData[];
      this.setUpDataGridStoreForExport();
    }
  }

  formatData(): DataResponse[] {
    const tempData = cloneDeep(this.data);
    if (tempData && !isEmpty(tempData)) {
      return tempData.data.map((dObj) => {
        keys(dObj).forEach((key) => {
          const [field] = filter(this.fields, {
            field: key
          });
          if (field) {
            dObj[key] = this.formatterService.formatPartialValue(
              field.format,
              dObj[key] as DataRowData
            );
          }
        });
        return dObj;
      });
    }
    return [];
  }

  setUpDataGridStoreForExport(): void {
    this.dataGridService.setDataGridContextStore({
      data: this.groups,
      columns: this.fields,
      tableType: 'ExpandableTable',
      tableName: this.data.name
    });
    const sub1: Subscription = this.filterContext$.subscribe({
      next: (filters: Filter[]) => {
        this.filters = filters;
      }
    });
    this.subscriptions.add(sub1);
    const sub2 = this.dataGridService.context.subscribe(
      (config: DataGridExportConfig) => {
        const latestConfig = filter(this.dataGridService.dataGridContextStore, {
          tableName: config.tableName
        });
        const latestConfigData = latestConfig[0]
          ?.data as unknown as IExpandableTableData[];
        const geoResultsExpansionTableConfig = filter(
          this.dataGridService.dataGridContextStore,
          {
            tableName: latestConfigData[0]?.expansionData.name
          }
        )[0];
        if (latestConfig.length > 0) {
          const format = config.format ? config.format : 'csv';
          const exportObjParams: ExportObjectParamValue = {
            literalId: '',
            name:
              this.exportFileName ||
              config.fileName ||
              config.tableName ||
              'RowGroupData',
            format,
            tableData: latestConfig[0]
              ?.data as unknown as IExpandableTableData[],
            expansionTableConfig: geoResultsExpansionTableConfig,
            columns:
              this.exportFields.length > 0 ? this.exportFields : this.fields
          };
          this.apiService.getExportData({
            filters: this.filters,
            type: DataEndpoint.dataSetExport,
            exportObjParams
          });
        }
      }
    );
    this.subscriptions.add(sub2);
  }

  ngOnDestroy(): void {
    this.dataGridService.removeTableFromDataGridStore(this.data.name);
    this.subscriptions.unsubscribe();
  }

  formatExpansionData() {
    const tempData = cloneDeep(this.expansionData);
    if (tempData && !isEmpty(tempData)) {
      tempData.pivotData = tempData.pivotData.map((dObj) => {
        keys(dObj).forEach((key) => {
          const [field] = filter(this.fields, {
            field: key
          });
          if (field) {
            dObj[key] = this.formatterService.formatPartialValue(
              field.format,
              dObj[key] as DataRowData
            );
          }
        });
        return dObj;
      });
    }
    return tempData;
  }
}
