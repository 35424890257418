import { Injectable } from '@angular/core';
import { ApiService as SharedApiService } from '@portal/app/shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {
  private static readonly subMessageType = 'PORTAL_UI_ERROR';
  constructor(private readonly sharedApiService: SharedApiService) {}

  logError(errorMessage: string) {
    this.sharedApiService
      .postLog('ERROR', errorMessage, ErrorHandlingService.subMessageType)
      .subscribe(() => {
        console.warn('');
      });
  }
}
