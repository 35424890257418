import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { lowerCase } from 'lodash-es';

import { DataSetService } from '@portal/app/dashboard/home-page/services/data-set.service';
import {
  DateTimeService,
  DayJsDateFormat,
  priorMonth
} from '@portal/app/shared/services/date-time.service';
import { ErrorHandlingService } from '@portal/app/dashboard/integrations/services/error-handling.service';
import { FormatService } from '@portal/app/dashboard/home-page/services/format.service';
import { FilterService } from '@portal/app/dashboard/home-page/services/filter.service';
import { FieldService } from '@portal/app/shared/services/field.service';

import {
  DataResponse,
  DataRowData,
  DataSetResponse,
  DashboardDataOrCountResponseType,
  Filter
} from '@portal/app/shared/types';

import {
  PERIOD_FLAG_LITERAL_ID,
  METRIC_NAME_STATUS_LITERAL_ID,
  BENCHMARK_INDUSTRY_FLAG_LITERAL_ID
} from './benchmark.constants';

import type { BenchmarkData, FormattedItem } from './benchmark.types';
import { RELATIVE_DAY_LITERAL_ID } from '@portal/app/dashboard/home-page/home-page.constants';

@Injectable({
  providedIn: 'root'
})
export class BenchmarkService implements OnDestroy {
  // Filters state
  private currentFilters: Filter[] = [];

  // Loading state
  private dataSubject = new BehaviorSubject<BenchmarkData | null>(null);
  public data$ = this.dataSubject.asObservable();

  // Cleanup
  private destroy$ = new Subject<void>();

  // Loading state
  private isLoadingSubject = new BehaviorSubject<boolean>(true);
  public isLoading$ = this.isLoadingSubject.asObservable();

  constructor(
    private dataSetService: DataSetService,
    private dateTimeService: DateTimeService,
    private errorHandlingService: ErrorHandlingService,
    private formatService: FormatService,
    private filterService: FilterService,
    private fieldService: FieldService
  ) {
    this.currentFilters = this.filterService.getCurrentFilters();
  }

  fetchData(): void {
    this.isLoadingSubject.next(true);

    this.dataSetService
      .fetchDataSets({
        dimensions: [],
        filters: this.getFilters(this.currentFilters) // Use the regular variable directly
      })
      .subscribe({
        next: (response) => {
          const formattedData = this.formatData(response);
          this.dataSubject.next(formattedData);
          this.isLoadingSubject.next(false);
        },
        error: (error) => {
          // Handle error
          this.errorHandlingService.logError(
            'BenchmarkService error: ' + error
          );
          this.isLoadingSubject.next(false);
        }
      });
  }

  getFilters(providedFilters: Filter[]): Filter[] {
    const neededFilterIds = ['conversion_type', 'isInitialFilterCall'];

    const selectedFilters = providedFilters.filter((filter) =>
      neededFilterIds.includes(filter.literalId)
    );

    // calulate prior month start/end dates
    const relativeDayOption =
      this.dateTimeService.getRelativeDayOptionByRelativeDay(priorMonth);
    if (!relativeDayOption || !relativeDayOption.action) {
      return [];
    }

    const { startDate, endDate } = relativeDayOption.action;

    // Hardcoded filters
    const hardcodedFilters = [
      this.filterService.createFilter({
        literalId: 'relative_day',
        type: 'string',
        value: priorMonth
      }),
      this.filterService.createFilter({
        literalId: 'dateStart',
        type: 'string',
        value: startDate.format(DayJsDateFormat.fullDate)
      }),
      this.filterService.createFilter({
        literalId: 'dateStop',
        type: 'string',
        value: endDate.format(DayJsDateFormat.fullDate)
      }),
      this.filterService.createFilter({
        literalId: 'dataSets',
        type: 'string[]',
        value: ['benchmarkOutliers']
      }),
      this.filterService.createFilter({
        literalId: 'conversion_type',
        type: 'string',
        value: 'Joins'
      })
    ];

    const combinedFilters = [...selectedFilters, ...hardcodedFilters];

    return Array.from(
      new Map(
        combinedFilters.map((filter) => [filter.literalId, filter])
      ).values()
    );
  }

  formatData(response: DashboardDataOrCountResponseType): {
    items: FormattedItem[];
  } {
    if (!Array.isArray(response)) {
      console.error('Response is not an array');
      return { items: [] };
    }

    const dataSetResponses = response as DataSetResponse[];
    const benchmarkOutliersResponse = dataSetResponses.find(
      (r): r is DataSetResponse => r.name === 'benchmarkOutliers'
    );

    if (!benchmarkOutliersResponse) {
      return { items: [] };
    }

    return { items: this.processDataItems(benchmarkOutliersResponse.data) };
  }

  private processDataItems(data: DataResponse[]): FormattedItem[] {
    return data
      .map(this.formatItem)
      .filter((item) => item !== null) as FormattedItem[];
  }

  private formatItem = (item: DataResponse): FormattedItem | null => {
    const parseNumber = (value: DataRowData): number => {
      if (typeof value === 'number') {
        return value;
      }
      if (typeof value === 'string') {
        return parseFloat(value) || 0;
      }
      return 0;
    };

    const myIndexValue = parseNumber(item.my_index_value as DataRowData);
    const metricsMedian = parseNumber(item.metrics_median as DataRowData);
    const countBrands = parseNumber(item.count_brands as DataRowData);

    const name = item.tactic || item.channel || '';
    const title = item.metrics_flag_filter?.toString() || '';
    const badge = item.metric_name?.toString() || '';
    const description = item.period_desc?.toString() || '';

    const fieldDefinition = this.fieldService.getFieldDefinitionByLiteralId(
      item.literal_id?.toString() || ''
    );

    const mySpend = this.formatService.formatValue(
      fieldDefinition,
      myIndexValue
    );
    const benchmark = this.formatService.formatValue(
      fieldDefinition,
      metricsMedian
    );

    const mySpendPercentage = myIndexValue / (myIndexValue + metricsMedian);
    const countBrandsHigherThanMySpend = Math.round(
      mySpendPercentage * countBrands
    );

    const tooltipHtml = `<div class="b1">Your spend allocation is higher than <span class="text-blue-300">${countBrandsHigherThanMySpend}</span> out of <span class="text-blue-300">${countBrands}</span> brands.</div>`;

    const tooltipOptions =
      mySpendPercentage > 0.5
        ? { positionTop: 0, positionLeft: -5 }
        : { positionTop: 10, positionLeft: 5 };

    return {
      name,
      title,
      badge,
      description,
      label: `${countBrands} Brands`,
      value: (mySpendPercentage * 100).toFixed(0),
      valueTooltip: tooltipHtml,
      tooltipPosition: mySpendPercentage > 0.5 ? 'left' : 'bottom',
      tooltipOptions,
      mySpend,
      benchmark,
      navigationData: {
        type: `"${lowerCase(item.metric_name as string)}"`,
        channel: `"${item.channel}"`,
        tactic: item.tactic ? `"${item.tactic}"` : '""',
        [METRIC_NAME_STATUS_LITERAL_ID]: '"Active"',
        [PERIOD_FLAG_LITERAL_ID]: '"Monthly"',
        [RELATIVE_DAY_LITERAL_ID]: '"Last Available Month"',
        [BENCHMARK_INDUSTRY_FLAG_LITERAL_ID]: '"All Brands"'
      }
    };
  };

  // Handles cleanup when the service is destroyed
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
