<div class="flex justify-between items-center w-full">
  <div class="flex items-center">
    <h2 class="dp2 text-gray-1000 mb-4">My Channels</h2>
    <div
      class="b1 text-blue-600 cursor-pointer pl-4 mt-1 mb-4"
      (click)="navigateToPageAllChannels()">
      View details
    </div>
  </div>
</div>

<div
  class="grid gap-4 grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4 items-stretch auto-rows-fr">
  <!-- Skeleton Loaders for Loading State -->
  <ng-container *ngIf="isLoading">
    <div *ngFor="let skeleton of [].constructor(6)">
      <div class="col-span-1 h-100">
        <m-skeleton height="186px" width="100%"></m-skeleton>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!isLoading">
    <div *ngFor="let channel of channels; let i = index">
      <m-card
        class="col-span-1"
        [hoverable]="true"
        [showIcon]="true"
        (click)="toggleModal(channel)">
        <div header class="flex justify-between items-center w-full">
          <span class="sh2">{{ channel.name }}</span>
        </div>
        <div class="grid grid-cols-2 gap-4 items-stretch mt-4 h-full">
          <ng-container *ngFor="let metric of channel.metrics; let i = index">
            <ng-container *ngIf="metric">
              <m-card
                [ngClass]="{
                  'col-span-2': channel.metrics.length === 1,
                  'col-span-1': channel.metrics.length > 1,
                }"
                variant="small">
                <div>
                  <div class="flex justify-between">
                    <div class="b1 text-gray-800">
                      {{ metric?.title }}
                    </div>
                  </div>
                  <div *ngIf="!metric?.hasError">
                    <div class="h5 text-gray-1000 mb-1">
                      {{ metric?.formattedValue }}
                    </div>

                    <m-tag
                      [isDark]="true"
                      [isPositive]="!!metric?.isPositive"
                      [isNeutral]="!!metric?.isNeutral"
                      [isUp]="!!metric?.isUp"
                      >{{
                        metric?.prevPercDifference / 100
                          | absoluteValue
                          | percent: "1.2-2"
                      }}</m-tag
                    >
                  </div>
                  <div *ngIf="metric?.hasError" class="c1 text-gray-700 mt-1">
                    Not Available
                  </div>
                </div>
              </m-card>
            </ng-container>
          </ng-container>
        </div>
      </m-card>
    </div>
  </ng-container>
</div>

<m-modal
  [dismissableMask]="true"
  [(visible)]="isModalVisible"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  (onHide)="closeModal()"
  [noPadding]="true"
  [style]="{ width: '1090px' }">
  <ng-container modalHeader>
    <div class="flex items-center p-2 mb-3">
      <h4 class="h4 mb-0 mr-2" *ngIf="!isModalLoading; else loadingHeader">
        {{ activeChannel?.name }}
      </h4>
      <ng-template #loadingHeader>
        <m-skeleton width="100px" height="32px"></m-skeleton>
      </ng-template>
    </div>
  </ng-container>

  <!-- Modal Body -->
  <div>
    <!-- Error State -->
    <m-not-available
      *ngIf="!isModalLoading && !metrics?.length"></m-not-available>

    <!-- Loading Skeletons -->
    <div *ngIf="isModalLoading">
      <div class="pl-4 flex">
        <m-skeleton class="mx-1" width="155px" height="100px"></m-skeleton>
        <m-skeleton class="mx-1" width="155px" height="100px"></m-skeleton>
        <m-skeleton class="mx-1" width="155px" height="100px"></m-skeleton>
        <m-skeleton class="mx-1" width="155px" height="100px"></m-skeleton>
        <m-skeleton class="mx-1" width="155px" height="100px"></m-skeleton>
      </div>
      <!-- Loading skeletons for each tab content -->
      <div class="grid grid-cols-3 gap-4 p-4">
        <m-skeleton width="334px" height="382px"></m-skeleton>
        <m-skeleton width="334px" height="382px"></m-skeleton>
        <m-skeleton width="334px" height="382px"></m-skeleton>
      </div>
    </div>

    <!-- Tab View -->
    <p-tabView
      class="m-tabview contained"
      (onChange)="onTabChange($event)"
      *ngIf="!isModalLoading && metrics?.length">
      <ng-container *ngFor="let metric of metrics; let i = index">
        <p-tabPanel>
          <ng-template pTemplate="header">
            <div class="m-tabview-header h-100">
              <!-- Tab header content -->
              <m-metric-amount-header
                *ngIf="!metric?.hasError"
                [title]="metric?.title"
                [amount]="metric?.formattedValue"
                [prevPercDifference]="metric?.prevPercDifference"
                [isPositive]="metric?.isPositive"
                [isNeutral]="metric?.isNeutral"
                [isUp]="metric?.isUp">
              </m-metric-amount-header>
            </div>
          </ng-template>
          <!-- Tab body content -->

          <!-- Charts -->
          <div *ngIf="activeTabIndex === i" class="grid grid-cols-3 gap-4">
            <portal-dimension-chart
              [activeChannel]="activeChannel?.name"
              [activeMetric]="metric"></portal-dimension-chart>
            <portal-compare-chart></portal-compare-chart>
            <portal-trending-chart></portal-trending-chart>
          </div>
        </p-tabPanel>
      </ng-container>
    </p-tabView>
  </div>

  <!-- Modal Footer -->
  <ng-container modalFooter>
    <div
      class="modal-footer flex justify-center w-full"
      *ngIf="metrics?.length">
      <m-button [type]="'secondary'" (click)="navigateToPage()">
        <span class="b1 text-blue-600">Explore this channel</span>
      </m-button>
    </div>
  </ng-container>
</m-modal>
