<div class="flex items-center relative w-full">
  <div class="relative w-[inherit]">
    <input
      autofocus
      #inputSearch
      (keyup.enter)="addFilterValue(filterType.contain); showFilterMenu = true"
      [(ngModel)]="searchValue"
      [disabled]="disabled"
      class="m-input pe-[3.5rem] {{
        totalKeywords > 0 ? 'placeholder:text-gray-1000' : ''
      }}"
      id="search-input"
      pInputText
      placeholder="{{
        totalKeywords > 0
          ? totalKeywords + (totalKeywords > 1 ? ' keywords' : ' keyword')
          : 'Search'
      }}" />
    <span class="flex gap-1 items-center absolute right-2 top-[6px]">
      <span
        *ngIf="!searchValue?.length && !showFilterMenu"
        class="material-symbols-outlined search-inline-button inline-search-icon"
        (click)="showFilterMenu = totalKeywords > 0">
        search
      </span>
      <span
        *ngIf="!searchValue?.length && showFilterMenu && totalKeywords > 0"
        class="material-symbols-outlined search-inline-button inline-search-icon"
        (click)="showFilterMenu = false">
        keyboard_arrow_up
      </span>
      <span
        *ngIf="searchValue?.length"
        class="material-symbols-outlined search-inline-button add-icon"
        (click)="
          $event.stopImmediatePropagation();
          showFilterMenu = true;
          inputSearch.click();
          addFilterValue(filterType.contain)
        ">
        add
      </span>
      <span
        *ngIf="searchValue?.length"
        class="material-symbols-outlined search-inline-button remove-icon"
        (click)="
          $event.stopImmediatePropagation();
          showFilterMenu = true;
          inputSearch.click();
          addFilterValue(filterType.exclude)
        ">
        remove
      </span>
    </span>
    <div
      [ngTemplateOutlet]="filterKeywords"
      class="absolute left-0 top-[3rem] z-50"></div>
  </div>
</div>
<ng-template #filterKeywords>
  <div
    class="filter-menu flex flex-col gap-2 justify-between"
    *ngIf="showFilterMenu && totalKeywords > 0">
    <div class="flex flex-col w-full gap-2 filter-items">
      <ng-container *ngFor="let group of filterValues">
        <div *ngIf="group.items?.length" class="filter-menu-group gap-2">
          <span
            class="material-symbols-outlined {{
              group.iconClass
            }}-icon text-gray-500"
            >{{ group.iconClass }}</span
          >
          <span class="b1 text-gray-800">{{ group.label | titlecase }}</span>
        </div>
        <div *ngIf="group.items?.length" class="flex gap-2 flex-wrap">
          <ng-container *ngFor="let value of group.items">
            <m-chip
              [label]="value"
              [removable]="true"
              (remove)="removeFilterValue($event, group.label)"></m-chip>
          </ng-container>
        </div>
      </ng-container>
    </div>
    <div class="filter-menu-footer">
      <m-button (click)="publishFilter()">Search</m-button>
    </div>
  </div>
</ng-template>
