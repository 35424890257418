import { Injectable } from '@angular/core';
import { ElementGroup } from '@portal/app/shared/types';
import { IBackBarLayoutConfig } from '@portal/app/shared/models/IBackbarLayout';

@Injectable({
  providedIn: 'root'
})
export class BackBarLayoutConfigTranslationService {
  public prepareBackBarConfig(item: ElementGroup): IBackBarLayoutConfig {
    return {
      label: item.label as string,
      span: item.span,
      styleClass: item.styleClass as string,
      parentType: 'REPORTING'
    };
  }
}
