import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'm-link',
  templateUrl: './m-link.component.html',
  styleUrls: ['./m-link.component.scss']
})
export class MLinkComponent implements OnChanges {
  @Input() link = '#';
  @Input() label = 'here';
  @Input() disabled = false;
  @Input() linkElement: 'a' | 'span' = 'a';
  @Input() styleClass = '';

  @Output() linkClick = new EventEmitter<string>();

  ngOnChanges(changes: SimpleChanges) {
    this.label = changes['label']?.currentValue || this.label;
  }

  publishLinkClick() {
    this.linkClick.emit(this.link);
  }
}
