/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import * as Highcharts from 'highcharts';
import { HighchartsChartComponent } from 'highcharts-angular';
import { LineChartProps } from '@design-system/components/m-line-chart/m-line-chart.component.types';

/**
 * Component for rendering a line chart using Highcharts.
 *
 * This component accepts inputs for configuring various aspects of the line chart,
 * such as the data series, axes, plot options, and tooltip. It utilizes the Highcharts library
 * to render an interactive and visually appealing line chart, ideal for displaying time-series data.
 *
 * @Input series - An array of series configuration objects. Each series represents a line in the chart.
 * @Input xAxis - Configuration for the x-axis of the chart.
 * @Input yAxisLeft - Configuration for the left y-axis of the chart.
 * @Input yAxisRight - Configuration for the right y-axis of the chart.
 * @Input plotOptions - Plot options for the series.
 * @Input tooltip - Tooltip options for the chart.
 */
@Component({
  selector: 'm-line-chart',
  templateUrl: './m-line-chart.component.html',
  styleUrls: ['./m-line-chart.component.scss']
})
export class MLineChartComponent implements OnInit {
  @ViewChild('chart')
  chartComponent!: HighchartsChartComponent;

  @Input() series: LineChartProps['series'] = [];
  @Input() xAxis: LineChartProps['xAxis'] = {};
  @Input() yAxis: LineChartProps['yAxis'] = [];
  @Input() plotOptions: LineChartProps['plotOptions'] = {};
  @Input() tooltip: LineChartProps['tooltip'] = {};
  @Input() legend: LineChartProps['legend'] = {};
  @Input() height: number | string = 300; // Default height
  @Input() options: Highcharts.Options = {}; // TODO:GP deprecate above props in favor of single entrypoint.
  @Output() chartRender = new EventEmitter<Highcharts.Chart>();

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};

  ngOnInit() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    // Initialize chart options based on input properties
    this.chartOptions = {
      // Configuration for the chart's appearance and behavior
      chart: {
        type: 'areaspline',
        backgroundColor: 'transparent',
        height: this.height,
        reflow: true,
        events: {
          render(this: Highcharts.Chart) {
            self.chartRender.emit(this);
          }
        }
      },
      // Hide credits, exporting options, and legend for a cleaner look
      credits: { enabled: false },
      exporting: { enabled: false },
      title: { text: '' },
      // Apply provided axis configurations
      xAxis: this.xAxis,
      yAxis: this.yAxis,
      // Apply provided plot options and tooltip configuration
      plotOptions: this.plotOptions,
      tooltip: this.tooltip,
      legend: this.legend,
      // Set the data series for the chart
      series: this.series,
      // Chart Options Override
      ...this.options
    };
  }
}
