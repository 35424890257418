import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MultiViewStore } from '@portal/app/shared/state/multi-view.store';
import {
  DataResponse,
  ElementGroup,
  FieldDefinitions,
  ViewResponse
} from '@portal/app/shared/types';
import { isEmpty } from 'lodash-es';

@Component({
  selector: 'portal-view-card',
  templateUrl: './view-card.component.html',
  styleUrls: ['./view-card.component.scss']
})
export class ViewCardComponent implements OnChanges {
  @Input() id: string | undefined;
  @Input() view: ViewResponse | undefined;
  @Input() item: ElementGroup | undefined;
  @Input() fieldDefinitions: FieldDefinitions = {};
  @Input() isLoading: boolean | undefined;

  public dataPoints: Promise<DataResponse> | undefined;

  constructor(private multiViewStore: MultiViewStore) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.view) {
      if (!isEmpty(this.view)) {
        this.dataPoints = this.multiViewStore.getViewDataPoints(this.view);
      } else {
        this.dataPoints = undefined;
      }
    }
  }
}
