// Styles
import { colors } from '@design-system/styles/colors';

// Utils
const generateTooltipHtml = (
  segmentName: string,
  percentage: string,
  spend: string,
  roas: string
): string => {
  return `
    <div>
        <div class="flex justify-center items-center w-full h-full">
            <div class="b3 text-gray-500">${segmentName}: ${percentage}%</div>
        </div>
        <div class="m-divider my-2"></div>
        <div class="flex justify-between w-full">
            <span class="b1 text-gray-000">Spend:</span>
            <span class="b1 text-gray-000">${spend}</span>
        </div>
        <div class="flex justify-between w-full">
            <span class="b1 text-gray-000">ROAS (i):</span>
            <span class="b1 text-gray-000">${roas}</span>
        </div>
    </div>`;
};

export const donutChart = {
  args: {
    data: [
      {
        color: colors['yellow-700'],
        name: 'Video',
        y: 100,
        tooltipHtml: generateTooltipHtml('Video', '12', '48K', '$2.56')
      },
      {
        color: colors['blue-400'],
        name: 'Paid Social',
        y: 88,
        tooltipHtml: generateTooltipHtml('Paid Social', '28', '189K', '$2.56')
      },
      {
        color: colors['green-700'],
        name: 'Others',
        y: 73,
        tooltipHtml: generateTooltipHtml('Others', '13', '48K', '$2.56')
      },
      {
        color: colors['green-500'],
        name: 'Display',
        y: 53,
        tooltipHtml: generateTooltipHtml('Display', '20', '127K', '$1.53')
      },
      {
        color: colors['blue-700'],
        name: 'Search',
        y: 40,
        tooltipHtml: generateTooltipHtml('Search', '15', '111K', '$2.03')
      },
      {
        color: colors['yellow-300'],
        name: 'Affiliate',
        y: 12,
        tooltipHtml: generateTooltipHtml('Affiliate', '12', '48K', '$2.56')
      }
    ],
    innerContent: `<div class="flex flex-col items-center content-center justify-center flex-wrap">
      <div class="b1 text-gray-900">Total Spend</div>
      <div class="h2 text-gray-1000">$186k</div>
      <div class="c1 text-blue-600 center cursor-pointer mt-2">
        View Details
      </div>
    </div>`
  }
};
