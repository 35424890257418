import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

// Services
import { ApiService } from '@portal/app/shared/services/api.service';
import { ChannelsService } from '@portal/app/dashboard/home-page/services/channels.service';
import { ErrorHandlingService } from '@portal/app/dashboard/integrations/services/error-handling.service';
import { FilterService } from './filter.service';
import { FieldService } from '@portal/app/shared/services/field.service';
import { ApiService as GeoApiService } from '@portal/app/dashboard/geo-doe-config/services/api.service';
import { MetricsService } from './metrics.service';
import { IRefreshTimeConfig } from '@portal/app/shared/models/IHeaderConfig';

// types
import type { Options, SeriesOptionsType } from 'highcharts';
import type { Filter } from '@portal/app/shared/types';
import type { IConversionType } from '@portal/app/dashboard/geo-doe-config/models';
@Injectable({
  providedIn: 'root'
})
export class HomepageService {
  private isLoadingSubject = new BehaviorSubject<boolean>(false);

  private portfolioMetricsSubject = new BehaviorSubject<string[]>([]);

  // Conversion types
  private conversionTypesSubject = new BehaviorSubject<IConversionType[]>([]);
  public conversionTypes$ = this.conversionTypesSubject.asObservable();

  // Conversion types
  private customerSectionConversionTypesSubject = new BehaviorSubject<[]>([]);

  public customerSectionConversionTypes$ =
    this.customerSectionConversionTypesSubject.asObservable();

  // Chart view state
  private showChartViewSubject = new BehaviorSubject<boolean>(true);
  public showChartView$ = this.showChartViewSubject.asObservable();

  // Customer view state
  private showCustomerViewSubject = new BehaviorSubject<boolean>(true);
  public showCustomerView$ = this.showCustomerViewSubject.asObservable();

  // Channel tile metrics state
  private channelTileMetricsSubject = new BehaviorSubject<string[]>([]);
  public channelTileMetrics$ = this.channelTileMetricsSubject.asObservable();

  // Channel view metrics state
  private channelViewMetricsSubject = new BehaviorSubject<string[]>([]);
  public channelViewMetrics$ = this.channelViewMetricsSubject.asObservable();

  // Channel metrics state
  private channelMetricsSubject = new BehaviorSubject<string[]>([]);
  public channelMetrics$ = this.channelMetricsSubject.asObservable();

  // Channel metrics state
  private hasDataSubject = new BehaviorSubject<boolean>(true);
  public hasData$ = this.hasDataSubject.asObservable();

  // Layout subjects
  private spendAllocationDimensionSubject = new BehaviorSubject<string>('');
  private compareChartPrimaryMetricSubject = new BehaviorSubject<string>('');
  private compareChartSecondaryMetricSubject = new BehaviorSubject<string>('');
  private customerDataChartDimensionsSubject = new BehaviorSubject<
    { label: string; value: string[] }[]
  >([]);

  private ltvVsCacDataChartDimensionsSubject = new BehaviorSubject<
    { label: string; value: string[] }[]
  >([]);

  private myCustomersSectionDateOptionsSubject = new BehaviorSubject<string[]>([
    ''
  ]);

  private mpoSectionShouldBeExpandedSubject = new BehaviorSubject<boolean>(
    false
  );

  // Layout observables
  public spendAllocationDimension$ =
    this.spendAllocationDimensionSubject.asObservable();

  public compareChartPrimaryMetric$ =
    this.compareChartPrimaryMetricSubject.asObservable();

  public compareChartSecondaryMetric$ =
    this.compareChartSecondaryMetricSubject.asObservable();

  public mpoSectionShouldBeExpanded$ =
    this.mpoSectionShouldBeExpandedSubject.asObservable();

  public customerDataChartDimensions$ =
    this.customerDataChartDimensionsSubject.asObservable();

  public ltvVsCacDataChartDimensions$ =
    this.ltvVsCacDataChartDimensionsSubject.asObservable();

  public myCustomersSectionDateOptions$ =
    this.myCustomersSectionDateOptionsSubject.asObservable();

  private showOptimizationResultsSubject = new BehaviorSubject<boolean>(false);
  public showOptimizationResults$ =
    this.showOptimizationResultsSubject.asObservable();

  private viewBySubject = new BehaviorSubject<string | null>(null);
  public viewBy$ = this.viewBySubject.asObservable();

  constructor(
    private apiService: ApiService,
    private errorHandlingService: ErrorHandlingService,
    private filterService: FilterService,
    private fieldService: FieldService,
    private geoApiService: GeoApiService,
    private metricsService: MetricsService,
    private channelsService: ChannelsService
  ) {}

  fetchAndSetHomepageData(): void {
    this.setLoading(true); // Set loading to true when starting the fetch

    this.fetchHomepageData().subscribe((data) => {
      if (!data) {
        this.setLoading(false);
        this.hasDataSubject.next(false);
        return;
      }

      // Set default conversion type and update filters
      data.filters = this.filterService.setDefaultConversionType(data.filters);

      let dateFilters = []; // Initialize dateFilters to ensure it's always defined

      const relativeDayFilter = data.filters.find(
        (filter: Filter) => filter.literalId === 'relative_day'
      );

      // If value is custom use day and compare_date values
      if (relativeDayFilter?.value?.toLowerCase().includes('custom')) {
        const dayFilter = data.filters.find(
          (f: Filter) => f.literalId === 'day'
        );
        const compareDateFilter = data.filters.find(
          (f: Filter) => f.literalId === 'compare_date'
        );

        dateFilters = this.filterService.getCustomDateFilters(
          dayFilter,
          compareDateFilter
        );
      } else {
        dateFilters = this.filterService.getDateFilters(
          relativeDayFilter?.value
        );
      }

      // Set conversion types
      this.setConversionTypes();

      // Set filters
      this.filterService.setFilters([...dateFilters, ...data.filters]);

      // Set fields
      this.fieldService.setFields(data.fieldDefinitions);

      // Process metrics to include 'LT' versions where applicable
      const processedMetrics = this.addLTMetrics(data.layout[0]?.metrics);

      // Set selected metrics
      this.metricsService.setSelectedMetrics(processedMetrics);

      // Determine non-null channels
      const nonNullChannels = this.getNonNullChannels(data.filters);

      // Set layout channels
      const selectedChannels =
        data.layout[0]?.channelByDisplayOrder.length === 0
          ? nonNullChannels
          : data.layout[0]?.channelByDisplayOrder;

      this.channelsService.setSelectedChannels(selectedChannels);

      this.channelsService.setAllChannels(nonNullChannels);

      this.setPortfolioMetrics(data?.layout[0]?.viewBy);

      this.setChartView(
        data?.layout[0]?.showSpendAllocationAndTrendingChartView
      );

      this.setCustomerView(data?.layout[0]?.showCustomerSection);

      this.setChannelTileMetrics(data?.layout[0]?.channelKpiTileMetrics);

      this.setChannelViewMetrics(data?.layout[0]?.channelKpiViewMetrics);

      this.setChannelMetrics(data?.layout[0]?.channelMetrics);

      this.setSpendAllocationDimension(
        data?.layout[0]?.spendAllocationDimension
      );
      this.setCompareChartPrimaryMetric(
        data?.layout[0]?.compareChartPrimaryMetric
      );
      this.setCompareChartSecondaryMetric(
        data?.layout[0]?.compareChartSecondaryMetric
      );
      this.setMpoSectionShouldBeExpanded(
        data?.layout[0]?.mpoSectionShouldBeExpanded
      );
      this.setCustomerDataChartDimensions(
        data?.layout[0]?.customerDataChartDimensions
      );
      this.setLtvVsCacDataChartDimensions(
        data?.layout[0]?.ltvVsCacDataChartDimensions
      );
      this.setCustomerSectionConversionTypes(
        data?.layout[0]?.customerSectionConversionType
      );
      this.setMyCustomersStartingDateOptions(
        data?.layout[0]?.customerSectionDataAvailableDates
      );

      this.setShowOptimizationResults(data?.layout[0]?.showOptimizationResults);

      this.setViewBy(data?.layout[0]?.viewBy);

      this.setLoading(false);
      this.hasDataSubject.next(true);
    });
  }

  setConversionTypes(): void {
    this.geoApiService
      .getConversionTypes()
      .pipe(take(1))
      .subscribe({
        next: (conversionTypeData) => {
          this.conversionTypesSubject.next(conversionTypeData);
        },
        error: (error) => {
          this.errorHandlingService.logError(
            'getConversionTypes error: ' + error
          );
          this.setLoading(false);
        }
      });
  }

  private addLTMetrics(metrics: string[]): string[] {
    return metrics.flatMap((metric) => {
      if (
        metric.endsWith('I') &&
        this.fieldService.fieldExists(metric.replace(/I$/, 'LT'))
      ) {
        return [metric, metric.replace(/I$/, 'LT')]; // Include original and 'LT' version
      }
      return [metric]; // Include original metric only
    });
  }

  private setPortfolioMetrics(viewBy: string): void {
    const portfolioMetrics =
      viewBy === 'ROAS'
        ? ['mediaSpend', 'roasI', 'salesI', 'cpoI', 'percSalesI']
        : ['mediaSpend', 'totalOrders', 'ordersI', 'cpoI', 'percOrdersI'];
    this.portfolioMetricsSubject.next(portfolioMetrics);
  }

  private getNonNullChannels(filters: Filter[]): string[] {
    const channelFilter = filters.find(
      (filter) => filter.literalId === 'channel'
    );
    return channelFilter?.options?.filter((option) => option !== null) ?? [];
  }

  // Getter to expose the isLoading observable
  get isLoading$(): Observable<boolean> {
    return this.isLoadingSubject.asObservable();
  }

  // Setter to update the isLoading state

  setLoading(isLoading: boolean) {
    this.isLoadingSubject.next(isLoading);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchHomepageData(): Observable<any> {
    return this.apiService.getDashboard().pipe(
      catchError((error) => {
        this.hasDataSubject.next(false);
        this.errorHandlingService.logError('fetchHomepageData error: ' + error);
        this.setLoading(false);
        return of(null); // Handle error scenario
      })
    );
  }

  getRefreshTimeConfig(): { refreshTime: IRefreshTimeConfig } {
    return {
      refreshTime: {
        label: 'Last Updated:',
        link: 'https://support.measured.com/article/pwc7n7hm1c-data-refresh-policy',
        columns: [
          {
            id: 'displayName',
            label: 'Data Source'
          },
          {
            id: 'availableDate',
            label: 'Last Date Available'
          }
        ]
      }
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateHomepageLayout(layout: any): Observable<any> {
    return this.apiService.overrideHomepageLayout(layout).pipe(
      catchError((error) => {
        this.errorHandlingService.logError(
          'updateHomepageLayout error: ' + error
        );
        this.setLoading(false);
        return of(null); // Handle error scenario
      })
    );
  }

  checkChartDataAvailability(chartOptions: Options): boolean {
    return (
      chartOptions.series?.some((series: SeriesOptionsType) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (series as any)?.data?.length > 0;
      }) ?? false
    );
  }

  private setChartView(showChartView: boolean) {
    this.showChartViewSubject.next(showChartView);
  }

  private setCustomerView(showCustomerView: boolean) {
    this.showCustomerViewSubject.next(showCustomerView);
  }

  private setChannelTileMetrics(channelTileMetrics: string[]) {
    this.channelTileMetricsSubject.next(channelTileMetrics);
  }

  private setChannelViewMetrics(channelViewMetrics: string[]) {
    this.channelViewMetricsSubject.next(channelViewMetrics);
  }

  private setChannelMetrics(channelMetrics: string[]) {
    this.channelMetricsSubject.next(channelMetrics);
  }

  private setSpendAllocationDimension(dimension: string) {
    this.spendAllocationDimensionSubject.next(dimension);
  }

  private setCompareChartPrimaryMetric(metric: string) {
    this.compareChartPrimaryMetricSubject.next(metric);
  }

  private setCompareChartSecondaryMetric(metric: string) {
    this.compareChartSecondaryMetricSubject.next(metric);
  }

  private setMpoSectionShouldBeExpanded(shouldBeExpanded: boolean) {
    this.mpoSectionShouldBeExpandedSubject.next(shouldBeExpanded);
  }

  private setCustomerDataChartDimensions(
    dimension: { label: string; value: string[] }[]
  ) {
    this.customerDataChartDimensionsSubject.next(dimension);
  }

  private setLtvVsCacDataChartDimensions(dimension: []) {
    this.ltvVsCacDataChartDimensionsSubject.next(dimension);
  }

  private setMyCustomersStartingDateOptions(dimension: []) {
    this.myCustomersSectionDateOptionsSubject.next(dimension);
  }

  private setCustomerSectionConversionTypes(dimension: [] | string) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dimensionArray: any[] = Array.isArray(dimension)
      ? [...dimension]
      : [dimension];

    const options = dimensionArray?.map((option) => ({
      label: option,
      value: option
    }));

    this.customerSectionConversionTypesSubject.next(options as []);
  }

  private setShowOptimizationResults(show: boolean) {
    this.showOptimizationResultsSubject.next(show);
  }

  private setViewBy(viewBy: string) {
    this.viewBySubject.next(viewBy);
  }
}
