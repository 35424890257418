/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// services
import { MetricsService } from '@portal/app/dashboard/home-page/services/metrics.service';
import { PortfolioService } from './portfolio.service';

// Types
import type { Metric } from '@portal/app/dashboard/home-page/services/metrics.service';

// Constants
import { DESCRIPTION_MAP, RESPONSIVE_OPTIONS } from './portfolio.const';

@Component({
  selector: 'portal-my-portfolio',
  templateUrl: './portfolio.component.html'
})
export class PortfolioComponent implements OnInit, OnDestroy {
  activeMetric?: Metric | null;
  private destroy$ = new Subject<void>();
  isLoading = true;
  isModalVisible = false;
  showCarousel = false;
  metrics: Metric[] = [];
  responsiveOptions = RESPONSIVE_OPTIONS;
  descriptionMap = DESCRIPTION_MAP;

  constructor(
    private metricsService: MetricsService,
    private portfolioService: PortfolioService
  ) {}

  ngOnInit() {
    this.subscribeToData();
  }

  private subscribeToData() {
    this.portfolioService.metrics$
      .pipe(takeUntil(this.destroy$))
      .subscribe((metrics) => {
        this.metrics = metrics;
        this.showCarousel = metrics?.length > 4;
      });

    this.portfolioService.isLoading$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoading) => {
        this.isLoading = isLoading;
      });
  }

  closeModal() {
    this.isModalVisible = false;
  }

  toggleModal(activeMetric: Metric) {
    this.isModalVisible = true;
    this.activeMetric = activeMetric;
    this.metricsService.setActiveMetric(activeMetric);
  }

  isValidDescription(key: any): boolean {
    return Boolean(
      this.descriptionMap[key as keyof typeof this.descriptionMap]
    );
  }

  // Handles cleanup when the service is destroyed
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
