import { Injectable } from '@angular/core';
import { Observable, Subject, Subscriber } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TableauService {
  private readonly exportSubject: Subject<string> = new Subject<string>();

  get export(): Observable<string> {
    return this.exportSubject.asObservable();
  }

  changeExport(value: string): Observable<string> {
    const obs = new Observable((subscriber: Subscriber<string>) => {
      subscriber.next(value);
      subscriber.complete();
    });

    obs.subscribe({
      next: () => this.exportSubject.next(value)
    });

    return obs;
  }
}
