import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ApiService,
  IChannels,
  ISegments,
  ITactics,
  IVCampaign
} from '@libs/apis';
import { BehaviorSubject } from 'rxjs';
import { SharedDataService } from '../../services/shared-data.service';
import {
  CampaignDimension,
  TaxonomyUiConstants
} from '../../taxonomy-ui/taxonomy-ui.constants';

@Component({
  selector: 'lib-taxonomy-campaign-mapping-update-popover',
  templateUrl: './campaign-mapping-update-popover.component.html',
  styleUrls: ['./campaign-mapping-update-popover.component.scss'],
  animations: TaxonomyUiConstants.updateTacticOverlayTriggerAnimations
})
export class CampaignMappingUpdatePopoverComponent implements OnInit {
  private static readonly geoTestMessage = `(Geo Test in progress)`;

  @Input()
  totalCampaigns = 0;

  @Input()
  selectedCampaignType = '';

  @Input()
  filter: Record<string, string> = {};

  @Input()
  selectedDataSource = '';

  @Input()
  selectedCampaignCount = 0;

  @Input()
  selectedDimensionOptions: Record<CampaignDimension, string[]> = {
    [CampaignDimension.channel]: [],
    [CampaignDimension.tactic]: [],
    [CampaignDimension.segment]: []
  };

  @Input()
  public clientId = 0;

  @Input()
  public brandId = 0;

  @Output() cancelDimensionMapping = new EventEmitter();

  @Output() updateDimensionMapping = new EventEmitter();

  public loading = new BehaviorSubject<boolean>(false);
  public tactics: ITactics[] = [];
  public channels: IChannels[] = [];
  public segments: ISegments[] = [];
  public campaigns: IVCampaign[] = [];
  public geoTestStatusByDimension: Record<string, boolean> = {};
  public isGeoTestRunning = false;
  public campaignDimension = CampaignDimension;
  public updateDimensionValue: Record<CampaignDimension, string> = {
    [CampaignDimension.channel]: '',
    [CampaignDimension.tactic]: '',
    [CampaignDimension.segment]: ''
  };

  constructor(
    private readonly apiService: ApiService,
    private readonly sharedDataService: SharedDataService
  ) {}

  ngOnInit(): void {
    this.fetchDimensionValues();
    this.getGeoTestStatus();
  }

  private fetchDimensionValues() {
    this.loading.next(true);
    const selectedDataSource = encodeURIComponent(this.selectedDataSource);
    Promise.all([
      this.apiService.taxonomyApisOperations.getTacticsByDatasource(
        this.clientId,
        this.brandId,
        selectedDataSource
      ),
      this.apiService.taxonomyApisOperations.getSegments(
        this.clientId,
        this.brandId,
        selectedDataSource
      ),
      this.apiService.taxonomyApisOperations.getChannels(
        this.clientId,
        this.brandId,
        selectedDataSource
      )
    ])
      .then(([tactics, segments, channels]) => {
        this.channels = channels;
        this.tactics = tactics;
        this.segments = segments;
        this.updateTacticsWithGeoTestStatus();
        this.loading.next(false);
      })
      .catch((error) => {
        console.error('Error: ', error.message);
        this.loading.next(false);
      });
  }

  abortTacticUpdate() {
    this.cancelDimensionMapping.emit();
  }

  handleGeoTestMessageVisibility() {
    this.isGeoTestRunning =
      Object.values(this.updateDimensionValue).findIndex((dimensionValue) =>
        dimensionValue.includes(
          CampaignMappingUpdatePopoverComponent.geoTestMessage
        )
      ) >= 0;
  }

  updateDimension() {
    this.removeGeoTestMessageIfPresent();
    this.updateDimensionMapping.emit(this.updateDimensionValue);
  }

  confirmToResetMapping() {
    console.warn('confirmToResetMapping');
  }

  private getGeoTestStatus() {
    this.sharedDataService
      .getGeoTestStatusByDimension()
      .subscribe((geoTestStatus) => {
        this.geoTestStatusByDimension = geoTestStatus;
      });
  }

  private updateTacticsWithGeoTestStatus() {
    this.tactics.forEach((tacticGroup) => {
      tacticGroup.items.forEach((tactic) => {
        if (this.geoTestStatusByDimension[tactic.tactic || '']) {
          tactic.tactic = `${tactic.tactic} ${CampaignMappingUpdatePopoverComponent.geoTestMessage}`;
        }
      });
    });
  }

  private removeGeoTestMessageIfPresent() {
    Object.values(CampaignDimension).forEach((dimension) => {
      if (
        this.updateDimensionValue[dimension]?.includes(
          CampaignMappingUpdatePopoverComponent.geoTestMessage
        )
      ) {
        this.updateDimensionValue[dimension] = this.updateDimensionValue[
          dimension
        ]
          .replace(CampaignMappingUpdatePopoverComponent.geoTestMessage, '')
          .trim();
      }
    });
  }

  setValue(dimension: CampaignDimension, event: { value: string }) {
    this.updateDimensionValue[dimension] = event.value || '';
  }
}
