import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IReportingDashboardManagerApiOperation } from '../models/IReportingDashboardManagerApiOperation';
import {
  Utility,
  Status,
  IProductConfigInstanceUpdateRequest,
  IProductConfigInstanceCreateRequest
} from '@libs/apis';
import { ITableauGenerator } from '../models/ITableauGenerator';
import { ITableauGeneratorResponse } from '../models/ITableauGeneratorResponse';
import {
  IDashboard,
  IDashboardInstance,
  IDashboardInstanceCreateRequest,
  IDashboardInstanceUpdateRequest,
  IProduct
} from '../models/IDashboard';
import { ILookups } from '../models/ILookups';
import {
  IProductConfigDashboard,
  IProductConfigDashboardInstance
} from '@libs/apis';

const promiseFrom = lastValueFrom;

export class ReportingDashboardManagerApis
  implements IReportingDashboardManagerApiOperation
{
  private constructor(
    private readonly httpClient: HttpClient,
    private readonly apiV1: string,
    private readonly apiV2: string
  ) {}

  public static reportingDashboardManagerApiOperation(
    httpClient: HttpClient,
    apiV1: string,
    apiV2: string
  ): IReportingDashboardManagerApiOperation {
    return new ReportingDashboardManagerApis(httpClient, apiV1, apiV2);
  }

  public async getDashboardList(clientId: number): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/xcv4/dashboard`,
      queryParameters: { clientId }
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async getDashboardDetails(id: number): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/xcv4/dashboard/:id`,
      pathVariables: { id }
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async getCanonicalsList(): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/xcv4/template-details`
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async createDashboard(data: any): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/xcv4/dashboard`
    });
    return promiseFrom(this.httpClient.post(url, data));
  }

  public async updateDashboardConfigs(data: any): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/xcv4/dashboard`
    });
    return promiseFrom(this.httpClient.put(url, data));
  }

  public async deleteDashboardConfig(
    aliasId: number,
    masterId: number
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/xcv4/dashboard/:masterId/:aliasId`,
      pathVariables: { masterId, aliasId }
    });
    return promiseFrom(this.httpClient.delete(url));
  }

  public async generateTableauDashboard(
    product: string,
    clientId: number,
    brandId: number,
    data: ITableauGenerator
  ): Promise<ITableauGeneratorResponse> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/tableau-generator/:product`,
      pathVariables: { product },
      queryParameters: { clientId, brandId }
    });
    return promiseFrom(
      this.httpClient.post<ITableauGeneratorResponse>(url, data)
    );
  }

  public async getProducts(): Promise<IProduct[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/products`
    });
    return promiseFrom(this.httpClient.get<IProduct[]>(url));
  }

  public async getDashboards(productId: string): Promise<IDashboard[]> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/dataviz/dashboards`,
      queryParameters: { productId }
    });
    return promiseFrom(this.httpClient.get<IDashboard[]>(url));
  }

  public async getDashboardInstances(
    clientId: number,
    brandId: number,
    statuses: Status[]
  ): Promise<IDashboardInstance[]> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/dataviz/dashboard-instances`,
      queryParameters: {
        clientId,
        brandId,
        statuses: statuses.join(',')
      }
    });
    return promiseFrom(this.httpClient.get<IDashboardInstance[]>(url));
  }

  public async createDashboards(
    clientId: number,
    brandId: number,
    dashboards: IDashboardInstanceCreateRequest
  ): Promise<ILookups[]> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/dataviz/dashboards`,
      queryParameters: { clientId, brandId }
    });
    return promiseFrom(this.httpClient.post<ILookups[]>(url, dashboards));
  }

  public async updateDashboards(
    dashboards: IDashboardInstanceUpdateRequest
  ): Promise<ILookups[]> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/dataviz/dashboards`
    });
    return promiseFrom(this.httpClient.put<ILookups[]>(url, dashboards));
  }

  public async getProductConfigDashboards(): Promise<
    IProductConfigDashboard[]
  > {
    const url = Utility.buildURL({
      url: `${this.apiV1}/product-config`
    });
    return promiseFrom(this.httpClient.get<IProductConfigDashboard[]>(url));
  }

  public async getProductConfigDashboardById(
    id: number
  ): Promise<IProductConfigDashboard> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/product-config/:id`,
      pathVariables: { id }
    });
    return promiseFrom(this.httpClient.get<IProductConfigDashboard>(url));
  }

  public async updateProductConfigDashboardById(
    id: number,
    body: any
  ): Promise<IProductConfigDashboard> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/product-config/:id`,
      pathVariables: { id }
    });
    return promiseFrom(this.httpClient.put<IProductConfigDashboard>(url, body));
  }

  public async createProductConfigDashboard(
    body: any
  ): Promise<IProductConfigDashboard> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/product-config/`
    });
    return promiseFrom(
      this.httpClient.post<IProductConfigDashboard>(url, body)
    );
  }

  public async getProductConfigDashboardInstances(
    clientId: number,
    brandId: number,
    configDashboardIds: number[]
  ): Promise<IProductConfigDashboardInstance[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/product-config-dashboards`,
      queryParameters: {
        clientId,
        brandId,
        configDashboardId: JSON.stringify(configDashboardIds)
      }
    });
    return promiseFrom(
      this.httpClient.get<IProductConfigDashboardInstance[]>(url)
    );
  }

  public async updateProductConfigDashboardInstances(
    dashboardUpdateRequest: IProductConfigInstanceUpdateRequest
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/product-config-dashboards`
    });
    return promiseFrom(this.httpClient.put(url, dashboardUpdateRequest));
  }

  public async createProductConfigInstances(
    dashboardCreateRequest: IProductConfigInstanceCreateRequest
  ): Promise<IProductConfigDashboardInstance[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/product-config-dashboards`
    });
    return promiseFrom(
      this.httpClient.post<IProductConfigDashboardInstance[]>(
        url,
        dashboardCreateRequest
      )
    );
  }
}
