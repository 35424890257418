<portal-title-bar
  [title]="title"
  [filterControls]="filterControls"
  (filterChanged)="setGlobalFilterValues($event)"
  [displayControls]="displayControls"
  [chartTableToggle]="chartTableToggle"
  [chartDataPoints]="chartDataPoints"
  [fieldDefinitions]="fieldDefinitions"
  [showExportIcon]="true"
  [overrideExport]="overrideExport"
  [exportMenu]="exportChartMenu"
  (chartTableToggleChanged)="toggleChartTable($event)"
  (exportData)="overrideExportChartHandler()"
  class="CHART-TITLE-BAR chartTitlebarAppendTo"></portal-title-bar>

<div class="row {{ productId }}">
  <div class="col-md-12">
    <portal-accuracy-alert
      *ngIf="chartAccuracyForUI?.displayAccuracy"
      [type]="'warning'"
      [message]="chartAccuracyForUI?.message">
    </portal-accuracy-alert>
    <div class="elements mb-1 gap-3">
      <label
        *ngFor="let metricControl of metricControls; index as i"
        class="label-format me-1 me-md-2"
        [ngClass]="
          metricControl.control.displayOrder <= 10
            ? 'element-left'
            : 'element-right'
        ">
        <em
          *ngIf="metricControls.length > 1"
          class="pi pill"
          [ngStyle]="{ background: chartColors[metricControl.key] }"></em>
        {{ metricControl.control.label }}
        <p-dropdown
          [options]="axisSeriesOptions"
          [(ngModel)]="selectedSeriesValues[metricControl.key]"
          (onChange)="seriesSelectionChanged(true)"
          optionLabel="label"
          class="d-block"
          [appendTo]="chartTitlebarAppendTo"
          [panelStyleClass]="dashboardLiteralId"
          [disabled]="chartDataLoading">
          <ng-template
            pTemplate="selectedItem"
            *ngIf="isMeasuredBenchmarkLandingPage(dashboardLiteralId)">
            <div>
              {{ selectedSeriesValues[metricControl.key].specialLabel.label }}
              <span class="highlighted-label">
                {{
                  selectedSeriesValues[metricControl.key].specialLabel
                    .highlightedValue
                }}
              </span>
            </div>
          </ng-template>
          <ng-template
            let-option
            pTemplate="item"
            *ngIf="isMeasuredBenchmarkLandingPage(dashboardLiteralId)">
            <div>
              {{ option.specialLabel.label }}
              <span class="highlighted-label">
                {{ option.specialLabel.highlightedValue }}
              </span>
            </div>
          </ng-template>
        </p-dropdown>
      </label>

      <label
        *ngIf="metricControls.length > 1"
        class="label-format ms-2 show-multiple-axes element-left mt-auto mb-2">
        <p-checkbox
          name="showMultipleAxis"
          [(ngModel)]="showMultipleAxes"
          [binary]="true"
          (click)="toggleMultipleAxes()"
          [disabled]="disableMultipleAxisCheckbox"
          label="Show Multiple Axes"></p-checkbox>
      </label>

      <div
        *ngFor="let plotlineControl of plotlineControls"
        class="element-right">
        <label class="label-format show-filter"
          >{{ plotlineControl.control.label }}
          <p-inputNumber
            [(ngModel)]="
              currentPlotlineControlValues[plotlineControl.key.toString()].value
            "
            mode="decimal"
            (onBlur)="handleRoasChange($event, plotlineControl.key)"
            [minFractionDigits]="2"
            [maxFractionDigits]="5"
            step=".1"
            inputId="rinput"
            [inputStyle]="{ width: '8rem' }"
            class="d-block"
            [disabled]="chartDataLoading"></p-inputNumber>
        </label>
      </div>
      <div
        *ngFor="let childField of childrenControls"
        class="element"
        [ngClass]="'element-right'">
        <label class="ms-2 label-format"
          >{{ childField.label }}
          <p-multiSelect
            *ngIf="childField.inputType === 'multiselect'"
            (onPanelHide)="updateChartSeriesControls(childField)"
            [(ngModel)]="childField.value"
            [options]="childField.options"
            [selectionLimit]="7"
            [style]="{ width: '140px' }"
            [panelStyle]="{ maxWidth: '287px' }"
            defaultLabel="Select"
            selectedItemsLabel="{0} selected"
            maxSelectedLabels="1"
            class="d-block">
            <ng-template let-option pTemplate="item">
              <span
                tooltipPosition="right"
                [pTooltip]="option.label.length >= 27 ? option.label : ''"
                tooltipStyleClass="saas-tooltip">
                {{ option.label | ellipsis: 27 }}
              </span>
            </ng-template>
          </p-multiSelect>
        </label>
      </div>
      <div
        *ngFor="let field of filterFields"
        class="element d-flex flex-column"
        [ngClass]="field.position <= 10 ? 'element-left' : 'element-right'">
        <label
          *ngIf="field.inputType === 'select'"
          for="id-{{ field.name }}"
          class="label-format show-filter"
          >{{ field.label }}
        </label>
        <p-dropdown
          inputId="id-{{ field.name }}"
          *ngIf="field.inputType === 'select'"
          [ngClass]="field.name"
          [panelStyleClass]="field.name"
          [options]="field.options"
          [(ngModel)]="field.value"
          (onChange)="updateChartSeriesControls()"
          [disabled]="chartDataLoading"></p-dropdown>
      </div>
    </div>
    <portal-empty-state [showMessage]="showEmptyState">
      <div
        [ngClass]="[
          'col-md-12',
          'chart-widget',
          hasCompare() ? 'chart-compare' : '',
        ]"
        *ngIf="chart"
        [chart]="chart"
        style="margin-top: 0.3rem"></div>
    </portal-empty-state>
  </div>
</div>

<div *ngIf="tableItem && tableData.length" class="row chart-data-table">
  <portal-data-grid-group
    [widgetLayout]="tableItem"
    [fieldDefinitions]="fieldDefinitions"
    [data]="tableData"
    [dataGridConfig]="dataGridConfig">
  </portal-data-grid-group>
</div>
