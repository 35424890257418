export interface IAvatarConfig {
  initials: string | null;
  email?: string;
  imageUrl?: string;
  size?: AvatarSize;
  shape?: AvatarShape;
  bg?: string;
  color?: string;
  icon?: string;
  styleClass?: string;
  iconStyleClass?: string;
  darkMode?: boolean;
}

export enum AvatarSize {
  large = 'large',
  default = 'default',
  small = 'small',
  xsmall = 'xsmall',
  xlarge = 'xlarge'
}

export enum AvatarShape {
  circle = 'circle',
  square = 'square'
}
