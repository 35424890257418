import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, take } from 'rxjs';
import { SelectionService } from '@portal/app/shared/services/selection.service';
import {
  DashboardError,
  DashboardService
} from '@portal/app/shared/services/dashboard.service';
import { DisplayDashboard } from '@portal/app/shared/types';
import { slugify } from '@portal/app/shared/helpers/helpers';
import { ViewStore } from '@portal/app/shared/state/view.store';
import { TableauService } from '@portal/app/shared/services/tableau.service';

export enum TableauExportType {
  'pdf' = 'export-pdf',
  'excel' = 'export-cross-tab',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  'csv' = 'export-cross-tab',
  'image' = 'image',
  'powerpoint' = 'export-powerpoint'
}

@Component({
  selector: 'portal-product',
  templateUrl: './tableau.component.html',
  styleUrls: ['./tableau.component.scss']
})
export class TableauComponent implements OnInit, OnDestroy {
  private static readonly mdwReportName = 'mdw-reports';
  public selectedBrandLabel = '';
  public sluggedBrandLabel = '';
  public dashboard: DisplayDashboard | undefined = undefined;
  public showExportButton = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private tableauViz: any | undefined;
  private readonly subs: Subscription = new Subscription();

  constructor(
    private readonly elRef: ElementRef,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly selectionService: SelectionService,
    private readonly dashboardService: DashboardService,
    private readonly ref: ChangeDetectorRef,
    public tableauService: TableauService,
    public viewStore: ViewStore
  ) {
    this.tableauViz = undefined;
  }

  ngOnInit(): void {
    // You can have access to the current client, brand, user, dashboards,
    //  as well the current active dashboard (based on the URL)
    this.selectedBrandLabel = this.selectionService.selectionLabel;
    this.sluggedBrandLabel = slugify(this.selectedBrandLabel);
    this.subs.add(
      this.route.params.subscribe((params) => {
        this.dashboardService
          .getDashboardById(params.productId)
          .pipe(take(1))
          .subscribe({
            next: (dashboard) => {
              this.showExportButton = false;
              this.dashboard = dashboard;
              // Fire off change to pick up dashboard and ensure the export button is not visible
              this.ref.detectChanges();
              this.initViz();
              this.subs.add(
                this.viewStore.changeTitle(this.dashboard.label).subscribe({
                  error: (err) => console.error(err)
                })
              );
            },
            error: (err: DashboardError) => {
              if (err.dashboards.length > 0) {
                const dashboard = this.dashboardService.getDashboardByLiteralid(
                  err.dashboards,
                  params.literalId
                );
                if (dashboard && dashboard.dashboardId <= 2) {
                  this.router
                    .navigate(
                      [
                        `/a/${params.brandSlug}/products/${dashboard.routerLinkPart}`
                      ],
                      {
                        replaceUrl: true
                      }
                    )
                    .then();
                }
              } else {
                console.error(err);
              }
            }
          });
      })
    );
    this.subs.add(
      this.tableauService.export.subscribe({
        next: (value) => this.handleExport(value)
      })
    );
  }

  initViz(): void {
    this.tableauViz = this.elRef.nativeElement.querySelector('#tableauViz');
  }

  hideTabs(literalId: string, dashboardId: number): boolean {
    // Custom Catalog dashboard id's
    if (
      [317, 319, 668726, 668727, 1656, 1081, 861564, 861563, 757591].includes(
        dashboardId
      )
    ) {
      return false;
    }
    return literalId !== TableauComponent.mdwReportName;
  }

  handleExport(value: string): void {
    if (!this.tableauViz) {
      console.error('Tableau is not initialized');
      return;
    }

    switch (value) {
      case 'pdf':
      case 'excel':
      case 'csv':
      case 'powerpoint':
        this.tableauViz.displayDialogAsync(TableauExportType[value]);
        break;
      case 'image':
        this.tableauViz.exportImageAsync();
        break;
      default:
        console.error(`the selected type ${value} is not handled!`);
        break;
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();

    if (this.tableauViz && this.tableauViz.dispose) {
      this.tableauViz.dispose();
    }
  }
}
