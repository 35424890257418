import { Injectable } from '@angular/core';
import { utils, writeFile, WritingOptions } from 'xlsx';
import {
  ExportTableType,
  TableResponse,
  FieldDefinitions,
  DataResponse,
  ElementTyping,
  PivotTableResponse,
  ITableField,
  IExpandableTableData,
  ChartResponse,
  FieldDefinition,
  DataRowData
} from '@portal/app/shared/types';
import { FormatterService } from '@portal/app/shared/services/formatter.service';
import { cloneDeep, filter, keys } from 'lodash-es';
import { ExportCompareService } from '@portal/app/shared/services/export-compare.service';
import { DataGridService } from '@portal/app/datagrid/services/data-grid.service';

@Injectable({
  providedIn: 'root'
})
export class ExportTableService {
  constructor(
    private readonly formatterService: FormatterService,
    private readonly exportCompareService: ExportCompareService,
    private readonly dataGridService: DataGridService
  ) {}

  public export(
    name: string,
    format: ExportTableType,
    data: DataResponse[]
  ): void {
    const worksheet = utils.json_to_sheet(data, { skipHeader: true });
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet);
    const fileName = `${name}.${format}`;

    if (format === 'xlsx') {
      writeFile(workbook, fileName);
    } else if (format === 'csv') {
      writeFile(workbook, fileName, {
        bookType: format,
        forceQuotes: true
      } as WritingOptions);
    } else if (format === 'tsv') {
      writeFile(workbook, fileName, {
        bookType: 'csv',
        forceQuotes: true,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        FS: '\t'
      } as WritingOptions);
    }
  }

  public exportTable(
    name: string,
    format: ExportTableType,
    fieldDefinitions: FieldDefinitions,
    table: TableResponse | PivotTableResponse,
    pivotRows = false,
    isPivotTable = false,
    showFooter = true,
    groupByFieldName: string | undefined = undefined
  ): void {
    const data = this.prepareData(
      fieldDefinitions,
      table,
      isPivotTable,
      showFooter,
      groupByFieldName,
      pivotRows
    );

    this.export(name, format, data);
  }

  public exportChart(
    name: string,
    format: ExportTableType,
    fieldDefinitions: FieldDefinitions,
    chart: ChartResponse
  ): void {
    const data = this.prepareChartData(fieldDefinitions, chart);
    this.export(name, format, data);
  }

  public prepareData(
    originFieldDefinitions: FieldDefinitions,
    originTable: TableResponse | PivotTableResponse,
    isPivotTable = false,
    showFooter = true,
    groupByFieldName: string | undefined = undefined,
    pivotRows = false
  ): DataResponse[] {
    const data: DataResponse[] = [];

    const fieldDefinitions = cloneDeep(originFieldDefinitions);
    const table = cloneDeep(originTable);
    table.fields =
      table.exportFields && table.exportFields.length > 0
        ? table.exportFields
        : table.fields;

    this.exportCompareService.prepareDataForCompare(fieldDefinitions, table);
    this.exportCompareService.prepareDataForDrivers(fieldDefinitions, table);

    const headerRow: DataResponse = this.getHeaderRows(
      table,
      isPivotTable,
      fieldDefinitions
    );
    data.push(headerRow);

    if (keys(table.groupByRowFooter).length) {
      const groupedData = this.prepareDataForGroupedRow(
        fieldDefinitions,
        table,
        headerRow,
        groupByFieldName
      );
      data.push(...groupedData);
    } else {
      table.data.forEach((row) => {
        const dataRow: DataResponse = {};
        table.fields.forEach((f) => {
          if (f.type !== ElementTyping.pivotDimension) {
            dataRow[f.dashboardFieldId] =
              this.formatterService.formatPartialValue(
                fieldDefinitions[f.dashboardFieldId] as FieldDefinition,
                row[f.dashboardFieldId] as string | number
              );
            // in case of pivot rows each row has its own field definition
          } else if (pivotRows && row.fieldToApply) {
            dataRow[f.dashboardFieldId] =
              this.formatterService.formatPartialValue(
                fieldDefinitions[row.fieldToApply] as FieldDefinition,
                row[f.dashboardFieldId] as string | number
              );
            // in case of pivot table each row and column have one common field definition
          } else if (isPivotTable) {
            dataRow[f.dashboardFieldId] =
              this.formatterService.formatPartialValue(
                fieldDefinitions.PIVOT_DIMENSION as FieldDefinition,
                row[f.dashboardFieldId] as string | number
              );
          }
        });
        data.push(dataRow);
      });
    }
    if ('footer' in table && showFooter) {
      const footerData = this.getFooterData(table, fieldDefinitions);
      data.push(...footerData);
    }
    return data;
  }

  public prepareChartData(
    originFieldDefinitions: FieldDefinitions,
    originChart: ChartResponse,
    isPivotTable = false
  ): DataResponse[] {
    const data: DataResponse[] = [];

    const fieldDefinitions = cloneDeep(originFieldDefinitions);
    const chart = cloneDeep(originChart);

    const headerRow: DataResponse = this.getHeaderRows(
      chart,
      isPivotTable,
      fieldDefinitions
    );
    data.push(headerRow);

    chart.data.forEach((row) => {
      const dataRow: DataResponse = {};
      chart.fields.forEach((f) => {
        dataRow[f.dashboardFieldId] = this.formatterService.formatPartialValue(
          fieldDefinitions[f.dashboardFieldId] as FieldDefinition,
          row[f.dashboardFieldId] as string | number
        );
      });
      data.push(dataRow);
    });
    return data;
  }

  private prepareDataForGroupedRow(
    fieldDefinitions: FieldDefinitions,
    table: TableResponse | PivotTableResponse,
    headerRow: DataResponse,
    groupByFieldName: string | undefined = undefined
  ) {
    const data: DataResponse[] = [];
    keys(table.groupByRowFooter).forEach((key) => {
      const groupTitleRow = { [keys(headerRow)[0] as string | number]: key };
      const newData = filter(table.data, (rowObj) => {
        return rowObj[groupByFieldName || ''] === key;
      });
      newData.push({
        [keys(headerRow)[0] as string | number]: 'Total',
        ...table.groupByRowFooter[key]
      });
      [groupTitleRow, ...newData].forEach((row) => {
        const dataRow: DataResponse = {};
        table.fields.forEach((f) => {
          if (f.type !== ElementTyping.pivotDimension) {
            dataRow[f.dashboardFieldId] =
              this.formatterService.formatPartialValue(
                fieldDefinitions[f.dashboardFieldId] as FieldDefinition,
                row[f.dashboardFieldId] as string | number
              );
          } else {
            dataRow[f.dashboardFieldId] =
              this.formatterService.formatPartialValue(
                fieldDefinitions.PIVOT_DIMENSION as FieldDefinition,
                row[f.dashboardFieldId] as string | number
              );
          }
        });
        data.push(dataRow);
      });
    });
    return data;
  }

  private getHeaderRows(
    table: TableResponse | PivotTableResponse | ChartResponse,
    isPivotTable: boolean,
    fieldDefinitions: FieldDefinitions
  ): DataResponse {
    const headerRow: DataResponse = {};
    table.fields.forEach((f) => {
      if (isPivotTable) {
        headerRow[f.dashboardFieldId] = f.label;
      } else {
        headerRow[f.dashboardFieldId] = (
          fieldDefinitions[f.dashboardFieldId] as FieldDefinition
        ).label;
      }
    });
    return headerRow;
  }

  private getFooterData(
    table: TableResponse | PivotTableResponse,
    fieldDefinitions: FieldDefinitions
  ): DataResponse[] {
    const data: DataResponse[] = [];
    table.footer.forEach((row) => {
      const dataRow: DataResponse = {};
      table.fields.forEach((f, index) => {
        if (f.type === ElementTyping.pivotDimension) {
          dataRow[f.dashboardFieldId] =
            this.formatterService.formatPartialValue(
              fieldDefinitions.PIVOT_DIMENSION as FieldDefinition,
              row[f.dashboardFieldId] as string | number
            );
        } else {
          const footerVal = this.formatterService.formatPartialValue(
            fieldDefinitions[f.dashboardFieldId] as FieldDefinition,
            row[f.dashboardFieldId] as string | number
          );
          dataRow[f.dashboardFieldId] = (
            index === 0
              ? row[f.dashboardFieldId]
                ? footerVal
                : row.footerTotalDisplayVal
              : this.formatterService.formatPartialValue(
                  fieldDefinitions[f.dashboardFieldId] as FieldDefinition,
                  row[f.dashboardFieldId] as string | number
                )
          ) as DataRowData;
        }
      });
      data.push(dataRow);
    });
    return data;
  }

  public exportExpandedTable(
    name: string,
    format: ExportTableType,
    tableData: IExpandableTableData[],
    columns: ITableField[]
  ): void {
    const data: DataResponse[] = [];
    const expansionData: DataResponse[] = [];
    let expansionTableColumns: ITableField[] = [];

    const headerRow: DataResponse = this.getExpandedTableHeaderRows(columns);
    data.push(headerRow);

    tableData.forEach((dObj) => {
      dObj.expandableData.forEach((expandRow) => {
        const row: DataResponse = {};
        columns.forEach((col) => {
          row[col.field] = expandRow[col.field] as DataRowData;
        });
        data.push(row);
      });
      const expansionTableConfig = filter(
        this.dataGridService.dataGridContextStore,
        {
          tableName: tableData[0]?.expansionData?.name
        }
      )[0];
      if (expansionTableConfig) {
        if (expansionTableConfig) {
          expansionTableColumns = expansionTableConfig.columns;
          const expansionHeaderRow: DataResponse =
            this.getExpandedTableHeaderRows(expansionTableConfig.columns);
          expansionData.push(expansionHeaderRow);

          (expansionTableConfig.data as DataResponse[]).forEach(
            (expansionRowData) => {
              const row: DataResponse = {};
              expansionTableConfig.columns.forEach((col) => {
                row[col.field] = expansionRowData[col.field] as DataRowData;
              });
              expansionData.push(row);
            }
          );
        }
      }
    });

    const worksheet = utils.json_to_sheet(data, {
      header: columns.map(({ field }) => field),
      skipHeader: true
    });
    utils.sheet_add_json(worksheet, expansionData, {
      header: expansionTableColumns.map(({ field }) => field),
      origin: `B${data.length + 1 + 1}`,
      skipHeader: true
    });

    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet);
    const fileName = `${name}.${format}`;

    if (format === 'xlsx') {
      writeFile(workbook, fileName);
    } else if (format === 'csv') {
      writeFile(workbook, fileName, {
        bookType: format,
        forceQuotes: true
      } as WritingOptions);
    } else if (format === 'tsv') {
      writeFile(workbook, fileName, {
        bookType: 'csv',
        forceQuotes: true,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        FS: '\t'
      } as WritingOptions);
    }
  }

  private getExpandedTableHeaderRows(columns: ITableField[]): DataResponse {
    const headerRow: DataResponse = {};
    columns.forEach((col) => {
      headerRow[col.field] = col.label;
    });
    return headerRow;
  }
}
