import { Injectable } from '@angular/core';
import { SelectItem } from 'primeng/api';
import {
  allTimeForAlerts,
  CurrentDropdownOption,
  custom,
  DateTimeService,
  DayJsDateFormat,
  latestFourteenDays,
  latestSevenDays,
  latestThirtyDays,
  todayDate,
  yesterday
} from '@portal/app/shared/services/date-time.service';
import { FilterSetUtil } from '@portal/app/dashboard/filter-set/filter-set-util';
import { DateRange } from '@portal/app/shared/types';

@Injectable({ providedIn: 'root' })
export class FilterBarService {
  private timePeriodOptions: CurrentDropdownOption[] = [];

  constructor(private dateTimeService: DateTimeService) {
    this.initializeTimePeriodOptions();
  }

  dateOptions = {
    todayDate: {
      name: todayDate,
      action: this.dateTimeService.calculateDaysFromToday()
    },
    yesterday: {
      name: yesterday,
      action: this.dateTimeService.calculateDaysFromYesterday()
    },
    latestSevenDays: {
      name: latestSevenDays,
      action: this.dateTimeService.calculatePrevious7DaysIncludingToday()
    },
    latestFourteenDays: {
      name: latestFourteenDays,
      action: this.dateTimeService.calculatePrevious14DaysIncludingToday()
    },
    latestThirtyDays: {
      name: latestThirtyDays,
      action: this.dateTimeService.calculatePrevious30DaysIncludingToday()
    },
    allTime: {
      name: allTimeForAlerts,
      action: this.dateTimeService.calculateAllTimeDateRangeForAlerts()
    },
    custom: { name: custom, action: null }
  };

  initializeTimePeriodOptions(): void {
    this.timePeriodOptions = Object.values(this.dateOptions);
  }

  getServiceOptions(services: string[]): SelectItem[] {
    return services.map((service) => ({
      label: service.charAt(0).toUpperCase() + service.slice(1).toLowerCase(),
      value: service
    }));
  }

  getTimePeriodOptions(): CurrentDropdownOption[] {
    return this.timePeriodOptions;
  }

  getDefaultTimePeriod(): DateRange {
    const allTimeStartDate = this.dateOptions.allTime.action;

    const timePeriod = [
      this.dateTimeService.format(
        allTimeStartDate.startDate,
        DayJsDateFormat.fullDate
      ),
      this.dateTimeService.format(
        allTimeStartDate.endDate,
        DayJsDateFormat.fullDate
      )
    ];

    return FilterSetUtil.setAsDateRange(timePeriod);
  }
}
