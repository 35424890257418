import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Message } from 'primeng/api';
import { ViewService } from '@portal/app/shared/services/view.service';
import { ContextStore } from '@portal/app/shared/state/context.store';
import { Filter } from '@portal/app/shared/types';

import { CompareBannerService } from '@portal/app/shared/components/compare-mode-banner/compare-mode-banner.service';
@Component({
  selector: 'portal-compare-mode-banner',
  templateUrl: './compare-mode-banner.component.html',
  styleUrls: ['./compare-mode-banner.component.scss']
})
export class CompareModeBannerComponent implements OnInit, OnDestroy {
  public messages: Message[] = [
    {
      severity: 'warn',
      detail:
        'One or more of the tactics you are comparing shows no spending within the selected timeframe.'
    }
  ];

  public showBanner = false;

  private destroy$ = new Subject<void>();

  constructor(
    private readonly viewService: ViewService,
    private readonly compareBannerService: CompareBannerService,
    private readonly contextStore: ContextStore
  ) {}

  ngOnInit() {
    this.contextStore.filterContext
      .pipe(takeUntil(this.destroy$))
      .subscribe((filters: Filter[]) => {
        const compareFilter = filters.find((f) => f.literalId === 'compare');
        if (compareFilter) {
          this.setVisibility(compareFilter.value as boolean);
        }
      });
  }

  setVisibility(isCompareMode: boolean) {
    this.compareBannerService.spendCountByTactic
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (count) => {
          this.showBanner = count > 0 && isCompareMode;
        }
      });
  }

  handleClose() {
    this.showBanner = false;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
