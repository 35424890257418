<div class="d-flex gap-2 align-items-center">
  <img [src]="iconPath" alt="calendar icon" class="img-fluid" />
  <portal-data-cell
    [style.font-size]="'0.875rem'"
    [dashboardId]="key"
    [data]="
      rowData[col.dashboardFieldId]
        | formatValue: fieldDefinitions[col.dashboardFieldId]
    "></portal-data-cell>
</div>
