/**
 * MDonutChartComponent: A component that renders a donut chart.
 *
 * @remarks
 * This component makes use of the Highcharts library to render a donut chart with given data.
 *
 * @packageDocumentation
 */

/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HC_solidGauge from 'highcharts/modules/solid-gauge';

// Types
import { DonutChartData } from './m-donut-chart.component.types';

HighchartsMore(Highcharts);
HC_solidGauge(Highcharts);

@Component({
  selector: 'm-donut-chart',
  templateUrl: './m-donut-chart.component.html'
})
export class MDonutChartComponent implements OnInit {
  @Input() data: DonutChartData[] = [];
  @Input() height: number | string = 300; // Default height
  @Input() innerContent = '';
  @Input() tooltipContent = '';

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};

  /**
   * Initializes the donut chart options on component initiation.
   */
  ngOnInit() {
    this.chartOptions = {
      chart: {
        type: 'pie',
        backgroundColor: 'transparent',
        height: this.height,
        style: {
          overflow: 'visible'
        },
        reflow: true
      },
      credits: {
        enabled: false
      },
      exporting: { enabled: false },
      title: {
        align: 'center',
        text: this.innerContent,
        useHTML: true,
        verticalAlign: 'middle'
      },
      tooltip: {
        // followPointer: true,
        formatter() {
          const point: any = this.point;
          if (point && point.tooltipHtml) {
            return `<div class="tooltip-body">${point.tooltipHtml}</div>`;
          }
          return '';
        },
        useHTML: true,
        positioner(boxWidth, boxHeight) {
          const chart = this.chart;
          // Position the tooltip at the top center of the chart
          const x = chart.plotLeft + chart.plotWidth * 0.5 - boxWidth * 0.5;
          const y = chart.plotTop - boxHeight - 10; // 10 pixels above the chart
          return { x, y };
        }
      },
      pane: {
        background: [
          {
            outerRadius: '100%',
            innerRadius: '80%',
            borderWidth: 0
          }
        ],
        endAngle: 360,
        size: '190px',
        startAngle: 0
      },
      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: []
      },
      plotOptions: {
        pie: {
          crisp: true,
          dataLabels: {
            enabled: false
          },
          linecap: 'round',
          stickyTracking: false,
          innerSize: '80%'
        }
      },
      series: [
        {
          type: 'pie',
          name: 'Percent',
          data: this.data,
          dataLabels: {
            format:
              '<div style="text-align:center"><span style="font-size:25px">{y}</span><br/><span style="font-size:12px;opacity:0.4">%</span></div>'
          }
        }
      ]
    };
  }
}
