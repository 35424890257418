//import {VendorHandler} from "./vendor-handler";
import { StorageService } from '../../../../shared/services/storage.service';
import { VendorHandler } from '@portal/app/dashboard/integrations/shared/vendor-handlers/vendor-handler';
import {
  IOAuthDetails,
  IVendorConnectConfirmation,
  IVendorConnectInitRequest,
  IVendorConnectInitResponse
} from '@portal/app/dashboard/integrations/models/vendor/VendorIntegrationModels';
import { IVendorInitiateRequest } from '@portal/app/dashboard/integrations/models/vendor/IVendorInitiateRequest';
import {
  callbackDomain,
  VendorIdSource
} from '@portal/app/dashboard/integrations/shared/constants';
import { IVendorConfigurationRequest } from '@portal/app/dashboard/integrations/models/vendor/IVendorConfigurationRequest';
import { map } from 'lodash-es';
import { IVendorOAuthConfirmationRequest } from '@portal/app/dashboard/integrations/models/vendor/IVendorOAuthConfirmationRequest';
import { IVendorReConfigurationRequest } from '@portal/app/dashboard/integrations/models/vendor/IVendorReConfigurationRequest';
import { WindowService } from '@portal/app/shared/services/window.service';

export class AmazonHandler implements VendorHandler {
  constructor(
    private storageService: StorageService,
    private readonly windowService: WindowService
  ) {}

  prepareRequestObjectToInitiateVendor(
    vendorDetails: IVendorConnectInitRequest
  ): IVendorInitiateRequest {
    /* eslint-disable no-console */
    console.log(`Amazon initiation data: ${vendorDetails}`);
    return {
      req: {},
      queryParams: { callbackDomain: callbackDomain() },
      pathParams: { vendorId: VendorIdSource.amazon }
    };
  }

  prepareRequestObjectToConfigureVendor(
    accounts: {
      accountName: string;
      accountId: string;
      clientAccountName?: string;
      managerId?: string;
      disabled: boolean;
      endpoint?: string;
    }[]
  ): IVendorConfigurationRequest {
    return {
      vendorId: VendorIdSource.amazon,
      vendorData: map(accounts, (account) => ({
        profileId: account.accountId,
        clientAccountName: account.clientAccountName,
        endpoint: account.endpoint ? account.endpoint : ''
      }))
    };
  }

  handleVendorInitiateResponse(data: IVendorConnectInitResponse): void {
    localStorage.setItem(
      'callBackParams',
      JSON.stringify(
        (data.vendorCredentialsDetails as IOAuthDetails).callbackParameters
      )
    );
    this.windowService.navigateToHref(
      (data.vendorCredentialsDetails as IOAuthDetails).redirectURL
    );
  }

  confirmVendor(data: IVendorConnectConfirmation): void {
    const jsonData = data.accountDetails
      ? JSON.stringify(data.accountDetails.vendorAccounts)
      : '';
    this.storageService.setIntegrationAccountDetails(
      VendorIdSource.amazon,
      jsonData
    );
  }

  prepareRequestToConfirmVendor(): IVendorOAuthConfirmationRequest {
    return {
      code: `${localStorage.getItem('code')}`,
      state: `${localStorage.getItem('state')}`,
      callbackDomain: callbackDomain()
    };
  }

  prepareRequestObjectToReConfigureVendor(
    accounts: {
      accountName: string;
      accountId: string;
      managerId?: string | undefined;
      disabled: boolean;
      endpoint?: string;
      clientAccountName?: string;
    }[],
    dsInstanceIds: number[]
  ): IVendorReConfigurationRequest {
    return {
      vendorId: VendorIdSource.amazon,
      vendorData: map(accounts, (account) => ({
        profileId: account.accountId,
        clientAccountName: account.clientAccountName,
        endpoint: account.endpoint ? account.endpoint : ''
      })),
      dsInstanceIds
    };
  }
}
