import { IVCampaign } from '@libs/apis';
import Dexie, { liveQuery, Table } from 'dexie';

export class AppDB extends Dexie {
  private static readonly versionNumber = 3;
  public static readonly tableName = 'campaigns';

  campaigns!: Table<IVCampaign, number>;
  private hasData = false;

  constructor(
    private readonly dbName: string,
    public readonly pageSize = 100
  ) {
    super('taxonomy_data_' + dbName);
    this.version(AppDB.versionNumber).stores({
      campaigns:
        '++id, tempRecordId, dimensionKey, campaign, adsetName, tactic, channel, segment'
    });

    this.resetDatabase()
      .then(() => console.warn(`database: ${this.name} reset completed`))
      .catch(console.error);
  }

  public isEmpty(): boolean {
    return !this.hasData;
  }

  public async resetDatabase() {
    if (!this.hasData) {
      return Promise.resolve();
    }
    await this.transaction('rw', 'campaigns', async () => {
      await this.campaigns.clear();
      this.hasData = false;
      return Promise.resolve();
    });
  }

  async populate(campaigns: IVCampaign[]) {
    await this.campaigns.bulkAdd(campaigns);
    liveQuery(() => this.campaigns.count()).subscribe(
      (count: number) => (this.hasData = count > 0)
    );
  }

  async selectAll() {
    return this.campaigns
      .where({ dimensionKey: this.dbName })
      .modify({ isSelected: true });
  }

  async unselectAll() {
    return this.campaigns
      .where({ dimensionKey: this.dbName })
      .modify({ isSelected: false });
  }

  async selectCampaign(tempRecordId: string) {
    return this.campaigns.where({ tempRecordId }).modify({ isSelected: true });
  }

  async unselectCampaign(tempRecordId: string) {
    return this.campaigns.where({ tempRecordId }).modify({ isSelected: false });
  }
}
