<div class="accuracy-alert">
  <div
    [ngClass]="[
      'icon',
      'center',
      type,
      type === 'info' ? 'pi pi-info-circle' : 'pi pi-exclamation-triangle',
    ]"></div>
  <span [ngClass]="[type]" #messageDiv id="message" class="message center">
    {{ message }}
  </span>
</div>
