import { Component, inject } from '@angular/core';
import { TabViewChangeEvent } from 'primeng/tabview';
import { MetricsService } from '@portal/app/dashboard/context-modal/services/metrics.service';

@Component({
  selector: 'portal-context-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss'],
  providers: [MetricsService]
})
export class MetricsComponent {
  metricsService = inject(MetricsService);

  isLoading = this.metricsService.isLoading;
  data = this.metricsService.data;
  isDataAvailable = this.metricsService.isDataAvailable;
  activeTabIndex = this.metricsService.activeTabIndex;

  constructor() {
    this.metricsService.setActiveTabIndex(0);
  }

  onTabChange(event: TabViewChangeEvent): void {
    this.metricsService.setActiveTabIndex(event.index);
  }
}
