<div
  class="m-tag"
  [ngClass]="{
    positive: isPositive,
    negative: !isPositive && !isNeutral,
    neutral: isNeutral,
    dark: isDark,
    hideBackground: !showBackground,
  }">
  <i aria-hidden="true" class="material-symbols-outlined icon-xsmall">{{
    isUp ? "arrow_upward" : "arrow_downward"
  }}</i>
  <span class="c2"><ng-content></ng-content></span>
</div>
