import {
  ContextField,
  DateRange,
  Filter,
  FilterElement,
  FilterOption,
  LayoutTypes
} from '@portal/app/shared/types';
import { BulkModeDropdownOption } from '@portal/app/dashboard/date-range-picker/date-range-picker.component';
import { DateTimeService } from '@portal/app/shared/services/date-time.service';

export class FilterSetUtil {
  public static mapFilterField(
    allFilters: Filter[],
    item: string,
    displayOrder = 0,
    layoutType: string,
    selectAll = true
  ): ContextField {
    return FilterSetUtil.mapField({
      f: allFilters.find((i) => i.literalId === item),
      allFilters,
      item,
      displayOrder,
      selectAll,
      layoutType
    });
  }

  public static mapFieldByFilter(
    filter: Filter,
    item: string,
    layoutType: string,
    displayOrder = 0
  ): ContextField {
    return FilterSetUtil.mapField({
      f: filter,
      item,
      displayOrder,
      selectAll: false,
      layoutType
    });
  }

  // noinspection JSUnusedLocalSymbols
  public static latestMapField(
    f: Filter | undefined,
    item: string,
    displayOrder: number,
    selectAll: boolean,
    layoutType: string
  ) {
    let options: string[] | FilterOption[] = [];
    if (f == null) {
      console.error(`filter for ${item} does not exist`);
      return {} as ContextField;
    }
    if (f.options != null) {
      options = f.options.filter((i) => i != null);
    }
    if (['TOGGLE', 'SELECT', 'MULTI_SELECT'].includes(layoutType)) {
      options = options.map(
        (o) =>
          ({
            label: typeof o === 'object' ? o.label : o,
            value: typeof o === 'object' ? o.value : o
          }) as FilterOption
      );
    }
    return {
      name: f.literalId,
      label: f.label,
      inputType: null,
      value: f.value,
      options,
      position: displayOrder,
      layoutType
    } as ContextField;
  }

  public static mapField({
    f,
    allFilters,
    item,
    displayOrder,
    selectAll,
    layoutType
  }: {
    f?: Filter;
    allFilters?: Filter[];
    item: string;
    displayOrder: number;
    selectAll: boolean;
    layoutType: string;
  }) {
    let bulkModeOptions: BulkModeDropdownOption[] | undefined;
    let bulkModeValue: string | undefined;
    let bulkModeFilterLiteralId = '';
    if (f == null) {
      if (layoutType === LayoutTypes.verticalSeperator) {
        return {
          name: item,
          label: '',
          inputType: null,
          value: '',
          position: displayOrder,
          layoutType
        } as ContextField;
      } else {
        console.error(`filter for ${item} does not exist`);
        return {} as ContextField;
      }
    }
    if (!['FILTER_SET', 'DATA_CHART', 'DATA_SET'].includes(layoutType)) {
      return this.latestMapField(f, item, displayOrder, selectAll, layoutType);
    }
    let options: string[] | FilterOption[] = [];
    if (f.options != null) {
      options = f.options.filter((i) => i != null);
    }
    let inputType = 'select';
    if (f.type === 'string[]') {
      inputType = 'multiselect';
      const optsAsString = options as string[];
      options = optsAsString.map(
        (o) =>
          ({
            label: o,
            value: o
          }) as FilterOption
      );
      const typedValue = f.value as string[];
      if (selectAll && typedValue && typedValue.length === 0) {
        // select all the things
        f.value = options.map((o) => o.value) as string[];
      }
    } else if (f.type === 'number') {
      inputType = 'number';
    } else if (f.type === 'dateRange') {
      inputType = 'dateRange';
      if (allFilters != null) {
        const bulkModeFilter = allFilters.find((fil) =>
          fil.literalId.includes('period_flag')
        );
        bulkModeFilterLiteralId = bulkModeFilter?.literalId || '';
        if (bulkModeFilter) {
          bulkModeValue = bulkModeFilter.value as string;
          bulkModeOptions = bulkModeFilter.options?.map((option) => {
            if (typeof option === 'object') {
              return option;
            }
            return { label: option, value: option };
          }) as BulkModeDropdownOption[];
        }
      }
    } else if (f.type === 'buttonGroup') {
      inputType = 'buttonGroup';
    } else if (
      inputType === 'select' &&
      options.length &&
      typeof options[0] !== 'object'
    ) {
      options = options.map(
        (o) =>
          ({
            label: o,
            value: o
          }) as FilterOption
      );
    }
    return {
      name: f.literalId,
      label: f.label,
      inputType,
      value: f.value,
      options,
      position: displayOrder,
      layoutType: inputType,
      bulkModeValue,
      bulkModeOptions,
      bulkModeFilterLiteralId,
      additionalFields: f.additionalFields
    } as ContextField;
  }

  public static sortByDisplayOrder(a: FilterElement, b: FilterElement) {
    if (a != null && b != null) {
      return a.displayOrder > b.displayOrder
        ? 1
        : a.displayOrder < b.displayOrder
          ? -1
          : 0;
    }
    return 0;
  }

  public static setAsDateRange(value: string[]): DateRange {
    return {
      startDate: DateTimeService.dayJsDate(value[0] as string),
      endDate: DateTimeService.dayJsDate(value[1] as string)
    };
  }
}
