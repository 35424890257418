import { Component, input } from '@angular/core';
import { CardVariant } from '@design-system/components/m-card';

@Component({
  selector: 'portal-context-channel-insights',
  templateUrl: './channel-insights.component.html'
})
export class ChannelInsightsComponent {
  public cardVariantType = CardVariant;

  viewBy = input.required<string>();
}
