import { Component, OnDestroy, OnInit } from '@angular/core';
import { FilterValue } from '@portal/app/shared/types';
import { SelectItem } from 'primeng/api';
import type { Options } from 'highcharts';

// services
import { HomepageService } from '@portal/app/dashboard/home-page/services/home-page.service';
import { DateTimeService } from '@portal/app/shared/services/date-time.service';
import { NewVsReturningCustomersService } from './new-vs-returning-customers.service';
import { MyCustomersService } from '@portal/app/dashboard/home-page/components/my-customers/my-customers.service';

import { Subject, takeUntil } from 'rxjs';
import { formatPercent } from '@angular/common';

@Component({
  selector: 'portal-new-vs-returning-customers',
  templateUrl: './new-vs-returning-customers.component.html',
  styleUrls: ['./new-vs-returning-customers.component.scss'],
  providers: [NewVsReturningCustomersService]
})
export class NewVsReturningCustomersComponent implements OnInit, OnDestroy {
  startDateValue: FilterValue = '';
  totalByOptions: SelectItem[] = [];
  totalByValue: FilterValue = [];
  startDateOptions: SelectItem[] = [];
  isDataAvailable = true;
  isLoading = true;
  newPercentValue = '';
  isPercentUp = true;
  newTotalvalue = '';
  newPercentValueKey = 'newCustomerPerc';
  newTotalvalueKey = 'newCustomerTotal';
  lastMonth = '';
  previousToLastMonth = '';
  minWidthValue = 120;

  stackedColumnChartOptions: Options = {};
  private destroy$ = new Subject<void>();

  constructor(
    private homepageService: HomepageService,
    private dateTimeService: DateTimeService,
    private newVsReturningCustomersService: NewVsReturningCustomersService,
    private myCustomersService: MyCustomersService
  ) {}

  get isChartReady(): boolean {
    return (
      !this.isLoading &&
      !!this.stackedColumnChartOptions?.series &&
      !!this.stackedColumnChartOptions?.xAxis &&
      !!this.stackedColumnChartOptions?.yAxis &&
      !!this.stackedColumnChartOptions?.plotOptions &&
      !!this.stackedColumnChartOptions?.tooltip
    );
  }

  ngOnInit(): void {
    this.homepageService.customerDataChartDimensions$
      .pipe(takeUntil(this.destroy$))
      .subscribe((dimensions) => {
        this.totalByOptions = dimensions;
        this.totalByValue = dimensions[0]?.value as FilterValue;
      });

    this.homepageService.myCustomersSectionDateOptions$
      .pipe(takeUntil(this.destroy$))
      .subscribe((dateOptions) => {
        if (dateOptions && dateOptions.length > 0) {
          const dataAvailableDates = this.dateTimeService.stripTimeFromDates(
            dateOptions
          ) as string[];
          this.startDateValue = dataAvailableDates?.[0] as FilterValue;
          this.startDateOptions =
            this.myCustomersService.prepareStartDateOptions(dataAvailableDates);
          this.initializeDateFilters();
          this.updateLastAndPreviousMonthValues();
        }
      });

    this.newVsReturningCustomersService.isLoading$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoading) => {
        this.isLoading = isLoading;
      });

    this.newVsReturningCustomersService.dataPoints$
      .pipe(takeUntil(this.destroy$))
      .subscribe((dataPoints) => {
        this.newPercentValue = formatPercent(
          Math.abs(Number(dataPoints[this.newPercentValueKey])),
          'en-US',
          '1.0-2'
        ) as string;
        this.isPercentUp =
          (dataPoints[this.newPercentValueKey] as unknown as number) > 0;
        this.newTotalvalue =
          this.newVsReturningCustomersService.formatTotalNewValue(
            this.newTotalvalueKey,
            dataPoints[this.newTotalvalueKey] as number
          );
      });

    this.newVsReturningCustomersService.chartData$
      .pipe(takeUntil(this.destroy$))
      .subscribe((chartData) => {
        if (chartData) {
          this.stackedColumnChartOptions = chartData;
          this.minWidthValue =
            this.newVsReturningCustomersService.getMinWidth();
          this.isDataAvailable =
            this.homepageService.checkChartDataAvailability(chartData);
        }
      });
  }

  onTotalByOptionsChange(newValue: string[] = ['']) {
    if (newValue && newValue.includes('newCustomer')) {
      this.newPercentValueKey = 'newCustomerPerc';
      this.newTotalvalueKey = 'newCustomerTotal';
    } else {
      this.newPercentValueKey = 'newCustomersRevenuePerc';
      this.newTotalvalueKey = 'newCustomersRevenueTotal';
    }
    this.newVsReturningCustomersService.setChartDimensions(newValue);
  }

  onStartDateChange(startDate = '') {
    const endDate: string = this.dateTimeService.getLastDayAtSixthMonth(
      this.startDateValue as string
    );
    const dateValue: string[] = [startDate, endDate];
    this.newVsReturningCustomersService.setDatevalue(dateValue);
  }

  initializeDateFilters() {
    const dateValue: string[] = [
      this.startDateValue as string,
      this.dateTimeService.getLastDayAtSixthMonth(this.startDateValue as string)
    ];
    this.newVsReturningCustomersService.setDatevalue(dateValue);
  }

  updateLastAndPreviousMonthValues() {
    this.lastMonth = this.myCustomersService.getLastMonth(
      this.startDateValue as string
    );
    this.previousToLastMonth = this.myCustomersService.getPreviousToLastMonth(
      this.startDateValue as string
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
