import { Injectable } from '@angular/core';
import { formatNumber, formatPercent } from '@angular/common';
import { forEach } from 'lodash-es';
import {
  DataGridElement,
  DataResponse,
  DataRowData,
  FieldDefinition,
  FieldDefinitions,
  Format,
  IFormat,
  IFormats,
  Type
} from '@portal/app/shared/types';
import { CustomCurrencyPipe } from '@portal/app/shared/pipes/custom-currency.pipe';
import { CurrencyService } from '@portal/app/shared/services/currency.service';
import { CurrencySymbolValue } from '@portal/app/shared/types/currency-format';
import { DateTimeService } from '@portal/app/shared/services/date-time.service';

declare global {
  interface Window {
    formatNumber: unknown;
  }
}

window.formatNumber = formatNumber;

@Injectable({
  providedIn: 'root'
})
export class FormatterService {
  public currencySymbolValue: CurrencySymbolValue | null = '$';

  public static fieldGenerator = (
    format: Format,
    digitsInfo: string | null = '1.0-0',
    type: Type = 'double'
  ): FieldDefinition => {
    return {
      id: -1,
      dashboardId: -1,
      literalId: 'testField',
      label: 'test field',
      format,
      type,
      digitsInfo,
      aggregateFunction: 'none'
    };
  };

  constructor(
    public currencyService: CurrencyService,
    public customCurrencyPipe: CustomCurrencyPipe
  ) {
    // GET THE CURRENCY SYMBOL($, €, £ etc...)
    const transformedValue = this.customCurrencyPipe.transform(1, '1.0-0');
    if (transformedValue) {
      // strip the symbol
      this.currencySymbolValue = transformedValue.slice(
        0,
        -1
      ) as CurrencySymbolValue;
    }
  }

  validateAndReplace(
    value: string | number,
    replaceWith: string | number
  ): string | number {
    if (
      value == null ||
      value === 'NaN' ||
      value === 'Infinity' ||
      value === '-Infinity' ||
      value === ''
    ) {
      return replaceWith;
    }
    return value;
  }

  /**
   * Formats a given value based on the specified field definition and optional parameters.
   *
   * @param field - The field definition containing formatting options.
   * @param value - The value to be formatted.
   * @param [removeNegativeSign=false] - Optional parameter to remove the negative sign from negative values.
   * @returns - The formatted value as a string.
   */
  format(
    field: FieldDefinition,
    value: number | string,
    removeNegativeSign = false
  ): string {
    if (
      value == null ||
      value === 'NaN' ||
      value === 'Infinity' ||
      value === '-Infinity' ||
      value === ''
    ) {
      return '-';
    } else if (value != null && typeof value === 'number' && !isFinite(value)) {
      return '-';
    }

    // Create a new variable to hold the transformed value
    let transformedValue = value;

    // Check if the negative sign should be removed
    if (
      removeNegativeSign &&
      typeof transformedValue === 'number' &&
      transformedValue < 0
    ) {
      transformedValue = Math.abs(transformedValue);
    }

    switch (field.format) {
      case 'currency':
        return (
          this.customCurrencyPipe.transform(
            transformedValue,
            field.digitsInfo || ''
          ) || ''
        );
      case 'percent':
        return formatPercent(
          transformedValue as number,
          'en-US',
          field.digitsInfo || ''
        );
      case 'number':
        return formatNumber(
          transformedValue as number,
          'en-US',
          field.digitsInfo || ''
        );
      case 'yyyy-MM-dd':
        return new Date(transformedValue as string).toLocaleString('en-US', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        });
      case 'UTC-yyyy-MM-dd':
        return DateTimeService.dateWithoutTimeZone(
          transformedValue
        ).toLocaleString('en-US', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        });
      case 'days':
        return `${transformedValue} Days`;
      default:
        return `${transformedValue}`;
    }
  }

  formatPartialValue(
    field: FieldDefinition | IFormat,
    value: number | string | null
  ): string {
    if (
      (value == null ||
        value === 'NaN' ||
        value === 'Infinity' ||
        value === '-Infinity') &&
      field?.format !== 'empty'
    ) {
      return '-';
    } else if (value != null && typeof value === 'number' && !isFinite(value)) {
      return '-';
    }
    switch (field?.format) {
      case 'currency':
        return (
          this.customCurrencyPipe.transform(value, field.digitsInfo || '') || ''
        );
      case 'percent':
        return value !== ''
          ? `${formatNumber(
              parseFloat(`${value}`),
              'en-US',
              field.digitsInfo || ''
            )}%`
          : '-';
      case 'number':
        return formatNumber(
          parseFloat(`${value}`),
          'en-US',
          field.digitsInfo || ''
        );
      case 'yyyy-MM-dd':
        return new Date(`${value}`).toLocaleString('en-US', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        });
      case 'UTC-yyyy-MM-dd':
        return DateTimeService.dateWithoutTimeZone(value).toLocaleString(
          'en-US',
          {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          }
        );
      case 'empty':
        return '';
      default:
        return `${value}`;
    }
  }

  formatDataPartially(
    fields: FieldDefinitions | IFormats,
    data: DataResponse[]
  ): DataResponse[] {
    return data.map((item: DataResponse) => {
      for (const key in item) {
        if (Object.prototype.hasOwnProperty.call(item, key) && fields[key]) {
          item[key] = this.convertPartialItem(
            fields[key] as FieldDefinition | IFormat,
            item[key] as string | number | null
          );
        }
      }
      return item;
    });
  }

  checkObjectEquality(obj1: DataResponse, obj2: DataResponse) {
    const obj1Length = Object.keys(obj1).length;
    const obj2Length = Object.keys(obj2).length;
    if (obj1Length === obj2Length) {
      return Object.keys(obj1).every((key) => {
        if (Object.keys(obj2).includes(key) && obj2[key] === obj1[key]) {
          return true;
        }
      });
    }
    return false;
  }

  groupByDimensions(
    element: DataGridElement,
    tableData: DataResponse[]
  ): DataResponse[] {
    // collect a list of dimension ids
    const dimensionsIds: string[] = [];
    forEach(element.selected, (col) => {
      if (col.type === 'DIMENSION') {
        dimensionsIds.push(col.dashboardFieldId);
      }
    });

    const presentCombos: DataResponse[] = [];
    forEach(tableData, (row) => {
      const rowDimensionCombo: DataResponse = {};
      forEach(dimensionsIds, (did) => {
        if (row[did]) {
          rowDimensionCombo[did] = row[did] as DataRowData;
        }
      });
      presentCombos.push(rowDimensionCombo);
    });
    // MAKE THE AVAILABLE DIMENSION COMBOS
    const uniqueCombos: DataResponse[] = [];
    forEach(presentCombos, (combo) => {
      if (uniqueCombos.length) {
        const avail: DataResponse = uniqueCombos.find((ucombo) => {
          if (this.checkObjectEquality(ucombo, combo)) {
            return combo;
          }
        }) as DataResponse;
        if (!avail) {
          uniqueCombos.push(combo);
        }
      } else {
        uniqueCombos.push(combo);
      }
    });

    //ASSIGN PIVOT DATA VALUES TO UNIQUE DIMENSION COMBOS
    forEach(tableData, (row) => {
      const rowDimensionCombo: DataResponse = {};
      forEach(dimensionsIds, (did) => {
        if (row[did]) {
          rowDimensionCombo[did] = row[did] as DataRowData;
        }
      });
      let comboMatchIndex = -1;
      let comboMatch: DataResponse = {};
      forEach(uniqueCombos, (ucombo, index) => {
        if (
          dimensionsIds.every(
            (id) =>
              Object.keys(rowDimensionCombo).includes(id) &&
              rowDimensionCombo[id] === ucombo[id]
          )
        ) {
          comboMatchIndex = index;
          comboMatch = row;
        }
      });
      if (comboMatch && comboMatchIndex !== -1) {
        uniqueCombos[comboMatchIndex] = Object.assign(
          {},
          uniqueCombos[comboMatchIndex],
          comboMatch
        );
      }
    });
    return uniqueCombos;
  }

  formatDataResponsePartially(
    fields: FieldDefinitions | IFormats,
    item: DataResponse
  ): DataResponse {
    for (const key in item) {
      if (Object.prototype.hasOwnProperty.call(item, key) && fields[key]) {
        item[key] = this.convertPartialItem(
          fields[key] as FieldDefinition | IFormat,
          item[key] as DataRowData
        );
      }
    }
    return item;
  }

  convertPartialItem(
    field: FieldDefinition | IFormat,
    value: number | string | null
  ) {
    if (
      value == null ||
      value === 'NaN' ||
      value === 'Infinity' ||
      value === '-Infinity'
    ) {
      return value;
    } else if (value != null && typeof value === 'number' && !isFinite(value)) {
      return value;
    }
    switch (field.format) {
      case 'currency':
      case 'number': {
        const val = parseFloat(
          formatNumber(
            value as number,
            'en-US',
            field.digitsInfo || ''
          ).replace(/,/g, '')
        );
        return Number.isFinite(val) ? val : 0;
      }
      case 'percent': {
        const val = parseFloat(
          formatPercent(
            value as number,
            'en-US',
            field.digitsInfo || ''
          ).replace(/,/g, '')
        );
        return Number.isFinite(val) ? val : 0;
      }
      default:
        return value;
    }
  }
}
