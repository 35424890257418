import {
  ICellConfig,
  ICellView,
  ICPAdStudyConfig,
  IPlatform
} from '@portal/app/dashboard/geo-doe-config/models';
import {
  DATE_FORMAT,
  DEFAULT_CLIENT_ID,
  GEO_DOE_PROPERTY_TYPE,
  MS_MARKET_CELL_STATUS,
  StudyStatus,
  VENDOR_DEPENDENCY_REMOVED,
  VendorConfiguredAt,
  VendorLevel
} from '@portal/app/dashboard/geo-doe-config/shared/constants';
import {
  getDateWithoutTimezoneConsideration,
  isAfterToday,
  isBeforeToday
} from '@portal/app/dashboard/geo-doe-config/shared/dateUtils';
import { CustomCurrencyPipe } from '@portal/app/shared/pipes/custom-currency.pipe';
import { DateTimeService } from '@portal/app/shared/services/date-time.service';
import { keys } from 'lodash-es';
import { CurrencySuffixPipe } from '@portal/app/dashboard/geo-doe-config/shared/pipes/currency-suffix.pipe';
import {
  CpCellType,
  ICPAdStudyConfig as ICPAdStudyConfigDTO
} from '@libs/apis';
import { ApiService } from '@portal/app/dashboard/geo-doe-config/services/api.service';
import { StudyConfigStoreService } from '@portal/app/dashboard/geo-doe-config/data-stores/study-config-store.service';
import { IMeasuredGenericConfig } from '@portal/app/dashboard/geo-doe-config/models/IMeasuredGenericConfig';
import { IStudyCell } from '@portal/app/dashboard/geo-doe-config/models/ICell';
import { TestStatusHelper } from '@portal/app/geo-designer/common/test-status.helper';

export const getActualStudyStatus = (study: ICPAdStudyConfig): string => {
  const {
    ACTIVE,
    SCHEDULED,
    LIVE,
    COMPLETED,
    PENDING_LAUNCH_TODAY,
    PENDING_LAUNCH
  } = StudyStatus;
  const today = new Date();
  const startDate = getDateWithoutTimezoneConsideration(
    study.startDate.toString().split('T')[0]
  );
  const endDate = getDateWithoutTimezoneConsideration(
    study.endDate.toString().split('T')[0]
  );
  const taxonomyFilters =
    study.cellConfigs.find((cell) => cell.cellType !== CpCellType.BAU)
      ?.taxonomyFilters || [];
  if (study.status === ACTIVE) {
    if (isAfterToday(startDate)) {
      return SCHEDULED;
    } else if (
      today.toDateString() === startDate.toDateString() &&
      !study.studyState
    ) {
      return PENDING_LAUNCH_TODAY;
    } else if (
      TestStatusHelper.isStudyYetToPendingToLaunch(
        study.studyState,
        study.disableSync,
        study.parentId,
        (taxonomyFilters || []).length || 0
      )
    ) {
      return PENDING_LAUNCH;
    } else if (
      DateTimeService.dateStringWithoutTimeZone(today, DATE_FORMAT) <=
        DateTimeService.dateStringWithoutTimeZone(endDate, DATE_FORMAT) &&
      DateTimeService.dateStringWithoutTimeZone(today, DATE_FORMAT) >=
        DateTimeService.dateStringWithoutTimeZone(startDate, DATE_FORMAT)
    ) {
      return LIVE;
    } else if (isBeforeToday(endDate)) {
      return COMPLETED;
    }
  }
  return study.status;
};

export const getMSExecutionIdInDate = (
  marketSelectionExecutionId: string
): string => {
  return DateTimeService.dateStringWithoutTimeZone(
    marketSelectionExecutionId,
    DATE_FORMAT
  );
};

export const getStudyStatusLabel = (status: string) => {
  switch (status) {
    case StudyStatus.ACTIVE:
      return 'Active';
    case StudyStatus.ARCHIVED:
      return 'Archived';
    case StudyStatus.COMPLETED:
      return 'Completed';
    case StudyStatus.DRAFT:
      return 'Draft';
    case StudyStatus.INACTIVE:
      return 'Inactive';
    case StudyStatus.LIVE:
      return 'Live';
    case StudyStatus.PENDING_LAUNCH_TODAY:
      return 'Pending_launch_today';
    case StudyStatus.SCHEDULED:
      return 'Scheduled';
  }
};

export const newMsCellStatus = (
  actualStudyStatus: string
): MS_MARKET_CELL_STATUS => {
  const status: Record<string, MS_MARKET_CELL_STATUS> = {
    [StudyStatus.COMPLETED]: MS_MARKET_CELL_STATUS.COOLING,
    [StudyStatus.SCHEDULED]: MS_MARKET_CELL_STATUS.SCHEDULED,
    [StudyStatus.LIVE]: MS_MARKET_CELL_STATUS.INFLIGHT,
    [StudyStatus.DRAFT]: MS_MARKET_CELL_STATUS.DRAFT
  };
  return status[actualStudyStatus]
    ? (status[actualStudyStatus] as MS_MARKET_CELL_STATUS)
    : MS_MARKET_CELL_STATUS.AVAILABLE;
};

export const setSavedStudyDatesWithoutTimezone = (
  study: ICPAdStudyConfig
): ICPAdStudyConfig => {
  if (study) {
    keys(study).forEach((key) => {
      const param = key as keyof ICPAdStudyConfig;
      if (
        study[param] &&
        [
          'startDate',
          'endDate',
          'inventoryRefStartDate',
          'inventoryRefEndDate',
          'reportStartDate',
          'reportEndDate'
        ].includes(key)
      ) {
        const value = DateTimeService.dateStringWithoutTimeZone(
          (study[param] as string).split('T')[0],
          DATE_FORMAT
        );
        (study[param] as string) = value as string;
      }
    });
  }
  return study;
};

export const setSavedTestDatesWithoutTimezone = (
  study: ICPAdStudyConfigDTO
): ICPAdStudyConfigDTO => {
  if (study) {
    keys(study).forEach((key) => {
      const param = key as keyof ICPAdStudyConfigDTO;
      if (
        study[param] &&
        [
          'startDate',
          'endDate',
          'inventoryRefStartDate',
          'inventoryRefEndDate',
          'reportStartDate',
          'reportEndDate'
        ].includes(key)
      ) {
        const value = DateTimeService.dateStringWithoutTimeZone(
          (study[param] as string).split('T')[0],
          DATE_FORMAT
        );
        (study[param] as string) = value as string;
      }
    });
  }
  return study;
};

export const performSliceOperationOnArray = (
  arrayVariable: (string | number)[],
  startSliceCount?: number,
  endSliceCount?: number
): (string | number)[] => {
  return arrayVariable.slice(startSliceCount, endSliceCount);
};

const vendorLevelInfo: Record<
  string,
  {
    id: InventoryId;
    name: InventoryName;
    savedTaxonomyId: TaxonomyId;
    header: string;
    savedTaxonomyName: TaxonomyName;
    dtdMapMeasuredId: DtdMeasuredInventoryId;
    dtdMapClientId: DtdClientInventoryId;
  }
> = {
  [VendorLevel.subCampaign]: {
    id: 'subCampaignId',
    name: 'subCampaignName',
    savedTaxonomyId: 'adGroups',
    header: 'Adset',
    savedTaxonomyName: 'names',
    dtdMapMeasuredId: 'measuredSubcampaign',
    dtdMapClientId: 'clientSubcampaign'
  },
  [VendorLevel.campaign]: {
    id: 'campaignId',
    name: 'campaignName',
    savedTaxonomyId: 'campaigns',
    header: 'Campaign',
    savedTaxonomyName: 'names',
    dtdMapMeasuredId: 'measuredCampaign',
    dtdMapClientId: 'clientCampaign'
  }
};

export const getColumnsByLevel = (
  level: string
):
  | {
      id: InventoryId;
      name: InventoryName;
      savedTaxonomyId: TaxonomyId;
      header: string;
      savedTaxonomyName: TaxonomyName;
      dtdMapMeasuredId: DtdMeasuredInventoryId;
      dtdMapClientId: DtdClientInventoryId;
    }
  | undefined => {
  if (
    level === VendorLevel.subCampaign &&
    vendorLevelInfo[VendorLevel.subCampaign]
  ) {
    return vendorLevelInfo[VendorLevel.subCampaign];
  } else if (vendorLevelInfo[VendorLevel.campaign]) {
    return vendorLevelInfo[VendorLevel.campaign];
  }
};

export const returnFieldAndHeaderForPlatform = (
  level: VendorLevel
): { field: string; header: string } => {
  const { name, header } = getColumnsByLevel(level) as {
    id: InventoryId;
    name: InventoryName;
    savedTaxonomyId: TaxonomyId;
    header: string;
    savedTaxonomyName: TaxonomyName;
    dtdMapMeasuredId: DtdMeasuredInventoryId;
    dtdMapClientId: DtdClientInventoryId;
  };
  return { field: name, header };
};

export type TaxonomyId = 'adGroups' | 'campaigns';
export type TaxonomyName = 'names';
export type InventoryId = 'subCampaignId' | 'campaignId';
export type InventoryName = 'subCampaignName' | 'campaignName';
export type DtdMeasuredInventoryId = 'measuredSubcampaign' | 'measuredCampaign';
export type DtdClientInventoryId = 'clientSubcampaign' | 'clientCampaign';

export const formatCurrencyValue = (
  value: number,
  currencySuffixPipe: CurrencySuffixPipe,
  customCurrencyPipe: CustomCurrencyPipe
) => {
  const currency = customCurrencyPipe.transform(value)?.charAt(0);
  return `${currency}${currencySuffixPipe.transform(value)}`;
};

export const experimentDurationInDays = (
  studyInfo: ICPAdStudyConfig,
  dateService: DateTimeService
) => {
  return dateService.difference(studyInfo.endDate, studyInfo.startDate) + 1;
};

export const getVendorLevelAndConfiguredAtForCell = (
  cell: ICellView | ICellConfig | IStudyCell,
  platform: IPlatform,
  studyConfigStoreService: StudyConfigStoreService
): { vendorLevel: VendorLevel; vendorConfiguredAt: VendorConfiguredAt } => {
  if (studyConfigStoreService.getIsReadOnlyView()) {
    return {
      vendorLevel: cell.vendorLevel,
      vendorConfiguredAt: cell.vendorConfiguredAt
    };
  }
  return {
    vendorLevel:
      cell.vendorLevel === platform.level ? cell.vendorLevel : platform.level,
    vendorConfiguredAt:
      cell.vendorConfiguredAt === platform.vendorConfiguredAt
        ? cell.vendorConfiguredAt
        : platform.vendorConfiguredAt
  };
};

export const checkForVendorDependencyRemoval = (
  apiService: ApiService,
  studyConfigStore: StudyConfigStoreService
): void => {
  apiService
    .getMeasuredGenericConfig(
      GEO_DOE_PROPERTY_TYPE,
      [VENDOR_DEPENDENCY_REMOVED],
      true
    )
    .subscribe({
      next: (genericConfig: IMeasuredGenericConfig[]) => {
        const clientLevelConfig = genericConfig.find(
          (config) => config.clientId !== DEFAULT_CLIENT_ID
        );
        const defaultClientConfig = genericConfig.find(
          (config) => config.clientId === DEFAULT_CLIENT_ID
        );
        const configObj = clientLevelConfig || defaultClientConfig;
        const isVendorRemovalEnabled = configObj
          ? configObj.paramValue === 'true'
          : false;
        studyConfigStore.setIsVendorRemovedConfig(isVendorRemovalEnabled);
      }
    });
};

export const getClientAndMeasuredInventoryIdKey = (
  vendorLevel: VendorLevel
): {
  clientCampaignIdKey: 'clientCampaign' | 'clientSubcampaign';
  measuredCampaignIdKey: 'measuredCampaign' | 'measuredSubcampaign';
} => {
  const clientCampaignIdKey =
    vendorLevel === VendorLevel.campaign
      ? 'clientCampaign'
      : 'clientSubcampaign';
  const measuredCampaignIdKey =
    vendorLevel === VendorLevel.campaign
      ? 'measuredCampaign'
      : 'measuredSubcampaign';
  return {
    clientCampaignIdKey,
    measuredCampaignIdKey
  };
};

export const getFormattedDateTime = (): string => {
  const now = new Date();
  return now.toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });
};
