<span class="m-panel">
  <p-panel
    [toggleable]="true"
    [collapsed]="collapsed"
    (collapsedChange)="collapsedChange.emit($event)">
    <!-- Custom header -->
    <ng-template pTemplate="header">
      <ng-content select="[header]"></ng-content>
    </ng-template>

    <!-- Custom content -->
    <ng-content></ng-content>

    <!-- Custom footer -->
    <ng-template pTemplate="footer">
      <ng-content select="[footer]"></ng-content>
    </ng-template>
  </p-panel>
</span>
