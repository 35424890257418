<div class="autocomplete">
  <p-autoComplete
    [showClear]="showClear()"
    [placeholder]="placeholder()"
    [showClear]="showClear()"
    [suggestions]="suggestions"
    [optionLabel]="optionLabel()"
    (completeMethod)="completeMethod($event)"
    (onSelect)="select($event)" />
  <span class="search-icon material-symbols-outlined text-blue-600">
    search
  </span>
</div>
