/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil
} from 'rxjs/operators';
import { isEqual } from 'lodash-es';

// Services
import { ApiService } from '@portal/app/shared/services/api.service';
import { ChannelsService } from '@portal/app/dashboard/home-page/services/channels.service';
import { FilterService } from '@portal/app/dashboard/home-page/services/filter.service';
import { FieldService } from '@portal/app/shared/services/field.service';
import { FormatService } from '@portal/app/dashboard/home-page/services/format.service';
import { MetricsService } from '@portal/app/dashboard/home-page/services/metrics.service';

// Configs
import { colors } from '@design-system/styles/colors';
import { baseChartConfig } from '@design-system/components/m-line-chart/m-line-chart.component.const';

// Types
import { DataEndpoint, Filter } from '@portal/app/shared/types';
import type { Metric } from '@portal/app/dashboard/home-page/services/metrics.service';
import type {
  Options,
  SeriesLineOptions,
  TooltipOptions,
  XAxisOptions,
  YAxisOptions
} from 'highcharts';
import { getGradientFill } from '@design-system/components/m-line-chart';

@Injectable({
  providedIn: 'root'
})
export class TrendingChartService implements OnDestroy {
  // Observables
  private chartDataSubject = new BehaviorSubject<any>(null);
  public chartData$ = this.chartDataSubject.asObservable();
  private destroy$ = new Subject<void>();
  private isLoadingSubject = new BehaviorSubject<boolean>(true);
  public isLoading$ = this.isLoadingSubject.asObservable();

  // Use baseChartConfig as the baseline configuration
  private singleLineChartOptions: Options = { ...baseChartConfig };

  private filtersSubject = new BehaviorSubject<Filter[]>([]);
  public filters$ = this.filtersSubject.asObservable();

  // Private vars
  private channels: string[] = [];
  private activeMetric: Metric | undefined;

  constructor(
    private readonly apiService: ApiService,
    private readonly channelsService: ChannelsService,
    private readonly filterService: FilterService,
    private readonly fieldService: FieldService,
    private readonly formatService: FormatService,
    private readonly metricService: MetricsService
  ) {
    this.subscribeToChanges();
  }

  private subscribeToChanges() {
    combineLatest([
      this.channelsService.activeChannel$,
      this.metricService.activeMetric$,
      this.filterService.filters$,
      this.channelsService.selectedChannels$
    ])
      .pipe(
        map(
          ([activeChannel, activeMetric, activeFilters, selectedChannels]) => ({
            activeChannel,
            activeMetric,
            activeFilters,
            selectedChannels
          })
        ),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        debounceTime(300),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: ({
          activeChannel,
          activeMetric,
          activeFilters,
          selectedChannels
        }) => {
          // Set global filters
          this.filtersSubject.next(activeFilters);

          this.channels = activeChannel ? [activeChannel] : selectedChannels;

          if (activeMetric) {
            this.activeMetric = activeMetric;
            this.fetchData(this.activeMetric, this.channels, activeFilters);
          }
        },
        error: (err: any) => console.error(err)
      });
  }

  public fetchDataWithTempFilters(relativeDay: string) {
    const activeFilters = this.filtersSubject.getValue();
    const filters = this.combineFilters(activeFilters, relativeDay);

    if (this.activeMetric) {
      this.fetchData(this.activeMetric, this.channels, filters);
    }
  }

  private combineFilters(
    activeFilters: Filter[],
    relativeDay: string
  ): Filter[] {
    const updatedDateFilters = this.filterService.getDateFilters(relativeDay);

    const allFilters = [...activeFilters, ...updatedDateFilters];

    const uniqueFiltersMap = allFilters.reduce((acc, filter) => {
      acc.set(filter.literalId, filter);
      return acc;
    }, new Map<string, Filter>());

    return Array.from(uniqueFiltersMap.values());
  }

  public getChartFilters(
    providedFilters: Filter[],
    chartDimensions: string[],
    channels?: string[]
  ): Filter[] {
    const neededFilterIds = [
      'dateStart',
      'dateStop',
      'relative_day',
      'conversion_type',
      'isInitialFilterCall',
      'resolution'
    ];

    const selectedFilters = providedFilters.filter((filter) =>
      neededFilterIds.includes(filter.literalId)
    );

    const hardcodedFilters = [
      this.filterService.createFilter({
        literalId: 'chartDimensions',
        type: 'string[]',
        value: chartDimensions
      }),
      this.filterService.createFilter({
        literalId: 'charts',
        type: 'string[]',
        value: ['chart']
      })
    ];

    if (channels && channels.length > 0) {
      const channelFilter = this.filterService.createFilter({
        literalId: 'channel',
        type: 'string[]',
        value: channels
      });
      const existingChannelFilterIndex = selectedFilters.findIndex(
        (filter) => filter.literalId === 'channel'
      );
      if (existingChannelFilterIndex !== -1) {
        selectedFilters[existingChannelFilterIndex] = channelFilter;
      } else {
        selectedFilters.push(channelFilter);
      }
    }

    const combinedFiltersMap = new Map(
      selectedFilters
        .concat(hardcodedFilters)
        .map((filter) => [filter.literalId, filter])
    );

    return Array.from(combinedFiltersMap.values());
  }

  fetchData(
    activeMetric: Metric,
    channels: string[],
    providedFilters: Filter[]
  ): void {
    this.isLoadingSubject.next(true);

    const chartDimensions = [activeMetric?.literalId] || [];
    const chartFilters = this.getChartFilters(
      providedFilters,
      chartDimensions,
      channels
    );

    this.apiService
      .getDashboardData({
        filters: chartFilters,
        type: DataEndpoint.dataCharts
      })
      .then((response) => {
        const key = chartDimensions[0] as string;
        const dataArray = (response as any)[0].data || [];
        const chartData = dataArray.map((item: any) => {
          const value = parseFloat(item[key]);
          return !isNaN(value) && isFinite(value) ? value : 0;
        });

        const categories = dataArray.map((item: any) =>
          dayjs.utc(item.date).format('MMM DD')
        );

        const updatedChartOptions = this.updateChartOptions(
          { chartData, categories },
          activeMetric
        );
        this.chartDataSubject.next(updatedChartOptions);
        this.isLoadingSubject.next(false);
      })
      .catch((error) => {
        console.error('Error fetching chart data:', error);
        this.isLoadingSubject.next(false);
      });
  }

  private updateChartOptions(
    chartDataResponse: any,
    activeMetric: Metric
  ): Options {
    const firstSeries = this.singleLineChartOptions?.series?.[0];
    const newSeriesData = this.formatSeriesData(
      firstSeries,
      chartDataResponse.chartData,
      activeMetric
    );
    const xAxisCategories = this.formatXAxisCategories(
      chartDataResponse.categories
    );
    const yAxisOptions = this.formatYAxisOptions(
      chartDataResponse.chartData,
      activeMetric
    );
    const tooltipOptions = this.formatTooltipOptions();

    return {
      ...this.singleLineChartOptions,
      series: [newSeriesData],
      xAxis: xAxisCategories,
      yAxis: yAxisOptions,
      tooltip: tooltipOptions,
      legend: {
        enabled: false
      }
    };
  }

  private formatSeriesData(
    firstSeries: any,
    chartData: any[],
    activeMetric: Metric
  ): SeriesLineOptions {
    return {
      ...firstSeries,
      data: chartData,
      name: activeMetric.title,
      color: colors['gray-800'],
      fillColor: getGradientFill(colors['gray-500'] as string)
    };
  }

  private formatXAxisCategories(categories: string[]): XAxisOptions {
    return {
      ...this.singleLineChartOptions?.xAxis,
      categories,
      crosshair: {
        width: 1.5,
        color: colors['gray-1100'],
        dashStyle: 'Dash'
      }
    };
  }

  private formatYAxisOptions(
    chartData: number[],
    activeMetric: Metric
  ): YAxisOptions {
    // Capture the service references in the outer scope
    const fieldService = this.fieldService;
    const formatService = this.formatService;

    const minValue = Math.min(...chartData);
    let maxValue = Math.max(...chartData);
    const buffer = maxValue * 0.25;
    maxValue += buffer;

    return {
      ...this.singleLineChartOptions?.yAxis,
      min: minValue,
      max: maxValue,
      labels: {
        formatter(this: Highcharts.AxisLabelsFormatterContextObject) {
          const value = this.value as number;
          const fieldDefinition = fieldService.getFieldDefinitionByLiteralId(
            activeMetric.literalId
          );
          return formatService.formatValue(fieldDefinition, value);
        }
      },
      crosshair: {
        width: 1,
        color: colors['gray-800'],
        dashStyle: 'Dash'
      }
    };
  }

  private formatTooltipOptions(): TooltipOptions {
    // Capture the service references in the outer scope
    const fieldService = this.fieldService;
    const formatService = this.formatService;

    return {
      useHTML: true,
      formatter(this: Highcharts.TooltipFormatterContextObject) {
        let tooltipHtml = `<div class="tooltip-body">`;
        tooltipHtml += `<span class="b3 text-gray-000 text-center block">${this.x}</span>`;
        tooltipHtml += `<div class="m-divider dark my-2"></div>`;

        if (this.y !== null && this.y !== undefined) {
          const seriesName = this.series.name;
          const fieldDef = fieldService.getFieldDefinitionByName(seriesName);
          const formattedValue = formatService.formatValue(fieldDef, this.y);

          tooltipHtml += `<div class="flex justify-between items-center text-gray-000">`;
          tooltipHtml += `<div><span style="color:${this.color}">\u25CF</span> ${seriesName}:</div>`;
          tooltipHtml += `<span>${formattedValue}</span>`;
          tooltipHtml += `</div>`;
        }

        tooltipHtml += `</div>`;
        return tooltipHtml;
      }
    };
  }

  public getRelativeDay(filters: Filter[]): string {
    const relativeDayFilter = filters.find(
      (filter) => filter.literalId === 'relative_day'
    );
    return relativeDayFilter?.value as string;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
