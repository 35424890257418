<m-banner
  *ngIf="mmmExportBannerService.showBannerSignal() && !alreadyShown"
  class="hero-banner"
  [type]="bannerConfig.type"
  [title]="
    mmmExportBannerService.showV1ModalSignal()
      ? bannerConfig.title
      : bannerConfig.titleNoDownloadResults
  "
  [closeable]="!mmmExportBannerService.showV1ModalSignal()"
  (closeBanner)="handleClose()"
  [creativeAlignment]="CreativeAlignment.center">
  <span
    action
    *ngIf="mmmExportBannerService.showV1ModalSignal()"
    class="banner-action-v1 block">
    <div class="b1 text-gray-800 mb-2">
      The upgraded model improves all aspects of your media measurement and
      optimization, <span class="b2 text-gray-1000">at no cost to you.</span>
      <br />
      Your account will be automatically switched over to the new model on
      <span class="b2 text-gray-1000">March 31, 2025.</span>
    </div>
    <div class="b2 text-gray-1000 mb-2">Why switch now?</div>
    <ul class="ml-4">
      <li class="b1 text-gray-800">
        <span class="b2 text-gray-1000">Built-in triangulation:</span> Always-on
        MMM and automatic calibration from your tests
      </li>
      <li class="b1 text-gray-800">
        <span class="b2 text-gray-1000">Advanced features:</span>  Get access to
        return curves, seasonality adjustments, and adstock effects
      </li>
      <li class="b1 text-gray-800">
        <span class="b2 text-gray-1000">Coming soon:</span> The new Media Plan
        Optimizer, new conversion types (retail, additional apps, Amazon)
      </li>
    </ul>

    <div class="mt-6 flex items-center gap-4">
      <m-button [type]="ButtonType.primary" (click)="toggleResultsModal()"
        >Review results now</m-button
      >

      @if (mmmExportBannerService.showSuccessMessageSignal()) {
        <m-tooltip
          content="Your customer success partner will follow <br /> up to discuss updating your model with you"
          [tooltipOptions]="{
            positionTop: -10,
          }">
          <div class="flex gap-2 cursor-pointer">
            <span class="material-symbols-outlined text-green-700 icon-regular"
              >check_circle</span
            >
            <div class="b1 text-gray-1000">Switch to new model requested</div>
          </div>
        </m-tooltip>
      } @else {
        <m-button [type]="ButtonType.secondary" (click)="toggleSwitchModal()">
          Switch to new model
        </m-button>
      }

      <a
        class="text-blue-600 no-underline hover:text-blue-500"
        href="https://support.measured.com/article/h62nuemky4-mim-overview"
        target="_blank">
        Learn more
      </a>
    </div>
  </span>

  <div
    creative
    class="w-full flex justify-center"
    *ngIf="mmmExportBannerService.showV1ModalSignal()">
    <a
      href="https://measured.zoom.us/webinar/register/3117325506849/WN_dBWMoyp2TRSCzH3yYjM2rg"
      target="_blank">
      <img src="/assets/images/creative/webinar.svg" alt="Webinar" />
    </a>
  </div>

  <span
    action
    *ngIf="!mmmExportBannerService.showV1ModalSignal()"
    class="banner-action-v2 block">
    <div class="b1 text-gray-700">
      Your results are now enhanced by the Measured Incrementality Model, which
      is powered by your media mix model. This gives you a full-portfolio view
      of your media spending's effectiveness by triangulating multiple data
      sources.
    </div>
    <div class="mt-6 flex align-center">
      <a
        class="text-blue-600 no-underline hover:text-blue-500"
        href="https://support.measured.com/article/h62nuemky4-mim-overview"
        target="_blank">
        Learn more about MIM
      </a>
    </div>
  </span>

  <div
    creative
    class="w-full flex justify-center"
    *ngIf="!mmmExportBannerService.showV1ModalSignal()">
    <img src="/assets/images/creative/mim-v2.svg" alt="MIM Banner" />
  </div>
</m-banner>

@if (showResultsModal) {
  <div
    class="results-modal fixed w-full h-full bg-gray-200 top-0 left-0 p-6 overflow-scroll"
    [ngClass]="{ 'pb-28': !mmmExportBannerService.isLoadingSignal() }"
    [@slideInOut]>
    <m-card
      [styleClass]="!mmmExportBannerService.isLoadingSignal() ? '!h-auto' : ''">
      <div header class="flex content-between justify-between w-full">
        <div class="h4 text-gray-1000">Your MIM v2 Metrics</div>
        <span
          class="material-symbols-outlined text-blue-600 icon-regular cursor-pointer"
          (click)="toggleResultsModal()"
          >close</span
        >
      </div>
      @if (mmmExportBannerService.isLoadingSignal()) {
        <div
          class="w-full h-full flex flex-wrap content-center items-center justify-center">
          <div
            class="w-[276px] flex flex-column flex-wrap gap-3 content-center items-center justify-center">
            <img
              src="assets/images/ufo-loader.svg"
              width="180px"
              alt="Loading" />
            <div class="dp2 text-gray-1000">Getting your new MIM data...</div>
            <div class="w-full">
              <m-progress-bar
                mode="indeterminate"
                styleClass="w-full indeterminate"></m-progress-bar>
            </div>
          </div>
        </div>
      } @else {
        <div>
          <div class="my-6">
            <!-- Filters -->
            <div class="flex items-center gap-2 w-full mb-6">
              <m-calendar
                view="date"
                [inline]="false"
                [numberOfMonths]="2"
                [firstDayOfWeek]="firstDayOfWeek"
                selectionMode="range"
                resolution="day"
                [ngModel]="mmmExportBannerService.selectedDatesSignal()"
                [showIcon]="true"
                (onSelect)="onDateSelect($event)"
                [minDate]="minDate"
                [maxDate]="maxDate"></m-calendar>

              <m-dropdown
                [options]="mmmExportBannerService.conversionTypeOptionsSignal()"
                [ngModel]="
                  mmmExportBannerService.selectedConversionTypeSignal()
                "
                (formControlChange)="
                  onConversionTypeChange($event?.value)
                "></m-dropdown>

              <m-multiselect
                [draggable]="false"
                [isValid]="true"
                [maxSelectedLabels]="0"
                [(ngModel)]="selectedChannels"
                [options]="mmmExportBannerService.channelOptionsSignal()"
                [selectedItemsLabel]="'{0} Selected Channels'"
                (onPanelHide)="channelPanelHide()"
                appendTo="body">
              </m-multiselect>

              <m-multiselect
                [draggable]="false"
                [isValid]="true"
                [maxSelectedLabels]="0"
                [(ngModel)]="selectedTactics"
                [options]="mmmExportBannerService.tacticOptionsSignal()"
                [selectedItemsLabel]="'{0} Selected Tactics'"
                (onPanelHide)="tacticPanelHide()"
                appendTo="body">
              </m-multiselect>
            </div>

            @if (value.length) {
              <m-card variant="small">
                <div class="flex items-center">
                  <span
                    class="material-symbols-outlined text-blue-400 icon-extra-large mr-2"
                    >monitoring</span
                  >

                  <span class="sh1 text-gray-1000">
                    The new MIM shows
                    <span class="sh2"
                      >your media’s contribution to your business is
                      {{
                        mmmExportBannerService.mediaContributionSignal()
                      }}</span
                    >, which was previously
                    {{
                      mmmExportBannerService.previousMediaContributionSignal()
                    }}.
                    <br />
                    This has led to an
                    <span class="sh2"
                      >overall incremental
                      {{ mmmExportBannerService.viewBySignal() }} of
                      {{
                        mmmExportBannerService.incrementalMetricNewSignal()
                      }}</span
                    >, which was previously
                    {{
                      mmmExportBannerService.incrementalMetricPreviousSignal()
                    }}.
                  </span>
                </div>
              </m-card>
            }
          </div>

          <div class="flex justify-between w-full mb-6">
            <div>
              <m-tab-view
                [headerSize]="headerSize"
                [tabPanelHeaders]="tabPanelHeaders"
                (activeIndexChange)="onTabChange($event)"
                [activeIndex]="activeIndex"></m-tab-view>
            </div>
            <m-multiselect
              [draggable]="false"
              [isValid]="true"
              [maxSelectedLabels]="0"
              [ngModel]="mmmExportBannerService.selectedMetricsSignal()"
              (ngModelChange)="onMetricsChange($event)"
              [options]="mmmExportBannerService.metricOptionsSignal()"
              [selectedItemsLabel]="'{0} Selected Metrics'"
              appendTo="body"
              panelStyleClass="-ml-10">
            </m-multiselect>
          </div>

          <!-- Table -->
          <div>
            <p-table
              class="m-table"
              [value]="value"
              [responsive]="true"
              sortMode="single"
              [sortField]="defaultSortField"
              [sortOrder]="defaultSortOrder">
              <ng-template pTemplate="header">
                <tr>
                  <th
                    *ngFor="let col of columns"
                    [ngClass]="col?.class"
                    scope="col"
                    [pSortableColumn]="col?.sortable ? col?.sortField : null">
                    <div class="flex">
                      <div [innerHTML]="col?.header"></div>
                      <p-sortIcon
                        *ngIf="col?.sortable"
                        [field]="col?.sortField"></p-sortIcon>
                    </div>
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td *ngFor="let col of columns" [ngClass]="col?.class">
                    <div [innerHTML]="rowData[col?.field]"></div>
                  </td>
                </tr>
              </ng-template>

              <ng-template pTemplate="footer">
                <tr>
                  <td *ngFor="let col of columns">
                    <div [innerHTML]="footerData[col?.field]"></div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      }
    </m-card>
    @if (!mmmExportBannerService.isLoadingSignal()) {
      <div class="fixed left-0 bottom-0 w-full z-10">
        <div
          class="flex items-center justify-end p-6 w-full bg-gray-000 shadow-base border border-solid border-gray-300">
          <m-dropdown
            placeholder="Export Data"
            [options]="exportOptions"
            [(ngModel)]="exportOption"
            (formControlChange)="onExportChange($event?.value)"
            [preventSelection]="true"
            [appendTo]="'body'"
            [responsiveOverlayDirection]="'down'"
            width="10rem"></m-dropdown>
        </div>
      </div>
    }
  </div>
}

<m-modal
  [dismissableMask]="true"
  [(visible)]="showSwitchModal"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [noPadding]="true"
  [style]="{ width: '800px' }">
  <ng-container modalHeader *ngIf="!showCongrats">
    <h4 class="h4 mb-0 mr-2">Switching to the new model</h4>
  </ng-container>

  <!-- Modal Body -->
  <div
    class="flex gap-6 content-center justify-center items-center"
    *ngIf="!showCongrats">
    <div>
      <img
        src="assets/images/creative/new-feature.svg"
        alt="Switch to new modal" />
    </div>
    <div>
      <div class="sh1 text-gray-1000 mb-4">
        With the new model, your account is fully powered by MMM! If you choose
        to switch, your customer success partner will follow up to discuss the
        next steps with you.
      </div>
    </div>
  </div>

  <ng-container modalFooter *ngIf="!showCongrats">
    <m-button
      [type]="ButtonType.secondary"
      (click)="toggleSwitchModal()"
      class="mr-4"
      >Cancel</m-button
    >
    <m-button [type]="ButtonType.primary" (click)="switchToNewModel()"
      >Request switch</m-button
    >
  </ng-container>
  @if (showCongrats) {
    <!-- Modal Body -->
    <div class="flex flex-col gap-6 content-center justify-center items-center">
      <div>
        <img
          src="assets/images/creative/thank-you.svg"
          width="160px"
          alt="Thanks" />
      </div>

      <div class="text-center">
        <div class="h4 text-gray-1000 mb-2">Switch requested!</div>

        <div class="sh1 text-gray-1000">
          Your customer success partner will reach out with more details.
        </div>
      </div>

      <m-button [type]="ButtonType.primary" (click)="toggleSwitchModal()"
        >Got it</m-button
      >
    </div>
  }
</m-modal>
