import { Component, Input } from '@angular/core';

@Component({
  selector: 'portal-previous-value',
  templateUrl: './previous-value.component.html'
})
export class PreviousValueComponent {
  @Input() value = '';
  @Input() isUp = true;
  @Input() alignment: 'left' | 'right' = 'left';

  get isPositive() {
    return !this.value.startsWith('-');
  }
}
