<p-carousel
  class="m-portfolio-carousel"
  [styleClass]="styleClass"
  [value]="value"
  [numVisible]="numVisible"
  [numScroll]="numScroll"
  [circular]="circular"
  [responsiveOptions]="responsiveOptions"
  [showIndicators]="showIndicators"
  [showNavigators]="showNavigators"
  (onPage)="onPage.emit($event)">
  <ng-template let-item pTemplate="item">
    <div class="p-carousel-item-wrapper">
      <m-metric-card
        [metric]="item"
        (cardClick)="onItemClick(item)"
        variant="standard"></m-metric-card>
    </div>
  </ng-template>
</p-carousel>
