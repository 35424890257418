import {
  Brand,
  BrandRow,
  Client,
  ClientType,
  CurrentStatus
} from '@portal/app/shared/types';

const getStaticRow = (
  name: string,
  clientId: number,
  brandId: number
): BrandRow => {
  const brand: Brand = {
    currencyCode: null,
    currencyFormat: null,
    currencyLocale: null,
    brandId,
    brandName: name,
    clientId,
    status: CurrentStatus.active,
    firstDayOfWeek: 0,
    rCreId: '',
    rCreTime: new Date(),
    rModId: null,
    rModTime: null
  };
  const client: Client = {
    clientId,
    clientName: name,
    clientType: ClientType.enterprise,
    referenceId: 0,
    referenceIdLiteral: '0',
    status: CurrentStatus.active,
    rCreId: '',
    rCreTime: new Date(),
    rModId: null,
    rModTime: null,
    brands: [brand],
    cloudAccountId: ''
  };
  return {
    clientId: brand.clientId,
    brandId: brand.brandId,
    name: brand.brandName,
    status: brand.status,
    client,
    brand
  };
};

export const getStaticRows = (): BrandRow[] => [
  getStaticRow('Development', 1001, 999),
  getStaticRow('Development: RT3-LT', 1001, 998)
];

// eslint-disable-next-line no-shadow
export enum VendorIdSource {
  facebook = 'facebook',
  criteo = 'criteo',
  shopify = 'shopify',
  adwords = 'adwords',
  ga = 'ga',
  pinterest = 'pinterest',
  tiktok = 'tiktok',
  klaviyo = 'klaviyo',
  bing = 'bing',
  snapchat = 'snapchat',
  cj = 'cj',
  impact = 'impact',
  impactTransaction = 'impactTransaction',
  tradedesk = 'tradedesk',
  shareasale = 'shareasale',
  reddit = 'reddit',
  pepperjam = 'pepperjam',
  ga4 = 'ga4',
  dbm = 'dbm',
  proprietarysot = 'proprietarysot',
  amazon = 'amazon',
  steelhouse = 'steelhouse',
  applovin = 'applovin'
}

export const vendorReportSegmentField = {
  klaviyo: 'Metric Id',
  criteo: 'Advertiser ID',
  pepperjam: 'Program Id'
};

// eslint-disable-next-line no-shadow
export enum IntegrationTypes {
  marketing = 'marketing',
  transaction = 'transaction'
}

/* eslint-disable @typescript-eslint/naming-convention*/
export const vendorCompositeDataSourceMapping: Record<string, VendorIds> = {
  'impact-radius': 'impact',
  'impact-radius-transactions': 'impactTransaction',
  transactionraw: 'proprietarysot',
  Steelhouse: 'steelhouse'
};
/* eslint-disable @typescript-eslint/naming-convention */

export type VendorIds =
  | 'facebook'
  | 'criteo'
  | 'shopify'
  | 'adwords'
  | 'ga'
  | 'pinterest'
  | 'tiktok'
  | 'klaviyo'
  | 'bing'
  | 'snapchat'
  | 'cj'
  | 'impact'
  | 'impactTransaction'
  | 'tradedesk'
  | 'shareasale'
  | 'reddit'
  | 'pepperjam'
  | 'ga4'
  | 'dbm'
  | 'proprietarysot'
  | 'amazon'
  | 'steelhouse'
  | 'applovin';

export const DATE_FORMAT = 'MMM DD, YYYY';
export const DATE_FILTER_FORMAT = 'YYYY-MM-DD';

export const CONNECTED_ACCOUNTS_LABEL = ' - Connected';

export const callbackDomain = (): string => {
  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1'
  ) {
    return `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
  }
  return `${window.location.protocol}//${window.location.hostname}`;
};

export const INTEGRATIONS_CLIENT_ID_KEY = '__integrations_client_id__';
export const INTEGRATIONS_BRAND_ID_KEY = '__integrations_brand_id__';
export const VENDORS_WHO_HAVE_CUSTOM_INFO_MESSAGE: Record<string, string> = {
  tradedesk: 'tradedesk',
  klaviyo: 'klaviyo',
  bing: 'bing'
};
