import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts';
import { cloneDeep } from 'lodash-es';
import { IChartOptions } from '@portal/app/charts/shared/chart-model';
import {
  CHART_COLORS,
  chartFontFamily
} from '@portal/app/charts/shared/chart-constants';
import { chartToolTipFormatter } from '@portal/app/charts/shared/utils';
import { ChartDataElement } from '@portal/app/shared/types';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { Subscriber } from 'rxjs/internal/Subscriber';
import { FormatterService } from '@portal/app/shared/services/formatter.service';
import { isMeasuredBenchmark } from '@portal/app/dashboard/utils';
import { colors } from '@design-system/styles/colors';

@Injectable({
  providedIn: 'root'
})
export class ChartService {
  private readonly geoExperimentChartTable: BehaviorSubject<
    ChartDataElement | undefined
  > = new BehaviorSubject<ChartDataElement | undefined>(undefined);

  get geoExperimentChartTableData(): Observable<ChartDataElement | undefined> {
    return this.geoExperimentChartTable.asObservable();
  }

  private readonly defaultChartOptions: IChartOptions = {
    type: '',
    xAxisText: '',
    xAxisType: 'linear',
    xAxisPropertyField: '',
    yAxisText: '',
    yAxisPropertyFields: [],
    enableDataLabels: false,
    axisSeriesOption: [],
    data: [],
    resolutionOptions: [],
    selectedResolution: ''
  };

  private readonly defaultChartConfigs = (
    firstDayOfWeek?: number
  ): Highcharts.Options => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    return {
      chart: {
        style: {
          fontFamily: chartFontFamily
        }
      },
      lang: {
        thousandsSep: ','
      },
      title: {
        text: undefined
      },
      yAxis: [],
      xAxis: {
        startOfWeek: firstDayOfWeek,
        alignTicks: true,
        crosshair: true,
        type: 'datetime',
        maxPadding: 0.02,
        minPadding: 0.02,
        title: {
          useHTML: true
        },
        labels: {
          useHTML: true
        },
        lineColor: colors['gray-300']
      },
      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'top',
        useHTML: true,
        symbolHeight: 0,
        symbolWidth: 0,
        symbolRadius: 0,
        labelFormatter(): string {
          return ` <span class="flex items-center">
            <span class="inline-block w-2 h-2 mr-1 rounded-full" style="background-color: ${this?.color};"></span>
            <span class="c1 text-gray-800 block z-0">${this?.name}</span>
          </span>`;
        }
      },
      colors: CHART_COLORS,
      plotOptions: {
        series: {
          label: {
            connectorAllowed: false
          },
          lineWidth: 2,
          marker: {
            enabled: false,
            symbol: 'circle',
            states: {
              hover: {
                fillColor: '#ffffff',
                lineWidth: 2,
                radius: 4,
                enabled: true
              }
            }
          },
          pointStart: 0,
          connectNulls: true
        }
      },
      series: [],
      tooltip: {
        shared: true,
        useHTML: true,
        backgroundColor: '#111111',
        borderColor: 'transparent',
        formatter() {
          const points = this.points || [];
          return chartToolTipFormatter(
            points,
            'Month',
            {},
            self.formatterService
          );
        }
      },
      credits: {
        enabled: false
      }
    };
  };

  constructor(private formatterService: FormatterService) {}

  setGeoExperimentChartTableData(data: ChartDataElement | undefined): void {
    const observable = new Observable(
      (subscriber: Subscriber<ChartDataElement>) => {
        subscriber.next(data);
        subscriber.complete();
      }
    );

    observable.subscribe({
      next: () => {
        this.geoExperimentChartTable.next(data);
      }
    });
  }

  getDefaultChartConfig(
    productId?: string,
    firstDayOfWeek?: number
  ): Highcharts.Options {
    return productId
      ? this.getChartConfigByProductId(productId, firstDayOfWeek)
      : cloneDeep(this.defaultChartConfigs(firstDayOfWeek));
  }

  getDefaultChartOptions(): IChartOptions {
    return cloneDeep(this.defaultChartOptions);
  }

  getChartConfigByProductId(
    productId: string,
    firstDayOfWeek?: number
  ): Highcharts.Options {
    const config = cloneDeep(this.defaultChartConfigs(firstDayOfWeek));
    if (productId === 'cross-platform-doe') {
      config.legend = { ...config.legend, verticalAlign: 'bottom' };
      config.exporting = {
        ...config.exporting,
        enabled: false
      };
    } else if (isMeasuredBenchmark(productId)) {
      this.updateChartConfigForBenchmarks(config, productId);
    }
    return cloneDeep(config);
  }

  updateChartConfigForBenchmarks(
    config: Highcharts.Options,
    productId: string
  ): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const axisLabelStyle = {
      style: {
        color: '#354358',
        fontSize: '14px'
      }
    };

    config.xAxis = {
      ...config.xAxis,
      crosshair: {
        width: 1,
        color: '#354358',
        dashStyle: 'ShortDot'
      },
      labels: axisLabelStyle
    };

    config.legend = {
      ...config.legend,
      symbolWidth: 8,
      symbolPadding: 10,
      itemStyle: {
        color: '#354358',
        fontSize: '14px',
        fontWeight: '400'
      }
    };

    config.tooltip = {
      ...config.tooltip,
      shadow: false,
      borderRadius: 5,
      backgroundColor: '#354358',
      borderColor: 'transparent',
      formatter() {
        const points = this.points || [];
        return chartToolTipFormatter(
          points,
          'DateMonthYear',
          {},
          self.formatterService,
          productId
        );
      }
    };

    config.exporting = {
      enabled: false
    };

    if (
      config.plotOptions &&
      config.plotOptions.series &&
      config.plotOptions.series.marker &&
      config.plotOptions.series.marker.states &&
      config.plotOptions.series.marker.states.hover
    ) {
      config.plotOptions.series.marker.states.hover.lineWidth = 4;
    }
  }
}
