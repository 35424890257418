import { lastValueFrom } from 'rxjs';
import { ILastTouchSettingApiOperation } from '../models/ILastTouchSettingApiOperation';
import { HttpClient } from '@angular/common/http';
import { IClientAttributionSetting } from '../models/IClientAttributionSetting';
import { IGlobalChannelTactic } from '../models/IGlobalChannelTactic';
import { IStandardAttributeSetting } from '../models/IStandardAttributeSetting';
import { Utility } from '../helpers/utility';

const promiseFrom = lastValueFrom;

export class LastTouchSettingApis implements ILastTouchSettingApiOperation {
  private constructor(
    private readonly httpClient: HttpClient,
    private readonly apiV1: string,
    private readonly apiV2: string
  ) {}

  public static lastTouchSettingApiOperation(
    httpClient: HttpClient,
    apiV1: string,
    apiV2: string
  ): ILastTouchSettingApiOperation {
    return new LastTouchSettingApis(httpClient, apiV1, apiV2);
  }

  public async getAllClientAttributionSettings(
    clientId: number,
    brandId?: number
  ): Promise<IClientAttributionSetting[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/client-attribution`,
      queryParameters: {
        clientId,
        brandId: brandId || null
      }
    });
    return promiseFrom(this.httpClient.get<IClientAttributionSetting[]>(url));
  }

  public async getClientAttributionSettings(
    clientId: number,
    brandId?: number
  ): Promise<IClientAttributionSetting[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/client-attribution/attribution-settings`,
      queryParameters: {
        clientId,
        brandId: brandId || null
      }
    });
    return promiseFrom(this.httpClient.get<IClientAttributionSetting[]>(url));
  }

  public async getAllStandardAttributionSettings(): Promise<
    IStandardAttributeSetting[]
  > {
    const url = Utility.buildURL({
      url: `${this.apiV1}/standard-attribution`
    });
    return promiseFrom(this.httpClient.get<IStandardAttributeSetting[]>(url));
  }

  public async getAllGlobalChannelTactic(): Promise<IGlobalChannelTactic[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/global-channel-tactic`
    });
    return promiseFrom(this.httpClient.get<IGlobalChannelTactic[]>(url));
  }

  public async createClientAttributeSettings(
    clientAttributeSettings: IClientAttributionSetting
  ): Promise<IClientAttributionSetting> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/client-attribution`
    });
    return promiseFrom(
      this.httpClient.post<IClientAttributionSetting>(
        url,
        clientAttributeSettings
      )
    );
  }

  public async updateClientAttributeSettings(
    id: number,
    clientAttributeSettings: IClientAttributionSetting
  ): Promise<IClientAttributionSetting> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/client-attribution/:id`,
      pathVariables: { id }
    });
    return promiseFrom(
      this.httpClient.put<IClientAttributionSetting>(
        url,
        clientAttributeSettings
      )
    );
  }

  public async createStandardAttributeSettings(
    standardAttributeSettings: IStandardAttributeSetting
  ): Promise<IStandardAttributeSetting> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/standard-attribution`
    });
    return promiseFrom(
      this.httpClient.post<IStandardAttributeSetting>(
        url,
        standardAttributeSettings
      )
    );
  }

  public async updateStandardAttributeSettings(
    id: number,
    standardAttributeSettings: IStandardAttributeSetting
  ): Promise<IStandardAttributeSetting> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/standard-attribution/:id`,
      pathVariables: { id }
    });
    return promiseFrom(
      this.httpClient.put<IStandardAttributeSetting>(
        url,
        standardAttributeSettings
      )
    );
  }
}
