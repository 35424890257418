/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { isEmpty, find, get } from 'lodash-es';

// Services
import { DataSetService } from '@portal/app/dashboard/home-page/services/data-set.service';

// Types
import { FieldDefinitions, Filter } from '@portal/app/shared/types';
import {
  IMmmModalResponse,
  MmmBannerService
} from '@portal/app/shared/components/mmm-banner/mmm-banner.service';
import { MmmModalDetailsService } from '@portal/app/shared/components/mmm-model-details/mmm-modal-details.service';
import { ViewStore } from '@portal/app/shared/state/view.store';
import { take } from 'rxjs';

@Component({
  selector: 'portal-mmm-banner',
  templateUrl: './mmm-banner.component.html',
  styleUrls: ['./mmm-banner.component.scss']
})
export class MmmBannerComponent implements OnInit, OnChanges {
  conversionType = '';
  startDate = '';
  endDate = '';
  spend = 0;
  noOfContributingTactics = 0;
  mimModelVersion = 1;

  @Input() mmmTooltip = '';
  @Input() filters: Filter[] = [];
  @Input() brandId = 0;
  @Input() fieldDefinitions: FieldDefinitions = {};
  literalId = '';

  constructor(
    private readonly dataSetService: DataSetService,
    private readonly mmmBannerService: MmmBannerService,
    private readonly mmmModalDetailsService: MmmModalDetailsService,
    private readonly viewStore: ViewStore
  ) {}

  ngOnInit() {
    this.viewStore.selectedBCPDId.pipe(take(1)).subscribe((value) => {
      this.literalId = value.literalId;
    });
    this.fetchData(this.filters);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filters) {
      this.fetchData(changes.filters.currentValue);
    }
  }

  navigateToMmmDoc(): void {
    window.open(
      'https://support.measured.com/article/h62nuemky4-mim-overview',
      '_blank'
    );
  }

  shouldShowModal() {
    return this.mimModelVersion === 2 && this.literalId === 'cross-channel';
  }

  showModalDetails() {
    if (this.shouldShowModal()) {
      this.mmmModalDetailsService.showModalSignal.set(true);
    } else {
      this.navigateToMmmDoc();
    }
  }

  fetchData(filters: Filter[]) {
    const currentFilters = this.mmmBannerService.getFilters(
      filters,
      this.brandId
    );
    const conversionTypeValue = get(
      find(currentFilters, { literalId: 'conversion_type' }),
      'value'
    );

    this.dataSetService
      .fetchDataSets({
        dimensions: [],
        filters: currentFilters,
        dashboardLiteralId: 'home-page',
        productLiteralId: 'portfolio'
      })
      .subscribe({
        next: (response) =>
          this.processResponse(response, conversionTypeValue as string),
        error: (err) => console.error(err)
      });
  }

  private processResponse(response: any, conversionTypeValue: string) {
    if (!isEmpty(response)) {
      const formattedResponse = find(
        this.mmmBannerService.formatData(response, this.fieldDefinitions),
        { conversionType: conversionTypeValue }
      ) as IMmmModalResponse;

      if (formattedResponse) {
        const {
          conversionType,
          startDate,
          endDate,
          spend,
          noOfContributingTactics,
          mimModelVersion
        } = formattedResponse;
        Object.assign(this, {
          conversionType,
          startDate,
          endDate,
          spend,
          noOfContributingTactics,
          mimModelVersion: parseInt(mimModelVersion.toString(), 10)
        });
        this.updateTooltip();
      }
    }
  }

  updateTooltip() {
    this.mmmTooltip = this.mmmBannerService.generateTooltip(
      this.conversionType,
      this.startDate,
      this.endDate,
      this.spend,
      this.noOfContributingTactics,
      this.mimModelVersion
    );
  }
}
