import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Types
import { FieldDefinitions, FieldDefinition } from '@portal/app/shared/types';

@Injectable({
  providedIn: 'root'
})
export class FieldService {
  private fieldsSubject = new BehaviorSubject<FieldDefinitions>({});
  fields$ = this.fieldsSubject.asObservable();

  getCurrentFields(): FieldDefinitions {
    return this.fieldsSubject.getValue();
  }

  setFields(newFields: FieldDefinitions): void {
    this.fieldsSubject.next(newFields);
  }

  fieldExists(literalId: string): boolean {
    const fields = this.fieldsSubject.getValue();
    return Object.keys(fields).some(
      (key) => fields[key]?.literalId === literalId
    );
  }

  getFieldDefinitionByLiteralId(literalId: string): FieldDefinition | null {
    const fields = this.fieldsSubject.getValue();
    return fields[literalId] || null;
  }

  getFieldDefinitionByName(name: string): FieldDefinition | null {
    const fields = this.fieldsSubject.getValue();
    const fieldEntry = Object.entries(fields).find(
      (field) => (field[1] || { label: '' }).label === name
    );
    return fieldEntry ? fieldEntry[1] : null;
  }
}
