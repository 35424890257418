import { Component, Input } from '@angular/core';
import { LayoutTypes } from '@portal/app/shared/types';

@Component({
  selector: 'portal-seperator',
  templateUrl: './seperator.component.html'
})
export class SeperatorComponent {
  @Input() layoutType?: string;
  public layoutTypes = LayoutTypes;
}
