import { createAction, props } from '@ngrx/store';
import {
  DataGridSortedColumn,
  ElementGroup,
  Filter,
  HeroElement
} from '@portal/app/shared/types';
import { DashboardState } from '@portal/app/store/app.state';

const setDashboard = createAction(
  '[Dashboard] setDashboard',
  props<DashboardState>()
);

const setCopiedFilters = createAction(
  '[Dashboard] setCopiedFilters',
  props<{ copiedFilters?: Filter[] }>()
);

const setUpdatedFilters = createAction(
  '[Dashboard] setUpdatedFilters',
  props<{ updatedFilters?: Filter[] }>()
);

const clearUpdatedFilters = createAction('[Dashboard] clearUpdatedFilters');

const setDimensionFormat = createAction(
  '[Dashboard] setDimensionFormat',
  props<{ dimensionFormat: string }>()
);

const updateLayout = createAction(
  '[Dashboard] updateLayout',
  props<{ layout?: ElementGroup[] }>()
);

const setFilter = createAction(
  '[Dashboard] setFilter',
  props<{ filter: Filter | null }>()
);

const setHeroGroup = createAction(
  '[Dashboard] setHeroGroup',
  props<{ heroGroupItems: HeroElement[]; heroGroupName: string }>()
);

const setDataGrid = createAction(
  '[Dashboard] setDataGrid',
  props<{
    dataGridSelected: string[];
    dataGridElement: string;
    orderChange: boolean;
  }>()
);

const setChart = createAction(
  '[Dashboard] setChart',
  props<{ chartSelectedY: string[]; chartElement: string }>()
);

const setDataGridSort = createAction(
  '[Dashboard] setDataGridSort',
  props<{
    dashboardId: number;
    dashboardLayoutId: number;
    dashboardLayoutLiteralId: string;
    sortedColumns: DataGridSortedColumn[];
  }>()
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const DashboardActions = {
  setDashboard,
  setCopiedFilters,
  setUpdatedFilters,
  clearUpdatedFilters,
  setDimensionFormat,
  updateLayout,
  setChart,
  setDataGrid,
  setDataGridSort,
  setHeroGroup,
  setFilter
};
