import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Filter } from '@portal/app/shared/types';
import { ViewStore } from '@portal/app/shared/state/view.store';
import { take } from 'rxjs';

@Component({
  selector: 'portal-radio-button-bar',
  templateUrl: './radio-bar.component.html',
  styleUrls: ['./radio-bar.component.scss']
})
export class RadioBarComponent implements OnInit {
  @Output() valueChange: EventEmitter<Filter> = new EventEmitter<Filter>();
  @Input() leftText = '';
  @Input() rightText = '';
  @Input() options: string[] | number[] = [];
  @Input() selectedValue!: string;
  @Input() filter?: Filter;
  @Input() isDisabled = false;

  public literalId = '';

  constructor(private readonly viewStore: ViewStore) {}

  ngOnInit(): void {
    this.viewStore.selectedBCPDId.pipe(take(1)).subscribe((value) => {
      this.literalId = value.literalId;
    });
  }

  onValueChange() {
    if (this.filter) {
      this.filter.value = this.selectedValue;
      this.valueChange.emit(this.filter);
    }
  }
}
