export enum FiltersType {
  STARTS_WITH = 'startsWith',
  CONTAINS = 'contains',
  ENDS_WITH = 'endsWith',
  EQUALS = 'equals',
  NOT_EQUALS = 'notEquals',
  IN = 'in',
  NOT_IN = 'notIn',
  NOT_CONTAINS = 'notContains'
}
