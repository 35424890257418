<div class="row result-accordion DASHBOARD_DETAIL">
  <div class="col-md-6"></div>
  <div class="col-md-6">
    <p-accordion
      expandIcon="pi pi-fw pi-chevron-down"
      collapseIcon="pi pi-fw pi-chevron-up">
      <p-accordionTab
        header="{{ nativeSectionsService.asString(item?.label) }}">
        <div class="row">
          <div *ngFor="let ele of elements" class="col-{{ ele.span }}">
            <label class="label-format fs-small pb-3"
              >{{ fieldDefinitions[ele.literalId].label }}
            </label>
          </div>
          <div
            *ngFor="let ele of elements"
            class="col-{{ ele.span }} result-detail fs-small">
            <div *ngFor="let dataValue of getDetails(ele)" class="fw-boldish">
              {{ dataValue }}
            </div>
          </div>
        </div>
      </p-accordionTab>
    </p-accordion>
  </div>
</div>
