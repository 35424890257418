import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { compact, filter, find, map, sortBy } from 'lodash-es';
import {
  DataEndpoint,
  DataGridConfig,
  DataGridElement,
  DataGridSelected,
  DataResponse,
  DataSetResponse,
  ElementGroup,
  ElementSubType,
  ExperimentCaption,
  ExperimentComparison,
  ExperimentDataElement,
  ExperimentExpansion,
  ExperimentResultData,
  ExperimentResultResponse,
  FbScaleResult,
  FieldDefinitions,
  Filter,
  FilterValue,
  IPivotRows,
  IStaticPivotConfig,
  ITableField
} from '@portal/app/shared/types';
import { NativeSectionsService } from '@portal/app/shared/services/native-sections.service';
import { ContextStore } from '@portal/app/shared/state/context.store';
import { ApiService } from '@portal/app/shared/services/api.service';
import { DataGridStore } from '@portal/app/datagrid/store/data-grid.store';
import { Observable, Subscription, take } from 'rxjs';
import {
  sortByDisplayOrder,
  sortByHighlight
} from '@portal/app/dashboard/utils';
import { ViewStore } from '@portal/app/shared/state/view.store';

@Component({
  selector: 'portal-experiment-result-data',
  templateUrl: './experiment-result-data.component.html',
  styleUrls: ['./experiment-result-data.component.scss']
})
export class ExperimentResultDataComponent implements OnInit, OnDestroy {
  @Input() item: ExperimentDataElement | undefined = undefined;
  @Input() fieldDefinitions: FieldDefinitions = {};

  public initialLayoutLiteralId = '';
  public layouts: ExperimentDataElement[] = [];
  public currentLayout: ExperimentDataElement | undefined = undefined;
  public data: ExperimentResultData[] = [];
  public dataGridConfig: DataGridConfig = {
    ...this.dataGridStore.getDataGridTableConfig(),
    tableScrollHeightClass: 'medium',
    customSort: false,
    showFooter: false
  };

  public layoutSelectionFilterElements: ElementSubType[] = [];
  public showExpansionSection = true;
  public loading = false;
  public literalId = '';
  private readonly subscriptions: Subscription = new Subscription();
  filterContext$: Observable<Filter[]> = this.contextStore.filterContext;
  exportFileName: string | undefined = undefined;
  groupByField = '';
  shouldExpandAllAtLoad = false;
  fields: ITableField[] = [];
  exportFields: ITableField[] = [];
  tableData: DataResponse[] = [];
  expansionData: {
    pivotFields: ITableField[];
    editableFields: ITableField[];
    data: DataResponse[];
    pivotRows: IPivotRows[];
    exportPivotRows: IPivotRows[];
  } = {
    pivotFields: [],
    editableFields: [],
    data: [],
    pivotRows: [],
    exportPivotRows: []
  };

  captionFields: ITableField[] = [];
  filters: Filter[] = [];
  emptyMessage = '';
  comparisionTableName = '';
  expansionTableName = '';
  dataPoints: DataResponse = {};

  constructor(
    public readonly nativeSectionsService: NativeSectionsService,
    private readonly contextStore: ContextStore,
    private readonly apiService: ApiService,
    private readonly dataGridStore: DataGridStore,
    private readonly viewStore: ViewStore
  ) {}

  ngOnInit(): void {
    this.viewStore.selectedBCPDId.pipe(take(1)).subscribe((value) => {
      this.literalId = value.literalId;
    });
    if (this.item && this.item.EXPERIMENT_LAYOUT_SELECTION_FILTER) {
      this.layoutSelectionFilterElements =
        this.item.EXPERIMENT_LAYOUT_SELECTION_FILTER.selected.sort(
          sortByDisplayOrder
        );
    }
    if (this.item && this.item.EXPERIMENT_LAYOUT_EXPANSION_SECTION_VISIBILITY) {
      this.showExpansionSection = this.getShowExpansionSectionValue();
    }
    const sub: Subscription = this.filterContext$.subscribe({
      next: (filters: Filter[]) => {
        this.filters = filters;
        this.loading = true;
        this.initialLayoutLiteralId = this.prepareLayoutLiteralId(filters);
        this.getExperimentLayout();
        if (this.item && this.item.EXPERIMENT_SIGNIFICANCE_FILTER) {
          this.emptyMessage = 'There are no ad sets in this view';
          const dataRequest =
            this.item?.EXPERIMENT_SIGNIFICANCE_FILTER.selected[0]
              ?.dashboardFilterId;
          if (dataRequest === 'significantRequest') {
            (
              filters[this.getIndex(filters, 'significantRequest')] as Filter
            ).value = true;
            (
              filters[this.getIndex(filters, 'inSignificantRequest')] as Filter
            ).value = false;
          } else {
            (
              filters[this.getIndex(filters, 'inSignificantRequest')] as Filter
            ).value = true;
            (
              filters[this.getIndex(filters, 'significantRequest')] as Filter
            ).value = false;
          }
        }
        this.apiService
          .getDashboardData({ filters, type: DataEndpoint.dataPoints })
          .then((res) => {
            this.dataPoints = res as DataResponse;
            this.setExportFileName();
          });
        this.apiService
          .getDashboardData({
            filters,
            type: DataEndpoint.dataExperimentResult
          })
          .then((response) => {
            const typedResponse =
              response as unknown as ExperimentResultResponse[];

            this.data = (typedResponse[0] as ExperimentResultResponse).data;

            if (this.data.length > 0) {
              (this.data[0] as ExperimentResultData).pivotRowsToIgnore =
                (typedResponse[0] as ExperimentResultResponse)
                  .pivotRowsToIgnore || [];
            }

            if (this.data && this.currentLayout) {
              const [layoutRowDefinition]: ExperimentComparison[] = filter(
                this.currentLayout.elements,
                (e) => e.layoutType === 'EXPERIMENT_RESULT_COMPARISON_SECTION'
              ) as ExperimentComparison[];
              this.data.map((dObj) => {
                dObj.comparison = this.displayOrderByRowDefinition(
                  layoutRowDefinition?.ROW?.selected as ElementGroup[],
                  dObj.comparison
                );
                dObj.show = this.showExpansionSection;
                if (this.item?.EXPERIMENT_LAYOUT_EXPANSION_SECTION_VISIBILITY) {
                  this.addHighlightToResults(filters, dObj);
                }
              });
              if (this.item?.EXPERIMENT_LAYOUT_EXPANSION_SECTION_VISIBILITY) {
                this.data = this.data.sort(sortByHighlight);
              }
            }
            this.loading = false;
          });
      }
    });
    this.subscriptions.add(sub);
  }

  prepareLayoutLiteralId(filters: Filter[]): string {
    const filterValues: FilterValue[] = compact(
      this.layoutSelectionFilterElements.map((element) => {
        const selectedFilter = find(filters, {
          literalId: element.dashboardFilterId
        }) as Filter;
        if (selectedFilter) {
          return selectedFilter.value as FilterValue;
        }
      })
    );
    return filterValues.join('');
  }

  getExperimentLayout(): void {
    this.currentLayout = undefined;
    if (this.item) {
      this.layouts = this.item.elements as ExperimentDataElement[];
      if (this.layouts.length) {
        this.currentLayout = find(
          this.layouts,
          (layoutObj) => layoutObj.literalId === this.initialLayoutLiteralId
        ) as ExperimentDataElement;
        this.currentLayout = this.currentLayout
          ? this.currentLayout
          : this.layouts[0];
      }
      this.setCaptionFields();
      this.setComparisionFields();
      this.setExpansionFields();
      this.setExpansionGroupByField();
      this.setExpansionVisibilityOnLoad();
    }
  }

  private displayOrderByRowDefinition(
    rowDefinition: ElementGroup[],
    data: DataResponse[]
  ): DataResponse[] {
    if (rowDefinition) {
      const orderedRows: DataResponse[] = [];
      rowDefinition
        .sort(this.nativeSectionsService.sortByDisplayOrder)
        .forEach((row) => {
          const dataObj = find(
            data,
            (d) => d.literalId === row.dashboardFieldId
          );
          if (dataObj) {
            orderedRows.push(dataObj);
          }
        });
      return orderedRows;
    } else {
      return data;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getIndex(filters: Filter[], request: any): number {
    return filters.findIndex((item) => item.literalId === request);
  }

  addHighlightToResults(filters: Filter[], data: ExperimentResultData) {
    const selectedViewByFilterValue = (
      filters[this.getIndex(filters, FbScaleResult.viewByFilter)] as Filter
    ).value;
    const filterValue = (
      filters[this.getIndex(filters, selectedViewByFilterValue)] as Filter
    ).value;
    const filterField =
      selectedViewByFilterValue === FbScaleResult.revenueFilter
        ? FbScaleResult.roas
        : FbScaleResult.cpo;
    const roasIorcpoIofResult = (data.comparison[0] as DataResponse)[
      filterField
    ];
    if (roasIorcpoIofResult && filterValue) {
      data.highlight =
        this.initialLayoutLiteralId === 'Revenue'
          ? roasIorcpoIofResult >= filterValue
          : roasIorcpoIofResult <= filterValue;
    }
  }

  getTableFields(selected: ElementGroup[] | DataGridSelected[]): ITableField[] {
    return compact(
      map(sortBy(selected, 'displayOrder'), (obj: DataGridSelected) => {
        return {
          label: this.fieldDefinitions[obj.dashboardFieldId]?.label,
          subText: this.fieldDefinitions[obj.dashboardFieldId]?.subText,
          field: obj.dashboardFieldId,
          format: {
            type: this.fieldDefinitions[obj.dashboardFieldId]?.type,
            format: this.fieldDefinitions[obj.dashboardFieldId]?.format,
            digitsInfo: this.fieldDefinitions[obj.dashboardFieldId]?.digitsInfo
          }
        };
      })
    ) as unknown as ITableField[];
  }

  setCaptionFields(): void {
    const captionLayout = find(this.currentLayout?.elements || [], {
      layoutType: 'EXPERIMENT_RESULT_CAPTION_SECTION'
    }) as ExperimentCaption;
    if (captionLayout) {
      const captionSelected = captionLayout.RESULT_CAPTION
        ? captionLayout.RESULT_CAPTION.selected
        : undefined;
      this.captionFields = captionSelected
        ? this.getTableFields(captionSelected)
        : [];
    }
  }

  setComparisionFields(): void {
    const comparisionLayout = find(this.currentLayout?.elements || [], {
      layoutType: 'EXPERIMENT_RESULT_COMPARISON_SECTION'
    }) as ExperimentComparison;
    if (comparisionLayout) {
      this.comparisionTableName = comparisionLayout.literalId;
      const comparisionSelected = comparisionLayout.COLUMN
        ? comparisionLayout.COLUMN.selected
        : undefined;
      this.fields = comparisionSelected
        ? this.getTableFields(comparisionSelected)
        : [];
      const comparisionExportFields = comparisionLayout.COLUMN
        ? comparisionLayout.COLUMN.export
        : undefined;
      this.exportFields = comparisionExportFields
        ? this.getTableFields(comparisionExportFields)
        : [];
    }
  }

  setExpansionFields(): void {
    const expansionLayout = find(this.currentLayout?.elements || [], {
      layoutType: 'EXPERIMENT_RESULT_EXPANSION_SECTION'
    }) as ElementGroup;
    if (
      expansionLayout &&
      expansionLayout.elements.length > 0 &&
      (expansionLayout.elements[0] as unknown as ElementGroup).elements &&
      ((expansionLayout.elements[0] as unknown as ElementGroup)
        .elements[0] as unknown as DataGridElement)
    ) {
      this.expansionTableName = expansionLayout.literalId;
      const dataLayout = (
        expansionLayout.elements[0] as unknown as ElementGroup
      ).elements[0] as unknown as ExperimentExpansion;
      const expansionSelected = dataLayout.PIVOT_DIMENSION
        ? dataLayout.PIVOT_DIMENSION.selected
        : undefined;
      this.expansionData.pivotFields = expansionSelected
        ? this.getTableFields(expansionSelected)
        : [];
      const pivotRowsSelected = dataLayout.PIVOT_ROW
        ? dataLayout.PIVOT_ROW.selected
        : undefined;
      this.expansionData.pivotRows = pivotRowsSelected
        ? this.getTableFields(pivotRowsSelected)
        : [];
      const exportPivotRowsSelected = dataLayout.PIVOT_ROW
        ? dataLayout.PIVOT_ROW.export
        : undefined;
      this.expansionData.exportPivotRows = exportPivotRowsSelected
        ? this.getTableFields(exportPivotRowsSelected)
        : [];
    }
  }

  getExpansionConfig(
    expansion: DataResponse[],
    dataObj: ExperimentResultResponse
  ): IStaticPivotConfig {
    return {
      pivotData: expansion,
      pivotFields: this.expansionData.pivotFields,
      styleClass: 'EXPERIMENT_RESULT_EXPANSION_SECTION',
      pivotRowHeaderLabel: 'Description',
      pivotRows: this.expansionData.pivotRows,
      exportPivotRows: this.expansionData.exportPivotRows,
      pivotRowsToIgnore: dataObj.pivotRowsToIgnore,
      name: this.expansionTableName
    };
  }

  setData(comparison: DataResponse[]): DataSetResponse {
    return {
      name: this.comparisionTableName,
      data: comparison
    };
  }

  setExpansionGroupByField(): void {
    if (this.item && this.item.EXPANSION_DATA_SET_GROUP_BY_FIELD) {
      const fieldId =
        this.item.EXPANSION_DATA_SET_GROUP_BY_FIELD.groupBy[0]
          ?.dashboardFieldId;
      this.groupByField = fieldId ? fieldId : '';
    } else {
      console.error('Not able to set the group by field');
      this.groupByField = '';
    }
  }

  setExpansionVisibilityOnLoad(): void {
    if (this.item) {
      const filterObj = find(this.filters, {
        literalId: 'shouldExpand'
      }) as Filter;
      this.shouldExpandAllAtLoad = filterObj
        ? (filterObj.value as boolean)
        : false;
    } else {
      console.error('Not able to set the default expansion');
    }
  }

  // TODO remove function if possible
  getShowExpansionSectionValue(): boolean {
    return (
      this.item?.EXPERIMENT_LAYOUT_EXPANSION_SECTION_VISIBILITY.selected[0]
        ?.label !== 'false'
    );
  }

  setExportFileName(): void {
    if (this.literalId === 'geo-experiment-result') {
      const studyName = this.dataPoints.studyName || '';
      const cellName = this.dataPoints.cellName || '';
      this.exportFileName = `Result-${studyName}-${cellName}`;
    }
  }
}
