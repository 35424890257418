import { Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
  selector: 'portal-link-column',
  templateUrl: './link-column.component.html',
  styleUrls: ['./link-column.component.scss']
})
export class LinkColumnComponent {
  @Input() value = '';
  @Input() noValue = '';
  @Input() isLink = true;

  @Output() navigate = new EventEmitter();

  navigateTo($event: Event) {
    $event.preventDefault();
    this.navigate.emit();
  }
}
