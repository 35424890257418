import { Component, Input, OnInit } from '@angular/core';
import {
  FieldDefinition,
  DataResponse,
  ElementType,
  HeroMetricBounded
} from '@portal/app/shared/types';
import { FormatterService } from '@portal/app/shared/services/formatter.service';
import { ViewStore } from '@portal/app/shared/state/view.store';
import { take } from 'rxjs';
import { PreviousPeriodService } from '@portal/app/shared/services/previous-period.service';
import { CompareService } from '@portal/app/shared/services/compare.service';
import { FieldTooltipsText } from '@portal/app/shared/constants';

@Component({
  selector: 'portal-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit {
  @Input() gridItem = false;
  @Input() name = '';
  @Input() label = '';
  @Input() value?: number | Promise<DataResponse>;
  @Input() field?: FieldDefinition;
  @Input() type?: ElementType;
  @Input() children: HeroMetricBounded[] = [];
  @Input() loading = true;

  public literalId = '';
  public valueToShow = '';
  public childrenToShow: HeroMetricBounded[] = [];
  public hasBoundedChildren = false;
  public showEmptyState = false;
  public previousValueToShow = '';
  public tooltipText = '';
  public isUp = true;

  constructor(
    private readonly formatterService: FormatterService,
    private readonly viewStore: ViewStore,
    private readonly previousPeriodService: PreviousPeriodService,
    private readonly compareService: CompareService
  ) {}

  ngOnInit(): void {
    this.viewStore.selectedBCPDId.pipe(take(1)).subscribe((value) => {
      this.literalId = value.literalId;
    });

    if (this.field != null && this.value != null) {
      if (typeof this.value === 'number') {
        this.valueToShow = this.formatterService.format(this.field, this.value);
        this.loading = false;
      } else {
        // this is the promise
        this.value
          .then((resp) => {
            this.showEmptyState = false;
            const valueToFormat =
              resp[this.name] != null ? (resp[this.name] as number) : 0;
            this.valueToShow = this.formatterService.format(
              this.field as FieldDefinition,
              valueToFormat
            );
            this.hasBoundedChildren =
              this.type === 'DATA_POINT_BOUNDED' && this.children.length === 2;
            if (this.hasBoundedChildren) {
              this.childrenToShow = this.children.map((child) => ({
                ...child,
                value: this.formatterService.format(
                  child.field,
                  resp[child.name] || 0
                )
              }));
            }
            if (
              this.field &&
              FieldTooltipsText[
                this.field?.literalId as keyof typeof FieldTooltipsText
              ]
            ) {
              this.tooltipText = this.getMetricTooltip(this.field);
            }
            if (this.hasPreviousValue()) {
              this.preparePreviousValues(resp);
            }
            this.loading = false;
          })
          .catch((error) => {
            if (error && error.error) {
              this.loading = false;
              this.showEmptyState = true;
            }
          });
      }
    } else {
      this.valueToShow = this.value ? this.value.toString() : '';
    }
  }

  shouldAddClass(): boolean {
    return this.literalId === 'portfolio-level';
  }

  hasPreviousValue() {
    return (
      this.compareService.compareIsAvailable && this.compareService.compare
    );
  }

  preparePreviousValues(response: DataResponse) {
    this.previousValueToShow =
      this.previousPeriodService.getDifferencePercentFormattedValue(
        response,
        this.field as FieldDefinition
      );
    this.tooltipText = this.previousPeriodService.getDataPointTooltip(
      this.valueToShow,
      response,
      this.field as FieldDefinition
    );
    this.isUp = this.previousPeriodService.isUp(
      response,
      this.field as FieldDefinition
    );
  }

  getMetricTooltip(fieldDefinition: FieldDefinition): string {
    let tooltipHtml = '<div class="w-64 text-center">';
    tooltipHtml += `<div class="b3">
        <span>${fieldDefinition.label}</span>
      </div>`;
    tooltipHtml += `<div class="m-divider dark my-2"></div>`;
    tooltipHtml += `<div class="b2 pt-1">
        <span>${FieldTooltipsText[fieldDefinition.literalId as keyof typeof FieldTooltipsText]}</span>
      </div>`;

    if (fieldDefinition.subText) {
      tooltipHtml += `<div class="m-divider dark my-2"></div>`;
      tooltipHtml += `<div>
          <span class="c2 text-gray-500">𝑓 (${fieldDefinition.subText})</span>
        </div>`;
    }

    tooltipHtml += '</div>';
    return tooltipHtml;
  }
}
