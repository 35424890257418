import { formatNumber } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  DataResponse,
  DataRowData,
  FieldDefinition,
  Format,
  TableResponse
} from '@portal/app/shared/types';
import { FormatterService } from '@portal/app/shared/services/formatter.service';
import { CompareService } from '@portal/app/shared/services/compare.service';
import { isArray } from 'lodash-es';

export interface DataDriver {
  [key: string]: unknown;
  name: string;
  value: number;
  previous: number;
  percent: number;
  difference: number;
  up: boolean;
  field?: FieldDefinition;
}

export type DataDriverResponse = Record<string, DataRowData | DataDriver[]>;

export interface DriverResponse extends Omit<TableResponse, 'data'> {
  data: DataResponse[] | DataDriverResponse[];
}

@Injectable({
  providedIn: 'root'
})
export class PreviousPeriodService {
  public static readonly labelCurrentPrefix = 'Current Period';
  public static readonly labelPreviousPrefix = 'Previous Period';
  public static readonly labelDifferencePrefix = 'Difference';

  public static readonly fieldPrefix = 'prevPer__';
  public static readonly fieldDifferencePrefix = 'prevPerDiff__';
  public static readonly fieldDatePrefix = 'prevPerDate__';
  public static readonly fieldDriversPrefix = 'prevPerDrivers__';
  public static readonly datagridCompareFields = [
    'mediaSpend',
    'cpoI',
    'roasI',
    'roasLT',
    'cpoLT',
    'ordersLT',
    'salesLT',
    'ordersI',
    'percOrdersI',
    'salesI',
    'percSalesI',
    'percInc',
    'impressionConversionRate',
    'clickConversionRate'
  ];

  public static readonly datagridDriversFields = ['cpoI', 'roasI'];

  private readonly labelPercentAbbreviation = 'p.p.';
  private readonly fieldDifferencePercentPrefix = 'prevPerDiffPerc__';
  private readonly fieldIsUpPrefix = 'prevPerIsUp__';
  private readonly percentAbbreviationFields = ['percOrdersI', 'percSalesI'];

  constructor(
    private readonly formatterService: FormatterService,
    private readonly compareService: CompareService
  ) {}

  getValue(dataResponse: DataDriverResponse, key: string) {
    return dataResponse[key];
  }

  getFormattedValue(
    dataResponse: DataDriverResponse,
    fieldDefinition: FieldDefinition
  ) {
    const literalId = `${PreviousPeriodService.fieldPrefix}${fieldDefinition.literalId}`;
    const value = this.getValue(dataResponse, literalId);
    if (value !== undefined && !isArray(value)) {
      return this.formatterService.format(fieldDefinition, value || 0);
    }
  }

  private getDifferenceFormattedValue(
    dataResponse: DataDriverResponse,
    fieldDefinition: FieldDefinition
  ) {
    const literalId = `${PreviousPeriodService.fieldDifferencePrefix}${fieldDefinition.literalId}`;
    const value = this.getValue(dataResponse, literalId);
    if (value !== undefined && !isArray(value)) {
      return this.formatterService.format(fieldDefinition, value || 0);
    }
    return '';
  }

  public isUp(
    dataResponse: DataDriverResponse,
    fieldDefinition: FieldDefinition
  ) {
    const literalId = `${this.fieldIsUpPrefix}${fieldDefinition.literalId}`;
    return Boolean(this.getValue(dataResponse, literalId));
  }

  public getDifferencePercentFormattedValue(
    dataResponse: DataDriverResponse,
    fieldDefinition: FieldDefinition
  ) {
    let literalId = `${this.fieldDifferencePercentPrefix}${fieldDefinition.literalId}`;
    let multiplyValue = 1;
    if (this.percentAbbreviationFields.includes(fieldDefinition.literalId)) {
      literalId = `${PreviousPeriodService.fieldDifferencePrefix}${fieldDefinition.literalId}`;
      multiplyValue = 100;
    }
    const value = this.getValue(dataResponse, literalId);
    if (value !== undefined) {
      return `${formatNumber(
        (value as number) * multiplyValue,
        'en-US',
        '1.2-2'
      )}${
        this.percentAbbreviationFields.includes(fieldDefinition.literalId)
          ? this.labelPercentAbbreviation
          : '%'
      }`;
    }
    return '';
  }

  public getDataPointTooltip(
    currentValue: string,
    dataResponse: DataDriverResponse,
    fieldDefinition: FieldDefinition
  ) {
    if (
      !this.compareService.compareIsAvailable ||
      !this.compareService.compare
    ) {
      return '';
    }

    const previousValue = this.getFormattedValue(dataResponse, fieldDefinition);
    let difference = this.getDifferenceFormattedValue(
      dataResponse,
      fieldDefinition
    );

    let isPercentAbbreviation = false;
    if (difference.includes('%')) {
      isPercentAbbreviation = true;
      difference = difference.replace('%', ` ${this.labelPercentAbbreviation}`);
    }

    const isUpValue = this.isUp(dataResponse, fieldDefinition);
    const newLayout = this.compareService.driversLayout;
    /* eslint-disable */
    return `
        ${!newLayout ? `   <div class="tooltip-title">${fieldDefinition.label} Details</div>
        <div class="tooltip-text fs-small">
          <div class="tooltip-value">${PreviousPeriodService.labelCurrentPrefix}: <span>${currentValue ? currentValue : ''}</span></div>
          <div class="tooltip-value">${PreviousPeriodService.labelPreviousPrefix}: <span>${previousValue ? previousValue : ''}</span></div>
          <div class="tooltip-value">${PreviousPeriodService.labelDifferencePrefix}: <span class="difference ${isUpValue ? '' : 'down'}">${difference ? difference : ''}</span>
        </div>
      </div>` : this.generateTooltip(fieldDefinition, difference, currentValue, previousValue ? previousValue : undefined, isUpValue)}
        ${this.getDriversTemplate(dataResponse, fieldDefinition, false, newLayout, isPercentAbbreviation)}`;
    /* eslint-enable */
  }

  public generatePreviousValueComponentAsString(
    currentValue: string,
    isUpValue: boolean
  ) {
    const isPositive = !currentValue.startsWith('-');
    /* eslint-disable */
    return `
          <span
              class="previous-period-value icon ${isUpValue ? 'up' : 'down'} ${
      currentValue === '0.00%' ? 'zero' : ''
    }">
              <span class="${currentValue === '0.00%' ? 'zero;' : ''}">
                ${currentValue}
              </span>
              <span class="pi ${
      isPositive ? 'pi-arrow-up' : 'pi-arrow-down'
    } "></span>
          </span>
    `;
    /* eslint-enable */
  }

  generateTooltip(
    fieldDefinition: FieldDefinition,
    difference: string,
    currentValue: string | undefined,
    previousValue: string | undefined,
    isUpValue: boolean
  ) {
    return `
      <div class="legacy">
      <div class="tooltip-text fs-small ">
        <div class="tooltip-section center-tooltip mb-0">
          <div class="tooltip-title">Change in ${fieldDefinition.label}</div>
        </div>
        <div class="tooltip-value-big">${
          difference
            ? this.generatePreviousValueComponentAsString(difference, isUpValue)
            : ''
        }</div>
        <div class="tooltip-value">
          ${previousValue !== undefined ? previousValue : ''}  ${
            currentValue !== undefined && previousValue !== undefined
              ? '<span class="pi pi-arrow-right"></span>'
              : ''
          } ${currentValue !== undefined ? currentValue : ''}</div>
      </div>
      </div>`;
  }

  getDriversTemplate(
    dataResponse: DataDriverResponse,
    fieldDefinition: FieldDefinition,
    dataSetTooltip = false,
    newLayout = false,
    isPercentAbbreviation = false
  ) {
    const drivers = this.getDrivers(dataResponse, fieldDefinition);
    if (drivers && drivers.length) {
      /* eslint-disable */
      let result = '';
      if (dataSetTooltip) {
        result = `
        <div class="tooltip-title">Contributing Factors</div>
        <div class="tooltip-title-grey-small mb-1">(Relative Amount Change)</div>`
      } else {
        result = `
        <div class="tooltip-title mb-1">Contributing Factors</div>`
      }
      // if new layout is set to false replace header with old layout
      if (!newLayout) {
        result = `<div class="tooltip-title">Drivers for ${fieldDefinition.label}</div>
        <div class="tooltip-text fs-small">`;
      }
      result += `
        <div class="tooltip-text fs-small">`;
      drivers.forEach((driver) => {
        result += `<div class="tooltip-value align-text-right">${driver.name}:<span class="difference">&nbsp;${this.generatePreviousValueComponentAsString(this.getFormattedDriverValue(driver, fieldDefinition, isPercentAbbreviation), driver.up)}&nbsp;</span></div>`;
      });
      const ppText = isPercentAbbreviation ? `<div class="tooltip-drivers text-description">
        What is p.p.? <br>
        P.P. stands for Percentage Points, <br>
        and it conveys the numerical <br>
        difference between two percentages.
      </div>` : '';
      return `</div><div class="tooltip-drivers ${!newLayout ? '' : 'legacy'}">${result}</div> ${ppText}`;
      /* eslint-enable */
    }
    return '';
  }

  getDrivers(
    dataResponse: DataDriverResponse,
    fieldDefinition: FieldDefinition
  ) {
    const literalId = `${PreviousPeriodService.fieldDriversPrefix}${fieldDefinition.literalId}`;
    return this.getValue(
      dataResponse as never,
      literalId
    ) as unknown as DataDriver[];
  }

  getFormattedDriverValue(
    driver: DataDriver,
    fieldDefinition: FieldDefinition,
    isPercentAbbreviation = false
  ) {
    if (driver.field && driver.field.format === ('percent' as Format)) {
      // if percent abbreviation is on use difference instead of percent
      if (isPercentAbbreviation) {
        return this.formatterService
          .formatPartialValue(
            driver.field || fieldDefinition,
            driver.difference
          )
          .replace('%', ` ${this.labelPercentAbbreviation}`);
      } else {
        return this.formatterService.formatPartialValue(
          driver.field || fieldDefinition,
          driver.percent
        );
      }
    }
    return this.formatterService.format(
      driver.field || fieldDefinition,
      driver.difference
    );
  }

  getDataGridTooltip(
    currentValue: string,
    dataResponse: DataDriverResponse,
    fieldDefinition: FieldDefinition
  ) {
    if (
      !this.compareService.compareIsAvailable ||
      !this.compareService.compare
    ) {
      return '';
    }
    const previousValue = this.getFormattedValue(dataResponse, fieldDefinition);
    const difference = this.getDifferenceFormattedValue(
      dataResponse,
      fieldDefinition
    );

    const isUpValue = this.isUp(dataResponse, fieldDefinition);
    const newLayout = this.compareService.driversLayout;
    const currentPeriodDate = this.compareService.formattedCurrentDate;
    const previousPeriodDate = this.compareService.formattedPreviousDate;
    /* eslint-disable */
    return `
    ${!newLayout ? `<div class="tooltip-text fs-small">
          <div class="tooltip-section">
            <div class="tooltip-title">Reference Period</div>
            <div class="tooltip-value">${currentPeriodDate ? currentPeriodDate : ''} ${previousPeriodDate ? ' vs. ' + previousPeriodDate : ''}</div>
          </div>
          <div class="tooltip-title">${currentValue !== undefined ? currentValue : ''} vs. ${previousValue !== undefined ? previousValue : '-'}
            <span class="ms-1 tooltip-text fs-small">${fieldDefinition.label}</span>
          </div>
          <div class="tooltip-value">${difference ? 'Difference:' : ''} <span class="difference ${isUpValue ? '' : 'down'}">${difference ? difference : ''}</span></div>
    </div>` : this.generateTooltip(fieldDefinition, difference, currentValue, previousValue ? previousValue : undefined, isUpValue)}
      ${this.getDriversTemplate(dataResponse, fieldDefinition, true, newLayout)}`;
    /* eslint-enable */
  }
}
