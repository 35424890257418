import { animate, style, transition, trigger } from "@angular/animations";

export class SlideInOutAnimator {
  public static slideInUpOutDown  = () => trigger('slideInOut', [
    transition(':enter', [
      style({ transform: 'translateY(100%)' }),
      animate('300ms', style({ transform: 'translateY(0%)' }))
    ]),
    transition(':leave', [
      animate('300ms', style({ transform: 'translateY(100%)' }))
    ])
  ]);
}
