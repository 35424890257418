import { ExportTableType } from '../base';
import {
  ElementSubType,
  ElementSubTyping,
  ElementTyping
} from './element-type';
import { FieldDefinitions } from './layout';
import { SortMeta } from 'primeng/api';

interface DataGridConfig {
  showFooter: boolean;
  exportShowFooter: boolean;
  striped: boolean;
  scrollable: boolean;
  scrollDirection: string;
  tableScrollHeightClass: 'short' | 'medium' | 'tall';
  customSort: boolean;
  showClearFiltersButton: boolean;
  virtualScrollItemSize: number;
  virtualScrollEnableOnCount: number;
  haveVirtualScroll: boolean;
  frozenColumnsEnabled: boolean;
  isResizable: boolean;
  dashboardSortMode: string;
  tableScrollHeightInRem?: string;
}

interface DataGridExportConfig {
  tableName: string;
  format?: ExportTableType;
  fileName?: string;
  fieldDefinitions?: FieldDefinitions;
  showFooter?: boolean;
  literalId?: string;
  fields?: (DataGridAvailable | DataGridEditable | DataGridSelected)[];
}

type DataGridColumnSubType = Extract<
  ElementSubTyping,
  typeof ElementSubTyping.selected | typeof ElementSubTyping.available
>;

interface DataGridPivotColumn extends ElementSubType {
  type: typeof ElementTyping.pivotDimension;
  subType: DataGridColumnSubType;
  format: string;
  fieldType: 'double' | 'string' | 'datetime';
  label: string;
  digitsInfo: string;
}

interface DataGridFilterEvent extends ElementSubType {
  type: ElementTyping.filterEvent;
}

type DataGridFilterEvents = Record<string, Record<string, DataGridFilterEvent>>;

interface DataGridExecutableFilterEvent {
  targetFilterId: string;
  targetValue: string;
  subType: string;
  columnDashboardFieldId: string;
}

type DataGridExecutableFilterEvents = Record<
  string,
  Record<string, DataGridExecutableFilterEvent[]>
>;

type DataGridTyping = Extract<
  ElementTyping,
  | typeof ElementTyping.column
  | typeof ElementTyping.dimension
  | typeof ElementTyping.pivotDimension
  | typeof ElementTyping.pivotData
  | typeof ElementTyping.pivotRow
  | typeof ElementTyping.pivotRowHeader
  | typeof ElementTyping.pivotRowHighlight
  | typeof ElementTyping.experimentLayoutFilter
  | typeof ElementTyping.filterEvent
  | typeof ElementTyping.sortedColumn
>;

interface DataGridSelected extends ElementSubType {
  type: DataGridTyping;
  subType: typeof ElementSubTyping.selected;
}

interface DataGridEditable extends ElementSubType {
  type: typeof ElementTyping.column | typeof ElementTyping.dimension;
  subType: typeof ElementSubTyping.editable;
}

interface DataGridGrouped extends ElementSubType {
  type: typeof ElementTyping.column | typeof ElementTyping.dimension;
  subType: typeof ElementSubTyping.grouped;
}

interface DataGridAvailable extends ElementSubType {
  type: DataGridTyping;
  subType: typeof ElementSubTyping.available;
}

interface DataGridEventDriven extends ElementSubType {
  type: typeof ElementTyping.filterEvent;
}

interface DataGridControls extends ElementSubType {
  type: ElementTyping.control;
  subType:
    | typeof ElementSubTyping.metricControl
    | typeof ElementSubTyping.filterControl;
}

interface DataGridBarVisualizer extends ElementSubType {
  type: typeof ElementTyping.column | typeof ElementTyping.dimension;
  subType: typeof ElementSubTyping.barVisualizer;
}

interface DataGridInfoWithIcon extends ElementSubType {
  type: typeof ElementTyping.column | typeof ElementTyping.dimension;
  subType: typeof ElementSubTyping.infoWithIcon;
}

interface DataGridNavigation extends ElementSubType {
  type: DataGridTyping;
  subType: typeof ElementSubTyping.selected | typeof ElementSubTyping.available;
  layoutType: 'NAVIGATION_COLUMN';
}

interface DataGridSortedColumn extends ElementSubType {
  type: DataGridTyping;
  subType:
    | typeof ElementSubTyping.ascending
    | typeof ElementSubTyping.descending;
}

type ICountByFields = Record<
  string,
  {
    count: number;
    cursor?: string;
  }
>;

interface PrimeNgSortEvent {
  field: string;
  order: PrimeNgSortOrder;
  multisortmeta: SortMeta[];
}

enum PrimeNgSortOrder {
  ascending = 1,
  descending = -1
}

enum DataGridSortMode {
  single = 'single',
  multiple = 'multiple'
}

export {
  ICountByFields,
  DataGridAvailable,
  DataGridConfig,
  DataGridExportConfig,
  DataGridEditable,
  DataGridSelected,
  DataGridPivotColumn,
  DataGridTyping,
  DataGridGrouped,
  DataGridControls,
  DataGridBarVisualizer,
  DataGridFilterEvents,
  DataGridFilterEvent,
  DataGridEventDriven,
  DataGridExecutableFilterEvent,
  DataGridExecutableFilterEvents,
  DataGridInfoWithIcon,
  DataGridNavigation,
  DataGridSortedColumn,
  DataGridSortMode,
  PrimeNgSortEvent,
  PrimeNgSortOrder
};
