// mpo-queries.service.ts
import { Injectable, inject } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { queryOptions } from '@tanstack/angular-query-experimental';
import { QueryKeys } from './query-keys';
import { MIMBFFControllerService } from '@libs/api';
import { QueryParamsService } from './query-params.service';

@Injectable({ providedIn: 'root' })
export class MIMQueriesService {
  private mimBffService = inject(MIMBFFControllerService);
  private queryParamsService = inject(QueryParamsService);

  // Existing queries...

  /**
   * New query to fetch the MIM version.
   * Uses clientId and brandId from the shared query params.
   */
  mimVersion() {
    const { clientId, brandId } = this.queryParamsService.queryParams();
    return queryOptions({
      queryKey: [QueryKeys.MimVersion, clientId, brandId],
      queryFn: () =>
        lastValueFrom(
          this.mimBffService.mimbffControllerGetMimVersion(clientId, brandId)
        ),
      enabled: !!clientId && !!brandId
    });
  }
}
