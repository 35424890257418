import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import {
  BrandRow,
  CurrentStatus,
  PermissionResponse
} from '@portal/app/shared/types';
import { ApiService } from '@portal/app/shared/services/api.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BrandRowsService {
  private callBrandRows: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private allBrandRows: BrandRow[] = [];

  constructor(private apiService: ApiService) {}

  get shouldFetchBrandRows(): Observable<boolean> {
    return this.callBrandRows.asObservable();
  }

  get brandRows(): BrandRow[] {
    return this.allBrandRows;
  }

  set brandRows(value: BrandRow[]) {
    this.allBrandRows = value;
  }

  public changeShouldFetchBrandRows(value: boolean): Observable<boolean> {
    const observable = new Observable((subscriber: Subscriber<boolean>) => {
      subscriber.next(value);
      subscriber.complete();
    });

    observable.subscribe({
      next: () => this.callBrandRows.next(value)
    });

    return observable;
  }

  public buildBrandRows(): Observable<BrandRow[]> {
    return new Observable<BrandRow[]>((subscribe) => {
      this.apiService.getClients().subscribe({
        next: (resp: PermissionResponse) => {
          const clients = resp != null ? resp.clients || [] : [];
          const rows: BrandRow[] = [];
          // Build out the rows
          clients.forEach((client) => {
            if (client.brands.length > 1) {
              // Iterate on all the brands, show the brand information
              client.brands.forEach((brand) => {
                if (
                  client.status === CurrentStatus.active &&
                  brand.status === CurrentStatus.active
                ) {
                  rows.push({
                    clientId: brand.clientId,
                    brandId: brand.brandId,
                    name: brand.brandName,
                    status: brand.status,
                    client,
                    brand
                  });
                }
              });
            } else {
              if (
                client.status === CurrentStatus.active &&
                client.brands[0]?.status === CurrentStatus.active
              ) {
                // There is only one brand
                rows.push({
                  client,
                  clientId: client.clientId,
                  brandId: client.brands[0].brandId,
                  name: client.brands[0].brandName,
                  status: client.status,
                  brand: client.brands[0]
                });
              }
            }
          });
          subscribe.next(rows);
          subscribe.complete();
        },
        error: (error: HttpErrorResponse) => {
          subscribe.error(error);
        }
      });
    });
  }
}
