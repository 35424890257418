import { Injectable } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject, firstValueFrom, of } from 'rxjs';
import { cloneDeep, unset } from 'lodash-es';
import {
  ViewResponse,
  FieldDefinitions,
  PortalEvent,
  ViewOperations,
  ViewType,
  PortalEventCategory,
  CurrentView
} from '@portal/app/shared/types';
import { ViewSelectorComponent } from '@portal/app/dashboard/multi-views/view-selector/view-selector.component';
import { MessageDialogComponent } from '@portal/app/shared/components/message-dialog/message-dialog.component';
import { NotesComponent } from '@portal/app/shared/components/notes/notes.component';
import { PortalEventBusService } from '@portal/app/shared/services/portal-event-bus.service';
import { MultiViewStore } from '@portal/app/shared/state/multi-view.store';
import { AuthService } from '@portal/app/shared/services/auth.service';
import { ElementGroup, MessageDialogText } from '@portal/app/shared/types';
import { ViewUtilityService } from './view-utility.service';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root'
})
export class MultiViewService {
  // Use getters and setters to access variables suffixed with Primary
  viewBackupPrimary: ViewResponse | undefined;

  selectorDialogRef: DynamicDialogRef | undefined;
  notesDialogRef: DynamicDialogRef | undefined;
  messageDialogRef: DynamicDialogRef | undefined;
  lastTimeSavedLoaded = false;

  viewEventStateSubject = new BehaviorSubject<PortalEvent>({});

  readonly viewEventState$ = this.viewEventStateSubject.asObservable();

  getViewEventStateValue() {
    return this.viewEventStateSubject.getValue();
  }

  get viewBackup() {
    return cloneDeep(this.viewBackupPrimary);
  }

  set viewBackup(view: ViewResponse | undefined) {
    this.viewBackupPrimary = cloneDeep(view);
  }

  constructor(
    private dialogService: DialogService,
    private portalEventBusService: PortalEventBusService,
    private multiViewStore: MultiViewStore,
    private viewUtilityService: ViewUtilityService,
    private authService: AuthService
  ) {
    this.portalEventBusService
      .on(PortalEventCategory.multiViewEvent)
      .subscribe((event) => {
        this.handleEvents(event);
      });
  }

  isViewOwner(storeId: string): boolean {
    const userEmail = this.authService.getUser()?.getEmail();
    const view = this.multiViewStore.getViewDataByStoreId(storeId);
    return !!(view && view.createdBy === userEmail);
  }

  handleEvents(event: PortalEvent) {
    switch (event.action) {
      case 'VIEW_OPERATION_RENAME':
        this.handleRenameAction(event);
        break;
      case 'VIEW_CONFIRM_CANCEL':
        this.handleConfirmCancelAction(event);
        break;
      case 'VIEW_CONFIRM_SAVE':
        this.handleConfirmSaveAction(event);
        break;
      case 'VIEW_OPERATION_SHARE':
      case 'VIEW_OPERATION_DEFAULT':
        this.handlePatchOperations(event);
        break;
      case 'VIEW_OPERATION_DELETE':
        this.handleDeleteAction(event);
        break;
      case 'VIEW_TYPE_ALL':
      case 'VIEW_TYPE_MY_PLANS':
      case 'VIEW_TYPE_SHARED':
        this.handleFilterAction(event);
        break;
      case 'VIEW_PLANS':
        this.handleMyPlansAction(event);
        break;
      case 'VIEW_NOTES':
        this.handleOpenNotesAction(event);
        break;
      case 'VIEW_OPEN':
        this.handleOpenAction(event);
        break;
      case 'VIEW_OPERATION_DUPLICATE':
        this.handleDuplicateAction(event);
        break;
      case 'VIEW_ADD_NEW':
        this.handleNewViewAction();
        break;
      case 'SAVE_NOTES':
        this.handleSaveNotesAction(event);
        break;
      case 'VIEW_SAVE':
        this.handleSaveViewAction(event);
        break;
      case 'CHILD_VIEW_SAVE':
        this.handleSaveChildViewAction(event);
        break;
      default:
        break;
    }
  }

  handleConfirmCancelAction(event: PortalEvent) {
    const savedView = this.viewBackup;
    const eventState = this.viewEventStateSubject.getValue();
    if (eventState.action !== 'VIEW_OPERATION_RENAME') {
      const currView = this.multiViewStore.getCurrentViewValue();
      if (currView && savedView) {
        this.multiViewStore.setCurrentView({
          ...currView,
          view: savedView
        });
      }
    }
    this.viewBackup = undefined;
    setTimeout(() => {
      event.metaData = {};
      this.viewEventStateSubject.next(event);
    }, 0);
  }

  handleConfirmSaveAction(event: PortalEvent) {
    if (event.metaData?.editableHeaderValue) {
      this.completeViewSaveAction(
        event.metaData.editableHeaderValue as string,
        event
      );
    } else {
      const viewStateMeta = this.getViewEventStateValue().metaData;
      setTimeout(() => {
        event.metaData = {
          ...event.metaData,
          ...viewStateMeta,
          prevAction:
            (viewStateMeta?.prevAction as string) ??
            (this.getViewEventStateValue().action as string)
        };
        this.viewEventStateSubject.next(event);
      }, 0);
    }
  }

  completeViewSaveAction(planName: string, event: PortalEvent) {
    if (event.metaData?.prevAction === 'VIEW_OPERATION_RENAME') {
      this.renamePlan(planName, event.id as string);
    }
  }

  handleRenameAction(event: PortalEvent) {
    setTimeout(() => {
      event.metaData = {
        controlSet: 'VIEW_CONFIRMATION_SET',
        editViewName: true
      };
      this.viewEventStateSubject.next(event);
    }, 0);
  }

  handleSaveViewAction(event: PortalEvent) {
    if (event.id) {
      const view = this.multiViewStore.getViewDataByStoreId(event.id as string);
      if (view && view.isMeasuredDefault) {
        const planName = `${view.name} (Copy)`;
        this.createView(planName, view.id);
      } else {
        this.multiViewStore.updateCurrentView();
      }
    }
  }

  handleSaveChildViewAction(event: PortalEvent) {
    if (event.id) {
      const view = this.multiViewStore.getViewDataByStoreId(event.id as string);
      if (view) {
        this.multiViewStore.updateChildView();
      }
    }
  }

  handleSaveNotesAction(event: PortalEvent) {
    if (event.id) {
      const view = this.multiViewStore.getViewDataByStoreId(event.id as string);
      if (view) {
        unset(view, 'filterOverrides');
        view.notes = event.payload?.notes ?? '';
        this.multiViewStore.updateView(view);
      }
    }
  }

  async handleNewViewAction() {
    const view = await this.multiViewStore.getDefaultView();
    if (view) {
      const planName = `${view.name} (Copy)`;
      this.createView(planName, view.id);
    }
  }

  handleDuplicateAction(event: PortalEvent) {
    const view = this.multiViewStore.getViewDataByStoreId(event.id as string);
    if (view) {
      const planName = `${view.name} (Copy)`;
      this.createView(planName, view.id);
    }
  }

  handlePatchOperations(event: PortalEvent) {
    const view = this.multiViewStore.getViewDataByStoreId(event.id as string);
    if (event.action && view) {
      let operation: ViewOperations | undefined;
      if (event.action === 'VIEW_OPERATION_DEFAULT') {
        operation = ViewOperations.default;
      } else if (event.action === 'VIEW_OPERATION_SHARE') {
        operation = view.badges.published
          ? ViewOperations.unpublish
          : ViewOperations.publish;
      }
      if (operation) {
        this.openMessageDialog(operation, view).then((confirm) => {
          if (confirm && operation) {
            this.multiViewStore.patchView(view, operation);
          }
        });
      }
    }
  }

  handleDeleteAction(event: PortalEvent) {
    const item = this.multiViewStore.getViewDataByStoreId(event.id as string);
    if (item) {
      this.openMessageDialog(ViewOperations.delete, item).then((confirm) => {
        if (confirm) {
          this.multiViewStore.deleteView(item);
        }
      });
    }
  }

  handleMyPlansAction(event: PortalEvent) {
    if (event.payload?.item && event.payload.fieldDefinitions)
      this.openViewSelectorDialog(
        event.payload?.item,
        event.payload.fieldDefinitions
      );
  }

  handleOpenAction(event: PortalEvent) {
    if (event.id) {
      const view = this.multiViewStore.getViewDataByStoreId(event.id as string);
      if (view) {
        this.multiViewStore.loadViewById(view.id as number);
        this.closeDialogs();
      }
    }
  }

  handleFilterAction(event: PortalEvent) {
    let viewType;
    if (event.action === 'VIEW_TYPE_ALL') {
      viewType = ViewType.allPlans;
    } else if (event.action === 'VIEW_TYPE_MY_PLANS') {
      viewType = ViewType.myPlans;
    } else if (event.action === 'VIEW_TYPE_SHARED') {
      viewType = ViewType.publishedPlans;
    }
    if (viewType) {
      this.multiViewStore.fetchViewList(viewType);
    }
  }

  handleOpenNotesAction(event: PortalEvent) {
    const item = event.id
      ? this.multiViewStore.getViewDataByStoreId(event.id as string)
      : undefined;
    if (item) this.openNotesDialog(item);
  }

  renamePlan(planName: string, id: string) {
    const view = this.multiViewStore.getViewDataByStoreId(id);
    if (view && view.name !== planName) {
      const storeId = view.storeId;
      view.name = planName;
      unset(view, 'filterOverrides');
      this.multiViewStore.updateView(view).then(() => {
        this.viewEventStateSubject.next({ id: storeId });
      });
    } else {
      this.portalEventBusService.emit({
        id,
        action: 'VIEW_CONFIRM_CANCEL',
        eventCategory: PortalEventCategory.multiViewEvent
      });
    }
  }

  createView(planName: string, id: number) {
    this.multiViewStore
      .createView(planName, id)
      .then((currentView: CurrentView) => {
        this.closeDialogs();
        this.portalEventBusService.emit({
          id: currentView.view?.storeId,
          action: 'VIEW_OPERATION_RENAME',
          eventCategory: PortalEventCategory.multiViewEvent
        });
      })
      .catch((message: string) => {
        console.error(message);
      });
  }

  openViewSelectorDialog(
    element: ElementGroup,
    fieldDefinitions: FieldDefinitions
  ) {
    this.closeDialogs();
    this.selectorDialogRef = this.dialogService.open(ViewSelectorComponent, {
      data: {
        element,
        fieldDefinitions
      },
      header: 'My Plans',
      width: '80%',
      styleClass: 'dynamicDialog',
      dismissableMask: true
    });
  }

  openNotesDialog(view: ViewResponse) {
    this.notesDialogRef = this.dialogService.open(NotesComponent, {
      data: {
        id: view?.storeId,
        notes: view?.notes,
        hasEditPermission: this.isViewOwner(view?.storeId as string),
        eventCategory: PortalEventCategory.multiViewEvent
      },
      header: 'Notes',
      width: '30%',
      styleClass: 'dynamicDialog',
      dismissableMask: true
    });
  }

  openMessageDialog(operation: ViewOperations, item: ViewResponse) {
    const header = this.viewUtilityService.getMessageDialogHeader(operation);
    const dialogData: MessageDialogText[] | undefined =
      this.viewUtilityService.getMessageDialogData(operation, item?.name);
    if (dialogData) {
      this.messageDialogRef = this.dialogService.open(MessageDialogComponent, {
        data: {
          layoutType: 'spanGroup',
          data: dialogData
        },
        header,
        width: '30%',
        styleClass: 'dynamicDialog confirmationDialog',
        dismissableMask: true
      });
      return firstValueFrom(this.messageDialogRef.onClose);
    }
    return firstValueFrom(of(undefined));
  }

  closeDialogs() {
    this.selectorDialogRef?.close();
    this.notesDialogRef?.close();
    this.messageDialogRef?.close();
  }

  getLastTimeSaved(id?: string, isChild?: boolean): void | string {
    let ltdate;
    if (id) {
      if (isChild && !this.lastTimeSavedLoaded) {
        this.lastTimeSavedLoaded = true;
        ltdate = this.multiViewStore.dashboardFilters.find(
          (filter) => filter.literalId === 'childLastTimeSaved'
        )?.value as string;
      } else {
        ltdate = this.multiViewStore.getViewDataByStoreId(id)?.lastTimeSaved;
      }
    }
    if (ltdate) {
      const currentDate = new Date();
      const currentTimestamp = currentDate.getTime();
      const lastSavedDate = new Date(ltdate);
      const lastSavedTimestamp = lastSavedDate.getTime();
      const diff = (currentTimestamp - lastSavedTimestamp) / 1000;
      const lasttimeSavedd = dayjs(ltdate).fromNow();

      const formattedDate = lastSavedDate.toLocaleString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });

      if (diff < 86400) {
        return lasttimeSavedd;
      }
      return formattedDate;
    }
  }
}
