import { Injectable } from '@angular/core';
import { FormatterService } from '@portal/app/shared/services/formatter.service';
import { FieldDefinition } from '@portal/app/shared/types';

interface TruncateOptions {
  value: number;
  isCurrency: boolean;
  formattedValue: string;
}

@Injectable({
  providedIn: 'root'
})
export class FormatService {
  constructor(private formatterService: FormatterService) {}

  formatValue(fieldDefinition: FieldDefinition | null, value: number): string {
    if (!fieldDefinition || value == null) {
      return '';
    }

    const isCurrency = fieldDefinition.format === 'currency';
    const shouldTruncate =
      value >= 10000 &&
      ['currency', 'number'].includes(fieldDefinition.format ?? '');
    const formattedValue = this.formatterService.format(fieldDefinition, value);

    // Adjusted to directly pass the object to match the expected method signature
    return shouldTruncate
      ? this.truncateValue({ formattedValue, isCurrency, value })
      : formattedValue;
  }

  truncateValue({
    formattedValue,
    isCurrency,
    value
  }: TruncateOptions): string {
    if (value < 10000) {
      return formattedValue; // Early return if value doesn't require truncation
    }

    let suffix = '';
    let truncatedValue = value;
    let currencySymbol = '';

    if (value >= 1000000) {
      truncatedValue /= 1000000;
      suffix = 'M';
    } else if (value >= 10000) {
      truncatedValue /= 1000;
      suffix = 'K';
    }

    let result = formattedValue;
    if (isCurrency) {
      const match = result.match(/^[\D]+/);
      if (match) {
        currencySymbol = match[0];
        result = result.replace(currencySymbol, ''); // Use a new variable to avoid modifying the const
      }
    }

    // Format the numeric part with two decimal places and append the suffix
    const numericPart = truncatedValue.toFixed(2) + suffix;
    return `${currencySymbol}${numericPart}`;
  }
}
