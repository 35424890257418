/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import { typographyConfig } from '../../styles/typography/typography.config';
import { colors } from '../../styles/colors/colors.config';
HighchartsMore(Highcharts);

interface CustomChart extends Highcharts.Chart {
  categoryGroup?: Highcharts.SVGElement;
}

@Component({
  selector: 'm-horizontal-bar-chart',
  templateUrl: './m-horizontal-bar-chart.component.html'
})
export class MHorizontalBarChartComponent implements OnInit {
  @Input() series: Highcharts.SeriesOptionsType[] = [];
  @Input() yAxis: Highcharts.YAxisOptions | Highcharts.YAxisOptions[] = {};
  @Input() xAxis: Highcharts.XAxisOptions | Highcharts.XAxisOptions[] = {};
  @Input() legend: Highcharts.LegendOptions = {};
  @Input() plotOptions: Highcharts.PlotOptions = {};
  @Input() tooltip: Highcharts.TooltipOptions = {};
  @Input() title: Highcharts.TitleOptions = {};
  @Input() width: number | string = 600; // Default width
  @Input() height: number | string = 300; // Default height

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};

  ngOnInit(): void {
    this.chartOptions = {
      chart: {
        backgroundColor: 'transparent',
        type: 'bar',
        height: this.height === null ? null : this.height,
        width: this.width === null ? null : this.width,
        events: {
          render: () => {
            const chart = this.Highcharts.charts[
              this.Highcharts.charts.length - 1
            ] as CustomChart;

            if (chart) {
              if (chart.categoryGroup && chart.renderer) {
                chart.categoryGroup.destroy();
              }

              chart.categoryGroup = chart.renderer.g('categoryGroup').add();

              chart.series[0]?.points.forEach((point) => {
                if (point.plotX !== undefined) {
                  chart.renderer
                    .text(
                      point.category as string,
                      chart.plotLeft + 2,
                      chart.plotHeight - point.plotX + chart.plotTop - 15
                    )
                    .css({
                      color: colors['gray-700'],
                      fontSize: typographyConfig.styles.c2.size,
                      fontWeight: typographyConfig.styles.c2.weight,
                      fontFamily: typographyConfig.styles.c2.fontFamily
                    })
                    .add(chart.categoryGroup);
                }
              });
            }
          }
        }
      },
      title: this.title,
      xAxis: this.xAxis,
      yAxis: this.yAxis,
      tooltip: this.tooltip,
      plotOptions: this.plotOptions,
      legend: this.legend,
      credits: { enabled: false },
      exporting: { enabled: false },
      series: this.series
    };
  }
}
