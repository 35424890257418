/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil
} from 'rxjs/operators';
import { capitalize, isEqual } from 'lodash-es';

// Chart Data Imports
import { donutChart } from '@design-system/components/m-donut-chart';

// Types and Services Imports
import { DataSetService } from '@portal/app/dashboard/home-page/services/data-set.service';
import { FormatService } from '@portal/app/dashboard/home-page/services/format.service';
import { FilterService } from '@portal/app/dashboard/home-page/services/filter.service';
import { FieldService } from '@portal/app/shared/services/field.service';

// Types
import { Filter } from '@portal/app/shared/types';
import { ChannelsService } from '@portal/app/dashboard/home-page/services/channels.service';

// Styles
import { colors } from '@design-system/styles/colors';
export interface Legend {
  color: string;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class SpendChartService implements OnDestroy {
  private chartDimensionsSubject = new BehaviorSubject<string[]>(['channel']);
  public chartDimensions$ = this.chartDimensionsSubject.asObservable();

  private chartDataSubject = new BehaviorSubject<any>(null);
  public chartData$ = this.chartDataSubject.asObservable();

  private chartLegendSubject = new BehaviorSubject<Legend[]>([]);
  public chartLegend$ = this.chartLegendSubject.asObservable();

  private currentAllFilters: Filter[] = [];
  private destroy$ = new Subject<void>();
  private donutChartOptions = donutChart.args;

  private isLoading = new BehaviorSubject<boolean>(true);

  private selectedChannels: string[] = [];

  constructor(
    private channelsService: ChannelsService,
    private dataSetService: DataSetService,
    private formatService: FormatService,
    private filterService: FilterService,
    private fieldService: FieldService
  ) {
    this.subscribeToChanges();
  }

  setChartDimensions(dimensions: string[]): void {
    this.chartDimensionsSubject.next(dimensions);
  }

  setChartLegend(name: string, color: string): void {
    const chartLegend = this.chartLegendSubject.getValue().slice(); // Create a shallow copy to avoid direct mutation

    // Check if the legend item already exists
    if (!chartLegend.some((legend: Legend) => legend.name === name)) {
      chartLegend.push({ name, color });
    }
    this.chartLegendSubject.next(chartLegend); // Update the observable value
  }

  private subscribeToChanges(): void {
    combineLatest([
      this.chartDimensions$,
      this.filterService.filters$,
      this.channelsService.selectedChannels$
    ])
      .pipe(
        map(([chartDimensions, filters, selectedChannels]) => ({
          chartDimensions,
          filters,
          selectedChannels
        })),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        debounceTime(300),
        takeUntil(this.destroy$)
      )
      .subscribe(({ chartDimensions, filters, selectedChannels }) => {
        this.chartLegendSubject.next([]);
        this.selectedChannels = selectedChannels;
        this.fetchData(chartDimensions, filters);
      });
  }

  get isLoading$(): Observable<boolean> {
    return this.isLoading.asObservable();
  }

  fetchData(dimensions: string[], filters: Filter[]): void {
    this.isLoading.next(true);

    const chartFilters = this.getChartFilters(filters);
    this.currentAllFilters = filters;
    this.dataSetService
      .fetchDataSets({
        dimensions,
        filters: chartFilters
      })
      .subscribe({
        next: (donutData: any) => {
          // Format data
          const formattedData = this.formatDonutChart(donutData);
          // Set data
          this.chartDataSubject.next(formattedData);
          this.isLoading.next(false);
        },
        error: (error: any) => {
          console.error('Error fetching donut bar chart data', error);
          this.isLoading.next(false);
        }
      });
  }

  getChartFilters(providedFilters: Filter[]): Filter[] {
    // Define which filters are needed for charts
    const neededFilterIds = [
      'dateStart',
      'dateStop',
      'relative_day',
      'conversion_type',
      'isInitialFilterCall',
      'resolution'
    ];

    // Select only necessary filters from providedFilters
    const selectedFilters = providedFilters.filter((filter) =>
      neededFilterIds.includes(filter.literalId)
    );

    // Hardcoded filters
    const hardcodedFilters = [
      this.filterService.createFilter({
        literalId: 'dataSets',
        type: 'string[]',
        value: ['spendAllocation']
      }),
      this.filterService.createFilter({
        literalId: 'channel',
        type: 'string[]',
        value: this.selectedChannels
      })
    ];

    // Combine selected provided filters with hardcoded filters
    const combinedFiltersMap = new Map(
      selectedFilters
        .concat(hardcodedFilters)
        .map((filter) => [filter.literalId, filter])
    );

    return Array.from(combinedFiltersMap.values());
  }

  formatDonutChart(donutData: any) {
    const fieldDefinition =
      this.fieldService.getFieldDefinitionByLiteralId('mediaSpend');
    const totalSpendFormatted = this.formatService.formatValue(
      fieldDefinition,
      donutData[0]?.dataPoints.mediaSpend
    );
    const innerContent = `<div class="flex flex-column items-center content-center justify-center flex-wrap">
      <div class="b1 text-gray-900">Total Spend</div>
      <div class="h2 text-gray-1000 mb-0">${totalSpendFormatted}</div>
      <div class="c1 text-blue-600 center cursor-pointer">
        View details
      </div>
    </div>
    `;

    return {
      ...this.donutChartOptions,
      innerContent,
      data: this.getSeriesData(donutData)
    };
  }

  getSeriesData(donutData: any) {
    const formatService = this.formatService;
    const fieldService = this.fieldService;
    const chartColors = [
      colors['yellow-700'],
      colors['blue-400'],
      colors['green-700'],
      colors['green-500'],
      colors['blue-700'],
      colors['yellow-300']
    ];

    let accumulatedPercentage = 100;

    return donutData[0]?.data.map((item: any, index: any) => {
      // Sanitize and validate numerical values
      const mediaSpendValue = this.sanitizeAndValidateNumber(item?.mediaSpend);
      const roasIValue = this.sanitizeAndValidateNumber(item?.roasI);
      const percSpendValue = this.sanitizeAndValidateNumber(item?.percSpend, 1);

      const mediaSpend =
        fieldService.getFieldDefinitionByLiteralId('mediaSpend');
      const spendFormatted = formatService.formatValue(
        mediaSpend,
        mediaSpendValue
      );
      const roasI = fieldService.getFieldDefinitionByLiteralId('roasI');
      const roasFormatted = formatService.formatValue(roasI, roasIValue);

      const percentageValue = percSpendValue * 100;
      const percentage = percentageValue.toFixed(2);
      const name = item?.channel || capitalize(item?.adPlatform);
      const color = chartColors[index % chartColors.length];
      // Update chart legend for each item
      this.setChartLegend(name, color as string);

      const tooltipHtml = `
        <div>
          <div class="flex justify-center items-center w-full h-full">
            <div class="b3 text-gray-500">${name}: ${percentage}%</div>
          </div>
          <div class="m-divider my-2"></div>
          <div class="flex justify-between w-full">
            <span class="b1 text-gray-000">Spend:</span>
            <span class="b1 text-gray-000">${spendFormatted}</span>
          </div>
          <div class="flex justify-between w-full">
            <span class="b1 text-gray-000">ROAS (i):</span>
            <span class="b1 text-gray-000">${roasFormatted}</span>
          </div>
        </div>`;

      const yValue = accumulatedPercentage;
      if (percentageValue > 0) {
        accumulatedPercentage -= percentageValue;
      }

      return {
        color,
        name,
        y: yValue,
        tooltipHtml
      };
    });
  }

  getFilters(): Filter[] {
    return this.currentAllFilters;
  }

  getActivateChannels(): string[] {
    return this.selectedChannels;
  }

  sanitizeAndValidateNumber(input: any, defaultValue = 0): number {
    // Attempt to parse the input as a float
    const sanitized = parseFloat(input);
    // Check if the result is a finite number
    if (isFinite(sanitized)) {
      return sanitized;
    }
    // Return a default value if input is NaN, Infinity, -Infinity, or any non-numeric value
    return defaultValue;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
