import { computed, inject, Injectable, OnDestroy, signal } from '@angular/core';
import { DataResponse } from '@portal/app/shared/types';
import {
  ContextModalParams,
  ContextModalType
} from '@portal/app/dashboard/context-modal/context-model.types';
import { SelectionService } from '@portal/app/shared/services/selection.service';
import { distinctUntilChanged, merge, Subject, takeUntil } from 'rxjs';
import { ContextStore } from '@portal/app/shared/state/context.store';

export interface MetricOption {
  label: string;
  value: string;
  disabled: boolean;
}

export interface DimensionOption {
  label: string;
  channel: string;
  tactic?: string;
}

@Injectable({
  providedIn: 'root'
})
export class LinkService implements OnDestroy {
  private destroy$ = new Subject<void>();
  private selectionService = inject(SelectionService);
  private contextStore = inject(ContextStore);

  private channelsSignal = signal<ContextModalParams[]>([]);
  private tacticsSignal = signal<ContextModalParams[]>([]);

  private metricOptionsSignal = signal<MetricOption[]>([]);
  private selectedMetricsSignal = signal<string[]>([]);

  constructor() {
    merge(
      this.selectionService.selectionChanged,
      this.contextStore.filterContext
    )
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(() => {
        this.clearAll();
      });
  }

  addRow(row: DataResponse) {
    if (this.isChannel(row)) {
      this.addChannelRow(row);
    } else if (this.isTactic(row)) {
      this.addTacticRow(row);
    }
  }

  addChannelRow(row: DataResponse) {
    this.channelsSignal.update((current) => {
      if (
        !this.channels().find(
          (curr) =>
            curr[ContextModalType.channel] === row[ContextModalType.channel]
        )
      ) {
        return [...current, { channel: row.channel as string }];
      }
      return current;
    });
  }

  addTacticRow(row: DataResponse) {
    this.tacticsSignal.update((current) => {
      if (
        !this.tactics().find(
          (curr) =>
            curr[ContextModalType.channel] === row[ContextModalType.channel] &&
            curr[ContextModalType.tactic] === row[ContextModalType.tactic]
        )
      ) {
        return [
          ...current,
          { channel: row.channel as string, tactic: row.tactic as string }
        ];
      }
      return current;
    });
  }

  clearAll() {
    this.channelsSignal.set([]);
    this.tacticsSignal.set([]);
    this.metricOptionsSignal.set([]);
  }

  setMetrics(metrics: MetricOption[], selectedMetric: string[]) {
    this.metricOptionsSignal.set(metrics);
    this.selectedMetricsSignal.set(selectedMetric);
  }

  setSelectedMetrics(metrics: string[]) {
    this.selectedMetricsSignal.set(metrics);
  }

  get channels() {
    return this.channelsSignal.asReadonly();
  }

  get tactics() {
    return this.tacticsSignal.asReadonly();
  }

  get metricOptions() {
    return this.metricOptionsSignal.asReadonly();
  }

  get selectedMetrics() {
    return this.selectedMetricsSignal.asReadonly();
  }

  channelsOptions = computed(() => {
    return this.channels().map((item) => ({
      label: item.channel,
      channel: item.channel
    })) as DimensionOption[];
  });

  tacticsOptions = computed(() => {
    return this.mapTacticsOptions(this.tactics());
  });

  mapTacticsOptions(tactics: ContextModalParams[]) {
    return tactics.map(
      (item) =>
        ({
          label: `${item.tactic} (${item.channel})`,
          channel: item.channel,
          tactic: item.tactic
        }) as DimensionOption
    );
  }

  getModalType(row: DataResponse) {
    const tactic = row[ContextModalType.tactic];
    const channel = row[ContextModalType.channel];
    if (channel && tactic) {
      return ContextModalType.tactic;
    } else if (channel) {
      return ContextModalType.channel;
    }
    return ContextModalType.unknown;
  }

  isLink(row: DataResponse) {
    return this.getModalType(row) !== ContextModalType.unknown;
  }

  isChannel(row: DataResponse) {
    return this.getModalType(row) === ContextModalType.channel;
  }

  isTactic(row: DataResponse) {
    return this.getModalType(row) === ContextModalType.tactic;
  }

  findNextChannel(row: ContextModalParams) {
    if (!this.channels().length) {
      return null;
    }
    const index = this.channels().findIndex(
      (item) => item.channel === row[ContextModalType.channel]
    );
    if (index !== -1 && index < this.channels().length - 1) {
      return this.channels()[index + 1];
    }
    return this.channels()[0];
  }

  findPrevChannel(row: ContextModalParams) {
    if (!this.channels().length) {
      return null;
    }
    const index = this.channels().findIndex(
      (item) => item.channel === row[ContextModalType.channel]
    );
    if (index !== -1 && index > 0) {
      return this.channels()[index - 1];
    }
    return this.channels()[this.channels().length - 1];
  }

  findNextTactic(row: ContextModalParams, data?: ContextModalParams[]) {
    const tactics = data || this.tactics();
    if (!tactics.length) {
      return null;
    }
    const index = tactics.findIndex(
      (item) => item.channel === row.channel && item.tactic === row.tactic
    );
    if (index !== -1 && index < tactics.length - 1) {
      return tactics[index + 1];
    }
    return tactics[0];
  }

  findPrevTactic(row: ContextModalParams, data?: ContextModalParams[]) {
    const tactics = data || this.tactics();
    if (!tactics.length) {
      return null;
    }
    const index = tactics.findIndex(
      (item) => item.channel === row.channel && item.tactic === row.tactic
    );
    if (index !== -1 && index > 0) {
      return tactics[index - 1];
    }
    return tactics[tactics.length - 1];
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
