import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DataResponse, Filter } from '@portal/app/shared/types';
import {
  BadgeType,
  IBadgeConfig,
  IHeaderConfig,
  IStatusBadgeConfig
} from '@portal/app/shared/models/IHeaderConfig';
import { Observable, Subscription } from 'rxjs';
import { ContextStore } from '@portal/app/shared/state/context.store';
import { NativeSectionsService } from '@portal/app/shared/services/native-sections.service';
import { isMeasuredBenchmarkDrillDownPage } from '@portal/app/dashboard/utils';

@Component({
  selector: 'portal-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent implements OnInit, OnDestroy {
  @Input() headerConfig!: IHeaderConfig;
  @Input() headerData!: DataResponse;
  @Input() showEmptyData = true;
  @Input() dashboardLiteralId = '';

  badges: IBadgeConfig[] = [];
  iconBadges: IBadgeConfig[] = [];
  statusBadges: IStatusBadgeConfig[] = [];
  filterContext$: Observable<Filter[]> = this.contextStore.filterContext;
  private subs: Subscription = new Subscription();

  constructor(
    public readonly nativeSectionsService: NativeSectionsService,
    private readonly contextStore: ContextStore
  ) {}

  ngOnInit(): void {
    this.categorizeBadgesBasedOnType();
  }

  categorizeBadgesBasedOnType(): void {
    this.badges = this.headerConfig.badges.filter(
      (e) => e.type === BadgeType.badge
    );
    this.iconBadges = this.headerConfig.badges.filter(
      (e) => e.type === BadgeType.iconBadge && !e.dashboardFilterId
    );
    this.statusBadges = this.headerConfig.badges.filter(
      (e) => e.type === BadgeType.iconBadge && e.dashboardFilterId
    );

    if (this.statusBadges.length) {
      this.getStatusBadgesInfo();
    }
  }

  getStatusBadgesInfo(): void {
    const context = this.filterContext$.subscribe({
      next: (filterSet) => {
        this.statusBadges.forEach((element) => {
          const filterValue = filterSet.find(
            (f) => f.literalId === element.dashboardFilterId
          )?.value as string[];
          element.value = this.getStatusBadgeDisplayValue(filterValue);
        });

        this.filterBadgesBasedOnDashboard();
      }
    });

    this.subs.add(context);
  }

  getStatusBadgeDisplayValue(filterValue?: string[]): string | undefined {
    // We get string[] for inactive option in benchmarks dropdown option. So validating if its an array, else skipping it.
    if (
      Array.isArray(filterValue) &&
      (filterValue.includes('New') || filterValue.includes('Inactive'))
    ) {
      return 'Inactive';
    }
  }

  filterBadgesBasedOnDashboard(): void {
    if (isMeasuredBenchmarkDrillDownPage(this.dashboardLiteralId)) {
      this.statusBadges = this.statusBadges.filter(
        (e) => e.value === 'Inactive'
      );
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
