import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ViewService } from '@portal/app/shared/services/view.service';

@Component({
  selector: 'portal-metrics-bar-visualizer',
  templateUrl: './metrics-bar-visualizer.component.html',
  styleUrls: ['./metrics-bar-visualizer.component.scss']
})
export class MetricsBarVisualizerComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() key = '';
  @Input() label!: string;
  @Input() value!: number;
  @Input() totalItems!: number;
  @Input() otherItems!: number;
  @Input() vizMetric = 'Metric';
  @Input() valueTooltip = '';
  @Input() medianTooltip = 'Benchmark';
  @Input() tooltipPosition: 'top' | 'bottom' = 'top';

  @ViewChild('progressBarContainer') progressBarContainer!: ElementRef;
  private progressBarMouseEnterEventListener!: EventListener;
  private progressBarMouseLeaveEventListener!: EventListener;

  constructor(private readonly viewService: ViewService) {}

  ngOnInit(): void {
    this.assignTooltip();
  }

  ngAfterViewInit(): void {
    // TODO: Find alternate approach to show tooltip to resolve the overlap with css
    const progressBarContainer = this.progressBarContainer.nativeElement;
    const indicator = progressBarContainer.querySelector(
      '.metric-value-dot-container'
    );

    this.progressBarMouseEnterEventListener = (): void => {
      this.onHover('primary');
      indicator.dispatchEvent(new Event('mouseenter'));
    };

    this.progressBarMouseLeaveEventListener = (): void => {
      this.onExit('primary');
      indicator.dispatchEvent(new Event('mouseleave'));
    };

    progressBarContainer.addEventListener(
      'mouseenter',
      this.progressBarMouseEnterEventListener
    );
    progressBarContainer.addEventListener(
      'mouseleave',
      this.progressBarMouseLeaveEventListener
    );
  }

  assignTooltip(): void {
    if (!this.valueTooltip) {
      const comparisonText = this.value === 0 ? 'lower' : 'higher';

      this.valueTooltip = `<div>Your ${this.vizMetric.toLowerCase()} is ${comparisonText} than ${
        this.value === 0 || this.value === 100
          ? 'all other brands.'
          : `<span class="value-tooltip-number">${this.otherItems}</span> out of <span class="value-tooltip-number">${this.totalItems}</span> brands.`
      }</div>`;
    }
  }

  onHover(type: 'primary' | 'secondary'): void {
    this.viewService.setComponentInteraction({
      key: this.key,
      subType: type,
      isHovered: true
    });
  }

  onExit(type: 'primary' | 'secondary'): void {
    this.viewService.setComponentInteraction({
      key: this.key,
      subType: type,
      isHovered: false
    });
  }

  ngOnDestroy(): void {
    const progressBarContainer = this.progressBarContainer.nativeElement;
    progressBarContainer.removeEventListener(
      'mouseenter',
      this.progressBarMouseEnterEventListener
    );
    progressBarContainer.removeEventListener(
      'mouseleave',
      this.progressBarMouseLeaveEventListener
    );
  }
}
