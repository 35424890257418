import { Injectable } from '@angular/core';
import {
  ApiService,
  CampaignType,
  HeroMetricType,
  IChannelsMappedAndUnmappedCount,
  ICompositeDSReportingDimensionsConfig,
  IFilterCriteria,
  IHeroMetricCard,
  IVCampaign
} from '@libs/apis';
import { IPTableColumnInfo } from '@libs/common-components';
import { ITabPanelHeader } from '@design-system/components/m-tab-view';
import { camelCase, cloneDeep, orderBy, sumBy } from 'lodash-es';
import { Constants } from '../components/campaigns-view/campaigns-view.constants';
import { TaxonomyHelper } from '../helper/taxonomy-helper';
import {
  TaxonomyDateRange,
  TaxonomyDimension
} from '../models/ICampaignFilterContext';
import { ICampaignsCountByDimension } from '../models/ICampaignsCountByDataSource';
import { IHeroMetricConfig } from '../models/IHeroMetricConfig';
import { TaxonomyTab } from '../models/TaxonomyTab';
import { CampaignDimension } from '../taxonomy-ui/taxonomy-ui.constants';
import { SharedDataService } from './shared-data.service';

@Injectable({
  providedIn: 'root'
})
export class CampaignViewService {
  private campaignViewTableColumnsByDimension: Record<
    string,
    IPTableColumnInfo[]
  > = {};

  constructor(
    private readonly apiService: ApiService,
    private readonly sharedDataService: SharedDataService
  ) {}

  public getHeroMetricConfig(heroMetrics: IHeroMetricCard[]) {
    return heroMetrics.map((metric) => {
      const config: IHeroMetricConfig = {
        campaignCount: Number(metric.campaignCount).toLocaleString('en-US'),
        mediaSpend: metric.spend
      } as IHeroMetricConfig;
      switch (metric.type) {
        case HeroMetricType.UNMAPPED:
          config.header = `Unmapped Campaigns (Last 30 Days)`;
          config.suffix = 'which needs mapping.';
          break;
        case HeroMetricType.MAPPED:
          config.header = `Campaigns Mapped (Last 30 Days)`;
          break;
        case HeroMetricType.TOTAL_MAPPED:
          config.header = `Total Campaigns Mapped`;
          break;
        default:
          break;
      }
      return config;
    });
  }

  public async getCampaignCounts(
    daterange: string,
    groupByDimension: TaxonomyDimension,
    campaignType?: TaxonomyTab
  ): Promise<Record<string, ICampaignsCountByDimension>> {
    try {
      const { clientId, brandId } =
        this.sharedDataService.getClientAndBrandId();

      const campaignsCount =
        await this.apiService.taxonomyApisOperations.getCampaignsCounts(
          Number(clientId),
          Number(brandId),
          true,
          daterange,
          groupByDimension,
          campaignType as unknown as CampaignType
        );

      return this.prepareCampaignCountByCampaignType(
        campaignsCount,
        groupByDimension
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error('Error: ', error.message);
      throw error;
    }
  }

  public async getCampaignCountWithFilter(
    daterange: string,
    groupByDimension: TaxonomyDimension,
    activeDimensionValue: string,
    campaignType?: TaxonomyTab,
    filterCriteria?: IFilterCriteria[]
  ): Promise<Record<string, ICampaignsCountByDimension>> {
    try {
      const { clientId, brandId } =
        this.sharedDataService.getClientAndBrandId();
      const campaignsCount =
        await this.apiService.taxonomyApisOperations.getCampaignCountWithFilter(
          Number(clientId),
          Number(brandId),
          {
            clientId,
            brandId,
            daterange,
            groupByDimension,
            campaignType,
            filterCriteria,
            includeInProgress: true,
            dimensions: [activeDimensionValue]
          }
        );

      return this.prepareCampaignCountByCampaignType(
        campaignsCount,
        groupByDimension
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error('Error: ', error.message);
      throw error;
    }
  }

  public prepareCampaignCountByCampaignType(
    campaignCounts: IChannelsMappedAndUnmappedCount[],
    groupByDimension: TaxonomyDimension
  ): Record<string, ICampaignsCountByDimension> {
    const campaignCountByDimension: Record<string, ICampaignsCountByDimension> =
      {};
    Object.values(TaxonomyTab).forEach((tab) => {
      campaignCountByDimension[tab] = {
        total: 0,
        metrics: []
      } as ICampaignsCountByDimension;
    });

    for (const campaignCount of campaignCounts) {
      this.addMappedCampaignCountIfCampaignsExists(
        campaignCount,
        campaignCountByDimension,
        groupByDimension
      );
      this.addUnmappedCampaignCountIfCampaignsExists(
        campaignCount,
        campaignCountByDimension,
        groupByDimension
      );
      this.addInProgressCampaignCountIfCampaignsExists(
        campaignCount,
        campaignCountByDimension,
        groupByDimension
      );
    }
    this.sortCampaignsCountByDatasource(campaignCountByDimension);
    return campaignCountByDimension;
  }

  public setTotalCountForTabs(
    campaignCountByDimension: Record<string, ICampaignsCountByDimension>
  ) {
    const tabs = cloneDeep(Constants.tabPanelHeaders);
    if (!Object.keys(campaignCountByDimension).length) {
      return tabs;
    }
    tabs.forEach((tab: ITabPanelHeader) => {
      tab.count = sumBy(
        campaignCountByDimension[camelCase(tab.field)]?.metrics,
        'count'
      );
    });

    return tabs;
  }

  private sortCampaignsCountByDatasource(
    campaignCountByDimension: Record<string, ICampaignsCountByDimension>
  ): void {
    Object.keys(campaignCountByDimension).forEach((dimension) => {
      if (campaignCountByDimension[dimension] !== undefined) {
        Object(campaignCountByDimension[dimension]).metrics = orderBy(
          campaignCountByDimension[dimension]?.metrics,
          ['spend'],
          ['desc']
        );
      }
    });
  }

  private addMappedCampaignCountIfCampaignsExists(
    campaignCount: IChannelsMappedAndUnmappedCount,
    campaignCountByDimension: Record<string, ICampaignsCountByDimension>,
    groupByDimension: TaxonomyDimension
  ) {
    if (!campaignCount.mappedCount) {
      return;
    }
    if (campaignCountByDimension[TaxonomyTab.mapped]) {
      campaignCountByDimension[TaxonomyTab.mapped].total +=
        campaignCount.mappedCount;
      campaignCountByDimension[TaxonomyTab.mapped].metrics.push({
        dimension: campaignCount[groupByDimension],
        count: campaignCount.mappedCount,
        spend: campaignCount.mappedSpend
      });
    }
  }

  private addUnmappedCampaignCountIfCampaignsExists(
    campaignCount: IChannelsMappedAndUnmappedCount,
    campaignCountByDimension: Record<string, ICampaignsCountByDimension>,
    groupByDimension: TaxonomyDimension
  ) {
    if (!campaignCount.unmappedCount) {
      return;
    }
    if (campaignCountByDimension[TaxonomyTab.unmapped]) {
      campaignCountByDimension[TaxonomyTab.unmapped].total +=
        campaignCount.unmappedCount;
      campaignCountByDimension[TaxonomyTab.unmapped].metrics.push({
        dimension: campaignCount[groupByDimension],
        count: campaignCount.unmappedCount,
        spend: campaignCount.unmappedSpend
      });
    }
  }

  private addInProgressCampaignCountIfCampaignsExists(
    campaignCount: IChannelsMappedAndUnmappedCount,
    campaignCountByDS: Record<string, ICampaignsCountByDimension>,
    groupByDimension: TaxonomyDimension
  ) {
    if (!campaignCount.modifiedCount) {
      return;
    }
    (
      campaignCountByDS[
        TaxonomyTab.underProcessing
      ] as ICampaignsCountByDimension
    ).total += campaignCount.modifiedCount;

    campaignCountByDS[TaxonomyTab.underProcessing]?.metrics.push({
      dimension: campaignCount[groupByDimension],
      count: campaignCount.modifiedCount
    });
  }

  public getCampaignsByDimension(
    clientId: number,
    brandId: number,
    campaignType: string,
    dimension: string,
    filterCriteria: IFilterCriteria[],
    offset: number,
    limit: number,
    daterange: TaxonomyDateRange,
    groupByDimension: TaxonomyDimension
  ): Promise<IVCampaign[]> {
    const campaignTypeKey =
      campaignType === TaxonomyTab.underProcessing
        ? CampaignType.UNDER_PROCESSING_CAMPAIGN
        : campaignType;
    return this.apiService.taxonomyApisOperations.getCampaignsByDatasourceNChannel(
      clientId,
      brandId,
      {
        campaignType: campaignTypeKey,
        dimensionValue: dimension,
        filterCriteria,
        groupByDimension,
        offset,
        limit,
        daterange
      }
    );
  }

  public prepareSelectedCampaignDimensionsValues(
    campaigns: IVCampaign[]
  ): Record<CampaignDimension, string[]> {
    const dimensionValues = {} as Record<CampaignDimension, string[]>;
    Object.values(CampaignDimension).forEach((dimension) => {
      dimensionValues[dimension] =
        TaxonomyHelper.getNonDuplicateSelectedCampaignDimensionOptions(
          dimension,
          campaigns
        );
    });
    return dimensionValues;
  }

  public async getDimensionsByDatasourceOrGroupByCampaignDimension(
    dimension: TaxonomyDimension,
    datasource: string,
    campaignType: TaxonomyTab | 'inReview'
  ): Promise<IPTableColumnInfo[]> {
    const campaignColumnsKey = dimension + '-' + campaignType;

    if (
      this.campaignViewTableColumnsByDimension[campaignColumnsKey] &&
      dimension !== TaxonomyDimension.compositeDataSourceName
    ) {
      return this.campaignViewTableColumnsByDimension[campaignColumnsKey] || [];
    }

    let dimensionConfigs: ICompositeDSReportingDimensionsConfig[] = [];

    if (dimension === TaxonomyDimension.compositeDataSourceName) {
      try {
        dimensionConfigs =
          await this.apiService.taxonomyApisOperations.getCompositeDSReportingDimensionConfigs(
            datasource
          );
      } catch (error) {
        console.error('Error: ', error);
      }
    }
    const columns = dimensionConfigs.map((config) => {
      let width = '';
      if (dimensionConfigs.length > 2) {
        width = Constants.dimensionWidthMap[config.ccmafColumnName] || '';
      } else if (dimensionConfigs.length === 2) {
        width = '25rem';
      } else {
        width = '30rem';
      }
      return {
        field: config.ccmafColumnName,
        header: config.columnLabel,
        width: `${width} !important`,
        isFilterEnabled: true
      };
    });

    const key =
      (dimension === TaxonomyDimension.compositeDataSourceName
        ? datasource
        : dimension) +
      '-' +
      campaignType;

    this.campaignViewTableColumnsByDimension[key] =
      this.collateDimensionSpecificAndDefaultColumns(
        columns,
        dimension,
        campaignType
      );

    return this.campaignViewTableColumnsByDimension[key] || [];
  }

  private collateDimensionSpecificAndDefaultColumns(
    columns: { field: string; width: string; header: string }[],
    dimension: TaxonomyDimension,
    campaignType: TaxonomyTab | 'inReview'
  ) {
    const defaultColumns =
      dimension === TaxonomyDimension.compositeDataSourceName
        ? columns.length
          ? columns
          : Constants.defaultDataSourceCampaignHeaders
        : columns;
    const campaignViewColumns = [
      ...defaultColumns,
      ...Constants.defaultCampaignHeadersByDimension[dimension]
    ] as IPTableColumnInfo[];
    if (campaignType !== TaxonomyTab.underProcessing) {
      return campaignViewColumns;
    }
    let impactColumnPriorIndex = -1;
    if (dimension === TaxonomyDimension.compositeDataSourceName) {
      impactColumnPriorIndex = campaignViewColumns.findIndex(
        (column) => column.field === 'accountName'
      );
    } else if (dimension === TaxonomyDimension.tactic) {
      impactColumnPriorIndex = campaignViewColumns.findIndex(
        (column) => column.field === 'compositeDataSourceName'
      );
    }
    if (impactColumnPriorIndex > -1) {
      campaignViewColumns.splice(
        impactColumnPriorIndex + 1,
        0,
        Constants.impactColumn
      );
    }
    return campaignViewColumns;
  }

  public getGeoTestStatusByDimension(clientId: number, brandId: number): void {
    this.apiService.taxonomyApisOperations
      .getGeoTestStatusByDimension(clientId, brandId)
      .then((geoTestStatus) => {
        this.sharedDataService.setGeoTestStatusByDimension(geoTestStatus);
      });
  }
}
