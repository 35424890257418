import { ITitle, TitleTypes } from '@libs/common-components';
import { IHeaderConfig } from '@portal/app/shared/models/IHeaderConfig';

const headerConfig: IHeaderConfig = {
  primaryTitle: {
    field: 'dataExportLabel',
    span: 12,
    defaultValue: 'Data Export'
  },
  secondaryTitles: [],
  badges: [],
  faq: {
    field: 'https://support.measured.com/article/hsls1dk7b1-data-export',
    label: 'Get info about <br/> Data Export'
  }
};

const titleConfig: ITitle[] = [
  {
    title: 'Data Export',
    subTitle: null,
    styleClass: '',
    type: TitleTypes.h3
  },
  {
    title: `Use these fields to specify the data you'll receive`,
    subTitle: null,
    styleClass: 'text-gray fw-normal',
    type: TitleTypes.info
  }
];

const timeSeriesResolution: { label: string; value: string }[] = [
  {
    label: 'Day',
    value: 'day'
  },
  {
    label: 'Week',
    value: 'week'
  },
  {
    label: 'Month',
    value: 'month'
  },
  {
    label: 'Quarter',
    value: 'quarter'
  },
  {
    label: 'Full Period',
    value: 'fullPeriod'
  }
];

const exportReportFormats: { label: string; value: string }[] = [
  {
    label: 'CSV',
    value: 'csv'
  },
  {
    label: 'Excel',
    value: 'xlsx'
  }
];

const infoMessage = `We'll send a downloadable link to the following email:`;

const exportDataFormDefaultValue = {
  reportName: 'xcd',
  timeSeriesResolution: 'day',
  exportFormat: 'csv'
};

export const exportDataConfigs = {
  headerConfig,
  titleConfig,
  timeSeriesResolution,
  exportReportFormats,
  infoMessage,
  exportDataFormDefaultValue
};
