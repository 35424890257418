import { computed, inject, Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  CompareDataByMetricResponseDTO,
  ContextModalType,
  MetricDetailsRequestDTO
} from '@portal/app/dashboard/context-modal/context-model.types';
import { catchError, filter, map, Observable, of, switchMap, tap } from 'rxjs';
import { ModalService } from '@portal/app/dashboard/context-modal/services/modal.service';
import { MetricsService } from '@portal/app/dashboard/context-modal/services/metrics.service';
import { ProgressBarComparisonOptions } from '@design-system/components/m-progress-bar-comparison';
import { isEmpty } from 'lodash-es';

interface CompareDataByMetricResponse {
  response: CompareDataByMetricResponseDTO;
  chartOptions: ProgressBarComparisonOptions;
}

@Injectable()
export class CompareChartService {
  private readonly http: HttpClient = inject(HttpClient);
  private readonly modalService: ModalService = inject(ModalService);

  private readonly metricsService: MetricsService = inject(MetricsService);

  private initialValue: CompareDataByMetricResponse = {
    response: {} as CompareDataByMetricResponseDTO,
    chartOptions: {} as ProgressBarComparisonOptions
  };

  private isLoadingSignal = signal<boolean>(false);

  public data = toSignal(this.fetchData$(), {
    initialValue: this.initialValue
  });

  get isLoading() {
    return this.isLoadingSignal.asReadonly();
  }

  isChartReady = computed(() => {
    return !this.isLoading() && !isEmpty(this.data().chartOptions);
  });

  showDrivers = computed(() => {
    const metric = this.metricsService.getActiveMetric();
    return (
      metric?.literalId !== 'totalSales' &&
      metric?.literalId !== 'totalOrders' &&
      this.modalService.modalType() === ContextModalType.channel
    );
  });

  getParams() {
    const modalParams = this.modalService.modalParams();
    return {
      ...this.modalService.getParams(),
      dimension: modalParams.tactic ? 'tactic' : 'channel',
      filter: {
        ...this.modalService.getDateParams(),
        channel: modalParams.channel,
        tactic: modalParams.tactic ? modalParams.tactic : undefined,
        conversion_type: this.modalService.conversionType(),
        literalId: this.metricsService.getActiveMetric()?.literalId
      }
    } as MetricDetailsRequestDTO;
  }

  fetchData$(): Observable<CompareDataByMetricResponse> {
    const url = `${ModalService.baseUriV1}/metric-level-comparison`;

    return this.metricsService.activeTabIndex$.pipe(
      filter((index: number) => index > -1),
      tap(() => this.isLoadingSignal.set(true)),
      switchMap(() =>
        this.http
          .post<CompareDataByMetricResponseDTO>(url, this.getParams())
          .pipe(
            catchError((error) => {
              console.error('Error fetching top metric data', error);
              this.isLoadingSignal.set(false);
              return of({} as CompareDataByMetricResponseDTO);
            }),
            map((response) => ({
              response,
              chartOptions: this.formatData(response)
            }))
          )
      ),
      tap(() => this.isLoadingSignal.set(false))
    );
  }

  private formatData(response: CompareDataByMetricResponseDTO) {
    const activeMetric = this.metricsService.getActiveMetric();

    const prevPeriod = this.modalService.comparePreviousPeriod();
    const curPeriod = this.modalService.compareCurrentPeriod();

    const chartOptions: ProgressBarComparisonOptions = {
      prevPeriodValue: response?.previousPeriodValue || 0,
      curPeriodValue: response?.value || 0,
      prevPeriod,
      curPeriod,
      prevPeriodFormattedValue: response?.previousPeriodFormattedValue,
      curPeriodFormattedValue: response?.formattedValue,
      percentageChange: activeMetric?.compareValue || 0,
      isPositive: activeMetric?.changeStatus === 'POSITIVE' || false,
      isUp: activeMetric?.arrowDirection === 'UP' || false,
      isNeutral: activeMetric?.changeStatus === 'NEUTRAL' || false,
      curPeriodDiff: activeMetric?.compareFormattedValue as string,
      isLarge:
        activeMetric?.literalId === 'totalSales' ||
        activeMetric?.literalId === 'totalOrders'
    };

    return chartOptions;
  }
}
