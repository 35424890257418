import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { IVCampaign } from '@libs/apis';
import { camelCase, cloneDeep, fromPairs, sortBy, toPairs } from 'lodash-es';
import { AccordionTab } from 'primeng/accordion';
import { BehaviorSubject } from 'rxjs';
import { TaxonomyHelper } from '../../helper/taxonomy-helper';
import { TaxonomyDimension } from '../../models/ICampaignFilterContext';
import { ReviewAction } from '../../models/ReviewAction';
import { CampaignViewService } from '../../services/campaign-view.service';
import { SharedDataService } from '../../services/shared-data.service';
import { Constants } from '../campaigns-view/campaigns-view.constants';
import { ReviewCampaignsService } from './review-campaigns.service';

@Component({
  selector: 'lib-taxonomy-unpublished-campaigns',
  templateUrl: './unpublished-campaigns.component.html',
  styleUrls: ['./unpublished-campaigns.component.scss']
})
export class UnpublishedCampaignsComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() timezone = 'America/New_York';
  @Input() unpublishedCampaigns: IVCampaign[] = [];
  @Input() showUnpublishedCampaigns = false;
  @Output() showUnpublishedCampaignsChanged = new EventEmitter<boolean>();
  @Output() removeUnpublishedCampaigns = new EventEmitter<{
    action: ReviewAction;
    payload: Record<string, string | null | undefined>;
  }>();

  unpublishedCampaignTitleConfigs = Constants.unpublishedCampaignTitleConfigs;
  taxonomyDimensions = Constants.groupByDimensions;

  geoTestStatusByDimension: Record<string, boolean> = {};
  loading = new BehaviorSubject<boolean>(true);
  activeDimension = TaxonomyDimension.compositeDataSourceName;
  campaignViewColumns = cloneDeep(
    Constants.defaultCampaignHeadersByDimension[this.activeDimension]
  );

  activeIndex = 0;
  campaignsByDimension: Record<string, Record<string, IVCampaign[]>> = {};
  campaignCountByDimension: Record<string, Record<string, number>> = {};

  constructor(
    private readonly reviewCampaignsService: ReviewCampaignsService,
    private readonly campaignViewService: CampaignViewService,
    private readonly sharedDataService: SharedDataService,
    private readonly renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.updateBodyOverflowProperty();
    this.prepareCampaignData();
    this.getGeoTestStatus();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['inReviewCampaigns'] &&
      !changes['inReviewCampaigns'].isFirstChange()
    ) {
      this.unpublishedCampaigns = changes['inReviewCampaigns'].currentValue;
    }

    if (
      changes['showInReviewCampaigns'] &&
      !changes['showInReviewCampaigns'].isFirstChange()
    ) {
      this.showUnpublishedCampaigns =
        changes['showInReviewCampaigns'].currentValue;
      this.updateBodyOverflowProperty();
    }
    if (
      changes['unpublishedCampaigns'] &&
      !changes['unpublishedCampaigns'].isFirstChange()
    ) {
      this.prepareCampaignData();
    }
  }

  ngOnDestroy(): void {
    this.updateBodyOverflowProperty(true);
  }

  closeInReviewCampaigns() {
    this.updateBodyOverflowProperty(true);
    this.showUnpublishedCampaignsChanged.emit(false);
  }

  handleImageError(event: Event, dimensionValue: string): void {
    const img = event.target as HTMLImageElement;
    if (!img.src.includes('.png')) {
      img.src = `/assets/images/integrations/${dimensionValue
        ?.toLowerCase()
        .replace(' ', '-')}-logo.png`;
    } else {
      img.src = `/assets/images/integrations/default-datasource.svg`;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  prepareCampaignData(event?: any) {
    if (event?.value) {
      this.activeDimension = event.value;
    }
    this.loading.next(true);
    const enrichedCampaigns = this.unpublishedCampaigns.map((campaign) =>
      TaxonomyHelper.enrichCampaign(campaign, this.timezone)
    );
    Object.values(TaxonomyDimension).forEach((dimension) => {
      this.campaignsByDimension = {
        ...this.campaignsByDimension,
        ...this.reviewCampaignsService.transformToCampaignsByDimension(
          enrichedCampaigns,
          dimension,
          dimension === TaxonomyDimension.compositeDataSourceName
            ? TaxonomyDimension.compositeDataSourceName
            : camelCase(`operational_${dimension}`)
        )
      };

      this.campaignCountByDimension = {
        ...this.campaignCountByDimension,
        ...this.reviewCampaignsService.transformToCampaignCountByDimension(
          enrichedCampaigns,
          dimension,
          dimension === TaxonomyDimension.compositeDataSourceName
            ? TaxonomyDimension.compositeDataSourceName
            : camelCase(`operational_${dimension}`)
        )
      };

      this.campaignCountByDimension = fromPairs(
        sortBy(toPairs(this.campaignCountByDimension), 0)
      );
    });
    this.prepareCampaignTableColumnsByDimension(this.activeIndex);
    this.loading.next(false);
  }

  private getGeoTestStatus() {
    this.sharedDataService
      .getGeoTestStatusByDimension()
      .subscribe((geoTestStatusByDimension) => {
        this.geoTestStatusByDimension = geoTestStatusByDimension;
      });
  }

  public prepareCampaignTableColumnsByDimension(
    activeIndex: number | null,
    tabs?: AccordionTab[]
  ) {
    console.warn('accordion tab header: ', tabs);
    if (activeIndex === null || activeIndex < 0) {
      return;
    }
    const dimensionValue = Object.keys(
      this.campaignCountByDimension[this.activeDimension] || {}
    )[activeIndex];

    if (!dimensionValue || !dimensionValue.length) {
      return;
    }

    this.loading.next(true);
    this.campaignViewService
      .getDimensionsByDatasourceOrGroupByCampaignDimension(
        this.activeDimension,
        dimensionValue || '',
        'inReview'
      )
      .then((columns) => {
        const inReviewColumns = cloneDeep(columns);
        inReviewColumns.forEach((column) => {
          if (['tactic', 'channel', 'segment'].includes(column.field)) {
            column.field = camelCase(`operational_${column.field}`);
          }
        });
        this.campaignViewColumns = [
          ...inReviewColumns,
          {
            header: '',
            width: '3rem',
            field: 'undo',
            type: 'undo'
          }
        ];
        TaxonomyHelper.handleSpendColumnVisibility(this.campaignViewColumns);
        console.warn('columns: ', this.campaignViewColumns);
        this.loading.next(false);
      })
      .catch(() => {
        this.loading.next(false);
      });
  }

  private updateBodyOverflowProperty(closeOverlay?: boolean) {
    if (this.showUnpublishedCampaigns && !closeOverlay) {
      this.renderer.addClass(document.body, 'overflow-hidden');
    } else {
      this.renderer.removeClass(document.body, 'overflow-hidden');
    }
  }

  removeAllFromGroup(dimensionValue: string) {
    this.removeUnpublishedCampaigns.emit({
      action: ReviewAction.removeWithFilters,
      payload: {
        groupByDimension: this.activeDimension,
        dimensionValue
      }
    });
  }

  removeUnpublishedRecord(campaign: IVCampaign) {
    this.removeUnpublishedCampaigns.emit({
      action: ReviewAction.removeWithFilters,
      payload: {
        groupByDimension: 'tempRecordId',
        dimensionValue: campaign.tempRecordId,
        campaignName: campaign.campaign
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logOpenAccordion($event: any) {
    console.warn('on open: ', $event);
  }
}
