export interface IHeroMetricCard {
  type: HeroMetricType;
  campaignCount: number;
  spend: number;
}

export enum HeroMetricType {
  UNMAPPED = 'unmapped',
  MAPPED = 'mapped',
  TOTAL_MAPPED = 'totalMapped'
}
