import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  PLATFORM_ID,
  Renderer2
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  AlertButtonType,
  AlertIconType,
  AlertItemProps,
  errorClassIconList,
  GroupByFieldConfig,
  IAlertPrimaryActionButtonProps,
  IGroupByFieldsConfigs,
  SeverityType,
  SeverityTypeClassMap
} from '@design-system/components/m-alert-item';
import { colors } from '@design-system/styles/colors';
import { FilterService, OverlayService, PrimeNGConfig } from 'primeng/api';
import { Table, TableService } from 'primeng/table';
import { TypePillSize } from '../m-type-pill/m-type-pill.component.types';

@Component({
  selector: 'm-row-group-table',
  templateUrl: './m-row-group-table.component.html',
  styleUrls: [
    '../m-table/m-table.component.scss',
    './m-row-group-table.component.scss'
  ]
})
export class MRowGroupTableComponent
  extends Table
  implements OnInit, OnChanges
{
  @Input() sortFields: string[] = [];
  @Input() selectedRowsData: Record<string, any>[] = [];
  @Input() groupByFieldsConfigs: IGroupByFieldsConfigs = {};
  @Input() fieldNametoGroupBy = '';
  @Input() enableRowSelectionCheckbox = true;
  @Input() enableTableHeaderCheckbox = true;
  @Input() tableDataLoading = true;
  @Input() searchInputHasData = false;
  @Output() handleAction = new EventEmitter<{
    actionButtonProps: IAlertPrimaryActionButtonProps;
    rowData: AlertItemProps;
  }>();

  @Output() selectedRows = new EventEmitter<AlertItemProps[]>();
  @Output() requestStackedDataForTable = new EventEmitter<AlertItemProps>();
  @Output() alertReadEvent = new EventEmitter<AlertItemProps>();
  @Output() resolveActionAlertEvent = new EventEmitter<AlertItemProps>();
  @Output() tableState = new EventEmitter<AlertItemProps[]>();
  override scrollHeight = '43.5rem';
  public tagSize = TypePillSize;
  protected readonly colors = colors;
  timeoutValue = 1;
  groupByFieldConfigLength = 0;

  constructor(
    @Inject(DOCUMENT) document: Document,
    @Inject(PLATFORM_ID) platformId: any,
    renderer: Renderer2,
    el: ElementRef,
    zone: NgZone,
    tableService: TableService,
    cd: ChangeDetectorRef,
    filterService: FilterService,
    overlayService: OverlayService,
    config: PrimeNGConfig,
    domSanitizer: DomSanitizer
  ) {
    super(
      document,
      platformId,
      renderer,
      el,
      zone,
      tableService,
      cd,
      filterService,
      overlayService,
      config,
      domSanitizer
    );
  }

  override ngOnInit(): void {
    this.groupByFieldConfigLength = Object.keys(
      this.groupByFieldsConfigs ?? {}
    ).length;
  }

  override ngOnChanges(): void {
    this.computeGroupByFieldsConfigs();
    this.groupByFieldConfigLength = Object.keys(
      this.groupByFieldsConfigs ?? {}
    ).length;
  }

  override toggleRow(rowData: AlertItemProps): void {
    if (rowData.isStacked) {
      this.requestStackedDataForTable.emit(rowData);
    }
    this.computeGroupByFieldsConfigs();

    if (!rowData.isRead && !rowData.expanded) {
      rowData.isRead = true;
      this.alertReadEvent.emit(rowData);
    }
    this.value = this.value.map((row) =>
      row.id === rowData.id ? this.updateRowState(row) : row
    );
  }

  onSelectionChange(event: Event) {
    event.stopPropagation();
    this.selectedRows.emit(this.selectedRowsData as AlertItemProps[]);
    this.tableState.emit(this.value as AlertItemProps[]);
  }

  private updateRowState(rowData: any): any {
    return {
      ...rowData,
      expanded: !rowData.isStacked ? !rowData.expanded : rowData.expanded
    };
  }

  private computeGroupByFieldsConfigs(): void {
    for (const key in this.groupByFieldsConfigs) {
      if (
        Object.prototype.hasOwnProperty.call(this.groupByFieldsConfigs, key)
      ) {
        const config = this.groupByFieldsConfigs[key] as GroupByFieldConfig;

        // Compute class
        config.computedClass =
          typeof config.class === 'function'
            ? config.class(this.value)
            : config.class;

        // Compute value
        config.computedValue =
          typeof config.value === 'function'
            ? config.value(this.value)
            : config.value;
      }
    }
  }

  getAlertIconClassName(alertIcon: AlertIconType) {
    return errorClassIconList.includes(alertIcon)
      ? 'error-icon'
      : `${alertIcon}-icon`;
  }

  handleActionClick(
    actionButtonProps: IAlertPrimaryActionButtonProps,
    rowData: AlertItemProps
  ): void {
    if (actionButtonProps.type === AlertButtonType.markAsResolved) {
      this.handleMarkAsResolved(rowData);
      return;
    }

    this.handleAction.emit({ actionButtonProps, rowData });
  }

  handleMarkAsResolved(rowData: AlertItemProps) {
    rowData.isVisible = false;
    rowData.isUndoView = true;
    this.timeoutValue = setTimeout(() => {
      rowData.isUndoView = false;
      this.resolveActionAlertEvent.emit(rowData);
    }, 5000) as unknown as number;
  }

  undoAction(rowData: AlertItemProps) {
    clearInterval(this.timeoutValue);
    rowData.isVisible = true;
    rowData.isUndoView = false;
  }

  getFirstLetterFromEmail(email: string): string {
    return email?.charAt(0).toUpperCase(); // Return the first letter
  }

  getSeverityClassName(severity: SeverityType) {
    return SeverityTypeClassMap[severity];
  }
}
