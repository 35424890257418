<p-carousel
  class="m-benchmarks-carousel"
  [styleClass]="styleClass"
  [value]="value"
  [numVisible]="numVisible"
  [numScroll]="numScroll"
  [circular]="circular"
  [responsiveOptions]="responsiveOptions"
  (onPage)="onPage.emit($event)">
  <ng-template let-item pTemplate="item">
    <div class="flex items-center">
      <div class="sh1 text-gray-900 mr-1">{{ item?.name }}</div>
      <m-type-pill size="tiny" text="{{ item?.badge }}"></m-type-pill>
    </div>
    <div class="c1 text-gray-800">
      {{ item?.title }} | {{ item?.description }}
    </div>

    <!-- Grid container with margin and grid -->
    <div class="grid gap-4 grid-cols-2 mt-3">
      <!-- First Column -->
      <div class="col-span-1">
        <m-card variant="small">
          <div class="c1 text-gray-800">My Metric</div>
          <div class="sh2 text-gray-1000">{{ item?.mySpend }}</div>
        </m-card>
      </div>

      <!-- Second Column -->
      <div class="col-span-1">
        <m-card variant="small">
          <div class="c1 text-gray-800">Benchmark</div>
          <div class="sh2 text-gray-1000">
            {{ item?.benchmark }}
          </div>
        </m-card>
      </div>
    </div>
    <!-- End Grid container -->
    <div class="sh1 text-gray-1000 my-3">Comparison</div>
    <m-chart-bar
      [label]="item?.label"
      [value]="item?.value"
      [valueTooltip]="item?.valueTooltip"
      [medianTooltip]="'<div class=&quot;b1 text-center&quot;>Benchmark</div>'"
      [tooltipPosition]="item?.tooltipPosition"
      [tooltipOptions]="item?.tooltipOptions">
    </m-chart-bar>

    <m-button
      (click)="onButtonClick()"
      type="secondary"
      styleClass="w-full my-3">
      <div class="b2 text-blue-600 w-full text-center">Explore Benchmarks</div>
    </m-button>
  </ng-template>
</p-carousel>
