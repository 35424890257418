<div>
  <p-accordion
    expandIcon="pi pi-chevron-right"
    collapseIcon="pi pi-chevron-down"
    (onOpen)="isAccordionClicked(true)"
    (onClose)="isAccordionClicked(false)"
    id="accordion">
    <p-accordionTab
      header="{{
        accordionData?.label ||
          accordionItems?.label ||
          accordionWithComponent?.label
      }}"
      class="{{
        accordionData?.styleClass ||
          accordionItems?.styleClass ||
          accordionWithComponent?.styleClass
      }}"
      selected="{{ accordionData?.isOpen || accordionWithComponent?.isOpen }}"
      id="accordionTab">
      <div *ngIf="accordionItems">
        <div
          class="{{ accordionItems.literalId }}"
          *ngFor="let item of accordionItems.elements"
          [ngSwitch]="item.layoutType">
          <div>
            <portal-native-sections-template
              [item]="item"
              [fieldDefinitions]="fieldDefinitions"
              [filters]="filters">
            </portal-native-sections-template>
          </div>
        </div>
      </div>
      <div id="accordion-data" *ngIf="accordionData?.data.trim().length > 0">
        {{ accordionData.data }}
      </div>
      <ng-template portalAccordion></ng-template>
    </p-accordionTab>
  </p-accordion>
</div>
