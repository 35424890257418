import { Injectable } from '@angular/core';
import {
  AssociatedFilterValue,
  DataEndpoint,
  DataResponse,
  Filter,
  FilterValue,
  FiltersToSend,
  MessageDialogText,
  ViewOperations,
  ViewResponse
} from '@portal/app/shared/types';
import { cloneDeep, pick } from 'lodash-es';
import { firstValueFrom } from 'rxjs';
import { ContextStore } from '@portal/app/shared/state/context.store';
import { ApiService } from '@portal/app/shared/services/api.service';
import { NativeSectionsService } from '@portal/app/shared/services/native-sections.service';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root'
})
export class ViewUtilityService {
  constructor(
    private readonly apiService: ApiService,
    private readonly contextStore: ContextStore,
    private readonly nativeSectionsService: NativeSectionsService
  ) {}

  assocFilterReducer(
    dimensions: string[],
    initialValue: AssociatedFilterValue[],
    assocFilters: AssociatedFilterValue[]
  ): AssociatedFilterValue[] {
    assocFilters?.forEach((assocFilter) => {
      let filterFound = false;
      initialValue.forEach((each) => {
        let dimensionsMatched = 0;
        dimensions.forEach((d) => {
          if (assocFilter[d] === each[d]) dimensionsMatched += 1;
        });
        if (dimensionsMatched === dimensions.length) {
          filterFound = true;
          Object.entries(assocFilter).forEach(
            ([k]) => (each[k] = assocFilter[k] as FilterValue)
          );
        }
      });
      if (!filterFound) {
        initialValue.push(assocFilter);
      }
    });
    return initialValue;
  }

  async getGlobalFiltersToSend(
    dashboardDefaultFilters: Filter[]
  ): Promise<FiltersToSend> {
    const global: FiltersToSend = {};
    const currFilters = await firstValueFrom(this.contextStore.filterContext);
    const def = this.apiService.generateFiltersToSend(
      cloneDeep(dashboardDefaultFilters),
      DataEndpoint.dataPoints
    );
    const changed = this.apiService.generateFiltersToSend(
      cloneDeep(currFilters),
      DataEndpoint.dataPoints
    );
    Object.entries(changed).forEach(([k]) => {
      if (k !== 'allocationSetting' && k !== 'dateStart' && k !== 'dateStop') {
        if (def[k] !== changed[k]) {
          global[k] = changed[k] as
            | string
            | number
            | boolean
            | string[]
            | number[];
        }
      }
    });

    // always send day and other required dates
    dashboardDefaultFilters
      .filter((f) => f.type === 'dateRange' && f.isRequired === 'YES')
      .forEach((filter) => {
        global[filter.literalId] = [
          changed.dateStart || def.dateStart,
          changed.dateStop || def.dateStop
        ] as string[];
      });

    // always send conversion_type, relative_day and planningBudget
    const alwaysSendFilters = [
      'conversion_type',
      'relative_day',
      'planningBudget'
    ];
    alwaysSendFilters.forEach((name) => {
      const filter = currFilters.find((f) => f.literalId === name);
      if (filter) {
        global[name] = filter.value as string;
      }
    });
    if ('childCampaign' in global) {
      delete global.childCampaign;
    }

    return global;
  }

  getChildGlobalFilters(filters: Filter[]): DataResponse {
    const filterToSend = this.apiService.generateFiltersToSend(
      cloneDeep(filters),
      DataEndpoint.dataPoints
    );
    const keys = Object.keys(filterToSend)?.filter((filter) =>
      filter.startsWith('child')
    );
    const allFilters = pick(filterToSend, keys);
    // if non campaign is selecte send campaign as all selected
    if (!('childCampaign' in allFilters)) {
      allFilters.childCampaign = [];
    }

    const childLastTimeSaved = dayjs()
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss.000[Z]');

    return {
      ...allFilters,
      childLastTimeSaved
    } as DataResponse;
  }

  getViewFiltersWithDefaults(f: Filter[], view: ViewResponse): Filter[] {
    const clonedFilter = cloneDeep(f);
    clonedFilter.forEach((filter) => {
      const globalValue = view?.filterOverrides?.global[filter.literalId];
      if (globalValue || globalValue === null) {
        filter.value = globalValue;
      }
    });
    return this.nativeSectionsService.setFilterDefaults(clonedFilter);
  }

  getMessageDialogData(
    key: ViewOperations,
    title: string
  ): MessageDialogText[] | undefined {
    let data;
    switch (key) {
      case ViewOperations.delete:
        data = [
          {
            text: 'Are you sure you want to delete'
          },
          {
            text: title,
            class: 'bold'
          },
          {
            text: 'from your list ?'
          }
        ];
        break;
      case ViewOperations.publish:
        data = [
          {
            text: 'Are you sure you want to publish'
          },
          {
            text: title,
            class: 'bold'
          },
          {
            text: '?'
          }
        ];
        break;
      case ViewOperations.unpublish:
        data = [
          {
            text: 'Are you sure you want to unpublish'
          },
          {
            text: title,
            class: 'bold'
          },
          {
            text: '?'
          }
        ];
        break;
      case ViewOperations.default:
        data = [
          {
            text: 'Are you sure you want to set the'
          },
          {
            text: title,
            class: 'bold'
          },
          {
            text: 'as the default plan?'
          }
        ];
        break;
    }
    return data;
  }

  getMessageDialogHeader(key: string): string | undefined {
    switch (key) {
      case ViewOperations.delete:
        return 'Confirm Deleting';
      case ViewOperations.publish:
        return 'Confirm Publish';
      case ViewOperations.unpublish:
        return 'Confirm Unpublish';
      case ViewOperations.default:
        return 'Confirm Default Plan';
      default:
        return undefined;
    }
  }
}
