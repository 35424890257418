import { ICrossPlatformDoeApiOperation } from '../models/ICrossPlatformDoeApiOperation';
import { Observable } from 'rxjs';
import { ICPAdStudyConfig } from '../models/geo-test/ICPAdStudyConfig';
import { HttpClient } from '@angular/common/http';
import { Utility } from '../helpers/utility';

export class CrossPlatformDoeApis implements ICrossPlatformDoeApiOperation {
  private constructor(
    private readonly httpClient: HttpClient,
    private readonly apiV1: string,
    private readonly apiV2: string
  ) {}

  public static crossPlatformDoeApiOperation(
    httpClient: HttpClient,
    apiV1: string,
    apiV2: string
  ): ICrossPlatformDoeApiOperation {
    return new CrossPlatformDoeApis(httpClient, apiV1, apiV2);
  }

  public getGeoExperiments(
    clientId: number,
    brandId: number,
    status: string
  ): Observable<ICPAdStudyConfig[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/cross-platform-doe`,
      queryParameters: { clientId, brandId, status }
    });
    return this.httpClient.get<ICPAdStudyConfig[]>(url);
  }
}
