import { EventEmitter, Injectable, signal, computed } from '@angular/core';
import {
  Brand,
  BrandRow,
  Client,
  JustBrand,
  SelectionSlug
} from '@portal/app/shared/types';
import { slugify } from '@portal/app/shared/helpers/helpers';
import { StorageService } from './storage.service';
import { split } from 'lodash-es';
import { Observable, throwError } from 'rxjs';
import { ClientStoreService } from '../../dashboard/geo-doe-config/data-stores/client-store.service';
import { CurrencyService } from '@portal/app/shared/services/currency.service';
import { CurrencySymbol } from '@portal/app/shared/types/currency-format';

@Injectable({
  providedIn: 'root'
})
export class SelectionService {
  // New reactive signal for the current selection.
  private _selection = signal<JustBrand>({ brand: null });
  private selection: JustBrand = { brand: null };

  public selectionLabel = '';
  public selectionChanged: EventEmitter<JustBrand> =
    new EventEmitter<JustBrand>();

  constructor(
    private readonly store: StorageService,
    private currencyService: CurrencyService,
    private clientStoreService: ClientStoreService
  ) {
    this.setSelectionLabel();
  }

  // Expose the reactive selection as a computed signal.
  clientBrandSignal = computed(() => {
    const brand = this._selection().brand;
    return {
      clientId: brand?.clientId || 0,
      brandId: brand?.brandId || 0
    };
  });

  clearSelection(): void {
    this.clientStoreService.client = undefined;
    this.selection = { brand: null };
    this._selection.set({ brand: null });
  }

  setUpBrandCurrency(brand: Brand) {
    const currencyCode = brand.currencyCode ? brand.currencyCode : 'USD';
    const currencyDisplay: CurrencySymbol =
      this.currencyService.checkCurrencySymbol(brand);
    const currencyLocale = brand?.currencyLocale
      ? brand.currencyLocale
      : 'en-US';
    this.currencyService.updateCurrencyData({
      code: currencyCode,
      locale: currencyLocale,
      display: currencyDisplay
    });
  }

  setSelection(client: Client, brand: Brand): void {
    this.setUpBrandCurrency(brand);
    this.clientStoreService.client = client;
    this.selection = { brand };
    this._selection.set({ brand });
    this.setSelectionLabel();
    // Emit an event for backward compatibility.
    if (client != null && brand != null) {
      this.selectionChanged.emit({ brand });
    }
  }

  getSelection(): JustBrand {
    return this.selection;
  }

  hasSelection(): boolean {
    return this.selection != null && this.selection.brand != null;
  }

  parseSelectionLabel(item: JustBrand | BrandRow = this.selection): string {
    if (item != null) {
      const { brand } = item;
      if (brand != null) {
        return brand?.brandName ? brand.brandName : '';
      }
    }
    return '';
  }

  buildSelectionSlug(item: JustBrand | BrandRow = this.selection): string {
    if (item != null) {
      const { brand } = item;
      const brandLabel = this.parseSelectionLabel(item);
      if (brand != null) {
        return `${brand.clientId}-${brand.brandId}-${slugify(brandLabel)}`;
      }
    }
    return '';
  }

  // Reverse of buildSelectionSlug.
  parseSelectionSlug(slug = ''): Observable<SelectionSlug> {
    const splitCharacter = '-';
    const splitItems = split(slug, splitCharacter);
    if (splitItems.length >= 3) {
      const clientId = Number(splitItems.shift());
      const brandId = Number(splitItems.shift());
      const brandLabel = splitItems.join(splitCharacter);
      return new Observable<SelectionSlug>((subscriber) => {
        subscriber.next({ brandId, clientId, brandLabel });
        subscriber.complete();
      });
    }
    return throwError(() => new Error('Brand Selection format is invalid'));
  }

  private setSelectionLabel(): void {
    this.selectionLabel = this.parseSelectionLabel();
  }

  getClientIdAndBrandId(): { clientId: number; brandId: number } {
    const brand = this.getSelection().brand;
    const clientId = brand?.clientId || 0;
    const brandId = brand?.brandId || 0;
    return { clientId, brandId };
  }
}
