import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormatterService } from '@portal/app/shared/services/formatter.service';
import {
  DataGridBarVisualizer,
  DataGridControls,
  DataGridEventDriven,
  DataGridExecutableFilterEvent,
  DataGridExecutableFilterEvents,
  DataGridGrouped,
  DataGridPivotColumn,
  DataGridSortedColumn,
  DataGridSortMode,
  ICountByFields,
  PrimeNgSortEvent,
  PrimeNgSortOrder
} from '@portal/app/shared/types/native/datagrid';
import {
  ContextField,
  DataEndpoint,
  DataGridAvailable,
  DataGridConfig,
  DataGridEditable,
  DataGridElement,
  DataGridExportConfig,
  DataGridSelected,
  DataResponse,
  DataRowFilter,
  DataSetResponse,
  ElementSubType,
  ElementSubTyping,
  ElementTyping,
  FieldDefinition,
  FieldDefinitions,
  Filter,
  FilterEvent,
  FilterField,
  FilterValue,
  IDataSetCountResponse,
  IFilterChange,
  LayoutTypes,
  TableResponse,
  WidgetType,
  ExportTableType,
  DataRowData
} from '@portal/app/shared/types';
import {
  ApiService,
  ExportObjectParamValue
} from '@portal/app/shared/services/api.service';
import { ViewService } from '@portal/app/shared/services/view.service';
import { ContextStore } from '@portal/app/shared/state/context.store';
import { FilterMetadata, FilterService, MenuItem, SortMeta } from 'primeng/api';
import {
  capitalize,
  cloneDeep,
  compact,
  debounce,
  find,
  flatten,
  forEach,
  isEqual,
  keys,
  map,
  some,
  sum,
  toLower,
  uniq,
  values
} from 'lodash-es';
import { Observable, Subscription, take } from 'rxjs';
import { ExportTableService } from '@portal/app/shared/services/export-table.service';
import { Table } from 'primeng/table';
import { InputNumber } from 'primeng/inputnumber';
import { DataGridStore } from '@portal/app/datagrid/store/data-grid.store';
import {
  combineFilters,
  createFilterFields,
  getFiltersFromFilterFields,
  isMeasuredBenchmarkLandingPage,
  sortByDisplayOrder,
  updateFilterOptions
} from '@portal/app/dashboard/utils';
import { DataGridEventDrivenColumns } from '@portal/app/shared/types/native/layout';
//services
import { Required } from '@portal/app/shared/decorators/required.decorator';
import { NativeSectionsService } from '@portal/app/shared/services/native-sections.service';
import { DateTimeService } from '@portal/app/shared/services/date-time.service';
import { ViewStore } from '@portal/app/shared/state/view.store';
import { DataGridService } from '@portal/app/datagrid/services/data-grid.service';
import { DatagridBaseComponent } from '@portal/app/datagrid/base-data-grid/data-grid-base.component';
import { PreviousPeriodService } from '@portal/app/shared/services/previous-period.service';
import { CompareService } from '@portal/app/shared/services/compare.service';
import { FiltersService } from '@portal/app/shared/services/filters.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionService } from '@portal/app/shared/services/selection.service';
import { IComponentTabChange } from '@portal/app/shared/components/component-tab/component-tab.component';
import { DashboardActions } from '@portal/app/store/dashboard/actions';
import { Store } from '@ngrx/store';
import { AppState } from '@portal/app/store/app.state';
import { MultiViewStore } from '@portal/app/shared/state/multi-view.store';
import { IDimensionNavigationConfig } from '@portal/app/datagrid/data-grid-dimension-navigator/data-grid-dimension-navigator.component';
import { COMPOSITE_COLUMN_EQUAL } from '@portal/app/shared/constants/constants';

import { CompareBannerService } from '@portal/app/shared/components/compare-mode-banner/compare-mode-banner.service';
import { LinkService as ContextModalLinkService } from '@portal/app/dashboard/context-modal/services/link.service';
import { FieldTooltipsText } from '@portal/app/shared/constants';

@Component({
  selector: 'portal-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss']
})
export class DataGridComponent
  extends DatagridBaseComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  @ViewChild('dt') dt!: Table;
  @ViewChild('filterInput') filterInput!: InputNumber;
  @Input() @Required element!: DataGridElement;
  @Input() @Required fieldDefinitions!: FieldDefinitions;
  @Input() elements: DataGridElement[] = [];
  @Input() name = '';
  @Input() title = '';
  @Input() filterControls: ContextField[] = [];
  @Input() filterable = false;
  @Input() dimensionPickerEnabled = false;
  @Input() metricPickerEnabled = false;
  @Input() filterSet: Filter[] = [];
  @Input() data: DataSetResponse[] = [];
  @Input() groupByRowFooter: Record<string, DataResponse> = {};
  @Input() literalId = '';
  @Input() persistedFilters: Filter[] = [];
  @Input() dataGridConfig: DataGridConfig =
    this.dataGridStore.getDataGridTableConfig();

  @Input() showChildSection: boolean | null = null;

  @Output() persistingFilters = new EventEmitter<Filter>();
  @Output() loaded = new EventEmitter<boolean>();

  dataGridName!: string;
  dimensionFormat = '';
  allSelected = false;
  showFooterRow = true;

  private readonly portfolioLevelLiteralId = 'portfolio-level';
  private readonly exportIconClassNames = 'pi pi-fw pi-file';
  private readonly resizeDebounceTime = 300;

  public exportTableMenu: MenuItem[] = [
    {
      label: 'CSV',
      icon: this.exportIconClassNames,
      styleClass: 'export-item csv',
      command: () => {
        this.exportWithFormat(
          'csv',
          { tableName: this.element.literalId },
          this.dt
        );
      }
    },
    {
      label: 'TSV',
      icon: this.exportIconClassNames,
      styleClass: 'export-item tsv',
      command: () => {
        this.exportWithFormat(
          'tsv',
          { tableName: this.element.literalId },
          this.dt
        );
      }
    },
    {
      label: 'Excel',
      icon: this.exportIconClassNames,
      styleClass: 'export-item excel',
      command: () => {
        this.exportWithFormat(
          'xlsx',
          { tableName: this.element.literalId },
          this.dt
        );
      }
    }
  ];

  public table: TableResponse = {
    fields: [],
    exportFields: [],
    editableFields: [],
    data: [],
    footer: [],
    groupByRowFooter: {}
  };

  public currentMetricValues: string[] = [];
  public metricsOptions: { label: string; value: string; disabled: boolean }[] =
    [];

  // Options for Dimensions
  public dimensions: Filter | undefined = undefined;
  public currentDimensionValues: string[] = [];
  public dimensionsOption: { label: string | null; value: string }[] = [];
  // END options for Dimensions
  public filterFields: ContextField[] = [];
  public loading = true;
  public countDataLoading = true;
  public wasFiltered = false;
  public showEmptyState = false;
  public productId = '';
  public isDesktopScreen = true;
  public groupByFieldName: string | undefined = undefined;
  private tableData: DataSetResponse[] = [];
  private dataPoints: DataResponse | undefined;
  private dataGridApiFilters: Filter[] = [];
  private previousDimensionValues: string[] = [];
  public clientId = 0;
  private previousDataGridFilters: Filter[] = [];
  private readonly subscriptions: Subscription = new Subscription();
  private readonly debouncedResize = debounce(
    () => this.checkScreenWidth(),
    this.resizeDebounceTime
  );

  public filters: Filter[] = [];
  public defaultFilterEventsExecuted = false;
  public filterEvents: DataGridExecutableFilterEvents = {};
  public filtersWithEventsIds: string[] = [];
  public eventDrivenColumns: DataGridEventDrivenColumns = {};
  public filtersToPersist: Filter[] = [];
  public persistentFilterIds: string[] = [];
  private viewId?: number;
  public widthSpecificToFields: Record<string, number> = {};
  public widthSpecificToFieldsUnit = DataGridStore.widthSpecificToFieldsUnit;

  private readonly filterContext$: Observable<Filter[]> =
    this.contextStore.filterContext;

  private readonly gridFilters$: Observable<Filter[]> =
    this.filtersService.dataGridFilter;

  private readonly chartFilter$: Observable<Filter[]> =
    this.filtersService.dataChartFilter;

  private readonly onFilterChange$: Observable<IFilterChange> =
    this.filtersService.onfilterChanged;

  private persistedSortField = '';
  private persistedSortOrder = 1;
  private persistedMultiSort: SortMeta[] = [];
  private dataGridStoreElement?: DataGridElement;

  public dimensionNavigationElements: DataGridControls[] = [];
  public dimensionNavigationConfig: IDimensionNavigationConfig[] = [];
  public countByFields: ICountByFields | null = null;
  public callDataCountAPIAfterError = false;
  public isDimensionNavigationChanged = false;
  public dimensionNavigatorLevelDataSetFilters: Record<
    string,
    Record<string, FilterMetadata | FilterMetadata[]>
  > = {};

  public matchModeOptions = [
    { label: 'contains', value: COMPOSITE_COLUMN_EQUAL }
  ];

  public customFilterDataCount = -1;
  public previousFilter: string | null = null;
  public isMeasuredBenchmarkLandingPage = isMeasuredBenchmarkLandingPage;
  public elementType = ElementTyping;
  public layoutTypes = LayoutTypes;
  public showChild = this.multiViewStore.showChild;

  constructor(
    public readonly nativeSectionsService: NativeSectionsService,
    public readonly dataGridService: DataGridService,
    public readonly formatterService: FormatterService,
    protected readonly apiService: ApiService,
    protected readonly contextStore: ContextStore,
    protected readonly exportTableService: ExportTableService,
    protected readonly dataGridStore: DataGridStore,
    protected readonly viewService: ViewService,
    protected readonly dateService: DateTimeService,
    protected readonly viewStore: ViewStore,
    private readonly filtersService: FiltersService,
    protected readonly compareService: CompareService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly selectionService: SelectionService,
    private readonly store: Store<AppState>,
    private readonly multiViewStore: MultiViewStore,
    private readonly filterService: FilterService,
    public readonly compareBannerService: CompareBannerService,
    public readonly contextModalLinkService: ContextModalLinkService
  ) {
    super(
      nativeSectionsService,
      dataGridService,
      apiService,
      contextStore,
      formatterService,
      exportTableService,
      dataGridStore,
      viewService,
      dateService,
      viewStore
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.data &&
      changes.groupByRowFooter &&
      !changes.data.firstChange &&
      !changes.groupByRowFooter.firstChange
    ) {
      this.data = changes.data.currentValue as unknown as DataSetResponse[];
      this.groupByRowFooter = changes.groupByRowFooter
        .currentValue as unknown as Record<string, DataResponse>;
      this.loadData();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.debouncedResize();
  }

  ngOnInit(): void {
    this.setupVirtualScrollItemSizeBasedOnTableHeight();
    this.compositeColumnFilter();
    this.checkScreenWidth();
    this.viewStore.selectedBCPDId.pipe(take(1)).subscribe((value) => {
      this.clientId = value.clientId;
      this.productId = value.productId;
      this.literalId = value.literalId;

      this.dataGridService.initSelection(this.literalId);
    });

    this.store.select('dashboard').subscribe((dashboardState) => {
      const dataSetLayout = dashboardState.layout.find(
        (layout) => layout.layoutType === this.element.layoutType
      );

      this.dataGridStoreElement = dataSetLayout?.elements.filter((element) => {
        return (
          this.getDashboardLayoutLiteralId(element as DataGridElement) ===
          this.getDashboardLayoutLiteralId(this.element)
        );
      })[0] as DataGridElement;
    });

    this.multiViewStore.currentView$.subscribe((currentView) => {
      this.viewId = currentView ? Number(currentView.viewId) : undefined;
    });

    // PLEASE ADD CUSTOME DATAGRID  CONFIG HERE
    this.initializeCustomDataGridConfig();
    this.prepareConfigForDimensionNavigator(true);

    if (this.element && this.element.grouped) {
      this.element.selected.push(
        ...(this.element?.grouped as unknown as DataGridSelected[])
      );
    }

    // check if metric picker is enabled
    if (this.element.CONTROL?.metricControl != null) {
      this.metricPickerEnabled = this.element.CONTROL?.metricControl.length > 0;
    }

    if (this.element) {
      this.removeChildColumns();
      this.table.fields = this.element.selected || [];
      if (this.element.editable && this.element.editable.length > 0) {
        this.table.fields = this.table.fields.concat(this.element.editable);
      }
      this.groupByFieldName = this.element.groupedBy
        ? this.element.groupedBy[0]?.dashboardFieldId
        : undefined;
    }

    this.buildSortPersistence();

    // initialize and verify values for filterEvents
    this.filterEventsInitialization();

    // send necessary info to parent for processing
    super.literalId = this.literalId;
    super.clientId = this.clientId;
    super.productId = this.productId;

    // collect a list of ids of filters that are supposed to be persisted in datagrid group
    this.persistentFilterIds = super.generatePersistentFilterIds(this.element);

    this.loadData();
    if (this.metricPickerEnabled) {
      this.setupMetrics();
    }
    this.route.queryParams.subscribe((params) => {
      const tabKey = `${this.literalId}_datagridTabIndex`;
      if (params[tabKey]) {
        this.dataGridService.activeTabIndex[this.literalId] = Number(
          params[tabKey]
        );
        if (this.element.COMPONENT_TAB && params[tabKey] === '1') {
          this.dataGridService.allowSelection[this.literalId] = true;
        }
      }
    });
    // set breakdown name on first load
    this.dataGridName = this.element.literalId;
  }

  ngAfterViewInit(): void {
    this.applySortPersistence();
  }

  get isMultiSort(): boolean {
    return this.dataGridConfig.dashboardSortMode === DataGridSortMode.multiple;
  }

  get isMultilineHeader(): boolean {
    return some(
      this.table.fields,
      (item) =>
        (
          this.fieldDefinitions[item.dashboardFieldId] as FieldDefinition
        ).label.split(' ').length > 1
    );
  }

  buildSortPersistence(): void {
    if (this.element?.SORTED_COLUMN) {
      const sortItems = [
        ...compact(this.element.SORTED_COLUMN.ascending),
        ...compact(this.element.SORTED_COLUMN.descending)
      ];

      if (this.isMultiSort) {
        sortItems.sort(sortByDisplayOrder);
        this.persistedMultiSort = sortItems.map((item) => ({
          field: item.dashboardFieldId,
          order: PrimeNgSortOrder[item.subType]
        }));
      } else {
        if (sortItems && sortItems[0]) {
          this.persistedSortField = sortItems[0].dashboardFieldId;
          this.persistedSortOrder = PrimeNgSortOrder[sortItems[0].subType];
        }
      }
    }
  }

  applySortPersistence() {
    if (this.isMultiSort) {
      this.dt.multiSortMeta = this.persistedMultiSort;
    } else {
      this.dt.sortField = this.persistedSortField;
      this.dt.sortOrder = this.persistedSortOrder;
    }
  }

  // a space to initialize custom datagridconfig
  initializeCustomDataGridConfig() {
    if (this.literalId === this.portfolioLevelLiteralId) {
      this.dataGridConfig.scrollable = false;
      this.dataGridConfig.showFooter = false;
      this.dataGridConfig.scrollDirection = '';
      this.dataGridConfig.isResizable = false;
      // TODO: remove ddi-internal after testing is done
    } else if (this.productId === 'ddi' || this.productId === 'ddi-internal') {
      this.dataGridConfig.showFooter = false;
      this.dataGridConfig.exportShowFooter = false;
      this.dimensionFormat = map(
        this.element.DIMENSION.selected,
        'dashboardFieldId'
      ).join('|');
      this.store.dispatch(
        DashboardActions.setDimensionFormat({
          dimensionFormat: this.dimensionFormat
        })
      );
    } else if (this.isMeasuredBenchmarkLandingPage(this.literalId)) {
      this.dataGridConfig.showFooter = false;
      this.dataGridConfig.exportShowFooter = false;
      this.dataGridConfig.tableScrollHeightClass = 'short';
    }
  }

  // initialize and verify values for filterEvents
  filterEventsInitialization() {
    // Collection and cleanup of filterevents from config
    this.filterEvents = super.collectSanitizeAndValidateFilterEventsConfig(
      this.element.elements,
      this.fieldDefinitions
    );
    // list of ids of filters that have events attached to them
    this.filtersWithEventsIds = Object.keys(this.filterEvents);
    // Collection of event driven columns
    if (this.element.EVENT_DRIVEN) {
      this.eventDrivenColumns =
        super.collectSanitizeAndValidateEventDrivenColumns(
          this.element.EVENT_DRIVEN as DataGridEventDrivenColumns,
          this.fieldDefinitions
        );
    }
  }

  // replace a column with targetColumnId with replaceColumnId
  replaceColumn(targetColumnId: string, replacementColumnId: string) {
    const targetColumn:
      | DataGridSelected
      | DataGridEditable
      | DataGridAvailable
      | DataGridGrouped
      | DataGridPivotColumn
      | DataGridEventDriven = this.table.fields.find(
      (field) => field.dashboardFieldId === targetColumnId
    ) as
      | DataGridSelected
      | DataGridEditable
      | DataGridAvailable
      | DataGridGrouped
      | DataGridPivotColumn
      | DataGridEventDriven;
    targetColumn.dashboardFieldId = replacementColumnId;
  }

  checkScreenWidth() {
    const minDesktopWidth = 768;
    this.isDesktopScreen = window.innerWidth >= minDesktopWidth;
  }

  // if any persisted filters are discovered, apply their previously persisted value to current filters
  applyPersistedFilters(
    persistedFilters: Filter[],
    regularFilters: Filter[]
  ): Filter[] {
    if (persistedFilters.length) {
      const defaultFilters = regularFilters;
      forEach(persistedFilters, (persistedFilter) => {
        const filterFound = defaultFilters.find(
          (filter) => filter.literalId === persistedFilter.literalId
        );
        if (
          filterFound &&
          this.persistentFilterIds.includes(filterFound.literalId)
        ) {
          filterFound.value = persistedFilter.value;
        }
      });
      return defaultFilters;
    } else {
      // incase of no filters discoreved just return normal filter values
      return regularFilters;
    }
  }

  loadData(): void {
    let dataGridFilterSubscription: Subscription;
    const contextStoreSubscription = this.filterContext$.subscribe({
      next: (filterSet) => {
        const filters = cloneDeep(filterSet);
        this.filters = this.applyPersistedFilters(
          this.persistedFilters,
          filters
        );
        super.filters = this.filters;
        this.filterFields = createFilterFields(this.element, this.filters);
        if (dataGridFilterSubscription) {
          dataGridFilterSubscription.unsubscribe();
        }
        const filterChangeSub = this.onFilterChange$.subscribe((f) => {
          if (
            f.filterType === WidgetType.context ||
            f.filterType === WidgetType.table
          ) {
            this.updateFilters(f.filters as Filter[]);
          }
        });
        this.subscriptions.add(filterChangeSub);
        this.filtersToPersist = super.generateFiltersToPersist(
          getFiltersFromFilterFields(
            this.filterControls,
            this.filters,
            'DATA_SET'
          ),
          this.persistentFilterIds
        );
        this.setFilterValues(true);
        this.setupDimensions();
        dataGridFilterSubscription = this.gridFilters$.subscribe({
          next: (dataGridFilters) => {
            // send additional filters and then view will check if value changed and update user settings
            dataGridFilters.forEach((filter) => {
              this.store.dispatch(DashboardActions.setFilter({ filter }));
            });
            this.dataGridApiFilters = dataGridFilters;
            if (
              dataGridFilters.length > 0 &&
              this.isDataGridFilterValueUpdated(dataGridFilters)
            ) {
              this.callApi(dataGridFilters);
              this.previousDataGridFilters = dataGridFilters;
            } else {
              this.callApi(dataGridFilters);
            }

            this.table.fields.sort(sortByDisplayOrder);
            this.dataGridService.setGroupedColumns(this.table.fields);
          }
        });
        this.subscriptions.add(dataGridFilterSubscription);
      }
    });

    const associatedFilterContextStoreSubscription =
      this.contextStore.associatedFilterContext.subscribe({
        next: () => {
          this.callApi([]);
        }
      });
    const dataGridContext = this.dataGridService.context.subscribe(
      (config: DataGridExportConfig) => {
        const format = config.format ? config.format : 'csv';
        if (config.literalId === this.portfolioLevelLiteralId) {
          const updatedConfig = this.getCustomizedMediaPlanConfig(config);
          this.exportWithFormat(format, updatedConfig, this.dt);
        } else if (config.tableName === this.name) {
          this.exportWithFormat(format, config, this.dt);
        }
      }
    );
    this.subscriptions.add(dataGridContext);
    this.subscriptions.add(associatedFilterContextStoreSubscription);
    this.subscriptions.add(contextStoreSubscription);
  }

  callApi(dataGridApiFilters: Filter[] = this.dataGridApiFilters) {
    this.getDatasetData(
      super.generateApiFilters(dataGridApiFilters),
      DataEndpoint.dataSets,
      this.currentDimensionValues
    );
  }

  getDatasetData(
    filters: Filter[],
    type: DataEndpoint,
    dimensionValues: string[] = []
  ) {
    this.loading = true;
    this.showEmptyState = false;
    if (this.data.length) {
      // Warning: Do not remove the clonedeep, without it, it's impacting the data
      this.tableData = cloneDeep(this.data);
      this.setupDataGrid(true);
      return;
    }
    const callAPIFor =
      this.dimensionNavigationElements.length &&
      (!this.isDimensionNavigationChanged || this.callDataCountAPIAfterError)
        ? ['DATA', 'COUNT']
        : ['DATA'];
    this.countDataLoading = callAPIFor.includes('COUNT');
    callAPIFor.forEach((apiCallType) => {
      this.apiService
        .getDashboardData({
          filters,
          type,
          dimensionValues,
          dimensionFormat: this.dimensionFormat,
          viewId: this.viewId,
          getCount: apiCallType === 'COUNT',
          dataSetLiteralIds: [this.element.literalId]
        })
        .then((response) => {
          // Saving dataset response into this.tableData to be later used to format it into table.data,
          //  table.fields & table.footer. In case of a context filter change, dimensions should reset
          if (apiCallType === 'DATA') {
            this.tableData = response as DataSetResponse[];
            this.setupDataGrid(true);
          } else if (apiCallType === 'COUNT') {
            this.callDataCountAPIAfterError = false;
            const data = response as unknown as IDataSetCountResponse[];
            this.countByFields = data[0]?.countByFields || {};
            this.countDataLoading = false;
            this.prepareConfigForDimensionNavigator();

            // This emits spend count to be used to display compare banner
            this.compareBannerService.setSpendCountbyTactic(
              this.countByFields?.tacticWithMediaSpendZero?.count || 0
            );
          }
          this.isDimensionNavigationChanged =
            !this.isDimensionNavigationChanged;
        })
        .catch((error) => {
          if (error && error.error) {
            this.callDataCountAPIAfterError = true;
            this.table.data = [];
            this.countByFields = {};
            this.prepareConfigForDimensionNavigator();
            this.showEmptyState = true;
            this.loading = false;
            this.countDataLoading = false;
          }
        });
    });
  }

  updateOnRowDataChange(newRow: DataSetResponse) {
    const newRowData: DataResponse = newRow.data[0] as DataResponse;
    // filter out all fieldDimensions
    const dashboardFieldIds: string[] = [];
    this.table.fields.forEach((field) => {
      if (field.type === 'DIMENSION') {
        dashboardFieldIds.push(field.dashboardFieldId);
      }
    });
    const allRows: DataRowFilter[] = [];
    // create object with all dimension field names and values for all rows from the table
    this.tableData[0]?.data.forEach((row) => {
      const rowLevelFilter: DataRowFilter = {};
      forEach(dashboardFieldIds, (dashboardFieldId) => {
        rowLevelFilter[String(dashboardFieldId)] = [
          row[dashboardFieldId] as DataRowData
        ];
      });
      allRows.push(rowLevelFilter);
    });
    const filterOverride: Record<string, string> = {
      filterLiteralId: 'inc_data_source',
      channel: newRowData.channel as string,
      tactic: newRowData.tactic as string,
      segment: newRowData.segment as string,
      dimensionFormat: this.dimensionFormat,
      value: newRowData.inc_data_source as string
    };
    this.apiService
      .createDataSetOverrideRow(filterOverride)
      .pipe(take(1))
      .subscribe({
        error: (err) => console.error(err)
      });
    // send data to another component(s)
    this.contextStore
      .emitComponentChangeAsFilter(allRows)
      .pipe(take(1))
      .subscribe({
        error: (err) => console.error(err)
      });

    this.table.data = super.replaceRow(
      newRowData,
      this.table,
      this.fieldDefinitions
    );
    this.table.footer = this.generateFooter(
      this.table.data,
      this.dataGridConfig.scrollable,
      this.fieldDefinitions,
      this.dataPoints
    );
    this.dataGridService.replaceSelection(this.literalId, newRowData);
  }

  //convert table data into table.data table.fields and table.footer
  // TODO: split format table into different functions for formatting data, fields and footer
  // TODO: add an event/observable to table.data so that everytime it gets updated the total row(footer) is recalculated automatically based on new data values
  formatTable(initialFormat = false): void {
    const tableRows = this.tableData.filter((i) => i.name === this.name); // find this table from the api response
    // this code should be executed only on initialization
    // TABLE.DATA ---
    if (tableRows != null && initialFormat) {
      super.getFormattedData(tableRows, this.fieldDefinitions);
    }

    // Warning: Do not remove the clonedeep, without it, it's impacting the data
    // TABLE.GROUPBYROWFOOTER ---
    this.table.groupByRowFooter = cloneDeep(this.groupByRowFooter) as Record<
      string,
      DataResponse
    >;
    if (this.table.groupByRowFooter) {
      for (const key of keys(this.table.groupByRowFooter)) {
        if (this.table.groupByRowFooter[key]) {
          this.table.groupByRowFooter[key] =
            this.formatterService.formatDataPartially(this.fieldDefinitions, [
              this.table.groupByRowFooter[key] as DataResponse
            ])[0] as DataResponse;
        }
      }
    }

    // TABLE.FIELDS ---
    const columns = super.generateFields(
      this.element,
      this.metricPickerEnabled,
      this.dimensions !== undefined,
      this.currentMetricValues,
      this.getDimensionValues(),
      this.eventDrivenColumns
    );
    //format table data using derived fields
    this.table.fields = columns.length
      ? (columns.sort(sortByDisplayOrder) as DataGridSelected[])
      : (this.table.fields.sort(sortByDisplayOrder) as DataGridSelected[]);
    this.calculateDynamicWidth();

    this.dataPoints =
      tableRows && tableRows.length ? tableRows[0]?.dataPoints : undefined;

    // filter events to be executed for default values
    if (initialFormat && !this.defaultFilterEventsExecuted) {
      this.executeFilterEventsWithDefaultValues();
    }

    // TABLE.FOOTER ---
    if (this.dt && this.dt.filteredValue) {
      this.table.footer = this.generateFooter(
        this.dt.filteredValue,
        this.dataGridConfig.scrollable,
        this.fieldDefinitions,
        this.dataPoints
      );
    } else if (this.table.data) {
      this.table.footer = this.generateFooter(
        this.table.data,
        this.dataGridConfig.scrollable,
        this.fieldDefinitions,
        this.dataPoints
      );
    }

    // TABLE.EDITABLEFIELDS ---
    this.table.editableFields = super.prepareEditableFields(
      this.table,
      this.filters
    );

    this.loaded.emit(true);
    this.loading = false;
    this.tableRefresh();
  }

  executeFilterEventsWithDefaultValues() {
    forEach(this.filters, (f) => {
      if (this.filtersWithEventsIds.includes(f.literalId)) {
        this.filterEventsExecution(f.literalId, f.value as string);
      }
    });
    this.defaultFilterEventsExecuted = true;
  }

  toggleFreezing() {
    this.dataGridConfig.frozenColumnsEnabled =
      !this.dataGridConfig.frozenColumnsEnabled;
  }

  tableRefresh() {
    setTimeout(() => {
      this.toggleFreezing();
    }, 0);
    this.toggleFreezing();
  }

  // ---DIMENSIONS---
  setupDimensions() {
    // LOOK FOR DIMENSIONS
    this.setDimensionsFromFilter();
    if (
      this.dimensionNavigationElements &&
      this.dimensionNavigationElements.length
    ) {
      const tableDimensionNavigationFilter = this.filters.find(
        (f) => f.literalId === 'tableDimensionNavigation'
      );
      if (this.dimensions && tableDimensionNavigationFilter) {
        this.dimensions.value = this.filters.find(
          (f) => f.literalId === tableDimensionNavigationFilter.value
        )?.value as FilterValue;
      }
    }
    // save value
    if (this.dimensions != null) {
      [this.dimensionsOption, this.currentDimensionValues] =
        this.generateDimensionsOptionsAndValues(
          this.element,
          this.fieldDefinitions
        );
      if ((this.dimensions.value as string[]).length === 0) {
        this.currentDimensionValues = cloneDeep(
          this.dimensions.options as string[]
        ).map((opt) => toLower(opt));
        return;
      }
      this.currentDimensionValues = (this.dimensions.value as string[]).map(
        (dim) => dim.toLowerCase()
      );
      this.previousDimensionValues = this.currentDimensionValues;
    }
  }

  setDimensionMetricFilterParams(literalId: string) {
    const filterExist = this.filters.find((f) => f.literalId === literalId);
    if (filterExist) {
      if (literalId === 'dimensions') {
        this.dataGridStore.dimensionControlValue = filterExist;
      }
    }
    const queryFilters = super
      .generateApiFilters(this.dataGridApiFilters)
      .map((filter) => {
        if (filter.literalId === 'dimensions') {
          filter.value = this.currentDimensionValues.map((dim) =>
            capitalize(dim)
          );
        }
        return filter;
      });
    if (literalId === 'dataGridMetrics') {
      const metricFilter = {
        literalId: 'dataGridMetrics',
        value: this.currentMetricValues
      } as Filter;
      this.dataGridStore.metricControlValue = metricFilter;
      queryFilters.push(metricFilter);
    }
    this.apiService.modifyParams(queryFilters);
  }

  onDimensionsPanelClose() {
    //in case of user deselecting all values add values back again
    if (this.currentDimensionValues.length <= 0) {
      this.currentDimensionValues = this.dimensions?.options?.map((el) =>
        el.toLowerCase()
      ) as string[];
    }
    //detect whether a change happened in selected values by comparing previous values with current ones
    if (!isEqual(this.previousDimensionValues, this.currentDimensionValues)) {
      const dimensionFilter = this.filters.find(
        (f) => f.literalId === 'dimensions'
      );
      if (dimensionFilter) {
        dimensionFilter.value = isEqual(
          dimensionFilter.options,
          this.currentDimensionValues
        )
          ? []
          : this.currentDimensionValues;
        this.updateFiltersValue([
          {
            filterLiteralId: 'dimensions',
            filterValue: dimensionFilter.value
          }
        ]);
      }
      this.setFilterValues();
      this.previousDimensionValues = this.currentDimensionValues; // since values were changed the current value will become previous value
      this.setDimensionMetricFilterParams('dimensions');
      this.store.dispatch(
        DashboardActions.setDataGrid({
          dataGridSelected: [
            ...this.currentMetricValues,
            ...this.currentDimensionValues
          ],
          dataGridElement: this.dataGridName,
          orderChange: false
        })
      );

      if (dimensionFilter) {
        this.store.dispatch(
          DashboardActions.setFilter({ filter: dimensionFilter })
        );
      }
    }
  }
  //---DIMENSIONS---

  setupMetrics() {
    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      if (params.dataGridMetrics) {
        this.currentMetricValues = JSON.parse(params.dataGridMetrics);
      }
    });
    [this.metricsOptions, this.currentMetricValues] =
      super.generateMetricsOptionsAndValues(
        this.element,
        this.fieldDefinitions,
        this.currentMetricValues
      );
    this.contextModalLinkService.setMetrics(
      this.metricsOptions,
      this.currentMetricValues
    );
  }

  //trigger on metric panel closing, generate a list of columns to be displayed in the table and pass it to be formatted as table
  onMetricPanelClose() {
    // in case of user deselecting all the values add the values back again
    if (this.currentMetricValues.length <= 0) {
      const freshMetricsSelectionValues: string[] = [];
      forEach(this.element.COLUMN.selected, (selectedCol) => {
        freshMetricsSelectionValues.push(selectedCol.dashboardFieldId);
      });
      forEach(this.element.COLUMN.available, (availableCol) => {
        const isAlreadyCreated = freshMetricsSelectionValues.find(
          (option) => option === availableCol.dashboardFieldId
        );
        if (!isAlreadyCreated) {
          freshMetricsSelectionValues.push(availableCol.dashboardFieldId);
        }
      });
      this.currentMetricValues = freshMetricsSelectionValues;
    }
    this.setDimensionMetricFilterParams('dataGridMetrics');
    this.store.dispatch(
      DashboardActions.setDataGrid({
        dataGridSelected: [
          ...this.currentMetricValues,
          ...this.currentDimensionValues
        ],
        dataGridElement: this.dataGridName,
        orderChange: false
      })
    );
    // TODO: for optimization in future version add a check to see if user has made any change to previous selected list and only then run the logic
    this.formatTable();
    this.calculateDynamicWidth();
    this.contextModalLinkService.setSelectedMetrics(this.currentMetricValues);
  }

  //---METRICS---
  onColumnReorder(event: {
    dragIndex: number;
    dropIndex: number;
    columns: (DataGridAvailable | DataGridSelected | DataGridEditable)[];
  }) {
    this.currentMetricValues = [];
    this.currentDimensionValues = [];
    forEach(event.columns, (column) => {
      if (column.type === 'DIMENSION') {
        this.currentDimensionValues.push(column.dashboardFieldId);
      } else if (column.type === 'COLUMN') {
        this.currentMetricValues.push(column.dashboardFieldId);
      }
    });

    this.maintainTableFieldOrderUntilReload(event.columns);

    this.table.editableFields = super.prepareEditableFields(
      this.table,
      this.filters
    );
    if (event.columns[event.dropIndex]?.type === 'DIMENSION') {
      this.tableRefresh();
    }

    this.store.dispatch(
      DashboardActions.setDataGrid({
        dataGridSelected: [
          ...this.currentMetricValues,
          ...this.currentDimensionValues
        ],
        dataGridElement: this.dataGridName,
        orderChange: true
      })
    );
  }

  format(field: FieldDefinition, value: string | number): string {
    return this.formatterService.format(field, value);
  }

  generateFooter(
    tableData: DataResponse[],
    scrollable: boolean,
    fieldDefinitions: FieldDefinitions,
    dataPoints: DataResponse | undefined
  ) {
    const finalSummary: DataResponse = super.createFooter(
      tableData,
      scrollable,
      fieldDefinitions
    );
    const customAggregationResults = super.handleCustomAggregation(
      finalSummary,
      fieldDefinitions,
      dataPoints
    );
    customAggregationResults.footerTotalDisplayVal = scrollable
      ? 'Total'
      : 'Grand Total';
    return [customAggregationResults];
  }

  clearFilters() {
    this.resetCustomFilterSupporter();
    this.dt.clear();
    if (this.table.data) {
      this.table.footer = this.generateFooter(
        this.table.data,
        this.dataGridConfig.scrollable,
        this.fieldDefinitions,
        this.dataPoints
      );
    }
    this.wasFiltered = false;
  }

  isAnyFilterSet(filters: Record<string, FilterField[]>): boolean {
    return !!flatten(values(filters)).find((i) => i.value != null);
  }

  filterChanged(event: FilterEvent) {
    if (this.isAnyFilterSet(event.filters)) {
      this.wasFiltered = true;
    }
    if (this.dt.filteredValue) {
      this.table.footer = this.generateFooter(
        this.dt.filteredValue,
        this.dataGridConfig.scrollable,
        this.fieldDefinitions,
        this.dataPoints
      );
    } else if (this.table.data) {
      this.table.footer = this.generateFooter(
        this.table.data,
        this.dataGridConfig.scrollable,
        this.fieldDefinitions,
        this.dataPoints
      );
    }
  }

  filterInputKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter' || e.key === 'Tab') {
      this.setFilterValues();
    }
  }

  filterEventsExecution(filterLiteralId: string, selectedValue: string) {
    const eventsForValue = (
      this.filterEvents[filterLiteralId] as Record<
        string,
        DataGridExecutableFilterEvent[]
      >
    )[selectedValue];
    const replacementQueue: Record<string, string>[] = [];

    forEach(eventsForValue, (event) => {
      forEach(this.table.fields, (column) => {
        if (
          event.targetValue === selectedValue &&
          event.subType === column.subType &&
          filterLiteralId === column.sourceLayoutLiteralId
        ) {
          replacementQueue.push({
            replacementColumnId: event.columnDashboardFieldId,
            targetColumnId: column.dashboardFieldId
          });
        }
      });
    });
    forEach(replacementQueue, (replacement) => {
      this.replaceColumn(
        replacement?.targetColumnId || '',
        replacement?.replacementColumnId || ''
      );
    });
  }

  setFilterValues(
    initialLoad?: boolean,
    event: {
      originalEvent: Record<string, string>;
      value: string;
    } | null = null,
    filterLiteralId = ''
  ): void {
    const filtersFromFilterFields = getFiltersFromFilterFields(
      this.filterFields,
      this.filters,
      'DATA_SET'
    );
    const filtersToPersist = super.generateFiltersToPersist(
      filtersFromFilterFields,
      this.persistentFilterIds
    );
    // if not initial load we need to change filters and persist them
    if (!initialLoad) {
      this.filtersService.changeFilters({
        widgetType: WidgetType.table,
        endpoint: DataEndpoint.dataSets
      });
      if (filterLiteralId && filterLiteralId !== '') {
        this.persistFilter(filterLiteralId, filtersToPersist);
      }
      // check if the filter that changed had any events associted to it, and execute those events
      if (
        filterLiteralId &&
        filterLiteralId !== '' &&
        this.filtersWithEventsIds.includes(filterLiteralId) &&
        event !== null
      ) {
        this.filterEventsExecution(filterLiteralId, event.value);
      }
      // we need to update filter options and persist filters with their default values
    } else {
      forEach(filtersToPersist, (filter) => {
        this.persistingFilters.emit(filter);
      });
      this.updateFilters(this.filters);
    }
    // collection of chart filters
    let chartFilters: Filter[] = [];
    this.chartFilter$
      .pipe(take(1))
      .subscribe((filters) => (chartFilters = filters));
    // updating the filters values in the filter service (which will trigger load data by subscription)

    const sub = this.filtersService
      .changeDataGridFilter(
        compact([
          ...getFiltersFromFilterFields(
            this.filterControls,
            this.filters,
            'DATA_SET'
          ),
          ...filtersFromFilterFields,
          this.getTableDimensionNavigationFilter()
        ])
      )
      .subscribe({
        next: (filters) => {
          this.apiService.modifyParams(
            combineFilters(super.generateApiFilters(filters), chartFilters)
          );
        },
        error: (err) => console.error(err)
      });
    this.subscriptions.add(sub);
  }

  //persist filters by sending them to datagrid group so a filter with same id will hold its value when user jumps between tables using breakdown
  persistFilter(filterliteralId: string, filters: Filter[]) {
    const updatedFilterToPersist = filters.find(
      (filter) => filter.literalId === filterliteralId
    );
    this.persistingFilters.emit(updatedFilterToPersist as Filter);
  }

  setupDataGrid(formatTable: boolean): void {
    this.formatTable(formatTable);

    if (super.isTableEmpty(this.tableData)) {
      this.showEmptyState = true;
    }
  }

  getCustomizedMediaPlanConfig(
    config: DataGridExportConfig
  ): DataGridExportConfig {
    const updatedConfig = { ...config };
    updatedConfig.fieldDefinitions = super.getUpdatedFieldDefinition(
      this.table,
      this.fieldDefinitions,
      this.element
    );
    updatedConfig.fields = [
      ...this.table.fields,
      ...this.element.available
    ] as (DataGridAvailable | DataGridEditable | DataGridSelected)[];
    return updatedConfig;
  }

  isPreviousPeriodColumn(col: DataGridSelected) {
    if (
      !this.compareService.compareIsAvailable ||
      !this.compareService.compare
    ) {
      return false;
    }
    return PreviousPeriodService.datagridCompareFields.includes(
      col.dashboardFieldId
    );
  }

  updateFilters(filters: Filter[]) {
    this.filterFields = updateFilterOptions(filters, this.filterFields);
    this.filterControls = updateFilterOptions(filters, this.filterControls);
  }

  // TODO: Remove this and update the portal-data-grid-row to show the column as editable column based on subType
  isMpoDashboard(): boolean {
    return this.literalId === this.portfolioLevelLiteralId;
  }

  handleTabChange(event: IComponentTabChange) {
    const item = event.tab.items[0] as unknown as ElementSubType;
    if (item.dashboardLayoutLiteralId === 'settingsManagerTab') {
      this.dataGridService.allowSelection[this.literalId] = event.index === 1;
      this.router.navigate([], {
        queryParams: {
          [`${this.literalId}_datagridTabIndex`]: event.index
        },
        queryParamsHandling: 'merge'
      });
    }
    this.calculateDynamicWidth();
  }

  handleTabAction(item: ElementSubType) {
    if (item.dashboardLayoutLiteralId === 'settingsManagerTab') {
      const brandSlug = this.selectionService.buildSelectionSlug();

      const conversionType = this.filters.find(
        (f) => f.literalId === 'conversion_type'
      );

      this.router.navigate(
        ['a', brandSlug, 'products', 'settings-manager', 'settings'],
        {
          queryParams: {
            literalId: this.literalId,
            conversionType: conversionType && conversionType.value,
            [`${this.literalId}_datagridTabIndex`]: 1
          }
        }
      );
    }
  }

  asStringArray(val: unknown): string[] {
    return val as string[];
  }

  ngOnDestroy(): void {
    super.unsubscribeToAll(this.subscriptions);
  }

  prepareConfigForDimensionNavigator(initialLoad?: boolean): void {
    this.dimensionNavigationElements =
      this.element.CONTROL?.dimensionNavigationControl?.sort(
        sortByDisplayOrder
      ) || [];
    if (
      this.dimensionNavigationElements &&
      this.dimensionNavigationElements.length
    ) {
      const tableDimensionNavigationFilter = this.findFilterByLiteralId(
        'tableDimensionNavigation'
      );

      this.dimensionNavigationConfig = this.dimensionNavigationElements.map(
        (dimensionNavigationEle) => {
          const eleFilter = this.findFilterByLiteralId(
            dimensionNavigationEle.dashboardFilterId as string
          );
          const countKey =
            eleFilter && eleFilter.value
              ? (eleFilter.value as unknown as string[])[0]
              : null;
          const isSelected =
            tableDimensionNavigationFilter?.value ===
            dimensionNavigationEle.dashboardFilterId;
          const configObj = {
            label:
              this.fieldDefinitions[dimensionNavigationEle.dashboardFieldId]
                ?.label || '',
            id: dimensionNavigationEle.dashboardFilterId as string,
            count:
              this.countByFields && countKey && this.countByFields[countKey]
                ? this.countByFields[countKey]?.count
                : 0,
            span: 3,
            showCount: true,
            isSelected
          };
          if (isSelected && initialLoad) {
            this.handleDimensionNavigation(
              configObj as IDimensionNavigationConfig
            );
          }
          return configObj;
        }
      ) as IDimensionNavigationConfig[];
    }
  }

  handleDimensionNavigation(config: IDimensionNavigationConfig): void {
    this.resetCustomFilterSupporter();
    this.manipulateDataSetUIFilters(config);
    this.isDimensionNavigationChanged = true;
    const clonedFilters = cloneDeep(this.filters);
    this.updateFiltersValue([
      {
        filterLiteralId: 'tableDimensionNavigation',
        filterValue: config.id
      },
      {
        filterLiteralId: 'dimensions',
        filterValue:
          (clonedFilters.find((f) => f.literalId === config.id)
            ?.value as unknown as string[]) || []
      }
    ]);
    this.setupDimensions();
    this.setFilterValues();
    this.applySortPersistence();
  }

  findFilterByLiteralId(literalId: string): Filter | undefined {
    return find(this.filters, { literalId });
  }

  getDimensionValues(): string[] {
    if (
      this.dimensionNavigationElements &&
      this.dimensionNavigationElements.length &&
      this.getTableFieldByElementType(ElementTyping.dimension).length &&
      this.getTableFieldByElementType(ElementTyping.dimension)[0]
        ?.layoutType === this.layoutTypes.composite
    ) {
      this.table.exportFields = super.generateFields(
        this.element,
        this.metricPickerEnabled,
        this.dimensions !== undefined,
        this.currentMetricValues,
        [this.currentDimensionValues[0] || ''],
        this.eventDrivenColumns
      ) as unknown as (
        | DataGridSelected
        | DataGridEditable
        | DataGridAvailable
        | DataGridGrouped
        | DataGridPivotColumn
        | DataGridBarVisualizer
      )[];
      return [this.currentDimensionValues[0] || ''];
    }
    return this.currentDimensionValues;
  }

  updateFiltersValue(
    params: {
      filterLiteralId: string;
      filterValue: FilterValue;
    }[]
  ): void {
    const allFilterIdsToUpdate = params.map(
      ({ filterLiteralId }) => filterLiteralId
    );
    const clonedFilters = cloneDeep(this.filters);
    this.filters = clonedFilters.map((fil) => {
      if (allFilterIdsToUpdate.includes(fil.literalId)) {
        fil.value = find(params, {
          filterLiteralId: fil.literalId
        })?.filterValue as unknown as FilterValue;
        return fil;
      }
      return fil;
    });
  }

  getTableDimensionNavigationFilter(): Filter | null {
    if (this.dimensionNavigationElements.length) {
      return this.findFilterByLiteralId('tableDimensionNavigation') || null;
    }
    return null;
  }

  manipulateDataSetUIFilters(config: IDimensionNavigationConfig): void {
    if (this.dimensionNavigationElements.length) {
      this.dimensionNavigatorLevelDataSetFilters[
        this.getTableDimensionNavigationFilter()?.value as string
      ] = cloneDeep(this.dt.filters);
      this.clearFilters();
      if (this.dimensionNavigatorLevelDataSetFilters[config.id]) {
        this.dt.filters = this.dimensionNavigatorLevelDataSetFilters[
          config.id
        ] as Record<string, FilterMetadata | FilterMetadata[]>;
      }
    }
  }

  setDimensionsFromFilter(): void {
    let dimensionsKey = 'dimensions';
    if (this.multiViewStore.showChild) {
      dimensionsKey = 'childDimensions';
    }
    this.dimensions = this.filters.find((f) => f.literalId === dimensionsKey);
  }

  removeChildColumns(): void {
    if (this.multiViewStore.showChild) {
      const index = this.element.selected.findIndex(
        (sel) => sel.dashboardFieldId === 'childCount'
      );
      this.element.selected.splice(index, 1);
    }
  }

  compositeColumnFilter(): void {
    this.filterService.register(
      COMPOSITE_COLUMN_EQUAL,
      (value: string, filter: string): boolean => {
        if (
          this.customFilterDataCount >= this.table.data.length - 1 &&
          this.previousFilter === filter
        ) {
          this.customFilterDataCount = -1;
        }
        if (
          this.previousFilter === filter ||
          this.customFilterDataCount === -1
        ) {
          this.previousFilter = filter;
          this.customFilterDataCount = this.customFilterDataCount + 1;
        }
        const [firstDimension, secondDimension = null] =
          this.currentDimensionValues;
        if (secondDimension && this.table.data) {
          const obj = this.table.data[this.customFilterDataCount];
          return (
            toLower((obj as DataResponse)[secondDimension] as string).includes(
              toLower(filter)
            ) ||
            toLower(
              (obj as DataResponse)[firstDimension as string] as string
            ).includes(toLower(filter))
          );
        }
        return toLower(value).includes(toLower(filter));
      }
    );
  }

  resetCustomFilterSupporter(): void {
    this.customFilterDataCount = -1;
    this.previousFilter = null;
  }

  selectAllRows(): void {
    this.allSelected = !this.allSelected;
    this.dataGridService.selectAll(
      this.literalId,
      this.allSelected ? this.table.data : []
    );
  }

  setupVirtualScrollItemSizeBasedOnTableHeight(): void {
    const compareSub = this.compareService.isCompareOn.subscribe(
      (isCompareOnVal) => {
        if (isCompareOnVal) {
          this.dataGridConfig.virtualScrollItemSize =
            DataGridStore.virtualScrollItemSizeWithCompare;
        } else {
          this.dataGridConfig.virtualScrollItemSize =
            DataGridStore.virtualScrollItemSizeWithoutCompare;
        }
      }
    );
    this.subscriptions.add(compareSub);
  }

  calculateDynamicWidth(): void {
    this.widthSpecificToFields = {};
    let totalWidth = 100;
    const allTableFields = this.table.fields;

    const dimensionFields = this.getTableFieldByElementType(
      ElementTyping.dimension
    );

    const metricFields = this.getTableFieldByElementType(ElementTyping.column);

    allTableFields.forEach((field) => {
      const dimensionFieldWidthObj =
        DataGridStore.dimensionWidthSpecification[field.dashboardFieldId];
      const defaultWidth =
        field.type === ElementTyping.column
          ? DataGridStore.metricColumnWidth
          : DataGridStore.defaultDimensionColumnWidth;
      const val = dimensionFieldWidthObj
        ? dimensionFieldWidthObj.width
        : defaultWidth;
      this.widthSpecificToFields[field.dashboardFieldId] = val;
      totalWidth = totalWidth - val;
    });
    const totalMetricWidth = metricFields.length;
    const extraWidth =
      totalWidth > totalMetricWidth ? totalWidth - totalMetricWidth : 0;
    if (extraWidth) {
      let total = 0;
      dimensionFields.forEach((dimension) => {
        total =
          total +
          (DataGridStore.dimensionWidthSpecification[dimension.dashboardFieldId]
            ? (
                DataGridStore.dimensionWidthSpecification[
                  dimension.dashboardFieldId
                ] as { width: number; maxWidth: number }
              ).width
            : DataGridStore.defaultDimensionColumnWidth);
      });

      dimensionFields.forEach((dimension) => {
        const val = this.widthSpecificToFields[dimension.dashboardFieldId];
        const updatedDimensionWidth = sum([
          extraWidth * (Number(val) / total),
          val
        ]);
        const maxWidth = DataGridStore.dimensionWidthSpecification[
          dimension.dashboardFieldId
        ]
          ? (
              DataGridStore.dimensionWidthSpecification[
                dimension.dashboardFieldId
              ] as { width: number; maxWidth: number }
            ).maxWidth
          : 0;
        this.widthSpecificToFields[dimension.dashboardFieldId] =
          updatedDimensionWidth > maxWidth ? maxWidth : updatedDimensionWidth;
      });
    }
  }

  getTableFieldByElementType(
    elementType: ElementTyping
  ): (
    | DataGridSelected
    | DataGridEditable
    | DataGridAvailable
    | DataGridGrouped
    | DataGridPivotColumn
    | DataGridBarVisualizer
  )[] {
    return this.table.fields.filter((field) => field.type === elementType);
  }

  resetFooterRow() {
    const resetTimeout = 100;
    this.showFooterRow = false;
    setTimeout(() => {
      this.showFooterRow = true;
    }, resetTimeout);
  }

  maintainTableFieldOrderUntilReload(
    columns: (DataGridAvailable | DataGridSelected | DataGridEditable)[]
  ): void {
    if (
      this.dimensionNavigationElements &&
      this.dimensionNavigationElements.length
    ) {
      const totalAvailableDimensions = this.element.available.filter(
        (obj) => obj.type === ElementTyping.dimension
      ).length;
      this.table.fields = columns.map((col, index) => {
        if (col.type === ElementTyping.column) {
          col.displayOrder = totalAvailableDimensions + 1 + index;
        }
        return col;
      });
    } else {
      this.table.fields = columns;
    }
  }

  getDashboardLayoutLiteralId(dataGridElement: DataGridElement): string {
    return (
      uniq(map(dataGridElement.available, 'dashboardLayoutLiteralId'))[0] || ''
    );
  }

  persistSort(event: PrimeNgSortEvent): void {
    const { field, order, multisortmeta } = event;
    const sortMeta = multisortmeta
      ? multisortmeta
      : [{ field, order } as SortMeta];

    if (this.isSortingChanged(sortMeta)) {
      const sortedColumns: DataGridSortedColumn[] = sortMeta.map(
        (col) =>
          ({
            ...this.table.fields.find(
              (el) => el.dashboardFieldId === col.field
            ),
            subType:
              col.order === PrimeNgSortOrder.ascending
                ? ElementSubTyping.ascending
                : ElementSubTyping.descending
          }) as DataGridSortedColumn
      );

      if (this.isMultiSort) {
        this.persistedMultiSort = sortMeta;
      } else {
        this.persistedSortField = field;
        this.persistedSortOrder = order;
      }

      this.store.dispatch(
        DashboardActions.setDataGridSort({
          dashboardId: this.element.dashboardId,
          dashboardLayoutId: this.dataGridStoreElement?.id || this.element.id,
          dashboardLayoutLiteralId: this.getDashboardLayoutLiteralId(
            this.element
          ),
          sortedColumns
        })
      );
    }
  }

  private isSortingChanged(sortState: SortMeta[]): boolean {
    const previousSortState = this.isMultiSort
      ? this.persistedMultiSort
      : [
          {
            field: this.persistedSortField,
            order: this.persistedSortOrder
          }
        ];

    return !isEqual(previousSortState, sortState);
  }

  private isDataGridFilterValueUpdated(dataGridFilters: Filter[]): boolean {
    return (
      compact(
        dataGridFilters.map((dataGridFilter) => {
          const filterWithPreviousValue = this.previousDataGridFilters.find(
            (f) => f.literalId === dataGridFilter.literalId
          );
          if (filterWithPreviousValue) {
            if (filterWithPreviousValue.value !== dataGridFilter.value) {
              return dataGridFilter;
            }
            return null;
          } else {
            return dataGridFilter;
          }
        })
      ).length > 0
    );
  }

  exportWithFormat(
    format: ExportTableType,
    config: DataGridExportConfig = { tableName: '' },
    dt: Table
  ): void {
    const fileName = `${this.literalId}-${this.clientId}-${this.dateService
      .today()
      .format('MM-DD-YYYY')}`;
    const exportObjParams = {
      literalId: this.element.literalId,
      name: config.fileName ? `${config.fileName}-${fileName}` : fileName,
      table: {
        ...this.table,
        data:
          this.literalId === 'geo-experiment-result'
            ? dt.filteredValue || this.table.data
            : []
      },
      format,
      availableFields: this.element.available as DataGridAvailable[],
      config,
      pivotRows: false,
      isPivotTable: false,
      showFooter: config.showFooter ?? this.dataGridConfig.exportShowFooter,
      groupByFieldName: this.groupByFieldName
    };
    this.callExportApi(
      super.generateApiFilters(this.dataGridApiFilters),
      DataEndpoint.dataSetExport,
      this.currentDimensionValues,
      exportObjParams
    );
  }

  callExportApi(
    filters: Filter[],
    type: DataEndpoint,
    dimensionValues: string[] = [],
    exportObjParams: ExportObjectParamValue
  ): void {
    this.apiService.getExportData({
      filters,
      type,
      dimensionValues,
      dimensionFormat: this.dimensionFormat,
      viewId: this.viewId,
      exportObjParams: { ...exportObjParams, literalId: this.element.literalId }
    });
  }

  getMetricTooltip(
    fieldDefinition: FieldDefinition | undefined
  ): string | null {
    if (!fieldDefinition) return null; // To not render tooltip

    if (
      !FieldTooltipsText[
        fieldDefinition.literalId as keyof typeof FieldTooltipsText
      ]
    ) {
      return null;
    }
    let tooltipHtml = '<div class="w-64 text-center">';
    tooltipHtml += `<div class="b3">
          <span>${fieldDefinition.label}</span>
        </div>`;
    tooltipHtml += `<div class="m-divider dark my-2"></div>`;
    tooltipHtml += `<div class="b2 pt-1">
          <span>${FieldTooltipsText[fieldDefinition.literalId as keyof typeof FieldTooltipsText]}</span>
        </div>`;

    if (fieldDefinition.subText) {
      tooltipHtml += `<div class="m-divider dark my-2"></div>`;
      tooltipHtml += `<div>
            <span class="c2 text-gray-500">𝑓 (${fieldDefinition.subText})</span>
          </div>`;
    }

    tooltipHtml += '</div>';
    return tooltipHtml;
  }
}
