/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompareBannerService {
  spendCountByTactic$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  get spendCountByTactic(): Observable<number> {
    return this.spendCountByTactic$.asObservable();
  }

  setSpendCountbyTactic(count: number): void {
    this.spendCountByTactic$.next(count);
  }
}
