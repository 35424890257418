import { AfterViewInit, Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { SelectItem } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';

// Services
import { CompareLineChartService } from '@portal/app/dashboard/home-page/components/compare-line-chart/compare-line-chart.service';
import { DropdownService } from '@portal/app/dashboard/home-page/services/dropdown.service';
import { FilterService } from '@portal/app/dashboard/home-page/services/filter.service';
import { HomepageService } from '@portal/app/dashboard/home-page/services/home-page.service';

// Types
import type { Options } from 'highcharts';
import type { FieldDefinitions } from '@portal/app/shared/types';

@Component({
  selector: 'portal-compare-line-chart',
  templateUrl: './compare-line-chart.component.html'
})
export class CompareLineChartComponent
  implements AfterViewInit, OnInit, OnDestroy
{
  compare = 'mediaSpend';
  compare2 = 'roasI';
  fields: FieldDefinitions = {};
  isDataAvailable = true;
  isLoading = true;
  metricOptions: SelectItem[] = [];
  multiLineChartOptions: Options = {};
  private destroy$ = new Subject<void>();
  relativeDay = 'Latest 30 Days';
  relativeDayOptions: SelectItem[] = [];
  viewInitialized = false;

  constructor(
    private compareLineChartService: CompareLineChartService,
    private dropdownService: DropdownService,
    private filterService: FilterService,
    private homepageService: HomepageService
  ) {}

  get isChartReady(): boolean {
    return (
      this.viewInitialized &&
      !this.isLoading &&
      !!this.multiLineChartOptions?.series &&
      !!this.multiLineChartOptions?.xAxis &&
      !!this.multiLineChartOptions?.yAxis &&
      !!this.multiLineChartOptions?.plotOptions &&
      !!this.multiLineChartOptions?.tooltip
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.viewInitialized = true;
    });
  }

  ngOnInit(): void {
    this.subscribeToData();

    this.relativeDayOptions = this.dropdownService.getRelativeDayOptions();

    this.metricOptions = this.dropdownService.getMetricOptions(true);
  }

  subscribeToData() {
    this.compareLineChartService.currentFilters$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const relativeDay = this.compareLineChartService.getRelativeDay();
        if (relativeDay) {
          this.relativeDay = relativeDay;
        }
      });

    this.compareLineChartService.isLoading$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoading) => {
        this.isLoading = isLoading;
      });

    this.compareLineChartService.chartData$
      .pipe(takeUntil(this.destroy$))
      .subscribe((chartData) => {
        if (chartData) {
          this.multiLineChartOptions = chartData;
          this.isDataAvailable =
            this.homepageService.checkChartDataAvailability(chartData);
        }
      });

    this.homepageService.compareChartPrimaryMetric$
      .pipe(takeUntil(this.destroy$))
      .subscribe((compareChartPrimaryMetric) => {
        if (compareChartPrimaryMetric) {
          this.compare = compareChartPrimaryMetric;
        }
      });

    this.homepageService.compareChartSecondaryMetric$
      .pipe(takeUntil(this.destroy$))
      .subscribe((compareChartSecondaryMetric) => {
        if (compareChartSecondaryMetric) {
          this.compare2 = compareChartSecondaryMetric;
        }
      });
  }

  getCompareOptions(): SelectItem[] {
    const fieldValues = Object.values(this.fields);

    return fieldValues.map((option) => ({
      label: option.label,
      value: option.literalId
    }));
  }

  onCompareChange(newValue: string) {
    this.compare = newValue;
    this.compareLineChartService.setChartDimensions([
      this.compare,
      this.compare2
    ]);
    this.saveLayout();
  }

  onCompare2Change(newValue: string) {
    this.compare2 = newValue;
    this.compareLineChartService.setChartDimensions([
      this.compare,
      this.compare2
    ]);
    this.saveLayout();
  }

  onRelativeDayChange(newValue: string) {
    this.relativeDay = newValue;

    const dateFilters = this.filterService.getDateFilters(newValue);
    this.compareLineChartService.fetchAndSetChartDataWithTempFilters(
      dateFilters
    );
  }

  saveLayout() {
    const layoutData = {
      compareChartPrimaryMetric: this.compare,
      compareChartSecondaryMetric: this.compare2
    };

    this.homepageService.updateHomepageLayout(layoutData).subscribe({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error: (error: any) => console.error(error)
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
