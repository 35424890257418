/**
 * Enum representing all possible positions for a tooltip.
 * Each position is a string value corresponding to the position relative to the target element.
 */
export enum TooltipPositionEnum {
  top = 'top',
  bottom = 'bottom',
  left = 'left',
  right = 'right'
}

/**
 * Type definition for tooltip positions.
 * It uses keyof typeof to create a type with the keys of TooltipPositionEnum as possible values.
 */
export type TooltipPosition = keyof typeof TooltipPositionEnum;

/**
 * Enum representing all possible arrow positions for a tooltip.
 * Each position is a string value corresponding to the arrow position.
 */
export enum TooltipArrowPositionEnum {
  center = 'center',
  left = 'left',
  right = 'right'
}

/**
 * Type definition for tooltip arrow positions.
 * It uses keyof typeof to create a type with the keys of TooltipArrowPositionEnum as possible values.
 */
export type TooltipArrowPosition = keyof typeof TooltipArrowPositionEnum;
