@if (title) {
  <div class="title">
    <div class="flex items-center">
      <span class="title-text">{{ title }}</span>
      @if (name === "incrementalityMetrics") {
        <portal-mmm-banner
          class="pl-4"
          [brandId]="brandId"
          [filters]="filters"
          [fieldDefinitions]="fieldDefinitions"></portal-mmm-banner>
      }
    </div>
  </div>
}
@if (heroes.length) {
  <div class="flex flex-col flex-wrap" [style.height]="gridWrapperHeight">
    <div
      class="hero-grid position-relative grid gap-4 grid-cols-{{
        ['totalCurRoasI', 'optimizeForData'].includes(heroes[0]?.name)
          ? 3
          : asHero(heroes).length
      }}"
      id="hero-group-container-{{ name }}">
      @for (hero of asHero(heroes); track hero; let index = $index) {
        <div id="{{ hero.field.literalId }}">
          <portal-hero
            [attr.gridName]="name"
            [attr.gridItemName]="hero.name"
            [ngClass]="[
              'text-center',
              'hero-item',
              'case-with-' +
                (heroes.length +
                  (heroes[0]?.type === 'DATA_POINT_SM' ||
                  heroes[0]?.name === 'optimizeForData'
                    ? 1
                    : 0)) +
                '-elements',
            ]"
            [style.width]="heroComponentWidth"
            [gridItem]="gridItem"
            [loading]="loading"
            [name]="hero.name"
            [field]="hero.field"
            [label]="hero.label"
            [value]="hero.value"
            [type]="hero.type"
            [children]="hero.children"></portal-hero>
        </div>
      }
    </div>
  </div>
}
