import { Inject, Injectable, NgZone } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { EMPTY, Observable, interval, of, throwError } from 'rxjs';
import {
  map,
  catchError,
  timeout,
  takeUntil,
  switchMap,
  filter,
  take
} from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '@portal/environments/environment';
import { MessageService } from 'primeng/api';
import {
  getIdToken,
  getOktaIdTokenObj
} from '@portal/app/shared/helpers/helpers';
import { ApiService } from '@portal/app/shared/services/api.service';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { LocalStorageService } from '@portal/app/shared/services/localstorage.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(
    private readonly router: Router,
    private readonly ngZone: NgZone,
    private readonly messageService: MessageService,
    private readonly apiService: ApiService,
    private readonly localStorageService: LocalStorageService,
    @Inject(OKTA_AUTH) private readonly oktaAuth: OktaAuth
  ) {}

  static createErrorObject(message: string) {
    return {
      key: 'error',
      severity: 'error',
      summary: 'Error',
      detail: message
    };
  }

  private waitForNewToken(oldToken: string): Observable<string> {
    return interval(1000).pipe(
      switchMap(() => {
        const newToken = getIdToken() as string;
        return of(newToken);
      }),
      filter((newToken) => Boolean(newToken && newToken !== oldToken)),
      take(1),
      timeout(10000),
      catchError(() => {
        localStorage.clear();
        this.router.navigateByUrl('/login').catch((e) => throwError(e));

        return EMPTY;
      })
    );
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const idTokenObj = getOktaIdTokenObj();
    const isTestingLogin = request.params.has('testLogin');
    let authToken = getIdToken();

    if (
      !environment.production &&
      (!environment.innovation ||
        (environment.innovation &&
          environment.apiDomain?.includes('localhost'))) &&
      environment.apiDomain?.includes('localhost') &&
      environment.authToken
    ) {
      authToken = environment.authToken;
    }

    if (!idTokenObj?.idToken) {
      localStorage.clear();
      this.router.navigateByUrl('/login');
      return EMPTY;
    }

    if (
      idTokenObj?.idToken &&
      this.oktaAuth.tokenManager.hasExpired(idTokenObj)
    ) {
      return this.waitForNewToken(idTokenObj.idToken).pipe(
        switchMap((newToken) => {
          const updatedRequest = request.clone({
            headers: request.headers
              .set('Accept', 'application/json')
              .set('provider', environment.ssoProvider)
              .set('Authorization', `Bearer ${newToken}`)
              .set('X-Page-UUID', this.localStorageService.getPageUUID())
          });
          return next.handle(updatedRequest);
        })
      );
    }

    const updatedRequest = request.clone({
      headers: request.headers
        .set('Accept', 'application/json')
        .set('provider', environment.ssoProvider)
        .set('Authorization', `Bearer ${authToken}`)
        .set('X-Page-UUID', this.localStorageService.getPageUUID())
    });
    return next.handle(updatedRequest).pipe(
      takeUntil(this.apiService.pendingRequestCancellationStatus),
      timeout(600000),
      map((event) => event),
      catchError((error: HttpErrorResponse) => {
        if (!isTestingLogin) {
          console.error(error, 'error');
          // Show elasticsearch error
          if (
            error &&
            error.error &&
            error.error.message &&
            error.error.errorCode === 'ResponseError'
          ) {
            this.ngZone.run(() => {
              this.messageService.add(
                AuthenticationInterceptor.createErrorObject(error.error.message)
              );
            });
          }
        }
        return throwError(() => error);
      })
    );
  }
}
