import { FiltersType, IFilterCriteria, IVCampaign } from '@libs/apis';

export class CampaignFiltersHelper {
  constructor(
    private readonly filterCriteria: IFilterCriteria[],
    private readonly delimiter = '#####',
    private readonly applySelectedFlagFilter = false
  ) {
    if (applySelectedFlagFilter) {
      this.filterCriteria.push({
        fieldName: 'isSelected',
        value: 'true',
        filter: FiltersType.EQUALS
      });
    }
  }

  public matches(campaign: IVCampaign): boolean {
    if (!this.filterCriteria?.length && !this.applySelectedFlagFilter) {
      return true;
    }

    return (
      (this.applySelectedFlagFilter && campaign.isSelected) ||
      this.filterCriteria
        .map((filter) => this.validateWithFilterOperator(campaign, filter))
        .reduce((matchResult1, matchResult2) => matchResult1 && matchResult2)
    );
  }

  private validateWithFilterOperator(
    campaign: IVCampaign,
    filter: IFilterCriteria
  ): boolean {
    switch (filter.filter) {
      case FiltersType.CONTAINS:
        return this.contains(campaign, filter);
      case FiltersType.NOT_CONTAINS:
        return this.notContains(campaign, filter);
      case FiltersType.STARTS_WITH:
        return this.startsWith(campaign, filter);
      case FiltersType.ENDS_WITH:
        return this.endsWith(campaign, filter);
      case FiltersType.EQUALS:
        return this.equals(campaign, filter);
      case FiltersType.NOT_EQUALS:
        return this.notEquals(campaign, filter);
      case FiltersType.IN:
        return this.in(campaign, filter);
      case FiltersType.NOT_IN:
        return this.notIn(campaign, filter);
      default:
        return false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private contains(campaign: any, filter: IFilterCriteria): boolean {
    return campaign[filter.fieldName]
      ?.toLowerCase()
      .includes(filter.value.toLowerCase());
  }

  private notContains(campaign: IVCampaign, filter: IFilterCriteria): boolean {
    return !this.contains(campaign, filter);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private startsWith(campaign: any, filter: IFilterCriteria): boolean {
    return campaign[filter.fieldName]
      ?.toLowerCase()
      .startsWith(filter.value.toLowerCase());
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private endsWith(campaign: any, filter: IFilterCriteria): boolean {
    return campaign[filter.fieldName]
      ?.toLowerCase()
      .endsWith(filter.value.toLowerCase());
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private equals(campaign: any, filter: IFilterCriteria): boolean {
    return (
      `${campaign[filter.fieldName]}`.toLowerCase() ===
      filter.value.toLowerCase()
    );
  }

  private notEquals(campaign: IVCampaign, filter: IFilterCriteria): boolean {
    return !this.equals(campaign, filter);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private in(campaign: any, filter: IFilterCriteria): boolean {
    return filter.value
      .toLowerCase()
      .split(this.delimiter)
      .includes(campaign[filter.fieldName]?.toLowerCase());
  }

  private notIn(campaign: IVCampaign, filter: IFilterCriteria): boolean {
    return !this.in(campaign, filter);
  }
}
