<div class="metric-card-container" [@slideIn]="data$ | async">
  @if (data$ | async; as dataPoints) {
    @for (card of item; track card) {
      <div class="metric-card-item border">
        <portal-metric-card-item
          [metricData]="card"
          [fieldDefinitions]="fieldDefinitions"
          [dataPoints]="dataPoints"
          [isMetricActive]="isMetricActive"
          [dashboardLiteralId]="dashboardLiteralId">
        </portal-metric-card-item>
      </div>
    }
  } @else {
    <div class="metric-card-item-loading">
      <p-progressSpinner></p-progressSpinner>
    </div>
  }
</div>
