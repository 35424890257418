import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { MultiViewStore } from '@portal/app/shared/state/multi-view.store';
import { MultiViewService } from '@portal/app/shared/services/multi-view.service';
import { PortalEventBusService } from '@portal/app/shared/services/portal-event-bus.service';
import {
  DataResponse,
  ElementGroup,
  FieldDefinitions,
  PortalEvent,
  PortalEventCategory,
  ViewResponse
} from '@portal/app/shared/types';
import { Subscription, take } from 'rxjs';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { isEmpty } from 'lodash-es';
import { ActivatedRoute, Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@portal/app/store/app.state';
import { MultiViewActions } from '@portal/app/store/multi-view/actions';

dayjs.extend(relativeTime);

@Component({
  selector: 'portal-view-header',
  templateUrl: './view-header.component.html',
  styleUrls: ['./view-header.component.scss']
})
export class ViewHeaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() id?: string;
  @Input() view?: ViewResponse;
  @Input() item?: ElementGroup;
  @Input() fieldDefinitions: FieldDefinitions = {};

  public dataPoints?: DataResponse;
  public isViewTitleEditMode = false;
  public editableHeaderValue?: string;
  public lastTimeSaved?: string;

  private readonly subs: Subscription = new Subscription();
  public showChild = false;
  public planName = '';
  private channelName = '';
  private tacticName = '';

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: HTMLElement) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside || targetElement instanceof HTMLDivElement) {
      this.multiViewService.viewEventState$.pipe(take(1)).subscribe((val) => {
        const controlSetLayout = val.metaData?.controlSet;
        if (
          controlSetLayout === 'VIEW_CONFIRMATION_SET' &&
          val.id === this.id
        ) {
          this.portalEventBusService.emit({
            id: this.id,
            action: 'VIEW_CONFIRM_CANCEL',
            eventCategory: PortalEventCategory.multiViewEvent
          });
        }
      });
    }
  }

  constructor(
    private elementRef: ElementRef,
    private multiViewStore: MultiViewStore,
    private multiViewService: MultiViewService,
    private portalEventBusService: PortalEventBusService,
    private readonly route: ActivatedRoute,
    private readonly store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.subs.add(
      this.route.queryParams.subscribe((params: Params) => {
        this.showChild = JSON.parse(params.showChild || false);
        if (this.showChild) {
          this.item?.elements.forEach((el) => (el.span = 6));
          if (params.channel) {
            this.channelName = JSON.parse(params.channel);
          }
          if (params.tactic) {
            this.tacticName = JSON.parse(params.tactic);
          }
        }
        this.getDataPoints();
        this.setLastTimeSaved();
      })
    );

    this.subs.add(
      this.multiViewService.viewEventState$.subscribe((e) => {
        if (e.id === this.id) {
          this.handleViewEvents(e);
        }
      })
    );

    setInterval(() => {
      this.setLastTimeSaved();
    }, 60000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.view) {
      this.getDataPoints();
    }
    this.setLastTimeSaved();
  }

  getDataPoints() {
    if (!isEmpty(this.view)) {
      this.multiViewStore
        .getViewDataPoints(this.view, this.showChild)
        .then((dataPoints) => {
          this.dataPoints = dataPoints;
          this.prepareChildData();
        });
    } else {
      this.dataPoints = {};
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  handleViewEvents(e: PortalEvent) {
    this.isViewTitleEditMode = !!e.metaData?.editViewName;
    if (e.action === 'VIEW_CONFIRM_SAVE' && this.editableHeaderValue) {
      e.metaData = {
        ...e.metaData,
        editableHeaderValue: this.editableHeaderValue
      };
      this.portalEventBusService.emit(e);
    }
  }

  onEditableHeaderValue(val: string) {
    this.editableHeaderValue = val;
  }

  onEnterClickedForTitle() {
    this.portalEventBusService.emit({
      id: this.id,
      action: 'VIEW_CONFIRM_SAVE',
      eventCategory: PortalEventCategory.multiViewEvent,
      metaData: {
        editableHeaderValue: this.editableHeaderValue as string,
        prevAction: 'VIEW_OPERATION_RENAME'
      }
    });
  }

  setLastTimeSaved() {
    this.lastTimeSaved = this.multiViewService.getLastTimeSaved(
      this.id
    ) as string;
  }

  navigateToParentPage(event: Event) {
    event.preventDefault();

    this.store.dispatch(
      MultiViewActions.navigateToParent({
        viewId: this.view?.id
      })
    );
  }

  prepareChildData() {
    if (this.showChild && this.dataPoints) {
      this.planName = (this.dataPoints.name as string) || 'Back';
      this.dataPoints.cellName = this.channelName;
      this.dataPoints.name = this.tacticName;
    }
  }
}
