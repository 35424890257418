import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ToastMessageService {
  constructor(private readonly messageService: MessageService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public showSuccess(message: string, title?: string, data?: any) {
    this.messageService.add({
      severity: 'success',
      summary: title || 'Success',
      detail: message,
      data: data || {}
    });
  }

  public showError(message: string, title?: string) {
    this.messageService.add({
      severity: 'error',
      summary: title || 'Error',
      detail: message
    });
  }
}
