import {
  Component,
  effect,
  ElementRef,
  inject,
  input,
  Renderer2,
  signal,
  ViewContainerRef
} from '@angular/core';
import { AllMediaResponseTable } from '@portal/app/dashboard/context-modal/services/media.service';
import { TacticModalComponent } from '@portal/app/dashboard/context-modal/components/tactic-modal/tactic-modal.component';
import {
  LinkService,
  DimensionOption
} from '@portal/app/dashboard/context-modal/services/link.service';
import { ContextModalParams } from '@portal/app/dashboard/context-modal/context-model.types';

@Component({
  selector: 'portal-context-media',
  templateUrl: './media.component.html'
})
export class MediaComponent {
  containerRef = inject(ViewContainerRef);
  elRef = inject(ElementRef);
  renderer = inject(Renderer2);
  linkService = inject(LinkService);

  isLoading = input.required<boolean>();
  table = input.required<AllMediaResponseTable>();
  isDataAvailable = input.required<boolean>();

  constructor() {
    effect(() => {
      const table = this.table();
      if (table.values.length > 0) {
        this.setupEventsAndModal();
      }
    });
  }

  setupEventsAndModal() {
    const links = this.elRef.nativeElement.querySelectorAll(
      '.context-modal-link'
    ) as HTMLElement[];

    if (links.length) {
      const tactics = Array.from(links).map((link) =>
        JSON.parse(link.getAttribute('data-row') || 'false')
      ) as ContextModalParams[];

      links.forEach((link: HTMLElement, i) => {
        const row = tactics[i];
        if (row) {
          this.renderer.listen(link, 'click', () => {
            this.createAndSetupTacticModal(row, tactics);
          });
        }
      });
    }
  }

  createAndSetupTacticModal(
    row: ContextModalParams,
    tactics: ContextModalParams[]
  ) {
    const modal = this.containerRef.createComponent(TacticModalComponent);

    // set properties
    modal.instance.hasBackButton = true;
    modal.instance.row = row;
    modal.instance.tactics = signal<ContextModalParams[]>(tactics);
    const tacticsOptions = this.linkService.mapTacticsOptions(tactics);
    modal.instance.tacticsOptions = signal<DimensionOption[]>(tacticsOptions);

    // events
    modal.instance.closeModal.subscribe(() => {
      this.containerRef.clear();
    });
  }
}
