export interface IAlertMessageConfig {
  summary: string;
  description?: string;
  key: string;
  severity: AlertSeverity;
  icon: AlertIcon;
  alertActions: IAlertAction[];
}

export enum AlertSeverity {
  danger = 'danger',
  warn = 'warning',
  success = 'success',
  info = 'info'
}

export enum AlertIcon {
  warning = 'warning',
  checkCircle = 'check_circle'
}

export interface IAlertAction {
  eventId: string;
  label: string;
  type: AlertActionType;
}

export enum AlertActionType {
  primary = 'primary',
  secondary = 'secondary'
}

export interface IAlertActionEvent {
  eventId: string;
}
