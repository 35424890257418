import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { sortByDisplayOrder } from '@portal/app/dashboard/utils';
import { orderBy } from 'lodash-es';
import { ElementSubType, DataGridElement } from '@portal/app/shared/types';

export interface IComponentTabChange {
  originalEvent: PointerEvent;
  index: number;
  tab: IComponentTab;
}

interface IComponentTab {
  key: string;
  label: string | null | undefined;
  items: ElementSubType[];
  displayOrder: number;
}

@Component({
  selector: 'portal-component-tab',
  templateUrl: './component-tab.component.html',
  styleUrls: ['./component-tab.component.scss']
})
export class ComponentTabComponent implements OnInit {
  @Input() element!: DataGridElement;
  @Input() activeTabIndex = 0;
  @Input() disableAction = false;
  @Output() tabChange = new EventEmitter<IComponentTabChange>();
  @Output() actionClick = new EventEmitter<ElementSubType>();

  public tabs: IComponentTab[] = [];

  ngOnInit(): void {
    this.prepareTabs();
  }

  prepareTabs() {
    if (this.element && this.element.COMPONENT_TAB) {
      const tabs = this.element.COMPONENT_TAB;
      for (const key in tabs) {
        if (tabs[key]) {
          const items = tabs[key] as ElementSubType[];
          const titleElement = items.find((v) => v.layoutType === 'TITLE');
          this.tabs.push({
            key,
            label: titleElement?.label,
            items: items.sort(sortByDisplayOrder),
            displayOrder: titleElement?.displayOrder || 0
          });
        }
      }

      this.tabs = orderBy(this.tabs, 'displayOrder');
    }
  }

  handleTabChange(event: IComponentTabChange) {
    this.tabChange.emit({
      ...event,
      tab: this.tabs[event.index] as IComponentTab
    });
  }

  handleActionClick(item: ElementSubType) {
    if (!this.disableAction) {
      this.actionClick.emit(item);
    }
  }

  hasTabs() {
    return this.element && !!this.element.COMPONENT_TAB;
  }
}
