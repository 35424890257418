import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  BottomPanelMessageType,
  IBottomPanelButtonConfig
} from '@design-system/components/m-bottom-panel';
import { NoDataState } from '@design-system/components/m-not-available';
import {
  ITabPanelHeader,
  TabViewHeaderSize
} from '@design-system/components/m-tab-view';
import { IFilterData } from '@design-system/components/m-text-field';
import {
  ApiService,
  CampaignType,
  FiltersType,
  ICampaignsCount,
  ICancelUnpublishedCampaignChanges,
  IFilterCriteria,
  IUpdateCampaignWithFilterCriteria,
  IVCampaign
} from '@libs/apis';
import { IPTableColumnInfo, ViewType } from '@libs/common-components';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import {
  camelCase,
  cloneDeep,
  isEmpty,
  isEqual,
  isNil,
  omitBy,
  snakeCase,
  uniq
} from 'lodash-es';
import { MenuItem } from 'primeng/api';
import { MenuItemCommandEvent } from 'primeng/api/menuitem';
import { Table } from 'primeng/table';
import { BehaviorSubject } from 'rxjs';
import { SlidingQueue } from '../../helper/sliding-queue';
import { TaxonomyHelper } from '../../helper/taxonomy-helper';
import {
  ICampaignFilterContext,
  TaxonomyDateRange,
  TaxonomyDimension
} from '../../models/ICampaignFilterContext';
import { ICampaignsCountByDimension } from '../../models/ICampaignsCountByDataSource';
import { IColumnFilterContext } from '../../models/IColumnFilterContext';
import { ReviewAction } from '../../models/ReviewAction';
import { TaxonomyTab } from '../../models/TaxonomyTab';
import { CampaignViewService } from '../../services/campaign-view.service';
import { SharedDataService } from '../../services/shared-data.service';
import { TableDataService } from '../../services/table-data.service';
import { ToastMessageService } from '../../services/toast-message.service';
import {
  CampaignDimension,
  TaxonomyUiConstants
} from '../../taxonomy-ui/taxonomy-ui.constants';
import { ReviewCampaignsService } from '../review-campaigns/review-campaigns.service';
import { Constants } from './campaigns-view.constants';

dayjs.extend(timezone);

@Component({
  selector: 'lib-taxonomy-campaigns-view',
  templateUrl: './campaigns-view.component.html',
  styleUrls: ['./campaigns-view.component.scss']
})
export class CampaignsViewComponent implements OnChanges {
  private static readonly delayForClearingSelectedCampaignInMillis = 500;

  loading = new BehaviorSubject<boolean>(false);
  tableLoading = new BehaviorSubject<boolean>(false);
  globalLoading = new BehaviorSubject<boolean>(false);

  @Input()
  public clientId = 0;

  @Input()
  public brandId = 0;

  @ViewChild('campaignTable', { static: false })
  private campaignTable!: Table;

  public headerSize: TabViewHeaderSize = TabViewHeaderSize.default;
  public tabPanelHeaders: ITabPanelHeader[] = Constants.tabPanelHeaders;
  public filteredCampaignViewColumns: IPTableColumnInfo[] = [
    ...TaxonomyUiConstants.defaultCampaignTableColumns,
    ...Constants.campaignViewHeaders
  ];

  public campaignViewColumns: IPTableColumnInfo[] = cloneDeep([
    ...TaxonomyUiConstants.defaultCampaignTableColumns,
    ...Constants.campaignViewHeaders
  ]);

  public virtualPageSize = 200;
  campaignQueue = new SlidingQueue<IVCampaign>(this.virtualPageSize);

  public showUpdateMappingOverlay = false;

  public pageSize = 200000;
  public noData = NoDataState.noData;
  public tabWiseNoDataMessages = Constants.tabWiseNoDataMessages;
  public activeTaxonomyTab = TaxonomyTab.unmapped;
  public dataSourceDimension = TaxonomyDimension.compositeDataSourceName;
  public activeAccordionDimensionValue = '';
  public activeAccordionIndex: number | null = null;
  public previousAccordionIndex: number | null = null;
  public campaigns: Record<string, IVCampaign[]> = {};
  public inReviewCampaigns: IVCampaign[] = [];
  public reviewActionButtons = Constants.reviewActionButtons;
  public reviewNavigationButtons: IBottomPanelButtonConfig[] = [];
  public showCampaignsToReview = false;
  public campaignAccordions: ICampaignsCount[] = [];
  public selectedCampaigns: IVCampaign[] = [];
  public allCampaignsSelected = false;
  public pageCampaignsSelected = false;
  public allCampaignsInDimensionSelected = false;
  public showCampaignMappingOperationDialog = false;
  public unmapCampaignsConfirmationMessage =
    'This will remove all campaigns from this review and submit screen. They will be reverted back to the unmapped state';

  public removeUnpublishedCampaignsPayload: Record<
    string,
    string | null | undefined
  > = {};

  public selectedDimensionOptions: Record<CampaignDimension, string[]> = {
    [CampaignDimension.channel]: [],
    [CampaignDimension.tactic]: [],
    [CampaignDimension.segment]: []
  };

  public mappedCampaignActions: MenuItem[] = [
    {
      label: '<span class="b1 text-gray-1000">Unmap Campaign</span>',
      escape: false,
      styleClass: 'transition-none',
      command: () => {
        this.showCampaignMappingOperationDialog = true;
        this.unmapCampaignsConfirmationMessage = `This will remove the channel, tactic & segment mapping from ${
          this.allCampaignsSelected ? 'all the campaigns' : 'the campaign'
        } <strong>${this.selectedMappedCampaign?.campaign || ''}</strong>`;
        this.isUnmapCampaignAction = true;
      }
    }
  ];

  public filterContext: ICampaignFilterContext = Constants.defaultFilterContext;

  public geoTestStatusByDimension: Record<string, boolean> = {};
  public loaderTitle = '';
  public loaderMessage = '';
  public loaderUiView = ViewType.loader;
  public isUnmapCampaignAction = false;

  dimensionSearchCriteria: Record<string, string> = {};
  public reviewUndoMessage = {
    message: '',
    messageType: BottomPanelMessageType.base
  };

  filterStateByTab: Record<string, IColumnFilterContext> = {};

  emptyFilterState = Constants.defaultFilterState;

  public campaignCountsByType: Record<string, ICampaignsCountByDimension> = {};
  private timezone = 'America/New_York';
  private selectedDateRange: TaxonomyDateRange = TaxonomyDateRange.last30Days;
  public requestInProgress = false;
  public selectionInProgress = false;
  selectedMappedCampaign: IVCampaign | null = null;
  private undoCampaigns: IVCampaign[] = [];
  public activeDimensionPageNumber = 0;
  public loadNext = Constants.loadNextText;
  public loadPrevious = Constants.loadPreviousText;
  public sort: { sortBy: string; sortOrder: string } | null = null;

  constructor(
    private readonly reviewCampaignsService: ReviewCampaignsService,
    private readonly campaignViewService: CampaignViewService,
    private readonly sharedDataService: SharedDataService,
    private readonly toastMessageService: ToastMessageService,
    private readonly apiService: ApiService,
    private readonly tableDataService: TableDataService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  get keyForActiveTabAndDimension(): string {
    return `${this.clientId}-${this.brandId}-${
      this.activeTaxonomyTab
    }-${snakeCase(this.activeAccordionDimensionValue)}`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loading.next(true);
    if (
      changes['clientId'] &&
      changes['clientId'].currentValue &&
      changes['clientId'].currentValue !== changes['clientId'].previousValue
    ) {
      this.initializeComponent();
    } else {
      this.loading.next(!changes['clientId']?.currentValue);
    }
  }

  private initializeComponent() {
    this.loading.next(true);
    this.tableDataService.reset().catch(console.error);
    this.campaignViewService.getGeoTestStatusByDimension(
      this.clientId,
      this.brandId
    );
    this.sharedDataService
      .getGeoTestStatusByDimension()
      .subscribe((geoTestStatusByDimension) => {
        this.geoTestStatusByDimension = geoTestStatusByDimension;
      });
    this.initializeFilterState();
    this.getCampaignCount();
    this.cdr.detectChanges();
  }

  publishTabChange(activeTab: number) {
    this.activeTaxonomyTab = Object.values(TaxonomyTab)[
      activeTab
    ] as TaxonomyTab;
    this.selectedCampaigns = [];
    this.campaignAccordions = [];
    this.campaignQueue.flush();
    this.activeAccordionIndex = null;
    this.previousAccordionIndex = null;
    this.campaignViewColumns = cloneDeep([
      ...TaxonomyUiConstants.defaultCampaignTableColumns,
      ...Constants.campaignViewHeaders
    ]);
    this.refreshCampaignView();
  }

  refreshCampaignView(
    context: ICampaignFilterContext = Constants.defaultFilterContext
  ) {
    this.selectedDateRange = context.dateRange as TaxonomyDateRange;
    if (
      this.filterContext.groupByDimension !== context.groupByDimension ||
      this.filterContext.dateRange !== context.dateRange
    ) {
      this.filterContext = context;
      this.unsetDimensionCampaigns();

      if (this.isFilterContextApplied()) {
        this.getCampaignCountWithFilter();
      } else {
        this.getCampaignCount();
      }
    }

    this.filterContext = context;
    this.filterCampaignAccordions();
    this.filterCampaignViewColumns();
  }

  private unsetDimensionCampaigns() {
    this.campaignQueue.flush();
    this.tableDataService
      .getStore(this.keyForActiveTabAndDimension)
      ?.resetDatabase()
      .catch(console.error);
    this.activeAccordionIndex = null;
    this.previousAccordionIndex = null;
  }

  public handleImageError(event: Event, campaignCount: ICampaignsCount): void {
    const img = event.target as HTMLImageElement;
    if (!img.src.includes('.png')) {
      img.src = `/assets/images/integrations/${campaignCount.dimensionLogoStr?.toLowerCase()}-logo.png`;
    } else {
      img.src = `/assets/images/integrations/default-datasource.svg`;
    }
  }

  refreshCampaignList(activeIndex: number | null, forceRefresh = false): void {
    this.tableLoading.next(true);
    this.cancelDimensionMapping();
    if (
      this.requestInProgress ||
      activeIndex === null ||
      this.previousAccordionIndex === activeIndex
    ) {
      return;
    }

    this.activeAccordionIndex = activeIndex;

    if (
      this.previousAccordionIndex !== null ||
      !isEmpty(this.campaignQueue.queue)
    ) {
      this.previousAccordionIndex = activeIndex;
      this.campaignQueue.flush();
    }

    this.activeAccordionDimensionValue =
      this.getActiveDimensionCampaignCount(activeIndex)?.dimension || '';
    this.loadCampaignsWithLazyLoading(forceRefresh);
  }

  private fetchAndPopulateCampaignsTable() {
    this.requestInProgress = true;
    this.tableDataService
      .getStore(this.keyForActiveTabAndDimension)
      .resetDatabase()
      .then(() => {
        this.campaignQueue.flush();
        this.fetchAndEnrichCampaigns();
      });
  }

  private repopulateCampaignsTable() {
    this.tableDataService
      .getCampaignsWithFilter(this.keyForActiveTabAndDimension, {
        pageNumber: this.activeDimensionPageNumber,
        filterCriteria: TaxonomyHelper.prepareGetCampaignFilterCriteria(
          this.filterStateByTab[
            this.getColumnFilterStateKey()
          ] as IColumnFilterContext,
          this.selectedMappedCampaign
        )
      })
      .then(({ campaigns }) => {
        this.campaignQueue.flush();
        this.campaignQueue.addLast(campaigns);
        this.tableLoading.next(false);
      });

    this.prepareCampaignTableColumnsByDimension().catch(console.error);
  }

  private fetchAndEnrichCampaigns() {
    this.tableLoading.next(true);

    const campaignRequest = {
      clientId: this.clientId,
      brandId: this.brandId,
      taxonomyTab: this.activeTaxonomyTab,
      dimensionValue: this.activeAccordionDimensionValue,
      offset: 0,
      limit: this.pageSize,
      dateRange: this.selectedDateRange,
      groupByDimension: this.getDimensionByActiveTab()
    };

    const filterCriteria = TaxonomyHelper.prepareGetCampaignFilterCriteria(
      this.filterStateByTab[
        this.getColumnFilterStateKey()
      ] as IColumnFilterContext,
      this.selectedMappedCampaign
    );

    this.campaignViewService
      .getCampaignsByDimension(
        campaignRequest.clientId,
        campaignRequest.brandId,
        campaignRequest.taxonomyTab,
        campaignRequest.dimensionValue,
        filterCriteria,
        campaignRequest.offset,
        campaignRequest.limit,
        campaignRequest.dateRange,
        campaignRequest.groupByDimension as TaxonomyDimension
      )
      .then((campaigns) => {
        const enrichedCampaigns = this.enrichCampaignsWithMetadata(campaigns);

        this.tableDataService
          .populateDataStore(
            this.keyForActiveTabAndDimension,
            enrichedCampaigns
          )
          .catch(console.error);

        this.prepareCampaignTableColumnsByDimension().catch(console.error);
        this.tableDataService
          .getCampaignsWithFilter(this.keyForActiveTabAndDimension, {
            pageNumber: this.activeDimensionPageNumber
          })
          .then(({ campaigns: filteredCampaigns }) => {
            this.campaignQueue.addLast(filteredCampaigns);
            this.tableLoading.next(false);
          });
      })
      .catch((error) => {
        console.error('Error occured while fetching campaigns', error);
        this.tableLoading.next(false);
      })
      .finally(() => {
        this.requestInProgress = false;
      });
  }

  private getDimensionByActiveTab() {
    return this.activeTaxonomyTab === TaxonomyTab.underProcessing &&
      this.filterContext.groupByDimension !==
        TaxonomyDimension.compositeDataSourceName
      ? camelCase(`operational_${this.filterContext.groupByDimension}`)
      : this.filterContext.groupByDimension;
  }

  private enrichCampaignsWithMetadata(campaigns: IVCampaign[]) {
    const nonMultipleTacticCampaigns =
      this.filterOutMultipleTacticsCampaigns(campaigns);

    return nonMultipleTacticCampaigns.map((campaign) => {
      const enrichedCampaign = TaxonomyHelper.enrichCampaign(
        campaign,
        this.timezone
      );

      if (this.allCampaignsSelected) {
        enrichedCampaign.isSelected = true;
      }

      enrichedCampaign.dimensionKey = this.keyForActiveTabAndDimension;
      enrichedCampaign.geoTestStatus =
        this.geoTestStatusByDimension[
          campaign.operationalTactic || campaign.tactic
        ];

      return enrichedCampaign;
    });
  }

  private getCampaignCount(setActiveAccordionIndex = false): void {
    this.loading.next(true);
    this.campaignViewService
      .getCampaignCountWithFilter(
        this.selectedDateRange,
        this.filterContext.groupByDimension,
        this.activeAccordionDimensionValue,
        undefined,
        []
      )
      .then((campaignCountsByType) => {
        this.campaignCountsByType =
          TaxonomyHelper.enrichCampaignCountsData(campaignCountsByType);

        this.filterCampaignAccordions();
        this.enrichTabPanelHeaders();

        this.loading.next(false);
        this.cdr.detectChanges();

        if (setActiveAccordionIndex) {
          this.activeAccordionIndex = this.getActiveAccordionIndex();
        }
      })
      .catch(() => {
        this.loading.next(false);
        this.cdr.detectChanges();
      });

    this.getInReviewCampaigns();
  }

  private enrichTabPanelHeaders() {
    this.tabPanelHeaders = this.campaignViewService.setTotalCountForTabs(
      this.campaignCountsByType
    );
  }

  private getInReviewCampaigns() {
    this.apiService.taxonomyApisOperations
      .getReviewNPublishedCampaigns(this.clientId, this.brandId, {
        status: 'DRAFTED',
        offset: 0,
        filterCriteria: []
      })
      .then((inReviewCampaigns) => {
        this.inReviewCampaigns = inReviewCampaigns;
        this.undoCampaigns =
          TaxonomyHelper.getReviewCampaignsByUnmappedCampaigns(
            this.undoCampaigns,
            inReviewCampaigns,
            this.campaignViewColumns.slice(0, 2).map((column) => column.field)
          );
        if (this.undoCampaigns.length && this.reviewNavigationButtons[0]) {
          this.reviewNavigationButtons[0].disabled = false;
        }
        if (!this.showCampaignsToReview) {
          this.prepareBottomPanelActionButtonsForClosedState();
        } else {
          this.showCampaignsToReview = this.inReviewCampaigns?.length > 0;

          if (!this.showCampaignsToReview) {
            this.prepareBottomPanelActionButtonsForClosedState();
          } else {
            this.prepareBottomPanelActionButtonsForOpenState();
          }
        }
      });
  }

  private prepareReviewActionButtons(inReviewCampaigns: IVCampaign[]) {
    const openReviewButton = Constants.reviewActionButtons.find(
      (button) => button.label === 'Review'
    );
    return [
      {
        ...openReviewButton,
        label: `Review all ${
          inReviewCampaigns.length
        } ${TaxonomyHelper.getCampaignString(inReviewCampaigns.length)}`
      }
    ] as IBottomPanelButtonConfig[];
  }

  handleReviewAction(event: {
    action: ReviewAction;
    payload: Record<string, string | null | undefined>;
  }) {
    this.removeUnpublishedCampaignsPayload = event.payload;
    switch (event.action) {
      case ReviewAction.openReview:
        this.showCampaignsToReview = true;
        this.prepareBottomPanelActionButtonsForOpenState();
        break;
      case ReviewAction.submitReview:
        this.resetCampaignsQueueForInProgress();
        this.submitInReviewCampaigns();
        break;
      case ReviewAction.closeReview:
        this.showCampaignsToReview = false;
        this.showCampaignMappingOperationDialog = false;
        this.prepareBottomPanelActionButtonsForClosedState();
        break;
      case ReviewAction.removeAll:
      case ReviewAction.removeWithFilters:
        this.unmapCampaignsConfirmationMessage =
          this.prepareCancelUnpublishedCampaignsMessage(
            event.action,
            event.payload
          );
        this.showCampaignMappingOperationDialog = true;
        break;
      case ReviewAction.undo:
        this.undoRecentUnPublishedCampaigns(event.action);
        break;
      default:
        this.reviewActionButtons = this.prepareReviewActionButtons(
          this.inReviewCampaigns
        );
        this.reviewNavigationButtons = [];
        break;
    }
  }

  private resetCampaignsQueueForInProgress() {
    if (this.activeTaxonomyTab === TaxonomyTab.underProcessing) {
      this.tableDataService
        .reset(
          `${this.clientId}-${this.brandId}-${TaxonomyTab.underProcessing}`
        )
        .then(() => {
          this.campaignQueue.flush();
          this.activeDimensionPageNumber = 0;
          this.activeAccordionDimensionValue = '';
          this.activeAccordionIndex = null;
          this.cdr.detectChanges();
        })
        .catch(console.error);
    }
  }

  private undoRecentUnPublishedCampaigns(action: ReviewAction.undo) {
    if (!this.undoCampaigns.length) {
      return;
    }

    const criteria = TaxonomyHelper.prepareFilterCriteriaWithRecordId(
      this.undoCampaigns
    );
    this.removeUnpublishedCampaignsPayload = {
      groupByDimension: Constants.tempRecordId,
      dimensionValue: criteria[0]?.value
    };
    this.unmapCampaignsConfirmationMessage =
      this.prepareCancelUnpublishedCampaignsMessage(
        action,
        this.removeUnpublishedCampaignsPayload
      );
    this.showCampaignMappingOperationDialog = true;
  }

  private prepareBottomPanelActionButtonsForOpenState() {
    this.reviewActionButtons =
      this.prepareReviewOpenActionButtons() as IBottomPanelButtonConfig[];
    this.reviewNavigationButtons = Constants.reviewOpenNavigationButtons;
    this.reviewUndoMessage.message = '';
  }

  private prepareBottomPanelActionButtonsForClosedState() {
    this.reviewActionButtons = this.prepareReviewActionButtons(
      this.inReviewCampaigns
    );
    if (!this.undoCampaigns.length) {
      this.reviewNavigationButtons = [];
    }
  }

  private prepareReviewOpenActionButtons() {
    return [
      {
        ...Constants.reviewOpenActionButtons[0],
        label: `Submit ${
          this.inReviewCampaigns.length
        } ${TaxonomyHelper.getCampaignString(this.inReviewCampaigns.length)}`
      },
      {
        ...Constants.reviewOpenActionButtons[1],
        label: `Go back`
      }
    ];
  }

  private prepareReviewNavigationButtonsWithUndo() {
    this.reviewNavigationButtons = Constants.reviewCloseNavigationButtons;
  }

  private async prepareCampaignTableColumnsByDimension(): Promise<void> {
    this.campaignViewColumns =
      await this.campaignViewService.getDimensionsByDatasourceOrGroupByCampaignDimension(
        this.filterContext.groupByDimension,
        this.activeAccordionDimensionValue,
        this.activeTaxonomyTab
      );

    this.filterCampaignViewColumns();

    if (this.activeTaxonomyTab !== TaxonomyTab.unmapped) {
      this.addActionsColumn();
      TaxonomyHelper.handleSpendColumnVisibility(this.campaignViewColumns);
      this.updateTaxonomyColumnFields();
    }

    this.filteredCampaignViewColumns = cloneDeep(this.campaignViewColumns);
  }

  private addActionsColumn() {
    if (
      this.campaignViewColumns.findIndex(
        (column) => column.field === 'actions'
      ) < 0
    ) {
      this.campaignViewColumns.push({
        field: 'actions',
        type: 'actions',
        header: '',
        width: '4rem !important'
      });
    }
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  async updateCampaignSelection($event: any, campaign: IVCampaign) {
    if (this.selectionInProgress) {
      return;
    }

    this.selectionInProgress = true;

    if ($event.checked) {
      await this.tableDataService.selectCampaign(
        this.keyForActiveTabAndDimension,
        campaign.tempRecordId
      );

      this.campaignQueue.queue
        .filter(
          (campaignInQueue) =>
            campaignInQueue.tempRecordId === campaign.tempRecordId
        )
        .forEach((campaignInQueue) => (campaignInQueue.isSelected = true));

      this.selectedCampaigns.push(campaign);
      this.selectionInProgress = false;

      this.showUpdateMappingOverlay = true;
    } else {
      await this.tableDataService.unselectCampaign(
        this.keyForActiveTabAndDimension,
        campaign.tempRecordId
      );

      this.campaignQueue.queue
        .filter(
          (campaignInQueue) =>
            campaignInQueue.tempRecordId === campaign.tempRecordId
        )
        .forEach((campaignInQueue) => (campaignInQueue.isSelected = false));

      const index = this.selectedCampaigns.indexOf(campaign);
      this.selectedCampaigns.splice(index, 1);
      this.selectionInProgress = false;

      this.showUpdateMappingOverlay =
        this.campaignQueue.queue.filter(
          (campaignInQueue) => campaignInQueue.isSelected
        ).length > 0;
    }

    this.cdr.detectChanges();

    this.selectedCampaigns = uniq(this.selectedCampaigns);
    this.allCampaignsSelected =
      this.selectedCampaigns.length > 0 &&
      this.selectedCampaigns.length ===
        (this.getActiveDimensionCampaignCount()?.count || 0);
  }

  cancelRemoveCampaignsOperation() {
    this.showCampaignMappingOperationDialog = false;
    this.selectedMappedCampaign = null;
    this.allCampaignsSelected = false;
    this.unmapCampaignsConfirmationMessage = '';
  }

  submitInReviewCampaigns() {
    this.loaderMessage = this.getLoaderMessageForSubmit();
    this.loaderUiView = ViewType.loader;
    this.loaderTitle = `We're on it!`;
    this.globalLoading.next(true);
    this.reviewCampaignsService
      .submitInReviewCampaigns(this.clientId, this.brandId)
      .then(() => {
        this.globalLoading.next(false);
        this.toastMessageService.showSuccess(
          'Your campaigns have now been moved to the In Progress mapping state. Please allow 2-3 hours for changes to take effect.',
          `${this.inReviewCampaigns.length} campaigns successfully submitted for mapping`
        );
        this.handleReviewAction({
          action: ReviewAction.closeReview,
          payload: {}
        });
        this.activeTaxonomyTab = TaxonomyTab.underProcessing;
        this.getCampaignCount();
      })
      .catch(() => {
        this.globalLoading.next(false);
        this.toastMessageService.showError(
          `Something went wrong!`,
          'We could not publish your changes. Please try again, or contact your customer success partner for assistance.'
        );
      });
  }

  cancelDimensionMapping() {
    this.selectedCampaigns = [];
    this.allCampaignsSelected = false;
    this.pageCampaignsSelected = false;
    this.selectionInProgress = true;
    this.activeDimensionPageNumber = 0;

    this.tableDataService
      .unselectAll(this.keyForActiveTabAndDimension)
      .then(() => {
        this.selectionInProgress = false;
        this.cdr.detectChanges();
      })
      .catch(console.error);

    this.campaignQueue.queue.forEach(
      (campaign) => (campaign.isSelected = false)
    );

    this.showUpdateMappingOverlay = false;
  }

  selectAllCampaignsInDimension($event: { checked: boolean }) {
    if (this.activeTaxonomyTab === TaxonomyTab.mapped) {
      this.selectedDimensionOptions =
        this.campaignViewService.prepareSelectedCampaignDimensionsValues(
          this.selectedCampaigns
        );
    }
    this.allCampaignsSelected = $event.checked;

    if (this.allCampaignsSelected) {
      this.setAllCampaignsAsSelected();
    } else {
      this.cancelDimensionMapping();
    }
  }

  private filterOutMultipleTacticsCampaigns(campaigns: IVCampaign[]) {
    return campaigns.filter(
      (campaign) => campaign.tactic !== TaxonomyUiConstants.multipleTactics
    );
  }

  private filterCampaignAccordions() {
    if (
      this.filterContext.dimensionFilter &&
      !isEmpty(this.filterContext.dimensionFilter)
    ) {
      this.campaignAccordions = (
        this.campaignCountsByType[this.activeTaxonomyTab]?.metrics || []
      )
        .filter((metric) =>
          metric.dimension
            ?.toLowerCase()
            .includes((this.filterContext.dimensionFilter || '').toLowerCase())
        )
        .map((metric) => {
          metric.isFilterApplied = !isEmpty(
            this.filterStateByTab[
              this.getColumnFilterStateKey(metric.dimension)
            ]?.filterCriteria
          );
          return metric;
        });
    } else {
      this.campaignAccordions =
        this.campaignCountsByType[this.activeTaxonomyTab]?.metrics || [];
    }
  }

  private filterCampaignViewColumns() {
    if (this.filterContext.tableColumns?.length) {
      this.filteredCampaignViewColumns = this.campaignViewColumns.filter(
        (column) => this.filterContext.tableColumns?.includes(column.field)
      );
    } else {
      this.filteredCampaignViewColumns = this.campaignViewColumns;
    }
  }

  private getActiveDimensionCampaignCount(
    accordionIndex?: number
  ): ICampaignsCount | null {
    const index = isNil(accordionIndex)
      ? this.getActiveAccordionIndex()
      : accordionIndex;

    return index >= 0
      ? this.campaignCountsByType[this.activeTaxonomyTab]?.metrics[index] ||
          null
      : null;
  }

  private getActiveAccordionIndex(): number {
    const index = this.campaignCountsByType[
      this.activeTaxonomyTab
    ]?.metrics.findIndex(
      (metric) => metric.dimension === this.activeAccordionDimensionValue
    );
    return index && index >= 0 ? index : 0;
  }

  public updateCampaignsMapping(
    updateDimensionValues: Record<CampaignDimension, string>
  ): void {
    const postBody = this.prepareUpdateCampaignBody(updateDimensionValues);
    this.globalLoading.next(true);
    this.loaderUiView = ViewType.loader;
    this.loaderTitle = `We're on it!`;
    this.loaderMessage = this.getLoaderMessageForUpdate();

    this.apiService.taxonomyApisOperations
      .updateCampaignsMapping(this.clientId, this.brandId, postBody)
      .then(async () => {
        this.toastMessageService.showSuccess(
          `${this.getSelectedCampaignCount().toLocaleString()} ${TaxonomyHelper.getCampaignString(
            this.getSelectedCampaignCount()
          )} changes saved in Draft mode. Please click on 'Review Changes' at the top of the page to confirm correctness.`
        );

        this.undoCampaigns = [...this.selectedCampaigns];

        this.reviewUndoMessage = {
          message: `${this.getSelectedCampaignCount()} ${TaxonomyHelper.getCampaignString(
            this.getSelectedCampaignCount()
          )} added for review`,
          messageType: BottomPanelMessageType.success
        };

        this.prepareReviewNavigationButtonsWithUndo();
        this.filterStateByTab[this.getColumnFilterStateKey()] =
          this.getDefaultColumnFilterState(this.activeAccordionDimensionValue);

        setTimeout(() => {
          this.reviewUndoMessage.message = '';
        }, 10000);

        this.getCampaignCount(true);
        this.selectedCampaigns = [];
        await this.tableDataService.reset(this.keyForActiveTabAndDimension);
        await this.tableDataService.reset(
          `${this.clientId}-${this.brandId}-${TaxonomyTab.underProcessing}`
        );
        this.refreshCampaignList(this.getActiveAccordionIndex(), true);
      })
      .catch((error) => {
        console.error('Error : ', error);
        this.toastMessageService.showError(
          'Something went wrong!',
          'Failed to update campaigns'
        );
      })
      .finally(() => {
        this.globalLoading.next(false);
        this.loaderUiView = ViewType.empty;
        this.loaderTitle = '';
        this.loaderMessage = '';
      });
  }

  private getSelectedCampaignCount() {
    let campaignsCount = this.selectedCampaigns.length;
    if (this.allCampaignsSelected) {
      campaignsCount = this.getActiveDimensionCampaignCount()?.count || 0;
    }
    return campaignsCount;
  }

  private prepareUpdateCampaignBody(
    updateDimensionValues: Record<CampaignDimension, string>
  ): IUpdateCampaignWithFilterCriteria {
    const body = {
      campaignType: this.activeTaxonomyTab as unknown as CampaignType,
      ...updateDimensionValues,
      brandId: this.brandId,
      filterCriteria: this.getFilterCriteria(),
      groupByDimension: this.filterContext.groupByDimension,
      dimensionValue: this.activeAccordionDimensionValue
    } as IUpdateCampaignWithFilterCriteria;

    if (
      this.filterContext.groupByDimension ===
      TaxonomyDimension.compositeDataSourceName
    ) {
      body.dataSourceId = this.activeAccordionDimensionValue;
    }

    return body;
  }

  private getLoaderMessageForUpdate(): string {
    return `Currently mapping ${this.getSelectedCampaignCount().toLocaleString()} ${TaxonomyHelper.getCampaignString(
      this.selectedCampaigns.length
    )}`;
  }

  private getLoaderMessageForSubmit(): string {
    return `Submitting ${this.inReviewCampaigns.length.toLocaleString()} ${TaxonomyHelper.getCampaignString(
      this.inReviewCampaigns.length
    )} mapping`;
  }

  private getFilterCriteria(): IFilterCriteria[] {
    if (!this.allCampaignsSelected || this.isFilterContextApplied()) {
      return TaxonomyHelper.prepareFilterCriteriaWithRecordId(
        this.selectedCampaigns
      );
    }
    return this.prepareFilterCriteriaForSelectAllCampaigns();
  }

  private prepareFilterCriteriaForSelectAllCampaigns(): IFilterCriteria[] {
    const filterCriteria: IFilterCriteria[] = [];
    const filter = omitBy(this.dimensionSearchCriteria, isNil);
    const dimensions = Object.keys(filter);
    filterCriteria.push({
      fieldName: this.filterContext.groupByDimension,
      value: this.activeAccordionDimensionValue,
      filter: FiltersType.EQUALS
    });

    if (dimensions.length) {
      dimensions.forEach((dimension) => {
        filterCriteria.push({
          fieldName: dimension,
          value: filter[dimension] || '',
          filter: FiltersType.CONTAINS
        });
      });
    }

    return filterCriteria;
  }

  unmapCampaignsWithFilter() {
    this.showCampaignMappingOperationDialog = false;
    this.globalLoading.next(true);

    if (this.selectedMappedCampaign || this.allCampaignsSelected) {
      this.resetCampaignsQueueForInProgress();
      this.unmapMappedCampaign();
    } else {
      this.cancelUnpublishedCampaignChanges();
    }
  }

  private cancelUnpublishedCampaignChanges() {
    const removeAllUnpublishedCampaigns = isEmpty(
      this.removeUnpublishedCampaignsPayload
    );

    this.loaderMessage = `Removing campaign(s) from Review and Submit screen`;
    this.loaderTitle = 'We are on it!';
    this.loaderUiView = ViewType.loader;

    let groupByDimension =
      this.removeUnpublishedCampaignsPayload['groupByDimension'];

    if (
      ![
        TaxonomyDimension.compositeDataSourceName,
        Constants.tempRecordId
      ].includes(groupByDimension as string) &&
      isEmpty(this.removeUnpublishedCampaignsPayload['campaignName'])
    ) {
      groupByDimension = camelCase(`drafted_${groupByDimension}`);
    }

    const cancelUnpublishedCampaignsRequest: ICancelUnpublishedCampaignChanges =
      {
        filterCriteria: removeAllUnpublishedCampaigns
          ? []
          : [
              {
                fieldName: `${groupByDimension}`,
                value: `${this.removeUnpublishedCampaignsPayload['dimensionValue']}`,
                filter:
                  !isEmpty(
                    this.removeUnpublishedCampaignsPayload['campaignName']
                  ) || groupByDimension === Constants.tempRecordId
                    ? FiltersType.IN
                    : FiltersType.EQUALS
              }
            ]
      };

    this.apiService.taxonomyApisOperations
      .cancelUnpublishedCampaignChanges(
        this.clientId,
        this.brandId,
        cancelUnpublishedCampaignsRequest
      )
      .then(() => {
        this.loaderTitle = 'Success!';
        this.loaderUiView = ViewType.success;
        this.loaderMessage = `Successfully removed campaigns from Review and Submit screen`;
        this.undoCampaigns = [];
        this.reviewUndoMessage.message = '';
        this.tableDataService.reset(this.keyForActiveTabAndDimension);
        this.getCampaignCount();
        this.activeAccordionDimensionValue = '';
        this.activeAccordionIndex = -1;
        this.campaignQueue.flush();
      })
      .catch((err) => {
        console.error('Error : ', err);
        this.loaderMessage = 'Failed to remove campaigns';
        this.loaderUiView = ViewType.error;
      })
      .finally(() => {
        setTimeout(
          () => this.globalLoading.next(false),
          TaxonomyUiConstants.waitTimeInMilliseconds
        );
      });
  }

  private prepareCancelUnpublishedCampaignsMessage(
    action:
      | ReviewAction.removeAll
      | ReviewAction.removeWithFilters
      | ReviewAction.undo,
    payload: Record<
      string,
      string | number | string[] | number[] | null | undefined
    >
  ) {
    if (action === ReviewAction.removeAll) {
      return 'This will remove <strong>all</strong> campaigns from this review and submit screen. They will be reverted back to the unmapped state';
    }
    if (
      payload['groupByDimension'] === TaxonomyDimension.compositeDataSourceName
    ) {
      return `This will remove all campaigns from <strong>${payload['dimensionValue']}</strong> group from this review and submit screen. They will be reverted back to the unmapped state`;
    }
    if (action === ReviewAction.undo) {
      return `This will undo the last campaign mapping operation. It will revert those campaigns back under the unmapped section`;
    }

    return `This will remove the campaign <strong>${
      payload['campaignName'] || ''
    }</strong> from this review and submit screen. It will be reverted back to the unmapped state`;
  }

  setUnmapCampaignAction(campaign: IVCampaign) {
    this.selectedMappedCampaign = campaign;
    this.isUnmapCampaignAction = true;
  }

  clearUnmapCampaignAction() {
    setTimeout(() => {
      if (!this.showCampaignMappingOperationDialog) {
        this.isUnmapCampaignAction = false;
        this.selectedMappedCampaign = null;
      }
    }, CampaignsViewComponent.delayForClearingSelectedCampaignInMillis);
  }

  private unmapMappedCampaign() {
    this.loaderTitle = `We're on it!`;
    this.loaderMessage = `Removing tactic mapping from the campaign`;
    this.loaderUiView = ViewType.loader;

    const unmapCampaignRequest: IUpdateCampaignWithFilterCriteria = {
      campaignType:
        this.activeTaxonomyTab === TaxonomyTab.mapped
          ? CampaignType.MAPPED_CAMPAIGN
          : CampaignType.UNDER_PROCESSING_CAMPAIGN,
      channel: TaxonomyUiConstants.unmappedValue,
      tactic: TaxonomyUiConstants.unmappedValue,
      segment: TaxonomyUiConstants.unmappedValue,
      groupByDimension:
        this.filterContext.groupByDimension ===
        TaxonomyDimension.compositeDataSourceName
          ? TaxonomyDimension.compositeDataSourceName
          : this.activeTaxonomyTab === TaxonomyTab.mapped
            ? this.filterContext.groupByDimension
            : camelCase('operational_' + this.filterContext.groupByDimension),
      dimensionValue: this.activeAccordionDimensionValue,
      filterCriteria: this.getFilterCriteriaForUnmapInProgressCampaigns()
    };

    this.apiService.taxonomyApisOperations
      .revertPublishedCampaignChanges(
        this.clientId,
        this.brandId,
        unmapCampaignRequest
      )
      .then(() => {
        this.loaderTitle = 'Success!';
        this.loaderUiView = ViewType.success;
        this.loaderMessage = `Successfully unmapped selected campaign`;
        this.allCampaignsSelected = false;
        this.getCampaignCount();
        this.filterStateByTab[this.getColumnFilterStateKey()] =
          this.getDefaultColumnFilterState(this.activeAccordionDimensionValue);
        this.tableDataService
          .reset(this.keyForActiveTabAndDimension)
          .catch(console.error);
        this.refreshCampaignList(this.getActiveAccordionIndex());
        this.activeTaxonomyTab = TaxonomyTab.unmapped;
        this.previousAccordionIndex = null;
        this.tableDataService
          .reset(this.keyForActiveTabAndDimension)
          .catch(console.error);
      })
      .catch((err) => {
        console.error('Error : ', err);
        this.loaderMessage = 'Oops! Failed to unmap campaign';
        this.loaderUiView = ViewType.error;
      })
      .finally(() => {
        setTimeout(
          () => this.globalLoading.next(false),
          TaxonomyUiConstants.waitTimeInMilliseconds
        );
      });
  }

  private getFilterCriteriaForUnmapInProgressCampaigns(): IFilterCriteria[] {
    if (this.isRequestToUnmapAllCampaignsWithDimensionSearchCriteria()) {
      return TaxonomyHelper.prepareGetCampaignFilterCriteria(
        this.filterStateByTab[
          this.getColumnFilterStateKey()
        ] as IColumnFilterContext,
        this.selectedMappedCampaign
      );
    }

    if (this.isRequestToUnmapAllCampaignsInGroupByDimension()) {
      return [
        {
          fieldName:
            this.filterContext.groupByDimension ===
            TaxonomyDimension.compositeDataSourceName
              ? TaxonomyDimension.compositeDataSourceName
              : this.activeTaxonomyTab === TaxonomyTab.mapped
                ? this.filterContext.groupByDimension
                : camelCase(
                    'operational_' + this.filterContext.groupByDimension
                  ),
          value: this.activeAccordionDimensionValue,
          filter: FiltersType.EQUALS
        }
      ];
    }

    return TaxonomyHelper.prepareSelectedMappedCampaignCriteria(
      this.selectedMappedCampaign
    );
  }

  private isRequestToUnmapAllCampaignsInGroupByDimension() {
    return !this.selectedMappedCampaign && this.allCampaignsSelected;
  }

  private isRequestToUnmapAllCampaignsWithDimensionSearchCriteria() {
    return !isEmpty(this.dimensionSearchCriteria) || this.allCampaignsSelected;
  }

  private updateTaxonomyColumnFields() {
    if (this.activeTaxonomyTab === TaxonomyTab.underProcessing) {
      this.campaignViewColumns = cloneDeep(this.campaignViewColumns).map(
        (column) => {
          if (['tactic', 'channel', 'segment'].includes(column.field)) {
            column.field = camelCase(`operational_${column.field}`);
          }
          return column;
        }
      );
    }
  }

  updateCampaignsWithFilter(dimensionKey: string, dimensionValue: IFilterData) {
    if (isNil(this.filterStateByTab[this.getColumnFilterStateKey()])) {
      this.filterStateByTab[this.getColumnFilterStateKey()] =
        this.getDefaultColumnFilterState(this.activeAccordionDimensionValue);
    }

    const previousFilter =
      this.filterStateByTab[this.getColumnFilterStateKey()]?.filterCriteria[
        dimensionKey
      ];
    if (previousFilter === dimensionValue) {
      return;
    }
    (
      this.filterStateByTab[
        this.getColumnFilterStateKey()
      ] as IColumnFilterContext
    ).filterCriteria[dimensionKey] = dimensionValue;
    this.repopulateCampaignsFromIndexDB();
  }

  private repopulateCampaignsFromIndexDB(sorting = false) {
    this.tableLoading.next(true);
    this.activeDimensionPageNumber = 0;
    this.campaignQueue.flush();

    const promises = [this.getCampaignsWithFilterFromIndexDB()];

    if (!sorting) {
      promises.push(this.getCampaignsCountWithFilterFromIndexDb());
    }
    Promise.all(promises).catch(console.error);
  }

  private async getCampaignsCountWithFilterFromIndexDb() {
    return this.tableDataService
      .getCampaignsCountWithFilter(this.keyForActiveTabAndDimension, {
        filterCriteria: TaxonomyHelper.prepareGetCampaignFilterCriteria(
          this.filterStateByTab[
            this.getColumnFilterStateKey()
          ] as IColumnFilterContext,
          this.selectedMappedCampaign
        )
      })
      .then((count: number) => {
        this.campaignCountsByType[this.activeTaxonomyTab]?.metrics.forEach(
          (accordion) => {
            if (accordion.dimension === this.activeAccordionDimensionValue) {
              accordion.count = count;
              accordion.isFilterApplied = this.isFilterContextApplied();
            }
          }
        );

        (
          this.filterStateByTab[
            this.getColumnFilterStateKey()
          ] as IColumnFilterContext
        ).totalCount = this.getActiveDimensionCampaignCount()?.count || 0;

        this.filterCampaignAccordions();
        this.enrichTabPanelHeaders();
      })
      .catch(console.error);
  }

  getCampaignsWithFilterFromIndexDB() {
    return this.tableDataService
      .getCampaignsWithFilter(this.keyForActiveTabAndDimension, {
        pageNumber: this.activeDimensionPageNumber,
        filterCriteria: TaxonomyHelper.prepareGetCampaignFilterCriteria(
          this.filterStateByTab[
            this.getColumnFilterStateKey()
          ] as IColumnFilterContext,
          this.selectedMappedCampaign
        ),
        ...this.sort
      })
      .then(({ storeName, campaigns }) => {
        if (storeName === this.keyForActiveTabAndDimension) {
          this.campaignQueue.addLast(campaigns);
        }
        this.tableLoading.next(false);
      })
      .catch(console.error);
  }

  unmapAllInProgressCampaigns(event: MenuItemCommandEvent) {
    this.allCampaignsSelected = true;
    this.mappedCampaignActions
      .find((action) => action.label?.includes('Unmap Campaign'))
      ?.command?.(event);
  }

  getActiveTabIndex(): number {
    return Object.values(TaxonomyTab).indexOf(this.activeTaxonomyTab);
  }

  private initializeFilterState(activeAccordionDimensionValue?: string) {
    if (activeAccordionDimensionValue) {
      this.filterStateByTab[activeAccordionDimensionValue] = {
        filterCriteria: {},
        activeDimension: activeAccordionDimensionValue,
        activeTab: this.activeTaxonomyTab,
        totalCount: this.getActiveDimensionCampaignCount()?.count || 0,
        filteredCount: 0
      };
    } else {
      this.filterStateByTab = {};
    }
  }

  isFilterContextApplied() {
    return (
      Object.entries(
        this.filterStateByTab[this.getColumnFilterStateKey()]?.filterCriteria ||
          {}
      ).filter(
        ([dimension, dimensionValue]) =>
          !isEmpty(dimension) &&
          (!isEmpty(dimensionValue?.contain) ||
            !isEmpty(dimensionValue?.exclude))
      ).length > 0
    );
  }

  private getCampaignCountWithFilter() {
    this.tableLoading.next(true);
    this.campaignViewService
      .getCampaignCountWithFilter(
        this.selectedDateRange,
        this.filterContext.groupByDimension,
        this.activeAccordionDimensionValue,
        this.activeTaxonomyTab,
        TaxonomyHelper.prepareGetCampaignFilterCriteria(
          this.filterStateByTab[
            this.getColumnFilterStateKey()
          ] as IColumnFilterContext,
          this.selectedMappedCampaign
        )
      )
      .then((campaignCountsByType) => {
        const enrichedCampaignCount =
          TaxonomyHelper.enrichCampaignCountsData(campaignCountsByType);

        this.updateAccordionFilters(enrichedCampaignCount);
        this.filterCampaignAccordions();
        this.enrichTabPanelHeaders();
        this.tableLoading.next(false);

        this.cdr.detectChanges();
      })
      .catch(() => {
        this.tableLoading.next(false);
        this.cdr.detectChanges();
      });
  }

  private updateAccordionFilters(
    enrichedCampaignCount: Record<string, ICampaignsCountByDimension>
  ) {
    Object.entries(enrichedCampaignCount).forEach(([key, value]) => {
      const campaignCount = this.campaignCountsByType[key];
      if (campaignCount) {
        campaignCount.total = value.metrics.reduce(
          (total, metric2) => total + metric2.count,
          0
        );

        value.metrics.forEach((apiMetric) => {
          this.campaignCountsByType[key]?.metrics
            .filter((metric) => metric.dimension === apiMetric.dimension)
            .forEach((metric) => {
              metric.count = apiMetric.count;
              metric.spend = apiMetric.spend;
            });
        });
      }
    });
  }

  private setAllCampaignsAsSelected() {
    this.selectionInProgress = true;
    this.tableDataService
      .selectCampaignsWithFilter(
        this.keyForActiveTabAndDimension,
        TaxonomyHelper.prepareGetCampaignFilterCriteria(
          this.filterStateByTab[
            this.getColumnFilterStateKey()
          ] as IColumnFilterContext,
          this.selectedMappedCampaign
        )
      )
      .then((selectedCount) => {
        if (selectedCount) {
          this.tableDataService
            .getSelectedCampaignsWithFilter(this.keyForActiveTabAndDimension, {
              selected: true
            })
            .then((campaigns: IVCampaign[]) => {
              this.selectedCampaigns = campaigns;
              this.selectionInProgress = false;
              console.warn(
                'select campaigns count: ',
                this.selectedCampaigns.length
              );
            });
        } else {
          this.selectionInProgress = false;
        }
      });

    this.campaignQueue.queue.forEach(
      (campaign) => (campaign.isSelected = true)
    );

    this.showUpdateMappingOverlay = true;
  }

  private loadCampaignsWithLazyLoading(forceRefresh = false) {
    this.tableDataService
      .hasData(this.keyForActiveTabAndDimension)
      .then((hasData) => {
        if (hasData && !forceRefresh) {
          this.repopulateCampaignsTable();
        } else {
          this.tableDataService.createStore(this.keyForActiveTabAndDimension);
          this.fetchAndPopulateCampaignsTable();
        }
      });
  }

  loadMoreCampaignsFromIndexDB(next?: boolean, table?: Table) {
    if (table) {
      this.campaignTable = table;
    }
    if (!next) {
      if (this.activeDimensionPageNumber === 0) {
        return;
      }
      this.loadPrevious = Constants.loadingText;
      this.activeDimensionPageNumber -= 1;
      this.getPreviousPageDataFromIndexDB();
    } else {
      this.loadNext = Constants.loadingText;
      this.activeDimensionPageNumber += 1;
      this.getNextPageDataFromIndexDB();
    }
  }

  private getPreviousPageDataFromIndexDB() {
    this.tableDataService
      .getCampaignsWithFilter(this.keyForActiveTabAndDimension, {
        pageNumber: this.activeDimensionPageNumber
      })
      .then(({ campaigns }) => {
        this.requestInProgress = false;
        this.campaignQueue.addFirst(campaigns);
        this.loadPrevious = Constants.loadPreviousText;
        this.campaignTable.scrollTo({ top: 640 });
      });
  }

  private getNextPageDataFromIndexDB() {
    this.tableDataService
      .getCampaignsWithFilter(this.keyForActiveTabAndDimension, {
        pageNumber: this.activeDimensionPageNumber
      })
      .then(({ campaigns }) => {
        this.requestInProgress = false;
        this.loadNext = Constants.loadNextText;
        this.campaignQueue.addLast(campaigns);
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public handleCampaignSortChange(event: any) {
    if (isEqual(this.sort, event)) {
      return;
    }
    this.sort = {
      sortBy: this.filteredCampaignViewColumns.find(
        (header) => header.header === event.sortBy
      )?.field as string,
      sortOrder: event.sortOrder
    };
    this.repopulateCampaignsFromIndexDB(true);
  }

  public getColumnFilterState(header: { field: string }): IFilterData {
    return (
      this.filterStateByTab[this.getColumnFilterStateKey()]?.filterCriteria[
        header.field
      ] ?? cloneDeep(this.emptyFilterState)
    );
  }

  private getColumnFilterStateKey(activeAccordion?: string): string {
    return `${this.activeTaxonomyTab}-${activeAccordion ?? this.activeAccordionDimensionValue}`;
  }

  public getDefaultColumnFilterState(
    activeAccordionDimensionValue: string
  ): IColumnFilterContext {
    return cloneDeep({
      filterCriteria: {},
      activeDimension: activeAccordionDimensionValue,
      activeTab: this.activeTaxonomyTab,
      totalCount: this.getActiveDimensionCampaignCount()?.count || 0,
      filteredCount: 0
    });
  }
}
