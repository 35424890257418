import { Component, Input } from '@angular/core';
import { ITitle } from '@portal/app/shared/models/ITitle';

@Component({
  selector: 'portal-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent {
  @Input() titleConfigs: ITitle[] = [];
}
