<div>
  <portal-portfolio-header></portal-portfolio-header>

  <div class="grid grid-cols-5 gap-4">
    <!-- Skeleton Loaders for Loading State -->
    <ng-container *ngIf="isLoading">
      <div *ngFor="let skeleton of [].constructor(5)">
        <div class="col-span-1 h-100">
          <m-skeleton height="150px" width="100%"></m-skeleton>
        </div>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="!isLoading">
    <!-- Dynamic Cards -->
    <div *ngIf="!showCarousel" class="grid grid-cols-4 2xl:grid-cols-5 gap-4">
      <ng-container *ngFor="let metric of metrics">
        <m-metric-card
          [metric]="metric"
          (cardClick)="toggleModal(metric)"></m-metric-card>
      </ng-container>
    </div>

    <!-- Card carousel -->
    <m-portfolio-carousel
      [value]="metrics"
      [responsiveOptions]="responsiveOptions"
      (click)="$event.stopPropagation()"
      (itemClick)="toggleModal($event)"
      *ngIf="showCarousel">
    </m-portfolio-carousel>
  </ng-container>
</div>

<m-modal
  [dismissableMask]="true"
  [(visible)]="isModalVisible"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  (onHide)="closeModal()"
  [style]="{ width: '1090px' }">
  <ng-container modalHeader>
    <div class="mb-4">
      <div class="flex items-center gap-4">
        <h4 class="h4 mb-0">{{ activeMetric?.title }}</h4>

        <div class="h4 mb-0 text-gray-400">|</div>
        <h4 class="h4 text-gray-1000 mb-0">
          {{ activeMetric?.formattedValue }}
        </h4>

        <m-tag
          [isPositive]="activeMetric?.isPositive"
          [isNeutral]="activeMetric?.isNeutral"
          [isUp]="activeMetric?.isUp"
          >{{
            activeMetric?.prevPercDifference / 100
              | absoluteValue
              | percent: "1.2-2"
          }}</m-tag
        >

        <m-type-pill
          *ngIf="activeMetric?.adPlatform"
          size="small"
          text="{{ activeMetric?.adPlatform }} (Ad Platform)"></m-type-pill>
      </div>

      <!-- Conditionally display the div only if the description exists -->
      <div
        *ngIf="
          activeMetric?.literalId && isValidDescription(activeMetric?.literalId)
        "
        class="text-gray-500 b2 flex mt-2">
        <p class="mb-0">
          {{ descriptionMap[activeMetric.literalId] }}
        </p>
        <!-- Link to the support article -->
        <a
          href="https://support.measured.com/article/h62nuemky4-mim-overview"
          target="_blank"
          rel="noopener noreferrer"
          class="no-underline ml-1 text-blue-600 hover:text-blue-600">
          Learn more about our methodology.
        </a>
      </div>
    </div>
  </ng-container>

  <!-- Charts -->
  <div class="grid grid-cols-3 gap-4 w-full h-full px-8">
    <portal-dimension-chart
      [activeMetric]="activeMetric"
      class="col-span-1"
      *ngIf="
        activeMetric?.literalId !== 'totalSales' &&
        activeMetric?.literalId !== 'totalOrders'
      "></portal-dimension-chart>
    <portal-compare-chart class="col-span-1"></portal-compare-chart>
    <portal-trending-chart
      [class.col-span-2]="
        activeMetric?.literalId === 'totalSales' ||
        activeMetric?.literalId === 'totalOrders'
      "
      [class.col-span-1]="
        activeMetric?.literalId !== 'totalSales' &&
        activeMetric?.literalId !== 'totalOrders'
      ">
      ></portal-trending-chart
    >
  </div>
</m-modal>
