import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonServicesModule } from '@libs/common-services';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { ChipModule } from 'primeng/chip';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TooltipModule } from 'primeng/tooltip';
import { AlertComponent } from './components/alert/alert.component';
import { EmptyTablePlaceholderComponent } from './components/empty-table-message/empty-table-placeholder.component';
import { HeaderComponent } from './components/header/header.component';
import { LoaderGlobalComponent } from './components/loader-global/loader-global.component';
import { LoaderInlineComponent } from './components/loader-inline/loader-inline.component';
import { TabHeaderComponent } from './components/tab-header/tab-header.component';
import { TitleComponent } from './components/title/title.component';
import { DesignSystemModule } from '@libs/design-system';

@NgModule({
  imports: [
    CommonModule,
    BadgeModule,
    ButtonModule,
    TooltipModule,
    DialogModule,
    ChipModule,
    ProgressSpinnerModule,
    FormsModule,
    CommonServicesModule,
    DesignSystemModule
  ],
  exports: [
    HeaderComponent,
    TabHeaderComponent,
    AlertComponent,
    TitleComponent,
    LoaderInlineComponent,
    LoaderGlobalComponent,
    EmptyTablePlaceholderComponent
  ],
  declarations: [
    HeaderComponent,
    TitleComponent,
    LoaderInlineComponent,
    TabHeaderComponent,
    AlertComponent,
    LoaderGlobalComponent,
    EmptyTablePlaceholderComponent
  ]
})
export class CommonComponentsModule {}
