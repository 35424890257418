import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'm-toast',
  templateUrl: './m-toast.component.html',
  styleUrls: ['./m-toast.component.scss']
})
export class MToastComponent {
  @Input() position = 'top-center';
  @Input() baseZIndex = 35000;

  @Output() actionClick = new EventEmitter<string>();

  navigateToContactSupportPage(): void {
    window.open('https://measured.atlassian.net/servicedesk/customer/portal/2');
  }

  refreshPage(): void {
    window.location.reload();
  }

  emitButtonAction(actionId: string) {
    this.actionClick.emit(actionId);
  }
}
