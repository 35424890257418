import {
  Component,
  Input,
  forwardRef,
  Output,
  EventEmitter,
  ElementRef,
  Renderer2,
  ChangeDetectorRef,
  NgZone
} from '@angular/core';
import { MultiSelect } from 'primeng/multiselect';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import Sortable from 'sortablejs';
import type { SortableEvent } from 'sortablejs';
import {
  FilterService,
  OverlayService,
  PrimeNGConfig,
  SelectItem
} from 'primeng/api';

@Component({
  selector: 'm-multiselect',
  templateUrl: './m-multiselect.component.html',
  styleUrls: ['./m-multiselect.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MMultiSelectComponent),
      multi: true
    }
  ]
})
export class MMultiSelectComponent
  extends MultiSelect
  implements ControlValueAccessor
{
  @Output() dragEnd = new EventEmitter<SelectItem[]>();

  @Input() isDraggable = false;
  @Input() width: string | undefined;
  @Input() height: string | undefined;
  @Input() isValid = true;

  constructor(
    el: ElementRef,
    renderer: Renderer2,
    cd: ChangeDetectorRef,
    zone: NgZone,
    filterService: FilterService,
    config: PrimeNGConfig,
    overlayService: OverlayService
  ) {
    super(el, renderer, cd, zone, filterService, config, overlayService);
  }

  // Handle
  handlePanelShow(): void {
    this.initSortable();

    this.preventDropdownCloseOnScroll();

    // Emit the event to support external handlers
    this.onPanelShow.emit();
  }

  private preventDropdownCloseOnScroll(): void {
    const modalContent = document.querySelector('.p-dialog-content');
    if (modalContent) {
      modalContent.addEventListener('scroll', this.stopScrollPropagation, true);
    }
  }

  private stopScrollPropagation = (event: Event) => {
    event.stopPropagation();
  };

  // Initialize sortable drag and drop
  initSortable() {
    if (!this.isDraggable) return;

    const container = document.querySelector('.p-multiselect-items');
    if (container) {
      new Sortable(container as HTMLElement, {
        animation: 100,
        delay: 100,
        draggable: 'p-multiselectitem',
        forceFallback: true,
        touchStartThreshold: 1,
        onClone: () => {
          container.classList.add('dragging-in-progress');
        },
        onEnd: (event: SortableEvent) => {
          const oldIndex = event.oldIndex ?? 0;
          const newIndex = event.newIndex ?? 0;

          this.reorderOptions(oldIndex, newIndex);

          container.classList.remove('dragging-in-progress');
          this.dragEnd.emit(this.options);
        }
      });
    }
  }

  // Assuming your options array consists of objects with 'label' and 'value' properties
  reorderOptions(oldIndex: number, newIndex: number): void {
    if (!this.options) {
      return;
    }
    const item = this.options.splice(oldIndex, 1)[0];
    this.options.splice(newIndex, 0, item);
  }
}
