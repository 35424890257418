<ng-container *ngIf="literalId === 'home-page'; else viewContainer">
  <portal-homepage></portal-homepage>
</ng-container>

<ng-template #viewContainer>
  <div class="view-container {{ literalId }} pb-4">
    <ng-container
      *ngIf="
        (isLoadingView$ | async) || loading;
        then spinnerTemplate;
        else contentTemplate
      ">
    </ng-container>

    <ng-template #contentTemplate>
      <ng-container
        *ngIf="
          (currentView$ | async).active === false;
          then viewNotAvailable;
          else viewTemplate
        ">
      </ng-container>
    </ng-template>

    <ng-template #viewTemplate>
      <portal-tab-bar (tabChanged)="tabChanged($event)"></portal-tab-bar>
      <div
        id="viewComponent"
        class="new-grid container-fluid gx-0 dashboard"
        [ngClass]="[
          nativeSectionsService.getClassByLiteralId(literalId),
          literalId,
          literalId === 'benchmarks-drill-down' ? '' : 'row',
        ]">
        <div
          *ngFor="let item of items"
          [ngSwitch]="item.layoutType"
          [ngClass]="[
            'new-view-item',
            'item',
            item.cssClass || '',
            'col-12',
            'col-lg-' + item.span,
            item.layoutType + '-parent',
            (item.layoutType === 'FILTER_SET' && item.displayOrder === 2) ||
            (item.layoutType === 'RESULT_HEADER' && item.displayOrder === 1)
              ? 'sticky-top'
              : '',
            item.span !== maxColsPerRow &&
            item.relativePosition !== null &&
            !item.relativePosition.includes(relativePosition.last)
              ? 'pe-lg-3 left-half'
              : '',
          ]">
          <div class="{{ item.layoutType }}">
            <!--            <div *ngIf="layoutConfig.dragEnabled" class="handle"></div>-->

            <ng-container
              *ngIf="
                !nativeSectionsService
                  .getWrapperComponentType()
                  .includes(item.layoutType)
              ">
              <portal-native-sections-template
                *ngIf="
                  !nativeSectionsService
                    .getWrapperComponentType()
                    .includes(item.layoutType)
                "
                [item]="item"
                [fieldDefinitions]="fieldDefinitions"
                [filters]="filters">
              </portal-native-sections-template>
            </ng-container>

            <div class="parent_component">
              <portal-tab
                *ngSwitchCase="'TAB_SECTION'"
                [tabGroup]="item"
                [dashboardLiteralId]="literalId"
                [fieldDefinitions]="fieldDefinitions"
                [filters]="filters">
              </portal-tab>

              <ng-container *ngIf="currentView$ | async as currentView">
                <portal-view-header
                  class="mt-10"
                  *ngSwitchCase="'VIEW_HEADER'"
                  [item]="item"
                  [fieldDefinitions]="fieldDefinitions"
                  [id]="currentView?.view?.storeId"
                  [view]="currentView?.view">
                </portal-view-header>
              </ng-container>

              <portal-context-bar
                *ngSwitchCase="'CONTEXT_BAR' || 'FILTER_SET'"
                [contextItem]="item"
                [fieldDefinitions]="fieldDefinitions"
                [filters]="filters">
              </portal-context-bar>
            </div>
            <div>
              <portal-accordion-section
                *ngSwitchCase="'ACCORDION_SECTION'"
                [accordionItems]="item"
                [fieldDefinitions]="fieldDefinitions"
                [filters]="filters">
              </portal-accordion-section>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #viewNotAvailable>
      <div class="centre-view-box flex-column">
        <p class="mb-2">
          This view is not available anymore. Switch to default view.
        </p>
        <button
          pButton
          type="button"
          class="w-auto p-button mx-2"
          label="Confirm"
          (click)="switchToDefaultView()"></button>
      </div>
    </ng-template>

    <ng-template #spinnerTemplate>
      <div class="full-page-loader">
        <p-progressSpinner></p-progressSpinner>
      </div>
    </ng-template>
  </div>
</ng-template>

<portal-context-channel-modal></portal-context-channel-modal>
<portal-context-tactic-modal></portal-context-tactic-modal>
