import { NgModule } from '@angular/core';
import { MenuModule } from 'primeng/menu';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InputTextModule } from 'primeng/inputtext';
import { RippleModule } from 'primeng/ripple';
import { BadgeModule } from 'primeng/badge';
import { TabViewModule } from 'primeng/tabview';

@NgModule({
  declarations: [],
  imports: [
    InputNumberModule,
    InputTextModule,
    DropdownModule,
    MenuModule,
    ButtonModule,
    TableModule,
    RippleModule,
    MultiSelectModule,
    ProgressSpinnerModule,
    TabViewModule,
    BadgeModule,
    ProgressSpinnerModule,
    TabViewModule
  ],
  exports: [
    InputNumberModule,
    InputTextModule,
    DropdownModule,
    MenuModule,
    ButtonModule,
    TableModule,
    RippleModule,
    MultiSelectModule,
    ProgressSpinnerModule,
    TabViewModule,
    BadgeModule,
    ProgressSpinnerModule,
    TabViewModule
  ]
})
export class PrimengModule {}
