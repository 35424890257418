import { Component, computed, inject, input } from '@angular/core';
import { CardVariant } from '@design-system/components/m-card';
import { NavigationService } from '@portal/app/shared/services/navigation.service';
@Component({
  selector: 'portal-channel-optimization-cards',
  templateUrl: './channel-optimization-cards.component.html'
})
export class ChannelOptimizationCardsComponent {
  navigationService = inject(NavigationService);

  viewBy = input.required<string>();

  cardVariantType = CardVariant;

  emptyStateCard = computed(() => {
    return {
      title: 'Optimization',
      content: `Last month, brands who optimized to incrementality generated a gain of <span class="b2 text-gray-1000">10% to their incremental ${this.viewBy()}</span> on average.`,
      buttonText: 'View details'
    };
  });

  onButtonAction() {
    this.navigationService.navigationMapping(
      'mpo',
      {
        filters: [],
        extraParams: {
          optimizeStrengthFilterValue: 1
        }
      },
      true
    );
  }
}
