<div *ngIf="item?.elements" class="d-flex justify-content-between">
  <div class="left-aligned-group">
    <ng-container
      [ngTemplateOutlet]="controlsGroupTemplate"
      [ngTemplateOutletContext]="{ items: leftAlignedGroup }">
    </ng-container>
  </div>
  <div
    class="right-aligned-group d-flex justify-content-center align-items-center">
    <ng-container
      [ngTemplateOutlet]="controlsGroupTemplate"
      [ngTemplateOutletContext]="{ items: rightAlignedGroup }">
    </ng-container>
  </div>
</div>

<ng-template #controlsGroupTemplate let-items="items">
  <ng-container *ngFor="let item of items">
    <ng-container [ngSwitch]="item.layoutType">
      <ng-container *ngIf="!showChild">
        <ng-container
          *ngSwitchCase="'VIEW_FAQ_ICON'"
          [ngTemplateOutlet]="faqIconTemplate"
          [ngTemplateOutletContext]="{ item: item }">
        </ng-container>
        <ng-container
          *ngSwitchCase="'VIEW_OPERATIONS'"
          [ngTemplateOutlet]="threeDotDropdownTemplate"
          [ngTemplateOutletContext]="{ item: item }">
        </ng-container>

        <ng-container
          *ngSwitchCase="'VIEW_PLANS'"
          [ngTemplateOutlet]="textButtonTemplate"
          [ngTemplateOutletContext]="{ item: item }">
        </ng-container>

        <ng-container
          *ngSwitchCase="'VIEW_NOTES'"
          [ngTemplateOutlet]="textButtonTemplate"
          [ngTemplateOutletContext]="{ item: item }">
        </ng-container>

        <ng-container
          *ngSwitchCase="'VIEW_SAVE'"
          [ngTemplateOutlet]="buttonTemplate"
          [ngTemplateOutletContext]="{
            item: item,
            state: viewPersistanceState | async,
            disabled: isSaveDisabled(id),
            layout: item.layoutType,
          }">
        </ng-container>

        <ng-container
          *ngSwitchCase="'VIEW_ADD_NEW'"
          [ngTemplateOutlet]="buttonTemplate"
          [ngTemplateOutletContext]="{ item: item }">
        </ng-container>

        <ng-container
          *ngSwitchCase="'VIEW_OPEN'"
          [ngTemplateOutlet]="buttonTemplate"
          [ngTemplateOutletContext]="{ item: item }">
        </ng-container>

        <ng-container
          *ngSwitchCase="'VIEW_CONFIRM_SAVE'"
          [ngTemplateOutlet]="buttonTemplate"
          [ngTemplateOutletContext]="{
            item: item,
            layout: item.layoutType,
            state: viewPersistanceState | async,
          }">
        </ng-container>

        <ng-container
          *ngSwitchCase="'VIEW_TYPE'"
          [ngTemplateOutlet]="dropdownSelectTemplate"
          [ngTemplateOutletContext]="{ item: item }">
        </ng-container>

        <ng-container
          *ngSwitchCase="'VIEW_SEARCH'"
          [ngTemplateOutlet]="inputTemplate"
          [ngTemplateOutletContext]="{ item: item }">
        </ng-container>
      </ng-container>

      <ng-container *ngIf="showChild">
        <ng-container *ngSwitchCase="'CHILD_SAVED_TIME'">
          <div
            *ngIf="lastTimeSaved"
            data-html2canvas-ignore="true"
            class="saved-time me-2">
            <p class="saved-time-text">
              <span class="time-label">Last time saved: </span>
              <span>{{ lastTimeSaved }}</span>
            </p>
          </div>
        </ng-container>
        <ng-container *ngIf="isSaveChildVisible">
          <ng-container
            *ngSwitchCase="'CHILD_VIEW_SAVE'"
            [ngTemplateOutlet]="buttonTemplate"
            [ngTemplateOutletContext]="{
              item: item,
              state: viewChildPersistanceState | async,
              disabled: isSaveDisabled(id),
              layout: item.layoutType,
              isChild: true,
            }">
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #threeDotDropdownTemplate let-item="item">
  <p-button
    type="button"
    icon="pi pi-ellipsis-v"
    label="{{ item?.label ?? '' }}"
    class="export-button dropdown-btn"
    (click)="dropdownMenu.toggle($event)"
    badge=""
    badgeClass="pi pi-angle-down">
  </p-button>
  <p-menu
    #dropdownMenu
    [popup]="true"
    [model]="menuHolder[item?.literalId]"
    appendTo="body">
  </p-menu>
</ng-template>

<ng-template #faqIconTemplate let-item="item">
  <m-tooltip
    content="<div class='b1 text-gray-000 text-center'>{{
      item?.label ?? ''
    }}</div>"
    (click)="onClickFAQ(mpoFaqlink)"
    position="bottom"
    [tooltipOptions]="{
      positionTop: 10,
      showDelay: 800,
    }">
    <m-button [type]="'secondary'" class="saas-ui-size-default">
      <span class="b2 text-blue-600">Learn more</span>
    </m-button>
  </m-tooltip>
</ng-template>

<ng-template #textButtonTemplate let-item="item">
  <p-button
    styleClass="p-button-text"
    label="{{ item?.label ?? '' }}"
    (click)="interact(item?.layoutType, item)"></p-button>
</ng-template>

<ng-template
  #buttonTemplate
  let-item="item"
  let-state="state"
  let-disabled="disabled"
  let-layout="layout"
  let-isChild="isChild">
  <button
    pButton
    id="{{ item.layoutType }}"
    type="button"
    [disabled]="disabled"
    [loading]="
      isChild
        ? isChildButtonLoading(layout, state)
        : isButtonLoading(layout, state)
    "
    [icon]="
      isChild ? getChildButtonIcon(layout, state) : getButtonIcon(layout, state)
    "
    class="context-control-button p-button"
    [ngClass]="{
      'p-button-disabled': disabled,
      'p-button-success': isChild
        ? isChildButtonSuccess(layout, state)
        : isButtonSuccess(layout, state),
    }"
    [label]="
      isChild
        ? getChildButtonLabel(layout, item, state)
        : getButtonLabel(layout, item, state)
    "
    (click)="interact(item?.layoutType, item)"></button>
</ng-template>

<ng-template #dropdownSelectTemplate let-item="item">
  <label class="dropdown-select">
    {{ item?.label }}
    <p-dropdown
      [options]="menuHolder[item?.literalId]"
      (onChange)="onDropdownSelectEvent($event, menuHolder[item?.literalId])"
      [filter]="false">
    </p-dropdown>
  </label>
</ng-template>

<ng-template #inputTemplate let-item="item">
  <div class="p-input-icon-right">
    <em class="pi pi-search"></em>
    <input
      pInputText
      type="text"
      placeholder="{{ item?.label }}"
      (keyup)="onKeyupEvent($event, item.layoutType)" />
  </div>
</ng-template>
