import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Observable, Subscription, take } from 'rxjs';
import {
  ContextField,
  DataResponse,
  DisplayControl,
  FieldDefinitions,
  DateRange,
  Filter
} from '@portal/app/shared/types';
import { ContextStore } from '@portal/app/shared/state/context.store';
import { NativeSectionsService } from '@portal/app/shared/services/native-sections.service';
import { ViewStore } from '@portal/app/shared/state/view.store';
import { Store } from '@ngrx/store';
import { DashboardActions } from '@portal/app/store/dashboard/actions';
import { selectStore } from '@portal/app/store/app.selectors';
import { cloneDeep } from 'lodash-es';
import { AppState } from '@portal/app/store/app.state';
import { MenuItem } from 'primeng/api';
import { Menu } from 'primeng/menu';

@Component({
  selector: 'portal-title-bar',
  templateUrl: './title-bar.component.html',
  styleUrls: ['./title-bar.component.scss']
})
export class TitleBarComponent implements OnInit, OnDestroy {
  @Input() title = '';
  @Input() filterControls: ContextField[] = [];
  @Input() chartTableToggle: null | boolean = null;
  @Input() displayControls: DisplayControl[] = [];
  @Input() chartDataPoints: DataResponse = {};
  @Input() fieldDefinitions: FieldDefinitions = {};
  @Input() overrideExport = false;
  @Input() exportMenu: null | MenuItem[] = null;
  @Input() showExportIcon = false;
  @Output() filterChanged = new EventEmitter<ContextField>();
  @Output() chartTableToggleChanged = new EventEmitter<boolean>();
  @Output() exportData = new EventEmitter<void>();
  @ViewChild('exportMenuHtmlElement') exportMenuHtmlElement: null | Menu = null;
  public literalId = '';
  private cachedValue: string | string[] | DateRange = '';
  private readonly subscriptions: Subscription = new Subscription();
  contextTitle = '';

  filterContext$: Observable<Filter[]> = this.contextStore.filterContext;

  constructor(
    private readonly contextStore: ContextStore,
    private readonly viewStore: ViewStore,
    public readonly nativeSectionsService: NativeSectionsService,
    private readonly store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.viewStore.selectedBCPDId.pipe(take(1)).subscribe((value) => {
      this.literalId = value.literalId;
    });
    const sub = this.filterContext$.subscribe({
      next: (filters) => {
        this.cachedValue = '';
        this.setContextTitle(filters);
      }
    });
    this.subscriptions.add(sub);
  }

  filterChange(control: ContextField): void {
    const filter = this.filterControls.find((f) => f.name === control.name);
    if (filter) {
      filter.value = control.value;
      if (this.cachedValue !== filter.value) {
        this.subscriptions.add(
          this.store.select(selectStore).subscribe((state) => {
            const allFilters = cloneDeep(state.dashboard?.copiedFilters);
            const matchingFilter = allFilters?.find(
              (f: Filter) => f.literalId === filter.name
            );
            if (matchingFilter) {
              matchingFilter.value = filter.value;
              this.store.dispatch(
                DashboardActions.setFilter({ filter: matchingFilter })
              );
            }
          })
        );
        this.cachedValue = filter.value + '';
        this.filterChanged.emit(control);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  toggleChange(): void {
    if (this.chartTableToggle !== null) {
      this.chartTableToggleChanged.emit(this.chartTableToggle);
    }
  }

  exportDataHandler($event: Event): void {
    if (this.overrideExport) {
      this.exportData.emit();
    } else {
      this.exportMenuHtmlElement?.toggle($event);
    }
  }

  setContextTitle(filters: Filter[]): void {
    if (this.literalId === 'benchmarks-drill-down') {
      let contextTitle = '';
      contextTitle = filters.find((filter) => filter.literalId === 'tactic')
        ?.value as string;
      if (!contextTitle) {
        contextTitle = filters.find((filter) => filter.literalId === 'channel')
          ?.value as string;
      }
      this.contextTitle = contextTitle;
    }
  }
}
