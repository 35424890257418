<div class="flex flex-wrap gap-2 justify-around py-6">
  @if (metricConfig.length) {
    @for (metric of metricConfig; track metric) {
      <div class="text-center">
        <div class="flex gap-2 items-end">
          <span class="text-gray-1000 h2 mb-0">{{ metric.campaignCount }}</span>
          <span class="text-gray-900 h5 mb-0">{{ metric.header }}</span>
        </div>
        <div class="b2 text-gray-600">
          That's
          <span class="text-gray-1000"
            >{{ metric.mediaSpend || 0 | customCurrency }} in media spend</span
          >
          {{ metric.suffix }}
        </div>
      </div>
    }
  } @else {
    @for (metric of defaultMetricConfig; track metric) {
      <div class="text-center">
        <div class="flex gap-2 items-end">
          <span class="text-gray-1000 h2 mb-0"
            ><p-skeleton size="3rem" styleClass="mr-2"></p-skeleton
          ></span>
          <span class="text-gray-900 h5 mb-0">
            <p-skeleton
              width="22rem"
              height="2rem"
              styleClass="mb-1"></p-skeleton
          ></span>
        </div>
        <div class="b2 text-gray-600 flex justify-center">
          <p-skeleton
            width="18rem"
            height="1rem"
            styleClass="ml-8 mt-1"></p-skeleton>
        </div>
      </div>
    }
  }
</div>
