<portal-context-modal-card
  class="h-full"
  [card]="emptyStateCard()"
  (buttonAction)="onButtonAction()">
  <div>
    <m-card [variant]="cardVariantType.small">
      <div class="grid grid-cols-12 gap-4 items-center">
        <div
          class="b1 text-gray-1000 col-span-7"
          [innerHTML]="emptyStateCard().content"></div>
        <div class="col-span-5">
          <img
            width="140px"
            class="block ml-auto"
            src="assets/images/optimization.svg"
            alt="Optimization" />
        </div>
      </div>
    </m-card>
  </div>
</portal-context-modal-card>
