import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  private readonly geoTestStatusByDimension: BehaviorSubject<
    Record<string, boolean>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  > = new BehaviorSubject<any>({});

  private readonly clientAndBrandId: BehaviorSubject<Record<string, number>> =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    new BehaviorSubject<any>({});

  public setGeoTestStatusByDimension(
    geoTestStatusByDimension: Record<string, boolean>
  ): void {
    this.geoTestStatusByDimension.next(geoTestStatusByDimension);
  }

  public getGeoTestStatusByDimension(): Observable<Record<string, boolean>> {
    return this.geoTestStatusByDimension.asObservable();
  }

  public setClientAndBrandId(clientId: number, brandId: number): void {
    return this.clientAndBrandId.next({ clientId, brandId });
  }

  public getClientAndBrandId(): Record<string, number> {
    return this.clientAndBrandId.value;
  }
}
