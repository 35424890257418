import { Injectable } from '@angular/core';
import { filter, Observable, Subject } from 'rxjs';
import { PortalEventCategory, PortalEvent } from '@portal/app/shared/types';

@Injectable({
  providedIn: 'root'
})
export class PortalEventBusService {
  private eventSubject$ = new Subject<PortalEvent>();
  private eventObservable = this.eventSubject$.asObservable();

  emit(event: PortalEvent) {
    this.eventSubject$.next(event);
  }

  on(eventCategory: PortalEventCategory): Observable<PortalEvent> {
    return this.eventObservable.pipe(
      filter((e: PortalEvent) => e.eventCategory === eventCategory)
    );
  }
}
