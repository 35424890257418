import { Component, inject, input } from '@angular/core';
import { CardVariant } from '@design-system/components/m-card';
import { TopMediaChartService } from '@portal/app/dashboard/context-modal/services/top-media-chart.service';

@Component({
  selector: 'portal-top-media-chart',
  templateUrl: './top-media-chart.component.html',
  styleUrl: './top-media-chart.component.scss',
  providers: [TopMediaChartService]
})
export class TopMediaChartComponent {
  topMediaChartService = inject(TopMediaChartService);

  title = input.required<string>();

  cardVariantType = CardVariant;

  isLoading = this.topMediaChartService.isLoading;
  isDataAvailable = this.topMediaChartService.isDataAvailable;
  isReady = this.topMediaChartService.isReady;

  chartOptions = this.topMediaChartService.chartOptions;
  titleSuffix = this.topMediaChartService.titleSuffix;
}
