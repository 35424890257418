/* eslint-disable @typescript-eslint/naming-convention */
export type ColorMap = Record<string, string>;

export const colors: ColorMap = {
  'gray-000': '#FFFFFF',
  'gray-100': '#F7F9FA',
  'gray-200': '#EDF0F2',
  'gray-300': '#E3E7EB',
  'gray-400': '#C9CFD8',
  'gray-500': '#AEB7C3',
  'gray-600': '#93A0AF',
  'gray-700': '#77879C',
  'gray-800': '#617087',
  'gray-900': '#4A5A70',
  'gray-1000': '#354358',
  'gray-1100': '#0A1626',
  'blue-100': '#eaf0f9',
  'blue-200': '#d6e2f2',
  'blue-300': '#acc4e5',
  'blue-400': '#83A7D9',
  'blue-500': '#5989CC',
  'blue-600': '#3e70b5',
  'blue-700': '#265699',
  'blue-800': '#1d4173',
  'blue-900': '#132b4c',
  'green-100': '#EFFAF5',
  'green-200': '#DFF4EB',
  'green-300': '#BFE9D8',
  'green-400': '#A0DFC4',
  'green-500': '#80D4B1',
  'green-600': '#60C99D',
  'green-700': '#4DA17E',
  'red-100': '#FAEEEE',
  'red-200': '#F5DCDC',
  'red-300': '#EBBABA',
  'red-400': '#E09797',
  'red-500': '#D67575',
  'red-600': '#CC5252',
  'red-700': '#BF3939',
  'yellow-100': '#FFFAF2',
  'yellow-200': '#FFF4E4',
  'yellow-300': '#FFEACA',
  'yellow-400': '#FFDFAF',
  'yellow-500': '#FFD595',
  'yellow-600': '#FFCA7A',
  'yellow-700': '#FFBD59',
  'orange-100': '#F1A47A',
  'orange-200': '#e9865d',
  'orange-300': '#E66B43',
  'orange-400': '#C95028',
  'purple-700': '#696cff'
};
