export class NavbarConstants {
  public static readonly user = 'user';
  public static readonly experimentDashboardId = -2;
  public static readonly decisioningId = 'decisioning';
  public static readonly experimentsId = 'experiments';
  public static readonly optimizeId = 'optimize';
  public static readonly settingsId = 'settings';
  public static readonly userOptions = 'user-options';
  public static readonly integrationsId = 'integrations';
  public static readonly newIntegrationsId = 'new-integrations';
  public static readonly onboardingId = 'onboarding-ui';
  public static readonly ddiSettingsId = 'setting-manager';
  public static readonly geoDesignerId = 'geo-designer';
  public static readonly dataExportId = 'data-export';
  public static readonly userAccountId = 'user-account';
  public static readonly mpoLiteralId = 'portfolio-level';
  public static readonly mpoProductId = 'media-plan-optimizer';
  public static readonly benchmarksId = 'measured-benchmarks';
  public static readonly shopifyDashboardId = 'shopify-dashboard';
  public static readonly homePageId = 'home-page';
  public static readonly adminPageId = 'admin';
  public static readonly campaignMapping = 'campaign-mapping';
  public static readonly experimentIds = [
    'fb-doe-conversion',
    'fb-doe-revenue',
    'catalog',
    'facebook-scale-report',
    'geo'
  ];

  public static readonly optimizationReportId = 'optimization-report';
  public static readonly crossChannelId = 'cross-channel';
  public static readonly notificationCenterId = 'notification-center';

  public static readonly ddiReportingRevenue = 'ddi-reporting-revenue';
  public static readonly ddiReportingConversion = 'ddi-reporting-conversion';
  public static readonly controlTowerId = 'control-tower';

  public static readonly experimentLabelRegex =
    /facebook|retargeting|catalog|geo|crm/gi;

  public static readonly standardDashboardLabels = [
    'AdWords',
    'AdWords Reporting',
    'Cross Channel',
    'Cross Channel ROAS Weekly',
    'Cross Channel CPO Weekly',
    'Cross Channel Weekly',
    'Customer Reporting',
    'Weekly Report',
    'TV Attribution',
    'Roku Incrementality',
    'Roku',
    'MDW Reports',
    'Export Reports',
    'DDI Reporting',
    'Measured Benchmarks',
    'Benchmarks'
  ];

  public static readonly experimentDashboardLabels = [
    'Retargeting',
    'Catalog',
    'Facebook',
    'Geo',
    'CRM',
    'Retargeting Incrementality',
    'Facebook Incrementality',
    'Facebook Scale',
    'Facebook Weekly',
    'Facebook ROAS Weekly',
    'Facebook CPO Weekly',
    'Catalog Incrementality',
    'Geo Incrementality'
  ];
}
