import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApiService } from '@portal/app/shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userNameSubject = new BehaviorSubject<string>('');

  // Getter for userName observable
  get userName(): Observable<string> {
    return this.userNameSubject.asObservable();
  }

  // Call this method where you fetch the userName
  fetchAndSetUserName(): void {
    this.fetchUserName().subscribe((userName) => {
      this.userNameSubject.next(userName);
    });
  }

  constructor(private apiService: ApiService) {}

  fetchUserName(): Observable<string> {
    return this.apiService.getUser().pipe(
      map((user) => user?.name?.trim().split(' ')[0] || ''),
      catchError((error) => {
        console.error('Error fetching user name:', error);

        return of('Unknown User');
      })
    );
  }

  fetchUserEmail(): Observable<string> {
    return this.apiService.getUser().pipe(
      map((user) => user?.email?.trim() || ''),
      catchError((error) => {
        console.error('Error fetching user email:', error);
        return of('Unknown User');
      })
    );
  }
}
