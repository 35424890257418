import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  cloneDeep,
  find,
  flatten,
  forEach,
  isEmpty,
  map,
  uniq,
  values
} from 'lodash-es';
import { Observable, Subscription, take } from 'rxjs';
import { Table } from 'primeng/table';
import { InputNumber } from 'primeng/inputnumber';
import { MenuItem, SortEvent } from 'primeng/api';
import {
  DataGridBarVisualizer,
  ExportTableType,
  FilterEvent,
  FilterField,
  IFilterChange,
  WidgetType
} from '@portal/app/shared/types';
import {
  ContextField,
  DataEndpoint,
  DataGridAvailable,
  DataGridConfig,
  DataGridEditable,
  DataGridElement,
  DataGridGrouped,
  DataGridSelected,
  DataResponse,
  DataSetResponse,
  ElementTyping,
  FieldDefinition,
  FieldDefinitions,
  Filter,
  FilterValue,
  PivotTableResponse
} from '@portal/app/shared/types';
import {
  combineFilters,
  createFilterFields,
  getFiltersFromFilterFields,
  updateFilterOptions
} from '@portal/app/dashboard/utils';
import { sortElementSubTypeByDisplayOrder } from '@portal/app/shared/helpers/helpers';
import {
  ApiService,
  ExportObjectParamValue
} from '@portal/app/shared/services/api.service';
import { ViewService } from '@portal/app/shared/services/view.service';
import { ContextStore } from '@portal/app/shared/state/context.store';
import { NativeSectionsService } from '@portal/app/shared/services/native-sections.service';
import { FormatterService } from '@portal/app/shared/services/formatter.service';
import { DataGridStore } from '@portal/app/datagrid/store/data-grid.store';
import { ExportTableService } from '@portal/app/shared/services/export-table.service';
import { DateTimeService } from '@portal/app/shared/services/date-time.service';
import { ViewStore } from '@portal/app/shared/state/view.store';
import { Required } from '@portal/app/shared/decorators/required.decorator';
import { FiltersService } from '@portal/app/shared/services/filters.service';
import { DatagridBaseComponent } from '../base-data-grid/data-grid-base.component';
import { DataGridService } from '../services/data-grid.service';
import { monthDateYearFormat } from '@portal/app/shared/constants/constants';

@Component({
  selector: 'portal-data-grid-pivot',
  templateUrl: './data-grid-pivot.component.html',
  styleUrls: ['./data-grid-pivot.component.scss']
})
export class DataGridPivotComponent
  extends DatagridBaseComponent
  implements OnInit, OnDestroy
{
  @ViewChild('dt') dt!: Table;
  @ViewChild('filterInput') filterInput!: InputNumber;
  @Input() @Required element!: DataGridElement;
  @Input() @Required fieldDefinitions!: FieldDefinitions;
  @Input() elements: DataGridElement[] = [];
  @Input() name = '';
  @Input() title = '';
  @Input() filterControls: ContextField[] = [];
  @Input() filterable = false;
  @Input() data: DataSetResponse[] = [];
  @Input() dataGridConfig: DataGridConfig =
    this.dataGridStore.getDataGridTableConfig();

  @Output() loaded = new EventEmitter<boolean>();

  public filterFields: ContextField[] = [];
  public loading = true;
  public wasFiltered = false;
  public showEmptyState = false;
  public pivotDimensionColumn: DataGridSelected = {} as DataGridSelected;
  public pivotDimensionId = '';
  public pivotDataColumn: DataGridSelected = {} as DataGridSelected;
  public pivotFieldDefinition: FieldDefinition = {} as FieldDefinition;
  public table: PivotTableResponse = {
    fields: [],
    editableFields: [],
    data: [],
    footer: [],
    groupByRowFooter: {}
  };

  public productId = '';
  public fieldsToHighlight: string[] = [];
  public exportTableMenu: MenuItem[] = [
    {
      label: 'CSV',
      icon: 'pi pi-fw pi-file',
      styleClass: 'export-item csv',
      command: () => {
        this.exportWithFormat('csv');
      }
    },
    {
      label: 'TSV',
      icon: 'pi pi-fw pi-file',
      styleClass: 'export-item tsv',
      command: () => {
        this.exportWithFormat('tsv');
      }
    },
    {
      label: 'Excel',
      icon: 'pi pi-fw pi-file',
      styleClass: 'export-item excel',
      command: () => {
        this.exportWithFormat('xlsx');
      }
    }
  ];

  public sortByDisplayOrder = sortElementSubTypeByDisplayOrder;
  public pivotRows: DataGridSelected[] = [];
  public pivotRowHeaders: DataGridSelected[] = [];
  public clientId = 0;
  public literalId = '';
  public frozenColumnsEnabled = true;
  public arrowsDisabledFor: string[] = [' ', '', '-', '0'];

  filterContext$: Observable<Filter[]> = this.contextStore.filterContext;
  gridFilters$: Observable<Filter[]> = this.filtersService.dataGridFilter;
  onFilterChange$: Observable<IFilterChange> =
    this.filtersService.onfilterChanged;

  protected filters: Filter[] = [];
  private subscriptions: Subscription = new Subscription();
  private dataPoints: DataResponse | undefined;
  private tableData: DataSetResponse[] = [];
  private previousFilterValue: FilterValue = null;
  private chartFilters$: Observable<Filter[]> =
    this.filtersService.dataChartFilter;

  private datagridApiFilters: Filter[] = [];

  constructor(
    public readonly nativeSectionsService: NativeSectionsService,
    protected readonly dataGridService: DataGridService,
    protected readonly apiService: ApiService,
    protected readonly contextStore: ContextStore,
    protected readonly exportTableService: ExportTableService,
    protected readonly dataGridStore: DataGridStore,
    protected readonly viewService: ViewService,
    protected readonly dateService: DateTimeService,
    protected readonly viewStore: ViewStore,
    private readonly filtersService: FiltersService,
    public readonly formatterService: FormatterService
  ) {
    super(
      nativeSectionsService,
      dataGridService,
      apiService,
      contextStore,
      formatterService,
      exportTableService,
      dataGridStore,
      viewService,
      dateService,
      viewStore
    );
    super.literalId = this.literalId;
    super.clientId = this.clientId;
    super.productId = this.productId;
  }

  ngOnInit(): void {
    this.viewStore.selectedBCPDId.pipe(take(1)).subscribe((value) => {
      this.clientId = value.clientId;
      this.productId = value.productId;
      this.literalId = value.literalId;
    });

    if (this.productId === 'cross-platform-doe') {
      this.dataGridConfig.showFooter = false;
      this.dataGridConfig.striped = false;
    }
    this.initializePivotValues(this.element);
    this.loadData();
  }

  initializePivotValues(element: DataGridElement) {
    this.pivotDimensionColumn = Object.assign(
      {},
      element.selected.find((field) => {
        if (field.type === ElementTyping.pivotDimension) {
          return field;
        }
      })
    ) as DataGridSelected;
    element.selected.forEach((field) => {
      if (field.type === ElementTyping.pivotRow) {
        this.pivotRows.push(field);
      }
      if (field.type === ElementTyping.pivotRowHeader) {
        this.pivotRowHeaders.push(field);
      }
    });
    this.pivotDimensionId = this.pivotDimensionColumn.dashboardFieldId;
    element.selected.forEach((col) => {
      if (col.type === ElementTyping.pivotRowHighlight) {
        const columnId = col?.dashboardFieldId as string;
        if (columnId !== null) {
          this.fieldsToHighlight.push(columnId);
        }
      }
    });
    if (!this.pivotRows.length) {
      // pivot_data operations can be executed only if pivot rows dont exist
      // collect pivot_data column and add a field definition of pivot data in the fieldDefinitions
      element.selected.forEach((col) => {
        if (col.type === ElementTyping.pivotData) {
          this.pivotDataColumn = col;
        }
      });
      this.fieldDefinitions.PIVOT_DIMENSION = this.fieldDefinitions[
        this.pivotDataColumn.dashboardFieldId
      ] as FieldDefinition;
    }
  }

  ngOnDestroy(): void {
    super.unsubscribeToAll(this.subscriptions);
  }

  loadData(): void {
    let dataGridFilterSubscription: Subscription;
    const contextStoreSubscription = this.filterContext$.subscribe({
      next: (filterSet) => {
        const filters = cloneDeep(filterSet);
        this.filters = filters;
        super.filters = filters;
        this.filterFields = createFilterFields(this.element, this.filters);
        this.correctFilterSelections();
        if (dataGridFilterSubscription) {
          dataGridFilterSubscription.unsubscribe();
        }
        const filterChangeSub = this.onFilterChange$.subscribe((f) => {
          if (
            f.filterType === WidgetType.context ||
            f.filterType === WidgetType.table
          ) {
            this.filterFields = updateFilterOptions(
              f.filters as Filter[],
              this.filterFields
            );
          }
        });
        this.subscriptions.add(filterChangeSub);
        this.setFilterValues(true);

        dataGridFilterSubscription = this.gridFilters$.subscribe({
          next: (dataGridFilters) => {
            if (dataGridFilters.length > 0) {
              const filterValue = dataGridFilters.find(
                (df) => df.literalId === 'dataSets'
              );
              this.datagridApiFilters = dataGridFilters;
              // if filter(s) exist, check if previousValue is not set, if it is different from the
              //  current filter value, or if it equals the first option in filterValues
              if (
                filterValue &&
                filterValue.options != null &&
                filterValue.options.length > 0
              ) {
                if (
                  this.previousFilterValue == null ||
                  this.previousFilterValue !== filterValue.value ||
                  filterValue.value === filterValue.options[0]
                ) {
                  this.callApi(dataGridFilters);
                  if (this.filterFields.length) {
                    const filterFieldValues = this.filterFields
                      .map((field) => field.value)
                      .join(':');
                    this.previousFilterValue =
                      filterValue.value + filterFieldValues;
                  } else {
                    this.previousFilterValue = filterValue.value;
                  }
                }
              }
              // Do nothing if there are dataGrid values and previousValue is the same as filterValue.value
            } else {
              // just call the API when there are no filters
              this.callApi(dataGridFilters);
            }
          }
        });
        this.subscriptions.add(dataGridFilterSubscription);
      }
    });
    this.subscriptions.add(contextStoreSubscription);
  }

  callApi(dataGridFilters: Filter[]) {
    this.getDatasetData(
      super.generateApiFilters(dataGridFilters),
      DataEndpoint.dataSets,
      []
    );
  }

  getDatasetData(
    filters: Filter[],
    type: DataEndpoint,
    dimensionValues: string[] = []
  ) {
    this.loading = true;
    this.showEmptyState = false;
    if (this.data.length) {
      this.tableData = cloneDeep(this.data);
      this.formatTable(true);
      this.loading = false;
      return;
    }
    this.apiService
      .getDashboardData({ filters, type, dimensionValues })
      .then((response) => {
        // Saving dataset response into this.tableData to be later used to format it into table.data,
        //  table.fields & table.footer. In case of a context filter change, dimensions should reset
        this.tableData = response as DataSetResponse[];
        this.formatTable(true);

        if (super.isTableEmpty(this.tableData)) {
          this.showEmptyState = true;
        }
      })
      .catch((error) => {
        if (error && error.error) {
          this.showEmptyState = true;
          this.loading = false;
        }
      });
  }

  generatePivotFieldsAndData(
    pivotDimension: DataGridSelected,
    pivotData: DataGridSelected,
    table: PivotTableResponse
  ): [
    DataResponse[],
    (
      | DataGridAvailable
      | DataGridSelected
      | DataGridEditable
      | DataGridGrouped
      | DataGridBarVisualizer
    )[]
  ] {
    let tableData = cloneDeep(table.data);
    let tableFields = cloneDeep(table.fields);

    // generate column headers by collecting value of pivotDimensionId from every row object
    let columnHeaders: string[] = [];
    if (this.pivotRows.length === 0) {
      forEach(tableData, (row) => {
        let columnHeader: string = row[this.pivotDimensionId] as string;
        columnHeader = columnHeader.replace(/ /g, '_');
        row[columnHeader] = this.getPivotDataValue(row, pivotData)[0];
        columnHeaders.push(columnHeader);
        row.fieldLiteralId = this.getPivotDataValue(row, pivotData)[1];
      });
      columnHeaders = [...new Set(columnHeaders)];
    } else {
      columnHeaders = uniq(
        map(tableData, (dObj) => {
          return dObj[this.pivotDimensionId] as string;
        })
      );
      const rows: DataResponse[] = [];
      forEach(this.pivotRows.sort(this.sortByDisplayOrder), (pivotRow) => {
        const updatedPivotRow: DataResponse = {};
        updatedPivotRow[
          (this.pivotRowHeaders[0] as DataGridSelected).dashboardFieldId
        ] = pivotRow.label;
        updatedPivotRow.fieldToApply = pivotRow.dashboardFieldId;
        columnHeaders.forEach((col) => {
          const obj = find(tableData, (d) => {
            return d[this.pivotDimensionId] === col;
          });
          updatedPivotRow[col] = obj
            ? (obj[pivotRow.dashboardFieldId] ??
              (obj[pivotRow.dashboardFieldId] || null))
            : null;
        });
        rows.push(updatedPivotRow);
      });
      tableData = cloneDeep(rows);
    }
    tableFields = tableFields.filter(
      (field) => field.type === ElementTyping.dimension
    );
    const newFields: DataGridSelected[] = [];
    if (pivotDimension) {
      forEach(columnHeaders, (header) => {
        const pivotFieldCopy = pivotDimension;
        pivotFieldCopy.dashboardFieldId = header;
        pivotFieldCopy.label = header.replace(/_/g, ' ');
        pivotFieldCopy.type = ElementTyping.pivotDimension;
        newFields.push({ ...pivotFieldCopy });
      });
    }
    return [tableData, [...tableFields, ...newFields]];
  }

  formatToPivot() {
    const elementCopy: DataGridElement = cloneDeep(this.element);

    [this.table.data, this.table.fields] = this.generatePivotFieldsAndData(
      this.pivotDimensionColumn,
      this.pivotDataColumn,
      this.table
    );
    if (this.pivotRows.length) {
      this.table.fields = [
        this.pivotRowHeaders[0] as DataGridSelected,
        ...this.table.fields
      ];
    } else {
      this.table.data = this.formatterService.groupByDimensions(
        elementCopy,
        this.table.data
      );
    }
    this.table.fields.sort(this.sortByDisplayOrder);
  }

  formatTable(initialFormat = false): void {
    const tableRows = this.tableData.filter((i) => i.name === this.name); // find this table from the api response
    // this code should be executed only on initialization, hence checking the length of the table.data if it is empty
    if (tableRows != null && initialFormat) {
      this.table.data = super.getFormattedData(
        tableRows,
        this.fieldDefinitions
      );
    }
    this.pivotDataColumn = {} as DataGridSelected;
    forEach(this.element.selected, (selcol) => {
      if (selcol.type === ElementTyping.pivotData) {
        this.pivotDataColumn = selcol;
      }
    });
    const columns = super.generateFields(this.element);
    //format table data using derived fields
    this.table.fields = columns.length
      ? (columns as DataGridSelected[])
      : (this.table.fields as DataGridSelected[]);
    this.dataPoints =
      tableRows && tableRows.length
        ? (tableRows[0] as DataSetResponse).dataPoints
        : undefined;
    this.table.editableFields = super.prepareEditableFields(
      this.table,
      this.filters
    );
    this.formatToPivot();
    this.loaded.emit(true);
    this.loading = false;
    this.tableRefresh();
  }

  onColumnReorder(event: {
    dragIndex: number;
    dropIndex: number;
    columns: (DataGridAvailable | DataGridSelected | DataGridEditable)[];
  }) {
    this.table.fields = event.columns;
    this.table.editableFields = super.prepareEditableFields(
      this.table,
      this.filters
    );
    if (event.columns[event.dropIndex]?.type === ElementTyping.column) {
      this.tableRefresh();
    }
  }

  toggleFreezing() {
    this.frozenColumnsEnabled = !this.frozenColumnsEnabled;
  }

  tableRefresh() {
    setTimeout(() => {
      this.toggleFreezing();
    }, 0);
    this.toggleFreezing();
  }

  exportWithFormat(format: ExportTableType): void {
    const fileName = `${this.literalId}-${this.clientId}-${this.dateService
      .today()
      .format(monthDateYearFormat)}`;
    const exportObjParams: ExportObjectParamValue = {
      literalId: this.element.literalId,
      name: fileName,
      format,
      pivotRows: this.pivotRows.length > 0,
      isPivotTable: true,
      showFooter: this.dataGridConfig.exportShowFooter
    };
    this.callExportApi(
      super.generateApiFilters(this.datagridApiFilters),
      DataEndpoint.dataSetExport,
      exportObjParams
    );
  }

  callExportApi(
    filters: Filter[],
    type: DataEndpoint,
    exportObjParams: ExportObjectParamValue
  ): void {
    this.apiService.getExportData({
      filters,
      type,
      exportObjParams
    });
  }

  clearFilters() {
    this.dt.clear();
    this.wasFiltered = false;
  }

  isAnyFilterSet(filters: Record<string, FilterField[]>): boolean {
    return !!flatten(values(filters)).find((i) => i.value != null);
  }

  filterChanged(event: FilterEvent) {
    if (this.isAnyFilterSet(event.filters)) {
      this.wasFiltered = true;
    }
  }

  filterInputKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter' || e.key === 'Tab') {
      this.setFilterValues();
    }
  }

  correctFilterSelections() {
    forEach(this.filterFields, (filterfield) => {
      const optionValues: string[] = [];
      if (
        filterfield.value === null &&
        filterfield.value === '' &&
        filterfield?.options &&
        filterfield.options.length > 0
      ) {
        forEach(filterfield.options, (opts) => {
          optionValues.push(opts?.value as string);
        });
        filterfield.value = optionValues;
      }
    });
  }

  setFilterValues(initialLoad?: boolean): void {
    let chartFilters: Filter[] = [];
    this.chartFilters$.pipe(take(1)).subscribe((filters) => {
      chartFilters = filters;
    });
    const sub = this.filtersService
      .changeDataGridFilter([
        ...getFiltersFromFilterFields(
          this.filterControls,
          this.filters,
          'DATA_SET'
        ),
        ...getFiltersFromFilterFields(
          this.filterFields,
          this.filters,
          'DATA_SET'
        )
      ])
      .subscribe({
        next: (filters) =>
          this.apiService.modifyParams(
            combineFilters(super.generateApiFilters(filters), chartFilters)
          ),
        error: (err) => console.error(err)
      });
    if (!initialLoad) {
      this.filtersService.changeFilters({
        widgetType: WidgetType.table,
        endpoint: DataEndpoint.dataSets
      });
    }
    this.subscriptions.add(sub);
  }

  customSort(event: SortEvent): number {
    if (
      event != null &&
      event.data != null &&
      event.field != null &&
      event.order != null
    ) {
      event.data.sort((data1, data2) => {
        const value1 = data1[event.field || ''];
        const value2 = data2[event.field || ''];
        let result: number;

        if (value1 == null && value2 != null) {
          result = -1;
        } else if (value1 != null && value2 == null) {
          result = 1;
        } else if (value1 == null && value2 == null) {
          result = 0;
        } else if (typeof value1 === 'string' && typeof value2 === 'string') {
          result = value1.localeCompare(value2);
        } else {
          result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
        }

        return (event.order || 0) * result;
      });
    }
    return 0;
  }

  asStringArray(val: unknown): string[] {
    return val as string[];
  }

  getPivotDataValue(
    row: DataResponse,
    pivotData: DataGridSelected
  ): [value: string, literalId: string] {
    return [row[pivotData.dashboardFieldId], pivotData.dashboardFieldId] as [
      string,
      string
    ];
  }

  // return correct field definition based on type of column
  getFieldDefinition(col: DataGridSelected, rowData: DataResponse = {}) {
    if (
      col.dashboardFieldId !== this.pivotRowHeaders[0]?.dashboardFieldId &&
      !isEmpty(rowData) &&
      rowData.fieldToApply
    ) {
      return this.fieldDefinitions[rowData.fieldToApply];
    } else if (col && col.type === ElementTyping.pivotDimension) {
      return this.fieldDefinitions.PIVOT_DIMENSION;
    } else {
      return this.fieldDefinitions[col.dashboardFieldId];
    }
  }
}
