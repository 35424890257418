import { Injectable } from '@angular/core';
import { IToastMessageConfig } from '@libs/common-services';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ToastMessageService {
  constructor(private readonly messageService: MessageService) {}

  showToastMessage(toastConfig: IToastMessageConfig, duration?: number): void {
    const { severity, detail, sticky = false, data, closable } = toastConfig;
    let { summary } = toastConfig;
    if (!summary) {
      switch (severity) {
        case 'error':
          summary = 'Error Message';
          break;
        default:
          break;
      }
    }
    this.messageService.add({
      severity,
      sticky,
      summary,
      detail,
      data,
      closable,
      life: duration
    });
  }
}
