import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataTrackerService {
  generateKeySuffix(value: string, suffix: string): string {
    const formattedSuffix = suffix.toLowerCase().split(' ').join('-');
    return `${value}${formattedSuffix}`;
  }
}
