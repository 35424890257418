import { ButtonType } from '@design-system/components/m-button';

export enum BottomPanelMessageType {
  base = 'base',
  info = 'info',
  success = 'success',
  warn = 'warn',
  error = 'error'
}

export interface IBottomPanelButtonConfig {
  id: string;
  label: string;
  type: ButtonType;
  disabled?: boolean;
  tooltip?: string;
}

export interface IConnectedPoints {
  connectedPoints: number;
  totalPoints: number;
}

export interface IButtonClickEvent {
  id: string;
  refEvent: Event;
}
