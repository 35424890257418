import { Injectable } from '@angular/core';
import { sortBy, uniqBy } from 'lodash-es';
import {
  BehaviorSubject,
  forkJoin,
  Observable,
  Subscriber,
  take,
  throwError
} from 'rxjs';
import {
  ApiService,
  DashboardParams
} from '@portal/app/shared/services/api.service';
import { SelectionService } from '@portal/app/shared/services/selection.service';
import { StorageService } from '@portal/app/shared/services/storage.service';
import { BrandRowsService } from '@portal/app/shared/services/brand-rows.service';
import { AuthService } from '@portal/app/shared/services/auth.service';
import { getSorted } from '@portal/app/shared/helpers/helpers';
import {
  BrandRow,
  CurrentStatus,
  DisplayDashboard,
  JustBrand,
  NativeDashboard,
  ProductDashboardPersistenceMode,
  User
} from '@portal/app/shared/types';
import { UserModel } from '@portal/app/shared/models/userModel';
import { getDefaultDashboard } from '@portal/app/dashboard/utils';
import {
  demoDefaultClient,
  defaultDashboardLiteralId
} from '@portal/app/shared/constants/constants';

export interface DashboardError {
  dashboards: DisplayDashboard[];
  error: string;
}

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private readonly dashboardsHolder = new BehaviorSubject<DisplayDashboard[]>(
    []
  );

  public dashboards: Observable<DisplayDashboard[]> =
    this.dashboardsHolder.asObservable();

  constructor(
    private readonly store: StorageService,
    private readonly apiService: ApiService,
    private readonly selectionService: SelectionService,
    private readonly brandRowsService: BrandRowsService,
    private readonly authService: AuthService
  ) {}

  public updateDashboards(
    justBrand: JustBrand
  ): Observable<DisplayDashboard[]> {
    // eslint-disable-next-line arrow-body-style,@typescript-eslint/no-unused-vars
    return this.getDashboards(justBrand);
  }

  public getDashboards(justBrand: JustBrand): Observable<DisplayDashboard[]> {
    const { brand } = justBrand;
    const clientId = brand?.clientId;
    const brandId = brand?.brandId;
    let dashboards: DisplayDashboard[];
    return new Observable((subscriber: Subscriber<DisplayDashboard[]>) => {
      this.getNormalizedDashboards({
        clientId,
        brandId
      })
        .pipe(take(1))
        .subscribe({
          next: (dbs) => {
            dashboards = dbs;
            dashboards = dashboards.concat(
              this.staticMeasuredDashboards(clientId, brandId)
            );
            dashboards = getSorted(dashboards, 'nativeDashboardLabel');
            this.dashboardsHolder.next(dashboards);
            subscriber.next(dashboards);
            subscriber.complete();
          },
          error: (e) => {
            subscriber.next([]);
            subscriber.complete();
            throwError(() =>
              Error(`there was an error, returning empty dashboards: ${e}`)
            );
          }
        });
    });
  }

  private staticMeasuredDashboards(
    clientId = -1,
    brandId = -1
  ): DisplayDashboard[] {
    const experimentDashboardId = -2;
    return [
      {
        literalId: 'facebook-scale-config',
        productId: 'facebook-incrementality',
        label: 'Facebook Scale Designer',
        clientId,
        brandId,
        tableauURIPart: null,
        routerLinkPart: `facebook-scale-config/`,
        dashboardId: experimentDashboardId,
        status: CurrentStatus.active,
        isMultiView: false
      },
      {
        literalId: 'geo-doe',
        productId: 'cross-platform-doe',
        label: 'Geo Designer',
        clientId,
        brandId,
        tableauURIPart: null,
        routerLinkPart: `geo-doe`,
        dashboardId: experimentDashboardId,
        status: CurrentStatus.active,
        isMultiView: false
      },
      {
        literalId: 'geo-designer',
        productId: 'cross-platform-doe',
        label: 'Geo Tests',
        clientId,
        brandId,
        tableauURIPart: null,
        routerLinkPart: `geo-designer/home`,
        dashboardId: experimentDashboardId,
        status: CurrentStatus.active,
        isMultiView: false
      },
      {
        literalId: 'notification-center',
        productId: 'alerts',
        label: 'Notification Center',
        clientId,
        brandId,
        tableauURIPart: null,
        routerLinkPart: `notification-center`,
        dashboardId: experimentDashboardId,
        status: CurrentStatus.active,
        isMultiView: false
      }
    ];
  }

  public getCurrentDashboards(): Observable<DisplayDashboard[]> {
    const hasB = (brand: JustBrand) =>
      brand != null &&
      brand.brand != null &&
      !!brand.brand.brandId &&
      !!brand.brand.clientId;
    const u = this.authService.getUser();
    let justBrand = this.selectionService.getSelection();
    return new Observable((subscriber: Subscriber<DisplayDashboard[]>) => {
      if (hasB(justBrand)) {
        // Client/brand have been set/selected.
        this.getDashboards(justBrand)
          .pipe(take(1))
          .subscribe({
            next: (dashboards) => {
              subscriber.next(dashboards);
              subscriber.complete();
            }
          });
      } else if (
        !(u && u.hasAccessToMultipleClientOrBrandIds(this.selectionService))
      ) {
        // client/brand not selected, and the user has access to only one client/brand
        this.authService
          .setFirstClientAndBrand()
          .pipe(take(1))
          .subscribe({
            next: () => {
              justBrand = this.selectionService.getSelection();
              return this.getDashboards(justBrand)
                .pipe(take(1))
                .subscribe({
                  next: (dashboards) => {
                    subscriber.next(dashboards);
                    subscriber.complete();
                  }
                });
            }
          });
      } else {
        subscriber.next([]);
        subscriber.complete();
      }
    });
  }

  public getDashboardById(dashboardId: string): Observable<DisplayDashboard> {
    return new Observable((subscriber: Subscriber<DisplayDashboard>) => {
      this.getCurrentDashboards()
        .pipe(take(1))
        .subscribe({
          next: (dashboards) => {
            const foundDashboard = dashboards.find(
              (item) => item.dashboardId === parseInt(dashboardId, 10)
            );
            return foundDashboard != null
              ? subscriber.next(foundDashboard)
              : subscriber.error({
                  dashboards,
                  error: `dashboard with id ${dashboardId} does not exist`
                } as DashboardError);
          }
        });
    });
  }

  public getDashboardByLiteralid(
    dashboards: DisplayDashboard[],
    literalid: string
  ): DisplayDashboard | undefined {
    return dashboards.find((item) => item.literalId === literalid);
  }

  public getNormalizedDashboards(
    queryParams: DashboardParams
  ): Observable<DisplayDashboard[]> {
    return new Observable<DisplayDashboard[]>((subscriber) => {
      forkJoin([
        this.apiService.getAvailableDashboards(queryParams),
        this.apiService.getNativeDashboards(queryParams)
      ]).subscribe({
        next: (resp) => {
          const legacy = resp[0];
          const native = resp[1];
          let normalized: DisplayDashboard[] = [];
          const nativeLiteralIds: string[] = [];
          const nativeMenuItems =
            this.getMenuItemsFromDashboardInstances(native);
          nativeLiteralIds.push(
            ...nativeMenuItems.map((item) => item.literalId)
          );
          normalized.push(...nativeMenuItems);
          legacy
            .filter((i) => !nativeLiteralIds.includes(i.nativeDashboardName))
            .forEach((i) => {
              normalized.push({
                dashboardId: i.dashboardId,
                clientId: i.clientId,
                brandId: i.brandId,
                literalId: i.nativeDashboardName,
                productId: i.nativeDashboardName,
                label: i.nativeDashboardLabel,
                routerLinkPart: `${i.dashboardId}/${i.nativeDashboardName}`,
                tableauURIPart: i.name,
                status: CurrentStatus.active,
                isMultiView: false
              });
            });
          normalized = getSorted(normalized, 'label');
          subscriber.next(normalized);
        }
      });
    });
  }

  public getMenuItemsFromDashboardInstances(
    dashboardInstances: NativeDashboard[]
  ): DisplayDashboard[] {
    // Sort instances by dashboardId for consistent order
    const sortedInstances = sortBy(
      dashboardInstances,
      (item) => item.dashboardId
    );
    const user: User | null = this.store.getAsUser('user');
    const isSuperUser =
      user != null
        ? new UserModel(user).isSuperUser(this.selectionService)
        : false;

    const isMeasuredUser =
      user != null ? new UserModel(user).isMeasuredUser() : false;

    const menuItems: DisplayDashboard[] = [];
    const availableInstances = sortedInstances.filter(
      (i) =>
        i.status === 'ACTIVE' ||
        (i.status === 'DRAFT' && (isSuperUser || isMeasuredUser))
    );

    const uniqInstances = uniqBy(availableInstances, 'dashboard.productId');
    for (const instance of uniqInstances) {
      const { dashboard } = instance;

      const instances = availableInstances.filter(
        (inst) => inst.dashboard.productId === dashboard.productId
      );

      const menuItem: DisplayDashboard = {
        dashboardId: dashboard.id,
        label: dashboard.product.name,
        literalId: dashboard.literalId,
        productId: dashboard.productId,
        routerLinkPart: `${dashboard.productId}/${dashboard.literalId}`,
        status: instance.status,
        clientId: instance.clientId,
        brandId: instance.brandId,
        tableauURIPart: null,
        isMultiView:
          instance.dashboard.dashboardPersistenceMode ===
          ProductDashboardPersistenceMode.multiView,
        items:
          instances.length > 1
            ? instances.map(
                (item: NativeDashboard) =>
                  ({
                    dashboardId: item.dashboard.id,
                    label: item.dashboard.displayName,
                    literalId: item.dashboard.literalId,
                    routerLinkPart: `${item.dashboard.productId}/${item.dashboard.literalId}`,
                    status: item.status,
                    clientId: item.clientId,
                    brandId: item.brandId,
                    tableauURIPart: null,
                    dashboardType: item.dashboard.dashboardType,
                    isMultiView:
                      item.dashboard.dashboardPersistenceMode ===
                      ProductDashboardPersistenceMode.multiView
                  }) as DisplayDashboard
              )
            : undefined
      };
      menuItems.push(menuItem);
    }
    return menuItems;
  }

  public landingPage(
    user: UserModel | null = this.authService.getUser()
  ): Observable<string> {
    return new Observable<string>((subscriber) => {
      if (user != null) {
        if (this.selectionService.hasSelection()) {
          this.getDashboardsByBrand(user)
            .pipe(take(1))
            .subscribe({
              next: (value) => {
                subscriber.next(value);
                subscriber.complete();
              }
            });
        } else {
          // Handle selection for Single and Multi Brand Client User/Admin
          // set it to the first brand they have access to for now.
          this.brandRowsService
            .buildBrandRows()
            .pipe(take(1))
            .subscribe({
              next: (brandRows) => {
                let { client, brand } = brandRows[0] as BrandRow;
                if (
                  user.isSuperUser(this.selectionService) &&
                  user.isMeasuredUser()
                ) {
                  // Specifically set to Measured Demo (if it exists)
                  const measuredDemo = brandRows.find(
                    (i) =>
                      i.clientId === demoDefaultClient.clientId &&
                      i.brandId === demoDefaultClient.brandId
                  );
                  if (measuredDemo != null) {
                    client = measuredDemo.client;
                    brand = measuredDemo.brand;
                  }
                }
                this.selectionService.setSelection(client, brand);
                this.getDashboardsByBrand(user)
                  .pipe(take(1))
                  .subscribe({
                    next: (value) => {
                      subscriber.next(value);
                      subscriber.complete();
                    }
                  });
              },
              error: () => {
                subscriber.next('');
                subscriber.complete();
              }
            });
        }
      } else {
        subscriber.next(`products/portfolio/${defaultDashboardLiteralId}`);
        subscriber.complete();
      }
    });
  }

  private getDashboardsByBrand(user: UserModel): Observable<string> {
    const justBrand = this.selectionService.getSelection();
    return new Observable((subscriber) => {
      this.getDashboards(justBrand)
        .pipe(take(1))
        .subscribe({
          next: (dashboards) => {
            const defaultDashboard = getDefaultDashboard(dashboards, user);
            subscriber.next(
              `${this.selectionService.buildSelectionSlug()}/products/${
                defaultDashboard?.routerLinkPart
              }`
            );
            subscriber.complete();
          },
          error: () => {
            subscriber.next('');
            subscriber.complete();
          }
        });
    });
  }
}
