@if (!isLoading && !showUndoButton) {
  <div
    [id]="id"
    class="m-alert"
    [class.m-alert-expanded]="isExpanded"
    [class.m-alert-collapsed]="!isExpanded"
    [class.read-alert]="isRead">
    <span
      class="material-symbols-outlined icon-small icon {{
        getAlertIconClassName()
      }}">
      {{ iconName }}
    </span>
    <div class="alert-content">
      <div class="title-content">
        <div (click)="toggleExpanded($event)" class="title-text">
          {{ title }}
          @if (isResolved) {
            <div
              class="material-symbols-outlined icon-small text-green-700 check-icon">
              check
            </div>
          }
        </div>
        <div class="subtitle-row">
          @if (tag) {
            <m-type-pill [size]="tagSize.small" [text]="tag"></m-type-pill>
          }
          @if (!tag) {
            <div></div>
          }
          @if (isStacked) {
            <m-tooltip
              content="similar alerts"
              position="top"
              [tooltipOptions]="{
                positionLeft: 10,
                positionTop: -5,
              }"
              ><div
                class="material-symbols-outlined icon-small text-gray-500 ml-4">
                note_stack
              </div></m-tooltip
            >
          }
          <div class="c1 text-gray-800 ms-auto">{{ timestamp }}</div>
        </div>
      </div>
      <div class="body-content" [class.body-content-expanded]="isExpanded">
        <div>
          <div class="body-text" [innerHTML]="body"></div>
        </div>
        @if (actionButtonsProps.length) {
          <div>
            @for (
              actionButtonProps of actionButtonsProps;
              track actionButtonProps
            ) {
              <m-button
                [type]="
                  actionButtonProps.type === 'ACTION_BUTTON'
                    ? 'primary'
                    : 'secondary'
                "
                [styleClass]="
                  actionButtonProps.type === 'ACTION_BUTTON' ? 'mb-3' : ''
                "
                size="small"
                (click)="
                  handleActionClick(
                    $event,
                    actionButtonProps.primaryActionButtonProperties,
                    actionButtonProps.type
                  )
                ">
                <span class="b2">
                  {{ actionButtonProps.label }}
                </span>
              </m-button>
            }
          </div>
        }
      </div>
    </div>
    <em
      (click)="toggleExpanded($event)"
      class="material-symbols-outlined open-icon"
      >{{
        isExpanded
          ? "keyboard_arrow_up"
          : isStacked
            ? "keyboard_arrow_right"
            : "keyboard_arrow_down"
      }}</em
    >
  </div>
}

@if (actionButtonsProps.length && showUndoButton) {
  <div class="undo-content">
    <div class="c1 text-gray-800">
      This message is being removed from your list of notifications.
      <div class="progress-bar">
        <div class="progress"></div>
      </div>
    </div>
    <div>
      <m-button
        type="secondary"
        size="small"
        styleClass="px-2 py-1"
        (click)="undoAction($event)"
        ><span class="material-symbols-outlined icon-small text-blue-600">
          replay
        </span>
        <span class="b2"> Undo </span>
      </m-button>
    </div>
  </div>
}

@if (isLoading) {
  <div class="m-alert m-alert-loading">
    <p-skeleton shape="circle" size="2.75rem"></p-skeleton>
    <div class="alert-content">
      <div class="title-content">
        <p-skeleton width="14.4375rem" height="1rem"></p-skeleton>
        <p-skeleton width="5rem" height="1rem"></p-skeleton>
        <div class="subtitle-row">
          <p-skeleton width="2.7rem" height="1.25rem"></p-skeleton>
          <p-skeleton
            class="ms-auto"
            width="4.5rem"
            height="0.75rem"></p-skeleton>
        </div>
      </div>
    </div>
    <em
      class="material-symbols-outlined icon-small text-gray-500 cursor-pointer"></em>
  </div>
}
