/* eslint-disable @typescript-eslint/no-explicit-any */
import { DOCUMENT } from '@angular/common';
import dayjs from 'dayjs';
import {
  Component,
  effect,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { isEqual } from 'lodash-es';

// Stores & Services
import { ContextStore } from '@portal/app/shared/state/context.store';
import { DatasetExportService } from '@portal/app/shared/services/dataset-export.service';
import { MmmExportBannerService } from './mmm-export-banner.service';
import { SelectionService } from '@portal/app/shared/services/selection.service';
import { ViewService } from '@portal/app/shared/services/view.service';
import { DatasetAvailabilityService } from '@portal/app/shared/services/dataset-availability.service';

// Constants & Types
import {
  ExportTableType,
  FieldDefinitions,
  Filter
} from '@portal/app/shared/types';
import { ButtonType } from '@design-system/components/m-button';
import {
  ColumnDefinition,
  FooterData,
  FormattedRow
} from './mmm-export-banner.types';
import { TabViewHeaderSize } from '@design-system/components/m-tab-view';
import { SlideInOutAnimator } from '@libs/common-animations';
import { CreativeAlignment } from '@design-system/components/banner/m-banner';

@Component({
  selector: 'portal-mmm-export-banner',
  templateUrl: './mmm-export-banner.component.html',
  styleUrls: ['./mmm-export-banner.component.scss'],
  animations: [SlideInOutAnimator.slideInUpOutDown()]
})
export class MmmExportBannerComponent implements OnInit, OnChanges {
  @Input() filters: Filter[] = [];
  @Input() fieldDefinitions: FieldDefinitions = {};

  // Banner Configuration
  public readonly bannerConfig = {
    type: 'primary',
    title: 'Your MIM v2 results are now ready!',
    titleNoDownloadResults: 'You have been upgraded to MIM v2!'
  };

  // Export Options
  public exportOption = '';
  public readonly exportOptions = [
    { label: 'CSV', value: 'csv' },
    { label: 'EXCEL', value: 'xlsx' }
  ];

  // UI States
  public showResultsModal = false;
  public showSwitchModal = false;
  public alreadyShown = false;
  public showCongrats = false;

  // Table configuration
  public readonly defaultSortField: string = 'mediaSpend_raw';
  public readonly defaultSortOrder: number = -1;
  public columns: ColumnDefinition[] = [];
  public footerData: FooterData | null = null;
  public selectedChannels: string[] = [];
  public selectedTactics: string[] = [];
  public value: FormattedRow[] = [];

  // Tabview Settings
  public activeIndex = 0;
  public headerSize = TabViewHeaderSize.default;
  public readonly tabPanelHeaders = [
    {
      header: 'Channels',
      count: 0,
      countType: 'draft',
      showCount: true,
      disabled: false
    },
    {
      header: 'Tactics',
      count: 0,
      countType: 'draft',
      showCount: true,
      disabled: false
    }
  ];

  // Calendar settings
  public resolution = 'day';
  public minDate: Date | null = dayjs()?.subtract(18, 'month')?.toDate();
  public maxDate: Date | null = dayjs()?.subtract(1, 'day')?.toDate();
  public firstDayOfWeek = 0;
  public startDate: Date | null = null;
  public endDate: Date | null = null;

  // Miscellaneous
  public readonly ButtonType = ButtonType;
  public readonly CreativeAlignment = CreativeAlignment;

  constructor(
    private readonly datasetAvailabilityService: DatasetAvailabilityService,
    private readonly contextStore: ContextStore,
    public readonly viewService: ViewService,
    private readonly selectionService: SelectionService,
    private readonly datasetExportService: DatasetExportService,
    public readonly mmmExportBannerService: MmmExportBannerService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
    // Use effects to react to changes in the signals
    effect(() => {
      this.initializeComponentState();
    });
  }

  ngOnInit() {
    this.initializeFilters();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['fieldDefinitions'] &&
      changes['fieldDefinitions'].currentValue
    ) {
      // Call setFields() whenever fieldDefinitions changes
      this.mmmExportBannerService.setFields(this.fieldDefinitions);
    }
  }

  /**
   * Method to initialize component state using mmmExportBannerService signals
   */
  private initializeComponentState(): void {
    // Set channels local state used for multiselect model
    this.selectedChannels =
      this.mmmExportBannerService.selectedChannelsSignal();

    // Set tactics local state used for multiselect model
    this.selectedTactics = this.mmmExportBannerService.selectedTacticsSignal();

    // Set table data local state used for column filtering
    this.footerData = this.mmmExportBannerService.footerDataSignal();
    this.value = this.mmmExportBannerService.fetchedDataSignal();
    this.columns = this.mmmExportBannerService.columnsSignal();

    // Initialize state for tab counts
    this.updateTabCounts();
  }

  /**
   * Method to handle initial filter setup and component initialization
   */
  private initializeFilters(): void {
    this.alreadyShown = !!localStorage.getItem(this.getLocalStorageKey());

    if (!this.alreadyShown) {
      this.contextStore.filterContext.subscribe((filters: Filter[]) => {
        this.mmmExportBannerService.setFilters(filters);
        this.datasetAvailabilityService.fetchData(filters);
        this.mmmExportBannerService.init();
      });
    }
  }

  /**
   * Handles the change in export option and triggers data export.
   * @param exportType The type of export selected.
   */
  onExportChange(exportType: string) {
    if (exportType) {
      this.exportData(exportType as ExportTableType);
    }
  }

  /**
   * Exports data in the specified format.
   * @param format The format to export data as.
   */
  exportData(format: ExportTableType): void {
    const { filter, dimensions } =
      this.mmmExportBannerService.prepareCommonReqParamForMIMV2Compare();

    this.datasetExportService.exportData(filter, dimensions, format);
  }

  /**
   * Handles closing of the banner and updates local storage.
   */
  handleClose() {
    localStorage.setItem(this.getLocalStorageKey(), 'true');
    this.mmmExportBannerService.showBannerSignal.set(false);
  }

  /**
   * Generates a unique key for local storage based on client and brand IDs.
   * @returns The local storage key string.
   */
  getLocalStorageKey(): string {
    const { clientId, brandId } = this.selectionService.getClientIdAndBrandId();
    return `mimBannerAlreadyShown_${clientId}_${brandId}`;
  }

  /**
   * Toggles the visibility of the results modal and fetches data if opening.
   */
  toggleResultsModal(): void {
    this.showResultsModal = !this.showResultsModal;
    if (this.showResultsModal) {
      // Prevent body from scrolling
      this.mmmExportBannerService.selectedMetricsSignal.set([]);
      this.renderer.addClass(this.document.body, 'overflow-hidden');
      this.mmmExportBannerService.fetchData();
    } else {
      this.renderer.removeClass(this.document.body, 'overflow-hidden');
    }
  }

  /**
   * Handles changes to the conversion type and fetches data accordingly.
   * @param conversionType The selected conversion type.
   */
  onConversionTypeChange(conversionType: string) {
    this.mmmExportBannerService.setConversionType(String(conversionType));
    this.mmmExportBannerService.fetchData();
  }

  /**
   * Handles changes to selected metrics and updates data and columns.
   * @param selectedMetrics The list of selected metrics.
   */
  onMetricsChange(selectedMetrics: string[]) {
    // Get the metric options in the correct order from metricOptionsSignal
    const metricOptions = this.mmmExportBannerService.metricOptionsSignal();

    // Create an ordered list of selected metrics based on metricOptions order
    const orderedMetrics = selectedMetrics
      .map((metric) => ({
        metric,
        index: metricOptions.findIndex((option) => option.value === metric)
      }))
      .sort((a, b) => a.index - b.index); // Sort based on index in metricOptions

    // Set the ordered metrics
    this.mmmExportBannerService.selectedMetricsSignal.set(
      orderedMetrics.map((item) => item.metric)
    );

    // Update columns and data based on the ordered metrics
    this.columns = this.mmmExportBannerService.generateColumns();
    this.filterDataBasedOnSelectedMetrics(
      orderedMetrics.map((item) => item.metric)
    );
  }

  /**
   * Triggers the switch to the new model via the service.
   */
  switchToNewModel() {
    this.mmmExportBannerService.switchToNewModel();
    // Show congratulations message in modal
    this.showCongrats = true;
    // Show success message in place of button
    this.mmmExportBannerService.showSuccessMessageSignal.set(true);
  }

  private filterDataBasedOnSelectedMetrics(selectedMetrics: string[]) {
    // Filter data
    this.value = this.mmmExportBannerService.fetchedDataSignal().map((row) => {
      const filteredRow: any = {};
      selectedMetrics.forEach((field: string) => {
        filteredRow[field] = row[field];
        filteredRow[`${field}_raw`] = row[`${field}_raw`];
      });
      return filteredRow;
    });

    // Footer remains the same
    this.footerData = this.mmmExportBannerService.footerDataSignal();
  }

  toggleSwitchModal() {
    this.showSwitchModal = !this.showSwitchModal;
  }

  // Modify onTabChange to dynamically show/hide the "Tactics" column
  onTabChange(index: number) {
    this.activeIndex = index;
    const includeTactics = index === 1; // Only include "Tactics" column on "Tactics" tab (index 1)

    this.mmmExportBannerService.includeTacticsSignal.set(includeTactics);
    this.mmmExportBannerService.fetchData();
  }

  channelPanelHide() {
    const channelsChanged = !isEqual(
      this.mmmExportBannerService.selectedChannelsSignal(),
      this.selectedChannels
    );

    if (channelsChanged) {
      this.mmmExportBannerService.updateSelectedChannels(this.selectedChannels);
    }
  }

  tacticPanelHide() {
    const TacticsChanged = !isEqual(
      this.mmmExportBannerService.selectedTacticsSignal(),
      this.selectedTactics
    );

    if (TacticsChanged) {
      this.mmmExportBannerService.updateSelectedTactics(this.selectedTactics);
    }
  }

  /**
   * Updates the count in tabPanelHeaders based on selected items length.
   */
  private updateTabCounts() {
    if (this.tabPanelHeaders[0]) {
      this.tabPanelHeaders[0].count = this.selectedChannels.length;
    }
    if (this.tabPanelHeaders[1]) {
      this.tabPanelHeaders[1].count = this.selectedTactics.length;
    }
  }

  onDateSelect(date: Date) {
    if (!this.startDate || (this.startDate && this.endDate)) {
      this.startDate = date;
      this.endDate = null;
    } else {
      this.endDate = date;
      if (this.startDate > this.endDate) {
        [this.startDate, this.endDate] = [this.endDate, this.startDate];
      }
    }

    if (this.startDate && this.endDate) {
      // Update date states
      this.mmmExportBannerService.setDateSignals(this.startDate, this.endDate);
      this.mmmExportBannerService.updateDayFilter();
      // Fetch data with updated date range
      this.mmmExportBannerService.fetchData();
    }
  }
}
