export enum AlertIconType {
  success = 'success',
  warning = 'warning',
  error = 'error',
  info = 'info',
  conversionPath = 'conversionPath',
  experiment = 'experiment',
  powerOff = 'powerOff',
  networkNode = 'networkNode'
}

export enum AlertDataType {
  error = 'ERROR',
  info = 'INFO',
  warning = 'WARNING',
  success = 'SUCCESS',
  conversionPath = 'CONVERSION_PATH',
  experiment = 'EXPERIMENT',
  powerOff = 'POWER_OFF',
  networkNode = 'NETWORK_NODE'
}

export enum Status {
  unread = 'UNREAD',
  read = 'READ',
  resolved = 'RESOLVED'
}

export const alertIconTypeMap = {
  [AlertDataType.error]: AlertIconType.error,
  [AlertDataType.info]: AlertIconType.info,
  [AlertDataType.warning]: AlertIconType.warning,
  [AlertDataType.success]: AlertIconType.success,
  [AlertDataType.conversionPath]: AlertIconType.conversionPath,
  [AlertDataType.experiment]: AlertIconType.experiment,
  [AlertDataType.powerOff]: AlertIconType.powerOff,
  [AlertDataType.networkNode]: AlertIconType.networkNode
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const AlertIconTypeToNameMap = {
  [AlertIconType.warning]: 'warning_amber',
  [AlertIconType.error]: 'warning_amber',
  [AlertIconType.success]: 'check_circle_outline',
  [AlertIconType.info]: 'info',
  [AlertIconType.conversionPath]: 'conversion_path',
  [AlertIconType.experiment]: 'experiment',
  [AlertIconType.powerOff]: 'power_off',
  [AlertIconType.networkNode]: 'network_node'
};

export enum AlertButtonType {
  markAsResolved = 'MARK_AS_RESOLVED_BUTTON',
  actionButton = 'ACTION_BUTTON',
  markAsUnresolved = 'MARK_AS_UNRESOLVED_BUTTON',
  viewDetails = 'VIEW_DETAILS'
}

export interface IAlertPrimaryActionButtonProps {
  label: string;
  order: number;
  type: AlertButtonType;
  primaryActionButtonProperties?: IPrimaryActionButtonProperties;
}

export interface IPrimaryActionButtonProperties {
  displayName: string;
  externalLink: string;
  routingLink: string;
  tooltip?: string;
  sequenceNumber: number;
}

export interface AlertItemProps {
  type?: string;
  brandName?: string;
  clientId?: number;
  brandId?: number;
  id: number;
  alertConfigId?: number;
  identifier?: string;
  alertLogIds?: number[];
  alertIcon?: AlertIconType;
  title: string;
  isRead?: boolean;
  isResolved?: boolean;
  tag?: string;
  isStacked?: boolean;
  stackedCount?: number;
  timestamp?: string;
  body: string[];
  shortDescription?: string[];
  actionButtonsProps: IAlertPrimaryActionButtonProps[];
  showOrangeBorder?: boolean;
  updateIsExpanded?: (id: number) => void;
  isActionAble: boolean;
  icon?: string;
  isVisible?: boolean;
  resolvedBy?: string;
  resolvedTime?: string;
  expanded?: boolean;
  isUndoView?: boolean;
  isLongDescription: boolean;
  severity?: string;
  studyName?: string;
  alertStartDate?: string;
}

export interface StackedAlertRequestParams {
  alertConfigId: number;
  identifier: string;
}

export interface UpdateAlertStatusRequestParams
  extends StackedAlertRequestParams {
  status: Status;
  alertLogIds: number[];
  clientId?: number;
  brandId?: number;
}

export const errorClassIconList = [
  AlertIconType.error,
  AlertIconType.conversionPath,
  AlertIconType.experiment,
  AlertIconType.powerOff,
  AlertIconType.networkNode
];

export type IGroupByFieldsConfigs = Record<string, GroupByFieldConfig>;

export interface GroupByFieldConfig {
  header: string;
  class?: string | ((data: AlertItemProps[]) => string);
  value?: string | ((data: AlertItemProps[]) => any);
  computedValue?: string;
  computedClass?: string;
}

export enum notificationTabs {
  active = 'Active',
  resolved = 'Resolved'
}

export interface IColumnFields {
  header: string;
  field: string;
  class: string;
  type: string;
}

export interface ISelectedDateRange {
  startDate: string;
  endDate: string;
  custom: boolean;
}

export interface IFilterChange {
  search: string;
  selectedServices: string[];
  selectedDateRange: ISelectedDateRange;
}

export enum CurrentAlertsViewType {
  normal = 'normal',
  stacked = 'stacked'
}
export enum TabType {
  active = 'ACTIVE',
  resolved = 'RESOLVED'
}
export type AlertsMode = 'popup' | 'table';
export enum NotificationViewMode {
  popup = 'POPUP',
  page = 'PAGE'
}

export type SeverityType = 'Low' | 'Medium' | 'High' | 'Critical';

export const SeverityTypeClassMap = {
  Low: 'text-gray-700',
  Medium: 'text-yellow-700',
  High: 'text-orange-300',
  Critical: 'text-red-700'
};
