@if (!isChartReady) {
  <div class="h-full">
    <m-skeleton width="334px" height="382px"></m-skeleton>
  </div>
}
@if (!isLoading && !isDataAvailable) {
  <div class="h-full">
    <m-card variant="small"><m-not-available></m-not-available></m-card>
  </div>
}

@if (isChartReady && isDataAvailable) {
  <div class="h-full">
    <m-card variant="small">
      <div header class="flex items-center w-full mb-2">
        <span class="sh2 mr-1">Top {{ activeMetric?.title }}</span>
        <!-- Show dropdown only if activeChannel is not provided -->
        @if (!activeChannel) {
          <m-dropdown
            [options]="dimensionsOptions"
            [(ngModel)]="dimensions"
            (formControlChange)="onDimensionsChange($event.value)"
            class="mr-2">
          </m-dropdown>
        }
        <!-- Show span if activeChannel is provided -->
        @if (activeChannel) {
          <span class="sh2 mr-1 capitalize">By Tactic</span>
        }
      </div>
      <div class="text-center w-full">
        <m-horizontal-bar-chart
          [legend]="horizontalBarChartOptions?.legend"
          [plotOptions]="horizontalBarChartOptions?.plotOptions"
          [series]="horizontalBarChartOptions?.series"
          [tooltip]="horizontalBarChartOptions?.tooltip"
          [xAxis]="horizontalBarChartOptions?.xAxis"
          [yAxis]="horizontalBarChartOptions?.yAxis"
          [title]="horizontalBarChartOptions?.title"
          [width]="300"
          [height]="300"></m-horizontal-bar-chart>
      </div>
    </m-card>
  </div>
}
