<ng-container *ngFor="let group of asFilterSetGroups(layout); let idx = index">
  <div
    *ngIf="idx === 0"
    class="d-flex flex-wrap"
    [ngClass]="[
      asFilterSetGroups(layout).length === 1 ? 'col-lg-12' : 'col-lg-8',
      isMeasuredBenchmark(filterConfig.dashboardLiteralId)
        ? 'gap-4 px-0'
        : 'gap-3',
    ]">
    <ng-container *ngIf="showChild">
      <div class="saved-time">
        <p class="saved-time-text">
          <span class="time-label">Historical reference date: </span>
          <span>{{ formatRangeDate }}</span>
        </p>
      </div>
    </ng-container>
    <ng-container
      *ngFor="let item of asContext(group)"
      class="filterSetAppendTo">
      <div
        *ngIf="
          (!showChild && item.name !== 'childCampaign') ||
          (showChild && item.name === 'childCampaign')
        ">
        <ng-template
          [ngIf]="
            item.layoutType === 'dateRange' || item.layoutType === 'DATE_RANGE'
          "
          [ngIfElse]="elseBlock">
          <portal-date-range-picker
            [label]="item.label"
            [dateFilter]="item"
            [compareFilter]="compareFilter"
            [ngClass]="[item.layoutType, item.name, 'context-filter']"
            [leftSide]="true"
            [showInputPickers]="
              !isMeasuredBenchmark(filterConfig.dashboardLiteralId)
            "
            [showPresetSelection]="
              !isMeasuredBenchmark(filterConfig.dashboardLiteralId)
            "
            [minDate]="minDate"
            [maxDate]="maxDate"
            [dateFormatType]="monthDateYearFormat"
            [date]="setAsDateRange(item.value)"
            [relativeDate]="relativeDate"
            (dateChanged)="
              updateDateRange(idx, item.name, $event)
            "></portal-date-range-picker>
        </ng-template>
        <ng-template #elseBlock>
          <label
            [ngClass]="[item.layoutType, item.name, 'context-filter', 'h-100']"
            >{{ item.label }}
            <div
              *ngIf="item.layoutType === layoutTypes.verticalSeperator"
              class="h-100">
              <portal-seperator
                [layoutType]="item.layoutType"></portal-seperator>
            </div>
            <p-dropdown
              *ngIf="
                item.layoutType === 'select' || item.layoutType === 'SELECT'
              "
              [(ngModel)]="item.value"
              [options]="item.options"
              [showClear]="false"
              [filter]="false"
              [baseZIndex]="2"
              [appendTo]="filterSetAppendTo"
              [panelStyleClass]="filterConfig.dashboardLiteralId"
              (onShow)="cacheValue(item.name, item.value)"
              (onHide)="updateContextFilter(idx, item.name)"
              scrollHeight="20rem"
              [placeholder]="item.label"
              [disabled]="isFiltersUpdating">
              <ng-template
                pTemplate="selectedItem"
                *ngIf="
                  filterConfig.dashboardLiteralId === 'benchmarks-drill-down' &&
                  item.name === 'tactic'
                ">
                <div>
                  <div>
                    <span
                      tooltipPosition="right"
                      [pTooltip]="item.label.length >= 27 ? item.label : ''"
                      tooltipStyleClass="saas-tooltip"
                      class="option-name">
                      {{
                        item.value || getChannelName(item.options)
                          | ellipsis: 27
                      }}
                    </span>
                    <div class="option-tag">
                      {{ item.value ? "Tactic" : "Channel" }}
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template
                let-option
                pTemplate="item"
                *ngIf="
                  filterConfig.dashboardLiteralId === 'benchmarks-drill-down' &&
                  item.name === 'tactic'
                ">
                <div>
                  <span
                    tooltipPosition="right"
                    [pTooltip]="option.label.length >= 27 ? option.label : ''"
                    tooltipStyleClass="saas-tooltip"
                    class="option-name"
                    >{{ option.label | ellipsis: 27 }}</span
                  >
                  <div class="option-tag">
                    {{ option.value ? "Tactic" : "Channel" }}
                  </div>
                </div>
              </ng-template>
            </p-dropdown>
            <p-multiSelect
              *ngIf="
                item.layoutType === 'multiselect' ||
                item.layoutType === 'MULTI_SELECT'
              "
              #multiSelect
              [virtualScroll]="item.options.length > 100"
              [virtualScrollItemSize]="44"
              [virtualScrollOptions]="{
                items: asStringArray(item.options),
              }"
              [(ngModel)]="item.value"
              [disabled]="isFiltersUpdating"
              [options]="item.options"
              [filter]="true"
              filterPlaceHolder="Search"
              [baseZIndex]="2"
              (onPanelShow)="cacheValue(item.name, item.value)"
              (onChange)="
                handleFilteredMultiSelectAllSelectionDeSelection(
                  idx,
                  multiSelect,
                  item.name
                )
              "
              (onPanelHide)="updateContextFilter(idx, item.name)"
              scrollHeight="20rem"
              [appendTo]="filterSetAppendTo"
              maxSelectedLabels="1"
              selectedItemsLabel="{0} selected"
              [ngClass]="item.additionalFields ? 'extended-dropdown' : ''">
              <ng-template let-option pTemplate="item">
                <span
                  tooltipPosition="right"
                  [pTooltip]="option.label.length >= 27 ? option.label : ''"
                  tooltipStyleClass="saas-tooltip">
                  {{ option.label | ellipsis: 27 }}
                </span>
              </ng-template>
              <ng-template *ngIf="item.additionalFields" pTemplate="footer">
                <div class="additional-fields-message">
                  A few {{ item.label | lowercase }} may not be visible due to
                  chosen filters.
                  <span
                    class="filter-footer-link"
                    (click)="onMultiselectFooterClickHandler(item)"
                    >View all {{ item.label | lowercase }}</span
                  >
                </div>
              </ng-template>
            </p-multiSelect>
            <div
              *ngIf="item.layoutType === 'NUMBER_INPUT_BOX'"
              class="p-inputgroup">
              <span class="p-inputgroup-addon">$</span>
              <p-inputNumber
                (onBlur)="updateContextFilter(idx, item.name)"
                (onKeyDown)="onKeyDown(idx, item.name, $event)"
                [(ngModel)]="item.value"
                [minFractionDigits]="2"></p-inputNumber>
            </div>

            <p-selectButton
              *ngIf="item.layoutType === 'TOGGLE'"
              name="toggle"
              class="filter-toggle"
              [options]="asStringArray(item.options)"
              [(ngModel)]="item.value"
              (onChange)="updateContextFilter(idx, item.name)">
            </p-selectButton>
            <portal-button-group
              *ngIf="item.layoutType === 'buttonGroup'"
              [options]="item.options"
              [(selectedOption)]="item.value"
              (selectedOptionChange)="updateContextFilter(idx, item.name)">
            </portal-button-group> </label
        ></ng-template>
      </div>
    </ng-container>
    <label class="reset-filters" *ngIf="showResetSelection">
      <button
        *ngIf="showResetSelection"
        pButton
        class="p-button p-button-text"
        (click)="resetFilterSelection()"
        label="Reset selections"></button>
    </label>
  </div>

  <div
    *ngIf="idx === 1"
    class="custom-opt-div col-lg-4 mt-md-2 mt-lg-0 d-flex justify-content-start justify-content-lg-end"
    [ngClass]="{
      'px-0': isMeasuredBenchmark(filterConfig.dashboardLiteralId),
    }">
    <ng-container *ngFor="let item of asContext(group)">
      <portal-date-range-picker
        *ngIf="
          item.layoutType === 'dateRange' || item.layoutType === 'DATE_RANGE'
        "
        [source]="'nativeDashBoard'"
        [label]="item.label"
        [dateFilter]="item"
        [compareFilter]="compareFilter"
        [ngClass]="[item.layoutType, item.name, 'context-filter']"
        [leftSide]="false"
        [showInputPickers]="
          !isMeasuredBenchmark(filterConfig.dashboardLiteralId)
        "
        [showPresetSelection]="
          !isMeasuredBenchmark(filterConfig.dashboardLiteralId)
        "
        [maxDate]="maxDate"
        [dateFormatType]="monthDateYearFormat"
        [date]="setAsDateRange(item.value)"
        [relativeDate]="relativeDate"
        (dateChanged)="
          updateDateRange(idx, item.name, $event)
        "></portal-date-range-picker>
      <ng-container *ngIf="item.layoutType === 'DOWNLOAD_ICON'">
        <button
          pButton
          class="p-button-text p-button-icon-only"
          pTooltip="Export your plan"
          tooltipPosition="bottom"
          tooltipStyleClass="saas-tooltip"
          showDelay="800"
          (click)="menu.toggle($event); getExportMenu(item)">
          <span class="material-symbols-outlined"> ios_share </span>
        </button>
        <p-menu #menu [popup]="true" appendTo="body" [model]="exportMenu">
        </p-menu>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
<ng-container *ngIf="isFilterPopupVisible">
  <portal-popup
    [(dialogVisibility)]="isFilterPopupVisible"
    [dialogTitle]="'All ' + filterPopupDialogTitle"
    dialogSubTitle="All of your {{
      filterPopupDialogTitle | lowercase
    }}, independent of filtering options"
    [tableColumns]="filterPopupTableColumns"
    (dialogVisibilityChange)="closeFilterPopup()"
    [tableData]="filterPopupTableData"></portal-popup>
</ng-container>
