/* eslint-disable @typescript-eslint/naming-convention */

/**
 * Configuration for typography styles and attributes.
 *
 * This object defines font families, style specifications for various typography elements
 * (like headings, body text, captions, etc.), and font weights.
 */
export const typographyConfig = {
  /**
   * Defines the font families used in the application.
   */
  fontFamily: {
    sans: ['Inter', 'sans-serif']
  },
  /**
   * Describes the styles for various typographic elements.
   * Each style includes specifications like line height, display name (preview),
   * font size, and font weight.
   */
  styles: {
    dp1: {
      fontFamily: '"Inter", sans-serif',
      lineHeight: '3.5rem',
      preview: 'Display Large',
      size: '3rem',
      weight: 'light'
    },
    dp2: {
      fontFamily: '"Inter", sans-serif',
      lineHeight: '1.5rem',
      preview: 'Display Medium',
      size: '1.25rem',
      weight: 'regular'
    },
    dp3: {
      fontFamily: '"Inter", sans-serif',
      lineHeight: '1.75rem',
      preview: 'Display Medium Bold',
      size: '1.25rem',
      weight: 'medium'
    },
    h1: {
      fontFamily: '"Inter", sans-serif',
      lineHeight: '3rem',
      preview: 'Heading 1',
      size: '2.5rem',
      weight: 'light'
    },
    h2: {
      fontFamily: '"Inter", sans-serif',
      lineHeight: '2.5rem',
      preview: 'Heading 2',
      size: '2rem',
      weight: 'light'
    },
    h3: {
      fontFamily: '"Inter", sans-serif',
      lineHeight: '2rem',
      preview: 'Heading 3',
      size: '1.75rem',
      weight: 'regular'
    },
    h4: {
      fontFamily: '"Inter", sans-serif',
      lineHeight: '2rem',
      preview: 'Heading 4',
      size: '1.5rem',
      weight: 'regular'
    },
    h5: {
      fontFamily: '"Inter", sans-serif',
      lineHeight: '1.75rem',
      preview: 'Heading 5',
      size: '1.38rem',
      weight: 'regular'
    },
    sh1: {
      fontFamily: '"Inter", sans-serif',
      lineHeight: '1.5rem',
      preview: 'Subheading Regular',
      size: '1rem',
      weight: 'regular'
    },
    sh2: {
      fontFamily: '"Inter", sans-serif',
      lineHeight: '1.5rem',
      preview: 'Subheading Medium',
      size: '1rem',
      weight: 'medium'
    },
    sh3: {
      fontFamily: '"Inter", sans-serif',
      lineHeight: '1.5rem',
      preview: 'Subheading Bold',
      size: '1rem',
      weight: 'bold'
    },
    b1: {
      fontFamily: '"Inter", sans-serif',
      lineHeight: '1.38rem',
      preview: 'Body Regular',
      size: '0.88rem',
      weight: 'regular'
    },
    b2: {
      fontFamily: '"Inter", sans-serif',
      lineHeight: '1.38rem',
      preview: 'Body Medium',
      size: '0.88rem',
      weight: 'medium'
    },
    b3: {
      fontFamily: '"Inter", sans-serif',
      lineHeight: '1.38rem',
      preview: 'Body Bold',
      size: '0.88rem',
      weight: 'bold'
    },
    c1: {
      fontFamily: '"Inter", sans-serif',
      lineHeight: '1rem',
      preview: 'Caption Regular',
      size: '0.75rem',
      weight: 'regular'
    },
    c2: {
      fontFamily: '"Inter", sans-serif',
      lineHeight: '1rem',
      preview: 'Caption Medium',
      size: '0.75rem',
      weight: 'medium'
    },
    c3: {
      fontFamily: '"Inter", sans-serif',
      lineHeight: '1rem',
      preview: 'Caption Bold',
      size: '0.75rem',
      weight: 'bold'
    },
    'icon-tiny': {
      fontFamily: 'Material Symbols Outlined',
      lineHeight: '0.5rem',
      preview: 'Icon Small',
      size: '0.5rem',
      weight: 'regular'
    },
    'icon-xsmall': {
      fontFamily: 'Material Symbols Outlined',
      lineHeight: '1rem',
      preview: 'Icon Extra Small',
      size: '1rem',
      weight: 'regular'
    },
    'icon-small': {
      fontFamily: 'Material Symbols Outlined',
      lineHeight: '1.25rem',
      preview: 'Icon Small',
      size: '1.25rem',
      weight: 'regular'
    },
    'icon-regular': {
      fontFamily: 'Material Symbols Outlined',
      lineHeight: '1.5rem',
      preview: 'Icon Regular',
      size: '1.5rem',
      weight: 'regular'
    },
    'icon-extra-large': {
      fontFamily: '',
      lineHeight: '3rem',
      preview: 'Icon Regular',
      size: '3rem',
      weight: 'regular'
    }
  },
  /**
   * Defines the font weights with their numeric representation.
   */
  weights: {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 600
  }
};
