<div class="m-table m-rowgroup-table h-100">
  @if (!tableDataLoading) {
    <p-table
      [value]="value"
      styleClass="p-datatable-lg"
      rowGroupMode="subheader"
      [groupRowsBy]="fieldNametoGroupBy"
      [columns]="columns"
      [(selection)]="selectedRowsData"
      [scrollable]="scrollable"
      [scrollHeight]="scrollHeight">
      <ng-template pTemplate="header">
        <tr>
          @if (enableRowSelectionCheckbox) {
            <th class="min-w-[52px]" scope="col">
              <p-tableHeaderCheckbox
                (click)="onSelectionChange($event)"
                [disabled]="!enableTableHeaderCheckbox"></p-tableHeaderCheckbox>
            </th>
          }
          @for (col of columns; track col.field) {
            <th
              [ngClass]="col?.class"
              scope="col"
              [pSortableColumn]="
                sortFields.includes(col.field) ? col.field : null
              ">
              <div class="flex">
                <div [innerHTML]="col?.header"></div>
                @if (sortFields.includes(col.field)) {
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                }
              </div>
            </th>
          }
        </tr>
      </ng-template>

      <ng-template pTemplate="groupheader" let-group>
        @if (groupByFieldConfigLength >= 1) {
          <tr pRowGroupHeader>
            <td [attr.colspan]="(columns?.length || 0) + 1">
              <ng-container
                *ngIf="
                  groupByFieldsConfigs[group[fieldNametoGroupBy]] as config
                ">
                <span class="sh2 text-gray-1000">{{ config.header }}</span>
                @if (config.class || config.value) {
                  <span [ngClass]="config.computedClass || ''">
                    {{ config.computedValue }}
                  </span>
                }
              </ng-container>
            </td>
          </tr>
        }
      </ng-template>

      <ng-template pTemplate="body" let-rowData>
        @if (rowData.isVisible) {
          <tr
            class="clickable-row cursor-pointer"
            [class.expanded-row]="rowData.expanded"
            [class.read-alert]="rowData.isRead"
            (click)="toggleRow(rowData)">
            @if (enableRowSelectionCheckbox) {
              <td>
                <p-tableCheckbox
                  [value]="rowData"
                  (click)="onSelectionChange($event)"
                  [disabled]="rowData.isRead"></p-tableCheckbox>
              </td>
            }
            <ng-container>
              @for (column of columns; track column) {
                <td [ngClass]="column.class">
                  <ng-container>
                    @switch (column.type) {
                      @case ("iconTitle") {
                        <div class="flex">
                          <span
                            class="material-symbols-outlined icon-small mr-2 {{
                              getAlertIconClassName(rowData.alertIcon)
                            }}">
                            {{ rowData.icon }}
                          </span>
                          <span>{{ rowData[column.field] }}</span>
                          @if (rowData.isStacked) {
                            <m-tooltip
                              content="{{
                                rowData.stackedCount
                              }} similar alerts"
                              position="top"
                              [tooltipOptions]="{
                                positionLeft: 10,
                                positionTop: -5,
                              }">
                              <div
                                class="material-symbols-outlined icon-small text-gray-500 ml-2">
                                note_stack
                              </div>
                            </m-tooltip>
                          }
                        </div>
                      }
                      @case ("tag") {
                        @if (rowData[column.field]) {
                          <m-type-pill
                            [size]="tagSize.small"
                            [text]="rowData[column.field]"
                            [backgroundColor]="
                              rowData.expanded
                                ? colors['gray-100']
                                : colors['gray-200']
                            "></m-type-pill>
                        }
                      }
                      @case ("text") {
                        {{ rowData[column.field] }}
                      }
                      @case ("ellipsisText") {
                        @if (rowData[column.field] !== "NA") {
                          <m-tooltip
                            [content]="rowData[column.field]"
                            position="top"
                            class="inline-flex"
                            [tooltipOptions]="{
                              positionTop: -6,
                              positionLeft: 3,
                            }">
                            <span class="ellipsis">{{
                              rowData[column.field]
                            }}</span>
                          </m-tooltip>
                        } @else {
                          <span class="ellipsis">{{
                            rowData[column.field]
                          }}</span>
                        }
                      }
                      @case ("severity") {
                        <span
                          class="{{
                            getSeverityClassName(rowData[column.field])
                          }}">
                          {{ rowData[column.field] }}
                        </span>
                      }
                      @case ("avatarText") {
                        <m-tooltip
                          [content]="rowData[column.field]"
                          position="top"
                          class="inline-flex"
                          [tooltipOptions]="{
                            positionTop: -6,
                            positionLeft: 3,
                          }">
                          <m-avatar
                            [avatars]="[
                              {
                                initials: getFirstLetterFromEmail(
                                  rowData[column.field]
                                ),
                                bg: colors['blue-400'],
                                color: colors['gray-000'],
                              },
                            ]"></m-avatar>
                        </m-tooltip>
                        <span class="ml-3">{{ rowData.resolvedTime }}</span>
                      }
                      @case ("action") {
                        <div
                          class="flex justify-center items-center"
                          [class.stacked-arrow]="rowData.isStacked">
                          @if (rowData.isStacked) {
                            <div class="mr-1 b1">
                              {{ rowData.stackedCount }}
                            </div>
                          }
                          <em class="material-symbols-outlined open-icon">
                            {{
                              rowData.expanded
                                ? "keyboard_arrow_up"
                                : rowData.isStacked
                                  ? "keyboard_arrow_right"
                                  : "keyboard_arrow_down"
                            }}
                          </em>
                        </div>
                      }
                    }
                  </ng-container>
                </td>
              }
            </ng-container>
          </tr>
        }
        @if (rowData.expanded && rowData.isVisible) {
          <tr
            class="expanded-content-row"
            [class.without-check-box]="!enableRowSelectionCheckbox">
            <td
              [attr.colspan]="(columns?.length || 0) + 1"
              style="border-top: none">
              <div class="expanded-content-wrapper">
                <div class="expanded-content b1 text-gray-1000 mb-4">
                  <div>
                    <div [innerHTML]="rowData.body"></div>
                  </div>
                </div>
                @if (rowData.actionButtonsProps?.length) {
                  <div class="flex gap-3">
                    @for (
                      actionButtonProps of rowData.actionButtonsProps;
                      track actionButtonProps
                    ) {
                      <m-button
                        [type]="
                          actionButtonProps.type === 'ACTION_BUTTON'
                            ? 'primary'
                            : 'secondary'
                        "
                        size="small"
                        (click)="handleActionClick(actionButtonProps, rowData)">
                        <span class="b2">
                          {{ actionButtonProps.label }}
                        </span>
                      </m-button>
                    }
                  </div>
                }
              </div>
            </td>
          </tr>
        }

        @if (rowData.isUndoView) {
          <tr>
            <td
              [attr.colspan]="(columns?.length || 0) + 1"
              style="border-top: none"
              class="p-0">
              <div class="undo-content">
                <div class="c1 text-gray-800">
                  This message is being removed from your list of notifications.
                  <div class="progress-bar">
                    <div class="progress"></div>
                  </div>
                </div>
                <div>
                  <m-button
                    type="secondary"
                    size="small"
                    styleClass="px-2 py-1"
                    (click)="undoAction(rowData)"
                    ><span
                      class="material-symbols-outlined icon-small text-blue-600">
                      replay
                    </span>
                    <span class="b2"> Undo </span>
                  </m-button>
                </div>
              </div>
            </td>
          </tr>
        }
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="100%" class="w-100 not-available-container">
            <div
              class="p-table-tbody-empty flex gap-3 flex-col justify-center items-center grow h-100">
              @if (!searchInputHasData) {
                <i
                  class="material-symbols-outlined icon-extra-large text-blue-300"
                  >air</i
                >
                <div class="sh2 text-gray-1000">All good!</div>
                <div class="b1 text-gray-1000">
                  You have nothing new for now.
                </div>
              } @else {
                <img
                  class="not-available-image"
                  src="assets/images/empty-state.svg"
                  alt="Not Available" />
                <div class="flex flex-col gap-2 items-center">
                  <div class="h5 m-0">Nothing found</div>
                  <span class="b1">Try changing your search phrase.</span>
                </div>
              }
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  } @else {
    <div class="flex flex-col gap-3">
      @for (item of [1, 2, 3, 4, 5, 6, 7, 8]; track item) {
        <ng-container>
          <div class="flex h-16 justify-between items-center">
            @for (
              width of ["3rem", "50rem", "11rem", "8rem", "5rem"];
              track width
            ) {
              <ng-container>
                <m-skeleton [width]="width" height="1rem"></m-skeleton>
              </ng-container>
            }
          </div>
        </ng-container>
      }
    </div>
  }
</div>
