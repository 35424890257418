/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import {
  ICompositeDataSource,
  IDsInstance,
  IStatusUpdateRequest,
  ITargetTableNames,
  ITblFileUploadSummary,
  ModelAssociation,
  Status
} from '@libs/apis';
import { lastValueFrom } from 'rxjs';
import { Utility } from '../helpers/utility';
import { IAcknowledgmentResponse } from '../models/IAcknowledgmentResponse';
import { ICompositeDataSourceIdUpdateRequest } from '../models/ICompositeDataSourceIdUpdateRequest';
import { IConversionType } from '../models/IConversionType';
import { IDataSource } from '../models/IDataSource';
import { IDataSourceDsInstanceDTO } from '../models/IDataSourceDsInstanceDTO';
import { IDsInstanceDTO } from '../models/IDsInstanceDTO';
import { IDtcReplicationClientDestinationConfig } from '../models/IDtcReplicationClientDestinationConfig';
import { IJobStatus } from '../models/IJobStatus';
import { IManageFactDataApiOperation } from '../models/IManageFactDataApiOperation';
import { IStatusUpdateResponse } from '../models/IStatusUpdateResponse';
import { ITargetTableColumns } from '../models/ITargetTableColumns';
import { IDataSourceFileUploadRegistrySummary } from '../models/IDataSourceFileUploadRegistrySummary';
import { IPreSignedUrl } from '../models/IPreSignedUrl';
import { IReportCustomerSuccessRequest } from '../models/IReportCustomerSuccessRequest';
import { IDataSourceDisplayData } from '../models/IDataSourceDisplayData';
import { IDataSourceValidateSOTRequest } from '../models/IDataSourceValidateSOTRequest';
import { IDataSourceValidateSOT } from '../models/IDataSourceValidateSOT';
import { IReportTransactionRollupRequest } from '../models/IReportTransactionRollupRequest';
import { IContactSupportRequest } from '../models/IContactSupportRequest';

const promiseFrom = lastValueFrom;

export class ManageFactDataApis implements IManageFactDataApiOperation {
  private constructor(
    private readonly httpClient: HttpClient,
    private readonly apiV1: string,
    private readonly apiV2: string
  ) {}

  public static manageFactDataApiOperation(
    httpClient: HttpClient,
    apiV1: string,
    apiV2: string
  ): IManageFactDataApiOperation {
    return new ManageFactDataApis(httpClient, apiV1, apiV2);
  }

  public async getDataSource(
    clientId?: number,
    dataSourceStatus?: string,
    dsOriginConfigStatus?: string,
    dataSourceType?: string
  ): Promise<IDataSource[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/data-source`,
      queryParameters: {
        clientId: clientId || null,
        dataSourceStatus: dataSourceStatus || null,
        dsOriginConfigStatus: dsOriginConfigStatus || null,
        dataSourceType: dataSourceType || null
      }
    });
    return promiseFrom(this.httpClient.get<IDataSource[]>(url));
  }

  public async getSelectedDataSourceId(dataSourceId: string): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/data-source/:dataSourceId`,
      pathVariables: { dataSourceId }
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async getAllDsInstances(
    clientId: number,
    dataSourceId?: string,
    status?: Status,
    associations?: ModelAssociation
  ): Promise<IDsInstance[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/ds-instances`,
      queryParameters: {
        clientId,
        dataSourceId: dataSourceId || null,
        status: status || null,
        associations: associations || null
      }
    });
    return promiseFrom(this.httpClient.get<IDsInstance[]>(url));
  }

  public async getDSInstancesById(id: number): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/ds-instances/:id`,
      pathVariables: { id }
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async getDefaultInstanceData(): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/data-source/dummy-default-instance`
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async getTargetTableColumns(
    targetTableName: string
  ): Promise<ITargetTableColumns[]> {
    return promiseFrom(
      this.httpClient.get<ITargetTableColumns[]>(
        `${this.apiV2}/data-source/column-names?targetTableName=${targetTableName}`
      )
    );
  }

  public async getTargetTableColumnsWithMetadata(
    targetTableName: string
  ): Promise<ITargetTableColumns[]> {
    return promiseFrom(
      this.httpClient.get<ITargetTableColumns[]>(
        `${this.apiV2}/data-source/column-names/metadata?targetTableName=${targetTableName}`
      )
    );
  }

  public async getDSWithMultipleDSInstancesById(
    dataSourceId: string
  ): Promise<IDataSourceDsInstanceDTO> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/data-source/default-ds-instance/:dataSourceId`,
      pathVariables: { dataSourceId }
    });
    return promiseFrom(this.httpClient.get<IDataSourceDsInstanceDTO>(url));
  }

  public async uploadToS3(
    fileData: FormData,
    clientId: number,
    dataSourceId: string,
    dsInstanceId: number
  ): Promise<IAcknowledgmentResponse> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/s3-bucket/upload-file`,
      queryParameters: {
        clientId,
        dataSourceId,
        dsInstanceId
      }
    });
    return promiseFrom(
      this.httpClient.post<IAcknowledgmentResponse>(url, fileData)
    );
  }

  public async getPreSignedUrl(
    clientId: number,
    fileName: string,
    dataSourceId: string
  ): Promise<IPreSignedUrl> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/s3-bucket/presigned-url`,
      queryParameters: {
        clientId,
        fileName,
        dataSourceId
      }
    });
    return promiseFrom(this.httpClient.get<IPreSignedUrl>(url));
  }

  public async updateFileUploadRegistry(
    clientId: number,
    fileName: string,
    dataSourceId: string,
    dsInstanceId: number
  ): Promise<IDataSourceFileUploadRegistrySummary> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/data-source-file-upload-registry/upload-summary`,
      queryParameters: {
        clientId,
        fileName,
        dataSourceId,
        dsInstanceId
      }
    });
    return promiseFrom(
      this.httpClient.post<IDataSourceFileUploadRegistrySummary>(url, {})
    );
  }

  public async getFileUploadRegistryByFileName(
    clientId: number,
    fileName: string,
    dataSourceId: string,
    dsInstanceId: number
  ): Promise<IDataSourceFileUploadRegistrySummary> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/data-source-file-upload-registry/file-summary`,
      queryParameters: {
        clientId,
        fileName,
        dataSourceId,
        dsInstanceId
      }
    });
    return promiseFrom(
      this.httpClient.get<IDataSourceFileUploadRegistrySummary>(url)
    );
  }

  public async uploadToS3Bucket(
    fileData: FormData
  ): Promise<IAcknowledgmentResponse> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/s3-bucket/geo-ms-file-upload`
    });
    return promiseFrom(
      this.httpClient.post<IAcknowledgmentResponse>(url, fileData)
    );
  }

  public async uploadSOTToS3Bucket(
    fileData: FormData,
    clientId: number | undefined,
    dataSourceId: string
  ): Promise<IAcknowledgmentResponse> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/s3-bucket/upload-file-sot`,
      queryParameters: {
        clientId,
        dataSourceId
      }
    });
    return promiseFrom(
      this.httpClient.post<IAcknowledgmentResponse>(url, fileData)
    );
  }

  public async getAllCompositeDataSource(
    status?: Status
  ): Promise<ICompositeDataSource[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/composite-data-source`,
      queryParameters: { status: status || null }
    });
    return promiseFrom(this.httpClient.get<ICompositeDataSource[]>(url));
  }

  public async getAllConversionTypes(
    clientId?: number,
    status?: Status,
    brandId?: number
  ): Promise<IConversionType[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/conversion-types`,
      queryParameters: {
        status: status === Status.ACTIVE ? status : null,
        clientId: clientId || null,
        brandId: brandId || null
      }
    });
    return promiseFrom(this.httpClient.get<IConversionType[]>(url));
  }

  public async getSelectedConversionRecords(
    id: number
  ): Promise<IConversionType> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/conversion-types/:id`,
      pathVariables: { id }
    });
    return promiseFrom(this.httpClient.get<IConversionType>(url));
  }

  public async createConversionType(
    data: IConversionType
  ): Promise<IConversionType> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/conversion-types`
    });
    return promiseFrom(this.httpClient.post<IConversionType>(url, data));
  }

  public updateConversionType(
    id: number,
    data: IConversionType
  ): Promise<IConversionType> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/conversion-types/:id`,
      pathVariables: { id }
    });
    return promiseFrom(this.httpClient.put<IConversionType>(url, data));
  }

  public async createDsConfigs(
    postData: IDsInstanceDTO,
    queryParam: boolean
  ): Promise<IDsInstance> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/ds-instances`,
      queryParameters: {
        CredentialsInFirstJob: queryParam
      }
    });
    return promiseFrom(this.httpClient.post<IDsInstance>(url, postData));
  }

  public async updateDsInstance(
    id: number,
    data: IDsInstance
  ): Promise<IDsInstance> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/ds-instances/:id`,
      pathVariables: { id }
    });
    return promiseFrom(this.httpClient.put<IDsInstance>(url, data));
  }

  public async getDataSourceByDataSourceId(
    dataSourceId: string
  ): Promise<IDataSource> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/data-source/:dataSourceId`,
      pathVariables: { dataSourceId }
    });
    return promiseFrom(this.httpClient.get<IDataSource>(url));
  }

  public async initSeeding(params: any): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/data-seeding/initialize`
    });
    return promiseFrom(this.httpClient.post(url, params));
  }

  public async getAllDatasources(
    dataSourceStatus?: string,
    dsOriginConfigStatus?: string
  ): Promise<IDataSource[]> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/data-source`,
      queryParameters: {
        dataSourceStatus: dataSourceStatus || null,
        dsOriginConfigStatus: dsOriginConfigStatus || null
      }
    });
    return promiseFrom(this.httpClient.get<IDataSource[]>(url));
  }

  public async getDataSourceByDSOriginConfigId(
    dataSourceId: string,
    dsOriginConfigId: string
  ): Promise<IDataSourceDsInstanceDTO> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/data-source/:dataSourceId/:dsOriginConfigId`,
      pathVariables: { dataSourceId, dsOriginConfigId }
    });
    return promiseFrom(this.httpClient.get<IDataSourceDsInstanceDTO>(url));
  }

  public async createNewDataSource(
    data: IDataSourceDsInstanceDTO
  ): Promise<IDataSourceDsInstanceDTO> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/data-source/`
    });
    return promiseFrom(
      this.httpClient.post<IDataSourceDsInstanceDTO>(url, data)
    );
  }

  public async createNewDsOriginConfig(
    data: IDataSourceDsInstanceDTO
  ): Promise<IDataSourceDsInstanceDTO> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/data-source/new-ds-origin-config`
    });
    return promiseFrom(
      this.httpClient.post<IDataSourceDsInstanceDTO>(url, data)
    );
  }

  public async updateSelectedDataSource(
    dataSourceId: string,
    data: IDataSourceDsInstanceDTO,
    dsOriginConfigId?: string
  ): Promise<IDataSourceDsInstanceDTO> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/data-source/:dataSourceId`,
      pathVariables: { dataSourceId },
      queryParameters: { dsOriginConfigId }
    });
    return promiseFrom(
      this.httpClient.put<IDataSourceDsInstanceDTO>(url, data)
    );
  }

  public async updateCompositeDataSourceId(
    data: ICompositeDataSourceIdUpdateRequest
  ): Promise<IStatusUpdateResponse> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/data-source`
    });
    return promiseFrom(this.httpClient.put<IStatusUpdateResponse>(url, data));
  }

  public async getAllTargetTableName(): Promise<ITargetTableNames[]> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/data-source/target-table-name`
    });
    return promiseFrom(this.httpClient.get<ITargetTableNames[]>(url));
  }

  public async findJobsNGaps(
    clientId: number,
    dsInstanceId: number[],
    startDate: string,
    endDate: string
  ): Promise<Record<number, IJobStatus[]>> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/data-seeding/jobs-and-gaps/:clientId`,
      pathVariables: { clientId },
      queryParameters: { startDate, endDate, dsInstanceId }
    });
    return promiseFrom(this.httpClient.get<Record<number, IJobStatus[]>>(url));
  }

  public async updateJobStatusToAbandoned(
    jobStatusUpdateObject: IStatusUpdateRequest
  ): Promise<IStatusUpdateResponse> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/data-seeding/abandon-jobs`
    });
    return promiseFrom(
      this.httpClient.put<IStatusUpdateResponse>(url, jobStatusUpdateObject)
    );
  }

  public async getCompositeDataSourceById(
    id: number
  ): Promise<ICompositeDataSource> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/composite-data-source/:id`,
      pathVariables: { id }
    });
    return promiseFrom(this.httpClient.get<ICompositeDataSource>(url));
  }

  public async getCompositeDataSourcesWithGeoDataAvailability(): Promise<
    ICompositeDataSource[]
  > {
    const url = `${this.apiV1}/composite-data-source/with-geo-data-availability`;
    return promiseFrom(this.httpClient.get<ICompositeDataSource[]>(url));
  }

  public async saveCompositeDataSource(
    compositeDataSource: ICompositeDataSource
  ): Promise<ICompositeDataSource> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/composite-data-source`
    });
    return promiseFrom(
      this.httpClient.post<ICompositeDataSource>(url, compositeDataSource)
    );
  }

  public async updateCompositeDataSource(
    id: number,
    compositeDataSource: ICompositeDataSource
  ): Promise<ICompositeDataSource> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/composite-data-source/:id`,
      pathVariables: { id }
    });
    return promiseFrom(
      this.httpClient.put<ICompositeDataSource>(url, compositeDataSource)
    );
  }

  public async getSelectedDtcReplicationClientDestinationConfig(
    clientId: number,
    id: number
  ): Promise<IDtcReplicationClientDestinationConfig> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/dtc-replication-client-destination-config/:id`,
      pathVariables: { id },
      queryParameters: { clientId }
    });
    return promiseFrom(
      this.httpClient.get<IDtcReplicationClientDestinationConfig>(url)
    );
  }

  public async getDtcReplicationClientDestinationConfigList(
    clientId: number,
    brandId: number
  ): Promise<IDtcReplicationClientDestinationConfig[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/dtc-replication-client-destination-config?brandId=${brandId}`,
      queryParameters: { clientId }
    });
    return promiseFrom(
      this.httpClient.get<IDtcReplicationClientDestinationConfig[]>(url)
    );
  }

  public async saveDtcReplicationClientDestinationConfig(
    dtcReplicationClientDestinationConfig: IDtcReplicationClientDestinationConfig
  ): Promise<IDtcReplicationClientDestinationConfig> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/dtc-replication-client-destination-config`
    });
    return promiseFrom(
      this.httpClient.post<IDtcReplicationClientDestinationConfig>(
        url,
        dtcReplicationClientDestinationConfig
      )
    );
  }

  public async updateDtcReplicationClientDestinationConfig(
    id: number,
    dtcReplicationClientDestinationConfig: IDtcReplicationClientDestinationConfig
  ): Promise<IDtcReplicationClientDestinationConfig> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/dtc-replication-client-destination-config/:id`,
      pathVariables: { id }
    });
    return promiseFrom(
      this.httpClient.put<IDtcReplicationClientDestinationConfig>(
        url,
        dtcReplicationClientDestinationConfig
      )
    );
  }

  public async getTargetTables(clientId: number): Promise<string[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/ds-instances/target-tables`,
      queryParameters: { clientId }
    });
    return promiseFrom(this.httpClient.get<string[]>(url));
  }

  public async getDataSourceQa(dataSourceId: string): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/data-source-qa`,
      queryParameters: { dataSourceId }
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async getReportData(
    clientId: number,
    query: Record<string, any>
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/data-source-qa/report/:clientId`,
      pathVariables: { clientId }
    });
    return promiseFrom(this.httpClient.post(url, query));
  }

  public async getDataSourceFileUploadRegistrySummary(
    dsInstanceId: number,
    clientId: number
  ): Promise<IDataSourceFileUploadRegistrySummary[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/data-source-file-upload-registry`,
      queryParameters: { dsInstanceId, clientId }
    });
    return promiseFrom(
      this.httpClient.get<IDataSourceFileUploadRegistrySummary[]>(url)
    );
  }

  public async getTblFileUploadSummary(
    batchId: string,
    fileName: string,
    clientId: number
  ): Promise<ITblFileUploadSummary[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/data-source-file-upload-registry/sot-stats`,
      queryParameters: { batchId, clientId, fileName }
    });
    return promiseFrom(this.httpClient.get<ITblFileUploadSummary[]>(url));
  }

  public async reportCustomerSuccess(
    clientId: number,
    brandId: number,
    reportCustomerSuccessReq: IReportCustomerSuccessRequest
  ): Promise<IAcknowledgmentResponse> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/data-source-file-upload-registry/report-customer-success`,
      queryParameters: { clientId, brandId }
    });
    return promiseFrom(
      this.httpClient.post<IAcknowledgmentResponse>(
        url,
        reportCustomerSuccessReq
      )
    );
  }

  public async updateReportCustomerSuccess(
    id: number,
    dataSourceFileUploadRegistrySummary: IDataSourceFileUploadRegistrySummary
  ): Promise<IDataSourceFileUploadRegistrySummary> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/data-source-file-upload-registry/report-customer-success/:id`,
      pathVariables: { id }
    });
    return promiseFrom(
      this.httpClient.put<IDataSourceFileUploadRegistrySummary>(
        url,
        dataSourceFileUploadRegistrySummary
      )
    );
  }

  public async getDataSourceValidateSOT(
    clientId: number | undefined,
    brandId: number | undefined,
    dataSourceId?: string,
    timeViewOption?: string
  ): Promise<IDataSourceDisplayData[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/sot-data-validation`,
      queryParameters: { clientId, brandId, dataSourceId, timeViewOption }
    });
    return promiseFrom(this.httpClient.get<IDataSourceDisplayData[]>(url));
  }

  public async updateDataSourceValidateSOT(
    clientId: number,
    brandId: number,
    dataSourceValidateSOTRequest: IDataSourceValidateSOTRequest
  ): Promise<IDataSourceValidateSOT> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/sot-data-validation/summary-status`,
      queryParameters: { clientId, brandId }
    });
    return promiseFrom(
      this.httpClient.post<IDataSourceValidateSOT>(
        url,
        dataSourceValidateSOTRequest
      )
    );
  }

  public async reportTxRollup(
    clientId: number,
    brandId: number,
    reportTransactionRollupRequest: IReportTransactionRollupRequest
  ): Promise<IAcknowledgmentResponse> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/sot-data-validation/report-tx-rollup`,
      queryParameters: { clientId, brandId }
    });
    return promiseFrom(
      this.httpClient.post<IAcknowledgmentResponse>(
        url,
        reportTransactionRollupRequest
      )
    );
  }

  public async contactSupport(
    clientId: number,
    brandId: number,
    contactSupportRequest: IContactSupportRequest
  ): Promise<IAcknowledgmentResponse> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/sot-data-validation/contact-support`,
      queryParameters: { clientId, brandId }
    });
    return promiseFrom(
      this.httpClient.post<IAcknowledgmentResponse>(url, contactSupportRequest)
    );
  }
}
