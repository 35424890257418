import { Pipe, PipeTransform } from '@angular/core';
import { FieldDefinition } from '@portal/app/shared/types';

@Pipe({ name: 'formatLabel' })
export class FormatLabelPipe implements PipeTransform {
  transform(field: FieldDefinition): string {
    const parts = field.label.split(' ');
    if (parts.length === 2 && parts[1] !== '%') {
      return field.label.replace(' ', '<br />');
    } else if (parts.length > 2) {
      let i = 0;
      const pos =
        parts[0] === 'My'
          ? Math.ceil(parts.length / 2)
          : Math.floor(parts.length / 2);
      return field.label.replace(/ /g, (match) => {
        return ++i === pos ? '<br />' : match;
      });
    }
    return field.label;
  }
}
