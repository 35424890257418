/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil, of, map, forkJoin } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

// Services Imports
import { ApiService } from '@portal/app/shared/services/api.service';
import { DataSetService } from '@portal/app/dashboard/home-page/services/data-set.service';
import { FieldService } from '@portal/app/shared/services/field.service';
import { FilterService } from '@portal/app/dashboard/home-page/services/filter.service';
import { FormatService } from '@portal/app/dashboard/home-page/services/format.service';

// Types
import { DataEndpoint, Filter, ViewType } from '@portal/app/shared/types';
export interface Data {
  name: string;
  optimizeStrengthFilter: number;
  totalOptNetSalesGain: string;
  totalOptNetSalesGainPerc: string;
  totalOptRoasI: string;
  totalCurRoasI: string;
  tactics: any[];
}

export const DEFAULT_PLAN_STRENGTH = 2;

@Injectable({
  providedIn: 'root'
})
export class RecommendedService implements OnDestroy {
  // Data state
  private dataSubject = new BehaviorSubject<any>(null);
  public data$ = this.dataSubject.asObservable();

  // Cleanup subscriptions
  private destroy$ = new Subject<void>();

  // Filters state
  private filtersSubject = new BehaviorSubject<Filter[]>([]);
  public filters$ = this.filtersSubject.asObservable();

  // Initial loading state
  private initialLoadingSubject = new BehaviorSubject<boolean>(true);
  public initialLoading$ = this.initialLoadingSubject.asObservable();

  // Separate loading indicator for subsequent fetches
  private subsequentLoadingSubject = new BehaviorSubject<boolean>(false);
  public subsequentLoading$ = this.subsequentLoadingSubject.asObservable();

  // constants
  readonly steps = [
    'Minimum strength',
    'Moderate strength',
    'Strong strength',
    'Maximum strength'
  ];

  readonly dimensions = ['channel', 'tactic'];

  constructor(
    private apiService: ApiService,
    private dataSetService: DataSetService,
    private formatService: FormatService,
    private fieldService: FieldService,
    private filterService: FilterService
  ) {
    this.subscribeToFilters();
  }

  private subscribeToFilters() {
    this.filterService.filters$
      .pipe(takeUntil(this.destroy$))
      .subscribe((filters) => {
        // Set filters observable
        this.filtersSubject.next(filters);
        this.hydrateData(filters);
      });
  }

  setStrengthFilter(strengthFilter: number) {
    this.fetchData(strengthFilter);
  }

  hydrateData(currentFilters: Filter[]): void {
    this.initialLoadingSubject.next(true);

    const dataSetFilters = this.getFilters(
      currentFilters,
      DEFAULT_PLAN_STRENGTH
    );

    forkJoin({
      viewList: this.apiService.getViewList(
        ViewType.default,
        'media-plan-optimizer',
        'portfolio-level'
      ),
      dashboardData: this.apiService.getDashboardData({
        filters: dataSetFilters,
        dimensionValues: this.dimensions,
        type: DataEndpoint.dataSets
      })
    })
      .pipe(
        map(({ viewList, dashboardData }) => {
          const defaultPlan = viewList[0];
          const name = defaultPlan?.name ?? '';
          const optimizeStrengthFilter =
            defaultPlan?.filterOverrides?.global?.optimizeStrengthFilter ??
            DEFAULT_PLAN_STRENGTH;
          const data = this.formatData(dashboardData);

          return { name, optimizeStrengthFilter, ...data };
        }),
        catchError((error) => {
          console.error('Error in hydrateData:', error);
          this.initialLoadingSubject.next(false);
          return of(null); // Handle this more gracefully as needed
        }),
        finalize(() => this.initialLoadingSubject.next(false))
      )
      .subscribe((data) => {
        if (data) {
          this.dataSubject.next(data);
        }
      });
  }

  fetchData(optimizeStrengthFilter: number): void {
    this.subsequentLoadingSubject.next(true); // Indicate loading for subsequent fetches
    const currentFilters = this.filtersSubject.getValue();
    const dataSetFilters = this.getFilters(
      currentFilters,
      optimizeStrengthFilter
    );

    this.dataSetService
      .fetchDataSets({ dimensions: this.dimensions, filters: dataSetFilters })
      .pipe(
        map((dataSets) => this.formatData(dataSets)),
        catchError((error) => {
          console.error('Error fetching chart data', error);
          return of(null);
        }),
        finalize(() => this.subsequentLoadingSubject.next(false))
      )
      .subscribe((data) => {
        if (data) {
          // Merge with existing data if needed, or just update
          this.dataSubject.next({ ...this.dataSubject.getValue(), ...data });
        }
      });
  }

  getFilters(
    providedFilters: Filter[],
    optimizeStrengthFilter: number
  ): Filter[] {
    // Define which filters are needed for charts
    const neededFilterIds = [
      'conversion_type',
      'isInitialFilterCall',
      'relative_day',
      'dateStart',
      'dateStop'
    ];

    // Select only necessary filters from providedFilters
    const selectedFilters = providedFilters.filter((filter) =>
      neededFilterIds.includes(filter.literalId)
    );

    // Hardcoded filters
    const hardcodedFilters = [
      this.filterService.createFilter({
        literalId: 'datasets',
        type: 'string[]',
        value: ['ROAS']
      }),
      this.filterService.createFilter({
        literalId: 'viewBy',
        type: 'string',
        value: 'ROAS'
      }),
      this.filterService.createFilter({
        literalId: 'optimizeForFilter',
        type: 'number',
        value: 30
      }),
      this.filterService.createFilter({
        literalId: 'optimizeStrengthFilter',
        type: 'number',
        value: optimizeStrengthFilter
      }),
      this.filterService.createFilter({
        literalId: 'allocationSetting',
        type: 'string[]',
        value: []
      }),
      this.filterService.createFilter({
        literalId: 'anchorAllocationSetting',
        type: 'string',
        value: 'Fixed Amount ($)'
      }),
      this.filterService.createFilter({
        literalId: 'optimizedCappedAllocationSetting',
        type: 'string',
        value: 'Fixed Amount ($)'
      }),
      this.filterService.createFilter({
        literalId: 'download',
        type: 'string',
        value: 'CSV'
      }),
      this.filterService.createFilter({
        literalId: 'percInc',
        type: 'string',
        value: 'Current'
      }),
      this.filterService.createFilter({
        literalId: 'dimensions',
        type: 'string[]',
        value: ['channel', 'tactic']
      }),
      this.filterService.createFilter({
        literalId: 'viewType',
        type: 'string',
        value: 'All Plans'
      }),
      this.filterService.createFilter({
        literalId: 'viewActions',
        type: 'string',
        value: 'Rename'
      }),
      this.filterService.createFilter({
        literalId: 'viewActionsHiddenForNonOwners',
        type: 'string',
        value: 'Rename'
      }),
      this.filterService.createFilter({
        literalId: 'childDimensions',
        type: 'string[]',
        value: ['campaign']
      }),
      this.filterService.createFilter({
        literalId: 'showChild',
        type: 'string',
        value: false
      }),
      this.filterService.createFilter({
        literalId: 'childOptimizeStrengthFilter',
        type: 'number',
        value: 1
      })
    ];

    // Combine selected provided filters with hardcoded filters
    const combinedFiltersMap = new Map(
      selectedFilters
        .concat(hardcodedFilters)
        .map((filter) => [filter.literalId, filter])
    );

    return Array.from(combinedFiltersMap.values());
  }

  // Utility function to format data based on its metric type
  formatMetric = (literalId: string, value: any) => {
    const fieldDefinition =
      this.fieldService.getFieldDefinitionByLiteralId(literalId);
    if (!fieldDefinition) {
      return value;
    }
    return this.formatService.formatValue(fieldDefinition || {}, value);
  };

  formatData(data: any) {
    const transformedTactics = data[0].data.map((tactic: any) => {
      return {
        ...tactic,
        name: tactic.tactic,
        currentBudget: this.formatMetric('curBudget', tactic.curBudget),
        additionalSpend: this.formatMetric(
          'optBudgetChanged',
          tactic.optBudgetChanged
        )
      };
    });

    return {
      tactics: transformedTactics,
      step: this.steps[data[0].dataPoints.optimizeStrength],
      totalOptNetSalesGain: this.formatMetric(
        'totalOptNetSalesGain',
        data[0].dataPoints.totalOptNetSalesGain
      ),
      totalOptNetSalesGainPerc: this.formatMetric(
        'totalOptNetSalesGainPerc',
        data[0].dataPoints.totalOptNetSalesGainPerc
      ),
      totalCurRoasI: this.formatMetric(
        'totalCurRoasI',
        data[0].dataPoints.totalCurRoasI
      ),
      totalOptRoasI: this.formatMetric(
        'totalOptRoasI',
        data[0].dataPoints.totalOptRoasI
      )
    };
  }

  tooltipData = (tactic: any) => ({
    content: `<div>
      <div class="flex justify-between items-center">
        <div class="flex items-center mr-2"><span class="text-gray-300 mr-1">\u25CF</span> <span class="b1 text-gray-000">Current Spend:</span> </div>

        <div class="b1 text-gray-000">${this.formatMetric(
          'curBudget',
          tactic.curBudget
        )}</div>
      </div>
      <div class="flex justify-between items-center">
        <div class="flex items-center mr-2"><span class="text-gray-700 mr-1">\u25CF</span> <span class="b1 text-gray-000">Additional Recommended Spend:</span> </div>

        <div class="b1 text-gray-000">${this.formatMetric(
          'optBudgetChanged',
          tactic.optBudgetChanged
        )}</div>
      </div>
      </div>`,

    position: 'top',

    arrowPosition: null
  });

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
