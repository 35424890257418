import {
  Component,
  effect,
  ElementRef,
  EventEmitter,
  inject,
  OnDestroy,
  Output,
  Renderer2
} from '@angular/core';
import { LinkService } from '@portal/app/dashboard/context-modal/services/link.service';

@Component({
  selector: 'portal-context-base-modal',
  template: ''
})
export abstract class ModalBaseComponent implements OnDestroy {
  private renderer = inject(Renderer2);
  protected linkService = inject(LinkService);
  private elRef = inject(ElementRef);
  private el!: HTMLElement;

  @Output() closeModal = new EventEmitter();

  // Abstract getter that children must implement
  protected abstract get isVisible(): boolean;

  constructor() {
    this.el = this.elRef.nativeElement;
    // append element to body
    this.renderer.appendChild(document.body, this.el);

    // Watch visibility and add/remove the overflow class accordingly.
    effect(() => {
      if (this.isVisible) {
        this.renderer.addClass(document.body, 'overflow-hidden');
      } else {
        this.renderer.removeClass(document.body, 'overflow-hidden');
      }
    });
  }

  ngOnDestroy(): void {
    // remove element from the body
    if (this.el) {
      this.renderer.removeChild(document.body, this.el);
    }
    // add scrollbar to body
    this.renderer.removeClass(document.body, 'overflow-hidden');
  }

  onCloseModal() {
    this.closeModal.emit();
    this.renderer.removeClass(document.body, 'overflow-hidden');
  }
}
