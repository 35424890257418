/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input } from '@angular/core';
import {
  Filter,
  ElementGroup,
  FieldDefinitions
} from '@portal/app/shared/types';
import { NativeSectionsService } from '@portal/app/shared/services/native-sections.service';
@Component({
  selector: 'portal-context-bar',
  templateUrl: './context-bar.component.html'
})
export class ContextBarComponent {
  @Input() contextItem: ElementGroup | undefined = undefined;
  @Input() fieldDefinitions: FieldDefinitions = {};
  @Input() filters: Filter[] = [];

  constructor(public readonly nativeSectionService: NativeSectionsService) {}
}
