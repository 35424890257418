import { Options } from 'highcharts';
import { colors } from '../../styles/colors/colors.config';
import { typographyConfig } from '@design-system/styles/typography/typography.config';

const formatNumber = (value: number, includeDecimal = true): string => {
  let formattedValue: number | string = value;
  let suffix = '';

  if (value >= 1000000) {
    formattedValue /= 1000000;
    suffix = 'M';
  } else if (value >= 1000) {
    formattedValue /= 1000;
    suffix = 'K';
  }

  formattedValue = includeDecimal
    ? formattedValue.toFixed(2)
    : Math.floor(formattedValue).toString();

  return formattedValue + suffix;
};

const calculatePercent = (yValue: number, total = 0): string => {
  const val = (yValue / total) * 100;
  return val.toFixed(2);
};
export const defaultConfig: Options = {
  xAxis: {
    categories: [
      'Oct `24',
      'Nov `24',
      'Dec `24',
      'Jan `24',
      'Feb `24',
      'Mar `24'
    ],
    lineColor: colors['gray-300'],
    lineWidth: 1,
    gridLineWidth: 0,
    labels: {
      style: {
        color: colors['gray-800'],
        fontSize: typographyConfig.styles.c1.size,
        fontFamily: typographyConfig.styles.c1.fontFamily,
        fontWeight: typographyConfig.styles.c1.weight
      }
    }
  },
  yAxis: {
    endOnTick: false,
    min: 1000000,
    max: 30000000,
    title: {
      text: ''
    },
    lineColor: colors['gray-300'],
    lineWidth: 1,
    gridLineWidth: 0,
    labels: {
      formatter() {
        const value = Number(this.value);
        return `$${formatNumber(value, false)}`;
      },
      style: {
        color: colors['gray-800'],
        fontSize: typographyConfig.styles.c1.size,
        fontFamily: typographyConfig.styles.c1.fontFamily,
        fontWeight: typographyConfig.styles.c1.weight
      }
    }
  },
  legend: {
    align: 'center',
    verticalAlign: 'top',
    x: 0,
    y: -40,
    borderWidth: 0,
    shadow: false,
    useHTML: true,
    labelFormatter() {
      return `<span class="c1 text-gray-800 block z-0">${this?.name}</span>`;
    },
    symbolWidth: 8,
    symbolHeight: 8
  },
  tooltip: {
    useHTML: true,
    outside: true,
    distance: 15,
    formatter() {
      if (this.point && this.point.y !== undefined) {
        const series = this.point.series;
        return `
                <div class="tooltip-body">
                    <div class="flex justify-center items-center h-full">
                        <div class="b2 text-gray-500">${series.name}</div>
                    </div>
                    <div class="flex justify-center items-center">
                    <span class="b2 text-gray-500 text-center">Date: ${
                      this.x
                    }</span>
                    </div>
                    <div class="m-divider my-2 text-gray-900"></div>
                    <div class="flex justify-between w-full">
                        <span class="b1 text-gray-000">Actual</span>
                        <span class="b1 text-gray-000">${formatNumber(
                          this.point.y,
                          true
                        )}</span>
                    </div>
                    <div class="flex justify-between w-full">
                        <span class="b1 text-gray-000">Percentage</span>
                        <span class="b1 text-gray-000">${calculatePercent(
                          this.point.y,
                          this.point.total
                        )}%</span>
                    </div>
                </div>`;
      } else {
        return '';
      }
    }
  },
  plotOptions: {
    column: {
      stacking: 'normal',
      dataLabels: {
        enabled: false
      },
      pointWidth: 16,
      groupPadding: 0,
      pointPadding: 0,
      states: {
        hover: {
          enabled: false
        },
        inactive: {
          opacity: 1
        }
      }
    }
  },
  series: [
    {
      type: 'column',
      name: 'New Customers',
      data: [18360000, 14245000, 4012000, 15226000, 8225000, 13112000],
      color: colors['green-700'],
      className: 'green-column'
    },
    {
      type: 'column',
      name: 'Returning Customers',
      data: [11360000, 7125000, 10145000, 11336000, 12436000, 12634000],
      color: colors['blue-400']
    }
  ]
};
