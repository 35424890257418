import { ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { InputSwitch } from 'primeng/inputswitch';

@Component({
  selector: 'm-switch',
  templateUrl: './m-switch.component.html',
  styleUrls: ['./m-switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MSwitchComponent),
      multi: true
    }
  ]
})
export class MSwitchComponent
  extends InputSwitch
  implements ControlValueAccessor
{
  @Input() isToggle = false;

  switchFormControl = new FormControl();

  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }

  override writeValue(value: any) {
    this.switchFormControl.setValue(value);
  }
}
