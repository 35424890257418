import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  NgZone,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { Dropdown } from 'primeng/dropdown';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FilterService, PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'm-dropdown',
  templateUrl: './m-dropdown.component.html',
  styleUrls: ['./m-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MDropdownComponent),
      multi: true
    }
  ]
})
export class MDropdownComponent
  extends Dropdown
  implements ControlValueAccessor
{
  @ViewChild('dropDown') dropDown!: Dropdown;
  @Input() width: string | undefined;
  @Input() preventSelection = false;
  @Output() formControlChange = new EventEmitter<any>();

  constructor(
    el: ElementRef,
    renderer: Renderer2,
    cd: ChangeDetectorRef,
    zone: NgZone,
    filterService: FilterService,
    config: PrimeNGConfig
  ) {
    super(el, renderer, cd, zone, filterService, config);
  }

  onChangeRelay($event: any) {
    this.formControlChange.emit($event);
    if (this.preventSelection) {
      this.dropDown.updateModel('');
    }
  }
}
