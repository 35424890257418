<div class="radio-bar-container">
  <div class="d-flex flex-row align-items-center justify-content-start">
    <span class="side-text left">{{ leftText }}</span>
    <div class="v-line"></div>
    @for (item of options; track item; let i = $index) {
      @if (i === 0) {
        <hr class="line left" />
      }
      <div class="radio-block d-flex flex-column justify-content-center">
        <label
          [ngClass]="{ 'selected-label': item === selectedValue }"
          class="radio-val"
          [for]="literalId + i"
          >{{ item }}</label
        >
        <input
          class="mt-1"
          type="radio"
          [disabled]="isDisabled"
          [value]="item"
          [(ngModel)]="selectedValue"
          (ngModelChange)="onValueChange()"
          [checked]="item === selectedValue"
          [name]="literalId"
          [id]="literalId + i" />
      </div>
      <hr [ngClass]="i === options.length - 1 ? 'line right' : 'line'" />
    }
    <div class="v-line"></div>
    <span class="side-text right">{{ rightText }}</span>
  </div>
</div>
