import { Component, Input } from '@angular/core';
import {
  DataResponse,
  ElementSubType,
  FieldDefinitions
} from '@portal/app/shared/types';
import { Required } from '@portal/app/shared/decorators/required.decorator';
import { ICompositeCellConfig } from '@portal/app/shared/models/ICompositeCellConfig';

@Component({
  selector: 'portal-composite-data-cell',
  templateUrl: './composite-data-cell.component.html',
  styleUrls: ['./composite-data-cell.component.scss']
})
export class CompositeDataCellComponent {
  @Input() @Required col!: ElementSubType;
  @Input() @Required fieldDefinitions!: FieldDefinitions;
  @Input() @Required rowData!: DataResponse;
  @Input() dashboardId = '';
  @Input() key?: string;
  @Input() cellLayoutType?: string;
  @Input() compositeCellConfig!: ICompositeCellConfig;

  getToolTip(): string {
    let toolTips = this.compositeCellConfig.fields.map((f) => {
      return this.compositeCellConfig.data[f.id]
        ? this.compositeCellConfig.data[f.id]
        : f.emptyValue;
    });
    toolTips = toolTips.map((toolTip, index) => {
      if (index === 0) {
        return `<span style="font-weight: 600; display: inline-block; word-break: break-word;">${toolTip}</span>`;
      }
      return `<span style="font-weight: 400; color: #ced4da; display: inline-block; word-break: break-word;">${toolTip}</span>`;
    });
    return `<div>${toolTips.join('\n')}</div>`;
  }
}
