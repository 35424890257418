import { Component, inject, computed } from '@angular/core';
import { CompareChartService } from '@portal/app/dashboard/context-modal/services/compare-chart.service';
import { MetricsService } from '@portal/app/dashboard/context-modal/services/metrics.service';
import { CardVariant } from '@design-system/components/m-card';

@Component({
  selector: 'portal-context-compare-chart',
  templateUrl: './compare-chart.component.html',
  styleUrl: './compare-chart.component.scss',
  providers: [CompareChartService]
})
export class CompareChartComponent {
  compareChartService = inject(CompareChartService);
  metricsService = inject(MetricsService);

  cardVariantType = CardVariant;

  isLoading = this.compareChartService.isLoading;
  isChartReady = this.compareChartService.isChartReady;
  showDrivers = this.compareChartService.showDrivers;

  data = this.compareChartService.data;
  drivers = computed(() => this.data().response.drivers);
  comparisonOptions = computed(() => this.data().chartOptions);

  metric = computed(() => this.metricsService.getActiveMetric());
}
