<ng-container *ngIf="linkElement === 'a'; else spanLink">
  <a
    class="m-link text-blue-600 {{ styleClass || '' }} {{
      disabled ? 'disabled' : ''
    }}"
    [href]="link"
    >{{ label }}</a
  >
</ng-container>
<ng-template #spanLink>
  <span
    class="m-link text-blue-600 {{ styleClass || '' }} {{
      disabled ? 'disabled' : ''
    }}"
    (click)="publishLinkClick()"
    >{{ label }}</span
  >
</ng-template>
