import {
  AssociatedFilter,
  BaseElement,
  ChartElement,
  ChartGroupElement,
  ContextField,
  ControlElement,
  CurrentStatus,
  DataGridElement,
  DateRange,
  DisplayDashboard,
  ElementGroup,
  ElementSubType,
  ExperimentResultData,
  Filter,
  FilterOption,
  FilterValue,
  LayoutResponse,
  RelativePosition,
  WidgetType
} from '@portal/app/shared/types';
import {
  cloneDeep,
  forEach,
  isArray,
  isEmpty,
  isEqual,
  orderBy
} from 'lodash-es';
import { DateTimeService } from '@portal/app/shared/services/date-time.service';
import { UserModel } from '@portal/app/shared/models/userModel';
import {
  DashboardLiteralIds,
  landingDashboardWithFallBack
} from '@portal/app/shared/constants/constants';
import { FilterSetUtil } from '@portal/app/dashboard/filter-set/filter-set-util';

export const sortByPosition = (a: ContextField, b: ContextField): number => {
  if (a.position > b.position) {
    return 1;
  } else if (a.position < b.position) {
    return -1;
  }
  return 0;
};

export const sortByKey = (a: string, b: string): number => {
  if (parseInt(a, 10) > parseInt(b, 10)) {
    return 1;
  } else if (parseInt(a, 10) < parseInt(b, 10)) {
    return -1;
  }
  return 0;
};

export const sortByDisplayOrder = (
  a: BaseElement | ElementSubType,
  b: BaseElement | ElementSubType
): number => {
  if (a.displayOrder && b.displayOrder) {
    if (a.displayOrder > b.displayOrder) {
      return 1;
    } else if (a.displayOrder < b.displayOrder) {
      return -1;
    }
    return 0;
  }
  return 0;
};

export const sortByHighlight = (
  a: ExperimentResultData,
  b: ExperimentResultData
): number => {
  if (a.highlight) {
    return -1;
  } else if (b.highlight) {
    return 1;
  }
  return 0;
};

export const createFilterField = (
  control: Filter,
  element: ControlElement,
  filters: Filter[]
): ContextField | undefined => {
  if (control) {
    const filter = filters.find(
      (fil: Filter) => fil.literalId === control.literalId
    );
    if (filter) {
      const field = FilterSetUtil.mapFieldByFilter(
        filter,
        control.literalId,
        element.layoutType,
        element.displayOrder || 0
      );
      addChildrenToFilterField(field, filters);
      return field;
    }
  }
};

export const addChildrenToFilterField = (
  field: ContextField,
  filters: Filter[]
) => {
  const filter = filters.find((fil: Filter) => fil.literalId === field.name);
  if (filter != null) {
    field.children = [];
    const { associatedFilters } = filter;

    if (!isEmpty(associatedFilters)) {
      for (const key in associatedFilters) {
        if (Object.prototype.hasOwnProperty.call(associatedFilters, key)) {
          const afs: AssociatedFilter[] = associatedFilters[
            key
          ] as AssociatedFilter[];
          afs.forEach((af) => {
            const childFilter = filters.find(
              (fil: Filter) => fil.literalId === af.associatedFilterLiteralId
            );
            if (childFilter) {
              const childField = FilterSetUtil.mapFieldByFilter(
                childFilter,
                childFilter.literalId,
                'FILTER_SET'
              );

              childField.parent = af.dashboardFilterValueTrigger;
              field.children?.push(childField);
            }
          });
        }
      }
    }
  }
};

export const createFilterFields = (
  element: ElementGroup | ChartElement | DataGridElement | ChartGroupElement,
  filters: Filter[]
) => {
  const filterFields: ContextField[] = [];
  const filterControl = isArray(element?.filterControl)
    ? element.filterControl
    : [];
  if (filterControl instanceof Array) {
    filterControl.forEach((control) => {
      const ctrl = control as ControlElement;
      ctrl.layoutType = element.layoutType;
      const filter = filters.find(
        (f) => f.literalId === ctrl.dashboardFilterId
      );
      if (filter) {
        const field = createFilterField(filter, ctrl, filters);
        if (field) {
          filterFields.push(field);
        }
      }
    });
  }
  return filterFields;
};

export const widgetSpecificFilters = (
  filters: Filter[] = [],
  widgetType: WidgetType
): Filter[] => {
  return filters.filter((filter) => filter.applyTo?.includes(widgetType));
};

export const getFiltersFromFilterFields = (
  contextFields: ContextField[],
  allFilters: Filter[],
  filterType: 'DATA_SET' | 'DATA_CHART'
): Filter[] => {
  const af = cloneDeep(allFilters);
  const filters: Filter[] = [];
  contextFields.forEach((field: ContextField) => {
    const filter = af.find(
      (f) =>
        f.applyTo &&
        f.applyTo.includes(filterType) &&
        f.literalId === field.name
    );
    if (filter) {
      filter.value = field.value || filter.value;
      filters.push(filter);
    }
    const { children } = field;
    if (children && children.length) {
      const child = children.find((c) => c.parent === field.value);
      if (child) {
        const childFilter = af.find(
          (f) =>
            f.applyTo &&
            f.applyTo.includes(filterType) &&
            f.literalId === child.name
        );
        if (childFilter) {
          childFilter.value = child.value;
          filters.push(childFilter);
        }
      }
    }
  });
  return filters;
};

export const updateOptions = (
  filterToUpdate: ContextField,
  updatedFilter: Filter
): FilterOption[] => {
  const options = updatedFilter.options?.map((opt) => {
    if (typeof opt === 'object') {
      return opt;
    }
    if (opt != null) {
      return { label: opt, value: opt } as FilterOption;
    }
  });
  return (filterToUpdate.options = options as FilterOption[]);
};

export const combineFilters = (
  contextFilters: Filter[] = [],
  widgetFilters: Filter[] = []
): Filter[] => {
  return contextFilters?.map((filter) => {
    const f = widgetFilters?.find(
      (child) => child.literalId === filter.literalId
    );
    if (f) {
      return f;
    }
    return filter;
  });
};

export const updateFilterOptions = (
  newFilters: Filter[],
  filterFields: ContextField[]
): ContextField[] => {
  return filterFields.map((child) => {
    let filter = newFilters.find((f) => f.literalId === child.name);
    if (filter) {
      child.options = updateOptions(child, filter);
      child.value = updateSelectedValues(filter as Filter, child) as
        | string[]
        | string
        | DateRange;
    }
    if (child?.children?.length) {
      child.children.map((childField) => {
        filter = newFilters.find((f) => f.literalId === childField.name);
        childField.options = updateOptions(childField, filter as Filter);
        childField.value = updateSelectedValues(
          filter as Filter,
          childField
        ) as string[];
      });
    }
    return child;
  });
};

export const updateSelectedValues = (
  filterOptions: Filter,
  filterToUpdate: ContextField
) => {
  if (filterOptions) {
    if (filterToUpdate.inputType === 'select') {
      //options come in both object and string form unpredictably
      //treating different option types with different approach
      if (
        filterOptions.options &&
        typeof filterOptions.options[0] === 'string'
      ) {
        const options = filterOptions.options as string[];
        const exist = options.find((o) => o === filterToUpdate.value);
        if (exist) {
          return exist;
        } else if (filterOptions.value) {
          const value = filterOptions.value as string;
          const present = filterOptions.options?.includes(value);
          return !present ?? options[0];
        }
      } else if (
        filterOptions.options &&
        typeof filterOptions.options[0] === 'object'
      ) {
        const options = filterOptions.options as unknown as FilterOption[];
        const exist = options.find((o) => o.value === filterToUpdate.value);
        if (exist) {
          return exist.value;
        } else if (filterOptions.value) {
          const value = filterOptions.value as string;
          // let present = filterOptions.options?.includes(value);
          let finalizedValue = options[0]?.value as string;
          forEach(options, (opt) => {
            if (isEqual(opt, { label: value, value })) {
              finalizedValue = opt.value as string;
            }
          });
          return finalizedValue;
        }
      }
    }
    if (filterToUpdate.inputType === 'multiselect') {
      const options = filterOptions.options as string[];
      const value = filterToUpdate.value as string[];
      const valueToSend = value?.filter((v) => {
        const exist = options.find((o) => o === v);
        if (exist) {
          return v;
        }
      });
      // additionalLayout property might change once the json based column in db is added
      // this will have to be refactored
      if (!valueToSend.length && filterToUpdate.additionalAttributes) {
        return selectFilterControlValues(filterToUpdate);
      }
      if (valueToSend) {
        return valueToSend;
      } else if (filterOptions.value) {
        return [options[0]];
      } else {
        return [];
      }
    }
    if (filterToUpdate.inputType === 'number') {
      return filterToUpdate.value;
    }
  }
};

export const selectFilterControlValues = (
  childCtrl: ContextField
): string[] => {
  if (childCtrl) {
    const ctrl = childCtrl as ContextField;
    if (
      isArray(ctrl.value) &&
      !isEmpty(ctrl.value) &&
      typeof ctrl.value[0] !== 'object'
    ) {
      return ctrl.value;
    }

    if (
      ctrl.options &&
      ctrl.additionalAttributes &&
      ctrl.options.length > Object.keys(ctrl.additionalAttributes).length
    ) {
      return childCtrl.options
        ?.slice(0, childCtrl.additionalAttributes?.valueCount as number)
        .map((filterOption) => filterOption.value) as string[];
    }
  }
  return [];
};

const asContextField = (
  filter: Filter,
  displayOrder?: number
): ContextField => {
  if (filter === undefined) {
    console.error(`filter does not exist`);
    return {} as ContextField;
  }

  if (filter == null) {
    console.error(`filter does not exist`);
    return {} as ContextField;
  }
  let options: string[] | FilterOption[] | number[] = [];
  if (filter.options != null) {
    options = filter.options;
  }
  let inputType = '';
  if (filter.type === 'string') {
    inputType = 'select';
    filter.value = isEmpty(filter.value)
      ? (options[0] as FilterValue)
      : filter.value;
  } else if (filter.type === 'string[]') {
    inputType = 'multiselect';
    const optsAsString = options as string[];
    options = optsAsString.map(
      (o) =>
        ({
          label: o,
          value: o
        }) as FilterOption
    );
    const typedValue = filter.value as string[];
    if (typedValue.length === 0) {
      // select all the things
      filter.value = options.map((o) => o.value) as string[];
    }
  } else if (filter.type === 'dateRange') {
    inputType = 'dateRange';
    if (isArray(filter.value)) {
      filter.value = {
        startDate: DateTimeService.dayJsDate(filter.value[0] as string),
        endDate: DateTimeService.dayJsDate(filter.value[1] as string)
      };
    }
  }
  return {
    name: filter.literalId,
    label: filter.label,
    inputType,
    value: filter.value,
    options,
    position: displayOrder || 0
  } as ContextField;
};

export { asContextField };

export const isMeasuredBenchmark = (literalId: string | null): boolean => {
  return (
    literalId === 'measured-benchmarks' || literalId === 'benchmarks-drill-down'
  );
};

export const isMeasuredBenchmarkLandingPage = (
  literalId: string | null
): boolean => {
  return literalId === 'measured-benchmarks';
};

export const isMeasuredBenchmarkDrillDownPage = (
  literalId: string | null
): boolean => {
  return literalId === 'benchmarks-drill-down';
};

export const landingDashboardPath = (
  dashboards: DisplayDashboard[],
  user: UserModel
): string => {
  return `/${getDefaultDashboard(dashboards, user)?.routerLinkPart}`;
};

export const landingDashboardURI = (
  brandProductPath: string,
  dashboards: DisplayDashboard[],
  user: UserModel
): string => {
  return `/a/${brandProductPath}${landingDashboardPath(dashboards, user)}`;
};

const getDefaultDashboardsBasedOnUserType = (
  dashboards: DisplayDashboard[],
  user: UserModel
) => {
  const defaultDashboards = dashboards.filter((dashboard) =>
    Object.keys(landingDashboardWithFallBack).includes(dashboard.literalId)
  );
  if (user.isMeasuredUser()) {
    return defaultDashboards.filter(
      (dd) =>
        dd.status === CurrentStatus.active || dd.status === CurrentStatus.draft
    );
  } else {
    return defaultDashboards.filter((dd) => dd.status === CurrentStatus.active);
  }
};

export const getDefaultDashboard = (
  dashboards: DisplayDashboard[],
  user: UserModel
) => {
  return orderBy(
    getDefaultDashboardsBasedOnUserType(dashboards, user).map((dash) => {
      const dashboardObj =
        (dash.items || []).find(
          (item) => item.literalId === DashboardLiteralIds.homePage
        ) || dash;
      return {
        dashboard: dashboardObj,
        displayOrder:
          landingDashboardWithFallBack[dashboardObj.literalId]?.displayOrder
      };
    }),
    'displayOrder'
  ).map((dashObj) => dashObj.dashboard)[0];
};

export const findRouterLinkByLiteralId = (
  data: DisplayDashboard[] = [],
  targetLiteralId: string
): { routerLinkPart: string; isMultiView: boolean } | null => {
  for (const item of data) {
    if (item.literalId === targetLiteralId) {
      return {
        routerLinkPart: item.routerLinkPart,
        isMultiView: item.isMultiView
      };
    }
    if (item.items) {
      const result = findRouterLinkByLiteralId(item.items, targetLiteralId);
      if (result) {
        return result;
      }
    }
  }
  return null;
};

//TODO: DU: This method was added to directly inject elements into the Cross Channel native framework layout API in order to not
//have to write a query that would affect thousands of records. This work is tied to  PMDEV-3344 & PMDEV-3572
export const addAdditionalLayoutTypes = (fullLayout: LayoutResponse) => {
  if (fullLayout.dashboardLiteralId !== 'cross-channel') {
    return;
  }
  const filterSetLayout = fullLayout.layout.find(
    (layout: ElementGroup) => layout.layoutType === 'FILTER_SET'
  );
  const index = filterSetLayout && fullLayout.layout.indexOf(filterSetLayout);
  if (index && index > -1) {
    // Powers the <portal-mmm-export-banner> component in native-sections.conmponent.html
    const heroBannerItem = {
      id: -1,
      layoutType: 'HERO_BANNER',
      dashboardId: filterSetLayout.dashboardId,
      literalId: 'heroBanner',
      label: null,
      parentLayoutId: null,
      displayOrder: 3,
      span: 12,
      elements: [],
      relativePosition: [RelativePosition.first, RelativePosition.last]
    };

    // Powers the <portal-compare-mode-banner> component in native-sections.conmponent.html
    const compareBannerItem = {
      id: -1,
      layoutType: 'COMPARE_BANNER',
      dashboardId: filterSetLayout.dashboardId,
      literalId: 'compareBanner',
      label: null,
      parentLayoutId: null,
      displayOrder: 3,
      span: 12,
      elements: [],
      relativePosition: [RelativePosition.first, RelativePosition.last]
    };

    // Insert HERO_BANNER item first
    // Note this index is hardcoded and is flimsey, so a layout update via API could affect the position of this component
    fullLayout.layout.splice(index, 0, heroBannerItem as ElementGroup);

    // Insert COMPARE_BANNER item immediately after the HERO_BANNER
    // Note this index is hardcoded and is flimsey, so a layout update via API could affect the position of this component
    fullLayout.layout.splice(index + 1, 0, compareBannerItem as ElementGroup);
  }
};
