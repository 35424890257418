@for (badge of badges; track badge) {
  @if (showEmptyData || (!showEmptyData && headerData[badge.field])) {
    <p-badge
      [severity]="badge.severity || 'success'"
      [class]="badge.styleClass || 'BADGE'"
      [value]="
        nativeSectionsService.asString(headerData[badge.field]) ?? 'No Data'
      ">
    </p-badge>
  }
}

@for (badge of iconBadges; track badge) {
  <div class="badge-container icon-badge-container">
    <div class="icon-badge">
      <i aria-hidden="true" class="pi {{ badge.icon }}"></i>
      <span>{{ headerData[badge.field] }}</span>
    </div>
  </div>
}

@for (badge of statusBadges; track badge) {
  <div class="badge-container status-badge-container">
    <div class="icon-badge">
      <i aria-hidden="true" class="pi {{ badge.icon }}"></i>
      <span>{{ badge.value }}</span>
    </div>
  </div>
}
