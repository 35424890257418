<div
  class="m-notification-center relative"
  [ngClass]="{ 'overflow-hidden': isStackedView }"
  #contentContainer>
  <div id="parent-view">
    <div class="title-container">
      <div class="title">
        <div
          class="cursor-pointer dp2 text-blue-600"
          (click)="navigateToNotificationPage()">
          Notification Center
        </div>
        <m-type-pill size="small" text="Beta"></m-type-pill>
        @if (!isLoading && (topListAlerts.length || bottomListAlerts.length)) {
          <span class="notification-dot"></span>
        }
      </div>
    </div>
    @if (!topListAlerts.length && !bottomListAlerts.length && !isLoading) {
      <div class="empty-state">
        <i class="material-symbols-outlined icon-extra-large text-blue-300"
          >air</i
        >
        <div class="empty-state-text">
          <div class="sh2 text-gray-1000">All good!</div>
          <div class="b1 text-gray-1000">You have nothing new for now.</div>
        </div>
      </div>
    }

    @if (isLoading) {
      <div class="list-alerts">
        <ng-container *ngFor="let _ of [].constructor(10)">
          <m-alert-item isLoading="true"></m-alert-item>
        </ng-container>
      </div>
    }
    @if (!isLoading && topListAlerts.length) {
      <div class="px-4 pb-2 alerts-display">
        <span class="sh3 text-gray-1000">Action needed</span>
        <span class="ml-2 action-alert-count c2 text-gray-000">{{
          getActionAlertsCount()
        }}</span>
      </div>
    }
    @if (!isLoading && topListAlerts.length) {
      <div [class.action-alerts]="topListAlerts.length" [@slideIn]>
        <div *ngFor="let alertItem of topListAlerts">
          <m-alert-item
            [id]="alertItem.id"
            [alertConfigId]="alertItem.alertConfigId"
            [identifier]="alertItem.identifier"
            [alertIcon]="alertItem.alertIcon"
            [title]="alertItem.title"
            [tag]="alertItem.tag"
            [timestamp]="alertItem.timestamp"
            [body]="alertItem.shortDescription"
            [actionButtonsProps]="alertItem.actionButtonsProps"
            [isRead]="isAlertRead(alertItem.id)"
            [isResolved]="isAlertResolved(alertItem.id)"
            [isExpanded]="shouldExpand(alertItem.id)"
            [updateIsExpanded]="updateExpandedAlert.bind(this)"
            [undoActionStatus]="undoActionStatus.bind(this)"
            (alertActionTriggered)="handleAlertActions($event)">
          </m-alert-item>
        </div>
      </div>
    }
    @if (!isLoading && bottomListAlerts.length) {
      <div class="px-4 pb-2 pt-3 alerts-display">
        <span class="sh3 text-gray-1000">Alerts</span>
        @if (unreadInfoAlertsCount > 0) {
          <span class="ml-2 notification-dot"></span>
        }
      </div>
    }

    @if (!isLoading && bottomListAlerts.length) {
      <div [class.info-alerts]="bottomListAlerts.length" [@slideIn]>
        <div *ngFor="let alertItem of bottomListAlerts">
          <m-alert-item
            [id]="alertItem.id"
            [alertConfigId]="alertItem.alertConfigId"
            [identifier]="alertItem.identifier"
            [alertLogIds]="alertItem.alertLogIds"
            [alertIcon]="alertItem.alertIcon"
            [title]="alertItem.title"
            [tag]="alertItem.tag"
            [isStacked]="alertItem.isStacked"
            [stackedCount]="alertItem.stackedCount"
            [timestamp]="alertItem.timestamp"
            [body]="alertItem.shortDescription"
            [actionButtonsProps]="alertItem.actionButtonsProps"
            [isRead]="isAlertRead(alertItem.id)"
            [isExpanded]="shouldExpand(alertItem.id)"
            [updateIsExpanded]="updateExpandedAlert.bind(this)">
          </m-alert-item>
        </div>
      </div>
    }
    @if (!isLoading && (topListAlerts.length || bottomListAlerts.length)) {
      <div class="view-all-section">
        <span
          class="b1 text-blue-600 cursor-pointer"
          (click)="navigateToNotificationPage()"
          >View All</span
        >
      </div>
    }
  </div>
  @if (isStackedView) {
    <div
      id="child-view"
      class="m-notification-center absolute w-full h-full inset-0 slide-left-animation"
      #contentContainer>
      <div class="title-container">
        <div class="title">
          <i
            class="material-symbols-outlined icon-small back"
            (click)="back($event)"
            >arrow_back</i
          >
          <div class="title-text">Stacked Alerts</div>
          <m-type-pill size="small" text="Beta"></m-type-pill>
          @if (
            !isLoading && (topListAlerts.length || bottomListAlerts.length)
          ) {
            <span class="notification-dot"></span>
          }
        </div>
      </div>

      @if (isStackedAlertsLoading) {
        <div class="list-alerts">
          <ng-container *ngFor="let _ of [].constructor(10)">
            <m-alert-item isLoading="true"></m-alert-item>
          </ng-container>
        </div>
      }
      @if (!isStackedAlertsLoading && stackedAlerts?.length) {
        <div [@slideIn]>
          <div *ngFor="let alertItem of stackedAlerts">
            <m-alert-item
              [id]="alertItem.id"
              [alertIcon]="alertItem.alertIcon"
              [title]="alertItem.title"
              [tag]="alertItem.tag"
              [timestamp]="alertItem.timestamp"
              [body]="alertItem.shortDescription"
              [actionButtonsProps]="alertItem.actionButtonsProps"
              [isRead]="true"
              [isExpanded]="shouldExpand(alertItem.id)"
              [updateIsExpanded]="updateExpandedAlert.bind(this)">
            </m-alert-item>
          </div>
        </div>
      }
      @if (
        !isStackedAlertsLoading &&
        (topListAlerts.length || bottomListAlerts.length)
      ) {
        <div class="view-all-section">
          <span
            class="b1 text-blue-600 cursor-pointer"
            (click)="navigateToNotificationPage()"
            >View All</span
          >
        </div>
      }
    </div>
  }
</div>
