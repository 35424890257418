/**
 * Api documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FeatureConfigResponseDTO { 
    featureName?: FeatureConfigResponseDTO.FeatureNameEnum;
    visibility?: FeatureConfigResponseDTO.VisibilityEnum;
}
export namespace FeatureConfigResponseDTO {
    export type FeatureNameEnum = 'ALERTS_FEATURE' | 'CAMPAIGN_PRORATION_SHUTDOWN' | 'CCMAF_V2' | 'DIF_BETA' | 'DIF_INTRA' | 'ENABLE_GMAIL_API_TO_SEND_AN_EMAIL' | 'LT_ADJ' | 'MIM_EXPORT_BANNER' | 'MPO_V2' | 'NEW_NAV_FEATURE' | 'V1_DDI' | 'V1_ENABLED' | 'V1_TAXONOMY' | 'V2_DS' | 'MIMV2Requested';
    export const FeatureNameEnum = {
        AlertsFeature: 'ALERTS_FEATURE' as FeatureNameEnum,
        CampaignProrationShutdown: 'CAMPAIGN_PRORATION_SHUTDOWN' as FeatureNameEnum,
        CcmafV2: 'CCMAF_V2' as FeatureNameEnum,
        DifBeta: 'DIF_BETA' as FeatureNameEnum,
        DifIntra: 'DIF_INTRA' as FeatureNameEnum,
        EnableGmailApiToSendAnEmail: 'ENABLE_GMAIL_API_TO_SEND_AN_EMAIL' as FeatureNameEnum,
        LtAdj: 'LT_ADJ' as FeatureNameEnum,
        MimExportBanner: 'MIM_EXPORT_BANNER' as FeatureNameEnum,
        MpoV2: 'MPO_V2' as FeatureNameEnum,
        NewNavFeature: 'NEW_NAV_FEATURE' as FeatureNameEnum,
        V1Ddi: 'V1_DDI' as FeatureNameEnum,
        V1Enabled: 'V1_ENABLED' as FeatureNameEnum,
        V1Taxonomy: 'V1_TAXONOMY' as FeatureNameEnum,
        V2Ds: 'V2_DS' as FeatureNameEnum,
        Mimv2Requested: 'MIMV2Requested' as FeatureNameEnum
    };
    export type VisibilityEnum = 'ALL_USERS' | 'INTERNAL_USERS';
    export const VisibilityEnum = {
        AllUsers: 'ALL_USERS' as VisibilityEnum,
        InternalUsers: 'INTERNAL_USERS' as VisibilityEnum
    };
}


