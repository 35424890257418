export interface IDataSourceValidateSOT {
  id: number;
  clientId: number;
  brandId: number;
  dataSourceId: string;
  monthStart: Date;
  monthEnd: Date;
  conversionType: string;
  status: Status;
  rCreId: string;
  rCreTime: Date;
  rModId: string;
  rModTime: Date;
}

export enum Status {
  ALL_STATUS = 'ALL_STATUS',
  MARKED_CORRECT = 'MARKED_CORRECT',
  MARKED_INCORRECT = 'MARKED_INCORRECT',
  PENDING_VALIDATION = 'PENDING_VALIDATION',
  DATA_UPLOAD_IN_PROGRESS = 'DATA_UPLOAD_IN_PROGRESS'
}
