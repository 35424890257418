import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonComponentsModule } from '@libs/common-components';
import {
  CommonDataShareService,
  CommonServicesModule
} from '@libs/common-services';
import { DateRangePickerModule } from '@libs/date-range-picker';
import { DesignSystemModule } from '@libs/design-system';
import { ChartsModule } from '@portal/app/charts/charts.module';
import { AccordionSectionComponent } from '@portal/app/dashboard/accordion-section/accordion-section.component';
import { BrandsComponent } from '@portal/app/dashboard/brands/brands.component';
import { BreadCrumbComponent } from '@portal/app/dashboard/bread-crumb/bread-crumb.component';
import { CustomCardGroupComponent } from '@portal/app/dashboard/card/custom-card-group/custom-card-group.component';
import { CustomCardComponent } from '@portal/app/dashboard/card/custom-card/custom-card.component';
import { ContextBarComponent } from '@portal/app/dashboard/context-bar/context-bar.component';
import { DashboardRoutingModule } from '@portal/app/dashboard/dashboard-routing.module';
import { DateRangePickerComponent } from '@portal/app/dashboard/date-range-picker/date-range-picker.component';
import { ErrorHandlerComponent } from '@portal/app/dashboard/error-handler/error-handler.component';
import { ExperimentResultDataComponent } from '@portal/app/dashboard/experiment-result-data/experiment-result-data.component';
import { ExperimentResultDetailSectionComponent } from '@portal/app/dashboard/experiment-result-detail-section/experiment-result-detail-section.component';
import { ExportNativeDashboardDataComponent } from '@portal/app/dashboard/export-native-dashboard-data/export-native-dashboard-data.component';
import { FilterSetComponent } from '@portal/app/dashboard/filter-set/filter-set.component';
import { HeroGroupComponent } from '@portal/app/dashboard/hero-group/hero-group.component';
import { HeroComponent } from '@portal/app/dashboard/hero/hero.component';
import { MetricCardGroupComponent } from '@portal/app/dashboard/metric-card/metric-card-group/metric-card-group.component';
import { MetricCardItemComponent } from '@portal/app/dashboard/metric-card/metric-card-item/metric-card-item.component';
import { ViewCardComponent } from '@portal/app/dashboard/multi-views/view-card/view-card.component';
import { ViewControlsComponent } from '@portal/app/dashboard/multi-views/view-controls/view-controls.component';
import { ViewHeaderComponent } from '@portal/app/dashboard/multi-views/view-header/view-header.component';
import { ViewSelectorComponent } from '@portal/app/dashboard/multi-views/view-selector/view-selector.component';
import { NativeSectionsComponent } from '@portal/app/dashboard/native-dashboard-template/native-sections.component';
import { NavbarComponent } from '@portal/app/dashboard/navbar/navbar.component';
import { PrimengModule } from '@portal/app/dashboard/primeng.module';
import { ProductsComponent } from '@portal/app/dashboard/products/products.component';
import { SelectBrandComponent } from '@portal/app/dashboard/select-brand/select-brand.component';
import { SeperatorComponent } from '@portal/app/dashboard/seperator/seperator.component';
import { TabBarComponent } from '@portal/app/dashboard/tab-bar/tab-bar.component';
import { TabComponent } from '@portal/app/dashboard/tab/tab.component';
import { TemplateComponent } from '@portal/app/dashboard/template/template.component';
import { ViewComponent } from '@portal/app/dashboard/view/view.component';
import { DataGridModule } from '@portal/app/datagrid/data-grid.module';
import { SortByPipe } from '@portal/app/shared/pipes/sort-by.pipe';
import { StorageService } from '@portal/app/shared/services/storage.service';
import { SharedModule } from '@portal/app/shared/shared.module';
import { DividerModule } from 'primeng/divider';
import { MessagesModule } from 'primeng/messages';
import { AccordionDirective } from './accordion-section/accordion-section.directive';
import { NotificationCenterComponent } from '@portal/app/dashboard/notification-center/notification-center.component';
import { NotificationCenterFilterComponent } from '@portal/app/dashboard/notification-center/components/notification-center-filter/notification-center-filter.component';

// Homepage components
import { TaxonomyUiModule } from '@libs/taxonomy-ui';
import { CustomerFacingTaxonomyComponent } from '@portal/app/dashboard/customer-facing-taxonomy/customer-facing-taxonomy.component';
import { BenchmarkComponent } from '@portal/app/dashboard/home-page/components/benchmark/benchmark.component';
import { CompareChartComponent } from '@portal/app/dashboard/home-page/components/compare-chart/compare-chart.component';
import { CompareLineChartComponent } from '@portal/app/dashboard/home-page/components/compare-line-chart/compare-line-chart.component';
import { DimensionChartComponent } from '@portal/app/dashboard/home-page/components/dimension-chart/dimension-chart.component';
import { GeoComponent } from '@portal/app/dashboard/home-page/components/geo/geo.component';
import { HomepageHeaderComponent } from '@portal/app/dashboard/home-page/components/header/header.component';
import { ChannelsComponent } from '@portal/app/dashboard/home-page/components/my-channels/my-channels.component';
import { PortfolioHeaderComponent } from '@portal/app/dashboard/home-page/components/portfolio-header/portfolio-header.component';
import { PortfolioComponent } from '@portal/app/dashboard/home-page/components/portfolio/portfolio.component';
import { RecommendedComponent } from '@portal/app/dashboard/home-page/components/recommended/recommended.component';
import { SpendChartComponent } from '@portal/app/dashboard/home-page/components/spend-chart/spend-chart.component';
import { TrendingChartComponent } from '@portal/app/dashboard/home-page/components/trending-chart/trending-chart.component';
import { HomepageComponent } from '@portal/app/dashboard/home-page/home-page.component';
import { ShopifyDashboardComponent } from './samples/shopify/shopify-dashboard.component';
import { MmmBannerComponent } from '@portal/app/shared/components/mmm-banner/mmm-banner.component';
import { MmmExportBannerComponent } from '@portal/app/shared/components/mmm-export-banner/mmm-export-banner.component';
import { MyCustomersComponent } from '@portal/app/dashboard/home-page/components/my-customers/my-customers.component';
import { NewVsReturningCustomersComponent } from '@portal/app/dashboard/home-page/components/new-vs-returning-customers/new-vs-returning-customers.component';
import { CacVsLtvComponent } from '@portal/app/dashboard/home-page/components/cac-vs-ltv/cac-vs-ltv.component';
import { FeedbackModalComponent } from '@portal/app/shared/components/feedback-modal/feedback-modal.component';
import { PortalOptimizationResultsComponent } from '@portal/app/dashboard/home-page/components/optimization-results/optimization-results.component';
import { NgxSortableModule } from 'ngx-sortable';
import { CompareModeBannerComponent } from '@portal/app/shared/components/compare-mode-banner/compare-mode-banner.component';
import { MmmModalDetailsComponent } from '@portal/app/shared/components/mmm-model-details/mmm-model-details.component';
import { ContextModalModule } from './context-modal/context-modal.module';

@NgModule({
  imports: [
    CommonModule,
    DashboardRoutingModule,
    ChartsModule,
    DataGridModule,
    PrimengModule,
    DateRangePickerModule.forRoot(),
    FormsModule,
    SharedModule,
    DividerModule,
    CommonServicesModule,
    CommonComponentsModule,
    ReactiveFormsModule,
    MessagesModule,
    DesignSystemModule,
    TaxonomyUiModule,
    NgxSortableModule,
    ContextModalModule
  ],
  declarations: [
    SortByPipe,
    NavbarComponent,
    TemplateComponent,
    ProductsComponent,
    BrandsComponent,
    ViewComponent,
    ContextBarComponent,
    HeroComponent,
    HeroGroupComponent,
    SelectBrandComponent,
    TabBarComponent,
    NativeSectionsComponent,
    TabComponent,
    FilterSetComponent,
    CustomCardComponent,
    CustomCardGroupComponent,
    ExperimentResultDataComponent,
    DateRangePickerComponent,
    AccordionSectionComponent,
    ExperimentResultDetailSectionComponent,
    ViewControlsComponent,
    ViewHeaderComponent,
    ViewCardComponent,
    ViewSelectorComponent,
    AccordionDirective,
    MetricCardGroupComponent,
    MetricCardItemComponent,
    AccordionDirective,
    ErrorHandlerComponent,
    BreadCrumbComponent,
    SeperatorComponent,
    ExportNativeDashboardDataComponent,
    HomepageComponent,
    HomepageHeaderComponent,
    PortfolioComponent,
    DimensionChartComponent,
    CompareChartComponent,
    TrendingChartComponent,
    SpendChartComponent,
    CompareLineChartComponent,
    ChannelsComponent,
    RecommendedComponent,
    BenchmarkComponent,
    PortfolioHeaderComponent,
    GeoComponent,
    ShopifyDashboardComponent,
    CustomerFacingTaxonomyComponent,
    MmmBannerComponent,
    MmmExportBannerComponent,
    MyCustomersComponent,
    NewVsReturningCustomersComponent,
    CacVsLtvComponent,
    FeedbackModalComponent,
    PortalOptimizationResultsComponent,
    CompareModeBannerComponent,
    NotificationCenterComponent,
    NotificationCenterFilterComponent,
    MmmModalDetailsComponent
  ],
  exports: [
    AccordionSectionComponent,
    AccordionDirective,
    HeroComponent,
    DateRangePickerComponent,
    SeperatorComponent
  ],
  providers: [StorageService, CommonDataShareService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DashboardModule {}
