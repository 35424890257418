import { IShopifyDashboardColumnDef } from '@portal/app/dashboard/samples/shopify/shopify-dashboard.models';
import { IHeaderConfig, ITitle, TitleTypes } from '@libs/common-components';

export class ShopifyDashboardConstants {
  public static readonly headerConfig: IHeaderConfig = {
    primaryTitle: {
      field: 'dashboardTitle',
      defaultValue: 'Shopify Dashboard',
      span: 12
    },
    secondaryTitles: [],
    badges: [],
    faq: {
      field:
        'https://support.measured.com/article/o1jlynwqr4-admin-beta#integration_tab',
      label: 'Learn more about Shopify Integration',
      span: 12
    }
  };

  public static readonly tableTitleConfigs: ITitle[] = [
    {
      title: 'Shopify Orders',
      subTitle: null,
      type: TitleTypes.h4,
      styleClass: 'mb-0 text-gray-1000'
    }
  ];

  public static readonly tableColumns: IShopifyDashboardColumnDef[] = [
    {
      field: 'shopName',
      checkbox: true,
      header: 'Shop Name',
      width: '8%'
    },
    {
      field: 'customerId',
      checkbox: true,
      header: 'Customer Id',
      width: '3%'
    },
    {
      field: 'orderId',
      header: 'Order Id',
      width: '7%'
    },
    {
      field: 'createdAt',
      header: 'Created At',
      width: '7%'
    },
    {
      field: 'totalLineItemsPrice',
      header: 'Line Items Price',
      width: '12%',
      type: 'currency'
    },
    {
      field: 'totalDiscounts',
      header: 'Total Discounts',
      width: '7%',
      type: 'currency'
    },
    {
      field: 'totalTax',
      header: 'Total Tax',
      width: '8%',
      type: 'currency'
    },
    {
      field: 'totalPrice',
      header: 'Total Price',
      width: '8%',
      type: 'currency'
    },
    {
      field: 'currency',
      header: 'Currency',
      width: '6%'
    },
    {
      field: 'fulfillmentStatus',
      header: 'Fulfillment Status',
      width: '12%'
    },
    {
      field: 'billingAddressCountry',
      header: 'Billing Country',
      width: '10%'
    },
    {
      field: 'cancelledAt',
      header: 'Cancelled At',
      width: '7%'
    },
    {
      field: 'sourceName',
      header: 'Source Name',
      width: '7%'
    }
  ];
}
