import { Component, Renderer2 } from '@angular/core';

@Component({
  selector: 'portal-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss']
})
export class FeedbackModalComponent {
  constructor(private renderer: Renderer2) {}

  public isFeedbackModalVisible = false;
  private isScriptLoaded = false;

  public toggleFeedbackModal(): void {
    this.isFeedbackModalVisible = !this.isFeedbackModalVisible;
  }

  public onShowFeedbackModal(): void {
    if (!this.isScriptLoaded) {
      setTimeout(() => {
        this.loadAidaform();
      }, 1000);
      this.isScriptLoaded = true;
    }
  }

  private loadAidaform(): void {
    const scriptElement = this.renderer.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.text = `(function(){var r,d=document,gt=d.getElementById,cr=d.createElement,tg=d.getElementsByTagName,id="aidaform-embed";if(!gt.call(d,id)){r=cr.call(d,"script");r.id=id;r.src="https://embed.aidaform.com/embed.js";(d.head || tg.call(d,"head")[0]).appendChild(r);}})()`;

    this.renderer.appendChild(document.head, scriptElement);
  }
}
