/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil
} from 'rxjs/operators';
import { isEqual } from 'lodash-es';

// Importing chart data for horizontal bar chart
import { horizontalBarChart } from '@design-system/components/m-horizontal-bar-chart';

// Importing types and services
import { ChannelsService } from '@portal/app/dashboard/home-page/services/channels.service';
import { DataSetService } from '@portal/app/dashboard/home-page/services/data-set.service';
import { FormatService } from '@portal/app/dashboard/home-page/services/format.service';
import { FilterService } from '@portal/app/dashboard/home-page/services/filter.service';
import { FieldService } from '@portal/app/shared/services/field.service';
import { MetricsService } from '@portal/app/dashboard/home-page/services/metrics.service';

// Importing types
import type { Filter } from '@portal/app/shared/types';
import type { Metric } from '@portal/app/dashboard/home-page/services/metrics.service';
import type {
  Options,
  PlotOptions,
  PointLabelObject,
  SeriesBarOptions
} from 'highcharts';

@Injectable({
  providedIn: 'root'
})
export class DimensionChartService implements OnDestroy {
  private chartDataSubject = new BehaviorSubject<any>(null);
  public chartData$ = this.chartDataSubject.asObservable();
  private destroy$ = new Subject<void>();
  private dimensionsSubject = new BehaviorSubject<string[]>(['channel']);
  public dimensions$ = this.dimensionsSubject.asObservable();
  private horizontalBarChartOptions: Options =
    (horizontalBarChart.args as Options) || {};

  private isLoadingSubject = new BehaviorSubject<boolean>(true);
  public isLoading$ = this.isLoadingSubject.asObservable();

  constructor(
    private channelsService: ChannelsService,
    private dataSetService: DataSetService,
    private formatService: FormatService,
    private filterService: FilterService,
    private fieldService: FieldService,
    private metricsService: MetricsService
  ) {
    this.subscribeToChanges();
  }

  subscribeToChanges() {
    combineLatest([
      this.metricsService.activeMetric$,
      this.channelsService.activeChannel$,
      this.dimensions$,
      this.filterService.filters$,
      this.channelsService.selectedChannels$
    ])
      .pipe(
        map(
          ([
            activeMetric,
            activeChannel,
            dimensions,
            filters,
            selectedChannels
          ]) => ({
            activeMetric,
            activeChannel,
            dimensions,
            filters,
            selectedChannels
          })
        ),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        debounceTime(300),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: ({
          activeMetric,
          activeChannel,
          dimensions,
          filters,
          selectedChannels
        }) => {
          const dimensionOverride = activeChannel ? ['tactic'] : dimensions;
          const channels = activeChannel ? [activeChannel] : selectedChannels;

          if (activeMetric) {
            this.fetchData(activeMetric, dimensionOverride, channels, filters);
          }
        },
        error: (err: any) => console.error(err) // handle errors appropriately
      });
  }

  setDimensions(dimension: string) {
    this.dimensionsSubject.next([dimension]);
  }

  // Fetches data based on the provided parameters
  fetchData(
    activeMetric: Metric,
    dimensions: string[],
    channels: string[],
    filters: Filter[]
  ): void {
    this.isLoadingSubject.next(true);

    // Construct filters for data fetching
    const dataSetFilters = this.getFilters(
      filters,
      activeMetric?.literalId,
      channels
    );

    this.dataSetService
      .fetchDataSets({ dimensions, filters: dataSetFilters })
      .subscribe({
        next: (dataSets) => {
          this.updateHorizontalBarChart(dataSets, activeMetric);
          this.isLoadingSubject.next(false);
        },
        error: (error) => {
          // TODO:GP add error state
          console.error('Error fetching chart data', error);
          this.isLoadingSubject.next(false);
        }
      });
  }

  getFilters(
    providedFilters: Filter[],
    literalId: string,
    channels?: string[]
  ): Filter[] {
    const necessaryFilterIds = [
      'dateStart',
      'dateStop',
      'relative_day',
      'conversion_type',
      'isInitialFilterCall'
    ];

    const selectedFilters = providedFilters.filter((filter) =>
      necessaryFilterIds.includes(filter.literalId)
    );

    const hardcodedFilters = [
      this.filterService.createFilter({
        literalId: 'dataSets',
        type: 'string[]',
        value: ['topChannelTactic']
      }),
      this.filterService.createFilter({
        literalId: 'metrics',
        type: 'string',
        value: { topChannelTactic: [literalId] } as unknown as string
      })
    ];

    // Check if channels exist and are not empty, then add the channel filter
    if (channels && channels?.length > 0) {
      const channelFilter = this.filterService.createFilter({
        literalId: 'channel',
        type: 'string[]',
        value: channels
      });
      hardcodedFilters.push(channelFilter);
    }

    const combinedFiltersMap = new Map(
      [...selectedFilters, ...hardcodedFilters].map((filter) => [
        filter.literalId,
        filter
      ])
    );

    return Array.from(combinedFiltersMap.values());
  }

  // Updates the horizontal bar chart options with new data
  updateHorizontalBarChart(horizontalBarData: any, activeMetric: Metric): void {
    const validData =
      horizontalBarData?.[0]?.data?.filter((item: any) => {
        const value = item?.[activeMetric?.literalId];
        return value > 0 && isFinite(value);
      }) ?? [];

    const formattedData: SeriesBarOptions[] = [
      {
        type: 'bar',
        name: 'Current Period',
        data: validData
          .map((item: any) => item?.[activeMetric?.literalId])
          .filter((value: number) => value > 0 && isFinite(value)),
        pointPadding: 0.2
      }
    ];

    const categories = validData
      .map((item: any) => item?.channel ?? item?.tactic ?? '')
      .filter((category: string) => category);
    const [minValue, maxValue] = this.calculateMinMaxValues(formattedData);

    const updatedOptions: Options = {
      ...this.horizontalBarChartOptions,
      series: formattedData,
      xAxis: { ...this.horizontalBarChartOptions.xAxis, categories },
      yAxis: {
        ...this.horizontalBarChartOptions.yAxis,
        min: minValue,
        max: maxValue
      },
      plotOptions: this.updateBarPlotOptions(activeMetric)
    };

    this.chartDataSubject.next(updatedOptions);
  }

  // Updates bar plot options based on the active metric
  private updateBarPlotOptions(activeMetric: Metric): PlotOptions {
    // Safely access existing bar options and ensure fallback to an empty object
    const existingBarOptions =
      this.horizontalBarChartOptions.plotOptions?.bar ?? {};
    const formatService = this.formatService;
    const fieldService = this.fieldService;

    return {
      bar: {
        ...existingBarOptions,
        dataLabels: {
          ...existingBarOptions.dataLabels,
          formatter(this: PointLabelObject) {
            const value = this.point?.y;
            const fieldDefinition = fieldService.getFieldDefinitionByLiteralId(
              activeMetric?.literalId
            );

            if (!value || !fieldDefinition) {
              return '';
            }
            return `<tspan style="stroke: none">${formatService.formatValue(
              fieldDefinition,
              value
            )}</tspan>`;
          }
        }
      }
    };
  }

  // Calculates the minimum and maximum values for the Y-axis
  private calculateMinMaxValues(data: any[]): [number, number] {
    // Ensure that we flatten safely and only consider numbers
    const flatData = data.flatMap((s) => s.data?.filter(isFinite) ?? []);
    let minValue = Math.min(...flatData);
    let maxValue = Math.max(...flatData);

    if (flatData.length === 0) {
      // Provide default values if no valid data exists
      minValue = 0;
      maxValue = 0;
    } else {
      // Add a buffer of 24% to the maxValue
      const maxBuffer = maxValue * 0.24;
      maxValue += maxBuffer;

      // Subtract 25% from the minValue but ensure it's not less than 0
      const minBuffer = minValue * 0.25;
      minValue = Math.max(minValue - minBuffer, 0);
    }

    return [minValue, maxValue];
  }

  // Handles cleanup when the service is destroyed
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
