<div class="flex items-stretch justify-between m-banner {{ type }} rounded-md">
  <div
    [ngStyle]="{
      'flex-direction': creativePlacement === 'first' ? 'row-reverse' : 'row',
    }"
    class="items-center w-full flex flex-row">
    <div
      class="flex flex-col items-start gap-2 min-h-full pl-6 py-6 pr-0 banner-content"
      [ngClass]="{ 'w-6/12': creativePlacement !== 'hidden' }">
      <span class="mb-0 h4 banner-text-{{ type }}">{{ title }}</span>

      <div *ngIf="subText" class="b1 banner-subtext-{{ type }}">
        {{ subText }}
      </div>

      <span class="b1 text-blue-600 my-auto text-xs banner-action">
        <ng-content select="[action]"></ng-content>
      </span>
    </div>
    @if (creativePlacement !== "hidden") {
      <div
        class="flex items-end banner-creative mt-auto w-6/12"
        [ngClass]="{
          'mt-auto': creativeAlignment === 'bottom',
          'mb-auto': creativeAlignment === 'top',
        }">
        <ng-content select="[creative]"></ng-content>
      </div>
    }
  </div>
  @if (closeable) {
    <div class="text-center mb-auto ms-auto p-6">
      <span
        class="material-symbols-outlined icon-small text-blue-600 cursor-pointer"
        (click)="handleBannerClose()"
        >close</span
      >
    </div>
  }
</div>
