import { Injectable } from '@angular/core';
import {
  MetricCardElement,
  BaseElement,
  ChartElement,
  ChartGroupElement,
  ContextField,
  DataGridEditable,
  DataGridSelected,
  ElementGroup,
  Filter,
  FilterControl,
  FilterElement,
  FilterOption,
  FilterValue,
  HeroElement,
  RelativePosition,
  TabElement,
  TitleElement,
  WidgetType,
  FieldDefinitions,
  FieldDefinition
} from '@portal/app/shared/types';
import {
  createFilterFields,
  selectFilterControlValues,
  sortByPosition
} from '@portal/app/dashboard/utils';
import { isArray, isEmpty } from 'lodash-es';
import { DateTimeService } from '@portal/app/shared/services/date-time.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { SelectionService } from '@portal/app/shared/services/selection.service';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root'
})
export class NativeSectionsService {
  public maxColsPerRow = 12;

  constructor(
    private readonly dateService: DateTimeService,
    public readonly router: Router,
    private readonly location: Location,
    private readonly selectionService: SelectionService
  ) {}

  public setFilterDefaults(filters: Filter[]): Filter[] {
    return filters.map((f: Filter) => {
      if (f.type === 'string[]') {
        this.handleMultiSelectStringTypeFilter(f);
      } else if (f.type === 'dateRange') {
        this.handleDateRangeFilter(f);
      } else {
        if (f.options != null && !isEmpty(f.options)) {
          const [defaultFilterOptionSelection] = f.options;
          if (f.type === 'string' && isEmpty(f.value)) {
            f.value =
              typeof defaultFilterOptionSelection === 'object'
                ? ((defaultFilterOptionSelection as FilterOption)
                    .value as FilterValue)
                : (defaultFilterOptionSelection as FilterValue);
          } else if (
            f.value === null &&
            typeof defaultFilterOptionSelection === 'object'
          ) {
            f.value =
              (defaultFilterOptionSelection as FilterOption) &&
              (defaultFilterOptionSelection as FilterOption).value
                ? ((defaultFilterOptionSelection as FilterOption)
                    .value as FilterValue)
                : f.value;
          } else if (f.type === 'number' && f.value === null) {
            f.value = null;
          } else if (f.value === null) {
            f.value = f.options[0] as FilterValue;
          }
        }
      }
      return f;
    });
  }

  public convertToWidgetLayout(
    item: ElementGroup | TabElement,
    filters: Filter[]
  ): ElementGroup {
    switch (item.layoutType) {
      case 'DATA_CHART': {
        return {
          ...this.setItemWidget(WidgetType.chart, filters, item)
        };
      }
      case 'DATA_SET': {
        return {
          ...item,
          widget: {
            filterControls: this.setFilterControls(
              filters,
              WidgetType.table,
              item
            )
          }
        };
      }
      default:
        return item as ElementGroup;
    }
  }

  setItemWidget(
    layoutType: WidgetType,
    filters: Filter[],
    item: ElementGroup
  ): ElementGroup {
    const filterControls = this.setFilterControls(filters, layoutType, item);
    let selectedElement = item.elements.length ? item.elements[0] : [];
    if (filterControls.length && item.elements.length) {
      const filterElement = item.elements.find(
        (el) => el.literalId === filterControls[0]?.value
      );
      if (filterElement) {
        selectedElement = filterElement;
      }
    }
    item.widget = {
      elements: item.elements as ChartElement[],
      filterControls: filterControls.sort(sortByPosition),
      element: selectedElement as ChartElement
    };
    return item;
  }

  setFilterControls(
    filters: Filter[],
    layoutType: WidgetType,
    item: ElementGroup
  ) {
    let filterControls: ContextField[] = [];

    const filterValues = filters.filter(
      (f) => f.applyTo && f.applyTo.includes(layoutType)
    );
    if (item.filterControl) {
      // start - remove this code once configuration is added using json column
      if (this.router.url.includes('products/facebook-incrementality')) {
        (item.filterControl as FilterControl[])?.map((ff) => {
          ff.additionalAttributes = {
            optionSize: 3,
            valueCount: 3
          };
        });
      }
      // end - remove this code once configuration is added using json column

      filterControls = createFilterFields(item, filterValues);
      filterControls.forEach((ctrl) => {
        // type added for additional layout for filterControls might change once the config is ready
        // will have to modify it accordingly
        const additionalLayout = (item.filterControl as FilterControl[])?.find(
          (fc) => fc.dashboardFilterId === ctrl.name
        )?.additionalAttributes;
        if (ctrl.children && additionalLayout) {
          ctrl.additionalAttributes = additionalLayout;
          ctrl.children?.map((child) => {
            child.additionalAttributes = additionalLayout;
            child.value = selectFilterControlValues(child);
          });
        }
      });
    }
    return filterControls;
  }

  asFilterElements(val: unknown): FilterElement[] {
    return val as FilterElement[];
  }

  asTitleElement(val: unknown): TitleElement[] {
    return val as TitleElement[];
  }

  asString(val: unknown): string {
    return val as string;
  }

  asStringValueOptions(val: unknown): { label: string; value: string }[] {
    return val as { label: string; value: string }[];
  }

  asHeroElements(val: unknown): HeroElement[] {
    return val as HeroElement[];
  }

  asTableColumns(val: unknown): (DataGridSelected | DataGridEditable)[] {
    return val as (DataGridSelected | DataGridEditable)[];
  }

  asContextFields(val: unknown): ContextField[] {
    return val as ContextField[];
  }

  asChartElement(val: unknown): ChartElement {
    return val as ChartElement;
  }

  asChartElements(val: unknown): ChartElement[] {
    return val as ChartElement[];
  }

  asChartGroupElements(val: unknown): ChartGroupElement {
    return val as ChartGroupElement;
  }

  asMetricCardElements(val: unknown): MetricCardElement[] {
    return val as MetricCardElement[];
  }

  createItemsFromNewBackend(
    layout: ElementGroup[],
    filters: Filter[]
  ): ElementGroup[] {
    return this.computeRelativePosition(
      layout.sort(this.sortByDisplayOrder)
    ).map((item: ElementGroup) => this.convertToWidgetLayout(item, filters));
  }

  sortByDisplayOrder(a: BaseElement, b: BaseElement): number {
    if (a.displayOrder > b.displayOrder) {
      return 1;
    } else if (a.displayOrder < b.displayOrder) {
      return -1;
    }
    return 0;
  }

  getClassByLiteralId(literalId: string): string {
    const classNameMap: Record<string, string> = {
      ['geo-experiment-result']: 'result saas-ui',
      ['fb-experiment-result']: 'result saas-ui',
      ['portfolio-level']: 'portfolioDashboard saas-ui',
      ['fb-doe-conversion']: 'facebook saas-ui native-dashboard-styles',
      ['fb-doe-revenue']: 'facebook saas-ui native-dashboard-styles',
      ['measured-benchmarks']: 'benchmarks saas-ui native-dashboard-styles',
      ['benchmarks-drill-down']: 'benchmarks saas-ui native-dashboard-styles',
      ['cross-channel']: 'saas-ui native-dashboard-styles',
      ['cross-channel-beta']: 'saas-ui native-dashboard-styles',
      ['ddi-reporting-revenue']: 'saas-ui native-dashboard-styles',
      ['ddi-reporting-conversion']: 'saas-ui native-dashboard-styles'
    };
    return classNameMap[literalId]
      ? String(classNameMap[literalId])
      : 'dashboard';
  }

  getDisplayNameByLiteralId(literalId: string): string {
    const displayNameMap: Record<string, string> = {
      ['portfolio-level']: 'Media Plan Optimizer'
    };
    return displayNameMap[literalId]
      ? String(displayNameMap[literalId])
      : 'dashboard-display-name';
  }

  getWrapperComponentType(): string[] {
    return ['TAB', 'CONTEXT_BAR'];
  }

  shouldShowExport(literalId: string): boolean {
    return !(
      literalId === 'measured-benchmarks' ||
      literalId === 'geo-experiment-result' ||
      literalId === 'fb-experiment-result' ||
      literalId === 'portfolio-level'
    );
  }

  getBackUrl(literalId: string): string | undefined {
    const urlPathPrefix = `a/${this.selectionService.buildSelectionSlug()}/products/`;
    const productBackUrl: Record<string, string> = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'geo-experiment-result': `${urlPathPrefix}geo-doe/geoExperiments`,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'fb-experiment-result': `${urlPathPrefix}facebook-scale-config/flights`
    };
    return productBackUrl[literalId];
  }

  private computeRelativePosition(
    sortedLayout: ElementGroup[]
  ): ElementGroup[] {
    let spanCache = 0;
    for (let i = 0, len = sortedLayout.length; i < len; i += 1) {
      const itm = sortedLayout[i] as ElementGroup;
      if (spanCache === 0 && itm.span === this.maxColsPerRow) {
        // The item starts at 0 and ends at 12
        itm.relativePosition = [RelativePosition.first, RelativePosition.last];
      } else if (spanCache === 0 && itm.span < this.maxColsPerRow) {
        // The item starts at 0 and ends before 12
        itm.relativePosition = [RelativePosition.first];
      } else if (spanCache > 0 && spanCache + itm.span < this.maxColsPerRow) {
        // The item starts in the middle and ends before 12
        itm.relativePosition = [RelativePosition.middle];
      } else if (spanCache > 0 && spanCache + itm.span === this.maxColsPerRow) {
        // The item starts in the middle and ends at 12
        itm.relativePosition = [RelativePosition.last];
      } else if (spanCache + itm.span > this.maxColsPerRow) {
        console.warn(
          `Item's width and positioning will make it wrap to the next line. item: ${itm.literalId}`
        );
        itm.relativePosition = [];
      }
      spanCache = (spanCache + itm.span) % this.maxColsPerRow;
    }
    return sortedLayout;
  }

  nativeDashboardBack(literalId: string): void {
    const url = this.getBackUrl(literalId);
    if (url) {
      this.router.navigate([url], {}).catch((e) => console.error(e));
    } else {
      this.location.back();
    }
  }

  fetchDataPointsBasedOnLayoutType(layoutType: string) {
    return ['TITLE', 'RESULT_HEADER'].includes(layoutType);
  }

  handleMultiSelectStringTypeFilter(f: Filter): void {
    if (!isEmpty(f.options) && isEmpty(f.value)) {
      f.value = [];
    }
  }

  private handleDateRangeFilter(f: Filter) {
    if (f.value === null || (isArray(f.value) && f.value.length === 0)) {
      f.value = this.dateService.calculatePrevious30DaysAsStringArray();
    }
  }

  /**
   * Modifies the labels of field definitions based on certain conditions.
   *
   * @param literalId - The ID used to determine certain conditional logic.
   * @param filters - The array of filters used to find the specific filter value needed in the logic.
   * @param fieldDefinitions - The initial field definitions to be modified.
   *
   * @returns The modified field definitions.
   */
  getModifiedFieldDefinitions(
    literalId: string,
    filters: Filter[],
    fieldDefinitions: FieldDefinitions
  ): FieldDefinitions {
    const showChild = filters.find(
      (filter) => filter.literalId === 'showChild'
    )?.value;

    return Object.keys(fieldDefinitions).reduce((acc, key) => {
      const field = fieldDefinitions[key] as FieldDefinition;

      if (literalId === 'portfolio-level' && showChild) {
        if (field.label === 'Target Budget') field.label = 'New Budget';
      }

      acc[key] = { ...field };
      return acc;
    }, {} as FieldDefinitions);
  }

  shouldShowDivider(literalId: string): boolean {
    const allowedIds = [
      'ddi-reporting-revenue',
      'ddi-reporting-conversion',
      'cross-channel'
    ];
    return allowedIds.indexOf(literalId) !== -1;
  }

  getOptionalLineBreakForId(literalId: string): string {
    const requiredIds = [
      'ddi-reporting-revenue',
      'ddi-reporting-conversion',
      'cross-channel'
    ];
    return requiredIds.indexOf(literalId) !== -1 ? '<br/>' : '';
  }

  getMinDate(reportingRangeInMonths: number | undefined): dayjs.Dayjs {
    return reportingRangeInMonths
      ? dayjs().subtract(reportingRangeInMonths, 'months')
      : this.dateService.systemWideMinDateForDatePicker();
  }
}
