import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataShareService {
  progressSpinner = new Subject<{ show: boolean; msg: string }>();

  updateProgressSpinnerStatus(show: boolean, msg: string): void {
    setTimeout(() => {
      this.progressSpinner.next({ show, msg });
    }, 1);
  }
}
