<div
  class="{{
    !!element.COMPONENT_TAB
      ? 'd-flex flex-column flex-md-row justify-content-between align-items-start'
      : ''
  }}">
  <portal-component-tab
    *ngIf="!!element.COMPONENT_TAB"
    [element]="element"
    [activeTabIndex]="dataGridService.activeTabIndex[this.literalId]"
    [disableAction]="dataGridService.selectedRows[literalId].length === 0"
    (tabChange)="handleTabChange($event)"
    (actionClick)="handleTabAction($event)"></portal-component-tab>
  <div
    class="filters d-flex justify-content-between align-items-center mb-3"
    [ngClass]="filterFields.length !== 0 ? 'has-inputs' : ''">
    <label
      *ngIf="
        dimensions &&
        dimensionPickerEnabled &&
        dimensionNavigationElements.length === 0
      "
      class="label-format">
      {{ dimensions.label }}
      <p-multiSelect
        #multiSelect
        [(ngModel)]="currentDimensionValues"
        [options]="dimensionsOption"
        [filter]="true"
        filterPlaceHolder="Search"
        [baseZIndex]="2"
        (onChange)="handleMinimumSelection(multiSelect)"
        (onPanelHide)="onDimensionsPanelClose()"
        scrollHeight="24rem"
        [appendTo]="dimensionSelectorPanelAppendTo"
        selectedItemsLabel="{currentDimensionsValues.length} selected">
        <ng-template let-option pTemplate="item">
          <span
            tooltipPosition="right"
            [pTooltip]="option.label.length >= 27 ? option.label : ''"
            tooltipStyleClass="saas-tooltip">
            {{ option.label | ellipsis: 27 }}
          </span>
        </ng-template>
      </p-multiSelect>
    </label>

    <div
      *ngIf="dimensionNavigationElements.length"
      class="dimension-navigator-container mt-auto">
      <portal-data-grid-dimension-navigator
        class="pt-2"
        [configs]="dimensionNavigationConfig"
        [loading]="countDataLoading"
        (selectedDimensionNavigation)="
          handleDimensionNavigation($event)
        "></portal-data-grid-dimension-navigator>
    </div>
    <label
      *ngIf="metricPickerEnabled && dimensionNavigationElements.length === 0"
      class="label-format">
      {{ "Metrics" }}
      <p-multiSelect
        #multiSelect
        [(ngModel)]="currentMetricValues"
        [options]="metricsOptions"
        [filter]="true"
        filterPlaceHolder="Search"
        [baseZIndex]="2"
        (onChange)="handleMinimumSelection(multiSelect)"
        (onPanelHide)="onMetricPanelClose()"
        scrollHeight="24rem"
        [appendTo]="metricSelectorPanelAppendTo"
        panelStyleClass="metric-selector-panel"
        selectedItemsLabel="{currentMetricsValues.length} selected">
        <ng-template let-option pTemplate="item">
          <span
            tooltipPosition="right"
            [pTooltip]="option.label.length >= 27 ? option.label : ''"
            tooltipStyleClass="saas-tooltip">
            {{ option.label | ellipsis: 27 }}
          </span>
        </ng-template>
      </p-multiSelect>
    </label>
    <ng-template [ngIf]="filterFields.length !== 0">
      <div class="filter-inputs d-flex">
        <div *ngFor="let field of filterFields" class="me-2">
          <label
            >{{ field?.label }}
            <p-dropdown
              *ngIf="field.inputType === 'select'"
              [options]="asStringArray(field.options)"
              [(ngModel)]="field.value"
              (onChange)="setFilterValues(false, $event, field.name)"
              class="d-block"
              appendTo="body"
              [panelStyleClass]="literalId">
            </p-dropdown>
            <div *ngIf="field.inputType === 'number'" class="p-inputgroup">
              <span class="p-inputgroup-addon">{{
                formatterService.currencySymbolValue
              }}</span>
              <p-inputNumber
                [(ngModel)]="field.value"
                name="{{ field.name }}"
                [placeholder]="'Enter a value'"
                [minFractionDigits]="2"
                (onKeyDown)="filterInputKeyDown($event)"></p-inputNumber>
            </div>
          </label>
        </div>
      </div>
    </ng-template>
    <div
      class="saas-ui filter-buttons mt-0 ms-auto d-flex align-items-end gap-3"
      [ngClass]="{
        'mt-sm-auto': dimensionNavigationElements.length === 0,
        literalId: true,
      }"
      *ngIf="nativeSectionsService.shouldShowExport(literalId)">
      <label
        *ngIf="metricPickerEnabled && dimensionNavigationElements.length > 0"
        class="label-format">
        {{ "Metrics" }}
        <p-multiSelect
          #multiSelect
          [(ngModel)]="currentMetricValues"
          [options]="metricsOptions"
          [filter]="true"
          filterPlaceHolder="Search"
          [baseZIndex]="2"
          (onChange)="handleMinimumSelection(multiSelect)"
          (onPanelHide)="onMetricPanelClose()"
          scrollHeight="24rem"
          [appendTo]="metricSelectorPanelAppendTo"
          panelStyleClass="metric-selector-panel"
          selectedItemsLabel="{currentMetricsValues.length} selected">
          <ng-template let-option pTemplate="item">
            <span
              tooltipPosition="right"
              [pTooltip]="option.label.length >= 27 ? option.label : ''"
              tooltipStyleClass="saas-tooltip">
              {{ option.label | ellipsis: 27 }}
            </span>
          </ng-template>
        </p-multiSelect>
      </label>
      <button
        pButton
        *ngIf="filterable && dataGridConfig.showClearFiltersButton"
        class="p-button-primary clear-filter-btn"
        [disabled]="!wasFiltered"
        (click)="clearFilters()"
        label="Clear Filters"></button>
      <button
        pButton
        class="p-button-text p-button-icon-only"
        pTooltip="Export"
        tooltipPosition="bottom"
        tooltipStyleClass="saas-tooltip"
        showDelay="800"
        (click)="menu.toggle($event)">
        <span class="material-symbols-outlined"> ios_share </span>
      </button>
      <p-menu
        styleClass="saas-menu-overlay"
        #menu
        [popup]="true"
        [model]="exportTableMenu"
        appendTo="body"></p-menu>
    </div>
  </div>
</div>

<portal-empty-state [showMessage]="showEmptyState">
  <div
    [ngClass]="[
      nativeSectionsService.getClassByLiteralId(literalId),
      'table-responsive',
    ]">
    <p-table
      #dt
      [attr.selectionMode]="
        dataGridService.allowSelection[this.literalId] ? 'multiple' : null
      "
      [(selection)]="dataGridService.selectedRows[literalId]"
      [columns]="table.fields"
      (sortFunction)="sortByColumn($event)"
      [loading]="loading"
      [loadingIcon]="'pi pi-spin pi-spinner'"
      [resizableColumns]="dataGridConfig.isResizable"
      columnResizeMode="expand"
      responsiveLayout="scroll"
      [scrollable]="dataGridConfig.scrollable"
      [virtualScroll]="
        dataGridConfig.haveVirtualScroll &&
        (this?.table?.data?.length ?? 0) >= 10
      "
      [virtualScrollItemSize]="dataGridConfig.virtualScrollItemSize"
      [scrollHeight]="dataGridConfig.tableScrollHeightInRem"
      [responsive]="true"
      [ngClass]="literalId"
      [scrollDirection]="dataGridConfig.scrollDirection"
      [customSort]="dataGridConfig.customSort"
      [value]="table.data"
      (onColReorder)="onColumnReorder($event)"
      [reorderableColumns]="true"
      tableStyleClass="{{ isMultilineHeader ? 'multiline-header' : '' }}"
      styleClass="{{
        this.dataGridConfig.striped
          ? 'p-datatable-striped text-elide'
          : 'text-elide'
      }} {{ dataGridConfig.tableScrollHeightClass }} {{
        dataGridConfig.haveVirtualScroll ? 'virtual-scroll-footer-position' : ''
      }}"
      (onFilter)="filterChanged($event)"
      (onLazyLoad)="resetFooterRow()"
      rowGroupMode="{{ groupByFieldName ? 'subheader' : '' }}"
      [groupRowsBy]="groupByFieldName"
      [sortMode]="dataGridConfig.dashboardSortMode"
      (onSort)="persistSort($event)">
      <ng-template pTemplate="header" let-cols>
        <tr class="col-group" *ngIf="element.grouped?.length > 0">
          <ng-container
            *ngFor="
              let col of dataGridService.asGroupHeaders(
                nativeSectionsService.asTableColumns(cols),
                fieldDefinitions,
                literalId,
                showChild
              );
              let i = index
            ">
            <th
              *ngIf="!(i === 0 && col.label === '')"
              [scope]="col.colspan && col.colspan > 1 ? 'colgroup' : 'col'"
              [attr.colspan]="col.colspan"
              [ngClass]="[
                dataGridService.labelToClass(col.originalLabel),
                col.format,
                col.literalId,
              ]"
              [style.min-width]="col.colspan * 85 + 'px'">
              <span>
                {{ col.label }}
              </span>
            </th>
            <th
              *ngIf="i === 0 && col.label === ''"
              [scope]="col.colspan && col.colspan > 1 ? 'colgroup' : 'col'"
              [attr.colspan]="col.colspan"
              class="table-title">
              {{ showChild ? "Campaign Allocation" : "Tactic Allocation" }}
            </th>
          </ng-container>
        </tr>

        <tr class="header-cols">
          <th *ngIf="showChildSection !== null" id="empty-table-header"></th>
          <!--select all rows-->
          <th
            *ngIf="
              dataGridService.isSelectionAllowed(element, literalId) &&
              !!element.COMPONENT_TAB
            "
            id="select-all-button">
            <p-tableHeaderCheckbox
              (click)="selectAllRows()"></p-tableHeaderCheckbox>
          </th>
          <th
            *ngFor="
              let col of nativeSectionsService.asTableColumns(cols);
              let i = index
            "
            tooltipStyleClass="m-tooltip"
            pTooltip="{{
              getMetricTooltip(fieldDefinitions[col.dashboardFieldId])
            }}"
            showDelay="700"
            tooltipPosition="top"
            [escape]="false"
            pReorderableColumn
            [pReorderableColumnDisabled]="col.type === elementType.dimension"
            pResizableColumn
            [pResizableColumnDisabled]="col.type === elementType.column"
            pFrozenColumn
            [frozen]="
              dataGridConfig.frozenColumnsEnabled &&
              col.type === elementType.dimension &&
              isDesktopScreen
            "
            [ngClass]="[
              dataGridService.getGroupedBorder(col),
              dataGridService.labelToClass(col.label),
              fieldDefinitions[col.dashboardFieldId].format,
              'col-' + fieldDefinitions[col.dashboardFieldId].literalId,
              isPreviousPeriodColumn(col) ? 'previous-period' : '',
            ]"
            [style.min-width]="
              widthSpecificToFields[col.dashboardFieldId] +
              widthSpecificToFieldsUnit
            "
            [style.left]="!dataGridConfig.frozenColumnsEnabled ? '0px' : ''"
            [pSortableColumn]="
              isMeasuredBenchmarkLandingPage(literalId) &&
              col.dashboardFieldId === 'fullView'
                ? null
                : col.dashboardFieldId
            "
            scope="col">
            <div
              [ngClass]="[
                dataGridService.getGroupedBorder(col),
                dataGridService.labelToClass(col.label),
              ]">
              <span
                class="header-title"
                innerHTML="{{
                  fieldDefinitions[col.dashboardFieldId] | formatLabel
                }}">
              </span>
              <ng-container *ngIf="literalId !== 'portfolio-level'">
                <p-sortIcon [field]="col.dashboardFieldId"></p-sortIcon>
                <ng-container
                  *ngIf="filterable"
                  [ngSwitch]="fieldDefinitions[col.dashboardFieldId].format">
                  <p-columnFilter
                    *ngSwitchCase="'percent'"
                    [type]="
                      fieldDefinitions[col.dashboardFieldId] | formatFilterType
                    "
                    [field]="col.dashboardFieldId"
                    display="menu"
                    [minFractionDigits]="2"></p-columnFilter>
                  <p-columnFilter
                    [matchModeOptions]="
                      col.type === elementType.dimension &&
                      col.layoutType === layoutTypes.composite
                        ? matchModeOptions
                        : null
                    "
                    *ngSwitchDefault
                    [type]="
                      fieldDefinitions[col.dashboardFieldId] | formatFilterType
                    "
                    [field]="col.dashboardFieldId"
                    display="menu"></p-columnFilter>
                </ng-container>
              </ng-container>
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="groupheader" let-groupData let-cols="columns">
        <tr pRowGroupHeader>
          <td [colSpan]="cols.length">
            <span class="p-text-bold p-ml-2">{{ groupData.type }}</span>
          </td>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-rowData
        let-cols="columns"
        let-i="rowIndex">
        <tr
          portal-data-grid-row
          [widthSpecificToFields]="widthSpecificToFields"
          [widthSpecificToFieldsUnit]="widthSpecificToFieldsUnit"
          [rowIndex]="i"
          [dashboardLiteralId]="literalId"
          [cols]="cols"
          [frozenColumnsEnabled]="dataGridConfig.frozenColumnsEnabled"
          [fieldDefinitions]="fieldDefinitions"
          [editableFields]="table.editableFields"
          [rowData]="rowData"
          [shouldShowEditableInc]="isMpoDashboard()"
          [isRowFrozen]="isDesktopScreen"
          (rowDataChanged)="updateOnRowDataChange($event)"
          [filterSet]="filterSet"
          [allowSelection]="
            dataGridService.isSelectionAllowed(element, literalId)
          "
          [name]="name"
          [dimensionFormat]="dimensionFormat"
          [showExpansionArrow]="showChildSection"></tr>
      </ng-template>
      <ng-template
        pTemplate="footer"
        let-cols
        *ngIf="showFooterRow && dataGridConfig.showFooter">
        <tr [hidden]="showEmptyState" *ngFor="let footerRow of table.footer">
          <td
            [style.min-width]="
              widthSpecificToFields[col.dashboardFieldId] +
              widthSpecificToFieldsUnit
            "
            *ngFor="
              let col of nativeSectionsService.asTableColumns(cols);
              let index = index
            "
            pFrozenColumn
            [frozen]="
              dataGridConfig.frozenColumnsEnabled &&
              col.type === elementType.dimension &&
              isDesktopScreen
            "
            [ngClass]="[
              fieldDefinitions[col.dashboardFieldId].format,
              'col-' + fieldDefinitions[col.dashboardFieldId].literalId,
            ]">
            {{
              index === 0
                ? footerRow.footerTotalDisplayVal
                : (footerRow[col.dashboardFieldId]
                  | formatValue: fieldDefinitions[col.dashboardFieldId])
            }}
          </td>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="groupfooter"
        let-groupData
        let-cols="columns"
        class="data-grid-group-total">
        <tr class="p-rowgroup-footer">
          <td
            *ngFor="
              let col of nativeSectionsService.asTableColumns(cols);
              index as index
            "
            [ngClass]="[
              fieldDefinitions[col.dashboardFieldId].format,
              fieldDefinitions[col.dashboardFieldId].literalId,
              fieldDefinitions[col.dashboardFieldId].type,
            ]">
            <span *ngIf="index === 0" class="fw-boldish">Total</span>
            <span *ngIf="index !== 0 && groupData[groupByFieldName]">{{
              this.table.groupByRowFooter[groupData[groupByFieldName]][
                col.dashboardFieldId
              ] | formatValue: fieldDefinitions[col.dashboardFieldId]
            }}</span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</portal-empty-state>
