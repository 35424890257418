@if (isTacticModalVisible()) {
  <portal-context-modal-content
    [title]="title()"
    [pill]="pill()"
    [hasBackButton]="hasBackButton"
    [autoCompleteOptions]="tacticsOptions()"
    autoCompleteOptionLabel="label"
    (closeModal)="onCloseModal()"
    (selectAction)="selectAction($event)"
    (nextAction)="nextAction()"
    (previousAction)="previousAction()">
    <m-tab-view [tabPanelHeaders]="tabPanelHeaders">
      <ng-template [mTabIndex]="1" #tabContent>
        <div class="pt-2">
          @if (mimVersion() === 1 || isRollUpConversionType) {
            <portal-context-summary
              [comparisonPeriod]="
                compareCurrentPeriod()
              "></portal-context-summary>
          } @else {
            <portal-context-tactic-summary
              [comparisonPeriod]="
                compareCurrentPeriod()
              "></portal-context-tactic-summary>
          }
          <portal-context-tactic-insights></portal-context-tactic-insights>
        </div>
      </ng-template>
      <ng-template [mTabIndex]="2" #tabContent>
        <portal-context-metrics></portal-context-metrics>
      </ng-template>
      <ng-template [mTabIndex]="3" #tabContent>
        <div class="pt-2">
          <portal-context-media
            [isLoading]="isMediaLoading()"
            [table]="mediaTable()"
            [isDataAvailable]="isMediaDataAvailable()"></portal-context-media>
        </div>
      </ng-template>
    </m-tab-view>
  </portal-context-modal-content>
}
