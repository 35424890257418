/*
 *ngFor="let c of oneDimArray | sortBy:true/false:'asc'"
 *ngFor="let c of arrayOfObjects | sortBy:true/false:'asc':'propertyName'"
 */
import { Pipe, PipeTransform } from '@angular/core';
import { orderBy, sortBy } from 'lodash-es';

@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {
  transform<T>(
    value: T[],
    caseInsensitive = false,
    order: 'desc' | 'asc' = 'desc',
    column = ''
  ): T[] {
    if (!value) {
      // no array
      return value;
    }
    if (!column || column === '') {
      // sort 1-dimensional array
      const sorted = this.sortOnCaseSensitivity(value, caseInsensitive);
      if (order === 'asc') {
        return sorted;
      } else {
        return sorted.reverse();
      }
    }
    if (value.length <= 1) {
      // array with only one item
      return value;
    } else {
      let converted: T[] = value;
      let columnToSort = column;
      if (caseInsensitive) {
        converted = this.convertMultiOnCaseSensitivity(
          value,
          column,
          caseInsensitive
        );
        columnToSort = 'sortCol';
      }
      return orderBy(converted, [columnToSort], [order]).map((v: T) => {
        const typedV = v as Record<string, unknown>;
        delete typedV.sortCol;
        return typedV;
      }) as T[];
    }
  }

  sortOnCaseSensitivity<T>(value: T[], caseInsensitive: boolean): T[] {
    return sortBy(value, (v) => {
      if (typeof v === 'string' && caseInsensitive) {
        return v.toLowerCase();
      }
      return v;
    });
  }

  convertMultiOnCaseSensitivity<T>(
    value: T[],
    column: string,
    caseInsensitive: boolean
  ): T[] {
    let converted = value;
    if (caseInsensitive) {
      converted = value.map((v: T) => {
        const typedV = v as Record<string, unknown>;
        const item = typedV[column];
        if (typeof item === 'string') {
          return { ...typedV, sortCol: item.toLowerCase() };
        }
        return typedV;
      }) as T[];
    }
    return converted;
  }
}
