import { Injectable, computed, inject } from '@angular/core';
import { SelectionService } from '@portal/app/shared/services/selection.service';

@Injectable({ providedIn: 'root' })
export class QueryParamsService {
  private selectionService = inject(SelectionService);

  // Computed signal for query parameters with unified naming.
  queryParams = computed(() => {
    const { clientId, brandId } = this.selectionService.clientBrandSignal();

    return {
      clientId,
      brandId
    };
  });
}
