import { Injectable } from '@angular/core';

/**
 * Service for navigating to an external URL by updating the window object.
 *
 * This service is designed to address timeout issues during testing when directly using `window.location.href`.
 * By providing this service, we can easily mock and control method behavior in our tests, avoiding such issues.
 *
 */

@Injectable({
  providedIn: 'root'
})
export class WindowService {
  /**
   * Navigates the browser to the specified URL.
   *
   * @param href - The URL to navigate to.
   */
  navigateToHref(href: string): void {
    window.location.href = href;
  }

  getCurrentPage(): string {
    return window.location.href;
  }
}
