<div class="my-4 col-span-1 h-full" *ngIf="isLoading">
  <m-skeleton height="330px" width="100%"></m-skeleton>
</div>

<div class="my-4" *ngIf="!isLoading">
  <m-card [isExpanded]="true">
    <div header class="flex justify-between items-center w-full">
      <div class="sh2 text-gray-1000">Geo Tests</div>
    </div>
    <div class="col-span-1 mt-4">
      <p-tabView class="m-tabview">
        <p-tabPanel>
          <ng-template pTemplate="header">
            <span class="sh1">Recommended</span>
          </ng-template>

          <div *ngIf="recommendedTests.length">
            <m-geo-carousel
              [value]="recommendedTests"
              [numVisible]="1"
              [numScroll]="1"
              [circular]="true"
              (click)="$event.stopPropagation()"
              (navigateRequest)="navigateToGeo($event)">
            </m-geo-carousel>
          </div>

          <div *ngIf="!recommendedTests.length">
            <m-not-found></m-not-found>
            <m-button
              (click)="navigateToGeo()"
              type="secondary"
              styleClass="w-full my-3">
              <div class="b2 text-blue-600 w-full text-center">
                Select a new test
              </div>
            </m-button>
          </div>
        </p-tabPanel>
        <p-tabPanel>
          <ng-template pTemplate="header">
            <span class="sh1">My Tests</span>
          </ng-template>

          <div *ngIf="myTests.length">
            <div class="flex justify-between mb-3">
              <span class="c3 text-gray-800">Status</span>
              <span class="c3 text-gray-800">Tests</span>
            </div>
            <m-card variant="small">
              <div *ngFor="let test of myTests" class="flex justify-between">
                <span [ngClass]="test.color" class="c2">{{ test.status }}</span>
                <span [ngClass]="test.color" class="c2">{{ test.count }}</span>
              </div>
            </m-card>

            <m-button
              (click)="navigateToGeo()"
              type="secondary"
              styleClass="w-full my-3">
              <div class="b2 text-blue-600 w-full text-center">View all</div>
            </m-button>
          </div>

          <div *ngIf="!myTests.length">
            <m-not-found></m-not-found>
            <m-button
              (click)="navigateToGeo()"
              type="secondary"
              styleClass="w-full my-3">
              <div class="b2 text-blue-600 w-full text-center">
                Select a new test
              </div>
            </m-button>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
  </m-card>
</div>
