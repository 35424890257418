import { Component, computed, OnDestroy, OnInit, signal } from '@angular/core';
import { TaxonomyBannerService } from '@portal/app/shared/components/taxonomy-banner/taxonomy-banner.service';
import { Subject, takeUntil } from 'rxjs';
import { Message } from 'primeng/api';

@Component({
  selector: 'portal-taxonomy-banner',
  templateUrl: './taxonomy-banner.component.html',
  styleUrl: './taxonomy-banner.component.scss'
})
export class TaxonomyBannerComponent implements OnInit, OnDestroy {
  public messages = signal<Message[]>([]);
  public messagesCount = computed(() => this.messages().length);

  private destroy$ = new Subject<void>();

  constructor(private readonly taxonomyBannerService: TaxonomyBannerService) {}

  ngOnInit(): void {
    this.taxonomyBannerService.getHeroMetrics();
    this.taxonomyBannerService.heroMetrics$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.messages.set(this.taxonomyBannerService.getUnmappedMessages());
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
