import { ActivatedRoute, Router } from '@angular/router';
import { NativeSectionsService } from '@portal/app/shared/services/native-sections.service';
import { Location } from '@angular/common';
import { INativeBackBar } from '@portal/app/shared/models/INativeBackBar';

const fullWidthSpan = 12;

export abstract class GenericBackBarAbstract implements INativeBackBar {
  abstract label: string;
  abstract routeParentPath: string | null;
  abstract span: number;

  protected defaultLabel = 'Back';
  protected defaultRouterParentPath = null;
  protected defaultSpan = fullWidthSpan;

  protected constructor(
    protected readonly router: Router,
    protected readonly route: ActivatedRoute,
    protected readonly nativeSectionsService: NativeSectionsService,
    protected readonly location: Location
  ) {}

  handleGenericBackEvent(): void {
    this.router
      .navigate([this.routeParentPath], {
        relativeTo: this.route.parent?.parent
      })
      .catch((e: Error) => {
        console.error(e);
      });
  }

  handleNativeBackEvent(productId: string): void {
    const url = this.nativeSectionsService.getBackUrl(productId);
    if (url) {
      this.router
        .navigate([url], {
          relativeTo: this.route
        })
        .catch((e) => console.error(e));
    } else {
      this.location.back();
    }
  }
}
