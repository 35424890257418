<div class="m-button-group-wrapper">
  @if (decoratedOptions.length) {
    @for (option of decoratedOptions; track option; let i = $index) {
      <button
        type="button"
        pButton
        [ngClass]="[
          'm-button-group-btn',
          option.value === selectedOption ? 'selected' : '',
        ]"
        (click)="$event.preventDefault(); onButtonClickHandler(i)"
        [label]="option.label"></button>
    }
  } @else {
    <button type="button" class="button border-0 m-0" [disabled]="true">
      No Data
    </button>
  }
</div>
