import { Component, Input } from '@angular/core';

@Component({
  selector: 'm-tag',
  templateUrl: './m-tag.component.html',
  styleUrls: ['./m-tag.component.scss']
})
export class MTagComponent {
  @Input() isDark = false;
  @Input() isPositive = true;
  @Input() isUp = true;
  @Input() isNeutral = false;
  @Input() showBackground = true;
}
