import * as Highcharts from 'highcharts';

export class AppConfigService {
  public static initialize(): void {
    this.setupHighchartsOptions();
  }

  private static setupHighchartsOptions(): void {
    Highcharts.setOptions({
      chart: {
        style: {
          fontFamily: 'Inter',
          fontSize: '0.9rem'
        }
      }
    });
  }
}
