import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { AuthService } from '@portal/app/shared/services/auth.service';
import { ClientStoreService } from '@portal/app/dashboard/geo-doe-config/data-stores/client-store.service';
import { ApiService } from '@portal/app/shared/services/api.service';
import { Brand } from '@portal/app/shared/types';
import { CurrencySymbol } from '@portal/app/shared/types/currency-format';
import { SelectionService } from '@portal/app/shared/services/selection.service';
import { CurrencyService } from '@portal/app/shared/services/currency.service';
import {
  INTEGRATIONS_BRAND_ID_KEY,
  INTEGRATIONS_CLIENT_ID_KEY
} from '@portal/app/dashboard/integrations/shared/constants';
import { StorageService } from '@portal/app/shared/services/storage.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { LocalStorageService } from '@portal/app/shared/services/localstorage.service';

@Component({
  selector: 'portal-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly titleService: Title,
    private readonly clientStoreService: ClientStoreService,
    private readonly currencyService: CurrencyService,
    private readonly selectionService: SelectionService,
    private readonly apiService: ApiService,
    private readonly storageService: StorageService,
    private readonly localStorageService: LocalStorageService,
    private readonly gtmService: GoogleTagManagerService
  ) {}

  ngOnInit(): void {
    const user = this.authService.getUser();
    // Handles case where a user refreshes the page
    //  and doesn't go through the login flow
    if (user != null) {
      this.apiService.getClients().subscribe({
        next: (resp) => {
          this.clientStoreService.allClients = resp.clients;
          this.authService.initPendo(user, this.clientStoreService.allClients);
          this.router.events.subscribe(() => {
            this.handleIntegrationsUIStorage();
          });
        }
      });
    }
    const brand = this.selectionService.getSelection();
    if (brand.brand) {
      this.setUpBrandCurrency(brand.brand);
    }
    const currentTitle = this.titleService.getTitle();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          // Handle Title
          this.localStorageService.setPageUUID();
          const descendent = this.getLastChild(this.activatedRoute);
          if (descendent.snapshot.data && descendent.snapshot.data.title) {
            return descendent.snapshot.data.title;
          }
          return currentTitle;
        })
      )
      .subscribe((title: string) => {
        this.titleService.setTitle(title);
      });
    this.gtmService.addGtmToDom();
  }

  setUpBrandCurrency(brand: Brand) {
    const currencyCode = brand.currencyCode ? brand.currencyCode : 'USD';
    const currencyDisplay: CurrencySymbol =
      this.currencyService.checkCurrencySymbol(brand);
    const currencyLocale = brand?.currencyLocale
      ? brand.currencyLocale
      : 'en-US';
    this.currencyService.updateCurrencyData({
      code: currencyCode,
      locale: currencyLocale,
      display: currencyDisplay
    });
  }

  private getLastChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    return activatedRoute.firstChild
      ? this.getLastChild(activatedRoute.firstChild)
      : activatedRoute;
  }

  private handleIntegrationsUIStorage() {
    const rt = this.getLastChild(this.activatedRoute);
    rt.data.subscribe((data) => {
      if (!data.isIntegrationsUI) {
        this.clearIntegrationsUIData();
      }
    });
  }

  private clearIntegrationsUIData(): void {
    this.storageService.remove(INTEGRATIONS_CLIENT_ID_KEY);
    this.storageService.remove(INTEGRATIONS_BRAND_ID_KEY);
  }
}
