import { IVCampaign } from '@libs/apis';
import {
  TaxonomyReviewChangeType,
  TaxonomyUiConstants
} from '../taxonomy-ui.constants';

export enum Status {
  drafted = 'DRAFTED',
  modified = 'MODIFIED'
}

export class TaxonomyReviewPublishConstants {
  public static readonly cancelPublishChanges = 'cancel-publish-changes';
  public static readonly tableColumns = [
    {
      width: '35%',
      field: 'campaign',
      header: 'Campaign'
    },
    {
      width: '20%',
      field: 'compositeDataSourceName',
      header: 'Data Source'
    }
  ];

  public static readonly operationalChannelTableColumns = {
    width: '15%',
    field: 'operationalChannel',
    header: 'Channel',
    previousValue: (campaign: IVCampaign) =>
      !campaign.channel ||
      campaign.channel === TaxonomyUiConstants.unmappedValue
        ? TaxonomyUiConstants.unmappedValue
        : campaign.channel,
    value: (campaign: IVCampaign) =>
      !campaign.operationalChannel ||
      campaign.operationalChannel === TaxonomyUiConstants.unmappedValue
        ? TaxonomyUiConstants.unmappedValue
        : campaign.operationalChannel
  };

  public static readonly operationalSegmentTableColumns = {
    width: '15%',
    field: 'operationalSegment',
    header: 'Segment',
    previousValue: (campaign: IVCampaign) =>
      !campaign.segment ||
      campaign.segment === TaxonomyUiConstants.unmappedValue
        ? TaxonomyUiConstants.unmappedValue
        : campaign.segment.includes('#####')
          ? 'Multiple Segments'
          : campaign.segment,
    value: (campaign: IVCampaign) => {
      return !campaign.operationalSegment ||
        campaign.operationalSegment === TaxonomyUiConstants.unmappedValue
        ? TaxonomyUiConstants.unmappedValue
        : campaign.operationalSegment.includes('#####')
          ? 'Multiple Segments'
          : campaign.operationalSegment;
    }
  };

  public static readonly operationalTacticTableColumns = {
    width: '15%',
    field: 'operationalTactic',
    header: 'Tactic',
    previousValue: (campaign: IVCampaign) =>
      !campaign.tactic || campaign.tactic === TaxonomyUiConstants.unmappedValue
        ? TaxonomyUiConstants.unmappedValue
        : campaign.tactic.includes('#####')
          ? 'Multiple Tactics'
          : campaign.tactic,
    value: (campaign: IVCampaign) =>
      !campaign.operationalTactic ||
      campaign.operationalTactic === TaxonomyUiConstants.unmappedValue
        ? TaxonomyUiConstants.unmappedValue
        : campaign.operationalTactic.includes('#####')
          ? 'Multiple Tactics'
          : campaign.operationalTactic
  };

  public static readonly actionTableColumn = {
    width: '10%',
    field: 'action',
    header: 'Action',
    hideSearch: true
  };

  public static readonly taxonomyReviewTabs = [
    {
      label: TaxonomyReviewChangeType.unpublished,
      value: Status.drafted
    },
    {
      label: TaxonomyReviewChangeType.pendingChanges,
      value: Status.modified
    }
  ];
}
