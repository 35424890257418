@if (isLoading()) {
  <div class="h-full">
    <m-skeleton width="100%" height="290px"></m-skeleton>
  </div>
} @else if (!isDataAvailable()) {
  <!-- Empty state -->
  <portal-context-modal-card
    class="h-full"
    [card]="emptyStateCard"
    (buttonAction)="onButtonAction()">
    <div>
      <m-card [variant]="cardVariantType.small">
        <div class="grid grid-cols-12 gap-4 items-center">
          <div
            class="b1 text-gray-1000 col-span-7"
            [innerHTML]="emptyStateCard.content"></div>
          <div class="col-span-5">
            <img
              width="140px"
              class="block ml-auto"
              src="assets/images/benchmarks.svg"
              alt="Benchmark" />
          </div>
        </div>
      </m-card>
    </div>
  </portal-context-modal-card>
} @else {
  <portal-context-modal-card
    class="h-full"
    [card]="card()"
    (buttonAction)="onButtonAction()">
    <div class="grid gap-3 grid-cols-2">
      @for (metric of card()?.metrics; track metric) {
        <div class="col-span-1" *ngIf="metric">
          <m-card [variant]="cardVariantType.small">
            <div class="b1 text-gray-800">{{ metric.label }}</div>
            <div class="sh2 text-gray-1000">{{ metric.formattedValue }}</div>
          </m-card>
        </div>
      }
    </div>
  </portal-context-modal-card>
}
