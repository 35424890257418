@if (titleConfigs.length > 0) {
  <div [ngClass]="'TITLE'">
    @for (titleConfig of titleConfigs; track titleConfig) {
      <div>
        @switch (titleConfig.type) {
          @case ("H3") {
            <h3 class="HEADING" [ngClass]="titleConfig.styleClass">
              {{ titleConfig.title }} {{ titleConfig.subTitle }}
            </h3>
          }
          @case ("H4") {
            <h4 class="SUB_HEADING" [ngClass]="titleConfig.styleClass">
              {{ titleConfig.title }} {{ titleConfig.subTitle }}
            </h4>
          }
          @case ("Info") {
            <div
              class="fw-boldish info-heading INFO_HEADING"
              [ngClass]="titleConfig.styleClass">
              {{ titleConfig.title }} {{ titleConfig.subTitle }}
              @if (titleConfig.icon) {
                <em
                  class="{{ titleConfig.icon.class }}"
                  [ngClass]="titleConfig.icon.styleClass"
                  showDelay="800"
                  pTooltip="{{ titleConfig.icon.tooltip }}"></em>
              }
            </div>
          }
          @case ("TITLE_SUBTITLE") {
            <div class="px-2">
              <label class="d-block fw-normal fs-5"
                >{{ titleConfig.title }}
                <p class="mt-3 small">
                  {{ titleConfig.subTitle }}
                </p>
              </label>
            </div>
          }
        }
      </div>
    }
  </div>
}
