@if (isLoading()) {
  <m-skeleton width="100%" height="290px"></m-skeleton>
} @else if (!isDataAvailable()) {
  <m-not-available></m-not-available>
} @else {
  <m-table
    [columns]="table().columns"
    [value]="table().values"
    [footerData]="table().footer"></m-table>
}
