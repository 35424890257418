import { Component, Input } from '@angular/core';
import { Required } from '@portal/app/shared/decorators/required.decorator';
import { FieldDefinitions } from '@portal//app/shared/types';

@Component({
  selector: 'portal-delta-cell',
  templateUrl: './delta-cell.component.html',
  styleUrls: ['./delta-cell.component.scss']
})
export class DeltaCellComponent {
  @Input() value = 0;
  @Input() changedValue = 0;
  @Input() @Required fieldDefinitions!: FieldDefinitions;
  @Input() @Required literalId!: string;

  getDeltaCellChangedClassName() {
    if (this.changedValue > 0) {
      return 'increased';
    } else if (this.changedValue < 0) {
      return 'decreased';
    } else {
      return 'zero';
    }
  }
}
