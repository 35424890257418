import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IProcessApiOperations } from '@libs/apis';
import { IProcessExecutionLog } from '@libs/apis';
import { Utility } from '../helpers/utility';
import {
  IProcessInstanceConfig,
  IProcessInstanceStatusUpdateRequest,
  IProcessInstanceStatusUpdateResponse,
  IProcessRunResponse,
  IProcessSchedule,
  IProcessType
} from '@libs/apis';
import { ClientType } from '@libs/apis';
import { Status } from '@libs/apis';

const promiseFrom = lastValueFrom;

export class ProcessApis implements IProcessApiOperations {
  private constructor(
    private readonly httpClient: HttpClient,
    private readonly apiV1: string,
    private readonly apiV2: string
  ) {}

  public static processApiOperation(
    httpClient: HttpClient,
    apiV1: string,
    apiV2: string
  ): IProcessApiOperations {
    return new ProcessApis(httpClient, apiV1, apiV2);
  }

  public async getProcessExecutionLogs(
    clientId: number
  ): Promise<Record<string, IProcessExecutionLog>> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/process-exe-log/:clientId`,
      pathVariables: { clientId }
    });

    return promiseFrom(
      this.httpClient.get<Record<string, IProcessExecutionLog>>(url)
    );
  }

  public async getAllClientProcess(
    clientId: number
  ): Promise<IProcessInstanceConfig[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/process-instance-config`,
      queryParameters: {
        clientId
      }
    });
    return promiseFrom(this.httpClient.get<IProcessInstanceConfig[]>(url));
  }

  public async getProcessInstance(
    processId: number
  ): Promise<IProcessInstanceConfig> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/process-instance-config/:processId`,
      pathVariables: {
        processId
      }
    });
    return promiseFrom(this.httpClient.get<IProcessInstanceConfig>(url));
  }

  public async getProcessSchedule(
    scheduleId: number
  ): Promise<IProcessSchedule> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/schedules/:scheduleId`,
      pathVariables: {
        scheduleId
      }
    });
    return promiseFrom(this.httpClient.get<IProcessSchedule>(url));
  }

  public async updateProcessInstanceStatus(
    clientId: number,
    statusUpdate: IProcessInstanceStatusUpdateRequest
  ): Promise<IProcessInstanceStatusUpdateResponse> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/process-instance-config/bulk-update/status`,
      queryParameters: {
        clientId
      }
    });
    return promiseFrom(
      this.httpClient.put<IProcessInstanceStatusUpdateResponse>(
        url,
        statusUpdate
      )
    );
  }

  public async runProcess(processId: number): Promise<IProcessRunResponse> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/process-instance-config/trigger/:processId`,
      pathVariables: {
        processId
      }
    });
    return promiseFrom(this.httpClient.post<IProcessRunResponse>(url, null));
  }

  public async getProcessType(processTypeId: string): Promise<IProcessType> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/process-type/:processTypeId`,
      pathVariables: {
        processTypeId
      }
    });
    return promiseFrom(this.httpClient.get<IProcessType>(url));
  }

  public async updateProcessInstanceConfig(
    processInstanceConfigId: number,
    data: IProcessInstanceConfig
  ): Promise<IProcessInstanceConfig> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/process-instance-config/:processInstanceConfigId`,
      pathVariables: {
        processInstanceConfigId
      }
    });
    return promiseFrom(this.httpClient.put<IProcessInstanceConfig>(url, data));
  }

  public async createProcessInstanceConfig(
    data: IProcessInstanceConfig
  ): Promise<IProcessInstanceConfig> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/process-instance-config`
    });
    return promiseFrom(this.httpClient.post<IProcessInstanceConfig>(url, data));
  }

  public async getAllProcessSchedule(): Promise<IProcessSchedule[]> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/schedules`
    });
    return promiseFrom(this.httpClient.get<IProcessSchedule[]>(url));
  }

  public async getAllProcessScheduleV1(): Promise<IProcessSchedule[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/schedules`
    });
    return promiseFrom(this.httpClient.get<IProcessSchedule[]>(url));
  }

  public async getAllProcessType(status?: Status): Promise<IProcessType[]> {
    const url = Utility.buildURL({
      url: `${this.apiV1}/process-type`,
      queryParameters: {
        statuses: status || null
      }
    });
    return promiseFrom(this.httpClient.get<IProcessType[]>(url));
  }

  public async getDefaultProcessTypes(
    clientType: ClientType
  ): Promise<IProcessInstanceConfig[]> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/process-instance-config/default-process-types/:clientType`,
      pathVariables: { clientType }
    });
    return promiseFrom(this.httpClient.get<IProcessInstanceConfig[]>(url));
  }
}
