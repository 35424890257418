<p-toast #toast class="m-toast" [position]="position" [baseZIndex]="baseZIndex">
  <ng-template let-message pTemplate="message">
    <div class="flex flex-col w-full">
      <div class="flex gap-2 items-start w-full text-left">
        @switch (message.severity) {
          @case ("success") {
            <span class="flex material-symbols-outlined text-green-700">
              check_circle
            </span>
          }
          @case ("warn") {
            <span class="flex material-symbols-outlined text-orange-300">
              warning
            </span>
          }
          @case ("error") {
            <span class="flex material-symbols-outlined text-red-700">
              error
            </span>
          }
          @case ("info") {
            <span class="flex material-symbols-outlined text-blue-700">
              info
            </span>
          }
        }
        <div class="flex flex-col gap-2 items-start w-full">
          <div class="flex items-center justify-between w-full">
            <span class="text-gray-1000 sh3">
              {{ message.summary }}
            </span>
            @if (message.closable) {
              <span
                class="flex items-center material-symbols-outlined text-blue-600 cursor-pointer"
                (click)="toast.messageService.clear(message.key)"
                >close</span
              >
            }
          </div>
          <div class="flex flex-col items-start gap-4 justify-center w-full">
            <div class="sh1 text-gray-1000 text-left">
              <span>{{ message.detail }}</span>
              @if (
                message.data?.showRefreshButton &&
                message.severity !== "success"
              ) {
                <span
                  ><a
                    class="p-toast-link"
                    target="_blank"
                    rel="noopener"
                    href="https://measured.atlassian.net/servicedesk/customer/portal/2">
                    contact support.</a
                  ></span
                >
              }
            </div>
            @if (
              (message.data?.buttons && message.data.buttons.length) ||
              message.data?.showRefreshButton ||
              message.data?.showSupportButton ||
              message.data?.showDismissButton
            ) {
              <div class="flex gap-4 items-center justify-end w-full">
                @if (message.data?.buttons && message.data?.buttons.length) {
                  @for (button of message.data?.buttons; track button) {
                    <m-button
                      [type]="button.type"
                      (click)="emitButtonAction(button.actionId)"
                      ><span class="b2">{{ button.label }}</span></m-button
                    >
                  }
                }
                @if (message.data?.showRefreshButton) {
                  <m-button (click)="refreshPage()"
                    ><span class="b2">Refresh page</span></m-button
                  >
                }
                @if (message.data?.showSupportButton) {
                  <m-button
                    type="primary"
                    (click)="navigateToContactSupportPage()"
                    ><span class="b2">Contact support</span></m-button
                  >
                }
                @if (message.data?.showDismissButton) {
                  <m-button
                    type="primary"
                    (click)="toast.messageService.clear(message.key)"
                    ><span class="b2">Dismiss</span></m-button
                  >
                }
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>
