/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, OnDestroy, WritableSignal, signal } from '@angular/core';
import dayjs from 'dayjs';
import {
  debounceTime,
  distinctUntilChanged,
  merge,
  Observable,
  Subject,
  takeUntil
} from 'rxjs';
import {
  DashboardDataControllerV2Service,
  FeatureConfigResponseDTO
} from '@libs/api';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ApiService } from '@portal/app/shared/services/api.service';
import { SelectionService } from '@portal/app/shared/services/selection.service';
import { FeatureConfigService } from '@portal/app/shared/services/feature-config.service';
import { ConfigSwitchService } from '@portal/app/shared/services/config-switch.service';
import { MmmBannerService } from '@portal/app/shared/components/mmm-banner/mmm-banner.service';
import { FormatterService } from '@portal/app/shared/services/formatter.service';
import { LayoutConfigService } from '@portal/app/shared/services/layout-config.service';
import { DatasetAvailabilityService } from '@portal/app/shared/services/dataset-availability.service';

import {
  DataEndpoint,
  FieldDefinitions,
  Filter
} from '@portal/app/shared/types';
import { FiltersService } from '@portal/app/shared/services/filters.service';
import {
  DashboardLiteralIds,
  FieldDefinitions as FieldDefinitionsEnum,
  FilterIds,
  ProductLiteralIds,
  ViewBy
} from '@portal/app/shared/constants';
import {
  ColumnDefinition,
  FormattedRow,
  FooterData
} from './mmm-export-banner.types';
import { SelectItem } from 'primeng/api';
import { DATE_FILTER_FORMAT } from '@portal/app/dashboard/integrations/shared/constants';
import { selectStore } from '@portal/app/store/app.selectors';
import { AppState } from '@portal/app/store/app.state';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class MmmExportBannerService implements OnDestroy {
  private destroy$ = new Subject<void>();

  public showBannerSignal = signal(false);
  public showV1ModalSignal = signal(true);
  public isLoadingSignal = signal(false);
  public fetchedDataSignal: WritableSignal<FormattedRow[]> = signal<
    FormattedRow[]
  >([]);

  public columnsSignal: WritableSignal<ColumnDefinition[]> = signal<
    ColumnDefinition[]
  >([]);

  public footerDataSignal: WritableSignal<FooterData | null> =
    signal<FooterData | null>(null);

  public mediaContributionSignal = signal<string | null>(null);
  public incrementalMetricNewSignal = signal<string | null>(null);
  public incrementalMetricPreviousSignal = signal<string | null>(null);

  public previousMediaContributionSignal = signal<string | null>(null);

  public showSuccessMessageSignal = signal<boolean>(false);

  // Fields and filters
  public fieldDefinitionsSignal = signal<FieldDefinitions>({});
  public filtersSignal = signal<Filter[]>([]);

  // Conversion type signals
  public conversionTypeOptionsSignal = signal<SelectItem[]>([]);
  public selectedConversionTypeSignal = signal<string>('');
  public rollupConversionTypeOptionsSignal = signal<string[]>([]);
  public isRollUpConversionTypeSelectedSignal = signal<boolean>(false);

  // Channel signals
  public channelOptionsSignal = signal<SelectItem[]>([]);
  public selectedChannelsSignal = signal<string[]>([]);

  // Tactics signals
  public tacticOptionsSignal = signal<SelectItem[]>([]);
  public selectedTacticsSignal = signal<string[]>([]);

  // Metrics signals
  public metricOptionsSignal = signal<SelectItem[]>([]);
  public selectedMetricsSignal = signal<string[]>([]);

  // Dates signals
  public dateStartSignal = signal<string>('');
  public dateStopSignal = signal<string>('');
  public selectedDatesSignal = signal<Date[]>([]);

  // View By used for ROAS/CPO metric mapping
  public viewBySignal = signal<ViewBy>(ViewBy.roas);

  // Define the metrics mapping with columnOrder
  public metricsMapping: Record<ViewBy, any> = {
    [ViewBy.roas]: {
      summarySignals: {
        mediaContribution: FieldDefinitionsEnum.newIncrementalOrdersPerc,
        previousMediaContribution: FieldDefinitionsEnum.incrementalOrdersPerc,
        incrementalMetricNew: FieldDefinitionsEnum.newIncrementalRoas,
        incrementalMetricPrevious: FieldDefinitionsEnum.incrementalRoas
      }
    },
    [ViewBy.cpo]: {
      summarySignals: {
        mediaContribution: FieldDefinitionsEnum.newIncrementalOrdersPerc,
        previousMediaContribution: FieldDefinitionsEnum.incrementalOrdersPerc,
        incrementalMetricNew: FieldDefinitionsEnum.newIncrementalCpo,
        incrementalMetricPrevious: FieldDefinitionsEnum.incrementalCpo
      }
    }
  };

  public includeTacticsSignal = signal<boolean>(false);

  constructor(
    private readonly apiService: ApiService,
    private readonly datasetAvailabilityService: DatasetAvailabilityService,
    private readonly dashboardDataControllerV2Service: DashboardDataControllerV2Service,
    private readonly configSwitchService: ConfigSwitchService,
    private readonly featureConfigService: FeatureConfigService,
    private readonly filtersService: FiltersService,
    private readonly formatterService: FormatterService,
    private readonly mmmBannerService: MmmBannerService,
    private readonly selectionService: SelectionService,
    private readonly layoutConfigService: LayoutConfigService,
    private sanitizer: DomSanitizer,
    private readonly store: Store<AppState>
  ) {
    this.store.select(selectStore).subscribe((state: AppState) => {
      this.rollupConversionTypeOptionsSignal.set(
        state.dashboard.rollupConversionTypes
      );
    });
    this.subscribeToBannerData();
    this.subscribeToAvailabilityData();
    // Subscribe to updates from FeatureConfig and Selection services
    merge(
      this.featureConfigService.data$,
      this.selectionService.selectionChanged
    )
      .pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(() => {
        this.updateBannerState();
      });
  }

  // Subscribe to availability data to automatically update conversion options
  private subscribeToAvailabilityData() {
    this.datasetAvailabilityService.data$
      .pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe({
        next: (availabilityData) => {
          this.setConversionTypeOptions(availabilityData);
        },
        error: (err) =>
          console.error('Error updating conversion type options:', err)
      });
  }

  // Call this method during initialization or whenever filters change
  public init() {
    this.getLayout();
    this.initDateRange();
    this.initConversionType();
    this.initChannels();
    this.initTactics();
  }

  public initDateRange() {
    const filters = this.filtersSignal();
    const dateFilter = this.filtersService.getFilterByLiteralId(
      filters,
      FilterIds.day
    );

    // Check if dateFilter.value is an array with two elements
    let startDate: Date;
    let endDate: Date;

    if (Array.isArray(dateFilter?.value) && dateFilter?.value?.length === 2) {
      // If dateFilter.value is an array, assume it has start and end dates
      [startDate, endDate] = dateFilter.value as [Date, Date];
    } else {
      // Default to last 18 months if no valid range is available
      startDate = dayjs().subtract(18, 'month').toDate();
      endDate = dayjs().subtract(1, 'day').toDate();
    }

    this.setDateSignals(startDate, endDate);
  }

  public setDateSignals(startDate: Date, endDate: Date) {
    this.dateStartSignal.set(dayjs(startDate).format(DATE_FILTER_FORMAT));
    this.dateStopSignal.set(dayjs(endDate).format(DATE_FILTER_FORMAT));

    this.selectedDatesSignal.set([
      dayjs(startDate).toDate(),
      dayjs(endDate).toDate()
    ]);
  }

  public updateDayFilter() {
    const updatedFilters = this.filtersSignal().map((filter) =>
      filter.literalId === FilterIds.day
        ? { ...filter, value: [this.dateStartSignal(), this.dateStopSignal()] }
        : filter
    );

    this.filtersSignal.set(updatedFilters);
  }

  private initConversionType() {
    const filters = this.filtersSignal();
    const conversionTypeFilter = this.filtersService.getFilterByLiteralId(
      filters,
      FilterIds.conversionTypeLiteralId
    );

    const selectedConversionType = conversionTypeFilter?.value
      ? String(conversionTypeFilter.value)
      : this.conversionTypeOptionsSignal()[0]?.value; // Default to empty string if no selection

    this.isRollUpConversionTypeSelectedSignal.set(
      this.rollupConversionTypeOptionsSignal().includes(selectedConversionType)
    );
    this.selectedConversionTypeSignal.set(selectedConversionType);
  }

  /**
   * Set conversion type options based on availability data
   * @param availabilityData Response from APi
   */
  private setConversionTypeOptions(availabilityData: any) {
    const conversionTypeOptions = availabilityData
      .filter(
        (item: any) =>
          item.mimExportHasData &&
          !this.rollupConversionTypeOptionsSignal().includes(
            item.conversion_type
          )
      )
      .map((item: any) => ({
        label: item.conversion_type,
        value: item.conversion_type
      }));

    this.conversionTypeOptionsSignal.set(conversionTypeOptions || []);
  }

  private initChannels() {
    const filters = this.filtersSignal();
    // Get selected channels from the filter or default to all
    const channelFilter = this.filtersService.getFilterByLiteralId(
      filters,
      FilterIds.channel
    );

    const selectedChannels =
      Array.isArray(channelFilter?.value) && channelFilter?.value?.length
        ? (channelFilter.value as string[])
        : channelFilter?.options?.map((option) => option);
    this.selectedChannelsSignal.set(selectedChannels as string[]);

    this.setChannelOptions(filters);
  }

  /**
   * Sets the channel filter options.
   * @param filters Array of filters to set.
   */
  private setChannelOptions(filters: Filter[]) {
    const channelFilter = this.filtersService.getFilterByLiteralId(
      filters,
      FilterIds.channel
    );
    const channelOptions =
      channelFilter?.options?.map((key: string) => ({
        label: key,
        value: key
      })) || [];

    this.channelOptionsSignal.set(channelOptions);
  }

  /**
   * Sets the tactic filter value and calls setTacticOptions.
   */
  private initTactics() {
    const filters = this.filtersSignal();
    const tacticFilter = this.filtersService.getFilterByLiteralId(
      filters,
      FilterIds.tactic
    );

    const selectedTactics =
      Array.isArray(tacticFilter?.value) && tacticFilter?.value?.length
        ? (tacticFilter.value as string[])
        : tacticFilter?.options?.map((option) => option); // Default to all tactics if none are selected

    this.selectedTacticsSignal.set(selectedTactics as string[]);
    this.setTacticOptions(filters);
  }

  /**
   * Sets the tactic filter options.
   * @param filters Array of filters to set.
   */
  private setTacticOptions(filters: Filter[]) {
    const tacticFilter = this.filtersService.getFilterByLiteralId(
      filters,
      FilterIds.tactic
    );
    const tacticOptions =
      tacticFilter?.options?.map((key: string) => ({
        label: key,
        value: key
      })) || [];

    this.tacticOptionsSignal.set(tacticOptions);
  }

  /**
   * Sets the metric filter options.
   * @param data Array of data from the request.
   */
  private initMetrics(data: any): void {
    const fieldDefinitions = this.fieldDefinitionsSignal();
    const dataset = data.find(
      (item: any) => item.name === 'mimComparisonDataV2WithCalc'
    );
    const viewBy = this.viewBySignal();
    const includeTactics = this.includeTacticsSignal();

    // Filter metric IDs and set selected metrics
    const metricIds = this.filterMetricIds(
      dataset?.keys || [],
      viewBy,
      includeTactics
    );
    this.selectedMetricsSignal.set(metricIds);

    // Filter metric options and set available metrics
    const metricOptions = this.filterMetricOptions(
      dataset?.keys || [],
      fieldDefinitions,
      includeTactics
    );
    this.metricOptionsSignal.set(metricOptions);
  }

  /**
   * Updates banner visibility and success message signals based on feature flags.
   *
   * - Sets `showBannerSignal` if "MimExportBanner" feature is enabled.
   * - Sets `showSuccessMessageSignal` if "V1Enabled" is disabled.
   */
  private updateBannerState() {
    const isFeatureEnabled = this.featureConfigService.isFeatureEnabled(
      FeatureConfigResponseDTO.FeatureNameEnum.MimExportBanner
    );
    this.showBannerSignal.set(isFeatureEnabled);

    const mimV2Requested = this.featureConfigService.isFeatureEnabled(
      FeatureConfigResponseDTO.FeatureNameEnum.Mimv2Requested
    );
    this.showSuccessMessageSignal.set(mimV2Requested);
  }

  /**
   * Fetches the layout configuration to determine the viewBy and sets up the metrics mapping.
   */
  getLayout() {
    const productId = ProductLiteralIds.portfolio;
    const dashboardId = DashboardLiteralIds.homePage;

    this.layoutConfigService
      .getLayoutConfig(productId, dashboardId)
      .pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(
        (data: any) => {
          const viewBy = data[0]?.viewBy as ViewBy;
          this.viewBySignal.set(viewBy);
        },
        (error: any) => {
          console.error('Error fetching layout data:', error);
        }
      );
  }

  /**
   * Sets the filters used in data fetching.
   * @param filters Array of filters to set.
   */
  setFilters(filters: Filter[]) {
    this.filtersSignal.set(filters);
  }

  /**
   * Sets the fields used in data fetching.
   * @param fields Array of fields to set.
   */
  setFields(fields: FieldDefinitions) {
    this.fieldDefinitionsSignal.set(fields);
  }

  /**
   * Sets the conversionType used in data fetching.
   * @param conversionType Array of conversionType to set.
   */
  setConversionType(conversionType: string) {
    this.selectedConversionTypeSignal.set(conversionType);
    this.isRollUpConversionTypeSelectedSignal.set(
      this.rollupConversionTypeOptionsSignal().includes(conversionType)
    );

    const updatedFilters = this.filtersSignal().map((filter) =>
      filter.literalId === FilterIds.conversionTypeLiteralId
        ? { ...filter, value: conversionType }
        : filter
    );
    this.filtersSignal.set(updatedFilters);
  }

  public prepareCommonReqParamForMIMV2Compare() {
    const selectedChannels = this.selectedChannelsSignal();
    const selectedTactics = this.selectedTacticsSignal();
    const conversionTypeFilter = this.selectedConversionTypeSignal();
    const dateStart = this.dateStartSignal();
    const dateStop = this.dateStopSignal();
    const includeTactics = this.includeTacticsSignal();
    const dimensions = includeTactics
      ? [FieldDefinitionsEnum.channel, FieldDefinitionsEnum.tactic]
      : [FieldDefinitionsEnum.channel];

    return {
      filter: {
        conversion_type: conversionTypeFilter,
        dateStart,
        dateStop,
        dataSets: ['mimComparisonDataV2WithCalc'],
        channel: selectedChannels,
        tactic: selectedTactics
      },
      dimensions
    };
  }

  /**
   * Fetches data based on field definitions, conversion type, and selected metrics.
   */
  public fetchData() {
    const fieldDefinitions = this.fieldDefinitionsSignal();
    const { filter, dimensions } = this.prepareCommonReqParamForMIMV2Compare();
    if (
      !fieldDefinitions ||
      !filter.conversion_type ||
      !filter.dateStart ||
      !filter.dateStop
    ) {
      console.error('Required filter missing');
      return;
    }

    const { clientId, brandId } = this.selectionService.getClientIdAndBrandId();

    const payload = {
      clientId,
      brandId,
      product: 'portfolio',
      dashboard: 'cross-channel',
      filter,
      dimensions
    };
    this.isLoadingSignal.set(true);
    this.dashboardDataControllerV2Service.getDataSetsPost(payload).subscribe({
      next: (data) => {
        this.initMetrics(data);
        this.formatAllData(data);
        this.isLoadingSignal.set(false);
      },
      error: (err) => {
        console.error('Error fetching data', err);
      }
    });
  }

  /**
   * Fetches filters as they change and updates filter signals.
   */
  public fetchFilters(filters: Filter[] | null = null): Observable<Filter[]> {
    return this.apiService.getFilters(DataEndpoint.dataPoints, filters || []);
  }

  /**
   * Formats all data, sets signals for data, columns, and footer.
   * @param data Data fetched from the API.

   */
  private formatAllData(data: any) {
    const fieldDefinitions = this.fieldDefinitionsSignal();
    const dataset = data.find(
      (item: any) => item.name === 'mimComparisonDataV2WithCalc'
    );
    if (!dataset || !dataset.data) {
      this.fetchedDataSignal.set([]);
      this.columnsSignal.set([]);
      this.footerDataSignal.set(null);
      return;
    }

    const values = dataset.data.map((row: any) => {
      const formattedRow: any = {};
      // PMDEV-4289: enable all metric options
      dataset?.keys?.forEach((field: string) => {
        if (this.isNewField(field)) {
          const percentDiffField = this.getPercentDiffField(field);
          const percentDiffValue = row[percentDiffField];
          formattedRow[field] = this.formatNewField(
            fieldDefinitions,
            field,
            row[field],
            percentDiffValue
          );
          formattedRow[`${field}_raw`] = row[field];
        } else {
          formattedRow[field] = this.formatField(
            fieldDefinitions,
            field,
            row[field]
          );
          formattedRow[`${field}_raw`] = row[field];
        }
      });

      return formattedRow;
    });

    const columns = this.generateColumns();
    const footer = this.generateFooter(dataset.dataPoints);

    this.fetchedDataSignal.set(values);
    this.columnsSignal.set(columns);
    this.footerDataSignal.set(footer);

    const summaryData = this.generateSummary(fieldDefinitions, data);

    this.mediaContributionSignal.set(summaryData.mediaContribution);
    this.incrementalMetricNewSignal.set(summaryData.incrementalMetricNew);
    this.incrementalMetricPreviousSignal.set(
      summaryData.incrementalMetricPrevious
    );
    this.previousMediaContributionSignal.set(
      summaryData.previousMediaContribution
    );
  }

  /**
   * Subscribes to `mmmBannerService` and updates signals.
   *
   * - Sets `showV1ModalSignal` if MIM model version is 1.
   * - Updates `conversionTypeSignal` with the conversion type.
   */
  private subscribeToBannerData() {
    this.mmmBannerService.data$
      .pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((mmmBanner) => {
        const mimModelVersion = mmmBanner?.mimModelVersion || 1;
        this.showV1ModalSignal.set(mimModelVersion === 1);
      });
  }

  /**
   * Determines if a field is a 'new' field requiring special formatting.
   * @param field Field name to check.
   * @returns True if it's a new field, false otherwise.
   */
  private isNewField(field: string): boolean {
    const newFields: string[] = [
      FieldDefinitionsEnum.newIncrementalRoas,
      FieldDefinitionsEnum.newIncrementalSalesPerc,
      FieldDefinitionsEnum.newIncrementalCpo,
      FieldDefinitionsEnum.newIncrementalOrdersPerc,
      FieldDefinitionsEnum.newAdjustmentFactor,
      FieldDefinitionsEnum.newIncrementalSales,
      FieldDefinitionsEnum.newIncrementalOrders
    ];
    return newFields.includes(field);
  }

  /**
   * Gets the corresponding percent difference field for a given new field.
   * @param field Field name to get percent difference field for.
   * @returns The percent difference field name.
   */
  private getPercentDiffField(field: string): string {
    const percentDiffFieldMapping: Record<string, string> = {
      [FieldDefinitionsEnum.newIncrementalRoas]:
        FieldDefinitionsEnum.incrementalRoasPercentDiff,
      [FieldDefinitionsEnum.newIncrementalSalesPerc]:
        FieldDefinitionsEnum.incrementalSalesPercPercentDiff,
      [FieldDefinitionsEnum.newIncrementalCpo]:
        FieldDefinitionsEnum.incrementalCpoPercentDiff,
      [FieldDefinitionsEnum.newIncrementalOrdersPerc]:
        FieldDefinitionsEnum.incrementalOrdersPercPercentDiff,
      [FieldDefinitionsEnum.newAdjustmentFactor]:
        FieldDefinitionsEnum.adjustmentFactorPercentDiff,
      [FieldDefinitionsEnum.newIncrementalSales]:
        FieldDefinitionsEnum.incrementalSalesPercentDiff,
      [FieldDefinitionsEnum.newIncrementalOrders]:
        FieldDefinitionsEnum.incrementalOrdersPercentDiff
    };
    return percentDiffFieldMapping[field] || '';
  }

  /**
   * Formats a 'new' field with percent difference and styling.
   * @param fieldDefinitions Field definitions for formatting.
   * @param fieldName Name of the field.
   * @param newValue New value to format.
   * @param percentDiffValue Optional percent difference value.
   * @returns Formatted HTML string.
   */
  private formatNewField(
    fieldDefinitions: FieldDefinitions,
    fieldName: string,
    newValue: number,
    percentDiffValue?: number
  ) {
    const fieldDef = fieldDefinitions[fieldName];
    const formattedNewValue =
      fieldDef && newValue !== null && newValue !== undefined
        ? this.formatterService.format(fieldDef, newValue, true)
        : newValue !== null && newValue !== undefined
          ? newValue
          : '-';

    if (percentDiffValue !== null && percentDiffValue !== undefined) {
      const percentDiffFieldDef =
        fieldDefinitions[this.getPercentDiffField(fieldName)];
      const formattedPercentDiff = percentDiffFieldDef
        ? this.formatterService.format(
            percentDiffFieldDef,
            percentDiffValue,
            true
          )
        : percentDiffValue;

      const isPositive = percentDiffValue > 0;

      return `
          <div>${formattedNewValue}<div class="flex ${
            isPositive ? 'text-green-700' : 'text-red-700'
          }">
              <i aria-hidden="true" class="material-symbols-outlined icon-xsmall">
                  ${isPositive ? 'arrow_upward' : 'arrow_downward'}
              </i><span class="c2">${formattedPercentDiff}</span></div>
          </div>`;
    } else {
      return `<div>${formattedNewValue}</div>`;
    }
  }

  /**
   * Formats a field using the formatter service.
   * @param fieldDefinitions Field definitions for formatting.
   * @param fieldName Name of the field.
   * @param value Value to format.
   * @returns Formatted value as a string.
   */
  private formatField(
    fieldDefinitions: FieldDefinitions,
    fieldName: string,
    value: any
  ) {
    const fieldDef = fieldDefinitions[fieldName];
    return fieldDef && value !== null && value !== undefined
      ? this.formatterService.format(fieldDef, value)
      : value !== null && value !== undefined
        ? value
        : '-';
  }

  /**
   * Generates columns for the table based on field definitions and fields list.
   * @returns Array of column definitions.
   */

  public generateColumns() {
    const fieldDefinitions = this.fieldDefinitionsSignal();
    const selectedMetrics = this.selectedMetricsSignal();
    return selectedMetrics.map((key) => {
      let label = fieldDefinitions[key]?.label || key;
      let customClass = '';

      if (label.includes('New')) {
        label = label.replace(
          /New/g,
          '<span class="text-orange-300">New</span>'
        );

        customClass = '!bg-gray-100';
      }

      // Sanitize the label
      const sanitizedLabel: SafeHtml =
        this.sanitizer.bypassSecurityTrustHtml(label);

      return {
        header: sanitizedLabel,
        field: key,
        sortField: `${key}_raw`,
        class: customClass,
        sortable: true
      };
    });
  }

  /**
   * Formats fields from data for the footer or summary.
   * @param fieldDefinitions Field definitions for formatting.
   * @param data Data object containing values.
   * @param fields List of fields to format.
   * @returns Object with formatted field values.
   */
  private formatFieldsFromData(
    fieldDefinitions: FieldDefinitions,
    data: any,
    fields: string[]
  ): any {
    const formattedData: any = {};

    fields.forEach((field: string) => {
      formattedData[field] = this.formatField(
        fieldDefinitions,
        field,
        data[field]
      );
    });

    return formattedData;
  }

  /**
   * Generates footer data based on total data points.
   * @param dataPoints Data points containing total data.
   * @returns Formatted footer data object.
   */
  public generateFooter(dataPoints: any) {
    const fieldDefinitions = this.fieldDefinitionsSignal();
    const totalData = dataPoints?.totalData;
    if (!totalData) return {};

    // Define the fields to format, excluding `tactic` and `channel`
    const fieldsToFormat = Object.keys(totalData).filter(
      (field: string) =>
        field !== FieldDefinitionsEnum.tactic &&
        field !== FieldDefinitionsEnum.channel
    );

    // Format only the first metric column with "Total" and leave others blank
    const formattedData = this.formatFieldsFromData(
      fieldDefinitions,
      totalData,
      fieldsToFormat
    );
    return {
      [FieldDefinitionsEnum.channel]: 'Total',
      [FieldDefinitionsEnum.tactic]: '',
      ...formattedData
    };
  }

  /**
   * Generates summary data for the banner based on total data.
   * @param fieldDefinitions Field definitions for formatting.
   * @param data Data array fetched from the API.
   * @returns Object containing summary data.
   */
  private generateSummary(fieldDefinitions: FieldDefinitions, data: any[]) {
    const viewBy = this.viewBySignal();

    if (!data || data.length === 0 || !viewBy) {
      console.warn(
        'Data points array is empty or viewBy is missing; cannot generate summary.'
      );
      return {};
    }

    const dataset = data.find(
      (item: any) => item.name === 'mimComparisonDataV2WithCalc'
    );

    const totalData = dataset?.dataPoints?.totalData;

    if (!totalData) {
      console.warn(
        'No totalData found in selected dataset for summary generation.'
      );
      return {};
    }

    const mapping = this.metricsMapping[viewBy];
    if (!mapping) {
      console.error(`No metrics mapping available for viewBy: ${viewBy}`);
      return {};
    }

    try {
      const fieldsToFormat = Object.values(mapping.summarySignals) as string[];
      const formattedData = this.formatFieldsFromData(
        fieldDefinitions,
        totalData,
        fieldsToFormat
      );

      return {
        mediaContribution:
          formattedData[mapping.summarySignals.mediaContribution],
        previousMediaContribution:
          formattedData[mapping.summarySignals.previousMediaContribution],
        incrementalMetricNew:
          formattedData[mapping.summarySignals.incrementalMetricNew],
        incrementalMetricPrevious:
          formattedData[mapping.summarySignals.incrementalMetricPrevious]
      };
    } catch (error) {
      console.error('Error generating summary:', error);
      return {};
    }
  }

  /**
   * Switches to the new model and updates the success signal.
   */
  public switchToNewModel() {
    this.configSwitchService.switchToMimV2().subscribe({
      next: () => {
        this.showSuccessMessageSignal.set(true);
      },
      error: (err) => {
        console.error('Error switching to MIM v2:', err);
      }
    });
  }

  private updateTacticFilters(filters: Filter[]): void {
    this.isLoadingSignal.set(true);

    // Define the filters to retain by their literalId
    const allowedFilters = [
      FilterIds.compare,
      FilterIds.conversionTypeLiteralId,
      FilterIds.day,
      FilterIds.compareDateLiteralId,
      FilterIds.isInitialFilterCall,
      FilterIds.layoutFeature,
      FilterIds.relativeDayLiteralId,
      FilterIds.showDatasetDrivers,
      FilterIds.showPointDrivers,
      FilterIds.channel,
      FilterIds.tactic,
      FilterIds.previousDateStartLiteralId,
      FilterIds.previousDateStopLiteralId
    ];

    // Filter out only the allowed filters by literalId
    const filteredFilters = filters.filter((filter) =>
      allowedFilters.includes(filter.literalId as FilterIds)
    );

    // Fetch updated filter options based on new channel selections
    this.fetchFilters(filteredFilters).subscribe({
      next: (fetchedFilters: Filter[]) => {
        // Extract the updated tactic filters from fetchedFilters
        const updatedTacticFilters = fetchedFilters.filter(
          (filter) => filter.literalId === FilterIds.tactic
        );

        // Override current filters with updated tactic filters
        const mergedFilters = filters.map((filter) => {
          if (filter.literalId === FilterIds.tactic) {
            return (
              updatedTacticFilters.find(
                (tacticFilter) => tacticFilter.literalId === filter.literalId
              ) || filter
            );
          }
          return filter;
        });

        // Update filters signal with merged filters
        this.setFilters(mergedFilters);

        // Then re-init tactics
        this.initTactics();

        // Finally fetch data
        this.fetchData();
      },
      error: (error: any) => {
        console.error('Error fetching filters:', error);
      }
    });
  }

  // Method to update channel selection and reflect it in filters
  public updateSelectedChannels(selectedChannels: string[]) {
    this.selectedChannelsSignal.set(selectedChannels);
    const updatedFilters = this.filtersSignal().map((filter) =>
      filter.literalId === FilterIds.channel
        ? { ...filter, value: selectedChannels }
        : filter
    );

    this.filtersSignal.set(updatedFilters);
    this.updateTacticFilters(updatedFilters);
  }

  // Method to update tactic selection and reflect it in filters
  public updateSelectedTactics(selectedTactics: string[]) {
    this.selectedTacticsSignal.set(selectedTactics);

    const updatedFilters = this.filtersSignal().map((filter) =>
      filter.literalId === FilterIds.tactic
        ? { ...filter, value: selectedTactics }
        : filter
    );
    this.filtersSignal.set(updatedFilters);
    this.fetchData();
  }

  /**
   * Filters metric IDs based on viewBy and tactic/channel configurations.
   * @param keys Array of metric keys.
   * @param viewBy Current viewBy setting.
   * @param includeTactics Whether to include tactic-related metrics.
   * @returns Array of filtered metric IDs.
   */
  private filterMetricIds(
    keys: string[],
    viewBy: ViewBy,
    includeTactics: boolean
  ): string[] {
    return keys.filter(
      (literalId) =>
        (viewBy === ViewBy.roas
          ? !literalId.toLowerCase().includes('orders') &&
            !literalId.toLowerCase().includes('cpo')
          : !literalId.toLowerCase().includes('sales') &&
            !literalId.toLowerCase().includes('roas')) &&
        (includeTactics || !literalId.toLowerCase().includes('tactic')) &&
        !literalId.toLowerCase().includes('diff')
    );
  }

  /**
   * Filters metric options to exclude "diff" metrics and apply tactic filters.
   * @param keys Array of metric keys.
   * @param fieldDefinitions Object containing field definitions with labels.
   * @param includeTactics Whether to include tactic-related metrics.
   * @returns Array of metric option objects with labels and values.
   */
  private filterMetricOptions(
    keys: string[],
    fieldDefinitions: Record<string, { label: string }>,
    includeTactics: boolean
  ): SelectItem[] {
    return keys
      .filter(
        (literalId) =>
          !literalId.toLowerCase().includes('diff') &&
          (includeTactics || !literalId.toLowerCase().includes('tactic'))
      )
      .map((literalId) => ({
        label: fieldDefinitions[literalId]?.label || '',
        value: literalId
      }));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
