import { Injectable } from '@angular/core';
import {
  ICampaignFilterCriteria,
  IFilterCriteria,
  IVCampaign
} from '@libs/apis';
import { liveQuery } from 'dexie';
import { isNil, omitBy } from 'lodash-es';
import { from } from 'rxjs';
import { CampaignFiltersHelper } from '../helper/campaign-filters-helper';
import { AppDB } from './taxonomy-db';

@Injectable({
  providedIn: 'root'
})
export class TableDataService {
  private readonly taxonomyStores: Record<string, AppDB> = {};

  createStore(storeName: string) {
    if (!this.taxonomyStores[storeName]) {
      this.taxonomyStores[storeName] = new AppDB(storeName);
    }

    return this.taxonomyStores[storeName];
  }

  public getStore(storeName: string): AppDB {
    return this.taxonomyStores[storeName] as AppDB;
  }

  async populateDataStore(storeName: string, campaigns: IVCampaign[]) {
    await this.getStore(storeName).populate(campaigns);
  }

  getCampaigns(storeName: string) {
    return from(
      liveQuery(() =>
        this.getStore(storeName)
          .campaigns.where({ dimensionKey: storeName })
          .toArray()
      )
    );
  }

  async getCampaignsWithFilter(
    storeName: string,
    filterCriteria: Partial<
      ICampaignFilterCriteria & {
        selected: boolean;
        pageNumber?: number;
        sortBy?: string;
        sortOrder?: string;
      }
    >
  ) {
    const { pageNumber, sortBy, sortOrder, ...filter } = omitBy(
      filterCriteria,
      isNil
    );
    const filterHelper = new CampaignFiltersHelper(
      (filter['filterCriteria'] as IFilterCriteria[]) || []
    );
    const store = this.getStore(storeName);
    const fetchQuery = store.campaigns
      .where({
        dimensionKey: storeName
      })
      .filter((campaign) => filterHelper.matches(campaign))
      .offset(((pageNumber as number) || 0) * store.pageSize)
      .limit(store.pageSize);
    let campaigns;
    if (sortBy) {
      campaigns = await (sortOrder === 'desc'
        ? fetchQuery.reverse().sortBy(sortBy as string)
        : fetchQuery.sortBy(sortBy as string));
    } else {
      campaigns = await fetchQuery.toArray();
    }
    return {
      storeName,
      campaigns
    };
  }

  getSelectedCampaignsWithFilter(
    storeName: string,
    filter: Partial<ICampaignFilterCriteria & { selected: boolean }>
  ) {
    const filterHelper = new CampaignFiltersHelper(
      (filter.filterCriteria as IFilterCriteria[]) || [],
      undefined,
      filter.selected
    );
    const store = this.getStore(storeName);
    return store.campaigns
      .where({
        dimensionKey: storeName
      })
      .filter((campaign) => filterHelper.matches(campaign))
      .sortBy('campaign');
  }

  getCampaignsCountWithFilter(
    storeName: string,
    filterCriteria: Partial<ICampaignFilterCriteria>
  ) {
    const filterHelper = new CampaignFiltersHelper(
      (filterCriteria.filterCriteria as IFilterCriteria[]) || []
    );
    const store = this.getStore(storeName);
    return store.campaigns
      .where({
        dimensionKey: storeName
      })
      .filter((campaign) => filterHelper.matches(campaign))
      .count();
  }

  selectAll(storeName: string) {
    if (!this.tableExists(storeName)) {
      return Promise.resolve(0);
    }

    return this.getStore(storeName).selectAll();
  }

  selectCampaignsWithFilter(
    storeName: string,
    filterCriteria: IFilterCriteria[]
  ) {
    const filterHelper = new CampaignFiltersHelper(filterCriteria || []);
    const store = this.getStore(storeName);
    return store.campaigns
      .where({
        dimensionKey: storeName
      })
      .filter((campaign) => filterHelper.matches(campaign))
      .modify({ isSelected: true });
  }

  unselectAll(storeName: string) {
    if (!this.tableExists(storeName)) {
      return Promise.resolve(0);
    }

    return this.getStore(storeName).unselectAll();
  }

  selectCampaign(storeName: string, tempRecordId: string) {
    if (!this.tableExists(storeName)) {
      return Promise.resolve(0);
    }

    return this.getStore(storeName).selectCampaign(tempRecordId);
  }

  unselectCampaign(storeName: string, tempRecordId: string) {
    if (!this.tableExists(storeName)) {
      return Promise.resolve(0);
    }

    return this.getStore(storeName).unselectCampaign(tempRecordId);
  }

  async reset(includePattern?: string) {
    await indexedDB.databases().then((dbs) => {
      return dbs
        .filter(
          (db) =>
            db.name &&
            db.name.includes('taxonomy_data') &&
            (!includePattern || db.name.includes(includePattern))
        )
        .map((db) => {
          if (db.name) {
            indexedDB.deleteDatabase(db.name);
          }
          return Promise.resolve();
        });
    });
  }

  public tableExists(storeName: string) {
    return !!this.getStore(storeName)?.campaigns;
  }

  public async hasData(storeName: string): Promise<boolean> {
    return Promise.resolve(
      this.tableExists(storeName) && !this.getStore(storeName).isEmpty()
    );
  }
}
