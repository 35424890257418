<div
  class="grid rounded-1 p-3 bg-white border border-gray-200"
  [ngClass]="{ two: column() === columnType.two }">
  @for (driver of drivers(); track driver; let even = $even) {
    <div class="flex justify-between driver-col" [ngClass]="{ divider: even }">
      <div class="b1 flex-1 text-gray-800">{{ driver.label }}</div>
      <div class="flex items-center">
        <m-tag
          class="h-4"
          [isPositive]="driver.isChangePositive"
          [isUp]="driver.arrowDirection !== 'DOWN'"
          [isNeutral]="false"
          [showBackground]="false">
          {{ "" }}
        </m-tag>
        <div class="b1 mx-2 w-11">
          <m-count-up
            [ngClass]="{
              'text-red-600': !driver.isChangePositive,
              'text-green-700': driver.isChangePositive,
            }"
            start="0"
            [end]="driver.formattedValue"
            [duration]="1"></m-count-up>
        </div>
      </div>
    </div>
  }
</div>
