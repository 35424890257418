import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  setPageUUID() {
    localStorage.setItem(`PAGE_UUID`, crypto.randomUUID());
  }

  getPageUUID(): string {
    const pageUUID = localStorage.getItem(`PAGE_UUID`) || '';
    return pageUUID;
  }

  clearPageUUID() {
    localStorage.removeItem(`PAGE_UUID`);
  }
}
