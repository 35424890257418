<div
  class="flex flex-column flex-wrap justify-center content-center items-center w-full h-[150px]">
  <img
    alt="Not Found"
    style="-webkit-user-drag: none"
    class="mb-2"
    src="assets/images/home-page/not-found.svg"
    width="46px" />
  <div class="b2 text-gray-600">Nothing to show here</div>
</div>
