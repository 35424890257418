import { Component, Input } from '@angular/core';

@Component({
  selector: 'portal-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent {
  @Input() showMessage = false;
  @Input() message = 'No available data.';
  @Input() containerClass = '';
}
