import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { IFormConfig, prepareForm } from '@libs/common-services';

export class TaxonomyUiFactory {
  private static readonly filterCriteriaWithValues: IFormConfig[] = [
    { field: 'campaign', defaultValue: 'contains' },
    { field: 'campaignFilterValue', updateOn: 'change' },
    { field: 'adsetName', defaultValue: 'contains' },
    { field: 'adsetNameFilterValue', updateOn: 'change' },
    { field: 'accountName', defaultValue: 'contains' },
    { field: 'accountNameFilterValue', updateOn: 'change' },
    { field: 'channel', defaultValue: 'contains' },
    { field: 'channelFilterValue', updateOn: 'change' },
    { field: 'tactic', defaultValue: 'contains' },
    { field: 'tacticFilterValue', updateOn: 'change' },
    { field: 'modifiedBy', defaultValue: 'contains' },
    { field: 'modifiedByFilterValue', updateOn: 'change' },
    { field: 'segment', defaultValue: 'contains' },
    { field: 'segmentFilterValue', updateOn: 'change' },
    { field: 'operationalChannel', defaultValue: 'contains' },
    { field: 'operationalChannelFilterValue', updateOn: 'change' },
    { field: 'operationalTactic', defaultValue: 'contains' },
    { field: 'operationalTacticFilterValue', updateOn: 'change' },
    { field: 'operationalSegment', defaultValue: 'contains' },
    { field: 'operationalSegmentFilterValue', updateOn: 'change' }
  ];

  private static readonly newTactic: IFormConfig[] = [
    {
      field: 'newTactic',
      validators: [Validators.required],
      updateOn: 'change'
    }
  ];

  private static readonly newChannel: IFormConfig[] = [
    {
      field: 'newChannel',
      validators: [Validators.required],
      updateOn: 'change'
    }
  ];

  private static readonly advanceFilterCriteriaWithValues: IFormConfig[] = [
    { field: 'campaign' },
    { field: 'adsetName' },
    { field: 'accountName' },
    { field: 'tactic' },
    { field: 'modifiedBy' },
    { field: 'segment' },
    { field: 'channel' },
    { field: 'operationalChannel' },
    { field: 'operationalTactic' },
    { field: 'operationalSegment' }
  ];

  constructor(private readonly fb: UntypedFormBuilder) {}

  public getFilterCriteriaForm(filterCriteria = {}) {
    const form = this.fb.group({});
    prepareForm(
      form,
      TaxonomyUiFactory.filterCriteriaWithValues,
      filterCriteria
    );
    return form;
  }

  public getNewTacticForm(newTactic = {}) {
    const form = this.fb.group({});
    prepareForm(form, TaxonomyUiFactory.newTactic, newTactic);
    return form;
  }

  public getNewChannelcForm(newChannel = {}) {
    const form = this.fb.group({});
    prepareForm(form, TaxonomyUiFactory.newChannel, newChannel);
    return form;
  }

  public getAdvanceFilterForm() {
    const form = this.fb.group({});
    TaxonomyUiFactory.advanceFilterCriteriaWithValues.forEach((config) => {
      form.setControl(
        config.field,
        new UntypedFormArray([
          new UntypedFormGroup({
            [config.field]: new UntypedFormControl('', { updateOn: 'change' }),
            [`is${config.field}Negative`]: new UntypedFormControl(false, {
              updateOn: 'change'
            })
          })
        ])
      );
    });
    return form;
  }
}
