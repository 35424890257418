import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscriber } from 'rxjs';
import {
  AssociatedFilterValues,
  AssociatedFilterValue,
  DataRowFilter,
  Filter
} from '@portal/app/shared/types';
import { isEqual } from 'lodash-es';
import { Store } from '@ngrx/store';
import { AppState } from '@portal/app/store/app.state';
import { MultiViewActions } from '@portal/app/store/multi-view/actions';

@Injectable({
  providedIn: 'root'
})
export class ContextStore {
  private associatedFilterHolder: AssociatedFilterValues = {};

  private contextFilters: BehaviorSubject<Filter[]> = new BehaviorSubject<
    Filter[]
  >([]);

  private readonly contextAssociatedFilters: Subject<AssociatedFilterValues> =
    new Subject<AssociatedFilterValues>();

  private readonly componentFilters: BehaviorSubject<DataRowFilter[]> =
    new BehaviorSubject<DataRowFilter[]>([]);

  private componentListenName: string[] = [];

  constructor(private store: Store<AppState>) {}

  get associatedFilterContext(): Observable<AssociatedFilterValues> {
    return this.contextAssociatedFilters.asObservable();
  }

  get associatedFilterSelection(): AssociatedFilterValues {
    return this.associatedFilterHolder;
  }

  set associatedFilterSelection(filterHolder: AssociatedFilterValues) {
    this.associatedFilterHolder = filterHolder;
  }

  get filterContext(): Observable<Filter[]> {
    return this.contextFilters.asObservable();
  }

  changeFilterContext(filters: Filter[]): Observable<Filter[]> {
    const observable = new Observable((obs: Subscriber<Filter[]>) => {
      obs.next(filters);
    });
    observable.subscribe({
      next: (f) => this.contextFilters.next(f)
    });
    return observable;
  }

  changeAssociatedFilterValues(
    key: string,
    filters: AssociatedFilterValue,
    showChild?: boolean
  ): Observable<AssociatedFilterValues> {
    if (this.associatedFilterHolder[key]) {
      this.associatedFilterHolder[key] = (
        this.associatedFilterHolder[key] as AssociatedFilterValue[]
      ).filter((filter: AssociatedFilterValue) =>
        this.filterAssociatedFilterHolder(filter, filters, showChild)
      );
      (this.associatedFilterHolder[key] as AssociatedFilterValue[]).push(
        filters
      );
    } else {
      this.associatedFilterHolder[key] = [];
      (this.associatedFilterHolder[key] as AssociatedFilterValue[]).push(
        filters
      );
    }
    const observable = new Observable(
      (subscriber: Subscriber<AssociatedFilterValues>) => {
        subscriber.next(this.associatedFilterHolder);
        subscriber.complete();
      }
    );

    observable.subscribe({
      next: () => {
        this.contextAssociatedFilters.next(this.associatedFilterHolder);
      }
    });

    return observable;
  }

  filterAssociatedFilterHolder(
    filter: AssociatedFilterValue,
    filters: AssociatedFilterValue,
    showChild?: boolean
  ): boolean {
    if (showChild) {
      return (
        filter.conversion_type !== filters.conversion_type ||
        filter.channel !== filters.channel ||
        filter.tactic !== filters.tactic ||
        filter.campaign !== filters.campaign
      );
    } else {
      return (
        filter.conversion_type !== filters.conversion_type ||
        filter.tactic !== filters.tactic ||
        filter.channel !== filters.channel
      );
    }
  }

  removeAssociatedFilterValues(
    key: string,
    filter: AssociatedFilterValue
  ): Observable<AssociatedFilterValues> {
    if (this.associatedFilterHolder[key]) {
      const associatedFilterArray = this.associatedFilterHolder[key];
      let filterFoundAtIndex: number | undefined;
      associatedFilterArray?.forEach((each, index) => {
        if (isEqual(each, filter)) {
          filterFoundAtIndex = index;
        }
      });
      if (typeof filterFoundAtIndex === 'number') {
        (this.associatedFilterHolder[key] as AssociatedFilterValue[]).splice(
          filterFoundAtIndex,
          1
        );
      }
    }
    const observable = new Observable(
      (subscriber: Subscriber<AssociatedFilterValues>) => {
        subscriber.next(this.associatedFilterHolder);
        subscriber.complete();
      }
    );

    observable.subscribe({
      next: () => {
        this.contextAssociatedFilters.next(this.associatedFilterHolder);
      }
    });

    return observable;
  }

  clearAssocFilterOnPageLoad(
    navigateToParent: boolean
  ): Observable<AssociatedFilterValues> {
    this.store.dispatch(
      MultiViewActions.setAssociatedFilterHolder({
        associatedFilterHolder: this.associatedFilterHolder
      })
    );
    if (!navigateToParent) {
      Object.entries(this.associatedFilterHolder).forEach(([key]) => {
        delete this.associatedFilterHolder[key];
      });
    }

    const observable = new Observable(
      (subscriber: Subscriber<AssociatedFilterValues>) => {
        subscriber.next(this.associatedFilterHolder);
        subscriber.complete();
      }
    );

    observable.subscribe({
      next: () => {
        this.contextAssociatedFilters.next(this.associatedFilterHolder);
      }
    });

    return observable;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get componentFilter(): Observable<DataRowFilter[]> {
    return this.componentFilters.asObservable();
  }

  emitComponentChangeAsFilter(
    data: DataRowFilter[]
  ): Observable<DataRowFilter[]> {
    const observable = new Observable(
      (subscriber: Subscriber<DataRowFilter[]>) => {
        subscriber.next(data);
        subscriber.complete();
      }
    );

    observable.subscribe({
      next: () => {
        this.componentFilters.next(data);
      }
    });

    return observable;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get listeningComponentName(): string[] {
    return this.componentListenName;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  set listeningComponentName(componentName: string[]) {
    this.componentListenName = componentName;
  }
}
