<div class="w-full h-full position-relative z-20">
  <div
    class="flex flex-col gap-4 border border-solid border-gray-400 rounded-1 me-0 p-4"
    [ngClass]="{
      'mapped-tactic-dialog': selectedCampaignType !== 'unmapped',
    }"
    [class]="
      totalCampaigns > 50
        ? 'assign-tactic-dialog'
        : 'assign-tactic-dialog-for-less-records'
    ">
    <div
      class="flex items-start justify-between"
      *ngIf="selectedCampaignType === 'unmapped'; else mappedCampaigns">
      <span class="text-[1.25rem] leading-6 text-gray-1000 font-regular">
        Map {{ selectedCampaignCount }} Selected
        {{ selectedCampaignCount > 1 ? "Campaigns" : "Campaign" }}
      </span>
      <span
        class="material-symbols-outlined icon-small text-blue-600 cursor-pointer"
        (click)="abortTacticUpdate()"
        >close</span
      >
    </div>
    <ng-template #mappedCampaigns>
      <div class="d-flex">
        <div class="d-inline-block">
          You've selected
          <span class="fw-bold">{{ selectedCampaignCount }}</span>
          {{ selectedCampaignCount > 1 ? "campaigns" : "campaign" }}
          mapped to
          <span class="fw-bold">{{
            selectedDimensionOptions["tactic"].length
          }}</span>
          {{
            selectedDimensionOptions["tactic"].length > 1
              ? "tactics,"
              : "tactic,"
          }}
          <span class="fw-bold">{{
            selectedDimensionOptions["channel"].length
          }}</span>
          {{
            selectedDimensionOptions["channel"].length > 1
              ? "channels,"
              : "channel,"
          }}
          and
          <span class="fw-bold">{{
            selectedDimensionOptions["segment"].length
          }}</span>
          {{
            selectedDimensionOptions["segment"].length > 1
              ? "segments"
              : "segment"
          }}
        </div>
        <div class="d-inline-block">
          <span
            (click)="abortTacticUpdate()"
            class="material-symbols-outlined icon-small text-blue-600 cursor-pointer"
            >close</span
          >
        </div>
      </div>
    </ng-template>

    <div class="flex flex-col gap-2">
      <label for="channels">Channel</label>
      <m-dropdown
        *ngIf="(loading | async) === false"
        id="channels"
        [group]="true"
        optionLabel="channel"
        optionValue="channel"
        placeholder="Select"
        [filter]="true"
        [showClear]="true"
        filterBy="channel"
        filterPlaceholder="Search"
        [options]="channels"
        [(ngModel)]="updateDimensionValue['channel']"
        styleClass="w-full"
        panelStyleClass=" "
        (onClear)="updateDimensionValue['channel'] = ''"
        (formControlChange)="setValue(campaignDimension.channel, $event)"
        width="100%">
        <ng-template let-group pTemplate="group">
          <span *ngIf="group.label" class="tactic-group-header">
            {{ group.label }}
          </span>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <span class="w-100" [ngSwitch]="item.type">
            <hr
              *ngSwitchCase="'divider'"
              class="mt-0"
              [style]="{
                padding: 'unset',
                margin: 'unset',
                background: 'none',
              }" />
            <span *ngSwitchCase="'button'" class="text-blue">{{
              item.channel
            }}</span>
            <span *ngSwitchCase="''">{{ item.label }}</span>
            <span *ngSwitchDefault>
              {{ item.channel }}
            </span>
          </span>
        </ng-template>
      </m-dropdown>
      <m-skeleton
        *ngIf="loading | async"
        height="2rem"
        width="100%"></m-skeleton>
    </div>
    <div class="flex flex-col gap-2">
      <label for="segments">Segment</label>
      <m-dropdown
        *ngIf="(loading | async) === false"
        id="segments"
        [group]="true"
        optionLabel="segment"
        optionValue="segment"
        placeholder="Select"
        [filter]="true"
        filterBy="segment"
        filterPlaceholder="Search"
        [options]="segments"
        [(ngModel)]="updateDimensionValue['segment']"
        panelStyleClass=" "
        styleClass="w-full"
        [showClear]="true"
        (onClear)="updateDimensionValue['segment'] = ''"
        (formControlChange)="setValue(campaignDimension.segment, $event)">
        <ng-template let-group pTemplate="group">
          <span *ngIf="group.label" class="tactic-group-header">
            {{ group.label }}
          </span>
        </ng-template>
      </m-dropdown>
      <m-skeleton
        *ngIf="loading | async"
        height="2rem"
        width="100%"></m-skeleton>
    </div>
    <div class="flex flex-col gap-2">
      <label for="tactics">Tactic</label>
      <m-dropdown
        *ngIf="(loading | async) === false"
        id="tactics"
        class="w-full"
        [group]="true"
        optionLabel="tactic"
        optionValue="tactic"
        placeholder="Select"
        [filter]="true"
        filterBy="tactic"
        filterPlaceholder="Search"
        [options]="tactics"
        [(ngModel)]="updateDimensionValue['tactic']"
        styleClass="w-full"
        panelStyleClass=" "
        (onChange)="handleGeoTestMessageVisibility()"
        [showClear]="true"
        (onClear)="updateDimensionValue['tactic'] = ''"
        (formControlChange)="setValue(campaignDimension.tactic, $event)">
        <ng-template let-group pTemplate="group">
          <span *ngIf="group.label" class="tactic-group-header">
            {{ group.label }}
          </span>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <span class="w-full" [ngSwitch]="item.type">
            <hr
              *ngSwitchCase="'divider'"
              class="mt-0"
              [style]="{
                padding: 'unset',
                margin: 'unset',
                background: 'none',
              }" />
            <span *ngSwitchCase="'button'" class="text-blue">{{
              item.tactic
            }}</span>
            <span *ngSwitchCase="''">{{ item.label }}</span>
            <span *ngSwitchDefault>
              {{ item.tactic }}
              {{
                geoTestStatusByDimension[item.tactic]
                  ? " (Geo test in progress)"
                  : ""
              }}
            </span>
          </span>
        </ng-template>
      </m-dropdown>
      <m-skeleton
        *ngIf="loading | async"
        height="2rem"
        width="100%"></m-skeleton>
    </div>
    <div
      *ngIf="isGeoTestRunning"
      class="flex w-full items-center justify-start gap-2">
      <span
        class="flex material-symbols-outlined icon-small text-blue-600 cursor-default"
        >info</span
      >
      <span class="b1 text-gray-800">Impacts Geo testing</span>
      <a
        class="saas-text-link"
        href="https://support.measured.com/article/wxdipxvthy-geo-test-overview"
        >Learn more about Geo testing</a
      >
    </div>
    <div
      class="flex w-full items-center justify-end mt-4"
      *ngIf="selectedCampaignType !== 'unmapped'">
      <button
        pButton
        label="Unmap"
        pTooltip="Unmap selected campaign(s) and use automated rules for campaign mapping"
        tooltipPosition="top"
        tooltipStyleClass="saas-tooltip"
        class="p-button-outlined d-inline-block me-3"
        (click)="confirmToResetMapping()"></button>
      <button
        pButton
        [disabled]="
          !(
            updateDimensionValue['tactic'] ||
            updateDimensionValue['segment'] ||
            updateDimensionValue['channel']
          )
        "
        label="Apply mapping"
        class="p-button-outlined d-inline-block"
        (click)="updateDimension()"></button>
    </div>
    <div
      class="flex w-full items-center justify-end mt-4"
      *ngIf="selectedCampaignType === 'unmapped'">
      <m-button
        [disabled]="
          !updateDimensionValue['tactic'].length &&
          !updateDimensionValue['segment'].length &&
          !updateDimensionValue['channel'].length
        "
        type="secondary"
        (click)="updateDimension()">
        Add for Review
      </m-button>
    </div>
  </div>
</div>
