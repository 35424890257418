<div
  *ngIf="title || filterControls.length > 0"
  class="d-flex pb-1 mb-2 justify-content-between align-items-end titleBarAppendTo"
  [ngClass]="{
    'pb-3': nativeSectionsService.getClassByLiteralId(literalId) !== 'result',
  }">
  <div *ngIf="title" class="title-bar mb-auto">
    {{ title }}
    <ng-container *ngIf="contextTitle">
      | <span class="context-title">{{ contextTitle }}</span>
    </ng-container>
  </div>
  <div *ngFor="let displayControl of displayControls" class="ms-auto">
    <label class="control-label fw-boldish"
      >{{ displayControl.label }}
      <input
        *ngIf="displayControl.layoutType === 'INPUT_BOX'"
        type="text"
        pInputText
        [value]="
          chartDataPoints[displayControl.dashboardFieldId]
            | formatValue: fieldDefinitions[displayControl.dashboardFieldId]
        "
        [disabled]="displayControl?.config?.disable"
        class="d-block {{ displayControl.label }}"
    /></label>
  </div>
  <div *ngFor="let control of filterControls" class="ms-auto">
    <label class="control-label"
      >{{ control.label }}
      <p-dropdown
        id="filterControl"
        [options]="control.options"
        [(ngModel)]="control.value"
        (onHide)="filterChange(control)"
        [appendTo]="titleBarAppendTo"
        panelStyleClass="{{ literalId }}"
        class="d-block"></p-dropdown>
    </label>
  </div>
  <div *ngIf="chartTableToggle !== null">
    <label
      for="chartTableToggleSwitch"
      class="control-label fw-boldish pt-3 px-2"
      >Table
      <p-inputSwitch
        id="chartTableToggleSwitch"
        [(ngModel)]="chartTableToggle"
        (onChange)="toggleChange()"
        class="d-block result-slider"></p-inputSwitch>
    </label>
  </div>
  <div *ngIf="showExportIcon">
    <button
      id="exportIcon"
      pButton
      class="p-button-text p-button-icon-only"
      pTooltip="Export"
      tooltipPosition="bottom"
      tooltipStyleClass="saas-tooltip"
      showDelay="800"
      (click)="exportDataHandler($event)">
      <span class="material-symbols-outlined"> ios_share </span>
    </button>
    <p-menu
      styleClass="saas-menu-overlay"
      #exportMenuHtmlElement
      [popup]="true"
      [model]="exportMenu"
      appendTo="body"></p-menu>
  </div>
</div>
