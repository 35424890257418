import { createReducer, on } from '@ngrx/store';
import { MultiViewActions } from '@portal/app/store/multi-view/actions';
import { MultiViewState } from '@portal/app/store/app.state';
import { cloneDeep } from 'lodash-es';

export const initialState: MultiViewState = {
  showChild: false,
  queryParams: {},
  filters: [],
  associatedFilterHolder: {}
};

export const multiViewReducer = createReducer(
  initialState,
  on(MultiViewActions.navigateToChild, (state, { filters }) => ({
    ...state,
    showChild: true,
    filters: cloneDeep(filters)
  })),
  on(MultiViewActions.navigateToParent, (state) => ({
    ...state,
    showChild: false
  })),
  on(MultiViewActions.setQueryParams, (state, { queryParams }) => ({
    ...state,
    queryParams
  })),
  on(
    MultiViewActions.setAssociatedFilterHolder,
    (state, { associatedFilterHolder }) => ({
      ...state,
      associatedFilterHolder: cloneDeep(associatedFilterHolder)
    })
  )
);
