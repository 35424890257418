import { ElementType, FilterSetElementType } from './element-type';
import { HeroElement, IFormat } from './element';
import { DateRange, Format } from './format';
import { DataResponse } from './response';
import { DataGridEventDriven, DataGridSortedColumn } from './datagrid';
import { BulkModeDropdownOption } from '@portal/app/dashboard/date-range-picker/date-range-picker.component';
import { BulkSelectionMode } from '@libs/date-range-picker';

type Metrics = Record<number, Column>;

interface Column {
  label: string;
  format: Format;
}

enum AggFunction {
  none = 'none',
  sum = 'sum',
  average = 'average',
  custom = 'custom'
}

interface ChartConfig {
  columns: number[];
  series: number[];
  xAxisSeries: number[];
}
type Type = 'string' | 'double' | 'number' | 'datetime' | null;

interface FieldDefinition {
  id: number;
  dashboardId: number;
  literalId: string;
  label: string;
  subText?: string | null;
  format: Format;
  type: Type;
  digitsInfo: string | null;
  aggregateFunction: 'none' | 'sum' | 'average' | 'custom' | null;
  defaultValue?: string;
  dashboardFieldId?: number;
  clientId?: number;
  brandId?: number;
  userId?: number;
  status?: 'ACTIVE' | 'INACTIVE';
  overrideLevel?: number;
}

interface FilterOption {
  label: string;
  value?: string;
  disabled?: string;
  perOptionCount?: number;
}

interface StoredHeroMetricLayout {
  name: string;
  elements: HeroElement[];
}

interface ContextField {
  name: string;
  label: string;
  inputType: 'select' | 'multiselect' | 'dateRange' | 'number' | null;
  value: string | string[] | DateRange | null;
  position: number;
  options?: FilterOption[];
  placeholder?: string;
  disabled?: boolean;
  parent?: string;
  children?: ContextField[];
  layoutType: FilterSetElementType | ElementType;
  additionalAttributes?: Record<string, string | number>;
  bulkModeValue: BulkSelectionMode | undefined;
  bulkModeOptions: BulkModeDropdownOption[];
  bulkModeFilterLiteralId: string;
}

type FieldDefinitions = Record<string, FieldDefinition>;

type IFormats = Record<string, IFormat>;

interface HeroMetric {
  name: string;
  label: string;
  value: number | Promise<DataResponse>;
  width: number;
  field: FieldDefinition;
  type: ElementType;
  children?: HeroMetricBounded[];
  x?: number;
  y?: number;
}

interface HeroMetricBounded {
  name: string;
  field: FieldDefinition;
  value?: string;
}

enum WidgetType {
  context = 'CONTEXT_BAR',
  filter = 'FILTER_SET',
  heroMetric = 'DATA_POINT',
  chart = 'DATA_CHART',
  table = 'DATA_SET',
  filterEvent = 'FILTER_EVENTS'
}

enum DataEndpoint {
  dataPoints = 'data-points',
  dataSets = 'data-sets',
  dataCharts = 'charts',
  dataExperimentResult = 'experiment-result',
  dataSetExport = 'export-data-set',
  chartExport = 'export-chart'
}

type ViewTabs = Record<string, ViewTab[]>;

interface ViewTab {
  title: string;
  link: string;
  checked?: boolean;
}

enum StatusEnum {
  pause = 1,
  play = 2,
  delete = 3,
  schedule = 4
}

interface MessageDialogText {
  text?: string;
  class?: string;
}
type DataGridEventDrivenColumns = Record<string, DataGridEventDriven[]>;

interface PersistenceRequest {
  dashboardId: number;
  dashboardLayoutId: number;
  dashboardLayoutLiteralId: string;
  persistenceOperation: string;
  data: PersistenceData[];
}

type PersistenceData = Partial<DataGridSortedColumn>;

enum PersistenceOperations {
  dataSetColumnSort = 'DATA_SET_COLUMN_SORT'
}

enum OverrideLevel {
  client = 'CLIENT',
  brand = 'BRAND',
  user = 'USER',
  view = 'VIEW'
}

export {
  FieldDefinitions,
  FieldDefinition,
  FilterOption,
  HeroMetric,
  HeroMetricBounded,
  Metrics,
  StoredHeroMetricLayout,
  ContextField,
  Column,
  ChartConfig,
  AggFunction,
  ViewTabs,
  WidgetType,
  DataEndpoint,
  IFormats,
  Type,
  ViewTab,
  StatusEnum,
  MessageDialogText,
  DataGridEventDrivenColumns,
  PersistenceRequest,
  PersistenceOperations,
  OverrideLevel
};
