import { FieldDefinitions } from '@portal/app/shared/types';
import { MetricDTO } from '@libs/api';

export enum ContextModalType {
  unknown = 'unknown',
  channel = 'channel',
  tactic = 'tactic'
}

export type ContextModalRow = Record<string, string>;

export interface ContextMetric {
  label: string;
  value: number;
  formattedValue: string;
}

export interface ContextModalCard {
  title: string;
  titleIcon?: string;
  description?: string;
  content?: string;
  buttonText: string;
  rows?: ContextModalRow[];
  metrics?: ContextMetric[];
  icons?: ContextModalRow[];
}

export interface ContextModalParams {
  channel: string;
  tactic?: string;
  conversionType?: string;
}

/* SummaryMetric API Types */
export interface ChannelMetricsFilterDTO {
  conversion_type: string;
  dateStart: string;
  dateStop: string;
  previousDateStart: string;
  previousDateStop: string;
  channel: string;
  tactic?: string;
}

export interface SummaryMetricRequestDTO {
  clientId: number;
  brandId: number;
  dimension: 'channel' | 'tactic';
  filter: ChannelMetricsFilterDTO;
}

export interface TopMetricDTO {
  label: string;
  value: number;
  formattedValue: string;
  isChangePositive: boolean;
  percentChange: string;
  isHigher: boolean;
}

export interface DriversDTO {
  label: string;
  value: number;
  formattedValue: string;
  isChangePositive: boolean;
  arrowDirection: 'UP' | 'DOWN';
}

export interface ChannelLevelSummaryMetricResponseDTO {
  topMetric: TopMetricDTO;
  drivers: DriversDTO[];
  channelKpiViewMetrics?: string[];
}

/* GeoTests API Types */
export interface GeoTestRequestDTO {
  clientId: number;
  brandId: number;
  conversion_type: string;
  dimension: 'channel' | 'tactic';
  channel: string;
  tactic?: string;
}

export interface GeoResponseDTO {
  type: 'LIVE' | 'PossibleTestOpportunities' | 'LatestResult' | 'NoData';
  liveGeoTest: LiveGeoTestDTO;
  testOpportunities: TestOpportunitiesDTO[];
  latestTestResult: LatestTestResultDTO;
}

export interface LiveGeoTestDTO {
  spend: {
    label: string;
    value: number;
    formattedValue: string;
  };
  contribution: {
    label: string;
    value: number;
    formattedValue: string;
  };
  tactic: string;
  cellType: string;
  testType: string;
  startDate: string;
  endDate: string;
  daysLeft: number;
}

export interface TestOpportunitiesDTO {
  tactic: string;
  spend: {
    label: string;
    value: number;
    formattedValue: string;
  };

  contribution: {
    label: string;
    value: number;
    formattedValue: string;
  };
}

export interface LatestTestResultDTO {
  metric: {
    label: string;
    value: number;
    formattedValue: string;
  };
  inc: {
    label: string;
    value: number;
    formattedValue: string;
  };
  cellType: string;
  testDurationInWeeks: string;
  testEndDate: string;
}

/* Benchmark API Types */
export interface BenchmarkFilterRequestDTO {
  conversion_type: string;
  channel: string;
  tactic: string;
  dateStart: string;
  dateStop: string;
}

export interface BenchmarkRequestDTO {
  clientId: number;
  brandId: number;
  filter: BenchmarkFilterRequestDTO;
}

export interface BenchmarkResponseDTO {
  metric: {
    label: string;
    value: number;
    formattedValue: string;
  };

  industryMetric: {
    label: string;
    value: number;
    formattedValue: string;
  };
  difference: string;
  isHigher: boolean;
}

/* Metrics API Types */
export interface MetricsRequestDTO {
  clientId: number;
  brandId: number;
  dimension: 'channel' | 'tactic';
  filter: ChannelMetricsFilterDTO;
}

export interface MetricsResponseDTO {
  literalId: string;
  label: string;
  value: number;
  formattedValue: string;
  compareValue: number;
  compareFormattedValue: string;
  arrowDirection: 'UP' | 'DOWN';
  changeStatus: 'POSITIVE' | 'NEGATIVE' | 'NEUTRAL';
}

/* Top metric API Types */
export interface TopMediaByMetricFilterDTO {
  conversion_type: string;
  dateStart: string;
  dateStop: string;
  channel: string;
  tactic?: string;
  literalId: string;
}

export interface TopMediaByMetricRequestDTO {
  clientId: number;
  brandId: number;
  dimension: 'tactic' | 'campaign';
  filter: TopMediaByMetricFilterDTO;
}

export interface TopMediaByMetricResponseDTO {
  key: string;
  value: number;
  formattedValue: string;
}

/* Compare chart API Types */
export interface ChannelMetricDetailsFilterDTO {
  conversion_type: string;
  dateStart: string;
  dateStop: string;
  previousDateStart: string;
  previousDateStop: string;
  channel: string;
  tactic: string;
  literalId: string;
}

export interface MetricDetailsRequestDTO {
  clientId: number;
  brandId: number;
  dimension: string;
  filter: ChannelMetricDetailsFilterDTO;
}

export interface CompareDataByMetricResponseDTO {
  value: number;
  formattedValue: string;
  previousPeriodValue: number;
  previousPeriodFormattedValue: string;
  drivers: DriversDTO[];
}

/* Trending chart API Types */
export interface TrendingDataByMetricResponseDTO {
  data: TrendingDataByMetricDataResponseDTO[];
  fieldDefinition: FieldDefinitions;
}

export interface TrendingDataByMetricDataResponseDTO {
  date: string;
  value: number;
  formattedValue: string;
}

/* All media API Types */
export interface AllMediaFilterDTO {
  conversion_type: string;
  dateStart: string;
  dateStop: string;
  channel: string;
  tactic: string;
}

export interface AllMediaRequestDTO {
  clientId: number;
  brandId: number;
  metrics: string[];
  dimension: 'tactic' | 'campaign';
  filter: AllMediaFilterDTO;
}

export interface TacticLevelMetricsDTO {
  label: string;
  value: number;
  formattedValue: string;
}

export interface AllMediaResponseDTO {
  data: Record<string, AllMediaDataObjectDTO>[];
  fieldDefinition: FieldDefinitions;
  totalRow: Record<string, string>;
}

export interface AllMediaDataObjectDTO {
  value: string | number;
  formattedValue: string;
}

/* Tactic optimizations API Types */
export interface TacticLevelOptimizationInsightFilterDTO {
  conversion_type: string;
  dateStart: string;
  dateStop: string;
}

export interface TacticLevelOptimizationInsightRequestDTO {
  clientId: number;
  brandId: number;
  channel: string;
  tactic: string;
  filter: TacticLevelOptimizationInsightFilterDTO;
}

export interface TacticLevelOptimizationInsightResponseDTO {
  currentBudgetAllocation: number;
  recommendedBudgetAllocation: number;
  difference: string;
  isBetter: boolean;
  isTacticOptimizedCapped: boolean;
}

/* View By Brand API Types */
export enum ViewType {
  'ROAS' = 'ROAS',
  'CPO' = 'CPO'
}

export interface ViewByDTO {
  viewBy: ViewType;
}

/* Return Curve API Types */
export interface TacticReturnCurveRequestDTO {
  clientId: number;
  channel: string;
  tactic: string;
  conversionType: string;
}

export interface TacticReturnCurveDataPoint {
  year: number;
  week: number;
  mediaSpend: MetricDTO;
  ordersLT: MetricDTO;
  returnCurveRes: MetricDTO;
  platformCurveRes: MetricDTO;
}

export interface CurveParams {
  a: number;
  b: number;
  adjFactor: number;
  modelDate: string;
}

export interface TacticReturnCurveResponseDTO {
  data: TacticReturnCurveDataPoint[];
  curveParams: CurveParams;
}
