<m-toast></m-toast>
<lib-ui-loader-global
  *ngIf="globalLoading | async"
  [loaderTitle]="loaderTitle"
  [loaderMessage]="loaderMessage"
  [view]="loaderUiView"
  [closable]="loaderUiView === 'success' || loaderUiView === 'error'"
  [continueButtonLabel]="'Continue'"
  loaderStyleClass="max-w-[25rem]"
  (handleContinue)="globalLoading.next(false)"></lib-ui-loader-global>
<div class="campaign-view relative">
  <!--#region: campaign-view filter -->
  <div class="absolute top-0 right-0 filter-context-wrapper">
    <lib-taxonomy-campaign-view-filter-context
      [activeTab]="activeTaxonomyTab"
      [campaignViewColumns]="campaignViewColumns"
      (campaignFilterChange)="
        refreshCampaignView($event)
      "></lib-taxonomy-campaign-view-filter-context>
  </div>
  <!--#endregion -->

  <!--#region: campaign-view tabs -->
  <m-tab-view
    [headerSize]="headerSize"
    [tabPanelHeaders]="tabPanelHeaders"
    (activeIndexChange)="publishTabChange($event)"
    [activeIndex]="getActiveTabIndex()">
    <ng-container *ngFor="let tab of tabPanelHeaders; let index = index">
      <!--suppress JSUnusedGlobalSymbols -->
      <ng-template [mTabIndex]="index + 1" #tabContent>
        <!--#region: campaign view accordions -->
        <div *ngIf="(loading | async) === false">
          <p-accordion
            [multiple]="false"
            class="saas-ui saas-ui-size-default"
            styleClass="overflow-visible"
            [activeIndex]="activeAccordionIndex"
            (activeIndexChange)="refreshCampaignList($any($event))">
            <p-accordionTab
              *ngFor="
                let campaignDimensionInfo of campaignAccordions;
                index as i
              "
              [disabled]="requestInProgress && i !== activeAccordionIndex">
              <ng-template pTemplate="header">
                <div class="flex items-center justify-between w-full">
                  <div class="flex items-center gap-2">
                    <!--#region: accordion logo-->
                    <img
                      *ngIf="
                        filterContext.groupByDimension === dataSourceDimension
                      "
                      class="data-source-icon"
                      src="/assets/images/integrations/{{
                        $any(campaignDimensionInfo.dimensionLogoStr) | lowercase
                      }}-logo.svg"
                      (error)="handleImageError($event, campaignDimensionInfo)"
                      alt="I" />
                    <!--#endregion -->

                    <!--#region: accordion title-->
                    <span class="sh2">{{
                      campaignDimensionInfo.dimension
                    }}</span>
                    <!--#endregion -->

                    <!--#region: accordion count-->
                    <p
                      class="bg-gray-200 text-gray-800 b2 rounded-full px-3 mb-0"
                      [pTooltip]="
                        !!campaignDimensionInfo.isFilterApplied
                          ? 'Filters applied'
                          : ''
                      "
                      tooltipPosition="top"
                      tooltipStyleClass="saas-tooltip"
                      [ngClass]="{
                        'accordion-count-filtered':
                          !!campaignDimensionInfo.isFilterApplied,
                      }">
                      {{ campaignDimensionInfo.count }}
                    </p>
                    <!--#endregion -->

                    <!--#region: accordion geo test-->
                    <div
                      *ngIf="
                        geoTestStatusByDimension[
                          campaignDimensionInfo.dimension
                        ]
                      "
                      class="flex items-center gap-1">
                      <span
                        class="flex material-symbols-outlined text-blue-600 text-base font-medium lh-1"
                        pTooltip="One or more of these campaign(s)<br />will affect an ongoing geo test"
                        [escape]="false"
                        tooltipPosition="top"
                        tooltipStyleClass="saas-tooltip"
                        >info</span
                      >
                      <p class="text-gray-1000 b1 mb-0">Geo Test in Progress</p>
                    </div>
                    <!--#endregion -->
                  </div>

                  <!--#region: spend total-->
                  <div
                    *ngIf="activeTaxonomyTab === 'unmapped'"
                    class="text-gray-1000 b3">
                    <p class="mb-0">
                      Total Spend:
                      {{
                        campaignDimensionInfo.spend || 0
                          | customCurrency: "1.0-0"
                      }}
                    </p>
                  </div>
                  <!--#endregion -->
                </div>
              </ng-template>
              <ng-template pTemplate="content">
                <div
                  class="flex flex-col gap-4 w-full min-h-[20rem] overflow-visible">
                  <div class="flex w-full justify-between gap-2 flex-nowrap">
                    <!--#region: campaigns table -->
                    <p-table
                      #campaignTable
                      [autoLayout]="true"
                      [columns]="filteredCampaignViewColumns"
                      [lazyLoadOnInit]="false"
                      [lazy]="true"
                      [ngClass]="{
                        'w-full': !showUpdateMappingOverlay,
                        'w-[calc(100%-28rem)]': showUpdateMappingOverlay,
                      }"
                      [resizableColumns]="true"
                      scrollHeight="25rem"
                      [totalRecords]="campaignDimensionInfo.count"
                      [value]="campaignQueue.queue"
                      columnResizeMode="expand"
                      [tableStyle]="{ 'min-width': '100%' }"
                      tableStyleClass="p-datatable-table-lg saas-ui saas-ui-size-default taxonomy-table table-fixed">
                      <ng-template pTemplate="header">
                        <tr class="sticky top-0 z-[5]">
                          <th
                            *ngIf="activeTaxonomyTab === 'unmapped'"
                            scope="col"
                            class="campaign-checkbox">
                            <m-checkbox
                              [disabled]="
                                campaignQueue.queue.length === 0 ||
                                allCampaignsInDimensionSelected ||
                                selectionInProgress
                              "
                              [binary]="true"
                              pTooltip="{{
                                showUpdateMappingOverlay && allCampaignsSelected
                                  ? 'Unselect'
                                  : 'Select'
                              }} all {{
                                campaignDimensionInfo.count
                              }} campaigns<br/>in this table"
                              tooltipStyleClass="saas-tooltip"
                              [escape]="false"
                              tooltipPosition="top"
                              [isChecked]="pageCampaignsSelected"
                              [(ngModel)]="pageCampaignsSelected"
                              (onChange)="
                                selectAllCampaignsInDimension($any($event))
                              "></m-checkbox>
                          </th>
                          <th
                            pResizableColumn
                            scope="col"
                            *ngFor="let header of filteredCampaignViewColumns"
                            [style.width]="header.width"
                            class="{{ header.styleClass }}">
                            <lib-taxonomy-campaign-view-column-header
                              *ngIf="header.isFilterEnabled; else defaultHeader"
                              class="block w-full"
                              [header]="header.header"
                              [headerStyle]="{
                                width: '100% !important',
                              }"
                              [filterData]="getColumnFilterState(header)"
                              (searchValueChange)="
                                updateCampaignsWithFilter(header.field, $event)
                              "
                              (sortChange)="
                                handleCampaignSortChange($event)
                              "></lib-taxonomy-campaign-view-column-header>
                            <ng-template #defaultHeader>
                              {{ header.header }}
                            </ng-template>
                          </th>
                        </tr>
                      </ng-template>
                      <ng-template
                        pTemplate="body"
                        let-campaign
                        let-columns="columns"
                        let-index="rowIndex">
                        <ng-container
                          *ngIf="activeDimensionPageNumber > 0 && index === 0">
                          <tr>
                            <td
                              class="p-table-td-lg text-center"
                              [colSpan]="columns.length">
                              <m-link
                                [label]="loadPrevious"
                                link="previous"
                                linkElement="span"
                                styleClass="b2"
                                (linkClick)="
                                  loadMoreCampaignsFromIndexDB(
                                    false,
                                    campaignTable
                                  )
                                "></m-link>
                            </td>
                          </tr>
                        </ng-container>
                        <tr>
                          <td
                            *ngIf="activeTaxonomyTab === 'unmapped'"
                            class="campaign-checkbox">
                            <m-checkbox
                              [value]="campaign"
                              (onChange)="
                                updateCampaignSelection($event, campaign)
                              "
                              [binary]="true"
                              [disabled]="
                                allCampaignsInDimensionSelected ||
                                selectionInProgress
                              "
                              [(ngModel)]="campaign.isSelected"
                              [isChecked]="campaign.isSelected"></m-checkbox>
                          </td>
                          <td
                            pResizableColumn
                            *ngFor="let header of columns"
                            class="{{ header.styleClass }}"
                            [style.text-overflow]="'ellipsis'"
                            [style.overflow]="'hidden'"
                            [ngSwitch]="header.field"
                            [style.width]="header.width"
                            [style.height]="'4rem !important'">
                            <!--#region: campaign avatar -->
                            <ng-container *ngSwitchCase="'modifiedBy'">
                              <div
                                class="mx-auto"
                                [pTooltip]="campaign.modifiedByTooltip"
                                [escape]="false"
                                tooltipPosition="top"
                                tooltipStyleClass="saas-tooltip">
                                <m-avatar
                                  class="cursor-default"
                                  [avatars]="[
                                    campaign.avatarConfig,
                                  ]"></m-avatar>
                              </div>
                            </ng-container>
                            <!--#endregion -->

                            <!--#region: campaign actions -->
                            <ng-container *ngSwitchCase="'actions'">
                              <m-button
                                type="tertiary"
                                class="p-0"
                                styleClass="p-0"
                                (click)="
                                  mappedCampaignMenu.toggle($event);
                                  setUnmapCampaignAction(campaign)
                                ">
                                <span
                                  class="flex material-symbols-outlined link text-blue-600 icon-small cursor-pointer"
                                  pTooltip="Modify campaign"
                                  tooltipPosition="top"
                                  tooltipStyleClass="saas-tooltip"
                                  >more_vert</span
                                >
                              </m-button>
                              <p-menu
                                styleClass="mapped-campaign-actions"
                                [popup]="true"
                                #mappedCampaignMenu
                                appendTo="body"
                                (onHide)="clearUnmapCampaignAction()"
                                [model]="mappedCampaignActions"></p-menu>
                            </ng-container>
                            <!--#endregion -->

                            <!--#region: campaign spend -->
                            <ng-container *ngSwitchCase="'spend'">
                              {{
                                campaign[header.field]
                                  ? (campaign[header.field]
                                    | customCurrency: "1.0-0")
                                  : "-"
                              }}
                            </ng-container>
                            <!--#endregion -->

                            <!--#region: campaign impact -->
                            <ng-container *ngSwitchCase="'impact'">
                              <div
                                *ngIf="campaign.geoTestStatus; else noImpact"
                                class="flex items-center gap-1">
                                <span
                                  class="flex material-symbols-outlined text-blue-600 text-base font-medium lh-1"
                                  >info</span
                                >
                                <p class="text-gray-1000 b1 mb-0">Geo Test</p>
                              </div>
                              <ng-template #noImpact>-</ng-template>
                            </ng-container>
                            <!--#endregion -->

                            <!--#region: campaign tactic -->
                            <ng-container *ngSwitchCase="'tactic'">
                              <ng-template
                                [ngTemplateOutlet]="tacticColumn"></ng-template>
                            </ng-container>
                            <ng-container *ngSwitchCase="'operationalTactic'">
                              <ng-template
                                [ngTemplateOutlet]="tacticColumn"></ng-template>
                            </ng-container>
                            <ng-template #tacticColumn>
                              <span
                                class="cursor-default"
                                [pTooltip]="campaign['tacticTooltip']"
                                [escape]="false"
                                tooltipPosition="top"
                                tooltipStyleClass="saas-tooltip">
                                {{ campaign[header.field] || "-" }}
                              </span>
                            </ng-template>

                            <ng-container *ngSwitchCase="'channel'">
                              <ng-template
                                [ngTemplateOutlet]="
                                  channelColumn
                                "></ng-template>
                            </ng-container>
                            <ng-container *ngSwitchCase="'operationalChannel'">
                              <ng-template
                                [ngTemplateOutlet]="
                                  channelColumn
                                "></ng-template>
                            </ng-container>
                            <ng-template #channelColumn>
                              <span
                                class="cursor-default"
                                [pTooltip]="campaign['channelTooltip']"
                                [escape]="false"
                                tooltipPosition="top"
                                tooltipStyleClass="saas-tooltip">
                                {{ campaign[header.field] || "-" }}
                              </span>
                            </ng-template>

                            <ng-container *ngSwitchCase="'segment'">
                              <ng-template
                                [ngTemplateOutlet]="
                                  segmentColumn
                                "></ng-template>
                            </ng-container>
                            <ng-container *ngSwitchCase="'operationalSegment'">
                              <ng-template
                                [ngTemplateOutlet]="
                                  segmentColumn
                                "></ng-template>
                            </ng-container>
                            <ng-template #segmentColumn>
                              <span
                                class="cursor-default"
                                [pTooltip]="campaign['segmentTooltip']"
                                [escape]="false"
                                tooltipPosition="top"
                                tooltipStyleClass="saas-tooltip">
                                {{ campaign[header.field] || "-" }}
                              </span>
                            </ng-template>
                            <!--#endregion-->

                            <ng-container *ngSwitchDefault>
                              <span
                                [pTooltip]="
                                  (campaign[header.field] + '').length > 30
                                    ? campaign[header.field]
                                    : ''
                                "
                                tooltipStyleClass="saas-tooltip"
                                tooltipPosition="top"
                                class="block max-h-full overflow-hidden overflow-ellipsis text-nowrap">
                                {{ campaign[header.field] || "-" }}
                              </span>
                            </ng-container>
                          </td>
                        </tr>
                        <ng-container
                          *ngIf="
                            index === campaignQueue.queue.length - 1 &&
                            activeDimensionPageNumber <
                              campaignDimensionInfo.count / virtualPageSize -
                                1 &&
                            campaignDimensionInfo.count >= 100
                          ">
                          <tr>
                            <td
                              class="p-table-td-lg text-center"
                              [colSpan]="columns.length">
                              <m-link
                                [label]="loadNext"
                                link="next"
                                linkElement="span"
                                styleClass="b2"
                                (linkClick)="
                                  loadMoreCampaignsFromIndexDB(
                                    true,
                                    campaignTable
                                  )
                                "></m-link>
                            </td>
                          </tr>
                        </ng-container>
                      </ng-template>
                      <ng-template pTemplate="emptymessage">
                        <!--#region: table loading skeleton -->
                        <ng-container
                          *ngIf="
                            !campaignQueue.queue.length &&
                            (tableLoading | async)
                          ">
                          <tr *ngFor="let i of [1, 2, 3, 4, 5]">
                            <td
                              *ngIf="activeTaxonomyTab === 'unmapped'"
                              [style.width]="'4rem'">
                              <p-tableCheckbox
                                [disabled]="true"></p-tableCheckbox>
                            </td>
                            <td
                              *ngFor="let header of filteredCampaignViewColumns"
                              class="p-table-td-lg {{ header.styleClass }}"
                              [style.width]="header.width"
                              [style.max-width]="header.width"
                              [style.min-width]="header.width">
                              <p-skeleton
                                styleClass="block tw-w-full"
                                height="2rem"
                                width="100%"></p-skeleton>
                            </td>
                          </tr>
                        </ng-container>
                        <!--#endregion -->

                        <!--#region: table no data -->
                        <ng-container
                          *ngIf="
                            (tableLoading | async) === false &&
                            !campaignQueue.queue?.length
                          ">
                          <tr>
                            <td colspan="100%">
                              <m-not-available
                                [noDataState]="noData"
                                [title]="
                                  'No campaigns within this group'
                                "></m-not-available>
                            </td>
                          </tr>
                        </ng-container>
                        <!--#endregion -->
                      </ng-template>
                    </p-table>
                    <!--#endregion -->

                    <!--#region: tactic selector -->
                    @if (showUpdateMappingOverlay) {
                      <lib-taxonomy-campaign-mapping-update-popover
                        class="self-stretch"
                        [clientId]="clientId"
                        [brandId]="brandId"
                        [selectedCampaignCount]="
                          allCampaignsSelected
                            ? campaignDimensionInfo.count
                            : selectedCampaigns.length
                        "
                        [selectedDimensionOptions]="selectedDimensionOptions"
                        [selectedCampaignType]="activeTaxonomyTab"
                        [selectedDataSource]="activeAccordionDimensionValue"
                        (cancelDimensionMapping)="cancelDimensionMapping()"
                        (updateDimensionMapping)="
                          updateCampaignsMapping($event)
                        "></lib-taxonomy-campaign-mapping-update-popover>
                    }
                    <!--#endregion -->
                  </div>
                </div>
              </ng-template>
            </p-accordionTab>
          </p-accordion>
          @if (
            campaignAccordions.length && activeTaxonomyTab === "underProcessing"
          ) {
            <div class="mt-6">
              <m-button
                type="secondary"
                (click)="unmapAllInProgressCampaigns($any($event))"
                >Remove all</m-button
              >
            </div>
          }
        </div>
        <!--#endregion -->

        <!--#region: accordion skeletons-->
        <div *ngIf="loading | async" class="flex flex-col w-full gap-4">
          <m-skeleton
            *ngFor="let i of [1, 2, 3, 4, 5]"
            height="4rem"
            width="100%"
            class="w-full"
            styleClass="w-full"></m-skeleton>
        </div>
        <!--#endregion -->

        <!--#region: no data for accordions -->
        <div
          *ngIf="
            campaignAccordions &&
            !campaignAccordions.length &&
            (loading | async) === false
          "
          class="flex flex-col w-full gap-4">
          <m-not-available
            [noDataState]="noData"
            [title]="tabWiseNoDataMessages[activeTaxonomyTab].title"
            [description]="
              tabWiseNoDataMessages[activeTaxonomyTab].description
            "></m-not-available>
        </div>
        <!--#endregion -->
      </ng-template>
    </ng-container>
  </m-tab-view>
  <!--#endregion -->
</div>

<!--#region: in-review campaigns bottom panel   -->
<m-bottom-panel
  [zIndex]="1200"
  *ngIf="inReviewCampaigns.length > 0"
  [rightActionButtons]="reviewActionButtons"
  [leftActionButtons]="reviewNavigationButtons"
  [message]="reviewUndoMessage.message"
  [messageType]="reviewUndoMessage.messageType"
  (buttonClick)="
    handleReviewAction({ action: $any($event.id), payload: {} })
  "></m-bottom-panel>
<!--#endregion -->

<!--#region: static review campaigns full-size panel   -->
<lib-taxonomy-unpublished-campaigns
  [showUnpublishedCampaigns]="showCampaignsToReview"
  (showUnpublishedCampaignsChanged)="
    handleReviewAction({
      action: $any('closeReview'),
      payload: {},
    })
  "
  (removeUnpublishedCampaigns)="handleReviewAction($event)"
  [unpublishedCampaigns]="
    inReviewCampaigns
  "></lib-taxonomy-unpublished-campaigns>
<!--#endregion -->

<!--#region: in-review or mapped campaigns confirmation popup -->
<m-modal
  [(visible)]="showCampaignMappingOperationDialog"
  [modal]="true"
  [closable]="true"
  [showHeader]="true"
  [showFooter]="true"
  (onHide)="cancelRemoveCampaignsOperation()">
  <div modalHeader class="flex w-full items-center">
    <span class="h4 text-gray-1000">Remove Campaigns?</span>
  </div>
  <div class="flex w-full items-center p-6">
    <span
      class="b1 text-gray-1000 w-[25rem]"
      [innerHTML]="unmapCampaignsConfirmationMessage">
    </span>
  </div>
  <div modalFooter class="flex w-full items-center justify-end gap-4">
    <m-button
      type="secondary"
      styleClass="m-0"
      (click)="cancelRemoveCampaignsOperation()">
      <span class="b2">Cancel</span>
    </m-button>
    <m-button
      type="primary"
      styleClass="m-0"
      (click)="unmapCampaignsWithFilter()">
      <span class="b2">Yes, remove</span>
    </m-button>
  </div>
</m-modal>
<!--#endregion -->
