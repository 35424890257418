import { IGeoLocation } from '.';

export interface IGeoCell {
  executionId: string;
  cells: number[];
  anchorCells: number[];
  anchorCellSize: number;
  cellName: string;
  cellRank: number;
  cellSize: number;
  geoSplitType: GeoSplitType;
  conversionTypeId: number;
  historicalMdl: number;
  locations: IGeoLocation[];
  anchorLocations: IGeoLocation[];
  cellConfigId?: number;
  recommendedTestId?: number;
  startDate?: string;
  endDate?: string;
}

export interface IGeoCellOption extends IGeoCell {
  label: string;
  disabled: boolean;
}

export enum GeoSplitType {
  REGION = 'REGION',
  DMA = 'DMA',
  CITY = 'CITY',
  ZIP = 'ZIP'
}
