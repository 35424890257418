import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageDialogText } from '@portal/app/shared/types';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef
} from 'primeng/dynamicdialog';

@Component({
  selector: 'portal-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss'],
  providers: [DialogService]
})
export class MessageDialogComponent implements OnInit, OnDestroy {
  layoutType: string | undefined;
  data: MessageDialogText[] | undefined;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit() {
    this.layoutType = this.config.data?.layoutType;
    this.data = this.config.data?.data;
  }

  close() {
    this.ref.close();
  }

  confirm() {
    this.ref.close(true);
  }

  ngOnDestroy(): void {
    if (this.ref) {
      this.ref.close();
    }
  }
}
