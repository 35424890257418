import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Message } from 'primeng/api';
import { ApiService, HeroMetricType, IHeroMetricCard } from '@libs/apis';
import { SelectionService } from '@portal/app/shared/services/selection.service';
import { FormatService } from '@portal/app/dashboard/home-page/services/format.service';

@Injectable({
  providedIn: 'root'
})
export class TaxonomyBannerService {
  private heroMetricsSubject = new BehaviorSubject<IHeroMetricCard[]>([]);
  heroMetrics$ = this.heroMetricsSubject.asObservable();

  constructor(
    private readonly apiService: ApiService,
    private readonly selectionService: SelectionService,
    private readonly formatService: FormatService
  ) {}

  public getHeroMetrics() {
    this.heroMetricsSubject.next([]);
    const { clientId, brandId } = this.selectionService.getClientIdAndBrandId();
    return this.apiService.taxonomyApisOperations
      .getHeroMetrics(clientId, brandId, [HeroMetricType.UNMAPPED])
      .then((data) => {
        this.heroMetricsSubject.next(data);
      });
  }

  public getUnmappedMessages() {
    const messages: Message[] = [];
    const metric = this.heroMetricsSubject.getValue()[0];
    if (metric && metric.campaignCount > 0) {
      messages.push(this.getMessage(metric));
    }
    return messages;
  }

  private getMessage(metric: IHeroMetricCard): Message {
    const spend = this.formatService.truncateValue({
      formattedValue: `$${metric.spend}`,
      isCurrency: true,
      value: metric.spend
    });
    const message = `You have ${metric.campaignCount} unmapped campaigns worth over ${spend}! Map them as soon as possible to see more accurate data.`;
    const url = `/a/${this.selectionService.buildSelectionSlug(
      this.selectionService.getSelection()
    )}/products/campaign-mapping`;
    const link = `<a href="${url}" target="blank" class="text-blue-600">Map campaigns</a>`;
    return {
      severity: 'warn',
      detail: `${message} ${link}`
    };
  }
}
