import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  OnChanges,
  SimpleChanges
} from '@angular/core';

export interface IDimensionNavigationConfig {
  label: string;
  id: string;
  span: number;
  showCount: boolean;
  count: number;
  isSelected: boolean;
}

@Component({
  selector: 'portal-data-grid-dimension-navigator',
  templateUrl: './data-grid-dimension-navigator.component.html',
  styleUrls: ['./data-grid-dimension-navigator.component.scss']
})
export class DataGridDimensionNavigatorComponent implements OnInit, OnChanges {
  @Input() configs: IDimensionNavigationConfig[] = [];
  @Input() loading = true;
  @Output() selectedDimensionNavigation = new EventEmitter();
  activeIndex = 0;

  ngOnInit(): void {
    this.setActiveIndexForTab();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.countByFields || changes.configs) {
      this.configs = changes.configs?.currentValue;
      this.setActiveIndexForTab();
    }
  }

  tabChange(event: { index: number }): void {
    this.selectedDimensionNavigation.next(this.configs[event.index]);
  }

  setActiveIndexForTab(): void {
    this.configs.forEach((config, index) => {
      if (config.isSelected) {
        this.activeIndex = index;
      }
    });
  }
}
