@if (value !== 0) {
  <div class="delta-show-cell">
    <span class="delta-show primary">{{
      value | formatValue: fieldDefinitions[literalId]
    }}</span>
    <span
      class="delta-show changed"
      [ngClass]="[getDeltaCellChangedClassName()]"
      ><span data-html2canvas-ignore="true"
        >{{ getDeltaCellChangedClassName() === "increased" ? "↑" : "" }}
        {{ getDeltaCellChangedClassName() === "decreased" ? "↓" : "" }}
      </span>
      @if (getDeltaCellChangedClassName() !== "zero") {
        <span>
          {{ changedValue | formatValue: fieldDefinitions[literalId] }}</span
        >
      } @else {
        <span>
          {{ changedValue | formatValue: fieldDefinitions[literalId] }}</span
        >
      }
    </span>
  </div>
} @else {
  <span>{{ value | customCurrency }}</span>
}
