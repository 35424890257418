import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BrandStoreService } from '@portal/app/dashboard/geo-doe-config/data-stores/brand-store.service';
import { StudyConfigStoreService } from '@portal/app/dashboard/geo-doe-config/data-stores/study-config-store.service';
import {
  ICampaign,
  IChannelTacticMap,
  IConversionType,
  ICPAdStudyConfig,
  IGeoDataSourceWithCampaigns,
  IGeoLocationFilter,
  IGeoLocations,
  IMarketFactDataWithConfigId,
  IPlatform,
  IStudyConfig,
  ISupportedCountries
} from '@portal/app/dashboard/geo-doe-config/models';
import { IAlertActionRequest } from '@portal/app/dashboard/geo-doe-config/models/IAlertActionRequest';
import {
  IHealthChecksResponse,
  IStudyConfigResponse
} from '@portal/app/dashboard/geo-doe-config/models/ICPAdStudyConfig';
import { ICPDoeConfig } from '@portal/app/dashboard/geo-doe-config/models/ICPDoeConfig';
import { ICPDoeStudyContaminationDetails } from '@portal/app/dashboard/geo-doe-config/models/ICPDoeStudyContaminationDetails';
import { INewSupportedCountry } from '@portal/app/dashboard/geo-doe-config/models/ICrossPlatformDoeSupportedCountries';
import { ILatestMSPlaceboMarketResponse } from '@portal/app/dashboard/geo-doe-config/models/ILatestMSMarketResponse';
import {
  IMeasuredGenericConfig,
  IMeasuredURI
} from '@portal/app/dashboard/geo-doe-config/models/IMeasuredGenericConfig';
import { IMsMarketByType } from '@portal/app/dashboard/geo-doe-config/models/IMsMarketByType';
import { IReportConfigReqParams } from '@portal/app/dashboard/geo-doe-config/models/IReportConfigReqParams';
import { IStudyAlerts } from '@portal/app/dashboard/geo-doe-config/models/IStudyAlerts';
import {
  IStudyContaminationValidatorRequest,
  IStudyContaminationValidatorResponse
} from '@portal/app/dashboard/geo-doe-config/models/IStudyContaminationValidatorResponse';
import { ITblCpDoeMarketRank } from '@portal/app/dashboard/geo-doe-config/models/ITblCpDoeMarketRank';
import { IUserPermissions } from '@portal/app/dashboard/geo-doe-config/models/IUserPermission';
import {
  CellTypes,
  GeoSplitType,
  getGeoLocationIds,
  StudyStatus,
  VendorConfiguredAt,
  VendorLevel
} from '@portal/app/dashboard/geo-doe-config/shared/constants';
import { environment } from '@portal/environments/environment';
import { isUndefined, omitBy } from 'lodash-es';
import { EMPTY, Observable, of } from 'rxjs';
import {
  IDTDMap,
  IUpdateStudyConfig,
  IUpdateStudyConfigDTDMap
} from '../models/IUpdateStudyConfigMidflight';
import { ICampaignRequestParams } from '@portal/app/dashboard/geo-doe-config/models/ICampaign';
import { IScaleDailyBudgetReqParams } from '@portal/app/dashboard/geo-doe-config/models/IScaleDailyBudgetReqParams';
import { IScaleDailyBudgetResponse } from '@portal/app/dashboard/geo-doe-config/models/IScaleDailyBudgetResponse';
import { GeoDesignerConstants } from '@portal/app/geo-designer/common/geo-designer.constants';

const measuredApi = `${environment.apiDomain}/api`;

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  measuredUriV1 = `${measuredApi}/v1/`;
  measuredUriV2 = `${measuredApi}/v2/`;

  constructor(
    private readonly http: HttpClient,
    private readonly brandStoreService: BrandStoreService,
    private readonly studyConfigStoreService: StudyConfigStoreService
  ) {}

  /* eslint-disable @typescript-eslint/no-explicit-any */
  login(): Observable<any> {
    return this.http.get(`${this.measuredUriV2}login`);
  }

  getUserPermission(): Observable<IUserPermissions> {
    return this.http.get<IUserPermissions>(
      `${this.measuredUriV2}user/permissions`
    );
  }

  getGeoExperiments(): Observable<ICPAdStudyConfig[]> {
    const params = new HttpParams()
      .set('clientId', `${this.brandStoreService.brand.clientId}`)
      .set('brandId', `${this.brandStoreService.brand.brandId}`);
    return this.http.get<ICPAdStudyConfig[]>(
      `${this.measuredUriV1}cross-platform-doe`,
      { params }
    );
  }

  getGeoExperimentSummary(
    studyId: number,
    isStudyWithVendorRemoval: boolean | null
  ): Observable<ICPAdStudyConfig> {
    const measuredURI = this.getMeasuredURI(isStudyWithVendorRemoval).hostURI;
    return this.http.get<ICPAdStudyConfig>(
      `${measuredURI}cross-platform-doe/${studyId}`
    );
  }

  getGeoLocations(
    geoLocationFilter: IGeoLocationFilter
  ): Observable<IGeoLocations[]> {
    let params = new HttpParams();
    const filter = omitBy(geoLocationFilter, isUndefined);
    for (const filterKey in filter) {
      if (filterKey) {
        if (filterKey === 'locationId') {
          (filter[filterKey] as string[]).forEach((id) => {
            params = params.append(filterKey, id);
          });
        } else {
          params = params.set(filterKey, filter[filterKey]);
        }
      }
    }

    return this.http.get<IGeoLocations[]>(
      `${this.measuredUriV1}geo-locations`,
      { params }
    );
  }

  getChannelTacticMap(): Observable<IChannelTacticMap[]> {
    const params = new HttpParams()
      .set('distinct', 'channel')
      .append('distinct', 'tactic')
      .set('clientId', `${this.brandStoreService.brand.clientId}`);
    return this.http.get<IChannelTacticMap[]>(
      `${this.measuredUriV1}channel-tactic-map`,
      { params }
    );
  }

  getConversionTypes(): Observable<IConversionType[]> {
    if (this.brandStoreService.brand) {
      const params = new HttpParams()
        .set('clientId', `${this.brandStoreService.brand.clientId}`)
        .set('brandId', `${this.brandStoreService.brand.brandId}`)
        .set('status', 'ACTIVE');
      return this.http.get<IConversionType[]>(
        `${this.measuredUriV1}conversion-types`,
        { params }
      );
    } else {
      return EMPTY;
    }
  }

  getPlatforms(version: number): Observable<IPlatform[]> {
    const hostURI =
      version === GeoDesignerConstants.designerVersion.v2
        ? this.measuredUriV2
        : this.getMeasuredURI(null).hostURI;
    const params = new HttpParams()
      .set('clientId', `${this.brandStoreService.brand?.clientId}`)
      .set(
        'conversionTypeId',
        `${this.studyConfigStoreService.studyConfiguration?.conversionTypeId}`
      )
      .set(
        'geoSplitLevel',
        `${this.studyConfigStoreService.studyConfiguration?.geoSplitLevel}`
      );
    return this.http.get<IPlatform[]>(
      `${hostURI}cross-platform-doe/platforms`,
      { params }
    );
  }

  getCampaignByPlatform(
    platform: string,
    inventoryRefStartDate: Date | string,
    inventoryRefEndDate: Date | string,
    vendorLevel: VendorLevel,
    vendorConfiguredAt: VendorConfiguredAt,
    doSpendCheck: boolean,
    isClientImplementation: boolean,
    platformIdentifier: string | null
  ): Observable<ICampaign[]> {
    const campaignsRequest: ICampaignRequestParams = {
      clientId: this.brandStoreService.brand.clientId,
      brandId: this.brandStoreService.brand.brandId,
      geoDataSourceId: platform,
      inventoryRefStartDate,
      inventoryRefEndDate,
      conversionTypeId:
        this.studyConfigStoreService.studyConfiguration.conversionTypeId,
      geoSplitLevel:
        this.studyConfigStoreService.studyConfiguration.geoSplitLevel,
      studyStartDate: this.studyConfigStoreService.studyConfiguration.startDate,
      studyEndDate: this.studyConfigStoreService.studyConfiguration.endDate,
      platformIdentifier,
      vendorLevel,
      vendorConfiguredAt,
      doSpendCheck,
      isClientImplementation
    };
    const url = `${this.getMeasuredURI().hostURI}cross-platform-doe/campaigns`;
    return this.http.post<ICampaign[]>(url, campaignsRequest);
  }

  getLatestMarketsFactDataWithConfigIds(
    filterParam: {
      inventoryRefStartDate: Date | string;
      inventoryRefEndDate: Date | string;
      conversionTypeId: number;
      geoSplitLevel: string;
      geoDataSourceWithCampaigns: IGeoDataSourceWithCampaigns[];
      msMarkets?: IMsMarketByType;
      marketExecutionId?: string;
    },
    isStudyWithVendorRemoval: boolean | null = null
  ): Observable<IMarketFactDataWithConfigId> {
    const {
      inventoryRefStartDate,
      inventoryRefEndDate,
      geoDataSourceWithCampaigns,
      conversionTypeId,
      geoSplitLevel,
      msMarkets = {},
      marketExecutionId
    } = filterParam;

    let params = new HttpParams()
      .set('clientId', `${this.brandStoreService.brand.clientId}`)
      .set('brandId', `${this.brandStoreService.brand.brandId}`)
      .set('inventoryRefStartDate', `${inventoryRefStartDate}`)
      .set('inventoryRefEndDate', `${inventoryRefEndDate}`)
      .set('conversionTypeId', `${conversionTypeId}`)
      .set('geoSplitLevel', geoSplitLevel);

    const executionId =
      this.studyConfigStoreService.getIsReadOnlyView() &&
      this.studyConfigStoreService.savedStudyConfig
        ? this.studyConfigStoreService.savedStudyConfig
            .marketSelectionExecutionId
        : marketExecutionId;
    if (executionId) {
      params = params.append('executionId', executionId);
    }
    return this.http.post<IMarketFactDataWithConfigId>(
      `${
        this.getMeasuredURI(isStudyWithVendorRemoval).hostURI
      }cross-platform-doe-market-selection/markets-fact-data`,
      { geoDataSourceWithCampaigns, msMarkets },
      { params }
    );
  }

  allocateGeoMarketsToCell(
    reqParams: {
      executionId: string;
      inventoryRefStartDate: Date | string;
      inventoryRefEndDate: Date | string;
      conversionTypeId: number;
      geoSplitLevel: string;
    },
    cellDetails: {
      excludedGeoMarketIds: number[];
      cells: IGeoDataSourceWithCampaigns[];
    }
  ): Observable<any[]> {
    const {
      executionId,
      inventoryRefStartDate,
      inventoryRefEndDate,
      conversionTypeId,
      geoSplitLevel
    } = reqParams;
    const params = new HttpParams()
      .set('clientId', `${this.brandStoreService.brand.clientId}`)
      .set('brandId', `${this.brandStoreService.brand.brandId}`)
      .set('inventoryRefStartDate', `${inventoryRefStartDate}`)
      .set('inventoryRefEndDate', `${inventoryRefEndDate}`)
      .set('executionId', `${executionId}`)
      .set('conversionTypeId', `${conversionTypeId}`)
      .set('geoSplitLevel', `${geoSplitLevel}`);

    return this.http.post<any[]>(
      `${this.measuredUriV1}cross-platform-doe-market-selection/market-allocation`,
      cellDetails,
      { params }
    );
  }

  validateStudyContamination(
    status: StudyStatus
  ): Observable<IStudyContaminationValidatorResponse> {
    if (status === StudyStatus.DRAFT) {
      return of({
        isStudyValidToSchedule: true,
        overlappingStudies: [],
        overlappingGeoLocationNames: []
      });
    }
    const studyDetails = {
      conversionTypeId:
        this.studyConfigStoreService.studyConfiguration.conversionTypeId,
      testCellGeoLocationIds: getGeoLocationIds(
        this.studyConfigStoreService.studyConfiguration.cells,
        false
      ),
      bauCellGeoLocationIds: getGeoLocationIds(
        this.studyConfigStoreService.studyConfiguration.cells,
        true
      ),
      geoScopeIds: this.studyConfigStoreService.studyConfiguration.geoScopeIds
    };
    const params = new HttpParams()
      .set('clientId', `${this.brandStoreService.brand.clientId}`)
      .set('brandId', `${this.brandStoreService.brand.brandId}`)
      .set(
        'studyStartDate',
        `${this.studyConfigStoreService.studyConfiguration.startDate}`
      )
      .set(
        'studyEndDate',
        `${this.studyConfigStoreService.studyConfiguration.endDate}`
      )
      .set(
        'geoSplitType',
        `${this.studyConfigStoreService.studyConfiguration.geoSplitLevel}`
      )
      .set(
        'allowedOverlapDurationOnStudyLaunchDateInDays',
        `${this.studyConfigStoreService.studyConfiguration.allowedOverlapDurationOnStudyLaunchDateInDays}`
      );
    return this.http.post<IStudyContaminationValidatorResponse>(
      `${this.measuredUriV1}cross-platform-doe/validate-study-contamination`,
      studyDetails,
      { params }
    );
  }

  validateStudyContaminationMSV2(
    parameters: IStudyContaminationValidatorRequest
  ): Observable<IStudyContaminationValidatorResponse> {
    const params = new HttpParams()
      .set('clientId', `${this.brandStoreService.brand.clientId}`)
      .set('brandId', `${this.brandStoreService.brand.brandId}`)
      .set('studyStartDate', `${parameters.studyStartDate}`)
      .set('studyEndDate', `${parameters.studyEndDate}`)
      .set('geoSplitType', `${parameters.geoSplitType}`)
      .set(
        'allowedOverlapDurationOnStudyLaunchDateInDays',
        `${parameters.allowedOverlapDurationOnStudyLaunchDateInDays}`
      );
    return this.http.post<IStudyContaminationValidatorResponse>(
      `${this.measuredUriV1}cross-platform-doe/validate-study-contamination`,
      {
        ...parameters.geoLocationDetails,
        designerVersion: parameters?.designerVersion
      },
      { params }
    );
  }

  saveStudy(studyConfig: IStudyConfig): Observable<IStudyConfigResponse> {
    const { hostURI, isV2 } = this.getMeasuredURI();
    studyConfig.isStudyWithVendorRemoval = isV2;
    return this.http.post<IStudyConfigResponse>(
      `${hostURI}cross-platform-doe`,
      studyConfig
    );
  }

  updateStudy(studyConfig: IStudyConfig): Observable<IStudyConfigResponse> {
    const { hostURI, isV2 } = this.getMeasuredURI();
    studyConfig.isStudyWithVendorRemoval = isV2;
    return this.http.put<IStudyConfigResponse>(
      `${hostURI}cross-platform-doe`,
      studyConfig
    );
  }

  inActivateStudy(studyId: number): Observable<ICPAdStudyConfig> {
    return this.http.delete<ICPAdStudyConfig>(
      `${this.measuredUriV1}cross-platform-doe/${studyId}`
    );
  }

  getStudyAlerts(): Observable<IStudyAlerts[]> {
    const params = new HttpParams()
      .set('clientId', `${this.brandStoreService.brand?.clientId}`)
      .set('brandId', `${this.brandStoreService.brand?.brandId}`);
    return this.http.get<IStudyAlerts[]>(
      `${this.measuredUriV1}cross-platform-doe/study-alerts`,
      {
        params
      }
    );
  }

  studyAlertAction(
    alertActionReq: IAlertActionRequest,
    isStudyWithVendorRemoval: boolean
  ): Observable<ICPDoeStudyContaminationDetails> {
    return this.http.post<ICPDoeStudyContaminationDetails>(
      `${
        this.getMeasuredURI(isStudyWithVendorRemoval).hostURI
      }cross-platform-doe/study-alert-action`,
      alertActionReq
    );
  }

  getSupportedCountries(): Observable<ISupportedCountries[]> {
    return this.http.get<ISupportedCountries[]>(
      `${this.measuredUriV1}supported-countries`
    );
  }

  addNewSupportedCountry(
    newSupportedCountryData: INewSupportedCountry
  ): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.measuredUriV1}supported-countries`,
      newSupportedCountryData
    );
  }

  getAllMsMarkets(filters: {
    executionId: string;
    conversionTypeId: number;
    geoSplitType: string;
  }): Observable<ITblCpDoeMarketRank[]> {
    const { executionId, conversionTypeId, geoSplitType } = filters;
    const params = new HttpParams()
      .set('clientId', `${this.brandStoreService.brand?.clientId}`)
      .set('executionId', executionId)
      .set('conversionTypeId', conversionTypeId)
      .set('geoSplitType', geoSplitType);
    return this.http.get<ITblCpDoeMarketRank[]>(
      `${this.measuredUriV1}cross-platform-doe-market-selection`,
      { params }
    );
  }

  getNewMSMarkets(filters: {
    conversionTypeId: number;
    geoSplitType: string;
    executionId: string | undefined;
  }): Observable<ILatestMSPlaceboMarketResponse[]> {
    let params = new HttpParams();

    const filter = omitBy(filters, isUndefined);
    for (const filterKey in filter) {
      if (filterKey) {
        params = params.set(filterKey, `${filter[filterKey]}`);
      }
    }
    params = params.set('clientId', `${this.brandStoreService.brand.clientId}`);
    return this.http.get<ILatestMSPlaceboMarketResponse[]>(
      `${this.measuredUriV1}cross-platform-doe-market`,
      { params }
    );
  }

  cloneStudy(
    studyId: number,
    studyConfig: { studyName: string },
    isStudyWithVendorRemoval: boolean
  ): Observable<ICPAdStudyConfig> {
    const { hostURI } = this.getMeasuredURI(isStudyWithVendorRemoval);
    return this.http.post<ICPAdStudyConfig>(
      `${hostURI}cross-platform-doe/clone-study/${studyId}`,
      studyConfig
    );
  }

  updateReportConfig(
    reportConfig: IReportConfigReqParams
  ): Observable<ICPAdStudyConfig> {
    const configs = {
      ...reportConfig,
      clientId: this.brandStoreService.brand?.clientId,
      brandId: this.brandStoreService.brand?.brandId
    };
    return this.http.put<ICPAdStudyConfig>(
      `${this.measuredUriV1}cross-platform-doe/report-config`,
      configs
    );
  }

  validateStudyName(studyName: string): Observable<boolean> {
    const params = new HttpParams()
      .set('clientId', `${this.brandStoreService.brand?.clientId}`)
      .set('brandId', `${this.brandStoreService.brand?.brandId}`)
      .set('studyName', studyName);
    return this.http.get<boolean>(
      `${this.measuredUriV1}cross-platform-doe/is-study-name-exists`,
      {
        params
      }
    );
  }

  disableSync(
    studyId: number,
    disableSyncMessage: string
  ): Observable<ICPAdStudyConfig> {
    return this.http.post<ICPAdStudyConfig>(
      `${this.measuredUriV1}cross-platform-doe/disable-sync/`,
      {
        studyId,
        disableSyncMessage
      }
    );
  }

  updateStudyConfigMidFlight(
    studyConfig: IUpdateStudyConfig
  ): Observable<ICPAdStudyConfig> {
    return this.http.post<ICPAdStudyConfig>(
      `${this.measuredUriV1}cross-platform-doe/update-study-config-mid-flight`,
      studyConfig
    );
  }

  getHealthChecks(
    conversionTypeId: number,
    granularity: string
  ): Observable<IHealthChecksResponse> {
    return of({});
    console.warn(conversionTypeId, granularity);
    // TODO: Implement the latest healthcheck logic fro v2 and v1
    // const params = new HttpParams()
    //   .set('clientId', `${this.brandStoreService.brand.clientId}`)
    //   .set('conversionTypeId', conversionTypeId)
    //   .set('granularity', granularity);
    // return this.http.get<IHealthChecksResponse>(
    //   `${this.measuredUriV1}cross-platform-doe/health-checks`,
    //   { params }
    // );
  }

  getCpDoeConfigs(
    conversionTypeId: number,
    geoSplitType: GeoSplitType
  ): Observable<ICPDoeConfig[]> {
    const params = new HttpParams()
      .set('clientId', `${this.brandStoreService.brand.clientId}`)
      .set('conversionTypeId', conversionTypeId)
      .set('geoSplitType', geoSplitType);
    return this.http.get<ICPDoeConfig[]>(
      `${this.measuredUriV1}cross-platform-doe-config`,
      { params }
    );
  }

  saveCpDoeConfig(cpDoeConfig: ICPDoeConfig): Observable<ICPDoeConfig> {
    return this.http.post<ICPDoeConfig>(
      `${this.measuredUriV1}cross-platform-doe-config`,
      {
        ...cpDoeConfig,
        clientId: this.brandStoreService.brand.clientId
      }
    );
  }

  updateCpDoeConfig(cpDoeConfig: ICPDoeConfig): Observable<ICPDoeConfig> {
    return this.http.put<ICPDoeConfig>(
      `${this.measuredUriV1}cross-platform-doe-config/${cpDoeConfig.id}`,
      {
        ...cpDoeConfig,
        clientId: this.brandStoreService.brand.clientId
      }
    );
  }

  getMeasuredGenericConfig(
    propertyType: string,
    paramName: string[],
    isDefaultRequired: boolean
  ): Observable<IMeasuredGenericConfig[]> {
    let params = new HttpParams()
      .set('clientId', `${this.brandStoreService.brand.clientId}`)
      .set('propertyType', propertyType)
      .set('isDefaultRequired', isDefaultRequired);
    for (const param of paramName) {
      if (param) {
        params = params.append('paramName', param);
      }
    }
    return this.http.get<IMeasuredGenericConfig[]>(
      `${this.measuredUriV1}measured-generic-config`,
      { params }
    );
  }

  getDTDMapData(studyId: number, cellId?: number[]): Observable<IDTDMap[]> {
    let params = new HttpParams()
      .set('clientId', `${this.brandStoreService.brand.clientId}`)
      .set('studyId', studyId);
    if (cellId) {
      cellId.forEach((id) => {
        params = params.append('cellIds', id);
      });
    }
    return this.http.get<IDTDMap[]>(
      `${this.measuredUriV1}cross-platform-doe/dtd-map-data`,
      { params }
    );
  }

  updateStudyConfigDTDMapMidFlight(
    studyId: number,
    dtdMapData: IUpdateStudyConfigDTDMap[]
  ): Observable<boolean> {
    const params = new HttpParams()
      .set('clientId', `${this.brandStoreService.brand.clientId}`)
      .set('studyId', studyId);
    return this.http.post<boolean>(
      `${this.measuredUriV1}cross-platform-doe/update-study-config-dtd-map-mid-flight`,
      dtdMapData,
      { params }
    );
  }

  getDailyScaleBudget(
    dailyScaleBudgetReqParams: IScaleDailyBudgetReqParams,
    cellType: CellTypes,
    isContributionOnly: number
  ): Observable<IScaleDailyBudgetResponse> {
    if (cellType === CellTypes.CONTROL_HOLDOUT || isContributionOnly) {
      return of({
        data: [],
        error: null
      });
    }
    return this.http.post<IScaleDailyBudgetResponse>(
      `${this.measuredUriV2}cross-platform-doe/calculate-and-adjust-daily-scale-budget`,
      dailyScaleBudgetReqParams
    );
  }

  private getMeasuredURI(
    isStudyWithVendorRemoval: boolean | null = null
  ): IMeasuredURI {
    const isClientEnabledForV2 =
      this.studyConfigStoreService.isVendorRemovedConfig;
    const isReadOnly = this.studyConfigStoreService.getIsReadOnlyView();
    const savedStudyConfig = this.studyConfigStoreService.savedStudyConfig;

    if (isStudyWithVendorRemoval !== null) {
      return {
        hostURI: isStudyWithVendorRemoval
          ? this.measuredUriV2
          : this.measuredUriV1,
        isV2: isStudyWithVendorRemoval as boolean
      };
    }
    if (isReadOnly) {
      return savedStudyConfig && savedStudyConfig.isStudyWithVendorRemoval
        ? { hostURI: this.measuredUriV2, isV2: true }
        : { hostURI: this.measuredUriV1, isV2: false };
    }

    if (isClientEnabledForV2) {
      return { hostURI: this.measuredUriV2, isV2: true };
    } else {
      return { hostURI: this.measuredUriV1, isV2: false };
    }
  }
}
