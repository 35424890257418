import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'multiTacticTooltip'
})
export class MultiTacticTooltipPipe implements PipeTransform {
  transform(tactics: string[] = []): string {
    return `
            <div class="text-start fs-14">
              <div class="fw-600 mb-2">Multi-Tactic Test:</div>
              <ul class="ps-3 m-0">
              ${tactics?.map((tactic) => `<li>${tactic}</li>`).join('\n')}
              </ul>
            </div>
           `;
  }
}
