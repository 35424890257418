import {
  DataGridSelected,
  DataGridEditable,
  DataGridAvailable,
  DataGridGrouped
} from './datagrid';
import {
  ChartAvailableY,
  ChartSelectedY,
  ChartSelectedX,
  ChartAvailableX,
  PrimaryMetric,
  SecondaryMetric,
  MetricControl,
  TableToggleControl,
  FilterControl,
  ControlElement,
  DisplayControl,
  ChartPlotlineX
} from './metric';
import {
  ChartElement,
  BackBarElement,
  HeaderElement,
  TabElement,
  TitleElement,
  ContextElement,
  ActionElement,
  ToggleElement,
  CardElement,
  RadioFilterElement,
  EditPopup,
  IconElement,
  ExperimentDataElement,
  ChartCommonGroupElement,
  DataGridElement,
  FilterElement,
  HeroElement,
  HeroElementBounded,
  RelativePosition,
  BreadCrumbElement
} from './element';
import { ContextField } from './layout';
import {
  DataResponse,
  DataSetResponse,
  FilterValue,
  IDataSetCountResponse
} from '@portal/app/shared/types';

enum LayoutTypes {
  composite = 'COMPOSITE',
  dimensionNavigator = 'DIMENSION_NAVIGATOR',
  filterSetRadioPopup = 'FILTER_SET_RADIO_POPUP',
  incEditableCell = 'INC_EDITABLE_CELL',
  deltaShow = 'DELTA_SHOW',
  barVisualizer = 'BAR_VISUALIZER',
  verticalSeperator = 'VERTICAL_SEPERATOR',
  campaignLink = 'CAMPAIGN_LINK'
}

enum ElementTyping {
  dimension = 'DIMENSION',
  control = 'CONTROL',
  column = 'COLUMN',
  header = 'HEADER',
  metric = 'METRIC',
  footer = 'FOOTER',
  pivotDimension = 'PIVOT_DIMENSION',
  pivotData = 'PIVOT_DATA',
  pivotRow = 'PIVOT_ROW',
  pivotRowHeader = 'PIVOT_ROW_HEADER',
  pivotRowHighlight = 'PIVOT_ROW_HIGHLIGHT',
  experimentLayoutFilter = 'EXPERIMENT_LAYOUT_SELECTION_FILTER',
  experimentLayoutExpansionVisibility = 'EXPERIMENT_LAYOUT_EXPANSION_SECTION_VISIBILITY',
  filterEvent = 'FILTER_EVENT',
  componentTab = 'COMPONENT_TAB',
  sortedColumn = 'SORTED_COLUMN'
}

enum ElementSubTyping {
  selectedY = 'selectedY',
  availableY = 'availableY',
  selectedX = 'selectedX',
  availableX = 'availableX',
  filterControl = 'filterControl',
  metricControl = 'metricControl',
  selected = 'selected',
  available = 'available',
  editable = 'editable',
  grouped = 'grouped',
  primaryMetric = 'primaryMetric',
  secondaryMetric = 'secondaryMetric',
  plotlineX = 'plotlineX',
  displayControl = 'displayControl',
  barVisualizer = 'barVisualizer',
  infoWithIcon = 'infoWithIcon',
  ascending = 'ascending',
  descending = 'descending'
}

enum FbScaleResult {
  viewByFilter = 'viewBy',
  revenueFilter = 'Revenue',
  conversionFilter = 'Conversions',
  roas = 'roas',
  cpo = 'cpo'
}

enum ALIGNMENT {
  vertical = 'VERTICAL',
  horizontal = 'HORIZONTAL'
}

enum DefaultValue {
  dash = '-',
  notApplicable = 'N/A',
  noData = 'No Data'
}

type DIMENSION = Record<
  string,
  ChartAvailableY | ChartSelectedY | ChartSelectedX | ChartAvailableX
>;

type CONTROL = Record<
  string,
  | PrimaryMetric
  | SecondaryMetric
  | MetricControl
  | TableToggleControl
  | FilterControl
>;

interface BaseElement {
  [index: string]:
    | null
    | undefined
    | number
    | string
    | string[]
    | boolean
    | ElementType
    | object
    // Element Group
    | (
        | FilterElement
        | HeroElement
        | HeroElementBounded
        | DataGridElement
        | ChartElement
        | BackBarElement
        | HeaderElement
        | TabElement
        | TitleElement
        | ContextElement
        | ActionElement
        | ToggleElement
        | CardElement
        | RadioFilterElement
        | EditPopup
        | IconElement
        | ExperimentDataElement
        | ChartCommonGroupElement
        | BreadCrumbElement
      )[]
    | RelativePosition[]
    // DataGrid
    | (DataGridSelected | DataGridEditable)[]
    | (DataGridAvailable | DataGridEditable)[]
    | (DataGridGrouped | DataGridEditable)[]
    // Chart
    | ChartAvailableY[]
    | ChartSelectedY[]
    | ChartAvailableX[]
    | ChartSelectedX[]
    | ControlElement[]
    | PrimaryMetric[]
    | SecondaryMetric[]
    | MetricControl[]
    | TableToggleControl[]
    | DisplayControl[]
    | FilterControl[]
    | CONTROL
    | DIMENSION
    | ChartPlotlineX[]
    | {
        elements: ChartElement[];
        filterControls: ContextField[];
        element: ChartElement;
      };

  id: number;
  dashboardId: number;
  literalId: string;
  label: string | null;
  parentLayoutId: number | null;
  layoutType: ElementType;
  displayOrder: number;
  span: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  dashboardFilterId?: string;
  additionalAttributes?: AdditionalAttributes;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AdditionalAttributes = Record<string, any>;

interface ElementSubType {
  status: 'ACTIVE' | 'INACTIVE';
  rCreId: string;
  rCreTime: string;
  rModId: string;
  rModTime: string;
  id: number;
  dashboardId: number;
  dashboardLayoutId: number;
  dashboardLayoutLiteralId: string;
  type: ElementTyping;
  subType: ElementSubTyping | string;
  dashboardFieldId: string;
  dashboardFilterId: string | null;
  displayOrder: number | null;
  label: string | null;
  layoutType?: string | null;
  sourceLayoutLiteralId?: string | null;
  targetLayoutLiteralId?: string | null;
  additionalAttributes?: AdditionalAttributes;
}

type ElementType =
  | 'VIEW_DATA_POINT'
  | 'DATA_POINT'
  | 'DATA_POINT_SM'
  | 'DATA_POINT_BOUNDED'
  | 'RESULT_DATA_POINT'
  | 'DATA_SET'
  | 'DATA_CHART'
  | 'DATA_CHART_GROUP'
  | 'DATA_CHART_COMMON'
  | 'FILTER_SET'
  | 'BACK_BAR'
  | 'HEADER'
  | 'TAB_SECTION'
  | 'TITLE'
  | 'CONTEXT_BAR'
  | 'ACTION_SET'
  | 'CARD_COMPONENT_SECTION'
  | 'CARD_COMPONENT'
  | 'FILTER_SET_RADIO'
  | 'TAB'
  | 'DASHBOARD_TITLE'
  | 'HEADING'
  | 'EDITABLE_HEADING'
  | 'SUB_HEADING'
  | 'BADGE'
  | 'DASHBOARD_STATIC_TITLE'
  | 'RESULT_HEADER'
  | 'EDIT_POPUP'
  | 'TOGGLE'
  | 'DOWNLOAD_ICON'
  | 'EXPERIMENT_RESULT_DATA_SECTION'
  | 'EXPERIMENT_RESULT_COMPARISON_SECTION'
  | 'ACCORDION_SECTION'
  | 'VIEW_SAVE'
  | 'VIEW_OPERATIONS'
  | 'VIEW_OPERATION_ITEMS'
  | 'VIEW_TYPE'
  | 'VIEW_TYPE_ITEMS'
  | 'BREAD_CRUMB'
  | 'FAQ'
  | 'BASEBALL_CARD'
  | 'PRIMARY'
  | 'SECONDARY'
  | 'ALT_METRIC'
  | 'BAR_VISUALIZER'
  | 'NAVIGATION_COLUMN'
  | 'ICON_BADGE'
  | 'VERTICAL_SEPERATOR'
  | 'REFRESH_TIME'
  | 'HERO_BANNER'
  | 'COMPARE_BANNER';

type FilterSetElementType =
  | 'TOGGLE'
  | 'DOWNLOAD_ICON'
  | 'SELECT'
  | 'MULTI_SELECT'
  | 'NUMBER_INPUT_BOX'
  | 'DATE_RANGE'
  | 'VERTICAL_SEPERATOR';

type ViewElementType =
  | 'VIEW_SAVE'
  | 'VIEW_OPERATIONS'
  | 'VIEW_OPERATION_ITEMS'
  | 'VIEW_TYPE'
  | 'VIEW_TYPE_ITEMS';

type EndpointType = 'DATA_SET' | 'DATA_CHART' | 'DATA_POINT';

type DashboardDataOrCountResponseType =
  | DataResponse
  | DataSetResponse[]
  | DataSetResponse
  | IDataSetCountResponse
  | IDataSetCountResponse[];

interface IRoutedFilterFromHomePage {
  from: 'home-page';
  filters: { literalId: string; value: FilterValue }[];
  conversionTypeId?: number;
}

export {
  ALIGNMENT,
  BaseElement,
  DashboardDataOrCountResponseType,
  DefaultValue,
  EndpointType,
  ElementTyping,
  ElementSubTyping,
  ElementType,
  ElementSubType,
  FilterSetElementType,
  FbScaleResult,
  ViewElementType,
  LayoutTypes,
  IRoutedFilterFromHomePage
};
