<div
  *ngIf="headerConfig && headerData"
  class="row result-header-parent justify-content-between align-items-center {{
    headerConfig.containerStyleClass
  }}">
  <span
    class="d-flex d-block-inline"
    [ngClass]="
      headerConfig.faq || headerConfig.refreshTime ? 'col-md-6' : 'col-md-12'
    ">
    <h2
      *ngIf="headerConfig.primaryTitle?.field"
      class="DASHBOARD_TITLE_HEADING px-2"
      id="primaryTitle"
      [ngClass]="
        handleIfThereAreNoData(headerConfig.primaryTitle, 'styleClass')
      ">
      {{
        headerData[
          handleIfThereAreNoData(headerConfig.primaryTitle, "field")
        ] ||
          headerConfig.primaryTitle.defaultValue ||
          "No Data"
      }}
      {{
        headerConfig.secondaryTitles && headerConfig.secondaryTitles.length
          ? "|"
          : ""
      }}
    </h2>
    <h3
      class="px-2"
      id="secondaryTitle"
      *ngFor="let secondaryTitle of headerConfig.secondaryTitles"
      [ngClass]="handleIfThereAreNoData(secondaryTitle, 'styleClass')">
      {{
        headerData[handleIfThereAreNoData(secondaryTitle, "field")] || "No Data"
      }}
    </h3>
    <ng-container *ngIf="headerConfig.editableTitle?.field">
      <h3
        *ngIf="!isEditMode"
        [ngClass]="{
          DASHBOARD_TITLE_HEADING: i === 0,
          'd-block px-2': true,
        }">
        {{
          headerData[
            handleIfThereAreNoData(headerConfig.editableTitle, "field")
          ] ?? "No Data"
        }}
      </h3>
      <input
        *ngIf="isEditMode"
        class="editable-input px-2"
        (keyup)="onEditableHeaderKeyUp($event)"
        type="text"
        value="{{
          headerData[
            handleIfThereAreNoData(headerConfig.editableTitle, 'field')
          ] ?? 'No Data'
        }}"
        autofocus />
    </ng-container>
    <portal-badge
      [headerConfig]="headerConfig"
      [headerData]="headerData"
      [showEmptyData]="showEmptyData"
      [dashboardLiteralId]="dashboardLiteralId"></portal-badge>
  </span>
  <span
    class="col-md-6 d-flex d-block-inline justify-content-end align-items-center">
    <portal-refresh-time
      *ngIf="headerConfig.refreshTime"
      [headerConfig]="headerConfig"></portal-refresh-time>
    <p-divider
      layout="vertical"
      *ngIf="
        nativeSectionsService.shouldShowDivider(dashboardLiteralId)
      "></p-divider>
    <m-tooltip
      *ngIf="headerConfig.faq"
      content="<div class='b1 text-gray-000 text-center'>{{
        headerConfig.faq.label
      }}</div>"
      (click)="onClickFAQ(handleIfThereAreNoData(headerConfig.faq, 'field'))"
      position="bottom"
      [tooltipOptions]="{
        positionTop: 10,
        showDelay: 800,
      }">
      <m-button
        [type]="'secondary'"
        class="saas-ui-size-default"
        [attr.data-tracker]="
          this.dataTrackerService.generateKeySuffix(
            'm_button__learn_more--',
            headerData[headerConfig.primaryTitle.field] ||
              headerConfig.primaryTitle.defaultValue ||
              ''
          )
        ">
        <span class="b2 text-blue-600">Learn more</span>
      </m-button>
    </m-tooltip>
  </span>
</div>
