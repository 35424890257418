<div class="flex relative flex-container m-layout">
  <!-- Main content including Navbar -->
  <div
    class="flex-grow flex flex-col"
    [ngClass]="{ 'overflow-wrapper': hasSidebarContent }">
    <!-- Navbar -->
    @if (hasNavbarContent) {
      <div class="bg-gray-000 border-b border-gray-300 navbar">
        <div class="w-full mx-auto p-3">
          <ng-content select="[navbar-content]"></ng-content>
        </div>
      </div>
    }

    <!-- Main Content -->
    <div class="flex-grow bg-gray-100">
      <div class="mx-auto p-4 pb-0">
        <ng-content select="[main-content]"></ng-content>
      </div>
    </div>
  </div>

  <!-- Sidebar (Fixed) -->
  @if (hasSidebarContent) {
    <div
      class="bg-gray-300 flex-shrink-0 sidebar fixed right-0 h-screen overflow-y-auto p-4">
      <ng-content select="[sidebar-content]"></ng-content>
    </div>
  }
</div>
