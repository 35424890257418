import { Component, Input } from '@angular/core';

@Component({
  selector: 'm-metric-amount-header',
  templateUrl: './m-metric-amount-header.component.html',
  styleUrls: ['./m-metric-amount-header.component.scss']
})
export class MMetricAmountHeaderComponent {
  @Input() title = '';
  @Input() amount = '';
  @Input() prevPercDifference = 0;
  @Input() prevPercDifferenceFormattedValue: string | null = null;
  @Input() isPositive = false;
  @Input() isNeutral = false;
  @Input() isUp = false;
}
