import { ICellConfig } from './ICellConfig';
import { IClient } from '../IClient';

export interface ICPAdStudyConfig {
  brandId: number;
  budgetScale: number;
  clientId: number;
  client: IClient;
  conversionTypeId: number;
  timeToConversion: number;
  geoSplitLevel: string;
  geoScopeIds: number[];
  studyId: number;
  studyName: string;
  studyState: { studyId: number; rModTime: string } | null;
  startDate: string;
  endDate: string;
  reportStartDate: string;
  reportEndDate: string;
  preExptStartTime: string;
  preExptEndTime: string;
  exptRefStartTime: string;
  exptRefEndTime: string;
  splitType: string;
  status: string;
  rCreId: string;
  rCreTime: string;
  rModId: string;
  rModTime: string;
  cellConfigs: ICellConfig[];
  marketSelectionExecutionId: string;
  dryRunRequired: number;
  dryRunSucceeded: string;
  inventoryRefStartDate: string;
  inventoryRefEndDate: string;
  unapprovedGeoIds: number[];
  parentId: number | null;
  reportingConfigChanges: string | null;
  reportingConfigChangeNote: string;
  disableSync: 0 | 1;
  hasPreExpDates: number;
  disableSyncMessage: string | null;
  disableSyncBy: string | null;
  disableSyncTimestamp: string | Date | null;
  approvedGeos: string | null;
  cellOptions: { label: string; value: ICellConfig }[];
  recommendationResult: string;
  designerVersion: 1 | 2;
  lastScheduledDesignerVersion?: 1 | 2 | null;
  isStudyWithVendorRemoval: boolean;
  displayStatus?: string;
}

export enum RecommendationResult {
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
  TACTIC_NOT_FOUND = 'TACTIC_NOT_FOUND',
  SUCCESS = 'SUCCESS',
  REJECTED = 'REJECTED',
  CUSTOMISED = 'CUSTOMISED',
  MS_EXPIRED = 'MS_EXPIRED'
}
