import { Observable, Subscription, take } from 'rxjs';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
  ContextField,
  DataGridConfig,
  DataGridElement,
  DataResponse,
  DataSetResponse,
  ElementGroup,
  FieldDefinitions,
  Filter,
  WidgetType
} from '@portal/app/shared/types';
import { ContextStore } from '@portal/app/shared/state/context.store';
import { Required } from '@portal/app/shared/decorators/required.decorator';
import { ViewStore } from '@portal/app/shared/state/view.store';
import { cloneDeep, find } from 'lodash-es';
import { DataGridStore } from '@portal/app/datagrid/store/data-grid.store';
import { forEach } from 'lodash-es';
import { FiltersService } from '@portal/app/shared/services/filters.service';
import { NativeSectionsService } from '@portal/app/shared/services/native-sections.service';
import { FormatterService } from '@portal/app/shared/services/formatter.service';
import { isCrossChannelDashboards } from '@portal/app/shared/constants/constants';

@Component({
  selector: 'portal-data-grid-group',
  templateUrl: './data-grid-group.component.html'
})
export class DataGridGroupComponent implements OnInit, OnDestroy {
  //TODO: Below fields are in scope of a layout. Can have a layout context to manage these fields.
  @Input() @Required widgetLayout!: ElementGroup;
  @Input() @Required fieldDefinitions!: FieldDefinitions;
  @Input() view = '';
  @Input() filterSet: Filter[] = [];
  @Input() data: DataSetResponse[] = [];
  @Input() groupByRowFooter: Record<string, DataResponse> = {};
  @Input() dataGridConfig: DataGridConfig =
    this.dataGridStore.getDataGridTableConfig();

  @Input() showChildSection: boolean | null = null;
  @Input() filterControls: ContextField[] = [];

  isCrossChannelDashboards = isCrossChannelDashboards;

  group!: ElementGroup;
  datasetFilters: Filter[] = [];
  literalId = '';
  productId = '';
  componentListen: string[] = [];
  breakdownFiltersPersistanceData: Filter[] = [];

  public selectedElements: DataGridElement[] = [];
  public tableTypes: Record<string, string> = {};
  private readonly elementSelectorFilterIds: string[] = ['dataSets'];
  private readonly subs: Subscription = new Subscription();

  filterContext$: Observable<Filter[]> = this.contextStore.filterContext;

  constructor(
    private readonly contextStore: ContextStore,
    private readonly viewStore: ViewStore,
    private readonly dataGridStore: DataGridStore,
    private readonly filtersService: FiltersService,
    public readonly nativeSectionsService: NativeSectionsService,
    public formatterService: FormatterService
  ) {}

  ngOnInit(): void {
    this.viewStore.selectedBCPDId.pipe(take(1)).subscribe((value) => {
      this.productId = value.productId;
      this.literalId = value.literalId;
      this.dataGridConfig = this.dataGridStore.getDataGridTableConfig(
        this.literalId
      );
    });
    this.group = cloneDeep(this.widgetLayout);
    this.getView();
    // if related components exist, set listening component(s) name(s)
    if (this.group.elements[0]?.RELATED_WIDGET) {
      this.componentListen = Object.keys(this.group.elements[0].RELATED_WIDGET);
      this.contextStore.listeningComponentName = this.componentListen;
    }
    const sub = this.filterContext$
      .pipe(take(1))
      .subscribe(this.loadInitialConfig.bind(this));
    this.subs.add(sub);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  loadInitialConfig(filterSet: Filter[]) {
    const filters = cloneDeep(filterSet);
    this.filterSet = filters;
    this.datasetFilters = filters.filter(
      (f) => f.applyTo && f.applyTo.includes(WidgetType.table)
    );

    this.selectedElements = this.setSelectedElements(
      this.filterControls,
      this.elementSelectorFilterIds,
      this.group
    );
    this.tableTypes = this.setTableType(this.tableTypes, this.selectedElements);
  }

  setGlobalFilterValues() {
    this.selectedElements = this.setSelectedElements(
      this.filterControls,
      this.elementSelectorFilterIds,
      this.group
    );
    this.tableTypes = this.setTableType(this.tableTypes, this.selectedElements);
  }

  // when persistent filters change in datagrid track them here
  updateConfigForFilterPersistance(updatedFilter: Filter) {
    const filterFound = this.breakdownFiltersPersistanceData.find(
      (filter) => filter.literalId === updatedFilter.literalId
    );
    if (filterFound) {
      filterFound.value = updatedFilter.value;
    } else {
      this.breakdownFiltersPersistanceData.push(updatedFilter);
    }
  }

  private setSelectedElements(
    filterControls: ContextField[],
    elementFilter: string[],
    group: ElementGroup
  ): DataGridElement[] {
    const selectDataFilter = filterControls.filter((control) =>
      elementFilter.includes(control.name)
    );
    let reassignableSelectedElements: DataGridElement[];
    // TODO: Sort elements by display order
    if (selectDataFilter.length > 0) {
      reassignableSelectedElements = selectDataFilter
        .flatMap((control) => control.value)
        .flatMap((value) =>
          group.elements.find((e) => e.literalId === value)
        ) as DataGridElement[];
    } else {
      reassignableSelectedElements = group.elements as DataGridElement[];
    }
    return reassignableSelectedElements;
  }

  private setTableType(
    tableTypes: Record<string, string> = {},
    selectedElements: DataGridElement[]
  ): Record<string, string> {
    forEach(selectedElements, (element) => {
      tableTypes[element.literalId] = 'SIMPLE';
      forEach(element?.available, (availableColumn) => {
        if (availableColumn.type === 'PIVOT_DIMENSION') {
          tableTypes[element.literalId] = 'PIVOT';
        }
      });
    });
    return tableTypes;
  }

  getView() {
    const sub = this.filterContext$.subscribe({
      next: (filters) => {
        const viewByFilter = find(
          filters,
          (filterObj) => filterObj.literalId === 'viewBy'
        );
        const isSwitchableView = find(
          this.widgetLayout.elements,
          (filterObj) => filterObj.literalId === viewByFilter?.value
        );
        if (isSwitchableView) {
          if (this.group) {
            const widgetLayout = cloneDeep(this.widgetLayout);
            this.group.elements = widgetLayout.elements.filter(
              (element) => element.literalId === viewByFilter?.value
            );
          }
        }
        this.loadInitialConfig(filters);
      }
    });
    this.subs.add(sub);
  }
}
