@if (chartData && geoPreAndPostExpChartData.length > 0 && groupByRowFooter) {
  <div class="row experiment-chart-table chart-data-table pt-3">
    @if (chartData) {
      <portal-data-grid-group
        [widgetLayout]="tableWidget"
        [fieldDefinitions]="fieldDefinitions"
        [data]="geoPreAndPostExpChartData"
        [dataGridConfig]="dataGridConfig"
        [groupByRowFooter]="groupByRowFooter">
      </portal-data-grid-group>
    }
  </div>
}
