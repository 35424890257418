<div class="container">
  <dl class="row mt-5">
    <dt class="col-md-2">User</dt>
    <dd class="col-md-10">{{ username }}</dd>
    <dt class="col-md-2">Password</dt>
    <dd class="col-md-2">********</dd>
    <dd class="col-md-7">
      <button class="btn btn-secondary" (click)="changePassword()">
        Change Password
      </button>
    </dd>
    <dt class="col-md-2">Company</dt>
    <dd class="col-md-10">{{ client }}</dd>
    <dt class="col-md-2">Role</dt>
    <dd class="col-md-10">{{ role }}</dd>
  </dl>
</div>
