import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  from,
  of,
  distinctUntilChanged
} from 'rxjs';
import {
  mergeMap,
  toArray,
  catchError,
  switchMap,
  debounceTime,
  takeUntil,
  map,
  tap
} from 'rxjs/operators';

// Services
import { ErrorHandlingService } from '@portal/app/dashboard/integrations/services/error-handling.service';
import { FieldService } from '@portal/app/shared/services/field.service';
import { FormatService } from '@portal/app/dashboard/home-page/services/format.service';
import { GeoRecommendationsService } from '@portal/app/geo-designer/services/geo-recommendations.service';
import { GeoTestsService } from '@portal/app/geo-designer/services/geo-tests.service';
import { HomepageService } from '@portal/app/dashboard/home-page/services/home-page.service';
import { HeaderService } from '@portal/app/dashboard/home-page/components/header/header.service';

// Types
import { GeoCellDisplayStatus } from '@portal/app/geo-designer/models/ICPAdTestConfig';
import type { IConversionType } from '@portal/app/dashboard/geo-doe-config/models';
import type { IGeoTest, IRecommendedTest } from '@libs/apis';
import type { RecommendedTest, MyTest } from './geo.types';
import type { FilterValue } from '@portal/app/shared/types';

@Injectable({
  providedIn: 'root'
})
export class GeoService implements OnDestroy {
  // conversion types
  private conversionTypes: IConversionType[] = [];
  private selectedConversionType: FilterValue = '';

  // recommended tests observable
  private recommendedTestsSubject = new BehaviorSubject<RecommendedTest[]>([]);
  public recommendedTests$ = this.recommendedTestsSubject.asObservable();

  // my tests observable
  private myTestsSubject = new BehaviorSubject<MyTest[]>([]);
  public myTests$ = this.myTestsSubject.asObservable();

  // Cleanup
  private destroy$ = new Subject<void>();

  // Loading state
  private isLoadingSubject = new BehaviorSubject<boolean>(true);
  public isLoading$ = this.isLoadingSubject.asObservable();

  constructor(
    private readonly errorHandlingService: ErrorHandlingService,
    private readonly formatService: FormatService,
    private readonly fieldService: FieldService,
    private readonly geoRecommendationsService: GeoRecommendationsService,
    private readonly geoTestsService: GeoTestsService,
    private readonly homepageService: HomepageService,
    private readonly headerService: HeaderService
  ) {
    // Subscribe to data changes
    this.subscribeToChanges();
  }

  private subscribeToChanges(): void {
    this.homepageService.conversionTypes$
      .pipe(
        debounceTime(300),
        tap((conversionTypes) => {
          this.conversionTypes = conversionTypes;
          this.isLoadingSubject.next(true);
          if (this.conversionTypes.length) {
            this.fetchAndFormatRecommendedTests();
          }
        }),
        switchMap(() => {
          return this.fetchAndFormatMyTests();
        }),
        catchError((error) => {
          this.errorHandlingService.logError('GeoService error: ' + error);
          this.isLoadingSubject.next(false);
          return of([] as MyTest[]);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((allGeoTests) => {
        this.myTestsSubject.next(allGeoTests);
        this.isLoadingSubject.next(false);
      });
  }

  private getConversionTypeById(conversionTypeId: number) {
    return this.conversionTypes.find((type) => type.id === conversionTypeId);
  }

  private fetchAndFormatRecommendedTests() {
    this.headerService.selectedConversionType$
      .pipe(
        distinctUntilChanged(),
        tap(() => this.isLoadingSubject.next(true)),
        switchMap((type) => {
          this.selectedConversionType = type;
          const conversionTypeId = this.getConversionTypeId() || 0;
          return this.geoRecommendationsService.getRecommendedTestsForConversionType(
            conversionTypeId
          );
        }),
        mergeMap((recommendedTests) =>
          this.formatRecommendedTests(recommendedTests)
        ),
        catchError((error) => {
          this.errorHandlingService.logError(
            'fetchAndFormatRecommendedTests error: ' + error
          );

          return of([] as RecommendedTest[]);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((recommendedTests) => {
        this.recommendedTestsSubject.next(recommendedTests.slice(0, 3));
        this.isLoadingSubject.next(false);
      });
  }

  private getConversionTypeId() {
    return this.conversionTypes.find(
      (type) => type.conversionType === this.selectedConversionType
    )?.id;
  }

  private fetchAndFormatMyTests(): Observable<MyTest[]> {
    return this.geoTestsService.getGeoTests$.pipe(
      mergeMap((allGeoTests) => this.formatMyTests(from([allGeoTests]))),
      catchError((error) => {
        this.errorHandlingService.logError(
          'fetchAndFormatMyTests error: ' + error
        );

        return of([]);
      })
    );
  }

  private formatMyTests(
    myTests$: Observable<IGeoTest[]>
  ): Observable<MyTest[]> {
    return myTests$.pipe(
      map((myTests) =>
        myTests.reduce(
          (acc, test) => {
            const status = test.geoStudy.displayStatus ?? 'none';
            if (!acc[status]) {
              acc[status] = {
                status,
                count: 0,
                color: this.getStatusColor(status)
              };
            }
            (acc[status] as MyTest).count += 1;
            return acc;
          },
          {} as Record<string, MyTest>
        )
      ),
      map((statusCounts) => Object.values(statusCounts)),
      catchError((error) => {
        this.errorHandlingService.logError('formatMyTests error: ' + error);
        return of([]);
      })
    );
  }

  private getStatusColor(status: string): string {
    switch (status) {
      case GeoCellDisplayStatus.inProgress:
      case GeoCellDisplayStatus.finished:
        return 'text-green-700';
      case GeoCellDisplayStatus.actionNeeded:
        return 'text-red-500';
      case GeoCellDisplayStatus.scheduled:
        return 'text-orange-200';
      default:
        return 'text-gray-700';
    }
  }

  private formatRecommendedTests(
    recommendedTests: IRecommendedTest[]
  ): Observable<RecommendedTest[]> {
    return from(recommendedTests).pipe(
      mergeMap((test) => {
        // Directly retrieve and use field definitions for formatting
        const spendFieldDefinition =
          this.fieldService.getFieldDefinitionByLiteralId('mediaSpend');
        const contributionFieldDefinition =
          this.fieldService.getFieldDefinitionByLiteralId('percOrdersI');

        // Get conversoin type by id
        const conversionType = this.getConversionTypeById(
          test.conversionTypeId
        );
        return of({
          contribution: this.formatService.formatValue(
            contributionFieldDefinition,
            test?.percOrdersI ?? 0
          ),
          conversionType: conversionType?.conversionType ?? '',
          conversionTypeId: conversionType?.id ?? 0,
          spend: this.formatService.formatValue(
            spendFieldDefinition,
            test?.mediaSpend ?? 0
          ),
          name: test.name
        });
      }),
      toArray(),
      catchError((error) => {
        this.errorHandlingService.logError(
          'formatRecommendedTests error: ' + error
        );

        return of([]);
      })
    );
  }

  // Handles cleanup when the service is destroyed
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
