<div *ngIf="showChild" class="back mb-4 px-2">
  <a href="#" (click)="navigateToParentPage($event)">
    <span class="pi pi-chevron-circle-left me-2"></span>
    {{ planName }}
  </a>
</div>
<div *ngIf="item && item.elements" class="d-flex flex-wrap">
  <ng-container *ngFor="let element of item.elements">
    <ng-container [ngSwitch]="element.layoutType">
      <portal-view-controls
        data-html2canvas-ignore="true"
        *ngSwitchCase="'VIEW_CONTROLS'"
        [item]="element"
        [id]="id"
        [view]="view"
        [fieldDefinitions]="fieldDefinitions"
        [ngClass]="element.span ? 'col-md-' + element.span : ''">
      </portal-view-controls>
      <portal-native-sections-template
        *ngSwitchCase="'RESULT_HEADER'"
        [item]="element"
        [ngClass]="element.span ? 'col-md-' + element.span : ''"
        [fieldDefinitions]="fieldDefinitions"
        [dataPoints]="dataPoints"
        [isEditMode]="isViewTitleEditMode"
        [showEmptyData]="false"
        [allowFetchDataPoints]="false"
        (enterKeyClicked)="onEnterClickedForTitle()"
        (editableHeaderValue)="onEditableHeaderValue($event)">
      </portal-native-sections-template>
      <ng-container *ngIf="!showChild">
        <ng-container *ngSwitchCase="'SAVED_TIME'">
          <div data-html2canvas-ignore="true" class="col-12 saved-time">
            <p class="saved-time-text">
              <span class="time-label">Last time saved: </span>
              <span>{{ lastTimeSaved }}</span>
            </p>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
