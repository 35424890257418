import dayjs, { Dayjs } from 'dayjs';

export enum SideEnum {
  left = 'left',
  right = 'right'
}

export type DateRanges = Record<string, [Dayjs, Dayjs]>;

export interface DateRange {
  label: string;
  dates: [Dayjs, Dayjs];
}

export interface ChosenDate {
  chosenLabel: string;
  startDate: Dayjs | undefined;
  endDate: Dayjs | undefined;
}

export interface TimePeriod {
  [index: string]: Dayjs | undefined;

  startDate: Dayjs | undefined;
  endDate: Dayjs | undefined;
}

export interface StartDate {
  startDate: Dayjs | undefined;
}

export interface EndDate {
  endDate: Dayjs | undefined;
}

export interface TimePickerVariables {
  secondsLabel: string[];
  selectedMinute: number;
  selectedSecond: number;
  hours: number[];
  seconds: number[];
  disabledHours: number[];
  disabledMinutes: number[];
  minutes: number[];
  minutesLabel: string[];
  selectedHour: number;
  disabledSeconds: number[];
  amDisabled?: boolean;
  pmDisabled?: boolean;
  ampmModel?: string;
  selected: Dayjs;
}

export type TimePickerVariablesHolder = Record<string, TimePickerVariables>;

export interface CalendarRowClasses {
  [index: number]: string;

  classList: string;
}

export type CalendarClasses = Record<number, CalendarRowClasses>;

interface Dropdowns {
  inMaxYear: boolean;
  yearArrays: number[];
  maxYear: number;
  minYear: number;
  currentMonth: number;
  inMinYear: boolean;
  monthArrays: number[];
  currentYear: number;
}

export type CalendarArrayWithProps<T> = T[] & {
  firstDay?: Dayjs;
  lastDay?: Dayjs;
};

export interface CalendarVariables {
  calRows: (0 | 1 | 2 | 3 | 4 | 5)[];
  calCols: (0 | 1 | 2 | 3 | 4 | 5 | 6)[];
  calendar: CalendarArrayWithProps<Dayjs[]>;
  minDate?: dayjs.Dayjs;
  year: number;
  classes: CalendarClasses;
  lastMonth: number;
  minute: number;
  second: number;
  daysInMonth: number;
  dayOfWeek: number;
  month: number;
  hour: number;
  firstDay: dayjs.Dayjs;
  lastYear: number;
  lastDay: dayjs.Dayjs;
  maxDate?: dayjs.Dayjs;
  daysInLastMonth: number;
  dropdowns?: Dropdowns;
}

export type CalendarVariableHolder = Record<string, CalendarVariables>;

export interface VisibleCalendar {
  month: Dayjs | null;
  calendar: CalendarArrayWithProps<Dayjs[]>;
}

export enum BulkSelectionMode {
  daily = 'Daily',
  weekly = 'Weekly',
  monthly = 'Monthly',
  custom = 'Custom'
}

export type DayJsCalendarUnit =
  | 'day'
  | 'days'
  | 'week'
  | 'weeks'
  | 'month'
  | 'months';
