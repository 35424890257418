<m-card
  [isExpandable]="false"
  [noShadow]="true"
  [variant]="cardVariant.compact"
  class="h-full">
  <div>
    <div class="sh2 text-gray-1000 mb-3 flex items-center">
      <span [innerHTML]="card.title"></span>
      @if (card.titleIcon) {
        <i
          class="material-symbols-outlined icon-small icon-filled text-orange-200 ml-2">
          {{ card.titleIcon }}</i
        >
      }
    </div>
    @if (card.description) {
      <div class="b1 text-gray-800 mb-3" [innerHTML]="card.description"></div>
    }
  </div>
  <div>
    <ng-content></ng-content>
    <div class="m-divider two-tone my-3"></div>
    <div class="flex justify-center">
      <m-button type="secondary" (click)="onButtonAction()">{{
        card.buttonText
      }}</m-button>
    </div>
  </div>
</m-card>
