import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  DataEndpoint,
  DataResponse,
  ElementGroup,
  FieldDefinitions,
  Filter,
  MetricCardElement
} from '@portal/app/shared/types';
import { NativeSectionsService } from '@portal/app/shared/services/native-sections.service';
import { DashboardConfigHeaderTranslationService } from '@portal/app/shared/services/native-translation-service/header/dashboard-config-header-translation.service';
import { BackBarLayoutConfigTranslationService } from '@portal/app/shared/services/native-translation-service/back-bar/back-bar-layout-config-translation.service';
import { ViewStore } from '@portal/app/shared/state/view.store';
import { Observable, Subscription, startWith, take } from 'rxjs';
import { ApiService } from '@portal/app/shared/services/api.service';
import { ViewService } from '@portal/app/shared/services/view.service';
import { TitleConfigTranslationService } from '@portal/app/shared/services/native-translation-service/title/title-config-translation.service';
import { FormatterService } from '@portal/app/shared/services/formatter.service';
import { ContextStore } from '@portal/app/shared/state/context.store';
import { MultiViewStore } from '@portal/app/shared/state/multi-view.store';
import { IHeaderConfig } from '@portal/app/shared/models/IHeaderConfig';
import { AppState } from '@portal/app/store/app.state';
import { Store } from '@ngrx/store';
import { selectStore } from '@portal/app/store/app.selectors';

@Component({
  selector: 'portal-native-sections-template',
  templateUrl: './native-sections.component.html'
})
export class NativeSectionsComponent implements OnInit, OnDestroy {
  @Input() item!: ElementGroup;
  @Input() fieldDefinitions: FieldDefinitions = {};
  @Input() filters: Filter[] = [];
  @Input() dataPoints: DataResponse = {};
  @Input() isEditMode = false;
  @Input() showEmptyData: boolean | undefined;
  @Input() allowFetchDataPoints = true;
  @Output() editableHeaderValue = new EventEmitter<string>();
  @Output() enterKeyClicked = new EventEmitter<boolean>();

  literalId = '';
  productId = '';
  public brandId = 0;
  headerConfig!: IHeaderConfig;
  private viewId?: number;
  private filterContext$: Observable<Filter[]> =
    this.contextStore.filterContext;

  public subscriptions: Subscription[] = [];
  constructor(
    public readonly nativeSectionsService: NativeSectionsService,
    public readonly dashboardConfigHeaderTranslationService: DashboardConfigHeaderTranslationService,
    public readonly titleConfigTranslationService: TitleConfigTranslationService,
    public readonly backBarLayoutConfigTranslationService: BackBarLayoutConfigTranslationService,
    private readonly viewStore: ViewStore,
    private readonly apiService: ApiService,
    private readonly viewService: ViewService,
    protected readonly contextStore: ContextStore,
    protected readonly formatterService: FormatterService,
    private multiViewStore: MultiViewStore,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.viewStore.selectedBCPDId.pipe(take(1)).subscribe((value) => {
      this.literalId = value.literalId;
      this.productId = value.productId;
      this.brandId = value.brandId;
    });
    this.multiViewStore.currentView$.subscribe((currentView) => {
      this.viewId = currentView ? Number(currentView.viewId) : undefined;
    });
    if (
      this.allowFetchDataPoints &&
      this.nativeSectionsService.fetchDataPointsBasedOnLayoutType(
        this.item.layoutType
      )
    ) {
      this.getDataPoints();
    }

    this.store
      .select(selectStore)
      .pipe(startWith(null))
      .subscribe((state) => {
        this.headerConfig =
          this.dashboardConfigHeaderTranslationService.prepareHeaderConfig(
            state,
            this.fieldDefinitions,
            this.literalId,
            this.item
          ) as IHeaderConfig;
      });

    // Update field definitions using the modified field definitions from NativeSectionsService
    this.fieldDefinitions =
      this.nativeSectionsService.getModifiedFieldDefinitions(
        this.literalId,
        this.filters,
        this.fieldDefinitions
      );
  }

  getDataPoints(): void {
    this.filterContext$.pipe(take(1)).subscribe({
      next: (filters) => {
        const updatedFilters = this.resetDataPoints(filters);
        const dataPromise = this.apiService.getDashboardData({
          filters: updatedFilters,
          type: DataEndpoint.dataPoints,
          viewId: this.viewId
        }) as Promise<DataResponse>;
        dataPromise
          .then((data) => {
            this.dataPoints = this.formatterService.formatDataPartially(
              this.fieldDefinitions,
              [data]
            )[0] as DataResponse;
          })
          .catch(() => {
            this.dataPoints = {};
          });
      }
    });
  }

  resetDataPoints(filters: Filter[]): Filter[] {
    return filters.map((filter) => {
      if (filter.literalId === 'dataPoints') {
        filter.value = [];
      }
      return filter;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  back(): void {
    this.nativeSectionsService.nativeDashboardBack(this.literalId);
  }

  onEditableHeaderValue(val: string) {
    this.editableHeaderValue.emit(val);
  }

  onEnterClicked(val: boolean) {
    this.enterKeyClicked.emit(val);
  }

  get metricCardItems(): MetricCardElement[] {
    return this.item.elements as MetricCardElement[];
  }
}
