<m-card
  class="h-100 cursor-pointer"
  [attr.data-tracker]="
    metric?.title
      ? 'm-card__' + metric.title.toLowerCase().split(' ').join('-')
      : null
  "
  [hoverable]="true"
  (click)="onClick()">
  <div header class="flex justify-between items-center w-full">
    <span class="sh2">{{ metric?.title }}</span>

    <m-tag
      [isPositive]="metric?.isPositive"
      [isNeutral]="metric?.isNeutral"
      [isUp]="metric?.isUp"
      >{{ metric?.prevPercDifference / 100 | percent: "1.2-2" }}</m-tag
    >
  </div>
  @if (metric?.hasError) {
    <div class="c1 text-gray-700 mt-1">Not Available</div>
  }
  @if (!metric?.hasError) {
    <div>
      <div class="mt-2 flex items-center">
        <span class="h3 text-gray-1000 m-0 mr-2">{{
          metric?.formattedValue
        }}</span>
      </div>
      @if (metric?.adPlatform) {
        <div class="mt-2">
          <m-type-pill
            size="small"
            text="{{ metric?.adPlatform }} (Ad Platform)"></m-type-pill>
        </div>
      }
    </div>
  }
</m-card>
