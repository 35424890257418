// Import necessary dependencies
import { colors } from '@design-system/styles/colors';
import { Options } from 'highcharts';

// Marker styles for all charts
export const markerStyles = {
  enabled: false,
  radius: 2,
  fillColor: colors['gray-1000'],
  lineWidth: 1,
  lineColor: colors['gray-000'],
  symbol: 'circle',
  states: {
    hover: {
      enabled: true
    }
  }
};

// Base chart configuration (shared presentation logic only)
export const baseChartConfig: Options = {
  title: {
    text: ''
  },
  credits: {
    enabled: false
  },
  xAxis: {
    lineColor: colors['gray-300'],
    labels: {
      style: {
        opacity: 1
      }
    }
  },
  yAxis: {
    title: {
      text: ''
    },
    endOnTick: false,
    gridLineWidth: 0, // Hide all horizontal grid lines
    minorGridLineWidth: 0, // Hide minor grid lines
    labels: {
      style: {
        opacity: 1
      }
    }
  },
  plotOptions: {
    series: {
      lineWidth: 1, // Set series line width to 1
      pointPlacement: 'on',
      marker: markerStyles,
      states: {
        hover: {
          halo: {
            size: 0
          }
        }
      }
    }
  },
  tooltip: {
    shared: true,
    useHTML: true
  },
  legend: {
    layout: 'horizontal',
    align: 'center',
    verticalAlign: 'top',
    symbolHeight: 0,
    symbolWidth: 0,
    symbolRadius: 0,
    useHTML: true,
    labelFormatter(): string {
      return ` <span class="flex items-center">
        <span class="inline-block w-2 h-2 mr-1 rounded-full" style="background-color: ${this.color};"></span>
        <span class="c1 text-gray-800 block z-0">${this.name}</span>
      </span>`;
    }
  },
  responsive: {
    rules: [] // An empty configuration ensures the property is defined
  }
};
