import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Chip } from 'primeng/chip';

@Component({
  selector: 'm-chip',
  templateUrl: './m-chip.component.html',
  styleUrls: ['./m-chip.component.scss']
})
export class MChipComponent extends Chip implements OnInit {
  @Input() disabled = false;
  @Input() value: string | null = null;
  @Output() remove = new EventEmitter<string | null>();

  constructor() {
    super();
  }

  ngOnInit() {
    this.value = this.value || this.label || null;
  }

  removeChip() {
    if (!this.disabled) {
      this.remove.emit(this.label || this.value);
    }
  }
}
