import { Injectable } from '@angular/core';
import {
  DataGridConfig,
  DataGridSortMode,
  Filter
} from '@portal/app/shared/types';
import { cloneDeep } from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class DataGridStore {
  private static readonly dataGridConfigMap: Record<
    string,
    Partial<DataGridConfig>
  > = {
    ['cross-channel']: {
      haveVirtualScroll: true,
      striped: false,
      tableScrollHeightClass: 'short'
    },
    ['cross-channel-beta']: {
      haveVirtualScroll: true,
      striped: false,
      tableScrollHeightClass: 'short'
    },
    ['cross-channel-legacy']: {
      haveVirtualScroll: true,
      striped: false,
      tableScrollHeightClass: 'short'
    },
    ['geo-experiment-result']: {
      haveVirtualScroll: false,
      striped: false,
      tableScrollHeightClass: 'tall',
      showFooter: false
    },
    ['ddi-reporting-revenue']: {
      striped: false
    },
    ['ddi-reporting-conversion']: {
      striped: false
    },
    ['fb-doe-conversion']: {
      striped: false
    },
    ['fb-doe-revenue']: {
      striped: false
    }
  };

  public static virtualScrollItemSizeWithoutCompare = 54;
  public static virtualScrollItemSizeWithCompare = 60;
  public static metricColumnWidth = 6.75;
  public static defaultDimensionColumnWidth = 14;
  public static widthSpecificToFieldsUnit = 'rem';
  public static dimensionWidthSpecification: Record<
    string,
    { width: number; maxWidth: number }
  > = {
    channel: {
      width: 8,
      maxWidth: 10
    },
    segment: {
      width: 10,
      maxWidth: 15
    },
    tactic: {
      width: 15,
      maxWidth: 25
    },
    campaign: {
      width: 25,
      maxWidth: 25
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    adset_name: {
      width: 25,
      maxWidth: 25
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    inc_data_source: {
      width: 13,
      maxWidth: 13
    },
    percSalesI: {
      width: 8,
      maxWidth: 8
    },
    indexPosition: {
      width: 20,
      maxWidth: 20
    },
    weekPercPenetration: {
      width: 15,
      maxWidth: 15
    },
    weeklyFreqTest: {
      width: 15,
      maxWidth: 15
    },
    convLT: {
      width: 15,
      maxWidth: 15
    },
    weeklyReachTest: {
      width: 15,
      maxWidth: 15
    },
    weeklyCpmrTest: {
      width: 13,
      maxWidth: 13
    },
    weeklyCpmrCtrl: {
      width: 13,
      maxWidth: 13
    },
    percIncLB: {
      width: 13,
      maxWidth: 13
    },
    percIncUB: {
      width: 13,
      maxWidth: 13
    },
    bidStrategy: {
      width: 15,
      maxWidth: 15
    },
    objective: {
      width: 15,
      maxWidth: 15
    },
    roasI: {
      width: 8,
      maxWidth: 8
    },
    roasLT: {
      width: 8,
      maxWidth: 8
    },
    cpoI: {
      width: 8,
      maxWidth: 8
    },
    cpoLT: {
      width: 8,
      maxWidth: 8
    },
    percInc: {
      width: 7,
      maxWidth: 11
    },
    percOrdersI: {
      width: 9,
      maxWidth: 9
    },
    salesLT: {
      width: 10,
      maxWidth: 10
    },
    salesI: {
      width: 10,
      maxWidth: 10
    },
    totalSpend: {
      width: 9,
      maxWidth: 9
    },
    ordersLT: {
      width: 9,
      maxWidth: 9
    },
    ordersI: {
      width: 9,
      maxWidth: 9
    },
    cpm: {
      width: 9,
      maxWidth: 9
    },
    cpc: {
      width: 9,
      maxWidth: 9
    },
    mediaSpend: {
      width: 10,
      maxWidth: 10
    }
  };

  private virtualScrollItemSize =
    DataGridStore.virtualScrollItemSizeWithoutCompare;

  private virtualScrollEnableOnCount = 10;
  private metricControlFilter?: Filter;

  private dimensionControlFilter?: Filter;

  private readonly defaultTableConfig: DataGridConfig = {
    showFooter: true,
    exportShowFooter: true,
    striped: true,
    scrollable: true,
    tableScrollHeightClass: 'tall',
    tableScrollHeightInRem: '45rem',
    scrollDirection: 'both',
    customSort: true,
    showClearFiltersButton: true,
    haveVirtualScroll: false,
    virtualScrollItemSize: this.virtualScrollItemSize,
    virtualScrollEnableOnCount: this.virtualScrollEnableOnCount,
    frozenColumnsEnabled: false,
    isResizable: true,
    dashboardSortMode: DataGridSortMode.single
  };

  get dimensionMetricControl(): Filter[] {
    if (this.metricControlFilter && this.dimensionControlFilter) {
      return [this.metricControlFilter, this.dimensionControlFilter];
    } else if (this.metricControlFilter) {
      return [this.metricControlFilter];
    } else if (this.dimensionControlFilter) {
      return [this.dimensionControlFilter];
    }
    return [];
  }

  set metricControlValue(filter: Filter | undefined) {
    this.metricControlFilter = filter;
  }

  set dimensionControlValue(filter: Filter | undefined) {
    this.dimensionControlFilter = filter;
  }

  getDataGridTableConfig(literalId?: string): DataGridConfig {
    const config = cloneDeep(this.defaultTableConfig);
    return {
      ...config,
      ...(DataGridStore.dataGridConfigMap[literalId || ''] || {})
    };
  }
}
