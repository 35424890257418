@if (value) {
  <span
    class="previous-period-value icon"
    [ngClass]="[
      isUp ? 'up' : 'down',
      alignment === 'left' ? 'left' : 'right',
      value === '0.00%' ? 'zero' : '',
    ]">
    <span [ngClass]="[value === '0.00%' ? 'zero' : '']">
      {{ value }}
    </span>
    <span
      [ngClass]="['pi', isPositive ? 'pi-arrow-up' : 'pi-arrow-down']"></span>
  </span>
} @else {
  n/a
}
