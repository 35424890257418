import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, firstValueFrom, throwError } from 'rxjs';
import {
  AssociatedFilterValue,
  AssociatedFilterValues,
  CurrentView,
  DataEndpoint,
  DataResponse,
  Filter,
  FilterValue,
  PortalEventCategory,
  ViewOperations,
  ViewResponse,
  ViewSaveState,
  ViewType
} from '@portal/app/shared/types';
import { ApiService } from '@portal/app/shared/services/api.service';
import { NativeSectionsService } from '@portal/app/shared/services/native-sections.service';
import { ViewUtilityService } from '@portal/app/shared/services/view-utility.service';
import { ContextStore } from '@portal/app/shared/state/context.store';
import { cloneDeep, pick } from 'lodash-es';
import dayjs from 'dayjs';
import { ViewStore } from './view.store';
import { customRangeValue } from '@libs/date-range-picker';
import { AssociatedFilterService } from '@portal/app/shared/services/associated-filter.service';

@Injectable({
  providedIn: 'root'
})
export class MultiViewStore {
  private static compareProps = ['id', 'literalId', 'value'];

  public rowLevelFilterSeparator = '||';
  // Use getters and setters to access variables suffixed with Primary
  private dashboardFiltersPrimary: Filter[] = [];
  private dashboardFiltersWithDefaultsPrimary: Filter[] = [];
  private currViewFiltersWithDefaultsPrimary: Filter[] = [];

  private defaultReferencePeriod: string | undefined;
  private title = '';
  private isAllocationSettingsMatching = false;
  private viewType: ViewType = ViewType.allPlans;
  public channel = '';
  public tactic = '';
  public showChild = false;

  private currViewSubject = new BehaviorSubject<CurrentView | null>(null);
  private viewsListSubject = new BehaviorSubject<ViewResponse[]>([]);
  private loadingViewSubject = new BehaviorSubject<boolean>(false);
  private loadingViewListSubject = new BehaviorSubject<boolean>(false);
  private loadingViewsIdsSubject = new BehaviorSubject<object>({});
  private viewPersistanceSubject = new BehaviorSubject<ViewSaveState>(
    ViewSaveState.saved
  );

  private viewChildPersistanceSubject = new BehaviorSubject<ViewSaveState>(
    ViewSaveState.saved
  );

  readonly currentView$ = this.currViewSubject.asObservable();
  readonly viewsList$ = this.viewsListSubject.asObservable();
  readonly isLoadingView$ = this.loadingViewSubject.asObservable();
  readonly isLoadingViewList$ = this.loadingViewListSubject.asObservable();
  readonly loadingViewsIds$ = this.loadingViewsIdsSubject.asObservable();
  readonly viewPersistanceState$ = this.viewPersistanceSubject.asObservable();
  readonly viewChildPersistanceState$ =
    this.viewChildPersistanceSubject.asObservable();

  constructor(
    private readonly apiService: ApiService,
    private readonly contextStore: ContextStore,
    private readonly nativeSectionsService: NativeSectionsService,
    private readonly viewUtilityService: ViewUtilityService,
    public readonly assocFilterService: AssociatedFilterService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly viewStore: ViewStore
  ) {
    this.contextStore.associatedFilterContext.subscribe((assocFilters) => {
      this.processAssocFilterChanges(assocFilters);
    });
    this.contextStore.filterContext.subscribe((filters) => {
      if (!this.isAllocationSettingsMatching) {
        this.processFilterChanges(filters);
      }
    });
    this.viewStore.title.subscribe((title) => {
      this.title = title;
    });
  }

  get dashboardFilters() {
    return cloneDeep(this.dashboardFiltersPrimary);
  }

  private set dashboardFilters(f: Filter[]) {
    this.dashboardFiltersPrimary = cloneDeep(f);
  }

  get dashboardFiltersWithDefaults() {
    return cloneDeep(this.dashboardFiltersWithDefaultsPrimary);
  }

  private set dashboardFiltersWithDefaults(f: Filter[]) {
    const filters = cloneDeep(f);
    const defaults = this.nativeSectionsService.setFilterDefaults(filters);
    this.dashboardFiltersWithDefaultsPrimary = defaults;

    const relativeDate = defaults.find(
      (fil) => fil.literalId === 'relative_day'
    );

    if (
      relativeDate &&
      relativeDate.value &&
      relativeDate.value !== customRangeValue
    ) {
      this.defaultReferencePeriod = relativeDate.value as string;
    } else {
      defaults.forEach((fil) => {
        if (fil.type === 'dateRange') {
          const day = this.apiService.generateFiltersToSend(
            [cloneDeep(fil)],
            DataEndpoint.dataPoints
          );
          this.defaultReferencePeriod = `${dayjs(
            day?.dateStart?.toString()
          ).format('MM/DD/YYYY')} - ${dayjs(day?.dateStop?.toString()).format(
            'MM/DD/YYYY'
          )}`;
        }
      });
    }
  }

  get currViewFiltersWithDefaults() {
    return cloneDeep(this.currViewFiltersWithDefaultsPrimary);
  }

  private set currViewFiltersWithDefaults(f: Filter[]) {
    this.currViewFiltersWithDefaultsPrimary = cloneDeep(f);
  }

  setCurrentView(value: CurrentView | null): Promise<CurrentView | null> {
    return new Promise((resolve) => {
      const val = cloneDeep(value);
      if (val && val?.view) {
        if (val?.reloadData !== false) {
          val.reloadData = true;
        }
        val.view = this.updateViewStoreIdentifiers(
          val.view,
          'ADD'
        ) as ViewResponse;
        val.view.storeId = val.view.id.toString() + '_curr';
        this.currViewSubject.next(val);
      } else {
        this.currViewSubject.next(val);
      }
      resolve(val);
    });
  }

  getCurrentViewValue() {
    return this.currViewSubject.getValue();
  }

  setViewList(val: ViewResponse[]) {
    if (val && Array.isArray(val)) {
      this.viewsListSubject.next(
        this.updateViewStoreIdentifiers(val, 'ADD') as ViewResponse[]
      );
    }
  }

  getViewListValue(): ViewResponse[] {
    return this.viewsListSubject.getValue();
  }

  async loadView(
    isViewAvailable: boolean,
    viewId?: number,
    showChild?: boolean
  ) {
    this.loadingViewSubject.next(true);
    if (viewId) {
      await this.loadViewById(viewId, false, showChild);
    } else {
      if (isViewAvailable) {
        await firstValueFrom(this.apiService.getDefaultViewId())
          .then(async (def) => {
            if (def && def?.status === 'INACTIVE') {
              this.setCurrentView({
                dashboardHasViews: true,
                viewId: def?.viewId,
                active: false
              });
            } else if (def && def?.status === 'ACTIVE') {
              await this.loadViewById(def?.viewId);
            }
          })
          .catch(() => {
            this.setCurrentView({ dashboardHasViews: false });
          });
      } else {
        this.setCurrentView({ dashboardHasViews: false });
      }
    }
    this.loadingViewSubject.next(false);
  }

  async loadViewById(id: number, resetUrl = true, showChild = false) {
    this.loadingViewSubject.next(true);
    try {
      await this.loadDashboardFilters(id);
      const view = await firstValueFrom(this.apiService.getView(id, showChild));
      this.currViewFiltersWithDefaults =
        this.viewUtilityService.getViewFiltersWithDefaults(
          this.dashboardFilters,
          view
        );
      this.setCurrentView({
        dashboardHasViews: true,
        viewId: id,
        active: true,
        view
      });
    } catch (error) {
      this.setCurrentView({
        dashboardHasViews: true,
        viewId: id,
        active: false
      });
    }
    if (resetUrl) {
      const queryParams: Params = { viewId: id };
      this.router
        .navigate([], {
          relativeTo: this.route,
          queryParams
        })
        .catch((error) => console.error(error));
    }
    this.loadingViewSubject.next(false);
  }

  async switchToDefaultView() {
    this.loadingViewSubject.next(true);
    const views = await firstValueFrom(
      this.apiService.getViewList(ViewType.default)
    );
    if (views && views[0]) {
      this.loadViewById(views[0].id);
    }
  }

  async getDefaultView() {
    const views = await firstValueFrom(
      this.apiService.getViewList(ViewType.default)
    );
    return views[0] ?? undefined;
  }

  async fetchViewList(viewType: ViewType = ViewType.allPlans) {
    this.viewType = viewType;
    this.loadingViewListSubject.next(true);
    try {
      const views = await firstValueFrom(this.apiService.getViewList(viewType));
      this.setViewList(views);
      this.loadingViewListSubject.next(false);
    } catch (error) {
      this.loadingViewListSubject.next(false);
      throwError(() => new Error('Error on loading view lists'));
    }
  }

  async loadDashboardFilters(id: number) {
    await firstValueFrom(
      this.apiService.getFilters(DataEndpoint.dataPoints, [], id)
    )
      .then((filters) => {
        this.dashboardFilters = filters;
        this.dashboardFiltersWithDefaults = this.dashboardFilters;
      })
      .catch((error) => {
        this.dashboardFilters = [];
        throwError(() => new Error(error));
      });
  }

  getViewDataPoints(
    view: ViewResponse,
    showChild = false
  ): Promise<DataResponse> {
    const filters = this.viewUtilityService.getViewFiltersWithDefaults(
      this.dashboardFilters,
      view
    );
    return new Promise((resolve) => {
      this.apiService
        .getDashboardData({
          filters,
          type: DataEndpoint.dataPoints,
          viewId: view?.id
        })
        .then((dataPoints) => {
          let referencePeriod: string;
          const global = view?.filterOverrides.global;
          if (global.relative_day && global.relative_day !== customRangeValue) {
            referencePeriod = `${global.relative_day}`;
          } else if (global.day) {
            const day = global.day as unknown as string[];
            if (Array.isArray(day) && day.length === 2) {
              referencePeriod = `${dayjs(day[0]).format(
                'MM/DD/YYYY'
              )} - ${dayjs(day[1]).format('MM/DD/YYYY')}`;
            } else {
              referencePeriod = '-';
            }
          } else {
            referencePeriod = this.defaultReferencePeriod ?? '-';
          }
          const badges: DataResponse = {};
          if (!showChild) {
            Object.entries(view.badges).forEach(([k, v]) => {
              if (v) {
                badges[k] = k.charAt(0).toUpperCase() + k.slice(1);
              }
            });
          }
          resolve({
            ...dataPoints,
            ...view,
            ...badges,
            referencePeriod,
            cellName: this.title
          } as unknown as DataResponse);
        });
    });
  }

  getViewDataByStoreId(storeId: string): ViewResponse | undefined {
    const view = [
      this.currViewSubject.getValue()?.view,
      ...this.viewsListSubject.getValue()
    ].find((each) => each?.storeId === storeId);
    return cloneDeep(view);
  }

  updateViewDataByStoreId(storeId: string, updatedView: ViewResponse) {
    const view = this.getViewDataByStoreId(storeId);
    if (view) {
      const currView = this.currViewSubject.getValue();
      if (currView && currView?.view?.id === view?.id) {
        this.setCurrentView({
          ...currView,
          view: updatedView,
          reloadData: false
        });
      }
      const viewList = this.viewsListSubject.getValue();
      const index = viewList.findIndex((v) => v.id === view.id);
      viewList[index] = updatedView;
      this.setViewList(viewList);
    }
  }

  updateViewStoreIdentifiers(
    val: ViewResponse | ViewResponse[],
    type: 'ADD' | 'REMOVE'
  ) {
    if (Array.isArray(val)) {
      val.forEach((e) => this.updateViewStoreIdentifiers(e, type));
    } else {
      if (type === 'ADD') {
        val.storeId = val.id.toString();
        val.eventCategory = PortalEventCategory.multiViewEvent;
      } else if (type === 'REMOVE') {
        delete val.storeId;
        delete val.eventCategory;
      }
    }
    return val;
  }

  processFilterChanges(fil: Filter[]) {
    const filters = cloneDeep(fil);
    const viewFilters = this.currViewFiltersWithDefaults;
    const showChild = JSON.parse(
      this.route.snapshot.queryParams.showChild || false
    );
    const persistanceSubject = showChild
      ? this.viewChildPersistanceSubject
      : this.viewPersistanceSubject;

    // Day format changed to discard time diff.
    [...filters, ...viewFilters].forEach((f) => {
      if (f.type === 'dateRange') {
        const dateRange = f.value as string[];
        f.value = [dateRange[0], dateRange[1]] as FilterValue;
      }
      if (
        f.literalId === 'dimensions' &&
        Array.isArray(f.value) &&
        f.value.length === 0
      ) {
        f.value = f.options;
      }
    });

    if (this.compareFilters(viewFilters, filters)) {
      persistanceSubject.next(ViewSaveState.saved);
    } else {
      persistanceSubject.next(ViewSaveState.notSaved);
    }
  }

  compareFilters(filter1: Filter[], filter2: Filter[]) {
    const showChild = JSON.parse(
      this.route.snapshot.queryParams.showChild || false
    );
    return (
      JSON.stringify(
        filter1
          .filter((f) =>
            showChild
              ? f.literalId.startsWith('child')
              : !f.literalId.startsWith('child')
          )
          .map((f) => pick(f, MultiViewStore.compareProps))
      ) ===
      JSON.stringify(
        filter2
          .filter((f) =>
            showChild
              ? f.literalId.startsWith('child')
              : !f.literalId.startsWith('child')
          )
          .map((f) => pick(f, MultiViewStore.compareProps))
      )
    );
  }

  processAssocFilterChanges(assocFilters: AssociatedFilterValues) {
    const view = this.getCurrentViewValue()?.view;
    let dim = this.getViewDimensions();
    if (dim && Array.isArray(dim) && dim?.length > 0) {
      const showChild = JSON.parse(
        this.route.snapshot.queryParams.showChild || false
      );

      if (showChild) {
        const childDimensions = this.currViewFiltersWithDefaults.find(
          (f) => f.literalId === 'childDimensions'
        );
        dim = dim.concat(childDimensions?.value as string[]);
      }

      const rowLevel = showChild
        ? view?.filterOverrides.childRowLevel
        : view?.filterOverrides.rowLevel;

      const persistanceSubject = showChild
        ? this.viewChildPersistanceSubject
        : this.viewPersistanceSubject;

      const baseAssocFilters: AssociatedFilterValue[] = [];
      const alloc = cloneDeep(assocFilters.allocationSetting);
      const inc = cloneDeep(assocFilters.incrementalitySetting);
      this.viewUtilityService.assocFilterReducer(
        dim,
        baseAssocFilters,
        alloc || []
      );
      this.viewUtilityService.assocFilterReducer(
        dim,
        baseAssocFilters,
        inc || []
      );
      this.isAllocationSettingsMatching = false;
      if (baseAssocFilters.length !== rowLevel?.length) {
        this.isAllocationSettingsMatching = true;
        persistanceSubject.next(ViewSaveState.notSaved);
      } else {
        baseAssocFilters?.forEach((assocFil) => {
          let filterFound = false;
          let filterModified = false;
          rowLevel.forEach((rowFil) => {
            let dimensionsMatched = 0;
            dim.forEach((d) => {
              if (rowFil[d] === assocFil[d]) dimensionsMatched += 1;
            });
            if (dimensionsMatched === dim.length) {
              filterFound = true;
              let valuesMatched = 0;
              if (
                Object.entries(rowFil).length ===
                Object.entries(assocFil).length
              ) {
                Object.entries(assocFil).forEach(([k, v]) => {
                  if (rowFil[k] === v) valuesMatched += 1;
                });
                if (valuesMatched !== Object.entries(assocFil).length) {
                  filterModified = true;
                }
              } else {
                filterModified = true;
              }
            }
          });
          if (!filterFound || filterModified) {
            persistanceSubject.next(ViewSaveState.notSaved);
          }
        });
      }
    }
  }

  getViewDimensions(): string[] {
    const dimFilter = this.currViewFiltersWithDefaults.find(
      (f) => f.literalId === 'dimensions'
    );
    return (dimFilter ? dimFilter.value : []) as string[];
  }

  async updateCurrentView() {
    const view = cloneDeep(this.getCurrentViewValue()?.view);
    const viewState = this.viewPersistanceSubject.getValue();
    if (view && viewState === ViewSaveState.notSaved) {
      this.viewPersistanceSubject.next(ViewSaveState.saving);
      await this.prepareViewToSend(view);
      this.updateViewStoreIdentifiers(view, 'REMOVE');
      this.apiService.updateView(view as ViewResponse).subscribe({
        next: (res: ViewResponse) => {
          this.viewPersistanceSubject.next(ViewSaveState.saved);
          const currView = this.currViewSubject.getValue();
          if (currView && currView?.view?.id === view?.id) {
            this.setCurrentView({
              ...currView,
              view: res,
              reloadData: false
            });
          }
        },
        error: (error) => {
          this.viewPersistanceSubject.next(ViewSaveState.notSaved);
          throwError(() => new Error(error));
        }
      });
    }
  }

  async updateChildView(): Promise<void> {
    const view = cloneDeep(this.getCurrentViewValue()?.view);
    const viewState = this.viewChildPersistanceSubject.getValue();
    if (view && viewState === ViewSaveState.notSaved) {
      this.viewChildPersistanceSubject.next(ViewSaveState.saving);
      await this.prepareChildToSend(view);
      this.apiService.updateView(view as ViewResponse).subscribe({
        next: (res: ViewResponse) => {
          this.viewChildPersistanceSubject.next(ViewSaveState.saved);
          const currView = this.currViewSubject.getValue();
          if (currView && currView?.view?.id === view?.id) {
            this.setCurrentView({
              ...currView,
              view: res,
              reloadData: false
            });
          }
        },
        error: (error) => {
          this.viewChildPersistanceSubject.next(ViewSaveState.notSaved);
          throwError(() => new Error(error));
        }
      });
    }
  }

  async prepareChildToSend(view: ViewResponse) {
    view.isChild = true;

    const global = await this.viewUtilityService.getGlobalFiltersToSend(
      this.dashboardFiltersWithDefaults
    );
    const childDimensions = global.childDimensions as unknown as string[];
    const dimensions = global.dimensions as unknown as string[];

    view.dimensionFormat = dimensions.join(this.rowLevelFilterSeparator);
    view.dimensionFormat += childDimensions.length
      ? `${this.rowLevelFilterSeparator}${childDimensions.join(
          this.rowLevelFilterSeparator
        )}`
      : '';

    const childRowLevel: AssociatedFilterValue[] = [];
    const assocFilters = this.contextStore.associatedFilterSelection;
    const allocSettings = cloneDeep(assocFilters.allocationSetting);
    const incSettings = cloneDeep(assocFilters.incrementalitySetting);

    this.viewUtilityService.assocFilterReducer(
      childDimensions,
      childRowLevel,
      (allocSettings &&
        allocSettings.filter((setting) => !!setting.campaign)) ||
        []
    );

    this.viewUtilityService.assocFilterReducer(
      childDimensions,
      childRowLevel,
      (incSettings && incSettings.filter((setting) => !!setting.campaign)) || []
    );

    const currFilters = await firstValueFrom(this.contextStore.filterContext);

    const conversionType = currFilters.find(
      (f) => f.literalId === 'conversion_type'
    )?.value;

    const globalDimensions = pick(global, dimensions as string[]);
    view.filterOverrides.global = {
      ...view.filterOverrides.global,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      conversion_type: conversionType,
      dimensions,
      ...globalDimensions
    } as unknown as DataResponse;

    view.filterOverrides.childGlobal =
      this.viewUtilityService.getChildGlobalFilters(currFilters);
    view.filterOverrides.childRowLevel = childRowLevel as DataResponse[];
  }

  async prepareViewToSend(view: ViewResponse) {
    const dim = this.getViewDimensions();
    const global = await this.viewUtilityService.getGlobalFiltersToSend(
      this.dashboardFiltersWithDefaults
    );
    const assocFilters = this.contextStore.associatedFilterSelection;
    const rowLevel: AssociatedFilterValue[] = [];
    const allocSettings = cloneDeep(assocFilters.allocationSetting);
    const incSettings = cloneDeep(assocFilters.incrementalitySetting);
    this.viewUtilityService.assocFilterReducer(
      dim,
      rowLevel,
      (allocSettings &&
        allocSettings.filter(
          (setting) =>
            setting.conversion_type === global.conversion_type &&
            !setting.campaign
        )) ||
        []
    );
    this.viewUtilityService.assocFilterReducer(
      dim,
      rowLevel,
      (incSettings &&
        incSettings.filter(
          (setting) => setting.conversion_type === global.conversion_type
        )) ||
        []
    );
    view.filterOverrides = {
      global: global as DataResponse,
      rowLevel: rowLevel as DataResponse[]
    };

    view.dimensionFormat = dim.join(this.rowLevelFilterSeparator);
  }

  createView(planName: string, id: number): Promise<CurrentView> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const view = {
        name: planName
      } as ViewResponse;

      await this.prepareViewToSend(view);

      this.apiService.postView(view, id).subscribe({
        next: (res) => {
          const queryParams: Params = { viewId: res.id };
          this.router
            .navigate([], {
              relativeTo: this.route,
              queryParams
            })
            .catch((error) => console.error(error));
          this.loadDashboardFilters(id).then(() => {
            this.currViewFiltersWithDefaults =
              this.viewUtilityService.getViewFiltersWithDefaults(
                this.dashboardFilters,
                res
              );
            this.setCurrentView({
              dashboardHasViews: true,
              viewId: id,
              active: true,
              view: res
            }).then((currentView) => {
              if (currentView) {
                resolve(currentView);
              }
            });
          });
        },
        error: () => {
          reject('Error while saving');
        }
      });
    });
  }

  updateView(view: ViewResponse): Promise<ViewResponse> {
    const storeId = view.storeId as string;
    const data = this.updateViewStoreIdentifiers(
      view,
      'REMOVE'
    ) as ViewResponse;
    return new Promise((resolve, reject) => {
      this.loadingViewsIdsSubject.next({
        ...this.loadingViewsIdsSubject.getValue(),
        [storeId]: true
      });
      this.viewPersistanceSubject.next(ViewSaveState.saving);
      this.apiService.updateView(data).subscribe({
        next: (res: ViewResponse) => {
          this.updateViewDataByStoreId(storeId, res);
          this.loadingViewsIdsSubject.next({
            ...this.loadingViewsIdsSubject.getValue(),
            [storeId]: false
          });
          this.viewPersistanceSubject.next(ViewSaveState.saved);
          resolve(res);
        },
        error: (error) => {
          this.loadingViewsIdsSubject.next({
            ...this.loadingViewsIdsSubject.getValue(),
            [storeId]: false
          });
          this.viewPersistanceSubject.next(ViewSaveState.notSaved);
          reject(error);
        }
      });
    });
  }

  patchView(view: ViewResponse, operation: ViewOperations) {
    this.loadingViewListSubject.next(true);
    this.apiService.patchView(view, operation).subscribe({
      next: async (res) => {
        if (res.success) {
          const curr = this.currViewSubject.getValue();
          if (curr?.view?.id) {
            this.apiService.getView(curr.view.id).subscribe({
              next: (viewRespone) => {
                this.setCurrentView({
                  ...curr,
                  view: viewRespone,
                  reloadData: false
                });
              },
              error: (error) => {
                throwError(() => new Error(error));
              }
            });
          }
          await this.fetchViewList(this.viewType);
        }
        this.loadingViewListSubject.next(false);
      },
      error: (error) => {
        this.loadingViewListSubject.next(false);
        throwError(() => new Error(error));
      }
    });
  }

  deleteView(view: ViewResponse) {
    const curr = this.getCurrentViewValue();
    if (curr?.view?.id === view.id) {
      this.loadingViewSubject.next(true);
    }
    this.apiService.deleteView(view.id).subscribe({
      next: async (res) => {
        if (res.success) {
          if (view.id === curr?.view?.id) {
            this.setCurrentView({
              dashboardHasViews: true,
              viewId: view.id,
              active: false
            });
          }
          this.loadingViewSubject.next(false);
          this.fetchViewList(this.viewType);
        }
      },
      error: (error) => {
        this.loadingViewSubject.next(false);
        throwError(() => new Error(error));
      }
    });
  }

  updateFilters(filters: Filter[], view?: ViewResponse): void {
    this.dashboardFilters = filters;
    if (view) {
      this.currViewFiltersWithDefaults =
        this.viewUtilityService.getViewFiltersWithDefaults(
          this.dashboardFilters,
          view
        );
    }
  }

  mapFilterOverridesToAllocationSetting(
    view: ViewResponse,
    filters: Filter[],
    showChild: boolean
  ): void {
    const rowLevel = showChild
      ? view.filterOverrides.childRowLevel
      : view.filterOverrides.rowLevel;

    rowLevel?.forEach((rowFilter) => {
      if (rowFilter.allocationSetting) {
        const fil = this.getFilterWithOverrideValues(rowFilter, filters);
        const assocFilter: AssociatedFilterValue = {};
        this.assocFilterService.generateFilterModel(
          'allocationSetting',
          assocFilter,
          fil
        );
        assocFilter.tactic = rowFilter?.tactic ?? null;
        assocFilter.channel = rowFilter?.channel ?? null;
        assocFilter.conversion_type = rowFilter?.conversion_type ?? null;
        if (showChild) {
          assocFilter.tactic = this.tactic;
          assocFilter.channel = this.channel;
          assocFilter.campaign = rowFilter?.campaign ?? null;
        }
        this.contextStore.changeAssociatedFilterValues(
          'allocationSetting',
          assocFilter,
          showChild
        );
      }
    });
  }

  mapFilterOverridesToIncrementalitySetting(
    view: ViewResponse,
    showChild: boolean
  ): void {
    const rowLevel = showChild
      ? view.filterOverrides.childRowLevel
      : view.filterOverrides.rowLevel;

    rowLevel?.forEach((rowFilter) => {
      if (rowFilter.percInc !== null && (rowFilter.percInc as number) >= 0) {
        const assocFilter: AssociatedFilterValue = {};
        assocFilter.percInc = rowFilter?.percInc ?? null;
        assocFilter.tactic = rowFilter?.tactic ?? null;
        assocFilter.channel = rowFilter?.channel ?? null;
        assocFilter.conversion_type = rowFilter?.conversion_type ?? null;
        if (showChild) {
          assocFilter.tactic = this.tactic;
          assocFilter.channel = this.channel;
          assocFilter.campaign = rowFilter?.campaign ?? null;
        }
        this.contextStore.changeAssociatedFilterValues(
          'incrementalitySetting',
          assocFilter,
          showChild
        );
      }
    });
  }

  getFilterWithOverrideValues(
    assocFilter: AssociatedFilterValue,
    f: Filter[]
  ): Filter[] {
    const keys: string[] = [];
    Object.entries(assocFilter).forEach(([key]) => {
      keys.push(key);
    });
    const filter = cloneDeep(f);
    filter.forEach((each: Filter) => {
      if (keys.includes(each.literalId)) {
        each.value = assocFilter[each.literalId] || null;
      }
    });
    return filter;
  }
}
