<div class="flex flex-col bg-gray-100 min-h-screen">
  <div class="w-full dashboard-header bg-gray-000">
    <div class="px-4 w-full">
      <lib-ui-header [headerConfig]="headerConfig" [headerData]="{}">
      </lib-ui-header>
    </div>
  </div>
  <m-card [isExpandable]="false" class="w-full p-4">
    <div #dataContainer class="flex flex-col gap-4 w-full">
      @if (loading | async) {
        <lib-ui-loader-inline
          [loaderContainer]="dataContainer"
          loaderTitle="We're on it!"
          loaderMessage="We're getting your shopify data"></lib-ui-loader-inline>
      }
      @if ((loading | async) === false) {
        <div class="flex flex-col gap-1 items-start">
          <lib-ui-title [titleConfigs]="tableTitleConfigs"></lib-ui-title>
          <span class="b1 text-gray-600"
            >Recent orders from your shopify shop appear here</span
          >
        </div>
      }
      <div class="flex w-full min-h-1/2 overflow-auto m-table justify-center">
        @if ((loading | async) === false && (noData | async) === false) {
          <p-table
            class="w-full"
            styleClass="w-full"
            [paginator]="true"
            [rows]="15"
            [rowsPerPageOptions]="[10, 15, 25, 50]"
            [columns]="dashboardColumns"
            [value]="dashboardData">
            <ng-template pTemplate="header">
              <tr>
                @for (col of dashboardColumns; track col) {
                  <th scope="col" pSortableColumn="{{ col.field }}">
                    <div class="flex items-center gap-2">
                      {{ col.header }}
                      <span
                        class="saas-icon material-symbols-outlined icon-small"
                        >sort</span
                      >
                    </div>
                  </th>
                }
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr>
                @for (col of columns; track col) {
                  <td>
                    @switch (col.field) {
                      @case ("createdAt") {
                        {{ rowData[col.field] || "" | date: "MMM, dd yyyy" }}
                      }
                      @case ("cancelledAt") {
                        {{ rowData[col.field] || "" | date: "MMM, dd yyyy" }}
                      }
                      @default {
                        @if (col.type === "currency") {
                          {{
                            rowData[col.field]
                              ? (rowData[col.field]
                                | currency: "USD" : "symbol" : "1.0-2")
                              : "-"
                          }}
                        } @else {
                          {{ rowData[col.field] || "-" }}
                        }
                      }
                    }
                  </td>
                }
              </tr>
            </ng-template>
          </p-table>
        }
      </div>
      @if (noData | async) {
        <m-not-available></m-not-available>
      }
    </div>
  </m-card>
</div>
