export enum TitleTypes {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  info = 'Info',
  titleWithSubTitle = 'TITLE_SUBTITLE'
}

export enum HeadingTypes {
  heading = 'HEADING',
  subHeading = 'SUB_HEADING',
  infoHeading = 'INFO_HEADING',
  labelValueHeading = 'LABEL_VALUE_HEADING'
}

export enum ViewType {
  loader = 'loader',
  success = 'success',
  error = 'error',
  empty = ''
}

export enum LoaderType {
  modal = 'modal',
  inline = 'inline'
}

export enum Size {
  lg = 'lg-size',
  sm = 'sm-size'
}
