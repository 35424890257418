import { Component, OnInit } from '@angular/core';
import { AuthService } from '@portal/app/shared/services/auth.service';
import { ViewStore } from '@portal/app/shared/state/view.store';
import { take } from 'rxjs';
import { SelectionService } from '@portal/app/shared/services/selection.service';
import { WindowService } from '@portal/app/shared/services/window.service';

@Component({
  selector: 'portal-user-details-page',
  templateUrl: './user-details-page.component.html'
})
export class UserDetailsPageComponent implements OnInit {
  public username = '';
  public client = '';
  public role = '';

  // private subs: Subscription = new Subscription();

  constructor(
    private readonly authService: AuthService,
    private readonly viewStore: ViewStore,
    private readonly selectionService: SelectionService,
    private readonly windowService: WindowService
  ) {}

  ngOnInit(): void {
    this.viewStore
      .changeTitle('User Details')
      .pipe(take(1))
      .subscribe({
        error: (e) => console.error(e)
      });
    const user = this.authService.getUser();
    if (user != null) {
      this.username = user.getUsername();
      this.role = user.getRoleLabel();
      this.client = user.getClientName(this.selectionService);
    }
  }

  changePassword(): void {
    // In order to change the password, you much do it on the AWS SSO Sign in page. After Sign in *should* redirect you to Portal
    this.windowService.navigateToHref(this.authService.changePasswordUrl);
  }
}
