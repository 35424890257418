import { Component, inject } from '@angular/core';
import { CardVariant } from '@design-system/components/m-card';
import { NavigationService } from '@portal/app/shared/services/navigation.service';
import { TacticOptimizationsService } from '@portal/app/dashboard/context-modal/services/tactic-optimizations.service';
@Component({
  selector: 'portal-tactic-optimization-cards',
  templateUrl: './tactic-optimization-cards.component.html',
  providers: [TacticOptimizationsService]
})
export class TacticOptimizationCardsComponent {
  navigationService = inject(NavigationService);
  tacticOptimizationzService = inject(TacticOptimizationsService);

  cardVariantType = CardVariant;

  isLoading = this.tacticOptimizationzService.isLoading;
  isDataAvailable = this.tacticOptimizationzService.isDataAvailable;
  isSame = this.tacticOptimizationzService.isSame;
  response = this.tacticOptimizationzService.response;
  card = this.tacticOptimizationzService.card;
  emptyStateCard = this.tacticOptimizationzService.emptyStateCard;

  onButtonAction() {
    this.navigationService.navigationMapping(
      'mpo',
      {
        filters: [],
        extraParams: {
          optimizeStrengthFilterValue: 1
        }
      },
      true
    );
  }
}
