import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  BottomPanelMessageType,
  IBottomPanelButtonConfig,
  IButtonClickEvent,
  IConnectedPoints
} from './m-bottom-panel.component.types';
import { ButtonType } from '@design-system/components/m-button';

@Component({
  selector: 'm-bottom-panel',
  templateUrl: './m-bottom-panel.component.html',
  styleUrls: ['./m-bottom-panel.component.scss']
})
export class MBottomPanelComponent implements OnInit, OnChanges {
  @Input() message = '';
  @Input() messageType: BottomPanelMessageType = BottomPanelMessageType.success;
  @Input() messageTimeoutInMillis = -1;
  @Input() rightActionButtons: IBottomPanelButtonConfig[] = [];
  @Input() leftActionButtons: IBottomPanelButtonConfig[] = [];
  @Input() connectedPoints: IConnectedPoints = {
    connectedPoints: 0,
    totalPoints: 0
  };

  @Input() buttonStates: { id: string; disabled: boolean }[] = [];
  @Input() zIndex = 50;

  connectedPointsMessage = '';

  @Output() buttonClick = new EventEmitter<IButtonClickEvent>();

  messageIcon = '';

  ngOnInit(): void {
    this.sortButtons();
    this.setMessageIcon();
    this.addMessageVisibilityTimeout();
    this.setConnectedPointsMessage();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.sortButtons();
    this.setMessageIcon();
    this.addMessageVisibilityTimeout();
    if (changes['connectedPoints']) {
      this.setConnectedPointsMessage();
    }
    if (changes['buttonStates']) {
      this.buttonStates.forEach((buttonState) => {
        const buttonItem = this.rightActionButtons.find(
          (button) => button.id === buttonState.id
        );
        if (buttonItem) {
          buttonItem.disabled = buttonState.disabled;
        }
      });
    }
  }

  private sortButtons() {
    const allButtonTypes = Object.values(ButtonType);
    this.leftActionButtons.sort(
      (btn1, btn2) =>
        allButtonTypes.indexOf(btn1.type) - allButtonTypes.indexOf(btn2.type)
    );

    this.rightActionButtons.sort(
      (btn1, btn2) =>
        allButtonTypes.indexOf(btn2.type) - allButtonTypes.indexOf(btn1.type)
    );
  }

  private setMessageIcon() {
    switch (this.messageType) {
      case BottomPanelMessageType.base:
      case BottomPanelMessageType.info:
      case BottomPanelMessageType.success:
        this.messageIcon = 'check';
        break;
      case BottomPanelMessageType.error:
      case BottomPanelMessageType.warn:
        this.messageIcon = 'error';
        break;
    }
  }

  publishButtonClick(panelButton: IBottomPanelButtonConfig, refEvent: Event) {
    this.buttonClick.emit({
      id: panelButton.id,
      refEvent: refEvent
    });
  }

  private addMessageVisibilityTimeout() {
    if (this.messageTimeoutInMillis > 0) {
      setTimeout(() => {
        this.message = '';
      }, this.messageTimeoutInMillis);
    }
  }

  private setConnectedPointsMessage() {
    this.connectedPointsMessage = `${this.connectedPoints.connectedPoints} / ${this.connectedPoints.totalPoints} points connected`;
  }
}
