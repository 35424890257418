import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NativeSectionsService } from '@portal/app/shared/services/native-sections.service';
import { ViewService } from '@portal/app/shared/services/view.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'portal-data-cell',
  templateUrl: './data-cell.component.html',
  styleUrls: ['./data-cell.component.scss']
})
export class DataCellComponent implements OnInit, OnDestroy {
  @Input() dashboardId = '';
  @Input() data!: string;
  @Input() key?: string;
  @Input() cellLayoutType?: string;

  noDataValues = ['No data', '-'];
  dataHovered: boolean | undefined = false;

  private subscriptions: Subscription = new Subscription();

  constructor(
    public readonly nativeSectionsService: NativeSectionsService,
    private readonly viewService: ViewService
  ) {}

  ngOnInit(): void {
    // if dashboardId exists and data is '-', allow dashboard based null data change
    if (this.dashboardId !== '' && this.data === '-') {
      switch (this.dashboardId) {
        case 'measured-benchmarks':
          // if dashboard measured-benchmarks replace '-' with 'No data'
          this.data = 'No data';
          break;

        case 'benchmarks-drill-down':
          this.data = 'No data';
          break;
      }
    }

    const interactionSubscription =
      this.viewService.interactionEvent$.subscribe((interaction) => {
        this.dataHovered =
          interaction?.key === this?.key &&
          interaction?.subType === this.cellLayoutType &&
          interaction?.isHovered;
      });

    this.subscriptions.add(interactionSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
