<div class="label-metric-container">
  <span [innerHTML]="label"></span>

  <div class="metric-container mt-1" #progressBarContainer>
    <div class="bar-container">
      <div class="bar-value" [style.width.%]="value"></div>
      <m-tooltip
        [content]="valueTooltip"
        [position]="tooltipPosition"
        [tooltipOptions]="tooltipOptions">
        <div class="metric-value-dot-container">
          @if (value !== null) {
            <div class="metric-value-dot"></div>
          }
        </div>
      </m-tooltip>
    </div>
  </div>

  <m-tooltip
    [content]="medianTooltip"
    position="bottom"
    [tooltipOptions]="{
      positionTop: 5,
      positionLeft: 5,
      showDelay: 800,
    }">
    <div
      class="median-dot-container"
      (mouseover)="onHover ? onHover('secondary') : null"
      (mouseleave)="onExit ? onExit('secondary') : null">
      <div class="median-dot"></div>
    </div>
  </m-tooltip>
</div>
