// generate class value for rows to be passed to class directive
import { Pipe, PipeTransform } from '@angular/core';
import { DataGridSelected, FieldDefinition } from '@portal/app/shared/types';

@Pipe({ name: 'generateRowClassInput' })
export class GenerateRowClassInputPipe implements PipeTransform {
  transform(
    fieldDefinition: FieldDefinition,
    pivotRowsActive: boolean,
    pivotHeaderDashboardFieldId: string,
    col: DataGridSelected
  ): (string | null)[] {
    if (
      pivotRowsActive &&
      col.dashboardFieldId !== pivotHeaderDashboardFieldId &&
      fieldDefinition.format !== 'empty'
    ) {
      return ['static-pivot-cell', 'random', 'string'];
    }
    return [
      fieldDefinition.format,
      fieldDefinition.literalId,
      fieldDefinition.type
    ];
  }
}
