<div class="flex justify-between items-center h-full">
  <span class="h2 text-orange-300 mb-0">{{ title }}</span>

  <div class="flex">
    @if (hasData) {
      <m-dropdown
        [options]="conversionTypeOptions"
        [(ngModel)]="conversionType"
        (formControlChange)="onConversionTypeChange($event?.value)"
        class="mr-2"></m-dropdown>
    }

    @if (hasData) {
      <m-dropdown
        [options]="relativeDayOptions"
        [(ngModel)]="relativeDay"
        (formControlChange)="onRelativeDayChange($event?.value)"
        (click)="onDropdownClick($event)"
        class="mr-2"></m-dropdown>
    }

    <m-tooltip
      content="<div class='b1 text-gray-000'>Get info about Homepage</div>"
      position="bottom"
      [tooltipOptions]="{
        positionTop: 10,
        showDelay: 800,
      }">
      <m-button [type]="'secondary'" (click)="navigateToSupportPage()">
        <span class="b2 text-blue-600">Learn more</span>
      </m-button>
    </m-tooltip>
  </div>
</div>

<!-- Date Picker -->
<portal-date-range-picker
  #datePicker
  label=""
  (cancelEvent)="handleCancel()"
  [containerClass]="'row'"
  [date]="selectedDateRange"
  [datePickerInputStyleClass]="'form-control p-inputtext'"
  [datePickerInputWidth]="'100%'"
  [hideDatePickerAfterApply]="true"
  [dateFormatType]="'MM-DD-YYYY'"
  (selectedCompareOptionChanged)="handleCompareOptionChange($event)"
  (dateChanged)="onCustomDateChange($event)"
  [showDatePickerWrapper]="false"
  [compareFilter]="compareFilter"
  [minDate]="minDate"
  [relativeDate]="relativeDayFilter"></portal-date-range-picker>
