<p-tabView
  class="component-tab"
  (onChange)="handleTabChange($event)"
  [hidden]="!hasTabs()"
  [activeIndex]="activeTabIndex">
  @for (tab of tabs; track tab) {
    <p-tabPanel [header]="tab.label">
      @for (item of tab.items; track item) {
        @switch (item.layoutType) {
          @case ("TEXT") {
            <p>
              {{ item.label }}
            </p>
          }
          @case ("ACTION") {
            <p>
              <p-button
                class="pButton ml5"
                [disabled]="disableAction"
                (click)="handleActionClick(item)"
                [label]="item.label"></p-button>
            </p>
          }
        }
      }
    </p-tabPanel>
  }
</p-tabView>
