@if (!isReady()) {
  <div class="h-full">
    <m-skeleton width="100%" height="372px"></m-skeleton>
  </div>
} @else if (!isLoading() && !isDataAvailable()) {
  <div class="h-full">
    <m-card [variant]="cardVariantType.small"
      ><m-not-available></m-not-available
    ></m-card>
  </div>
} @else if (isReady() && isDataAvailable()) {
  <div class="h-full">
    <m-card [variant]="cardVariantType.small">
      <div header class="flex items-center w-full mb-2">
        <span class="sh2 mr-1">Top {{ title() }}</span>
        <!-- Show span if activeChannel is provided -->
        @if (titleSuffix()) {
          <span class="sh2 mr-1 capitalize">{{ titleSuffix() }}</span>
        }
      </div>
      <div class="text-center w-full">
        <m-horizontal-bar-chart
          [legend]="chartOptions().legend"
          [plotOptions]="chartOptions().plotOptions"
          [series]="chartOptions().series"
          [tooltip]="chartOptions().tooltip"
          [xAxis]="chartOptions().xAxis"
          [yAxis]="chartOptions().yAxis"
          [title]="chartOptions().title"
          [width]="null"
          [height]="300"></m-horizontal-bar-chart>
      </div>
    </m-card>
  </div>
}
