export interface ICampaignFilterContext {
  dateRange: TaxonomyDateRange;
  groupByDimension: TaxonomyDimension;
  dimensionFilter?: string;
  tableColumns?: string[];
}

export enum TaxonomyDateRange {
  all = 'all',
  last3Days = 'last_3_days',
  last7Days = 'last_7_days',
  last14Days = 'last_14_days',
  last30Days = 'last_30_days',
  last90Days = 'last_90_days',
  last52Weeks = 'last_52_week',
  mtd = 'current_month'
}

export enum TaxonomyDimension {
  compositeDataSourceName = 'compositeDataSourceName',
  tactic = 'tactic',
  channel = 'channel',
  segment = 'segment'
}
