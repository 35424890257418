<div class="row">
  <div class="col-md-12">
    <portal-empty-state
      [showMessage]="data.length === 0 && !loading"
      [message]="emptyMessage"
      [containerClass]="data.length === 0 ? 'experiment-no-data' : ''">
      <div
        class="{{ data.length === 0 ? 'no-data-spinner' : 'spinner' }}"
        *ngIf="loading">
        <p-progressSpinner></p-progressSpinner>
      </div>
      <section *ngFor="let dObj of data" [ngClass]="loading ? 'overlay' : ''">
        <div
          *ngIf="captionFields.length > 0"
          class="row EXPERIMENT_RESULT_CAPTION_SECTION">
          <div
            *ngFor="let caption of captionFields"
            [style]="'width: ' + 100 / captionFields.length + '%'">
            <p class="fw-boldish">
              {{ caption.label }}
            </p>
            <span>{{ dObj.caption[caption.field] }}</span>
          </div>
          <div
            *ngIf="
              item &&
              item.EXPERIMENT_LAYOUT_EXPANSION_SECTION_VISIBILITY &&
              dObj.highlight
            "
            class="d-flex justify-content-end align-items-center">
            <em class="pi pi-check highlight-result me-1"></em>
            Meets Target Value
          </div>
        </div>
        <portal-data-grid-row-group-expand
          *ngIf="dObj"
          [shouldExpandAllAtLoad]="shouldExpandAllAtLoad"
          [groupByField]="groupByField"
          [fields]="fields"
          [exportFields]="exportFields"
          [exportFileName]="exportFileName"
          [data]="setData(dObj.comparison)"
          [styleClass]="'EXPERIMENT_RESULT_COMPARISON_SECTION'"
          [expansionData]="getExpansionConfig(dObj.expansion, dObj)"
          [highlightColumns]="
            item &&
            item.EXPERIMENT_LAYOUT_EXPANSION_SECTION_VISIBILITY &&
            dObj.highlight
              ? initialLayoutLiteralId === 'Revenue'
                ? ['roas']
                : ['cpo']
              : []
          "></portal-data-grid-row-group-expand>

        <hr
          *ngIf="item && item.EXPERIMENT_LAYOUT_EXPANSION_SECTION_VISIBILITY" />
      </section>
    </portal-empty-state>
  </div>
</div>
