<div
  class="m-progress-bar-comparison flex justify-center items-center gap-4"
  [ngClass]="{
    large: options.isLarge || false,
  }">
  <div class="flex self-start w-full">
    <m-tooltip
      [content]="options.prevPeriod"
      class="w-full"
      [tooltipOptions]="tooltipOptions">
      <div class="label-container flex flex-col items-center">
        <div class="c2 text-gray-800 mb-6 text-center">
          Previous <br />
          Period
        </div>
        <div class="b2 text-gray-1000">
          {{ options.prevPeriodFormattedValue }}
        </div>
      </div>
    </m-tooltip>
  </div>

  <div class="bar-container flex flex-col justify-center gap-1.5">
    <p-progressBar
      class="prev-bar"
      [value]="prevPeriodPercentage"
      [showValue]="false"></p-progressBar>

    <p-progressBar
      class="cur-bar"
      [ngClass]="{
        positive: !options.isNeutral && options.isPositive,
        negative: !options.isNeutral && !options.isPositive,
        default: options.isNeutral,
      }"
      [value]="curPeriodPercentage"
      [showValue]="false"></p-progressBar>
  </div>

  <div class="flex self-start w-full">
    <m-tooltip
      [content]="options.curPeriod"
      class="w-full"
      [tooltipOptions]="tooltipOptions">
      <div class="label-container flex flex-col items-center">
        <div class="c2 text-gray-800 mb-6 text-center">
          Current <br />
          Period
        </div>
        <div class="b2 text-gray-1000 mb-1">
          {{ options.curPeriodFormattedValue }}
        </div>
        <m-tag
          [isPositive]="options.isPositive"
          [isUp]="options.isUp"
          [isNeutral]="!!options.isNeutral"
          [showBackground]="false"
          >{{ options.curPeriodDiff }}</m-tag
        >
      </div>
    </m-tooltip>
  </div>
</div>
