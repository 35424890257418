import { computed, inject, Injectable, signal } from '@angular/core';
import { ApiService, IGeoCell, IRecommendedTest } from '@libs/apis';
import { GeoTestsService } from '@portal/app/geo-designer/services/geo-tests.service';
import { BrandStoreService } from '@portal/app/dashboard/geo-doe-config/data-stores/brand-store.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { switchMap, map, tap } from 'rxjs/operators';
import { Observable, of, skip } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root'
})
export class GeoRecommendationsService {
  #recommendedTests = signal<IRecommendedTest[]>([]);
  #recommendationLoading = signal(true);
  #conversionTypes = signal<{ id: number; conversionType: string }[]>([]);
  #selectedConversionTypeId = signal<number | undefined>(undefined);

  recommendedTests = this.#recommendedTests.asReadonly();
  recommendationLoading = this.#recommendationLoading.asReadonly();
  conversionTypes = this.#conversionTypes.asReadonly();
  selectedConversionType = this.#selectedConversionTypeId.asReadonly();

  marketReferenceForConversionType = computed(() => {
    return this.geoTestsService
      .marketReferenceData()
      .find(
        (referenceDsOutput) =>
          referenceDsOutput.conversionTypeId === this.selectedConversionType()
      );
  });

  private readonly geoTestsService = inject(GeoTestsService);
  private readonly apiService = inject(ApiService);
  private readonly brandStoreService = inject(BrandStoreService);

  constructor() {
    toObservable(this.#selectedConversionTypeId)
      .pipe(
        skip(1),
        tap(() => this.#recommendationLoading.set(true)),
        switchMap((conversionType) => {
          if (conversionType) {
            return this.getRecommendedTestsForConversionType(conversionType);
          }
          return of([]);
        })
      )
      .subscribe({
        next: (recommendedTests) => {
          this.#recommendedTests.set(recommendedTests);
          this.#recommendationLoading.set(false);
        }
      });
  }

  setSelectedConversionTypeId(conversionTypeId: number): void {
    this.#selectedConversionTypeId.set(conversionTypeId);
  }

  getRecommendedTestsForConversionType(
    conversionTypeId: number
  ): Observable<IRecommendedTest[]> {
    return fromPromise(
      this.apiService.geoDoeApisOperations.getTestRecommendations(
        this.brandStoreService.brand.clientId,
        this.brandStoreService.brand.brandId,
        conversionTypeId
      )
    ).pipe(
      map((tests) =>
        tests.map((test) => ({
          ...test,
          isTestable: test.metadata.isTestable
        }))
      )
    );
  }

  getGeoAllDsMarkets(): Observable<IGeoCell[]> {
    const { clientId, brandId } = this.brandStoreService.brand;
    const selectedConversionTypeId = this.selectedConversionType();

    if (selectedConversionTypeId) {
      return fromPromise(
        this.apiService.geoDoeApisOperations.getGeoDsMarkets(
          clientId,
          brandId,
          selectedConversionTypeId
        )
      );
    } else {
      return of([]);
    }
  }

  fetchRecommendedConversionTypes() {
    return fromPromise(
      this.apiService.geoDoeApisOperations.getRecommendedConversionTypes(
        this.brandStoreService.brand.clientId,
        this.brandStoreService.brand.brandId
      )
    ).pipe(
      tap(({ conversionTypes }) => this.#conversionTypes.set(conversionTypes))
    );
  }
}
