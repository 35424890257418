import { Injectable } from '@angular/core';
import {
  ControlElement,
  DataResponse,
  FieldDefinition,
  FieldDefinitions
} from '@portal/app/shared/types';
import { ChartComponent } from '@portal/app/charts/chart.component';
import { CompareService } from '@portal/app/shared/services/compare.service';
import { cloneDeep, forEach, intersection, isEqual } from 'lodash-es';
import { CHART_COLORS } from '@portal/app/charts/shared/chart-constants';
import { PreviousPeriodService } from '@portal/app/shared/services/previous-period.service';
import { IChartSeriesOption } from '@portal/app/charts/shared/chart-model';

@Injectable({
  providedIn: 'root'
})
export class ChartCompareService {
  private compareApplied = false;
  private originMetricControls: { control: ControlElement; key: number }[] = [];
  private originFieldDefinitions: FieldDefinitions = {};
  private originColors: string[] = [];
  private originSelectedSeriesValues: Record<string, IChartSeriesOption> = {};

  constructor(private readonly compareService: CompareService) {}

  showCompareData(chartComponent: ChartComponent, response: DataResponse[]) {
    if (!this.compareService.compareIsAvailable) {
      return;
    }
    if (this.compareService.compare && !this.compareApplied) {
      this.cloneInitialValues(chartComponent);
    }

    if (this.compareService.compare && response.length) {
      const props = this.findProps(chartComponent, response);
      this.setYAxis(chartComponent, props);
      this.setMetrics(chartComponent);
      this.setDimensions(chartComponent);
      this.setSelections(chartComponent, props);
      this.setFieldDefinitions(chartComponent, props);
      this.compareApplied = true;
    }

    if (!this.compareService.compare && this.compareApplied) {
      this.restoreInitialValues(chartComponent);
      chartComponent.seriesSelectionChanged();
      this.compareApplied = false;
    }
  }

  findProps(chartComponent: ChartComponent, response: DataResponse[]) {
    const fieldKeys = Object.keys(chartComponent.fieldDefinitions).filter(
      (key) =>
        !['date', 'day'].includes(key) &&
        !key.startsWith(PreviousPeriodService.fieldPrefix)
    );
    return Object.keys(response[0] as DataResponse).filter((key: string) =>
      fieldKeys.includes(key)
    );
  }

  setYAxis(chartComponent: ChartComponent, props: string[]) {
    const yAxisPropertyFields: string[] = [];
    props.forEach((prop) => {
      yAxisPropertyFields.push(prop);
      yAxisPropertyFields.push(`${PreviousPeriodService.fieldPrefix}${prop}`);
    });

    chartComponent.chartOptions.yAxisPropertyFields = yAxisPropertyFields;
  }

  setMetrics(chartComponent: ChartComponent) {
    chartComponent.metricControls = chartComponent.metricControls.slice(0, 1);
  }

  setDimensions(chartComponent: ChartComponent) {
    chartComponent.selectedDimensions = chartComponent.selectedDimensions.slice(
      0,
      1
    );
  }

  setSelections(chartComponent: ChartComponent, props: string[]) {
    // HACK: Filter out undefined or null values from selectedSeriesValues
    const validSelectedSeriesValues = Object.values(
      chartComponent.selectedSeriesValues
    ).filter((value) => value !== undefined && value !== null);

    // allow all for selection
    const selectedValues: IChartSeriesOption[] = [];
    forEach(validSelectedSeriesValues, (value) => {
      value.disabled = false;
      if (isEqual(intersection(props, value.fields), value.fields)) {
        selectedValues.push(value);
      }
    });

    chartComponent.selectedSeriesValues = Object.assign(
      {} as Record<string, IChartSeriesOption>,
      selectedValues
    );
  }

  setFieldDefinitions(chartComponent: ChartComponent, props: string[]) {
    for (const key of props) {
      const prevKey = `${PreviousPeriodService.fieldPrefix}${key}`;
      const prevField = cloneDeep(chartComponent.fieldDefinitions[key]);
      if (prevField) {
        prevField.literalId = prevKey;
      }
      if (!chartComponent.fieldDefinitions[prevKey]) {
        chartComponent.fieldDefinitions[prevKey] = prevField as FieldDefinition;
      }
    }
  }

  cloneInitialValues(chartComponent: ChartComponent) {
    this.originMetricControls = cloneDeep(chartComponent.metricControls);
    this.originFieldDefinitions = cloneDeep(chartComponent.fieldDefinitions);
    this.originColors = cloneDeep(CHART_COLORS);
    this.originSelectedSeriesValues = cloneDeep(
      chartComponent.selectedSeriesValues
    );
  }

  restoreInitialValues(chartComponent: ChartComponent) {
    chartComponent.metricControls = this.originMetricControls;
    chartComponent.fieldDefinitions = this.originFieldDefinitions;
    chartComponent.chartConfig.colors = this.originColors;
    chartComponent.selectedSeriesValues = this.originSelectedSeriesValues;
  }
}
