import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DashboardService } from '@portal/app/shared/services/dashboard.service';
import {
  ActivatedRoute,
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import { Subscription, take } from 'rxjs';
import { ViewStore } from '@portal/app/shared/state/view.store';
import { NavbarComponent } from '../navbar/navbar.component';

@Component({
  selector: 'portal-main',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit, OnDestroy {
  public isTableauUriPath = false;
  private readonly subs: Subscription = new Subscription();
  productId = '';

  constructor(
    private readonly ref: ChangeDetectorRef,
    public router: Router,
    private readonly route: ActivatedRoute,
    private readonly dashboardService: DashboardService,
    public readonly viewStore: ViewStore
  ) {
    this.subs.add(
      this.router.events.subscribe((event: Event) => {
        switch (true) {
          case event instanceof NavigationStart:
          case event instanceof NavigationError:
          case event instanceof NavigationCancel: {
            break;
          }
          case event instanceof NavigationEnd: {
            this.isTableauUriPath = NavbarComponent.isTableauUriPath(
              location.href
            );
            break;
          }
          default: {
            break;
          }
        }
      })
    );
  }

  ngOnInit(): void {
    this.isTableauUriPath = NavbarComponent.isTableauUriPath(location.href);
    this.subs.add(
      this.dashboardService.dashboards.pipe(take(1)).subscribe({
        next: (dashboards) => {
          if (dashboards.length) {
            this.ref.detectChanges();
          } else {
            this.dashboardService
              .getCurrentDashboards()
              .pipe(take(1))
              .subscribe({
                next: () => {
                  this.ref.detectChanges();
                }
              });
          }
        }
      })
    );
    this.viewStore.selectedBCPDId.subscribe((value) => {
      this.productId = value.productId;
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
