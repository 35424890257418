import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  MetricCardElement,
  DataEndpoint,
  DataResponse,
  FieldDefinitions,
  Filter
} from '@portal/app/shared/types';
import { Required } from '@portal/app/shared/decorators/required.decorator';
import { Observable, Subscription } from 'rxjs';
import { ApiService } from '@portal/app/shared/services/api.service';
import { ContextStore } from '@portal/app/shared/state/context.store';
import { cloneDeep } from 'lodash-es';
import { SlideInAnimator } from '@libs/common-animations';

@Component({
  selector: 'portal-metric-card-group',
  templateUrl: './metric-card-group.component.html',
  styleUrls: ['./metric-card-group.component.scss'],
  animations: [SlideInAnimator.slideInToRight('.metric-card-item')]
})
export class MetricCardGroupComponent implements OnInit, OnDestroy {
  @Input() @Required item!: MetricCardElement[];
  @Input() fieldDefinitions: FieldDefinitions = {};
  @Input() dashboardLiteralId?: string;

  isMetricActive = true;
  data$?: Promise<DataResponse>;
  filterContext$: Observable<Filter[]> = this.contextStore.filterContext;
  private subs: Subscription = new Subscription();

  constructor(
    private readonly apiService: ApiService,
    private readonly contextStore: ContextStore
  ) {}

  ngOnInit(): void {
    this.getDataPoints();
  }

  getDataPoints(): void {
    const context = this.filterContext$.subscribe({
      next: (filterSet) => {
        const metricStatusFilter = filterSet.find(
          (f) => f.literalId === 'metric_name_status'
        );
        if (metricStatusFilter) {
          this.isMetricActive = metricStatusFilter.value === 'Active';
        }
        const filters = cloneDeep(filterSet);
        this.loadData(filters);
      }
    });
    this.subs.add(context);
  }

  loadData(filters: Filter[]): void {
    this.data$ = this.apiService.getDashboardData({
      filters,
      type: DataEndpoint.dataPoints
    }) as Promise<DataResponse>;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
