<ng-container *ngIf="getLayoutType(getFilter(name)) === 'RADIO'">
  <div class="top-filter-container mb-2 pb-2">
    <ng-container *ngFor="let option of getFilter(name)?.options">
      <div [ngClass]="labelToClass(option)" class="radio-input">
        <input
          type="radio"
          [(ngModel)]="getFilter(name).value"
          (ngModelChange)="onFilterValueChange(getFilter(name))"
          (click)="getFilter(name).value = option"
          [name]="getFilter(name).literalId"
          [value]="option"
          [checked]="getFilter(name).value === option"
          [id]="labelToClass(option)" />
        <label [for]="labelToClass(option)">{{ option }}</label>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="getFilter(name).associatedFilters">
    <div class="associatedFilter">
      <ng-container
        *ngFor="
          let associatedFilter of getFilter(name).associatedFilters[
            getFilter(name).value
          ]
        ">
        <ng-container
          *ngIf="getFilter(associatedFilter.associatedFilterLiteralId)">
          <portal-app-filter-set-radio-popup
            [name]="associatedFilter?.associatedFilterLiteralId"
            [dashboardId]="dashboardId"
            [fieldDefinitions]="fieldDefinitions"
            (filterChange)="onFilterValueChange($event)"
            [filterSet]="filterSet">
          </portal-app-filter-set-radio-popup>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="getLayoutType(getFilter(name)) === 'SELECT_WITH_TEXTBOX'">
  <p-dropdown
    [(ngModel)]="getFilter(name).value"
    class="select-input"
    (ngModelChange)="onFilterValueChange(getFilter(name))"
    [options]="getFilter(name).options"
    [showClear]="false"
    [filter]="false"
    [baseZIndex]="2"
    (onHide)="updateContext(idx, getFilter(name).literalId)"
    scrollHeight="24rem"
    appendTo="body"
    [placeholder]="getFilter(name).label">
  </p-dropdown>
  <ng-container
    *ngFor="
      let associatedFilter of getFilter(name).associatedFilters[
        getFilter(name).value
      ]
    ">
    <ng-container *ngIf="getFilter(associatedFilter.associatedFilterLiteralId)">
      <portal-app-filter-set-radio-popup
        [name]="associatedFilter?.associatedFilterLiteralId"
        [dashboardId]="dashboardId"
        (filterChange)="onFilterValueChange($event)"
        [fieldDefinitions]="fieldDefinitions"
        [filterSet]="filterSet">
      </portal-app-filter-set-radio-popup>
    </ng-container>
  </ng-container>
</ng-container>
<p-dropdown
  *ngIf="getLayoutType(getFilter(name)) === 'SELECT'"
  [(ngModel)]="getFilter(name).value"
  (ngModelChange)="onFilterValueChange(getFilter(name))"
  [options]="getFilter(name).options"
  [showClear]="false"
  [filter]="false"
  [baseZIndex]="2"
  (onHide)="updateContext(idx, getFilter(name).literalId)"
  scrollHeight="24rem"
  appendTo="body"
  [placeholder]="getFilter(name).label">
</p-dropdown>
<p-inputNumber
  type="number"
  class="target-input"
  *ngIf="getLayoutType(getFilter(name)) === 'INPUT_NUMBER'"
  [ngClass]="name"
  [(ngModel)]="getFilter(name).value"
  [minFractionDigits]="getMinDigits(getFilter(name))"
  (ngModelChange)="onFilterValueChange(getFilter(name))"></p-inputNumber>
