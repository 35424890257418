import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IPTableColumnInfo } from '@libs/common-components';
import { isEqual, isUndefined, omitBy } from 'lodash-es';
import { Listbox } from 'primeng/listbox';
import {
  ICampaignFilterContext,
  TaxonomyDateRange,
  TaxonomyDimension
} from '../../../models/ICampaignFilterContext';
import { ITaxonomyTabFilterConfig } from '../../../models/ITaxonomyTabFilterConfig';
import { TaxonomyTab } from '../../../models/TaxonomyTab';
import { Constants } from '../campaigns-view.constants';

@Component({
  selector: 'lib-taxonomy-campaign-view-filter-context',
  templateUrl: './campaign-view-filter-context.component.html',
  styleUrls: ['./campaign-view-filter-context.component.scss']
})
export class CampaignViewFilterContextComponent
  implements OnChanges, AfterViewInit
{
  private static readonly activeTabKey = 'activeTab';

  @ViewChild('columnSelector') columnSelector!: Listbox;
  @ViewChild('columnSelectorButton') columnSelectorButton!: ElementRef;

  @Input() activeTab: TaxonomyTab = TaxonomyTab.unmapped;
  @Input() campaignViewColumns: IPTableColumnInfo[] = [];
  @Output() campaignFilterChange = new EventEmitter<ICampaignFilterContext>();

  public dateRangeOptions = Constants.dateRangeOptions;
  public groupByDimensions = Constants.groupByDimensions;

  public campaignViewFilterForm!: FormGroup;
  public filterConfig: ITaxonomyTabFilterConfig;
  public accordionFilterPlaceholder = 'Search data source';
  public selectedCampaignViewColumns: IPTableColumnInfo[] = [];

  constructor() {
    this.filterConfig = Constants.taxonomyTabConfig[this.activeTab];
    this.initializeFilterContextForm();
  }

  private initializeFilterContextForm() {
    this.campaignViewFilterForm = new FormGroup({
      groupByDimension: new FormControl<TaxonomyDimension>(
        TaxonomyDimension.compositeDataSourceName
      ),
      dimensionFilter: new FormControl<string | undefined>(undefined),
      dateRange: new FormControl<TaxonomyDateRange | undefined>(
        TaxonomyDateRange.last30Days
      ),
      tableColumns: new FormControl<string[] | undefined>(undefined)
    });
  }

  @HostListener('document:click', ['$event'])
  listboxClickOut(event: Event) {
    if (
      this.columnSelector &&
      !this.columnSelector.el.nativeElement.contains(event.target) &&
      this.columnSelectorButton.nativeElement !== event.target
    ) {
      // logic to hide your p-listbox
      this.columnSelector.el.nativeElement.style.display = 'none';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes[CampaignViewFilterContextComponent.activeTabKey] &&
      changes[CampaignViewFilterContextComponent.activeTabKey]?.currentValue
    ) {
      const currentActiveTabValue = changes[
        CampaignViewFilterContextComponent.activeTabKey
      ]?.currentValue as TaxonomyTab;

      const previousActiveTabValue = changes[
        CampaignViewFilterContextComponent.activeTabKey
      ]?.previousValue as TaxonomyTab;

      if (previousActiveTabValue !== currentActiveTabValue) {
        this.initializeFilterContextForm();
      }
      this.activeTab = currentActiveTabValue || this.activeTab;
    }

    if (
      !isEqual(
        changes['campaignViewColumns']?.currentValue,
        changes['campaignViewColumns']?.previousValue
      )
    ) {
      this.campaignViewFilterForm.patchValue({
        tableColumns: changes['campaignViewColumns']?.currentValue.map(
          (column: IPTableColumnInfo) => column.field
        )
      });
    }
    this.filterConfig = Constants.taxonomyTabConfig[this.activeTab];
  }

  ngAfterViewInit() {
    this.columnSelector.el.nativeElement.style.display = 'none';
  }

  refreshCampaignRecords(value?: string, fieldName?: string) {
    if (fieldName) {
      this.campaignViewFilterForm.get(fieldName)?.setValue(value);
    }

    const campaignFilterContext = this.campaignViewFilterForm.value;
    if (!campaignFilterContext.tableColumns?.length) {
      campaignFilterContext.tableColumns = this.campaignViewColumns;
    }

    this.setAccordionFilterPlaceholder();

    this.campaignFilterChange.emit(
      omitBy(
        campaignFilterContext as ICampaignFilterContext,
        isUndefined
      ) as ICampaignFilterContext
    );
  }

  toggleColumnSelector() {
    const columnSelectorElement = this.columnSelector.el.nativeElement;
    if (columnSelectorElement.style.display === 'none') {
      columnSelectorElement.style.display = 'block';
    } else {
      columnSelectorElement.style.display = 'none';
    }
  }

  private setAccordionFilterPlaceholder() {
    this.accordionFilterPlaceholder = `Search ${
      this.campaignViewFilterForm.value.groupByDimension ===
      'compositeDataSourceName'
        ? 'data source'
        : this.campaignViewFilterForm.value.groupByDimension
    }`;
  }
}
