<div class="flex justify-between gap-3">
  <!-- Date Filter -->
  <portal-date-range-picker
    #datePicker
    label=""
    [containerClass]="'row'"
    class="saas-ui saas-ui-size-default"
    [datePickerInputStyleClass]="'p-inputtext'"
    [datePickerInputWidth]="'100%'"
    [hideDatePickerAfterApply]="true"
    [dateFormatType]="'MM-DD-YYYY'"
    [currentRangeOptions]="timePeriodOptions"
    [date]="selectedTimePeriod"
    (dateChanged)="onDateChange($event)"
    [maxDate]="maxDate"
    [availabilityInfoTextRequired]="false"></portal-date-range-picker>

  <!-- Services Checkbox -->
  <div>
    <m-multiselect
      [maxSelectedLabels]="1"
      [(ngModel)]="selectedServices"
      [isValid]="!errorMetrics"
      (ngModelChange)="onServicesChange($event)"
      [options]="serviceOptions"
      width="170px"
      height="32px"
      [selectedItemsLabel]="'{0} services selected'"></m-multiselect>
  </div>

  <!-- Search Input -->
  <span
    class="saas-input-icon-right saas-ui saas-ui-size-default"
    style="width: 13rem">
    <span class="material-symbols-outlined saas-icon text-blue"> search </span>
    <input
      id="dimension-search"
      type="text"
      pInputText
      placeholder="Search"
      autocomplete="off"
      (input)="onSearch($event)" />
  </span>
</div>
