@if (mimModelVersion === 1 && mmmTooltip.length > 0) {
  <div>
    <m-tooltip
      [content]="mmmTooltip"
      position="bottom"
      [tooltipOptions]="{
        positionTop: 15,
        showDelay: 400,
      }">
      <div class="mmm-banner">
        <div class="mmm-banner-shimmer"></div>
        <div class="mmm-banner-content">
          <img
            alt="MIM Sparkles"
            src="assets/images/home-page/sparkles.svg"
            width="23px"
            class="pr-2" />
          <div>
            MIM v{{ mimModelVersion }}:
            <span class="text-gray-1000">
              Powered by Incrementality Experiments & MMM
              <a
                class="text-blue-600 cursor-pointer relative z-1 ml-3"
                (click)="navigateToMmmDoc()"
                >Learn more</a
              >
            </span>
          </div>
        </div>
      </div>
    </m-tooltip>
  </div>
} @else {
  <div class="mmm-banner">
    <div class="mmm-banner-shimmer"></div>
    <div class="mmm-banner-content">
      <img
        alt="MIM Sparkles"
        src="assets/images/home-page/sparkles.svg"
        width="23px"
        class="pr-2" />
      <div>
        MIM v{{ mimModelVersion }}:
        <span class="text-gray-1000">
          Powered by Incrementality Experiments & MMM
          <a
            class="text-blue-600 cursor-pointer relative z-1 ml-3"
            (click)="showModalDetails()"
            >{{ shouldShowModal() ? "View model details" : "Learn more" }}</a
          >
        </span>
      </div>
    </div>
  </div>
}

@if (mimModelVersion === 2) {
  <portal-mmm-modal-details />
}
