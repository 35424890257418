<ng-container *ngIf="item" [ngSwitch]="item.layoutType">
  <portal-header
    *ngSwitchCase="'RESULT_HEADER'"
    [dashboardLiteralId]="literalId"
    [headerConfig]="headerConfig"
    [headerData]="dataPoints"
    [isEditMode]="isEditMode"
    [showEmptyData]="showEmptyData"
    (enterKeyClicked)="onEnterClicked($event)"
    (editableHeaderValue)="onEditableHeaderValue($event)">
  </portal-header>

  <portal-experiment-result-detail-section
    *ngSwitchCase="'DASHBOARD_DETAIL'"
    [item]="item"
    [fieldDefinitions]="
      fieldDefinitions
    "></portal-experiment-result-detail-section>

  <portal-experiment-result-data
    *ngSwitchCase="'EXPERIMENT_RESULT_DATA_SECTION'"
    [item]="item"
    [fieldDefinitions]="fieldDefinitions">
  </portal-experiment-result-data>

  <portal-title
    *ngSwitchCase="'TITLE'"
    [titleConfigs]="
      titleConfigTranslationService.prepareTitleConfig(
        item,
        dataPoints,
        fieldDefinitions
      )
    ">
  </portal-title>

  <portal-filter-set
    *ngSwitchCase="'FILTER_SET'"
    [name]="item.literalId"
    [elements]="nativeSectionsService.asFilterElements(item.elements)"
    class="item-content row py-2 {{ item.literalId }} m-0">
  </portal-filter-set>

  <portal-mmm-export-banner
    *ngSwitchCase="'HERO_BANNER'"
    [filters]="filters"
    [fieldDefinitions]="fieldDefinitions">
  </portal-mmm-export-banner>

  <portal-compare-mode-banner
    *ngSwitchCase="'COMPARE_BANNER'"></portal-compare-mode-banner>

  <!-- Hero Metrics -->
  <portal-hero-group
    *ngSwitchCase="'DATA_POINT'"
    class="item-content"
    [title]="nativeSectionsService.asString(item.label)"
    [name]="nativeSectionsService.asString(item.literalId)"
    [width]="item.span"
    [items]="nativeSectionsService.asHeroElements(item.elements)"
    [gridItem]="true"
    [brandId]="brandId"
    [filters]="filters"
    [fieldDefinitions]="fieldDefinitions"></portal-hero-group>

  <!-- Chart -->
  <div *ngSwitchCase="'DATA_CHART'" class="item-content">
    <portal-chart
      *ngIf="item.elements"
      [fieldDefinitions]="fieldDefinitions"
      [title]="item.label"
      [filterControls]="
        nativeSectionsService.asContextFields(item.widget.filterControls)
      "
      [elements]="nativeSectionsService.asChartElements(item.widget.elements)"
      [chartElement]="
        nativeSectionsService.asChartElement(item.widget.element)
      ">
    </portal-chart>
  </div>
  <div *ngSwitchCase="'DATA_CHART_GROUP'" class="item-content">
    <portal-chart-group
      [filters]="filters"
      [chartGroup]="nativeSectionsService.asChartGroupElements(item)"
      [fieldDefinitions]="fieldDefinitions">
    </portal-chart-group>
  </div>
  <!-- Table -->
  <ng-container *ngSwitchCase="'DATA_SET'">
    <portal-data-grid-group
      [widgetLayout]="item"
      [filterControls]="
        nativeSectionsService.asContextFields(item.widget.filterControls)
      "
      [fieldDefinitions]="fieldDefinitions"
      [filterSet]="filters">
    </portal-data-grid-group>
  </ng-container>

  <ng-container *ngSwitchCase="'CARD_COMPONENT_SECTION'">
    <portal-card-group
      class="card-item-content {{ item.literalId }}"
      [widgetLayout]="item"
      [filterSet]="filters"
      [fieldDefinitions]="fieldDefinitions">
    </portal-card-group>
  </ng-container>

  <!--  Metric Cards-->
  <ng-container *ngSwitchCase="'BASEBALL_CARD'">
    <portal-metric-card-group
      [item]="metricCardItems"
      [fieldDefinitions]="fieldDefinitions"
      [dashboardLiteralId]="literalId">
    </portal-metric-card-group>
  </ng-container>

  <ng-container *ngSwitchCase="'BACK_BAR'">
    <portal-back-bar
      [backBarConfig]="
        backBarLayoutConfigTranslationService.prepareBackBarConfig(item)
      ">
    </portal-back-bar>
  </ng-container>
  <portal-accordion-section
    *ngSwitchCase="'ACCORDION_SECTION'"
    [accordionItems]="item"
    [fieldDefinitions]="fieldDefinitions"
    [filters]="filters">
  </portal-accordion-section>

  <portal-bread-crumb
    *ngSwitchCase="'BREAD_CRUMB'"
    [layout]="item"
    [fieldDefinitions]="fieldDefinitions"></portal-bread-crumb>
</ng-container>
