export const chartFontFamily =
  'Inter, system-ui, -apple-system, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';

export const CHART_COLORS = [
  '#E66B43', // $orange
  '#3e70b5', // $Measured blue
  '#20C997', // $teal
  '#7a5195', // $indigo
  '#f7a35c',
  '#8085e9',
  '#f15c80',
  '#e4d354',
  '#2b908f',
  '#f45b5b',
  '#91e8e1'
];

export const GEO_NATIVE_CHART_COLORS = [
  ...CHART_COLORS,
  '#96c670',
  '#ffa600',
  '#e55c5c',
  '#5a94d8',
  '#6f42c1',
  '#ef5675',
  '#a2c7e9',
  '#6610f2',
  '#bd5091'
];
