import { Injectable } from '@angular/core';
import {
  Brand,
  CurrencyCode,
  CurrencyData,
  CurrencyFormat,
  CurrencySymbol
} from '@libs/common-services';
import { LocaleCode } from '@libs/common-services';

const DEFAULT_DISPLAY: CurrencySymbol = 'symbol';
const DEFAULT_CODE: CurrencyCode = 'USD';
const DEFAULT_LOCALE: LocaleCode = 'en-US';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  private currencyValue: CurrencyData = {
    locale: DEFAULT_LOCALE,
    display: DEFAULT_DISPLAY,
    code: DEFAULT_CODE
  };

  public updateCurrencyData(value: CurrencyData): void {
    this.currencyValue = value;
  }

  get currencyConfig(): CurrencyData {
    return this.currencyValue;
  }

  public checkCurrencySymbol(brand: Brand): CurrencySymbol {
    // find the value in the CurrencyFormatMap
    if (String(CurrencyFormat.shortCode) === brand.currencyFormat) {
      return 'code';
    }
    if (String(CurrencyFormat.symbol) === brand.currencyFormat) {
      return 'symbol';
    }
    if (String(CurrencyFormat.symbolNarrow) === brand.currencyFormat) {
      return 'symbol-narrow';
    }
    return 'symbol';
  }
}
