<div class="navbar fixed-top navbar-light bg-light pt-2">
  <a class="d-block mx-auto" routerLink="/">
    <img
      src="assets/images/measured-logo.svg"
      alt="logo"
      class="company-logo d-block mx-auto pb-2 img-fluid" />
  </a>
</div>
<div class="container skip-header terms-body">
  <div class="row">
    <div class="col-12">
      <h3><strong>Terms</strong><br /></h3>
      <p>
        Your use of Portal is governed by your Master Subscription and
        Consulting Services Agreement (or other similar agreement) with
        Measured, Inc (Measured). If you are not a Measured customer and do not
        have an agreement in place with Measured, you are not authorized to use
        Portal.
      </p>
      <h3><strong>Contact Us</strong><br /></h3>
      <p>
        If you have any questions about the Terms of Service, please contact us
        at
        <a href="mailto:info@measured.com">info&#64;measured.com</a>.
      </p>
    </div>
  </div>
</div>
