<m-card>
  <div
    header
    class="flex flex-col lg:flex-row justify-between items-start 2xl:items-center w-full">
    <div class="flex items-center">
      <span class="sh2 mr-2 mt-2">CAC</span>
      <span class="c1 mr-2 mt-2">vs</span>

      <m-dropdown
        class="mr-2 mt-2"
        [options]="ltvOptions"
        [(ngModel)]="ltvValue"
        (formControlChange)="onLtvDimensionChange($event?.value)"
        [disabled]="isLoading"></m-dropdown>
    </div>
    <div class="flex items-center">
      <span class="sh2 mr-2 mt-2">Starting</span>

      <m-dropdown
        class="min-w-[200px] mt-2"
        [options]="startDateOptions"
        [(ngModel)]="startDateValue"
        (formControlChange)="onStartDateChange($event?.value)"
        [disabled]="isLoading"></m-dropdown>
    </div>
  </div>
  <div class="mt-4">
    <!-- Loading state -->
    @if (!isChartReady && isLoading) {
      <m-skeleton width="100%" height="300px"></m-skeleton>
    }
    @if (!isChartReady && isLoading) {
      <div class="mt-3 flex flex-wrap justify-center gap-2">
        @for (item of [].constructor(4); track item; let i = $index) {
          <m-skeleton width="5rem" height="1rem"></m-skeleton>
        }
      </div>
    }

    <!-- Error state -->
    @if (!isLoading && !isDataAvailable) {
      <m-not-available></m-not-available>
    }
    @if (isChartReady && isDataAvailable) {
      <div>
        <!-- Chart section -->
        <m-basic-column-chart
          class="w-full"
          [series]="basicColumnChartOptions?.series"
          [xAxis]="basicColumnChartOptions?.xAxis"
          [yAxis]="basicColumnChartOptions?.yAxis"
          [legend]="basicColumnChartOptions?.legend"
          [tooltip]="basicColumnChartOptions?.tooltip"
          [plotOptions]="basicColumnChartOptions?.plotOptions"
          [minWidth]="minWidthValue"></m-basic-column-chart>
        <div class="flex justify-center w-full mt-2 lh-1">
          <m-tooltip
            content="<div class='b1 text-gray-000 text-center'>For {{
              lastMonth
            }}</div>"
            position="top"
            [tooltipOptions]="{
              positionTop: -10,
            }">
            <span class="c3 text-gray-1000"
              >CAC: {{ tooltipData?.currentCAC }}</span
            ></m-tooltip
          >
          <p-divider layout="vertical"></p-divider>
          <m-tooltip
            content="<div class='b1 text-gray-000 text-center'>Change ({{
              lastMonth
            }} vs {{ previousToLastMonth }})</div>"
            position="top"
            [tooltipOptions]="{
              positionTop: -10,
              positionLeft: 7,
            }">
            <m-tag
              [isPositive]="tooltipData?.isPercCACUp"
              [isUp]="tooltipData?.isPercCACUp"
              [showBackground]="false">
              {{ tooltipData?.percCAC }}
            </m-tag>
          </m-tooltip>
          <m-tooltip
            content="<div class='b1 text-gray-000 text-center'>For {{
              lastMonth
            }}</div>"
            position="top"
            [tooltipOptions]="{
              positionTop: -10,
            }">
            <span class="c3 text-gray-1000 pl-6"
              >LTV: {{ tooltipData?.currentLTV }}</span
            ></m-tooltip
          >
          <p-divider layout="vertical"></p-divider>
          <m-tooltip
            content="<div class='b1 text-gray-000 text-center'>Change ({{
              lastMonth
            }} vs {{ previousToLastMonth }})</div>"
            position="top"
            [tooltipOptions]="{
              positionTop: -10,
              positionLeft: 7,
            }">
            <m-tag
              [isPositive]="tooltipData?.isPercLTVUp"
              [isUp]="tooltipData?.isPercLTVUp"
              [showBackground]="false">
              {{ tooltipData?.percLTV }}
            </m-tag></m-tooltip
          >
        </div>
      </div>
    }
  </div>
</m-card>
