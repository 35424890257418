/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Types
import { SelectItem } from 'primeng/api';

// consts
const DEFAULT_CHANNELS = [
  'Search',
  'Social',
  'Display',
  'Affiliate',
  'SMS',
  'Email'
];

@Injectable({
  providedIn: 'root'
})
export class ChannelsService {
  private channelsSubject = new BehaviorSubject<string[]>([]);
  channels$ = this.channelsSubject.asObservable();

  private selectedChannelsSubject = new BehaviorSubject<string[]>(
    DEFAULT_CHANNELS
  );

  selectedChannels$ = this.selectedChannelsSubject.asObservable();

  private activeChannelSubject = new BehaviorSubject<string>('');
  activeChannel$ = this.activeChannelSubject.asObservable();

  setAllChannels(newChannels: string[]): void {
    this.channelsSubject.next(newChannels);
  }

  getAllChannels(): string[] {
    return this.channelsSubject.getValue();
  }

  setSelectedChannels(newChannels: string[]): void {
    this.selectedChannelsSubject.next(newChannels);
  }

  getSelectedChannels(): string[] {
    return this.selectedChannelsSubject.getValue();
  }

  getActiveChannel(): string {
    return this.activeChannelSubject.getValue();
  }

  /**
   * Sets the active channel string and updates the BehaviorSubject.
   *
   * @param newActiveChannel - The new active channel string to be set.
   */
  setActiveChannel(newActiveChannel: string): void {
    this.activeChannelSubject.next(newActiveChannel);
  }

  getChannelOptions(): SelectItem[] {
    const channels = this.channelsSubject.getValue();
    return channels.map((channel) => ({ label: channel, value: channel }));
  }
}
