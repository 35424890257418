<div class="button-container">
  <div class="btn-group">
    @for (item of options; track item) {
      <button
        [ngClass]="
          item === filter.value
            ? 'btn-active btn btn-secondary btn-grp'
            : 'btn btn-secondary btn-grp'
        "
        type="button"
        (click)="isCustomValue = false; filter.value = item; onValueChange()">
        {{ item }}
      </button>
    }
  </div>
  @if (shouldShowCustom()) {
    <div class="d-flex flex-row custom-input-container">
      <input
        type="radio"
        #rad
        (click)="isCustomValue = true"
        [checked]="isCustomValue"
        [value]="true"
        [name]="literalId" />
      <span class="m-1">Custom</span>
      @if (isCustomValue === true) {
        <input
          class="custom-input"
          type="number"
          [(ngModel)]="filter.value"
          (ngModelChange)="onValueChange()" />
        <span class="days">Days</span>
      }
    </div>
  }
</div>
