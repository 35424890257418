import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import dayjs from 'dayjs';

//Services
import { DateTimeService } from '@portal/app/shared/services/date-time.service';

@Injectable({
  providedIn: 'root'
})
export class MyCustomersService implements OnDestroy {
  private readonly conversionTypeChangeSubject = new BehaviorSubject<string>(
    ''
  );

  public conversionTypeChange$ =
    this.conversionTypeChangeSubject.asObservable();

  private destroy$ = new Subject<void>();

  constructor(private dateTimeService: DateTimeService) {}

  updateCustomerConversionType(conversionType: string): void {
    this.conversionTypeChangeSubject.next(conversionType);
  }

  getLastMonth(date: string) {
    const someDate = new Date(
      this.dateTimeService.getLastDayAtSixthMonth(date)
    );
    const monthName = new Intl.DateTimeFormat('en-US', {
      month: 'long'
    }).format(someDate);
    const year = someDate.getFullYear().toString().slice(-2);
    return `${monthName} '${year}`;
  }

  getPreviousToLastMonth(date: string) {
    const someDate = new Date(
      this.dateTimeService.getLastDayAtSixthMonth(date)
    );
    someDate.setDate(0);
    const monthName = new Intl.DateTimeFormat('en-US', {
      month: 'long'
    }).format(someDate);
    const year = someDate.getFullYear().toString().slice(-2);
    return `${monthName} '${year}`;
  }

  // Handles cleanup when the service is destroyed
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  prepareStartDateOptions(dates: string[]) {
    return dates.map((date) => {
      const label = dayjs.utc(date).format('MMM YYYY');
      return { label, value: date };
    });
  }
}
