@if (isLoading()) {
  <!-- Loading Skeletons -->
  <div>
    <div class="pl-4 flex">
      <m-skeleton class="mx-1" width="174px" height="122px"></m-skeleton>
      <m-skeleton class="mx-1" width="174px" height="122px"></m-skeleton>
      <m-skeleton class="mx-1" width="174px" height="122px"></m-skeleton>
      <m-skeleton class="mx-1" width="174px" height="122px"></m-skeleton>
      <m-skeleton class="mx-1" width="174px" height="122px"></m-skeleton>
    </div>
    <!-- Loading skeletons for each tab content -->
    <div class="grid grid-cols-3 gap-4 p-4">
      <m-skeleton width="100%" height="366px"></m-skeleton>
      <m-skeleton width="100%" height="366px"></m-skeleton>
      <m-skeleton width="100%" height="366px"></m-skeleton>
    </div>
  </div>
} @else if (!isDataAvailable()) {
  <!-- Error State -->
  <m-not-available *ngIf="!isLoading() && !isDataAvailable()"></m-not-available>
} @else {
  <!-- Tab View -->
  <p-tabView class="m-tabview contained" (onChange)="onTabChange($event)">
    <ng-container *ngFor="let metric of data(); let i = index">
      <p-tabPanel>
        <ng-template pTemplate="header">
          <div class="m-tabview-header h-100">
            <!-- Tab header content -->
            <m-metric-amount-header
              [title]="metric.label"
              [amount]="metric.formattedValue"
              [prevPercDifference]="metric.compareValue"
              [prevPercDifferenceFormattedValue]="metric.compareFormattedValue"
              [isPositive]="metric.changeStatus === 'POSITIVE'"
              [isNeutral]="metric.changeStatus === 'NEUTRAL'"
              [isUp]="metric.arrowDirection === 'UP'">
            </m-metric-amount-header>
          </div>
        </ng-template>
        <!-- Tab body content -->
        @if (activeTabIndex() === i) {
          <div class="grid grid-cols-3 gap-4">
            <portal-top-media-chart
              [title]="metric.label"></portal-top-media-chart>
            <portal-context-compare-chart></portal-context-compare-chart>
            <portal-context-trending-chart></portal-context-trending-chart>
          </div>
        }
      </p-tabPanel>
    </ng-container>
  </p-tabView>
}
