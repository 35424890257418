/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil
} from 'rxjs/operators';
import { isEqual } from 'lodash-es';

// Services
import { ApiService } from '@portal/app/shared/services/api.service';
import { DateTimeService } from '@portal/app/shared/services/date-time.service';
import { FilterService } from '@portal/app/dashboard/home-page/services/filter.service';
import { FieldService } from '@portal/app/shared/services/field.service';
import { FormatService } from '@portal/app/dashboard/home-page/services/format.service';
import { MyCustomersService } from '@portal/app/dashboard/home-page/components/my-customers/my-customers.service';

import { defaultConfigs } from '@design-system/components/m-basic-column-chart/m-basic-column-chart.component.utils';

import {
  DataEndpoint,
  Filter,
  FieldDefinitions,
  DataSetResponse
} from '@portal/app/shared/types';
import {
  AxisLabelsFormatterContextObject,
  Options,
  SeriesOptionsType,
  TooltipOptions,
  YAxisOptions
} from 'highcharts';
import dayjs from 'dayjs';
import { formatPercent } from '@angular/common';
import {
  CHART_AXIS_FORMAT_MONTHYEAR,
  formatDateForChart
} from '@portal/app/charts/shared/utils';

export interface TooltipData {
  currentCAC: string;
  currentLTV: string;
  percCAC: string;
  percLTV: string;
  isPercCACUp: boolean;
  isPercLTVUp: boolean;
}

@Injectable()
export class CacVsLtvService implements OnDestroy {
  // Chart options state
  private readonly chartDataSubject = new BehaviorSubject<any>(null);
  public chartData$ = this.chartDataSubject.asObservable();
  public ltvCacRatioArray: number[] = [];

  // Chart filters state
  private readonly chartDimensionsSubject = new BehaviorSubject<string[]>([
    'cac',
    'ltv6Months'
  ]);

  public chartDimensions$ = this.chartDimensionsSubject.asObservable();

  private readonly dataPointsSubject = new BehaviorSubject<any>(null);

  public dataPoints$ = this.dataPointsSubject.asObservable();

  private readonly dateValueSubject = new BehaviorSubject<string[]>(['', '']);

  public dateValue$ = this.dateValueSubject.asObservable();

  // Local filters state
  public currentFilters$ = new BehaviorSubject<Filter[]>([]);

  private readonly currentFields$ = new BehaviorSubject<FieldDefinitions>({});

  // Cleanup
  private destroy$ = new Subject<void>();

  // Lodaing state
  private readonly isLoadingSubject = new BehaviorSubject<boolean>(false);
  public isLoading$ = this.isLoadingSubject.asObservable();

  // Default chart config
  basicColumnChartOptions?: Options = defaultConfigs;

  minWidth = 216;

  ltvDimesnionValue = 'ltv6Months';

  private readonly GROUP_WIDTH = 32;
  private readonly COLUMN_SPACING = 4;

  constructor(
    private readonly apiService: ApiService,
    public readonly dateTimeService: DateTimeService,
    private readonly filterService: FilterService,
    private readonly fieldService: FieldService,
    private readonly formatService: FormatService,
    private readonly myCustomersService: MyCustomersService
  ) {
    this.subscribeToChanges();
  }

  private subscribeToChanges(): void {
    combineLatest([
      this.chartDimensions$,
      this.dateValue$,
      this.fieldService.fields$,
      this.filterService.filters$,
      this.myCustomersService.conversionTypeChange$
    ])
      .pipe(
        map(
          ([
            chartDimensions,
            dateValue,
            fields,
            filters,
            customerSectionConversionType
          ]) => ({
            chartDimensions,
            dateValue,
            fields,
            filters,
            customerSectionConversionType
          })
        ),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        debounceTime(300),
        takeUntil(this.destroy$)
      )
      .subscribe(
        ({
          chartDimensions,
          dateValue,
          fields,
          filters,
          customerSectionConversionType
        }) => {
          this.currentFields$.next(fields);
          this.currentFilters$.next(filters);
          this.fetchAndSetChartData(
            chartDimensions,
            dateValue,
            fields,
            filters,
            customerSectionConversionType
          );
        }
      );
  }

  setChartDimensions(dimension: string): void {
    this.ltvDimesnionValue = dimension;
    const dimensions = ['cac', this.ltvDimesnionValue];
    this.chartDimensionsSubject.next(dimensions);
  }

  setDatevalue(dimensions: string[]): void {
    this.dateValueSubject.next(dimensions);
  }

  getChartFilters(
    chartDimensions: string[],
    dateValue: string[],
    customerSectionConversionType: string,
    filters?: Filter[]
  ): Filter[] {
    const currentFilters = this.currentFilters$.getValue();
    const combinedFilters = filters
      ? [...currentFilters, ...filters]
      : [...currentFilters];
    // Define which filters are needed for charts
    const neededFilterIds = ['isInitialFilterCall'];

    // Select only necessary filters from combinedFilters
    const selectedFilters = combinedFilters.filter((filter) =>
      neededFilterIds.includes(filter.literalId)
    );

    // Hardcoded filters
    const hardcodedFilters = [
      this.filterService.createFilter({
        literalId: 'chartDimensions',
        type: 'string[]',
        value: chartDimensions
      }),
      this.filterService.createFilter({
        literalId: 'charts',
        type: 'string[]',
        value: ['ltvVsCacChartData']
      }),
      this.filterService.createFilter({
        literalId: 'resolution',
        type: 'string',
        value: 'month'
      }),
      this.filterService.createFilter({
        literalId: 'dateStart',
        type: 'string',
        value: dateValue[0] as string
      }),
      this.filterService.createFilter({
        literalId: 'dateStop',
        type: 'string',
        value: dateValue[1] as string
      }),
      this.filterService.createFilter({
        literalId: 'ltvDimension',
        type: 'string',
        value: this.ltvDimesnionValue
      }),
      this.filterService.createFilter({
        literalId: 'conversion_type',
        type: 'string',
        value: customerSectionConversionType
      })
    ];

    // Combine selected provided filters with hardcoded filters
    const combinedFiltersMap = new Map(
      selectedFilters
        .concat(hardcodedFilters)
        .map((filter) => [filter.literalId, filter])
    );

    return Array.from(combinedFiltersMap.values());
  }

  private fetchAndSetChartData(
    chartDimensions: string[],
    dateValue: string[],
    fields: FieldDefinitions,
    filters: Filter[],
    customerSectionConversionType: string
  ): void {
    this.isLoadingSubject.next(true);

    const chartFilters = this.getChartFilters(
      chartDimensions,
      dateValue,
      customerSectionConversionType,
      filters
    );

    this.apiService
      .getDashboardData({
        filters: chartFilters,
        type: DataEndpoint.dataCharts
      })
      .then((response) => {
        const formattedChartData = this.formatChartData(
          response,
          fields,
          chartDimensions
        );
        this.updateDataPointsValue(response as DataSetResponse[]);
        this.chartDataSubject.next(formattedChartData);
        this.isLoadingSubject.next(false);
      })
      .catch((error) => {
        console.error('Error fetching chart data:', error);
        this.chartDataSubject.next({});
        this.isLoadingSubject.next(false);
      });
  }

  formatSeriesData(series: SeriesOptionsType[]): SeriesOptionsType[] {
    // Initialize basicColumnChartOptions and its series array if not already initialized
    this.basicColumnChartOptions = this.basicColumnChartOptions || {
      series: []
    };

    // Update or add new series data
    const newSeries = series?.map((newSeriesData, index) => {
      // Ensure existing series data is an object
      const existingSeriesData =
        this.basicColumnChartOptions?.series?.[index] ?? {};

      // Update series data if new data is available
      if ('data' in newSeriesData && Array.isArray(newSeriesData?.data)) {
        return {
          ...existingSeriesData,
          data: newSeriesData?.data
        };
      }
      return existingSeriesData;
    });

    return newSeries as SeriesOptionsType[];
  }

  formatYAxis(series: SeriesOptionsType[], chartDimensions: string[]) {
    const formatService = this.formatService;
    const fieldService = this.fieldService;

    // Initialize basicColumnChartOptions and its series array if not already initialized
    this.basicColumnChartOptions = this.basicColumnChartOptions || {
      series: []
    };

    // Pre-calculate series min/max values
    const seriesMinMax = series?.map((s: SeriesOptionsType) => {
      if ('data' in s && Array.isArray(s?.data)) {
        const seriesData = s?.data as number[];
        const minValue = Math.min(...seriesData);
        const maxValue = Math.max(...seriesData);
        return { min: minValue, max: maxValue };
      }
      return { min: 0, max: 0 };
    });

    // Extract all min and max values into separate arrays
    const allMinValues = seriesMinMax.map((obj) => obj.min);
    const allMaxValues = seriesMinMax.map((obj) => obj.max);

    // Find the minimum and maximum values from these arrays
    const leastValue = Math.min(...allMinValues);
    const largestValue = Math.max(...allMaxValues);

    // Create a new array with the minimum and maximum values
    const finalArray = [{ min: leastValue, max: largestValue }];

    const yAxisFormatters = chartDimensions?.map((dimension: string) => {
      return function (this: AxisLabelsFormatterContextObject) {
        const value = this?.value as number;
        const fieldDefinition =
          fieldService.getFieldDefinitionByLiteralId(dimension);
        if (!fieldDefinition) {
          return value.toString(); // Ensure string is returned
        }
        return formatService.formatValue(fieldDefinition, value).toString(); // Convert formatted value to string
      };
    });

    // Handle yAxis options
    const yAxisOptions = Array.isArray(this.basicColumnChartOptions?.yAxis)
      ? this.basicColumnChartOptions?.yAxis
      : [this.basicColumnChartOptions?.yAxis || {}];

    const updatedYAxis = yAxisOptions?.map((yAxis, index) => {
      const { min, max } = finalArray[index] as { min: number; max: number };
      return {
        ...yAxis,
        min,
        max,
        labels: {
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          formatter: yAxisFormatters[index] || (() => {})
        }
      };
    });

    return updatedYAxis;
  }

  formatTooltip(ltvCacRatioArray: number[]): TooltipOptions {
    const formatService = this.formatService;
    const fieldService = this.fieldService;

    return {
      useHTML: true,
      outside: true,
      distance: 15,
      formatter() {
        if (this && typeof this.x === 'string' && this.y) {
          const fieldDef = fieldService.getFieldDefinitionByName(
            this.series.name
          );
          const formattedValue = formatService.formatValue(fieldDef, this.y);
          return `
            <div class="tooltip-body">
                <div class="b2 text-gray-500 text-center">${formatDateForChart(
                  dayjs.utc(this?.x, CHART_AXIS_FORMAT_MONTHYEAR).valueOf(),
                  'Month'
                )}</div>
                <div class="m-divider my-2 text-gray-900"></div>
                <div class="flex justify-between w-full">
                    <span class="b1 text-gray-000">${
                      this.point.series.name
                    }</span>
                    <span class="b1 text-gray-000">${formattedValue}</span>
                </div>
                <div class="flex justify-between w-full">
                    <span class="b1 text-gray-000">LTV/CAC Ratio</span>
                    <span class="b1 text-gray-000">${
                      ltvCacRatioArray[this.point.index]
                    }</span>
                </div>
            </div>`;
        } else {
          return ``;
        }
      },
      style: {
        zIndex: 10
      }
    };
  }

  formatChartData(
    response: any,
    fields: FieldDefinitions,
    chartDimensions: string[]
  ): Options {
    // Before returning this.basicColumnChartOptions, ensure it's not undefined
    this.basicColumnChartOptions = this.basicColumnChartOptions || {
      series: [],
      yAxis: {},
      xAxis: {}
    }; // Provide a minimal valid Options object

    const dataArray = response[0]?.data || [];
    // Create a series for each dimension
    const series: SeriesOptionsType[] = chartDimensions?.map(
      (dimension: string) => ({
        type: 'column',
        name: fields[dimension]?.label,
        data: dataArray?.map((item: any) => item[dimension] || 0)
      })
    );
    const seriesData = series.slice(0, 2); //TODO as it has tooltip data also slicing it
    // Format series data
    const newSeries = this.formatSeriesData(seriesData);

    //Format cacLtvRatioData
    this.ltvCacRatioArray = this.generateLTVCACRatioArray(
      dataArray
    ) as unknown as number[]; // Remove null values from the array

    // Format yxis
    const yAxis = this.formatYAxis(seriesData, chartDimensions);

    // Format tooltip
    const tooltip = this.formatTooltip(this.ltvCacRatioArray);

    // Format categories
    const categories = dataArray.map((item: any) =>
      formatDateForChart(item.date, 'AbbrMonthYear')
    );

    this.setMinWidth(categories.length);

    // Update the chart options
    this.basicColumnChartOptions = {
      ...this.basicColumnChartOptions,
      series: newSeries as SeriesOptionsType[],
      xAxis: {
        ...this.basicColumnChartOptions?.xAxis,
        categories
      },
      yAxis: yAxis as YAxisOptions | YAxisOptions[],
      tooltip,
      plotOptions: {
        ...this.basicColumnChartOptions?.plotOptions,
        column: {
          ...this.basicColumnChartOptions?.plotOptions?.column,
          groupPadding: this.calculateGroupPadding(categories.length)
        }
      }
    };

    return this.basicColumnChartOptions as Options;
  }

  public calculateGroupPadding(categoriesCount: number): number {
    // Coefficients derived from linear regression analysis of given datasets
    const slope = -0.01828;
    const intercept = 0.50468;

    // Calculate the group padding using the linear formula
    const groupPadding = slope * categoriesCount + intercept;

    // Round the result to three decimal places and return as number
    return parseFloat(groupPadding.toFixed(3));
  }

  public setMinWidth(categoriesCount: number) {
    this.minWidth =
      categoriesCount * this.GROUP_WIDTH +
      categoriesCount * this.COLUMN_SPACING;
  }

  public getMinWidth() {
    return this.minWidth;
  }

  updateDataPointsValue(response: DataSetResponse[]) {
    const dataPoints = response[0]?.dataPoints || {};
    const formattedData = this.formatTooltipData(
      dataPoints as unknown as TooltipData
    );
    this.dataPointsSubject.next(formattedData);
  }

  generateLTVCACRatioArray(data: []) {
    return data
      .map((item: any) => {
        const ltvCacRatio = item?.ltvCacRatio;

        if (typeof ltvCacRatio === 'number' && !isNaN(ltvCacRatio)) {
          return ltvCacRatio.toFixed(2);
        }
        return null;
      })
      .filter((value: any) => value !== null);
  }

  formatTooltipData(data: TooltipData) {
    return {
      currentCAC: this.formatCacLtvValue(
        'cac',
        data.currentCAC as unknown as number
      ),
      currentLTV: this.formatCacLtvValue(
        'ltv3Months',
        data.currentLTV as unknown as number
      ),
      percCAC: formatPercent(
        Math.abs(Number(data.percCAC)),
        'en-US',
        '1.0-2'
      ) as string,
      percLTV: formatPercent(
        Math.abs(Number(data.percLTV)),
        'en-US',
        '1.0-2'
      ) as string,
      isPercCACUp: (data.percCAC as unknown as number) > 0 ? true : false,
      isPercLTVUp: (data.percLTV as unknown as number) > 0 ? true : false
    };
  }

  formatCacLtvValue(literalId: string, value: number): string {
    const fieldDef = this.fieldService.getFieldDefinitionByLiteralId(literalId);
    const formattedValue = this.formatService.formatValue(fieldDef, value);
    return formattedValue;
  }

  // Handles cleanup when the service is destroyed
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
