<portal-title-bar
  [title]="group?.label"
  [filterControls]="filterControls"
  (filterChanged)="setGlobalFilterValues()"></portal-title-bar>
<div class="sh1 text-gray-800" *ngIf="group.literalId === 'dataSummary'">
  These settings are strictly for viewing hypothetical situations. They will not
  be saved.
</div>
@if (literalId === "cross-channel") {
  <portal-taxonomy-banner></portal-taxonomy-banner>
}
<ng-container *ngFor="let element of selectedElements">
  <ng-container [ngSwitch]="tableTypes[element.literalId]">
    <portal-data-grid
      *ngSwitchCase="'SIMPLE'"
      [title]="element?.label || ''"
      [name]="element?.literalId"
      [literalId]="literalId"
      [fieldDefinitions]="fieldDefinitions"
      [element]="element"
      [filterable]="true"
      [filterSet]="filterSet"
      [filterControls]="filterControls"
      [dimensionPickerEnabled]="isCrossChannelDashboards(literalId)"
      [metricPickerEnabled]="isCrossChannelDashboards(literalId)"
      [data]="data"
      [groupByRowFooter]="groupByRowFooter"
      [dataGridConfig]="dataGridConfig"
      (persistingFilters)="updateConfigForFilterPersistance($event)"
      [persistedFilters]="breakdownFiltersPersistanceData"
      [showChildSection]="showChildSection"
      class="item-content pt-3"
      [ngClass]="{
        'item-content': true,
        'pt-3':
          'result' !== nativeSectionsService.getClassByLiteralId(literalId),
      }">
    </portal-data-grid>
    <portal-data-grid-pivot
      *ngSwitchCase="'PIVOT'"
      [title]="element?.label || ''"
      [name]="element?.literalId"
      [fieldDefinitions]="fieldDefinitions"
      [element]="element"
      [filterable]="true"
      [filterControls]="filterControls"
      [dataGridConfig]="dataGridConfig"
      [data]="data"
      class="item-content pt-3"
      [ngClass]="{
        'item-content': true,
        'pt-3':
          'result' !== nativeSectionsService.getClassByLiteralId(literalId),
      }">
    </portal-data-grid-pivot>
  </ng-container>
</ng-container>
