<div class="flex justify-between items-center w-full">
  <div class="flex items-center">
    <h2 class="dp2 text-gray-1000 mb-0">My Customers</h2>
    @if (isTableauCustomerReportingDashboardAvailable) {
      <div
        class="b1 text-blue-600 cursor-pointer pl-4 mt-1"
        (click)="navigateToCustomerReporting()">
        View details
      </div>
    }
  </div>

  <div class="flex items-center">
    <m-dropdown
      [options]="conversionTypeOptions"
      [(ngModel)]="conversionType"
      (formControlChange)="onConversionTypeChange($event?.value)"
      class="mr-2 min-w-[210px]"></m-dropdown>
  </div>
</div>
<div class="grid grid-cols-12 gap-4 mt-4 h-full">
  <!-- Customers / Revenue -->
  <div class="col-span-12 2xl:col-span-5 h-full">
    <portal-new-vs-returning-customers></portal-new-vs-returning-customers>
  </div>

  <!-- LTV CAC -->
  <div class="col-span-12 2xl:col-span-7 h-full">
    <portal-cac-vs-ltv></portal-cac-vs-ltv>
  </div>
</div>
