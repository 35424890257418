import { Component, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonDataShareService {
  public childToParentState = new BehaviorSubject<IChildToParentState>({});
}

export interface IChildToParentState {
  isSwitchEnabled?: Record<string, { isEnabled: boolean }>;
  isSearchEnabled?: boolean;
  emitLatestToChildState?: boolean;
  component?: Component;
}
