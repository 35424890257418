import { ITitle } from '@portal/app/shared/models/ITitle';
import { FormatterService } from '@portal/app/shared/services/formatter.service';
import { Injectable } from '@angular/core';
import { NativeSectionsService } from '@portal/app/shared/services/native-sections.service';
import {
  DataResponse,
  DataRowData,
  ElementGroup,
  FieldDefinition,
  FieldDefinitions,
  TitleElement
} from '@portal/app/shared/types';
import {
  HeadingTypes,
  TitleTypes
} from '@portal/app/shared/constants/constants';

@Injectable({
  providedIn: 'root'
})
export class TitleConfigTranslationService {
  constructor(
    public readonly nativeSectionsService: NativeSectionsService,
    protected readonly formatterService: FormatterService
  ) {}

  public prepareTitleConfig(
    item: ElementGroup,
    data: DataResponse,
    fieldDefinitions: FieldDefinitions
  ): ITitle[] {
    if (!item) {
      return [];
    }
    return (
      item.elements.sort(
        this.nativeSectionsService.sortByDisplayOrder
      ) as TitleElement[]
    ).map((title) => {
      const titleData = this.prepareValue(title, data, fieldDefinitions);
      return {
        title: titleData.label,
        subTitle: titleData.value,
        type: this.getType(title.layoutType)
      } as ITitle;
    });
  }

  prepareValue(
    element: TitleElement,
    data: DataResponse,
    fieldDefinitions: FieldDefinitions
  ): { label: DataRowData; value: DataRowData } {
    const literalId = element.literalId;
    const field = fieldDefinitions[literalId];
    const value =
      data && data[literalId]
        ? this.formatterService.formatPartialValue(
            field as FieldDefinition,
            data[literalId] as string | number | null
          )
        : null;
    const label: DataRowData = fieldDefinitions[literalId]
      ? fieldDefinitions[literalId]?.label || ''
      : element.label;
    return { label, value };
  }

  getType(layoutType: string): string {
    const type: Record<string, TitleTypes> = {
      [HeadingTypes.heading]: TitleTypes.h3,
      [HeadingTypes.subHeading]: TitleTypes.h4,
      [HeadingTypes.infoHeading]: TitleTypes.info,
      [HeadingTypes.labelValueHeading]: TitleTypes.titleWithSubTitle
    };
    return type[layoutType] as string;
  }
}
