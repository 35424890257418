import { UserRole, UserType } from '@portal/app/shared/models/userModel';
import { LocaleCode } from '@portal/app/shared/types/locale-codes';
import {
  CurrencyCode,
  CurrencySymbol
} from '@portal/app/shared/types/currency-format';
import { SelectionService } from '@portal/app/shared/services/selection.service';
import { OktaAuthOptions } from '@okta/okta-auth-js';
import { IUserAccess } from '@libs/apis';

interface AngularEnvironment {
  production: boolean;
  stage: boolean;
  innovation: boolean;
  clientVersion: string;
  apiV1: string;
  apiV2: string;
  apiDomain: null | string;
  authToken?: null | string;
  ssoProvider: SSOProvider;
  oktaConfig: OktaAuthOptions & { learnUponAppId: string };
  awsConfig: {
    issuer: string;
    clientId: string;
    redirectUri: string;
  };
  gtmId: string | null;
}

export enum SSOProvider {
  aws = 'AWS',
  okta = 'OKTA'
}

interface SelectionSlug {
  clientId: number;
  brandId: number;
  brandLabel: string;
}

interface PermissionResponse {
  clients: Client[];
  role: UserRole;
  userType: UserType;
}

interface LogResponse {
  logId: string;
}

interface LogRequestParams {
  appId: 'portal';
  message: string;
  subType: string;
}

interface SendEmailParams {
  to?: string[];
  subject?: string;
  emailBody?: string;
  from?: string;
}

interface SendEmailResponse {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ResponseMetadata: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    RequestId: string;
  };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  MessageId: string;
}

interface Client {
  readonly clientId: number;
  readonly clientName: string;
  readonly clientType: ClientType;
  readonly referenceId: number;
  readonly referenceIdLiteral: string;
  readonly cloudAccountId: string | null;
  readonly status: CurrentStatus;
  readonly rCreId: string;
  readonly rCreTime: Date;
  readonly rModId: string | null;
  readonly rModTime: Date | null;
  readonly brands: Brand[];
  readonly industry?: string;
  readonly geography?: string;
  readonly clientBucketName?: string;
}

interface Brand {
  [index: string]: string | number | Date | null;
  readonly brandId: number;
  readonly brandName: string;
  readonly clientId: number;
  readonly status: CurrentStatus;
  readonly currencyFormat: CurrencySymbol | null;
  readonly currencyLocale: LocaleCode | null;
  readonly currencyCode: CurrencyCode | null;
  readonly firstDayOfWeek: number;
  readonly rCreId: string;
  readonly rCreTime: Date;
  readonly rModId: string | null;
  readonly rModTime: Date | null;
}

interface BrandRow {
  [index: string]: string | number | CurrentStatus | Client | Brand | null;
  clientId: number;
  brandId: number;
  name: string;
  status: CurrentStatus;
  client: Client;
  brand: Brand;
}

// eslint-disable-next-line no-shadow
enum ClientType {
  enterprise = 'ENTERPRISE'
}

// eslint-disable-next-line no-shadow
enum CurrentStatus {
  // noinspection JSUnusedGlobalSymbols
  active = 'ACTIVE',
  inactive = 'INACTIVE',
  draft = 'DRAFT',
  ready = 'READY'
}

interface JustBrand {
  brand: Brand | null;
}

interface User {
  readonly role: UserRole;
  clientIds: readonly string[];
  brandIds: readonly string[];
  readonly userType: UserType;
  readonly userName: string;
  readonly email: string;
  readonly authTime: number;
  readonly expiryTime: number;
  readonly roles?: IUserAccess[];
  readonly name?: string;
}

interface UserDetailsInterface {
  user: User;
  getRole(): string;
  getClientIds(): number[];
  getBrandIds(): number[];
  getUsername(): string;
  getEmail(): string;
  getUserType(): User['userType'];
  getClientName(selectionService: SelectionService): string;
  userDetails(selectionService: SelectionService): UserDetails;
}

interface UserDetails {
  [index: string]: string;

  id: string;
  email: string;
  role: string;
  userType: string;
  multiBrandAccess: string;
}

interface Product {
  id: string;
  name: string;
}

interface ConfigDashboard {
  status: CurrentStatus;
  rCreId: string;
  rCreTime: string;
  rModId: string | null;
  rModTime: string | null;
  id: number;
  displayName: string;
  literalId: string;
}

interface ConfigDashboardInstance {
  status: CurrentStatus;
  rCreId: string;
  rCreTime: string;
  rModId: string | null;
  rModTime: string | null;
  id: number;
  displayName: string;
  literalId: string;
  clientId: number;
  brandId: number | null;
  userId: string | null;
  configDashboard: ConfigDashboard;
}

interface NativeDashboard {
  status: CurrentStatus;
  rCreId: string;
  rCreTime: string;
  rModId: string | null;
  rModTime: string | null;
  id: number;
  dashboardId: number;
  clientId: number;
  brandId: number;
  displayName: string;
  dashboard: NativeDashboardInstance;
}

interface NativeDashboardInstance {
  status: CurrentStatus;
  rCreId: string;
  rCreTime: string;
  rModId: string | null;
  rModTime: string | null;
  id: number;
  literalId: string;
  productId: string;
  displayName: string;
  product: Product;
  dashboardType?: string;
  dashboardPersistenceMode: string;
}

interface Dashboard {
  [index: string]: string | number | null | undefined;
  readonly dashboardId: number; // id -> dashboardId
  readonly productId: string | null; // unused
  readonly clientId: number; // clientId -> clientId
  readonly brandId: number; // brandId -> brandId
  readonly name: string; // unused
  readonly nativeDashboardName: string; // literalId -> literalId
  readonly nativeDashboardLabel: string; // displayName -> label
  readonly description: string; // unused
  readonly url: string; // productId + literalId -> uriPart
}

interface DisplayDashboard {
  [index: string]:
    | string
    | number
    | boolean
    | DisplayDashboard[]
    | null
    | undefined;
  readonly dashboardId: number;
  readonly clientId: number;
  readonly brandId: number;
  readonly literalId: string;
  readonly productId: string;
  readonly label: string;
  readonly routerLinkPart: string;
  readonly tableauURIPart: string | null;
  readonly status: CurrentStatus;
  readonly items?: DisplayDashboard[];
  readonly dashboardType?: string;
  readonly isMultiView: boolean;
}

type ExportTableType = 'xlsx' | 'csv' | 'tsv';

interface FilterField {
  value: string | number | null;
  matchMode: string;
  operator: string;
}

interface FilterEvent {
  filters: Record<string, FilterField[]>;
  filteredValues: Record<string, string | number>;
}

interface CurrencyData {
  code: CurrencyCode;
  locale: LocaleCode;
  display: 'code' | 'symbol' | 'symbol-narrow';
}

export {
  Brand,
  BrandRow,
  CurrentStatus,
  Client,
  JustBrand,
  ClientType,
  ConfigDashboardInstance,
  Dashboard,
  DisplayDashboard,
  AngularEnvironment,
  ExportTableType,
  FilterEvent,
  FilterField,
  LogResponse,
  LogRequestParams,
  NativeDashboard,
  NativeDashboardInstance,
  Product,
  PermissionResponse,
  SendEmailParams,
  SendEmailResponse,
  SelectionSlug,
  User,
  UserDetailsInterface,
  UserDetails,
  CurrencyData
};
