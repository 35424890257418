import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  AlertItemProps,
  AlertsMode,
  IAlertPrimaryActionButtonProps,
  IFilterChange,
  IGroupByFieldsConfigs,
  IColumnFields,
  StackedAlertRequestParams,
  Status,
  TabType,
  NotificationViewMode,
  notificationTabs,
  CurrentAlertsViewType,
  AlertButtonType
} from '@design-system/components/m-alert-item';
import { AlertsService } from '@portal/app/shared/services/alerts.service';
import { BrandStoreService } from '@portal/app/dashboard/geo-doe-config/data-stores/brand-store.service';
import { IAlert } from '@portal/app/shared/types/native/IAlert';
import { MNotificationCenterPopupComponent } from '@design-system/components/m-notification-center';
import { Router } from '@angular/router';
import { SelectionService } from '@portal/app/shared/services/selection.service';
import {
  NotificationTableConfigs,
  tabOptions
} from '@portal/app/dashboard/notification-center/notification-center.constants';
import { NotificationCenterService } from '@portal/app/dashboard/notification-center/notification-center.service';
import { MButtonGroupOption } from '@design-system/components/m-button-group';

@Component({
  selector: 'portal-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss']
})
export class NotificationCenterComponent implements OnInit, OnChanges {
  public actionAlerts: AlertItemProps[] = [];
  public infoAlerts: AlertItemProps[] = [];
  public alerts: AlertItemProps[] = [];
  public stackedAlertsData: AlertItemProps[] = [];
  public tableData: AlertItemProps[] = [];
  public unreadIds: number[] = [];
  public resolvedIds: number[] = [];
  public unreadInfoAlertIds: number[] = [];
  public alertsDataLoading = false;
  public rowsSelected = 0;
  public enableMarkAsRead = false;
  public currentView: CurrentAlertsViewType = CurrentAlertsViewType.normal;
  currentAlertsViewType = CurrentAlertsViewType;
  public columnFields: IColumnFields[] =
    NotificationTableConfigs.columnFieldsForActiveTab;

  public showSelectableRow = true;
  public initialActiveAlerts: AlertItemProps[] = [];
  public initialResolvedAlerts: AlertItemProps[] = [];
  public groupByFieldsConfigs: IGroupByFieldsConfigs =
    NotificationTableConfigs.groupConfig;

  public selectedTabOption = notificationTabs.active;
  public filteredData: AlertItemProps[] = [];
  public alertFilters: IFilterChange = {
    search: '',
    selectedServices: [],
    selectedDateRange: {
      startDate: '',
      endDate: '',
      custom: false
    }
  };

  public selectedRowsData: AlertItemProps[] = [];
  public tabOptions: MButtonGroupOption[] = tabOptions;
  previousTabSelected: string = this.selectedTabOption;

  @Input() visibilityType = NotificationViewMode.page;
  notificationViewMode = NotificationViewMode;
  @Input() notificationCenterVisible = false;

  @ViewChild('notificationCenter') notificationCenter:
    | MNotificationCenterPopupComponent
    | undefined;

  @Output() closePopup = new EventEmitter<void>();

  constructor(
    private readonly alertsService: AlertsService,
    private readonly brandStoreService: BrandStoreService,
    private readonly router: Router,
    private readonly selectionService: SelectionService,
    private readonly notificationCenterService: NotificationCenterService
  ) {}

  ngOnInit(): void {
    if (this.visibilityType === NotificationViewMode.page) {
      this.getAllAlerts(TabType.active, NotificationViewMode.page);
    } else {
      const { clientId, brandId } = this.brandStoreService.brand ?? {};

      if (clientId && brandId) {
        this.alertsService.getAlertsCount(clientId, brandId).subscribe({
          next: ({ actionableAlert = 0, infoAlert = 0 }) => {
            this.notificationCenterService.actionAlertsCountSubject.next(
              actionableAlert
            );
            this.notificationCenterService.unreadInfoAlertsCountSubject.next(
              infoAlert
            );
          },
          error: (error) => {
            console.error('Error while fetching the alerts count', error);
          }
        });
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.notificationCenterVisible &&
      this.visibilityType === NotificationViewMode.popup &&
      this.notificationCenterVisible
    ) {
      this.getAllAlerts(TabType.active, NotificationViewMode.popup);
    }
  }

  public getAllAlerts = (
    tab: TabType,
    notificationView: NotificationViewMode
  ): void => {
    this.alertsDataLoading = true;
    this.alertsService
      .getAllGroupedAlerts(
        this.brandStoreService.brand?.clientId,
        this.brandStoreService.brand?.brandId,
        tab
      )
      .subscribe({
        next: (alertsResponse: IAlert) => {
          const mappedAlerts =
            this.alertsService.mapPaginatedAlertsResponseToAlertItems(
              alertsResponse.alerts,
              true,
              notificationView
            );
          this.notificationCenterService.alertTagOptionsSubject.next(
            alertsResponse.alertTags
          );
          this.alertFilters = {
            ...this.alertFilters,
            selectedServices: alertsResponse.alertTags
          };
          this.alerts = mappedAlerts;
          this.categorizeAlerts(this.alerts);
          this.applyAllFilters();
        },
        error: (error) => {
          this.alertsDataLoading = false;
          console.error(`Error while fetching ${tab} alerts`, error);
        }
      });
  };

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    if (
      !this.notificationCenter?.contentContainer?.nativeElement?.contains(
        event.target as Node
      ) &&
      this.notificationCenterVisible
    ) {
      this.closePopup.emit();
    }
  }

  public getAllStackedAlerts = (
    stackedAlertConfig: StackedAlertRequestParams
  ): void => {
    this.alertsService
      .getStackedAlerts(
        this.brandStoreService.brand?.clientId,
        this.brandStoreService.brand?.brandId,
        stackedAlertConfig
      )
      .subscribe({
        next: (alertsResponse: IAlert) => {
          const mappedAlerts =
            this.alertsService.mapPaginatedAlertsResponseToAlertItems(
              alertsResponse.alerts,
              false
            );
          this.stackedAlertsData = mappedAlerts;
          this.currentView = CurrentAlertsViewType.stacked;
          this.showSelectableRow = false;
          this.groupByFieldsConfigs = {};
          this.tableData = mappedAlerts;
          this.alertsDataLoading = false;
        },
        error: (error) => {
          this.alertsDataLoading = false;
          console.error('Error while fetching stacked alerts', error);
        }
      });
  };

  public categorizeAlerts = (alerts: AlertItemProps[]) => {
    if (this.selectedTabOption === notificationTabs.active) {
      this.actionAlerts = [];
      this.infoAlerts = [];
      this.unreadInfoAlertIds = [];

      alerts.forEach((alert) => {
        if (alert.isActionAble) {
          this.actionAlerts.push(alert);
        } else {
          this.infoAlerts.push(alert);
          if (!alert.isRead) {
            this.unreadInfoAlertIds.push(alert.id);
          }
        }
      });

      this.notificationCenterService.actionAlertsCountSubject.next(
        this.actionAlerts.length
      );
      this.notificationCenterService.unreadInfoAlertsCountSubject.next(
        this.unreadInfoAlertIds.length
      );
      this.updateUnreadAlertIds(this.alerts);
      this.updateResolvedAlertIds(this.alerts);
      this.tableData = [...this.actionAlerts, ...this.infoAlerts];
      this.initialActiveAlerts = [...this.tableData];
    } else {
      this.tableData = [...alerts];
      this.initialResolvedAlerts = [...this.tableData];
    }
    this.alertsDataLoading = false;
  };

  public updateUnreadAlertIds = (alerts: AlertItemProps[]) => {
    this.unreadIds = alerts
      .filter((alert) => !alert.isRead)
      .map((alert) => alert.id);
  };

  public updateResolvedAlertIds = (alerts: AlertItemProps[]) => {
    this.resolvedIds = alerts
      .filter((alert) => alert.isResolved)
      .map((alert) => alert.id);
  };

  public navigateToActionAlertLink = (routingLink: string) => {
    const currentUrl = this.router.url;
    const shouldNavigate = !currentUrl.includes(routingLink);
    if (shouldNavigate) {
      window.open(
        `/a/${this.selectionService.buildSelectionSlug()}/products/${routingLink}`,
        '_blank'
      );
    } else {
      this.closePopup.emit();
    }
  };

  public handleActionButtonClick({
    actionButtonProps,
    rowData
  }: {
    actionButtonProps: IAlertPrimaryActionButtonProps;
    rowData: AlertItemProps;
  }): void {
    const { type, primaryActionButtonProperties } = actionButtonProps;

    if (
      type === AlertButtonType.actionButton &&
      primaryActionButtonProperties
    ) {
      const { externalLink, routingLink } = primaryActionButtonProperties;
      if (externalLink) {
        window.open(externalLink, '_blank');
      } else if (routingLink) {
        this.navigateToActionAlertLink(routingLink);
      }
      return;
    }

    if (type === AlertButtonType.markAsUnresolved) {
      this.updateAlertStatusAsUnResolved(rowData);
    }
  }

  public requestStackedAlerts(data: AlertItemProps) {
    this.alertsDataLoading = true;
    this.getAllStackedAlerts({
      alertConfigId: data.alertConfigId || 0,
      identifier: data.identifier || ''
    });
  }

  setSelectedTab(tabValue: string) {
    if (this.currentView === CurrentAlertsViewType.stacked) {
      this.goToNormalView();
    }
    if (tabValue !== this.previousTabSelected) {
      const isActive = tabValue === notificationTabs.active;
      this.selectedTabOption = tabValue as notificationTabs;
      this.showSelectableRow = isActive;
      this.groupByFieldsConfigs = isActive
        ? NotificationTableConfigs.groupConfig
        : {};
      this.columnFields = isActive
        ? NotificationTableConfigs.columnFieldsForActiveTab
        : NotificationTableConfigs.columnFieldsForResolvedTab;
      this.getAllAlerts(
        isActive ? TabType.active : TabType.resolved,
        NotificationViewMode.page
      );
      this.previousTabSelected = tabValue;
    }
  }

  navigateToNotificationCenter(viewDetailsAlertId: number) {
    this.closePopup.emit();
    this.notificationCenterService.expandAlertId.set(viewDetailsAlertId);
    this.router
      .navigate([
        `/a/${this.selectionService.buildSelectionSlug()}/products/notification-center`
      ])
      .catch((e) => console.error(e));
  }

  processSelectedAlertsUpdate(selectedAlerts: AlertItemProps[]): void {
    this.enableMarkAsRead =
      selectedAlerts.length > 0 &&
      selectedAlerts.filter((alert) => !alert.isRead).length > 0;
    this.notificationCenterService.selectedAlertsSubject.next(selectedAlerts);
    if (!this.enableMarkAsRead) {
      this.selectedRowsData = [];
    }
  }

  saveTableState(alerts: AlertItemProps[]): void {
    this.notificationCenterService.tableCurrentState.set(alerts);
  }

  goToNormalView() {
    this.currentView = CurrentAlertsViewType.normal;
    this.showSelectableRow = true;
    this.tableData = [...this.initialActiveAlerts];
    this.groupByFieldsConfigs = NotificationTableConfigs.groupConfig;
  }

  public applyAllFilters(): void {
    const initialAlerts =
      this.selectedTabOption === notificationTabs.active
        ? this.initialActiveAlerts
        : this.initialResolvedAlerts;

    const filteredByDate = this.notificationCenterService.filterByDate(
      initialAlerts,
      this.alertFilters.selectedDateRange
    );

    const filteredByService = this.notificationCenterService.filterByService(
      filteredByDate,
      this.alertFilters.selectedServices
    );

    const filteredBySearch = this.notificationCenterService.filterBySearch(
      filteredByService,
      this.alertFilters.search
    );

    this.tableData = [...filteredBySearch];
    this.expandLongDescriptionAlert();
  }

  public expandLongDescriptionAlert() {
    const longDescriptionAlertId =
      this.notificationCenterService.expandAlertId();
    if (longDescriptionAlertId !== 0 && this.tableData.length > 0) {
      this.tableData = this.tableData.map((item) =>
        item.id === longDescriptionAlertId ? { ...item, expanded: true } : item
      );
      this.notificationCenterService.expandAlertId.set(0);
    }
  }

  public updateFilters(filters: IFilterChange) {
    this.alertFilters = filters;
    this.applyAllFilters();
  }

  public updateAlertStatusAsResolved = (
    data: AlertItemProps,
    mode: AlertsMode
  ): void => {
    this.alertsService
      .updateAlertStatus(
        this.brandStoreService.brand?.clientId,
        this.brandStoreService.brand?.brandId,
        [
          this.notificationCenterService.createUpdateAlertConfig(
            data,
            Status.resolved
          )
        ]
      )
      .subscribe({
        next: () => {
          this.actionAlerts = this.actionAlerts.filter(
            (alert) => alert.id !== data.id
          );
          this.notificationCenterService.actionAlertsCountSubject.next(
            this.actionAlerts.length
          );

          this.tableData = [...this.actionAlerts, ...this.infoAlerts];
          this.initialActiveAlerts = [...this.tableData]; //update it to be in sync with current data.
        },
        error: (error) => {
          console.error(
            'Error while updating the alert status to resolved',
            error
          );
          if (mode === 'popup') {
            this.resolvedIds.pop();
          } else {
            this.tableData = this.notificationCenterService
              .tableCurrentState()
              .map((alert) =>
                alert.id === data.id ? { ...alert, isVisible: true } : alert
              );
          }
        }
      });
  };

  public updateAlertStatusAsUnResolved = (data: AlertItemProps): void => {
    this.alertsService
      .updateAlertStatus(
        this.brandStoreService.brand?.clientId,
        this.brandStoreService.brand?.brandId,
        [
          this.notificationCenterService.createUpdateAlertConfig(
            data,
            Status.unread
          )
        ]
      )
      .subscribe({
        next: () => {
          this.tableData = this.tableData.filter(
            (alert) => alert.id !== data.id
          );
          this.initialResolvedAlerts = [...this.tableData]; //update it to be in sync with current data.
          const currectActionAlertsCount =
            this.notificationCenterService.actionAlertsCountSubject.getValue();
          this.notificationCenterService.actionAlertsCountSubject.next(
            currectActionAlertsCount + 1
          );
        },
        error: (error) => {
          console.error(
            'Error while updating the alert status to unresolved',
            error
          );
        }
      });
  };

  markSelectedAlertsAsRead(alerts: AlertItemProps[], mode: AlertsMode | null) {
    // Get all selected alerts and filter out the unread ones
    const unreadAlerts = this.notificationCenterService.getUnreadAlerts(alerts);
    const unreadAlertIds = new Set(unreadAlerts.map((item) => item.id));
    const currentTableState =
      this.notificationCenterService.tableCurrentState();

    // Prepare the array of updateAlertConfig objects for each unread alert
    const updateAlertConfigs = unreadAlerts.map((alert) =>
      this.notificationCenterService.createUpdateAlertConfig(alert, Status.read)
    );
    this.alertsService
      .updateAlertStatus(
        this.brandStoreService.brand?.clientId,
        this.brandStoreService.brand?.brandId,
        updateAlertConfigs
      )
      .subscribe({
        next: () => {
          // Update the tableData by setting isRead to true for the unread alerts
          if (mode === null) {
            this.tableData = currentTableState.map((item) =>
              unreadAlertIds.has(item.id) ? { ...item, isRead: true } : item
            );
            this.enableMarkAsRead = false;
            this.selectedRowsData = [];
          } else {
            const selectedAlerts =
              this.notificationCenterService.getSelectedAlerts();
            this.processSelectedAlertsUpdate(selectedAlerts);
          } //todo vj to use expanded or explore change detection
          this.unreadIds = this.unreadIds.filter(
            (id) => !unreadAlertIds.has(id)
          );
          this.unreadInfoAlertIds = this.unreadInfoAlertIds.filter(
            (unreadInfoAlertId) => !unreadAlertIds.has(unreadInfoAlertId)
          );
          this.notificationCenterService.unreadInfoAlertsCountSubject.next(
            this.unreadInfoAlertIds.length
          );
        },
        error: (error) => {
          console.error(
            'Error while updating the alert statuses to read',
            error
          );
          if (mode === 'table' || mode === null) {
            this.tableData = currentTableState.map((item) =>
              unreadAlertIds.has(item.id) ? { ...item, isRead: false } : item
            );
          }
          this.unreadIds.push(...unreadAlertIds);
        }
      });
  }
}
