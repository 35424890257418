import { Component } from '@angular/core';
import { CardVariant } from '@design-system/components/m-card';

@Component({
  selector: 'portal-context-tactic-insights',
  templateUrl: './tactic-insights.component.html'
})
export class TacticInsightsComponent {
  public cardVariantType = CardVariant;
}
