import { Injectable } from '@angular/core';
import { DateRange } from '@portal/app/shared/types';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { monthDateYearFormat } from '../constants/constants';
@Injectable({
  providedIn: 'root'
})
export class CompareService {
  private compareExists = false;
  private compareValue = false;
  private layoutStyle = false;
  private currentDateRangeValue?: DateRange;
  private previousDateRangeValue?: DateRange;

  private compareSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  get isCompareOn(): Observable<boolean> {
    return this.compareSub.asObservable();
  }

  setCompareValue(isCompareOn: boolean): void {
    this.compareSub.next(isCompareOn);
  }

  get currentDate(): DateRange | undefined {
    return this.currentDateRangeValue;
  }

  set currentDate(value: DateRange | undefined) {
    this.currentDateRangeValue = value;
  }

  get previousDate(): DateRange | undefined {
    return this.previousDateRangeValue;
  }

  set previousDate(value: DateRange | undefined) {
    this.previousDateRangeValue = value;
  }

  get compare() {
    return this.compareValue;
  }

  set compare(value: boolean) {
    this.compareValue = value;
    this.setCompareValue(value);
  }

  get driversLayout() {
    return this.layoutStyle;
  }

  set driversLayout(value: boolean) {
    this.layoutStyle = value;
  }

  get compareIsAvailable() {
    return this.compareExists;
  }

  set compareIsAvailable(value: boolean) {
    this.compareExists = value;
  }

  get formattedCurrentDate() {
    return this.currentDateRangeValue
      ? this.formattedRangeDate(this.currentDateRangeValue)
      : '';
  }

  get formattedPreviousDate() {
    return this.previousDateRangeValue
      ? this.formattedRangeDate(this.previousDateRangeValue)
      : '';
  }

  private formattedRangeDate(date: DateRange) {
    if (date.startDate && date.endDate) {
      return this.historicalDateDisplayFormat([
        date?.startDate.format(monthDateYearFormat),
        date?.endDate.format(monthDateYearFormat)
      ]);
    }
    return '';
  }

  public historicalDateDisplayFormat(date: string[]): string {
    return date.length === 2 ? `${date[0]} - ${date[1]}` : '';
  }
}
