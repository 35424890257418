<div
  class="flex justify-center items-center w-screen h-screen"
  *ngIf="isLoading">
  <p-progressSpinner></p-progressSpinner>
</div>

<m-layout *ngIf="!isLoading" class="m-home-page">
  <div #navbarContent navbar-content>
    <portal-homepage-header [hasData]="hasData"></portal-homepage-header>
  </div>

  <div main-content *ngIf="hasData">
    <portal-my-portfolio></portal-my-portfolio>

    <portal-optimization-results *ngIf="showOptimizationResults">
    </portal-optimization-results>

    <div class="grid grid-cols-12 gap-4 mt-4 h-full" *ngIf="showChartView">
      <!-- Donut Chart Card -->
      <div class="col-span-4 h-full">
        <portal-spend-chart></portal-spend-chart>
      </div>

      <!-- Line Chart Card -->
      <div class="col-span-8 h-full">
        <portal-compare-line-chart></portal-compare-line-chart>
      </div>
    </div>

    <div *ngIf="showCustomerView">
      <div class="m-divider two-tone my-4"></div>
      <portal-my-customers></portal-my-customers>
    </div>

    <div class="m-divider two-tone my-4"></div>
    <portal-my-channels></portal-my-channels>
    <portal-footer class="mt-4"></portal-footer>
  </div>

  <div #sidebarContent sidebar-content *ngIf="hasData">
    <h2 class="dp2 mb-4 text-gray-1000">Highlights</h2>
    <portal-geo></portal-geo>
    <portal-recommended></portal-recommended>
    <portal-benchmark></portal-benchmark>
  </div>

  <div
    main-content
    *ngIf="!hasData"
    class="main-content flex flex-col items-center h-[calc(100vh-9rem)]">
    <img
      src="assets/images/data-processing.svg"
      alt="Hold Up!"
      class="max-w-2xl w-full" />
    <div class="dp2 text-gray-1000 mb-2 mt-10">
      We&rsquo;re still working on this!
    </div>
    <div class="b2 text-gray-600">
      Contact your customer success partner to see how it&rsquo;s coming along.
    </div>
  </div>
</m-layout>
