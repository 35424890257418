import { Component, Input, OnInit } from '@angular/core';
import { NoDataState } from './m-not-available.component.types';

@Component({
  selector: 'm-not-available',
  templateUrl: './m-not-available.component.html'
})
export class MNotAvailableComponent implements OnInit {
  private readonly dataStateCreativeMap: Record<NoDataState, string> = {
    [NoDataState.notAvailable]: 'not-available.svg',
    [NoDataState.noData]: 'empty-state.svg',
    [NoDataState.nothingHere]: 'nothing-here.svg',
    [NoDataState.dataProcessing]: 'data-processing.svg'
  };

  @Input() title = 'Not Available';
  // eslint-disable-next-line
  @Input() description = "We don't have any data to show here";
  @Input() noDataState: NoDataState = NoDataState.notAvailable;
  @Input() height = '350px';
  @Input() width = '240px';

  creative = 'not-available.svg';

  ngOnInit() {
    this.creative =
      this.dataStateCreativeMap[this.noDataState] || this.creative;
  }
}
