import { Component, Input, OnInit } from '@angular/core';
import {
  DataGridEditable,
  DataGridSelected,
  DataResponse
} from '@portal/app/shared/types';
@Component({
  selector: 'portal-compare-column',
  templateUrl: './compare-column.component.html'
})
export class CompareColumnComponent implements OnInit {
  @Input() key?: string;
  @Input() item!: DataGridEditable | DataGridSelected;
  @Input() rowData: DataResponse = {};
  @Input() vizMetric = '';

  label = '';
  totalItems = 0;
  otherItems = 0;
  percentValue: number | null = null;

  ngOnInit(): void {
    this.buildVizData();
  }

  buildVizData(): void {
    this.totalItems = Number(
      this.rowData[this.item?.additionalAttributes?.vizFields.totalItemsKey]
    );

    this.otherItems = Number(
      this.rowData[this.item?.additionalAttributes?.vizFields.otherItemsKey]
    );

    this.label = `${this.totalItems} Brands`;

    this.percentValue =
      this.rowData[this.item.dashboardFieldId] != null
        ? Number(this.rowData[this.item.dashboardFieldId])
        : null;
  }
}
