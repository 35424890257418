@if (isLoading() || !isChartReady()) {
  <div class="h-full">
    <m-skeleton width="100%" height="372px"></m-skeleton>
  </div>
} @else {
  <div class="h-full" [ngClass]="{ 'no-drivers': !showDrivers() }">
    <m-card [variant]="cardVariantType.small">
      <div class="container">
        <div class="sh2 text-gray-1000 mb-6">Compare to Previous Period</div>
        <div class="text-center w-full" class="chart">
          <m-progress-bar-comparison
            [options]="comparisonOptions()"></m-progress-bar-comparison>
          <div *ngIf="showDrivers()">
            <div class="c1 text-gray-800 mt-6 mb-4 text-center">
              What caused this change:
            </div>
            <div *ngFor="let driver of drivers()" class="flex justify-between">
              <div class="c2 text-gray-1000 truncate">{{ driver.label }}</div>
              <m-tag
                [isDark]="true"
                [isPositive]="!!driver?.isChangePositive"
                [isUp]="driver?.arrowDirection === 'UP'"
                [isNeutral]="metric()?.literalId === 'mediaSpend'"
                [showBackground]="false">
                {{ driver.formattedValue }}
                <span *ngIf="metric()?.literalId !== 'percSalesI'">%</span>
                <span *ngIf="metric()?.literalId === 'percSalesI'">p.p.</span>
              </m-tag>
            </div>
          </div>
        </div>
      </div>
    </m-card>
  </div>
}
