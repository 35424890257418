import { AfterViewInit, Component, inject, input } from '@angular/core';
import { CardVariant } from '@design-system/components/m-card';
import { SummaryMetricService } from '@portal/app/dashboard/context-modal/services/summary-metric.service';
import { ReturnCurveChartService } from '@portal/app/dashboard/context-modal/services/return-curve-chart.service';
import { Column } from '@portal/app/dashboard/context-modal/components/driver-display/driver-display.component';

@Component({
  selector: 'portal-context-tactic-summary',
  templateUrl: './tactic-summary.component.html',
  providers: [SummaryMetricService, ReturnCurveChartService]
})
export class TacticSummaryComponent implements AfterViewInit {
  public cardVariant = CardVariant;
  public columnType = Column;
  private summaryMetricService = inject(SummaryMetricService);
  private returnCurveService = inject(ReturnCurveChartService);

  comparisonPeriod = input.required<string>();

  isLoading = this.summaryMetricService.isLoading;
  isDataAvailable = this.summaryMetricService.isDataAvailable;
  topMetric = this.summaryMetricService.topMetric;
  drivers = this.summaryMetricService.drivers;

  returnCurveIsLoading = this.returnCurveService.isLoading;
  returnCurveChartOptions = this.returnCurveService.chartOptions;
  returnCurveIsReady = this.returnCurveService.isChartReady;
  returnCurveValid = this.returnCurveService.isChartValid;

  private readonly LIGHT_GREY_HEX = '#8d98a8';

  legend = {
    itemStyle: {
      color: this.LIGHT_GREY_HEX
    },
    layout: 'horizontal',
    align: 'center',
    verticalAlign: 'top'
  };

  viewInitialized = false;

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.viewInitialized = true;
    });
  }
}
