import { computed, inject, Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  BenchmarkResponseDTO,
  BenchmarkRequestDTO,
  ContextModalCard
} from '@portal/app/dashboard/context-modal/context-model.types';
import { catchError, map, Observable, of, switchMap, tap } from 'rxjs';
import { ModalService } from '@portal/app/dashboard/context-modal/services/modal.service';
import { environment } from '@portal/environments/environment';

export interface BenchmarkResponse {
  response: BenchmarkResponseDTO;
  card: ContextModalCard;
}

@Injectable()
export class BenchmarkService {
  public static baseUriV1 = `${environment.apiDomain}/api/v1/bff/benchmark/context-menu`;

  private http: HttpClient = inject(HttpClient);
  private modalService: ModalService = inject(ModalService);

  private isLoadingSignal = signal<boolean>(false);
  public data = toSignal(this.fetchData$(), {
    initialValue: {
      response: {} as BenchmarkResponseDTO,
      card: {} as ContextModalCard
    } as BenchmarkResponse
  });

  get isLoading() {
    return this.isLoadingSignal.asReadonly();
  }

  response = computed(() => this.data()?.response);
  card = computed(() => this.data()?.card);

  isDataAvailable = computed(() => {
    return this.response()?.metric && this.response()?.industryMetric;
  });

  emptyStateCard = {
    title: 'Benchmarks',
    content:
      'Brands who optimized with incrementality saw an average <span class="b2">increase of 8.90%</span> to their ROI over 6 months.',
    buttonText: 'Explore more'
  } as ContextModalCard;

  getParams() {
    const { channel, tactic } = this.modalService.modalParams();
    const { dateStart, dateStop } = this.modalService.getDateParams();
    return {
      ...this.modalService.getParams(),
      filter: {
        channel,
        tactic,
        conversion_type: this.modalService.conversionType(),
        dateStart,
        dateStop
      }
    } as BenchmarkRequestDTO;
  }

  fetchData$(): Observable<BenchmarkResponse> {
    const url = `${BenchmarkService.baseUriV1}/benchmark-by-dimension`;

    return this.modalService.modalParams$.pipe(
      tap(() => this.isLoadingSignal.set(true)),
      switchMap(() =>
        this.http.post<BenchmarkResponseDTO>(url, this.getParams()).pipe(
          catchError((error) => {
            console.error('Error fetching benchmark data', error);
            this.isLoadingSignal.set(false);
            return of({} as BenchmarkResponseDTO);
          })
        )
      ),
      tap(() => this.isLoadingSignal.set(false)),
      map((response) => {
        if (response?.difference === '∞%') {
          return {
            response: {} as BenchmarkResponseDTO,
            card: {} as ContextModalCard
          };
        }
        return {
          response,
          card: this.getCard(response)
        };
      })
    );
  }

  getCard(response: BenchmarkResponseDTO) {
    const metrics = [];
    const metric = response?.metric;
    if (metric) {
      metrics.push(metric);
    }
    const industryMetric = response?.industryMetric;
    if (industryMetric) {
      metrics.push(industryMetric);
    }

    const isChannel = this.modalService.modalType() === 'channel';
    let description = '';
    if (
      metric &&
      industryMetric &&
      metric.formattedValue === industryMetric.formattedValue
    ) {
      description = `This ${isChannel ? 'channel' : 'tactic'}'s performance is <span class="b2 text-gray-1000">similar</span> to brands in your industry.`;
    } else {
      description = `This ${isChannel ? 'channel' : 'tactic'}'s performance is <span class="b2 text-gray-1000">${response?.difference} ${response?.isHigher ? 'higher' : 'lower'}</span> than similar brands in your industry.`;
    }

    return {
      title: 'Latest Benchmark',
      description,
      buttonText: 'Explore more',
      metrics
    } as ContextModalCard;
  }
}
