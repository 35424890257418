/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil
} from 'rxjs/operators';
import { isEqual } from 'lodash-es';

// Types and Services Imports
import { ChannelsService } from '@portal/app/dashboard/home-page/services/channels.service';
import { FormatService } from '@portal/app/dashboard/home-page/services/format.service';
import { FilterService } from '@portal/app/dashboard/home-page/services/filter.service';
import { FieldService } from '@portal/app/shared/services/field.service';
import { MetricsService } from '@portal/app/dashboard/home-page/services/metrics.service';

// Types
import type { Filter, FilterValue } from '@portal/app/shared/types';
import type { Metric } from '@portal/app/dashboard/home-page/services/metrics.service';
import type { ProgressBarComparisonOptions } from '@design-system/components/m-progress-bar-comparison/m-progress-bar-comparison.component.types';

@Injectable({
  providedIn: 'root'
})
export class CompareChartService implements OnDestroy {
  // Current selected channel (modal)
  private activeChannelSubject = new BehaviorSubject<string>('');
  public activeChannel$ = this.activeChannelSubject.asObservable();

  // Current selected metric (modal)
  private activemMetricSubject = new BehaviorSubject<Metric | undefined>(
    undefined
  );

  public activeMetric$ = this.activemMetricSubject.asObservable();

  // Observable containing chart data
  private chartDataSubject = new BehaviorSubject<
    ProgressBarComparisonOptions | undefined
  >(undefined);

  public chartData$ = this.chartDataSubject.asObservable();

  // Cleanup
  private destroy$ = new Subject<void>();

  // Loading state
  private isLoadingSubject = new BehaviorSubject<boolean>(true);
  public isLoading$ = this.isLoadingSubject.asObservable();

  private selectedCompareOptionSubject = new BehaviorSubject<string>('');
  public selectedCompareOption$ =
    this.selectedCompareOptionSubject.asObservable();

  constructor(
    private channelsService: ChannelsService,
    private formatService: FormatService,
    private filterService: FilterService,
    private fieldService: FieldService,
    private metricsService: MetricsService
  ) {
    this.subscribeToChanges();
    this.subscribeToSelectedCompareOption();
  }

  private subscribeToSelectedCompareOption(): void {
    this.filterService.selectedCompareOption$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (option) => {
          this.selectedCompareOptionSubject.next(option || '');
        },
        error: (err) =>
          console.error('Error subscribing to selectedCompareOption', err)
      });
  }

  private subscribeToChanges(): void {
    combineLatest([
      this.channelsService.activeChannel$,
      this.metricsService.activeMetric$,
      this.filterService.filters$,
      this.channelsService.selectedChannels$
    ])
      .pipe(
        map(([activeChannel, activeMetric, filters, selectedChannels]) => ({
          activeChannel,
          activeMetric,
          filters,
          selectedChannels
        })),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        debounceTime(300),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: ({ activeChannel, activeMetric, filters, selectedChannels }) => {
          this.activeChannelSubject.next(activeChannel);
          const channels = activeChannel ? [activeChannel] : selectedChannels;
          const metrics = activeMetric ? [activeMetric?.literalId ?? ''] : [];

          if (activeMetric) {
            this.fetchData(channels, filters, metrics);
          }
        },
        error: (err: any) => console.error(err) // handle errors appropriately
      });
  }

  private getFilters(filters: Filter[]): Filter[] {
    const updatedDateFilters = this.filterService.getDateFilters();

    // Concatenate filters with updatedDateFilters, giving precedence to the latter
    const allFilters = [...filters, ...updatedDateFilters];

    // Reduce the concatenated array to a Map to ensure uniqueness by literalId
    const uniqueFiltersMap = allFilters.reduce((acc, filter) => {
      acc.set(filter.literalId, filter);
      return acc;
    }, new Map<string, Filter>());

    // Convert the Map back into an array of Filter objects
    return Array.from(uniqueFiltersMap.values());
  }

  private fetchData(
    channels: string[],
    filters: Filter[],
    metrics: string[]
  ): void {
    this.isLoadingSubject.next(true);

    // Combine filters with any other logic specific to fetching data
    const combinedFilters = this.getFilters(filters);
    this.metricsService
      .fetchData(combinedFilters, metrics, channels)
      .subscribe({
        next: (data) => {
          // Active metric
          const activeMetric = data[0];

          // Set metric data (drivers)
          this.activemMetricSubject.next(activeMetric);

          this.formatData(activeMetric, combinedFilters);
          this.isLoadingSubject.next(false);
        },
        error: (error) => {
          // Handle error
          console.error('PortfolioService fetchData error:', error);
          this.isLoadingSubject.next(false);
        }
      });
  }

  private formatData(activeMetric: Metric, filters: Filter[]) {
    const dateStart = filters.find(
      (f: Filter) => f.literalId === 'dateStart'
    )?.value;
    const dateStop = filters.find(
      (f: Filter) => f.literalId === 'dateStop'
    )?.value;
    const prevDateStart = filters.find(
      (f: Filter) => f.literalId === 'previousDateStart'
    )?.value;
    const prevDateStop = filters.find(
      (f: Filter) => f.literalId === 'previousDateStop'
    )?.value;

    const absPercentageChange = Math.abs(
      parseFloat(activeMetric?.prevPercDifference?.toFixed(2) || '0')
    );
    const diff = activeMetric?.literalId === 'percSalesI' ? ' p.p.' : '%';

    const curPeriodDiff = `${absPercentageChange}${diff}`;

    const fieldDef = this.fieldService.getFieldDefinitionByLiteralId(
      activeMetric?.literalId
    );

    const options: ProgressBarComparisonOptions = {
      prevPeriodValue: activeMetric?.prevValue || 0,
      curPeriodValue: activeMetric?.value || 0,
      prevPeriod: `${this.formatDate(prevDateStart)} - ${this.formatDate(
        prevDateStop
      )}`,
      curPeriod: `${this.formatDate(dateStart)} - ${this.formatDate(dateStop)}`,
      prevPeriodFormattedValue: this.formatService.formatValue(
        fieldDef,
        activeMetric?.prevValue
      ),
      curPeriodFormattedValue: this.formatService.formatValue(
        fieldDef,
        activeMetric?.value
      ),
      percentageChange: activeMetric?.prevPercDifference || 0,
      isPositive: activeMetric?.isPositive || false,
      isUp: activeMetric?.isUp || false,
      isNeutral: activeMetric?.isNeutral || false,
      curPeriodDiff,
      isLarge:
        activeMetric?.literalId === 'totalSales' ||
        activeMetric?.literalId === 'totalOrders'
    };

    this.chartDataSubject.next(options);
  }

  formatDate(date: FilterValue | undefined): string {
    return date ? dayjs.utc(date as string).format('MMM DD, YYYY') : '';
  }

  // Handles cleanup when the service is destroyed
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
