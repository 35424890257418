<div class="h-full" *ngIf="!isChartReady">
  <m-skeleton width="334px" height="382px"></m-skeleton>
</div>

<div class="h-full" *ngIf="isChartReady">
  <m-card variant="small">
    <div>
      <div class="sh2 text-gray-1000 mb-6">
        Compare to {{ selectedCompareOption }}
      </div>
      <div class="text-center w-full">
        <m-progress-bar-comparison
          [options]="comparisonOptions!"></m-progress-bar-comparison>
        <div *ngIf="isNotTotalMetric">
          <div class="c1 text-gray-800 mt-6 mb-4 text-center">
            What caused this change:
          </div>
          <div
            *ngFor="let driver of metric?.drivers"
            class="flex justify-between">
            <div class="c2 text-gray-1000 truncate">{{ driver.name }}</div>
            <m-tag
              [isDark]="true"
              [isPositive]="driver?.driverChange === 'POSITIVE'"
              [isUp]="driver?.up"
              [isNeutral]="metric?.literalId === 'mediaSpend'"
              [showBackground]="false">
              {{ driver.percent | absoluteValue | number: "1.0-2" }}
              <span *ngIf="metric?.literalId !== 'percSalesI'">%</span>
              <span *ngIf="metric?.literalId === 'percSalesI'">p.p.</span>
            </m-tag>
          </div>
        </div>
      </div>
    </div>
  </m-card>
</div>
