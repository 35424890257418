import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PlatformApis } from './platform-apis/platform-apis';
import { IApiService } from './models/IApiService';
import { SSOAPIOperations } from './sso-operations/SSOAPIOperations';
import { ProcessApis } from './process-apis/process-apis';
import { LastTouchSettingApis } from './last-touch-settings-apis/last-touch-setting-apis';
import { ManageFactDataApis } from './manage-fact-data-apis/manage-fact-data-apis';
import { FacebookDoeApis } from './facebook-doe-apis/facebook-doe-apis';
import { ReportingFeedsApis } from './reporting-feeds-apis/reporting-feeds-apis';
import { ReportingDashboardManagerApis } from './reporting-dashboard-manager-apis/reporting-dashboard-manager-apis';
import { ReportingIncrementalitySettingsApis } from './reporting-incrementality-settings-apis/reporting-incrementality-settings-apis';
import { TaxonomyApis } from './taxonomy/taxonomy-apis';
import { CrossPlatformDoeApis } from './cross-platform-doe-apis/cross-platform-doe-apis';
import { GeoDoeApis } from './geo-doe-apis/geo-doe-apis';
import { IAngularEnvironment } from './models/i-angular-environment';
import { NativeFrameworkApis } from './native-framework-apis/native-framework-apis';

@Injectable({
  providedIn: 'root'
})
export class ApiService implements IApiService {
  private apiV1Private = this.environment.apiV1;
  private apiV2Private = this.environment.apiV2;

  constructor(
    private readonly httpClient: HttpClient,
    @Inject('environment') private readonly environment: IAngularEnvironment
  ) {}

  public set apiV1(apiV1: string) {
    this.apiV1Private = apiV1;
  }

  public set apiV2(apiV2: string) {
    this.apiV2Private = apiV2;
  }

  public readonly platformApisOperations = PlatformApis.platformApiOperation(
    this.httpClient,
    this.apiV1Private,
    this.apiV2Private
  );

  public readonly processApisOperations = ProcessApis.processApiOperation(
    this.httpClient,
    this.apiV1Private,
    this.apiV2Private
  );

  public readonly lastTouchSettingApisOperations =
    LastTouchSettingApis.lastTouchSettingApiOperation(
      this.httpClient,
      this.apiV1Private,
      this.apiV2Private
    );

  public readonly manageFactDataApisOperations =
    ManageFactDataApis.manageFactDataApiOperation(
      this.httpClient,
      this.apiV1Private,
      this.apiV2Private
    );

  public readonly facebookDoeApisOperations =
    FacebookDoeApis.facebookDoeApiOperation(
      this.httpClient,
      this.apiV1Private,
      this.apiV2Private
    );

  public readonly reportingFeedsApisOperations =
    ReportingFeedsApis.reportingFeedsApiOperation(
      this.httpClient,
      this.apiV1Private,
      this.apiV2Private
    );

  public readonly reportingDashboardManagerApisOperations =
    ReportingDashboardManagerApis.reportingDashboardManagerApiOperation(
      this.httpClient,
      this.apiV1Private,
      this.apiV2Private
    );

  public readonly reportingIncrementalitySettingsApisOperations =
    ReportingIncrementalitySettingsApis.reportingIncrementalitySettingsApiOperation(
      this.httpClient,
      this.apiV1Private,
      this.apiV2Private
    );

  public readonly taxonomyApisOperations = TaxonomyApis.taxonomyApiOperation(
    this.httpClient,
    this.apiV1Private,
    this.apiV2Private
  );

  public readonly crossPlatformDoeApisOperations =
    CrossPlatformDoeApis.crossPlatformDoeApiOperation(
      this.httpClient,
      this.apiV1Private,
      this.apiV2Private
    );

  public readonly geoDoeApisOperations = GeoDoeApis.geoDoeApiOperation(
    this.httpClient,
    this.apiV1Private,
    this.apiV2Private
  );

  public readonly ssoOperations = SSOAPIOperations.getInstance(
    this.httpClient,
    this.apiV2Private
  );

  public readonly nativeFrameworkApis = NativeFrameworkApis.getInstance(
    this.httpClient,
    this.apiV1Private,
    this.apiV2Private
  );
}
