<td *ngIf="allowSelection">
  <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
</td>
<td
  *ngIf="rowIndex === 0 && showExpansionArrow !== null"
  [rowSpan]="rowIndex === 0 ? rowData.length : 0"
  class="expansion-col">
  <button
    type="button"
    pButton
    pRipple
    class="p-button-text p-button-rounded p-button-plain"
    (click)="emitRowExpansionClick(!showExpansionArrow)"
    [icon]="
      showExpansionArrow ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
    "></button>
</td>

<td
  [pSelectableRow]="rowData"
  [pSelectableRowDisabled]="
    !allowSelection || dataGridService.preventSelection(col)
  "
  pEditableColumn
  *ngFor="let col of cols; let i = index"
  pFrozenColumn
  [pTooltip]="getTooltip(col)"
  tooltipStyleClass="custom-tooltip datagrid-tooltip"
  tooltipPosition="top"
  [escape]="false"
  [frozen]="
    frozenColumnsEnabled && col.type === elementTypes.dimension && isRowFrozen
  "
  [ngClass]="[
    fieldDefinitions[col.dashboardFieldId].format,
    fieldDefinitions[col.dashboardFieldId].literalId,
    fieldDefinitions[col.dashboardFieldId].type,
    highlightColumns.includes(col.dashboardFieldId) && rowIndex === 0
      ? 'highlight-cell'
      : '',
    hasPreviousPeriod(col) ? 'previous-period tooltip-container' : '',
  ]"
  [style.width]="
    widthSpecificToFields[col.dashboardFieldId] + widthSpecificToFieldsUnit
  ">
  <!--  <span class="p-column-title">{{
      fieldDefinitions[col.dashboardFieldId].label
    }}</span>-->
  <div
    [ngClass]="[
      col.type,
      dataGridService.getGroupedBorder(col),
      dataGridService.labelToClass(col.label),
    ]">
    <ng-container *ngIf="col.layoutType; else otherSubTypes">
      <div
        *ngIf="col.layoutType === layoutTypes.filterSetRadioPopup"
        class="d-flex gap-2 justify-content-between">
        <portal-overlay-panel
          #op
          class="d-none"
          [layoutType]="col.layoutType"
          [name]="col.dashboardFieldId"
          [dashboardId]="col.dashboardId"
          [data]="rowData"
          [fieldDefinitions]="fieldDefinitions"
          [filterSet]="filterSetClone"></portal-overlay-panel>
        <div>
          <div>
            {{
              addCharacterInParentheses(rowData[col.dashboardFieldId], rowData)
            }}
          </div>
          <div></div>
        </div>
        <div data-html2canvas-ignore="true">
          <img
            class="edit-pencil-icon act-as-link"
            (click)="op.show($event)"
            src="assets/icons/pencil.svg"
            alt="Edit Icon" />
        </div>
      </div>
      <div
        *ngIf="
          shouldShowEditableInc &&
          col.layoutType === layoutTypes.incEditableCell
        "
        class="custom-input-div">
        <p-cellEditor class="w-100 d-flex gap-2 justify-content-center">
          <ng-template pTemplate="input">
            <input
              class="custom-row-input"
              pInputText
              [ngClass]="{
                maxLimitWarning: isIncSettingMaxLimit(
                  asNumber(rowData[col.dashboardFieldId])
                ),
              }"
              type="number"
              [(ngModel)]="rowData[col.dashboardFieldId]"
              required
              (blur)="onIncrementalitySettingChange(col.dashboardFieldId)"
              (keydown.enter)="
                onIncrementalitySettingChange(col.dashboardFieldId)
              " />
          </ng-template>
          <ng-template pTemplate="output">
            <span class="flex-grow-1">
              {{
                rowData[col.dashboardFieldId]
                  | formatValue: fieldDefinitions[col.dashboardFieldId]
              }}
            </span>
            <div data-html2canvas-ignore="true" class="revert-icon-div">
              <img
                *ngIf="!getIncrementalitySettingVisibility()"
                class="edit-pencil-icon act-as-link"
                src="assets/icons/pencil.svg"
                alt="Edit Icon" />
              <img
                class="revert-icon act-as-link"
                (click)="onRevertIncrementalitySettingChanges()"
                src="assets/icons/revert.svg"
                alt="Edit Icon"
                *ngIf="getIncrementalitySettingVisibility()" />
            </div>
          </ng-template>
        </p-cellEditor>
      </div>
      <div
        *ngIf="col.layoutType === layoutTypes.campaignLink"
        class="campaign-link">
        <portal-link-column
          [isLink]="rowData[col.dashboardFieldId] > 0"
          [value]="
            rowData[col.dashboardFieldId]
              | formatValue: fieldDefinitions[col.dashboardFieldId]
          "
          [noValue]="rowData[col.dashboardFieldId]"
          (navigate)="navigateToChildPage()"></portal-link-column>
      </div>
      <portal-delta-cell
        *ngIf="col.layoutType === layoutTypes.deltaShow"
        [fieldDefinitions]="fieldDefinitions"
        [value]="asNumber(rowData[col.dashboardFieldId])"
        [changedValue]="asNumber(rowData[col.dashboardFieldId + 'Changed'])"
        [literalId]="col.dashboardFieldId">
      </portal-delta-cell>

      <portal-composite-data-cell
        *ngIf="col.layoutType === layoutTypes.composite"
        [key]="col.dashboardLayoutLiteralId + rowIndex"
        [cellLayoutType]="asCellLayoutType(col.subType)"
        [col]="col"
        [rowData]="rowData"
        [fieldDefinitions]="fieldDefinitions"
        [dashboardId]="dashboardLiteralId"
        [compositeCellConfig]="getCompositeCellConfig(col, rowData)">
      </portal-composite-data-cell>

      <div *ngIf="col.layoutType === layoutTypes.dimensionNavigator">
        <span
          [class]="col?.styleClass"
          [ngClass]="{
            'first-dimension': i === 0,
            'second-dimension': i === 1,
          }">
          <ng-container *ngIf="i === 0; else otherCol">
            <portal-context-modal-link
              [value]="rowData[col.dashboardFieldId]"
              [row]="rowData"></portal-context-modal-link>
          </ng-container>
          <ng-template #otherCol>
            {{
              rowData[col.dashboardFieldId]
                | formatValue: fieldDefinitions[col.dashboardFieldId]
            }}
          </ng-template>
        </span>
      </div>

      <ng-container *ngIf="col.layoutType === layoutTypes.barVisualizer">
        <portal-compare-column
          *ngIf="!loading"
          [key]="col.dashboardLayoutLiteralId + rowIndex"
          [item]="$any(col)"
          [rowData]="rowData"
          [vizMetric]="benchmarkFilterValue">
        </portal-compare-column>
        <div *ngIf="loading">
          <p-progressSpinner [style]="{ width: '20px', height: '20px' }">
            Loading!
          </p-progressSpinner>
        </div>
      </ng-container>

      <ng-container *ngIf="col.layoutType === 'ICON_DATA_CELL'">
        <portal-icon-data-cell
          [key]="col.dashboardLayoutLiteralId"
          [col]="$any(col)"
          [rowData]="rowData"
          [fieldDefinitions]="fieldDefinitions">
        </portal-icon-data-cell>
      </ng-container>

      <ng-container *ngIf="col.layoutType === 'NAVIGATION_COLUMN'">
        <portal-navigation-column
          [link]="
            navigationLinks[col.dashboardFieldId]
          "></portal-navigation-column>
      </ng-container>
    </ng-container>

    <ng-template #otherSubTypes>
      <ng-container [ngSwitch]="col.subType">
        <ng-container *ngSwitchCase="'editable'">
          <div *ngIf="!loading" [ngSwitch]="editableFields[i].inputType">
            <div *ngSwitchCase="'select'">
              <p-dropdown
                [(ngModel)]="rowData[col.dashboardFieldId]"
                (onChange)="onDataChange()"
                [options]="editableFields[i].options"
                [showClear]="false"
                [filter]="false"
                [baseZIndex]="2"
                scrollHeight="24rem"
                appendTo="body"
                panelStyleClass="saas-dropdown-panel"
                [ngClass]="fieldDefinitions[col.dashboardFieldId].literalId"
                [placeholder]="editableFields[i].label">
              </p-dropdown>
            </div>
            <div *ngSwitchCase="'multiselect'">
              <p-multiSelect
                [(ngModel)]="rowData[col.dashboardFieldId]"
                [options]="editableFields[i].options"
                (onChange)="onDataChange()"
                [filter]="false"
                [baseZIndex]="2"
                scrollHeight="24rem"
                appendTo="body"
                selectedItemsLabel="{0} selected">
              </p-multiSelect>
            </div>
            <div *ngSwitchCase="'dateRange'">Date not supported</div>
            <div *ngSwitchDefault>Control Not defined</div>
          </div>
          <div *ngIf="loading">
            <p-progressSpinner [style]="{ width: '20px', height: '20px' }"
              >Loading!</p-progressSpinner
            >
          </div>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <ng-container *ngIf="!loading">
            <div
              *ngIf="fieldDefinitions[col.dashboardFieldId].type === 'string'"
              [pTooltip]="
                !showCellTooltip
                  ? null
                  : (rowData[col.dashboardFieldId]
                    | formatValue: fieldDefinitions[col.dashboardFieldId])
              "
              tooltipStyleClass="saas-tooltip"
              tooltipPosition="right">
              <!-- instead of directly displaying a string, portal-data-cell component will take that string and will let you perform custom manipulations and stylings ontop of it -->
              <portal-data-cell
                [dashboardId]="dashboardLiteralId"
                [key]="col.dashboardLayoutLiteralId + rowIndex"
                [cellLayoutType]="asCellLayoutType(col.subType)"
                [data]="
                  rowData[col.dashboardFieldId]
                    | formatValue: fieldDefinitions[col.dashboardFieldId]
                "></portal-data-cell>
            </div>
            <div
              *ngIf="fieldDefinitions[col.dashboardFieldId].type !== 'string'"
              [ngClass]="{
                'negative-value': rowData[col.dashboardFieldId] < 0,
              }">
              <!-- instead of directly displaying a string, portal-data-cell component will let you perform custom manipulations and stylings ontop of it-->
              <portal-data-cell
                [dashboardId]="dashboardLiteralId"
                [key]="col.dashboardLayoutLiteralId + rowIndex"
                [cellLayoutType]="asCellLayoutType(col.subType)"
                [data]="
                  rowData[col.dashboardFieldId]
                    | formatValue: fieldDefinitions[col.dashboardFieldId]
                "></portal-data-cell>
            </div>
          </ng-container>
          <ng-container *ngIf="loading">
            <p-progressSpinner [style]="{ width: '20px', height: '20px' }"
              >Loading!</p-progressSpinner
            >
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-template>
  </div>
  <portal-previous-value
    *ngIf="hasPreviousPeriod(col)"
    [value]="getPreviousPeriodValue(col)"
    [alignment]="
      fieldDefinitions[col.dashboardFieldId].type === 'string'
        ? 'left'
        : 'right'
    "
    [isUp]="!!getUpPreviousValue(col)"></portal-previous-value>
</td>
