import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import {
  DataGridAvailable,
  DataGridExportConfig,
  DataGridEditable,
  DataGridGrouped,
  DataGridSelected,
  FieldDefinitions,
  DataResponse,
  DataGridBarVisualizer,
  DataGridElement,
  ITableField,
  IExpandableTableData,
  IExportTableConfig,
  DataGridInfoWithIcon,
  ElementSubTyping,
  DataRowData
} from '@portal/app/shared/types';
import { SortEvent } from 'primeng/api';
import { compact, groupBy, isEmpty, memoize } from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class DataGridService implements OnDestroy {
  public selectedRows: Record<string, DataResponse[]> = {};
  public allowSelection: Record<string, boolean> = {};
  public activeTabIndex: Record<string, number> = {};
  private preventSelectionColumns = ['inc_data_source'];

  private readonly dataGridContext: Subject<DataGridExportConfig> =
    new Subject<DataGridExportConfig>();

  public groupedColumns!: Record<string, DataGridGrouped[]>;

  private readonly dataGridContextStoreSub: BehaviorSubject<
    {
      columns: ITableField[];
      data: IExpandableTableData[] | DataResponse[];
      tableType: string;
      tableName: string;
    }[]
  > = new BehaviorSubject<
    {
      columns: ITableField[];
      data: IExpandableTableData[] | DataResponse[];
      tableType: string;
      tableName: string;
    }[]
  >([]);

  constructor() {
    // Memoize functions to avoid unnecessary re-computation
    this.asGroupHeaders = memoize(this.asGroupHeaders);
    this.labelToClass = memoize(this.labelToClass);
    this.getGroupedBorder = memoize(this.getGroupedBorder);
    this.isSelectionAllowed = memoize(this.isSelectionAllowed);
  }

  ngOnDestroy() {
    this.dataGridContext.complete();
    this.dataGridContextStoreSub.complete();
  }

  get context(): Observable<DataGridExportConfig> {
    return this.dataGridContext.asObservable();
  }

  setGroupedColumns(
    fields: (
      | DataGridSelected
      | DataGridEditable
      | DataGridAvailable
      | DataGridGrouped
      | DataGridBarVisualizer
      | DataGridInfoWithIcon
    )[]
  ): void {
    this.groupedColumns = groupBy(
      fields.filter((f) => f.subType === ElementSubTyping.grouped),
      'label'
    ) as Record<string, DataGridGrouped[]>;
  }

  getGroupedBorder(col: DataGridGrouped): string {
    if (col && col.label) {
      const group = this.groupedColumns[col.label];
      if (group && group.length) {
        const index = group.indexOf(col);
        if (index === 0) {
          return 'left-border';
        } else if (index === group.length - 1) {
          return 'right-border';
        }
      }
      return '';
    } else {
      return '';
    }
  }

  // event based table sorter
  tableSorter(
    event: SortEvent,
    data1: DataResponse,
    data2: DataResponse
  ): -1 | 0 | 1 {
    const nullSet: (string | number | null | boolean | Date)[] = [
      null,
      NaN,
      Infinity,
      -Infinity,
      'NaN',
      'Infinity',
      '-Infinity',
      ''
    ];
    const value1 = data1[String(event.field)] as DataRowData;
    const value2 = data2[String(event.field)] as DataRowData;
    let result: -1 | 0 | 1;

    if (nullSet.includes(value1) && !nullSet.includes(value2)) {
      result = -1;
    } else if (!nullSet.includes(value1) && nullSet.includes(value2)) {
      result = 1;
    } else if (nullSet.includes(value1) && nullSet.includes(value2)) {
      result = 0;
    } else if (
      typeof value1 === 'string' &&
      typeof value2 === 'string' &&
      event.field === 'date'
    ) {
      result =
        new Date(value1) > new Date(value2)
          ? 1
          : new Date(value1) < new Date(value2)
            ? -1
            : 0;
    } else if (typeof value1 === 'string' && typeof value2 === 'string') {
      result = value1.localeCompare(value2) as -1 | 0 | 1;
    } else if (value1 != null && value2 != null) {
      result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
    } else {
      console.warn(
        `Custom sort fell through, not sorting for ${value1}, ${value2}`
      );
      result = 0;
    }
    return ((event.order || 0) * result) as -1 | 0 | 1;
  }

  /**
   * Generates group headers based on column definitions and other parameters.
   *
   * @param val - The array representing either selected or editable data grid columns.
   * @param fieldDefinitions - Definitions detailing the format and identification details of fields.
   * @param literalId - Literal ID to conditionally handle label overriding.
   * @param showChild - A boolean flag to assist in conditionally handling label overriding.
   *
   * @returns An array of objects representing group headers with necessary details.
   */
  asGroupHeaders(
    val: (DataGridSelected | DataGridEditable)[],
    fieldDefinitions: FieldDefinitions,
    literalId: string,
    showChild: boolean
  ): {
    colspan: number;
    label: string;
    originalLabel: string;
    format: string | null;
    literalId: string;
  }[] {
    const groups: {
      colspan: number;
      label: string;
      originalLabel: string;
      format: string | null;
      literalId: string;
    }[] = [];
    let lastSubType: string | undefined;

    val.forEach((col) => {
      let label = this.labelIn(col.label);
      const originalLabel = label;
      // Label override logic for specific conditions
      if (literalId === 'portfolio-level' && showChild) {
        if (['Optimized Allocation', 'Current Allocation'].includes(label)) {
          label = `New Budget under ${label}`;
        }
      }

      if (lastSubType !== label) {
        lastSubType = label;
        groups.push({
          colspan: 1,
          label,
          originalLabel,
          format: fieldDefinitions[col.dashboardFieldId]?.format || '',
          literalId: fieldDefinitions[col.dashboardFieldId]?.literalId || ''
        });
      } else {
        (
          groups[groups.length - 1] as {
            colspan: number;
            label: string;
            originalLabel: string;
            format: string | null;
            literalId: string;
          }
        ).colspan++;
      }
    });

    return groups;
  }

  labelIn(label: string | null): string {
    return label == null ? '' : label;
  }

  labelToClass(label: string): string {
    return label == null ? '' : label.split(' ').join('').toLowerCase();
  }

  exportTable(config: DataGridExportConfig) {
    this.dataGridContext.next(config);
  }

  initSelection(literalId: string) {
    this.allowSelection[literalId] = this.allowSelection[literalId] || true;
    this.selectedRows[literalId] = this.selectedRows[literalId] || [];
    this.activeTabIndex[literalId] = this.activeTabIndex[literalId] || 0;
  }

  resetSelections() {
    Object.keys(this.selectedRows).forEach((literalId) => {
      this.selectedRows[literalId] = [];
    });
  }

  isSelectionAllowed(element: DataGridElement, literalId: string) {
    return !!(element.COMPONENT_TAB && this.allowSelection[literalId]);
  }

  replaceSelection(literalId: string, rowData: DataResponse) {
    const rowIndex = this.selectedRows[literalId]?.findIndex(
      (sr) =>
        sr.channel === rowData.channel &&
        sr.tactic === rowData.tactic &&
        sr.segment === rowData.segment
    );
    if (rowIndex && rowIndex > -1) {
      (this.selectedRows[literalId] as DataResponse[])[rowIndex] = rowData;
    }
  }

  selectAll(literalId: string, rowData: DataResponse[]): void {
    this.selectedRows[literalId] = rowData;
  }

  preventSelection(col: DataGridSelected | DataGridEditable) {
    return this.preventSelectionColumns.includes(col.dashboardFieldId);
  }

  get dataGridContextStore(): IExportTableConfig[] {
    return this.dataGridContextStoreSub.getValue();
  }

  setDataGridContextStore(data: IExportTableConfig) {
    const dataGrids = this.dataGridContextStore;
    dataGrids.push(data);
    this.dataGridContextStoreSub.next(dataGrids);
  }

  removeTableFromDataGridStore(tableName: string): void {
    const dataGridStore = this.dataGridContextStore;
    if (!isEmpty(dataGridStore)) {
      const updatedDataGridStore = compact(
        dataGridStore.map((s) => {
          if (s.tableName !== tableName) {
            return s;
          }
        })
      );
      this.dataGridContextStoreSub.next(updatedDataGridStore);
    }
  }
}
