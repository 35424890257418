import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
  OnDestroy
} from '@angular/core';
import type { TooltipOptions } from 'primeng/api';
import { TooltipPosition } from '../m-tooltip/m-tooltip.component.types';

@Component({
  selector: 'm-chart-bar',
  templateUrl: './m-chart-bar.component.html',
  styleUrls: ['./m-chart-bar.component.scss']
})
export class MChartBarComponent implements AfterViewInit, OnDestroy {
  @Input() label!: string;
  @Input() value!: number;
  @Input() valueTooltip = '';
  @Input() medianTooltip = 'Benchmark';
  @Input() tooltipPosition: TooltipPosition = 'bottom';
  @Input() tooltipOptions: TooltipOptions = {};
  @Input() onHover?: (type: 'primary' | 'secondary') => void;
  @Input() onExit?: (type: 'primary' | 'secondary') => void;

  @ViewChild('progressBarContainer') progressBarContainer!: ElementRef;

  ngAfterViewInit(): void {
    const progressBarContainer = this.progressBarContainer.nativeElement;

    progressBarContainer.addEventListener('mouseenter', () => {
      this.triggerHover('primary');
    });
    progressBarContainer.addEventListener('mouseleave', () => {
      this.triggerExit('primary');
    });
  }

  triggerHover(type: 'primary' | 'secondary'): void {
    this.onHover?.(type);
  }

  triggerExit(type: 'primary' | 'secondary'): void {
    this.onExit?.(type);
  }

  ngOnDestroy(): void {
    const progressBarContainer = this.progressBarContainer.nativeElement;
    progressBarContainer.removeEventListener(
      'mouseenter',
      this.triggerHover.bind(this)
    );
    progressBarContainer.removeEventListener(
      'mouseleave',
      this.triggerExit.bind(this)
    );
  }
}
