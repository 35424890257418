import {
  GeoTestMethod,
  GeoTestType,
  ICellConfig,
  ICPAdStudyConfig
} from '@libs/apis';

export enum GeoTestStatus {
  active = 'Active',
  scheduled = 'Scheduled',
  finished = 'Finished'
}

export enum GeoCellDisplayStatus {
  inProgress = 'In Progress',
  resultsPending = 'Results Pending',
  scheduled = 'Scheduled',
  actionNeeded = 'Action Needed',
  pendingToLaunch = 'Pending Launch',
  draft = 'Draft',
  finished = 'Finished',
  none = 'None'
}

export interface ICPAdTestConfig extends ICPAdStudyConfig {
  actualStatus: GeoTestStatus;
  displayStatus: GeoCellDisplayStatus;
  bauCell: ICellConfig | null;
}

export interface ICellConfigDisplay extends ICellConfig {
  startDate: string;
  endDate: string;
  displayStatus?: string;
  method?: GeoTestMethod;
  conversionType?: string;
  percInc?: number;
  percOrdersI?: number;
  loading?: boolean;
  treatment?: GeoTestType;
}
