import { computed, inject, Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  TopMediaByMetricResponseDTO,
  TopMediaByMetricRequestDTO,
  ContextModalType
} from '@portal/app/dashboard/context-modal/context-model.types';
import { catchError, filter, map, Observable, of, switchMap, tap } from 'rxjs';
import { ModalService } from '@portal/app/dashboard/context-modal/services/modal.service';
import { MetricsService } from '@portal/app/dashboard/context-modal/services/metrics.service';
import {
  Options,
  PlotOptions,
  PointLabelObject,
  SeriesBarOptions
} from 'highcharts';
import { horizontalBarChart } from '@design-system/components/m-horizontal-bar-chart';
interface TopMediaByMetricResponse {
  response: TopMediaByMetricResponseDTO[];
  chartOptions: Options;
}

@Injectable()
export class TopMediaChartService {
  private readonly http: HttpClient = inject(HttpClient);
  private readonly modalService: ModalService = inject(ModalService);

  private readonly metricsService: MetricsService = inject(MetricsService);

  private initialValue: TopMediaByMetricResponse = {
    response: [] as TopMediaByMetricResponseDTO[],
    chartOptions: {} as Options
  };

  private isLoadingSignal = signal<boolean>(false);

  public data = toSignal(this.fetchData$(), {
    initialValue: this.initialValue
  });

  isDataAvailable = computed(() => this.data()?.response.length > 0);
  chartOptions = computed(() => this.data()?.chartOptions);

  isReady = computed(() => {
    return (
      !this.isLoading() &&
      !!this.chartOptions()?.legend &&
      !!this.chartOptions()?.plotOptions &&
      !!this.chartOptions()?.series &&
      !!this.chartOptions()?.tooltip &&
      !!this.chartOptions()?.xAxis &&
      !!this.chartOptions()?.yAxis &&
      !!this.chartOptions()?.title
    );
  });

  titleSuffix = computed(() => {
    if (this.modalService.modalType() === ContextModalType.channel) {
      return ' By Tactic';
    } else if (this.modalService.modalType() === ContextModalType.tactic) {
      return ' By Campaigns';
    }
    return '';
  });

  get isLoading() {
    return this.isLoadingSignal.asReadonly();
  }

  getParams() {
    const modalParams = this.modalService.modalParams();
    const { dateStart, dateStop } = this.modalService.getDateParams();
    return {
      ...this.modalService.getParams(),
      dimension: modalParams.tactic ? 'campaign' : 'tactic',
      filter: {
        dateStart,
        dateStop,
        channel: modalParams.channel,
        tactic: modalParams.tactic ? modalParams.tactic : undefined,
        conversion_type: this.modalService.conversionType(),
        literalId: this.metricsService.getActiveMetric()?.literalId
      }
    } as TopMediaByMetricRequestDTO;
  }

  fetchData$(): Observable<TopMediaByMetricResponse> {
    const url = `${ModalService.baseUriV1}/metric-level-top-media`;

    return this.metricsService.activeTabIndex$.pipe(
      filter((index: number) => index > -1),
      tap(() => this.isLoadingSignal.set(true)),
      switchMap(() =>
        this.http
          .post<TopMediaByMetricResponseDTO[]>(url, this.getParams())
          .pipe(
            catchError((error) => {
              console.error('Error fetching top metric data', error);
              this.isLoadingSignal.set(false);
              return of([]);
            }),
            map((response) => ({
              response,
              chartOptions: this.getChartOptions(response)
            }))
          )
      ),
      tap(() => this.isLoadingSignal.set(false))
    );
  }

  getChartOptions(data: TopMediaByMetricResponseDTO[]) {
    const validData =
      data?.filter((item) => {
        const value = item.value;
        return value > 0 && isFinite(value);
      }) ?? [];

    const formattedData: SeriesBarOptions[] = [
      {
        type: 'bar',
        name: 'Current Period',
        data: validData.map((item) => item?.value),
        pointPadding: 0.2
      }
    ];

    const categories = validData
      .map((item: TopMediaByMetricResponseDTO) => item?.key ?? '')
      .filter((category: string) => category);

    const chartOptions = {
      ...horizontalBarChart.args,
      series: formattedData,
      xAxis: { ...horizontalBarChart?.args?.xAxis, categories },
      yAxis: {
        ...horizontalBarChart.args?.yAxis,
        ...this.calculateMinMaxValues(data)
      },
      plotOptions: this.updateBarPlotOptions(validData)
    };

    return chartOptions as Options;
  }

  private calculateMinMaxValues(data: TopMediaByMetricResponseDTO[]) {
    // Ensure that we flatten safely and only consider numbers
    const numericData = data.filter((metric) => isFinite(metric.value)) ?? [];
    const values = numericData.map((item) => item.value);
    let min = Math.min(...values);
    let max = Math.max(...values);

    if (numericData.length === 0) {
      // Provide default values if no valid data exists
      min = 0;
      max = 0;
    } else {
      // Add a buffer of 24% to the maxValue
      const maxBuffer = max * 0.24;
      max += maxBuffer;

      // Subtract 25% from the minValue but ensure it's not less than 0
      const minBuffer = min * 0.25;
      min = Math.max(min - minBuffer, 0);
    }

    return { min, max };
  }

  private updateBarPlotOptions(
    data: TopMediaByMetricResponseDTO[]
  ): PlotOptions {
    // Safely access existing bar options and ensure fallback to an empty object
    const existingBarOptions = horizontalBarChart.args?.plotOptions?.bar ?? {};
    return {
      bar: {
        ...existingBarOptions,
        dataLabels: {
          ...existingBarOptions.dataLabels,
          formatter(this: PointLabelObject) {
            const value = data[this.point.index]?.formattedValue;
            return `<tspan style="stroke: none">${value}</tspan>`;
          }
        }
      }
    };
  }
}
