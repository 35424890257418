import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ViewType } from '@libs/common-components';

@Component({
  selector: 'lib-ui-loader-global',
  templateUrl: './loader-global.component.html',
  styleUrls: ['./loader-global.component.scss']
})
export class LoaderGlobalComponent {
  @Input() isTransparent = false;
  @Input() loaderTitle = `We're on it!`;
  @Input() loaderMessage = 'Please wait while the operation completes';
  @Input() view: ViewType = ViewType.loader;
  @Input() closable = false;
  @Input() continueButtonLabel = 'Continue';
  @Input() loaderStyleClass = '';

  @Output() handleContinue = new EventEmitter<void>();
  @Output() handleRetry = new EventEmitter<void>();
  @Output() handleClose = new EventEmitter<void>();
}
