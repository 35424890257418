@if (dashboard) {
  <div
    class="d-flex align-items-center justify-content-center h-100 w-100 ui-background">
    <!--suppress HtmlUnknownTag -->
    <tableau-viz
      width="100vw"
      height="100vh"
      id="tableauViz"
      src="https://us-east-1.online.tableau.com/t/measuredanalytics/views/{{
        this.dashboard.tableauURIPart
      }}"
      toolbar="hidden"
      iframe-auth
      hide-tabs>
    </tableau-viz>
  </div>
} @else {
  <div class="show-error">
    <h2>This is awkward...</h2>
    <p>
      It looks like you do not have access to this dashboard, or there is an
      issue with the URL.
    </p>
    <p>
      Please go back to the <a routerLink="../../">main</a> page and try again.
      If it continues to occur, please contact Measured Support.
    </p>
  </div>
}
