<!-- Donut Chart Card -->
<m-card>
  <div
    header
    class="flex flex-col xl:flex-row justify-between items-start xl:items-center w-full">
    <span class="sh2 mb-2 xl:!mb-0">Spend Allocation</span>

    <!-- Dropdown Component -->
    <m-dropdown
      class="w-full xl:w-auto"
      [options]="dimensionsOptions"
      [(ngModel)]="dimensions"
      (formControlChange)="onDimensionsChange($event?.value)"
      [disabled]="isLoading"></m-dropdown>
  </div>

  <!-- Chart -->
  <div class="flex flex-col items-center justify-center h-full text-center">
    <!-- Loading State -->
    @if (!isChartReady) {
      <m-skeleton
        width="190px"
        height="190px"
        shape="circle"
        class="my-8"></m-skeleton>
    }

    <!-- Empty State -->
    @if (!isLoading && isChartDataEmpty) {
      <m-not-available></m-not-available>
    }

    <!-- Chart -->
    <div class="my-3">
      @if (isChartReady && !isChartDataEmpty) {
        <m-donut-chart
          (click)="navigateToPage()"
          [data]="donutChartOptions?.data"
          [innerContent]="donutChartOptions?.innerContent"
          [height]="250">
        </m-donut-chart>
      }
    </div>

    <!-- Legend -->
    <div class="flex flex-wrap justify-evenly w-[250px]">
      <!-- Skeleton Loaders -->
      @if (!isChartReady) {
        <div class="flex flex-wrap justify-evenly w-[250px]">
          @for (item of [].constructor(3); track item; let i = $index) {
            <m-skeleton width="75px" height="20px"></m-skeleton>
          }
          @for (item of [].constructor(3); track item; let i = $index) {
            <m-skeleton width="75px" height="20px" class="mt-2"></m-skeleton>
          }
        </div>
      }

      <!-- Chart Legends -->
      @for (legend of chartLegend; track legend) {
        <div class="flex flex-wrap items-center mr-2 mb-2">
          <span
            class="mr-1"
            [ngStyle]="{ color: legend?.color, fontSize: '8px' }"
            >&#x25CF;</span
          >
          <span class="c1 text-gray-800">{{ legend?.name }}</span>
        </div>
      }
    </div>
  </div>
</m-card>
