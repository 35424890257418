import { ElementSubType } from '@portal/app/shared/types';
import { IDToken } from '@okta/okta-auth-js';

export const titleBase = 'Portal |';

const getUriSegments = (href: string): string[] =>
  new URL(href).pathname.split('/');

export const getNextToLastUriSegment = (href: string): string => {
  const segments = getUriSegments(href);
  if (segments.length > 0) {
    const lastSegment = segments.pop();
    if (lastSegment != null) {
      // Handle potential trailing slash
      if (lastSegment !== '/') {
        const secondToLast = segments.pop();
        return secondToLast != null ? secondToLast : '';
      } else {
        segments.pop();
        const trueSecondToLast = segments.pop();
        return trueSecondToLast != null ? trueSecondToLast : '';
      }
    }
  }
  return '';
};

export const sortElementSubTypeByDisplayOrder = (
  a: ElementSubType,
  b: ElementSubType
): 0 | -1 | 1 => {
  if (a.displayOrder != null && b.displayOrder != null) {
    return a.displayOrder > b.displayOrder
      ? 1
      : a.displayOrder < b.displayOrder
        ? -1
        : 0;
  }
  return 0;
};

export const getSorted = <T>(items: T[], comparator: string): T[] =>
  items.sort((a: T | null, b: T | null) => {
    const typedA = a as unknown as Record<string, number> | null;
    const typedB = b as unknown as Record<string, number> | null;
    if (typedA != null && typedB != null) {
      const aSide = typedA[comparator];
      const bSide = typedB[comparator];
      if (aSide != null && bSide != null) {
        return aSide > bSide ? 1 : aSide === bSide ? 0 : -1;
      }
      return 0;
    }
    return 0;
  });

export const slugify = (text: string, separator?: string): string => {
  let transformedText = text.toString().toLowerCase().trim();

  const sets = [
    { to: 'a', from: '[ÀÁÂÃÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶΑΆ]' },
    { to: 'b', from: '[Β]' },
    { to: 'ae', from: '[Ä]' },
    { to: 'c', from: '[ÇĆĈČ]' },
    { to: 'd', from: '[ÐĎĐÞΔ]' },
    { to: 'e', from: '[ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆΕΈ]' },
    { to: 'f', from: '[Φ]' },
    { to: 'g', from: '[ĜĞĢǴΓ]' },
    { to: 'h', from: '[ĤḦ]' },
    { to: 'i', from: '[ÌÍÎÏĨĪĮİỈỊΗΉΙΊΪΐ]' },
    { to: 'j', from: '[Ĵ]' },
    { to: 'ij', from: '[Ĳ]' },
    { to: 'k', from: '[ĶΚ]' },
    { to: 'ks', from: '[Ξ]' },
    { to: 'l', from: '[ĹĻĽŁΛ]' },
    { to: 'm', from: '[ḾΜ]' },
    { to: 'n', from: '[ÑŃŅŇΝ]' },
    { to: 'o', from: '[ÒÓÔÕØŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠΟΌΩΏ]' },
    { to: 'oe', from: '[ŒÖ]' },
    { to: 'p', from: '[ṕΠ]' },
    { to: 'ps', from: '[Ψ]' },
    { to: 'r', from: '[ŔŖŘΡ]' },
    { to: 's', from: '[ŚŜŞŠΣς]' },
    { to: 'ss', from: '[ß]' },
    { to: 't', from: '[ŢŤΤ]' },
    { to: 'th', from: '[Θ]' },
    { to: 'u', from: '[ÙÚÛŨŪŬŮŰŲỤỦỨỪỬỮỰƯΥΎΫΰ]' },
    { to: 'ue', from: '[Ü]' },
    { to: 'w', from: '[ẂŴẀẄ]' },
    { to: 'x', from: '[ẍΧ]' },
    { to: 'y', from: '[ÝŶŸỲỴỶỸ]' },
    { to: 'z', from: '[ŹŻŽΖ]' },
    // eslint-disable-next-line quotes,@typescript-eslint/quotes
    { to: '-', from: "[·/_,:;']" }
  ];

  sets.forEach((set) => {
    transformedText = transformedText.replace(
      new RegExp(set.from, 'gi'),
      set.to
    );
  });

  transformedText = transformedText
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text

  if (typeof separator !== 'undefined' && separator !== '-') {
    transformedText = transformedText.replace(/-/g, separator);
  }

  return transformedText;
};

export const getOktaIdTokenObj = (): IDToken => {
  const oktaIdTokenObject: IDToken = JSON.parse(
    localStorage.getItem('okta-token-storage') || '{}'
  )?.idToken;

  return oktaIdTokenObject;
};

export const getIdToken = (): string | null => {
  const oktaIdTokenObject: IDToken | undefined = getOktaIdTokenObj();

  return oktaIdTokenObject?.idToken || localStorage.getItem('id_token');
};
