import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '@portal/app/shared/services/auth.service';
import { DashboardService } from '@portal/app/shared/services/dashboard.service';
import { UserModel } from '@portal/app/shared/models/userModel';
import { ApiService } from '@portal/app/shared/services/api.service';
import { StorageService } from '@portal/app/shared/services/storage.service';
import { ClientStoreService } from '@portal/app/dashboard/geo-doe-config/data-stores/client-store.service';
import { Observable, Subject, take, takeUntil, throwError } from 'rxjs';
import { MessageService } from 'primeng/api';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { getOktaIdTokenObj } from '@portal/app/shared/helpers/helpers';

@Component({
  selector: 'portal-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  public isRedirecting = false;
  public submitting = false;
  private readonly returnUrlCookieKey = 'returnUrl';
  public isMobileModalVisible = false;
  public isDontShowAgainEnabled = false;
  private readonly dontShowAgainKey = 'isDontShowAgainEnabled';
  private destroy$ = new Subject<void>();

  constructor(
    public authService: AuthService,
    public dashboardService: DashboardService,
    public apiService: ApiService,
    public store: StorageService,
    private messageService: MessageService,
    private readonly clientStoreService: ClientStoreService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    @Inject(OKTA_AUTH) private readonly oktaAuth: OktaAuth
  ) {}

  ngOnInit(): void {
    if (this.isMobile()) {
      this.isMobileModalVisible = true;
    }

    const dontShowAgainFlag = localStorage.getItem(this.dontShowAgainKey);
    if (dontShowAgainFlag === 'true') {
      this.isMobileModalVisible = false;
    }

    const returnUrl: string = this.route.snapshot.queryParams.returnUrl;
    if (returnUrl) {
      // Ephemeral will store it in sessionStorage
      this.store.set(this.returnUrlCookieKey, returnUrl, true);
      this.isRedirecting = true;
    }

    this.authService.idToken$
      .pipe(takeUntil(this.destroy$))
      .subscribe((idToken) => {
        const idTokenObj = getOktaIdTokenObj();

        if (
          idTokenObj?.idToken &&
          !this.oktaAuth.tokenManager.hasExpired(idTokenObj) &&
          idToken === idTokenObj.idToken
        ) {
          this.processLogin(idToken);
        }
      });

    if (this.router.url.includes('/force-login')) {
      this.authService.signIn().catch((e) => throwError(() => new Error(e)));
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public isMobile() {
    const userAgent = navigator.userAgent || navigator.vendor;
    return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
      userAgent
    );
  }

  public closeMobileModal() {
    this.isMobileModalVisible = false;

    if (this.isDontShowAgainEnabled) {
      localStorage.setItem(this.dontShowAgainKey, 'true');
    }
  }

  public onCheckboxChange() {
    this.isDontShowAgainEnabled = !this.isDontShowAgainEnabled;
  }

  public processLogin(idToken: string): void {
    if (!idToken) {
      // drop out for now
      return;
    }

    this.submitting = true;

    this.apiService.getUser(true).subscribe({
      next: (user) => {
        this.authService.setUser(user);
        // Already authenticated, Init Pendo & go into the app
        this.initStateAndNavigate(new UserModel(user));
      },
      error: (error: HttpErrorResponse) => {
        if (error.status === 401) {
          // not yet authenticated... testLogin failed.
          this.submitting = true;
          this.authService
            .login(idToken)
            .pipe(take(1))
            .subscribe({
              next: (userWithModel) => {
                if (userWithModel == null) {
                  this.submitting = false;
                  console.error(`Unable to authenticate user account.`);
                } else {
                  // Authenticated, initialize pendo
                  this.initStateAndNavigate(userWithModel);
                }
              },
              error: (e) => {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  sticky: true,
                  detail: `We encountered the following error: ${String(
                    e
                  )} - Please contact support to resolve the issue`
                });
                // clear localstorage
                localStorage.clear();

                this.submitting = false;
                throwError(() => e);
              }
            });
        } else {
          // Only show an error if it is not a 401 unauthorized error.
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            sticky: true,
            detail: `We encountered the following error: ${error.status}: ${error.error} - Please contact support to resolve the issue`
          });
          // clear localstorage
          localStorage.clear();

          this.submitting = false;
          throwError(() => error);
        }
      }
    });
  }

  private initStateAndNavigate(userWithModel: UserModel): void {
    this.apiService
      .getClients()
      .pipe(take(1))
      .subscribe({
        next: (resp) => {
          this.clientStoreService.allClients = resp.clients;
          this.authService.initPendo(
            userWithModel,
            this.clientStoreService.allClients
          );
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'No Permission',
            sticky: true,
            detail:
              'Your account does not have access to a client dashboard. please contact support to resolve the issue'
          });
          this.submitting = false;
          // clear localstorage
          localStorage.clear();

          throwError(() => new Error(err));
          // Incorrect auth, user does not have permissions
        }
      });
    this.getPathAfterAuth(userWithModel)
      .pipe(take(1))
      .subscribe({
        next: (path) => {
          const queryParams = Object.fromEntries(
            Object.entries(this.route.snapshot.queryParams).filter(
              ([key]) => key !== this.returnUrlCookieKey
            )
          );

          this.router.navigate([path], {
            replaceUrl: true,
            queryParams
          });
        },
        error: () => (this.submitting = false)
      });
  }

  private getPathAfterAuth(user: UserModel): Observable<string> {
    return new Observable<string>((subscriber) => {
      // Grabs it from sessionStorage
      const returnUrl = this.store.get(this.returnUrlCookieKey, true);

      if (returnUrl) {
        // Remove from sessionStorage
        this.store.remove(this.returnUrlCookieKey, true);
        subscriber.next(returnUrl);
        subscriber.complete();
      } else {
        this.dashboardService
          .landingPage(user)
          .pipe(take(1))
          .subscribe({
            next: (path) => {
              subscriber.next(`/a/${path}`);
              subscriber.complete();
            }
          });
      }
    });
  }
}
