import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DesignSystemModule } from '@libs/design-system';
import { PrimengModule } from '@portal/app/dashboard/primeng.module';

import { LinkComponent } from '@portal/app/dashboard/context-modal/components/link/link.component';
import { ChannelModalComponent } from '@portal/app/dashboard/context-modal/components/channel-modal/channel-modal.component';
import { TacticModalComponent } from '@portal/app/dashboard/context-modal/components/tactic-modal/tactic-modal.component';
import { ContentComponent } from '@portal/app/dashboard/context-modal/components/content/content.component';
import { SummaryComponent } from '@portal/app/dashboard/context-modal/components/summary/summary.component';
import { TacticSummaryComponent } from '@portal/app/dashboard/context-modal/components/tactic-summary/tactic-summary.component';
import { DriverDisplayComponent } from '@portal/app/dashboard/context-modal/components/driver-display/driver-display.component';
import { ChannelInsightsComponent } from '@portal/app/dashboard/context-modal/components/channel-insights/channel-insights.component';
import { TacticInsightsComponent } from '@portal/app/dashboard/context-modal/components/tactic-insights/tactic-insights.component';
import { ModalCardComponent } from '@portal/app/dashboard/context-modal/components/card/card.component';
import { MetricsComponent } from '@portal/app/dashboard/context-modal/components/metrics/metrics.component';
import { CompareChartComponent } from '@portal/app/dashboard/context-modal/components/compare-chart/compare-chart.component';
import { TrendingChartComponent } from '@portal/app/dashboard/context-modal/components/trending-chart/trending-chart.component';
import { GeoTestCardsComponent } from '@portal/app/dashboard/context-modal/components/geo-test-cards/geo-test-cards.component';
import { BenchmarkCardsComponent } from '@portal/app/dashboard/context-modal/components/benchmark-cards/benchmark-cards.component';
import { ChannelOptimizationCardsComponent } from '@portal/app/dashboard/context-modal/components/channel-optimization-cards/channel-optimization-cards.component';
import { TacticOptimizationCardsComponent } from '@portal/app/dashboard/context-modal/components/tactic-optimization-cards/tactic-optimization-cards.component';
import { TopMediaChartComponent } from '@portal/app/dashboard/context-modal/components/top-media-chart/top-media-chart.component';
import { MediaComponent } from '@portal/app/dashboard/context-modal/components/media/media.component';
import { AutoCompleteComponent } from '@portal/app/dashboard/context-modal/components/autocomplete/autocomplete.component';

@NgModule({
  declarations: [
    LinkComponent,
    ChannelModalComponent,
    TacticModalComponent,
    ContentComponent,
    SummaryComponent,
    TacticSummaryComponent,
    DriverDisplayComponent,
    ChannelInsightsComponent,
    TacticInsightsComponent,
    ModalCardComponent,
    MetricsComponent,
    CompareChartComponent,
    TrendingChartComponent,
    GeoTestCardsComponent,
    BenchmarkCardsComponent,
    ChannelOptimizationCardsComponent,
    TacticOptimizationCardsComponent,
    TopMediaChartComponent,
    MediaComponent,
    AutoCompleteComponent
  ],
  imports: [CommonModule, DesignSystemModule, PrimengModule],
  exports: [
    LinkComponent,
    ChannelModalComponent,
    TacticModalComponent,
    ContentComponent,
    SummaryComponent,
    TacticSummaryComponent,
    DriverDisplayComponent,
    ChannelInsightsComponent,
    TacticInsightsComponent,
    ModalCardComponent,
    MetricsComponent,
    CompareChartComponent,
    TrendingChartComponent,
    GeoTestCardsComponent,
    BenchmarkCardsComponent,
    ChannelOptimizationCardsComponent,
    TacticOptimizationCardsComponent,
    TopMediaChartComponent,
    MediaComponent,
    AutoCompleteComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ContextModalModule {}
