import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[mTabIndex]',
  exportAs: 'mTabIndex'
})
export class MTabIndexDirective {
  constructor(public templateRef: TemplateRef<any>) {}
  @Input('mTabIndex') tabIndex = 0;
}
