<p-dialog
  class="saas-ui saas-ui-size-default"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [visible]="true"
  [baseZIndex]="10000"
  [closable]="closable"
  [showHeader]="false">
  <div
    class="d-flex flex-column gap-4 w-100 h-100 saas-ui align-items-center dialog-min-width {{
      loaderStyleClass
    }}">
    @if (loaderTitle) {
      <h4 class="loader-title" [innerHtml]="loaderTitle"></h4>
    }
    <p class="fw-normal text-gray fs-14 mb-0" [innerHtml]="loaderMessage"></p>
    <div>
      @switch (view) {
        @case ("success") {
          <div class="d-flex flex-column align-items-center gap-4">
            <span class="material-symbols-outlined success-tick">
              check_circle
            </span>
            <div class="mx-auto">
              <button
                pButton
                label="{{ continueButtonLabel }}"
                class="p-button-primary"
                (click)="handleContinue.emit()"></button>
            </div>
          </div>
        }
        @case ("error") {
          <div class="d-flex flex-column align-items-center gap-4">
            <span class="material-symbols-outlined error"> error </span>
            <div class="mx-auto">
              <button
                pButton
                label="Retry"
                class="p-button-primary"
                (click)="handleRetry.emit()"></button>
            </div>
          </div>
        }
        @default {
          <div>
            <img
              alt="Loading..."
              src="/assets/images/spinner.gif"
              class="inline-spinner mx-auto" />
          </div>
        }
      }
    </div>
  </div>
</p-dialog>
