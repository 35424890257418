import { UntypedFormControl } from '@angular/forms';
import { IFormConfig } from '../model/IFormConfig';
import { isEmpty } from 'lodash-es';

export const prepareForm = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: any,
  fields: IFormConfig[] = [],
  data: Record<string, string> = {}
) => {
  const formData = data || {};
  fields?.forEach(({ field, defaultValue, updateOn, validators, readonly }) => {
    form.setControl(
      field,
      new UntypedFormControl(
        {
          value:
            formData[field] ||
            getDefaultValueWhenTypeIsFunction(defaultValue, formData) ||
            (typeof defaultValue === 'boolean' ? defaultValue : null),
          disabled: getDisabled(formData, readonly)
        },
        {
          validators: validators ? validators : [],
          updateOn: updateOn === 'change' ? 'change' : 'blur'
        }
      )
    );
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getDisabled = (formData: any, readonlyConfig: undefined | boolean) =>
  isEmpty(formData) ? false : readonlyConfig;

const getDefaultValueWhenTypeIsFunction = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue: any,
  formData: Record<string, string>
) =>
  typeof defaultValue === 'function' ? defaultValue(formData) : defaultValue;
