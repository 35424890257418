// generate class value for column headers to be passed to class directive
import { Pipe, PipeTransform } from '@angular/core';
import {
  DataGridSelected,
  ElementTyping,
  FieldDefinitions
} from '@portal/app/shared/types';

@Pipe({ name: 'generateHeaderClassInput' })
export class GenerateClassInputPipe implements PipeTransform {
  transform(
    col: DataGridSelected,
    fieldDefs: FieldDefinitions,
    pivotRowsActive: boolean,
    pivotDashboardFieldId: string
  ) {
    if (pivotRowsActive && pivotDashboardFieldId !== col.dashboardFieldId) {
      return ['static-pivot-cell', 'random'];
    }
    if (col.type !== ElementTyping.pivotDimension) {
      return [
        fieldDefs[col.dashboardFieldId]?.format,
        fieldDefs[col.dashboardFieldId]?.literalId
      ];
    } else if (fieldDefs.PIVOT_DIMENSION) {
      return [
        fieldDefs.PIVOT_DIMENSION.format,
        `col-${fieldDefs.PIVOT_DIMENSION.literalId}`
      ];
    }
    return ['none', 'channel'];
  }
}
