import { Component, OnInit } from '@angular/core';
import {
  Brand as LibBrand,
  CurrencyCode,
  CurrencyService,
  CurrencySymbol,
  LocaleCode
} from '@libs/common-services';
import { SelectionService } from '@portal/app/shared/services/selection.service';
import { Brand, JustBrand } from '@portal/app/shared/types';

const DEFAULT_DISPLAY: CurrencySymbol = 'symbol';
const DEFAULT_CODE: CurrencyCode = 'USD';
const DEFAULT_LOCALE: LocaleCode = 'en-US';

@Component({
  selector: 'portal-customer-facing-taxonomy',
  templateUrl: './customer-facing-taxonomy.component.html',
  styleUrls: []
})
export class CustomerFacingTaxonomyComponent implements OnInit {
  clientId = 0;
  brandId = 0;
  constructor(
    private readonly selectionService: SelectionService,
    private readonly currencyService: CurrencyService
  ) {}

  ngOnInit() {
    this.selectionService.selectionChanged.subscribe((brand: JustBrand) => {
      this.initializeWithBrand(brand.brand);
    });
    if (this.selectionService.hasSelection()) {
      this.initializeWithBrand(this.selectionService.getSelection().brand);
    }
  }

  private initializeWithBrand(currentBrand: Brand | null) {
    let currencyDisplay = DEFAULT_DISPLAY;

    if (currentBrand) {
      currencyDisplay =
        this.currencyService.checkCurrencySymbol(
          currentBrand as unknown as LibBrand
        ) || DEFAULT_DISPLAY;
    }

    this.clientId = currentBrand?.clientId || 0;
    this.brandId = currentBrand?.brandId || 0;

    this.currencyService.updateCurrencyData({
      code: currentBrand?.currencyCode || DEFAULT_CODE,
      locale: currentBrand?.currencyLocale || DEFAULT_LOCALE,
      display: currencyDisplay
    });
  }
}
