export enum FieldDefinitions {
  ordersI = 'ordersI',
  salesI = 'salesI',
  ordersLT = 'ordersLT',
  salesLT = 'salesLT',
  cpoI = 'cpoI',
  roasI = 'roasI',
  mediaSpend = 'mediaSpend',
  previousOrdersI = 'previousOrdersI',
  previousSalesI = 'previousSalesI',
  currentOrdersI = 'currentOrdersI',
  currentSalesI = 'currentSalesI',
  proratedCurOrdersI = 'proratedCurOrdersI',
  proratedCurCpoI = 'proratedCurCpoI',
  proratedCurSalesI = 'proratedCurSalesI',
  proratedCurRoasI = 'proratedCurRoasI',
  changeInPercent = 'changeInPercent',
  tactic = 'tactic',
  channel = 'channel',
  incrementalRoas = 'incremental_roas_mim_xcd',
  newIncrementalRoas = 'incremental_roas_mim_v2',
  incrementalRoasPercentDiff = 'incremental_roas_percent_diff',
  incrementalCpo = 'incremental_cpo_mim_xcd',
  newIncrementalCpo = 'incremental_cpo_mim_v2',
  incrementalCpoPercentDiff = 'incremental_cpo_percent_diff',
  incrementalSalesPerc = 'incremental_sales_perc_mim_xcd',
  newIncrementalSalesPerc = 'incremental_sales_perc_mim_v2',
  incrementalSalesPercPercentDiff = 'incremental_sales_perc_percent_diff',
  incrementalOrdersPerc = 'incremental_orders_perc_mim_xcd',
  newIncrementalOrdersPerc = 'incremental_orders_perc_mim_v2',
  incrementalOrdersPercPercentDiff = 'incremental_orders_perc_percent_diff',
  adjustmentFactor = 'adjustment_factor_mim_xcd',
  newAdjustmentFactor = 'adjustment_factor_mim_v2',
  adjustmentFactorPercentDiff = 'adjustment_factor_percent_diff',
  incrementalOrders = 'incremental_orders_mim_xcd',
  incrementalOrdersPercentDiff = 'incremental_orders_percent_diff',
  newIncrementalOrders = 'incremental_orders_mim_v2',
  incrementalSales = 'incremental_sales_mim_xcd',
  newIncrementalSales = 'incremental_sales_mim_v2',
  incrementalSalesPercentDiff = 'incremental_sales_percent_diff'
}

// TODO (MP + GP): move to platform-model-ts
export enum FieldTooltipsText {
  baselineSales = 'The amount of revenue due to non-media factors',
  ordersI = 'The number of orders due to exposure to your media',
  percOrdersI = 'The portion of total orders due to exposure to your media',
  salesI = 'The amount of revenue due to exposure to your media',
  percSalesI = 'The portion of total revenue due to exposure to your media',
  aovI = 'The average value of your incremental orders',
  cpoI = 'The average cost for each incremental order',
  roasI = 'The return on ad spending generated by your incremental sales',
  totalOrders = 'The total orders reported by your transactional data sources',
  totalSales = 'The total revenue reported by your transactional data sources',
  mediaSpend = 'The amount you spent on media advertising within the date range',
  aov = 'The average value of your total orders',
  salesLT = 'The total revenue reported by your ad platforms',
  ordersLT = 'The total orders reported by your ad platforms',
  cpoLT = 'The cost per order reported by your ad platforms',
  roasLT = 'The return on ad spending reported by your ad platforms',
  baselineOrders = 'The number of orders due to non-media factors',
  impressionConversionRate = 'The percent of your ad impressions that became orders',
  clickConversionRate = 'The percent of your ad clicks that became orders'
}
