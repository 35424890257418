import { Component, inject } from '@angular/core';
import { GeoTestsService } from '@portal/app/dashboard/context-modal/services/geo-tests.service';
import { CardVariant } from '@design-system/components/m-card';
import { NavigationService } from '@portal/app/shared/services/navigation.service';

@Component({
  selector: 'portal-geo-test-cards',
  templateUrl: './geo-test-cards.component.html',
  styleUrl: './geo-test-cards.component.scss',
  providers: [GeoTestsService]
})
export class GeoTestCardsComponent {
  geoTestsService = inject(GeoTestsService);
  navigationService = inject(NavigationService);

  cardVariantType = CardVariant;

  isLoading = this.geoTestsService.isLoading;

  type = this.geoTestsService.type;
  liveGeoTest = this.geoTestsService.liveGeoTest;
  testOpportunities = this.geoTestsService.testOpportunities;

  isDataAvailable = this.geoTestsService.isDataAvailable;

  getLiveGeoTestDates = this.geoTestsService.getLiveGeoTestDates;
  getDaysLeftPercent = this.geoTestsService.getDaysLeftPercent;
  getDaysLeftLabel = this.geoTestsService.getDaysLeftLabel;

  liveGeoTestCard = this.geoTestsService.liveGeoTestCard;
  testOpportunitiesCard = this.geoTestsService.testOpportunitiesCard;
  latestTestResultCard = this.geoTestsService.latestTestResultCard;
  emptyStateCard = this.geoTestsService.emptyStateCard;

  onButtonAction() {
    this.navigationService.navigationMapping(
      'geo',
      {
        filters: [],
        extraParams: {
          // conversionTypeId,
          queryParams: {}
        }
      },
      true
    );
  }
}
