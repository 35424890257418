<div
  class="m-calendar"
  [ngClass]="{
    'week-resolution': resolution === 'week',
    'day-resolution': resolution !== 'week',
    'not-inline': !inline,
  }">
  <p-calendar
    [(ngModel)]="value"
    [view]="view"
    [dateFormat]="dateFormat"
    [readonlyInput]="readonlyInput"
    [inline]="inline"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [numberOfMonths]="numberOfMonths"
    [firstDayOfWeek]="firstDayOfWeek"
    [selectionMode]="selectionMode"
    [showIcon]="showIcon"
    (onMonthChange)="handleMonthChange($event)"
    (mouseover)="onHoverDate($event)"
    (onSelect)="onSelect.emit($event)"
    (click)="onDateClick($event)"
    (onShow)="handleShow($event)">
  </p-calendar>
</div>
