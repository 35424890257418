import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  IPopupTableColumnConfig,
  IPopupTableDataConfig
} from '@portal/app/shared/models/IPopup';

@Component({
  selector: 'portal-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent {
  @Input() dialogVisibility = false;
  @Input() dialogTitle = '';
  @Input() dialogSubTitle = '';
  @Input() tableColumns: IPopupTableColumnConfig[] = [];
  @Input() tableData: IPopupTableDataConfig[] = [];
  @Output() dialogVisibilityChange = new EventEmitter<boolean>();

  hidePopup() {
    this.dialogVisibilityChange.emit(false);
  }
}
