<div class="card-section-container">
  @if (currentLayout && isDataLoaded) {
    <div class="card-section" [ngClass]="currentLayout.literalId">
      @for (item of currentLayout.elements; track item) {
        @switch (item.layoutType) {
          @case ("CARD_COMPONENT") {
            <portal-custom-card
              [widgetLayout]="item"
              [fieldDefinitions]="fieldDefinitions"
              [data]="data">
            </portal-custom-card>
          }
          @case ("EDIT_POPUP") {
            <div>
              <portal-overlay-panel
                #op
                [layoutType]="item.layoutType"
                [filterSet]="filterSet"
                [fieldDefinitions]="fieldDefinitions"
                [data]="data"
                [widgetLayout]="item">
              </portal-overlay-panel>
              <div class="card-edit-popup-icon" data-html2canvas-ignore="true">
                <img
                  class="pencil-icon act-as-link"
                  (click)="op.show($event)"
                  src="assets/icons/pencil-with-border.svg"
                  alt="edit" />
              </div>
            </div>
          }
        }
      }
    </div>
  }
</div>
