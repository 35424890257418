import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import * as Highcharts from 'highcharts';
import { Chart } from 'angular-highcharts';
import {
  IChartData,
  IChartOptions,
  IChartSeriesOption,
  PlotlineValues,
  ResolutionType,
  RoasChangedEvent
} from '@portal/app/charts/shared/chart-model';
import { ChartService } from '@portal/app/charts/services/chart.service';
import {
  cloneDeep,
  find,
  forEach,
  groupBy,
  intersection,
  isArray,
  isEmpty,
  isEqual,
  isUndefined,
  kebabCase,
  keyBy,
  keys,
  map,
  mapValues,
  merge,
  omitBy,
  upperFirst,
  values
} from 'lodash-es';
import {
  getDefaultPlotlineControlValue as getDefaultPlotlineControlValue,
  getMetricFieldByLiteralId,
  getResolutionsOptionsFromFilters,
  getSelectedResolutionFromFilters,
  prepareChartSeries,
  prepareChartTooltip,
  prepareChartYAxisConfig,
  xAxisType
} from '@portal/app/charts/shared/utils';
import { ContextStore } from '@portal/app/shared/state/context.store';
import { ApiService } from '@portal/app/shared/services/api.service';
import { Observable, skip, Subscription, take } from 'rxjs';
import {
  ChartAvailableY,
  ChartElement,
  ChartPlotlineX,
  ChartResponse,
  ChartSelectedY,
  ContextField,
  ControlElement,
  DataEndpoint,
  DataGridConfig,
  DataGridExportConfig,
  DataResponse,
  DataResponseAccuracy,
  DataRowData,
  DataSetResponse,
  DisplayControl,
  ElementGroup,
  ElementSubTyping,
  ExportTableType,
  FieldDefinition,
  FieldDefinitions,
  Filter,
  Format,
  IFilterChange,
  TableToggleControl,
  WidgetType
} from '@portal/app/shared/types';
import {
  createFilterFields,
  getFiltersFromFilterFields,
  isMeasuredBenchmark,
  isMeasuredBenchmarkLandingPage,
  selectFilterControlValues,
  sortByDisplayOrder,
  sortByKey,
  sortByPosition,
  updateFilterOptions
} from '@portal/app/dashboard/utils';
import { CHART_COLORS } from '@portal/app/charts/shared/chart-constants';
import { DataGridStore } from '@portal/app/datagrid/store/data-grid.store';
import { ViewStore } from '@portal/app/shared/state/view.store';
import { DataGridService } from '@portal/app/datagrid/services/data-grid.service';

import { AccuracyAlertComponent } from '@portal/app/shared/components/accuracy-alert/accuracy-alert.component';
import { FormatterService } from '@portal/app/shared/services/formatter.service';
import { CompareService } from '@portal/app/shared/services/compare.service';
import { ChartCompareService } from '@portal/app/charts/services/chart-compare.service';
import { ActivatedRoute } from '@angular/router';
import { FiltersService } from '@portal/app/shared/services/filters.service';
import { DashboardActions } from '@portal/app/store/dashboard/actions';
import { AppState } from '@portal/app/store/app.state';
import { Store } from '@ngrx/store';
import { ExportTableService } from '@portal/app/shared/services/export-table.service';
import { MenuItem } from 'primeng/api';
import { DateTimeService } from '@portal/app/shared/services/date-time.service';
import {
  monthDateYearFormat,
  ProductLiteralIds
} from '@portal/app/shared/constants';
import { SelectionService } from '@portal/app/shared/services/selection.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

const RESOLUTION_FILTER_NAME = 'resolution';

@Component({
  selector: 'portal-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, OnDestroy {
  @ViewChild(AccuracyAlertComponent) accuracyAlert!: AccuracyAlertComponent;

  @Input() chartElement!: ChartElement;
  @Input() fieldDefinitions: FieldDefinitions = {};
  @Input() title = '';
  @Input() filterControls: ContextField[] = [];
  @Input() childrenControls: ContextField[] = [];
  @Input() elements: ChartElement[] = [];
  @Input() overrideExport = false;
  @Output() isUpdate = new EventEmitter();
  @Output() dataPointClick? = new EventEmitter();

  breakdownName!: string;
  productId = '';
  private firstDayOfWeek: number | undefined = 1;
  chartOptions: IChartOptions = this.chartService.getDefaultChartOptions();
  chartConfig!: Highcharts.Options;

  dashboardLiteralId = '';
  chart: Chart | null = null;
  resolutions: string[] = [];
  axisSeriesOptions: IChartSeriesOption[] = [];
  selectedSeriesValues: Record<string, IChartSeriesOption> = {
    key: {
      fields: [],
      label: '',
      disabled: true
    }
  };

  currentPlotlineControlValues: PlotlineValues = {};

  showMultipleAxes = false;
  disableMultipleAxisCheckbox = false;
  selectedResolution: ResolutionType = 'Week';
  selectedY: ChartSelectedY[] = [];
  availableY: ChartAvailableY[] = [];
  selectedDimensions: string[] = [];
  filters: Filter[] = [];
  public filterFields: ContextField[] = [];
  metricControls: { control: ControlElement; key: number }[] = [];
  plotlineX: ChartPlotlineX[] = [];
  plotlineControls: { control: ChartPlotlineX; key: number }[] = [];
  chartColors = CHART_COLORS;
  public showEmptyState = false;
  tableItem: ElementGroup | undefined = undefined;
  tableData: DataSetResponse[] = [];
  chartName = '';
  chartDataPoints: DataResponse | undefined;
  chartTableToggle: boolean | null = null;
  currentChartToggleValue: boolean | null = null;
  displayControls: DisplayControl[] = [];
  dataGridConfig: DataGridConfig = this.dataGridStore.getDataGridTableConfig();
  chartResponseAccuracy: DataResponseAccuracy | undefined;
  benchmarksResolutionFormat: ResolutionType = 'DateMonthYear';
  chartAccuracyForUI!: {
    displayAccuracy: boolean;
    message: string;
  };

  isCompareOn = false;
  chartDataLoading = false;

  metricControlParams: string[] = [];
  isMeasuredBenchmarkLandingPage = isMeasuredBenchmarkLandingPage;

  private readonly subs: Subscription = new Subscription();
  filterContext$: Observable<Filter[]> = this.contextStore.filterContext;
  chartFilters$: Observable<Filter[]> = this.filtersService.dataChartFilter;
  gridFilters$: Observable<Filter[]> = this.filtersService.dataGridFilter;
  onFilterChange$: Observable<IFilterChange> =
    this.filtersService.onfilterChanged;

  public exportChartMenu: MenuItem[] = [
    {
      label: 'CSV',
      icon: 'pi pi-fw pi-file',
      styleClass: 'export-item csv',
      command: () => {
        this.exportWithFormat('csv');
      }
    },
    {
      label: 'TSV',
      icon: 'pi pi-fw pi-file',
      styleClass: 'export-item tsv',
      command: () => {
        this.exportWithFormat('tsv');
      }
    },
    {
      label: 'Excel',
      icon: 'pi pi-fw pi-file',
      styleClass: 'export-item excel',
      command: () => {
        this.exportWithFormat('xlsx');
      }
    }
  ];

  chartResponse: ChartResponse = {
    fields: [],
    data: []
  };

  constructor(
    private readonly chartService: ChartService,
    private readonly formatterService: FormatterService,
    private readonly contextStore: ContextStore,
    private readonly apiService: ApiService,
    private readonly dataGridStore: DataGridStore,
    private readonly viewStore: ViewStore,
    private readonly dataGridService: DataGridService,
    private filtersService: FiltersService,
    private readonly compareService: CompareService,
    private readonly chartCompareService: ChartCompareService,
    private readonly route: ActivatedRoute,
    private store: Store<AppState>,
    private readonly exportTableService: ExportTableService,
    private readonly dateService: DateTimeService,
    private readonly selectionService: SelectionService
  ) {
    /* Warning: Below subscription needed to be skipped for first time as on initial load we have the
    filters and chart specific filter subscription in-place and this is redundant for the initial load
     */
    this.onFilterChange$.pipe(takeUntilDestroyed(), skip(1)).subscribe((fc) => {
      if (
        fc.filterType === WidgetType.context ||
        fc.filterType === WidgetType.chart
      ) {
        this.updateFilters(fc.filters as Filter[]);
      }
    });
  }

  ngOnInit(): void {
    this.firstDayOfWeek =
      this.selectionService.getSelection().brand?.firstDayOfWeek;
    this.chartConfig = this.chartService.getDefaultChartConfig(
      this.productId,
      this.firstDayOfWeek
    );
    const compareSub = this.compareService.isCompareOn.subscribe(
      (isCompareOnVal) => {
        this.isCompareOn = isCompareOnVal;
        if (isCompareOnVal) {
          this.showMultipleAxes = false;
        }
      }
    );
    this.subs.add(compareSub);
    this.setupChartInitialValue();
    this.setShowTableToggle();
    this.setShouldOverrideExport();
    const filterSub = this.filterContext$.pipe(take(1)).subscribe({
      next: (filters) => {
        this.filters = cloneDeep(filters);
        this.updateFilters(this.filters);
        if (this.filterControls[0]) {
          this.setGlobalFilterValues(this.filterControls[0]);
        }
      }
    });
    filterSub.unsubscribe();
    this.setupChartMetrics();
    this.chartOptions = {
      ...this.prepareIChartOptions(
        this.chartElement as ChartElement,
        this.filters,
        this.fieldDefinitions
      )
    };
    this.createChart();
    this.resolutions = this.chartOptions.resolutionOptions.map((i) =>
      upperFirst(i)
    );

    this.selectedResolution = upperFirst(
      this.chartOptions.selectedResolution
    ) as ResolutionType;
  }

  /**
   * Hack:PMDEV-4108 Ensures that `selectedY` array never contains more than two items.
   * This is a temporary solution to enforce the two-item limit.
   *
   * @param selectedY - The array of selected Y-axis elements.
   * @returns Filtered array containing a maximum of 2 elements.
   */
  private filterSelectedY(selectedY: ChartSelectedY[]): ChartSelectedY[] {
    return this.productId !== ProductLiteralIds.ddi
      ? selectedY?.slice(0, 2)
      : selectedY;
  }

  // initialize values used through out the component
  setupChartInitialValue() {
    this.selectedY = this.filterSelectedY(
      this.chartElement?.DIMENSION?.selectedY as ChartSelectedY[]
    );

    this.availableY = this.chartElement?.DIMENSION
      ?.availableY as ChartAvailableY[];
    // collecting dashboardLiteralId from route
    this.subs.add(
      this.route.params.subscribe((data) => {
        this.dashboardLiteralId = data.literalId;
      })
    );
    this.viewStore.selectedBCPDId.pipe(take(1)).subscribe((value) => {
      this.productId = value.productId;
    });
    this.route.queryParams.subscribe((params) => {
      if (params.chartDimensions) {
        this.metricControlParams = JSON.parse(params.chartDimensions);
      }
    });
    this.chartConfig = this.chartService.getDefaultChartConfig(
      this.productId,
      this.firstDayOfWeek
    );
  }

  // takes accuracy from api response ang gereates information required by UI indication such as alert message
  generateAccuracyDetailsForMessage() {
    const accuracyResolutions: string[] = [];
    const accuracy: Record<string, number> = {};
    const accuracyMetrics: string[] = [];
    let displayAccuracy = false;
    let accuracyExists = false;
    let message = '';
    if (
      this.chartResponseAccuracy &&
      Object.keys(this.chartResponseAccuracy).length > 0
    ) {
      accuracyExists = Object.keys(this.chartResponseAccuracy).length > 0;
      const allMetrics = Object.keys(this.chartResponseAccuracy);
      forEach(allMetrics, (val) => {
        if (
          accuracyExists &&
          this.chartResponseAccuracy &&
          (
            this.chartResponseAccuracy[val] as {
              resolution: string;
              missingResolutions: number;
            }
          ).missingResolutions > 0
        ) {
          accuracyMetrics.push(val);
          accuracyResolutions.push(
            (
              this.chartResponseAccuracy[val] as {
                resolution: string;
                missingResolutions: number;
              }
            ).resolution as string
          );
          accuracy[val] = (
            this.chartResponseAccuracy[val] as {
              resolution: string;
              missingResolutions: number;
            }
          ).missingResolutions;
        }
      });
      displayAccuracy = Object.keys(accuracy).length > 0;
      if (Object.keys(accuracy).length > 2) {
        message = `the metrics in chart are missing data for a few ${this.selectedResolution}s from selected period`;
      } else if (Object.keys(accuracy).length > 0) {
        let newMessage = '';
        for (let i = 0; i < accuracyMetrics.length; i++) {
          const newResolution =
            accuracyMetrics[i] &&
            (accuracy[accuracyMetrics[i] as string] as number) > 1
              ? `${accuracyResolutions[i]}s`
              : accuracyResolutions[i];
          newMessage += `${
            (
              this.fieldDefinitions[
                accuracyMetrics[i] as string
              ] as FieldDefinition
            ).label
          } is missing data for ${
            accuracy[accuracyMetrics[i] as string]
          } ${newResolution}`;
          newMessage +=
            i === accuracyMetrics.length - 1
              ? ' in selected time period.'
              : ' and ';
        }
        message = newMessage;
      }
    }
    this.chartAccuracyForUI = {
      displayAccuracy,
      message
    };
  }

  prepareIChartOptions(
    val: unknown,
    filters: Filter[],
    fieldDefs: FieldDefinitions
  ): IChartOptions {
    const element = val as ChartElement;
    // TODO Re-enable when charts section is loaded
    const chartOptions = this.chartService.getDefaultChartOptions();
    chartOptions.type = 'line';
    // options that we need to show in control
    chartOptions.axisSeriesOption = this.axisSeriesOptions;
    // x axis series can only be one field
    chartOptions.xAxisPropertyField = getMetricFieldByLiteralId(
      'date',
      fieldDefs
    );
    // display series in y axis (can be multiple)
    //getting this.axisSeriesOptions set up before finding options values in it from getOptionBySubtype()
    if (this.metricControls.length) {
      //creating an array of primaryMetric and secondaryMetric default values
      chartOptions.yAxisPropertyFields = [];
      forEach(this.selectedSeriesValues, (value) => {
        chartOptions.yAxisPropertyFields.push(...value.fields);
      });
    } else {
      chartOptions.yAxisPropertyFields = (
        this.selectedY?.sort(sortByDisplayOrder) || []
      ).map((i) => i.dashboardFieldId);
    }
    // TODO pass data as a *string or a httprequest object*, or data
    chartOptions.data = [];
    // list of options to show in resolution dropdown
    chartOptions.resolutionOptions = getResolutionsOptionsFromFilters(filters);
    // selected resolution option
    chartOptions.selectedResolution = getSelectedResolutionFromFilters(filters);

    const chartFilters = filters.filter(
      (f) => f.applyTo && f.applyTo.includes('DATA_CHART')
    );

    if (chartFilters && chartFilters.length) {
      const childControls = this.childrenControls.map((f) => f.name);
      const filterFields = createFilterFields(element, chartFilters).filter(
        (filter) => !childControls.includes(filter.name)
      );
      this.filterFields =
        filterFields && filterFields.length
          ? filterFields?.sort(sortByPosition)
          : filterFields;
    }
    return chartOptions;
  }

  //expand the UI indication to display the message everytime a metric with inaccurate data is being displayed
  displayMessage(): void {
    if (
      this.accuracyAlert?.messageDiv &&
      this.chartAccuracyForUI.displayAccuracy
    ) {
      this.accuracyAlert.messageDiv.nativeElement.classList.toggle(
        'display-message'
      );
      setTimeout(() => {
        if (this.accuracyAlert?.messageDiv) {
          this.accuracyAlert.messageDiv.nativeElement.classList.toggle(
            'display-message'
          );
        }
      }, 5000);
    }
  }

  //called on roas input change
  handleRoasChange(event: RoasChangedEvent, key: number) {
    const value = Number((event.target as HTMLTextAreaElement).value);
    const currentValue = value;
    const roasFilter = this.filters.find(
      (filter) => filter.literalId === 'roasI'
    );
    if (roasFilter) {
      roasFilter.value = value;
      this.store.dispatch(DashboardActions.setFilter({ filter: roasFilter }));
    }
    if (this.currentPlotlineControlValues) {
      // let previousval = 0;
      if (
        currentValue ||
        !currentValue ||
        currentValue === 0 ||
        currentValue === null
      ) {
        (
          this.currentPlotlineControlValues[key] as {
            value: number;
            label: string;
          }
        ).value = currentValue;
      }
      this.updateChart();
    }
  }

  updateChart(
    showLoading = false,
    highChartsOptions: Highcharts.Options = this.prepareHighchartsOptions(
      this.chartOptions
    )
  ) {
    if (this.chart) {
      this.chart.ref$.pipe(take(1)).subscribe((chart) => {
        chart.update(highChartsOptions, true, true);
        if (showLoading) {
          chart.showLoading('');
        } else {
          chart.hideLoading();
        }
        chart.reflow();
      });
    }
  }

  setupChartMetrics(): void {
    const selectedMetrics = this.metricControlParams;
    this.axisSeriesOptions = [];
    this.metricControls = [];

    forEach(this.availableY, (option) => {
      // if an availableY config contains multiDashboardFieldId the collect the multiDashboardFieldId array as field, instead of collecting dashboardFieldId
      const label = this.fieldDefinitions[option.dashboardFieldId]?.label;
      this.axisSeriesOptions.push({
        fields: option.additionalAttributes?.multiDashboardFieldIds.length
          ? option.additionalAttributes?.multiDashboardFieldIds
          : [option.dashboardFieldId],
        label: label || '',
        disabled: false,
        specialLabel: this.getSpecialLabel(label || '')
      });
    });

    // SETTING UP METRICS
    //finding and saving metric controls in an array
    //setting default selected values
    //adding key and color properties to metric controls being stored
    this.selectedSeriesValues = {};
    if (this.chartElement?.metricControl) {
      const controls: ControlElement[] = this.chartElement
        ?.metricControl as ControlElement[];
      if (controls && isArray(controls)) {
        forEach(controls.sort(sortByDisplayOrder), (control, key) => {
          const seriesPresent = this.getSeriesOptionByFieldId(
            selectedMetrics[key] as string
          );
          if (selectedMetrics.length && seriesPresent) {
            control.dashboardFieldId = selectedMetrics[key] || '';
            this.metricControls.push({ control, key });
          } else {
            this.metricControls.push({ control, key });
          }
        });
      }
      if (this.selectedY && this.selectedY.length) {
        forEach(this.selectedY.sort(sortByDisplayOrder), (element, key) => {
          const seriesPresent = this.getSeriesOptionByFieldId(
            selectedMetrics[key] as string
          );
          if (selectedMetrics.length && seriesPresent) {
            element.dashboardFieldId = selectedMetrics[key] || '';
          }
          this.selectedSeriesValues = omitBy(
            {
              ...this.selectedSeriesValues,
              [key]: this.getSeriesOptionByFieldId(element.dashboardFieldId)
            },
            isUndefined
          );
        });
      }
    }

    // HACK: Final cleanup with setTimeout to remove undefined values after async processes
    setTimeout(() => {
      this.selectedSeriesValues = Object.fromEntries(
        Object.entries(this.selectedSeriesValues).filter(
          ([, value]) => value !== undefined
        )
      );
    }, 0);

    // setting default value in chartDimensions filter to fetch them on load
    if (this.metricControls.length) {
      for (const ifilter of this.filters) {
        if (ifilter.literalId === 'chartDimensions') {
          const filterValues: string[] = [];
          forEach(this.selectedSeriesValues, (seriesValue) => {
            filterValues.push(...seriesValue.fields);
          });
          ifilter.value = filterValues;
          break;
        }
      }
    }
    this.disableSelectedOptions();
    this.evaluateMultipleAxesCheckboxAvailability();

    // SETTING UP TARGET ROAS
    this.plotlineX = (this.chartElement?.plotlineX || []) as ChartPlotlineX[];
    const filters: ChartPlotlineX[] = this.plotlineX;
    this.currentPlotlineControlValues = {};
    this.plotlineControls = [];
    forEach(filters, (control, key) => {
      this.plotlineControls.push({ control, key });
      this.currentPlotlineControlValues = {
        ...this.currentPlotlineControlValues,
        [key]: {
          value: getDefaultPlotlineControlValue(
            this.filters,
            control.dashboardFilterId as string
          ),
          label: control.label
        }
      };
    });
    this.metricControlParams = [];
  }

  getSpecialLabel(
    label: string
  ): { label: string; highlightedValue: string } | undefined {
    if (isMeasuredBenchmarkLandingPage(this.dashboardLiteralId)) {
      const splittedString = label.split(' ');
      return {
        label: splittedString[0] || '',
        highlightedValue: splittedString[1] || ''
      };
    }
  }

  createChart(): void {
    const chartConfig = this.prepareHighchartsOptions(this.chartOptions);
    this.chart = new Chart(chartConfig);
    if (isEmpty(this.chartOptions.data) && this.chart) {
      this.loadData();
    }
  }

  prepareHighchartsOptions(chartOptions: IChartOptions): Highcharts.Options {
    let chartConfig = this.chartConfig;
    chartConfig.chart = merge({}, chartConfig.chart, {
      type: chartOptions.type
    });
    let yAxis = prepareChartYAxisConfig({
      chartOptions: this.chartOptions,
      isMultipleAxesActive: this.showMultipleAxes,
      fieldDefinitions: this.fieldDefinitions,
      plotlineValues: this.currentPlotlineControlValues,
      formatterServiceInstance: this.formatterService
    });

    const series = prepareChartSeries({
      chartOptions: this.chartOptions,
      isMultipleAxesActive: this.showMultipleAxes,
      fieldDefinitions: this.fieldDefinitions,
      plotlineValues: this.currentPlotlineControlValues,
      formatterServiceInstance: this.formatterService
    });

    const tooltip = prepareChartTooltip(
      this.selectedResolution,
      this.fieldDefinitions,
      this.chartConfig.tooltip,
      this.formatterService,
      this.productId,
      this.hasCompare()
    );

    const resolutionField = this.filterFields.find(
      (f) => f.name === RESOLUTION_FILTER_NAME
    );
    if (resolutionField && resolutionField.value) {
      this.selectedResolution = upperFirst(
        resolutionField.value as string
      ) as ResolutionType;
    }

    yAxis = this.customizeYaxis(yAxis, this.productId);

    const chartConfigData = {
      yAxis,
      series,
      xAxis: {
        ...chartConfig.xAxis,
        type: xAxisType(
          this.fieldDefinitions[
            chartOptions.xAxisPropertyField
          ] as FieldDefinition
        )
      },
      tooltip
    };
    chartConfig = {
      ...chartConfig,
      ...chartConfigData
    };
    return chartConfig;
  }

  seriesSelectionChanged(calledFromHtml = false): void {
    this.chartDataLoading = true;
    this.disableSelectedOptions();
    const axisSeriesOptionsFields: string[] = [];
    forEach(this.selectedY.sort(sortByDisplayOrder), (option) => {
      axisSeriesOptionsFields.push(option.dashboardFieldId);
    });
    const selectedValueFields: string[] = [];
    forEach(keys(this.selectedSeriesValues).sort(sortByKey), (idx) => {
      selectedValueFields.push(
        ...(this.selectedSeriesValues[idx] as IChartSeriesOption).fields
      );
    });
    if (this.metricControls.length) {
      this.chartOptions.yAxisPropertyFields = [];
      this.chartOptions.yAxisPropertyFields.push(...selectedValueFields);
    } else {
      this.chartOptions.yAxisPropertyFields = Array.from(
        new Set([...selectedValueFields, ...axisSeriesOptionsFields])
      );
    }
    if (calledFromHtml) {
      this.store.dispatch(
        DashboardActions.setChart({
          chartSelectedY: this.chartOptions.yAxisPropertyFields,
          chartElement: this.breakdownName
        })
      );
    }
    this.evaluateMultipleAxesCheckboxAvailability();
    this.setChartFilterValues();
  }

  disableSelectedOptions(): void {
    if (this.metricControls.length) {
      const selectedSeriesOptionValues: string[] = [];
      forEach(this.selectedSeriesValues, (seriesValue) => {
        selectedSeriesOptionValues.push(...seriesValue.fields);
      });

      this.axisSeriesOptions.forEach((option) => {
        option.disabled = isEqual(
          intersection(selectedSeriesOptionValues, option.fields),
          option.fields
        );
      });
    }
  }

  toggleMultipleAxes(): void {
    this.updateChart();
  }

  loadData(): void {
    let chartFilterSub: Subscription;
    const sub = this.filterContext$.subscribe({
      next: (filterSet) => {
        const filters = cloneDeep(filterSet);
        this.tableItem = undefined;
        this.tableData = [];
        this.filters = filters;
        if (chartFilterSub) {
          chartFilterSub.unsubscribe();
        }
        this.setChartFilterValues(true);

        this.updateChart(true);
        chartFilterSub = this.chartFilters$.subscribe((chartFilters) => {
          chartFilters.forEach((filter) => {
            this.store.dispatch(DashboardActions.setFilter({ filter }));
          });
          if (this.chart != null) {
            this.chart.ref$
              .pipe(take(1))
              .subscribe((chart) => chart.showLoading(''));
          }
          let gridFilters: Filter[] = [];
          this.gridFilters$
            .pipe(take(1))
            .subscribe((fil) => (gridFilters = fil));
          this.apiService
            .getDashboardData(
              {
                filters: this.getMappedFilters(
                  filters,
                  chartFilters,
                  gridFilters,
                  'literalId'
                ),
                type: DataEndpoint.dataCharts
              },
              this.dashboardLiteralId
            )
            .then((response) => {
              this.showEmptyState = false;
              this.chartDataLoading = false;
              if (this.chart != null) {
                this.chart.ref$
                  .pipe(take(1))
                  .subscribe((chart) => chart.showLoading(''));
              }

              const typedResponse = response as DataSetResponse[];
              let typedData: DataResponse[] = [];
              this.chartName = '';
              this.chartDataPoints = undefined;
              if (typedResponse[0]) {
                this.chartName = typedResponse[0].name;
                this.chartDataPoints = typedResponse[0].dataPoints;
                this.chartResponseAccuracy = typedResponse[0].accuracy;
                typedData = typedResponse[0].data ? typedResponse[0].data : [];
                this.generateAccuracyDetailsForMessage();
                this.displayMessage();
              }

              if (this.chartOptions && this.chart != null) {
                this.chartOptions.data = (
                  typedResponse[0] as DataSetResponse
                ).data.map((row) =>
                  mapValues(row, (value: string | number | null) =>
                    typeof value === 'number'
                      ? isFinite(value)
                        ? value
                        : 0
                      : value === 'Infinity' || value === 'NaN'
                        ? 0
                        : value
                  )
                );

                if ((typedResponse[0] as DataSetResponse).seriesBy) {
                  let chartData: IChartData[] = [];
                  const propNames = this.selectedY.map(
                    (sY) => sY.dashboardFieldId
                  );
                  if (propNames) {
                    for (const propName of propNames) {
                      chartData = (
                        typedResponse[0] as DataSetResponse
                      ).data.map((data) => {
                        return {
                          data: data.data as unknown as DataResponse[],
                          name: data.name,
                          propName: propName as string
                        };
                      }) as IChartData[];
                    }
                  }
                  chartData.map((data) => {
                    data.data.map((d) => {
                      map(d, (value, key) => {
                        if (value) {
                          d[key] =
                            typeof value === 'number'
                              ? isFinite(value)
                                ? value
                                : 0
                              : value === 'Infinity' || value === 'NaN'
                                ? 0
                                : value;
                        }
                      });
                    });
                  });
                  this.chartOptions.data = chartData;
                } else if (
                  this.childrenControls.length &&
                  !(typedResponse[0] as DataSetResponse).seriesBy
                ) {
                  const groupByNames = this.childrenControls.map(
                    (control) => control.name
                  );

                  if (groupByNames.length) {
                    const chartData: IChartData[] = [];
                    const propNames = this.selectedY.map(
                      (sY) => sY.dashboardFieldId
                    );
                    for (const groupName of groupByNames) {
                      if (propNames && propNames.length) {
                        for (const propName of propNames) {
                          chartData.push(
                            ...values(
                              mapValues(
                                groupBy(this.chartOptions.data, groupName),
                                (data, name) =>
                                  ({ name, propName, data }) as IChartData
                              )
                            )
                          );
                        }
                      }
                    }
                    this.chartOptions.data = chartData;
                  }
                }

                this.chartCompareService.showCompareData(this, typedData);

                if (!this.chartOptions.data.length) {
                  this.showEmptyState = true;
                }

                this.updateChart(false);
                this.setDisplayControls();
                this.toggleChartTable(this.currentChartToggleValue);
              }
            })
            .catch((error) => {
              this.chartDataLoading = false;
              if (error && error.error) {
                this.showEmptyState = true;
                this.chartOptions.data = [];
                this.updateChart(false);
              }
              if (this.chart != null) {
                this.chart.ref$
                  .pipe(take(1))
                  .subscribe((chart) => chart.hideLoading());
              }
            });
        });
        this.subs.add(chartFilterSub);
      }
    });
    this.subs.add(sub);
  }

  getSeriesOptionByFieldId(dashboardFieldId: string): IChartSeriesOption {
    const availableYLayout = this.availableY.find(
      (availabley) => availabley.dashboardFieldId === dashboardFieldId
    );
    if (
      availableYLayout?.additionalAttributes?.multiDashboardFieldIds.length > 0
    ) {
      const value = find(this.axisSeriesOptions, (option) =>
        isEqual(
          option.fields,
          availableYLayout?.additionalAttributes?.multiDashboardFieldIds
        )
      );
      return value as unknown as IChartSeriesOption;
    } else {
      const value = find(this.axisSeriesOptions, (option) =>
        option.fields.includes(dashboardFieldId)
      );
      return value as unknown as IChartSeriesOption;
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.filtersService.clearFilters();
  }

  // decide whether multple axis checkbox should be displayed
  evaluateMultipleAxesCheckboxAvailability(): void {
    const defs = this.fieldDefinitions;
    let formats: Format[] = [];
    if (this.metricControls.length) {
      //saving the format of first selectedSeries option and comparing it with formats of other option values
      forEach(this.selectedSeriesValues, (seriesValue) => {
        forEach(seriesValue.fields, (seriesField) => {
          formats.push((defs[seriesField] as FieldDefinition).format);
        });
      });
    } else {
      forEach(this.chartOptions.yAxisPropertyFields, (yAxisPropertyField) => {
        formats.push((defs[yAxisPropertyField] as FieldDefinition).format);
      });
    }
    formats = Array.from(new Set([...formats]));
    if (formats.length > 1) {
      this.disableMultipleAxisCheckbox = true;
    } else if (formats.length === 1) {
      this.disableMultipleAxisCheckbox = false;
    }
    this.showMultipleAxes = this.isCompareOn
      ? false
      : this.disableMultipleAxisCheckbox || this.showMultipleAxes;
  }

  setChartFilterValues(initialLoad?: boolean): void {
    if (this.metricControls.length) {
      this.selectedDimensions = [];
      forEach(keys(this.selectedSeriesValues).sort(sortByKey), (idx) => {
        if (this.selectedSeriesValues[idx]) {
          this.selectedDimensions.push(
            ...(this.selectedSeriesValues[idx] as IChartSeriesOption).fields
          );
        }
      });
      this.chartOptions.yAxisPropertyFields = this.selectedDimensions;
    } else {
      this.selectedDimensions = [...this.chartOptions.yAxisPropertyFields];
    }

    const filters = cloneDeep(this.filters) || [];
    if (filters.length !== 0) {
      const globalFilters = getFiltersFromFilterFields(
        this.filterControls,
        filters,
        'DATA_CHART'
      );

      const chartFilters = getFiltersFromFilterFields(
        this.filterFields,
        filters,
        'DATA_CHART'
      );

      const childrenFilters = getFiltersFromFilterFields(
        this.childrenControls,
        filters,
        'DATA_CHART'
      );

      const dimensionFilter = filters.find(
        (f) => f.literalId === 'chartDimensions'
      );
      if (dimensionFilter != null) {
        dimensionFilter.value = this.selectedDimensions;
        chartFilters.push(dimensionFilter);
        const selectedY: ChartSelectedY[] = [];
        this.selectedDimensions.forEach((d) => {
          const foundAvailableY = this.chartElement.availableY.find(
            (av: ChartAvailableY) => d === av.dashboardFieldId
          );
          if (foundAvailableY) {
            selectedY.push(
              Object.assign({}, foundAvailableY, {
                subType:
                  ElementSubTyping.selectedY as typeof ElementSubTyping.selectedY
              })
            );
          }
        });
        this.selectedY = selectedY;
      } else {
        const filter = {
          literalId: 'chartDimensions',
          value: this.selectedDimensions
        } as Filter;
        chartFilters.push(filter);
      }
      const chartSub = this.filtersService
        .changeDataChartFilter([
          ...globalFilters,
          ...childrenFilters,
          ...chartFilters
        ])
        .subscribe({
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          next: (f) => {
            if (!initialLoad) {
              this.filtersService.changeFilters({
                widgetType: WidgetType.chart,
                endpoint: DataEndpoint.dataCharts
              });
            }
          },
          error: (err) => console.error(err)
        });
      chartSub.unsubscribe();
      this.updateChart(true);
    }
  }

  updateFilters(filters: Filter[]) {
    this.filterControls = updateFilterOptions(filters, this.filterControls);
    this.filterFields = updateFilterOptions(filters, this.filterFields);
  }

  updateChartSeriesControls(childField: ContextField): void {
    this.chartDataLoading = true;
    // additionalLayout property might change once the json based column in db is added
    if (!childField?.value?.length && childField?.additionalAttributes) {
      childField.value = selectFilterControlValues(childField);
    }
    this.setChartFilterValues();
  }

  // triggered by breakdown
  setGlobalFilterValues(control: ContextField) {
    if (control.children) {
      const childControl = control.children.find(
        (c) => c.parent === control.value
      );
      if (childControl) {
        this.childrenControls = [childControl];
      } else {
        this.childrenControls = [];
      }
    }

    const chartElement = this.elements.find(
      (el) => el.literalId === control.value
    );

    if (chartElement) {
      this.breakdownName = chartElement.literalId;
      this.chartElement = chartElement;
      this.setupChartInitialValue();
      this.setupChartMetrics();
      return this.seriesSelectionChanged();
    }

    this.setChartFilterValues();
  }

  toggleChartTable(toggleValue: boolean | null): void {
    this.currentChartToggleValue = toggleValue;
    if (toggleValue) {
      this.setChartTableData();
    } else {
      this.tableItem = undefined;
      this.tableData = [];
    }
  }

  setChartTableData(): void {
    if (this.productId === 'cross-platform-doe') {
      this.dataGridConfig.striped = false;
      this.dataGridConfig.tableScrollHeightClass = 'short';
      this.dataGridConfig.showFooter = false;
    }
    this.tableItem = cloneDeep({
      elements: [
        {
          selected: [...this.chartElement.selectedX, ...this.selectedY],
          layoutType: WidgetType.table,
          literalId: this.chartName,
          span: 12
        }
      ]
    }) as unknown as ElementGroup;
    const chartData = this.chartOptions.data
      ? (this.chartOptions.data as DataResponse[])
      : [];
    const chartTableData = chartData.map((chartDataObj) => {
      if (chartDataObj.date) {
        chartDataObj.date = new Date(chartDataObj.date).toDateString();
      }
      return chartDataObj;
    });
    this.tableData = [
      { name: this.chartName, data: chartTableData }
    ] as DataSetResponse[];
  }

  setShowTableToggle(): void {
    const chartTableToggleControl: TableToggleControl[] | undefined =
      this.chartElement?.tableToggleControl;
    this.chartTableToggle =
      chartTableToggleControl && chartTableToggleControl.length ? false : null;
  }

  setShouldOverrideExport(): void {
    this.overrideExport = !!(
      this.chartElement &&
      this.chartElement.exportTable &&
      this.chartElement.exportTable[0]
    );
  }

  setDisplayControls(): void {
    this.displayControls = this.chartElement.displayControl || [];
    this.displayControls.forEach((control) => {
      if (this.chartDataPoints) {
        control.value = this.chartDataPoints[
          control.dashboardFieldId
        ] as number;
      }
    });
  }

  overrideExportChartHandler(): void {
    const config: DataGridExportConfig = {
      tableName: this.chartName,
      fileName: kebabCase(this.chartName),
      showFooter: false
    };
    this.dataGridService.exportTable(config);
  }

  hasCompare() {
    return this.compareService.compare;
  }

  // convert different types of filter into a single unique array of filters
  getMappedFilters(
    filters: Filter[],
    chartFilters: Filter[],
    dataGridFilters: Filter[],
    objectMappingKey: string
  ) {
    return map(
      keyBy(
        ([] as Filter[]).concat(filters, chartFilters, dataGridFilters),
        objectMappingKey
      ),
      (ifilter) => ifilter
    );
  }

  // add any special y axis options customization logic here
  customizeYaxis(
    yAxis: Highcharts.YAxisOptions | Highcharts.YAxisOptions[],
    productId: string
  ): Highcharts.YAxisOptions | Highcharts.YAxisOptions[] {
    const customizedYaxis = yAxis;
    if (isMeasuredBenchmark(productId)) {
      this.selectedResolution = this.benchmarksResolutionFormat;
      if (
        customizedYaxis &&
        !isArray(customizedYaxis) &&
        customizedYaxis.labels
      ) {
        customizedYaxis.labels.style = {
          color: '#354358',
          fontSize: '14px'
        };
        return customizedYaxis;
      } else if (customizedYaxis && isArray(customizedYaxis)) {
        forEach(customizedYaxis, (yaxisOption) => {
          if (yaxisOption.labels) {
            yaxisOption.labels.style = {
              color: '#354358',
              fontSize: '14px'
            };
          }
        });
        return customizedYaxis;
      } else {
        return customizedYaxis;
      }
    }
    return yAxis;
  }

  exportWithFormat(format: ExportTableType): void {
    this.chartResponse.fields = [
      ...this.chartElement.selectedX,
      ...this.selectedY
    ];
    const data = this.prepareChartExportData();
    const fileName = `${this.dashboardLiteralId}-${this.dateService
      .today()
      .format(monthDateYearFormat)}`;
    const exportObjParams = {
      literalId: this.chartName,
      name: fileName,
      format,
      chart: {
        ...this.chartResponse,
        data
      }
    };
    this.apiService.getExportData({
      filters: this.filters,
      type: DataEndpoint.chartExport,
      exportObjParams
    });
  }

  prepareChartExportData(): DataResponse[] {
    const dashboardFieldIds = this.chartResponse.fields.map(
      (e) => e.dashboardFieldId
    );

    const dataResponse: DataResponse[] = [];
    this.chartOptions.data.forEach((chartData) => {
      const data: DataResponse = {};
      dashboardFieldIds.forEach((id) => {
        if (this.isDateField(id)) {
          data[id] = this.dateService.formatWithoutLocalTimeZone(
            (chartData as DataResponse)[id],
            monthDateYearFormat
          );
        } else {
          data[id] = (chartData as DataResponse)[id] as DataRowData;
        }
      });
      dataResponse.push(data);
    });

    return dataResponse;
  }

  private isDateField = (id: string): boolean =>
    (this.fieldDefinitions[id] as FieldDefinition).type === 'datetime';
}
