import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Subscription, filter, switchMap, take, tap } from 'rxjs';
import { SelectionService } from '@portal/app/shared/services/selection.service';
import { DashboardService } from '@portal/app/shared/services/dashboard.service';
import { ConfigDashboardService } from '@portal/app/shared/services/config-dashboard.service';
import {
  BrandRow,
  ConfigDashboardInstance,
  DisplayDashboard
} from '@portal/app/shared/types';
import { UserModel } from '@portal/app/shared/models/userModel';
import { MenuItem } from 'primeng/api';
import { ViewStore } from '@portal/app/shared/state/view.store';
import {
  ActivationEnd,
  ActivationStart,
  ChildActivationEnd,
  ChildActivationStart,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
  RouterEvent,
  Scroll
} from '@angular/router';
import { TableauService } from '@portal/app/shared/services/tableau.service';
import { BrandRowsService } from '@portal/app/shared/services/brand-rows.service';
import { getNextToLastUriSegment } from '@portal/app/shared/helpers/helpers';
import { AuthService } from '@portal/app/shared/services/auth.service';
import { landingDashboardURI } from '@portal/app/dashboard/utils';
import { NavigationService } from '@portal/app/shared/services/navigation.service';
import { NavbarConstants } from '@portal/app/shared/types/navigation';
import { NotificationCenterService } from '@portal/app/dashboard/notification-center/notification-center.service';
import { NotificationViewMode } from '@design-system/components/m-alert-item';
declare type RouterEvents =
  | RouterEvent
  | RouteConfigLoadStart
  | RouteConfigLoadEnd
  | ChildActivationStart
  | ChildActivationEnd
  | ActivationStart
  | ActivationEnd
  | Scroll;

@Component({
  selector: 'portal-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterContentInit, OnDestroy {
  @Output() public exportRequest = new EventEmitter<Event>();

  @ViewChild('emailModalContent')
  public emailModalContent!: TemplateRef<unknown>;

  public landingDashboardLink = '';
  public brandProductPath = 'products';

  public username = '';
  public userOptionsMenuItems: MenuItem[] = [];
  public settingsMenuItems: MenuItem[] = [];
  public supportMenuItems: MenuItem[] = [];
  public onClickBenchmark?: () => void = undefined;
  public onClickOptimize?: () => void = undefined;
  public experimentMenuItems: MenuItem[] = [];
  public analyzeMenuItems: MenuItem[] = [];
  public optimizeMenuItems: MenuItem[] = [];

  public logOutModalIsVisible = false;
  public brandsDialogVisible = false;
  public brandLabel = '';
  public dashboardTitle = '';
  public dashboards: DisplayDashboard[] = [];
  public showExport = false;
  public tableauExportItems: MenuItem[] = [
    {
      label: 'PDF',
      icon: 'pi pi-fw pi-file',
      styleClass: 'tableau-export-item pdf',
      command: () => {
        this.handleExport('pdf');
      }
    },
    {
      label: 'Excel',
      icon: 'pi pi-fw pi-file',
      styleClass: 'tableau-export-item excel',
      command: () => {
        this.handleExport('excel');
      }
    },
    {
      label: 'CSV',
      icon: 'pi pi-fw pi-file',
      styleClass: 'tableau-export-item csv',
      command: () => {
        this.handleExport('csv');
      }
    },
    {
      label: 'Image',
      icon: 'pi pi-fw pi-file',
      styleClass: 'tableau-export-item image',
      command: () => {
        this.handleExport('image');
      }
    },
    {
      label: 'Powerpoint',
      icon: 'pi pi-fw pi-file',
      styleClass: 'tableau-export-item powerpoint',
      command: () => {
        this.handleExport('powerpoint');
      }
    }
  ];

  public clientId = 0;
  public brandId = 0;
  public user!: UserModel;
  public brandRows: BrandRow[] = [];

  public notificationCenterVisible = false;
  public actionAlertsCount = 0;
  public unreadInfoAlertsCount = 0;
  notificationViewMode = NotificationViewMode;

  private subs: Subscription = new Subscription();
  private readonly configDashboards: Record<string, ConfigDashboardInstance> =
    {};

  constructor(
    public tableauService: TableauService,
    public viewStore: ViewStore,
    public dashboardService: DashboardService,
    public configDashboardService: ConfigDashboardService,
    private readonly authService: AuthService,
    public brandRowsService: BrandRowsService,
    private readonly selectionService: SelectionService,
    private readonly router: Router,
    private readonly ref: ChangeDetectorRef,
    private readonly navigationService: NavigationService,
    private readonly notificationCenterService: NotificationCenterService
  ) {}

  static isTableauUriPath(href: string): boolean {
    return String(parseInt(getNextToLastUriSegment(href), 10)) !== 'NaN';
  }

  ngOnInit(): void {
    this.showExport = NavbarComponent.isTableauUriPath(location.href);
    // Emit the initial value of the toggle. Doesn't work on the constructor
    this.setBrandLabel(this.selectionService.selectionLabel);
    const brand = this.selectionService.getSelection().brand;
    if (brand) {
      this.clientId = brand.clientId;
      this.brandId = brand.brandId;
    }

    this.setBrandProductPath();
    this.subs.add(
      this.selectionService.selectionChanged.subscribe(() => {
        const selectionLabel = this.selectionService.selectionLabel;
        if (selectionLabel != null) {
          this.setBrandLabel(selectionLabel);
          this.setBrandProductPath(selectionLabel);
        }
        this.ref.detectChanges();
      })
    );
    this.subs.add(
      this.viewStore.title.subscribe({
        next: (value) => (this.dashboardTitle = value)
      })
    );
    this.subs.add(
      this.router.events.subscribe((event: RouterEvents) => {
        switch (true) {
          case event instanceof NavigationStart: {
            break;
          }
          case event instanceof NavigationEnd:
          case event instanceof NavigationCancel:
          case event instanceof NavigationError: {
            this.showExport = NavbarComponent.isTableauUriPath(
              `https://portal.measured.com/${
                (event as NavigationEnd | NavigationCancel | NavigationError)
                  .url
              }`
            );
            break;
          }
          default: {
            break;
          }
        }
      })
    );
    this.subs.add(
      this.notificationCenterService.actionAlertsCount$.subscribe({
        next: (value) => {
          this.actionAlertsCount = value;
        }
      })
    );
    this.subs.add(
      this.notificationCenterService.unreadInfoAlertsCount$.subscribe({
        next: (value) => {
          this.unreadInfoAlertsCount = value;
        }
      })
    );
    this.user = this.authService.getUser() as UserModel;
  }

  ngAfterContentInit(): void {
    this.subs.add(
      this.brandRowsService
        .buildBrandRows()
        .pipe(
          take(1),
          switchMap((brandRows) => {
            this.brandRows = brandRows;

            return this.dashboardService.dashboards.pipe(
              filter((dashboards) => dashboards.length > 0),
              take(1),
              tap((dashboards: DisplayDashboard[]) => {
                this.dashboards = dashboards;
                this.landingDashboardLink = landingDashboardURI(
                  this.brandProductPath,
                  this.dashboards,
                  this.user
                );
              }),
              switchMap(() =>
                this.configDashboardService.getConfigDashboards().pipe(take(1))
              )
            );
          })
        )
        .subscribe({
          next: (configDashboards: ConfigDashboardInstance[]) => {
            configDashboards.forEach(
              (dashboardInstance) =>
                (this.configDashboards[dashboardInstance.literalId] =
                  dashboardInstance)
            );
          },
          error: (error) => {
            console.error(
              'Error while fetching the config dashboard instance',
              error
            );
          }
        })
        .add(() => {
          if (this.dashboards.length) {
            this.handleDashboardsChanged(this.dashboards);
          }
        })
    );
  }

  public handleDashboardsChanged(
    dashboards: DisplayDashboard[] = this.dashboards
  ): void {
    this.username = this.user.getUsername();
    this.userOptionsMenuItems =
      this.navigationService.createUserOptionsMenuItems(
        this.configDashboards,
        this.showBrandsDialog.bind(this),
        this.brandRows,
        this.updateLogOutModalIsVisible.bind(this)
      );
    this.settingsMenuItems = this.navigationService.createSettingsMenuItems(
      this.configDashboards,
      this.brandProductPath
    );

    this.supportMenuItems = [
      {
        label: 'Help Center',
        url: 'https://support.measured.com/'
      },
      {
        label: 'Customer Support',
        url: 'https://measured.atlassian.net/servicedesk/customer/portal/2'
      }
    ];

    // Append MIM to settings menu items as per PMDEV-2796
    this.appendMimMenuItem(dashboards, this.settingsMenuItems);
    this.onClickBenchmark = this.navigationService.createOnClickBenchmark(
      dashboards,
      this.brandProductPath
    );
    this.onClickOptimize = this.navigationService.createOnClickOptimize(
      dashboards,
      this.brandProductPath
    );
    this.experimentMenuItems = this.navigationService.createExperimentMenuItems(
      dashboards,
      this.configDashboards,
      this.brandProductPath
    );

    this.analyzeMenuItems = this.navigationService.createAnalyzeMenuItems(
      dashboards,
      this.brandProductPath
    );
    this.optimizeMenuItems = this.navigationService.createOptimizeMenuItems(
      dashboards,
      this.brandProductPath
    );
  }

  public onClickHome() {
    this.router.navigateByUrl(this.landingDashboardLink).catch(console.error);
  }

  public setBrandProductPath(
    selectionLabel: string = this.selectionService.selectionLabel
  ): void {
    this.brandProductPath =
      selectionLabel === ''
        ? 'products'
        : `${this.selectionService.buildSelectionSlug()}/products`;
  }

  public setBrandLabel(label: string): void {
    if (label !== '') {
      this.brandLabel = label;
    } else {
      this.brandLabel = '';
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onClickNotificationCenter(e: Event): void {
    e.stopPropagation();
    this.notificationCenterVisible = !this.notificationCenterVisible;
  }

  public showBrandsDialog(): void {
    this.brandRowsService.changeShouldFetchBrandRows(true);
    this.brandsDialogVisible = true;
  }

  public hideBrandsDialog(): void {
    this.brandsDialogVisible = false;
  }

  public handleExport(type: string): void {
    this.tableauService
      .changeExport(type)
      .pipe(take(1))
      .subscribe({ error: (err) => console.error(err) });
  }

  public updateLogOutModalIsVisible(updatedValue: boolean): void {
    this.logOutModalIsVisible = updatedValue;
  }

  public closeBrandDialogModal(): void {
    this.brandsDialogVisible = false;
  }

  /**
   * Appends a new menu item for MIM to the settings menu items if it exists.
   *
   * @param dashboards - The array of dashboard configurations to search through.
   * @param settingsMenuItems - The existing array of settings menu items to append to.
   */
  private appendMimMenuItem(
    dashboards: DisplayDashboard[],
    settingsMenuItems: MenuItem[]
  ): void {
    // Get MIM dashboard
    const mim = dashboards.find(
      (d) =>
        d.literalId === NavbarConstants.ddiReportingRevenue ||
        d.literalId === NavbarConstants.ddiReportingConversion
    );
    // Ensure mim exists before attempting to append
    if (mim) {
      this.settingsMenuItems = [
        {
          id: mim.literalId,
          label: mim.label,
          title: mim.label,
          url: `/a/${this.brandProductPath}/${mim.routerLinkPart}`
        },
        ...settingsMenuItems
      ];
    }
  }
}
