import { Component, OnDestroy, OnInit } from '@angular/core';
import { PortalEventBusService } from '@portal/app/shared/services/portal-event-bus.service';
import { MultiViewStore } from '@portal/app/shared/state/multi-view.store';
import {
  ViewResponse,
  ElementGroup,
  FieldDefinitions,
  PortalEventCategory
} from '@portal/app/shared/types';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  BehaviorSubject,
  combineLatestWith,
  map,
  Observable,
  Subscription
} from 'rxjs';

@Component({
  selector: 'portal-view-selector',
  templateUrl: './view-selector.component.html',
  styleUrls: ['./view-selector.component.scss']
})
export class ViewSelectorComponent implements OnInit, OnDestroy {
  public item: ElementGroup | undefined;
  public fieldDefinitions: FieldDefinitions = {};
  private searchSubject = new BehaviorSubject<string>('');
  private search$ = this.searchSubject.asObservable();
  private views$: Observable<ViewResponse[]>;
  public filteredViews$: Observable<ViewResponse[]>;
  public isLoadingViewList$: Observable<boolean>;
  public loadingViewsIds$: Observable<object>;
  private readonly subs: Subscription = new Subscription();

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private multiViewStore: MultiViewStore,
    private portalEventBusService: PortalEventBusService
  ) {
    this.multiViewStore.fetchViewList();
    this.views$ = this.multiViewStore.viewsList$;
    this.isLoadingViewList$ = this.multiViewStore.isLoadingViewList$;
    this.loadingViewsIds$ = this.multiViewStore.loadingViewsIds$;
    this.filteredViews$ = this.views$.pipe(
      combineLatestWith(this.search$),
      map(([views, searchText]) => {
        return views.filter((view) => {
          const nameInLowercase = view.name.toLowerCase();
          const searchInLowecase = searchText.toLowerCase();
          return nameInLowercase.includes(searchInLowecase);
        });
      })
    );
  }

  ngOnInit() {
    this.item = this.config.data?.element;
    this.fieldDefinitions = this.config.data?.fieldDefinitions;
    const sub = this.portalEventBusService
      .on(PortalEventCategory.multiViewEvent)
      .subscribe((e) => {
        if (
          e.action === 'VIEW_SEARCH' &&
          typeof e.payload?.value === 'string'
        ) {
          this.searchSubject.next(e.payload?.value as string);
        }
      });
    this.subs.add(sub);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
