import { formatDate } from '@angular/common';
import {
  ChartDataTypes,
  ChartXAxisType,
  CustomTooltipFormatterContextObject,
  IChartOptions,
  IChartSeriesOption,
  PlotlineValues,
  ResolutionType
} from '@portal/app/charts/shared/chart-model';
import { CHART_COLORS } from '@portal/app/charts/shared/chart-constants';
import { find, forEach, isArray } from 'lodash-es';
import {
  YAxisOptions,
  DashStyleValue,
  YAxisPlotLinesOptions,
  YAxisTitleOptions,
  YAxisLabelsOptions,
  TooltipOptions,
  SeriesLineOptions,
  Dictionary,
  PointOptionsObject
} from 'highcharts';
import {
  DataResponse,
  FieldDefinition,
  FieldDefinitions,
  Filter
} from '@portal/app/shared/types';
import { FormatterService } from '@portal/app/shared/services/formatter.service';
import { PreviousPeriodService } from '@portal/app/shared/services/previous-period.service';
import { isMeasuredBenchmark } from '@portal/app/dashboard/utils';
import { colors } from '@design-system/styles/colors';

export const CHART_AXIS_FORMAT_DATEMONTHYEAR = `MMM DD, 'YY`;
export const CHART_AXIS_FORMAT_MONTHYEAR = `MMM 'YY`;

export const prepareChartTooltip: (
  selectedResolution: ResolutionType,
  fieldDefinitions: FieldDefinitions,
  defaultTooltip: TooltipOptions | undefined,
  formatterService: FormatterService,
  productId?: string,
  compareEnabled?: boolean
) => TooltipOptions = (
  selectedResolution: ResolutionType,
  fieldDefinitions: FieldDefinitions,
  defaultTooltip: TooltipOptions | undefined,
  formatterService: FormatterService,
  productId?: string,
  compareEnabled?: boolean
) => ({
  ...defaultTooltip,
  formatter() {
    const points = this.points || [];
    return chartToolTipFormatter(
      points,
      selectedResolution,
      fieldDefinitions,
      formatterService,
      productId,
      compareEnabled
    );
  }
});

export const chartToolTipFormatter: (
  points: CustomTooltipFormatterContextObject[],
  type: ResolutionType,
  fieldDefinitions: FieldDefinitions,
  formatterService: FormatterService,
  productId?: string,
  compareEnabled?: boolean
) => string = (
  points: CustomTooltipFormatterContextObject[],
  type: ResolutionType,
  fieldDefinitions: FieldDefinitions,
  formatterService: FormatterService,
  productId?: string,
  compareEnabled?: boolean
) => {
  {
    if (productId && isMeasuredBenchmark(productId)) {
      // TODO: Use the tooltip directive to remove the html from the .ts file
      return getBenchmarksTooltipStyling(
        points,
        'DateMonthYear',
        fieldDefinitions,
        formatterService
      );
    }

    if (compareEnabled) {
      return getCompareModeTooltipStyling(
        points,
        'DateMonthYear',
        fieldDefinitions,
        formatterService
      );
    }
    let str = `<div class="tooltip-body">`;
    str += `<span class="b3 text-gray-500 text-center block">${formatDateForChart(
      points[0]?.x as number,
      type
    )}</span>`;
    str += `<div class="m-divider dark my-2"></div>`;
    points.forEach((point) => {
      const seriesLabel = point.series.name;
      const options = point.series.options;
      const { propName } = options.custom as Dictionary<string>;
      if (propName) {
        const fieldDef = getFieldByLiteralId(propName, fieldDefinitions);
        if (fieldDef) {
          const pointValue = formatterService.format(
            fieldDef,
            point.y as number
          );
          str += `<div class="flex justify-between items-center">`;
          str += `<div><span style="color:${point?.color}; font-size: 0.88rem; font-family: Inter;">\u25CF</span> <span class="b1 text-gray-000 ml-1">${seriesLabel}&nbsp;&nbsp;</span>:</div>`;
          str += `<span class="b1 text-gray-000">${pointValue}</span>`;
          str += `</div>`;
        }
      }
    });
    str += `</div>`;
    return str;
  }
};

const getBenchmarksTooltipStyling = (
  points: CustomTooltipFormatterContextObject[],
  type: ResolutionType,
  fieldDefinitions: FieldDefinitions,
  formatterService: FormatterService
): string => {
  // TODO: Use the tooltip directive to remove the html from the .ts file
  let str = `<div class="tooltip-body">`;
  str += `<span class="b3 text-gray-500 text-center block">${formatDateForChart(
    points[0]?.x as number,
    type
  )}</span>`;
  str += `<div class="m-divider dark my-2"></div>`;
  points.forEach((point) => {
    const seriesLabel = point.series.name;
    const options = point.series.options;
    const { propName } = options.custom as Dictionary<string>;
    if (propName) {
      const fieldDef = getFieldByLiteralId(propName, fieldDefinitions);
      if (fieldDef) {
        const pointValue = formatterService.format(fieldDef, point.y as number);

        str += `<div class="flex justify-between items-center">`;
        str += `<div><span style="color:${point?.color}; font-size: 0.88rem; font-family: Inter;">\u25CF</span> <span class="b1 text-gray-000 ml-1">${seriesLabel}&nbsp;</span>:</div>`;
        str += `<span class="b1 text-gray-000">${pointValue}</span>`;
        str += `</div>`;
      }
    }
  });

  str += '</div>';
  return str;
};

const getCompareModeTooltipStyling = (
  points: CustomTooltipFormatterContextObject[],
  type: ResolutionType,
  fieldDefinitions: FieldDefinitions,
  formatterService: FormatterService
): string => {
  let str = `<div class="tooltip-body">`;
  str += `<span class="b2 text-gray-500 text-center block">Comparison</span>`;
  str += `<div class="m-divider dark my-2"></div>`;

  const primaryDate = points[0]?.x;
  const previousDate = points[0]?.point?.previousDate;

  points.forEach((point, index) => {
    const seriesLabel = point.series.name?.split(' ').slice(2).join(' ');
    const propName = (point.series.options?.custom as Dictionary<string>)
      ?.propName;
    const fieldDef = propName
      ? getFieldByLiteralId(propName, fieldDefinitions)
      : null;

    if (!fieldDef) return;

    const pointValue = formatterService.format(fieldDef, point.y as number);
    str += `<div class="flex justify-between items-center">`;
    str += `<div><span style="color:${point?.color}; font-size: 0.88rem; font-family: Inter;">\u25CF</span> <span class="b1 text-gray-000 ml-1">${seriesLabel}&nbsp;&nbsp;</span>:</div>`;
    str += `<span class="b1 ${point.point?.previousDate ? 'text-gray-000' : 'text-gray-500'}">${pointValue}</span>`;
    str += `</div>`;
    const dateToFormat = index === 0 ? primaryDate : previousDate;
    const DARK_GRAY = '#617087'; // the point's color looks too close to dark tooltip bg
    str += `<span style="color:${point?.color === DARK_GRAY ? '' : point?.color};" class="c1 ml-4 ${point?.color === DARK_GRAY ? 'text-gray-500' : ''}">${formatDateForChart(
      dateToFormat as number,
      type
    )}</span>`;
  });

  str += '</div>';
  return str;
};

export const formatDateForChart: (
  val: number,
  type: ResolutionType
) => string = (val: number, type: ResolutionType): string => {
  if (type === 'Month') {
    return formatDate(val, `MMMM yyyy`, 'en-US', 'UTC');
  } else if (type === 'Year') {
    return formatDate(val, `yyyy`, 'en-US', 'UTC');
  } else if (type === 'AbbrMonthYear') {
    return formatDate(val, `MMM ''yy`, 'en-US', 'UTC'); // Escape single quote for 'YY
  } else if (type === 'DateMonthYear') {
    return formatDate(val, `MMMM dd, yyyy`, 'en-US', 'UTC');
  }
  return formatDate(val, `MMMM dd, yyyy`, 'en-US', 'UTC');
};

export const prepareChartSeries = (props: {
  chartOptions: IChartOptions;
  isMultipleAxesActive: boolean;
  fieldDefinitions: FieldDefinitions;
  plotlineValues: PlotlineValues;
  formatterServiceInstance: FormatterService;
}): SeriesLineOptions[] => {
  const series: SeriesLineOptions[] = [];
  const chartYAxisConfigs = prepareChartYAxisConfig({
    chartOptions: props.chartOptions,
    isMultipleAxesActive: props.isMultipleAxesActive,
    fieldDefinitions: props.fieldDefinitions,
    plotlineValues: props.plotlineValues,
    formatterServiceInstance: props.formatterServiceInstance
  });
  const {
    xAxisPropertyField,
    yAxisPropertyFields,
    data: chartData,
    axisSeriesOption
  } = props.chartOptions;

  const [firstItem] = chartData;
  if (firstItem && firstItem.name !== undefined) {
    chartData.forEach((item, index) => {
      if (item) {
        const seriesConfig: SeriesLineOptions = {
          name: String(item.name),
          type: 'line',
          data: (item.data as DataResponse[]).map((value) => ({
            x: (value as DataResponse)[xAxisPropertyField] as number,
            y: (value as DataResponse)[item.propName as string] as number
          })),
          custom: {
            propName: item.propName,
            type: ChartDataTypes.dataChart
          },
          marker: {
            states: {
              hover: {
                lineColor: CHART_COLORS[index]
              }
            }
          },
          yAxis: '0'
        };
        series.push(seriesConfig);
      }
    });
  } else {
    const prevPeriodField = yAxisPropertyFields.find((field) =>
      field.startsWith(PreviousPeriodService.fieldPrefix)
    );

    yAxisPropertyFields.forEach((propertyField, index) => {
      const option = axisSeriesOption.find((opt) =>
        opt.fields.includes(propertyField)
      ) as IChartSeriesOption;
      let name = '';
      if (option && option.fields.length > 1) {
        name =
          props.fieldDefinitions[propertyField] != null
            ? (props.fieldDefinitions[propertyField] as FieldDefinition).label
            : propertyField;
      } else {
        name = option != null ? option.label : propertyField;
      }
      const isPrevPeriodField = name.startsWith(
        PreviousPeriodService.fieldPrefix
      );

      if (prevPeriodField) {
        if (isPrevPeriodField) {
          const fieldName = (
            props.fieldDefinitions[propertyField] as FieldDefinition
          ).label;
          name = `${PreviousPeriodService.labelPreviousPrefix} ${fieldName}`;
        } else {
          name = `${PreviousPeriodService.labelCurrentPrefix} ${name}`;
        }
      }
      // Define the color based on the isPrevPeriodField flag
      const lineColor = isPrevPeriodField
        ? colors['gray-800']
        : CHART_COLORS[index];

      const seriesConfig: SeriesLineOptions = {
        name,
        type: 'line',
        dashStyle: isPrevPeriodField ? 'ShortDash' : 'Solid',

        // Mapping chartData with destructuring to avoid repetitive casting
        data: chartData.map((value) => {
          const dataResponse = value as DataResponse;

          return {
            x: dataResponse[xAxisPropertyField] as number,
            y: dataResponse[propertyField] as number,
            previousDate: dataResponse[
              `${PreviousPeriodService.fieldDatePrefix}${option?.fields}`
            ] as number
          };
        }),
        custom: {
          propName: propertyField,
          type: ChartDataTypes.regular
        },
        color: lineColor,
        marker: {
          states: {
            hover: {
              lineColor: lineColor
            }
          }
        }
      };

      if (
        seriesConfig &&
        hasInvalidNonEmptyDataPointsObject(seriesConfig.data)
      ) {
        seriesConfig['showInLegend'] = false;
      } else {
        seriesConfig['showInLegend'] = true;
      }

      if (
        chartYAxisConfigs &&
        isArray(chartYAxisConfigs) &&
        chartYAxisConfigs.length > 1
      ) {
        seriesConfig.yAxis = String(index);
      } else {
        seriesConfig.yAxis = '0';
      }
      series.push(seriesConfig);
    });
  }
  return series;
};

/**
 * hasInvalidNonEmptyDataPointsObject
 * In MIM Explorer charts, series like {name:Index-Medium, data:[{x:1,y:undefined}, {x:2, y: undefined}] }, still shows the series in the legend despite not showing in graph.
 * @param data Highcharts data
 * @returns True if there's a non zero array of PointOptionsObject, has invalid points (x or y === undefined)
 */
const hasInvalidNonEmptyDataPointsObject = (
  data:
    | (number | [string | number, number | null] | PointOptionsObject | null)[]
    | undefined
): boolean => {
  // empty data
  if (!data || data?.length === 0) {
    return false;
  }
  const firstElement = data[0];

  if (
    firstElement !== null &&
    typeof firstElement === 'object' &&
    'x' in firstElement &&
    'y' in firstElement &&
    (firstElement.x === undefined || firstElement.y === undefined)
  ) {
    return true;
  }

  return false;
};

export const prepareChartYAxisConfig = (props: {
  chartOptions: IChartOptions;
  isMultipleAxesActive: boolean;
  fieldDefinitions: FieldDefinitions;
  plotlineValues: PlotlineValues;
  formatterServiceInstance: FormatterService;
}): YAxisOptions | YAxisOptions[] => {
  const propertyFields = props.chartOptions.yAxisPropertyFields;
  let types: string[] = [];
  propertyFields.forEach((field) => {
    types.push(props.fieldDefinitions[field]?.format || '');
  });
  types = Array.from(new Set(types));
  if (
    props.isMultipleAxesActive === true ||
    (propertyFields.length > 1 && types.length > 1)
  ) {
    const config: YAxisOptions | YAxisOptions[] = [];
    propertyFields.forEach((propertyField, index) => {
      if (index > 0 && index === propertyFields.length - 1) {
        config.push(
          getYAxisOptions(
            getAxisLabel(propertyField, props.chartOptions.axisSeriesOption),
            index,
            String(index),
            true,
            {},
            props.fieldDefinitions[propertyField] as FieldDefinition,
            props.formatterServiceInstance
          )
        );
      } else {
        config.push(
          getYAxisOptions(
            getAxisLabel(propertyField, props.chartOptions.axisSeriesOption),
            index,
            String(index),
            false,
            props.plotlineValues,
            props.fieldDefinitions[propertyField] as FieldDefinition,
            props.formatterServiceInstance
          )
        );
      }
    });
    return config;
  }

  let titleText = getAxisLabel(
    propertyFields[0] as string,
    props.chartOptions.axisSeriesOption
  );
  forEach(propertyFields, (value, key) => {
    if (key !== 0) {
      const label = getAxisLabel(value, props.chartOptions.axisSeriesOption);
      titleText += label ? ` - ${label}` : '';
    }
  });
  return getYAxisOptions(
    titleText,
    0,
    '0',
    false,
    props.plotlineValues,
    props.fieldDefinitions[propertyFields[0] as string] as FieldDefinition,
    props.formatterServiceInstance
  );
};

const getYAxisOptions = (
  title: string,
  colorIndex: number,
  id: string,
  opposite = false,
  plotLineValues: PlotlineValues,
  fieldDefinition: FieldDefinition,
  formatterServiceInstance: FormatterService
): YAxisOptions => {
  const shortDash: DashStyleValue = 'ShortDash';
  const plotLines: YAxisPlotLinesOptions[] = [];
  let plotLineTitleText = '';
  for (let i = 0; i < Object.keys(plotLineValues).length; i++) {
    const value = plotLineValues[i]?.value;
    // if plotLine value is 0, do not show the plotLine
    if (value) {
      // if plotLineTitle already has text in it, add a '-' before adding another label to it
      plotLineTitleText = plotLineTitleText.length
        ? plotLineTitleText.concat(' - ' + plotLineValues[i]?.label)
        : plotLineTitleText.concat(plotLineValues[i]?.label || '');
      plotLines.push({
        dashStyle: shortDash,
        color: '#000000',
        value: plotLineValues[i]?.value,
        width: 2,
        label: {
          text: plotLineValues[i]?.label,
          useHTML: true
        }
      });
    }
  }
  const yAxisTitle: YAxisTitleOptions = {
    text: plotLines.length ? plotLineTitleText : title,
    useHTML: true,
    style: plotLines.length
      ? { color: '#302B28' }
      : { color: CHART_COLORS[colorIndex] }
  };
  const labels: YAxisLabelsOptions = {
    useHTML: true,
    style: plotLines.length
      ? { color: '#302B28' }
      : { color: CHART_COLORS[colorIndex] },
    formatter: function () {
      const convertedValue = formatterServiceInstance.convertPartialItem(
        fieldDefinition,
        this.value
      );
      const formattedValue = formatterServiceInstance.formatPartialValue(
        fieldDefinition,
        convertedValue
      );
      return formattedValue || convertedValue;
    } as Highcharts.AxisLabelsFormatterCallbackFunction
  };
  return {
    title: yAxisTitle,
    plotLines,
    opposite,
    labels,
    id
  };
};

export const getAxisLabel = (
  axisPropertyField: string,
  seriesOption: IChartSeriesOption[]
): string => {
  const foundSeriesOption = find(
    seriesOption,
    (opt) => opt.fields.length === 1 && opt.fields.includes(axisPropertyField)
  ) as IChartSeriesOption;
  const label = foundSeriesOption?.label;
  return label != null ? label : '';
};

export const getMetricFieldByLiteralId: (
  literalId: string,
  fieldDefs: FieldDefinitions
) => string = (literalId: string, fieldDefs: FieldDefinitions): string => {
  for (const field of Object.keys(fieldDefs)) {
    if (fieldDefs[field]?.literalId === literalId) {
      return field;
    }
  }
  return '';
};

export const getFieldByLiteralId = (
  literalId: string,
  fieldDefs: FieldDefinitions
): FieldDefinition | undefined => {
  for (const field of Object.keys(fieldDefs)) {
    if (fieldDefs[field]?.literalId === literalId) {
      return fieldDefs[field];
    }
  }
};

export const xAxisType = (fieldDefinition: FieldDefinition): ChartXAxisType => {
  const format = fieldDefinition?.format || '';
  if (format.includes('yy')) {
    return 'datetime';
  }
  return 'linear';
};

export const getResolutionsOptionsFromFilters = (
  filters: Filter[] = []
): string[] => {
  const resolutions = filters.find((f) => f.literalId === 'resolution');
  return resolutions && resolutions.options ? resolutions.options : [];
};

export const getSelectedResolutionFromFilters = (
  filters: Filter[] = []
): string => {
  const resolutions = filters.find((f) => f.literalId === 'resolution');
  const resolutionOptions =
    resolutions && resolutions.options ? resolutions.options : [];
  return resolutions && resolutions.value
    ? (resolutions.value as string)
    : (resolutionOptions[0] as string);
};

export const getDefaultPlotlineControlValue = (
  filters: Filter[] = [],
  literalId: string
): number => {
  const plotlineFilter = filters.find((f) => f.literalId === literalId);
  return (plotlineFilter?.value as number) || 0;
};
