<div [ngClass]="[parentClass, 'button-group-container']">
  @if (decoratedOptions.length) {
    <span class="p-buttonset">
      @for (option of decoratedOptions; track option; let i = $index) {
        <button
          type="button"
          pButton
          [ngClass]="[
            'button-group-button',
            option.value === selectedOption ? 'selected-button' : '',
          ]"
          (click)="$event.preventDefault(); onButtonClickHandler(i)"
          label="{{ option.label }}"></button>
      }
    </span>
  }
  @if (!decoratedOptions.length) {
    <button type="button" class="button disabled" [disabled]="true">
      No Data
    </button>
  }
</div>
