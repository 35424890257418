/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil
} from 'rxjs/operators';
import { isEqual } from 'lodash-es';

// Services
import { ChannelsService } from '@portal/app/dashboard/home-page/services/channels.service';
import { FilterService } from '@portal/app/dashboard/home-page/services/filter.service';
import { MetricsService } from '@portal/app/dashboard/home-page/services/metrics.service';

// Types
import type { Filter } from '@portal/app/shared/types';
import type { Metric } from '@portal/app/dashboard/home-page/services/metrics.service';

@Injectable({
  providedIn: 'root'
})
export class PortfolioService implements OnDestroy {
  // Cleanup
  private destroy$ = new Subject<void>();
  // metrics
  private metricsSubject = new BehaviorSubject<Metric[]>([]);
  public metrics$ = this.metricsSubject.asObservable();
  // loading state
  private isLoadingSubject = new BehaviorSubject<boolean>(true);
  public isLoading$ = this.isLoadingSubject.asObservable();

  constructor(
    private channelsService: ChannelsService,
    private filterService: FilterService,
    private metricsService: MetricsService
  ) {
    this.subscribeToChanges();
  }

  private subscribeToChanges() {
    combineLatest([
      this.filterService.filters$,
      this.channelsService.selectedChannels$,
      this.metricsService.selectedMetrics$
    ])
      .pipe(
        map(([filters, selectedChannels, selectedMetrics]) => ({
          filters,
          selectedChannels,
          selectedMetrics
        })),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        debounceTime(300),
        takeUntil(this.destroy$)
      )
      .subscribe(({ filters, selectedChannels, selectedMetrics }) => {
        this.fetchData(filters, selectedMetrics, selectedChannels);
      });
  }

  fetchData(filters: Filter[], metrics: string[], channels: string[]) {
    this.isLoadingSubject.next(true);

    this.metricsService.fetchData(filters, metrics, channels).subscribe({
      next: (data) => {
        this.metricsSubject.next(data);
        this.isLoadingSubject.next(false);
      },
      error: (error) => {
        // Handle error
        console.error('PortfolioService fetchData error:', error);
        this.isLoadingSubject.next(false);
      }
    });
  }

  // Handles cleanup when the service is destroyed
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
