import { Status } from './Status';

export enum UserRole {
  superUser = 'SUPERUSER',
  clientAdmin = 'CLIENT_ADMIN',
  clientUser = 'CLIENT_USER',
  measuredDev = 'MEASURED_DEV',
  measuredSol = 'MEASURED_SOL'
}

export enum UserType {
  internal = 'internal',
  external = 'external'
}

export interface User {
  readonly role: UserRole;
  clientIds: readonly string[];
  brandIds: readonly string[];
  readonly userType: UserType;
  readonly userName: string;
  readonly email: string;
  readonly authTime: number;
  readonly expiryTime: number;
  readonly roles?: IUserAccess[];
}

export interface IUserAccess {
  id: number;
  userName: string;
  role: string;
  brandId: number;
  clientId: number;
  status: Status;
  rCreId?: string;
  rCreTime?: Date;
  rModId?: string;
  rModTime?: Date;
}

export interface IEnrichedUser {
  userName: string;
  firstName: string;
  lastName: string;
  email: string;
  userType?: string;
  clientIds?: string[];
  brandIds?: string[];
  awsLastnameBackup?: string;
  status?: string;
  access: IAccess[];
  userGroups?: any[];
}

export interface IAccess {
  userName: string;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  userType?: string;
  clientIds?: string[];
  brandIds?: string[];
  awsLastnameBackup?: string;
  status: string;
  rCreId: string;
  rCreTime: string;
  rModId?: string;
  rModTime: string;
  id: number;
  role: string;
  brandId?: number;
  clientId?: number;
  clientName?: string;
  brandName?: string;
}
