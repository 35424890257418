import { computed, inject, Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  ChannelLevelSummaryMetricResponseDTO,
  SummaryMetricRequestDTO
} from '@portal/app/dashboard/context-modal/context-model.types';
import { catchError, map, Observable, of, switchMap, tap } from 'rxjs';
import { ModalService } from '@portal/app/dashboard/context-modal/services/modal.service';
import { isEmpty } from 'lodash-es';

@Injectable()
export class SummaryMetricService {
  private readonly http: HttpClient = inject(HttpClient);
  private readonly modalService: ModalService = inject(ModalService);

  private isLoadingSignal = signal<boolean>(false);

  public data = toSignal(this.fetchData$(), {
    initialValue: {} as ChannelLevelSummaryMetricResponseDTO
  });

  public topMetric = computed(() => this.data()?.topMetric);
  public drivers = computed(() => this.data()?.drivers);
  isDataAvailable = computed(
    () => !isEmpty(this.topMetric()) && !isEmpty(this.drivers())
  );

  get isLoading() {
    return this.isLoadingSignal;
  }

  getParams() {
    const modalParams = this.modalService.modalParams();
    return {
      ...this.modalService.getParams(),
      dimension: modalParams.tactic ? 'tactic' : 'channel',
      filter: {
        ...this.modalService.getDateParams(),
        channel: modalParams.channel,
        tactic: modalParams.tactic ? modalParams.tactic : undefined,
        conversion_type: this.modalService.conversionType()
      }
    } as SummaryMetricRequestDTO;
  }

  fetchData$(): Observable<ChannelLevelSummaryMetricResponseDTO> {
    const url = `${ModalService.baseUriV1}/summary-metric`;

    return this.modalService.modalParams$.pipe(
      tap(() => {
        this.isLoadingSignal.set(true);
      }),
      switchMap(() =>
        this.http
          .post<ChannelLevelSummaryMetricResponseDTO>(url, this.getParams())
          .pipe(
            catchError((error) => {
              console.error('Error fetching summary-metric data', error);
              this.isLoadingSignal.set(false);
              return of({} as ChannelLevelSummaryMetricResponseDTO);
            })
          )
      ),
      map((response) => {
        if (response?.topMetric?.formattedValue === '-') {
          response.topMetric.formattedValue = '';
          response.topMetric.value = 0;
          response.topMetric.percentChange = '';
        }
        return response;
      }),
      tap(() => this.isLoadingSignal.set(false))
    );
  }
}
