/**
 * Api documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreatePlanRequestDTO { 
    clientId: number;
    brandId: number;
    planName: string;
    conversionTypeId: number;
    viewType: CreatePlanRequestDTO.ViewTypeEnum;
}
export namespace CreatePlanRequestDTO {
    export type ViewTypeEnum = 'ROAS' | 'CPO';
    export const ViewTypeEnum = {
        Roas: 'ROAS' as ViewTypeEnum,
        Cpo: 'CPO' as ViewTypeEnum
    };
}


