<div
  [style]="{ height }"
  class="flex flex-col flex-wrap justify-center content-center items-center w-full">
  <img
    alt="{{ title }}"
    style="-webkit-user-drag: none; width: {{ width }}"
    class="mb-4"
    src="assets/images/creative/{{ creative }}" />
  <div class="dp2 text-gray-1000 mb-2">{{ title }}</div>
  <span class="b2 text-gray-600">{{ description }}</span>
</div>
