import { Component, Input, OnInit } from '@angular/core';
import { NativeSectionsService } from '@portal/app/shared/services/native-sections.service';
import {
  DataEndpoint,
  DataResponse,
  ElementGroup,
  FieldDefinitions,
  Filter,
  TitleElement
} from '@portal/app/shared/types';
import { ContextStore } from '@portal/app/shared/state/context.store';
import { ApiService } from '@portal/app/shared/services/api.service';
import { ViewStore } from '@portal/app/shared/state/view.store';
import { ViewService } from '@portal/app/shared/services/view.service';
import { Observable, Subscription, take } from 'rxjs';

@Component({
  selector: 'portal-experiment-result-detail-section',
  templateUrl: './experiment-result-detail-section.component.html',
  styleUrls: ['./experiment-result-detail-section.component.scss']
})
export class ExperimentResultDetailSectionComponent implements OnInit {
  @Input() item: ElementGroup | undefined = undefined;
  @Input() data?: DataResponse;
  @Input() fieldDefinitions: FieldDefinitions = {};
  @Input() view = '';

  public literalId = '';
  public elements: TitleElement[] = [];
  public subscriptions: Subscription = new Subscription();
  filterContext$: Observable<Filter[]> = this.contextStore.filterContext;

  constructor(
    private readonly contextStore: ContextStore,
    private readonly apiService: ApiService,
    public readonly nativeSectionsService: NativeSectionsService,
    private readonly viewStore: ViewStore,
    private readonly viewService: ViewService
  ) {}

  ngOnInit(): void {
    this.viewStore.selectedBCPDId.pipe(take(1)).subscribe((value) => {
      this.literalId = value.literalId;
    });
    if (this.item) {
      this.elements = this.item.elements.sort(
        this.nativeSectionsService.sortByDisplayOrder
      ) as TitleElement[];
    }
    if (this.data == null) {
      const sub = this.filterContext$.pipe(take(1)).subscribe({
        //test.reduce((cur, next) => {cur[next.name] = next.value; return cur },{})
        next: (filters) => {
          const dataPromise = this.apiService.getDashboardData({
            filters,
            type: DataEndpoint.dataPoints
          }) as Promise<DataResponse>;
          dataPromise
            .then((data) => {
              this.data = data;
            })
            .catch(() => {
              this.data = {};
            });
        }
      });
      this.subscriptions.add(sub);
    }
  }

  getDetails(ele: ElementGroup): string[] {
    const dataObj: DataResponse = this.data ?? {};
    const literalId: string = ele.literalId ?? '';
    const value: string = (dataObj[literalId] as string) ?? '';
    if (value) {
      return value.substring(1, value.length - 1).split(',');
    }
    return [];
  }
}
