import { HttpClient } from '@angular/common/http';
import { computed, inject, Injectable, signal } from '@angular/core';
import {
  TableColumn,
  TableFooter,
  TableValue
} from '@design-system/pages/optimization-report/m-table';
import { ContextStore } from '@portal/app/shared/state/context.store';
import { Filter, JustBrand } from '@portal/app/shared/types';
import { Options } from 'highcharts';
import { environment } from '@portal/environments/environment';
import { Params } from '@angular/router';
import {
  MIMContributingTacticsResponseDTO,
  MIMContributingTestData,
  MIMContributingTestResponseDTO,
  MIMMediaMixModelResponseDTO,
  MIMModelHistoryPerformanceResponseDTO
} from '@portal/app/shared/components/mmm-model-details/mmm-modal-details.types';
import {
  catchError,
  combineLatest,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap
} from 'rxjs';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { GeoDesignerConstants } from '@portal/app/geo-designer/common/geo-designer.constants';
import { GeoTestType } from '@libs/apis';
import { capitalize, isEmpty } from 'lodash-es';
import {
  DateTimeService,
  DayJsDateFormat
} from '@portal/app/shared/services/date-time.service';
import { baseChartConfig } from '@design-system/components/m-line-chart';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { SelectionService } from '@portal/app/shared/services/selection.service';
import { selectStore } from '@portal/app/store/app.selectors';
import { AppState } from '@portal/app/store/app.state';
import { Store } from '@ngrx/store';

const singleLineChart = { ...baseChartConfig };

export interface MediaResponse {
  label: string;
  value: string;
}

export interface TableData {
  columns: TableColumn[];
  values: TableValue[];
  footer?: TableFooter[];
}

export interface ContributingResponse {
  response: MIMContributingTestResponseDTO | MIMContributingTacticsResponseDTO;
  table: TableData;
}

export interface HistoryPerformanceResponse {
  response: MIMModelHistoryPerformanceResponseDTO;
  chartOptions: Options;
}

export enum ContributingType {
  TESTS = 'tests',
  TACTICS = 'tactics'
}

@Injectable({
  providedIn: 'root'
})
export class MmmModalDetailsService {
  private destroy$ = new Subject<void>();
  private readonly http: HttpClient = inject(HttpClient);
  private contextStore = inject(ContextStore);
  private dateTimeService = inject(DateTimeService);
  private selectionService = inject(SelectionService);

  public baseUriV1 = `${environment.apiDomain}/api/v1/bff/mim`;

  public showModalSignal = signal(false);
  private showModal$ = toObservable(this.showModalSignal);

  public selectionSignal = signal<JustBrand>({} as JustBrand);
  public selection$ = toObservable(this.selectionSignal);

  public conversionTypeSignal = signal<string>('');
  private conversionType$ = toObservable(this.conversionTypeSignal);
  public conversionTypeOptionsSignal = signal<string[]>([]);
  private rollupConversionTypeOptionsSignal = signal<string[]>([]);

  public resolutionSignal = signal<string>('week');
  private resolution$ = toObservable(this.resolutionSignal);
  public resolutionOptionsSignal = signal<{ value: string; label: string }[]>([
    { value: 'day', label: 'Daily' },
    { value: 'week', label: 'Weekly' },
    { value: 'month', label: 'Monthly' }
  ]);

  public periodSignal = signal<string>('Last 12 Months');
  private period$ = toObservable(this.periodSignal);
  public periodOptionsSignal = signal<string[]>(['Last 12 Months', 'All Time']);

  public isMediumsLoadingSignal = signal<boolean>(false);
  public mediumsSignal = toSignal(this.fetchMediumsData$(), {
    initialValue: [] as MediaResponse[]
  });

  public isTestsLoadingSignal = signal<boolean>(false);
  public testsSignal = toSignal(this.fetchTestsData$(), {
    initialValue: {
      response: {} as
        | MIMContributingTestResponseDTO
        | MIMContributingTacticsResponseDTO,
      table: {} as TableData
    } as ContributingResponse
  });

  public isTestsDataAvailableSignal = computed(
    () => this.testsSignal().response?.data?.length > 0
  );

  public isTacticsLoadingSignal = signal<boolean>(false);
  public tacticsSignal = toSignal(this.fetchTacticsData$(), {
    initialValue: {
      response: {} as MIMContributingTacticsResponseDTO,
      table: {} as TableData
    } as ContributingResponse
  });

  public isTacticsDataAvailableSignal = computed(
    () => this.tacticsSignal().response?.data?.length > 0
  );

  public isHistoryPerformanceLoadingSignal = signal<boolean>(false);
  public historyPerformancesSignal = toSignal(
    this.fetchHistoryPerformanceData$(),
    {
      initialValue: {
        response: {} as MIMModelHistoryPerformanceResponseDTO,
        chartOptions: {} as Options
      } as HistoryPerformanceResponse
    }
  );

  public chartOptionsSignal = computed(
    () => this.historyPerformancesSignal().chartOptions
  );

  public isChartReadySignal = computed(
    () => !isEmpty(this.historyPerformancesSignal().chartOptions)
  );

  public isChartDataAvailableSignal = computed(
    () =>
      this.historyPerformancesSignal().response?.data?.filter(
        (s) => s.percOrdersI.value !== 0
      ).length > 0
  );

  public hoverPointSignal = signal<Highcharts.Point | null>(null);

  constructor(private readonly store: Store<AppState>) {
    this.store.select(selectStore).subscribe((state: AppState) => {
      this.rollupConversionTypeOptionsSignal.set(
        state.dashboard.rollupConversionTypes
      );
    });

    this.contextStore.filterContext.subscribe((filters: Filter[]) => {
      this.setConversionTypeOptionsFromFilter(filters);
    });

    this.selectionService.selectionChanged
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((selection) => {
        this.selectionSignal.set(selection);
      });
  }

  private setConversionTypeOptionsFromFilter(filters: Filter[]) {
    const type = filters.find((f) => f.literalId === 'conversion_type');
    const conversionTypeOptions = (type?.options || []).filter(
      (ct) => !this.rollupConversionTypeOptionsSignal().includes(ct)
    );
    let selectedConversionType = conversionTypeOptions.length
      ? conversionTypeOptions[0]
      : '';
    if (
      type &&
      type.value &&
      !this.rollupConversionTypeOptionsSignal().includes(type?.value as string)
    ) {
      selectedConversionType = type.value as string;
    }
    this.conversionTypeSignal.set(selectedConversionType as string);
    this.conversionTypeOptionsSignal.set(conversionTypeOptions);
  }

  getParams(): Params {
    const { clientId, brandId } = this.selectionService.getClientIdAndBrandId();
    return {
      clientId,
      brandId,
      conversion_type: this.conversionTypeSignal()
    };
  }

  getHistoryDateParams(period: string) {
    if (period === 'Last 12 Months') {
      const last52days = this.dateTimeService.calculatePrevious52Weeks();
      return {
        dateStart: last52days.startDate.format(DayJsDateFormat.fullDate),
        dateStop: last52days.endDate.format(DayJsDateFormat.fullDate)
      };
    } else if (period === 'All Time') {
      return {
        dateStart: this.dateTimeService
          .maximumEndDate()
          .subtract(3, 'year')
          .format(DayJsDateFormat.fullDate),
        dateStop: this.dateTimeService
          .maximumEndDate()
          .format(DayJsDateFormat.fullDate)
      };
    }
  }

  fetchMediumsData$(): Observable<MediaResponse[]> {
    const url = `${this.baseUriV1}/media-mix-model-details`;

    return combineLatest([
      this.selection$,
      this.conversionType$,
      this.showModal$
    ]).pipe(
      filter(() => this.showModalSignal()),
      tap(() => this.isMediumsLoadingSignal.set(true)),
      switchMap(() =>
        this.http.post<MIMMediaMixModelResponseDTO>(url, this.getParams()).pipe(
          catchError((error) => {
            console.error('Error fetching media-mix-model-details data', error);
            this.isMediumsLoadingSignal.set(false);
            return of({} as MIMMediaMixModelResponseDTO);
          }),
          map((response: MIMMediaMixModelResponseDTO) => {
            return [
              { label: 'Scheduled Update', value: response?.scheduledUpdate },
              {
                label: 'Model Calibration Period',
                value: response?.modelCalibrationPeriod
              },
              {
                label: 'Contributing Tactics',
                value: response?.noOfContributingTactics
              },
              { label: 'Total Spend Modeled', value: response?.totalSpend }
            ] as MediaResponse[];
          })
        )
      ),
      tap(() => {
        this.isMediumsLoadingSignal.set(false);
      })
    );
  }

  fetchTestsData$(): Observable<ContributingResponse> {
    const url = `${this.baseUriV1}/mim-contributing-tests`;

    return combineLatest([
      this.selection$,
      this.conversionType$,
      this.showModal$
    ]).pipe(
      filter(() => this.showModalSignal()),
      tap(() => this.isTestsLoadingSignal.set(true)),
      switchMap(() =>
        this.http
          .post<MIMContributingTestResponseDTO>(url, this.getParams())
          .pipe(
            catchError((error) => {
              console.error(
                'Error fetching mim-contributing-tests data',
                error
              );
              this.isTestsLoadingSignal.set(false);
              return of({} as MIMContributingTestResponseDTO);
            }),
            map((response: MIMContributingTestResponseDTO) => {
              return {
                response,
                table: this.getTableData(response, ContributingType.TESTS)
              } as ContributingResponse;
            })
          )
      ),
      tap(() => {
        this.isTestsLoadingSignal.set(false);
      })
    );
  }

  fetchTacticsData$(): Observable<ContributingResponse> {
    const url = `${this.baseUriV1}/mim-contributing-tactics`;

    return combineLatest([
      this.selection$,
      this.conversionType$,
      this.showModal$
    ]).pipe(
      filter(() => this.showModalSignal()),
      tap(() => this.isTacticsLoadingSignal.set(true)),
      switchMap(() =>
        this.http
          .post<MIMContributingTacticsResponseDTO>(url, this.getParams())
          .pipe(
            catchError((error) => {
              console.error(
                'Error fetching mim-contributing-tactics data',
                error
              );
              this.isTacticsLoadingSignal.set(false);
              return of({} as MIMContributingTacticsResponseDTO);
            }),
            map((response: MIMContributingTacticsResponseDTO) => {
              return {
                response,
                table: this.getTableData(response, ContributingType.TACTICS)
              } as ContributingResponse;
            })
          )
      ),
      tap(() => {
        this.isTacticsLoadingSignal.set(false);
      })
    );
  }

  fetchHistoryPerformanceData$(): Observable<HistoryPerformanceResponse> {
    const url = `${this.baseUriV1}/mim-model-performance-history`;

    return combineLatest([
      this.conversionType$,
      this.resolution$,
      this.period$,
      this.selection$,
      this.showModal$
    ]).pipe(
      filter(() => this.showModalSignal()),
      tap(() => {
        this.isHistoryPerformanceLoadingSignal.set(true);
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([conversionType, resolution, period]) =>
        this.http
          .post<MIMModelHistoryPerformanceResponseDTO>(url, {
            ...this.getParams(),
            conversion_type: conversionType,
            resolution,
            ...this.getHistoryDateParams(period)
          })
          .pipe(
            catchError((error) => {
              console.error(
                'Error fetching mim-model-performance-history data',
                error
              );
              this.isHistoryPerformanceLoadingSignal.set(false);
              return of({} as MIMModelHistoryPerformanceResponseDTO);
            }),
            map((response: MIMModelHistoryPerformanceResponseDTO) => {
              return {
                response,
                chartOptions: this.getChartOptions(response)
              } as HistoryPerformanceResponse;
            }),
            startWith({
              response: {} as MIMModelHistoryPerformanceResponseDTO,
              chartOptions: {} as Options
            } as HistoryPerformanceResponse)
          )
      ),
      tap(() => {
        this.isHistoryPerformanceLoadingSignal.set(false);
      })
    );
  }

  async exportChartData(format: string) {
    const endpoint = 'mim-model-performance-history';
    const params = this.getParams();
    const brandName = this.selectionService.selectionLabel;

    const exportParams = {
      ...params,
      format,
      resolution: this.resolutionSignal(),
      ...this.getHistoryDateParams(this.periodSignal()),
      brandName
    };

    try {
      const response = await firstValueFrom(
        this.http.post(`${this.baseUriV1}/export/${endpoint}`, exportParams, {
          responseType: 'blob'
        })
      );

      if (response) {
        const filename = `${endpoint}-${params.clientId}-${params.brandId}.${format}`;
        saveAs(response, filename); // Ensure response is a Blob
      }
    } catch (error) {
      console.error(`Export failed for ${endpoint}`, error);
    }
  }

  async exportTableData(index: number, format: string) {
    const endpoint =
      index === 0 ? 'mim-contributing-tests' : 'mim-contributing-tactics';
    const params = this.getParams();
    const brandName = this.selectionService.selectionLabel;

    const exportParams = {
      ...params,
      format,
      brandName
    };

    try {
      const response = await firstValueFrom(
        this.http.post(`${this.baseUriV1}/export/${endpoint}`, exportParams, {
          responseType: 'blob'
        })
      );

      if (response) {
        const filename = `${endpoint}-${params.clientId}-${params.brandId}.${format}`;
        saveAs(response, filename); // Ensure response is a Blob
      }
    } catch (error) {
      console.error(`Export failed for ${endpoint}`, error);
    }
  }

  getTableData(
    response:
      | MIMContributingTestResponseDTO
      | MIMContributingTacticsResponseDTO,
    type: ContributingType
  ): TableData {
    const columns = this.getTableColumns(response);
    const values: TableValue[] =
      type === ContributingType.TESTS
        ? this.getTableTestsValues(response as MIMContributingTestResponseDTO)
        : this.getTableTacticsValues(
            response as MIMContributingTacticsResponseDTO
          );

    const footer: TableFooter[] =
      type === ContributingType.TESTS
        ? []
        : this.getTableFooter(
            response as MIMContributingTacticsResponseDTO,
            columns
          );
    return {
      columns,
      values,
      footer
    };
  }

  getTableColumns(
    response: MIMContributingTestResponseDTO | MIMContributingTacticsResponseDTO
  ): TableColumn[] {
    return Object.entries(response?.fieldDefinition || {}).map(
      ([key, field]) => {
        return {
          header: field?.label,
          field: key,
          sortField: `${key}Raw`,
          class: '',
          sortable: true
        } as TableColumn;
      }
    );
  }

  getTableValue(
    property:
      | string
      | boolean
      | string[]
      | undefined
      | { value: number | Date; formattedValue: string },
    value: 'value' | 'formattedValue' = 'formattedValue'
  ): string | boolean | string[] {
    if (
      typeof property === 'object' &&
      !Array.isArray(property) &&
      property !== undefined &&
      ('value' in property || 'formattedValue' in property)
    ) {
      return property[value] as string | boolean | string[];
    }
    return property as string | boolean | string[];
  }

  getTableTestsValues(response: MIMContributingTestResponseDTO): TableValue[] {
    return response?.data?.map((data) => {
      const value: TableValue = {};
      for (const key in data) {
        if (key === 'testName') {
          value[key] = data[key];
        } else if (key === 'testType') {
          const icon =
            GeoDesignerConstants.testTypeIcons[data[key] as GeoTestType];
          value[key] =
            `<div class="flex"><span class="material-symbols-outlined icon-small text-gray-500 mr-2">${icon}</span>${capitalize(data[key]?.toLowerCase())}</div>`;
        } else {
          value[key] = this.getTableValue(data[key], 'formattedValue');
        }
        value[`${key}Raw`] = this.getTableValue(data[key], 'value');
        value.row = data;
      }
      return value;
    });
  }

  getTableTacticsValues(
    response: MIMContributingTacticsResponseDTO
  ): TableValue[] {
    return response?.data?.map((data) => {
      const value: TableValue = {};
      for (const key in data) {
        value[key] = this.getTableValue(data[key], 'formattedValue');
        value[`${key}Raw`] = this.getTableValue(data[key], 'value');
      }
      return value;
    });
  }

  getTableFooter(
    response: MIMContributingTacticsResponseDTO,
    columns: TableColumn[]
  ): TableFooter[] {
    return columns.map((col) => {
      let header = response?.totalData[col.field];
      if (!header) {
        header = '-';
      }
      return {
        header
      } as TableFooter;
    }, {});
  }

  getChartOptions(response: MIMModelHistoryPerformanceResponseDTO) {
    const data = response?.data || [];
    // Preserve full date strings for calculations
    const originalCategories = data.map((item) => item.date.formattedValue);
    // Format dates for x-axis labels only
    const formattedCategories = originalCategories.map((date) =>
      dayjs(date, 'MM-DD-YYYY').format('MMM DD')
    );

    const values = data.map((item) => item.percOrdersI.value * 100) || [];
    const min = Math.min(...values);
    const max = Math.max(...values) * 1.1;

    return {
      ...singleLineChart,
      legend: {
        enabled: false
      },
      xAxis: {
        categories: formattedCategories,
        lineColor: '#E3E7EB'
      },
      yAxis: this.getChartYAxis(min, max),
      plotOptions: this.getChartPlotOptions(),
      series: [
        this.getChartDataSeries(values),
        ...this.getChartTestsSeries(
          originalCategories, // Pass full date strings for marker calculation
          response?.contributingTests || [],
          min
        )
      ],
      tooltip: this.getChartTooltip()
    } as Options;
  }

  getChartYAxis(min: number, max: number) {
    return {
      title: {
        text: 'Incremental Orders %'
      },
      gridLineWidth: 1,
      gridLineColor: '#E3E7EB',
      gridLineDashStyle: 'Solid',
      labels: {
        format: '{value}%'
      },
      min,
      max,
      endOnTick: false,
      startOnTick: false
    };
  }

  getChartPlotOptions() {
    return {
      series: {
        pointPlacement: 'on',
        states: {
          inactive: {
            enabled: false
          },
          hover: {
            enabled: true
          }
        }
      }
    };
  }

  getChartTestData(
    categories: string[],
    test: MIMContributingTestData,
    min: number,
    index: number
  ) {
    // Hide all elements initially
    const initValue = min - 100;
    const result = new Array(categories.length).fill({ y: initValue });
    // Convert each full date string to a Date using dayjs
    const categoryDates = categories.map((date) =>
      dayjs(date, 'MM-DD-YYYY').toDate()
    );

    // Parse the calibration date from the test
    const calibrationDate = dayjs(test.calibrationDate.value).toDate();
    let closestIndex = 0;
    let smallestDiff = Infinity;

    // Find the category date that is closest to the calibration date
    categoryDates.forEach((categoryDate, i) => {
      const diff = Math.abs(categoryDate.getTime() - calibrationDate.getTime());
      if (diff < smallestDiff) {
        smallestDiff = diff;
        closestIndex = i;
      }
    });

    result[closestIndex] = {
      y: min,
      custom: { test, index }
    };

    return result;
  }

  getChartTooltip() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const service = this;
    return {
      enabled: true,
      useHTML: true,
      outside: true,
      animation: false,
      shadow: false,
      style: {
        zIndex: 1024
      },
      formatter(this: Highcharts.TooltipFormatterContextObject) {
        const test = this.point.options.custom?.test as MIMContributingTestData;
        if (test) {
          let tacticsHtml = '';
          test.tactics?.slice(0, 2).map((tactic: string) => {
            tacticsHtml += `<div class="b1 text-gray-000 text-ellipsis overflow-hidden">${tactic}</div>`;
          });
          if (test.tactics?.length > 2) {
            const count = test.tactics?.length - 2;
            tacticsHtml += `<div class="c2 text-gray-500 mt-2">+${count} more ${count === 1 ? 'tactic' : 'tactics'}</div>`;
          }

          return `
          <div class="flex flex-col text-center p-2 max-w-[260px] relative">
            <div class="b2 text-gray-000 mb-[6px] text-wrap">${test.testName}</div>
            <div class="c2 text-gray-500">${test.studyStartDate} - ${test.studyEndDate}</div>
            <div class="m-divider dark my-2"></div>
            <div class="text-left">
              ${tacticsHtml}
            </div>
            <div class="m-divider dark my-2"></div>
            <div class="grid grid-cols-2">
              <span class="b1 text-gray-000 text-left">Type</span>
              <span class="b1 text-gray-000 text-right">${capitalize(test.testType?.toLowerCase())}</span>
              <span class="b1 text-gray-000 text-left">Calibration date</span>
              <span class="b1 text-gray-000 text-right">${test.calibrationDate.formattedValue}</span>
              <span class="b1 text-gray-000 text-left">Contribution</span>
              <span class="b1 text-gray-000 text-right">${test.contribution.formattedValue}</span>
              <span class="b1 text-gray-000 text-left">Confidence Level</span>
              <span class="b1 text-gray-000 text-right">${test.confidenceLevel.formattedValue}</span>
            </div>
          </div>`;
        } else {
          return `
          <div class="flex flex-col text-center p-2 max-w-[260px] relative">
            <div class="b2 text-gray-500">${this.x}</div>
            <div class="m-divider dark my-2"></div>
            <div class="grid grid-cols-2">
              <span class="b1 text-gray-000 text-left">${this.series.name}</span>
              <span class="b1 text-gray-000 text-right">${this?.y?.toFixed(2)}%</span>
            </div
          </div>`;
        }
      },
      positioner(
        this: Highcharts.Tooltip,
        width: number,
        height: number,
        point: Highcharts.Point
      ) {
        const hoverPoint = service.hoverPointSignal();
        const markerElement = document.querySelector(
          `div[data-point-index="${hoverPoint?.options?.custom?.index}"]`
        );

        let x, y;
        if (markerElement) {
          const markerRect = markerElement.getBoundingClientRect();
          const chartRect = this.chart.container.getBoundingClientRect();

          x =
            markerRect.left + markerRect.width / 2 - chartRect.left - width / 2;
          y = markerRect.top - chartRect.top - height - 8;
        } else {
          x = (point?.plotX || 0) + this.chart.plotLeft - width / 2;
          y = (point?.plotY || 0) + this.chart.plotTop - height - 14;
        }

        const offset = 10;
        const chartWidth = this.chart.chartWidth;
        const chartHeight = this.chart.chartHeight;

        if (x < 0) {
          x = offset;
        }
        if (x + width > chartWidth) {
          x = chartWidth - width - offset;
        }
        if (y + height > chartHeight) {
          y = chartHeight - height - offset;
        }

        return { x, y };
      }
    };
  }

  getChartDataSeries(data: number[]) {
    return {
      type: 'areaspline',
      name: 'Incremental Orders %',
      data,
      color: {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
        stops: [
          [0, '#FFD6BC'],
          [1, '#FFFFFF']
        ]
      },
      lineColor: '#FF5733',
      lineWidth: 1,
      marker: {
        enabled: false,
        radius: 2,
        fillColor: '#265699',
        lineWidth: 1,
        lineColor: '#FFFFFF',
        symbol: 'circle'
      }
    } as Highcharts.SeriesOptionsType;
  }

  getChartTestsSeries(
    categories: string[],
    tests: MIMContributingTestData[],
    min: number
  ) {
    const result = [] as Highcharts.SeriesOptionsType[];

    tests.forEach((test, index) => {
      const data = this.getChartTestData(categories, test, min, index);
      result.push({
        type: 'line',
        name: `#Test# ${test?.calibrationDate.formattedValue}`,
        data,
        color: '#354358',
        lineWidth: 0,
        enableMouseTracking: true,
        marker: {
          enabled: false
        }
      });
    });
    return result;
  }
}
