export class SlidingQueue<T> {
  public queue: T[] = [];
  private readonly fixedSize: number = 20;

  constructor(queueSize?: number) {
    this.fixedSize = queueSize || this.fixedSize;
  }

  public addFirst(values: T[]) {
    this.queue.unshift(...values);
    const excess = this.queue.length - this.fixedSize;
    if (excess <= 0) {
      return;
    }
    this.queue.splice(this.fixedSize, excess);
  }

  public addLast(values: T[]) {
    this.queue.push(...values);
    const excess = this.queue.length - this.fixedSize;
    if (excess <= 0) {
      return;
    }
    this.queue.splice(0, excess);
  }

  flush() {
    this.queue = [];
  }
}
