<div
  id="loader-wrapper"
  class="w-100 h-100 top-0 start-0 position-absolute show-above {{
    type === 'inline' ? '' : 'backdrop-brightness-80'
  }} d-flex align-items-center saas-ui">
  @if (type === "inline") {
    <div
      class="d-flex align-items-center flex-column justify-items-center p-2 rounded rounded-2 w-100">
      <img
        alt="Loading..."
        src="/assets/images/spinner.gif"
        class="inline-spinner-medium" />
      <h4 class="fw-normal text-gray mt-4 mb-0">
        {{ loaderTitle }}
      </h4>
      <p class="fw-normal text-gray mt-2 mb-6 fs-14">
        {{ loaderMessage }}
      </p>
    </div>
  }
  @if (!config && type === "modal") {
    <div
      class="w-inherit mx-auto p-3 ui-card d-flex flex-column align-items-center {{
        size
      }}"
      style="min-height: 196px; max-width: 460px; min-width: 230px"
      [ngStyle]="isTransparent ? { background: 'unset', border: 'unset' } : {}">
      @if (view === "error") {
        <span
          class="material-symbols-outlined saas-icon-lg link-icon ms-auto text-gray"
          (click)="handleClose.emit()"
          >close</span
        >
      }
      <h4 class="fw-normal text-gray mt-4 mb-0">
        {{ loaderTitle }}
      </h4>
      <p class="fw-normal text-gray mt-2 mb-6 fs-14">
        {{ loaderMessage }}
      </p>
      <div>
        @switch (view) {
          @case ("success") {
            <div class="text-center mt-3">
              <span class="material-symbols-outlined success-tick">
                check_circle
              </span>
              <div class="mt-3 mb-2 mx-auto">
                <button
                  pButton
                  label="Continue"
                  class="p-button-primary"
                  (click)="handleContinue.emit()"></button>
              </div>
            </div>
          }
          @case ("error") {
            <div class="text-center">
              <span class="material-symbols-outlined error"> error </span>
              <div class="mt-3 mb-2 mx-auto">
                <button
                  pButton
                  label="Retry"
                  class="p-button-primary"
                  (click)="handleRetry.emit()"></button>
              </div>
            </div>
          }
          @default {
            <div>
              <img
                alt="Loading..."
                src="/assets/images/spinner.gif"
                class="inline-spinner mx-auto mt-3 mb-2" />
            </div>
          }
        }
      </div>
    </div>
  }
  @if (config && config.isMessage && type === "modal") {
    <div
      class="w-inherit mx-auto p-4 ui-card side-bar-{{
        config.view
      }} message-box"
      [ngStyle]="isTransparent ? { background: 'unset', border: 'unset' } : {}">
      <div class="row">
        <div class="col-md-12 header-wrapper">
          @if (config.titleIcon && config.view === "success") {
            <span class="material-symbols-outlined success-message-tick">
              check_circle
            </span>
          }
          @if (config.titleIcon && config.view === "error") {
            <span class="material-symbols-outlined error-message-tick">
              report_problem
            </span>
          }
          <span class="fw-boldish px-2">{{ config.title }}</span>
          @if (config.closeText) {
            <span
              class="material-icons ms-auto fs-5 text-gray float-end"
              (click)="handleClose.emit()"
              >close</span
            >
          }
          @if (config.closeIcon) {
            <span
              class="material-icons ms-auto fs-5 text-gray float-end"
              (click)="handleClose.emit()"
              ><em class="pi pi pi-times ms-1"></em
            ></span>
          }
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <p class="fw-normal mt-2 mb-6 px-4">
            {{ loaderMessage }}
          </p>
        </div>
      </div>
      <div class="row">
        @if (config.okayButton) {
          <div class="col-md-12">
            <button
              pButton
              [label]="config.okayButtonText || 'Okay'"
              class="p-button-primary float-end"
              (click)="handleContinue.emit()"></button>
          </div>
        }
      </div>
    </div>
  }
</div>
