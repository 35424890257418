export enum FilterIds {
  relativeDayLiteralId = 'relative_day',
  compareDateLiteralId = 'compare_date',
  dateStartLiteralId = 'dateStart',
  dateStopLiteralId = 'dateStop',
  conversionTypeLiteralId = 'conversion_type',
  previousDateStartLiteralId = 'previousDateStart',
  previousDateStopLiteralId = 'previousDateStop',
  optimizeForFilter = 'optimizeForFilter',
  optimizeStrengthFilter = 'optimizeStrengthFilter',
  isInitialFilterCall = 'isInitialFilterCall',
  startDate = 'start_date',
  endDate = 'end_date',
  viewBy = 'viewBy',
  resolution = 'resolution',
  day = 'day',
  channel = 'channel',
  tactic = 'tactic',
  layoutFeature = 'layout_feature',
  showDatasetDrivers = 'showDatasetDrivers',
  showPointDrivers = 'showPointDrivers',
  compare = 'compare'
}

export enum Resolution {
  weekly = 'week',
  monthly = 'month',
  quarterly = 'quarter'
}
