import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import {
  DataResponse,
  DataSetResponse,
  Filter
} from '@portal/app/shared/types';
import { DatasetBaseService } from '@portal/app/shared/services/dataset-base.service';
import { ViewStore } from '@portal/app/shared/state/view.store';
import { DateTimeService } from '@portal/app/shared/services/date-time.service';

export enum DataAvailabilityName {
  mimExportHasData = 'mimExportHasData'
}

@Injectable({
  providedIn: 'root'
})
export class DatasetAvailabilityService extends DatasetBaseService<DataResponse> {
  constructor(
    private readonly http: HttpClient,
    protected readonly viewStore: ViewStore,
    protected readonly dateService: DateTimeService
  ) {
    super(viewStore, dateService);
  }

  getParams(): Params {
    const params = super.getParams();
    params.dimensions = ['conversion_type'];
    return params;
  }

  getFilters(filters: Filter[]): Params {
    const filterParams = super.getFilters(filters);
    filterParams.dataSets = ['mimV2DataAvailabilityByCT'];
    return filterParams;
  }

  fetchData(filters: Filter[]) {
    const queryParams = this.getQueryParams(filters);

    return this.http
      .post<
        DataSetResponse[]
      >(`${DatasetBaseService.measuredUriV2}dataviz/data/data-sets`, queryParams)
      .subscribe((response) => {
        const data = response[0]?.data;
        if (data) {
          this.setData(data);
        }
      });
  }

  hasData(key: DataAvailabilityName): boolean {
    const row = this.getData().find(
      (d) => d.conversion_type === this.conversionType && d[key]
    );
    return !!row;
  }
}
