import { IUserDetails } from './IUserDetails';
import { Injectable } from '@angular/core';
import { IUserAccess, User, UserRole } from '@libs/apis';

@Injectable()
export class UserModel implements IUserDetails {
  public user: User;

  constructor(user: User) {
    this.user = user;
  }

  public getRole(): string {
    return this.user.role;
  }

  public getRoleLabel(): string {
    switch (this.getRole()) {
      case UserRole.superUser:
        return 'Super User';
      case UserRole.clientAdmin:
        return 'Client Admin';
      case UserRole.clientUser:
        return 'User';
      case UserRole.measuredDev:
        return 'Measured Developer';
      case UserRole.measuredSol:
        return 'Measured Solutions';
      default:
        return 'User';
    }
  }

  public isSuperUser(): boolean {
    return (
      this.getRole() === UserRole.superUser ||
      this.getRole() === UserRole.measuredDev ||
      this.getRole() === UserRole.measuredSol
    );
  }

  public isMeasuredUser(): boolean {
    return this.getUsername().includes('@measured.com');
  }

  public hasAccessToMultipleClientOrBrandIds(): boolean {
    const numClients = this.getClientIds().length;
    const numBrands = this.getBrandIds().length;
    return (
      numClients === 0 ||
      numClients > 1 ||
      numBrands === 0 ||
      numBrands > 1 ||
      this.isSuperUser()
    );
  }

  public getClientIds(): number[] {
    return this.user.clientIds.map((id) => parseInt(id, 10));
  }

  public getBrandIds(): number[] {
    return this.user.brandIds.map((id) => parseInt(id, 10));
  }

  public getUsername(): string {
    return this.user.userName;
  }

  public getEmail(): string {
    return this.user.email;
  }

  public getUserType(): User['userType'] {
    return this.user.userType;
  }

  public getUserRoles(): IUserAccess[] {
    return this.user.roles || [];
  }
}
