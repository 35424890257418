<div class="label-metric-container">
  <span class="label-container">{{ label }}</span>
  <div class="metric-container mt-1" #progressBarContainer>
    <div class="bar-container">
      <div class="bar-value" [style.width.%]="value"></div>
      <div
        class="metric-value-dot-container"
        pTooltip="{{ value !== null && valueTooltip ? valueTooltip : null }}"
        [tooltipPosition]="tooltipPosition"
        tooltipStyleClass="dot-tooltip"
        showDelay="800"
        [escape]="false">
        @if (value !== null) {
          <div class="metric-value-dot"></div>
        }
      </div>
    </div>
  </div>
  <div
    class="median-dot-container"
    pTooltip="{{ medianTooltip }}"
    [tooltipPosition]="tooltipPosition"
    tooltipStyleClass="median-dot-tooltip"
    showDelay="800"
    (mouseover)="onHover('secondary')"
    (mouseleave)="onExit('secondary')">
    <div class="median-dot"></div>
  </div>
</div>
