<div [@slideInOut] class="context-modal-container flex flex-col h-full">
  <m-card
    [isDraggable]="false"
    [isHoverable]="false"
    class="w-full p-6 max-w-screen-3xl mx-auto">
    <div class="flex justify-between items-center pb-6">
      <div class="flex items-center">
        <div class="h4 text-gray-1000 mr-4 mb-0">
          @if (hasBackButton()) {
            <a
              class="flex items-center text-blue-600 cursor-pointer"
              (click)="onCloseModal()">
              <span class="material-symbols-outlined mr-2"> arrow_back </span>
              {{ title() }}
            </a>
          } @else {
            {{ title() }}
          }
        </div>
        <m-type-pill [size]="typePillSize.small" [text]="pill()"></m-type-pill>
      </div>
      <div class="flex items-center">
        <div class="flex gap-2 items-center mr-4">
          <span class="material-symbols-outlined icon-filled text-gray-500">
            filter_alt
          </span>
          <div class="b1 text-gray-800">
            {{ conversionType() }}
          </div>
        </div>
        <span
          class="material-symbols-outlined text-blue-600 cursor-pointer"
          (click)="onCloseModal()">
          close
        </span>
      </div>
    </div>
    <ng-content></ng-content>
  </m-card>
  <div
    class="flex justify-between items-center mt-auto sticky bottom-0 bg-white px-6 py-7 border-top border-gray-300">
    <div>
      <m-button type="tertiary" (click)="onPreviousAction()">
        <i class="material-symbols-outlined icon-small mr-1">chevron_left</i>
        <span class="b2">Previous {{ modalType() }}</span>
      </m-button>
    </div>
    <div>
      <portal-autocomplete
        [options]="autoCompleteOptions()"
        [optionLabel]="autoCompleteOptionLabel()"
        [placeholder]="'Search ' + modalType()"
        (selectItem)="onSelectItem($event)"></portal-autocomplete>
    </div>
    <div>
      <m-button type="tertiary" (click)="onNextAction()">
        <span class="b2">Next {{ modalType() }}</span>
        <i class="material-symbols-outlined icon-small mr-1">chevron_right</i>
      </m-button>
    </div>
  </div>
</div>
