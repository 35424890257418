@if (!compositeCellConfig) {
  <div class="comp-col">
    @for (
      compositeCol of col?.additionalAttributes.multiDashboardFieldIds;
      track compositeCol;
      let i = $index
    ) {
      @if (i === 0) {
        <div class="comp-col-main">
          <portal-data-cell
            [dashboardId]="dashboardId"
            [key]="key"
            [cellLayoutType]="cellLayoutType"
            [data]="
              rowData[col.dashboardFieldId]
                | formatValue: fieldDefinitions[col.dashboardFieldId]
            "></portal-data-cell>
        </div>
      } @else {
        @if (
          col.additionalAttributes?.eligibleFields.includes(
            col.dashboardFieldId
          ) &&
          (rowData[col.dashboardFieldId] || rowData[col.dashboardFieldId] === 0)
        ) {
          <div class="comp-col-composite-item">
            <div class="indication-text">
              {{ rowData[compositeCol] }}
            </div>
          </div>
        }
      }
    }
  </div>
}

@if (compositeCellConfig) {
  <div [pTooltip]="getToolTip()" [escape]="false">
    @for (col of compositeCellConfig.fields; track col; let index = $index) {
      <span
        [class]="col?.styleClass"
        [ngClass]="{
          'first-dimension': index === 0,
          'second-dimension': index > 0,
          'd-block': col.alignment === 'VERTICAL',
        }">
        {{
          compositeCellConfig.data[col.id]
            ? compositeCellConfig.data[col.id]
            : col.emptyValue
        }}
      </span>
    }
  </div>
}
