import { Component } from '@angular/core';

@Component({
  selector: 'portal-error-handler',
  template:
    '<div class="text-danger fallback-container d-flex justify-content-center">' +
    '  <h1>{{ errorMessage }}</h1> ' +
    '</div>',
  styles: ['.fallback-container { padding-top: 10%; margin-bottom: 10%; }']
})
export class ErrorHandlerComponent {
  errorMessage = `There was a problem while configuring your dashboard, please reach out to measured support.`;
}
