import { ApiService, IAngularEnvironment } from '@libs/apis';
import {
  IDataSourceDsInstanceDTO,
  IDsInstance,
  IDsInstanceDTO,
  IRawDsInstanceDTO,
  Status
} from '@libs/apis';
import { Inject, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class InputFileIngestionHelper {
  apiService: ApiService;
  constructor(
    apiService: ApiService,
    @Inject('environment') private readonly environment: IAngularEnvironment
  ) {
    this.apiService = apiService;
  }

  defaultDsInstanceDTO: IDataSourceDsInstanceDTO = {
    dataSource: {},
    defaultDsInstance: {},
    componentMonitoring: { importType: '' }
  };

  public async createDsInstance(
    dsInstanceDTO: IDsInstanceDTO,
    queryParam: boolean
  ): Promise<IDsInstance> {
    return this.apiService.manageFactDataApisOperations.createDsConfigs(
      dsInstanceDTO,
      queryParam
    );
  }

  public async updateDsInstance(
    dsInstanceId: number,
    dsInstanceDTO: IDsInstance
  ): Promise<void> {
    await this.apiService.manageFactDataApisOperations.updateDsInstance(
      dsInstanceId,
      dsInstanceDTO
    );
  }

  public async saveDsInstance(
    rawData: IRawDsInstanceDTO
  ): Promise<IDsInstance> {
    const postData = await this.buildDsInstanceCreationBody(rawData);
    return this.createDsInstance(postData, false);
  }

  public async buildDsInstanceCreationBody(
    rawData: IRawDsInstanceDTO
  ): Promise<IDsInstanceDTO> {
    this.defaultDsInstanceDTO =
      await this.apiService.manageFactDataApisOperations.getDSWithMultipleDSInstancesById(
        rawData.dataSourceId || ''
      ); // confirm for origin config id and change the api call
    const postBody = {
      dsInstance: {
        ...this.defaultDsInstanceDTO.defaultDsInstance[0],
        ...this.prepareDsInstanceFormValues(rawData),
        dsInputFileConfigs: rawData.inputFileConfig,
        name: rawData.dsInstanceName,
        clientId: rawData.clientId,
        dsJobConfigs: {
          name: `job-${rawData.dsInstanceName}`,
          scheduleId: 308,
          dsOriginCredentialId: 12,
          noOfRetries: 4,
          mergeStrategy: 'none',
          params: {},
          status: Status.ACTIVE
        }
      },
      dataSourceId: rawData.dataSourceId || '',
      baseUrl:
        this.defaultDsInstanceDTO.defaultDsInstance[0].originConfig.baseUrl
    };
    delete postBody.dsInstance.id;
    return postBody;
  }

  prepareDsInstanceFormValues(rawData: IRawDsInstanceDTO) {
    return {
      name: rawData.clientId + '-instance',
      destinationBaseUrl:
        rawData.clientId +
        (this.environment.innovation ? '-measured-test' : '-measured'),
      destinationUrlPath: rawData.dataSourceId + '/',
      rawBucketName: `__raw/${rawData.dataSourceId}/`,
      cleanedBucketName:
        rawData.clientId +
        (this.environment.innovation ? '-measured-test' : '-measured'),
      destinationParams: JSON.stringify(
        this.defaultDsInstanceDTO.defaultDsInstance[0].destinationParams
      )
    };
  }

  buildDsInstanceUpdateBody(): Promise<IDsInstance> {
    const dummyIDsInstance: IDsInstance = {
      id: 0,
      name: 'dummy',
      destinationBaseUrl: 'dummy',
      destinationUrlPath: 'dummy',
      rawBucketName: 'dummy',
      cleanedBucketName: 'dummy',
      destinationParams: 'dummy',
      dsInputFileConfigs: [],
      dsJobConfigs: [
        {
          id: 0,
          name: 'dummy',
          scheduleId: 0,
          dsOriginCredentialId: 0,
          noOfRetries: 0,
          mergeStrategy: 'dummy',
          jobConfigParams: {},
          scheduleType: 'dummy',
          notificationChannel: 'dummy',
          status: Status.ACTIVE
        }
      ],
      status: Status.ACTIVE
    };
    return Promise.resolve(dummyIDsInstance);
  }
}
