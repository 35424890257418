<p-chip
  class="m-chip {{ disabled ? 'chip-disabled' : '' }}"
  [label]="label"
  [removable]="removable"
  [style]="style"
  [styleClass]="styleClass">
  <div class="flex items-center gap-1">
    <ng-container *ngIf="icon && icon.length">
      <span
        class="flex material-symbols-outlined icon-small {{
          disabled ? 'text-gray-600' : 'text-blue-600'
        }}"
        >{{ icon }}</span
      >
    </ng-container>
    <span class="chip-label">{{ label }}</span>
    <ng-container *ngIf="removable">
      <span
        class="flex material-symbols-outlined icon-small cursor-pointer {{
          disabled ? 'text-gray-600' : 'text-blue-600'
        }}"
        (click)="disabled ? $event.stopImmediatePropagation() : removeChip()"
        >close</span
      >
    </ng-container>
  </div>
</p-chip>
