@if (tableData.data.length > 0) {
  <p-table
    #dt
    [value]="tableData.data"
    [columns]="tableData.columns"
    responsiveLayout="scroll"
    [responsive]="true"
    [reorderableColumns]="true"
    class="pivot-table"
    [ngClass]="styleClass">
    <ng-template pTemplate="header" let-columns>
      <tr>
        @for (col of columns; track col; let index = $index) {
          <th pFrozenColumn id="header" [frozen]="index === 0">
            {{ col.label }}
          </th>
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        @for (col of columns; track col) {
          <td id="body">
            <div>{{ rowData[col.field] }}</div>
            <div class="pivot-header-sub-text">
              {{ rowData[col.subText] !== null ? rowData[col.subText] : "" }}
            </div>
          </td>
        }
      </tr>
    </ng-template>
  </p-table>
}
