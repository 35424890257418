/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import dayjs, { ManipulateType } from 'dayjs';
import type { Dayjs } from 'dayjs';

// Services
import {
  DateTimeService,
  latestThirtyDays,
  latestFiftyTwoWeeks,
  currentYear,
  previousMonth,
  previousQuarter,
  previousYear,
  customCompare
} from '@portal/app/shared/services/date-time.service';

// types
import { Filter, FilterValue } from '@portal/app/shared/types';
interface DateRange {
  startDate: Dayjs;
  endDate: Dayjs;
}
interface FilterArgs {
  applyTo?: string[] | null;
  format?: string;
  isRequired?: 'YES' | 'NO';
  literalId: string;
  type: 'string' | 'string[]' | 'number' | 'number[]' | 'dateRange' | 'boolean';
  value: FilterValue;
  options?: string[] | null;
}

// Constants
import { HOMEPAGE_DASHBOARD_ID } from '@portal/app/dashboard/home-page/home-page.constants';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private filtersSubject = new BehaviorSubject<Filter[]>(this.defaultFilters());
  filters$ = this.filtersSubject.asObservable();

  // Observable to track the selected compare option
  private selectedCompareOptionSubject = new BehaviorSubject<string | null>(
    null
  );

  selectedCompareOption$ = this.selectedCompareOptionSubject.asObservable();

  constructor(private dateTimeService: DateTimeService) {}

  setFilters(newFilters: Filter[]): void {
    const currentFilters = this.filtersSubject.getValue();

    const filterMap = new Map(
      currentFilters.map((filter) => [filter.literalId, filter])
    );

    newFilters.forEach((newFilter) => {
      filterMap.set(newFilter.literalId, newFilter);
    });

    this.filtersSubject.next(Array.from(filterMap.values()));
  }

  getCurrentFilters(): Filter[] {
    return this.filtersSubject.getValue();
  }

  createFilter({
    applyTo = null,
    isRequired = 'NO',
    format = '',
    literalId,
    type = 'string',
    value,
    options = null
  }: FilterArgs): Filter {
    return {
      dashboardId: HOMEPAGE_DASHBOARD_ID,
      name: literalId,
      literalId,
      isRequired,
      type,
      format,
      applyTo,
      value,
      options,
      label: literalId,
      id: -1,
      associatedFilters: undefined
    };
  }

  defaultFilters() {
    return [
      this.createFilter({
        literalId: 'isInitialFilterCall',
        type: 'string',
        value: false
      }),
      this.createFilter({
        literalId: 'relative_day',
        type: 'string',
        value: latestThirtyDays
      })
    ];
  }

  /**
   * Updates the value of a filter based on its literalId.
   *
   * @param literalId The ID of the filter to update.
   * @param newValue The new value to set for the filter.
   */
  updateFilterValue(literalId: string, newValue: any): void {
    const currentFilters = this.filtersSubject.getValue();
    const filterIndex = currentFilters.findIndex(
      (filter) => filter.literalId === literalId
    );

    if (filterIndex !== -1) {
      const updatedFilters = currentFilters.map((filter, index) =>
        index === filterIndex ? { ...filter, value: newValue } : filter
      );

      this.setFilters(updatedFilters);
    }
  }

  private getResolution(relativeDay: string): ManipulateType {
    if ([latestFiftyTwoWeeks].includes(relativeDay)) {
      return 'week';
    } else if ([currentYear].includes(relativeDay)) {
      return 'month';
    } else {
      return 'day';
    }
  }

  calculatePreviousPeriod(date: DateRange): DateRange {
    const dayDifference = date.endDate.diff(date.startDate, 'day') + 1;
    return {
      startDate: dayjs(date.startDate).utc(true).subtract(dayDifference, 'day'),
      endDate: dayjs(date.startDate).utc(true).subtract(1, 'day')
    };
  }

  calculatePrevious(
    date: DateRange,
    timePeriod: 'month' | 'quarter' | 'year'
  ): DateRange {
    return {
      startDate: dayjs(date.startDate).utc(true).subtract(1, timePeriod),
      endDate: dayjs(date.endDate).utc(true).subtract(1, timePeriod)
    };
  }

  private getRelativeDayValue(): string {
    const relativeDayFilter = this.filtersSubject
      .getValue()
      .find((filter) => filter.literalId === 'relative_day');
    // Default to 'Latest 30 Days' if not found
    return relativeDayFilter
      ? (relativeDayFilter.value as string)
      : latestThirtyDays;
  }

  /**
   * Method to construct and return date filters.
   *
   * @param relativeDay Optional string specifying the relative day key.
   * @returns An array of Filter objects.
   */
  getDateFilters(relativeDay?: string): Filter[] {
    const currentRelativeDay = relativeDay || this.getRelativeDayValue();
    const resolution = this.getResolution(currentRelativeDay);
    const relativeDayOption =
      this.dateTimeService.getRelativeDayOptionByRelativeDay(
        currentRelativeDay
      );

    if (!relativeDayOption || !relativeDayOption.action) {
      return [];
    }

    const { startDate, endDate } = relativeDayOption.action;
    const startDateObj = dayjs(startDate);
    const endDateObj = dayjs(endDate);

    // compare filter values
    const compare = this.getCurrentCompareOption();
    const currentFilters = this.filtersSubject.getValue();
    const compareDateFilter = currentFilters.find(
      (filter) => filter.literalId === 'compare_date'
    ) as Filter;

    let prevPeriod;
    switch (compare) {
      case previousMonth:
        prevPeriod = this.calculatePrevious({ startDate, endDate }, 'month');
        break;
      case previousQuarter:
        prevPeriod = this.calculatePrevious({ startDate, endDate }, 'quarter');
        break;
      case previousYear:
        prevPeriod = this.calculatePrevious({ startDate, endDate }, 'year');
        break;
      case customCompare:
        if (compareDateFilter && Array.isArray(compareDateFilter.value)) {
          prevPeriod = {
            prevStartDate: dayjs(compareDateFilter.value[0] as string),
            prevEndDate: dayjs(compareDateFilter.value[1] as string)
          };
        } else {
          prevPeriod = this.calculatePreviousPeriod({ startDate, endDate });
        }
        break;
      default:
        prevPeriod = this.calculatePreviousPeriod({ startDate, endDate });
        break;
    }

    const prevStartDate = prevPeriod.startDate;
    const prevEndDate = prevPeriod.endDate;

    return this.createOrUpdateDateFilters(
      startDateObj,
      endDateObj,
      resolution,
      prevStartDate,
      prevEndDate,
      currentRelativeDay
    );
  }

  /**
   * Utility method to create or update a filter based on its literalId.
   *
   * @param literalId String identifier of the filter.
   * @param value The new value to set for the filter.
   * @returns A Filter object.
   */
  createOrUpdateFilter(literalId: string, value: any): Filter {
    const existingFilters = this.filtersSubject.getValue();
    const existingFilter = existingFilters.find(
      (f) => f.literalId === literalId
    );

    if (existingFilter) {
      return { ...existingFilter, value }; // Update existing filter with new value.
    }

    // Create a new filter if it does not exist.
    return this.createFilter({ literalId, type: 'string', value });
  }

  setDefaultConversionType(filters: Filter[]): Filter[] {
    const conversionTypeFilter = filters.find(
      (filter) => filter.literalId === 'conversion_type'
    );

    // Check if the filter exists and its value is null
    if (conversionTypeFilter && conversionTypeFilter.value === null) {
      // Also check if options exist and have at least one element
      if (
        conversionTypeFilter.options &&
        conversionTypeFilter.options.length > 0
      ) {
        conversionTypeFilter.value = conversionTypeFilter.options[0] as string;
      }
    }

    return filters;
  }

  /**
   * Creates or updates custom date filters based on provided day and compare_date filters.
   *
   * @param dayFilter - The filter containing start and end dates.
   * @param compareDateFilter - The filter containing comparison start and end dates.
   * @returns An array of created or updated filters.
   */
  getCustomDateFilters(dayFilter: Filter, compareDateFilter: Filter): Filter[] {
    if (
      !dayFilter ||
      !compareDateFilter ||
      !Array.isArray(dayFilter.value) ||
      !Array.isArray(compareDateFilter.value)
    ) {
      return [];
    }

    const startDate = dayjs(dayFilter.value[0] as Dayjs);
    const endDate = dayjs(dayFilter.value[1] as Dayjs);
    const prevStartDate = dayjs(compareDateFilter.value[0] as Dayjs);
    const prevEndDate = dayjs(compareDateFilter.value[1] as Dayjs);
    const resolution = this.calculateResolution(startDate, endDate);

    return this.createOrUpdateDateFilters(
      startDate,
      endDate,
      resolution,
      prevStartDate,
      prevEndDate
    );
  }

  // Method to update the selected compare option
  setSelectedCompareOption(option: string): void {
    this.selectedCompareOptionSubject.next(option);
  }

  private calculateResolution(startDate: Dayjs, endDate: Dayjs): string {
    const daysDiff = endDate.diff(startDate, 'day');
    return daysDiff < 180 ? 'day' : 'month';
  }

  private createOrUpdateDateFilters(
    startDate: Dayjs,
    endDate: Dayjs,
    resolution: string,
    prevStartDate?: Dayjs,
    prevEndDate?: Dayjs,
    relativeDay?: string
  ): Filter[] {
    const filters: Filter[] = [
      this.createOrUpdateFilter('relative_day', relativeDay),
      this.createOrUpdateFilter('dateStart', startDate.format('YYYY-MM-DD')),
      this.createOrUpdateFilter('dateStop', endDate.format('YYYY-MM-DD')),
      this.createOrUpdateFilter('resolution', resolution),
      this.createOrUpdateFilter('day', [
        startDate.format('YYYY-MM-DD'),
        endDate.format('YYYY-MM-DD')
      ])
    ];

    if (prevStartDate && prevEndDate) {
      filters.push(
        this.createOrUpdateFilter(
          'previousDateStart',
          prevStartDate.format('YYYY-MM-DD')
        ),
        this.createOrUpdateFilter(
          'previousDateStop',
          prevEndDate.format('YYYY-MM-DD')
        ),
        this.createOrUpdateFilter('compare_date', [
          prevStartDate.format('YYYY-MM-DD'),
          prevEndDate.format('YYYY-MM-DD')
        ])
      );
    }

    return filters;
  }

  getCurrentCompareOption(): string | null {
    return this.selectedCompareOptionSubject.getValue();
  }
}
