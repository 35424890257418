import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CardVariant } from '@design-system/components/m-card';
import { ContextModalCard } from '@portal/app/dashboard/context-modal/context-model.types';

@Component({
  selector: 'portal-context-modal-card',
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss'
})
export class ModalCardComponent {
  public cardVariant = CardVariant;

  @Input() card!: ContextModalCard;

  @Output() buttonAction = new EventEmitter();

  onButtonAction() {
    this.buttonAction.emit();
  }
}
