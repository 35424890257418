<m-fade-in-up>
  @if (isLoading()) {
    <m-skeleton height="230px" class="w-full"></m-skeleton>
  } @else if (!isDataAvailable()) {
    <m-not-available
      height="230px"
      description="We don't have a summary ready for you now"></m-not-available>
  } @else {
    <m-card
      class="w-full"
      *ngIf="topMetric()"
      [noShadow]="true"
      [variant]="cardVariant.compact">
      <div class="w-full grid grid-cols-12 gap-6 items-center">
        <div class="text-center col-span-2">
          <div class="max-w-[200px] m-auto">
            <img
              class="max-w-full"
              [src]="
                topMetric().isChangePositive
                  ? 'assets/images/revenue.svg'
                  : 'assets/images/taxes.svg'
              "
              alt="Metric" />
          </div>
        </div>
        <div class="col-span-4 flex flex-col gap-1">
          @if (topMetric().value === 0) {
            <div
              class="sh2"
              [ngClass]="
                topMetric().isChangePositive
                  ? 'text-green-700'
                  : 'text-orange-300'
              ">
              -
            </div>
          } @else {
            <m-count-up-display
              [end]="topMetric().formattedValue || ''"
              [label]="
                topMetric().label + ' for this channel in the last 30 days'
              "
              [textClass]="
                'h2 ' +
                (topMetric().isChangePositive
                  ? 'text-green-700'
                  : 'text-orange-300')
              "
              [duration]="1"
              subtitleClass="sh2">
            </m-count-up-display>
          }

          <div class="m-divider two-tone"></div>

          <div class="sh1 text-gray-1000">
            @if (topMetric().isHigher) {
              {{ topMetric().label }} for this channel is
              <strong>{{ topMetric().percentChange }} higher</strong>
              than your portfolio average.
            } @else {
              {{ topMetric().label }} for this channel is
              <strong>{{ topMetric().percentChange }} lower</strong>
              than your portfolio average.
            }
          </div>
        </div>

        <div class="col-span-6">
          <m-card [variant]="cardVariant.small">
            <div class="sh2 text-gray-1000 mb-1">
              Drivers for {{ topMetric().label }}
            </div>
            <div class="c1 text-gray-800 mb-3">
              Comparison period: {{ comparisonPeriod() }}
            </div>
            <portal-context-driver-display
              [drivers]="drivers()"></portal-context-driver-display>
          </m-card>
        </div>
      </div>
    </m-card>
  }
</m-fade-in-up>
