import { Component, ElementRef, Input } from '@angular/core';
import { Button } from 'primeng/button';
import { ButtonType } from './m-button.component.types';

@Component({
  selector: 'm-button',
  templateUrl: './m-button.component.html',
  styleUrls: ['./m-button.component.scss']
})
export class MButtonComponent extends Button {
  /*
   * keyof typeof ButtonType makes it necessary to create new button types with enum variable name and value to be exactly the same!
   */
  @Input() override type: ButtonType | keyof typeof ButtonType =
    ButtonType.primary;

  constructor(el: ElementRef) {
    super(el);
  }
}
