/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@portal/environments/environment';
import { SelectionService } from '@portal/app/shared/services/selection.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigSwitchService {
  private readonly measuredUriV2 = `${environment.apiDomain}/api/v2/`;

  constructor(
    private readonly http: HttpClient,
    private readonly selectionService: SelectionService // Assuming SelectionService exists to fetch client and brand IDs
  ) {}

  switchToMimV2(): Observable<any> {
    // Retrieve client and brand IDs from SelectionService
    const { clientId, brandId } = this.selectionService.getClientIdAndBrandId();

    // Construct the URL with query parameters
    const url = `${this.measuredUriV2}dataviz/config/switch-to-mim-v2?clientId=${clientId}&brandId=${brandId}`;

    // Make the POST request
    return this.http.post(url, {});
  }
}
