import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from '@portal/app/auth/auth-routing.module';
import { LoginComponent } from '@portal/app/auth/login/login.component';
import { AuthService } from '@portal/app/shared/services/auth.service';
import { StorageService } from '@portal/app/shared/services/storage.service';
import { SharedModule } from '@portal/app/shared/shared.module';
import { CommonServicesModule } from '@libs/common-services';
import { environment } from '@portal/environments/environment';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { DesignSystemModule } from '@libs/design-system';

@NgModule({
  imports: [
    CommonModule,
    AuthRoutingModule,
    SharedModule,
    CommonServicesModule.forRoot(environment),
    MessagesModule,
    ToastModule,
    DesignSystemModule
  ],
  declarations: [LoginComponent],
  providers: [AuthService, StorageService, MessageService]
})
export class AuthModule {}
