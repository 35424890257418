import { IPTableColumnInfo, ITitle, TitleTypes } from '@libs/common-components';
import { ButtonType } from '@design-system/components/m-button';
import { ITabPanelHeader } from '@design-system/components/m-tab-view';
import { IBottomPanelButtonConfig } from '@design-system/components/m-bottom-panel';
import {
  ICampaignFilterContext,
  TaxonomyDateRange,
  TaxonomyDimension
} from '../../models/ICampaignFilterContext';
import { ITaxonomyTabFilterConfig } from '../../models/ITaxonomyTabFilterConfig';
import { ReviewAction } from '../../models/ReviewAction';
import { TaxonomyTab } from '../../models/TaxonomyTab';
import { IFilterData } from '@design-system/components/m-text-field';

export class Constants {
  public static readonly dimensionWidthMap: Record<string, string> = {
    campaign: '22rem',
    adsetName: '22rem',
    accountName: '14rem',
    tactic: '15rem',
    channel: '12rem',
    segment: '12rem',
    spend: '10rem',
    modifiedBy: '10rem',
    undo: '5rem'
  };

  public static readonly tabPanelHeaders: ITabPanelHeader[] = [
    {
      header: 'Unmapped',
      showCount: true,
      count: 0,
      disabled: false,
      field: TaxonomyTab.unmapped
    },
    {
      header: 'In Progress',
      showCount: true,
      count: 0,
      disabled: false,
      field: TaxonomyTab.underProcessing
    },
    {
      header: 'Mapped',
      showCount: true,
      count: 0,
      disabled: false,
      field: TaxonomyTab.mapped
    }
  ];

  public static readonly defaultDataSourceCampaignHeaders = [
    {
      field: 'campaign',
      header: 'Campaign',
      width: '22rem !important',
      isFilterEnabled: true
    },
    /* {
      field: 'adsetName',
      header: 'Ad Set',
      width: '22rem !important',
      isFilterEnabled: true
    }, */
    {
      field: 'accountName',
      header: 'Account Name',
      width: '22rem !important',
      isFilterEnabled: true
    }
  ];

  public static readonly defaultCampaignHeadersByDimension: Record<
    TaxonomyDimension,
    IPTableColumnInfo[]
  > = {
    [TaxonomyDimension.compositeDataSourceName]: [
      {
        field: 'tactic',
        header: 'Tactic',
        width: '12rem !important',
        isFilterEnabled: true
      },
      {
        field: 'channel',
        header: 'Channel',
        width: '8rem !important',
        isFilterEnabled: true
      },
      {
        field: 'segment',
        header: 'Segment',
        width: '10rem !important',
        isFilterEnabled: true
      },
      {
        field: 'spend',
        header: 'Spend',
        width: '7rem !important'
      },
      {
        field: 'modifiedBy',
        header: 'Modified',
        width: '8rem !important',
        type: 'badge',
        styleClass: 'text-center justify-center'
      }
    ],
    [TaxonomyDimension.tactic]: [
      {
        field: 'campaign',
        header: 'Campaign',
        width: '22rem !important',
        isFilterEnabled: true
      },
      {
        field: 'adsetName',
        header: 'Ad Set',
        width: '22rem !important',
        isFilterEnabled: true
      },
      {
        field: 'accountName',
        header: 'Ad Account',
        width: '22rem !important',
        isFilterEnabled: true
      },
      {
        field: 'compositeDataSourceName',
        header: 'Data Source',
        width: '12rem !important'
      },
      {
        field: 'channel',
        header: 'Channel',
        width: '8rem !important',
        isFilterEnabled: true
      },
      {
        field: 'segment',
        header: 'Segment',
        width: '10rem !important',
        isFilterEnabled: true
      },
      {
        field: 'spend',
        header: 'Spend',
        width: '7rem !important'
      },
      {
        field: 'modifiedBy',
        header: 'Modified',
        width: '8rem !important',
        type: 'badge',
        styleClass: 'text-center justify-center'
      }
    ],
    [TaxonomyDimension.channel]: [
      {
        field: 'campaign',
        header: 'Campaign',
        width: '22rem !important',
        isFilterEnabled: true
      },
      {
        field: 'adsetName',
        header: 'Ad Set',
        width: '22rem !important',
        isFilterEnabled: true
      },
      {
        field: 'accountName',
        header: 'Ad Account',
        width: '22rem !important',
        isFilterEnabled: true
      },
      {
        field: 'compositeDataSourceName',
        header: 'Data Source',
        width: '12rem !important',
        isFilterEnabled: true
      },
      {
        field: 'tactic',
        header: 'Tactic',
        width: '12rem !important',
        isFilterEnabled: true
      },
      {
        field: 'segment',
        header: 'Segment',
        width: '10rem !important',
        isFilterEnabled: true
      },
      {
        field: 'spend',
        header: 'Spend',
        width: '7rem !important'
      },
      {
        field: 'modifiedBy',
        header: 'Modified',
        width: '8rem !important',
        type: 'badge',
        styleClass: 'text-center justify-center'
      }
    ],
    [TaxonomyDimension.segment]: [
      {
        field: 'campaign',
        header: 'Campaign',
        width: '22rem !important',
        isFilterEnabled: true
      },
      {
        field: 'adsetName',
        header: 'Ad Set',
        width: '22rem !important',
        isFilterEnabled: true
      },
      {
        field: 'accountName',
        header: 'Ad Account',
        width: '22rem !important',
        isFilterEnabled: true
      },
      {
        field: 'compositeDataSourceName',
        header: 'Data Source',
        width: '12rem !important',
        isFilterEnabled: true
      },
      {
        field: 'tactic',
        header: 'Tactic',
        width: '12rem !important',
        isFilterEnabled: true
      },
      {
        field: 'channel',
        header: 'Channel',
        width: '8rem !important',
        isFilterEnabled: true
      },
      {
        field: 'spend',
        header: 'Spend',
        width: '7rem !important'
      },
      {
        field: 'modifiedBy',
        header: 'Modified',
        width: '8rem !important',
        type: 'badge',
        styleClass: 'text-center justify-center'
      }
    ]
  };

  public static readonly campaignViewHeaders: IPTableColumnInfo[] = [
    {
      field: 'tactic',
      header: 'Tactic',
      width: '12rem !important',
      isFilterEnabled: true
    },
    {
      field: 'channel',
      header: 'Channel',
      width: '10rem !important',
      isFilterEnabled: true
    },
    {
      field: 'segment',
      header: 'Segment',
      width: '10rem !important',
      isFilterEnabled: true
    },
    {
      field: 'spend',
      header: 'Spend',
      width: '7rem !important'
    },
    {
      field: 'modifiedBy',
      header: 'Modified',
      width: '8rem !important',
      type: 'badge',
      styleClass: 'text-center justify-center'
    }
  ];

  public static readonly dateRangeOptions: {
    label: string;
    value: string;
  }[] = [
    {
      label: 'All',
      value: TaxonomyDateRange.all
    },
    {
      label: 'Last 3 days',
      value: TaxonomyDateRange.last3Days
    },
    {
      label: 'Last 7 days',
      value: TaxonomyDateRange.last7Days
    },
    {
      label: 'Last 14 days',
      value: TaxonomyDateRange.last14Days
    },
    {
      label: 'Last 30 days',
      value: TaxonomyDateRange.last30Days
    },
    {
      label: 'Last 90 days',
      value: TaxonomyDateRange.last90Days
    },
    {
      label: 'Last 52 weeks',
      value: TaxonomyDateRange.last52Weeks
    },
    {
      label: 'Current month (MTD)',
      value: TaxonomyDateRange.mtd
    }
  ];

  public static readonly groupByDimensions = [
    {
      label: 'Data source',
      value: TaxonomyDimension.compositeDataSourceName
    },
    {
      label: 'Tactic',
      value: TaxonomyDimension.tactic
    },
    {
      label: 'Channel',
      value: TaxonomyDimension.channel
    },
    {
      label: 'Segment',
      value: TaxonomyDimension.segment
    }
  ];

  public static readonly taxonomyTabConfig: Record<
    TaxonomyTab,
    ITaxonomyTabFilterConfig
  > = {
    [TaxonomyTab.unmapped]: {
      isDateRangeEnabled: true,
      isDimensionSelectorEnabled: false,
      isColumnSelectorEnabled: true
    },
    [TaxonomyTab.underProcessing]: {
      isDateRangeEnabled: false,
      isDimensionSelectorEnabled: true,
      isColumnSelectorEnabled: true
    },
    [TaxonomyTab.mapped]: {
      isDateRangeEnabled: false,
      isDimensionSelectorEnabled: true,
      isColumnSelectorEnabled: true
    }
  };

  public static readonly reviewOpenActionButtons: IBottomPanelButtonConfig[] = [
    {
      label: 'Submit Review',
      type: ButtonType.primary,
      id: ReviewAction.submitReview,
      tooltip: 'Submit tactic changes'
    },
    {
      label: 'Go back',
      type: ButtonType.secondary,
      id: ReviewAction.closeReview,
      tooltip: ''
    }
  ];

  public static readonly reviewOpenNavigationButtons: IBottomPanelButtonConfig[] =
    [
      {
        label: 'Remove all',
        type: ButtonType.secondary,
        id: ReviewAction.removeAll,
        tooltip: ''
      }
    ];

  public static readonly reviewCloseNavigationButtons: IBottomPanelButtonConfig[] =
    [
      {
        label: 'Undo',
        type: ButtonType.secondary,
        id: ReviewAction.undo,
        disabled: true,
        tooltip: ''
      }
    ];

  public static readonly reviewActionButtons: IBottomPanelButtonConfig[] = [
    {
      label: 'Review',
      type: ButtonType.primary,
      id: ReviewAction.openReview,
      tooltip: 'Review these campaigns'
    },
    {
      label: 'Cancel',
      type: ButtonType.secondary,
      id: ReviewAction.closeReview,
      tooltip: 'Cancel'
    }
  ];

  public static readonly unpublishedCampaignTitleConfigs: ITitle[] = [
    {
      title: 'Review and Submit Campaigns',
      type: TitleTypes.h4,
      styleClass: 'h4 text-gray-1000',
      subTitle: null
    },
    {
      title:
        'The campaigns you selected for review are listed below. Once submitted, allow 2-3 hours for changes to take effect.',
      subTitle: null,
      type: TitleTypes.info,
      styleClass: 'b1 text-gray-800'
    }
  ];

  public static readonly tabWiseNoDataMessages: Record<
    TaxonomyTab,
    { title: string; description: string }
  > = {
    [TaxonomyTab.unmapped]: {
      title: 'No unmapped campaigns',
      description:
        'All done! Review any modified campaigns by clicking the "Review All Campaigns" button below and submit them.'
    },
    [TaxonomyTab.underProcessing]: {
      title: 'No in progress campaigns',
      description: 'Check the unmapped tab to map those campaigns, if any.'
    },
    [TaxonomyTab.mapped]: {
      title: 'No mapped campaigns',
      description:
        'Check the unmapped tab to review those campaigns and map them.'
    }
  };

  public static readonly impactColumn: IPTableColumnInfo = {
    field: 'impact',
    header: 'Impact',
    width: '8rem !important',
    isFilterEnabled: false
  };

  public static readonly defaultFilterContext: ICampaignFilterContext = {
    dateRange: TaxonomyDateRange.last30Days,
    groupByDimension: TaxonomyDimension.compositeDataSourceName
  };

  public static readonly defaultFilterState: IFilterData = {
    contain: [],
    exclude: []
  };

  public static readonly tempRecordId = 'tempRecordId';

  public static readonly loadPreviousText = 'Load Previous';
  public static readonly loadNextText = 'Load Next';
  public static readonly loadingText = 'Loading...';
}
