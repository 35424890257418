import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IntegrationStoreService {
  private readonly vendorConnectionErrorStub: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);

  get error(): string | null {
    return this.vendorConnectionErrorStub.getValue();
  }

  setError(error: string | null) {
    this.vendorConnectionErrorStub.next(error);
  }
}
