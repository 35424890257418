export enum Status {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PAUSED = 'PAUSED',
  DISABLED = 'DISABLED',
  DRAFT = 'DRAFT',
  OV2 = 'OV2',
  READY = 'READY',
  ABANDONED = 'ABANDONED',
  SUCCESS = 'SUCCESS',
  UPLOADED = 'UPLOADED'
}
