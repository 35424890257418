<p-dropdown
  #dropDown
  class="m-dropdown"
  [(ngModel)]="value"
  [options]="options"
  [style.width]="width"
  [optionValue]="optionValue"
  [optionLabel]="optionLabel"
  [optionDisabled]="optionDisabled"
  [disabled]="disabled || false"
  [placeholder]="placeholder()"
  [appendTo]="appendTo"
  [resetFilterOnHide]="resetFilterOnHide"
  (onChange)="onChangeRelay($event)"
  [group]="group"
  [filter]="filter"
  [filterBy]="filterBy"
  [filterPlaceholder]="filterPlaceholder"
  [styleClass]="styleClass"
  [panelStyleClass]="panelStyleClass + ' m-dropdown-panel'"
  (formControlChange)="onModelChange($event)">
</p-dropdown>
