import { IVendorConfigurationRequest } from '@portal/app/dashboard/integrations/models/vendor/IVendorConfigurationRequest';
import { IVendorInitiateRequest } from '@portal/app/dashboard/integrations/models/vendor/IVendorInitiateRequest';
import { IVendorOAuthConfirmationRequest } from '@portal/app/dashboard/integrations/models/vendor/IVendorOAuthConfirmationRequest';
import { IVendorReConfigurationRequest } from '@portal/app/dashboard/integrations/models/vendor/IVendorReConfigurationRequest';
import {
  IVendorConnectConfirmation,
  IVendorConnectInitResponse
} from '@portal/app/dashboard/integrations/models/vendor/VendorIntegrationModels';
import { VendorIdSource } from '@portal/app/dashboard/integrations/shared/constants';
import { VendorHandler } from '@portal/app/dashboard/integrations/shared/vendor-handlers/vendor-handler';
import { map } from 'lodash-es';

export class CJHandler implements VendorHandler {
  prepareRequestObjectToInitiateVendor(): IVendorInitiateRequest {
    return {
      queryParams: {},
      pathParams: { vendorId: VendorIdSource.cj }
    };
  }

  handleVendorInitiateResponse(data: IVendorConnectInitResponse): void {
    /* eslint-disable no-console */
    console.log(`${VendorIdSource.cj} initiation handler: ${data}`);
    this.confirmVendor();
  }

  confirmVendor(): IVendorConnectConfirmation {
    throw new Error('Method not implemented.');
  }

  prepareRequestObjectToConfigureVendor(
    accounts: {
      accountName: string;
      accountId: string;
      managerId?: string;
      disabled: boolean;
    }[]
  ): IVendorConfigurationRequest {
    return {
      vendorId: VendorIdSource.cj,
      vendorData: map(accounts, (account) => ({
        clientAccountId: account.accountId
      }))
    };
  }

  prepareRequestToConfirmVendor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    confirmationData: Record<string, any>
  ): IVendorOAuthConfirmationRequest {
    return {
      userId: confirmationData.userId,
      userSecret: confirmationData.userSecret
    };
  }

  prepareRequestObjectToReConfigureVendor(
    accounts: {
      accountName: string;
      accountId: string;
      managerId?: string | undefined;
      disabled: boolean;
    }[],
    dsInstanceIds: number[]
  ): IVendorReConfigurationRequest {
    return {
      vendorId: VendorIdSource.cj,
      vendorData: map(accounts, (account) => ({
        clientAccountId: account.accountId
      })),
      dsInstanceIds
    };
  }
}
