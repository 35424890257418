@if (isLoading) {
  <div class="col-span-1 h-100">
    <m-skeleton height="240px" width="100%"></m-skeleton>
  </div>
}

<m-panel
  [toggleable]="true"
  [collapsed]="isCollapsed"
  (collapsedChange)="handleExpandChange($event)"
  *ngIf="!isLoading"
  styleClass="hover:bg-blue-100 transition-all duration-300">
  <!-- Start Panel Header -->
  <div header>
    <div class="flex justify-between items-center w-full">
      <span class="sh2 text-gray-1000">Recommended Optimization</span>
    </div>

    <!-- Additional header content -->
    <div class="sh1 text-gray-900 mt-3">{{ data?.name }}</div>
    <div class="c1 text-gray-800">
      Current Plan | {{ relativeDayFilter?.value || "Last 30 Days" }}
    </div>

    <div
      *ngIf="isDataLoading; else panelContent"
      class="grid gap-4 grid-cols-12 mt-3">
      <!-- First Column -->
      <div class="col-span-6">
        <m-skeleton height="100px" width="100%"></m-skeleton>
      </div>
      <!-- Second Column -->
      <div class="col-span-6">
        <m-skeleton height="100px" width="100%"></m-skeleton>
      </div>
    </div>

    <ng-template #panelContent>
      <div class="grid gap-4 grid-cols-12 mt-3">
        <!-- First Column -->
        <div class="col-span-6">
          <m-card variant="small">
            <div class="c1 text-gray-800">Sales Net Gain</div>
            <div class="sh2 text-gray-1000">
              {{ data?.totalOptNetSalesGain }}
            </div>
            <m-tag [isPositive]="true" [isUp]="true" [showBackground]="false">
              {{ data?.totalOptNetSalesGainPerc }}
            </m-tag>
          </m-card>
        </div>

        <!-- Second Column -->
        <div class="col-span-6">
          <m-card variant="small">
            <div class="c1 text-gray-800">Incremental ROAS</div>
            <div class="sh2 text-gray-1000">{{ data?.totalOptRoasI }}</div>
            <div class="c2 text-gray-600">
              {{ data?.totalCurRoasI }} (Current)
            </div>
          </m-card>
        </div>
      </div>
    </ng-template>
  </div>
  <!-- End Panel Header -->

  <!-- Start Panel Body (always visible) -->
  <div>
    <div class="mt-4">
      <div class="sh1 text-gray-900">Recommended Spending</div>

      <div *ngIf="isDataLoading; else tacticsList">
        <table class="mt-3" aria-hidden="true">
          <tbody>
            <tr *ngFor="let row of [1, 2, 3, 4]" class="d-block pb-1">
              <td class="w-[8.25rem]">
                <m-skeleton height="1.2rem" width="100%"></m-skeleton>
              </td>
              <td class="w-[4rem] px-2">
                <m-skeleton height="1.2rem" width="100%"></m-skeleton>
              </td>
              <td class="w-[4.3rem]">
                <m-skeleton height="1.2rem" width="100%"></m-skeleton>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <ng-template #tacticsList>
        <table class="mt-3">
          <caption></caption>
          <thead class="c3 text-gray-1000 border-b-8 border-transparent">
            <tr>
              <th scope="col" class="w-[8.25rem]">Tactic</th>
              <th scope="col" class="w-[4rem] px-2 text-nowrap">Ref. Spend</th>
              <th scope="col" class="w-[4.3rem]">Additional</th>
            </tr>
          </thead>
          <tbody class="c2">
            <tr *ngFor="let item of data?.tactics">
              <m-tooltip
                [content]="item.name"
                position="bottom"
                [tooltipOptions]="{ showDelay: 800 }">
                <td
                  class="d-inline-block overflow-hidden text-truncate text-nowrap w-[8.25rem] text-gray-800">
                  {{ item.name }}
                </td>
              </m-tooltip>
              <td class="w-[4rem] px-2 text-gray-800">
                {{ item.currentBudget }}
              </td>
              <td class="text-green-700 w-[4.3rem]">
                {{ "+" + item.additionalSpend }}
              </td>
            </tr>
          </tbody>
        </table>
      </ng-template>
    </div>
  </div>
  <!-- End Panel Body -->

  <!-- Start Expandable Footer (collapsed by default) -->
  <div footer>
    <div class="mt-4">
      <div class="sh1 text-gray-1000">Adjust Optimization</div>
      <m-slider-step
        [initialValue]="optimizeStrengthFilterIndex"
        [steps]="steps"
        (click)="$event.stopPropagation()"
        (stepChange)="onStepChange($event)">
      </m-slider-step>
    </div>

    <m-button
      (click)="navigateToPage()"
      type="secondary"
      styleClass="w-full mt-4">
      <div class="b2 text-blue-600 w-full text-center">
        View full recommendation
      </div>
    </m-button>
  </div>
  <!-- End Expandable Footer -->
</m-panel>
