import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartComponent } from '@portal/app/charts/chart.component';
import { ChartModule } from 'angular-highcharts';
import { FormsModule } from '@angular/forms';
import { PrimengModule } from '@portal/app/charts/primeng.module';
import { SharedModule } from '@portal/app/shared/shared.module';
import { DataGridModule } from '@portal/app/datagrid/data-grid.module';
import { ChartGroupComponent } from './chart-group/chart-group.component';
import { GeoExperimentResultChartGroupTableComponent } from '@portal/app/charts/geo-experiment-result/geo-experiment-result-chart-group-table/geo-experiment-result-chart-group-table.component';
import { CommonServicesModule } from '@libs/common-services';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [
    ChartComponent,
    ChartGroupComponent,
    GeoExperimentResultChartGroupTableComponent
  ],
  exports: [
    ChartComponent,
    ChartGroupComponent,
    GeoExperimentResultChartGroupTableComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ChartModule,
    PrimengModule,
    SharedModule,
    DataGridModule,
    CommonServicesModule,
    TooltipModule
  ]
})
export class ChartsModule {}
