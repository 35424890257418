<m-card>
  <div
    header
    class="flex flex-col lg:flex-row justify-between items-start 2xl:items-center w-full">
    <div class="flex items-center">
      <span class="sh2 mr-2 mt-2">Total</span>

      <m-dropdown
        class="mr-2 mt-2"
        [options]="totalByOptions"
        [(ngModel)]="totalByValue"
        (formControlChange)="onTotalByOptionsChange($event?.value)"
        [disabled]="isLoading"></m-dropdown>
    </div>
    <div class="flex items-center">
      <span class="sh2 mr-2 mt-2">Starting</span>

      <m-dropdown
        class="mr-2 mt-2 min-w-[200px] 2xl:min-w-0"
        [options]="startDateOptions"
        [(ngModel)]="startDateValue"
        (formControlChange)="onStartDateChange($event?.value)"
        [disabled]="isLoading"></m-dropdown>
    </div>
  </div>
  <div class="mt-4">
    <!-- Loading state -->
    @if (!isChartReady && isLoading) {
      <m-skeleton width="100%" height="300px"></m-skeleton>
    }
    @if (!isChartReady && isLoading) {
      <div class="mt-3 flex flex-wrap justify-center gap-2">
        @for (item of [].constructor(2); track item; let i = $index) {
          <m-skeleton width="6rem" height="1rem"></m-skeleton>
        }
      </div>
    }

    <!-- Error state -->
    @if (!isLoading && !isDataAvailable) {
      <m-not-available></m-not-available>
    }
    @if (isChartReady && isDataAvailable) {
      <div>
        @if (isChartReady && isDataAvailable) {
          <m-stacked-column-chart
            class="w-full"
            [series]="stackedColumnChartOptions?.series"
            [xAxis]="stackedColumnChartOptions?.xAxis"
            [yAxis]="stackedColumnChartOptions?.yAxis"
            [legend]="stackedColumnChartOptions?.legend"
            [tooltip]="stackedColumnChartOptions?.tooltip"
            [plotOptions]="stackedColumnChartOptions?.plotOptions"
            [minWidth]="minWidthValue"></m-stacked-column-chart>
        }
        <div class="flex justify-center w-full mt-2 lh-1">
          <m-tooltip
            content="<div class='b1 text-gray-000 text-center'>For {{
              lastMonth
            }}</div>"
            position="top"
            [tooltipOptions]="{
              positionTop: -10,
            }">
            <span class="c3 text-gray-1000"
              >New Customers: {{ newTotalvalue }}</span
            >
          </m-tooltip>
          <p-divider layout="vertical"></p-divider>
          <m-tooltip
            content="<div class='b1 text-gray-000 text-center'>Change ({{
              lastMonth
            }} vs {{ previousToLastMonth }})</div>"
            position="top"
            [tooltipOptions]="{
              positionTop: -10,
              positionLeft: 8,
            }">
            <m-tag
              [isPositive]="isPercentUp"
              [isUp]="isPercentUp"
              [showBackground]="false">
              {{ newPercentValue }}
            </m-tag>
          </m-tooltip>
        </div>
      </div>
    }
  </div>
</m-card>
