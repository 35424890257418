import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IReportingIncrementalitySettingsApiOperation } from '../models/IReportingIncrementalitySettingsApiOperation';
import { Utility } from '../helpers/utility';
import { SheetInfoResponse } from '../models/SheetInfoResponse';
import { SheetDataResponse } from '../models/SheetDataResponse';
import { SheetDataValidation } from '../models/SheetDataValidation';
import { IDDIStatus } from '../models/IDDIStatus';

const promiseFrom = lastValueFrom;

export class ReportingIncrementalitySettingsApis
  implements IReportingIncrementalitySettingsApiOperation
{
  private constructor(
    private readonly httpClient: HttpClient,
    private readonly apiV1: string,
    private readonly apiV2: string
  ) {}

  public static reportingIncrementalitySettingsApiOperation(
    httpClient: HttpClient,
    apiV1: string,
    apiV2: string
  ): IReportingIncrementalitySettingsApiOperation {
    return new ReportingIncrementalitySettingsApis(httpClient, apiV1, apiV2);
  }

  public async getIncrementalitySettingsDetailsV2(incId: number): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/incrementality-settings/:incId`,
      pathVariables: { incId }
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async addIncrementalitySettingsDetailsV2(
    clientId: number,
    data: any
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/incrementality-settings`,
      queryParameters: { clientId }
    });
    return promiseFrom(this.httpClient.post(url, data));
  }

  public async updateIncrementalitySettingsDetailsV2(
    incId: number,
    clientId: number,
    data: any
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/incrementality-settings/:incId`,
      pathVariables: { incId },
      queryParameters: { clientId }
    });
    return promiseFrom(this.httpClient.put(url, data));
  }

  public async getIncrementalitySettingsListV2(clientId: number): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/incrementality-settings`,
      queryParameters: { clientId }
    });
    return promiseFrom(this.httpClient.get(url));
  }

  public async getSheetInfoV2(clientId: number): Promise<SheetInfoResponse> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/incrementality-settings/sheet-info`
    });
    const options = { params: { clientId } };
    return promiseFrom(this.httpClient.get<SheetInfoResponse>(url, options));
  }

  public async getSheetDataV2(clientId: number): Promise<SheetDataResponse> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/incrementality-settings/csv`
    });
    const options = { params: { clientId } };
    return promiseFrom(this.httpClient.get<SheetDataResponse>(url, options));
  }

  public async validateSheetDataV2(
    clientId: number,
    sheetName: string
  ): Promise<SheetDataValidation> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/incrementality-settings/bulk-upload/validate`,
      queryParameters: {
        clientId,
        sheetName
      }
    });
    const options = '';
    return promiseFrom(this.httpClient.post<SheetDataValidation>(url, options));
  }

  public async bulkUploadSheetDataV2(
    clientId: number,
    sheetName: string
  ): Promise<any> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/incrementality-settings/bulk-upload`,
      queryParameters: {
        clientId,
        sheetName
      }
    });
    const options = '';
    return promiseFrom(this.httpClient.post(url, options));
  }

  public async getDDIStatus(clientId: number): Promise<IDDIStatus> {
    const url = Utility.buildURL({
      url: `${this.apiV2}/incrementality-settings/ddi-status`,
      queryParameters: { clientId }
    });
    return promiseFrom(this.httpClient.get<IDDIStatus>(url));
  }
}
