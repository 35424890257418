import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { SelectItem } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';

// Import services
import { DimensionChartService } from '@portal/app/dashboard/home-page/components/dimension-chart/dimension-chart.service';
import { HomepageService } from '@portal/app/dashboard/home-page/services/home-page.service';

// Types
import type { Metric } from '@portal/app/dashboard/home-page/services/metrics.service';
import type { Options } from 'highcharts';

@Component({
  selector: 'portal-dimension-chart',
  templateUrl: './dimension-chart.component.html'
})
export class DimensionChartComponent implements OnInit, OnDestroy {
  @Input() activeChannel?: string;
  @Input() activeMetric?: Metric;
  private destroy$ = new Subject<void>();
  dimensions = 'channel';
  dimensionsOptions: SelectItem[] = [
    { label: 'By Channel', value: 'channel' },
    { label: 'By Tactic', value: 'tactic' }
  ];

  horizontalBarChartOptions: Options = {};
  isDataAvailable = true;
  isLoading = true;

  constructor(
    private dimensionChartService: DimensionChartService,
    private homepageService: HomepageService
  ) {}

  get isChartReady(): boolean {
    return (
      !this.isLoading &&
      !!this.horizontalBarChartOptions?.legend &&
      !!this.horizontalBarChartOptions?.plotOptions &&
      !!this.horizontalBarChartOptions?.series &&
      !!this.horizontalBarChartOptions?.tooltip &&
      !!this.horizontalBarChartOptions?.xAxis &&
      !!this.horizontalBarChartOptions?.yAxis &&
      !!this.horizontalBarChartOptions?.title
    );
  }

  ngOnInit() {
    this.subscribeToData();
  }

  subscribeToData() {
    this.dimensionChartService.chartData$
      .pipe(takeUntil(this.destroy$))
      .subscribe((chartData) => {
        if (chartData) {
          this.horizontalBarChartOptions = chartData;
          this.isDataAvailable =
            this.homepageService.checkChartDataAvailability(chartData);
        }
      });

    this.dimensionChartService.isLoading$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoading) => (this.isLoading = isLoading));

    this.dimensionChartService.dimensions$
      .pipe(takeUntil(this.destroy$))
      .subscribe((dimensions) => (this.dimensions = dimensions[0] as string));
  }

  onDimensionsChange(newValue: string) {
    this.dimensionChartService.setDimensions(newValue);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
