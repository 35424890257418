/* eslint-disable @typescript-eslint/no-explicit-any */
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  NgZone,
  Output,
  PLATFORM_ID,
  Renderer2
} from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { Carousel } from 'primeng/carousel';

@Component({
  selector: 'm-benchmarks-carousel',
  templateUrl: './m-benchmarks-carousel.component.html',
  styleUrls: ['./m-benchmarks-carousel.component.scss']
})
export class MCarouselComponent extends Carousel {
  constructor(
    el: ElementRef,
    zone: NgZone,
    cd: ChangeDetectorRef,
    renderer: Renderer2,
    @Inject(DOCUMENT) document: Document,
    @Inject(PLATFORM_ID) platformId: any,
    config: PrimeNGConfig
  ) {
    super(el, zone, cd, renderer, document, platformId, config);
  }

  @Output() navigateRequest = new EventEmitter<void>();

  onButtonClick(): void {
    this.navigateRequest.emit();
  }
}
