import { Component, OnInit } from '@angular/core';
import { IHeaderConfig, ITitle } from '@libs/common-components';
import {
  IShopifyDashboardColumnDef,
  IShopifyDashboardData
} from '@portal/app/dashboard/samples/shopify/shopify-dashboard.models';
import { dummyData } from '@portal/app/dashboard/samples/shopify/shopify-dashboard.stubs';
import { ShopifyDashboardConstants } from '@portal/app/dashboard/samples/shopify/shopify-dashboard.constants';
import { ApiService, DataEndpoint } from '@libs/apis';
import { SelectionService } from '@portal/app/shared/services/selection.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Component({
  selector: 'portal-shopify-dashboard',
  templateUrl: './shopify-dashboard.component.html',
  styleUrls: ['./shopify-dashboard.component.scss']
})
export class ShopifyDashboardComponent implements OnInit {
  headerConfig: IHeaderConfig = ShopifyDashboardConstants.headerConfig;
  tableTitleConfigs: ITitle[] = ShopifyDashboardConstants.tableTitleConfigs;
  dashboardColumns: IShopifyDashboardColumnDef[] =
    ShopifyDashboardConstants.tableColumns;

  loading = new BehaviorSubject<boolean>(false);
  noData = new BehaviorSubject<boolean>(false);
  error = new BehaviorSubject<boolean>(false);
  dashboardData = dummyData;

  constructor(
    private readonly selectionService: SelectionService,
    private readonly apiService: ApiService
  ) {}

  ngOnInit(): void {
    this.loading.next(true);
    const brand = this.selectionService.getSelection();
    this.apiService.nativeFrameworkApis
      .getSampleDashboardData('shopify-dashboard', DataEndpoint.dataSets, {
        clientId: brand.brand?.clientId || 10077,
        brandId: brand.brand?.brandId || 1100,
        dashboard: 'shopify-dashboard',
        product: 'shopify',
        dashboardId: 'shopify-dashboard',
        filter: {}
      })
      .then((response) => {
        if (response && response.data && response.data.length) {
          this.dashboardData =
            response.data as unknown as IShopifyDashboardData[];
          this.loading.next(false);
        } else {
          this.loading.next(false);
          this.noData.next(true);
        }
      })
      .catch((error) => {
        console.error('failed to get shopify data, cause: ', error);
        this.error.next(true);
      });
  }
}
