import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SelectItem } from 'primeng/api/selectitem';
import {
  CurrentDropdownOption,
  DayJsDateFormat,
  DateTimeService
} from '@portal/app/shared/services/date-time.service';
import { DateRange, DateRangeData } from '@portal/app/shared/types';
import { FilterBarService } from '@portal/app/dashboard/notification-center/components/notification-center-filter/notification-center-filter.service';
import { NotificationCenterService } from '@portal/app/dashboard/notification-center/notification-center.service';
import { Subject, takeUntil } from 'rxjs';
import { isArray } from 'lodash-es';
import { FilterSetUtil } from '@portal/app/dashboard/filter-set/filter-set-util';
import { IFilterChange } from '@design-system/components/m-alert-item/m-alert-item.component.types';
import dayjs from 'dayjs';

@Component({
  selector: 'portal-notification-center-filters',
  templateUrl: './notification-center-filter.component.html',
  styleUrls: ['./notification-center-filter.component.scss']
})
export class NotificationCenterFilterComponent implements OnInit {
  @Output() filterChange: EventEmitter<IFilterChange> =
    new EventEmitter<IFilterChange>();

  errorMetrics = false;
  serviceOptions: SelectItem[] = [];
  selectedServices: string[] = [];
  searchValue = '';
  timePeriodOptions: CurrentDropdownOption[] =
    this.filterBarService.getTimePeriodOptions();

  selectedTimePeriod: DateRange = this.filterBarService.getDefaultTimePeriod();
  private destroy$ = new Subject<void>();
  maxDate = dayjs();

  constructor(
    private notificationCenterService: NotificationCenterService,
    private filterBarService: FilterBarService,
    private dateTimeService: DateTimeService
  ) {}

  ngOnInit(): void {
    this.notificationCenterService.alertTagOptions$
      .pipe(takeUntil(this.destroy$))
      .subscribe((alertTagData) => {
        if (alertTagData) {
          this.selectedServices = alertTagData;
          this.serviceOptions =
            this.filterBarService.getServiceOptions(alertTagData);
        }
      });
  }

  onSearch(event: Event): void {
    this.searchValue = (event.target as HTMLInputElement).value;
    this.filterChange.emit({
      search: this.searchValue,
      selectedServices: this.selectedServices,
      selectedDateRange: {
        startDate: this.selectedTimePeriod.startDate as unknown as string,
        endDate: this.selectedTimePeriod.endDate as unknown as string,
        custom: false
      }
    });
  }

  onServicesChange(items: string[]): void {
    this.errorMetrics = !items.length;
    this.selectedServices = items;
    this.filterChange.emit({
      search: this.searchValue,
      selectedServices: this.selectedServices,
      selectedDateRange: {
        startDate: this.selectedTimePeriod.startDate as unknown as string,
        endDate: this.selectedTimePeriod.endDate as unknown as string,
        custom: false
      }
    });
  }

  onDateChange(dateRangeData: DateRangeData[]) {
    const date = dateRangeData.find((obj) => obj.name === 'regularDate');
    const relativeDate = dateRangeData.find(
      (obj) => obj.name === 'relativeDate'
    );
    if (date && date.value && isArray(date.value) && date.value.length === 2) {
      const startDate = this.dateTimeService.format(
        date.value[0],
        DayJsDateFormat.fullDate
      );
      const endDate = this.dateTimeService.format(
        date.value[1],
        DayJsDateFormat.fullDate
      );
      this.filterChange.emit({
        search: this.searchValue,
        selectedServices: this.selectedServices,
        selectedDateRange: {
          startDate: startDate,
          endDate: endDate,
          custom:
            typeof relativeDate?.value === 'string' &&
            ['Custom', 'Yesterday'].includes(relativeDate.value)
        }
      });
      this.selectedTimePeriod = FilterSetUtil.setAsDateRange([
        startDate,
        endDate
      ]);
    }
  }
}
