import { Injectable } from '@angular/core';
import dayjs, { ManipulateType } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Weekdays } from '@portal/app/dashboard/geo-doe-config/shared/constants';
import { finalEndingDate, initialStartingDate } from '@libs/date-range-picker';
import { DateRange, FilterValue } from '@portal/app/shared/types';
import { monthDateYearFormat } from '@portal/app/shared/constants';

dayjs.extend(utc);

type CalendarUnit =
  | 'day'
  | 'week'
  | 'quarter'
  | 'month'
  | 'year'
  | 'hour'
  | 'minute'
  | 'second'
  | 'millisecond';

// noinspection JSUnusedGlobalSymbols
export enum Day {
  sunday = 0,
  monday = 1,
  tuesday = 2,
  wednesday = 3,
  thursday = 4,
  friday = 5,
  saturday = 6
}

enum DayJsUnit {
  day = 'day',
  days = 'days',
  week = 'week',
  weeks = 'weeks',
  month = 'month',
  year = 'year',
  quarter = 'quarter'
}

export enum DayJsDateFormat {
  fullDate = 'YYYY-MM-DD',
  nameOfDay = 'dddd'
}

type DayJsNativeType = string | number | Date | dayjs.Dayjs | null | undefined;

const zero = 0;
const one = 1;
const two = 2;
const three = 3;
const six = 6;
const thirteen = 13;
const twentyNine = 29;
const fiftyTwo = 52;
const ninty = 90;

export interface CurrentDropdownOption {
  name: string;
  action: {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
  } | null;
}

export const autoValue = 'Auto';
export const autoStartDate = 'Auto Start Date';
export const autoEndDate = 'Auto End Date';
export const allTime = 'All time';
export const todayDate = 'Today';
export const yesterday = 'Yesterday';
export const latestFourteenDays = 'Latest 14 Days';
export const latestSevenDays = 'Latest 7 Days';
export const lastCompleteWeek = 'Last Complete Week';
export const lastAvailableMonth = 'Last Available Month';
export const latestThirtyDays = 'Latest 30 Days';
export const latestFiftyTwoWeeks = 'Latest 52 Weeks';
export const currentMonth = 'Current Month (MTD)';
export const priorMonth = 'Prior Month';
export const currentQuarter = 'Current Quarter (QTD)';
export const currentYear = 'Current Year (YTD)';
export const custom = 'Custom';

export const previousPeriod = 'Previous Period';
export const previousMonth = 'Previous Month';
export const previousQuarter = 'Previous Quarter';
export const previousYear = 'Previous Year';
export const customCompare = 'Custom Compare';

export const latestNintyDays = 'Latest 90 Days';
export const allTimeForAlerts = 'All Time';

export const dayFilterLiteralIds = [
  'day',
  'processing_start_date',
  'processing_start_date_growth',
  'int_pulse_start_date'
];

@Injectable({
  providedIn: 'root'
})
export class DateTimeService {
  public settingsManagerOptions: CurrentDropdownOption[] = [
    {
      name: autoStartDate,
      action: this.calculateAutoStartDateRange()
    },
    {
      name: autoEndDate,
      action: this.calculateAutoEndDateRange()
    },
    {
      name: allTime,
      action: this.calculateAllTimeDateRange()
    }
  ];

  public currentRangeOptions: CurrentDropdownOption[] = [
    {
      name: latestSevenDays,
      action: this.calculatePrevious7Days()
    },
    {
      name: latestFourteenDays,
      action: this.calculatePrevious14Days()
    },
    {
      name: latestThirtyDays,
      action: this.calculatePrevious30Days()
    },
    {
      name: latestFiftyTwoWeeks,
      action: this.calculatePrevious52Weeks()
    },
    { name: currentMonth, action: this.calculateThisMonth() },
    { name: priorMonth, action: this.calculatePriorMonth() },
    {
      name: currentQuarter,
      action: this.calculateCurrentQuarter()
    },
    { name: currentYear, action: this.calculateCurrentYear() },
    { name: custom, action: null }
  ];

  public benchmarkRangeOptions: CurrentDropdownOption[] = [
    {
      name: lastCompleteWeek,
      action: this.calculateLastCompleteWeekForBenchmark()
    },
    {
      name: lastAvailableMonth,
      action: this.calculateLastAvailableMonthForBenchmark()
    },
    { name: custom, action: null }
  ];

  public static dayJsDate(date?: DayJsNativeType): dayjs.Dayjs {
    return dayjs(date).utc(true);
  }

  public static dayAfterTomorrow(isUTC = true): Date {
    return isUTC
      ? dayjs().utc(true).add(two, DayJsUnit.days).toDate()
      : dayjs().add(two, DayJsUnit.days).toDate();
  }

  public static yesterdayDate(): Date {
    return dayjs().utc(true).subtract(one, DayJsUnit.day).toDate();
  }

  public static isToday(date: DayJsNativeType): boolean {
    return dayjs(date).utc(true).isSame(dayjs().utc(true).endOf(DayJsUnit.day));
  }

  public static isAfterToday(date: DayJsNativeType): boolean {
    return dayjs(date)
      .utc(true)
      .isAfter(dayjs().utc(true).endOf(DayJsUnit.day));
  }

  public static isBeforeToday(date?: DayJsNativeType): boolean {
    return dayjs(date)
      .utc(true)
      .isBefore(dayjs().utc(true).startOf(DayJsUnit.day));
  }

  public static dayDuration(
    endDate: DayJsNativeType,
    startDate: DayJsNativeType
  ): number {
    return (
      Math.abs(
        dayjs(endDate)
          .utc(true)
          .diff(dayjs(startDate).utc(true), DayJsUnit.days)
      ) + one
    );
  }

  public static dayDurationWithoutUTC(
    endDate: DayJsNativeType,
    startDate: DayJsNativeType
  ): number {
    return (
      Math.abs(dayjs(endDate).diff(dayjs(startDate), DayJsUnit.days)) + one
    );
  }

  public static dateSince(date: DayJsNativeType, days: number): Date {
    return dayjs(date).utc(true).subtract(days, DayJsUnit.day).toDate();
  }

  public static dateSinceWithOutTimezone(
    date: DayJsNativeType,
    days: number
  ): Date {
    return dayjs(date).subtract(days, DayJsUnit.day).toDate();
  }

  public static dateAfter(date: DayJsNativeType, days: number): Date {
    return dayjs(date).utc(true).add(days, DayJsUnit.day).toDate();
  }

  public static dateString(
    date: DayJsNativeType,
    format: string | undefined
  ): string {
    return dayjs(date).utc(true).format(format);
  }

  public static dateStringWithoutTimeZone(
    date: DayJsNativeType,
    format: string | undefined
  ): string {
    let formattedDate = date;
    if (typeof date === 'object' && date != null) {
      formattedDate = date.toString().split('Z')[0];
    }
    return dayjs(formattedDate).format(format);
  }

  public static dateWithoutTimeZone(date: DayJsNativeType): Date {
    let formattedDate = date;
    if (typeof date === 'object' && date != null) {
      formattedDate = date.toString().split('Z')[0];
    }
    return dayjs(formattedDate).toDate();
  }

  public static nextStartDate(
    date: DayJsNativeType,
    weekdays: typeof Weekdays
  ): Date {
    const nextAllowableDay = dayjs(date).utc(true);
    const dayOfWeek = nextAllowableDay.get(DayJsUnit.day);
    if (
      Object.values(weekdays).includes(
        nextAllowableDay
          .day(dayOfWeek)
          .format(DayJsDateFormat.nameOfDay) as Weekdays
      )
    ) {
      return dayjs
        .utc(nextAllowableDay)
        .startOf(DayJsUnit.week)
        .add(one, DayJsUnit.week)
        .day(Day.monday) // monday
        .toDate();
    }
    return nextAllowableDay.toDate();
  }

  public static endDate(
    date: DayJsNativeType,
    weekdays: typeof Weekdays
  ): Date {
    return dayjs
      .utc(DateTimeService.nextStartDate(date, weekdays))
      .add(twentyNine, DayJsUnit.days)
      .toDate();
  }

  public static nextStartDateWithoutUTC(
    date: DayJsNativeType,
    weekdays: typeof Weekdays
  ): Date {
    const nextAllowableDay = dayjs(date);
    const dayOfWeek = nextAllowableDay.get(DayJsUnit.day);
    if (
      Object.values(weekdays).includes(
        nextAllowableDay
          .day(dayOfWeek)
          .format(DayJsDateFormat.nameOfDay) as Weekdays
      )
    ) {
      return nextAllowableDay
        .add(three, DayJsUnit.day)
        .day(Day.monday)
        .toDate();
    }
    return nextAllowableDay.toDate();
  }

  public today(): dayjs.Dayjs {
    return dayjs().utc(true);
  }

  public yesterday(): dayjs.Dayjs {
    return this.subtract(undefined, one, DayJsUnit.days);
  }

  // day before yesterday( 2 days before today)
  public twoDaysAgo(): dayjs.Dayjs {
    return this.subtract(undefined, two, DayJsUnit.days);
  }

  // day before yesterday( 2 days before today)
  public threeDaysAgo(): dayjs.Dayjs {
    return this.subtract(undefined, three, DayJsUnit.days);
  }

  public calculateAutoStartDateRange() {
    return {
      startDate: initialStartingDate,
      endDate: this.threeDaysAgo()
    };
  }

  public calculateAutoEndDateRange() {
    return {
      startDate: dayjs().utc(true),
      endDate: finalEndingDate
    };
  }

  public calculateAllTimeDateRange() {
    return {
      startDate: initialStartingDate,
      endDate: finalEndingDate
    };
  }

  public calculateAllTimeDateRangeForAlerts() {
    return {
      startDate: initialStartingDate,
      endDate: this.today()
    };
  }

  public maximumEndDate(): dayjs.Dayjs {
    return this.yesterday();
  }

  public calculateBulkWeeklyMaxDateForBenchmark(): dayjs.Dayjs {
    const subtractWeekCount = this.getNumberOfDaysToAvoidForWeeklyBenchmark();
    const subtractDayCount = this.today().day();
    return this.today()
      .subtract(subtractWeekCount, DayJsUnit.week)
      .subtract(subtractDayCount, DayJsUnit.days);
  }

  public calculateDaysFromToday() {
    return {
      startDate: this.today(),
      endDate: this.today()
    };
  }

  public calculateDaysFromYesterday() {
    return {
      startDate: this.yesterday(),
      endDate: this.yesterday()
    };
  }

  public calculatePrevious7Days() {
    return {
      startDate: this.maximumEndDate().subtract(six, DayJsUnit.days),
      endDate: this.maximumEndDate()
    };
  }

  public calculatePrevious14Days() {
    return {
      startDate: this.maximumEndDate().subtract(thirteen, DayJsUnit.days),
      endDate: this.maximumEndDate()
    };
  }

  public calculatePrevious30Days() {
    return {
      startDate: this.maximumEndDate().subtract(twentyNine, DayJsUnit.days),
      endDate: this.maximumEndDate()
    };
  }

  public calculatePrevious7DaysIncludingToday() {
    return {
      startDate: this.today().subtract(six, DayJsUnit.days),
      endDate: this.today()
    };
  }

  public calculatePrevious14DaysIncludingToday() {
    return {
      startDate: this.today().subtract(thirteen, DayJsUnit.days),
      endDate: this.today()
    };
  }

  public calculatePrevious30DaysIncludingToday() {
    return {
      startDate: this.today().subtract(twentyNine, DayJsUnit.days),
      endDate: this.today()
    };
  }

  public calculatePrevious30DaysAsStringArray() {
    return [
      this.maximumEndDate()
        .subtract(twentyNine, DayJsUnit.days)
        .format(DayJsDateFormat.fullDate),
      this.maximumEndDate().format(DayJsDateFormat.fullDate)
    ];
  }

  public calculatePrevious52Weeks() {
    return {
      startDate: this.maximumEndDate().subtract(fiftyTwo, DayJsUnit.weeks),
      endDate: this.maximumEndDate()
    };
  }

  public calculateThisMonth() {
    return {
      startDate: this.maximumEndDate().startOf(DayJsUnit.month),
      endDate: this.maximumEndDate()
    };
  }

  public calculatePriorMonth() {
    return {
      startDate: this.maximumEndDate()
        .subtract(one, DayJsUnit.month)
        .startOf(DayJsUnit.month),
      endDate: this.maximumEndDate()
        .subtract(one, DayJsUnit.month)
        .endOf(DayJsUnit.month)
    };
  }

  public calculateCurrentQuarter() {
    return {
      startDate: this.maximumEndDate().startOf(DayJsUnit.quarter),
      endDate: this.maximumEndDate()
    };
  }

  public calculateCurrentYear() {
    return {
      startDate: this.maximumEndDate().startOf(DayJsUnit.year),
      endDate: this.maximumEndDate()
    };
  }

  public format(date: DayJsNativeType, format: string | undefined): string {
    return dayjs(date).utc(true).format(format);
  }

  public difference(
    first: DayJsNativeType,
    second: DayJsNativeType,
    unit: CalendarUnit = DayJsUnit.day
  ): number {
    return dayjs(first).utc(true).diff(dayjs(second).utc(true), unit);
  }

  public startDateAndEndDateInclusiveDifference(
    first: DayJsNativeType,
    second: DayJsNativeType
  ): number {
    return (
      dayjs(first).utc(true).diff(dayjs(second).utc(true), DayJsUnit.day) + 1
    );
  }

  public add(
    first: DayJsNativeType,
    numberToAdd: number,
    unit: ManipulateType | undefined
  ): dayjs.Dayjs {
    return dayjs(first).utc(true).add(numberToAdd, unit);
  }

  public subtract(
    first: DayJsNativeType,
    numberToSubtract: number,
    unit: ManipulateType | undefined
  ): dayjs.Dayjs {
    return dayjs(first).utc(true).subtract(numberToSubtract, unit);
  }

  public addWithoutUtc(
    first: DayJsNativeType,
    numberToAdd: number,
    unit: ManipulateType | undefined
  ): dayjs.Dayjs {
    return dayjs(first).add(numberToAdd, unit);
  }

  public subtractWithoutUtc(
    first: DayJsNativeType,
    numberToSubtract: number,
    unit: ManipulateType | undefined
  ): dayjs.Dayjs {
    return dayjs(first).subtract(numberToSubtract, unit);
  }

  public formatWithoutUtc(
    date: DayJsNativeType,
    format: string | undefined
  ): string {
    return dayjs(date).format(format);
  }

  public calculateLastCompleteWeekForBenchmark(): {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
  } {
    const endDate = this.calculateBulkWeeklyMaxDateForBenchmark();
    return {
      startDate: endDate.startOf(DayJsUnit.week),
      endDate
    };
  }

  public calculateFirstCompleteWeekByMonth(date: dayjs.Dayjs): {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
  } {
    return {
      startDate: dayjs(date).utc().startOf(DayJsUnit.month),
      endDate: dayjs(date)
        .utc()
        .startOf(DayJsUnit.month)
        .add(six, DayJsUnit.days)
    };
  }

  public calculateCompleteMonthByDate(date: dayjs.Dayjs): {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
  } {
    return {
      startDate: dayjs(date).utc().startOf(DayJsUnit.month),
      endDate: dayjs(date).utc().endOf(DayJsUnit.month)
    };
  }

  public calculateLastAvailableMonthForBenchmark(): {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
  } {
    const subtractMonthCount =
      this.getNumberOfMonthsToAvoidForMonthForBenchmark();
    return {
      startDate: this.twoDaysAgo()
        .subtract(subtractMonthCount, DayJsUnit.month)
        .startOf(DayJsUnit.month),
      endDate: this.twoDaysAgo()
        .subtract(subtractMonthCount, DayJsUnit.month)
        .endOf(DayJsUnit.month)
    };
  }

  getNumberOfMonthsToAvoidForMonthForBenchmark(): number {
    // Enable previous month only after 3rd of the current month its requirement for the Benchmarks
    return this.today().date() > three ? one : two;
  }

  getNumberOfDaysToAvoidForWeeklyBenchmark(): number {
    // Past week will be available after Tuesday of current week
    return this.today().day() < three ? one : zero;
  }

  setInitialMaxDateForBenchMarkBulkMode(): dayjs.Dayjs {
    const subtractMonthCount =
      this.getNumberOfMonthsToAvoidForMonthForBenchmark();
    return this.today()
      .subtract(subtractMonthCount, DayJsUnit.month)
      .endOf(DayJsUnit.month);
  }

  calculatePrevious90Days() {
    return {
      startDate: this.maximumEndDate().subtract(ninty, DayJsUnit.days),
      endDate: this.maximumEndDate()
    };
  }

  public formatWithoutLocalTimeZone(
    date: DayJsNativeType,
    format: string | undefined
  ): string {
    return dayjs(date).utc(false).format(format);
  }

  prepareDateStartAndStopForFiltersInitialCall(): string[] | null {
    const option = this.currentRangeOptions.find(
      (op) => op.name === latestThirtyDays
    );
    const dateRangeObj = option ? option.action : null;
    if (dateRangeObj) {
      const startDate = DateTimeService.dayJsDate(
        dateRangeObj.startDate
      ).format(DayJsDateFormat.fullDate);
      const endDate = DateTimeService.dayJsDate(dateRangeObj.endDate).format(
        DayJsDateFormat.fullDate
      );
      return [startDate, endDate];
    }
    return null;
  }

  getRelativeDayOptionByRelativeDay(
    relativeDayFilterValue: FilterValue
  ): CurrentDropdownOption | undefined {
    return [
      ...this.settingsManagerOptions,
      ...this.currentRangeOptions,
      ...this.benchmarkRangeOptions
    ].find((option) => option.name === relativeDayFilterValue);
  }

  public calculateFirstMondayOfMonthFromDate(date: dayjs.Dayjs): {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
  } {
    const daysTillMonday = (8 - date.day()) % 7;
    const startDate = dayjs(date.set('date', date.date() + daysTillMonday));

    return {
      startDate,
      endDate: startDate.add(six, DayJsUnit.days)
    };
  }

  public systemWideMinDateForDatePicker() {
    return dayjs('01-01-2001', 'MM-DD-YYYY');
  }

  public generateStartDateOptions() {
    const startDateOptions = [];
    const today = new Date(); // Use the actual current date

    const sixMonthsAgo = new Date(today);
    const currentValue = today.getMonth() + 1;
    sixMonthsAgo.setMonth(today.getMonth() - 6); // Set to 6 months ago

    const startYear = sixMonthsAgo.getFullYear();
    const startMonth = sixMonthsAgo.getMonth();

    // Dynamic end year calculation based on start month
    const endYear = currentValue > 6 ? startYear - 2 : startYear - 1; // 2 years ago if startMonth >= June, else 1 year ago
    const endMonth = (startMonth - 6 + 12) % 12; // Adjust for wrap-around

    for (let year = startYear; year >= endYear; year--) {
      const monthStart = year === startYear ? startMonth : 11; // Start from the calculated start month or December
      const monthEnd = year === endYear ? endMonth : 0; // End at the calculated end month or January

      for (let month = monthStart; month >= monthEnd; month--) {
        const monthName = new Intl.DateTimeFormat('en-US', {
          month: 'short'
        }).format(new Date(year, month));
        const valueInYearMonthDate = `${year}-${(month + 1)
          .toString()
          .padStart(2, '0')}-01`;
        startDateOptions.push({
          label: `${monthName} ${year}`,
          value: valueInYearMonthDate
        });
      }
    }

    return startDateOptions;
  }

  getLastDayAtSixthMonth(date: string): string {
    const inputDate = dayjs.tz(date, 'YYYY-MM-DD', 'UTC');

    // Add 6 months to the input date.
    // Then move to the start of that month and subtract 1 day to get the last day of the previous month.
    const resultDate = inputDate.add(5, 'month').endOf('month');

    // Format the result to 'YYYY-MM-DD'
    return resultDate.format('YYYY-MM-DD');
  }

  getFirstDayOfSixMonthsAgo() {
    const today = new Date();
    const sixMonthsAgo = new Date(today);
    sixMonthsAgo.setMonth(today.getMonth() - 6);

    const year = sixMonthsAgo.getFullYear();
    const month = (sixMonthsAgo.getMonth() + 1).toString().padStart(2, '0');

    return `${year}-${month}-01`;
  }

  public calculatePreviousPeriod(date: DateRange) {
    const dayDifference: number = date.endDate.diff(date.startDate, 'day') + 1;
    return {
      startDate: dayjs(date.startDate, monthDateYearFormat)
        .utc(true)
        .subtract(dayDifference, 'day'),
      endDate: dayjs(date.startDate, monthDateYearFormat)
        .utc(true)
        .subtract(1, 'day')
    };
  }

  stripTimeFromDates(dates: string[]) {
    return dates.map((date: string) => date.split('T')[0]);
  }
}
