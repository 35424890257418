/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Observable, catchError, from, of } from 'rxjs';

// Services
import { ApiService } from '@portal/app/shared/services/api.service';

// Types
import { DataEndpoint, Filter } from '@portal/app/shared/types';

@Injectable({
  providedIn: 'root'
})
export class DataPointService {
  constructor(
    private apiService: ApiService // private filterService: FilterService, // private formatService: FormatService
  ) {}

  fetchDataPoints(filters: Filter[]): Observable<any> {
    const dashboardDataPromise = this.apiService.getDashboardData({
      filters,
      type: DataEndpoint.dataPoints
    });

    return from(dashboardDataPromise).pipe(
      catchError((error) => {
        console.error('Error in fetchDataPoints:', error);
        return of([]);
      })
    );
  }
}
