import { AdwordsHandler } from '@portal/app/dashboard/integrations/shared/vendor-handlers/adwords-handler';
import { BingHandler } from '@portal/app/dashboard/integrations/shared/vendor-handlers/bing-handler';
import { CJHandler } from '@portal/app/dashboard/integrations/shared/vendor-handlers/cj-handler';
import { CriteoHandler } from '@portal/app/dashboard/integrations/shared/vendor-handlers/criteo-handler';
import { FacebookHandler } from '@portal/app/dashboard/integrations/shared/vendor-handlers/facebook-handler';
import { GAHandler } from '@portal/app/dashboard/integrations/shared/vendor-handlers/ga-handler';
import { ImpactRadiusHandler } from '@portal/app/dashboard/integrations/shared/vendor-handlers/impactRadius-handler';
import { ImpactRadiusTransactionHandler } from '@portal/app/dashboard/integrations/shared/vendor-handlers/impactRadiusTransaction-handler';
import { KlaviyoHandler } from '@portal/app/dashboard/integrations/shared/vendor-handlers/klaviyo-handler';
import { PepperjamHandler } from '@portal/app/dashboard/integrations/shared/vendor-handlers/pepperjam-handler';
import { PinterestHandler } from '@portal/app/dashboard/integrations/shared/vendor-handlers/pinterest-handler';
import { RedditHandler } from '@portal/app/dashboard/integrations/shared/vendor-handlers/reddit-handler';
import { ShareASaleHandler } from '@portal/app/dashboard/integrations/shared/vendor-handlers/shareasale-handler';
import { ShopifyHandler } from '@portal/app/dashboard/integrations/shared/vendor-handlers/shopify-handler';
import { SnapchatHandler } from '@portal/app/dashboard/integrations/shared/vendor-handlers/snapchat-handler';
import { TikTokHandler } from '@portal/app/dashboard/integrations/shared/vendor-handlers/tiktok-handler';
import { TradedeskHandler } from '@portal/app/dashboard/integrations/shared/vendor-handlers/tradedesk-handler';
import { GA4Handler } from '@portal/app/dashboard/integrations/shared/vendor-handlers/ga4-handler';
import { DV360Handler } from '@portal/app/dashboard/integrations/shared/vendor-handlers/dv360-handler';
import { ProprietarySOTHandler } from '@portal/app/dashboard/integrations/shared/vendor-handlers/proprietarysot-handler';
import { AmazonHandler } from '@portal/app/dashboard/integrations/shared/vendor-handlers/amazon-handler';
import { SteelHouseHandler } from '@portal/app/dashboard/integrations/shared/vendor-handlers/steelhouse-handler';
import { ApplovinHandler } from '@portal/app/dashboard/integrations/shared/vendor-handlers/applovin-handler';

export const VENDOR_FACTORY = {
  facebook: FacebookHandler,
  criteo: CriteoHandler,
  shopify: ShopifyHandler,
  adwords: AdwordsHandler,
  ga: GAHandler,
  pinterest: PinterestHandler,
  tiktok: TikTokHandler,
  klaviyo: KlaviyoHandler,
  bing: BingHandler,
  snapchat: SnapchatHandler,
  cj: CJHandler,
  tradedesk: TradedeskHandler,
  shareasale: ShareASaleHandler,
  reddit: RedditHandler,
  impact: ImpactRadiusHandler,
  impactTransaction: ImpactRadiusTransactionHandler,
  pepperjam: PepperjamHandler,
  ga4: GA4Handler,
  dbm: DV360Handler,
  proprietarysot: ProprietarySOTHandler,
  amazon: AmazonHandler,
  steelhouse: SteelHouseHandler,
  applovin: ApplovinHandler
};
