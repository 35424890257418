@if (items.length && items.length > 1) {
  <div class="gx-0" [style.max-width]="'111rem'">
    <div class="col-4 ms-auto d-flex justify-content-end align-items-center">
      <div
        class="btn-group btn-group-sm"
        role="group"
        aria-label="Basic radio toggle button group">
        @for (item of items; track item) {
          <input
            type="radio"
            class="btn-check"
            name="btnradio"
            id="tab-bar-radio"
            autocomplete="off"
            [checked]="item.checked" />
          <label
            class="btn btn-outline-dark"
            for="tab-bar-radio"
            (click)="$event.preventDefault(); navigate(item)"
            >{{ item.title }}</label
          >
        }
      </div>
    </div>
  </div>
}
