import { Component } from '@angular/core';
import { AuthService } from '@portal/app/shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';
import { SelectionService } from '@portal/app/shared/services/selection.service';

@Component({
  template: `<h5>Redirecting...</h5>`
})
export class SmartRedirectComponent {
  constructor(
    private readonly selectionService: SelectionService,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {
    let literalId = '';
    let productId = '';
    this.route.params.pipe(take(1)).subscribe({
      next: (params) => {
        literalId = params.literalId || '';
        productId = params.productId || '';
      }
    });
    this.authService
      .setFirstClientAndBrand()
      .pipe(take(1))
      .subscribe({
        next: () => {
          // build selection slug
          const brandSlug = this.selectionService.buildSelectionSlug();
          this.router
            .navigate(['a', brandSlug, 'products', productId, literalId], {
              replaceUrl: true
            })
            .catch((error) => console.error(error));
        }
      });
  }
}
