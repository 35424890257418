import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { saveAs } from 'file-saver';
import { DataResponse, ExportTableType } from '@portal/app/shared/types';
import { DatasetBaseService } from '@portal/app/shared/services/dataset-base.service';
import { ViewStore } from '@portal/app/shared/state/view.store';
import { DateTimeService } from '@portal/app/shared/services/date-time.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DatasetExportService extends DatasetBaseService<DataResponse> {
  constructor(
    private readonly http: HttpClient,
    protected readonly viewStore: ViewStore,
    protected readonly dateService: DateTimeService
  ) {
    super(viewStore, dateService);
  }

  getExportParams(): Params {
    return {
      format: 'csv',
      showFooter: false,
      literalId: 'mimComparisonDataV2WithCalc'
    };
  }

  async exportData(
    filters: Record<string, string | number | Date | string[]>,
    dimensions: string[],
    format: ExportTableType
  ): Promise<void> {
    const productParams = this.getParams();
    const exportParams = this.getExportParams();

    const params = {
      ...productParams,
      dimensions,
      filter: { ...filters, dataSets: ['mimComparisonDataV2WithCalc'] },
      exportObjParams: {
        ...exportParams,
        format
      }
    };

    try {
      const response = await firstValueFrom(
        this.http.post(
          `${DatasetBaseService.measuredUriV2}dataviz/data/export-data-set`,
          params,
          { responseType: 'blob' }
        )
      );

      if (response) {
        const filename = `${productParams.clientId}-${productParams.brandId}-${exportParams.literalId}.${format}`;
        saveAs(response, filename); // Ensure response is a Blob
      }
    } catch (error) {
      console.error('Export failed:', error);
    }
  }
}
