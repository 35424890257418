import { Component, Input, OnInit } from '@angular/core';
import { AvatarSize, IAvatarConfig } from './m-avatar.component.types';
import { isEmpty } from 'lodash-es';
import { colors } from '@design-system/styles/colors';

@Component({
  selector: 'm-avatar',
  templateUrl: './m-avatar.component.html',
  styleUrls: ['./m-avatar.component.scss']
})
export class MAvatarComponent implements OnInit {
  private avatarSizeMap!: Record<
    AvatarSize,
    { size: string; fontSize: string }
  >;

  @Input() avatars: IAvatarConfig[] = [];
  @Input() grouped = true;

  defaultBgColor = colors['gray-400'];
  defaultFontColor = colors['gray-1000'];
  firstAvatar!: IAvatarConfig;

  ngOnInit() {
    this.initializeAvatarSizeMap(); // Call the separate method to initialize the map
    if (this.avatars && this.avatars.length > 0) {
      this.avatars.forEach((avatar) => {
        this.initializeLabel(avatar);
        this.initializeDefaultStyle(avatar);
        this.initializeDarkMode(avatar);
      });
      this.firstAvatar = this.avatars[0] as IAvatarConfig;
    }
  }

  private initializeAvatarSizeMap() {
    this.avatarSizeMap = {
      [AvatarSize.small]: {
        size: '1.5rem',
        fontSize: '0.75rem'
      },
      [AvatarSize.xsmall]: {
        size: '1rem',
        fontSize: '0.5rem' // TODO: get typography css classes mapped along with sizes from the design team
      },
      [AvatarSize.default]: {
        size: '2rem',
        fontSize: '1rem'
      },
      [AvatarSize.large]: {
        size: '3rem',
        fontSize: '1.5rem'
      },
      [AvatarSize.xlarge]: {
        size: '4rem',
        fontSize: '2rem'
      }
    };
  }

  private initializeDarkMode(avatar: IAvatarConfig) {
    if (avatar.darkMode) {
      avatar.bg = colors['bg-gray-1000'];
      avatar.color = colors['text-gray-000'];
    }
  }

  private initializeDefaultStyle(avatar: IAvatarConfig) {
    if (!avatar.bg) {
      avatar.bg = this.defaultBgColor;
      avatar.color = this.defaultFontColor;
    }
  }

  private initializeLabel(avatar: IAvatarConfig) {
    if (avatar.email && !isEmpty(avatar.email)) {
      avatar.initials = this.extractInitials(avatar.email);
    }
  }

  sizeValue(avatar: IAvatarConfig): string {
    return this.avatarSizeMap[avatar.size || AvatarSize.default].size;
  }

  fontSize(avatar: IAvatarConfig): string {
    return this.avatarSizeMap[avatar.size || AvatarSize.default].fontSize;
  }

  private extractInitials(email: string) {
    const namePart = email.split('@')[0] || '';
    return namePart
      .split('.')
      .map((part) => part.charAt(0).toUpperCase())
      .join('');
  }
}
