import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  ViewEncapsulation
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Panel } from 'primeng/panel';

@Component({
  selector: 'm-panel',
  templateUrl: './m-panel.component.html',
  styleUrls: ['./m-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MPanelComponent),
      multi: true
    }
  ]
})
export class MPanelComponent extends Panel {
  constructor(el: ElementRef, cd: ChangeDetectorRef) {
    super(el, cd);
  }
}
