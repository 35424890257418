import {
  User,
  UserDetails,
  UserDetailsInterface
} from '@portal/app/shared/types';
import { Injectable } from '@angular/core';
import { SelectionService } from '@portal/app/shared/services/selection.service';
import { IUserAccess } from '@libs/apis';

export enum UserRole {
  superUser = 'SUPERUSER',
  clientAdmin = 'CLIENT_ADMIN',
  clientUser = 'CLIENT_USER',
  measuredDev = 'MEASURED_DEV',
  measuredSol = 'MEASURED_SOL'
}

export enum UserType {
  internal = 'internal',
  external = 'external'
}

@Injectable()
export class UserModel implements UserDetailsInterface {
  public static unknownBrand = 'Unknown';
  public static measuredBrand = 'Measured';

  public user: User;

  constructor(user: User) {
    this.user = user;
  }

  public getRole(): string {
    return this.user.role;
  }

  public getAssignedRole(
    selectedClientId: number | undefined,
    selectedBrandId: number | undefined
  ): string | null {
    if (this.user.roles && this.user.roles.length) {
      const userRoleValue = Object.values(UserRole);
      const assignedRole = this.user.roles
        ?.filter(
          (role) =>
            (!role.clientId && !role.brandId) ||
            (role.clientId === selectedClientId && !role.brandId) ||
            (role.clientId === selectedClientId &&
              role.brandId === selectedBrandId)
        )
        .sort(
          (role1, role2) =>
            userRoleValue.indexOf(role2.role as UserRole) -
            userRoleValue.indexOf(role1.role as UserRole)
        );
      return assignedRole && assignedRole.length
        ? (assignedRole[0] as IUserAccess).role
        : this.user.role;
    }
    return this.user.role;
  }

  public getRoleLabel(): string {
    switch (this.getRole()) {
      case UserRole.superUser:
        return 'Super User';
      case UserRole.clientAdmin:
        return 'Client Admin';
      case UserRole.clientUser:
        return 'User';
      case UserRole.measuredDev:
        return 'Measured Developer';
      case UserRole.measuredSol:
        return 'Measured Solutions';
      default:
        return 'User';
    }
  }

  public isSuperUser(selectionService: SelectionService): boolean {
    const { clientId, brandId } = selectionService.getClientIdAndBrandId();
    return (
      this.getAssignedRole(clientId, brandId) === UserRole.superUser ||
      this.getAssignedRole(clientId, brandId) === UserRole.measuredDev ||
      this.getAssignedRole(clientId, brandId) === UserRole.measuredSol
    );
  }

  public isMeasuredUser(): boolean {
    return this.getUsername().includes('@measured.com');
  }

  public hasAccessToMultipleClientOrBrandIds(
    selectionService: SelectionService
  ): boolean {
    const numClients = this.getClientIds().length;
    const numBrands = this.getBrandIds().length;
    return (
      numClients === 0 ||
      numClients > 1 ||
      numBrands === 0 ||
      numBrands > 1 ||
      this.isSuperUser(selectionService)
    );
  }

  public getClientIds(): number[] {
    // returns an empty array if no Client Ids are identified
    return this.user.clientIds.map((id) => parseInt(id, 10));
  }

  public getBrandIds(): number[] {
    // returns an empty array if no Brand Ids are identified
    return this.user.brandIds.map((id) => parseInt(id, 10));
  }

  public getUsername(): string {
    return this.user.userName;
  }

  public getEmail(): string {
    return this.user.email;
  }

  public getUserType(): User['userType'] {
    return this.user.userType;
  }

  public userDetails(selectionService: SelectionService): UserDetails {
    return {
      id: this.getUsername(),
      email: this.getEmail(),
      role: this.getRoleLabel(),
      userType: this.getUserType(),
      multiBrandAccess: JSON.stringify(
        this.hasAccessToMultipleClientOrBrandIds(selectionService)
      )
    };
  }

  public getClientName(selectionService: SelectionService): string {
    if (this.isMeasuredUser()) {
      return UserModel.measuredBrand;
    } else {
      const { brand } = selectionService.getSelection();
      if (brand != null) {
        // pull from brand (CU / CA user, has selected a client/brand)
        return brand.brandName;
      }
    }
    return UserModel.unknownBrand;
  }
}
