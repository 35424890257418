import { Component, Input, OnInit } from '@angular/core';
import {
  FieldDefinitions,
  Filter,
  TabElement,
  TabSectionElement
} from '@portal/app/shared/types';
import { NativeSectionsService } from '@portal/app/shared/services/native-sections.service';
import { ApiService } from '@portal/app/shared/services/api.service';
import { FiltersService } from '@portal/app/shared/services/filters.service';
import { take } from 'rxjs';
import { ViewService } from '@portal/app/shared/services/view.service';
import { ContextStore } from '@portal/app/shared/state/context.store';
import { cloneDeep, forEach } from 'lodash-es';
import { sortByDisplayOrder } from '../utils';

@Component({
  selector: 'portal-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnInit {
  @Input() tabGroup: TabSectionElement | undefined = undefined;
  @Input() fieldDefinitions: FieldDefinitions = {};
  @Input() filters: Filter[] = [];
  @Input() dashboardLiteralId: string | null = null;
  public tabElements: TabElement[] = [];
  public activeIndex = 0;
  public tabsControlFilter!: Filter | undefined;

  constructor(
    public readonly nativeSectionsService: NativeSectionsService,
    private readonly apiService: ApiService,
    public readonly filtersService: FiltersService,
    public readonly viewService: ViewService,
    public readonly contextStore: ContextStore
  ) {}

  ngOnInit(): void {
    this.prepareTabElements();
    this.tabsControlFilter = this.prepareTabFilter(
      this.tabGroup as TabSectionElement
    );
    if (this.tabsControlFilter) {
      this.activeIndex = this.getTabIndexFromTabLiteralId(
        this.tabsControlFilter.value as string,
        this.tabElements
      );
    }
  }

  getTabIndexFromTabLiteralId(
    tabControlFilterValue: string,
    tabElements: TabElement[]
  ): number {
    let finalIndex = 0;
    tabElements.find((tabElement, index) => {
      if (tabElement.literalId === tabControlFilterValue) {
        finalIndex = index;
      }
    });
    return finalIndex;
  }

  getTabLiteralIdFromIndex(
    tabIndex: number,
    tabElements: TabElement[]
  ): string | undefined {
    let finalLiteralId;
    forEach(tabElements, (tab, index) => {
      if (index === tabIndex) {
        finalLiteralId = tab.literalId;
      }
    });
    return finalLiteralId;
  }

  tabChange(event: { index: number }): void {
    this.activeIndex = event.index;
    if (this.tabsControlFilter) {
      this.updateTabSectionFilterValue(event.index);
      this.updateFilters(this.activeIndex);
    }
  }

  updateTabSectionFilterValue(index: number) {
    const tabLiteralId = this.getTabLiteralIdFromIndex(index, this.tabElements);
    if (this.tabsControlFilter && tabLiteralId) {
      this.tabsControlFilter.value = tabLiteralId;
    }
    const tabFilterInFilters = this.filters.find(
      (fil) => fil.literalId === this.tabsControlFilter?.literalId
    );
    if (tabFilterInFilters) {
      tabFilterInFilters.value = tabLiteralId as string;
    }
  }

  prepareTabFilter(tabSectionLayout: TabSectionElement): Filter | undefined {
    if (
      tabSectionLayout &&
      tabSectionLayout.CONTROL &&
      tabSectionLayout.CONTROL?.filterControl &&
      tabSectionLayout.CONTROL?.filterControl.length
    ) {
      const controlFilters = tabSectionLayout.CONTROL?.filterControl;
      if (controlFilters?.length) {
        return this.filters.find((filter) => {
          return filter.literalId === controlFilters[0]?.dashboardFilterId;
        });
      }
      return undefined;
    }
    return undefined;
  }

  prepareTabElements(): void {
    if (this.tabGroup) {
      this.tabElements = this.tabGroup.elements
        .sort(sortByDisplayOrder)
        .map((tab) => {
          tab.elements = (tab.elements as TabElement[]).map((tabElement) => {
            return this.nativeSectionsService.convertToWidgetLayout(
              tabElement as TabElement,
              this.filters
            ) as TabElement;
          }) as TabElement[];
          return tab;
        }) as TabElement[];
    }
  }

  // if a filter is assigned to the tab section, change in tabs is considered as change in filters
  // update filters
  updateFilters(index: number) {
    // remove elements of the tab before fetching and updating context filers
    // once context updated reassign elemnts

    const elementsOfCurrentTab = cloneDeep(this.tabElements[index]?.elements);
    (this.tabElements[index] as TabElement).elements = [];
    this.filtersService
      .getFilters(this.filters)
      .pipe(take(1))
      .subscribe({
        next: (filters) => {
          const receivedFilters = filters;
          this.filters =
            this.nativeSectionsService.setFilterDefaults(receivedFilters);
          this.filters = this.viewService.updateFilterValues(
            receivedFilters,
            this.filters
          );
          this.filtersService.updateDateBasedOnRelativeDayFilter(this.filters);
          this.apiService.modifyParams(this.filters);
          this.contextStore.changeFilterContext(this.filters);
          // using setTimeOut to make sure elements are reassigned after the context filters are updated
          (this.tabElements[index] as TabElement).elements =
            elementsOfCurrentTab as TabElement['elements'];
        }
      });
  }
}
