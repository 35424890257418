@if (isLoading || isRecommendedLoading) {
  <div class="my-4 col-span-1 h-full">
    <m-skeleton height="420px" width="100%"></m-skeleton>
  </div>
}

<div
  class="my-4"
  *ngIf="!isLoading && !isRecommendedLoading && data?.items as benchmarks">
  <m-card>
    <div header class="flex justify-between items-center w-full">
      <div class="sh2 text-gray-1000">Top 5 Benchmarks</div>
    </div>
    <div class="col-span-1 mt-4">
      <m-benchmarks-carousel
        [value]="benchmarks"
        [numVisible]="1"
        [numScroll]="1"
        [circular]="true"
        (onPage)="onPageChange($event)"
        (click)="$event.stopPropagation()"
        (navigateRequest)="navigateToBenchmark()">
      </m-benchmarks-carousel>
    </div>
  </m-card>
</div>
