import { VendorHandler } from '@portal/app/dashboard/integrations/shared/vendor-handlers/vendor-handler';
import {
  IVendorConnectConfirmation,
  IVendorConnectInitRequest,
  IVendorConnectInitResponse
} from '@portal/app/dashboard/integrations/models/vendor/VendorIntegrationModels';
import { IVendorConfigurationRequest } from '@portal/app/dashboard/integrations/models/vendor/IVendorConfigurationRequest';
import { IVendorInitiateRequest } from '@portal/app/dashboard/integrations/models/vendor/IVendorInitiateRequest';
import { IVendorReConfigurationRequest } from '@portal/app/dashboard/integrations/models/vendor/IVendorReConfigurationRequest';
import { IVendorOAuthConfirmationRequest } from '@portal/app/dashboard/integrations/models/vendor/IVendorOAuthConfirmationRequest';

export class ProprietarySOTHandler implements VendorHandler {
  confirmVendor(data: IVendorConnectConfirmation): void {
    console.warn('ProprietarySOTHandler', data);
  }

  handleVendorInitiateResponse(data: IVendorConnectInitResponse): void {
    console.warn('ProprietarySOTHandler', data);
  }

  prepareRequestObjectToConfigureVendor(
    accounts: {
      accountName: string;
      accountId: string;
      managerId?: string;
      disabled: boolean;
    }[]
  ): IVendorConfigurationRequest {
    console.warn('ProprietarySOTHandler', accounts);
    return {} as IVendorConfigurationRequest;
  }

  prepareRequestObjectToInitiateVendor(
    vendorDetails: IVendorConnectInitRequest
  ): IVendorInitiateRequest {
    console.warn('ProprietarySOTHandler', vendorDetails);
    return {} as IVendorInitiateRequest;
  }

  prepareRequestObjectToReConfigureVendor(
    accounts: {
      accountName: string;
      accountId: string;
      managerId?: string;
      disabled: boolean;
    }[],
    dsInstanceIds: number[]
  ): IVendorReConfigurationRequest {
    console.warn('ProprietarySOTHandler', accounts, dsInstanceIds);
    return {} as IVendorReConfigurationRequest;
  }

  prepareRequestToConfirmVendor(
    confirmationData?: Record<string, unknown>
  ): IVendorOAuthConfirmationRequest {
    console.warn('ProprietarySOTHandler', confirmationData);
    return {} as IVendorOAuthConfirmationRequest;
  }
}
