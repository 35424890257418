import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { DashboardRequestParameters } from '@portal/app/shared/types';

@Injectable({
  providedIn: 'root'
})
export class ViewStore {
  public selectedBCPDId = new BehaviorSubject<DashboardRequestParameters>({
    clientId: 0,
    brandId: 0,
    literalId: '',
    productId: ''
  });

  private readonly titleSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>('');

  get title(): Observable<string> {
    return this.titleSubject.asObservable();
  }

  changeTitle(value: string): Observable<string> {
    const obs = new Observable((subscriber: Subscriber<string>) => {
      subscriber.next(value);
      subscriber.complete();
    });

    obs.subscribe({
      next: () => this.titleSubject.next(value)
    });

    return obs;
  }

  setBrandClientProductDashboardId(data: DashboardRequestParameters): void {
    this.selectedBCPDId.next(data);
  }
}
